import { Component, OnInit } from '@angular/core';
import { selectorAuth } from '../../../auth/auth.reducer';
import { Store } from '@ngrx/store';
import { AuthService } from '../../../auth/auth.service';
import { ActingAs } from 'app/interfaces/auth.interfaces';
@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit {
  user;
  businesses;
  actingAs: ActingAs;

  constructor(public store: Store<any>, public authService: AuthService) {
    this.store.select(selectorAuth).subscribe((next) => {
      this.user = next.user;
      this.businesses = next.businesses;
      this.actingAs = next.actingAs;
    });
  }

  selectBusiness(business_id) {
    this.authService.setActingAs(business_id, true);
  }

  ngOnInit() {}
}
