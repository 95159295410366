import { selectorUser } from './../../auth/auth.reducer';
import { AuthUser } from './../../interfaces/auth.interfaces';
import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { NotificationsService } from './notifications.service';
import Notification from './notification';

@Component({
  moduleId: module.id,
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  public notifications: Notification[] = [];
  user: AuthUser;

  constructor(private notificationsService: NotificationsService, private store: Store<any>) {
    this.store.select(selectorUser).subscribe((next) => (this.user = next));
  }

  ngOnInit() {
    this.notificationsService.publication().subscribe((notification) => {
      if (!notification) {
        return;
      }

      this.notifications = [notification, ...this.notifications];
      setTimeout(() => this.close(notification), this.user.is_admin && notification.type !== 'success' ? 20000 : 8000);
    });
  }

  close(removedNotication: Notification) {
    this.notifications = this.notifications.filter((notification) => notification !== removedNotication);
  }

  notificationClass(notification) {
    return {
      success: notification.type === 'success',
      error: notification.type === 'error',
      alert: notification.type === 'alert',
    };
  }
}
