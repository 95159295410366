import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { selectorActingAs } from 'app/auth/auth.reducer';
import * as noUiSlider from 'nouislider';

@Component({
  selector: 'app-material-shift-selector',
  templateUrl: './material-shift-selector.component.html',
  styleUrls: ['./material-shift-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MaterialShiftSelectorComponent implements OnInit {
  @Input() availableRanges: Array<Array<number>>;
  @Input() selectedRange: Array<any> = [9, 17];
  @Output() changeShift: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('rangeSlider', { static: true }) slider: ElementRef;

  constructor(private store: Store<any>) {
    this.store.select(selectorActingAs).subscribe((next) => {
      this.availableRanges = JSON.parse(next.default_bucket_shifts);
    });
  }

  rangeString(range) {
    return `${Math.floor(parseInt(range[0], 10))}:${
      range[0] % 1 === 0 ? '00' : (parseFloat(range[0]) % 1) * 60
    }   - ${Math.floor(parseInt(range[1], 10))}:${range[1] % 1 === 0 ? '00' : (parseFloat(range[1]) % 1) * 60}`;
  }

  selectShift(values) {
    this.selectedRange = values;
    this.changeShift.emit(this.selectedRange);
  }

  initSlider() {
    noUiSlider.create(this.slider.nativeElement, {
      start: this.selectedRange,
      connect: true,
      range: {
        min: 0,
        max: 23.75,
      },
      step: 0.25,
    });

    this.slider.nativeElement.noUiSlider.on('update', (values) => {
      this.selectedRange = values;
      this.changeShift.emit(this.selectedRange);
    });
  }
  ngOnInit() {
    if (!this.availableRanges || !this.availableRanges.length) {
      this.initSlider();
    } else {
      this.selectedRange = this.availableRanges[0];
    }
    this.changeShift.emit(this.selectedRange);
  }
}
