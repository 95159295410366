<section>
  <heading 
    [icon]="iconTypes.Details"
    [color]="uiColours.Blue">
    <h4 class="heading-title">Consignment Details</h4>
  </heading>
  <div class="light-card general-options">
    <div class="form-wrap">
      <div class="row">
        <input-field 
          (click)="openDatePickerModal()" 
          [svgIcon]="'details.svg'" 
          [type]="'text'"
          (valueChanged)="consignment.delivery_date = $event" 
          [value]="consignment.delivery_date"
          [label]="'Delivery Date'"></input-field>
        <input-field [placeholder]="'Eg.  08:00'" [type]="'text'" (valueChanged)="consignment.shift_start = $event"
          [value]="consignment.shift_start" [label]="'Shift Start'">
        </input-field>
        <input-field [placeholder]="'Eg. 17:00'" [type]="'text'" (valueChanged)="consignment.shift_end = $event"
          [value]="consignment.shift_end" [label]="'Shift End'">
        </input-field>
        <select-field [label]="'Warehouse/Store'"
          (valueChanged)="consignment.warehouse_id = $event.value; consignment.warehouse_name = $event.name"
          [options]="warehouseOptions" [selectedOption]="warehouseOptions[0]"></select-field>
      </div>
      <div class="row">
        <app-autocomplete 
          [placeholder]="'Eg. Woodstock'" 
          [sourceData]="zoneData" 
          [label]="'Zone (Optional)'"
          (valueChanged)="shipment.consignment = $event.trim()" 
          [value]="shipment.consignment">
        </app-autocomplete>
        <input-field [placeholder]="'Eg. BLW00000000S1'" [type]="'text'"
          (valueChanged)="shipment.invoice_number = $event" [value]="shipment.invoice_number"
          [label]="'Referece/Invoice Number'"></input-field>
        <input-field [placeholder]="'Eg. Picup Technologies'" [type]="'text'"
          (valueChanged)="shipment.contact.customer_name = $event" [value]="shipment.contact.customer_name"
          [label]="'Customer Name'"></input-field>
        <input-field [placeholder]="'Eg. 021 000 0000'" [type]="'tel'"
          (valueChanged)="shipment.contact.customer_phone = $event" [value]="shipment.contact.customer_phone"
          [label]="'Phone Number'"></input-field>
      </div>
      <div class="row address">
        <app-places-autocomplete (placesResponse)="onPlacesChange()" [value]="shipment.address">
        </app-places-autocomplete>
      </div>
      <div class="row parcel-selector">
        <div *ngFor="let parcelType of parcelTypes" class="counter">
          <img [src]="'/assets/img/' + parcelType + '.svg'" [alt]="parcelType + ' icon'" />
          <div class="spacer"></div>
          <div class="info">
            <svg-icon src="/assets/img/icons/down-grey.svg" (click)="removeParcel(parcelType)"></svg-icon>
            <h3>{{ shipment.parcels[parcelType] }}</h3>
            <svg-icon src="/assets/img/icons/up-grey.svg" (click)="addParcel(parcelType)"></svg-icon>
          </div>
        </div>
      </div>
      <div class="row footer justify-end">
        <app-basic-button 
          [title]="'Cancel'" 
          [processing]="processing" 
          [type]="buttonTypes.Primary" 
          (click)="setInitialValues()">
        </app-basic-button>
        <app-basic-button 
          [title]="'Add Consignment'" 
          [processing]="processing" 
          [active]="true" 
          [type]="buttonTypes.Highlight"
          (click)="validate()"></app-basic-button>
      </div>
    </div>
  </div>
</section>