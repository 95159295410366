import { clearPostDispatchFilters } from './../dashboard/post-dispatch/post-dispatch.actions';
import { filter, switchMap, take, takeUntil, } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import {
  setAccounts,
  selectorUser,
  setActingAs,
  selectorActingAs,
  setUser,
  setBusinesses,
  selectorBusinesses,
} from './auth.reducer';
import { Store } from '@ngrx/store';
import axios from 'app/api/axios';
import { BehaviorSubject, Observable, Subject, combineLatest, interval, of } from 'rxjs';
import { ActingAs, AuthUser, FSGlobalConfig, UberEnabledWarehousesConfig } from 'app/interfaces/auth.interfaces';
import { NotificationsService } from 'app/shared/notifications/notifications.service';
import * as fromAuth from './auth.reducer';
import { clearBucketFilters } from 'app/dashboard/buckets/manage-bucket.actions';
import { clearActiveTripStore } from 'app/dashboard/new-trips/store/new-trips.actions';
import { AxiosResponse } from 'axios';
import { LocalStorageService } from 'app/shared/local-storage.service';
import { AccmanLoginResponse, GetAccountDetailsResponse, LoginUserData } from './auth.interface';
import { AngularFirestore } from '@angular/fire/firestore';
import { AccessManagementService } from './access-management.service';
import { FreshworksService } from 'app/shared/freshworks/freshworks.service';
import { HttpClient, HttpContext } from '@angular/common/http';
import { signUpInterface } from 'app/login/login.component';
import { USE_AUTH_INTERCEPTOR } from 'app/shared/interceptors/auth-token-context';
import { jwtDecode } from 'jwt-decode';

interface DecodedToken {
  exp: number;
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  authError = new BehaviorSubject(undefined);
  siteUnderMaintenance = new BehaviorSubject(undefined);
  pendingInvites = new BehaviorSubject([]);
  authState: firebase.User = null;
  token: string;
  businesses;
  user: AuthUser;
  actingAs: ActingAs;
  isAdmin = false;
  isAuthenticated = false;
  authLost = new BehaviorSubject(false);
  loading = new BehaviorSubject(false);
  useKLRouting: boolean = false;
  public loggingIn$: Subject<boolean> = new Subject<boolean>();
  public readonly otpRequired$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  set oneTimePinRequired(value: boolean) {
    this.otpRequired$.next(value);
  }
  private unsubscribe$ = new Subject<void>();
  private readonly isFreshWorksLoaded$ = this.freshworksService.isLoaded$;

  set loginStatus(status: boolean) {
    this.loggingIn$.next(status)
  }

  private resetLoading$ = new BehaviorSubject<boolean>(false);
  set passwordResetLoading(value: boolean) {
    this.resetLoading$.next(value);
  }
  public isResetPasswordLoading$ = this.resetLoading$.asObservable();

  private creatingAccount$ = new BehaviorSubject<boolean>(false);
  set creatingAccount(value: boolean) {
    this.creatingAccount$.next(value);
  }
  public isCreatingAccount$ = this.creatingAccount$.asObservable();

  private fsTokenLoaded$ = new BehaviorSubject<boolean>(false);
  set fsTokenLoaded(value: boolean) {
    this.fsTokenLoaded$.next(value);
  }
  public isFsTokenLoaded$ = this.fsTokenLoaded$.asObservable();

  constructor(
    public notificationsService: NotificationsService,
    public router: Router,
    public afAuth: AngularFireAuth,
    public firestore: AngularFirestore,
    public store: Store<fromAuth.authState>,
    public localStorageService: LocalStorageService,
    private accessManagementService: AccessManagementService,
    private freshworksService: FreshworksService,
    private httpClient: HttpClient
  ) {
    this.checkMaintenance();
    this.storeCourierIntegrationOrderRecreateConfig();

    const user$ = this.store.select(selectorUser);
    const actingAs$ = this.store.select(selectorActingAs);
    const businesses$ = this.store.select(selectorBusinesses);
    const isAuthenticated$ = this.store.select(fromAuth.isUserAuthenticated);

    combineLatest([user$, actingAs$, businesses$, isAuthenticated$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([user, actingAs, businesses, isAuthenticated]: [AuthUser, ActingAs, any, boolean]) => {
        this.user = user
        this.actingAs = actingAs;
        this.businesses = businesses;
        this.isAuthenticated = isAuthenticated;
      });

    this.authLost
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((next) => {
        if (next) {
          this.logout();
        }
      });

    this.initTokenCheck();
  }

  private initTokenCheck(): void {
    interval(180000)
      .pipe(
        filter(() => this.isAuthenticated === true),
        switchMap(() => of(this.isTokenExpiringSoon())),
        takeUntil(this.unsubscribe$))
      .subscribe({
        next: (isTokenExpiring: boolean) => {
          if (isTokenExpiring) {
            this.refreshUserDetails();
          }
        },
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getUserAccessRoles(): Promise<unknown> {
    if (this.actingAs) {
      return axios.get('/user/' + this.user.user_id + '/get-access-mapping/' + this.actingAs.id).then((response) => {
        this.store.dispatch(new fromAuth.setUserAccessRoles(response?.data));
      });
    }
  }

  updateFirebaseUser(user_model): void {
    // used in the manage-user-service
    this.afAuth.currentUser
      .then((user) => {
        user.updateEmail(user_model.email);
        user.updateProfile({ displayName: user_model.name });
      })
      .then(() => {
        this.notificationsService.publish({ type: 'success', message: 'Please log in with your new details.' });
      })
      .catch((error) => {
        this.notificationsService.publish({ type: 'error', message: error });
        throw error;
      });
  }

  checkMaintenance(): void {
    const collection = this.firestore.collection('frontend-config');
    collection
      .doc('global')
      .valueChanges()
      .subscribe((data: FSGlobalConfig) => {
        if (data.maintenance_mode.is_active === true) {
          this.logout();
          this.siteUnderMaintenance.next(data.maintenance_mode);
        }
      });
  }

  checkIsAdmin(): Promise<unknown> {
    const promise = new Promise((resolve, _reject) => {
      resolve(this.user.is_admin);
    });
    return promise;
  }

  signUp(newUserData: {
    name,
    phone,
    password,
    email
  }): Promise<unknown> {
    return axios({
      method: 'POST',
      url: '/user/signup/',
      data: newUserData,
    }).then((res) => {
      return res.data;
    });
  }

  signUp2(newUserData: signUpInterface): Observable<any> {
    return this.httpClient.post(`${environment.integration.rootUrl}user/signup/`, newUserData,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('id_token')}` }
      })
  }

  acceptInvite(business_id: string): void {
    axios({
      method: 'POST',
      url: '/business/' + business_id + '/accept-invitation',
    })
      .then(() => {
        this.updateBusinesses();
        this.notificationsService.publish({ type: 'success', message: 'Invite Accepted' });
      })
      .catch((error) => this.notificationsService.publish({ type: 'error', message: error.message }));
  }

  updateBusinesses(): void {
    this.clientInitialize()
      .then((res) => {
        this.store.dispatch(setBusinesses(res));
      })
      .catch((error) => this.authError.next(error));
  }

  getAccounts(): void {
    this.clientInitialize()
      .then((res: []) => {
        this.store.dispatch(setAccounts(res));
      })
      .catch((error) => this.authError.next(error));
  }

  updateUser(): void {
    this.clientInitialize()
      .then((res: AuthUser) => {
        this.store.dispatch(setUser(res));
      })
      .catch((error) => this.notificationsService.publish({ type: 'error', message: error.message }));
  }

  initialize(): void {
    // if (this.useKLRouting) {
    //   if (this.user.preferred_business_id && this.user.preferred_business_id.substring(0, 8) === 'business') {
    //     this.setKLActingAs(this.user.preferred_business_id);
    //     this.handleAccessManagementBusiness(this.user.preferred_business_id);
    //   } else if (this.businesses?.length) {
    //     this.setKLActingAs(this.businesses[0].business_id);
    //     this.handleAccessManagementBusiness(this.businesses[0].business_id);
    //   } else {
    //     this.setKLActingAs(null);
    //   }
    //   return;
    // }
    // Passes null as business ID when acting as user
    // Check if user has preferred default account, if so, use that. Otherwise, if user only has one business, use that, else regular user logon.
    if (this.user.preferred_business_id && this.user.preferred_business_id.substring(0, 8) === 'business') {
      this.setActingAs(this.user.preferred_business_id, true);
      this.handleAccessManagementBusiness(this.user.preferred_business_id);
    } else if (this.businesses?.length) {
      this.setActingAs(this.businesses[0].business_id, true);
      this.handleAccessManagementBusiness(this.businesses[0].business_id);
    } else {
      this.setActingAs(null, true);
    }
  }

  handleAccessManagementBusiness(businessId: string): void {
    this.accessManagementService.setCurrentBusinessId(businessId);
    this.accessManagementService.getBusinessModules(businessId);
  }

  getAccountDetails(businessId: string): Promise<GetAccountDetailsResponse> {
    const accountData = {
      business_id: null,
      user_id: this.user.user_id,
    };
    if (businessId) {
      accountData.business_id = businessId;
    }
    return axios({
      method: 'POST',
      url: '/account/get-account-details',
      data: accountData,
    }).then((response: AxiosResponse<GetAccountDetailsResponse>) => {
      return response?.data;
    });
  }

  getWarehousesForBusiness(): Promise<unknown> {
    if (this.actingAs) {
      return axios({
        method: 'POST',
        url: 'generic/cqrs/get-warehouses-for-business',
        data: { business_id: this.actingAs?.id },
      }).then((response) => {
        this.store.dispatch(new fromAuth.setWarehouses(response?.data.output));
      });
    }
  }

  updateAccount(businessId: string): void {
    this.getAccountDetails(businessId).then((response) => {
      this.store.dispatch(setActingAs(response.acting_as));
    });
  }

  setKLActingAs(businessId: string): void {
    this.getAccountDetails(businessId)
      .then((response: GetAccountDetailsResponse) => {
        this.store.dispatch(new fromAuth.setWarehouses(''));
        this.store.dispatch(setActingAs(response.acting_as));
        this.store.dispatch(new clearBucketFilters());
        this.store.dispatch(new clearActiveTripStore());
        this.store.dispatch(new clearPostDispatchFilters());
        this.localStorageService.saveState(response.acting_as);
        this.router.navigate(['kl/dashboard']);
      })
      .catch((error) => {
        this.authError.next(error.message);
      });
  }

  setActingAs(businessId: string, redirectToDashboard: boolean): void {
    if (redirectToDashboard) {
      this.loading.next(true);
    }
    this.getAccountDetails(businessId)
      .then((response: GetAccountDetailsResponse) => {
        this.store.dispatch(new fromAuth.setWarehouses(''));
        this.store.dispatch(setActingAs(response.acting_as));
        this.store.dispatch(new clearBucketFilters());
        this.store.dispatch(new clearActiveTripStore());
        this.store.dispatch(new clearPostDispatchFilters());
        this.store.dispatch(new fromAuth.setUberEnabledWarehousesConfig(null));
        this.storeUberEnabledWarehousesConfig(response.acting_as.id);
        this.localStorageService.saveState(response.acting_as);
        if (redirectToDashboard) {
          this.navigateToDashboard();
          this.loading.next(false);
          this.loginStatus = false;
        }
      })
      .catch((error) => {
        this.authError.next(error.message);
      });
  }

  storeUberEnabledWarehousesConfig(business_id: string): void {
    const collection = this.firestore.collection('uber-enabled-warehouses');
    collection
      .doc(business_id)
      .valueChanges()
      .pipe(
        take(1)
      )
      .subscribe({
        next: (data: UberEnabledWarehousesConfig) => {
          if (data) {
            this.store.dispatch(new fromAuth.setUberEnabledWarehousesConfig(data));
          } else {
          }
        }});
  }

  navigateToDashboard(): void {
    this.router.navigate(['/dashboard']);
  }

  clientInitialize(): Promise<AuthUser | []> {
    return axios({
      method: 'GET',
      url: '/enterprise/client-initialize',
    })
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  storeCourierIntegrationOrderRecreateConfig(): void {
    const collection = this.firestore.collection('frontend-config');
    collection
      .doc('global')
      .valueChanges()
      .pipe(
        take(1)
      )
      .subscribe((data: FSGlobalConfig) => {
        if (data.courier_integration_order_recreate_config) {
          this.store.dispatch(new fromAuth.setCourierIntegrationConfig(data.courier_integration_order_recreate_config));
        }
        if(environment.overrideLastMileForDevReasons){
          // change this value for local development
          this.lastMileCollectionName('last-mile');
        } else {
          this.lastMileCollectionName(data.last_mile_collection_name);
        }
      });
  }

  public logout(): void {
    this.freshworksService.clearUserData();
    localStorage.removeItem('id_token');
    this.notificationsService.publish({ type: 'success', message: 'You have been logged out.' });
    this.store.dispatch(fromAuth.setIsAuthenticated(false));
    this.afAuth.signOut();
    this.router.navigate(['/']);
  }

  loginWithEmailAndPassword(email: string, password: string): Observable<any> {
    return this.httpClient.post(`${environment.accessManagement.baseUrl}/login/password/`,
      {
        Email: email,
        Password: password,
        As: 'User'
      },
      {
        context: new HttpContext().set(USE_AUTH_INTERCEPTOR, true)
      })
  }

  loginWithEmailPasswordAndOTP(email: string, password: string, otp: string): Observable<any> {
    return this.httpClient.post(`${environment.accessManagement.baseUrl}/login/password/`,
      {
        Email: email,
        Password: password,
        OneTimePin: otp,
        As: 'User'
      },
      {
        context: new HttpContext().set(USE_AUTH_INTERCEPTOR, true)
      })
  }

  SetUserDataWithAccessToken(userData: LoginUserData): void {
    this.token = userData.IdToken;
    localStorage.setItem('id_token', this.token);
    localStorage.setItem('cust_token', userData.CustomToken);
    this.tokenSignIn(userData.CustomToken);
    if (userData) {
      this.accessManagementService.SetUserDataWithAccessToken(userData);
      this.getAccounts();
    }
  }

  ChangePassword(oobCode: string, newPassword: string): Observable<any> {
    return this.httpClient.post(`${environment.accessManagement.baseUrl}/login/password-change`,
      {
        OobCode: oobCode,
        NewPassword: newPassword
      },
      {
        context: new HttpContext().set(USE_AUTH_INTERCEPTOR, true)
      }
    )
  }

  CheckPasswordComplexity(password: string): Observable<any> {
    return this.httpClient.post(`${environment.accessManagement.baseUrl}/helper/check-password-complexity`,
      {
        Password: password
      },
      {
        context: new HttpContext().set(USE_AUTH_INTERCEPTOR, true)
      }
    )
  }

  refreshToken(refreshToken: string): Observable<{ AccessToken: string, RefreshToken: string }> {
    return this.httpClient.get<{ AccessToken: string, RefreshToken: string }>(`${environment.accessManagement.baseUrl}/login/refresh?refreshToken=${refreshToken}`);
  }

  userDetails(): void {
    this.httpClient.get<LoginUserData>(`${environment.accessManagement.baseUrl}/login/details`,
      {
        context: new HttpContext().set(USE_AUTH_INTERCEPTOR, true)
      }
    ).pipe(take(1)).subscribe((value: LoginUserData) => {
      this.SetUserDataWithAccessToken(value);
    });
  }

  sendPasswordResetEmail(email: string): Observable<void> {
    return this.httpClient.get<void>(`${environment.accessManagement.baseUrl}/login/reset-password?Email=${encodeURIComponent(email)}`,
      {
        context: new HttpContext().set(USE_AUTH_INTERCEPTOR, true)
      }
    ).pipe(take(1));
  }

  confirmPasswordReset(password: string, oobCode: string): Observable<void> {
    return this.httpClient.post<void>(`${environment.accessManagement.baseUrl}/login/password-change`,
      {
        OobCode: oobCode,
        NewPassword: password
      },
      {
        context: new HttpContext().set(USE_AUTH_INTERCEPTOR, true)
      }
    ).pipe(take(1));
  }

  verifyPasswordResetCode(oobCode: string): Observable<any> {
    return this.httpClient.post<void>(`${environment.accessManagement.baseUrl}/login/verify-oob-code`,
      {
        OobCode: oobCode
      },
      {
        context: new HttpContext().set(USE_AUTH_INTERCEPTOR, true)
      }
    ).pipe(take(1));
  }

  tokenSignIn(idToken: string, anonymous: boolean = false): void {
    if (!anonymous && this.isTokenExpiringSoon()) {
      this.refreshUserDetails();
      return;
    }

    this.afAuth.signInWithCustomToken(idToken)
      .then(() => {
        this.fsTokenLoaded = true;
      }).catch(() => {
        this.notificationsService.publish({
          message: 'Login failed. An error occurred that prevents you from completing your login, please try again',
          type: 'error'
        })
        this.logout();
      })
  }

  private isTokenExpiringSoon(): boolean {
    const customAuthToken = localStorage.getItem('cust_token');

    if (!customAuthToken || customAuthToken === 'undefined') {
      return true;
    }

    const decodedToken: DecodedToken = jwtDecode(customAuthToken);
    const expirationTime = decodedToken.exp * 1000;
    const timeRemaining = expirationTime - Date.now();

    return timeRemaining <= 600000;
  }

  private refreshUserDetails(): void {
    this.httpClient.get<AccmanLoginResponse>(`${environment.accessManagement.baseUrl}/login/details`,
      {
        context: new HttpContext().set(USE_AUTH_INTERCEPTOR, true)
      }
    ).pipe(take(1)).subscribe({
      next: (value: AccmanLoginResponse) => {
        localStorage.setItem('cust_token', value.Value.CustomToken);
        this.tokenSignIn(value.Value.CustomToken);
      },
      error: () => {
        this.notificationsService.publish({
          message: 'Login failed. An error occurred that prevents you from completing your login, please try again',
          type: 'error'
        })
        this.logout();
      }
    });
  }

  private lastMileCollectionName(value: string): void {
    this.store.dispatch(fromAuth.setLastMileCollectionName(value));
  }
}
