<div class="summary" *ngIf="showSummary || context === 'lastMile'">
  <app-grid-container
    [columns]="'repeat(' + (context === 'courier' ? 7 : 4) + ', 1fr)'"
    [padding]="'0rem'"
    [colGap]="'2rem'"
    [rowGap]="'0rem'"
    [theme]="UiThemes.HighlightedSummary"
  >
    <app-stats-icon
      [icon]="IconTypes.Parcel"
      [dataSizeRem]="1.5"
      [iconColor]="UiColors.Blue"
      [iconSizeRem]="2"
      [label]="'Packages'"
      [loading]="false"
      [data]="totals.packages.toString()"
    >
    </app-stats-icon>
    <app-stats-icon
      [icon]="IconTypes.Parcel"
      [dataSizeRem]="1.5"
      [iconColor]="UiColors.Blue"
      [iconSizeRem]="2"
      [label]="'Collected'"
      [loading]="false"
      [data]="totals.collected.toString()"
    >
    </app-stats-icon>

    <app-stats-icon
      [icon]="IconTypes.Parcel"
      [dataSizeRem]="1.5"
      [iconColor]="UiColors.Green"
      [iconSizeRem]="2"
      [label]="'Delivered'"
      [loading]="false"
      [data]="totals.delivered.toString()"
    >
    </app-stats-icon>

    <app-stats-icon
      [icon]="IconTypes.Parcel"
      [dataSizeRem]="1.5"
      [iconColor]="UiColors.Red"
      [iconSizeRem]="2"
      [label]="'Failed'"
      [loading]="false"
      [data]="totals.failed.toString()"
    >
    </app-stats-icon>

    <app-stats-icon
      *ngIf="context === 'courier'"
      [icon]="IconTypes.Details"
      [dataSizeRem]="1.5"
      [iconColor]="UiColors.Blue"
      [iconSizeRem]="2"
      [label]="'Reference'"
      [loading]="false"
      [data]="courierReference"
    >
    </app-stats-icon>

    <app-stats-icon
      *ngIf="context === 'courier'"
      [icon]="IconTypes.Driver"
      [dataSizeRem]="1.5"
      [iconColor]="UiColors.Blue"
      [iconSizeRem]="2"
      [label]="'Courier'"
      [loading]="false"
      [data]="courierName"
    >
    </app-stats-icon>
    <!-- TODO: serviceTypeDescription does not exsist -->
    <!-- <app-stats-icon
      *ngIf="context === 'courier'"
      [icon]="IconTypes.Driver"
      [dataSizeRem]="1.5"
      [iconColor]="UiColors.Blue"
      [iconSizeRem]="2"
      [label]="'Service Type'"
      [loading]="false"
      [data]="serviceTypeDescription"
    >
    </app-stats-icon> -->
  </app-grid-container>
</div>

<div class="trip-waypoint" *ngFor="let reference of businessReferenceBreakdown">
  <heading
    [icon]="IconTypes.Profile"
    [showToggle]="true"
    [color]="reference.statusDotColor"
    (click)="collapsible.toggle()"
  >
    <div>
      <span>{{ reference.BusinessReference | uppercase }}</span> <br />
      <span class="from-to">
        {{ reference.Origin.WarehouseName ? reference.Origin.WarehouseName : reference.Origin.Contact.Name }} ->
        {{
          reference.Destination.WarehouseName ? reference.Destination.WarehouseName : reference.Destination.Contact.Name
        }}
      </span>
    </div>

    <div class="controls driver-status">
      <span *ngIf="reference.DriverName"
        ><app-icon [icon]="reference.statusIcon" [size]="1" [color]="reference.statusDotColor"></app-icon>
        {{ reference.DriverName | uppercase }}<br />
      </span>
      <span *ngIf="reference.status"
        ><app-icon [icon]="IconTypes.Dot" [size]="1" [color]="reference.statusDotColor"></app-icon>
        {{ reference.status | uppercase }}</span
      >
    </div>
  </heading>
  <div appCollapsible [expanded]="orderWaybill ? hasOrderWaybill(reference) : false" #collapsible="appCollapsible">
    <div class="order-summary">
      <app-grid-container [columns]="'repeat(3, 1fr)'" [colGap]="'1rem'" [padding]="'2rem'" [rowGap]="'2rem'">
        <div class="collection">
          <h4>Collection:</h4>
          <app-icon [icon]="IconTypes.Marker" [size]="1" [color]="UiColors.Blue"></app-icon>
          {{ reference.Origin?.Contact?.ClientAddress ? reference.Origin?.Contact?.ClientAddress + ' - ' : ""}} {{ reference.Origin.FormattedAddress }} <br />

          <div *ngIf="!reference.Origin.WarehouseId">
            <app-icon [icon]="IconTypes.Profile" [size]="1" [color]="UiColors.Blue"></app-icon>
            {{ reference.Origin.Contact.Name }} <br />
            <app-icon [icon]="IconTypes.Phone" [size]="1" [color]="UiColors.Blue"></app-icon>
            {{ reference.Origin.Contact.PhoneNumber }} <br />
            <app-icon [icon]="IconTypes.Mail" [size]="1" [color]="UiColors.Blue"></app-icon>
            {{ reference.Origin.Contact.Email }} <br />
            <app-icon [icon]="IconTypes.Details" [size]="1" [color]="UiColors.Blue"></app-icon>
            {{ reference?.Origin?.Contact?.Instructions ? reference.Origin.Contact.Instructions : '-' }} <br />
          </div>

          <div *ngIf="reference.Origin.WarehouseId">
            <app-icon [icon]="IconTypes.Profile" [size]="1" [color]="UiColors.Blue"></app-icon>
            {{ reference.Origin.Contact?.Name ? reference.Origin.Contact.Name : reference.Origin.WarehouseName }} <br />
            <app-icon [icon]="IconTypes.Phone" [size]="1" [color]="UiColors.Blue"></app-icon>
            {{ reference.Origin.Contact?.PhoneNumber ? reference.Origin.Contact.PhoneNumber : reference.Origin.WarehousePhone }} <br />
            <app-icon [icon]="IconTypes.Mail" [size]="1" [color]="UiColors.Blue"></app-icon>
            {{ reference.Origin.Contact?.Email ? reference.Origin.Contact.Email : reference.Origin.WarehouseEmail }} <br />
            <app-icon [icon]="IconTypes.Details" [size]="1" [color]="UiColors.Blue"></app-icon>
            {{ reference?.Origin?.Contact?.Instructions ? reference.Origin.Contact.Instructions : '-' }} <br />
          </div>
        </div>
        <div class="delivery">
          <h4>Delivery:</h4>
          <app-icon [icon]="IconTypes.Marker" [size]="1" [color]="UiColors.Blue"></app-icon>
          {{ reference.Destination?.Contact?.ClientAddress ? (reference.Destination?.Contact?.ClientAddress + ' - ') : ""}}{{ reference.Destination.FormattedAddress }} <br />

          <div *ngIf="!reference.Destination.WarehouseId">
            <app-icon [icon]="IconTypes.Profile" [size]="1" [color]="UiColors.Blue"></app-icon>
            {{ reference.Destination.Contact.Name }} <br />
            <app-icon [icon]="IconTypes.Phone" [size]="1" [color]="UiColors.Blue"></app-icon>
            {{ reference.Destination.Contact.PhoneNumber }} <br />
            <app-icon [icon]="IconTypes.Mail" [size]="1" [color]="UiColors.Blue"></app-icon>
            {{ reference.Destination.Contact.Email }} <br />
            <app-icon [icon]="IconTypes.Details" [size]="1" [color]="UiColors.Blue"></app-icon>
            {{ reference?.Destination?.Contact?.Instructions ? reference.Destination.Contact.Instructions : '-' }} <br />
          </div>

          <div *ngIf="reference.Destination.WarehouseId">
            <app-icon [icon]="IconTypes.Profile" [size]="1" [color]="UiColors.Blue"></app-icon>
            {{ reference.Destination.WarehouseName }} <br />
            <app-icon [icon]="IconTypes.Phone" [size]="1" [color]="UiColors.Blue"></app-icon>
            {{ reference.Destination.WarehousePhone }} <br />
            <app-icon [icon]="IconTypes.Mail" [size]="1" [color]="UiColors.Blue"></app-icon>
            {{ reference.Destination.WarehouseEmail }} <br />
          </div>
        </div>
        <div class="delivery">
          <app-flex-container [padding]="'1rem 0rem'" [justifyContent]="justifyContent.SpaceBetween">
            
            <app-basic-button
              *ngIf="showGoToOrder"
              [title]="'Go To Trip'"
              [type]="buttonTypes.Primary"
              (click)="goToOrder(reference.OrderId)"
            ></app-basic-button>
            <app-basic-button
              *ngIf="!showGoToOrder && reference.CourierOrderId"
              [title]="'Go To Courier Order'"
              [type]="buttonTypes.Primary"
              (click)="goToCourierOrder(reference.CourierOrderId)"
            >
            </app-basic-button>
            <app-basic-button
              [processing]="downloadingPOD"
              download
              *ngIf="orderPODs?.SignaturesDict[reference.OrderWaybill]"
              [title]="'Download POD'"
              [type]="buttonTypes.Primary"
              (click)="downloadPod(reference.OrderWaybill)"
            >
            </app-basic-button>
          </app-flex-container>
          <app-order-pod
            [reference]="reference"
            [podData]="orderPODs?.SignaturesDict[reference.OrderWaybill]"
          ></app-order-pod>
        </div>
      </app-grid-container>
      <app-table
        [columns]="context !== 'courier' ? parcelOverviewColumns : courierParcelOverviewColumns"
        [tableData]="reference.tableData"
        [rowsSelectable]="false"
      >
      </app-table>
    </div>
  </div>
</div>
