import { Pipe, PipeTransform } from '@angular/core';

enum HealthStateEnum {
  Good = 'good',
  Bad = 'bad',
  Warning = 'warning',
  Primary = 'primary',
  Default = '',
}

export const LastEventMapping = new Map<string, string>([
  ['CourierTrackingEventProcessed', HealthStateEnum.Primary],
  ['WaypointRouteEnded', HealthStateEnum.Primary],
  ['DriverArrivedAtWaypoint', HealthStateEnum.Primary],
  ['LastMileParcelsUnassigned', HealthStateEnum.Default],
  ['DriverEnRouteToWarehouse', HealthStateEnum.Primary],
  ['WaypointRouteOptimized', HealthStateEnum.Primary],
  ['WarehouseRouteOptimized', HealthStateEnum.Primary],
  ['ParcelsAssignedFromOrderAggregate', HealthStateEnum.Primary],
  ['OrderCompleted', HealthStateEnum.Good],
  ['OrderCancelled', HealthStateEnum.Bad],
  ['ParcelsCancelled', HealthStateEnum.Bad],
  ['CancelParcelsFailed', HealthStateEnum.Bad],
  ['OrderCreated', HealthStateEnum.Primary],
  ['CreateCourierOrderRequested', HealthStateEnum.Default],
  ['ParcelsAdded', HealthStateEnum.Default],
  ['ParcelDetailsUpdated', HealthStateEnum.Default],
  ['ParcelsAssignedToLastMile', HealthStateEnum.Good],
  ['LastMileParcelsTransferred', HealthStateEnum.Default],
  ['TriedToAssignLotteryDriver', HealthStateEnum.Default],
  ['SucceedToAssignLotteryDriver', HealthStateEnum.Good],
  ['FailedToAssignLotteryDriver', HealthStateEnum.Bad],
  ['StartLotteryScheduled', HealthStateEnum.Default],
  ['LotteryStarted', HealthStateEnum.Primary],
  ['LotteryFailedToStart', HealthStateEnum.Bad],
  ['LotterySkipped', HealthStateEnum.Default],
  ['LotteryCancelled', HealthStateEnum.Warning],
  ['CourierOrderStateUpdated', HealthStateEnum.Good],
  ['ParcelsUnassignedFromLastMileAggregate', HealthStateEnum.Primary],
  ['AssignLastMileParcelsFailed', HealthStateEnum.Warning],
  ['OptimizeRouteFailed', HealthStateEnum.Bad],
  ['ParcelsFromLastMileReconciled', HealthStateEnum.Default],
  ['OrderPaymentHandledFromOrderPaymentAggregate', HealthStateEnum.Good],
  ['LastMileRouteStarted', HealthStateEnum.Good],
  ['LastMileRouteEnded', HealthStateEnum.Good],
  ['LastMileRouteParcelsCollected', HealthStateEnum.Primary],
  ['LastMileRouteParcelsFailedCollection', HealthStateEnum.Bad],
  ['CreateCourierWaybillSucceeded', HealthStateEnum.Good],
  ['CourierWaybillCreated', HealthStateEnum.Good],
  ['ParcelsAssignedToCourierOrder', HealthStateEnum.Primary],
  ['CourierOrderStatusUpdated', HealthStateEnum.Primary],
  ['CourierOrderCompleteUpdated', HealthStateEnum.Good],
  ['CourierOrderCancelledUpdated', HealthStateEnum.Warning],
  ['CreateCourierOrderFailed', HealthStateEnum.Bad],
  ['FailedToCreateCourierWaybill', HealthStateEnum.Warning],
  ['DriverEnRouteToWaypoint', HealthStateEnum.Primary],
  ['DriverDeliveredParcelsAtWaypoint', HealthStateEnum.Good],
  ['DriverFinalizedAtWaypoint', HealthStateEnum.Good],
  ['LotterySkipped', HealthStateEnum.Bad],
  ['AllCollectionsFailed', HealthStateEnum.Bad],
  ['DriverCollectedParcelsAtWaypoint', HealthStateEnum.Good]
]);

@Pipe({
  name: 'lastEventColour'
})
export class LastEventColourPipe implements PipeTransform {

  transform(value: string): unknown {
    return LastEventMapping.get(value);
  }

}
