import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CarouselItem } from '../../carousel.interface';

@Component({
  selector: 'app-carousel-item',
  templateUrl: './carousel-item.component.html',
  styleUrls: ['./carousel-item.component.scss']
})
export class CarouselItemComponent implements OnInit {
  @Input() item: CarouselItem;
  @Output() itemClicked: EventEmitter<string | number> = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

}
