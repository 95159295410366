import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Order_Keys, OrdersTableColumns } from './order-selection.interfaces';
import { TableAction, TableColumn, TableDataMap, TableRow } from '../../../../shared/table/table.interfaces';
import { IconTypes } from '../../../../shared/icon/icon.interfaces';
import { UiColors } from '../../../../interfaces/ui.interfaces';
import { selectorActingAs, selectorWarehouses } from '../../../../auth/auth.reducer';
import { mapDataToTable } from '../../../../shared/table/table.helpers';
import { OrderSelectionService } from './order-selection.service';
import { ConfirmModalComponent } from '../../../../shared/modals/confirm-modal.component';
import { SimpleModalService } from 'ngx-simple-modal';
import { ChangeBucketComponent } from '../verify/change-bucket/change-bucket.component';
import { ManageBucketService } from '../../manage-bucket.service';
import { selectorActiveBucket } from '../../manage-bucket.reducer';
import { CreateBucketModalComponent } from 'app/shared/buckets/create-bucket-modal/create-bucket-modal.component';
import { UserRoles, Warehouse } from 'app/interfaces/auth.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';

@Component({
  selector: 'app-order-selection',
  templateUrl: './order-selection.component.html',
  styleUrls: ['./order-selection.component.scss'],
  providers: [OrderSelectionService],
})
export class OrderSelectionComponent implements OnInit, OnDestroy {
  @Input() state;
  @Input() user;
  @Input() user_permissions;
  @Input() readOnly = false;
  UserRoles = UserRoles;

  initialized = false;
  buttonTypes = ButtonTypes

  public orders = {
    selected: 0,
    unassigned: <TableRow[]>[],
    ignored: <TableRow[]>[],
  };
  private orderIds = {};

  public ordersBulkActions = {
    unassigned: <TableAction[]>[],
    ignored: <TableAction[]>[],
  };

  public ordersTableColumns: TableColumn[] = [
    { name: OrdersTableColumns.Id, displayName: '#', sortable: true, hidden: true },
    { name: OrdersTableColumns.BucketOrderId, displayName: 'Bucket Order #', hidden: true },
    { name: OrdersTableColumns.BusinessReference, displayName: 'Business Reference', sortable: true },
    { name: OrdersTableColumns.CustomerName, displayName: 'Customer Name', sortable: true },
    { name: OrdersTableColumns.CustomerPhone, displayName: 'Customer Phone', sortable: true },
    { name: OrdersTableColumns.CustomerAddress, displayName: 'Address', sortable: true },
  ];

  public ordersDataMap: TableDataMap = {
    cells: {
      [OrdersTableColumns.Id]: { column: OrdersTableColumns.Id, map: Order_Keys.OrderId },
      [OrdersTableColumns.BucketOrderId]: { column: OrdersTableColumns.BucketOrderId, map: Order_Keys.BucketOrderId },
      [OrdersTableColumns.BusinessReference]: {
        column: OrdersTableColumns.BusinessReference,
        map: Order_Keys.BusinessReference,
        displayPipeArgs: ['none'],
      },
      [OrdersTableColumns.CustomerName]: { column: OrdersTableColumns.CustomerName, map: Order_Keys.CustomerName },
      [OrdersTableColumns.CustomerPhone]: {
        column: OrdersTableColumns.CustomerPhone,
        map: Order_Keys.CustomerPhone,
        displayPipeArgs: ['none'],
      },
      [OrdersTableColumns.CustomerAddress]: {
        column: OrdersTableColumns.CustomerAddress,
        map: Order_Keys.GeocodedAddress,
        annotation_map: [Order_Keys.ClientAddress],
      },
    },
  };

  loadedOrders;
  warehouse_id;
  actingAs;
  couriers;
  tableNameMap = new Map();
  warehouses: Warehouse[];

  loaded = false;

  public ordersLoaded = false;
  private businessLoaded = false;
  public ordersSaved = false;
  public savingOrdersInProgress = false;
  public finaliseOrdersInProgress = false;

  public saveButtonLabel = 'Save';

  public IconTypes = IconTypes;
  public UiColors = UiColors;

  private orderSubscription;
  private savingInProgressSubscription;
  private savedSuccessfullySubscription;
  private finalisingInProgressSubscription;

  constructor(
    private store: Store<any>,
    private simpleModalService: SimpleModalService,
    private orderSelectionService: OrderSelectionService,
    private manageBucketService: ManageBucketService
  ) {}

  ngOnInit() {
    this.orderSelectionService
      .getCourierOrdersV2()
      .then((response) => {
        this.orderSelectionService.setCourierOrders(response);
      })
      .then(() => {
        this.store.select(selectorActingAs).subscribe((next) => {
          if (next) {
            this.actingAs = next;
            this.businessLoaded = true;
            this.manageBucketService
              .getCourierDetails(this.state.bucket_id)
              .then((response) => {
                this.couriers = response;
                this.ordersBulkActions.unassigned = this.createBulkActions(response);
                this.ordersBulkActions.ignored = this.createBulkActions(response);
                this.reassignInactiveCourierOrders();
                this.addDefaultBulkOptions('unassigned');
                this.addDefaultBulkOptions('ignored');

                this.loaded = true;
              })
              .then(() => {
                this.orderSubscription = this.orderSelectionService.orders$.subscribe((res) => {
                  if (!res) {
                    return;
                  }
                  this.ordersLoaded = true;
                  this.loadedOrders = res.data;
                  this.initTableNamesMap(this.couriers);
                  this.updateOrderObjectsWithCouriers();
                  this.addCouriersToOrdersObject();
                  // this.reassignInactiveCourierOrders();
                });
              });
          }
        });
      });

    this.store.select(selectorActiveBucket).subscribe((next) => {
      if (next) {
        this.warehouse_id = next.warehouse_id;
      }
    });

    this.store.select(selectorWarehouses).subscribe((next) => (this.warehouses = next));

    // Saving Trips
    this.savingInProgressSubscription = this.orderSelectionService.savingInProgress$.subscribe((observer) => {
      this.savingOrdersInProgress = observer || false;
      if (this.savingOrdersInProgress) {
        this.saveButtonLabel = 'Saving';
      }
    });

    this.savedSuccessfullySubscription = this.orderSelectionService.savedSuccessfully$.subscribe((observer) => {
      this.ordersSaved = observer || false;
      if (this.ordersSaved) {
        this.saveButtonLabel = 'Saved';
      }
    });

    // Finalising Trips
    this.finalisingInProgressSubscription = this.orderSelectionService.finalisingInProgress$.subscribe(
      (observer) => (this.finaliseOrdersInProgress = observer || false)
    );
  }

  initTableNamesMap(courierResponse: any): void {
    courierResponse?.forEach((courier) => {
      courier.service_types?.forEach((service_type) => {
        this.tableNameMap.set(service_type.id.toLowerCase(), service_type.description.replace(/_/g, ' '));
      });
    });
  }

  autoAssign(): void {
    this.manageBucketService.getAutoAssignCourierOrders().then((res) => {
      res?.forEach((order) => {
        this.loadedOrders.find((loadedOrder) => loadedOrder.order_id === order.OrderId)['courier_id'] = order.CourierId;
        this.loadedOrders.find((loadedOrder) => loadedOrder.order_id === order.OrderId)[
          'courier_service_type_uid'
        ] = order.CourierServiceTypeUid.toLowerCase();
      });
      this.initialize();
    });
  }

  initialize() {
    this.initialized = true;
    this.orders.unassigned = [];
    this.orders.ignored = [];
    this.loadedOrders?.forEach((order) => {
      if (!order.courier_id) {
        this.orders.unassigned = [
          ...this.orders.unassigned,
          ...mapDataToTable([order], this.ordersDataMap, this.ordersTableColumns),
        ];
      } else {
        if (this.orders[order.courier_id + ' - ' + order.courier_service_type_uid] === undefined) {
          this.orders[order.courier_id + ' - ' + order.courier_service_type_uid] = [];
        }
        this.orders[order.courier_id + ' - ' + order.courier_service_type_uid] = [
          ...this.orders[order.courier_id + ' - ' + order.courier_service_type_uid],
          ...mapDataToTable([order], this.ordersDataMap, this.ordersTableColumns),
        ];
        this.orders.selected++;
      }
    });
  }

  ngOnDestroy(): void {
    this.orderSubscription.unsubscribe();
    this.savingInProgressSubscription.unsubscribe();
    this.savedSuccessfullySubscription.unsubscribe();
    this.finalisingInProgressSubscription.unsubscribe();
  }

  createBulkActions(couriers) {
    const newActions = [];
    couriers?.forEach((courier) => {
      courier.service_types?.forEach((service_type) => {
        newActions.push({
          event: courier.courier_id + ' - ' + service_type.id.toLowerCase(),
          title: 'Send to ' + courier.name + ' - ' + service_type.description.replace(/_/g, ' '),
          icon: IconTypes.Courier,
          icon_color: UiColors.Green,
        });
      });
    });

    return newActions;
  }

  addDefaultBulkOptions(table) {
    const restageAction = {
      event: 'restageOrders',
      title: 'Re-Stage Orders',
      icon: IconTypes.Bucket,
      icon_color: UiColors.Yellow,
    };
    const changeBucketAction = {
      event: 'changeBucket',
      title: 'Move to Bucket',
      icon: IconTypes.Bucket,
      icon_color: UiColors.Blue,
    };
    const ignoreAction = {
      event: 'ignored',
      title: 'Ignore Order',
      icon: IconTypes.Cross,
      icon_color: UiColors.Red,
    };

    if (table === 'ignored') {
      this.ordersBulkActions[table].push(restageAction, changeBucketAction);
    } else {
      this.ordersBulkActions[table].push(restageAction, changeBucketAction, ignoreAction);
    }
  }

  updateOrderObjectsWithCouriers() {
    this.couriers?.forEach((courier) => {
      courier.service_types?.forEach((service_type) => {
        this.ordersBulkActions[
          courier.courier_id + ' - ' + service_type.id.toLowerCase()
        ] = [];

        this.ordersBulkActions[courier.courier_id + ' - ' + service_type.id.toLowerCase()].push({
          event: 'unassigned',
          title: 'Unassign orders',
          icon: IconTypes.Courier,
          icon_color: UiColors.Green,
        });
        this.addDefaultBulkOptions(courier.courier_id + ' - ' + service_type.id.toLowerCase());
      });
    });
    if (this.ordersLoaded && this.couriers && !this.initialized) {
      this.initialize();
    }
  }

  getCourierTableKeys(): string[] {
    return Object.keys(this.orders).filter((key) => key !== 'unassigned' && key !== 'selected' && key !== 'ignored');
  }

  getOrderIds(table): string[] {
    return this.orders[table].map((row) => this.getRowId(row));
  }

  getRowId(row: TableRow) {
    return this.getCellValue(row, 'id');
  }

  getCellValue(row: TableRow, cellName: string) {
    return row.cells.find((cell) => cell.column === cellName).value;
  }

  updateSelectedCount() {
    this.orders.selected = 0;
    this.getCourierTableKeys().map((key) => {
      this.orders.selected += this.orders[key].length;
    });
  }

  updateOrderIds() {
    this.getCourierTableKeys().map((key) => {
      this.orderIds[key] = this.getOrderIds(key);
    });
  }

  reassignInactiveCourierOrders() {
    if (!this.ordersLoaded || !this.businessLoaded) {
      return;
    }

    const activeCouriers = this.couriers.map((courier) => courier.courier_id);

    Object?.keys(this.orders)?.forEach((key) => {
      if (['unassigned', 'selected', ...activeCouriers].indexOf(key) === -1) {
        this.updateOrderTables(this.orders[key], key, 'unassigned');
      }
    });
  }

  saveOrders() {
    this.updateOrderIds();
    const courierBody = Object.entries(this.orderIds);
    const courierArray = [];
    courierBody?.forEach((courier) => {
      courierArray.push({
        courier_id: courier[0].split(' ')[0],
        courier_service_type_uid: courier[0].split(' ')[2],
        order_ids: courier[1],
      });
    });
    return this.orderSelectionService.selectCourierOrders(courierArray).then((response) => {
      return response;
    });
  }

  finaliseOrders() {
    this.saveOrders().then(() => {
      if (this.orders.unassigned.length > 0) {
        this.simpleModalService
          .addModal(ConfirmModalComponent, {
            title: 'Are you sure?',
            message: 'You still have unassigned orders. Finalising now will leave those orders unassigned.',
          })
          .subscribe((isConfirmed) => {
            if (!isConfirmed) {
              return;
            }
            this.orderSelectionService.finalise();
          });
      } else {
        this.orderSelectionService.finalise();
      }
    });
  }

  addCouriersToOrdersObject() {
    this.couriers?.forEach((courier) => {
      courier?.service_types?.forEach((service_type) => {
        {
          if (this.orders[courier.courier_id + ' - ' + service_type.id.toLowerCase()] === undefined) {
            this.orders[courier.courier_id + ' - ' + service_type.id.toLowerCase()] = [];
          }
        }
      });
    });
    if (this.ordersLoaded && this.couriers && !this.initialized) {
      this.initialize();
    }
  }

  resetOrderTables() {
    this.getCourierTableKeys().map((key) => {
      this.updateOrderTables(this.orders[key], key, 'unassigned');
    });
  }

  handleTableAction($event, table) {
    const action = $event.action.event;
    const order_ids = [];
    const bucket_order_ids = [];
    switch (action) {
      case 'restageOrders':
        $event.rows?.forEach((element) => {
          bucket_order_ids.push(element.cells.find((cell) => cell.column === 'bucketOrderId').value);
        });
        this.restageOrders(bucket_order_ids);
        break;
      case 'changeBucket':
        $event.rows?.forEach((element) => {
          order_ids.push(element.cells.find((cell) => cell.column === 'id').value);
        });
        this.changeBucket(order_ids);
        break;
      default:
        this.updateOrderTables($event.rows, table, $event.action.event);
        break;
    }
  }

  updateOrderTables(rows, fromTable, toTable) {
    // Workaround for orders that would disappear if you reselected the same table again
    if (fromTable === toTable) {
      return;
    }

    rows?.forEach((row) => this.orders[toTable].push(row));
    const selectedIds = rows.map((rowTo) => this.getRowId(rowTo));
    this.orders[fromTable] = this.orders[fromTable].filter((rowFrom) => {
      const id = this.getRowId(rowFrom);
      return selectedIds.indexOf(id) === -1;
    });

    this.updateSelectedCount();
    this.ordersSaved = false;
    this.saveButtonLabel = 'Save';
    if (this.ordersLoaded && this.couriers && !this.initialized) {
      this.initialize();
    }
  }

  changeBucket(order_ids) {
    this.simpleModalService.addModal(ChangeBucketComponent).subscribe((result) => {
      if (result) {
        if (result !== 'newBucket') {
          const changeBucketData = {
            new_bucket_id: result,
            order_ids: order_ids,
          };
          this.manageBucketService.changeBucket(changeBucketData).then(() => {
            this.orderSelectionService.getCourierOrders();
          });
        } else {
          const warehouses = { warehouses: this.warehouses };
          this.simpleModalService.addModal(CreateBucketModalComponent, warehouses).subscribe((create_result) => {
            const changeBucketData = {
              bucket_details: create_result,
              order_ids: order_ids,
            };
            this.manageBucketService.changeBucket(changeBucketData).then(() => {
              this.orderSelectionService.getCourierOrders();
            });
          });
        }
      }
    });
  }

  restageOrders(bucket_order_ids) {
    this.simpleModalService
      .addModal(ConfirmModalComponent, {
        title: 'Re-stage Orders',
        message: 'Are you sure? This can not be undone. Selected orders will be placed in your staging orders bucket. ',
      })
      .subscribe((isConfirmed) => {
        if (!isConfirmed) {
          return;
        }
        this.manageBucketService.restageOrders(bucket_order_ids).then(() => {
          this.orderSelectionService.getCourierOrders();
        });
      });
  }
}
