import { Component, Input, OnChanges } from '@angular/core';
import { getIconUrl} from './icon.helpers';
import {IconProps, IconTypes} from './icon.interfaces';
import {UiColors} from '../../interfaces/ui.interfaces';
import {setColorClass} from '../helpers';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})

export class IconComponent implements OnChanges, IconProps {
  @Input() icon: IconTypes;
  @Input() color: UiColors;
  @Input() size: number;
  @Input() showHover: boolean;
  @Input() isActive = false;

  url: string;
  classes: string;

  ngOnChanges(): void {
    this.url = getIconUrl(this.icon);
    this.classes = setColorClass(this.color) + (this.showHover ? ' showHover' : '') + (this.isActive ? ' isActive' : '');
  }
}
