import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import axios from 'app/api/axios';
import { NotificationsService } from '../../shared/notifications/notifications.service';
import { Store } from '@ngrx/store';
import { selectorUser, selectorActingAs } from 'app/auth/auth.reducer';
import { environment } from '../.././../environments/environment';
import { ActingAs } from 'app/interfaces/auth.interfaces';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class PlaceOrderService {
  environment = environment;

  user;
  actingAs: ActingAs;
  randomNum;
  isEnterprise;
  constructor(private notificationService: NotificationsService, private store: Store<any>, private router: Router) {
    this.store.select(selectorActingAs).subscribe((next) => (this.actingAs = next));
    this.store.select(selectorUser).subscribe((next) => (this.user = next));
  }

  quoteResult = new BehaviorSubject(undefined);

  createFreight(request) {
    request['business_id'] = this.actingAs.id;
    request['merchant_id'] = this.environment.merchantId;
    request['user_id'] = this.user.user_id;
    request['courier_costing'] = 'NONE';
    request['is_pre_assign_tracking_number'] = true;

    return axios({
      method: 'POST',
      url: 'basic/create/one-to-many',
      data: request,
    })
      .then(() => {
        this.notificationService.publish({ type: 'success', message: 'Freight trip placed successfully' });
        this.router.navigate(['/dashboard/post-dispatch/']);
        return;
      })
      .catch((error) => {
        this.notificationService.publish({ type: 'error', message: error.response?.data.message });
        throw error.response?.data;
      });
  }

  quotePicup(request) {
    if (this.actingAs.package === 'individual') {
      request['business_id'] = null;
    } else {
      request['business_id'] = this.actingAs.id;
    }
    request['merchant_id'] = this.environment.merchantId;
    request['user_id'] = this.user.user_id;

    if ((this.user.is_admin || this.actingAs.payment_methods.includes('Account')) && environment.envName !== 'Africa') {
      request['courier_costing'] = 'NONE';
    } else {
      request['courier_costing'] = 'NONE';
    }

    return axios({
      method: 'POST',
      url: 'integration/quote/one-to-many',
      data: request,
      headers: { 'api-key': this.actingAs.id },
    })
      .then((response) => {
        this.quoteResult.next(response);
        return response;
      })
      .catch((error) => {
        this.notificationService.publish({ type: 'error', message: error.response.data.message });
        throw error.response?.data;
      });
  }

  createOrder(picup_details): Promise<{ order_id: string }> {
    return axios({
      method: 'POST',
      url: 'integration/create/one-to-many',
      data: picup_details,
      headers: { 'api-key': this.actingAs.id },
    })
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        this.notificationService.publish({ type: 'error', message: error.response.data.message });
        throw error.response?.data;
      });
  }
}
