import { Component, OnDestroy } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationsService } from 'app/shared/notifications/notifications.service';
import { OperationsTrackingService } from 'app/operations-tracking/operations-tracking.service';
import { OrderFrontEndStateEnum } from 'app/dashboard/post-dispatch/post-dispatch.interfaces';
import { OrderStateEnum, OrderSummary } from 'app/operations-tracking/operations-tracking.interface';
import { OrderTrackingComponent } from '../order-tracking/order-tracking.component';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-scheduled',
  templateUrl: './scheduled.component.html',
  styleUrls: ['./scheduled.component.scss'],
})
export class ScheduledComponent extends OrderTrackingComponent implements OnDestroy {
  scheduledSubscription: Subscription;
  dataSource = new MatTableDataSource<OrderSummary>([]);
  OrderStateEnum = OrderStateEnum;
  constructor(
    public operationsTrackingService: OperationsTrackingService,
    public notificationsService: NotificationsService,
    public store: Store
  ) {
    super(notificationsService, operationsTrackingService, store);
    this.operationsTrackingService.usedIds$.pipe(takeUntil(this.unsubscribe$)).subscribe((ids: string[]) => {
      this.selectedIds = ids;
      this.getScheduledOrders();
    });
  }

  ngOnDestroy(): void {
    this.scheduledSubscription?.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getScheduledOrders(): void {
    const batches = this.getFirestoreBatchesForOrders(OrderFrontEndStateEnum.Scheduled);
    this.scheduledSubscription?.unsubscribe();
    this.scheduledSubscription = combineLatest(batches).subscribe((res) => {
      let orders: OrderSummary[] = [];
      orders = orders.concat(...res);
      this.dataSource.data = orders;
    });
  }
}
