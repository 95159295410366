import { Component, Directive, Inject, Injectable, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectorUser, selectorWarehouses } from 'app/auth/auth.reducer';
import { FSGlobalConfig, Warehouse } from 'app/interfaces/auth.interfaces';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AdminViewReports, Report, Reports, ReportTypeEnum, Tabs } from '../reporting.data';
import {  ReportingService } from '../reporting.service';
import { SelectWarehousesComponent } from './components/select-warehouses/select-warehouses.component';
import { DateAdapter } from '@angular/material/core';
import { MatDateRangeSelectionStrategy, DateRange, MAT_DATE_RANGE_SELECTION_STRATEGY } from '@angular/material/datepicker';
import { ConfirmationModalComponent, ConfirmationModalData } from 'app/shared/confirmation-modal/confirmation-modal.component';

// Force a maxrange on the datepicker
@Injectable()
export class MaxRangeSelectionStrategy<D>
implements MatDateRangeSelectionStrategy<D> {
  start: D;
  public delta: number;
  constructor(private _dateAdapter: DateAdapter<D>, private dialog: MatDialog) {}

  selectionFinished(date: D, currentRange: DateRange<D>): DateRange<D> {
    let { start, end } = currentRange;
    if (start == null || (start && end)) {
      start = date;
      end = null;
    } else if (end == null) {
      const maxDate = this._dateAdapter.addCalendarDays(start, this.delta);
      end = date ? (date > maxDate ? maxDate : date) : null;
    }
    if(this._dateAdapter.addCalendarDays(start, this.delta) >= end && end != null && this.delta === 7 && this.calculateDiff(start, end) === this.delta){
      this.warningMessageDisplay();
    }
    return new DateRange<D>(start, end);
  }

  calculateDiff(start: D, end: D): number{
    if(start != null && end != null) {
      const startDate = new Date(this._dateAdapter.toIso8601(start));
      const endDate = new Date(this._dateAdapter.toIso8601(end));
      const days = Math.floor((endDate.getTime() - startDate.getTime()) / 1000 / 60 / 60 / 24);
      return days;
    }
  }

  createPreview(
    activeDate: D | null,
    currentRange: DateRange<D>
  ): DateRange<D> {
    if (currentRange.start && !currentRange.end) {
      const maxDate = this._dateAdapter.addCalendarDays(
        currentRange.start,
        this.delta
      );
      const rangeEnd = activeDate
        ? activeDate > maxDate
          ? maxDate
          : activeDate
        : null;

      return new DateRange(currentRange.start, rangeEnd);
    }
    return new DateRange<D>(null, null);
  }

  warningMessageDisplay(): void{
    const configuration: MatDialogConfig<ConfirmationModalData> = {
      width: '25%',
      data: {
        cancelButtonText: 'Cancel',
        confirmationButtonText: 'Confirm',
        description: 'During business hours you cannot select a date range of greater than 7 days. If you want to download a report with a longer date range please try again after 19:00 SAST',
        title: 'Warning Message'
      },
    };
    const dialogRef: MatDialogRef<ConfirmationModalComponent, boolean> = this.dialog.open(
      ConfirmationModalComponent,
      configuration
    );

    dialogRef.afterClosed().pipe(
    ).subscribe(() => {

    });
  }
}

@Directive({
  selector: '[maxRange]',
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: MaxRangeSelectionStrategy
    }
  ]
})
export class MaxRangeDirective {
  constructor(
    @Inject(MAT_DATE_RANGE_SELECTION_STRATEGY)
    private maxRangeStrategy: MaxRangeSelectionStrategy<any>
  ) {}
  @Input() set maxRange(value: number) {
    this.maxRangeStrategy.delta = +value || 7;
  }
}

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss'],
})
export class ReportingComponent implements OnInit {
  tabs = Tabs;
  activeTab: string = '';
  routerSubscription: Subscription;
  rangePickerGroup: FormGroup;
  minDate: Date = new Date(new Date().setMonth(new Date().getMonth()-6));
  ReportTypeEnum = ReportTypeEnum;
  reports: Report[] = Reports;
  isAdminUser: boolean = false;
  isAdminView: boolean = false;
  isLoading: boolean = false;
  userWarehouses: Warehouse[] = [];
  dateMaxRange: number = 7;

  constructor(private router: Router, private reportingService: ReportingService, private store: Store, private dialog: MatDialog) {
    this.store.select(selectorUser).subscribe((x) => this.isAdminUser = x.is_admin);
    this.store.select(selectorWarehouses).subscribe((x) => (this.userWarehouses = x));
    this.initializeDateRangePicker();
    if (this.router.url.includes('admin')) {
      this.reports = AdminViewReports;
      this.isAdminView = true;
    }
  }

  ngOnInit(): void {
    this.getMaxRange();
  }

  initializeActiveTab(): void {
    this.routerSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const location = event.url.substring(event.url.lastIndexOf('/') + 1);
        this.activeTab = this.getActiveTab(location);
      });

    const defaultLocation = this.router.url.substring(this.router.url.lastIndexOf('/') + 1);
    this.activeTab = this.getActiveTab(defaultLocation);
  }

  initializeDateRangePicker(): void {
    const dateRange = this.reportingService.initializeDateRange();
    this.rangePickerGroup = new FormGroup({
      start: new FormControl(dateRange.startDate),
      end: new FormControl(dateRange.endDate),
    });
  }

  getActiveTab(route: string): string {
    return this.tabs.find((x) => x.route === route).name;
  }

  handleReport(report: Report): void {
    this.reportingService.setDateRange({startDate: this.rangePickerGroup.get('start').value, endDate: this.rangePickerGroup.get('end').value});
    switch (true) {
      case report.RequiresWarehouse: {
        this.handleWarehouseReport(report);
        break;
      }
      case report.UsesGenericService: {
        this.getReport(report);
        break;
      }
      default: {
        this.getAdminReport(report);
        break;
      }
    }
  }

  getReport(report: Report, warehouseIds?: string[]): void {
    this.isLoading = true;
    this.reportingService.getReport(report.Url, report.FileName, this.isAdminView, warehouseIds).then(() => {
      this.isLoading = false;
    }).catch(() => this.isLoading = false);
  }

  getAdminReport(report: Report): void {
    this.isLoading = true;
    this.reportingService.getAdminReport(report.Url, report.FileName).then(() => {
      this.isLoading = false;
    }).catch(() => this.isLoading = false);
  }

  handleWarehouseReport(report: Report): void {
    const dialogRef: MatDialogRef<SelectWarehousesComponent, string[]> = this.dialog.open(SelectWarehousesComponent, {
      width: '300px',
      data: { Warehouses: this.userWarehouses }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.length === 0) {
        return;
      }
      this.getReport(report, result);
    });
  }

  getMaxRange(): void{
    const globalConfig = this.reportingService.getDateRangePeriod();
    globalConfig.subscribe((config: FSGlobalConfig) => {
      if(config.reporting_days_limit){
        this.dateMaxRange = config.reporting_days_limit;
      }
    });
  }
}
