import { Component, OnInit, Input } from '@angular/core';
import { UiThemes, UiColors } from 'app/interfaces/ui.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-new-trips-sumnmary',
  templateUrl: './new-trips-sumnmary.component.html',
  styleUrls: ['./new-trips-sumnmary.component.scss'],
})
export class NewTripsSumnmaryComponent implements OnInit {
  @Input() Parcels;
  @Input() businessReferenceBreakdown;
  @Input() uniqueWaypoints;
  @Input() lottery;
  @Input() vehicle;
  @Input() isAdmin;
  @Input() quote;
  @Input() username;

  public UiThemes = UiThemes;
  public icons = {
    types: IconTypes,
    colors: UiColors,
  };
  totals = {
    packages: 0,
    collected: 0,
    delivered: 0,
    failed: 0,
    returned: 0,
  };
  uniquebusinessReferencesCount = 0;

  vehicleTypes = new Map<string, IconTypes>([
    ['vehicle-motorcycle', IconTypes.Motorbike],
    ['vehicle-car', IconTypes.Car],
    ['vehicle-small-van', IconTypes.SmallVan],
    ['vehicle-large-van', IconTypes.SmallVan],
  ]);
  vehicleNames = new Map<string, string>([
    ['vehicle-motorcycle', 'Motorbike'],
    ['vehicle-car', 'Car'],
    ['vehicle-small-van', 'Small Van'],
    ['vehicle-large-van', 'Large Van'],
  ]);

  environment = environment;

  ngOnInit(): void {
    this.totals = {
      packages: 0,
      collected: 0,
      delivered: 0,
      failed: 0,
      returned: 0,
    };
    this.uniquebusinessReferencesCount = Object.keys(this.businessReferenceBreakdown).length;
    this.Parcels.forEach((parcel) => {
      this.totals.packages++;
      switch (parcel.OrderParcelState) {
        case 'Collected':
        case 'AtSortingFacility':
        case 'OutForDelivery':
          this.totals.collected++;
          break;
        case 'Delivered':
          this.totals.delivered++;
          break;
        case 'OutForReturn':
        case 'Cancelled':
        case 'AllocationFailed':
        case 'ExecutionFailed':
        case 'FailedCollection':
        case 'FailedDelivery':
        case 'FailedReturn':
          this.totals.failed++;
          break;
        case 'Returned':
          this.totals.returned++;
          break;
      }
    });
  }
}
