  <!-- Scheduled Orders -->
  <app-collapsible-order-table
  *ngIf="scheduledFirestoreTrips | async as scheduledTrips"
  [tripType]="'Scheduled Trips'"
  [trips]="scheduledTrips"
  [tripsTotal]="scheduledTotal"
  [objectType]="CollapsibleTableTypeEnum.Order"
  [icon]="Icons.Types.Calendar"
  [color]="Icons.Colors.Blue"
  [tableDataMap]="fsOrderTableDataMap"
  [tableColumns]="fsOrderTableColumns"
  [primaryActions]="postDispatchTablePrimaryActions"
  [defaultDisplayAmount]="scheduledAmount"
  [showPagination]="true"
  (paginateEvent)="handleScheduledTrips($event)"
  (tableSortChanged)="handleTripsSortChange($event)"
  >
  </app-collapsible-order-table>
