import { Injectable, OnDestroy } from '@angular/core';
import axios from 'app/api/axios';
import { NotificationsService } from 'app/shared/notifications/notifications.service';
import { Store } from '@ngrx/store';
import { selectorActiveTripId } from '../store/new-trips.reducer';
import { AxiosPromise } from 'axios';
import { AutoNotesService } from 'app/dashboard/notes/auto-notes.service';
import { AutoNotesTypes } from 'app/dashboard/notes/notes.constants';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DriverLoadService implements OnDestroy {
  DRIVER_LOAD_BASE_URL = '/last-mile/';
  orderId;
  unsubscribe$ = new Subject<void>();
  constructor(
    private notificationsService: NotificationsService,
    private store: Store<any>,
    private autoNotesService: AutoNotesService
  ) {
    this.store.select(selectorActiveTripId).pipe(takeUntil(this.unsubscribe$)).subscribe((next) => (this.orderId = next));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  clearDriverLoad(last_mile_id: string, trip_id: string, waybills: string[], driverName: string): AxiosPromise<void> {
    return axios({
      method: 'POST',
      url: this.DRIVER_LOAD_BASE_URL + last_mile_id + '/unassign',
      data: {
        clear_device: false,
        order_ids: [trip_id],
        route_numbers: null,
        waybills: waybills,
      },
    })
      .then((response) => {
        this.autoNotesService.generateNote(
          {
            autoNotesType: AutoNotesTypes.driverRemoved,
            orderId: trip_id,
            driverName: driverName
          }
        )
        this.notify(true, 'Driver Unassigned');
        return response;
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }


  transferParcelsToDriver(parcel_waybills: string[], driver_id: string, last_mile_id_from: string, orderId): Promise<any> {
    return axios({
      method: 'POST',
      url: 'last-mile/' + last_mile_id_from + '/driver-transfer-parcels',
      data: { trasfer_to_driver_id: driver_id, parcel_waybills, driver_location: {} },
    })
      .then(() => {
        this.autoNotesService.generateNote(
          {
            autoNotesType: AutoNotesTypes.parcelsMoved,
            orderId: orderId
          }
        )
        this.notify(true, 'Parcels Transfered');
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }


  getActiveParcels(driverload_id) {
    return axios({
      method: 'POST',
      url: this.DRIVER_LOAD_BASE_URL + driverload_id + '/get-active-parcels/',
      data: {
        order_id: this.orderId,
      },
    })
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  getFailureReasons() {
    return axios.get(this.DRIVER_LOAD_BASE_URL + 'failure-reasons').then((response) => {
      return response?.data;
    });
  }

  notify(successful, message) {
    const notificationType = successful ? 'success' : 'error';
    this.notificationsService.publish({
      type: notificationType,
      message: message,
    });
  }
}
