import { Pipe, PipeTransform } from '@angular/core';
import { AdminService } from 'app/admin/admin.service';

@Pipe({
  name: 'assignmentMethod'
})
export class AssignmentMethodPipe implements PipeTransform {

  constructor(private adminService: AdminService) {}

  async transform(assignmentMethod: string | null): Promise<string> {
    if(!assignmentMethod) {
      return '';
    }
    // fix for older orders remove at somepoint
    if(assignmentMethod.startsWith('user')){
      const user = await this.adminService.getUser(assignmentMethod);
      return `${user?.name}`
    }
    // end old fix
    const userId = assignmentMethod.split(' ');
    if(userId.length === 3){
      if (userId[2].includes('user')) {
        const user = await this.adminService.getUser(userId[2]);
        return `${userId[0]} - ${user?.name}`;
      }
    }
    return assignmentMethod;
  }

}
