import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-side-icon-with-text',
  templateUrl: './side-icon-with-text.component.html',
  styleUrls: ['./side-icon-with-text.component.scss']
})
export class SideIconWithTextComponent implements OnInit {

  constructor() { }

  @Input() imgUrl:string;
  @Input() title:string;
  @Input() text:string;


  

  ngOnInit(): void {
  }

}
