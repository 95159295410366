import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import axios from 'app/api/axios';
import * as fromAuth from 'app/auth/auth.reducer';
import { selectorBusinesses, selectorUser } from 'app/auth/auth.reducer';
import { HealthStates, VehicleTypes } from 'app/dashboard/trips.enums';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { IconProps, IconTypes } from 'app/shared/icon/icon.interfaces';
import { NotificationsService } from 'app/shared/notifications/notifications.service';
import {
  TableDataMapFormatter,
  TableDataMapIconFormatter,
  TableDataSetHighlightColor,
} from 'app/shared/table/table.interfaces';
import { AxiosResponse } from 'axios';
import firebase from 'firebase/app';
import 'firebase/firestore';
import * as moment from 'moment';
import { SimpleModalService } from 'ngx-simple-modal';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { firebaseAxios } from './../api/axios';
import { AccountBalanceObj, LedgerResponse, mapData } from './admin.interfaces';
import { DriverSearchModel, DriverSearchResult } from './drivers/driver-search/driver-search.interfaces';

export const mfSetHealthHighlightColor: TableDataSetHighlightColor = (map: [string, string, string], item: unknown) => {
  switch (map[0]) {
    case 'finance':
      if (item[map[1]] === item[map[2]]) {
        return 'highlight-' + HealthStates.Good;
      } else {
        return 'highlight-' + HealthStates.Warning;
      }
    case 'drivers':
      if (item[map[1]]) {
        return 'highlight-' + HealthStates.Good;
      } else {
        return 'highlight-' + HealthStates.Warning;
      }
  }
};

export const mfFormatEnum: TableDataMapFormatter = (map: [string], item: unknown) => {
  if (item[map[0]] === null) {
    return '-';
  }
  switch (map[0]) {
    case 'is_online':
      return item[map[0]] === true ? 'Online' : 'Offline';
    case 'is_active':
      return item[map[0]] === true ? 'Activated' : 'Deactivated';
    case 'role':
      return item[map[0]] === 0 ? 'Picup' : item[map[0]] === 1 ? 'Contract' : 'Both';
    case 'acting_as':
      return item[map[0]] === 0 ? 'Picup' : 'Contract';
    case 'status':
      return item[map[0]] === 0 ? 'Pending' : item[map[0]] === 1 ? 'Approved' : 'Declined';
  }
};

export const mfiVehicleType: TableDataMapIconFormatter = (
  map: [string, string],
  item: unknown,
  icon: IconProps
): IconProps => {
  if (map[0] === 'vehicle') {
    switch (item[map[0]]) {
      case VehicleTypes.Car:
        icon.icon = IconTypes.Car;
        icon.color = UiColors.Grey5;
        break;
      case VehicleTypes.MotorCycle:
        icon.icon = IconTypes.Motorbike;
        icon.color = UiColors.Grey5;
        break;
      case VehicleTypes.VanLarge:
        icon.icon = IconTypes.Truck;
        icon.color = UiColors.Grey5;
        break;
      case VehicleTypes.VanSmall:
        icon.icon = IconTypes.SmallVan;
        icon.color = UiColors.Grey5;
        break;
      case VehicleTypes.Freight:
        icon.icon = IconTypes.VehicleFreight;
        icon.color = UiColors.Grey5;
        break;
      default:
        icon.icon = IconTypes.Cross;
        icon.color = UiColors.Grey5;
        break;
    }
  } else if (item[map[0]] && item[map[0]].length > 0) {
    switch (item[map[0]][0][map[1]]) {
      case VehicleTypes.Car:
        icon.icon = IconTypes.Car;
        icon.color = UiColors.Grey5;
        break;
      case VehicleTypes.MotorCycle:
        icon.icon = IconTypes.Motorbike;
        icon.color = UiColors.Grey5;
        break;
      case VehicleTypes.VanLarge:
        icon.icon = IconTypes.Truck;
        icon.color = UiColors.Grey5;
        break;
      case VehicleTypes.VanSmall:
        icon.icon = IconTypes.SmallVan;
        icon.color = UiColors.Grey5;
        break;
      case VehicleTypes.Freight:
        icon.icon = IconTypes.VehicleFreight;
        icon.color = UiColors.Grey5;
        break;
      default:
        icon.icon = IconTypes.Cross;
        icon.color = UiColors.Grey5;
        break;
    }
    return icon;
  } else {
    icon.icon = IconTypes.Cross;
    icon.color = UiColors.Grey5;
  }

  return icon;
};

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  user;
  driverNotesIds;
  businesses;
  driverNotes = new BehaviorSubject(undefined);
  picupsFinancials$ = new BehaviorSubject(undefined);
  adminFreights$ = new BehaviorSubject(undefined);
  today_buckets = new BehaviorSubject(undefined);
  tomorrow_buckets = new BehaviorSubject(undefined);
  driverNotesSubscription;

  todayBucketSubscription: Subscription;
  tomorrowBucketSubscription: Subscription;

  adminBuckets$: Observable<any>;
  adminBusinessFilter$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  adminDateFilter$: BehaviorSubject<string | null> = new BehaviorSubject(moment().format('YYYY-MM-DD'));

  mfGetEFTDetails: TableDataMapFormatter = (map: [string], item: unknown) => {
    if (item[map[0]].aggregate_type === 3) {
      const business = this.businesses.find((b) => b.business_id === item[map[0]].aggregate_id);
      return business.name;
    } else {
      return 'Individual User';
    }
  };

  constructor(
    private notificationsService: NotificationsService,
    private store: Store<fromAuth.State>,
    private db: AngularFireDatabase,
    private simpleModalService: SimpleModalService,
    public afAuth: AngularFireAuth,
    private firestore: AngularFirestore
  ) {
    this.store.select(selectorUser).subscribe((next) => {
      this.user = next;
    });
    this.store.select(selectorBusinesses).subscribe((next) => (this.businesses = next));
  }

  getAdminBuckets(): void {
    this.adminBuckets$ = combineLatest([this.adminBusinessFilter$, this.adminDateFilter$]).pipe(
      switchMap(([business_id, date]) =>
        this.firestore
          .collection('buckets', (ref) => {
            let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
            query = query.where('IsDeleted', '==', false);
            if (business_id) {
              query = query.where('business_id', '==', business_id);
            }
            if (date) {
              query = query.where('delivery_date', '==', date);
            }
            return query;
          })
          .valueChanges()
      )
    );
  }

  getLedger(
    start_date: string,
    end_date: string,
    ledger_type: string,
    ledger_for_identity: string
  ): Promise<LedgerResponse> {
    return axios({
      method: 'POST',
      url: 'ledger/search',
      data: {
        ledger_type,
        start_date,
        end_date,
        ledger_for_identity,
      },
    }).then((response) => {
      return response?.data;
    });
  }

  getDriverStatement(start_date: string, end_date: string, driver_id: string): Promise<LedgerResponse> {
    return axios({
      method: 'POST',
      url: 'driver/' + driver_id + '/get-statement',
      data: {
        start_date,
        end_date,
      },
    }).then((response) => {
      return response?.data;
    });
  }

  async getUser(user_id: string): Promise<any> {
    const response = await axios({
      method: 'POST',
      url: 'generic/cqrs/get-user-details',
      data: {
        user_id,
      },
    });
    return response?.data;
  }

  getFinancialsData(): Promise<[]> {
    if (this.user) {
    }
    return axios({
      method: 'POST',
      url: 'financials/get-trips-in-financials',
      data: {
        business_id: null,
        user_id: null,
        driver_id: null,
        states: ['Financials', 'Cancelled'],
        start_date: null,
        end_date: null,
      },
    }).then((response) => {
      return response?.data;
    });
  }

  getRouteOrderBreakdown(routeId) {
    return axios({
      method: 'POST',
      url: 'generic/cqrs/get-route-order-breakdown',
      data: {
        route_id: routeId,
      },
    }).then((response) => {
      return response?.data;
    });
  }

  getAdminFreights(): void {
    const freights = {
      bidding: [],
      inProgress: [],
    };
    if (this.user) {
      axios({
        method: 'POST',
        url: '/freight/query/freights/all',
        data: {
          admin_aggregate_id: this.user.user_id,
        },
      })
        .then((response) => {
          response?.data.forEach((freightResult) => {
            if (freightResult.status === 'bidding') {
              freights.bidding.push(freightResult);
            } else if (
              freightResult.status === 'finalizing' ||
              freightResult.status === 'finalized' ||
              freightResult.status === 'trips_pending'
            ) {
              freights.inProgress.push(freightResult);
            }
          });
          this.adminFreights$.next(freights);
          this.notificationsService.publish({
            type: 'success',
            message: 'Successfully got freights',
          });
        })
        .catch((error) =>
          this.notificationsService.publish({
            type: 'error',
            message: error.response?.data.message,
          })
        );
    }
  }

  approveFinancials(ids: string[]): Promise<any | AxiosResponse<any>> {
    return axios({
      method: 'POST',
      url: 'financials/approve-picup-transactions',
      data: {
        picup_ids: ids,
      },
    })
      .then((response) => {
        this.notificationsService.publish({
          type: 'success',
          message: 'Successfully Approved Picup',
        });
        return response;
      })
      .catch((error) =>
        this.notificationsService.publish({
          type: 'error',
          message: error.response?.data.message,
        })
      );
  }

  adminSearchDrivers(driverSearch: DriverSearchModel): Promise<DriverSearchResult> {
    return axios({
      method: 'POST',
      url: 'generic/cqrs/search-drivers',
      data: {
        search_term: driverSearch.searchTerm,
        page_number: driverSearch.pageNumber,
        page_size: driverSearch.pageSize,
        search_type: driverSearch.searchType
      },
    })
      .then((response) => {
        return response?.data;
      })
      .catch((error) =>
        this.notificationsService.publish({
          type: 'error',
          message: error.response?.data.message,
        })
      );
  }
  adminSearchUsers(searchString: string): Promise<[]> {
    return axios({
      method: 'POST',
      url: 'generic/cqrs/search-users',
      data: { search_term: searchString },
    })
      .then((response) => {
        return response?.data;
      })
      .catch((error) =>
        this.notificationsService.publish({
          type: 'error',
          message: error.response?.data.message,
        })
      );
  }

  getDriversNearLocation(data: mapData): Promise<[]> {
    return firebaseAxios({
      method: 'POST',
      url: '/getAllDriversNearPoint',
      data: data,
    }).then((res) => {
      return res.data;
    });
  }

  deleteArea(area_id: string): Promise<void> {
    return axios({
      method: 'POST',
      url: 'spatial/delete-spatial-geography/' + area_id,
      data: null,
    })
      .then(() => {
        this.notificationsService.publish({
          type: 'success',
          message: 'Area Deleted',
        });
      })
      .catch((error) =>
        this.notificationsService.publish({
          type: 'error',
          message: error.response?.data.message,
        })
      );
  }

  sendPasswordResetEmail(user_email: string): Promise<void> {
    return this.afAuth
      .sendPasswordResetEmail(user_email)
      .then((response) => {
        this.notifyAndUpdate(true, 'Password Reset Email Sent');
        return response;
      })
      .catch((error) => {
        this.notifyAndUpdate(false, error.response?.data.message);
        return;
      });
  }

  deleteUser(user_id: string): Promise<any | AxiosResponse<any>> {
    return axios
      .delete('user/' + user_id)
      .then((response) => {
        this.notifyAndUpdate(true, 'User Deleted');
        return response;
      })
      .catch((error) => {
        this.notifyAndUpdate(false, error.response?.data.message);
        return;
      });
  }

  getAccountBalances(): Promise<AccountBalanceObj[]> {
    return axios({
      method: 'GET',
      url: 'driver/account-balances',
    })
      .then((response) => {
        return response?.data;
      })
      .catch((error) =>
        this.notificationsService.publish({
          type: 'error',
          message: error.response?.data.message,
        })
      );
  }

  assignPicupToUser(picupId: string, userId: string): Promise<void> {
    return axios({
      method: 'POST',
      url: 'trip-management/claim-enterprise-picup',
      data: { picup_id: picupId, user_id: userId },
    })
      .then(() => {
        this.notificationsService.publish({
          type: 'success',
          message: 'Picup Assigned',
        });
      })
      .catch((error) =>
        this.notificationsService.publish({
          type: 'error',
          message: error.response?.data.message,
        })
      );
  }

  createPaymentRun(
    payments: { aggregate_id: string; amount: number }[],
    reference: string
  ): Promise<any | AxiosResponse<any>> {
    return axios({
      method: 'POST',
      url: 'payment-run/create-payment-run',
      data: { payments: payments, reference },
    })
      .then((response) => {
        this.notificationsService.publish({
          type: 'success',
          message: 'Payment Run Created',
        });
        return response;
      })
      .catch((error) =>
        this.notificationsService.publish({
          type: 'error',
          message: error.response?.data.message,
        })
      );
  }

  adjustDriverPayment(
    driver_ledger_id: string,
    payment: { amount: number; reason: string }
  ): Promise<any | AxiosResponse<any>> {
    return axios({
      method: 'POST',
      url: 'driver-ledger/' + driver_ledger_id + '/adjust-balance',
      data: payment,
    })
      .then((response) => {
        this.notificationsService.publish({
          type: 'success',
          message: 'Driver Balance Adjusted',
        });
        return response;
      })
      .catch((error) =>
        this.notificationsService.publish({
          type: 'error',
          message: error.response?.data.message,
        })
      );
  }

  approveDriverLedger(approval_array): Promise<any> {
    return axios({
      method: 'POST',
      url: 'driver-ledger/approve-driver-earnings',
      data: approval_array,
    })
      .then((response) => {
        this.notificationsService.publish({
          type: 'success',
          message: 'Driver Ledgers Approved',
        });
        return response;
      })
      .catch((error) =>
        this.notificationsService.publish({
          type: 'error',
          message: error.response?.data.message,
        })
      );
  }

  getActiveLastMiles(fleet_allocation) {
    return axios({
      method: 'POST',
      url: 'generic/cqrs/get-active-last-mile-v2-debug',
      data: {
        fleet_allocation,
      },
    })
      .then((response) => {
        return response?.data;
      })
      .catch((error) =>
        this.notificationsService.publish({
          type: 'error',
          message: error.response?.data.message,
        })
      );
  }

  notifyAndUpdate(successful: boolean, message: string): void {
    const notificationType = successful ? 'success' : 'error';
    this.notificationsService.publish({
      type: notificationType,
      message: message,
    });
  }

  createAdmin(user_id: string): Promise<any> {
    return axios({
      method: 'POST',
      url: `user/${user_id}/create-admin`,
      data: {
        user_id,
      },
    }).then((response) => {
      return response?.data;
    });
  }

  setUserActingAsRoles(user_id: string, roles: string[]): Promise<any> {
    return axios({
      method: 'POST',
      url: `user/${user_id}/set-roles`,
      data: roles,
    }).then((response) => {
      return response?.data;
    });
  }
}
