import { Injectable } from '@angular/core';
import axios from 'app/api/axios';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'app/auth/auth.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { Store } from '@ngrx/store';
import { selectorActingAs } from 'app/auth/auth.reducer';
import { NotificationsService } from '../notifications/notifications.service';
import { PlaceOrderService } from 'app/dashboard/place-order/place-order.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  actingAs;
  firebaseCheckoutSubscription;
  checkoutDetails = new BehaviorSubject(undefined);
  checkoutId = new BehaviorSubject(undefined);
  checkoutDomainId: string;
  wallet = new BehaviorSubject(undefined);
  paymentError = new BehaviorSubject(undefined);
  constructor(
    public authService: AuthService,
    public notificationsService: NotificationsService,
    public placeOrderService: PlaceOrderService,
    private db: AngularFireDatabase,
    private router: Router,
    public store: Store<any>
  ) {
    this.store.select(selectorActingAs).subscribe((next) => {
      this.actingAs = next;
      this.getBusinessCredits();
    });
  }

  // CheckoutIntention
  // 0 None,
  // 1 CreatePicup,
  // 2 CreateCourierOrder,
  // 3 AddCredits

  // PaymentType
  //  0 None
  //  1 Credits
  //  2 Peach
  //  3 Eft
  //  4 Account

  PeachPreferences = {
    style: 'plain',
    brandDetection: 'true',
    locale: 'za',
    paymentTarget: 'cnpIframe',
    shopperResultTarget: 'cnpIframe',
  };


  payWithPaystackSavedCard(authCode, amount){
    alert('Not Implemented' + authCode + amount);
    return true
  }

  createOneToMany(request) {
    return this.placeOrderService.createOrder(request).then((response) => {
      this.router.navigate(['/dashboard/orders/trip/', response.order_id]);
      return true;
    });
  }

  createOneToManyFromQuote(request) {
    return axios({
      method: 'POST',
      url: '/basic/create/one-to-many-from-quote',
      headers: {
        'api-key': this.actingAs.id,
      },
      data: request,
    })
      .then((response: any) => {
        if (response.data['order_id']) {
          this.router.navigate(['/dashboard/orders/trip/', response.data['order_id']]);
        }
        this.getBusinessCredits();
        return response.data.checkout_response.id;
      })
      .catch((error) => {
        this.paymentError.next(error.response?.data.Message);
        throw error.response?.data.Message;
      });
  }

  getBusinessCredits() {
    return axios({
      method: 'GET',
      url: '/business/' + this.actingAs.id + '/credits',
    }).then((response: any) => {
      this.wallet.next({ credits: response.data.credits });
    });
  }

  setResourcePath(resourcePath: string): void {
    const data = {
      resource_path: resourcePath,
    };
    axios({
      method: 'POST',
      url: '/peach/' + this.checkoutDomainId + '/set-peach-resource-path',
      data: data,
    });
  }

  startFromScratch(): void {
    this.paymentError.next(undefined);
    if (this.firebaseCheckoutSubscription) {
      this.firebaseCheckoutSubscription.unsubscribe();
    }
  }
}
