// Map Formatters
import { TableDataMapFormatter, TableDataSetHighlightColor } from '../../shared/table/table.interfaces';
import { HealthStates, LotteryStateEnums, CourierStatuses } from '../trips.enums';

export const mfGetLastItemTime: TableDataMapFormatter = (map: string[], item: any) => {
  return map[1]
    ? item[map[0]].admin.time_stamp
      ? item[map[0]].admin.time_stamp
      : item[map[0]].admin.time
    : item[map[0]].client.time_stamp
      ? item[map[0]].client.time_stamp
      : item[map[0]].client.time;
};

export const mfGetLastItemDetails: TableDataMapFormatter = (map: string[], item: any) => {
  return map[1] ? item[map[0]].admin.describe : item[map[0]].client.describe;
};

export const getNestedValues: TableDataMapFormatter = (map: string[], item: any) => {
  const obj = item[map[0]];
  const field = map[1];
  return obj[field];
};

export const mfSetAdminHealthHighlightColor: TableDataSetHighlightColor = (
  map: [string, string, string],
  item: unknown
) => {
  switch (map[0]) {
    case 'finance':
      if (item[map[1]] === item[map[2]]) {
        return 'highlight-' + HealthStates.Good;
      } else {
        return 'highlight-' + HealthStates.Warning;
      }
    case 'drivers':
      if (item[map[1]]) {
        return 'highlight-' + HealthStates.Good;
      } else {
        return 'highlight-' + HealthStates.Warning;
      }
  }
};

export const mfSetHealthHighlightColor: TableDataSetHighlightColor = (map: string[], item: any) => {
  switch (item[map[0]]) {
    case HealthStates.Bad:
      return 'highlight-' + HealthStates.Bad;
    case HealthStates.Good:
      return 'highlight-' + HealthStates.Good;
    case HealthStates.Warning:
      return 'highlight-' + HealthStates.Warning;
  }
};

export const mfSetCourierHealthHighlightColor: TableDataSetHighlightColor = (map: string[], item: any) => {
  switch (item[map[0]]) {
    case CourierStatuses.WaybillAcceptedFailed:
      return 'highlight-' + HealthStates.Bad;
    case CourierStatuses.WaybillCreationFailed:
      return 'highlight-' + HealthStates.Bad;
    case CourierStatuses.DeliveryFailed:
      return 'highlight-' + HealthStates.Bad;
    case CourierStatuses.OrderCancelled:
      return 'highlight-' + HealthStates.Warning;
    case CourierStatuses.WaybillCreated:
      return 'highlight-' + HealthStates.Primary;
    default:
      return 'highlight-' + HealthStates.Good;
  }
};

export const mfSetPreferredDriverHighlightColor: TableDataSetHighlightColor = (map: string[], item: any) => {
  if (item[map[0]] > 0) {
    return 'highlight-' + HealthStates.Good;
  } else {
    return 'highlight-' + HealthStates.Default;
  }
};

export const mfSetStateHighlightColor: TableDataSetHighlightColor = (map: string[], item: any) => {
  switch (item[map[0]]) {
    case LotteryStateEnums.WaitingForDriverResponse:
      return 'highlight-' + HealthStates.Default;
    case LotteryStateEnums.DriverAccepted:
      return 'highlight-' + HealthStates.Good;
    case LotteryStateEnums.DriverRejected:
      return 'highlight-' + HealthStates.Bad;
    case LotteryStateEnums.DriverAssigned:
      return 'highlight-' + HealthStates.Primary;
    case LotteryStateEnums.DriverConfirmed:
      return 'highlight-' + HealthStates.Primary;
    case true:
      return 'highlight-' + HealthStates.Good;
    case false:
      return 'highlight-' + HealthStates.Bad;
  }
};

export const mfSetVehicleStatusHighlightColor: TableDataSetHighlightColor = (map: string[], item: any) => {
  switch (item[map[0]]) {
    case 0:
      return 'highlight-' + HealthStates.Default;
    case 1:
      return 'highlight-' + HealthStates.Good;
    case 2:
      return 'highlight-' + HealthStates.Primary;
    case 3:
      return 'highlight-' + HealthStates.Bad;
  }
};
