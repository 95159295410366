import { Injectable } from '@angular/core';
import axios from 'app/api/axios';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { NotificationsService } from '../../../../shared/notifications/notifications.service';
import { selectorActiveBucketId } from '../../manage-bucket.reducer';

@Injectable({
  providedIn: 'root',
})
export class OrderSelectionService {
  orders$ = new BehaviorSubject(undefined);
  selectedOrders$ = new BehaviorSubject(undefined);
  finalisingInProgress$ = new BehaviorSubject(undefined);

  savingInProgress$ = new BehaviorSubject(undefined);
  savedSuccessfully$ = new BehaviorSubject(undefined);

  bucketId;

  private API_BUCKET_BASE = '/bucket/';
  private COURIER_ORDERS = '/courier-orders';
  private SELECT_COURIER_ORDERS = '/select-courier-orders';
  private SELECTED_COURIER_ORDERS = '/selected-courier-orders';
  private DISPATCHING = '/dispatching';

  private getUrlFinaliseOrders = (bucketId: number): string => this.API_BUCKET_BASE + bucketId + this.DISPATCHING;

  constructor(private store: Store<any>, private notificationsService: NotificationsService) {
    this.store.select(selectorActiveBucketId).subscribe((next) => {
      this.bucketId = next;
    });
  }

  public getCourierOrders(): void {
    axios.get(this.API_BUCKET_BASE + this.bucketId + this.COURIER_ORDERS).then((response) => {
      this.orders$.next(response?.data);
    });
  }

  public setCourierOrders(orders) {
    this.orders$.next(orders);
  }

  public getCourierOrdersV2() {
    return axios.get(this.API_BUCKET_BASE + this.bucketId + this.COURIER_ORDERS);
  }

  public getSelectedCourierOrders(): void {
    axios
      .get(this.API_BUCKET_BASE + this.bucketId + this.SELECTED_COURIER_ORDERS)
      .then((response) => this.selectedOrders$.next(response?.data));
  }

  public selectCourierOrders(orders): Promise<any> {
    this.savingInProgress$.next(true);

    return axios
      .post(this.API_BUCKET_BASE + this.bucketId + this.SELECT_COURIER_ORDERS, orders)
      .then((response) => {
        this.notificationsService.publish({
          type: 'success',
          message: 'Orders saved successfully.',
        });
        this.savedSuccessfully$.next(true);
        this.savingInProgress$.next(false);
        return response;
      })
      .catch((error) => {
        this.notificationsService.publish({
          type: 'error',
          message: 'Error: ' + error.response?.data.message,
        });

        this.savingInProgress$.next(false);
      });
  }

  public finalise(): void {
    this.finalisingInProgress$.next(true);
    axios
      .post(this.getUrlFinaliseOrders(this.bucketId))
      .then(() => {
        this.finalisingInProgress$.next(false);
        this.notificationsService.publish({ type: 'success', message: 'Finalised Courier Order Selection' });
      })
      .catch((error) => {
        this.finalisingInProgress$.next(false);
        this.notificationsService.publish({ type: 'error', message: 'Error: ' + error.response?.data.message });
      });
  }

  public clearCourierOrders(): void {
    this.orders$.next(undefined);
  }
}
