<app-section class="App Details Summary">
  <heading [compact]="true">
    <app-flex-container class="header-box" [justifyContent]="justifyContent.SpaceBetween" [theme]="UiThemes.Dark">
      {{ 'LOTTERY RUN: ' + (index + 1) + ' - ' + startedAt }}
      <div>
        <app-basic-button
          [active]="false"
          [title]="'Cancel Lottery'"
          [type]="buttonTypes.Warning"
          [processing]="processing"
          [disabled]="lottery.status > 30"
          (click)="cancelLottery()"
        ></app-basic-button>
        <app-basic-button
          [active]="false"
          [title]="'Toggle Map'"
          [type]="buttonTypes.Primary"
          (click)="toggleMap()"
        ></app-basic-button>
      </div>
    </app-flex-container>
  </heading>
  <app-flex-container [borderBottom]="true" [theme]="UiThemes.Dark">
    <!-- TODO: Parcel statuses -->
    <app-stats-icon
      [icon]="icons.types.Truck"
      [iconColor]="icons.colors.Blue"
      [iconSizeRem]="2"
      [label]="'Fleet'"
      [data]="lotteryTypes.get(lottery.fleet)"
    ></app-stats-icon>
    <app-stats-icon
      [icon]="vehicleTypes.get(lottery.vehicle)"
      [iconColor]="icons.colors.Blue"
      [iconSizeRem]="2"
      [label]="'Vehicle'"
      [data]="vehicleNames.get(lottery.vehicle)"
    ></app-stats-icon>
    <app-stats-icon
      [icon]="icons.types.Marker"
      [iconColor]="icons.colors.Blue"
      [iconSizeRem]="2"
      [label]="'Waypoints'"
      [data]="lottery?.waypoints?.length ? lottery?.waypoints?.length : '-'"
    ></app-stats-icon>
    <app-stats-icon
      [icon]="icons.types.Trip"
      [iconColor]="icons.colors.Blue"
      [iconSizeRem]="2"
      [label]="'Distance'"
      [data]="(lottery.total_trip_distance_meters | number: '1.2-2') + 'km'"
    ></app-stats-icon>
    <app-stats-icon
      [icon]="icons.types.History"
      [iconColor]="icons.colors.Blue"
      [iconSizeRem]="2"
      [label]="'Status'"
      [data]="lottery.status === 20 ? timeLeft : showStatus(lottery.status)"
    ></app-stats-icon>
    <app-stats-icon
      [icon]="icons.types.DebitCard"
      [iconColor]="icons.colors.Yellow"
      [iconSizeRem]="2"
      [label]="'Earnings'"
      [data]="lottery.fleet === 2 || isAdmin ? (lottery.earnings | currency: environment.currency_symbol) : '-'"
    ></app-stats-icon>
    <app-stats-icon
      [icon]="icons.types.Driver"
      [iconColor]="icons.colors.Blue"
      [iconSizeRem]="2"
      [label]="'Assigned Driver'"
      [data]="lottery.assigned_driver ? lottery.assigned_driver : ' - '"
    ></app-stats-icon>
  </app-flex-container>
  <app-table
    *ngIf="!showMap && lotteryDataMap"
    [zeroDataMessage]="'No Eligible Drivers Found'"
    [columns]="lotteryColumns"
    [dataMap]="lotteryDataMap"
    [rawData]="lottery.drivers | sortBy: 'distance':'ascending'"
    [rowsSelectable]="false"
  >
  </app-table>
  <div class="map-wrap" *ngIf="showMap">
    <app-map-view
      [waypoints]="[warehouse_waypoint]"
      [mapType]="mapTypes.Tracking"
      [driverIds]="lottery.status < 30 ? drivers : null"
    ></app-map-view>
  </div>
</app-section>
