<div class="how-to-block">
  <div class="image">
    <img [src]="imageSrc" alt="" />
  </div>
  <div class="content">
    <h5 class="number">{{ number }}</h5>
    <h3>{{ title }}</h3>
    <small>{{ content }}</small>
    <svg-icon
      class="right-underline"
      [applyClass]="true"
      [src]="'/assets/img/icons/' + IconTypes.Underline + '.svg'"
    ></svg-icon>
  </div>
</div>
