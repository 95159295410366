<app-modal
  [open]="true"
  [actions]="actions"
  [highlightPrimaryAction]="true"
  [size]="ModalSizes.Large"
  (handleAction)="handleAction($event)"
>
  <heading [icon]="IconTypes.Truck" [color]="UiColors.Blue">
    <h4 class="heading-title">Select Preferred Contractors</h4>
    <p class="heading-description">Contractors selected here will be used in a preferred lottery</p>
  </heading>

  <div class="modal-body">
    <app-loader *ngIf="loading; else displayContractors" [loadingMessage]="'Loading Contractors'"></app-loader>
    <ng-template #displayContractors>
        <div *ngIf="contractors; else noContractors">
          <div class="driver-filters">
            <app-basic-button-group
            [buttons]="filterButtons"
            [currentTab]="filter"
            [buttonRemPadding]="1"
            (buttonClicked)="handleFilters($event)"
          ></app-basic-button-group>
          </div>
          <table class="driver-select-table">
            <thead>
            <tr>
              <th>Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Vehicle Type</th>
              <th>Toggle</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let contractor of filteredContractors">
              <td>{{contractor.driver_name}}</td>
              <td>{{contractor.driver_phone}}</td>
              <td>{{contractor.driver_email}}</td>
              <td><svg-icon
                [svgStyle]="{ 'width.rem': '4' }"
                [applyClass]="true"
                [src]="'/assets/img/vehicles/' + contractor.vehicle_id + '.svg'"
                class="vehicle-icon"
              ></svg-icon></td>
              <td>
                <app-icon-button 
                [icon]="IconTypes.Check" 
                (click)="toggleContractor(contractor)"
                [color]="drivers.includes(contractor.driver_id) ? UiColors.Green : UiColors.Grey2">
                </app-icon-button>
              </td>
            </tr>
          </tbody>
          </table>
        </div>
        <ng-template #noContractors>
          <app-loader [displayLoader]="false" [loadingMessage]="'No contractors available for this bucket'"></app-loader>
        </ng-template>
    </ng-template>
  </div>
</app-modal>
