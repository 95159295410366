import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FSOrderTableColumns, FSOrderTableDataMap } from 'app/dashboard/post-dispatch/post-dispatch.constants';
import { OrderFrontEndStateEnum } from 'app/dashboard/post-dispatch/post-dispatch.interfaces';
import { PostDispatchService } from 'app/dashboard/post-dispatch/post-dispatch.service';
import { ActingAs } from 'app/interfaces/auth.interfaces';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { CollapsibleTableTypeEnum, Order, Paginate, PaginateEnum } from 'app/shared/shared.interfaces';
import { SortEvent, TableAction } from 'app/shared/table/table.interfaces';
import { from, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-tracking-inprogress-trips',
  templateUrl: './tracking-inprogress-trips.component.html',
  styleUrls: ['./tracking-inprogress-trips.component.scss']
})
export class TrackingInprogressTripsComponent implements OnInit, OnDestroy {

  @Input() postDispatchTablePrimaryActions: TableAction[] = [];

  inProgressFirestoreTrips: Observable<Order[]>;
  inProgressAmount: number = 10;
  inProgressSubscription: Subscription;
  inProgressTotal: number;

  CollapsibleTableTypeEnum = CollapsibleTableTypeEnum;

  fsOrderTableColumns = FSOrderTableColumns;
  fsOrderTableDataMap = FSOrderTableDataMap;

  Icons = {
    Colors: UiColors,
    Types: IconTypes,
  };

  actingAs: ActingAs;

  constructor(private postDispatchService: PostDispatchService) { }

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy(): void {
    this.inProgressSubscription.unsubscribe();
  }

  getData(): void {
    this.handleInProgressTrips({ Amount: this.inProgressAmount, Direction: PaginateEnum.None });
    this.handlePostDispatchTotals();
  }

  handlePostDispatchTotals(): void {
    this.postDispatchService.getPostDispatchTotals(false).then((data) => {
      this.inProgressTotal = data.output.find((x) => x.State === OrderFrontEndStateEnum.InProgress)?.Cnt;
    });
  }

  handleInProgressTrips($event: Paginate): void {
    this.inProgressAmount = $event.Amount;
    this.inProgressSubscription?.unsubscribe();
    this.inProgressSubscription = this.postDispatchService
      .getFireStoreOrderSummaryStreamByState(this.inProgressAmount, $event.Direction, OrderFrontEndStateEnum.InProgress, false)
      .subscribe((response) => {
        if (response[0]) {
          this.postDispatchService.setTripSnapshot(
            {
              First: response[0].payload.doc,
              Last: response[response.length - 1].payload.doc,
            },
            OrderFrontEndStateEnum.InProgress
          );

          const data: Order[] = [];
          for (const item of response) {
            data.push(item.payload.doc.data());
          }
          this.inProgressFirestoreTrips = from([data]);
        } else {
          this.inProgressFirestoreTrips = from([]);
        }
      });
  }

  handleTripsSortChange($event: SortEvent): void {
    this.postDispatchService.orderOrderBy = $event;
    this.handleInProgressTrips({ Amount: this.inProgressAmount, Direction: PaginateEnum.None });
  }
}
