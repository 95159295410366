import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardBucketTable } from 'app/dashboard/home/home.interface';
@Component({
  selector: 'app-bucket-table',
  templateUrl: './bucket-table.component.html',
  styleUrls: ['./bucket-table.component.scss']
})
export class BucketTableComponent implements OnInit {

  @Input() bucketData: DashboardBucketTable;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  navigateToBucket(bucketId: string): void {
    this.router.navigate(['dashboard/buckets/', { outlets: { Buckets: [bucketId] } }]);
  }

}
