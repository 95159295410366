import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ModalSizes, ModalAction } from 'app/shared/modals/modal.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import * as moment from 'moment';
import { Store } from '@ngrx/store';
import { selectorUserPermissions } from 'app/auth/auth.reducer';
import { Warehouse } from 'app/interfaces/auth.interfaces';
import { BucketDetails } from '../bucket.interfaces';
import { AvailableBucketsResultEnum } from 'app/shared/shared.interfaces';

@Component({
  selector: 'app-create-bucket-modal',
  templateUrl: './create-bucket-modal.component.html',
  styleUrls: ['./create-bucket-modal.component.scss'],
})
export class CreateBucketModalComponent extends SimpleModalComponent<any, any> implements OnInit {
  public ModalSizes = ModalSizes;
  public IconTypes = IconTypes;
  public uiColors = UiColors;
  public UiThemes = UiThemes;
  public ButtonTypes = ButtonTypes;
  public actions: ModalAction[] = [
    { name: 'close', title: 'Close', isPrimary: false },
    { name: 'createBucket', title: 'Create Bucket', isPrimary: true },
  ];

  delivery_date = moment(new Date()).format('YYYY-MM-DD');
  warehouses: Warehouse[];
  allowed_warehouses = [];
  warehouse_choice = true;

  data: {
    warehouses: Warehouse[];
    business_id: string;
    availableBucketResult: AvailableBucketsResultEnum;
  }

  selected_warehouse: Warehouse;
  shift_start;
  shift_end;
  active_bucket;
  user_permissions;
  warehouseId: string;
  constructor(private store: Store<any>) {
    super();
    this.store.select(selectorUserPermissions).subscribe((next) => (this.user_permissions = next));
  }

  dateChanged($event) {
    this.delivery_date = moment($event).format('YYYY-MM-DD');
  }
  changeShift($event) {
    this.shift_start = moment.utc($event[0] * 3600000).format('HH:mm');
    this.shift_end = moment.utc($event[1] * 3600000).format('HH:mm');
  }

  ngOnInit(): void {
    if (this.warehouse_choice) {
      if (this.user_permissions?.warehouses?.length && !this.user_permissions?.modules?.includes('super-admin')) {
        this.warehouses = this.warehouses.filter((warehouse) => {
          return this.user_permissions.warehouses.includes(warehouse.id);
        });
      }
      this.warehouses.forEach((warehouse) => {
        if (warehouse.id === this.active_bucket?.warehouse_id) {
          this.selected_warehouse = warehouse;
        }
      });
      if (this.warehouses.length === 1) {
        this.selected_warehouse = this.warehouses[0];
      }
    }
  }

  handleAction($event) {
    switch ($event) {
      case 'close':
        this.result = undefined;
        this.close();
        break;
      case 'createBucket': {
        const bucket_details: BucketDetails = {
          delivery_date: this.delivery_date,
          shift_start: this.shift_start,
          shift_end: this.shift_end,
          warehouse_id: this.warehouse_choice ? this.selected_warehouse.id : this.warehouseId,
        };
        this.result = bucket_details;
        this.close();
      }
    }
  }
}
