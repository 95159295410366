import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UiThemes } from 'app/interfaces/ui.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import moment from 'moment';
import { environment } from 'environments/environment';
import { PayStackPaymentResponse, PaystackSavedCard } from '../payment.interfaces';
import { PaystackService } from './paystack.service';
import { ConfirmModalComponent } from 'app/shared/modals/confirm-modal.component';
import { SimpleModalService } from 'ngx-simple-modal';
declare let PaystackPop: any;

@Component({
  selector: 'app-paystack',
  templateUrl: './paystack.component.html',
  styleUrls: ['./paystack.component.scss'],
})
export class PaystackComponent {
  IconTypes = IconTypes;
  ButtonTypes = ButtonTypes;
  UiThemes = UiThemes;

  savedCards: PaystackSavedCard[] = [];
  authCode: string;
  isSaveCard: boolean = true;
  loadingPayment: boolean = false;

  @Input() amount: number;
  @Input() reference: string;
  @Input() email: string;
  @Input() userId: string;
  @Input() businessId: string;
  @Input() paymentType: string;
  @Input() currency: string = 'ZAR';
  @Output() transactionOutcome = new EventEmitter<PayStackPaymentResponse>();
  @Output() windowClosed = new EventEmitter<void>();
  @Output() resetPaymentRequested = new EventEmitter<void>();

  constructor(private simpleModalService: SimpleModalService, private paystackService: PaystackService) {}

  cardsLoaded($event): void {
    this.savedCards = $event;
  }

  savedCardSelected(authCode): void {
    // Paystack Deals with Cents only
    const amount = this.amount * 100;
    this.loadingPayment = true;
    this.paystackService.payWithPaystackSavedCard(authCode, amount, this.email).then((res) => {
      this.transactionOutcome.emit(res);
      this.loadingPayment = false;
    });
  }

  resetPayment(): void {
    this.resetPaymentRequested.emit();
  }

  payWithPaystack(): void {
    // known eslint error: https://stackoverflow.com/questions/63293903/resolve-typescript-eslint-no-invalid-this-issue-for-class-fields-without-conf
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const scope = this;
    // Mke sure is number and number has 2 decimal places - backend is inconsistent - also, we have to convert rands to cents here
    const priceInCents = +(scope.amount * 100).toFixed(0);
    const handler = PaystackPop.setup({
      key: environment.billingModule.paystackPublicKey, // Replace with your public key
      email: scope.email,
      amount: priceInCents,
      currency: this.currency,
      ref: scope.reference.replace(/[^A-Z0-9]+/gi, '') + '-' + moment().format('YYYY-MM-DDHH-ss'),
      onClose: function () {
        scope.windowClosed.emit();
      },
      callback: function (response) {
        scope.simpleModalService
          .addModal(ConfirmModalComponent, {
            title: 'Save Card',
            message: 'Would you like to save this card for next time?',
          })
          .subscribe((isConfirmed) => {
            if (isConfirmed) {
              scope.paystackService.saveCard(response.transaction, scope.userId, scope.businessId);
            }
            scope.transactionOutcome.emit(response);
          });
      },
    });
    handler.openIframe();
  }
}
