<div *ngIf="parcelStats" class="layout">
    <div class="header-Layout">
        <p class="header-text">Order/Parcel Statistics</p>
    </div>
    <div class="rectangles">
        <p class="small-text spaceing">Total No. orders for trip</p>
        <span class="number-text">{{parcelStats?.totalOrders ?? 0}}</span>    
    </div>
    <div class="rectangles colors div-gap">
        <div class="per-layout">
            <p class="percentage">{{(parcelStats?.percentageCompleteOrders | number: '1.0-0') ?? 0}}% &nbsp;&nbsp;</p>
            <p class="small-text">Completed trips</p>
        </div>
       <span class="number-text">{{(((parcelStats?.totalOrders * parcelStats.percentageCompleteOrders) ?? 0) / 100) | number: '1.0-0' }}</span>
    </div>
    <div class="rectangles">
        <p class="small-text spaceing">Total No. parcels for trips</p>
        <span class="number-text">{{parcelStats?.totalParcels ?? 0}}</span>
    </div>
    <div class="rectangles colors div-gap">
        <div class="per-layout">
            <span class="percentage">{{(parcelStats?.percentageDeliveredParcels | number: '1.0-0') ?? 0}}% &nbsp;&nbsp;</span>
            <p class="small-text">Delivered parcels</p>
        </div>
       <span class="number-text">{{(((parcelStats?.totalParcels * parcelStats?.percentageDeliveredParcels) ?? 0)/ 100) | number: '1.0-0'}}</span>
    </div>
</div>
