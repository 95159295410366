import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { PaymentService } from '../payment.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-peach-payments',
  templateUrl: './peach-payments.component.html',
  styleUrls: ['./peach-payments.component.scss']
})

export class PeachPaymentsComponent implements OnInit, OnDestroy {

  @Input() checkoutId: string;
  public url: any;

  constructor(
    private sanitizer: DomSanitizer,
    private paymentService: PaymentService
  ) {
    (<any>window).scopeToShare = this;
  }


  onLoad() {
    this.loadScripts();
  }

  setResourcePath(resource_path) {
    this.paymentService.setResourcePath(resource_path);
    document.getElementById('cnpIframe').remove();
  }

   loadScripts() {

    const myIframe = document.getElementById('cnpIframe');

    const scriptPeach = (<HTMLIFrameElement>myIframe).contentWindow.document.createElement('script');
    scriptPeach.type = 'text/javascript';
    scriptPeach.src = environment.peachUrl + this.checkoutId;

    const jqueryPreset = (<HTMLIFrameElement>myIframe).contentWindow.document.createElement('script');
    jqueryPreset.type = 'text/javascript';
    jqueryPreset.src = 'https://code.jquery.com/jquery.js';

    const scriptPresets = (<HTMLIFrameElement>myIframe).contentWindow.document.createElement('script');
    scriptPresets.type = 'text/javascript';
    scriptPresets.src = '/assets/peach.js';

    const styles = (<HTMLIFrameElement>myIframe).contentWindow.document.createElement('link');
    styles.type = 'text/css';
    styles.rel = 'stylesheet';
    styles.href = '/assets/css/peach.css';


    (<HTMLIFrameElement>myIframe).contentWindow.document.head.appendChild(jqueryPreset);
    (<HTMLIFrameElement>myIframe).contentWindow.document.head.appendChild(scriptPresets);
    (<HTMLIFrameElement>myIframe).contentWindow.document.head.appendChild(styles);
     (<HTMLIFrameElement>myIframe).contentWindow.document.head.appendChild(scriptPeach);

     const form = '<form action=' + environment.peachRedirectUrl + ' class="paymentWidgets" data-brands="VISA MASTER AMEX"></form>';
     (<HTMLIFrameElement>myIframe).contentWindow.document.body.innerHTML = form;


  }

  ngOnInit() {
  }


  ngOnDestroy() {
  }



}
