<div class="modal">
  <div class="modal-content">
      <heading 
        [icon]="iconTypes.Alert"
        [color]="uiColors.Orange">
        <h4 class="heading-title">File Errors</h4>
      </heading>
    <div class="modal-body">
      <div class="modal-content">
        <p>Please note: The records below have issues. Amend the data set to fix these errors.</p>
        <ul *ngFor="let error of errors">
          <li>{{error}}</li>
        </ul>
      </div>
      <div class="modal-footer">
        <app-basic-button 
          [title]="'Dismiss'" 
          [type]="buttonTypes.Tertiary" 
          (click)="close()">
        </app-basic-button>
      </div>
    </div>
  </div>
</div>