<footer>
  <div class="content">
    <div class="footer-links-grid">
      <div class="logo-link">
        <div class="logo">
          <img src="/assets/img/picup-logo.svg" [routerLink]="'/home'" alt="Picup" />
        </div>
        <div class="karoo">
          <img src="/assets/img/static-site/company/carzuka-logo.png" alt="">
          <p>A subsidiary of Karooooo Limited.</p>
          <a href="https://www.karooooo.com/" target="_blank">karooooo.com</a>
        </div>
      </div>
      <div class="listlink">
        <div>
          <div class="foot-links">
            <ul>
              <h4>SOLUTIONS</h4>
              <li><a routerLink="/solutions/ondemand">On-Demand </a></li>
              <li><a routerLink="/solutions/enterprise">Logistics Management</a></li>
              <li><a routerLink="/solutions/tms">Fleet Management</a></li>
              <li><a routerLink="/solutions/geocoding">Geocoding</a></li>
            </ul>
          </div>

        </div>
        <div>
          <div class="foot-links">
            <ul>
              <h4>INDUSTRIES</h4>
              <li><a routerLink="/industries/ecommerce">eCommerce</a></li>
              <li><a routerLink="/industries/pharma">Pharma</a></li>
              <li><a routerLink="/industries/grocery">Grocery</a></li>
              <li><a routerLink="/industries/retail">Retail</a></li>
              <li><a routerLink="/industries/automotive">Automotive</a></li>
            </ul>
          </div>
        </div>
        <div>
          <div class="foot-links">
            <ul>
              <h4>CONTACT</h4>
              <li><a href="https://www.google.com/maps/search/?api=1&query=Black River Park North, South Park Entrance, 2 Fir St, Observatory, Cape Town, 7925"
                target="_blank" rel="noopener noreferrer">
                  <svg-icon [svgStyle]="{ 'width.rem': '1.3'}" [applyClass]="true"
                    [src]="'/assets/img/icons/' + IconTypes.Marker + '.svg'"></svg-icon>
                    Black River Park North, South Park Entrance, 2 Fir St, Observatory, Cape Town, 7925
                </a></li>
            </ul>
          </div>
        </div>
        <div>
          <div class="foot-links">
            <ul>
              <h4>DEVELOPER</h4>
              <li><a href="https://integrate.picup.co.za/" target="_blank">API Documentation</a></li>
              <!-- <li><a href="https://wordpress.org/plugins/woo-picup-shipping/" target="_blank">Woocommerce shipping
                  plugin</a></li> -->
              <li><a href="https://www.picup.co.za/assets/pages/terms-conditions.html" target="_blank">Terms &
                  conditions</a></li>
              <li><a href="https://www.picup.co.za/assets/pages/privacy-policy.html" target="_blank">Privacy Policy</a>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div class="foot-links">
            <ul>
              <h4>Company</h4>
              <!-- <li><a routerLink="/picup-app">Picup App</a></li> -->
              <!-- <li><a routerLink="/careers">Careers</a></li> -->
              <!-- <li><a routerLink="/carbon-neutral">Carbon Neutral</a></li> -->
              <li class="nondropdown"><a href="https://medium.com/picup" target="_blank">Blog</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="context === 'Africa'" class="africa-container">
      <div class="logo">
        <img src="/assets/img/picup-logo.svg" alt="Picup" />
      </div>
      <div class="ctas" *ngIf="context === 'Africa'">
        <app-slider-button 
          [style.marginRight.px]="30"
          routerLink="/login" 
          [theme]="uiThemes.Home"
          [iconType]="IconTypes.Warehouse" [labelText]="'Log In'">
        </app-slider-button>

        <app-slider-button 
          routerLink="/register" 
          [theme]="uiThemes.Home" 
          [iconType]="IconTypes.Profile"
          [labelText]="'Register'">
        </app-slider-button>
      </div>
    </div>
    <div class="socialicons">
      <div class="socialin">
        <a href="https://www.facebook.com/PicupSA/" target="_blank"><img src="../assets/img/fb.svg" alt="" /></a>
        <a href="https://www.instagram.com/picupsa/" target="_blank"><img src="../assets/img/insta.svg" alt="" /></a>
        <a href="https://www.linkedin.com/company/picup/mycompany/" target="_blank"><img
            src="../assets/img/linkedin.svg" alt="" /></a>
        <a href="https://twitter.com/picupsa" target="_blank"><img src="../assets/img/twitter.svg" alt="" /></a>
      </div>
      <div class="leftsocialicons">
        <ul>
          <li><a routerLink="/drive" id="drive">DRIVE</a></li>
          <li><a href="https://docs.google.com/forms/d/e/1FAIpQLSdBzSrPXiFjoSWEqJhymOwQ1FA7KGLqfdQ_n9iEWZtToK1m2Q/viewform?usp=sf_link" id="signup">BOOK A DEMO</a></li>
          <li><a routerLink="/login" id="login">LOGIN</a></li>
        </ul>
      </div>
    </div>
  </div>
</footer>

<div class="banner">
  <p>All Rights Reserved &copy; Karooooo Logistics (Pty) Ltd (trading as Picup) 2024.</p>
</div>
