import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dashboardLoader'
})
export class DashboardLoaderPipe implements PipeTransform {

  transform(values: boolean[]): boolean {
    return values.every((x) => x);
  }

}
