import { MapMarkerColors } from './../../interfaces/ui.interfaces';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { IconTypes } from '../icon/icon.interfaces';
import { ModalSizes } from '../modals/modal.interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonTypes } from '../buttons/basic-button.component';
import { parcelOverviewColumns } from 'app/dashboard/new-trips/new-trips.constants';
import { DisplayPipes, TableColumn, TableDataMap, TableDataMapFormatter, TableRow } from '../table/table.interfaces';
import { mapDataToTable } from '../table/table.helpers';
import { environment } from 'environments/environment';
import { mfHumanizeString, mfAmountPaid } from '../table/map-formatters.table.helpers';
import moment from 'moment';
import { SharedService } from '../shared.service';
import { JustifyContent } from '../flex-container/flex-container.interfaces';
import { CourierOrderParcelStateEnum, LastMileParcelStateEnum, OrderParcelStateEnum } from '../shared.interfaces';

export const onActiveRouteFormatter: TableDataMapFormatter = (map: string[], item: any) => {
  if (item[map[1]]) {
    return 'On Active Route';
  } else {
    if (typeof item[map[0]] !== 'string') {
      return item[map[0]];
    }
    let value = item[map[0]].split(/(?=[A-Z])/).join(' ');
    value = value[0].toUpperCase() + value.slice(1);
    return value;
  }
};

@Component({
  selector: 'app-fs-business-reference-breakdown',
  templateUrl: './fs-business-reference-breakdown.component.html',
  styleUrls: ['./fs-business-reference-breakdown.component.scss'],
})
export class FsBusinessReferenceBreakdownComponent implements OnChanges {
  @Input() businessReferenceBreakdown;
  @Input() context;
  @Input() orderId;
  @Input() uniqueWaypoints;
  @Input() uniqueLastMileIds;
  @Input() orderPODs;
  @Input() orderWaybill: string = '';

  @Output() dataModified = new EventEmitter();

  justifyContent = JustifyContent;
  downloadingPOD = false;
  showGoToOrder = false;
  showSummary = false;
  courierReference;
  courierSericeTypeDescription;
  courierName;
  totals = {
    packages: 0,
    collected: 0,
    delivered: 0,
    failed: 0,
  };

  environment = environment;

  signatureMap = new Map();

  public parcelOverviewColumns = parcelOverviewColumns;

  lastMileParcelOverviewDataMap: TableDataMap = {
    cells: {
      parcel_state: {
        column: 'parcel_state',
        map: ['LastMileParcelState'],
        format: mfHumanizeString,
      },
      parcel_reference: {
        column: 'parcel_reference',
        map: 'ParcelReference',
        displayPipeArgs: ['none'],
      },
      waybill: {
        column: 'waybill',
        map: 'ParcelWaybill',
        displayPipeArgs: ['none'],
      },
      parcel_value: {
        column: 'parcel_value',
        map: 'ParcelValue',
        prefix: environment.currency_symbol,
        displayPipeArgs: DisplayPipes.decimalsTwo,
      },
      amount_paid: {
        column: 'amount_paid',
        map: 'AmountPaid',
        format: mfAmountPaid,
      },
      payment_type: {
        column: 'payment_type',
        map: 'PaymentType',
        format: mfHumanizeString,
      },
      failed_reason: {
        column: 'failed_reason',
        map: 'FailedReason',
        format: mfHumanizeString,
      },
      reconciled_reason: {
        column: 'reconciled_reason',
        map: 'ReconciledReason',
        format: mfHumanizeString,
      },
    },
  };

  courierParcelOverviewColumns: TableColumn[] = [
    { name: 'parcel_state', displayName: 'Status' },
    { name: 'courier_reference', displayName: 'Courier Reference' },
    { name: 'parcel_reference', displayName: 'Parcel Reference' },
    { name: 'waybill', displayName: 'Waybill Number' },
    { name: 'parcel_value', displayName: 'Parcel Value' },
    { name: 'amount_paid', displayName: 'Paid' },
    { name: 'payment_type', displayName: 'Payment Type' },
    { name: 'reconciled_reason', displayName: 'Reconciled Reason' },
    { name: 'failed_reason', displayName: 'Failure Reason' },
  ];

  courierParcelOverviewDataMap: TableDataMap = {
    cells: {
      parcel_state: {
        column: 'parcel_state',
        map: ['CourierParcelState'],
        format: mfHumanizeString,
      },
      courier_reference: {
        column: 'courier_reference',
        map: ['CourierReference'],
      },
      parcel_reference: {
        column: 'parcel_reference',
        map: 'ParcelReference',
        displayPipeArgs: ['none'],
      },
      waybill: {
        column: 'waybill',
        map: 'ParcelWaybill',
        displayPipeArgs: ['none'],
      },

      parcel_value: {
        column: 'ParcelValue',
        map: 'Cost',
        prefix: environment.currency_symbol,
        displayPipeArgs: DisplayPipes.decimalsTwo,
      },
      amount_paid: {
        column: 'AmountPaid',
        map: 'amount_paid',
        format: mfAmountPaid,
      },
      payment_type: {
        column: 'PaymentType',
        map: 'payment_type',
        format: mfHumanizeString,
      },
      failed_reason: {
        column: 'Failure',
        map: 'FailedReason',
        format: mfHumanizeString,
      },
      reconciled_reason: {
        column: 'reconciled_reason',
        map: 'ReconciledReason',
        format: mfHumanizeString,
      },
    },
  };

  orderParcelOverviewDataMap: TableDataMap = {
    cells: {
      parcel_state: {
        column: 'parcel_state',
        map: ['OrderParcelState', 'ActiveRouteId'],
        format: onActiveRouteFormatter,
      },
      parcel_reference: {
        column: 'parcel_reference',
        map: 'ParcelReference',
        displayPipeArgs: ['none'],
      },
      waybill: {
        column: 'waybill',
        map: 'ParcelWaybill',
        displayPipeArgs: ['none'],
      },
      parcel_value: {
        column: 'ParcelValue',
        map: 'ParcelValue',
        prefix: environment.currency_symbol,
        displayPipeArgs: DisplayPipes.decimalsTwo,
      },
      amount_paid: {
        column: 'AmountPaid',
        map: 'AmountPaid',
        format: mfAmountPaid,
      },
      payment_type: {
        column: 'PaymentType',
        map: 'PaymentType',
        format: mfHumanizeString,
      },
      failed_reason: {
        column: 'Failure',
        map: 'FailedReason',
        format: mfHumanizeString,
      },
      reconciled_reason: {
        column: 'reconciled_reason',
        map: 'ReconciledReason',
        format: mfHumanizeString,
      },
    },
  };
  parcelOverviewTableData: TableRow[];

  contacts;
  public ButtonTypes: ButtonTypes;
  public IconTypes = IconTypes;
  public UiColors = UiColors;
  public ModalSizes = ModalSizes;
  public UiThemes = UiThemes;
  public MapMarkerColors = MapMarkerColors;
  buttonTypes = ButtonTypes;

  constructor(private sharedService: SharedService, private router: Router, private activatedRoute: ActivatedRoute) {
    if (activatedRoute.snapshot.url[0].path !== 'trip') {
      this.showGoToOrder = true;
      this.showSummary = true;
    }
  }

  ngOnChanges(): void {
    if (this.context === 'order' || this.context === 'courier') {
      this.orderContext();
    } else if (this.context === 'lastMile') {
      this.lastMileContext();
    }
    this.signatureMapping();
  }

  goToOrder(orderId: string): void {
    this.router.navigate(['dashboard/orders/trip/', orderId]);
  }

  goToCourierOrder(CourierOrderId: string): void {
    this.router.navigate(['dashboard/courier-orders/order/', CourierOrderId]);
  }

  signatureMapping(): void {
    let url;
    const urlBase =
      'https://storage.googleapis.com/' + environment.firebase.storageBucket + '/signature/mobile-signatures/';
    this.businessReferenceBreakdown.forEach((reference) => {
      if (reference.LatestDeliveredRouteId && reference.Parcels.every((parcel) => parcel.ReconciledReason)) {
        url = 'assets/img/admin_signature.png';
        this.signatureMap.set(reference.OrderWaybill + '-deliver', url);
      } else if (reference.LatestReturnedRouteId && reference.Parcels.every((parcel) => parcel.ReconciledReason)) {
        url = 'assets/img/admin_signature.png';
        this.signatureMap.set(reference.OrderWaybill + '-return', url);
      } else if (
        reference.LatestDeliveredRouteId &&
        reference.Parcels.some((parcel) => parcel.OrderParcelState === 'Delivered')
      ) {
        url =
          urlBase +
          reference.LastMileId +
          '_' +
          reference.LatestDeliveredRouteId?.slice(6) +
          '_' +
          reference.OrderWaybill +
          '_delivery.jpg';
        this.signatureMap.set(reference.OrderWaybill + '-deliver', url);
      } else if (
        reference.LatestReturnedRouteId &&
        reference.Parcels.some((parcel) => parcel.OrderParcelState === 'Returned')
      ) {
        url =
          urlBase +
          reference.LastMileId +
          '_' +
          reference.LatestReturnedRouteId?.slice(6) +
          '_' +
          reference.OrderWaybill +
          '_return.jpg';
        this.signatureMap.set(reference.OrderWaybill + '-return', url);
      }
    });
  }

  lastMileContext(): void {
    const parcelArray = [];
    this.businessReferenceBreakdown.forEach((reference) => {
      let status: string = null;
      let statusDotColor = null;
      let statusIcon = null;

      if (reference.Parcels.every((parcel) => parcel.LastMileParcelState === LastMileParcelStateEnum.Delivered)) {
        status = LastMileParcelStateEnum.Delivered;
        statusDotColor = UiColors.Green;
        statusIcon = IconTypes.Check;
      } else if (reference.Parcels.some((parcel) => parcel.ActiveRouteId)) {
        status = 'On Active Route';
        statusDotColor = UiColors.Blue;
        statusIcon = IconTypes.Trip;
      } else if (
        reference.Parcels.every((parcel) => parcel.LastMileParcelState === LastMileParcelStateEnum.Cancelled)
      ) {
        status = LastMileParcelStateEnum.Cancelled;
        statusDotColor = UiColors.Red;
        statusIcon = IconTypes.Cross;
      } else if (reference.Parcels.every((parcel) => parcel.LastMileParcelState === LastMileParcelStateEnum.Returned)) {
        status = LastMileParcelStateEnum.Returned;
        statusDotColor = UiColors.Green;
        statusIcon = IconTypes.Warehouse;
      } else if (reference.Parcels.some((parcel) => parcel.LastMileParcelState === LastMileParcelStateEnum.Assigned)) {
        status = `${LastMileParcelStateEnum.Assigned} - Pending Route Start`;
        statusDotColor = UiColors.Blue;
        statusIcon = IconTypes.Truck;
      } else if (
        reference.Parcels.some(
          (parcel) => parcel.LastMileParcelState === LastMileParcelStateEnum.PendingRecollection
        ) ||
        reference.Parcels.some((parcel) => parcel.LastMileParcelState === LastMileParcelStateEnum.PendingRedelivery)
      ) {
        status = 'Retry - Pending Route Start';
        statusDotColor = UiColors.Yellow;
        statusIcon = IconTypes.Truck;
      } else if (
        reference.Parcels.every((parcel) => parcel.LastMileParcelState === LastMileParcelStateEnum.PendingAllocation) ||
        reference.Parcels.some((parcel) => parcel.LastMileParcelState === LastMileParcelStateEnum.None)
      ) {
        status = 'Pending Allocation';
        statusDotColor = UiColors.Blue;
        statusIcon = IconTypes.Truck;
      } else if (
        reference.Parcels.every((parcel) => parcel.LastMileParcelState === LastMileParcelStateEnum.Collected)
      ) {
        status = `${LastMileParcelStateEnum.Collected} - Pending Delivery Route Start`;
        statusDotColor = UiColors.Blue;
        statusIcon = IconTypes.Truck;
      } else if (
        reference.Parcels.some((parcel) => parcel.LastMileParcelState === LastMileParcelStateEnum.ExecutionFailed) ||
        reference.Parcels.some((parcel) => parcel.LastMileParcelState === LastMileParcelStateEnum.FailedCollection) ||
        reference.Parcels.some((parcel) => parcel.LastMileParcelState === LastMileParcelStateEnum.FailedDelivery) ||
        reference.Parcels.some(
          (parcel) => parcel.LastMileParcelState === LastMileParcelStateEnum.FinalCollectionAttemptFailed
        ) ||
        reference.Parcels.some((parcel) => parcel.LastMileParcelState === LastMileParcelStateEnum.FailedReturn) ||
        reference.Parcels.some((parcel) => parcel.LastMileParcelState === LastMileParcelStateEnum.PendingReturn)
      ) {
        status = 'Contains Failures - Pending Redelivery/Return Route Start';
        statusDotColor = UiColors.Yellow;
        statusIcon = IconTypes.Alert;
      } else {
      }
      reference['statusIcon'] = statusIcon;
      reference['status'] = status;
      reference['statusDotColor'] = statusDotColor;
      reference['tableData'] = mapDataToTable(
        reference.Parcels,
        this.lastMileParcelOverviewDataMap,
        this.parcelOverviewColumns
      );
      reference.Parcels.forEach((parcel) => {
        parcelArray.push(parcel);
      });
    });

    this.setTotals(parcelArray);
  }

  orderContext(): void {
    const parcelArray = [];
    if (this.context === 'courier') {
      this.courierName = this.businessReferenceBreakdown[0].CourierName;
      this.courierReference = this.businessReferenceBreakdown[0].CourierReference;
      this.courierSericeTypeDescription = this.businessReferenceBreakdown[0].ServiceTypeDescription;
    }
    this.businessReferenceBreakdown.forEach((reference) => {
      let status = null;
      let statusDotColor = null;
      let statusIcon = null;
      let mapMarkerColor = null; // Added marker color here to pass back to order view. Big plays
      if (reference.Parcels.every((parcel) => parcel.CourierParcelState === CourierOrderParcelStateEnum.Delivered)) {
        status = 'Delivered';
        statusDotColor = UiColors.Green;
        statusIcon = IconTypes.Check;
        mapMarkerColor = MapMarkerColors.Green;
      } else if (reference.Parcels.every((parcel) => parcel.OrderParcelState === OrderParcelStateEnum.Delivered)) {
        status = 'Delivered';
        statusDotColor = UiColors.Green;
        statusIcon = IconTypes.Check;
        mapMarkerColor = MapMarkerColors.Green;
      } else if (
        reference.Parcels.every((parcel) => parcel.CourierParcelState === CourierOrderParcelStateEnum.CourierAccepted)
      ) {
        status = 'Courier Accepted';
        statusDotColor = UiColors.Green;
        statusIcon = IconTypes.Trip;
      } else if (
        reference.Parcels.some(
          (parcel) => parcel.CourierParcelState === CourierOrderParcelStateEnum.WaybillCreationFailed
        )
      ) {
        status = 'Waybill Creation Failed';
        statusDotColor = UiColors.Red;
        statusIcon = IconTypes.Cross;
        mapMarkerColor = MapMarkerColors.Red;
      } else if (
        reference.Parcels.every((parcel) => parcel.OrderParcelState === OrderParcelStateEnum.FailedCollection)
      ) {
        status = 'All Collections Failed';
        statusDotColor = UiColors.Red;
        statusIcon = IconTypes.Alert;
        mapMarkerColor = MapMarkerColors.Red;
      } else if (
        reference.Parcels.some(
          (parcel) =>
            parcel.OrderParcelState === OrderParcelStateEnum.FailedCollection ||
            parcel.OrderParcelState === OrderParcelStateEnum.FinalCollectionAttemptFailed
        ) &&
        !reference.Parcels.every((parcel) => parcel.OrderParcelState === OrderParcelStateEnum.FailedCollection)
      ) {
        status = 'Contains Failed Collections';
        statusDotColor = UiColors.Orange;
        statusIcon = IconTypes.Alert;
        mapMarkerColor = MapMarkerColors.Orange;
      } else if (reference.Parcels.every((parcel) => parcel.OrderParcelState === OrderParcelStateEnum.Returned)) {
        status = 'Returned';
        statusDotColor = UiColors.Yellow;
        statusIcon = IconTypes.Warehouse;
        mapMarkerColor = MapMarkerColors.Yellow;
      } else if (
        reference.Parcels.some((parcel) => parcel.OrderParcelState === OrderParcelStateEnum.AllocatedToPicup)
      ) {
        status = 'Assigned - Pending Route Start';
        statusDotColor = UiColors.Blue;
        statusIcon = IconTypes.Truck;
        mapMarkerColor = MapMarkerColors.Blue;
      } else if (
        reference.Parcels.every((parcel) => parcel.OrderParcelState === OrderParcelStateEnum.PendingAllocation) ||
        reference.Parcels.some((parcel) => parcel.OrderParcelState === OrderParcelStateEnum.None)
      ) {
        status = 'Pending Allocation';
        statusDotColor = UiColors.Blue;
        statusIcon = IconTypes.Truck;
        mapMarkerColor = MapMarkerColors.Blue;
      } else if (
        reference.Parcels.some((parcel) => parcel.OrderParcelState === OrderParcelStateEnum.Delivered) &&
        reference.Parcels.every((parcel) => parcel.OrderParcelState !== OrderParcelStateEnum.Delivered)
      ) {
        status = 'Partial Delivery';
        statusDotColor = UiColors.Yellow;
        statusIcon = IconTypes.Trip;
        mapMarkerColor = MapMarkerColors.Yellow;
      } else if (reference.Parcels.every((parcel) => parcel.OrderParcelState === OrderParcelStateEnum.Collected)) {
        status = 'Collected - Pending Delivery Route Start';
        statusDotColor = UiColors.Blue;
        statusIcon = IconTypes.Truck;
        mapMarkerColor = MapMarkerColors.Blue;
      } else if (reference.Parcels.every((parcel) => parcel.OrderParcelState === OrderParcelStateEnum.Cancelled)) {
        status = 'All Parcels Cancelled';
        statusDotColor = UiColors.Red;
        statusIcon = IconTypes.Cross;
        mapMarkerColor = MapMarkerColors.Red;
      } else if (reference.Parcels.some((parcel) => parcel.OrderParcelState === OrderParcelStateEnum.Cancelled)) {
        status = 'Contains Cancelled Parcels';
        statusDotColor = UiColors.Red;
        statusIcon = IconTypes.Cross;
        mapMarkerColor = MapMarkerColors.Red;
      } else if (
        reference.Parcels.some((parcel) => parcel.OrderParcelState === OrderParcelStateEnum.ExecutionFailed) ||
        reference.Parcels.some((parcel) => parcel.OrderParcelState === OrderParcelStateEnum.FailedDelivery) ||
        reference.Parcels.some((parcel) => parcel.OrderParcelState === OrderParcelStateEnum.FailedReturn)
      ) {
        status = 'Contains Failures - Pending Redelivery/Return Route Start';
        statusDotColor = UiColors.Yellow;
        statusIcon = IconTypes.Alert;
        mapMarkerColor = MapMarkerColors.Yellow;
      } else if (
        reference.Parcels.every((parcel) => parcel.OrderParcelState === OrderParcelStateEnum.StagedAssignment)
      ) {
        status = 'Assignment Staged';
        statusDotColor = UiColors.Blue;
        statusIcon = IconTypes.Truck;
        mapMarkerColor = MapMarkerColors.Blue;
      } else {
        if (this.context === 'order') {
          status = 'On Active Route';
          statusDotColor = UiColors.Blue;
          statusIcon = IconTypes.Trip;
          mapMarkerColor = MapMarkerColors.Blue;
        } else {
          status = 'Courier Order In Progress';
          statusDotColor = UiColors.Blue;
          statusIcon = IconTypes.Trip;
          mapMarkerColor = MapMarkerColors.Blue;
        }
      }
      reference['statusIcon'] = statusIcon;
      if (reference.Parcels[0]?.FinalizedAt) {
        status = status + ' - ' + moment(reference.Parcels[0]?.FinalizedAt?.toMillis()).format('HH:mm');
      }
      reference['status'] = status;
      reference['statusDotColor'] = statusDotColor;
      reference['mapMarkerColor'] = mapMarkerColor;
      reference['tableData'] = mapDataToTable(
        reference.Parcels,
        this.context === 'order' ? this.orderParcelOverviewDataMap : this.courierParcelOverviewDataMap,
        this.context === 'order' ? this.parcelOverviewColumns : this.courierParcelOverviewColumns
      );

      reference.Parcels.forEach((parcel) => {
        parcelArray.push(parcel);
      });
      this.setCourierTotals(parcelArray);
    });

    this.dataModified.emit(true);
  }

  setCourierTotals(parcelArray: any[]): void {
    this.totals = {
      packages: parcelArray.length,
      collected: 0,
      delivered: 0,
      failed: 0,
    };
    // Need to get all states here
    parcelArray.forEach((parcel) => {
      switch (parcel.OrderParcelState) {
        case OrderParcelStateEnum.Collected:
        case OrderParcelStateEnum.AtSortingFacility:
        case OrderParcelStateEnum.OutForDelivery:
          this.totals.collected++;
          break;
        case OrderParcelStateEnum.Delivered:
          this.totals.delivered++;
          break;
        case OrderParcelStateEnum.WaybillCreationFailed:
        case OrderParcelStateEnum.Cancelled:
        case OrderParcelStateEnum.AllocationFailed:
        case OrderParcelStateEnum.ExecutionFailed:
        case OrderParcelStateEnum.FailedCollection:
        case OrderParcelStateEnum.FailedReturn:
          this.totals.failed++;
          break;
      }
    });
  }

  downloadPod(orderWaybill) {
    this.downloadingPOD = true;
    this.sharedService.downloadOrderPOD(orderWaybill).then(() => (this.downloadingPOD = false));
  }

  setTotals(parcelArray: any[]): void {
    this.totals = {
      packages: parcelArray.length,
      collected: 0,
      delivered: 0,
      failed: 0,
    };
    parcelArray.forEach((parcel) => {
      switch (parcel.LastMileParcelState) {
        case LastMileParcelStateEnum.Delivered:
          this.totals.delivered++;
          break;
        case LastMileParcelStateEnum.Collected:
          this.totals.collected++;
          break;
        case LastMileParcelStateEnum.FailedDelivery:
        case LastMileParcelStateEnum.ExecutionFailed:
        case LastMileParcelStateEnum.FailedCollection:
        case LastMileParcelStateEnum.FailedReturn:
        case LastMileParcelStateEnum.Cancelled:
          this.totals.failed++;
          break;
      }
    });
  }

  hasOrderWaybill(reference: any): boolean {
    const hasWaybill = reference.Parcels.find((x) => x.ParcelWaybill === this.orderWaybill);
    return hasWaybill;
  }
}
