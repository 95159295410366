<div class="customer">
  <div class="customer-info">
    <app-flex-container
      [theme]="UiThemes.Dark"
      [borderBottom]="true"
      [borderTop]="true"
      [justifyContent]="justifyContent.SpaceBetween"
      [padding]="'2rem'"
    >
      <div class="customer-details">
        <div class="customer-detail name">
          <app-icon-with-text
            [icon]="IconTypes.Profile"
            [iconColor]="UiColors.Blue"
            [iconSizeRem]="1.5"
            [iconSpacingRem]="1.5"
            [text]="contact?.Name ? contact.Name : '-'"
            [sizeRem]="2"
            [color]="UiColors.White"
          >
          </app-icon-with-text>
        </div>
        <div class="customer-detail">
          <app-icon-with-text
            [icon]="IconTypes.Mail"
            [iconColor]="UiColors.Grey5"
            [iconSizeRem]="1"
            [iconSpacingRem]="2"
            [text]="contact?.Email ? contact.Email : '-'"
            [sizeRem]="1"
            [color]="UiColors.White"
          >
          </app-icon-with-text>
        </div>
        <div class="customer-detail">
          <app-icon-with-text
            [icon]="IconTypes.Phone"
            [iconColor]="UiColors.Grey5"
            [iconSizeRem]="1"
            [iconSpacingRem]="2"
            [text]="contact?.Phone ? contact.Phone : '-'"
            [sizeRem]="1"
            [color]="UiColors.White"
          >
          </app-icon-with-text>
        </div>
        <div class="customer-detail">
          <app-icon-with-text
            [icon]="IconTypes.Details"
            [iconColor]="UiColors.Grey5"
            [iconSizeRem]="1"
            [iconSpacingRem]="2"
            [text]="contact?.Instructions ? contact.Instructions : '-'"
            [sizeRem]="1"
            [color]="UiColors.White"
          >
          </app-icon-with-text>
        </div>
      </div>
      <!-- TODO: Admin Actions Button -->
      <!-- <div class="image-wrap" *ngIf="contact.return_signature || contact.return_verification_photo">
        <div class="pod-image" *ngIf="contact.return_verification_photo">
          <div class="image-div">
            <a class="link" [href]="contact.return_verification_photo" target="_blank" title="Download Image">
              <span class="hover">
                <app-icon
                  class="download-icon"
                  [icon]="IconTypes.Delivery"
                  [color]="UiColors.Grey1"
                  [size]="1.5"
                ></app-icon>
              </span>
            </a>
          </div>
        </div>
        <div *ngIf="contact.return_signature">
          <div class="customer-detail">
            <app-icon-with-text
              [icon]="IconTypes.Profile"
              [iconColor]="UiColors.Blue"
              [iconSizeRem]="1"
              [iconSpacingRem]="1"
              [text]="contact.return_signature.signee"
              [sizeRem]="1"
              [color]="UiColors.White"
            >
            </app-icon-with-text>
          </div>
          <div class="customer-detail">
            <app-icon-with-text
              [icon]="IconTypes.Details"
              [iconColor]="UiColors.Blue"
              [iconSizeRem]="1"
              [iconSpacingRem]="1"
              [text]="contact.return_signature.relationship"
              [sizeRem]="1"
              [color]="UiColors.White"
            >
            </app-icon-with-text>
          </div>
          <app-image-with-caption
            [widthRem]="16.67"
            [heightRem]="6.67"
            [src]="contact.return_signature.url"
            [download]="true"
            [showCaption]="false"
          >
          </app-image-with-caption>
        </div>
      </div>
      <div class="image-wrap" *ngIf="contact.delivery_signature || contact.delivery_verification_photo">
        <div class="pod-image" *ngIf="contact.delivery_verification_photo">
          <div class="image-div">
            <a class="link" [href]="contact.delivery_verification_photo" target="_blank" title="Download Image">
              <span class="hover">
                <app-icon
                  class="download-icon"
                  [icon]="IconTypes.Delivery"
                  [color]="UiColors.Grey1"
                  [size]="1.5"
                ></app-icon>
              </span>
            </a>
          </div>
        </div>
        <div *ngIf="contact.delivery_signature">
          <div class="customer-detail">
            <app-icon-with-text
              [icon]="IconTypes.Profile"
              [iconColor]="UiColors.Blue"
              [iconSizeRem]="1"
              [iconSpacingRem]="1"
              [text]="contact.delivery_signature.signee"
              [sizeRem]="1"
              [color]="UiColors.White"
            >
            </app-icon-with-text>
          </div>
          <div class="customer-detail">
            <app-icon-with-text
              [icon]="IconTypes.Details"
              [iconColor]="UiColors.Blue"
              [iconSizeRem]="1"
              [iconSpacingRem]="1"
              [text]="contact.delivery_signature.relationship"
              [sizeRem]="1"
              [color]="UiColors.White"
            >
            </app-icon-with-text>
          </div>
          <div class="customer-detail" *ngIf="contact.total_amount_paid">
            <app-icon-with-text
              [icon]="IconTypes.Dollar"
              [iconColor]="UiColors.Blue"
              [iconSizeRem]="1"
              [iconSpacingRem]="1"
              [text]="'Amount Paid: ' + (contact.total_amount_paid | currency: 'R ')"
              [sizeRem]="1"
              [color]="UiColors.White"
            >
            </app-icon-with-text>
          </div>
          <app-image-with-caption
            [widthRem]="16.67"
            [heightRem]="6.67"
            [src]="contact.delivery_signature.url"
            [download]="true"
            [showCaption]="false"
          >
          </app-image-with-caption>
        </div>
      </div>
      <div class="image-wrap" *ngIf="contact.collection_signature || contact.collection_verification_photo">
        <div class="pod-image" *ngIf="contact.collection_verification_photo">
          <div class="image-div">
            <a class="link" [href]="contact.collection_verification_photo" target="_blank" title="Download Image">
              <span class="hover">
                <app-icon
                  class="download-icon"
                  [icon]="IconTypes.Delivery"
                  [color]="UiColors.Grey1"
                  [size]="1.5"
                ></app-icon>
              </span>
            </a>
          </div>
        </div>
        <div *ngIf="contact.collection_signature">
          <div class="customer-detail">
            <app-icon-with-text
              [icon]="IconTypes.Profile"
              [iconColor]="UiColors.Blue"
              [iconSizeRem]="1"
              [iconSpacingRem]="1"
              [text]="contact.collection_signature.signee"
              [sizeRem]="1"
              [color]="UiColors.White"
            >
            </app-icon-with-text>
          </div>
          <div class="customer-detail">
            <app-icon-with-text
              [icon]="IconTypes.Details"
              [iconColor]="UiColors.Blue"
              [iconSizeRem]="1"
              [iconSpacingRem]="1"
              [text]="contact.collection_signature.relationship"
              [sizeRem]="1"
              [color]="UiColors.White"
            >
            </app-icon-with-text>
          </div>

          <app-image-with-caption
            [widthRem]="16.67"
            [heightRem]="6.67"
            [src]="contact.collection_signature.url"
            [download]="true"
            [showCaption]="false"
          >
          </app-image-with-caption>
        </div>
      </div> -->
    </app-flex-container>
    <app-table
      [columns]="parcelOverviewColumns"
      [tableData]="parcelOverviewTableData"
      [rowsSelectable]="false"
    ></app-table>
  </div>
</div>
