import { TableDataMapFormatter } from 'app/shared/table/table.interfaces';
import { HealthStates } from '../trips.enums';

export const LastEventConstant = {
  OrderCreatedEvent: {
    color: HealthStates.Default,
    label: 'New',
  },
  OwnerLoadOrderAddedEvent: {
    color: HealthStates.Default,
    label: 'New',
  },
  LastMileParcelsAssignedEvent: {
    color: HealthStates.Primary,
    label: 'Driver Assigned',
  },
  ParcelsReconciledEvent: {
    color: HealthStates.Good,
    label: 'Complete',
  },
  OptimizedRouteStartedEvent: {
    color: HealthStates.Good,
    label: 'In Progress',
  },
  OptimizedRouteEndedEvent: {
    color: HealthStates.Good,
    label: 'Route Complete',
  },
  DriverEnRouteToWarehouseEvent: {
    color: HealthStates.Good,
    label: 'In Progress',
  },
  DriverEnRouteToWaypointEvent: {
    color: HealthStates.Good,
    label: 'In Progress',
  },
  DriverArrivedAtWarehouseEvent: {
    color: HealthStates.Good,
    label: 'In Progress',
  },
  DriverArrivedAtWaypointEvent: {
    color: HealthStates.Good,
    label: 'In Progress',
  },
  DriverFinalizedAtWarehouseEvent: {
    color: HealthStates.Good,
    label: 'In Progress',
  },
  DriverFinalizedAtWaypointEvent: {
    color: HealthStates.Good,
    label: 'In Progress',
  },
  DriverSucceededParcelsAtWarehouseEvent: {
    color: HealthStates.Good,
    label: 'In Progress',
  },
  DriverSucceededParcelsAtWaypointEvent: {
    color: HealthStates.Good,
    label: 'In Progress',
  },
  DriverFailedEnRouteToWarehouseEvent: {
    color: HealthStates.Bad,
    label: 'In Progress',
  },
  DriverFailedParcelsAtWarehouseEvent: {
    color: HealthStates.Bad,
    label: 'In Progress',
  },
  DriverFailedEnRouteToWaypointEvent: {
    color: HealthStates.Bad,
    label: 'In Progress',
  },
  DriverFailedContactAtWaypointEvent: {
    color: HealthStates.Bad,
    label: 'In Progress',
  },
  DriverFailedParcelsAtWaypointEvent: {
    color: HealthStates.Bad,
    label: 'In Progress',
  },
  LastMileClearedEvent: {
    color: HealthStates.Warning,
    label: 'Unassigned',
  },
};

export const mfParcelStateEnum: TableDataMapFormatter = (map: [string], item: any) => {
  switch (item[map[0]]) {
    case 0:
      return 'None';
    case 1:
      return 'Assigned';
    case 2:
      return 'Collected';
    case 3:
      return 'FailedCollection';
    case 4:
      return 'Delivered';
    case 5:
      return 'FailedDelivery';
    case 6:
      return 'Returned';
  }
};

export enum TripMenuItemEnum {
  Debrief = 'debrief',
  AssignDriver = 'assign-driver',
  AssignParcels = 'assign-parcels',
  DownloadWaybills = 'download-waybills',
  CancelOrder = 'cancel-order',
  ForceCompleteOrder = 'force-complete-order',
  UnassignOrderParcels = 'unassign-order-parcels',
  RecreateTrip = 'recreate-trip',
  RehydrateOrder = 'rehydrate-order',
  AttemptDirectAssignment = 'attempt-direct-assignment',
  AllFailedFinalCollections = 'all-failed-final-collections',
  SelfAssign = 'self-assign',
  RemoveAssignedUser = 'remove-self-assign',
  RecreateAsCourierOrder= 'recreate-as-courier-order'
}
