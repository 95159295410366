
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TableColumn } from 'app/shared/generic-modular-table/generic-modular-table.interfaces';

@Component({
  selector: 'app-table-select-modal',
  templateUrl: './table-select-modal.component.html',
  styleUrls: ['./table-select-modal.component.scss']
})
export class TableSelectModalComponent implements OnInit {
  tableColumns: TableColumn[];
  heading: string = 'Select';
  selectedData: unknown[] = [];
  dataSource: MatTableDataSource<unknown> = new MatTableDataSource([]);
  @Output() confirm = new EventEmitter<unknown[]>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TableSelectDialogData,
    private dialogRef: MatDialogRef<TableSelectModalComponent>) {  }

  ngOnInit(): void {
  }

  save(): void {
    this.dialogRef.close(this.selectedData);
  }

  selectedItems(items: unknown[]): void {
    this.selectedData = items;
  }
}

export interface TableSelectDialogData{
  heading: string,
  tableColumns: TableColumn[],
  tableData: MatTableDataSource<unknown[]>,
  showPaging: boolean
}





