import { Component } from '@angular/core';
import { unAuthedAxios } from 'app/api/axios';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ButtonTypes } from '../buttons/basic-button.component';
import { JustifyContent } from '../flex-container/flex-container.interfaces';
import { IconTypes } from '../icon/icon.interfaces';
import { ModalSizes } from '../modals/modal.interfaces';
import { PhoneValidationPipe } from '../pipes/phone-validation.pipe';

export interface RequestDemoModel {
  name: string;
  workEmail: string;
  phone: string;
  company: string;
  jobTitle: string;
  country: string;
  userType: string;
}

@Component({
  selector: 'app-request-demo-modal',
  templateUrl: './request-demo-modal.component.html',
  styleUrls: ['./request-demo-modal.component.scss'],
})
export class RequestDemoModalComponent
  extends SimpleModalComponent<RequestDemoModel, boolean>
  implements RequestDemoModel {
  ModalSizes = ModalSizes;
  UiThemes = UiThemes;
  UiColors = UiColors;
  IconTypes = IconTypes;
  buttonTypes = ButtonTypes;
  JustifyContent: JustifyContent;
  submitting = false;
  name: string;
  workEmail: string;
  phone: string;
  company: string;
  jobTitle: string;
  country: string;
  userType: string;
  success: boolean;
  processing: boolean;
  emailInvalid: boolean = false;
  phoneInvalid: boolean = false;

  constructor(private phoneValidationPipe: PhoneValidationPipe) {
    super();
  }

  sendEmail() {
    if (!this.phoneValidationPipe.transform(this.phone)) {
      this.phoneInvalid = true;
      return;
    } else if (!this.validateEmail(this.workEmail)) {
      this.emailInvalid = true;
      return;
    } else {
      this.processing = true;
      return unAuthedAxios({
        method: 'POST',
        url: '/communication/send-custom-mail',
        data: {
          template_id: 'f414246a-7445-4157-bbdc-8b393fe5003a',
          receiver_email: 'info@picup.co.za',
          receiver_name: this.workEmail,
          subject: 'New Demo Request Received',
          substitutions: {
            name: this.name,
            workEmail: this.workEmail,
            phone: this.phone,
            company: this.company,
            jobTitle: this.jobTitle,
            country: this.country,
            userType: this.userType,
          },
        },
      })
        .then(() => {
          this.success = true;
          this.processing = false;
        })
        .catch(() => {
          this.processing = false;
        });
    }
  }

  closeModal() {
    this.close();
  }

  validateEmail(email: string): boolean {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  ngOnInit(): void {}
}
