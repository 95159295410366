import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { SetWebhookComponent } from '../set-webhook/set-webhook.component';
import { SimpleModalService } from 'ngx-simple-modal';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { MapTypes } from 'app/shared/map/map.interfaces';
import { ConfirmModalComponent } from 'app/shared/modals/confirm-modal.component';
import { ManageBusinessService } from '../manage-business.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-integration-details',
  templateUrl: './integration-details.component.html',
  styleUrls: ['./integration-details.component.scss'],
})
export class IntegrationDetailsComponent implements OnInit {
  public IconTypes = IconTypes;
  public ButtonTypes = ButtonTypes;
  public UiThemes = UiThemes;
  public MapTypes = MapTypes;
  environment = environment;
  uiColors = UiColors;

  @Input() actingAs;
  @Input() businessDetails;

  loading = false;
  processing = false;
  @Output() reload = new EventEmitter<any>();

  constructor(private simpleModalService: SimpleModalService, private manageBusinessService: ManageBusinessService) {}

  setWebhookURL(): void {
    this.processing = true;
    this.simpleModalService
      .addModal(SetWebhookComponent, { businessDetails: this.businessDetails })
      .subscribe((result) => {
        if (!result) {
          this.processing = false;
          return;
        }
        this.manageBusinessService
          .setWebhookURL(result)
          .then(() => {
            this.reload.emit(true);
            this.processing = false;
          })
          .catch(() => (this.processing = false));
      });
  }

  sendTestWebhooks(): void {
    this.loading = true;
    this.simpleModalService
      .addModal(ConfirmModalComponent, {
        title: 'Test Webhooks',
        message: 'This will send an example of each webhook to the configured address.',
      })
      .subscribe((isConfirmed) => {
        if (!isConfirmed) {
          this.loading = false;
          return;
        }
        this.manageBusinessService
          .testWebhooks()
          .then(() => (this.loading = false))
          .catch(() => (this.loading = false));
      });
  }

  ngOnInit(): void {}
}
