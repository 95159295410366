<div class="button-wrap" style="justify-content: flex-start">
  <ng-template ngFor let-section [ngForOf]="sectionIds">
    <!-- TODO: refactor for hidden button attribute -->
    <app-basic-button
      [disabled]="section.disabled && selectedSection !== section.name"
      *ngIf="!section.hidden"
      [active]="selectedSection === section.name"
      [title]="section.name"
      [type]="highlight && selectedSection === section.name ? buttonTypes.Highlight : buttonTypes.Primary"
      (click)="selectSection(section.name)"
    ></app-basic-button>
  </ng-template>
</div>
