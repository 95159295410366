
    <div class="timeline-content" *ngIf="timeline">
        <!-- Date-time -->
        <div class="timesline-date">  
            <!-- bigdate -->
            <div class="timeline-bigdate">
                {{timeline.Timestamp | date: 'dd MMM yy'}}
            </div>
            <!-- time -->
            <div class="timeline-smalldate">
                {{timeline.Timestamp | date: 'hh:mm a'}}
            </div>
        </div>
        <!-- point icon -->
        <div class="timeline-icon" >
            <mat-icon class="icon-start" 
                *ngIf="position === timelinePositionEnum.first" 
                [ngClass]="getClassBasedOnTime(timeline.Timestamp)">
                arrow_circle_down
            </mat-icon>
            <mat-icon class="icon" 
                *ngIf="position === timelinePositionEnum.other" 
                [ngClass]="getClassBasedOnTime(timeline.Timestamp)">
                trip_origin
            </mat-icon>
            <mat-icon class="icon-start" 
                *ngIf="position === timelinePositionEnum.last" 
                [ngClass]="getClassBasedOnTime(timeline.Timestamp)">
                radio_button_checked
            </mat-icon>
        </div>
        <!-- information -->
        <div class="timeline-info">
            <!-- description -->
            <div class="timeline-desc">
                {{timeline.EventDescription | timelineEventNaming}}
            </div>
            <!-- email -->
            <div class="timeline-email">
                {{timeline.EventArg2}}
            </div>
        </div>
    </div>
