import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { IconTypes } from '../icon/icon.interfaces';
import { UiColors } from '../../interfaces/ui.interfaces';
import { ModalSizes, ModalAction } from './modal.interfaces';

export interface ConfirmModel {
  title: string;
  description?: string;
  message: string;
  allow_false?: boolean;
}

@Component({
  moduleId: module.id,
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent extends SimpleModalComponent<ConfirmModel, boolean> implements ConfirmModel {
  public ModalSizes = ModalSizes;
  public IconTypes = IconTypes;
  public UiColors = UiColors;
  public actions: ModalAction[] = [
    { name: 'cancel', title: 'Cancel' },
    { name: 'confirm', title: 'Confirm', isPrimary: true },
  ];
  title: string;
  description: string;
  message: string;

  constructor() {
    super();
  }

  handleAction($event) {
    switch ($event) {
      case 'cancel':
        this.result = false;
        this.close();
        break;
      case 'confirm':
        this.result = true;
        this.close();
        break;
    }
  }
}
