import { Component, ElementRef, OnInit, ViewChild, Input } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
  @Input() maxWidth: string = '891px';
  @ViewChild('carouselSlides') carouselSlides: ElementRef<HTMLDivElement>;
  constructor() { }

  ngOnInit(): void {
  }

  goRight(): void {
    const el = this.carouselSlides.nativeElement.firstChild as HTMLElement;
    this.carouselSlides.nativeElement.scrollLeft += el?.clientWidth;
  }

  goLeft(): void {
    const el = this.carouselSlides.nativeElement.firstChild as HTMLElement;
    this.carouselSlides.nativeElement.scrollLeft -= el?.clientWidth;
  }

}
