import {UiThemes} from '../../interfaces/ui.interfaces';
import {SpacingProperties} from '../flex-child/flex-child.interfaces';

export enum JustifyContent {
  FlexStart = 'flex-start',
  FlexEnd = 'flex-end',
  Center = 'center',
  SpaceAround = 'space-around',
  SpaceBetween = 'space-between',
  SpaceEvenly = 'space-evenly',
}

export interface FlexContainerProps {
  borderBottom: boolean;
  borderTop: boolean;
  borderedItems: boolean;
  justifyContent: JustifyContent;
  padding: SpacingProperties | string;
  theme: UiThemes;
}

