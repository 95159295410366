import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { ManageUserService } from './manage-user.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [],
  exports: [],
  providers: [ManageUserService],
  entryComponents: [],
})
export class ManageUserModule { }
