import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { NotificationsService } from 'app/shared/notifications/notifications.service';
import { JustifyContent } from 'app/shared/flex-container/flex-container.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { environment } from 'environments/environment';
import { ActingAs, MaintenanceMode } from 'app/interfaces/auth.interfaces';
import { selectorActingAs } from 'app/auth/auth.reducer';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { ErrorService } from 'app/auth/error.service';
import { take } from 'rxjs/operators';
import { Subject } from 'rxjs';
declare const particlesJS: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  isMobile = false;
  resetMessage;
  resetPassword = false;
  loginError;
  loading = false;
  signUp: boolean;
  branding;
  errorSubscription;
  logoUrl;
  context = environment.envName;
  siteUnderMaintenance: MaintenanceMode;
  actingAs: ActingAs;
  buttonTypes = ButtonTypes;
  prefilledEmail: string = null;
  passwordExpired: boolean = false;

  public IconTypes = IconTypes;
  public UiColors = UiColors;
  public UiThemes = UiThemes;
  public justifyContent = JustifyContent;

  private unsubscribe$ = new Subject<void>()

  constructor(
    public store: Store<any>,
    public router: Router,
    public authService: AuthService,
    public notificationsService: NotificationsService,
    private activatedRoute: ActivatedRoute,
    private errorService: ErrorService
  ) {
    this.store.select(selectorActingAs).subscribe((next: ActingAs) => {
      if (next) {
        this.actingAs = next;
        this.branding = next.branding ? next.branding : {};
        if (!this.branding.description) {
          this.branding.description = next.id;
        }
      }
    });
    this.authService.siteUnderMaintenance.subscribe((underMaintenance: MaintenanceMode) => {
      this.siteUnderMaintenance = underMaintenance;
    });
    this.errorSubscription = this.authService.authError.subscribe((error) => {
      this.loginError = error;
      if (error) {
        this.loading = false;
      }
    });
    this.getBranding();
    this.isMobile = this.checkIfMobile();
    // If the route has register in the name sign up and fill in the email
    if (activatedRoute.snapshot.url[0] && activatedRoute.snapshot.url[0].path === 'register') {
      this.signUp = true;
      this.activatedRoute.queryParams.subscribe((params) => {
        if (params['email']) {
          this.prefilledEmail = params['email'];
        }
      });
    }
    if (activatedRoute.snapshot.url[0] && activatedRoute.snapshot.url[0].path === 'reset') {
      this.resetPassword = true;
    }
  }

  goToPlaystore(): void {
    window.location.href = 'https://play.google.com/store/apps/details?id=za.co.picup.consumer&hl=en_ZA';
  }

  goHome(): void {
    this.errorService.invalidOtp = false;
    this.router.navigate(['/']);
  }

  checkIfMobile(): boolean {
    const userAgent = navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(userAgent)
      ? true
      : false;
    // If we decide doing this by size is better:
    // return window.outerWidth < 900 ? true : false;
  }

  getBranding(): void {
    const localAuth = JSON.parse(localStorage.getItem('state'));
    this.logoUrl = localAuth?.auth?.actingAs?.branding?.logo_url;
  }

  ngOnDestroy(): void {
    this.errorSubscription.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    if (!this.isMobile) {
      particlesJS.load('particles-js', 'assets/particles.json', function () { });
    }

    this.errorService.isExpiredPassword$.subscribe(value => {
      this.passwordExpired = value;
    });
  }

  resendOtpLogin(loginData: LoginInterface): void {
    this.authService.loginWithEmailAndPassword(loginData.email, loginData.password)
      .pipe(take(1))
      .subscribe(
        {
          next: () => {
            this.notificationsService.publish({
              message: 'OTP resent please check your phone or email for the new code',
              type: 'success'
            })
          },
          complete: () => {
            this.errorService.invalidOtp = false;
          }
        });
  }

  attemptLogin(loginData: LoginInterface): void {
    this.authService.loginStatus = true;
    if(loginData.otp){
      this.authService.loginWithEmailPasswordAndOTP(loginData.email, loginData.password, loginData.otp.trim())
        .pipe(take(1))
        .subscribe(
          { next: value => {
            this.authService.SetUserDataWithAccessToken(value);
          }});
    } else {
      this.authService.loginWithEmailAndPassword(loginData.email, loginData.password)
        .pipe(take(1))
        .subscribe(
          {
            next: (value: any) => {
              if(value.Requires2Fa){
                this.authService.oneTimePinRequired = true;
                this.notificationsService.publish({
                  message: 'A new OTP has been sent to your email address',
                  type: 'success'
                })
              } else {
                // move to login
                this.authService.SetUserDataWithAccessToken(value);
              }
            },
            complete: () => {
              this.resetMessage = null;
              this.authService.loginStatus = false;
            }
          }
        )
    }
  }

  forgotPassword(): void {
    this.resetPassword = true;
  }

  returnToLogin(): void {
    this.resetMessage = null;
    this.resetPassword = false;
  }

  forgotPasswordReset(email: string): void {
    this.authService.passwordResetLoading = true;
    this.loginError = null;
    this.authService
      .sendPasswordResetEmail(email)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.notificationsService.publish({
            message: `${this.passwordExpired ? 'Your password has expired ': ''}A password reset email has been sent to your email address`,
            type: 'success'
          })
          this.resetMessage = 'A password reset link has been sent to your email address';
        }, error: (error) => {
          this.loginError = error.message;
          this.resetMessage = 'Failed to send the reset link, please try again in a few minutes';
        },
        complete: () => {
          this.errorService.invalidPassword = false;
          this.authService.passwordResetLoading = false;
        }})
  }

  signUpEvent(data: signUpInterface): void {
    this.authService.creatingAccount = true;
    this.authService
      .signUp2(data)
      .pipe(take(1))
      .subscribe(() => {
        this.notificationsService.publish({ type: 'success', message: 'Thank you for registering, please try and login after a few minutes'});
        this.signUp = false;
      },(error) => {
        this.loginError = error.response?.data.message;
      },() => {
        this.loading = false;
        this.authService.creatingAccount = false;
      });
  }
}

export interface LoginInterface {
  email: string,
  password: string,
  otp?: string
}

export interface signUpInterface{
  name: string,
  phone: string,
  password: string,
  email: string
}
