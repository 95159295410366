import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneValidation',
  pure: true,
})
export class PhoneValidationPipe implements PipeTransform {
  public pattern: RegExp = new RegExp(/^([+0-9]{2,4})?[0-9]{10}$/);
  transform(phoneNumber: string): boolean {
    return this.pattern.test(phoneNumber?.replace(/\s/g, ''));
  }
}
