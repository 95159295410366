import { Component, OnInit } from '@angular/core';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { GridJustifyItems } from 'app/shared/grid-container/grid-container.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { ModalAction, ModalSizes } from 'app/shared/modals/modal.interfaces';
import { ModalActionsEnum } from 'app/shared/shared.interfaces';
import moment from 'moment';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { FleetAllocationEnum } from '../new-trips.interfaces';

export interface ActivationDialogResult {
  scheduled_date: string;
  fleet_allocation: string;
}

export interface ActivationDialogData {
  trip_fleet: string;
  is_admin: boolean;
  currentDate: Date;
}

@Component({
  selector: 'app-new-trips-activation-dialog',
  templateUrl: './new-trips-activation-dialog.component.html',
  styleUrls: ['./new-trips-activation-dialog.component.scss'],
})
export class NewTripsActivationDialogComponent
  extends SimpleModalComponent<ActivationDialogData, ActivationDialogResult>
  implements OnInit {
  public ModalSizes = ModalSizes;
  public GridJustifyItems = GridJustifyItems;
  public IconTypes = IconTypes;
  public uiColors = UiColors;
  public UiThemes = UiThemes;
  public ButtonTypes = ButtonTypes;
  public actions: ModalAction[] = [
    { name: ModalActionsEnum.Cancel, title: ModalActionsEnum.Cancel },
    { name: ModalActionsEnum.Schedule, title: ModalActionsEnum.Schedule, isPrimary: true },
    { name: ModalActionsEnum.ActivateNow, title: ModalActionsEnum.ActivateNow, isPrimary: true },
  ];
  currentDate: Date;
  time: string;
  title: string = 'Edit Activation Time';
  fleet_allocation: string;
  fleet_types: string[] = [];
  trip_fleet: string;
  is_admin: boolean;
  startingTime: number[] = [];

  Icons = {
    Colors: UiColors,
    Types: IconTypes,
  };

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.startingTime[0] = this.currentDate.getHours() + this.currentDate.getMinutes() / 60;
    this.fleet_allocation = this.trip_fleet;
    this.setupFleets();
  }

  setupFleets(): void {
    if (this.is_admin) {
      this.fleet_types = [FleetAllocationEnum.Contractor, FleetAllocationEnum.Picup, FleetAllocationEnum.PicupPlus];
    } else {
      switch (this.trip_fleet) {
        case FleetAllocationEnum.Contractor: {
          this.fleet_types = [FleetAllocationEnum.Contractor, FleetAllocationEnum.Picup];
          break;
        }
        case FleetAllocationEnum.Picup: {
          this.fleet_types = [FleetAllocationEnum.Picup];
          this.fleet_allocation = FleetAllocationEnum.Picup;
          break;
        }
        case FleetAllocationEnum.PicupPlus: {
          this.fleet_types = [FleetAllocationEnum.PicupPlus];
          this.fleet_allocation = FleetAllocationEnum.PicupPlus;
          break;
        }
      }
    }
  }

  dateChanged(date: Date): void {
    this.currentDate = date;
  }

  timeChanged(time: number): void {
    this.time = moment.utc(time * 3600000).format('HH:mm:ss');
  }

  handleAction($event: string): void {
    switch ($event) {
      case ModalActionsEnum.Cancel: {
        this.result = null;
        this.close();
        break;
      }
      case ModalActionsEnum.Schedule: {
        const formattedDate = moment(this.currentDate).format('YYYY-MM-DD');
        const scheduled_date = moment(`${formattedDate} ${this.time}`, 'YYYY-MM-DD HH:mm:ss').format();
        this.result = { fleet_allocation: this.fleet_allocation, scheduled_date };
        this.close();
        break;
      }
      case ModalActionsEnum.ActivateNow: {
        this.result = {
          fleet_allocation: this.fleet_allocation,
          scheduled_date: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        };
        this.close();
        break;
      }
    }
  }
}
