import { Component, OnInit } from '@angular/core';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { IconTypes } from '../icon/icon.interfaces';

@Component({
  selector: 'app-home-page-status',
  templateUrl: './home-page-status.component.html',
  styleUrls: ['./home-page-status.component.scss']
})
export class HomePageStatusComponent implements OnInit {
  public Icons = {
    Colors: UiColors,
    Types: IconTypes
  };
  data = [
    {
      'name': 'Current Alerts',
      'value': 2
    },
    {
      'name': 'Last Week',
      'value': 6,
    }
  ];
  public UiThemes = UiThemes;

  constructor() { }

  ngOnInit() {
  }

}
