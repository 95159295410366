import { Component, OnInit, OnDestroy } from '@angular/core';
import { selectorActingAs, selectorUser, selectorUserPermissions, selectorWarehouses } from '../../../auth/auth.reducer';
import { Store } from '@ngrx/store';
import { SimpleModalService } from 'ngx-simple-modal';
import { ManageBucketService } from '../manage-bucket.service';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { DatePickerModalComponent } from '../../../shared/modals/date-picker-modal.component';
import { dispatchedCountFilter } from './filters';

import { Bucket, BucketsPayload } from '../buckets.interfaces';
import { AuthService } from 'app/auth/auth.service';
import { BucketService } from '../bucket.service';
import * as moment from 'moment';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { ActingAs, Warehouse } from 'app/interfaces/auth.interfaces';
import { selectorBucketFilters } from '../manage-bucket.reducer';
import { setBucketFilters } from '../manage-bucket.actions';
import { environment } from 'environments/environment';
import { AvailableBucketsComponent } from 'app/shared/buckets/available-buckets/available-buckets.component';
import { AvailableBucketsResultEnum } from 'app/shared/shared.interfaces';
import { CreateBucketModalComponent } from 'app/shared/buckets/create-bucket-modal/create-bucket-modal.component';
import { ModifyBucketOrderComponent } from '../modify-bucket-order/modify-bucket-order.component';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnDestroy, OnInit {
  activeTypeFilter = new BehaviorSubject(null);
  activeLimitFilter = new BehaviorSubject(100);
  activeWarehouseFilter = new BehaviorSubject<Warehouse>(null);
  activeDateFilter = new BehaviorSubject(null);
  filtered_buckets: Observable<any[]>;
  historic_filtered_buckets: Observable<any[]>;
  stagedBuckets;
  allBuckets;
  all_count: number;
  closed_count: number;
  dispatched_buckets$: Observable<Bucket[]>;
  searchString: string;
  IconTypes = IconTypes;
  UiColors = UiColors;
  buttonTypes = ButtonTypes;
  actingAs: ActingAs;
  warehouses: Warehouse[];
  userPermissions;
  available_warehouses: Warehouse[];
  environment = environment;
  loadedStaged = false;
  pageNumber: number = 1

  public ngDestroyed$ = new Subject();

  ENTERPRISE_BUCKETS = 'enterprise-buckets';

  firstLoad = true;

  storeSubscription;

  active_buckets: Observable<[]> = this.bucketService.buckets$;
  open_buckets$: Observable<[]> = this.bucketService.openBuckets$;
  deleted_buckets$: Observable<Bucket[]> = this.bucketService.deletedBuckets$;

  constructor(
    private bucketService: BucketService,
    public simpleModalService: SimpleModalService,
    public manageBucketService: ManageBucketService,
    public store: Store<any>,
    public authService: AuthService,
    public router: Router
  ) {
    this.store.select(selectorActingAs).subscribe((next) => {
      this.actingAs = next;
    });

    this.bucketService.stagedBuckets.pipe(takeUntil(this.ngDestroyed$)).subscribe((stagedBuckets) => {
      if (stagedBuckets) {
        this.stagedBuckets = stagedBuckets;
        this.firstLoad = false;
      }
    });

    this.store.select(selectorUserPermissions).subscribe((next) => {
      this.userPermissions = next;
    });
    this.store.select(selectorWarehouses).subscribe((next) => (this.warehouses = next));
    this.store.select(selectorBucketFilters).subscribe((filters) => {
      this.activeWarehouseFilter.next(filters.warehouse);
      this.activeDateFilter.next(filters.date);
      this.activeTypeFilter.next(filters.type);
      this.activeLimitFilter.next(filters.limit);
    });
  }

  ngOnInit(): void {
    this.stagedBuckets = undefined;
    this.allBuckets = undefined;
    this.historic_filtered_buckets = undefined;

    this.all_count = undefined;
    if (this.userPermissions?.warehouses?.length && !this.userPermissions?.modules?.includes('super-admin')) {
      this.available_warehouses = this.warehouses.filter((warehouse: Warehouse) =>
        this.userPermissions.warehouses.includes(warehouse.id)
      );
    } else {
      this.available_warehouses = this.warehouses;
    }
    if (this.activeTypeFilter.getValue() === 'all') {
      this.setTypeFilter('all');
    }
    if (this.activeTypeFilter.getValue() === 'staged') {
      this.setTypeFilter('staged');
    }
    this.dispatched_buckets$ = this.dispatchedCounts(this.active_buckets);
  }

  dispatchedCounts(filtered_buckets: Observable<Bucket[]>): Observable<Bucket[]> {
    return dispatchedCountFilter(filtered_buckets);
  }

  ngOnDestroy(): void {
    this.all_count = undefined;
    this.historic_filtered_buckets = undefined;
  }

  setTypeFilter(value: string): void {
    const payload: BucketsPayload = {
      warehouse: this.activeWarehouseFilter.getValue(),
      date: this.activeDateFilter.getValue(),
      type: value,
      limit: this.activeLimitFilter.getValue()
    };

    if (value === 'staged') {
      this.bucketService.getStagedBucketsForBusiness(this.actingAs.id, payload, this.pageNumber).then(() => (this.loadedStaged = true));
    }
    if (value === 'all') {
      payload.limit = null;
    }
    this.store.dispatch(new setBucketFilters(payload));
  }

  setWarehouseFilter(warehouse: Warehouse): void {
    const payload = {
      warehouse: this.activeWarehouseFilter.getValue(),
      date: this.activeDateFilter.getValue(),
      type: this.activeTypeFilter.getValue(),
      limit: this.activeLimitFilter.getValue(),
    };
    if (!warehouse) {
      payload.warehouse = null;
      this.store.dispatch(new setBucketFilters(payload));
    } else {
      payload.warehouse = warehouse;
      this.store.dispatch(new setBucketFilters(payload));
    }
    if (payload.type === 'staged') {
      this.bucketService.getStagedBucketsForBusiness(this.actingAs.id, payload, this.pageNumber).then(() => (this.loadedStaged = true));
    }
  }
  setLimitFilter(limit: number): void {
    const payload = {
      warehouse: this.activeWarehouseFilter.getValue(),
      date: this.activeDateFilter.getValue(),
      type: this.activeTypeFilter.getValue(),
      limit: limit,
    };
    this.store.dispatch(new setBucketFilters(payload));
    if (payload.type === 'staged') {
      this.bucketService.getStagedBucketsForBusiness(this.actingAs.id, payload, this.pageNumber).then(() => (this.loadedStaged = true));
    }
  }

  public trackByFunction(index, item) {
    if (!item) {
      return null;
    }
    return index.id;
  }

  clearSearch(): void {
    if (document.getElementById('input')) {
      document.getElementById('input').focus();
    }
    this.searchString = null;
  }

  selectStagedOrders(warehouse_id: string): void {
    this.router.navigate(['dashboard/staged-orders/' + warehouse_id]);
  }

  openDatePickerModal(): void {
    this.simpleModalService.addModal(DatePickerModalComponent).subscribe((date) => {
      if (!date) {
        return;
      }
      const payload = {
        warehouse: this.activeWarehouseFilter.getValue(),
        date: moment(date).format('YYYY-MM-DD'),
        type: this.activeTypeFilter.getValue(),
        limit: this.activeLimitFilter.getValue(),
      };
      this.store.dispatch(new setBucketFilters(payload));
    });
  }

  clearDateFilter(): void {
    const payload = {
      warehouse: this.activeWarehouseFilter.getValue(),
      date: null,
      type: this.activeTypeFilter.getValue(),
      limit: this.activeLimitFilter.getValue(),
    };
    this.store.dispatch(new setBucketFilters(payload));
  }

  createOrder(): void {
    let data: any = { business_id: this.actingAs.id };
    const warehouses = { warehouses: this.available_warehouses, business_id: this.actingAs.id };
    this.simpleModalService.addModal(AvailableBucketsComponent, data).subscribe((result) => {
      if (!result) {
        return;
      }
      switch (result) {
        case null:
        case undefined:
        case AvailableBucketsResultEnum.None:
          return;
        case AvailableBucketsResultEnum.Create:
          this.simpleModalService.addModal(CreateBucketModalComponent, warehouses).subscribe((create_result) => {
            if (!create_result) {
              return;
            }
            data = { ...data, availableBucketResult: result, bucket_details: create_result };
            this.simpleModalService.addModal(ModifyBucketOrderComponent, data);
          });
          break;
        default:
          data = { ...data, bucket_id: result?.bucket_id, warehouse_id: result?.warehouse_id };
          this.simpleModalService.addModal(ModifyBucketOrderComponent, data);
          break;
      }
    });
  }
}
