import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FSAdminActiveLastMileTableMap, FSAdminActiveLastMileTableColumns } from 'app/admin/admin.contants';
import { PostDispatchService } from 'app/dashboard/post-dispatch/post-dispatch.service';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { LastMile, CollapsibleTableTypeEnum, PaginateEnum, Paginate } from 'app/shared/shared.interfaces';
import { TableAction, SortEvent } from 'app/shared/table/table.interfaces';
import { Observable, Subscription, from } from 'rxjs';

@Component({
  selector: 'app-tracking-pending',
  templateUrl: './tracking-pending.component.html',
  styleUrls: ['./tracking-pending.component.scss']
})
export class TrackingPendingComponent implements OnInit, OnDestroy {
  @Input() activeDriverPrimaryActions: TableAction[] = [];

  Icons = {
    Colors: UiColors,
    Types: IconTypes,
  };
  pendingRoutes: Observable<LastMile[]>;
  pendingRoutesAmount: number = 10;
  pendingRoutesSubscription: Subscription;
  pendingRoutesTotal: number;

  fsActiveLastMileTableMap = FSAdminActiveLastMileTableMap;
  fsActiveLastMileTableColumns = FSAdminActiveLastMileTableColumns;

  CollapsibleTableTypeEnum = CollapsibleTableTypeEnum;

  constructor(private postDispatchService: PostDispatchService) {}

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy(): void {
    this.pendingRoutesSubscription.unsubscribe();
  }

  getData(): void {
    this.handlePendingLastMiles({ Amount: this.pendingRoutesAmount, Direction: PaginateEnum.None });
  }

  handlePendingLastMiles($event: Paginate): void {
    this.pendingRoutesAmount = $event.Amount;
    this.pendingRoutesSubscription?.unsubscribe();
    this.pendingRoutesSubscription = this.postDispatchService
      .getPendingFirestoreLastmiles(false)
      .subscribe((response) => {
        this.pendingRoutes = from([response]);
        this.pendingRoutesTotal = response.length;
      });
  }

  handleLastMileSortChange($event: SortEvent): void {
    this.postDispatchService.lastMileOrderBy = $event;
    this.handlePendingLastMiles({ Amount: this.pendingRoutesAmount, Direction: PaginateEnum.None });
  }
}
