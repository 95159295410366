<app-section class="App Details Summary">
    <heading class="white-text" [compact]="true">SUMMARY</heading>
    <app-flex-container [borderBottom]="true" [theme]="UiThemes.Dark" class="white-text">
      <!-- TODO: Parcel statuses -->
  
      <app-stats-icon
        [icon]="icons.types.Marker"
        [iconColor]="icons.colors.Blue"
        [iconSizeRem]="2"
        [label]="'Waypoints'"
        [data]="summaryData?.waypoint?.waypoints"
      ></app-stats-icon>
  
      <app-stats-icon
        [icon]="icons.types.Profile"
        [iconColor]="icons.colors.Blue"
        [iconSizeRem]="2"
        [label]="'Contacts'"
        [data]="summaryData?.waypoint?.contacts"
      ></app-stats-icon>
  
      <app-stats-icon
        [icon]="icons.types.Parcel"
        [iconColor]="icons.colors.Blue"
        [iconSizeRem]="2"
        [label]="'Parcels'"
        [data]="summaryData?.parcel?.total?.toString()"
      ></app-stats-icon>
  
      <div class="separator"></div>
  
      <app-stats-icon
        [icon]="icons.types.Parcel"
        [iconColor]="icons.colors.Blue"
        [iconSizeRem]="2"
        [label]="'Total'"
        [data]="summaryData?.parcel?.total?.toString()"
      ></app-stats-icon>
  
      <app-stats-icon
        [icon]="icons.types.Parcel"
        [iconColor]="icons.colors.Blue"
        [iconSizeRem]="2"
        [label]="'Collected'"
        [data]="summaryData?.parcel?.collected?.toString()"
      ></app-stats-icon>
      <app-stats-icon
        [icon]="icons.types.Parcel"
        [iconColor]="icons.colors.Blue"
        [iconSizeRem]="2"
        [label]="'Delivered'"
        [data]="summaryData?.parcel?.delivered?.toString()"
      ></app-stats-icon>
      <app-stats-icon
        [icon]="icons.types.Parcel"
        [iconColor]="icons.colors.Yellow"
        [iconSizeRem]="2"
        [label]="'Returned'"
        [data]="summaryData?.parcel?.returned?.toString()"
      ></app-stats-icon>
      <app-stats-icon
        [icon]="icons.types.Cross"
        [iconColor]="icons.colors.Red"
        [iconSizeRem]="2"
        [label]="'Failed'"
        [data]="summaryData?.parcel?.failed.toString()"
      ></app-stats-icon>
    </app-flex-container>
    <app-flex-container [borderBottom]="true" [theme]="UiThemes.Dark" class="white-text">
      <app-stats-icon
      [icon]="icons.types.Users"
      [iconColor]="summaryData?.username ? icons.colors.Blue : icons.colors.Red "
      [iconSizeRem]="2"
      [label]="'Assigned User'"
      [data]="summaryData?.username ?? 'Unassigned'">
    </app-stats-icon>
      <app-stats-icon
        [icon]="vehicleTypes.get(summaryData?.vehicleType)"
        [iconColor]="icons.colors.Blue"
        [iconSizeRem]="2"
        [label]="'Vehicle'"
        [data]="vehicleNames.get(summaryData?.vehicleType)">
      </app-stats-icon>
      <ng-container >
        <app-stats-icon 
        [icon]="icons.types.Trip"
        [iconColor]="icons.colors.Blue"
        [iconSizeRem]="2"
        [label]="'Distance'"
        [data]="(summaryData?.quote?.distanceInMeters/1000| number: '1.2-2') + ' km'"
      ></app-stats-icon>
  
      <app-stats-icon
      [icon]="icons.types.DebitCard"
      [iconColor]="icons.colors.Yellow"
      [iconSizeRem]="2"
      [label]="'Earnings'"
      [data]="summaryData?.quote?.earningsAmount | currency: environment?.currency_symbol"
      ></app-stats-icon>
    </ng-container>
  </app-flex-container>
  
  </app-section>
  