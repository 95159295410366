import {Component, Input} from '@angular/core';
import {TableRow, TableColumn} from '../table.interfaces';
import {hideCell, formatCellValue} from '../table.helpers';
import {DecimalPipe} from '@angular/common';

@Component({
  selector: '[app-table-summary-row]',
  templateUrl: './table-summary-row.component.html',
  styleUrls: ['./table-summary-row.component.scss'],
  providers: [DecimalPipe]
})
export class TableSummaryRowComponent {
  @Input() showRowHeader = true;
  @Input() showRowActions = true;
  @Input() rowTitle: string;
  @Input() summaryData: TableRow;
  @Input() columns: TableColumn[];

  _hideCell = hideCell;

  constructor(private decimalPipe: DecimalPipe) {}

  _formatCellValue(val, pipeArgs, prefix, postfix) {
    if (!isNaN(val)) {
      return formatCellValue(this.decimalPipe.transform(val, pipeArgs) as string, prefix, postfix);
    }

    return formatCellValue(val, prefix, postfix);
  }
}
