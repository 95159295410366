<div class="pgs">
    <div class="main-packages" [class.bgcolor]="backgroundColor === 'white'">
      <a routerLink="{{ routerLink }}"
        ><div class="packages">
          <div class="top-package1"><img src="{{ firstImgSrc }}" alt="" /></div>
          <div class="bottom-package">
            <h4>{{ firstTitle }}</h4>
            <p>
              {{ firstSubtitle }}
            </p>
            <img src="{{ firstImgSrc2 }}" alt="" />
          </div></div
      ></a>
  
      <a routerLink="{{ routerLink2 }}"
        ><div class="packages2">
          <div class="top-package1"><img src="{{ secondImgSrc }}" alt="" /></div>
          <div class="bottom-package">
            <h4>{{ secondTitle }}</h4>
            <p>
                {{ secondSubtitle }}
            </p>
            <img src="{{ secondImgSrc2 }}" alt="" />
          </div></div
      ></a>
    <ng-container *ngIf="routerLink3">
        <a routerLink="{{ routerLink3 }}"
          ><div class="packages3">
            <div class="top-package1"><img src="{{ thirdImgSrc }}" alt="" /></div>
            <div class="bottom-package">
              <h4>{{ thirdTitle }}</h4>
              <p>
                  {{ thirdSubtitle }}
              </p>
              <img src="{{ thirdImgSrc2 }}" alt="" />
            </div></div
        ></a>
      </ng-container>
    </div>
    <ng-content></ng-content>
  </div>