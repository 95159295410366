import { Driver } from 'app/billing/financials.interface';

export enum AccessManagementModules {
  dashboard = 'Dashboard',
  planning = 'Planning',
  courier = 'Courier',
  financials = 'Financials',
  v2 = 'V2'
}

export enum AccessLevelName{
  dispatcher = 'dispatcher',
  legacy = 'legacy',
  tracker = 'tracker'
}
export interface GetAccountResponse {
  User: AccessManagementUser;
  DefaultBusiness: AccessManagementDefaultBusiness;
  Businesses: AccessManagementBusiness;
}

export interface AccessManagementUser {
  Id: string;
  Name: string;
  Surname: string;
  FullName: string;
  Phone: string;
  Email: string;
}

export interface AccessManagementDefaultBusiness {
  Id: string;
  Name: string;
  Modules: string[];
}

export interface AccessManagementBusiness {
  Id: string;
  Name: string;
}

export interface AccessManagementModule {
  ModuleId: number;
  ModuleName: string;
  AccessLevelId: number;
  AccessLevelName: string;
}

export interface AccessManagementHub {
  HubId: string;
  HubName: string;
  AccessLevelId: number;
  AccessLevelName: string;
}

export interface LoginResponse {
  AccessToken: string;
  Businesses: any[];
  DefaultBusiness: any;
  User: AccessManagementUser;
}

export interface GetAccessManagementBusinessResponse {
  businesses: {
    nodes: {
      id: number;
      uid: string;
      name: string;
    }[]
  }
}

export interface GetAccessManagementVendorsResponse {
  vendors: {
    nodes: {
      id: number;
      uid: string;
      name: string;
    }[]
  }
}

export interface GetAccessManagementUserVendorsResponse {
  vendorUsers: {
    nodes: {
      vendor: {
        id: number;
        uid: string;
        name: string;
      }
    }[]
  }
}

export interface GetAccessManagementVendorDriversResponse {
  vendors: {
    nodes: {
      drivers: Driver[];
    }[]
  }
}

export interface GetAccessManagementDriverByUidResponse {
  drivers: Nodes<Driver[]>;
}

export interface Nodes<T> {
  nodes: T;
}
