import { Injectable } from '@angular/core';
import axios from 'app/api/axios';
import { NotificationsService } from 'app/shared/notifications/notifications.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import moment from 'moment';
import { DashboardFilters, DeliveryStatsResponse } from '../home.interface';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  private dashboardFilters: BehaviorSubject<DashboardFilters> = new BehaviorSubject({
    dateFrom: moment().subtract(3, 'd').toDate(),
    dateTo: new Date(),
    warehouseId: '',
  });
  buckets$: Observable<any>;

  constructor(private notificationService: NotificationsService, private firestore: AngularFirestore) {}

  getDeliveryStats(
    businessIds: string,
    warehouseIds: string[] | string,
    dateFrom: Date,
    dateTo: Date,
    fleet: string[]
  ): Promise<DeliveryStatsResponse> {
    if (!dateFrom || !dateTo) {
      return;
    }
    const date_from = moment(dateFrom).format('YYYY-MM-DD hh:mm:ss A Z');
    const date_to = moment(dateTo).format('YYYY-MM-DD hh:mm:ss A Z');
    return axios({
      method: 'POST',
      url: 'generic/cqrs/get-delivery-statistics',
      data: {
        business_id: businessIds,
        warehouse_ids: warehouseIds,
        date_from,
        date_to,
        fleet,
      },
    })
      .then((response) => response?.data)
      .catch((error) => this.notificationService.publish({ type: 'error', message: error.reponse?.data.message }));
  }

  getFleetStats(businessIds: string, warehouseIds: string[] | string, dateFrom: Date, dateTo: Date): Promise<any> {
    if (!dateFrom || !dateTo) {
      return;
    }
    const date_from = moment(dateFrom).format('YYYY-MM-DD hh:mm:ss A Z');
    const date_to = moment(dateTo).format('YYYY-MM-DD hh:mm:ss A Z');
    return axios({
      method: 'POST',
      url: 'generic/cqrs/get-fleet-statistics',
      data: {
        business_id: businessIds,
        warehouse_ids: warehouseIds,
        date_from,
        date_to,
      },
    })
      .then((response) => response?.data)
      .catch((error) => this.notificationService.publish({ type: 'error', message: error.reponse?.data.message }));
  }

  getOrderParcelStats(
    businessIds: string,
    warehouseIds: string[] | string,
    dateFrom: Date,
    dateTo: Date,
    fleet: string[]
  ): Promise<any> {
    if (!dateFrom || !dateTo) {
      return;
    }
    const date_from = moment(dateFrom).format('YYYY-MM-DD hh:mm:ss A Z');
    const date_to = moment(dateTo).format('YYYY-MM-DD hh:mm:ss A Z');
    return axios({
      method: 'POST',
      url: 'generic/cqrs/get-order-parcel-statistics',
      data: {
        business_id: businessIds,
        warehouse_ids: warehouseIds,
        date_from,
        date_to,
        fleet,
      },
    })
      .then((response) => response?.data)
      .catch((error) => this.notificationService.publish({ type: 'error', message: error.reponse?.data.message }));
  }

  getBuckets(businessId: string): any {
    return this.firestore
      .collection('buckets', (ref) => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        query = query.where('IsDeleted', '==', false);
        query = query.where('business_id', '==', businessId);
        query = query.where('delivery_date', '>=', moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD'));
        query = query.where('delivery_date', '<=', moment().format('YYYY-MM-DD'));
        query = query.orderBy('delivery_date', 'desc');
        query = query.limit(18)

        return query;
      })
      .get();
  }

  setDashboardFilters(filters: DashboardFilters): void {
    this.dashboardFilters.next(filters);
  }

  get dashboardFilters$(): Observable<DashboardFilters> {
    return new Observable((f) => {
      this.dashboardFilters.subscribe(f);
    });
  }
}
