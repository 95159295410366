<div
  class="heading"
  [ngClass]="{ compact: compact, 'show-toggle': showToggle, border: border }"
  (click)="handleClick()"
>
  <div *ngIf="icon || highlight !== undefined" class="heading-highlight" [ngClass]="color">
    <app-icon *ngIf="icon" [icon]="icon" [color]="color" [size]="2.1"> </app-icon>
    <span *ngIf="highlight !== undefined" class="heading-highlight-value">
      {{ highlight }}
    </span>
  </div>
  <div class="heading-content">
    <div class="heading-text" [ngStyle]="{ 'padding-right': showToggle && 0 }">
      <ng-content></ng-content>
    </div>
    <div *ngIf="showToggle" class="heading-toggle">
      <app-icon *ngIf="!toggleActive" [icon]="ui.icons.Down" [size]="0.7" [color]="ui.colors.White"></app-icon>
      <app-icon *ngIf="toggleActive" [icon]="ui.icons.Up" [size]="0.7" [color]="ui.colors.White"></app-icon>
    </div>
  </div>
</div>
