<app-modal
  [open]="true"
  [actions]="actions"
  [highlightPrimaryAction]="true"
  [size]="ModalSizes.Medium"
  (handleAction)="handleAction($event)"
>
  <heading [icon]="IconTypes.Alert" [color]="UiColors.Orange">
    <h4 class="heading-title">{{ title }}</h4>
  </heading>

  <div class="modal-body">
    <app-flex-container [flexDirection]="'column'" [flexWrap]="'wrap'" [padding]="'2rem'" [theme]="UiThemes.Light">
      <p class="description">{{ message }}</p>
      <p class="key">{{ key }}</p>

      <input-field
        [type]="'text'"
        (valueChanged)="user_input = $event"
        [autoComplete]="'off'"
        [value]="user_input"
        [required]="true"
      ></input-field>
    </app-flex-container>
  </div>
</app-modal>
