import { Component, Input, OnInit } from '@angular/core';
import { DashboardOrderStats } from 'app/dashboard/home/home.interface';

@Component({
  selector: 'app-order-statistics',
  templateUrl: './order-statistics.component.html',
  styleUrls: ['./order-statistics.component.scss']
})
export class OrderStatisticsComponent implements OnInit {

  @Input() parcelStats: DashboardOrderStats;

  constructor() { }

  ngOnInit(): void {
  }

}
