import { Component, Input, OnInit } from '@angular/core';
import { ShiftDate } from '../../models';
import { UploadOrdersService } from 'app/dashboard/upload-orders/upload-orders.service';
import { NotificationsService } from '../notifications/notifications.service';
import * as moment from 'moment';
import { ButtonTypes } from '../buttons/basic-button.component';

@Component({
  selector: 'date-time',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.scss'],
})
export class DateTimeComponent implements OnInit {
  date;
  end_date;
  @Input() shiftDates: Array<ShiftDate>;
  @Input() toggleHighlight = false;
  @Input() CSVUploadDetails;
  @Input() warehouses;
  activeWarehouse;
  validated = false;
  errors;
  newBucket;
  activeWarehouseName;
  processing = false;
  shift_start;
  shift_end;
  buttonTypes = ButtonTypes;

  constructor(public uploadOrdersService: UploadOrdersService, private notificationsService: NotificationsService) {}

  // public availableRanges: Array<Array<number>> = [[9, 12], [13, 17]];

  setActiveWarehouse() {
    this.warehouses.forEach((warehouse) => {
      if (this.CSVUploadDetails.validation.bucket[0].warehouse_id === warehouse.id) {
        this.activeWarehouseName = warehouse.name;
      }
    });
  }

  changeDate(event) {
    this.CSVUploadDetails.validation.bucket[0].delivery_date = event;
    this.date = event;
    this.errors = null;
    this.validated = false;
  }

  changeShift(event) {
    this.CSVUploadDetails.validation.bucket[0].shift_start = moment.utc(event[0] * 3600000).format('HH:mm');
    this.CSVUploadDetails.validation.bucket[0].shift_end = moment.utc(event[1] * 3600000).format('HH:mm');
    this.errors = null;
    this.validated = false;
  }

  validateCSV() {
    this.errors = null;

    const processDetails = {
      filename: this.CSVUploadDetails.filename,
      delivery_date: moment(this.date).format('YYYY-MM-DD'),
      shift_start: this.CSVUploadDetails.validation.bucket[0].shift_start,
      shift_end: this.CSVUploadDetails.validation.bucket[0].shift_end,
      warehouse_id: this.CSVUploadDetails.validation.bucket[0].warehouse_id,
      warehouse_name: this.activeWarehouseName,
    };
    this.uploadOrdersService
      .validateCSV(processDetails)
      .then((response) => {
        this.CSVUploadDetails = response;
        if (response.error_number === 0) {
          this.validated = true;
          if (response.validation.bucket[0].bucket_id === -1) {
            this.newBucket = true;
          } else {
            this.newBucket = false;
          }
        } else {
          this.errors = response;
        }
      })
      .catch((error) => {
        this.errors = error.error_message;
      });
  }

  processCSV() {
    this.processing = true;

    const processDetails = {
      filename: this.CSVUploadDetails.filename,
      delivery_date: moment(this.date).format('YYYY-MM-DD'),
      shift_start: this.CSVUploadDetails.validation.bucket[0].shift_start,
      shift_end: this.CSVUploadDetails.validation.bucket[0].shift_end,
      warehouse_id: this.CSVUploadDetails.validation.bucket[0].warehouse_id,
    };
    this.uploadOrdersService.processCSV(processDetails).catch((error) => {
      this.notificationsService.publish({
        type: 'error',
        message: error.response?.data.message,
      });
      throw error;
    });
  }

  timeStringToFloat(time) {
    const hoursMinutes = time.split(/[.:]/);
    const hours = parseInt(hoursMinutes[0], 10);
    const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    return hours + minutes / 60;
  }

  ngOnInit() {
    this.setActiveWarehouse();

    if (this.CSVUploadDetails.validation.bucket[0].shift_start) {
      this.shift_start = this.timeStringToFloat(this.CSVUploadDetails.validation.bucket[0].shift_start);
    } else {
      this.shift_start = 9;
    }

    if (this.CSVUploadDetails.validation.bucket[0].shift_end) {
      this.shift_end = this.timeStringToFloat(this.CSVUploadDetails.validation.bucket[0].shift_end);
    } else {
      this.shift_end = 17;
    }

    if (this.CSVUploadDetails.validation.bucket[0].delivery_date) {
      this.date = this.CSVUploadDetails.validation.bucket[0].delivery_date;
    } else {
      this.date = moment().add(1, 'day').format();
    }

    if (this.CSVUploadDetails.validation.bucket[0].delivery_date_end) {
      this.end_date = this.CSVUploadDetails.validation.bucket[0].delivery_date_end;
    }
  }
}
