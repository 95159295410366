import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { DatePickerModalComponent } from 'app/shared/modals/date-picker-modal.component';
import {
  DisplayPipes,
  TableAction,
  TableActionTaken,
  TableColumn,
  TableDataMap,
} from 'app/shared/table/table.interfaces';
import moment from 'moment';
import { SimpleModalService } from 'ngx-simple-modal';

@Component({
  selector: 'app-post-dispatch-today',
  templateUrl: './post-dispatch-today.component.html',
  styleUrls: ['./post-dispatch-today.component.scss'],
})
export class PostDispatchTodayComponent implements OnInit {
  @Input() todaySummaryData: any[];
  @Output() dateChangedEvent: EventEmitter<string> = new EventEmitter<string>();
  todaysDate: string;
  selectedDate: string;
  buttonTypes = ButtonTypes;

  Icons = {
    Colors: UiColors,
    Types: IconTypes,
  };

  lastMileProgressTableColumns: TableColumn[] = [
    { name: 'order_id', displayName: ' ', hidden: true },
    { name: 'customer_reference', displayName: 'Reference' },
    { name: 'warehouse', displayName: 'Warehouse', sortable: true },
    { name: 'progress', displayName: 'Progress', sortable: true, shouldCalculate: false },
    { name: 'assigned', displayName: 'Assigned', sortable: true, shouldCalculate: true },
    { name: 'collected', displayName: 'Collected', sortable: true, shouldCalculate: true },
    { name: 'failed_collection', displayName: 'Failed Collection', sortable: true, shouldCalculate: true },
    { name: 'delivered', displayName: 'Delivered', sortable: true, shouldCalculate: true },
    { name: 'failed_delivery', displayName: 'Failed Delivery', sortable: true, shouldCalculate: true },
    { name: 'returned', displayName: 'Returned', sortable: true, shouldCalculate: true },
    { name: 'cancelled', displayName: 'Cancelled', sortable: true, shouldCalculate: true },
    { name: 'failed_return', displayName: 'Failed Return', sortable: true, shouldCalculate: true },
    { name: 'updated_at', displayName: 'Last Updated', sortable: true },
    { name: 'pending', displayName: 'Pending', sortable: true, shouldCalculate: true },
    {
      name: 'completed',
      displayName: 'Completed',
      sortable: true,
      shouldCalculate: true,
    },
    { name: 'driver_name', displayName: 'Driver', sortable: true, shouldCalculate: true },
    {
      name: 'final_collection_attempt_failed',
      displayName: 'Final Collection Failed',
      sortable: true,
      shouldCalculate: true,
    },
    { name: 'failed', displayName: 'Failed', sortable: true, shouldCalculate: true },
    { name: 'total', displayName: 'Total', sortable: true, shouldCalculate: true },
  ];

  lastMileProgressTableMap: TableDataMap = {
    cells: {
      order_id: {
        column: 'order_id',
        map: 'order_id',
      },
      customer_reference: {
        column: 'customer_reference',
        map: 'customer_reference',
      },
      warehouse: {
        column: 'warehouse',
        map: 'warehouse',
      },
      progress: {
        column: 'progress',
        map: 'progress',
        displayPipeArgs: DisplayPipes.decimalsNone,
        postfix: '%',
      },
      total: {
        column: 'total',
        map: 'total',
      },
      pending: {
        column: 'pending',
        map: 'pending',
      },
      completed: {
        column: 'completed',
        map: 'completed',
      },
      failed: {
        column: 'failed',
        map: 'failed',
      },
      assigned: {
        column: 'assigned',
        map: 'assigned',
      },
      collected: {
        column: 'collected',
        map: 'collected',
      },
      failed_collection: {
        column: 'failed_collection',
        map: 'failed_collection',
      },
      delivered: {
        column: 'delivered',
        map: 'delivered',
      },
      failed_delivery: {
        column: 'failed_delivery',
        map: 'failed_delivery',
      },
      returned: {
        column: 'returned',
        map: 'returned',
      },
      driver_name: {
        column: 'driver_name',
        map: 'driver_name',
      },
      final_collection_attempt_failed: {
        column: 'final_collection_attempt_failed',
        map: 'final_collection_attempt_failed',
      },
      cancelled: {
        column: 'cancelled',
        map: 'cancelled',
      },
      failed_return: {
        column: 'failed_return',
        map: 'failed_return',
      },
      updated_at: {
        column: 'updated_at',
        map: 'updated_at',
        displayPipeArgs: ['timeAgo'],
      },
    },
  };

  lastMileProgressPrimaryActions: TableAction[] = [
    { event: 'copyId', title: 'Copy ID', icon: IconTypes.Details, icon_color: UiColors.Grey8 },
    { event: 'viewTrip', title: 'View Trip', icon: IconTypes.Eye, icon_color: UiColors.Grey8 },
  ];

  constructor(private router: Router, private simpleModalService: SimpleModalService) {}

  ngOnInit(): void {
    this.todaysDate = moment().format('YYYY-MM-DD');
    this.selectedDate = moment().format('YYYY-MM-DD');
  }

  handleLastMileProgressPrimaryActions($event: TableActionTaken): void {
    const tripId = $event.rows[0].cells.find((cell) => cell.column === 'order_id').value;
    const selBox = document.createElement('textarea');
    switch ($event.action.event) {
      case 'viewTrip':
        this.router.navigate(['dashboard/orders/trip/', tripId]);
        break;
      case 'copyId':
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = tripId as string;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        break;
    }
  }

  changeDate(event: Event): void {
    event.stopPropagation();
    this.simpleModalService.addModal(DatePickerModalComponent, { maxDate: new Date() }).subscribe((result: string) => {
      if (!result) {
        return;
      }
      this.selectedDate = moment(result).format('YYYY-MM-DD');
      this.dateChangedEvent.emit(result);
    });
  }
}
