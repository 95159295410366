import { Component, OnInit, OnDestroy } from '@angular/core';
import { selectorUser, selectorActingAs, authState } from '../../auth/auth.reducer';
import { Store } from '@ngrx/store';
import { AuthService } from '../../auth/auth.service';
import { ManageBusinessService } from './manage-business.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { ReplaySubject } from 'rxjs';
import { MapTypes } from '../../shared/map/map.interfaces';
import { UiColors, UiThemes } from '../../interfaces/ui.interfaces';
import { ButtonTypes } from '../../shared/buttons/basic-button.component';
import { IconTypes } from '../../shared/icon/icon.interfaces';
import { AddBusinessComponent } from './add-business/add-business.component';
import { SimpleModalService } from 'ngx-simple-modal';
import { SetEnterpriseComponent } from './set-enterprise/set-enterprise.component';
import { ConfirmInputModalComponent } from 'app/shared/modals/confirm-input-modal/confirm-input-modal.component';
import { NotificationsService } from 'app/shared/notifications/notifications.service';
import { AddCreditsComponent } from './add-credits/add-credits.component';
import { Router } from '@angular/router';
import { ActingAs, AuthUser, PaymentTerms, UserRoles } from 'app/interfaces/auth.interfaces';
import { environment } from 'environments/environment';
import { ManageBusinessModel, ManageBusinessSection } from './manage-business.interface';
import { PackageTypeEnum } from 'app/shared/shared.interfaces';
import { FilterItem } from 'app/planning/planning.interfaces';
import { PillInputDropdownItem } from 'app/shared/pill-input/pill-input.interface';
import { PhoneValidationPipe } from 'app/shared/pipes/phone-validation.pipe';
@Component({
  selector: 'app-manage-business',
  templateUrl: './manage-business.component.html',
  styleUrls: ['./manage-business.component.scss'],
})
export class ManageBusinessComponent implements OnInit, OnDestroy {
  public IconTypes = IconTypes;
  public buttonTypes = ButtonTypes;
  public UiThemes = UiThemes;
  uiColors = UiColors;

  business_model: ManageBusinessModel;
  business_details: ManageBusinessModel;
  api_error_response: string;
  warehouseConfig: boolean = false;
  loading: boolean = false;
  user: AuthUser;
  showIntegration: boolean;
  selectedSection: string;
  showDriversOnMap: boolean = false;
  contractDriverIds: string[] = [];
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  filterArgs: boolean = true;
  sectionIds: ManageBusinessSection[];
  actingAs: ActingAs;
  showCredits: boolean = false;
  countryCodes: PillInputDropdownItem[] = [];
  filteredCodes: PillInputDropdownItem[] = [];
  phoneNotValid: boolean = false;

  // Remove this when we can tell where a company is enterprise or not

  public MapTypes = MapTypes;

  constructor(
    private simpleModalService: SimpleModalService,
    public store: Store<authState>,
    public authService: AuthService,
    private manageBusinessService: ManageBusinessService,
    public db: AngularFireDatabase,
    private notificationsService: NotificationsService,
    private router: Router,
    private phoneValidationPipe: PhoneValidationPipe
  ) {
    this.store.select(selectorActingAs).subscribe((actingAs) => {
      if (actingAs) {
        this.actingAs = actingAs;

        if (actingAs.payment_terms === PaymentTerms.Prepaid) {
          this.showCredits = true;
        }
        this.showIntegration = this.checkShowIntegration(this.actingAs.package);
        if (this.actingAs.package) {
          this.store.select(selectorUser).subscribe((user) => {
            this.user = user;
          });
        }
        this.setSectionIds();
      }
    });
  }

  ngOnInit(): void {
    this.getBusinessDetails();
    this.countryCodes = this.setDefaultCountryCodes();
    this.filteredCodes = this.countryCodes;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  setDefaultCountryCodes(): PillInputDropdownItem[] {
    return environment.countryCodes.map((data) => {
      return {
        Id: data,
        Name: data,
      };
    });
  }

  addNewCountryCode($event: FilterItem): void {
    if (this.business_model.supported_geocode_country_codes.indexOf($event.Name) === -1) {
      this.business_model.supported_geocode_country_codes = [
        ...this.business_model.supported_geocode_country_codes,
        $event.Name,
      ];
    }
    this.filterCountryCodes('');
  }

  clearCountryCodes(): void {
    this.business_model.supported_geocode_country_codes = [];
  }

  clearCountryCode($event: number): void {
    const clearedCountryCode = this.business_model.supported_geocode_country_codes[$event];
    this.business_model.supported_geocode_country_codes = [
      ...this.business_model.supported_geocode_country_codes.filter((x) => x !== clearedCountryCode),
    ];
  }

  filterCountryCodes(value: string): void {
    this.filteredCodes = this.countryCodes.filter((x) => x.Name.toLowerCase().includes(value.toLowerCase()));
  }

  canAccessLocations(): boolean {
    if (
      this.user.is_admin ||
      this.actingAs.roles.includes(UserRoles.SuperAdministrator) ||
      this.actingAs.roles.includes(UserRoles.Administrator)
    ) {
      return true;
    }
    return false;
  }

  cancel(): void {
    this.business_model = this.business_details;
  }

  updateBusiness(): void {
    if (!this.phoneValidationPipe.transform(this.business_model.phone)) {
      this.phoneNotValid = true;
      return;
    }
    this.manageBusinessService.updateBusiness(this.business_model).then(() => this.getBusinessDetails());
    this.phoneNotValid = false;
  }

  changePackage(): void {
    const modal_data = {
      billing_package: this.actingAs.package,
    };
    this.simpleModalService.addModal(SetEnterpriseComponent, modal_data);
  }

  addCredits(): void {
    this.simpleModalService.addModal(AddCreditsComponent).subscribe((result) => {
      if (!result) {
        return;
      }
      this.manageBusinessService.addCreditsToBusiness(result);
    });
  }

  viewLedger(): void {
    this.router.navigate(['/admin/financials/ledgers/business', this.actingAs.id]);
  }

  checkShowIntegration(packageType: string): boolean {
    switch (packageType) {
      case PackageTypeEnum.Basic:
        return false;
      default:
        return true;
    }
  }

  createBusiness(): void {
    this.simpleModalService.addModal(AddBusinessComponent).subscribe((result) => {
      if (!result) {
        return;
      }
      this.manageBusinessService.createBusiness(result);
    });
  }

  deleteBusiness(): void {
    this.simpleModalService
      .addModal(ConfirmInputModalComponent, {
        title: 'Delete Business',
        message:
          'Are you sure you wish to delete this business? To confirm, please type the name of the business as shown: ',
        key: this.actingAs.name,
      })
      .subscribe((result) => {
        if (result) {
          this.manageBusinessService
            .deleteBusiness(this.actingAs.id)
            .then(() => {
              this.notificationsService.publish({ message: 'Business Successfully Deleted', type: 'success' });
              this.authService.setActingAs(null, true);
              this.authService.updateBusinesses();
            })
            .catch((error) =>
              this.notificationsService.publish({
                message: error.response?.data.message,
                type: 'error',
              })
            );
        } else {
          return;
        }
      });
  }

  setSectionIds(): void {
    this.sectionIds = [
      { name: 'General' },
      { name: 'Credits', hidden: !this.showCredits },
      { name: 'Invoices' },
      { name: 'Integration', hidden: !this.showIntegration },
      { name: 'Addresses' },
      // { name: 'Couriers', hidden: !this.user.is_admin },
    ];
  }

  hasChanged(): boolean {
    return JSON.stringify(this.business_details) === JSON.stringify(this.business_model);
  }

  reset(): void {
    this.business_model = this.business_details;
  }

  getBusinessDetails(): void {
    this.manageBusinessService.getBusinessDetails(this.actingAs.id).then((data) => {
      this.business_details = data;
      this.business_model = JSON.parse(JSON.stringify(this.business_details));
    });
  }

  reload(): void {
    this.getBusinessDetails();
  }
}
