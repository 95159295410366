import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ButtonTypes } from '../buttons/basic-button.component';

@Component({
  selector: 'app-page-filter-buttons',
  templateUrl: './page-filter-buttons.component.html',
  styleUrls: ['./page-filter-buttons.component.scss'],
})
export class PageFilterButtonsComponent implements OnInit {
  @Input() sectionIds: Array<any>;
  @Output() sectionSelected = new EventEmitter<any>();
  @Input() selectedSection: string;
  @Input() highlight = false;

  buttonTypes = ButtonTypes;

  constructor() {}

  selectSection(section) {
    if (this.selectedSection !== section) {
      this.selectedSection = section;
      this.sectionSelected.emit(section);
    } else {
      this.selectedSection = undefined;
      this.sectionSelected.emit(undefined);
    }
  }
  ngOnInit() {}
}
