import { VehicleSizesEnum } from 'app/interfaces/vehicle-type.interfaces';
import { mfRoundTripFormatter, firestoreDateObject, mfGetDriverNamesCommaSeparated, mfHandleNoneFleet, mfCountInArray } from 'app/shared/table/map-formatters.table.helpers';
import { TableColumn, TableDataMap } from 'app/shared/table/table.interfaces';
import firebase from 'firebase/app';
import { firestorePostDispatchEventNameMapping, firestorePostDispatchEventHighlighter } from '../post-dispatch/post-dispatch.constants';
import { Warehouse } from 'app/interfaces/auth.interfaces';
import { FullAddressModelOut } from '../place-order/trip.interfaces';

export enum BucketStatuses {
  Enqueued = 'Enqueued',
  Initializing = 'Initializing',
  Processing = 'Processing',
  Polling = 'Polling',
  Completed = 'Completed',
  Errored = 'Errored',
  Skipped = 'Skipped',
  Pending = 'Pending',
}

export enum BucketStates {
  Importing = 'Importing',
  AutoGeocoding = 'AutoGeocoding',
  PreRouteManagement = 'PreRouteManagement',
  PollingRoutific = 'PollingRoutific',
  Costing = 'Costing',
  SelectingTrips = 'SelectingTrips',
  SelectingCourierOrders = 'SelectingCourierOrders',
  Dispatching = 'Dispatching',
  Alert = 'Alert',
}

export enum BucketStatesUi {
  Importing = 'Importing',
  AutoGeocoding = 'Auto Geocoding',
  Verifying = 'Verifying',
  CostingSettings = 'Costing Settings',
  Costing = 'Costing',
  TripSelection = 'Trip Selection',
  CourierOrderSelection = '3rd Party Orders',
  Confirmation = 'Confirmation',
  Dispatched = 'Dispatched',
  Alert = 'Alert',
}

export interface Bucket {
  avg_price_per_parcel: number;
  avg_cost_per_order: number;
  bucket_id: number;
  business_id: string;
  business_name: string;
  current_progress: number;
  current_state: BucketStates;
  current_status: BucketStatuses;
  delivery_date: string;
  delivery_date_end?: string;
  dispatch_time: string;
  exception: string | null;
  flagged_orders: number;
  rejected_orders: number;
  failed_orders: number;
  IsDeleted: boolean;
  unverified_orders: number;
  unrouted: number;
  routes: number;
  shift: string | null;
  shift_end: string;
  shift_end_date_time: firebase.firestore.Timestamp;
  shift_start: string;
  shift_start_date_time: firebase.firestore.Timestamp;
  states: any[];
  total_orders: number;
  unserved_orders: number;
  warehouse_id: string;
  warehouse_name: string;
  middle_mile_orders: number;
}

export interface BucketState {
  bucket_id: number;
  completed_at?: string | null;
  created_at?: string;
  error?: null | string;
  id?: number;
  info?: string | null;
  job_id?: string | null;
  progress: number | null;
  state: BucketStates;
  status: BucketStatuses;
  user?: string | null;
}

export interface ProcessBucketOptionsRequest {
  process_bucket_options: ProcessBucketOptions;
  bucket_contractors: BucketContractors;
}

export interface ProcessBucketOptions {
  cash_deposit_threshold: number;
  is_courier_only: boolean;
  requires_cash_deposits: boolean;
  collection_lead_time_minutes: string;
  use_fixed_sequencing: boolean;
  fleet: FleetVehicles;
  is_round_trip: boolean;
  collection_barcode_options: string;
  delivery_barcode_options: string;
  is_collection_pre_assign_tracking_number: boolean;
  time_at_waypoint_minutes: number;
  is_schedule_lottery: boolean;
  can_collect_return_parcels: boolean;
  requires_one_time_pin: boolean;
  start_location?: FullAddressModelOut;
  end_location?: FullAddressModelOut;
}

export interface BucketContractors {
  motorcycle_count: number;
  car_count: number;
  small_van_count: number;
  large_van_count: number;
  motorcycle_preferred_drivers: string[];
  car_preferred_drivers: string[];
  small_van_preferred_drivers: string[];
  large_van_preferred_drivers: string[];
}

export interface FleetVehicles {
  'vehicle-motorcycle': FleetVehicle;
  'vehicle-car': FleetVehicle;
  'vehicle-small-van': FleetVehicle;
  'vehicle-large-van': FleetVehicle;
}

export interface FleetVehicle {
  capacity: number;
  count: number;
}

export interface GetOrderDetailsRequest {
  bucket_id: string;
  business_reference: string;
}

export interface SearchAddressBookRequest {
  business_id: string;
  search_term: string;
}

export interface GetContractorsResponse {
  error_number: number;
  output: Contractor[];
}

export interface Contractor {
  driver_id: string;
  driver_name: string;
  driver_phone: string;
  driver_email: string;
  vehicle_id: VehicleSizesEnum;
}

export const FSBucketTableColumns: TableColumn[] = [
  { name: 'trip_id', displayName: 'Trip ID', hidden: true },
  { name: 'customer_reference', displayName: 'Customer Reference', sortable: false },
  { name: 'scheduled_date', displayName: 'Scheduled Date', sortable: true },
  { name: 'status', displayName: 'Status', sortable: false },
  { name: 'warehouse_name', displayName: 'Warehouse', sortable: true },
  { name: 'is_round_trip', displayName: 'Trip Type', sortable: false },
  { name: 'driver_names', displayName: 'Drivers', sortable: false },
  { name: 'fleet_allocation', displayName: 'Fleet', sortable: false },
  { name: 'last_event', displayName: 'Last Event', sortable: true },
  { name: 'last_updated', displayName: 'Last Updated', sortable: true },
  { name: 'parcel_count', displayName: 'Parcels', sortable: false },
];

export const FSBucketTableDataMap: TableDataMap = {
  cells: {
    trip_id: { column: 'trip_id', map: 'Id' },
    is_round_trip: { column: 'is_round_trip', map: 'IsRoundTrip', format: mfRoundTripFormatter },
    customer_reference: { column: 'customer_reference', map: 'CustomerReference' },
    scheduled_date: {
      column: 'scheduled_date',
      map: ['ScheduledDate'],
      format: firestoreDateObject,
      displayPipeArgs: ['date', 'dd LLL HH:mm'],
    },
    status: { column: 'status', map: 'OrderState' },
    warehouse_name: { column: 'warehouse_name', map: 'WarehouseName' },
    driver_names: {
      column: 'driver_names',
      map: ['DriverNames', 'FleetAllocation', 'CourierName'],
      format: mfGetDriverNamesCommaSeparated,
    },
    fleet_allocation: { column: 'fleet_allocation', map: ['FleetAllocation'], format: mfHandleNoneFleet },

    last_event: {
      column: 'last_event',
      map: ['LastEventName'],
      format: firestorePostDispatchEventNameMapping,
      highlighted: true,
      highlightColorMap: ['LastEventName'],
      highlightColorClass: firestorePostDispatchEventHighlighter,
    },
    last_updated: {
      column: 'last_updated',
      map: ['Timestamp'],
      format: firestoreDateObject,
      displayPipeArgs: ['timeAgo'],
    },
    parcel_count: {
      column: 'parcel_count',
      map: ['Parcels'],
      format: mfCountInArray,
    },
  },
};


export interface BucketsPayload {
  warehouse: Warehouse,
  date: string,
  type: string,
  limit: number
}


export interface StagedBucketsModel {
  AwaitingDispatch: number
  EnRoute: number
  Failed: number
  OrderCount: number
  Received: number
  RowNumber: number
  Staged: number
  ToWarehouseId: string
  ToWarehouseName: string
}
