<div class="row geocode-all">
  <app-basic-button 
    [title]="'Geocode All'" 
    [type]="buttonType.Highlight" 
    [active]="true" 
    (click)="openGeocoder(null, null)">
  </app-basic-button>
</div>
<div class="summary-table gradient-bg">
  <div class="row">
    <h4>Address Summary</h4>
  </div>
  <div class="row">
    <ng-template ngFor let-block [ngForOf]="address_summary">
      <div class="summary-block">
        <h2><svg-icon src="/assets/img/icons/geocoded.svg" class="icon"></svg-icon>{{ block.count }}</h2>
        <label>{{ block.status | stripUnderscores }}</label>
      </div>
    </ng-template>
  </div>
</div>

<div class="table-wrap lookup">
  <heading [icon]="Icons.Types.Geocoded" [color]="Icons.Colors.Blue">
    <h4 class="heading-title">Address Lookup</h4>
    <div class="controls" *ngIf="shownAddresses.length > 0">
      <label> <input type="checkbox" [(ngModel)]="showOriginalAddress" />Show Annotations</label>
    </div>
  </heading>
  <div class="row gradient-bg actions-wrap">
    <div class="search-bar">
      <label for="search">
        <svg-icon src="../../../assets/img/icons/search.svg" class="icon"></svg-icon>
      </label>
      <input
        type="text"
        [(ngModel)]="searchText"
        placeholder="Search by Name, Email, Address, Suburb, Postal Code, Customer Reference, Order Reference"
        (keydown.enter)="search(searchText)"
      />
    </div>
    <app-basic-button 
      [title]="'Search'"
      [type]="buttonType.Primary"
      (click)="search(searchText)">
    </app-basic-button>
  </div>
  <div class="row">
    <app-table
      *ngIf="!firstLoad"
      [zeroDataMessage]="'There are no addresses available.'"
      [columns]="tableColumns"
      [tableData]="shownAddresses"
      [primaryActions]="tablePrimaryActions"
      [bulkActions]="tableBulkActions"
      [showAnnotations]="showOriginalAddress"
      (actionTaken)="respondToTableAction($event)"
      (moreDataRequested)="loadMoreTableData()"
      [showViewMore]="showViewMore"
      (viewAllRequested)="showAllData()"
      [showViewAll]="showViewMore"
    ></app-table>
  </div>

  <app-zones></app-zones>
</div>
