import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FSOrderTableColumns, FSOrderTableDataMap } from 'app/dashboard/post-dispatch/post-dispatch.constants';
import { OrderFrontEndStateEnum } from 'app/dashboard/post-dispatch/post-dispatch.interfaces';
import { PostDispatchService } from 'app/dashboard/post-dispatch/post-dispatch.service';
import { ActingAs } from 'app/interfaces/auth.interfaces';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { Order, CollapsibleTableTypeEnum, PaginateEnum, Paginate } from 'app/shared/shared.interfaces';
import { TableAction, SortEvent } from 'app/shared/table/table.interfaces';
import { Observable, Subscription, from } from 'rxjs';

@Component({
  selector: 'app-dispatch-lotteries',
  templateUrl: './dispatch-lotteries.component.html',
  styleUrls: ['./dispatch-lotteries.component.scss']
})
export class DispatchLotteriesComponent implements OnInit, OnDestroy {

  @Input() postDispatchTablePrimaryActions: TableAction[] = [];

  Icons = {
    Colors: UiColors,
    Types: IconTypes,
  };

  lotteryRunningFirestoreTrips: Observable<Order[]>;
  lotteryRunningAmount: number = 10;
  lotteryRunningSubscription: Subscription;
  lotteryRunningTotal: number;

  fsOrderTableColumns = FSOrderTableColumns;
  fsOrderTableDataMap = FSOrderTableDataMap;

  CollapsibleTableTypeEnum = CollapsibleTableTypeEnum;

  actingAs: ActingAs;

  constructor(private postDispatchService: PostDispatchService) { }

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy(): void {
    this.lotteryRunningSubscription.unsubscribe();
  }

  getData(): void {
    this.handleLotteryRunningTrips({ Amount: this.lotteryRunningAmount, Direction: PaginateEnum.None });
    this.handlePostDispatchTotals();
  }

  handlePostDispatchTotals(): void {
    this.postDispatchService.getPostDispatchTotals(false).then((data) => {
      this.lotteryRunningTotal = data.output.find((x) => x.State === OrderFrontEndStateEnum.LotteryRunning)?.Cnt;
    });
  }

  handleLotteryRunningTrips($event: Paginate): void {
    this.lotteryRunningAmount = $event.Amount;
    this.lotteryRunningSubscription?.unsubscribe();
    this.lotteryRunningSubscription = this.postDispatchService
      .getFireStoreOrderSummaryStreamByState(
        this.lotteryRunningAmount,
        $event.Direction,
        OrderFrontEndStateEnum.LotteryRunning,
        false
      )
      .subscribe((response) => {
        if (response[0]) {
          this.postDispatchService.setTripSnapshot(
            {
              First: response[0].payload.doc,
              Last: response[response.length - 1].payload.doc,
            },
            OrderFrontEndStateEnum.LotteryRunning
          );

          const data: Order[] = [];
          for (const item of response) {
            data.push(item.payload.doc.data());
          }
          this.lotteryRunningFirestoreTrips = from([data]);
        } else {
          this.lotteryRunningFirestoreTrips = from([]);
        }
      });
  }


  handleTripsSortChange($event: SortEvent): void {
    this.postDispatchService.orderOrderBy = $event;
    this.handleLotteryRunningTrips({ Amount: this.lotteryRunningAmount, Direction: PaginateEnum.None });
  }

}
