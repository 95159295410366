import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin/admin.component';
import { AdminGuard } from './auth/admin.guard';
import { StaticSiteComponent } from './static-site/static-site.component';
import { SmsTrackingComponent } from './sms-tracking/sms-tracking.component';

import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth/auth.service';

import { AuthGuard } from './auth/auth.guard';

@Injectable({ providedIn: 'root' })
export class ResetPasswordResolver implements Resolve<boolean> {
  constructor(private authService: AuthService){}

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    const oobCode = route.queryParams['oobCode']
    return this.authService
      .verifyPasswordResetCode(oobCode)
  }
}

const appRoutes: Routes = [
  {
    path: '',
    // REPLACE  StaticSiteComponent for homepage to show
    component: StaticSiteComponent,
    pathMatch: 'prefix',
    loadChildren: () => import('./static-site/static-site.module').then((m) => m.StaticSiteModule),
    data: { breadcrumb: 'Homepage' },
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { breadcrumb: 'Login' },
  },
  {
    path: 'register',
    component: LoginComponent,
    data: { breadcrumb: 'Register' },
  },
  {
    path: 'reset',
    component: LoginComponent,
    data: { breadcrumb: 'Reset-Password' },
  },
  {
    path: 'reset-password',
    resolve: {
      enableResetPasswordPage: ResetPasswordResolver
    },
    component: ResetPasswordComponent,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'o/:id',
    component: SmsTrackingComponent,
    data: { breadcrumb: 'Order-Tracking' },
  },
  {
    path: 'order-tracking',
    component: SmsTrackingComponent,
    data: { breadcrumb: 'Order-Tracking' },
  },
  {
    path: 'admin',
    data: { breadcrumb: 'Admin' },
    canActivate: [AdminGuard, AuthGuard],
    component: AdminComponent,
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
  },
  // {
  //   path: 'kl',
  //   component: KlFrameComponent,
  //   loadChildren: () => import('./kl-frame/kl-frame.module').then((m) => m.KlFrameModule),
  // },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}




