<app-modal
  [open]="true"
  [actions]="actions"
  [highlightPrimaryAction]="true"
  [size]="ModalSizes.Large"
  (handleAction)="handleAction($event)"
>
  <heading [icon]="iconTypes.Map" [color]="uiColors.Blue">
    <h4 class="heading-title">Consign Orders</h4>
  </heading>
  <div class="content">
    <app-grid-container
      [padding]="'0'"
      [columns]="'5fr 2fr'"
      [colGap]="'2rem'"
      [rowGap]="'1rem'"
      [theme]="uiThemes.Light"
    >
      <div class="slide-selector">
        <h6>Consign orders outside radius of</h6>
        <div #rangeSlider></div>
      </div>
      <div *ngIf="!editRadius; else edit" class="result">
        <h4>{{ selected_radius / 1000 }} Km </h4>
        <mat-icon (click)="editRadius = !editRadius">edit</mat-icon>
      </div>
      <ng-template #edit>
        <div class="result">
          <!-- TODO: (change) -->
          <input type="number" 
            class="manual-radius-input" 
            [value]="selected_radius / 1000" 
            (change)="setRadius($event.target)"/>
          <div class="distance">KM</div>
          <mat-icon (click)="editRadius = !editRadius">edit</mat-icon>
        </div>
      </ng-template>
      <div class="name">
        <input-field
          [disabled]="no_routing"
          [label]="'Name'"
          [type]="'text'"
          (valueChanged)="consignment_name = $event"
          [value]="consignment_name"
        >
        </input-field>
      </div>
      <div class="option">
        <app-flex-container [justifyContent]="justifyContent.FlexStart">
          <input type="checkbox" id="routing" (click)="toggle()" [checked]="no_routing" />
          <h6>DO NOT ROUTE</h6></app-flex-container
        >
      </div>
    </app-grid-container>
  </div>
</app-modal>
