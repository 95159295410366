import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
})
export class InputFieldComponent {
  @Input() type: string;
  @Input() label: string;
  @Input() error: string;
  @Input() value: string;
  @Input() svgIcon: string;
  @Input() svgActionIcon: string;
  @Input() svgActionIconColor: string;
  @Input() svgIcon2: string;
  @Input() svgActionIcon2: string;
  @Input() svgActionIconColor2: string;
  @Input() visibleAction2: boolean = false;
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() labelTheme = 'dark';
  @Input() required;
  @Input() isInvalid = false;
  @Input() autoComplete = 'off';
  @Input() readOnly = false;
  @Input() styles = '';
  @Input() maxLength: number = null;

  trimmedValue;

  @Output() valueChanged = new EventEmitter<string>();
  @Output() actionClicked = new EventEmitter<string>();
  @Output() action2Clicked = new EventEmitter<string>();
  @Output() focusTriggered = new EventEmitter<boolean>();

  onChange(): void {
    this.valueChanged.emit(this.value);
  }

  actionClicker(): void {
    this.actionClicked.emit(this.value);
  }

  action2Clicker(): void {
    this.action2Clicked.emit(this.value);
  }

  onFocus(focus: boolean): void {
    this.focusTriggered.emit(focus);
  }

  trimWhiteSpace(): void {
    if (this.value && typeof this.value === 'string') {
      this.value = this.value.replace(/(^\s+|\s+$)/g, '');
    }
  }
}
