import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusIcon'
})
export class StatusIconPipe implements PipeTransform {

  transform(value: boolean): string {
    return value ? 'check_circle' : 'cancel';
  }

}
