import { Business } from './../../interfaces/businesses.interfaces';
import { Warehouse } from './../../interfaces/auth.interfaces';
import { StoreAction } from 'app/interfaces/auth.interfaces';
import * as Actions from './post-dispatch.actions';
import { ScheduledDropDownEnum } from 'app/admin/admin-post-dispatch/admin-post-dispatch/admin-post-dispatch.interface';

export interface PostDispatchState {
  postDispatchFilters: {
    mode: string;
    warehouse: null | Warehouse;
    fleet_allocation: null | string;
    limit: number;
    business: Business;
    scheduledMode: ScheduledDropDownEnum;
  };
}

const initialStateFromSomewhere = JSON.parse(localStorage.getItem('state'));

const initialState: PostDispatchState = {
  postDispatchFilters: {
    mode: 'order',
    warehouse: null,
    fleet_allocation: null,
    limit: null,
    business: null,
    scheduledMode: ScheduledDropDownEnum.All
  },
};

const getInitialState = (): PostDispatchState => {
  if (initialStateFromSomewhere) {
    if (initialStateFromSomewhere.post_dispatch !== undefined && initialStateFromSomewhere.post_dispatch !== null) {
      const state = initialStateFromSomewhere.post_dispatch;
      return { ...initialState, ...state };
    } else {
      return { ...initialState };
    }
  } else {
    return { ...initialState };
  }
};

export const selectorPostDispatchFilters = (state) => state.post_dispatch.postDispatchFilters;

export const PostDispatchReducer = (state = getInitialState(), action: StoreAction) => {
  switch (action.type) {
    case Actions.SET_POST_DISPATCH_FILTERS:
      return {
        ...state,
        postDispatchFilters: action.payload,
      };
    case Actions.CLEAR_POST_DISPATCH_FILTERS:
      return {
        ...state,
        postDispatchFilters: initialState.postDispatchFilters,
      };
    default:
      return state;
  }
};
