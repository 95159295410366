import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appCollapsible]',
  exportAs: 'appCollapsible',
})
export class CollapsibleDirective implements OnInit {
  @Input() public expanded: boolean;
  @Input() public animationTime = 500;

  public isExpanded = true;
  public isCollapsed = false;
  public isCollapse = true;
  public isCollapsing = false;
  public collapsing = false;
  public elHeight: number;

  protected elRef: ElementRef;
  protected htmlElement: HTMLElement;
  protected renderer: Renderer2;

  public constructor(elementRef: ElementRef, renderer: Renderer2) {
    this.elRef = elementRef;
    this.renderer = renderer;
    this.htmlElement = this.elRef.nativeElement as HTMLElement;
  }

  ngOnInit() {
    this.renderer.addClass(this.htmlElement, 'show');
    this.setElementHeight();
    this.renderer.setStyle(this.htmlElement, 'transition', this.animationTime + 'ms ease');
    this.renderer.setStyle(this.htmlElement, 'overflow', 'hidden');

    if (!this.expanded) {
      this.renderer.removeClass(this.htmlElement, 'show');
      this.hide();
    } else {
      this.show();
    }

    this.isExpanded = this.expanded;
  }

  public resize(): void {
    this.setElementHeight();
    this.renderer.setStyle(this.htmlElement, 'height', 100 + '%');
  }

  public toggle(): void {
    if (!this.collapsing) {
      if (this.isExpanded) {
        this.hide();
      } else {
        this.show();
      }
    }
  }

  public hide(): void {
    this.collapsing = true;
    this.isCollapse = false;
    this.isCollapsing = true;

    this.isExpanded = false;
    this.isCollapsed = true;

    this.renderer.removeClass(this.htmlElement, 'expanded');
    this.renderer.removeClass(this.htmlElement, 'show');
    this.renderer.addClass(this.htmlElement, 'collapsing');

    this.renderer.setStyle(this.htmlElement, 'height', '0');

    setTimeout(() => {
      this.renderer.removeClass(this.htmlElement, 'collapsing');
      this.renderer.addClass(this.htmlElement, 'expanded');

      this.collapsing = false;
    }, this.animationTime);
  }

  public show(): void {
    if (!this.isExpanded) {
      this.setElementHeight();

      this.collapsing = true;
      this.isCollapse = false;
      this.isCollapsing = true;

      this.isExpanded = true;
      this.isCollapsed = false;

      this.renderer.removeClass(this.htmlElement, 'expanded');
      this.renderer.addClass(this.htmlElement, 'collapsing');

      setTimeout(() => {
        this.renderer.setStyle(this.htmlElement, 'height', 100 + '%');
      }, 10);

      setTimeout(() => {
        this.renderer.removeClass(this.htmlElement, 'collapsing');
        this.renderer.addClass(this.htmlElement, 'expanded');
        this.renderer.addClass(this.htmlElement, 'show');

        this.collapsing = false;
      }, this.animationTime - this.animationTime * 0.5);
    }
  }

  private setElementHeight() {
    this.elHeight = this.htmlElement.scrollHeight;
  }
}
