import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timelineEventNaming'
})
export class TimelineEventNamingPipe implements PipeTransform {

  transform(inputString: string): unknown {
    return inputString.split(/(?=[A-Z])/).join(' ');
  }

}
