<app-section>
  <heading [icon]="IconTypes.Dollar" [color]="UiColors.Orange" [showToggle]="false"
    (click)="collapsibleInProgress.toggle()">
    <h4 class="heading-title">
      <strong>{{ driverEarnings?.length }} Route Approval</strong>
    </h4>
    <div class="controls">
      <app-basic-button 
        [title]="'Refresh'" 
        [type]="buttonTypes.Primary" 
        (click)="getTableData()"> 
      </app-basic-button>
    </div>
  </heading>
  <div appCollapsible [expanded]="true" #collapsibleInProgress="appCollapsible">
    <app-table *ngIf="driverEarnings" 
      [dataMap]="driverEarningsDataMap" 
      [columns]="driverEarningsTableColumns"
      [primaryActions]="AdminFinancePrimaryActions" 
      [rowsSelectable]="true" 
      [bulkActions]="AdminFinanceBulkActions"
      (actionTaken)="handleDriverEarningsAction($event)" 
      [zeroDataMessage]="'No Financials to Approve'"
      [rawData]="driverEarnings">
    </app-table>
  </div>
</app-section>