import { FleetAllocationEnum } from './../new-trips.interfaces';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { JustifyContent } from 'app/shared/flex-container/flex-container.interfaces';
import { Subscription } from 'rxjs';
import { NewTripsService } from '../new-trips.service';
import { AuthUser } from 'app/interfaces/auth.interfaces';
import { Store } from '@ngrx/store';
import { selectorUser } from 'app/auth/auth.reducer';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';

@Component({
  selector: 'app-new-trips-lottery',
  templateUrl: './new-trips-lottery.component.html',
  styleUrls: ['./new-trips-lottery.component.scss'],
})
export class NewTripsLotteryComponent implements OnInit, OnDestroy {
  @Input() orderId: string;
  @Input() drivers;
  @Input() lotteries = [];
  @Input() fleet;
  @Input() HasPreferredDrivers: boolean;
  JustifyContent = JustifyContent;
  lottery_details;
  orderLotterySubscription: Subscription;
  processing = false;
  user: AuthUser;
  fleetAllocationEnum = FleetAllocationEnum;
  buttonTypes = ButtonTypes;

  constructor(private newTripsService: NewTripsService, private store: Store<any>) {
    this.store.select(selectorUser).subscribe((next) => (this.user = next));
  }

  ngOnInit(): void {}

  startLotteryFromBackend(fleet: FleetAllocationEnum): void {
    this.processing = true;
    this.newTripsService
      .startLotteryBackground(this.orderId, fleet)
      .then(() => (this.processing = false))
      .catch(() => (this.processing = false));
  }

  startMultipleLotteryFromBackend(fleet: FleetAllocationEnum): void {
    this.processing = true;
    this.newTripsService
      .startMultipleLotteryBackground(this.orderId, fleet)
      .then(() => (this.processing = false))
      .catch(() => (this.processing = false));
  }

  ngOnDestroy(): void {
    if (this.orderLotterySubscription) {
      this.orderLotterySubscription.unsubscribe();
    }
  }

  opposite(fleet: FleetAllocationEnum): FleetAllocationEnum {
    switch (fleet) {
      case FleetAllocationEnum.Contractor:
        return FleetAllocationEnum.Picup;
      case FleetAllocationEnum.Picup:
        return FleetAllocationEnum.Contractor;
    }
  }
}
