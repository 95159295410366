import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { Warehouse } from 'app/interfaces/auth.interfaces';

export interface WaypointStatusSettings {
  icon: IconTypes;
  color: UiColors;
  timestamp?: string;
  distance?: number;
  label: string;
}

export enum FleetAllocationEnum {
  Picup = 'Picup',
  Contractor = 'Contractor',
  PicupPlus = 'PicupPlus',
  Preferred = 'Preferred',
}

export enum OrderSourceEnum {
  OneToMany = 'OneToMany',
  Bucket = 'Bucket',
}

export interface OrderDetailsFull {
  active_last_mile: any;
  bucket_id: number;
  collection_date: string;
  customer_references: string;
  owner_id: string;
  order_status: string;
  order_source: OrderSourceEnum;
  order_state: any;
  fleet_allocation: FleetAllocationEnum;
  failed_courier_orders: any[];
  warehouse?: string;
  warehouse_address?: string;
  summary: {
    waypoints: number;
    consignments: number;
    routes: number;
    parcel_count: number;
    collected_count: number;
    delivered_count: number;
    failed_count: number;
  };
  contacts: any[];
  consignments: Consignment[];
  parcels: any[];
  signatures: Signature[];
  waypoints: Waypoint[];
  routes: string[];
  courier_orders: any[];
  drivers: RouteDriver[];
  assigned_drivers: RouteDriver[];
  active_driver_id: string;
}
export interface RouteDriver {
  driver_id: string;
  last_mile_id: string;
  name: string;
  email: string;
  phone: string;
}

export interface Waypoint {
  consignments: [];
  waypoint_id: number;
  signature_id: number;
  waypoint_type: string;
  visit_type: string;
  warehouse_id: string;
  name: string;
  phone: string;
  instructions: string;
  address: string;
  postal_code: string;
  suburb: string;
  city?: string;
  country?: string;
  country_code?: string;
  lat: number;
  lng: number;
  parcels: Parcel[];
  visit_state: string;
}
export interface Route {
  route_number: string;
  consignments: any;
  driver_id: string;
  driver_name: string;
  polylines: any[];
  route_type: string;
  started_at: string;
  ended_at: string;
  ended_source: string;
  parcel_count: number;
  collected_count: number;
  delivered_count: number;
  failed_count: number;
  waypoints: RouteWaypoint[];
  parcel_states: ParcelStates;
}

export interface ParcelStates {
  none: number;
  assigned: number;
  collected: number;
  delivered: number;
  failed_collection: number;
  failed_delivery: number;
  returned: number;
}

export interface RouteWaypoint {
  waypoint_id: number;
  visit_type: string;
  arrived_at: string;
  finished_at: string;
  lat: number;
  lng: number;
  distance_in_meters: number | null;
  parcel_count: number;
  collected_count: number;
  delivered_count: number;
  failed_count: number;
  parcels: RouteParcel[];
  signature_id: string;
  visit_state: string;
  parcel_states: ParcelStates;
}

export interface RouteParcel {
  parcel_waybill: string;
  parcel_state: string;
  failed_reason?: string;
  reconcile_action?: string;
  reconcile_reason?: string;
}

export interface Signature {
  signature_id: number;
  signature_type: string;
  route_number: string;
  url: string;
  signee: string;
  relationship: string;
  created_at: string;
}

export interface Consignment {
  consignment_id: number;
  consgignment: string;
  delivery_date: string;
  parcels: Parcel[];
}

export interface Parcel {
  ActiveRouteId?: string;
  AmountPaid?: string;
  Barcode: string;
  BusinessId: string;
  BusinessName: string;
  BusinessReference: string;
  CancelledBy?: string;
  CollectionAttempts: number;
  CollectionBarcodeOptions: string;
  Consignment?: string;
  CourierOrderId?: string;
  CourierParcelState: string;
  CourierReference?: string;
  CustomerReference: string;
  DeliveryAttempts: number;
  DeliveryBarcodeOptions: string;
  Description?: string;
  Destination: any;
  DestinationWarehouse?: any;
  Dimensions: any;
  DriverId: string;
  DriverName: string;
  FailedReason?: string;
  FinalizedAt?: string;
  IsIgnored: boolean;
  IsRetry: boolean;
  LastMileId: string;
  LastMileParcelState: string;
  LatestDeliveredRouteId: string;
  LatestReturnedRouteId: string;
  OneTimePin: string;
  OrderId: string;
  OrderParcelState: string;
  OrderSource: string;
  Origin: any;
  OriginWarehouse: Warehouse;
  OriginalBarcode?: string;
  OwnerId?: string;
  OwnerLedgerId?: string;
  OwnerName?: string;
  ParcelReference: string;
  ParcelValue?: number;
  ParcelWaybill: string;
  PaymentState?: string;
  PaymentType?: string;
  ReconciledBy?: string;
  ReconciledReason?: string;
  Size?: any;
  SourceBucketId: number;
  SourceTripId: number;
  CustomerName?: string;
  FormattedAddress?: string;
}

export interface ParcelOverview {
  parcels_delivered: number;
  parcels_failed_delivery: number;
  parcels_pending_delivery: number;
  parcels_pending_collection: number;
  total_parcels: number;
  parcels_collected: number;
  parcels_failed_collection: number;
}

export enum DriverActingAs {
  Picup = 0,
  Contract = 1,
  Both = 2,
}

export enum DriverRole {
  Picup = 0,
  Contract = 1,
  Both = 2,
}

export interface Location {
  latitude: number;
  longitude: number;
}

export interface SQLDriver {
  _version: 23;
  account_number: string;
  acting_as: DriverActingAs;
  branch_code: string;
  city: string;
  driver_id: string;
  email: string;
  is_activated: boolean;
  is_deleted: boolean;
  is_online: true;
  location: Location;
  name: string;
  phone: string;
  registration_id: string;
  role: DriverRole;
  suburb: string;
  unique_device_identifier: string;
  user_id: string;
  vehicles: string[];
}

export const enum SQLOrdersTableColumns {
  TripID = 'trip_id',
  CustomerReference = 'customer_reference',
  DeliveryDate = 'delivery_date',
  WarehouseName = 'warehouse_name',
  FleetAllocation = 'fleet_allocation',
  LastEvent = 'last_event',
  LastUpdated = 'last_updated',
  DriverNames = 'driver_names',
  TotalParcels = 'total_parcels',
  ParcelsPendingCollection = 'parcels_pending_collection',
  ParcelsPendingDelivery = 'parcels_pending_delivery',
  Waypoints = 'waypoints',
  Progress = 'progress',
  Consignments = 'consignments',
  OrderStatus = 'order_status',
  OwnerName = 'ownerName',
  Parcels = 'parcels',
  WarehouseId = 'warehouse_id',
}

export interface ViewerDictionary {
  [Key: string]: Viewer;
}

export interface Viewer {
  is_admin: boolean;
  user_name: string;
}

export interface DriverAssignedOrders {
  AssignedOrders: AssignedOrder[];
  LastMileId?: string;
}

export interface AssignedOrder {
  BusinssName: string;
  CollectionDate: firebase.default.firestore.Timestamp;
  CustomerReference: string;
  IsActive: boolean;
  OrderId: string;
  ParcelCount: number;
}

export interface OrderTimelineFirestore {
  Id: string;
  Events: OrderTimelineFirestoreEventData[];
}

export interface OrderTimelineFirestoreEventData{
  EventName: string;
  Timestamp: firebase.default.firestore.Timestamp;
  UserEmail: string;
}

export interface RecreateCourierOrderResponse {
  original_order: string,
  new_order_Id: string,
  is_success: boolean,
  output: string[]
}
