export enum CourierOrdersTabsEnum {
  Details = 'Details',
  Timeline = 'Timeline',
  Notes = 'Notes',
}

export interface CourierOrderParcel {
  ActiveRouteId: string;
  AmountPaid: string;
  Barcode: string;
  BusinessId: string;
  BusinessName: string;
  BusinessReference: string;
  CancelledBy: string;
  CollectionAttempts: number;
  CollectionBarcodeOptions: string;
  Consignment: any;
  CourierOrderId: string;
  CourierParcelState: string;
  CourierReference: string;
  CustomerReference: string;
  DeliveryAttempts: number;
  DeliveryBarcodeOptions: string;
  Description: any;
  Destination: any;
  DestinationWarehouse: any;
  Dimensions: any;
  DriverName: string;
  FailedReason: any;
  FinalizedAt: any;
  IsIgnored: boolean;
  IsRetry: boolean;
  LastMileId: string;
  LastMileParcelState: string;
  LatestDeliveredRouteId: string;
  LatestReturnedRouteId: string;
  OneTimePin: string;
  OrderId: string;
  OrderParcelState: string;
  OrderSource: string;
  Origin: any;
  OriginWarehouse: any;
  OriginalBarcode: string;
  OwnerId: string;
  OwnerLedgerId: string;
  OwnerName: string;
  ParcelReference: string;
  ParcelValue: any;
  ParcelWaybill: string;
  PaymentState: any;
  PaymentType: any;
  ReconciledBy: string;
  ReconciledReason: string;
  Size: any;
  SourceBucketId: number;
  SourceTripid: string;
  ServiceTypeDescription: any;
}

export interface CourierOrderDetails {
  BucketId: number;
  BusinessId: string;
  BusinessName: string;
  BusinessReference: string;
  CourierCode: string;
  CourierId: string;
  CourierName: string;
  CourierReference: string;
  CreateCourierOrderResponse: any;
  CustomerReference: string;
  Id: string;
  IsCancelled: boolean;
  IsComplete: boolean;
  IsWaybillCreated: boolean;
  LastEventName: string;
  OrderId: string;
  OrderWaybillNumber: string;
  ParcelTracking: any[];
  Parcels: any[];
  RehydratedAt: any;
  RehydratedVersion: number;
  ServiceTypeDescription: string;
  ServiceTypeUid: string;
  Timestamp: any;
  TrackingEventType: string;
  Version: number;
  WarehouseId: string;
  WarehouseName: string;
}
