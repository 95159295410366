import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import axios from 'app/api/axios';
import { SimpleModalService } from 'ngx-simple-modal';
import { NotificationsService } from '../../../../shared/notifications/notifications.service';
import { GeocodingToolComponent, GeocoderConfig } from '../../../../shared/geocoding-tool/geocoding-tool.component';
import { selectorActiveBucketId } from '../../manage-bucket.reducer';
import { selectorActingAs, selectorUser, selectorWarehouses } from '../../../../auth/auth.reducer';
import { UiThemes } from 'app/interfaces/ui.interfaces';
import { ChangeBucketComponent } from './change-bucket/change-bucket.component';
import { ManageBucketService } from '../../manage-bucket.service';
import { CrossdockComponent } from './crossdock/crossdock.component';
import { ModifyBucketOrderComponent } from '../../modify-bucket-order/modify-bucket-order.component';
import { ConfirmModalComponent } from 'app/shared/modals/confirm-modal.component';
import { JustifyContent } from 'app/shared/flex-container/flex-container.interfaces';
import { CreateBucketModalComponent } from 'app/shared/buckets/create-bucket-modal/create-bucket-modal.component';
import { ConsignRadiusComponent } from './consign-radius/consign-radius.component';
import { ManualRoutingComponent } from './manual-routing/manual-routing.component';
import { ActingAs, Warehouse } from 'app/interfaces/auth.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { bucketMarkerDetails, VerifyMapComponent } from './map/verify-map.component';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss'],
})
export class VerifyComponent implements OnDestroy, OnInit {
  @Input() readOnly = false;
  actingAs: ActingAs;
  user;
  public UiThemes = UiThemes;
  public JustifyContent = JustifyContent;
  bucketId: number;
  updateMap = false;
  tableToggle = false;
  updateTable = false;
  order_ids = [];
  activeBucket;
  bucketSubscription;
  unverifiedWarning = true;
  tableFirstLoad = true;
  geoJson;
  warehouses: Warehouse[];
  buttonTypes = ButtonTypes
  markerInfo: any[] = [];
  loadingGeoJson = true;
  unsubscribe$ = new Subject<void>();

  @ViewChild('verifyMap', { static: false }) mapView: VerifyMapComponent;

  vetAllBusinessList = [
    'business-5e99eca6-8e3b-47bc-a2a2-fcf610c18ccf',
    'business-bf407e3c-39cf-4599-8bdf-3092d851d65c',
  ];

  constructor(
    private manageBucketService: ManageBucketService,
    public store: Store<any>,
    private notificationsService: NotificationsService,
    private simpleModalService: SimpleModalService
  ) {
    this.store.select(selectorActiveBucketId).subscribe((next) => (this.bucketId = next));
    this.store.select(selectorActingAs).subscribe((next) => (this.actingAs = next));
    this.store.select(selectorUser).subscribe((next) => (this.user = next));
    this.store.select(selectorWarehouses).subscribe((next) => (this.warehouses = next));
    this.bucketSubscription = this.manageBucketService.getBucketObject().subscribe((next) => {
      this.activeBucket = next;
      this.checkForFlaggedFailed();
    });
  }

  ngOnInit() {
    this.getGeoJson();
  }

  respondToFlag(event) {
    this.flagOrder(event.business_reference, event.reason);
  }

  respondToGeocode(config): void {
    this.geocodeAddresses(config);
  }

  respondToMapChangeBucket(ids: bucketMarkerDetails[]): void {
    this.markerInfo = ids;
    this.changeBucket(ids.map(x => x.order_id));
  }

  respondToChangeBucket(ids: string[]): void {
    this.changeBucket(ids);
  }

  respondToTableToggle() {
    this.tableToggle = !this.tableToggle;
  }

  vetAll() {
    this.simpleModalService
      .addModal(ConfirmModalComponent, {
        title: 'Vet All Addresses',
        message:
          'Please ensure that both the formatted address and geocode provided has been validated. Address related failed deliveries will incur additional charges.',
      })
      .subscribe((isConfirmed) => {
        if (!isConfirmed) {
          return;
        }
        this.manageBucketService.vetAll().then(() => {
          this.updateData();
        });
      });
  }

  openCrossdockModal() {
    const config = {
      geoJson: this.geoJson,
      activeBucket: this.activeBucket,
    };
    this.simpleModalService.addModal(CrossdockComponent, config).subscribe((result) => {
      if (!result) {
        return;
      }
      if (result) {
        this.manageBucketService.updateCrossDocks(this.bucketId, result).then(() => {
          this.updateData();
        });
      }
    });
  }

  getGeoJson() {
    this.manageBucketService.getVerifyingGeoJson(this.bucketId).then((response) => {
      this.geoJson = response;
      this.loadingGeoJson = false;
    });
  }

  consign() {
    this.simpleModalService.addModal(ConsignRadiusComponent).subscribe((result) => {
      if (!result) {
        return;
      }
      const data = {
        radius_in_meters: result.selected_radius,
        consignment: !result.no_routing ? result.consignment_name : 'no_route',
      };
      this.manageBucketService.consignOrdersOutsideRadius(this.bucketId, data);
    });
  }

  checkForFlaggedFailed() {
    if (
      this.activeBucket.failed_orders > 0 ||
      this.activeBucket.skipped_orders > 0 ||
      this.activeBucket.unverified_orders > 0
    ) {
      this.unverifiedWarning = true;
    } else {
      this.unverifiedWarning = false;
    }
  }

  updateData() {
    this.checkForFlaggedFailed();
    this.updateMap = !this.updateMap;
    this.updateTable = !this.updateTable;
  }

  flagOrder(business_reference, reason) {
    this.notificationsService.publish({ message: 'Flagging order' });
    const data = { business_reference, reason };
    axios
      .post(`/enterprise/${this.actingAs.id}/flag-order/`, data)
      .then(() => {
        this.notificationsService.publish({
          type: 'success',
          message: 'Order flagged successfully',
        });
        this.updateData();
      })
      .catch((error) =>
        this.notificationsService.publish({
          type: 'error',
          message: error.response?.data.message,
        })
      );
  }

  geocodeAddresses(config) {
    this.simpleModalService.addModal(GeocodingToolComponent, config).subscribe((result) => {
      if (!result) {
        this.updateData();
        return;
      }
      if (!result.successful) {
        return this.notificationsService.publish({
          type: 'error',
          message: result.message,
        });
      }
      this.notificationsService.publish({
        type: 'success',
        message: 'No further addresses to geocode',
      });
      this.updateData();
    });
  }

  geocodeBucket() {
    const config: GeocoderConfig = {
      addresses: null,
      bucket_id: this.bucketId,
    };
    this.geocodeAddresses(config);
  }

  changeBucket(orderIds: string[]) {
    this.simpleModalService.addModal(ChangeBucketComponent).subscribe((result) => {
      if (result) {
        if (result !== 'newBucket') {
          const changeBucketData = {
            new_bucket_id: result,
            order_ids: orderIds,
          };
          this.manageBucketService.changeBucket(changeBucketData).then(() => {
            this.updateData();
            this.mapView?.removeMapMarker(this.markerInfo.map(x => x.business_reference));
            this.markerInfo = [];
          });
        } else {
          const modal_data = {
            warehouses: this.warehouses,
          };
          this.simpleModalService.addModal(CreateBucketModalComponent, modal_data).subscribe((create_result) => {
            if (!create_result) {
              return;
            }
            const changeBucketData = {
              bucket_details: create_result,
              order_ids: orderIds,
            };
            this.manageBucketService.changeBucket(changeBucketData).then(() => {
              this.updateData();
              this.mapView?.removeMapMarker(this.markerInfo.map(x => x.business_reference));
              this.markerInfo = [];
            });
          });
        }
      }
    });
  }

  respondToRemoveOrder(orderids) {
    this.simpleModalService
      .addModal(ConfirmModalComponent, {
        title: 'Are you sure?',
        message: 'You are about to remove order(s) from the bucket.',
      })
      .subscribe((isConfirmed) => {
        if (!isConfirmed) {
          return;
        } else {
          this.manageBucketService.removeOrder(this.bucketId, null, orderids).then(() => {
            this.updateData();
            this.mapView?.removeMapMarker(orderids);
          }
          );
        }
      });
  }

  respondToRestageOrder(bucket_order_ids) {
    this.simpleModalService
      .addModal(ConfirmModalComponent, {
        title: 'Re-stage Orders',
        message: 'Are you sure? This can not be undone. Selected orders will be placed in your staging orders bucket. ',
      })
      .subscribe((isConfirmed) => {
        if (!isConfirmed) {
          return;
        }
        this.manageBucketService.restageOrders(bucket_order_ids).then(() => {
          this.updateData();
        });
      });
  }

  respondToManualRouting(business_references) {
    const data = {
      total_orders: business_references.length,
    };
    this.simpleModalService.addModal(ManualRoutingComponent, data).subscribe((result) => {
      if (!result) {
        return;
      }
      const api_data = {
        delivery_date: result.delivery_date,
        trip_name: result.trip_name,
        order_ids: business_references,
      };
      this.manageBucketService.createManualTrip(this.bucketId, api_data).then(() => this.updateData());
    });
  }

  respondToEditOrder(business_reference) {
    const data = {
      bucket_id: this.bucketId,
      business_reference: business_reference,
      business_id: this.actingAs.id,
    };
    this.simpleModalService.addModal(ModifyBucketOrderComponent, data).subscribe((result) => {
      if (result) {
        this.updateData();
      }
    });
  }

  ngOnDestroy(): void {
    this.bucketSubscription.unsubscribe();
  }
}
