export interface Driver {
  email: string;
  id: string;
  is_activated: boolean;
  name: string;
  phone: string;
  vehicle: string;
  online?: boolean;
  orderCount?: number;
  onTrip?: boolean;
  distanceToWarehouse?: string;
  parcelCount?: number;
}

export enum DriverTypeEnum {
  Contractor = 'Contractor',
  PlusDriver = 'PlusDriver',
}

export interface AssignedOrdersData {
  OrderCount: number;
  ParcelCount: number;
}
