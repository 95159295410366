import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FSAdminActiveLastMileTableMap, FSAdminActiveLastMileTableColumns } from 'app/admin/admin.contants';
import { PostDispatchService } from 'app/dashboard/post-dispatch/post-dispatch.service';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { LastMile, CollapsibleTableTypeEnum, PaginateEnum, Paginate } from 'app/shared/shared.interfaces';
import { TableAction } from 'app/shared/table/table.interfaces';
import { Observable, Subscription, from } from 'rxjs';

@Component({
  selector: 'app-dispatch-failed',
  templateUrl: './dispatch-failed.component.html',
  styleUrls: ['./dispatch-failed.component.scss']
})
export class DispatchFailedComponent implements OnInit, OnDestroy {
  @Input()  postDispatchTablePrimaryActions: TableAction[] = [];
  Icons = {
    Colors: UiColors,
    Types: IconTypes,
  };

  failedRoutes: Observable<LastMile[]>;
  failedRoutesAmount: number = 10;
  failedRoutesSubscription: Subscription;
  failedRoutesTotal: number;

  fsActiveLastMileTableMap = FSAdminActiveLastMileTableMap;
  fsActiveLastMileTableColumns = FSAdminActiveLastMileTableColumns;

  CollapsibleTableTypeEnum = CollapsibleTableTypeEnum;

  constructor(private postDispatchService: PostDispatchService) {}

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy(): void {
    this.failedRoutesSubscription?.unsubscribe();
  }

  getData(): void {
    this.handleFailedLastMiles({ Amount: this.failedRoutesTotal, Direction: PaginateEnum.None });
  }

  handleFailedLastMiles($event: Paginate): void {
    this.failedRoutesAmount = $event.Amount;
    this.failedRoutesSubscription?.unsubscribe();
    this.failedRoutesSubscription = this.postDispatchService.getFailedFirestoreLastmiles(false)
      .subscribe((response) => {
        const res = response.filter(x => (x.HasFailedCollection === true) || (x.HasFailedDelivery === true));
        this.failedRoutes = from([res]);
        this.failedRoutesTotal = res.length;
      });
  }
}
