<div class="modal">
  <div class="modal-content">
    <app-icon class="closeModal" (click)="closeModal()" [icon]="IconTypes.Cross" [color]="UiColors.Grey3" [size]="2">
    </app-icon>
    <div *ngIf="!success">
      <h2>Schedule a Demo</h2>
      <h4>Are you a looking to learn more about our logistics solutions? Get in touch today.</h4>
      <app-grid-container [columns]="'repeat(1, 1fr)'" [padding]="'2rem 0rem'" [colGap]="'1rem'" [rowGap]="'1rem'">
        <input-field [type]="'text'" (valueChanged)="name = $event" [value]="name" [label]="'Name'"></input-field>
        <input-field [type]="'text'" (valueChanged)="workEmail = $event" [value]="workEmail" [label]="'Work Email'">
          <div class="invalidmessage" *ngIf="emailInvalid">
            <p>Work email not valid</p>
          </div>
        </input-field>
        <input-field [type]="'tel'" (valueChanged)="phone = $event" [value]="phone" [label]="'Phone'"></input-field>
        <div class="invalidmessage" *ngIf="phoneInvalid">
          <p>Phone number not valid</p>
        </div>
        <input-field [type]="'text'" (valueChanged)="company = $event" [value]="company" [label]="'Company'">
        </input-field>
        <input-field [type]="'text'" (valueChanged)="jobTitle = $event" [value]="jobTitle" [label]="'Job Title'">
        </input-field>
        <input-field [type]="'text'" (valueChanged)="country = $event" [value]="country" [label]="'Country'">
        </input-field>
        <app-dropdown class="dropdown" [label]="'SOLUTIONS OF INTEREST'"
          [activeItem]="userType ? userType : 'Select Solution Type'" [dropdownTheme]="'light'">
          <div dropdownRef>
            <a class="dropdown-item" (click)="userType = 'On Demand'">On Demand</a>
            <a class="dropdown-item" (click)="userType = 'Logistics Management'">Logistics Management</a>
            <a class="dropdown-item" (click)="userType = 'Fleet Management'">Fleet Management</a>
            <a class="dropdown-item" (click)="userType = 'Geocoding'">Geocoding</a>
            <a class="dropdown-item" (click)="userType = 'All'">All</a>
          </div>
        </app-dropdown>

        <br />
        <app-basic-button 
          class="submit" 
          [disabled]="!name && !workEmail" 
          [active]="true" 
          [title]="'Lets Chat'"
          [type]="buttonTypes.Highlight" 
          (click)="sendEmail()" 
          [processing]="processing">
        </app-basic-button>
      </app-grid-container>
    </div>
    <div *ngIf="success" class="success">
      <img [src]="'/assets/img/static-site/solutions/picup-tms.png'" alt="" />
      <h2>Request Successfully Sent</h2>
      <h4>One of our sales representatives will be in touch shortly.</h4>
      <br />
      <app-basic-button 
        class="button" 
        [disabled]="!name && !workEmail" 
        [active]="true" 
        [title]="'Close'"
        [type]="buttonTypes.Highlight" 
        (click)="close()" 
        [processing]="processing">
      </app-basic-button>
    </div>
  </div>
</div>