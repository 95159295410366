<div class="table-wrap lookup">
  <heading [icon]="Icons.Types.Search" [color]="Icons.Colors.Blue">
    <h4 class="heading-title">Global Order Search <app-icon appTooltip [tooltipText]="'Global order search has a minimum 3 character requirement.'" class="icon" [icon]="Icons.Types.QuestionCircle" [size]="2" [color]="Icons.Colors.Blue"></app-icon></h4>
    <div class="controls"></div>
  </heading>
  <div class="row gradient-bg actions-wrap">
    <div class="search-bar">
      <label for="search">
        <svg-icon src="../../../assets/img/icons/search.svg" class="icon"></svg-icon>
      </label>
      <input type="text" [(ngModel)]="searchText" [placeholder]="placeholderText" (keydown.enter)="searchOrders()" />
    </div>
    <app-basic-button
      [title]="'Search'"
      [processing]="processing"
      [type]="buttonTypes.Primary"
      (click)="searchOrders()"
      [disabled]="searchText.length < 3"
    ></app-basic-button>
  </div>
  <app-table
    *ngIf="searchResult"
    [columns]="environment.appVersion === 1 ? searchResultColumns : searchResultColumns2"
    [dataMap]="environment.appVersion === 1 ? searchResultDataMap : searchResultDataMap2"
    [rawData]="shownResults"
    [rowsSelectable]="false"
    [showAnnotations]="true"
    [primaryActions]="tablePrimaryActions"
    (actionTaken)="respondToTableAction($event)"
    [zeroDataMessage]="'No orders match this search criteria'"
    [showViewMore]="showViewMore"
    (moreDataRequested)="loadMoreTableData()"
    (viewAllRequested)="showAllData()"
    [showViewAll]="showViewMore"
  >
  </app-table>
</div>
