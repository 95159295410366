import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotesComponent } from './notes.component';
import { SharedModule } from 'app/shared/shared.module';
import { NotesModalComponent } from './notes-modal/notes-modal.component';

@NgModule({
  declarations: [NotesComponent, NotesModalComponent],
  exports: [NotesComponent],
  imports: [CommonModule, SharedModule],
})
export class NotesModule {}
