import { Pipe, PipeTransform } from '@angular/core';
import { ShipmentStateEnum } from '../new-trips-timeline/modules/order-timeline-visual/order-timeline-visual.interfaces';

@Pipe({
  name: 'mapShipmentState'
})
export class MapShipmentStatePipe implements PipeTransform {

  transform(value: string): string {
    return ShipmentStateEnum[value] ?? value;
  }

}
