<div class="processing-indicator">
  <figure class="indicator-wrap">
    <svg width="100%" height="100%">
      <circle
        class="bg-spinner"
        cx="50%"
        cy="50%"
        r="calc(50% - 5px)"
        stroke-dasharray="0"
        fill="transparent"
        stroke="rgba(0,0,0,.1)"
        stroke-width="5"
      ></circle>
      <circle
        class="bg-loader"
        cx="50%"
        cy="50%"
        r="calc(50% - 10px)"
        stroke-dasharray="0"
        fill="transparent"
        stroke="rgba(0,0,0,.2)"
        stroke-width="5"
      ></circle>
      <circle
        #processingIndicatorSpinner
        [ngClass]="{
          spinner: status !== _bucketStatuses.Completed,
          'spinner-hidden': status === _bucketStatuses.Completed,
          alert: title === 'Alert'
        }"
        cx="50%"
        cy="50%"
        r="calc(50% - 5px)"
        [attr.stroke-dasharray]="spinnerDashArray"
        [attr.stroke-dashoffset]="spinnerBgLength"
        fill="transparent"
        stroke-linecap="round"
        stroke-width="5"
      ></circle>
      <circle
        #processingIndicatorLoader
        [ngClass]="{ loader: percentageComplete > 0, 'loader-hidden': percentageComplete === 0 }"
        cx="50%"
        cy="50%"
        r="calc(50% - 10px)"
        [attr.stroke-dasharray]="loaderDashArray"
        fill="transparent"
        stroke-linecap="round"
        stroke-width="5"
      ></circle>
    </svg>
  </figure>
  <div
    class="content"
    [ngClass]="{
      alert: title === 'Alert'
    }"
  >
    <div class="title">{{ title }}</div>
    <div class="heading">{{ status }}</div>
    <div *ngIf="message" class="message">{{ message }}</div>
    <div *ngIf="timeLeft" class="time-left">
      <div class="text">About</div>
      <div class="time">{{ timeLeft }}</div>
    </div>

  </div>

  <div class="error">
    <h4>{{ error }}</h4>
  </div>

 
</div>
