import { IconProps, IconTypes } from '../icon/icon.interfaces';
import { UiColors } from '../../interfaces/ui.interfaces';

export const enum TablePrefixes {
  Zar = 'R ',
}

export const enum TablePostfixes {
  Km = ' km',
}

export const enum DisplayPipes {
  decimalsNone = '1.0-0',
  decimalsTwo = '1.2-2',
}

export enum SortDirection {
  ASC = 'asc',
  DES = 'desc',
}

export interface TableAction {
  event: string;
  title: string;
  icon: IconTypes;
  icon_color?: UiColors;
  dependency?: string;
  reverse_dependency?: string;
  field?: string;
  valuesArray?: any;
  hidden?: boolean;
  rows?: any[];
}

export interface TableColumn {
  name: string;
  displayName?: string;
  hidden?: boolean;
  shouldCalculate?: boolean;
  sortable?: boolean;
  sortDirection?: SortDirection;
  field?: string;
}

export interface TableDataByColumn {
  column: string;
  sortData: TableSortData[];
  values: (string | number)[];
  shouldCalculate?: boolean;
  shouldSort?: boolean;
  prefix?: string;
  postfix?: string;
  displayPipeArgs?: string;
}

export interface TableSummaryData {
  totals: TableRow;
  averages: TableRow;
}

export interface TableRow {
  cells: TableCell[];
  index?: number;
  heading?: string;
  selected?: boolean;
  isSelectable?: boolean;
}

export interface TableCell {
  column: string;
  value: string | number;
  annotation?: string | number;
  highlighted?: boolean;
  highlightColorClass?: string;
  icon?: IconTypes;
  iconColor?: UiColors;
  iconSizeRem?: number;
  color?: string;
  annotation_color?: string;
  prefix?: string;
  postfix?: string;
  displayPipeArgs?: string | [string] | [string, string];
  displayAnnotationPipeArgs?: string | [string] | [string, string];
}

export interface TableActionTaken {
  action: TableAction;
  rows: TableRow[];
}

export type TableDataMapFormatter = (
  maps: (firebase.default.firestore.Timestamp | string | number)[],
  item: unknown
) => string | number;
export type TableDataMapIconFormatter = (maps: string[], item: unknown, icon: IconProps) => IconProps;
export type TableDataSetHighlightColor = (maps: (string | number)[], item: unknown) => string;

export interface TableDataMap {
  row?: {
    isSelectable?: string;
    heading?: string;
    selected?: string;
  };
  cells: {
    [propName: string]: {
      column: string;
      map: string | (string | boolean)[];
      annotation_map?: string | string[];
      format?: TableDataMapFormatter;
      formatAnnotation?: TableDataMapFormatter;
      highlighted?: boolean;
      highlightColorClass?: TableDataSetHighlightColor;
      highlightColorMap?: string[];
      icon?: IconTypes;
      iconColor?: UiColors;
      iconSizeRem?: number;
      iconMap?: string[];
      iconFormat?: TableDataMapIconFormatter;
      color?: string;
      annotation_color?: string;
      prefix?: string;
      postfix?: string;
      displayPipeArgs?: string | [string] | [string, string];
      displayAnnotationPipeArgs?: string | [string] | [string, string];
    };
  };
}

export interface TableSortData {
  index: number;
  value: string | number;
}

export interface TableColumnSortedState {
  [propName: string]: {
    status?: SortDirection;
  };
}

export interface SortEvent {
  direction: 'asc' | 'desc';
  field: string;
}
