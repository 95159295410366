<app-modal
  [size]="ModalSizes.Large"
  [open]="true"
  [actions]="actions"
  [highlightPrimaryAction]="true"
  (handleAction)="handleAction($event)"
>
  <heading [icon]="IconTypes.Bucket" [color]="uiColors.Blue">
    <h4 class="heading-title">Select a bucket to transfer to</h4>
    <app-basic-button [title]="'Create New Bucket'" [type]="buttonTypes.Primary" (click)="createNewBucket()"> </app-basic-button>
  </heading>
  <div class="modal-content">
    <app-table
      [rawData]="availableBuckets"
      [dataMap]="availableBucketsTableDataMap"
      [columns]="availableBucketsTableColumns"
      [isLoading]="!availableBuckets"
      [primaryActions]="availableBucketsPrimaryActions"
      (actionTaken)="selectBucket($event)"
      [rowsSelectable]="false"
    >
    </app-table>
  </div>
</app-modal>
