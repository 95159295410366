<app-modal
  [open]="true"
  [actions]="actions"
  [highlightPrimaryAction]="true"
  [size]="ModalSizes.Large"
  (handleAction)="handleAction($event)"
>
  <heading [icon]="IconTypes.Courier" [color]="uiColors.Blue">
    <h4 class="heading-title">{{ 'Reconcile ' + route_type + ' Route' | uppercase }}</h4>
    <mat-icon
      matTooltip="Select business references to filter by, and then select the state you'd like those parcels to be placed in"
      class="help-icon"
      >help</mat-icon
    >
  </heading>
  <div class="modal-body">
    <app-section>
      <app-grid-container [columns]="'repeat(2, 1fr)'" [colGap]="'2rem'" [rowGap]="'2rem'" [padding]="'0 2rem'">
        <div>Filter by Business Reference</div>
        <div>Set Parcel state</div>
        <mat-form-field appearance="fill">
          <mat-label>Business References</mat-label>
          <mat-select [formControl]="references" multiple>
            <mat-option *ngFor="let reference of businessReferences" [value]="reference">{{ reference }}</mat-option>
          </mat-select>
        </mat-form-field>
        <app-dropdown
          [activeItem]="all_result !== undefined ? all_result : 'Set Parcel State'"
          [dropdownTheme]="'light'"
          [labelTheme]="'dark'"
        >
          <div dropdownRef>
            <ng-template ngFor let-option [ngForOf]="warehouse_options">
              <a class="dropdown-item" (click)="setAll(option)">{{ option | humanize }}</a>
            </ng-template>
          </div>
        </app-dropdown>
      </app-grid-container>
      <app-grid-container [columns]="'repeat(1, 1fr)'" [colGap]="'2rem'" [rowGap]="'2rem'" [padding]="'1rem 2rem 0rem'">
        <input-field
          [isInvalid]="!reconcile_reason && displayInvalid"
          [type]="'text'"
          [labelTheme]="'dark'"
          (valueChanged)="reconcile_reason = $event"
          [autoComplete]="'off'"
          [value]="reconcile_reason"
          [label]="'Reconcile Reason'"
          [required]="true"
        >
        </input-field>
      </app-grid-container>
    </app-section>
    <hr />
    <app-grid-container [columns]="'repeat(4, 1fr)'" [colGap]="'2rem'" [rowGap]="'2rem'" [padding]="'0 2rem'">
      <div class="head">
        {{ 'Parcel Reference' }}
      </div>
      <div class="head">{{ 'Parcel Waybill' }}</div>
      <div class="head">{{ 'Parcel State' }}</div>
      <div class="head">{{ 'Action' }}</div>
    </app-grid-container>
    <app-section *ngFor="let parcel of filteredParcels; let i = index">
      <app-grid-container
        [alignItems]="'center'"
        [columns]="'repeat(4, 1fr)'"
        [colGap]="'2rem'"
        [rowGap]="'2rem'"
        [padding]="'0 2rem'"
      >
        <div class="info">
          <span>
            <svg-icon [svgStyle]="{ 'width.rem': '2' }" src="/assets/img/icons/parcel.svg"></svg-icon>
            {{ parcel.ParcelReference }}
          </span>
        </div>

        <div class="info">
          <span>
            <svg-icon [svgStyle]="{ 'width.rem': '2' }" src="/assets/img/icons/parcel.svg"></svg-icon>
            {{ parcel.ParcelWaybill }}
          </span>
        </div>
        <div class="status">
          <!-- TODO: Remove conditional once RM's are the same -->
          {{ parcel.ParcelState ? parcel.ParcelState : parcel.LastMileParcelState }}
        </div>

        <app-dropdown
          [disabled]="
            (parcel.ParcelState ? parcel.ParcelState : parcel.LastMileParcelState) === 'Delivered' ||
            (parcel.ParcelState ? parcel.ParcelState : parcel.LastMileParcelState) === 'Returned' ||
            (route_type === 'available' && parcel.route_number)
          "
          [activeItem]="
            parcel.result
              ? parcel.result
              : (parcel.ParcelState ? parcel.ParcelState : parcel.LastMileParcelState) === 'Delivered' ||
                (parcel.ParcelState ? parcel.ParcelState : parcel.LastMileParcelState) === 'Returned' ||
                (route_type === 'available' && parcel.route_number)
              ? 'Actioned'
              : 'Select Result'
          "
          [dropdownTheme]="'light'"
          [labelTheme]="'dark'"
        >
          <div dropdownRef>
            <ng-template ngFor let-option [ngForOf]="warehouse_options">
              <a class="dropdown-item" (click)="parcel['result'] = option">{{ option | humanize }}</a>
            </ng-template>
          </div>
        </app-dropdown>
      </app-grid-container>
      <app-grid-container
        *ngIf="
          parcel.result !== undefined &&
          parcel.result !== 'Collect' &&
          parcel.result !== 'Deliver' &&
          parcel.result !== 'Return'
        "
        [columns]="'repeat(1, 1fr)'"
        [colGap]="'2rem'"
        [rowGap]="'2rem'"
        [padding]="'1rem 2rem 0rem'"
      >
        <input-field
          [type]="'text'"
          [labelTheme]="'dark'"
          (valueChanged)="parcel['failure_reason'] = $event"
          [autoComplete]="'off'"
          [value]="parcel.failure_reason"
          [label]="'Failure Reason'"
          [required]="true"
        >
        </input-field>
      </app-grid-container>
    </app-section>
  </div>
</app-modal>
