
<app-modal 
  [open]="true" 
  [actions]="actions" 
  [highlightPrimaryAction]="true" 
  [size]="ModalSizes.Medium"
  (handleAction)="handleAction($event)">
  <heading 
    [icon]="IconTypes.Bucket" 
    [color]="UiColors.Blue">
    <h4 class="heading-title">Name your zone</h4>
  </heading>

  <div class="modal-body">
    <div class="form-wrap">
      <div class="row">
        <input-field [type]="'text'" (valueChanged)="zone_name = $event" [value]="zone_name" [label]="'Name'">
        </input-field>
      </div>
    </div>
  </div>

</app-modal>