import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ProgressComponent implements OnInit {
  @Input() status: string;

  currentStatus: string

  ngOnInit() {
    let state: string;
    switch (this.status) {
      case 'IMPORTING':
      case 'IMPORTED':
      case 'GEOCODING':
        state = 'geocoding';
        break;
      case 'AUTO_GEOCODED':
        state = 'Verifying';
        break;
      case 'SENT_TO_ROUTIFIC':
      case 'CHECK_ROUTIFIC':
      case 'ROUTIFIC_COMPLETE':
      case 'COSTING':
        state = 'processing';
        break;
      case 'COSTED':
        state = 'trip_selection';
        break;
      case 'CONFIRMED':
        state = 'finalise';
        break;
      default:
        state = 'geocoding';
        break;
    }
    this.currentStatus = state;
  }

  isGeocodingComplete() {
    return this.status === 'AUTO_GEOCODED' ||
           this.status === 'SENT_TO_ROUTIFIC' ||
           this.status === 'CHECK_ROUTIFIC' ||
           this.status === 'ROUTIFIC_COMPLETE' ||
           this.status === 'COSTING' ||
           this.status === 'COSTED' ||
           this.status === 'CONFIRMED';
  }

  isVerifyComplete() {
    return this.status === 'SENT_TO_ROUTIFIC' ||
           this.status === 'CHECK_ROUTIFIC' ||
           this.status === 'ROUTIFIC_COMPLETE' ||
           this.status === 'COSTING' ||
           this.status === 'COSTED' ||
           this.status === 'CONFIRMED';
  }

  isProcessingComplete() {
    return this.status === 'COSTING' ||
           this.status === 'COSTED' ||
           this.status === 'CONFIRMED';
  }

  isTripSelectionComplete() {
    return this.status === 'CONFIRMED';
  }

  isFinaliseComplete() {
    return this.status === 'CONFIRMED';
  }
}
