import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { AuthService } from 'app/auth/auth.service';
import { signUpInterface } from 'app/login/login.component';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { debounce, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit, OnDestroy {
  @Input() prefilledEmail: string;
  @Output() signUp = new EventEmitter<signUpInterface>();
  signUpFormGroup: FormGroup = new FormGroup({
    firstNameControl: new FormControl('', Validators.required),
    lastNameControl: new FormControl('', Validators.required),
    phoneControl: new FormControl('', [Validators.required, CustomTelValidator()]),
    emailControl: new FormControl('',[Validators.email, Validators.required]),
    passwordControl: new FormControl('',[Validators.required, Validators.minLength(3)], [this.passwordValidators()]),
    confirmControl: new FormControl('',[Validators.required])
  }, [ConfirmPasswordValidator('passwordControl', 'confirmControl')]);

  hidePassword = true;
  hidePasswordConfirm = true;

  passwordChanged$ = new Subject<void>();
  passwordErrors$ = new BehaviorSubject<string[]>([]);
  set passwordErrors(value: string[]) {
    this.passwordErrors$.next(value);
  }

  unsubscribe$ = new Subject<void>();
  tooltipString = null;

  constructor(public authService: AuthService){}

  ngOnInit(): void {
    if(this.prefilledEmail){
      this.signUpFormGroup.controls.emailControl.setValue(this.prefilledEmail);
    }

    this.passwordErrors$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      this.tooltipString = null;
      if(value?.length > 0){
        this.tooltipString = '';
        value.forEach(element => {
          this.tooltipString = this.tooltipString + '\n' + element;
        });
      }
    })
  }

  ngOnDestroy(): void {
    this.passwordChanged$.next();
    this.passwordChanged$.complete()
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  emitSignUp(): void{
    if(this.signUpFormGroup.valid){
      const signUpData: signUpInterface = {
        name: this.signUpFormGroup.controls.firstNameControl.value + ' ' + this.signUpFormGroup.controls.lastNameControl.value,
        phone: this.signUpFormGroup.controls.phoneControl.value,
        email: this.signUpFormGroup.controls.emailControl.value,
        password: this.signUpFormGroup.controls.passwordControl.value
      }
      this.signUp.emit(signUpData);
    }
  }

  passwordValidators(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      const value = control.value;
      if(!value){
        return null;
      }
      this.passwordChanged$.next()
      return this.authService.CheckPasswordComplexity(value)
        .pipe(
          takeUntil(this.passwordChanged$),
          map((errors) => {
            if(errors.Problems.length > 0) {
            // build errors
              const errorArray = []
              const array = []
              errors.Problems.forEach(err => {
                errorArray.push({[err.Code.toString()]: err.Message})
                array.push(err.Message)
              });
              this.passwordErrors = array;
              return of(errors)
            }
            this.passwordErrors = []
            return null
          }));
    }
  }

  get password(): AbstractControl {
    return this.signUpFormGroup.get('passwordControl');
  }
}

export function ConfirmPasswordValidator(controlName: string, matchingControlName: string): ValidatorFn {
  return (abstractControl: AbstractControl): ValidationErrors | null => {
    const control = abstractControl.get(controlName);
    const matchingControl = abstractControl.get(matchingControlName);

    if(!matchingControl.value){
      return null;
    }

    const matching = (control.value === matchingControl.value);
    matchingControl.setErrors(!matching ? {passwordMismatch: true} : null);
    return null
  }
}

export function CustomPasswordValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if(!value){
      return null;
    }
    const hasUpperCase = /[A-Z]+/.test(value);
    const hasLowerCase = /[a-z]+/.test(value);
    const hasNumeric = /[0-9]+/.test(value);
    const hasSymbol = /\W+/.test(value);
    if(hasUpperCase && hasLowerCase && hasNumeric && hasSymbol){
      return null
    }

    return {
      missingGroup: true,
      missingUpperCase: !hasUpperCase,
      missingLowerCase: !hasLowerCase,
      missingNumeric: !hasNumeric,
      missingSymbol: !hasSymbol
    }
  }
}

export function CustomTelValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if(!value){
      return null;
    }

    const startsWithPlusOrNumber = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(value);
    return !startsWithPlusOrNumber ? {invalidNumber: !startsWithPlusOrNumber} : null
  }
}
