import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { OrderTimelineFirestore } from 'app/dashboard/new-trips/new-trips.interfaces';
import { Injectable, OnDestroy } from '@angular/core';

@Injectable()
export class NewTripsTimelineService implements OnDestroy{
  private readonly _orderId: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  readonly orderId$ = this._orderId.asObservable();

  private readonly _tripTimelineData$: BehaviorSubject<OrderTimelineFirestore | null> = new BehaviorSubject<OrderTimelineFirestore | null>(null);
  readonly tripTimelineData$ = this._tripTimelineData$.asObservable();

  private unsubscribe$ = new Subject<void>();

  constructor(private firestore: AngularFirestore) {
    this.orderId$.pipe(
      takeUntil(this.unsubscribe$),
      switchMap((orderId: string) => {
        if (orderId) {
          return this.getOrderTimelineData(orderId);
        } else {
          return of(null);
        }
      })
    ).subscribe((data: OrderTimelineFirestore) => {
      this._tripTimelineData$.next(data);
    });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getOrderTimelineData(orderId: string): Observable<OrderTimelineFirestore> {
    return this.firestore
      .collection<OrderTimelineFirestore>('order-timeline').doc(orderId).valueChanges();
  }

  setOrderId(orderId: string): void {
    this._orderId.next(orderId);
  }
}
