<div class="modal">
    <div class="modal-content">
      <heading [icon]="Icons.Types.Geocoded" [color]="Icons.Colors.Blue">
        <h4 class="heading-title">Set Warehouse Rates</h4>
      </heading>
  
      <div class="modal-body">
        <div class="form-wrap">
          <div class="row">
            <select-field [label]="'Pricing Strategy'" (valueChanged)="parcel_size = $event.value"
              [options]="pricingModels" [selectedOption]="pricingModels[2]"></select-field>
            <input-field [type]="'text'" (valueChanged)="name = $event" [value]="name" [label]="'Name in CSV'"></input-field>
          </div>
        </div>
        <div class="modal-footer">
          <app-basic-button [title]="'Cancel'" [type]="buttonTypes.Tertiary" (click)="cancel();"></app-basic-button>
          <app-basic-button [title]="'Add Code'" [type]="buttonTypes.Secondary" (click)="addGiftCode()"></app-basic-button>
        </div>
      </div>
    </div>
  </div>
  