import { Component } from '@angular/core';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { ModalAction, ModalSizes } from 'app/shared/modals/modal.interfaces';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  selector: 'app-reconcile-courier-order-modal',
  templateUrl: './reconcile-courier-order-modal.component.html',
  styleUrls: ['./reconcile-courier-order-modal.component.scss'],
})
export class ReconcileCourierOrderModalComponent
  extends SimpleModalComponent<any, any>
  implements ReconcileCourierOrderModalComponent {
  IconTypes = IconTypes;
  ModalSizes = ModalSizes;
  uiColors = UiColors;
  public actions: ModalAction[] = [
    { name: 'close', title: 'Close', isPrimary: false },
    { name: 'confirm', title: 'Confirm', isPrimary: true },
  ];

  selectedStateEnum;
  selectedStateName;
  overrideReason;

  states = [
    { name: 'WaybillCreationFailed', value: 19 },
    { name: 'Collected', value: 2 },
    { name: 'FailedCollection', value: 3 },
    { name: 'Delivered', value: 4 },
    { name: 'DeliveryAttemptFailed', value: 5 },
    { name: 'Cancelled', value: 6 },
    { name: 'WaybillCreated', value: 7 },
    { name: 'ReadyForCollection', value: 9 },
    { name: 'OutForCollection', value: 10 },
    { name: 'InTransit', value: 15 },
    { name: 'OutForDelivery', value: 16 },
    { name: 'AtCourierCenter', value: 40 },
    { name: 'Returned', value: 75 },
    { name: 'FinalDeliveryAttemptFailed', value: 18 },
  ];
  constructor() {
    super();
  }

  setActiveState(state) {
    this.selectedStateEnum = state.value;
    this.selectedStateName = state.name;
  }

  handleAction($event: string): void {
    switch ($event) {
      case 'close':
        this.close();
        break;
      case 'confirm':
        this.result = [this.selectedStateEnum, this.overrideReason];
        this.close();
        break;
    }
  }
}
