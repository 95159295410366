import { EventEmitter } from '@angular/core';
import { ButtonTypes } from '../buttons/basic-button.component';

export interface ModalComponentProps {
  open: boolean;
  actions: ModalAction[];
  size: ModalSizes;
  highlightPrimaryAction: boolean;
  handleAction: EventEmitter<string>;
}

export enum ModalSizes {
  Small = 'modal-small',
  Medium = 'modal-medium',
  Large = 'modal-large',
  XLarge = 'modal-xlarge',
  FullScreen = 'modal-fullscreen',
}

export interface ModalAction {
  name: string | ButtonTypes;
  title: string;
  isPrimary?: boolean;
  active?: boolean;
  disabled?: boolean;
  processing?: boolean;
}
