import { environment } from './../../../../../environments/environment';
import { selectorUser } from './../../../../auth/auth.reducer';
import { AuthUser } from './../../../../interfaces/auth.interfaces';
import { Component, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import axios from 'app/api/axios';
import { selectorActiveBucketId } from '../../manage-bucket.reducer';
import { selectorActingAs } from '../../../../auth/auth.reducer';
import { NotificationsService } from '../../../../shared/notifications/notifications.service';
import { IconTypes } from '../../../../shared/icon/icon.interfaces';
import { UiColors, UiThemes } from '../../../../interfaces/ui.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { BucketContractors, ProcessBucketOptions, ProcessBucketOptionsRequest } from '../../buckets.interfaces';
import { VehicleSizesEnum } from '../../../../interfaces/vehicle-type.interfaces';
import { ActingAs } from 'app/interfaces/auth.interfaces';
import { NotificationTypeEnum, PackageTypeEnum } from 'app/shared/shared.interfaces';
import { ManageBucketService } from '../../manage-bucket.service';
import { SettingsService } from 'app/dashboard/settings/settings.service';
import { ConfirmModalComponent } from 'app/shared/modals/confirm-modal.component';
import { SimpleModalService } from 'ngx-simple-modal';
import { StartEndPointService } from 'app/shared/start-end-point/start-end-point.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { StartEndPointComponent } from 'app/shared/start-end-point/start-end-point.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-costing-settings',
  templateUrl: './costing-settings.component.html',
  styleUrls: ['./costing-settings.component.scss'],
  providers: [
    StartEndPointService
  ]
})
export class CostingSettingsComponent implements OnInit, OnDestroy {
  @Input() readOnly = false;
  settingsOpen: boolean = false;
  multipleVehicles: boolean = false;
  Icons = {
    Colors: UiColors,
    Types: IconTypes,
  };
  actingAs: ActingAs;
  fleet_management: boolean = false;
  environment = environment;
  buttonTypes = ButtonTypes

  barcodeOptions = new Map<string, string>([
    ['UseTrackingNumber', 'Use Tracking Number (Default)'],
    ['DoNotAssign', 'Do Not Assign (Generic)'],
    ['UseParcelWaybill', 'Use Parcel Waybill'],
  ]);

  // ENUM DECLARED
  // Giving vehicles a type gives a type error
  vehicles: string[] = Object.values(VehicleSizesEnum);

  public ButtonTypes = ButtonTypes;
  public UiThemes = UiThemes;

  inProcess: boolean = false;
  bucketId: number;
  is_courier_only: boolean = false;
  requires_cash_deposits: boolean = false;
  process_bucket_options: ProcessBucketOptions;
  bucket_contractors: BucketContractors = {
    car_count: 0,
    car_preferred_drivers: [],
    large_van_count: 0,
    large_van_preferred_drivers: [],
    motorcycle_count: 0,
    motorcycle_preferred_drivers: [],
    small_van_count: 0,
    small_van_preferred_drivers: [],
  };
  cash_deposit_threshold: number;
  selected_vehicles: string[] = [];
  user: AuthUser;
  PackageTypeEnum = PackageTypeEnum;
  useNewContractorSettings: boolean = false;
  autoStartPicupLottery: boolean;
  unsubscribe$ = new Subject<void>();
  constructor(
    public store: Store<any>,
    private notificationsService: NotificationsService,
    private bucketService: ManageBucketService,
    private settingsService: SettingsService,
    private simpleModalService: SimpleModalService,
    private startEndPointService: StartEndPointService,
    private dialog: MatDialog,
    private viewContainerRef: ViewContainerRef
  ) {
    this.store.select(selectorActiveBucketId).subscribe((next) => (this.bucketId = next));
    this.store.select(selectorActingAs).subscribe((next) => (this.actingAs = next));
    this.store.select(selectorUser).subscribe((next) => {
      this.user = next;
    });
  }

  ngOnInit(): void {
    this.bucketService
      .getBusinessLotteryOptions()
      .then((res) => {
        this.autoStartPicupLottery = res.data.should_run_lottery;
      })
      .catch((error) => {
        throw error.response?.data;
      });

    this.getContractors();
    this.settingsService
      .getContractorConsignmentRadiusCutOffMeter(this.bucketService.activeBucket?.warehouse_id)
      .then((response) => {
        if (response) {
          this.useNewContractorSettings = true;
          if (
            (this.actingAs.package === PackageTypeEnum.TMS || this.actingAs.package === PackageTypeEnum.Freight) &&
            this.user.is_admin
          ) {
            this.fleet_management = true;
          }
        } else {
          if (this.actingAs.package === PackageTypeEnum.TMS || this.actingAs.package === PackageTypeEnum.Freight) {
            this.fleet_management = true;
          }
        }
      });
    axios
      .get('bucket/' + this.bucketId + '/get-process-bucket-options/')
      .then((response) => {
        this.process_bucket_options = response?.data;
        this.is_courier_only = this.process_bucket_options.is_courier_only;
        this.checkDefaultVehicles();
        this.vehicles?.forEach((vehicle) => {
          if (this.process_bucket_options.fleet[vehicle].count > 0) {
            this.selected_vehicles.push(vehicle);
          }
        });
      })
      .catch((error) =>
        this.notificationsService.publish({ message: error.response?.data.message, type: NotificationTypeEnum.Error })
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete();
  }

  lotteryConfirmationModal(): void {
    if (!this.autoStartPicupLottery && !this.process_bucket_options.is_schedule_lottery) {
      this.simpleModalService
        .addModal(ConfirmModalComponent, {
          title: 'Lottery Autostart disabled',
          message: 'This lottery auto start has been disabled, are you sure you want to overwrite this',
        })
        .subscribe((isConfirmed) => {
          if (!isConfirmed) {
            return;
          }
          this.process_bucket_options.is_schedule_lottery = !this.process_bucket_options.is_schedule_lottery;
        });
    } else {
      this.process_bucket_options.is_schedule_lottery = !this.process_bucket_options.is_schedule_lottery;
    }
  }

  toggleSettings(): void {
    this.settingsOpen = !this.settingsOpen;
  }
  checkDefaultVehicles(): void {
    let i = 0;
    this.vehicles?.forEach((vehicle) => {
      if (this.process_bucket_options.fleet[vehicle].count > 0) {
        i++;
      }
      if (i > 1) {
        this.multipleVehicles = true;
      } else {
        this.multipleVehicles = false;
      }
    });
  }

  getContractors(): Promise<void> {
    return this.bucketService.getContractors().then((response) => {
      if (response) {
        this.bucket_contractors = response;
      }
    });
  }

  onProcessBucket(): void {
    // OVERWRITE THE BUSINESS AUTO START LOTTERY WHEN THE USER SELECTS NO
    if (!this.process_bucket_options.is_schedule_lottery) {
      this.autoStartPicupLottery = true;
    }
    if (!this.autoStartPicupLottery) {
      this.simpleModalService
        .addModal(ConfirmModalComponent, {
          title: 'Lottery Autostart disabled',
          message: 'This lottery auto start has been disabled, are you sure you want to overwrite',
        })
        .subscribe((isConfirmed) => {
          if (!isConfirmed) {
            return;
          }
          this.processBucket();
        });
    } else {
      this.processBucket();
    }
  }

  processBucket(): void {
    this.inProcess = true;
    this.vehicles?.forEach((vehicle) => {
      if (!this.process_bucket_options.fleet[vehicle].count) {
        this.process_bucket_options.fleet[vehicle].count = 0;
      }
      if (!this.process_bucket_options.fleet[vehicle].capacity) {
        this.process_bucket_options.fleet[vehicle].capacity = 0;
      }
    });
    this.process_bucket_options.cash_deposit_threshold = this.cash_deposit_threshold
      ? parseFloat(this.cash_deposit_threshold.toString())
      : 0;
    if (this.is_courier_only) {
      this.process_bucket_options.is_courier_only = true;
    } else {
      this.process_bucket_options.is_courier_only = false;
    }
    if (this.requires_cash_deposits) {
      this.process_bucket_options.requires_cash_deposits = true;
    } else {
      this.process_bucket_options.requires_cash_deposits = false;
    }

    const request: ProcessBucketOptionsRequest = {
      bucket_contractors: this.bucket_contractors,
      process_bucket_options: this.process_bucket_options,
    };

    const startEndPointValues = this.startEndPointService?.outModel$?.getValue();
    if (startEndPointValues) {
      request.process_bucket_options.start_location = startEndPointValues?.startAddress;
      request.process_bucket_options.end_location = startEndPointValues?.endAddress;
    }

    axios
      .post(`bucket/${this.bucketId}/process-bucket/`, request)
      .then(() =>
        this.notificationsService.publish({
          message: 'Starting to process this bucket',
          type: NotificationTypeEnum.Success,
        })
      )
      .catch((error) =>
        this.notificationsService.publish({
          message: error.response?.data.message,
          type: NotificationTypeEnum.Error,
        })
      )
      .finally(() => (this.inProcess = false));
  }

  selectVehicle(vehicle: string): void {
    if (!this.readOnly) {
      this.setOptionsForVehicles(0);
      this.selected_vehicles = [];
      this.selected_vehicles.push(vehicle);
      this.process_bucket_options.fleet[vehicle].count = 100;
    }
  }

  setOptionsForVehicles(amount: number): void {
    this.process_bucket_options.fleet['vehicle-motorcycle'].count = amount;
    this.process_bucket_options.fleet['vehicle-car'].count = amount;
    this.process_bucket_options.fleet['vehicle-small-van'].count = amount;
    this.process_bucket_options.fleet['vehicle-large-van'].count = amount;
  }

  contractorsChanged(contractors: BucketContractors): void {
    this.bucket_contractors = contractors;
  }

  openStartEndTaskModal(): void{
    const dialogRef: MatDialogRef<StartEndPointComponent> =
    this.dialog.open(
      StartEndPointComponent,
      {
        panelClass: 'noBorder',
        minHeight: '30%',
        minWidth: '70%',
        viewContainerRef: this.viewContainerRef
      }
    );

    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
      });
  }
}
