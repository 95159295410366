<input type="file" style="display: none" #file (change)="onFileAdded()" />
<app-basic-button
  *ngIf="showEditConsignment"
  [title]="'Edit Consignment'"
  [processing]="processing"
  [type]="ButtonTypes.Primary"
  (click)="editConsignment()"
></app-basic-button>
<!-- <app-basic-button [title]="'Create Consignment'" [processing]="processing" [type]="buttonTypes.Highlight"
    (click)="createConsignment();"></app-basic-button> -->
<app-basic-button
  [title]="'Upload Deliveries'"
  [processing]="processing"
  [type]="ButtonTypes.Highlight"
  (click)="uploadCSV()"
  [active]="true"
></app-basic-button>

<app-basic-button
  *ngIf="showGenerateRandomBucket"
  [title]="'Generate Random Bucket'"
  [processing]="processing"
  [type]="ButtonTypes.Highlight"
  [active]="true"
  (click)="generateRandomBucket()"
>
</app-basic-button>
