import { CourierOrdersModule } from './../courier-orders/courier-orders.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewTripsComponent } from './new-trips.component';
import { SharedModule } from 'app/shared/shared.module';
import { AssignDriverloadComponent } from './driver-load/assign-driverload/assign-driverload.component';
import { NewTripsRoutingModule } from './new-trips-routing.module';
import { StoreModule } from '@ngrx/store';
import { NewTripsReducer } from './store/new-trips.reducer';
import { NewTripsMapComponent } from './new-trips-map/new-trips-map.component';
import { NewTripsSumnmaryComponent } from './new-trips-sumnmary/new-trips-sumnmary.component';
import { ActionParcelsComponent } from './driver-load/action-parcels/action-parcels.component';
import { NewTripsRoutesComponent } from './new-trips-routes/new-trips-routes.component';
import { AssignParcelsComponent } from './driver-load/assign-parcels/assign-parcels.component';
import { NewTripsTimelineComponent } from './new-trips-timeline/new-trips-timeline.component';
import { NewTripsNotesComponent } from './new-trips-notes/new-trips-notes.component';
import { NewTripsFailedComponent } from './new-trips-failed/new-trips-failed.component';
import { DirectivesModule } from 'app/directives/directives.module';
import { NewTripsLotteryComponent } from './new-trips-lottery/new-trips-lottery.component';
import { LotteryDetailsComponent } from './new-trips-lottery/lottery-details/lottery-details.component';
import { NotesModule } from '../notes/notes.module';
import { NewTripsFailedCourierComponent } from './new-trips-failed-courier/new-trips-failed-courier.component';
import { LastMileModule } from '../last-mile/last-mile.module';
import { NewTripsHistoryComponent } from './new-trips-history/new-trips-history.component';
import { HistoricWaypointRouteComponent } from './new-trips-history/historic-waypoint-route/historic-waypoint-route.component';
import { NewTripsActivationDialogComponent } from './new-trips-activation-dialog/new-trips-activation-dialog.component';
import { TripHealthPipe } from './pipes/trip-health.pipe';
import { ActivationDatePipe } from './pipes/activation-date.pipe';
import { OpenNewTripDialogComponent } from './open-new-trip-dialog/open-new-trip-dialog.component';
import { AssignmentMethodPipe } from './assignment-method.pipe';
import { OrderTimelineVisualComponent } from './new-trips-timeline/modules/order-timeline-visual/order-timeline-visual.component';
import { TimelinePointComponent } from './new-trips-timeline/modules/order-timeline-visual/components/timeline-point/timeline-point.component';
import { MapShipmentStatePipe } from './pipes/map-shipment-state.pipe';
import { TimelineEventNamingPipe } from './pipes/timeline-event-naming.pipe';

@NgModule({
  declarations: [
    NewTripsComponent,
    AssignDriverloadComponent,
    NewTripsMapComponent,
    NewTripsSumnmaryComponent,
    ActionParcelsComponent,
    NewTripsRoutesComponent,
    AssignParcelsComponent,
    NewTripsTimelineComponent,
    NewTripsNotesComponent,
    NewTripsFailedComponent,
    NewTripsLotteryComponent,
    LotteryDetailsComponent,
    NewTripsFailedCourierComponent,
    NewTripsHistoryComponent,
    HistoricWaypointRouteComponent,
    NewTripsActivationDialogComponent,
    TripHealthPipe,
    ActivationDatePipe,
    OpenNewTripDialogComponent,
    AssignmentMethodPipe,
    OrderTimelineVisualComponent,
    TimelinePointComponent,
    MapShipmentStatePipe,
    TimelineEventNamingPipe,
  ],
  imports: [
    LastMileModule,
    DirectivesModule,
    CommonModule,
    SharedModule,
    NewTripsRoutingModule,
    NotesModule,
    CourierOrdersModule,
    StoreModule.forFeature('trips', { manageTrips: NewTripsReducer }),
  ],
  exports: [NewTripsFailedComponent],
})
export class NewTripsModule {}
