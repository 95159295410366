import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MapViewService } from '../map-view.service';
import { WaypointMarkerColors } from 'app/dashboard/new-trips/new-trips.constants';

@Component({
  selector: 'app-map-waypoint-marker',
  templateUrl: './map-waypoint-marker.component.html',
  styleUrls: ['./map-waypoint-marker.component.scss'],
})
export class MapWaypointMarkerComponent implements OnInit, OnDestroy {
  @Input() waypoint;
  @Input() mapElement;
  @Input() index;
  marker;

  constructor(private mapViewService: MapViewService) {}

  // $blue: #27B7FC;
  // $light-blue: #B3E8FF;
  // $green: #02DE73;
  // $yellow: #F8E71C;
  // $orange: #FF7100;
  // $red: #F1453D;

  getMarkerColor(): WaypointMarkerColors {
    switch (this.waypoint?.visit_state) {
      case 'Finalized':
        if (
          this.waypoint.parcel_count === this.waypoint.delivered_count ||
          this.waypoint.parcel_count === this.waypoint.collected_count ||
          this.waypoint.parcel_count === this.waypoint.returned_count
        ) {
          return WaypointMarkerColors.complete;
        } else if (this.waypoint.parcel_count === this.waypoint.failed_count) {
          return WaypointMarkerColors.failed;
        } else {
          return WaypointMarkerColors.partial;
        }
      case 'Completed':
        if (this.waypoint.parcel_count === this.waypoint.delivered_count) {
          return WaypointMarkerColors.complete;
        } else if (this.waypoint.parcel_count === this.waypoint.failed_count) {
          return WaypointMarkerColors.failed;
        } else {
          return WaypointMarkerColors.partial;
        }
      case 'Failed':
        return WaypointMarkerColors.failed;

      case 'At Waypoint':
        return WaypointMarkerColors.visiting;

      case 'Pending':
        return WaypointMarkerColors.pending;
      case 'EnRoute':
        return WaypointMarkerColors.enRoute;
      case 'Visiting':
        return WaypointMarkerColors.visiting;
      case 'Dispatching':
        return WaypointMarkerColors.dispatching;
      case 'Cancelled':
        return WaypointMarkerColors.cancelled;
      default:
        return WaypointMarkerColors.pending;
    }
  }

  createMarker(): void {
    const waypoint = this.waypoint;

    this.mapViewService.addMarkerToBounds(this.waypoint.waypoint_id, waypoint.lat, waypoint.lng);

    function icon() {
      if (
        waypoint.waypoint_type === 'Collection' ||
        waypoint.visit_type === 'Collection' ||
        waypoint.waypoint_type === 'Warehouse'
      ) {
        return {
          url: '/assets/img/map-icon.png',
          size: new google.maps.Size(50, 50),
        };
      } else {
        return {
          path:
            'M11.9,5.9C11.9,2.7,9.2,0,5.9,0S0,2.7,0,5.9c0,0.2,0,0.4,0,0.6C0.4,10,3.9,16.9,5.9,16.9 c1.8,0,5.5-6.8,5.9-10.3C11.9,6.4,11.9,6.2,11.9,5.9z',
          fillColor: this.getMarkerColor(),
          color: '#fff',
          fillOpacity: 1,
          strokeWeight: 0,
          scale: 2,
          fontColor: 'red',
          labelOrigin: new google.maps.Point(6, 6),
          anchor: new google.maps.Point(6, 15),
        };
      }
    }
    function label() {
      if (
        waypoint.visit_type === 'Collection' ||
        waypoint.waypoint_type === 'Collection' ||
        waypoint.waypoint_type === 'Warehouse'
      ) {
        return;
      } else {
        return {
          text: waypoint.index ? JSON.stringify(waypoint.index) : ' ',
          color: '#fff',
          fontSize: '12px',
          fontWeight: 'bold',
        };
      }
    }
    const position = new google.maps.LatLng(this.waypoint.lat, this.waypoint.lng);
    this.marker = new google.maps.Marker({
      position: position,
      map: this.mapElement,
      label: label(),
      icon: icon(),
    });
  }

  ngOnInit(): void {
    if (!this.waypoint.lat && !this.waypoint.lng) {
      this.waypoint = {
        lat: this.waypoint.location[0],
        lng: this.waypoint.location[1],
        waypoint_type: this.waypoint.waypoint_index === 0 ? 'Warehouse' : null,
        index: this.waypoint.waypoint_index,
        visit_state: this.waypoint.status,
        parcel_count: this.waypoint.parcels_overview.total_parcels,
        failed_count: this.waypoint.parcels_overview.parcels_failed_delivery,
        collected_count: this.waypoint.parcels_overview.parcels_collected,
        delivered_count: this.waypoint.parcels_overview.parcels_delivered,
        ...this.waypoint,
      };
      this.createMarker();
    } else if (
      this.waypoint.lat &&
      this.waypoint.lng &&
      this.waypoint &&
      this.waypoint.waypoint_id !== -1 &&
      this.waypoint.parcel_states
    ) {
      this.waypoint = {
        index: this.index,

        failed_count: this.waypoint.parcel_states.failed_collection + this.waypoint.parcel_states.failed_delivery,
        collected_count: this.waypoint.parcel_states.collected,
        delivered_count: this.waypoint.parcel_states.delivered,
        returned_count: this.waypoint.parcel_states.returned,
        ...this.waypoint,
      };
      this.createMarker();
    } else if (!this.waypoint.parcels_overview && this.waypoint.visit_state) {
      this.createMarker();
    }
  }

  ngOnDestroy(): void {
    if (this.marker) {
      this.marker.setMap(null);
      this.marker = null;
    }
  }
}
