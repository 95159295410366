<app-section>
  <heading [showToggle]="true" 
    (click)="collapsible.toggle()" 
    [icon]="icons.types.Alert" 
    [color]="icons.colors.Red">
    <h4 class="heading-title">Failed Courier Orders</h4>
  </heading>
  <div appCollapsible [expanded]="false" #collapsible="appCollapsible">
    <app-table
      [columns]="failedCourierTableColumns"
      [dataMap]="failedCourierTableDataMap"
      [rawData]="failedCourierOrders"
      [rowsSelectable]="true"
      [bulkActions]="failedCourierBulkActions"
      (actionTaken)="handleTableActions($event)"
      [showBulkActionsAlways]="true"
    >
    </app-table>
  </div>
</app-section>
