import { Injectable } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { AssignDriverloadComponent } from './driver-load/assign-driverload/assign-driverload.component';
import axios from 'app/api/axios';
import { Store } from '@ngrx/store';
import {
  selectorActiveTripId,
  selectorActiveTripDetails,
  selectorActiveTripWarehouseId,
} from './store/new-trips.reducer';
import { NotificationsService } from 'app/shared/notifications/notifications.service';
import { ActingAs, AuthUser, CourierIntegrationOrderRecreateConfig, UberEnabledWarehousesConfig } from 'app/interfaces/auth.interfaces';
import { selectCourierIntegrationOrderRecreateConfig, selectorActingAs, selectorUser } from 'app/auth/auth.reducer';
import { ActionParcelsComponent } from './driver-load/action-parcels/action-parcels.component';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { AssignParcelsComponent } from './driver-load/assign-parcels/assign-parcels.component';
import { OrderDetailsFull, DriverAssignedOrders, RecreateCourierOrderResponse } from './new-trips.interfaces';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AxiosPromise, AxiosResponse } from 'axios';
import firebase from 'firebase/app';
import { Order } from 'app/shared/shared.interfaces';
import { ActivationDialogResult } from './new-trips-activation-dialog/new-trips-activation-dialog.component';
import { AssignDriverData, AssignDriverModalComponent } from 'app/shared/assign-driver-modal/assign-driver-modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { AutoNotesService } from '../notes/auto-notes.service';
import { AutoNotesTypes } from '../notes/notes.constants';

@Injectable({
  providedIn: 'root',
})
export class NewTripsService {
  public tripId: string;
  tripDetails;
  activeTripWarehouseId: string;
  actingAs: ActingAs;
  updateData$ = new Subject<boolean>();
  user: AuthUser;

  orderNotesSubscription;
  lotterySubscription: any;
  orderNotes = new BehaviorSubject(undefined);
  orderLotteries = new BehaviorSubject(undefined);

  orderNoteIds = [];

  // Courier order integration config
  private readonly _courierIntegrationOrderRecreateConfig$ = new BehaviorSubject<CourierIntegrationOrderRecreateConfig>(null);
  readonly courierIntegrationOrderRecreateConfig$ = this._courierIntegrationOrderRecreateConfig$.asObservable();

  private readonly _isLoading$ = new BehaviorSubject<boolean>(false);
  readonly isLoading$ = this._isLoading$.asObservable();

  set isLoading(state: boolean){
    this._isLoading$.next(state);
  }

  private readonly _warehouseEnabledForUber$ = new BehaviorSubject<boolean>(false);
  readonly warehouseEnabledForUber$ = this._warehouseEnabledForUber$.asObservable();


  constructor(
    private simpleModalService: SimpleModalService,
    private store: Store<any>,
    private notificationsService: NotificationsService,
    private dialog: MatDialog,
    private firestore: AngularFirestore,
    private httpClient: HttpClient,
    private autoNotesService: AutoNotesService
  ) {
    this.store.select(selectorActiveTripId).subscribe((next) => (this.tripId = next));
    this.store.select(selectorActiveTripDetails).subscribe((next) => (this.tripDetails = next));
    this.store.select(selectorActiveTripWarehouseId).subscribe((next) => (this.activeTripWarehouseId = next));
    this.store.select(selectorActingAs).subscribe((next) => (this.actingAs = next));
    this.store.select(selectorUser).subscribe((next) => (this.user = next));
    this.store.select(selectCourierIntegrationOrderRecreateConfig).pipe(take(1)).subscribe((config: CourierIntegrationOrderRecreateConfig) => this._courierIntegrationOrderRecreateConfig$.next(config));
  }

  getTrip(trip_id) {
    return axios.get('/trip/' + trip_id).then((response) => {
      return response?.data;
    });
  }

  getOrderDetailsObject(order_id: string) {
    const docRef = this.firestore.collection<Order>('orders');
    return docRef.doc(order_id).valueChanges();
  }

  getOrderViewObject(order_id: string) {
    const docRef = this.firestore.collection('order-view');
    return docRef.doc(order_id).valueChanges();
  }

  addFirestoreDoc(order_id, data) {
    this.firestore.collection('order-view').doc(order_id).set(data, { merge: true });
  }

  removeFirestoreDoc(order_id, user_id) {
    this.firestore
      .collection('order-view')
      .doc(order_id)
      .update({
        [user_id]: firebase.firestore.FieldValue.delete(),
      });
  }

  getOrderPodObject(order_id: string) {
    const docRef = this.firestore.collection('order-pod');
    return docRef.doc(order_id).valueChanges();
  }

  getOrderDetailsFull(order_id: string): Promise<OrderDetailsFull> {
    return axios({
      method: 'POST',
      url: '/generic/cqrs/get-order-details-full',
      data: { order_id: order_id },
    }).then((response) => {
      return response?.data.output;
    });
  }

  rehydrateAggregate(orderId: string) {
    axios({
      method: 'POST',
      url: '/firestore/rehydrate-firestore-readmodel/' + orderId,
    })
      .then((res) => {
        return res.data;
      })
      .catch(() =>
        this.notificationsService.publish({
          type: 'error',
          message: 'Error building order - Please contact support. (Error Code:RH-' + orderId + ')',
        })
      );
  }

  cancelLottery(order_id: string): Promise<any> {
    return axios({
      method: 'POST',
      url: 'order/' + order_id + '/cancel-lottery',
    })
      .then((response) => {
        this.notify(true, 'Lottery Cancelled');
        return response;
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  getMapData(trip_id) {
    return axios.get('/trip/' + trip_id + '/polyline').then((response) => {
      return response?.data;
    });
  }

  handleTripActions(order_id, $event, Parcels, warehouseId?: string) {
    switch ($event) {
      case 'cancel-order':
        this.cancelOrder(order_id);
        break;
      case 'assign-driver':
        this.assignDriver(order_id, warehouseId);
        break;
      case 'assign-parcels':
        this.assignParcels(order_id, Parcels);
        break;
    }
  }

  scheduleOrder(scheduleDetails: ActivationDialogResult, orderId: string) {
    return axios({
      method: 'POST',
      url: 'order/' + orderId + '/schedule-order',
      data: {
        scheduled_date: scheduleDetails.scheduled_date,
        fleet_allocation: scheduleDetails.fleet_allocation,
      },
    })
      .then((response) => {
        this.autoNotesService.generateNote({
          autoNotesType: AutoNotesTypes.adjustedSchedule,
          orderId: orderId,
        })
        this.notify(true, 'Activation Time Updated');
        this.updateData$.next(true);
        return response;
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  cancelOrder(order_id: string) {
    return axios({
      method: 'POST',
      url: 'order/cancel-orders',
      data: {
        business_id: this.actingAs.id,
        order_ids: [order_id],
      },
    })
      .then((response) => {
        this.notify(true, 'Order Cancelled');
        this.updateData$.next(true);
        return response;
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  getFailedParcels(order_id: string): Promise<any> {
    return axios({
      method: 'POST',
      url: 'generic/cqrs/get-failed-parcels',
      data: { order_id: order_id },
    }).then((response) => {
      return response?.data;
    });
  }

  bulkReattempt(parcel_array: string[], last_mile_id: string) {
    return axios({
      method: 'POST',
      url: 'last-mile/' + last_mile_id + '/end-waypoint-route',
      data: parcel_array,
    })
      .then((response) => {
        this.notify(true, 'Parcels Added To Route');
        this.updateData$.next(true);
        return response;
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  enqueueCourierOrders(courier_order_ids: string[]): Promise<any> {
    return axios({
      method: 'POST',
      url: 'courier-order/enqueue-create-courier-orders',
      data: courier_order_ids,
    })
      .then((response) => {
        this.notify(true, 'Orders Queued');
        this.updateData$.next(true);
        return response;
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  assignDriver(order_id: string, warehouseId: string): void {
    const data: AssignDriverData = {
      BusinessId: this.actingAs.id,
      WarehouseId: warehouseId,
      IsPicupEmployee: this.user.is_admin
    };
    const dialogRef: MatDialogRef<AssignDriverModalComponent, {driverId: string, driverName: string}> =
    this.dialog.open(AssignDriverModalComponent, {
      width: '89%',
      height: '75%',
      data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      const assignDetails = {
        order_id: order_id,
        warehouse_id: this.activeTripWarehouseId,
        assign_to_driver_id: result.driverId,
        is_start_route: false,
        driver_name: result.driverName
      };
      this.assignDriverToTrip(assignDetails).then(() => {
        this.updateData$.next(true);
      });
    });
  }

  getWarehouseRoute(routeId: string) {
    const docRef = this.firestore.collection('warehouse-routes');
    return docRef.doc(routeId).valueChanges();
  }

  getWaypointRoute(routeId: string) {
    const docRef = this.firestore.collection('waypoint-routes');
    return docRef.doc(routeId).valueChanges();
  }

  getOrderPolyline(order_id: string): Promise<{ polyline: string }> {
    return axios({
      method: 'POST',
      url: 'generic/cqrs/get-polyline-for-order',
      data: { order_id },
    })
      .then(({ data }) => data)
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  assignParcels(order_id, Parcels) {
    const modal_data = {
      user_id: this.user.user_id,
      business_id: this.actingAs.id,
      Parcels: this.actingAs.id,
    };
    this.simpleModalService
      .addModal(AssignParcelsComponent, { order_id, Parcels, assign: true })
      .subscribe((result) => {
        if (!result) {
          return;
        }
        this.simpleModalService.addModal(AssignDriverloadComponent, modal_data).subscribe((result2) => {
          if (!result2) {
            return;
          }
          this.assignParcelsToDriver(result, result2.driverId, order_id, result2.driverName);
        });
      });
  }

  transferParcels(last_mile_id, order_id) {
    const modal_data = {
      user_id: this.user.user_id,
      business_id: this.actingAs.id,
      assign: false,
    };
    this.simpleModalService
      .addModal(AssignParcelsComponent, { order_id, assign: false, last_mile_id })
      .subscribe((result) => {
        if (!result) {
          return;
        }
        this.simpleModalService.addModal(AssignDriverloadComponent, modal_data).subscribe((result2) => {
          if (!result2) {
            return;
          }
          this.transferParcelsToDriver(result, result2.driverId, last_mile_id);
        });
      });
  }

  transferParcelsToDriver(parcel_waybills: string[], driver_id: string, last_mile_id_from: string): Promise<any> {
    return axios({
      method: 'POST',
      url: 'last-mile/' + last_mile_id_from + '/driver-transfer-parcels',
      data: { trasfer_to_driver_id: driver_id, parcel_waybills, driver_location: {} },
    })
      .then(() => {
        this.autoNotesService.generateNote(
          {
            autoNotesType: AutoNotesTypes.parcelsMoved,
            orderId: this.tripId
          }
        )
        this.notify(true, 'Parcels Transfered');
        this.updateData$.next(true);
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  assignDriverToTrip(assignBody) {
    return axios({
      method: 'POST',
      url: 'order/assign-orders-to-drivers',
      data: [assignBody],
    })
      .then((response) => {
        this.autoNotesService.generateNote({
          autoNotesType: AutoNotesTypes.driverAssigned,
          orderId: assignBody.order_id,
          driverName: assignBody.driver_name
        })
        this.notify(true, 'Driver Assigned');
        return response;
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  assignParcelsToDriver(parcel_waybills: string[], driver_id: string, order_id: string, driverName: string): Promise<any> {
    return axios({
      method: 'POST',
      url: 'order/' + order_id + '/assign-parcels-to-driver',
      data: { driver_id: driver_id, parcel_waybills },
    })
      .then(() => {
        this.autoNotesService.generateNote(
          {
            autoNotesType: AutoNotesTypes.driverAssigned,
            orderId: this.tripId,
            driverName: driverName
          }
        )
        this.notify(true, 'Parcels Assigned')
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  unassignParcelsFromDriver(parcel_waybills, driver_id, order_id) {
    return axios({
      method: 'POST',
      url: 'order/' + order_id + '/unassign-parcels-from-driver',
      data: { driver_id, parcel_waybills },
    })
      .then(() => {
        this.notify(true, 'Parcels Unassigned')
        this.updateData$.next(true)
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  forceAssignStagedParcels(parcel_waybills: string[], order_id: string): void {
    axios({
      method: 'POST',
      url: 'order/' + order_id + '/force-staged-order',
      data: { parcel_waybills: parcel_waybills },
    })
      .then(() => {
        this.notify(true, 'Parcels force assigned to active last mile');
        this.updateData$.next(true);
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
      });
  }

  getDriverAssignedOrders(driverId: string) {
    const docRef = this.firestore.collection<DriverAssignedOrders>('driver-assigned-orders');
    return docRef.doc(driverId).valueChanges();
  }

  actionParcels(order_id) {
    this.simpleModalService.addModal(ActionParcelsComponent, { order_id }).subscribe((result) => {
      if (!result) {
        return;
      }
      this.actionActiveParcels(order_id, result).then(() => {
        this.updateData$.next(true);
      });
    });
  }

  actionActiveParcels(order_id, requestBody): Promise<any | AxiosResponse<any>> {
    return axios({
      method: 'POST',
      url: 'order/' + order_id + '/reconcile-parcels',
      data: requestBody,
    })
      .then(() => this.notify(true, 'Parcels Actioned'))
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  allocateAndStart(shift_details, last_mile_id, order_id): Promise<any | AxiosResponse<any>> {
    const data = {
      optimize_route: shift_details,
      trips: [{ last_mile_id: last_mile_id, order_id: order_id }],
    };
    return axios({
      method: 'POST',
      url: 'last-mile/allocate-and-start-optimized-route',
      data: data,
    })
      .then(() => this.notify(true, 'Driver Allocated'))
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  attemptDirectAssignment(orderId: string): Promise<void> {
    return axios({
      method: 'POST',
      url: `order/${orderId}/attempt-direct-assignment`,
    }).then((response) => {
      return response?.data;
    });
  }

  getCourierTrackingEvents(courier_order_ids: string[]): Promise<any | AxiosResponse<any>> {
    return axios({
      method: 'POST',
      url: 'generic/cqrs/get-courier-order-tracking-events',
      data: { courier_order_ids },
    })
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  getLotteryDetails(order_id: string): Promise<any | AxiosResponse<any>> {
    return axios({
      method: 'POST',
      url: 'lottery/get-lottery-details',
      data: { order_id: order_id },
    })
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  startLotteryBackground(order_id: string, fleet): Promise<any | AxiosResponse<any>> {
    return axios({
      method: 'POST',
      url: 'order/' + order_id + '/start-lottery',
      data: { start_lottery_at: null, fleet_allocation: fleet },
    })
      .then((response) => {
        this.autoNotesService.generateNote(
          {
            autoNotesType: AutoNotesTypes.lotteryRun,
            orderId: order_id
          });
        return response?.data;
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  startMultipleLotteryBackground(order_id: string, fleet): Promise<any | AxiosResponse<any>> {
    return axios({
      method: 'POST',
      url: 'order/' + order_id + '/start-lottery-with-retries',
      data: { start_lottery_at: null, fleet_allocation: fleet },
    })
      .then((response) => {
        this.autoNotesService.generateNote(
          {
            autoNotesType: AutoNotesTypes.lotteryRun,
            orderId: order_id
          });
        return response?.data;
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  destroyLotterySubscription(): void {
    this.lotterySubscription();
  }

  getLotterySeenObject(lottery_id: string): Observable<any> {
    const docRef = this.firestore.collection('lottery-seen');
    return docRef.doc(lottery_id).valueChanges();
  }

  getLotteryObject(order_id: string): void {
    const docRef: AngularFirestoreCollection = this.firestore.collection('lottery');
    this.lotterySubscription = docRef.ref
      .where('order_id', '==', order_id)
      .orderBy('created_at', 'desc')
      .onSnapshot((querySnapshot) => {
        const lotteries = [];

        querySnapshot.forEach((lottery) => {
          lotteries.push({
            ...lottery.data(),
            id: lottery.id,
          });
        });
        this.orderLotteries.next(lotteries);
      });
  }

  notify(successful: boolean, message: string): void {
    const notificationType = successful ? 'success' : 'error';
    this.notificationsService.publish({
      type: notificationType,
      message: message,
    });
  }

  removeFailedFinalCollections(orderId: string): Promise<AxiosResponse<any>> {
    return axios({
      method: 'POST',
      url: 'order/' + orderId + '/acknowledge-failed-final-collection',
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  recreateCourierOrder(orderId: string, ): Observable<RecreateCourierOrderResponse> {
    this._isLoading$.next(true);
    const body = this.getOrderRecreateRequest();

    return this.httpClient.post<RecreateCourierOrderResponse>(`${environment.integration.rootUrl}order/${orderId}/recreate-as-courier-order`,
      body,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('id_token')}`,
          'api-key': this.actingAs.id},
      })
  }

  getCourierOrderConfigEnabledBusinessUids(): string[] {
    if (!this._courierIntegrationOrderRecreateConfig$.getValue()) {
      return [];
    }

    const config = this._courierIntegrationOrderRecreateConfig$.getValue();
    return Object.keys(config).flatMap(id => config[id].enabled_business_uids);
  }

  // Create a response model here... so dont use any
  private getOrderRecreateRequest(): any {
    // For now, we will always only have 1 courier, but later we would need to find the courier here by courierId, that will be passed down to this method!
    // const courierConfig = Object.keys(this.courierIntegrationOrderRecreateConfig).map((key) => this.courierIntegrationOrderRecreateConfig[key]);
    const courier_id = Object.keys(this._courierIntegrationOrderRecreateConfig$.getValue())[0];
    const { service_type_uid } = this._courierIntegrationOrderRecreateConfig$.getValue()[courier_id];

    return {
      courier_id,
      service_type_uid,
      cancel_original: true,
      validate_with_courier: true
    };
  }

  reserveOrderForUser(orderId: string): void {
    axios({
      method: 'POST',
      url: '/order/reserve-orders',
      data: {
        order_reservations: [{
          order_id: orderId,
          user_id: this.user.user_id
        }],
      }
    }).then(() => {
      this.autoNotesService.generateNote({
        autoNotesType: AutoNotesTypes.userAssigned,
        orderId: orderId
      });
    })
  }

  unreserveOrderForUser(orderId: string): void {
    axios({
      method: 'POST',
      url: '/order/unreserve-orders',
      data: {
        order_ids: [orderId]
      },
    }).then(() => {
      this.autoNotesService.generateNote( {
        autoNotesType: AutoNotesTypes.userRemoved,
        orderId: orderId
      });
    })
  }

  uberEnabledWarehousesConfig(business_id: string, warehouseId: string): void {
    const collection = this.firestore.collection('uber-enabled-warehouses');
    collection
      .doc(business_id)
      .valueChanges()
      .pipe(
        take(1)
      )
      .subscribe({
        next: (data: UberEnabledWarehousesConfig) => {
          const enabled = data?.warehouse_ids?.some(id => id === warehouseId) ?? false;
          this._warehouseEnabledForUber$.next(enabled);
        }});
  }
}
