import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BucketContractors, Contractor } from 'app/dashboard/buckets/buckets.interfaces';
import { ManageBucketService } from 'app/dashboard/buckets/manage-bucket.service';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { VehicleSizesEnum } from 'app/interfaces/vehicle-type.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { SimpleModalService } from 'ngx-simple-modal';
import { DriverSelectModalComponent } from '../driver-select-modal/driver-select-modal.component';
import { ContractorColumns, ContractorDataMap } from './contractor-settings.constants';

@Component({
  selector: 'app-contractor-settings',
  templateUrl: './contractor-settings.component.html',
  styleUrls: ['./contractor-settings.component.scss'],
})
export class ContractorSettingsComponent implements OnInit {
  @Input() bucket_contractors: BucketContractors;
  @Output() contractorsChangedEvent: EventEmitter<BucketContractors> = new EventEmitter<BucketContractors>();
  ButtonTypes = ButtonTypes;
  UiThemes = UiThemes;
  VehicleSizesEnum = VehicleSizesEnum;
  Icons = IconTypes;
  UiColors = UiColors;
  contractors: Contractor[] = [];
  contractorColumns = ContractorColumns;
  contractorDataMap = ContractorDataMap;
  loading: boolean = false;
  constructor(private simpleModalService: SimpleModalService, private bucketService: ManageBucketService) {}

  ngOnInit(): void {
    this.loading = true;
    this.getContractors().then(() => {
      this.getAssignableContractors();
    });
  }

  getAssignableContractors(): void {
    this.bucketService.getAssignableContractors().then((response) => {
      if (response) {
        const drivers = this.getDrivers();
        const contractorData: Contractor[] = [];
        response.output.forEach((data) => {
          if (drivers.includes(data.driver_id)) {
            contractorData.push(data);
          }
        });
        this.contractors = [...contractorData];
      }
      this.loading = false;
    });
  }

  getContractors(): Promise<void> {
    return this.bucketService.getContractors().then((response) => {
      if (response) {
        this.bucket_contractors = response;
      }
    });
  }

  addDriver(): void {
    this.simpleModalService
      .addModal(DriverSelectModalComponent, { drivers: this.getDrivers() })
      .subscribe((response: Contractor[]) => {
        if (response) {
          this.bucket_contractors.motorcycle_preferred_drivers = [];
          this.bucket_contractors.small_van_preferred_drivers = [];
          this.bucket_contractors.car_preferred_drivers = [];
          this.bucket_contractors.large_van_preferred_drivers = [];
          this.contractors = [];
          response.forEach((contractor) => {
            this.contractors.push(contractor);
            switch (contractor.vehicle_id) {
              case VehicleSizesEnum.vehicle_motorcycle: {
                this.bucket_contractors.motorcycle_preferred_drivers.push(contractor.driver_id);
                break;
              }
              case VehicleSizesEnum.vehicle_small_van: {
                this.bucket_contractors.small_van_preferred_drivers.push(contractor.driver_id);
                break;
              }
              case VehicleSizesEnum.vehicle_car: {
                this.bucket_contractors.car_preferred_drivers.push(contractor.driver_id);
                break;
              }
              case VehicleSizesEnum.vehicle_large_van: {
                this.bucket_contractors.large_van_preferred_drivers.push(contractor.driver_id);
                break;
              }
            }
          });

          this.bucket_contractors.car_count = this.bucket_contractors.car_preferred_drivers.length;
          this.bucket_contractors.motorcycle_count = this.bucket_contractors.motorcycle_preferred_drivers.length;
          this.bucket_contractors.small_van_count = this.bucket_contractors.small_van_preferred_drivers.length;
          this.bucket_contractors.large_van_count = this.bucket_contractors.large_van_preferred_drivers.length;
          this.contractorsChangedEvent.emit(this.bucket_contractors);
        }
      });
  }

  getDrivers(): string[] {
    const drivers: string[] = [
      ...this.bucket_contractors.motorcycle_preferred_drivers,
      ...this.bucket_contractors.small_van_preferred_drivers,
      ...this.bucket_contractors.car_preferred_drivers,
      ...this.bucket_contractors.large_van_preferred_drivers,
    ];

    return drivers;
  }

  hasSelectedContractors(): boolean {
    if (this.bucket_contractors.car_preferred_drivers.length > 0) {
      return true;
    }

    if (this.bucket_contractors.motorcycle_preferred_drivers.length > 0) {
      return true;
    }

    if (this.bucket_contractors.large_van_preferred_drivers.length > 0) {
      return true;
    }

    if (this.bucket_contractors.small_van_preferred_drivers.length > 0) {
      return true;
    }

    return false;
  }
}
