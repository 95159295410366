import { from, Observable } from 'rxjs';
import { AddressBookEntry } from './../../shared.interfaces';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { UiThemes, UiColors } from 'app/interfaces/ui.interfaces';
import { Address, Contact, Parcel, Waypoint } from '../../../dashboard/place-order/trip.interfaces';
import { MapTypes } from 'app/shared/map/map.interfaces';
import { lightMapStyle } from 'app/shared/map/map.constants';
import { environment } from 'environments/environment';
import { Store } from '@ngrx/store';
import { authState, selectorActingAs, selectorUserPermissions, selectorWarehouses } from 'app/auth/auth.reducer';
import { ActingAs, Warehouse } from 'app/interfaces/auth.interfaces';
import axios from 'app/api/axios';
import { PhoneValidationPipe } from 'app/shared/pipes/phone-validation.pipe';
import { MapDrawingHelperDirective } from 'app/shared/map-drawing-helper/map-drawing-helper.directive';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-waypoint',
  templateUrl: './waypoint.component.html',
  styleUrls: ['./waypoint.component.scss'],
})
export class WaypointComponent implements OnInit, OnChanges {
  public IconTypes = IconTypes;
  public ButtonTypes = ButtonTypes;
  public UiThemes = UiThemes;
  public UiColors = UiColors;
  public MapTypes = MapTypes;

  mapStyles = lightMapStyle;
  map: google.maps.LatLngLiteral = {
    lat: -33.9765176,
    lng: 18.4654239,
  };
  mapStyle = {
    'height.px': 300,
  };

  mapOptions: google.maps.MapOptions = {
    center: this.map,
    scrollwheel: true,
    styles: lightMapStyle,
    zoomControl: false,
    streetViewControl: false,
    clickableIcons: false,
    disableDefaultUI: true,
    mapTypeControl: false
  }
  mapElement: any;
  @ViewChild('map', { static: false }) gMap: any;

  @Input() packageType;
  @Input() displayInvalid = false;
  @Input() isCollection = false;
  @Input() waypoint: Waypoint = {
    is_valid: false,
    address: <Address>{
      complex: '',
      unit_no: '',
    },
    contact: <Contact>{
      name: null,
      email: null,
      telephone: null,
      cellphone: null,
      description: null,
    },
    parcels: Array<Parcel>(),
    special_instructions: '',
    contact_description: null,
    save: false,
    warehouse_id: null,
    searchAddress: false,
    returnTrip: false
  };
  searchAddress = false;
  environment = environment;
  selectedWarehouse;
  available_warehouses: Warehouse[] = [];
  warehouses: Warehouse[];

  // @Input() addressBook;
  saveAddress = false;
  addressBookUsed = true;
  userPermissions;
  actingAs: ActingAs;
  parcelError;
  parcelTypes = ['parcel-a4-envelope', 'parcel-small', 'parcel-medium', 'parcel-large', 'parcel-xlarge'];
  addressBook: any = [];
  phoneTouched: boolean = false;
  marker: google.maps.Marker;
  address: any;

  enabledDescriptions: number[] = [];

  constructor(private store: Store<authState>, private phoneValidationPipe: PhoneValidationPipe) {
    this.store.select(selectorUserPermissions).subscribe((next) => (this.userPermissions = next));
    this.store.select(selectorActingAs).subscribe((next) => (this.actingAs = next));
    this.store.select(selectorWarehouses).subscribe((next) => (this.warehouses = next));
  }

  observableSource = (keyword: string): Observable<AddressBookEntry[]> => {
    if (keyword) {
      return from(
        axios({
          method: 'POST',
          url: '/address-book/search-address-book',
          data: {
            business_id: this.actingAs.id,
            search_term: keyword,
          },
        }).then((response) => {
          return response.data;
        })
      );
    } else {
      return from([]);
    }
  };

  ngOnChanges(sChanges: SimpleChanges): void{
    if(sChanges.waypoint){
      this.mapOptions.center = new google.maps.LatLng(this.waypoint.address['latitude'], this.waypoint.address['longitude'])
      this.mapElement?.setCenter(new google.maps.LatLng(this.waypoint.address['latitude'], this.waypoint.address['longitude']));
      MapDrawingHelperDirective.removeMapMarker( this.marker);
      this.marker = MapDrawingHelperDirective.createMapMarker(this.mapElement, new google.maps.LatLng(this.waypoint.address['latitude'], this.waypoint.address['longitude']));

    }
  }

  getAddressesFromAddressBook(keyword: string): any {
    if(keyword){
      this.getAddresses(keyword).then((response) => {
        this.address = response.data;
      });
    } else {
      this.address = [];
    }
  }

  // This should go into a service but there is no waypoint or one to one service
  getAddresses( keyword: string): any{
    return axios({
      method: 'POST',
      url: '/address-book/search-address-book',
      data: {
        business_id: this.actingAs.id,
        search_term: keyword,
      },
    }).then((data) => {
      return(data)
    });
  }

  // TODO: fix typings
  onAddressBookPlace($event: AddressBookEntry | any): void {
    this.addressBookUsed = true;
    this.waypoint.address['formatted_address'] = $event.formatted_address;
    this.waypoint.address['latitude'] = $event.latitude;
    this.waypoint.address['longitude'] = $event.longitude;
    this.waypoint.address['unit_no'] = $event.unit_no;
    this.waypoint.contact.name = $event.name;
    this.waypoint.contact.email = $event.email;
    this.waypoint.contact.cellphone = $event.phone;
    this.waypoint['contact_description'] = $event.description;
    this.waypoint.address.complex = $event.complex;
    this.waypoint.address.country = $event.country;
    this.waypoint.address.country_short_code = $event.country_short_code;
    this.mapOptions.center = {lat: this.waypoint.address['latitude'], lng: this.waypoint.address['longitude']}
    this.mapElement?.setCenter(new google.maps.LatLng(this.waypoint.address['latitude'], this.waypoint.address['longitude']));

  }

  onPlacesChange(address: google.maps.places.PlaceResult): void {
    this.addressBookUsed = false;
    this.waypoint.address['complex'] = null;
    this.waypoint.address['formatted_address'] = address.formatted_address;
    this.waypoint.address['latitude'] = address.geometry.location.lat();
    this.waypoint.address['longitude'] = address.geometry.location.lng();
    this.mapOptions.center = {lat: this.waypoint.address['latitude'], lng: this.waypoint.address['longitude']}
    this.mapElement?.setCenter(new google.maps.LatLng(this.waypoint.address['latitude'], this.waypoint.address['longitude']));

    if (address.types.includes('establishment') || address.types.includes('point_of_interest')) {
      this.waypoint.address['complex'] = address.name;
    }
    address.address_components.forEach((component) => {
      if (component.types.includes('street_number')) {
        this.waypoint.address['street_or_farm_no'] = component.short_name;
      }
      if (component.types.includes('route')) {
        this.waypoint.address['street_or_farm'] = component.short_name;
      }
      if (component.types.includes('sublocality')) {
        // suburb
        this.waypoint.address['suburb'] = component.short_name;
      }
      if (component.types.includes('locality')) {
        // city
        this.waypoint.address['city'] = component.short_name;
      }
      if (component.types.includes('country')) {
        // country
        this.waypoint.address['country'] = component.long_name;
        this.waypoint.address['country_short_code'] = component.short_name;
      }
      if (component.types.includes('postal_code')) {
        // postalcode
        this.waypoint.address['postal_code'] = component.short_name;
      }
    });
  }

  addParcel(parcelType: string): void {
    this.waypoint.parcels.push({ size: parcelType });
  }

  removeParcel(index: number): void {
    this.waypoint.parcels.splice(index, 1);

    // remove description index marker
    const indexNumber = this.enabledDescriptions?.findIndex(x => x === index);
    if(index >= 0){
      this.enabledDescriptions.splice(indexNumber, 1);
      this.enabledDescriptions = [...this.enabledDescriptions];
    }
  }

  ngOnInit(): void {
    if (this.userPermissions?.warehouses?.length && !this.userPermissions?.modules?.includes('super-admin')) {
      this.available_warehouses = this.warehouses.filter((warehouse) =>
        this.userPermissions.warehouses.includes(warehouse.id)
      );
    } else {
      this.available_warehouses = this?.warehouses;
    }
    if (!this.available_warehouses?.length) {
      this.waypoint.searchAddress = true;
    }
    if (this.waypoint.warehouse_id) {
      this.prePopulateWarehouse(this.waypoint.warehouse_id);
    }
  }
  prePopulateWarehouse(warehouse_id: string): void {
    this.waypoint.address = <Address>{};
    this.waypoint.contact = <Contact>{};
    const previous_warehouse = this.available_warehouses.find((warehouse) => warehouse.id === warehouse_id);
    this.selectWarehouse(previous_warehouse);
  }

  manualAddress(): void {
    this.waypoint.searchAddress = true;
    this.addressBookUsed = false;
    this.selectedWarehouse = null;
    this.waypoint.warehouse = '';
    this.waypoint.warehouse_id = '';
  }

  selectWarehouse(warehouse): void {
    this.addressBookUsed = true;
    this.selectedWarehouse = warehouse;
    this.waypoint.address['formatted_address'] = warehouse.address;
    this.waypoint.address['complex'] = warehouse.name;
    this.waypoint.address['latitude'] = warehouse.location.latitude;
    this.waypoint.address['longitude'] = warehouse.location.longitude;
    this.waypoint.address['postal_code'] = warehouse.postal_code;
    this.waypoint.address['suburb'] = warehouse.suburb;
    this.waypoint.contact.cellphone = warehouse.contact ? warehouse.contact.PhoneNumber : warehouse.phone;
    this.waypoint.contact.email = warehouse.contact ? warehouse.contact.Email : warehouse.email;
    this.waypoint.address.country = warehouse.country;
    this.waypoint.address.country_short_code = warehouse.country_short_code;
    this.waypoint.contact.name = warehouse.contact ? warehouse.contact.Name : warehouse.name;
    this.waypoint.warehouse = warehouse.name;
    this.waypoint.warehouse_id = warehouse.id;
    this.mapOptions.center = {lat: this.waypoint.address['latitude'], lng: this.waypoint.address['longitude']}
    this.mapElement?.setCenter(new google.maps.LatLng(this.waypoint.address['latitude'], this.waypoint.address['longitude']));
    this.checkValid();
  }

  public mapElementReady(): void {
    if (this.gMap) {
      this.mapElement = this.gMap.googleMap;
      if(this.waypoint?.address && this.mapElement) {
        // this.mapElement?.setCenter(new google.maps.LatLng(this.waypoint.address['latitude'], this.waypoint.address['longitude']));
        MapDrawingHelperDirective.removeMapMarker( this.marker);
        this.marker = MapDrawingHelperDirective.createMapMarker(this.mapElement, new google.maps.LatLng(this.waypoint.address['latitude'], this.waypoint.address['longitude']));
      }
    }
  }

  checkValid(): void {
    this.waypoint['touched'] = true;
    const waypoint = this.waypoint;
    if (
      waypoint.address.formatted_address &&
      (this.isCollection || this.waypoint.parcels.length > 0) &&
      waypoint.contact.name &&
      this.phoneValidationPipe.transform(waypoint.contact.cellphone) &&
      this.validateEmail(waypoint.contact.email) &&
      this.validateParcels()
    ) {
      waypoint.is_valid = true;
    } else {
      waypoint.is_valid = false;
      this.displayInvalid = !this.displayInvalid;
    }
  }

  validateParcels(): boolean {
    if (this.isCollection) {
      return true;
    } else {
      if (this.waypoint?.parcels.every((parcel) => parcel.reference)) {
        return true;
      } else {
        return false;
      }
    }
  }

  validateEmail(email: string): boolean {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  isDescriptionEnabled(indexNumber: number): boolean {
    if(this.enabledDescriptions.length > 0){
      return this.enabledDescriptions.findIndex(x => x === indexNumber) >= 0 ?? false;
    }
    return false;
  }

  addRemoveEnabledIndex(indexNumber: number): void {
    const index = this.enabledDescriptions.findIndex(x => x === indexNumber);
    if(index >= 0){
      this.enabledDescriptions.splice(index, 1);
      this.enabledDescriptions = [...this.enabledDescriptions];
    } else {
      this.enabledDescriptions.push(indexNumber);
      this.enabledDescriptions = [...this.enabledDescriptions];
    }
  }

  clearDescription(indexNumber: number): void {
    if(this.waypoint?.parcels[indexNumber].parcel_description){
      this.waypoint.parcels[indexNumber].parcel_description = ''
    }
  }

  enableReturns(event: MatSlideToggleChange): void {
    if(event) {
      this.waypoint.returnTrip = event.checked;
    }
  }
}
