import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NewTripsComponent } from './new-trips.component';
import { NewTripsTimelineComponent } from './new-trips-timeline/new-trips-timeline.component';
import { NewTripsNotesComponent } from './new-trips-notes/new-trips-notes.component';
import { NewTripsLotteryComponent } from './new-trips-lottery/new-trips-lottery.component';

const routes: Routes = [
  {
    path: 'trip/:id',
    data: { breadcrumb: 'View Trip' },
    component: NewTripsComponent,
    children: [
      {
        path: 'timeline',
        data: { breadcrumb: 'Timeline' },
        outlet: 'Trip',
        component: NewTripsTimelineComponent,
      },
      { path: 'lottery', data: { breadcrumb: 'Lottery' }, outlet: 'Trip', component: NewTripsLotteryComponent },
      { path: 'notes', data: { breadcrumb: 'Notes' }, outlet: 'Trip', component: NewTripsNotesComponent },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NewTripsRoutingModule {}
