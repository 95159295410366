<!-- <section>
  <heading [icon]="'/assets/img/icons/warehouse.svg'" [color]="uiColors.Blue">
    <h4 class="heading-title">Consignment Details</h4>
    <div class="controls">
      <input type="file" style="display:none" #file (change)="onFileAdded()" />
      <app-basic-button [title]="'Upload Invoice Data'" [processing]="processing" [type]="buttonTypes.Highlight" (click)="uploadConsignmentData();"></app-basic-button>
    </div>
  </heading>
  <div class="light-card general-options">
    <div class="form-wrap">
     
    </div>
  </div>
</section> -->

<section>
  <heading [icon]="iconTypes.Details" [color]="uiColors.Blue">
    <h4 class="heading-title">Consignment Details</h4>
  </heading>
  <div class="light-card general-options">
    <div class="form-wrap">
      <div class="row lookup">
        <input-field [placeholder]="'EG. 56978'" [type]="'text'" (valueChanged)="lookUpValue = $event"
          [value]="lookUpValue" [label]="'Lookup Value'" (keydown.enter)="searchLookups(lookUpValue)"></input-field>
        <app-basic-button [active]="true" [title]="'Search Reference'" [processing]="processing" [type]="buttonTypes.Highlight"
          (click)="searchLookups(lookUpValue)"></app-basic-button>
      </div>
    </div>
    <div class="form-wrap" *ngIf="orderFound">
      <div class="row meta" *ngIf="consignment?.bucket_id">
        <h4>Bucket Id: #{{ consignment.bucket_id }}</h4>
        <app-basic-button class="remove-button" *ngIf="!shipment?.flagged_reason; else showIsDeleted"
          [title]="'Remove Order From Consignment'" [processing]="processing" [type]="buttonTypes.Warning"
          (click)="removeOrderFromConsignment()"></app-basic-button>
        <ng-template #showIsDeleted>
          <h4 class="error">This order has been removed from the consignment. Update below to re-add.</h4>
        </ng-template>
      </div>
      <!-- <div class="row"> -->
      <!-- (click)="openDatePickerModal()" [svgIcon]="'details.svg'" -->
      <app-grid-container [columns]="'repeat(4, 1fr)'" [colGap]="'2rem'" [rowGap]="'2rem'">
        <input-field [disabled]="true" [type]="'text'" (valueChanged)="consignment.delivery_date = $event"
          [value]="consignment.delivery_date" [label]="'Delivery Date'"></input-field>
        <input-field [disabled]="true" [placeholder]="'Eg. Local Warehouse'" [type]="'text'"
          (valueChanged)="consignment.warehouse_name = $event" [value]="consignment.warehouse_name"
          [label]="'Warehouse Name'"></input-field>
        <app-autocomplete [placeholder]="'Eg. Woodstock'" [sourceData]="zoneData" [label]="'Zone'"
          (valueChanged)="shipment.consignment = $event" [value]="shipment.consignment"></app-autocomplete>
        <input-field [disabled]="true" [placeholder]="'Eg. BLW00000000S1'" [type]="'text'"
          (valueChanged)="shipment.invoice_number = $event" [value]="shipment.invoice_number"
          [label]="'Invoice Number'">
        </input-field>
        <input-field [disabled]="true" [placeholder]="'Eg.  08:00'" [type]="'text'"
          (valueChanged)="consignment.shift_start = $event" [value]="consignment.shift_start" [label]="'Shift Start'">
        </input-field>
        <input-field [disabled]="true" [placeholder]="'Eg. 17:00'" [type]="'text'"
          (valueChanged)="consignment.shift_end = $event" [value]="consignment.shift_end" [label]="'Shift End'">
        </input-field>
        <input-field [placeholder]="'Eg. 16:00'" [type]="'text'"
          (valueChanged)="shipment.delivery_window_start = $event" [value]="shipment.delivery_window_start"
          [label]="'Delivery Window Start'"></input-field>
        <input-field [placeholder]="'Eg. 16:30'" [type]="'text'" (valueChanged)="shipment.delivery_window_end = $event"
          [value]="shipment.delivery_window_end" [label]="'Delivery Window End'"></input-field>
        <input-field [placeholder]="'Eg. Picup Technologies'" [type]="'text'"
          (valueChanged)="shipment.contact.customer_name = $event" [value]="shipment.contact.customer_name"
          [label]="'Company Name'"></input-field>
        <input-field [placeholder]="'Eg. 021 000 0000'" [type]="'tel'"
          (valueChanged)="shipment.contact.customer_phone = $event" [value]="shipment.contact.customer_phone"
          [label]="'Phone Number'"></input-field>
      </app-grid-container>

      <app-grid-container [padding]="'2rem 0rem'" [columns]="'repeat(4, 1fr)'" [colGap]="'2rem'" [rowGap]="'2rem'">
        <!-- </div> -->
        <!-- <div class="row"> -->
        <input-field [disabled]="true" [placeholder]="'Street'" [type]="'text'"
          (valueChanged)="shipment.address_line1 = $event" [value]="shipment.address_line1" [label]="'Address Line 1'">
        </input-field>
        <input-field [disabled]="true" [placeholder]="'Suburb'" [type]="'text'"
          (valueChanged)="shipment.address_line2 = $event" [value]="shipment.address_line2" [label]="'Address Line 2'">
        </input-field>
        <input-field [disabled]="true" [placeholder]="'City'" [type]="'text'"
          (valueChanged)="shipment.address_line3 = $event" [value]="shipment.address_line3" [label]="'Address Line 3'">
        </input-field>
        <input-field [disabled]="true" [placeholder]="'Country'" [type]="'text'"
          (valueChanged)="shipment.address_line4 = $event" [value]="shipment.address_line4" [label]="'Address Line 4'">
        </input-field>
      </app-grid-container>

      <!-- </div> -->

      <div class="row justify-end">
        <p (click)="overrideTrue()">Incorrect Address?</p>
      </div>
      <div class="row address" *ngIf="override">
        <input-field [placeholder]="'6 Beach Road, Woodstock, Cape Town'" [type]="'text'"
          (valueChanged)="shipment.address = $event" [value]="shipment.address" [label]="'Override Address'">
        </input-field>
        <div class="row justify-end">
          <p (click)="overrideFalse()">Cancel</p>
        </div>
      </div>
      <div class="row parcel-selector">
        <div *ngFor="let parcelType of parcelTypes" class="counter">
          <img [src]="'/assets/img/' + parcelType + '.svg'" [alt]="parcelType + ' icon'" />
          <div class="spacer"></div>
          <div class="info">
            <svg-icon src="/assets/img/icons/down-grey.svg" (click)="removeParcel(parcelType)"></svg-icon>
            <h3>{{ shipment.parcels[parcelType] }}</h3>
            <svg-icon src="/assets/img/icons/up-grey.svg" (click)="addParcel(parcelType)"></svg-icon>
          </div>
        </div>
      </div>
      <div class="row footer justify-end">
        <app-basic-button [title]="'Cancel'" [processing]="processing" [type]="buttonTypes.Primary" (click)="setInitialValues()">
        </app-basic-button>
        <app-basic-button [title]="'Update Consignment'" [processing]="processing" [type]="buttonTypes.Highlight" [active]="true"
          (click)="validate()"></app-basic-button>
      </div>
    </div>
  </div>
</section>