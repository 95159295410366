<app-section *ngIf="Route?.WaypointVisits && Route?.RouteEndedAt === null">
  <div class="loading-route">
    <h3>This order has one or many in progress routes</h3>
  </div>
</app-section>
<app-last-mile-fs-waypoint-route
  [RouteStartedAt]="Route?.RouteStartedAt"
  [RouteEndedAt]="Route?.RouteEndedAt"
  *ngIf="Route?.WaypointVisits && Route?.RouteEndedAt != null"
  [WaypointVisits]="Route.WaypointVisits"
  [Parcels]="Route.Parcels"
  [RouteId]="Route?.Id"
  [isHistoricRoute]="true"
  [lastMilePolyline]="[polyline]"
>
</app-last-mile-fs-waypoint-route>
