import { Component, OnInit, Input } from '@angular/core';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { SharedService } from '../shared.service';
import { TableColumn, TableDataMap, TableAction, TableActionTaken } from '../table/table.interfaces';
import { IconTypes } from '../icon/icon.interfaces';
import { AuthService } from 'app/auth/auth.service';
import { SimpleModalService } from 'ngx-simple-modal';
import { ConfirmModalComponent } from '../modals/confirm-modal.component';
import { NotificationsService } from '../notifications/notifications.service';
import { EditAddressComponent } from './edit-address/edit-address.component';
import { selectorActingAs, selectorUser } from 'app/auth/auth.reducer';
import { Store } from '@ngrx/store';
import { AddressBookEntry } from '../shared.interfaces';
import { ActingAs, AuthUser } from 'app/interfaces/auth.interfaces';
import { ButtonTypes } from '../buttons/basic-button.component';

@Component({
  selector: 'app-address-book',
  templateUrl: './address-book.component.html',
  styleUrls: ['./address-book.component.scss'],
})
export class AddressBookComponent implements OnInit {
  public addressColumns: TableColumn[] = [
    { name: 'address_book_id', displayName: 'address_book_id', hidden: true },
    { name: 'description', displayName: 'Description', sortable: true },
    { name: 'name', displayName: 'Name', sortable: true },
    { name: 'phone', displayName: 'Phone Number' },
    { name: 'email', displayName: 'Email Address' },
    { name: 'complex', displayName: 'Complex' },
    { name: 'formatted_address', displayName: 'Formatted Address' },
  ];

  public addressTableDataMap: TableDataMap = {
    cells: {
      address_book_id: { column: 'address_book_id', map: 'address_book_id' },
      description: { column: 'description', map: 'description' },
      name: { column: 'name', map: 'name' },
      phone: { column: 'phone', map: 'phone' },
      email: { column: 'email', map: 'email' },
      complex: { column: 'complex', map: 'complex' },
      formatted_address: {
        column: 'formatted_address',
        map: 'formatted_address',
      },
    },
  };

  public tablePrimaryActions: TableAction[] = [
    { event: 'edit', title: 'Edit Contact', icon: IconTypes.Edit },
    { event: 'delete', title: 'Delete Contact', icon: IconTypes.Delete },
  ];

  @Input() context;

  public UiThemes = UiThemes;
  uiColours = UiColors;
  buttonTypes = ButtonTypes;
  iconTypes = IconTypes;
  addressBook_id;
  addresses = [];
  actingAs: ActingAs;
  user: AuthUser;
  showViewMore = true;
  numberOfResults = 5;
  shownAddresses = [];
  searchString: string;
  filteredAddresses = [];
  addressRequestInfo = { business_id: null, user_id: null, warehouse_id: null, address_book_id: null };

  constructor(
    public sharedService: SharedService,
    public authService: AuthService,
    private store: Store<any>,
    public simpleModalService: SimpleModalService,
    public notificationService: NotificationsService
  ) {
    this.store.select(selectorActingAs).subscribe((next) => {
      this.actingAs = next;
    });
    this.store.select(selectorUser).subscribe((next) => {
      this.user = next;
    });
  }

  getAddresses(): void {
    this.sharedService.getAddresses(this.addressRequestInfo).then((response) => {
      this.addresses = response;
      this.showAddresses();
    });
  }

  addAddress(): void {
    this.simpleModalService.addModal(EditAddressComponent).subscribe((result) => {
      if (!result) {
        return;
      }
      if (this.context === 'business') {
        result.business_id = this.actingAs.id;
      } else if (this.context === 'user') {
        result.user_id = this.actingAs.id;
      }
      this.sharedService
        .setAddress(result)
        .then(() => {
          this.notificationService.publish({ type: 'success', message: 'Contact Added' });
          this.getAddresses();
          return;
        })
        .catch((error) => {
          this.notificationService.publish({ type: 'error', message: error.response?.data.message });
          throw error.response?.data.message;
        });
    });
  }

  editAddress(address_book_id: number): void {
    const edit_address = this.addresses.find(
      (address_entry: AddressBookEntry) => address_entry.address_book_id === address_book_id
    );
    this.simpleModalService.addModal(EditAddressComponent, { address_model: edit_address }).subscribe((result) => {
      if (!result) {
        return;
      }
      if (this.context === 'business') {
        result.business_id = this.actingAs.id;
      } else if (this.context === 'user') {
        result.user_id = this.actingAs.id;
      }
      this.sharedService
        .setAddress(result)
        .then(() => {
          this.notificationService.publish({ type: 'success', message: 'Contact Modified' });
          this.getAddresses();
          return;
        })
        .catch((error) => {
          this.notificationService.publish({ type: 'error', message: error.response?.data.message });
          throw error.response?.data.message;
        });
    });
  }

  deleteAddress(address_id: number): void {
    this.simpleModalService
      .addModal(ConfirmModalComponent, {
        title: 'Contact Delete',
        message: 'Are you sure you wish to delete this contact?',
      })
      .subscribe((isConfirmed) => {
        if (!isConfirmed) {
          return;
        }
        this.addressRequestInfo.address_book_id = address_id;
        this.sharedService
          .deleteAddress(this.addressRequestInfo)
          .then(() => {
            this.getAddresses();
            return;
          })
          .catch((error) => {
            this.notificationService.publish({ type: 'error', message: error.response?.data.message });
            throw error.response?.data.message;
          });
      });
  }

  respondToTableActions($event: TableActionTaken): void {
    const address_id = $event.rows[0].cells.find((cell) => cell.column === 'address_book_id').value as number;

    switch ($event.action.event) {
      case 'delete':
        this.deleteAddress(address_id);
        return;
      case 'edit':
        this.editAddress(address_id);
        return;
    }
  }

  ngOnInit(): void {
    switch (this.context) {
      case 'user':
        this.addressRequestInfo.user_id = this.user.user_id;
        break;
      case 'business':
        this.addressRequestInfo.business_id = this.actingAs.id;
        break;
    }
    this.getAddresses();
  }

  calculateViewMore(comparison, shown) {
    if (comparison.length <= shown.length) {
      this.showViewMore = false;
    } else {
      this.showViewMore = true;
    }
  }

  loadMoreTableData() {
    this.shownAddresses = [];
    this.numberOfResults = this.numberOfResults + 5;
    this.showAddresses();
    if (this.addresses.length <= this.shownAddresses.length) {
      this.showViewMore = false;
    }
  }

  showAddresses() {
    let i = 0;
    this.shownAddresses = [];

    if (this.filteredAddresses.length >= 0 && this.searchString) {
      this.filteredAddresses.forEach((address) => {
        i++;
        if (i <= this.numberOfResults) {
          this.shownAddresses.push(address);
        }
        this.calculateViewMore(this.filteredAddresses, this.shownAddresses);
      });
    } else {
      this.addresses.forEach((address) => {
        i++;
        if (i <= this.numberOfResults) {
          this.shownAddresses.push(address);
        }
        this.calculateViewMore(this.addresses, this.shownAddresses);
      });
    }
  }

  filterAddresses(searchString) {
    const newArray = this.addresses.filter((address) => {
      return address.description.toLowerCase().includes(searchString.toLowerCase());
    });
    this.filteredAddresses = newArray;
    this.showAddresses();
  }
}
