<div class="route-waypoint" *ngIf="waypoint && waypoint.WaypointIndex">
  <heading
    [color]="waypointStatus.color"
    [highlight]="waypoint.WaypointIndex"
    [showToggle]="true"
    (click)="collapsible.toggle()"
  >
    <span class="waypoints">
      <span class="waypoint-address">{{ waypoint.Address | uppercase }}</span
      ><br />
      <span *ngIf="waypoint.VisitType !== 'Warehouse'" class="waypoint-contacts">
        <span *ngFor="let contact of waypoint.Contacts; let i = index">{{ contact.Name | uppercase }} </span>
      </span>
      <span *ngIf="waypoint.VisitType === 'Warehouse'" class="waypoint-contacts">
        <span>
          <app-icon [icon]="IconTypes.Warehouse" [size]="1" [color]="UiColors.Blue"> </app-icon>
          {{ waypoint.Contacts[0].Name | uppercase }}
        </span>
      </span>
    </span>
    <app-flex-container>
      <span>
        <app-icon-timestamp-status
          [icon]="waypointStatus.icon"
          [iconColor]="waypointStatus.color"
          [iconSizeRem]="0.7"
          [text]="waypointStatus.label"
          [start]="waypoint.VisitingAt?.toMillis()"
          [end]="waypoint.FinalizedAt?.toMillis()"
          [distance]="waypoint.Distance"
          [timestamp]="
            waypoint.VisitState === 'Finalized'
              ? waypoint.FinalizedAt.toMillis()
              : waypoint.VisitingAt
              ? waypoint.VisitingAt.toMillis()
              : undefined
          "
          [sizeRem]="0.83"
          [failures]="waypoint.FailedCount"
        >
        </app-icon-timestamp-status>
        <div>EXPECTED ARRIVAL: {{waypoint.PlannedArrival?.toMillis() | date: 'shortTime'}}</div>
      </span>
    </app-flex-container>
  </heading>
  <div appCollapsible #collapsible="appCollapsible">
    <div [ngStyle]="{ 'padding-bottom.rem': 2 }">
      <app-last-mile-fs-warehouse-contact
        *ngIf="waypoint.VisitType === 'Warehouse'"
        [Parcels]="Parcels"
        [contacts]="waypoint.Contacts"
      >
      </app-last-mile-fs-warehouse-contact>
      <ng-template *ngIf="waypoint.VisitType != 'Warehouse'" ngFor let-contact [ngForOf]="waypoint.Contacts">
        <app-last-mile-fs-waypoint-contact [Parcels]="Parcels" [contact]="contact"> </app-last-mile-fs-waypoint-contact>
      </ng-template>
    </div>
  </div>
</div>
