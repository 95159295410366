import { Component, OnInit, Input } from '@angular/core';
import { IconTypes } from '../icon/icon.interfaces';

@Component({
  selector: 'app-how-to-block',
  templateUrl: './how-to-block.component.html',
  styleUrls: ['./how-to-block.component.scss']
})
export class HowToBlockComponent implements OnInit {

  constructor() { }
  IconTypes = IconTypes;

  @Input() number: string;
  @Input() title: string;
  @Input() imageSrc: string;
  @Input() content: string;

  ngOnInit() {
  }

}
