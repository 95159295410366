<div class="input-wrap">
  <label>{{ label }}</label>
  <div class="input-field-wrap">
    <app-icon *ngIf="svgIcon" [size]="1.4" [icon]="svgIcon" [ngClass]="'password-icon'"></app-icon>
    <input
      [placeholder]="placeholder"
      id="autocomplete"
      mat-input
      (ngModelChange)="onChange($event)"
      [value]="value || ''"
      [(ngModel)]="value"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete #auto="matAutocomplete"
      (optionSelected)="onSelectionChanged()">
      <!-- format auto complete -->
      <mat-option *ngFor="let address of sourceData" 
        [value]="address"
        class="autocomplete-format">
        <strong>
          {{address?.description}}
        </strong>
          - {{address?.name}}
        <br>
        <small>
          {{address?.formatted_address}}
        </small>
      </mat-option>
      <mat-option *ngIf="sourceData?.length === 0" class="autocomplete-format">
        No results found
      </mat-option>
    </mat-autocomplete>
  </div>
  <span *ngIf="error" class="error-message">{{ error }}</span>
</div>


<!-- [select-on-blur]="true"
[list-formatter]="autocompleListFormatter"
[accept-user-input]="true"
[source]="sourceData" -->
