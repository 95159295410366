import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FSAdminActiveLastMileTableMap, FSAdminActiveLastMileTableColumns } from 'app/admin/admin.contants';
import { PostDispatchService } from 'app/dashboard/post-dispatch/post-dispatch.service';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { LastMile, CollapsibleTableTypeEnum, PaginateEnum, Paginate } from 'app/shared/shared.interfaces';
import { SortEvent, TableAction } from 'app/shared/table/table.interfaces';
import { Observable, Subscription, from } from 'rxjs';

@Component({
  selector: 'app-tracking-active-routes',
  templateUrl: './tracking-active-routes.component.html',
  styleUrls: ['./tracking-active-routes.component.scss']
})
export class TrackingActiveRoutesComponent implements OnInit, OnDestroy {

  @Input() activeDriverPrimaryActions: TableAction[] = [];

  Icons = {
    Colors: UiColors,
    Types: IconTypes,
  };

  activeRoutes: Observable<LastMile[]>;
  activeRoutesAmount: number = 10;
  activeRoutesSubscription: Subscription;
  activeRoutesTotal: number;

  fsActiveLastMileTableMap = FSAdminActiveLastMileTableMap;
  fsActiveLastMileTableColumns = FSAdminActiveLastMileTableColumns;

  CollapsibleTableTypeEnum = CollapsibleTableTypeEnum;

  constructor(private postDispatchService: PostDispatchService) {}

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy(): void {
    this.activeRoutesSubscription.unsubscribe();
  }

  getData(): void {
    this.handleActiveLastMiles({ Amount: this.activeRoutesAmount, Direction: PaginateEnum.None });
  }

  handleActiveLastMiles($event: Paginate): void {
    this.activeRoutesAmount = $event.Amount;
    this.activeRoutesSubscription?.unsubscribe();
    this.activeRoutesSubscription = this.postDispatchService.getActiveFirestoreLastmiles(false).subscribe((response) => {
      this.activeRoutes = from([response]);
      this.activeRoutesTotal = response.length;
    });
  }

  handleLastMileSortChange($event: SortEvent): void {
    this.postDispatchService.lastMileOrderBy = $event;
    this.handleActiveLastMiles({ Amount: this.activeRoutesAmount, Direction: PaginateEnum.None });
  }

}
