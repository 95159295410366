import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CourierOrdersComponent } from './courier-orders.component';
import { CourierOrdersRoutingModule } from './courier-orders-routing.module';
import { CourierOrdersTimelineComponent } from './courier-orders-timeline/courier-orders-timeline.component';
import { ReconcileCourierOrderModalComponent } from './reconcile-courier-order-modal/reconcile-courier-order-modal.component';
import { NotesModule } from '../notes/notes.module';

@NgModule({
  declarations: [CourierOrdersComponent, CourierOrdersTimelineComponent, ReconcileCourierOrderModalComponent],
  exports: [],
  imports: [CommonModule, CourierOrdersRoutingModule, SharedModule, NotesModule],
})
export class CourierOrdersModule {}
