import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { ModalSizes } from 'app/shared/modals/modal.interfaces';
import { PlaceOrderService } from 'app/dashboard/place-order/place-order.service';
import { GridJustifyItems } from 'app/shared/grid-container/grid-container.interfaces';
import { JustifyContent } from 'app/shared/flex-container/flex-container.interfaces';
import { PaymentService } from 'app/shared/payment/payment.service';
import * as moment from 'moment';
import { environment } from '../../../../environments/environment';
import { MethodIntentionEnum } from './select-method.interface';

@Component({
  selector: 'app-select-method',
  templateUrl: './select-method.component.html',
  styleUrls: ['./select-method.component.scss'],
})
export class SelectMethodComponent implements OnInit, OnDestroy {
  @Output() clear = new EventEmitter();
  @Output() back = new EventEmitter();
  @Input() quoteResult;

  error: any;
  processing = false;
  showCreate = false;
  currentTab;
  intention: number;
  wallet;
  paymentResponse;
  selected;
  price_incl_vat;
  currency_symbol = environment.currency_symbol;
  paymentRequest;
  paymentMethods = [
    {
      type: ButtonTypes.Primary,
      title: 'Account',
    },
  ];

  public ButtonTypes = ButtonTypes;
  public IconTypes = IconTypes;
  public UiColors = UiColors;
  public ModalSizes = ModalSizes;
  public GridJustifyItems = GridJustifyItems;
  public justifyContent = JustifyContent;
  public UiThemes = UiThemes;

  fulfillmentOptionImageMap = new Map<string, string>([
    ['Cheapest', IconTypes.FulfillmentCheapest],
    ['Fastest', IconTypes.FulfillmentFastest],
  ]);

  constructor(private placeOrderService: PlaceOrderService, private paymentService: PaymentService) {
    this.paymentService.wallet.subscribe((wallet) => {
      this.wallet = wallet;
    });
  }

  isActive(selectedCard): boolean {
    return this.selected === selectedCard;
  }

  momentPipe(data): string {
    return moment(data).calendar();
  }

  selectMethod(type, rawRequest, quoteId, price_incl_vat): void {
    this.paymentService.startFromScratch();
    this.selected = rawRequest;
    this.price_incl_vat = price_incl_vat;
    let peachPaymentRequest;
    switch (type) {
      case 'Picup':
        peachPaymentRequest = this.quoteResult.request;
        peachPaymentRequest['vehicle_id'] = rawRequest;
        peachPaymentRequest['quote_id'] = quoteId;
        peachPaymentRequest['price_incl_vat'] = price_incl_vat;
        this.intention = MethodIntentionEnum.PayForPicup;
        break;
      // case 'courier_requests':
      //   requestBody = rawRequest;
      //   this.intention = 2;
      //   break;
    }
    this.paymentRequest = peachPaymentRequest;
  }

  goBack(): void {
    this.back.emit();
  }

  ngOnInit(): void {
    const option = this.quoteResult?.picup?.service_types[0];
    this.selectMethod('Picup', option.vehicle_id ?? option.description, option.quote_id, option.price_incl_vat);
  }

  ngOnDestroy(): void {
    this.paymentRequest = null;
    this.placeOrderService.quoteResult.next(undefined);
  }
}
