<h4 class="select-header" *ngIf="!paymentOpen">Select Payment Method:</h4>
<app-basic-button-group *ngIf="!paymentOpen" 
  [buttons]="paymentMethods" 
  [buttonRemPadding]="1"
  (buttonClicked)="handleButtonClick($event)">
</app-basic-button-group>

<!-- <section *ngIf="payStackSelected">
  <app-paystack 
    *ngIf="showPaystack" 
    [businessId]="actingAs?.id" 
    [userId]="user?.user_id" 
    [amount]="price" 
    [email]="user?.email" 
    [reference]="request?.customer_ref"
    (transactionOutcome)="paystackTransactionOutcome($event)" 
    (resetPaymentRequested)="resetPayment();">
  </app-paystack>
</section> -->

<!-- <section *ngIf="checkoutId">
  <app-peach-payments [checkoutId]="checkoutId"></app-peach-payments>
</section> -->

<div *ngIf="accountSelected" class="credits">
  <app-grid-container 
    [columns]="'repeat(1, 1fr)'" 
    [padding]="'5rem 5rem 0rem'" 
    [colGap]="'2rem'" 
    [rowGap]="'2rem'">
    <h4>Confirm payment with Post-Paid Account?</h4>
    <p>Please note that this Picup will be billed to your monthly account.</p>
  </app-grid-container>
  <div class="confirm-buttons">
    <app-basic-button 
      [type]="ButtonTypes.Primary" 
      [title]="'Cancel'" 
      (click)="resetPayment()"> 
    </app-basic-button>
    <app-basic-button 
      [type]="ButtonTypes.Highlight" 
      [active]="true" 
      [title]="'Continue'" 
      (click)="confirmAccount()">
    </app-basic-button>
  </div>
</div>



<div *ngIf="creditsSelected" class="credits">
  <app-grid-container [columns]="'repeat(1, 1fr)'" [padding]="'5rem 5rem 0rem'" [colGap]="'2rem'" [rowGap]="'2rem'">
    <h4>Confirm payment with credits?</h4>
  </app-grid-container>
  <div class="confirm-buttons">
    <app-basic-button 
      [type]="ButtonTypes.Primary" 
      [title]="'Cancel'" 
      (click)="resetPayment()"> 
    </app-basic-button>
    <app-basic-button 
      [type]="ButtonTypes.Highlight" 
      [active]="true" 
      [title]="'Continue'" 
      (click)="confirmCredits()">
    </app-basic-button>
  </div>
</div>

<div *ngIf="paymentError" class="error">
  <h4>{{ paymentError }}</h4>
  <app-basic-button 
    [type]="ButtonTypes.Primary" 
    [title]="'Try Again'" 
    (click)="resetPayment()"> 
  </app-basic-button>
</div>


<div *ngIf="(loading && !paymentError) || waitingForPeachRepsonse">
  <app-grid-container 
    [columns]="'repeat(1, 1fr)'" 
    [padding]="'5rem 5rem 0rem'" 
    [colGap]="'2rem'" 
    [rowGap]="'2rem'">
    <img *ngIf="intention !== 3" src="/assets/img/loader-light.gif" width="250" alt="" />
  </app-grid-container>
</div>