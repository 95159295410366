<app-section class="App Details Summary">
  <heading [compact]="true">SUMMARY</heading>
  <app-flex-container [borderBottom]="lottery ? false : true" [theme]="UiThemes.Dark">
    <!-- TODO: Parcel statuses -->

    <app-stats-icon
      [icon]="icons.types.Marker"
      [iconColor]="icons.colors.Blue"
      [iconSizeRem]="2"
      [label]="'Waypoints'"
      [data]="uniqueWaypoints"
    ></app-stats-icon>

    <app-stats-icon
      [icon]="icons.types.Profile"
      [iconColor]="icons.colors.Blue"
      [iconSizeRem]="2"
      [label]="'Contacts'"
      [data]="uniquebusinessReferencesCount.toString()"
    ></app-stats-icon>

    <app-stats-icon
      [icon]="icons.types.Parcel"
      [iconColor]="icons.colors.Blue"
      [iconSizeRem]="2"
      [label]="'Parcels'"
      [data]="Parcels.length"
    ></app-stats-icon>

    <div class="separator"></div>

    <app-stats-icon
      [icon]="icons.types.Parcel"
      [iconColor]="icons.colors.Blue"
      [iconSizeRem]="2"
      [label]="'Total'"
      [data]="totals.packages.toString()"
    ></app-stats-icon>

    <app-stats-icon
      [icon]="icons.types.Parcel"
      [iconColor]="icons.colors.Blue"
      [iconSizeRem]="2"
      [label]="'Collected'"
      [data]="totals.collected.toString()"
    ></app-stats-icon>
    <app-stats-icon
      [icon]="icons.types.Parcel"
      [iconColor]="icons.colors.Blue"
      [iconSizeRem]="2"
      [label]="'Delivered'"
      [data]="totals.delivered.toString()"
    ></app-stats-icon>
    <app-stats-icon
      [icon]="icons.types.Parcel"
      [iconColor]="icons.colors.Yellow"
      [iconSizeRem]="2"
      [label]="'Returned'"
      [data]="totals.returned.toString()"
    ></app-stats-icon>
    <app-stats-icon
      [icon]="icons.types.Cross"
      [iconColor]="icons.colors.Red"
      [iconSizeRem]="2"
      [label]="'Failed'"
      [data]="totals.failed.toString()"
    ></app-stats-icon>
  </app-flex-container>
  <app-flex-container [borderBottom]="true" [theme]="UiThemes.Dark" *ngIf="isAdmin">
      <app-stats-icon
      [icon]="icons.types.Users"
      [iconColor]="username ? icons.colors.Blue : icons.colors.Red "
      [iconSizeRem]="2"
      [label]="'Assigned User'"
      [data]="username ?? 'Unassigned'">
    </app-stats-icon>
    <app-stats-icon
      [icon]="vehicleTypes.get(vehicle)"
      [iconColor]="icons.colors.Blue"
      [iconSizeRem]="2"
      [label]="'Vehicle'"
      [data]="vehicleNames.get(vehicle)">
    </app-stats-icon>
    <ng-container *ngIf="lottery || quote">
      <app-stats-icon 
      [icon]="icons.types.Trip"
      [iconColor]="icons.colors.Blue"
      [iconSizeRem]="2"
      [label]="'Distance'"
      [data]="quote ? (quote.DistanceInMeters/1000 | number: '1.2-2') + ' km' : ((lottery?.total_trip_distance_meters | number: '1.2-2') ?? 0) + ' km' "
    ></app-stats-icon>

    <app-stats-icon
    [icon]="icons.types.DebitCard"
    [iconColor]="icons.colors.Yellow"
    [iconSizeRem]="2"
    [label]="'Earnings'"
    [data]="quote && isAdmin ? (quote.Earnings[0].Amount | currency: environment?.currency_symbol) :(lottery?.fleet === 2 || isAdmin ? (lottery?.earnings | currency: environment?.currency_symbol) : '-') ?? 0"
    ></app-stats-icon>
  </ng-container>
</app-flex-container>

</app-section>
