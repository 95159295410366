import { Component, OnInit } from '@angular/core';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { ModalAction, ModalSizes } from 'app/shared/modals/modal.interfaces';
import { ModalActionsEnum } from 'app/shared/shared.interfaces';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  selector: 'app-single-send-message',
  templateUrl: './single-send-message.component.html',
  styleUrls: ['./single-send-message.component.scss']
})
export class SingleSendMessageComponent extends SimpleModalComponent<{message: string}, boolean> implements OnInit {
  actions: ModalAction[] =[
    { name: ModalActionsEnum.Close, title: ModalActionsEnum.Close, isPrimary: true}
  ];
  IconTypes = IconTypes;
  ModalSizes = ModalSizes;
  uiColors = UiColors;

  message: string = 'There was an error sending to courier'
  constructor() {
    super();
    this.result = false;
  }

  ngOnInit(): void {
  }

  handleAction($event: string): void {
    switch ($event) {
      case ModalActionsEnum.Close:
        this.result = false;
        this.close();
        break;
    }
  }
}

