import {Component, Input, OnChanges} from '@angular/core';
import {NoContentPlaceholderProps} from './no-content-placeholder.interfaces';

@Component({
  selector: 'app-no-content-placeholder',
  templateUrl: './no-content-placeholder.component.html',
  styleUrls: ['./no-content-placeholder.component.scss']
})
export class NoContentPlaceholderComponent implements NoContentPlaceholderProps, OnChanges {
  @Input() message;
  @Input() padding;
  @Input() theme;

  public classes: string[];

  ngOnChanges() {
    this.classes = this.setClasses();
  }

  setClasses() {
    const classes: string[] = [];
    if (this.theme) {
      classes.push(this.theme);
    }

    return classes;
  }
}
