import { Component, OnInit, NgZone, ChangeDetectorRef } from '@angular/core';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { IconTypes } from '../icon/icon.interfaces';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { ModalSizes, ModalAction } from '../modals/modal.interfaces';
import { GridJustifyItems } from '../grid-container/grid-container.interfaces';
import { JustifyContent } from '../flex-container/flex-container.interfaces';
import * as axios from 'axios/dist/axios.min.js';
import { ConfirmModalComponent } from '../modals/confirm-modal.component';
import { NewZoneNameComponent } from 'app/dashboard/geocoding/zones/new-zone-name/new-zone-name.component';
import { darkMapStyle } from '../map/map.constants';
import { ButtonTypes } from '../buttons/basic-button.component';

@Component({
  selector: 'app-zone-editor',
  templateUrl: './zone-editor.component.html',
  styleUrls: ['./zone-editor.component.scss'],
})
export class ZoneEditorComponent extends SimpleModalComponent<any, any> implements OnInit {
  public IconTypes = IconTypes;
  public UiColors = UiColors;
  public UiThemes = UiThemes;
  public ModalSizes = ModalSizes;
  public GridJustifyItems = GridJustifyItems;
  public justifyContent = JustifyContent;
  buttonTypes = ButtonTypes;
  public actions: ModalAction[] = [
    { name: 'cancel', title: 'Cancel' },
    { name: 'verify', title: 'Verify', isPrimary: true, disabled: true },
    { name: 'confirm', title: 'Confirm', isPrimary: true, disabled: true },
  ];

  zone;
  selected_suburbs = [];
  drawMode = false;
  confirmed = undefined;
  business_id;
  drawingManager;
  original_zone;
  overlay = [];
  currentMode = true;
  new_zone = [];
  intersecting_zones;
  mapElement;
  map: google.maps.LatLngLiteral = {
    lat: -33.9765176,
    lng: 18.4654239
  }
  mapOptions: google.maps.MapOptions = {
    mapTypeControl: true,
    zoomControl: false,
    scrollwheel: true,
    streetViewControl: false,
    clickableIcons: false,
    styles: darkMapStyle,
    center: this.map
  }

  constructor(public simpleModalService: SimpleModalService, private ref: ChangeDetectorRef, public ngZone: NgZone) {
    super();
  }

  ngOnInit(): void {
    this.original_zone = this.zone;
  }

  handleAction($event): void {
    switch ($event) {
      case 'cancel':
        this.result = undefined;
        this.close();
        break;
      case 'confirm':
        this.confirmEdits();
        break;
      case 'verify':
        this.verifyEdits();
        break;
    }
  }

  removeZone(index): void {
    this.selected_suburbs.splice(index, 1);
  }

  selectSuburb(coords): void {
    this.actions[1].disabled = false;
    const data = {
      latitude: coords.lat(),
      longitude: coords.lng(),
      radius: 0,
    };
    axios.post('/suburb/suburb-search', data).then((response) => {
      this.selected_suburbs.push(response?.data);
    });
  }

  public trackByFunction(index, item): string {
    if (!item) {
      return null;
    }
    return index.id;
  }

  toggleMode(): void {
    this.currentMode = !this.currentMode;
    this.initDrawingManager(this.mapElement);
  }

  mapElementReady(map): void {
    this.mapElement = map;

    if (this.zone) {
      this.mapElement.fitBounds(this.extendMapBounds(this.zone.geojson));
    }
    if (!this.drawMode) {
      google.maps.event.addListener(map, 'click', function (event) {
        this.ngZone.run(() => {
          this.selectSuburb(event.latLng);
          this.ref.detectChanges();
        });
      });
    }
  }

  initDrawingManager(map: any): void {
    const drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: false,
      drawingControlOptions: {
        drawingModes: [google.maps.drawing.OverlayType.POLYGON],
      },
      polygonOptions: {
        fillColor: this.currentMode ? '#32b3ff' : UiColors.Red,
        strokeColor: this.currentMode ? '#32b3ff' : UiColors.Red,
        fillOpacity: 0.5,
        strokeWeight: 3,
        clickable: false,
        editable: true,
        zIndex: 1,
      },
    });
    drawingManager.setMap(map);

    google.maps.event.addListener(drawingManager, 'overlaycomplete', function (event) {
      const GeoJSON = [];
      this.overlay.push(event.overlay);
      for (const point of event.overlay.getPath().getArray()) {
        GeoJSON.push([point.lng(), point.lat()]);
      }
      // Need end point to equal start point:
      GeoJSON.push(GeoJSON[0]);
      this.new_zone.push(GeoJSON);
      this.actions[1].disabled = false;
    });

    google.maps.event.addListener(document.getElementById('reset'), 'click', function () {
      this.overlay.forEach((overlay) => {
        overlay.setMap(null);
      });
      drawingManager.setDrawingMode(null);

      this.reset();
    });
  }

  reset(): void {
    this.new_zone = [];
    this.overlay.forEach((overlay) => {
      overlay.setMap(null);
    });
    this.drawMode = false;
    this.selected_suburbs = [];
    this.intersecting_zones = undefined;
    this.actions[2].disabled = true;
    this.actions[1].disabled = true;
    this.confirmed = undefined;
  }

  verifyEdits(): void {
    const suburb_ids = [];
    this.selected_suburbs.forEach((suburb) => {
      if (suburb.features[0]) {
        suburb_ids.push(suburb.features[0].properties.id);
      }
    });
    const data = {
      business_id: this.business_id,
      polygon: this.new_zone.length > 0 ? this.new_zone : null,
      type: this.business_id ? 'zones' : 'picup-areas',
      suburb_ids: suburb_ids,
    };
    axios.post('/spatial/intersects', data).then((response) => {
      this.actions[2].disabled = false;
      this.intersecting_zones = response?.data;
      if (response?.data.features.length === 0) {
        this.confirmed = true;
      } else {
        this.confirmed = false;
      }
    });
  }

  confirmEdits(): void {
    if (this.zone) {
      const overlapping_count = this.intersecting_zones.features.length;
      const suburb_ids = [];
      this.selected_suburbs.forEach((suburb) => {
        if (suburb.features[0]) {
          suburb_ids.push(suburb.features[0].properties.id);
        }
      });
      this.simpleModalService
        .addModal(ConfirmModalComponent, {
          title: 'Are you sure?',
          message: overlapping_count + ' overlapping zones will be overwritten',
        })
        .subscribe((isConfirmed) => {
          if (!isConfirmed) {
            return;
          } else {
            const data = {
              id: this.zone.id,
              polygon: this.new_zone.length > 0 ? this.new_zone : null,
              operation: this.currentMode ? 'union' : 'difference',
              suburb_ids: suburb_ids,
            };
            axios.post('/spatial/update-spatial-geography', data).then(() => {
              this.result = true;
              this.close();
            });
          }
        });
    } else {
      this.createNewZone();
    }
  }

  toggleDrawMode(): void {
    this.drawMode = !this.drawMode;
    if (this.drawMode) {
      this.initDrawingManager(this.mapElement);
    }
  }

  createNewZone(): void {
    const suburb_ids = [];
    this.selected_suburbs.forEach((suburb) => {
      if (suburb.features[0]) {
        suburb_ids.push(suburb.features[0].properties.id);
      }
    });
    this.simpleModalService.addModal(NewZoneNameComponent).subscribe((result) => {
      if (result) {
        const data = {
          type: this.business_id ? 'zones' : 'picup-areas',
          business_id: this.business_id,
          polygon: this.new_zone,
          name: result,
          suburb_ids: suburb_ids,
        };
        axios.post('/spatial/insert-spatial-geography', data).then(() => {
          this.result = true;
          this.close();
        });
      }
    });
  }

  extendMapBounds(geoArray): google.maps.LatLngBounds{
    const bounds = new google.maps.LatLngBounds();
    geoArray.features.forEach(function (feature) {
      if (feature.geometry.type === 'Point') {
        bounds.extend(new google.maps.LatLng(feature.geometry.coordinates[1], feature.geometry.coordinates[0]));
      }
      if (feature.geometry.type === 'Polygon') {
        feature.geometry.coordinates.forEach((coordinate) => {
          coordinate.forEach((element) => {
            bounds.extend(new google.maps.LatLng(element[1], element[0]));
          });
        });
      }
      if (feature.geometry.type === 'MultiPolygon') {
        feature.geometry.coordinates.forEach((coordinate) => {
          coordinate.forEach((element) => {
            element.forEach((deepest) => {
              bounds.extend(new google.maps.LatLng(deepest[1], deepest[0]));
            });
          });
        });
      }
    });
    return bounds;
  }
  // create or find models
  highlightStyle(): any {
    return {
      fillOpacity: 0.4,
      strokeOpacity: 1,
      fillColor: UiColors.White,
      strokeColor: UiColors.White,
    };
  }

  suburbStyle(): any {
    return {
      fillOpacity: 0.2,
      strokeOpacity: 1,
      fillColor: UiColors.Yellow,
      strokeColor: UiColors.Yellow,
    };
  }

  confirmStyle(): any {
    return {
      fillOpacity: 0.4,
      strokeOpacity: 1,
      fillColor: '#32b3ff',
      strokeColor: '#32b3ff',
    };
  }

  intersectingStyle(): any {
    return {
      fillOpacity: 0.3,
      strokeOpacity: 1,
      fillColor: UiColors.Red,
      strokeColor: UiColors.Red,
    };
  }
}
