<app-grid-container [columns]="'repeat(2, 1fr)'" [colGap]="'2rem'" [theme]="UiThemes.Light">
  <app-dropdown
    class="warehouse-dropdown"
    *ngIf="isCollection && !waypoint?.searchAddress && available_warehouses?.length"
    [label]="'Collection Warehouse'"
    [activeItem]="waypoint?.warehouse ? waypoint?.warehouse : 'Select a warehouse...'"
    [dropdownTheme]="'light'"
  >
    <div dropdownRef>
      <a class="dropdown-item" (click)="manualAddress()">
        {{ 'Manual Address' }}
      </a>
      <a *ngFor="let warehouse of available_warehouses" class="dropdown-item" (click)="selectWarehouse(warehouse)">
        {{ warehouse.name }}
      </a>
    </div>
  </app-dropdown>
  <app-places-autocomplete
    *ngIf="waypoint?.searchAddress || !isCollection || available_warehouses?.length === 0 || !available_warehouses"
    [label]="waypoint?.contact_description ? 'Address' : 'Search Address'"
    (placesResponse)="onPlacesChange($event); checkValid()"
    [value]="waypoint?.address?.formatted_address"
    [placeholder]="''"
    [isInvalid]="!waypoint?.address.formatted_address && !waypoint?.address.latitude && displayInvalid"
  >
  </app-places-autocomplete>
  <app-autocomplete
    class="addressBook"
    [placeholder]="'Start Typing...'"
    [sourceData]="address"
    [label]="'Or Select From Address book'"
    (valueChanged)="onAddressBookPlace($event); checkValid()"
    (keywordChanged)="getAddressesFromAddressBook($event)"
    [value]="waypoint?.contact_description"
  >
  </app-autocomplete>
</app-grid-container>

<app-grid-container
  *ngIf="waypoint?.address.latitude"
  [columns]="'repeat(1, 1fr)'"
  [colGap]="'2rem'"
  [theme]="UiThemes.Light"
  [padding]="'2rem 0'"
>
  <google-map
    #map
    width="100%"
    height="300px"
    class="map"
    id="map"
    [zoom]="14"
    (tilesloaded)="mapElementReady()"
    [options]="mapOptions"
  >
  </google-map>
</app-grid-container>

<app-grid-container [columns]="'repeat(2, 1fr)'" [colGap]="'2rem'" [theme]="UiThemes.Light">
  <input-field
    [type]="'text'"
    (valueChanged)="waypoint?.address.complex = $event"
    [value]="waypoint?.address.complex"
    [label]="'Business/Complex Name'"
    [disabled]="selectedWarehouse"
  ></input-field>
  <input-field
    [type]="'text'"
    (valueChanged)="waypoint?.address.unit_no = $event"
    [value]="waypoint?.address.unit_no"
    [label]="'Unit/Floor'"
    [disabled]="selectedWarehouse"
  >
  </input-field>
</app-grid-container>

<app-grid-container [columns]="'repeat(3, 1fr)'" [colGap]="'2rem'" [theme]="UiThemes.Light">
  <input-field
    [type]="'text'"
    (valueChanged)="waypoint?.contact.name = $event; checkValid()"
    [autoComplete]="'new-name'"
    [value]="waypoint?.contact.name"
    [label]="'Name'"
    [isInvalid]="!waypoint?.contact.name && displayInvalid"
    [disabled]="selectedWarehouse"
  >
  </input-field>
  <input-field
    [type]="'tel'"
    (valueChanged)="waypoint?.contact.cellphone = $event; checkValid()"
    [value]="waypoint?.contact.cellphone"
    [label]="'Phone'"
    [isInvalid]="!(waypoint?.contact.cellphone | phoneValidation) && displayInvalid"
    [disabled]="selectedWarehouse"
    [autoComplete]="'none'"
  ></input-field>
  <input-field
    [autoComplete]="'none'"
    [type]="'text'"
    (valueChanged)="waypoint?.contact.email = $event; checkValid()"
    [value]="waypoint?.contact.email"
    [label]="'Email'"
    [isInvalid]="(!validateEmail(waypoint?.contact.email) || !waypoint?.contact.email) && displayInvalid"
    [disabled]="selectedWarehouse"
  >
  </input-field>
</app-grid-container>

<input-field
  [autoComplete]="'off'"
  [type]="'text'"
  (valueChanged)="waypoint.special_instructions = $event"
  [value]="waypoint.special_instructions"
  [label]="'Special Instructions'"
></input-field>
<label *ngIf="!addressBookUsed" class="saveAddress">
  Save Address:
  <input type="checkbox" (change)="waypoint.save = !waypoint?.save" [checked]="waypoint?.save"
/></label>

<app-grid-container *ngIf="waypoint?.save" [columns]="'repeat(3, 1fr)'" [colGap]="'2rem'" [theme]="UiThemes.Light">
  <input-field
    [placeholder]="'Description'"
    [autoComplete]="'none'"
    [type]="'text'"
    (valueChanged)="waypoint['contact_description'] = $event"
    [value]="waypoint?.contact_description ? waypoint?.contact_description : waypoint?.contact.name"
  >
  </input-field>
</app-grid-container>
<app-grid-container
  *ngIf="!isCollection && packageType !== 'freight'"
  [columns]="'repeat(2, 1fr)'"
  [padding]="'1rem 0rem'"
  [colGap]="'2rem'"
  [theme]="UiThemes.Light"
>
  <div class="parcels">
    <label>Click the correct parcel Size to add it to the trip</label>
    <app-grid-container [columns]="'repeat(5, 1fr)'" [colGap]="'2rem'" [rowGap]="'2rem'" [theme]="UiThemes.Light">
      <div class="selectors parcel-selector" *ngFor="let parcelType of parcelTypes" (click)="addParcel(parcelType)">
        <svg-icon [src]="'/assets/img/' + parcelType + '.svg'"></svg-icon>
      </div>
    </app-grid-container>
  </div>
  <app-grid-container
    [ngStyle]=""
    class="parcel-editor-container"
    [columns]="'repeat(2, 1fr)'"
    [colGap]="'2rem'"
    [rowGap]="'1rem'"
    [padding]="'2rem 0 0'"
    [theme]="UiThemes.Light"
  >
    <div class="parcel-editor" *ngFor="let parcel of waypoint?.parcels; let i = index">
      <input-field
        [isInvalid]="!parcel.reference && displayInvalid"
        [svgActionIcon]="IconTypes.Cross + '.svg'"
        [placeholder]="'Parcel Reference'"
        [type]="'text'"
        (actionClicked)="removeParcel(i)"
        (valueChanged)="parcel.reference = $event; checkValid()"
        [value]="parcel.reference"
        [label]="parcel.size"
        [svgActionIcon2]="IconTypes.Details + '.svg'"
        (action2Clicked)="addRemoveEnabledIndex(i)"
        [visibleAction2]="!(enabledDescriptions | isDescriptionEnabled : i)"
      >
      </input-field>
      <input-field
        *ngIf="enabledDescriptions | isDescriptionEnabled : i"
        [placeholder]="'Parcel Description'"
        [type]="'text'"
        (valueChanged)="parcel.parcel_description = $event; checkValid()"
        [value]="parcel.parcel_description"
        (actionClicked)="clearDescription(i)"
        [svgActionIcon]="IconTypes.Cross + '.svg'"
      >
      </input-field>
    </div>
  </app-grid-container>
</app-grid-container>

<!-- <app-grid-container class="toggle-container">
  <label class="toggle-label">Enable return trip:</label>
  <mat-slide-toggle (change)="enableReturns($event)"> </mat-slide-toggle>
</app-grid-container> -->

<app-flex-container [theme]="UiThemes.Light" [padding]="'0'" class="cta">
  <div class="error" *ngIf="waypoint?.parcels?.length && !waypoint?.parcels[0]?.reference && displayInvalid">
    <h4>Please input a reference</h4>
  </div>
  <div class="error" *ngIf="waypoint?.parcels?.length === 0 && displayInvalid">
    <h4>Please select a parcel</h4>
  </div>
  <div
    class="error"
    *ngIf="waypoint?.contact?.cellphone?.length < 10 && displayInvalid && waypoint?.contact?.cellphone"
  >
    <h4>Please supply at least 10 digits</h4>
  </div>
</app-flex-container>
