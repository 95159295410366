<div class="modal">
  <div class="modal-content">
    <div class="splitter">
      <google-map
        #map
        width="100%"
        height="100%"
        [zoom]="mapZoom"
        [options]="gMapOptions"
      >
        <!-- markers for loop here -->
        <!-- <map-marker
          #markerRed
          *ngIf="geocodedMarkerOptions.position"
          [agmFitBounds]="true"
          [options]="geocodedMarkerOptions"
          (mouseOver)="onMouseOver(infoWindow, $event)"
          (mouseOut)="onMouseOut(infoWindow, $event)"
          (markerClick)="onMouseClick($event)"
          (dragEnd)="onDragEndRed($event)"
        > -->
          <!-- <google-map-snazzy-info-window [showCloseButton]="false" #infoWindow [panOnOpen]="false" [fontColor]="'black'">
            <ng-template>
              <h4>Suggested location</h4>
              <h6>Click to use</h6>
            </ng-template>
          </google-map-snazzy-info-window> -->
        <!-- </map-marker>
        <map-marker
          #markerGreen
          *ngIf="clientMarkerOptions.position"
          [options]="clientMarkerOptions"
          [agmFitBounds]="true"
          (mouseOver)="onGreenMouseOver(infoWindow2, $event)"
          (mouseOut)="onGreenMouseOut(infoWindow2, $event)"
          (dragEnd)="onDragEndGreen($event)"
          (markerClick)="onGreenClick($event)"
        > -->
          <!-- <google-map-snazzy-info-window [showCloseButton]="false" #infoWindow2 [panOnOpen]="false" [fontColor]="'black'">
            <ng-template>
              <h4>Provided Coordinates</h4>
              <h6>Click to use</h6>
            </ng-template>
          </google-map-snazzy-info-window> -->
        <!-- </map-marker> -->
        <!-- <div *ngIf="zone_response && zone_required">
          <ng-template ngFor let-index="index" let-zone [ngForOf]="zone_response?.zones">
            <agm-data-layer
              [geoJson]="zone.geojson"
              (layerClick)="layerClick($event)"
              [style]="index === 0 ? zoneStyle : multiStyle"
            >
            </agm-data-layer>
          </ng-template>
          <agm-data-layer
            *ngIf="zone_response?.suburb?.geojson"
            [geoJson]="zone_response.suburb.geojson"
            [style]="suburbStyle"
          >
          </agm-data-layer>
        </div> -->
      </google-map>
      <div class="legend">
        <div class="geocoded">
          GEOCODED POINT
          <svg-icon
            [svgStyle]="{ 'width.px': '27', fill: '#ea4335' }"
            src="../../../assets/img/icons/map-marker.svg"
            class="icon"
          ></svg-icon>
        </div>
        <div class="waypoint" *ngIf="clientMarkerOptions.position">
          WAYPOINT COORDINATE
          <svg-icon
            [svgStyle]="{ 'width.px': '27', fill: '#00e64d' }"
            src="../../../assets/img/icons/map-marker.svg"
            class="icon"
          ></svg-icon>
        </div>
      </div>
    </div>
    <div class="splitter geocoder-container gradient-bg">
      <div class="address-container" *ngIf="original_address">
        <div class="block">
          <div class="title">
            <div class="row">
              <h4>Provided Data</h4>
              <div class="underline"></div>
            </div>
          </div>
          <div class="row">
            <label
              >Address
              <p>
                <!-- string interpolation fails here if we get '&' character in the address. innerHTML is the solution -->
                <span
                  *ngIf="original_address.address && original_address.address.client_address"
                  [innerHTML]="original_address.address.client_address"
                ></span>
              </p>
            </label>
          </div>
          <div class="contact-details">
            <div class="row row-double">
              <label
                >Name
                <p>{{ original_address?.address?.customer_name || 'Not Provided' }}</p>
              </label>
              <label
                >Number
                <p>{{ original_address?.address?.customer_phone || 'Not Provided' }}</p>
              </label>
            </div>
          </div>
        </div>

        <div class="block" *ngIf="!new_address && original_address?.address?.geocoded_address">
          <div class="title">
            <div class="row">
              <h4>Geocoded Address</h4>
              <div class="underline"></div>
            </div>
          </div>
          <div class="autocomplete-result">
            <div class="row">
              <label
                >Geocoded
                <p>{{ original_address.address.geocoded_address }}</p>
              </label>
            </div>
            <div class="row row-double" *ngIf="zone_response && zone_required">
              <label
                >Suburb
                <p>{{ zone_response?.suburb.name || 'Not Provided' }}</p>
              </label>
              <label
                >Zone
                <p>{{ (zone_response?.zones)[0]?.name || 'Not Provided' }}</p>
              </label>
            </div>
          </div>
        </div>

        <div class="block">
          <div class="title">
            <div class="row">
              <h4>Overwrite Existing Geocode</h4>
              <div class="underline"></div>
            </div>
          </div>
          <div class="row">
            <div id="autocomplete-wrap2">
              <app-places-autocomplete
                (placesResponse)="getAutoCompleteAddress($event)"
                [showClear]="true"
                (clearAutoComplete)="clearAutoComplete()"
                [value]="autocomplete"
                [placeholder]="'Enter Address'"
              >
              </app-places-autocomplete>
            </div>
          </div>
          <div class="autocomplete-result" *ngIf="new_address && zone_response">
            <div class="row">
              <label
                >Geocoded
                <p>{{ new_address.formatted_address }}</p>
              </label>
            </div>

            <div class="row row-double" *ngIf="zone_required && zone_response.zones">
              <label
                >Suburb
                <p>{{ zone_response?.suburb.name || 'Not Provided' }}</p>
              </label>
              <label *ngIf="zone_response?.zones?.length === 1"
                >Zone
                <p>{{ (zone_response?.zones)[0]?.name }}</p>
              </label>
            </div>
            <div *ngIf="zone_required && zone_response?.zones?.length > 1">
              <div class="row">
                <label>Select Zone</label>
              </div>
              <div class="row">
                <app-dropdown
                  *ngIf="zone_response?.zones.length > 1"
                  class="dropdown"
                  [activeItem]="zone_response.zones[0].name"
                  [dropdownTheme]="'light'"
                >
                  <div dropdownRef>
                    <ng-template ngFor let-zone [ngForOf]="zone_response.zones">
                      <a (click)="selectZone(zone.name)" class="dropdown-item">{{ zone.name }}</a>
                    </ng-template>
                  </div>
                </app-dropdown>
              </div>
            </div>
          </div>
        </div>

        <div class="block">
          <div class="row row-double">
            <input-field
              [type]="'text'"
              [labelTheme]="'grey'"
              (valueChanged)="unit = $event"
              [autoComplete]="'off'"
              [value]="unit"
              [label]="'Unit'"
            >
            </input-field>
            <input-field
              [type]="'text'"
              [labelTheme]="'grey'"
              (valueChanged)="building = $event"
              [autoComplete]="'off'"
              [value]="building"
              [label]="'Building Or Complex'"
            >
            </input-field>
          </div>
        </div>

        <div class="block">
          <div class="row row-double" *ngIf="manual_entry_required">
            <input-field
              [type]="'text'"
              [labelTheme]="'grey'"
              (valueChanged)="explicit_suburb = $event"
              [autoComplete]="'off'"
              [value]="explicit_suburb"
              [label]="'Suburb'"
              [required]="true"
              [isInvalid]="!explicit_suburb"
            >
            </input-field>
            <input-field
              [type]="'text'"
              [labelTheme]="'grey'"
              (valueChanged)="explicit_postalcode = $event"
              [autoComplete]="'off'"
              [value]="explicit_postalcode"
              [label]="'Postal Code'"
              [required]="true"
              [isInvalid]="!explicit_postalcode"
            >
            </input-field>
          </div>
        </div>

        <div class="block">
          <div class="row">
            <app-basic-button
              [hidden]="clientMarkerOptions.position == null"
              class="add"
              [title]="'Add Driver Location Pin'"
              [type]="buttonTypes.Secondary"
              (click)="addDriverLocationPin()"
            >
            </app-basic-button>
          </div>

          <div class="row row-double">
            <app-basic-button
              [disabled]="noGeocode || pin_updated || manual_entry_required"
              [processing]="vettingProcess"
              [title]="'Vett'"
              [block]="true"
              [type]="buttonTypes.Green"
              (click)="vetAddress()"
            >
            </app-basic-button>
            <app-basic-button
              [disabled]="!new_address && !pin_updated && !manual_entry_required"
              [title]="'Update'"
              [block]="true"
              [type]="buttonTypes.Highlight"
              [active]="true"
              (click)="saveNewGeocode()"
            >
            </app-basic-button>
          </div>
          <div class="row row-double">
            <app-basic-button
              [disabled]="this.statuses.length === 0"
              [title]="'Skip'"
              [block]="true"
              [type]="buttonTypes.Primary"
              (click)="skipAddress()"
            >
            </app-basic-button>
            <app-basic-button 
              [title]="'Flag'" 
              [block]="true" 
              [type]="buttonTypes.Warning" 
              (click)="flagAddress()">
            </app-basic-button>
          </div>
        </div>

        <div *ngIf="error" class="error">
          <p>{{ error }}</p>
        </div>

        <div class="block">
          <ng-template ngFor let-status [ngForOf]="statuses">
            <small>
              <strong>{{ status.status | stripUnderscores }}:</strong> {{ status.count }}
            </small>
          </ng-template>
        </div>
      </div>
      <app-loader *ngIf="!original_address" 
        [loadingMessage]="'Loading Address'">
      </app-loader>
      <div class="block">
        <div class="row">
          <app-basic-button 
            class="cancel" 
            [title]="'Cancel'" 
            [type]="buttonTypes.Tertiary" 
            (click)="cancel()">
          </app-basic-button>
        </div>
      </div>
    </div>
  </div>
</div>
