import {
  firebaseNotesDateObject,
  getDeeplyNestedCustomerName,
  lotteryRestartCountFormatter,
  mfHandleNoneDriverReservation,
  mfHandleNoneFleet,
  mfHandleNoneFleetPlanned,
  mfRoundTripFormatter,
} from './../shared/table/map-formatters.table.helpers';
import {
  TableDataMap,
  TableColumn,
  TableAction,
  TableDataSetHighlightColor,
  TablePrefixes,
} from 'app/shared/table/table.interfaces';
import { Admin_Trips_Columns, AdminTrip$Keys, Admin_Col_Finance } from './admin.interfaces';
import {
  mfGetNestedObjectValue,
  mfGetDriverType,
  mfGetNestedObjectValuesByKeyArray,
  mfWaypointCompleteStatus,
  mfWaypointCompleteProgress,
  mfWaypointTotal,
  htmlContent,
  mfHumanizeString,
  mfGetNestedArrayPropertyCommaSeparated,
  firestoreDateObject,
  mfGetArrayPropertyCommaSeparated,
  mfGetDriverNamesCommaSeparated,
} from 'app/shared/table/map-formatters.table.helpers';
import {
  mfGetLastItemTime,
  mfSetHealthHighlightColor,
  mfGetLastItemDetails,
  mfSetAdminHealthHighlightColor,
} from 'app/dashboard/post-dispatch/post-dispatch.helpers';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { PD_Col_Sched, PD_Col_InProg } from 'app/dashboard/post-dispatch/post-dispatch.interfaces';
import { ActiveTrip$Keys, HealthStates, PicupDetailsKeys } from 'app/dashboard/trips.enums';
import { environment } from 'environments/environment';
import { mfFormatEnum, mfiVehicleType } from './admin.service';
import {
  firestoreCourierPostDispatchEventHighlighter,
  firestorePostDispatchEventHighlighter,
  firestorePostDispatchEventNameMapping,
  fireStorePostDispatchimeAgoHighlighter,
  lotteryRestartCountHighlighter,
} from 'app/dashboard/post-dispatch/post-dispatch.constants';

export const mfPublicNoteHighlighter: TableDataSetHighlightColor = (map: string[], item: any) => {
  if (item[map[0]]) {
    return 'highlight-' + HealthStates.Primary;
  } else {
    return null;
  }
};

export const AdminTableDataMap: TableDataMap = {
  cells: {
    [Admin_Trips_Columns.PicupId]: { column: Admin_Trips_Columns.PicupId, map: AdminTrip$Keys.PicupId },
    [Admin_Trips_Columns.ClaimedByUserId]: {
      column: Admin_Trips_Columns.ClaimedByUserId,
      map: AdminTrip$Keys.ClaimedByUserId,
    },
    [Admin_Trips_Columns.CustomerRefNum]: {
      column: Admin_Trips_Columns.CustomerRefNum,
      map: [AdminTrip$Keys.PicupDetails, AdminTrip$Keys.CustomerReference],
      format: mfGetNestedObjectValue,
    },
    [Admin_Trips_Columns.WhenAcceptedByCustomer]: {
      column: Admin_Trips_Columns.PicupId,
      map: AdminTrip$Keys.WhenAcceptedByCustomer,
      displayPipeArgs: ['date', 'mediumTime'],
    },
    [Admin_Trips_Columns.TripId]: {
      column: Admin_Trips_Columns.TripId,
      map: [AdminTrip$Keys.PicupDetails, AdminTrip$Keys.TripId],
      format: mfGetNestedObjectValue,
      displayPipeArgs: ['none'],
    },
    [Admin_Trips_Columns.LastEvent]: {
      column: Admin_Trips_Columns.LastEvent,
      map: [AdminTrip$Keys.LastEvent],
      format: mfGetLastItemTime,
      displayPipeArgs: ['timeAgo'],
    },
    [Admin_Trips_Columns.LastEventDetails]: {
      column: Admin_Trips_Columns.LastEventDetails,
      map: [AdminTrip$Keys.LastEvent, AdminTrip$Keys.Admin, AdminTrip$Keys.Describe],
      format: mfGetNestedObjectValuesByKeyArray,
      highlighted: true,
      highlightColorClass: mfSetHealthHighlightColor,
      highlightColorMap: [AdminTrip$Keys.Health],
    },
    [Admin_Trips_Columns.NextEvent]: { column: Admin_Trips_Columns.NextEvent, map: [AdminTrip$Keys.NextEvent] },
    [Admin_Trips_Columns.OwnerName]: {
      column: Admin_Trips_Columns.OwnerName,
      map: [AdminTrip$Keys.PicupDetails, AdminTrip$Keys.OwnerName],
      format: mfGetNestedObjectValue,
    },
    [Admin_Trips_Columns.DriverName]: { column: Admin_Trips_Columns.DriverName, map: [AdminTrip$Keys.DriverName] },
    [Admin_Trips_Columns.Waypoints]: {
      column: Admin_Trips_Columns.Waypoints,
      map: [AdminTrip$Keys.PicupDetails, AdminTrip$Keys.WaypointStatus],
      format: mfWaypointCompleteStatus,
    },
    [Admin_Trips_Columns.Progress]: {
      column: Admin_Trips_Columns.Progress,
      map: [AdminTrip$Keys.WaypointStatus],
      format: mfWaypointCompleteProgress,
    },
    [Admin_Trips_Columns.TripType]: {
      column: Admin_Trips_Columns.TripType,
      map: [AdminTrip$Keys.TripType],
      format: mfGetDriverType,
    },
  },
};

export const AdminTableDataColumns: TableColumn[] = [
  { name: Admin_Trips_Columns.PicupId, displayName: ' ', hidden: true },
  { name: Admin_Trips_Columns.ClaimedByUserId, displayName: ' ', hidden: true },
  { name: Admin_Trips_Columns.TripId, displayName: 'Trip Id' },
  { name: Admin_Trips_Columns.CustomerRefNum, displayName: 'Customer Ref' },
  { name: Admin_Trips_Columns.WhenAcceptedByCustomer, displayName: 'When Accepted', sortable: true },
  { name: Admin_Trips_Columns.LastEvent, displayName: 'Last Event', sortable: true },
  { name: Admin_Trips_Columns.LastEventDetails, displayName: 'Admin Event' },
  { name: Admin_Trips_Columns.NextEvent, displayName: 'Next Event', sortable: true },
  { name: Admin_Trips_Columns.OwnerName, displayName: 'User/Business', sortable: true },
  { name: Admin_Trips_Columns.DriverName, displayName: 'Driver', sortable: true },
  { name: Admin_Trips_Columns.Waypoints, displayName: 'Waypoints', sortable: true },
  { name: Admin_Trips_Columns.Progress, displayName: 'Progress', sortable: false },
  { name: Admin_Trips_Columns.TripType, displayName: 'Trip Type', sortable: true },
];

export const AdminScheduledTableDataColumns: TableColumn[] = [
  { name: Admin_Trips_Columns.PicupId, displayName: ' ', hidden: true },
  { name: Admin_Trips_Columns.ClaimedByUserId, displayName: ' ', hidden: true },
  { name: Admin_Trips_Columns.TripId, displayName: 'Trip Id' },
  { name: Admin_Trips_Columns.ScheduledTimeStamp, displayName: 'Scheduled', sortable: true },
  { name: Admin_Trips_Columns.Scheduled, displayName: 'When Released', sortable: true },
  { name: Admin_Trips_Columns.LastEvent, displayName: 'Last Event', sortable: true },
  { name: Admin_Trips_Columns.LastEventDetails, displayName: 'Last Event' },
  { name: Admin_Trips_Columns.OwnerName, displayName: 'Owner', sortable: true },
  { name: Admin_Trips_Columns.WarehouseName, displayName: 'Warehouse', sortable: true },
  { name: Admin_Trips_Columns.Waypoints, displayName: 'Waypoints', sortable: true },
];

export const AdminScheduleTableMap: TableDataMap = {
  cells: {
    [Admin_Trips_Columns.PicupId]: { column: Admin_Trips_Columns.PicupId, map: AdminTrip$Keys.PicupId },
    [Admin_Trips_Columns.ClaimedByUserId]: {
      column: Admin_Trips_Columns.ClaimedByUserId,
      map: AdminTrip$Keys.ClaimedByUserId,
    },
    [Admin_Trips_Columns.TripId]: {
      column: Admin_Trips_Columns.TripId,
      map: [AdminTrip$Keys.PicupDetails, AdminTrip$Keys.TripId],
      format: mfGetNestedObjectValue,
      displayPipeArgs: ['none'],
    },
    [Admin_Trips_Columns.Scheduled]: {
      column: Admin_Trips_Columns.Scheduled,
      map: [AdminTrip$Keys.PicupDetails, AdminTrip$Keys.ScheduledDate],
      format: mfGetNestedObjectValue,
      displayPipeArgs: ['timeAgo'],
    },
    [Admin_Trips_Columns.ScheduledTimeStamp]: {
      column: Admin_Trips_Columns.ScheduledTimeStamp,
      map: [AdminTrip$Keys.PicupDetails, AdminTrip$Keys.ScheduledDate],
      format: mfGetNestedObjectValue,
      displayPipeArgs: ['date', 'dd LLL HH:mm'],
    },
    [Admin_Trips_Columns.LastEvent]: {
      column: Admin_Trips_Columns.LastEvent,
      map: [AdminTrip$Keys.LastEvent],
      format: mfGetLastItemTime,
      displayPipeArgs: ['timeAgo'],
    },
    [Admin_Trips_Columns.LastEventDetails]: {
      column: Admin_Trips_Columns.LastEventDetails,
      map: [AdminTrip$Keys.LastEvent],
      format: mfGetLastItemDetails,
    },
    [Admin_Trips_Columns.OwnerName]: {
      column: Admin_Trips_Columns.OwnerName,
      map: [AdminTrip$Keys.PicupDetails, AdminTrip$Keys.OwnerName],
      format: mfGetNestedObjectValue,
    },
    [Admin_Trips_Columns.WarehouseName]: {
      column: Admin_Trips_Columns.WarehouseName,
      map: [AdminTrip$Keys.PicupDetails, AdminTrip$Keys.WarehouseName],
      format: mfGetNestedObjectValue,
    },
    [Admin_Trips_Columns.Waypoints]: {
      column: Admin_Trips_Columns.Waypoints,
      map: AdminTrip$Keys.TotalWaypoints,
      format: mfWaypointTotal,
    },
  },
};

export const AdminTablePrimaryActions: TableAction[] = [
  { event: 'viewTrip', title: 'View Trip', icon: IconTypes.Eye, icon_color: UiColors.Grey8 },

  {
    event: 'assignToMe',
    title: 'Assign Trip',
    icon: IconTypes.Profile,
    icon_color: UiColors.Grey8,
    reverse_dependency: 'claimedByUserId',
  },
  {
    event: 'assignToMe',
    title: 'Assign Trip',
    icon: IconTypes.Profile,
    icon_color: UiColors.Blue,
    dependency: 'claimedByUserId',
  },
];

export const PostDispatchUserInProgressTableColumns: TableColumn[] = [
  { name: PD_Col_InProg.PicupId, displayName: ' ', hidden: true },
  { name: PD_Col_InProg.CustomerRefNum, displayName: 'Customer Ref' },
  { name: PD_Col_InProg.LastEvent, displayName: 'Last Event', sortable: true },
  { name: PD_Col_InProg.LastEventDetails, displayName: 'Last Event' },
  { name: PD_Col_InProg.NextEvent, displayName: 'Next Event', sortable: true },
  { name: PD_Col_InProg.DriverName, displayName: 'Driver', sortable: true },
  { name: PD_Col_InProg.Waypoints, displayName: 'Waypoints', sortable: true },
  { name: PD_Col_InProg.TripType, displayName: 'Trip Type', sortable: true },
];

export const PostDispatchUserScheduledTableColumns: TableColumn[] = [
  { name: PD_Col_Sched.PicupId, displayName: ' ', hidden: true },
  { name: PD_Col_Sched.CustomerRefNum, displayName: 'Customer Ref' },
  { name: PD_Col_Sched.ScheduledTimeStamp, displayName: 'Scheduled', sortable: true },
  { name: PD_Col_Sched.Scheduled, displayName: 'When Released', sortable: true },
  { name: PD_Col_InProg.LastEvent, displayName: 'Last Event', sortable: true },
  { name: PD_Col_InProg.LastEventDetails, displayName: 'Last Event' },
  { name: PD_Col_Sched.Waypoints, displayName: 'Waypoints', sortable: true },
];

export const PostDispatchUserInProgressTableMap: TableDataMap = {
  cells: {
    [PD_Col_InProg.PicupId]: { column: PD_Col_InProg.PicupId, map: ActiveTrip$Keys.PicupId },
    [PD_Col_InProg.CustomerRefNum]: {
      column: PD_Col_InProg.CustomerRefNum,
      map: [ActiveTrip$Keys.PicupDetails, PicupDetailsKeys.CustomerReference],
      format: mfGetNestedObjectValue,
    },
    [PD_Col_InProg.LastEvent]: {
      column: PD_Col_InProg.LastEvent,
      map: [ActiveTrip$Keys.LastEvent],
      format: mfGetLastItemTime,
      displayPipeArgs: ['timeAgo'],
    },
    [PD_Col_InProg.LastEventDetails]: {
      column: PD_Col_InProg.LastEventDetails,
      map: [ActiveTrip$Keys.LastEvent],
      format: mfGetLastItemDetails,
      highlighted: true,
      highlightColorClass: mfSetHealthHighlightColor,
      highlightColorMap: [ActiveTrip$Keys.Health],
    },
    [PD_Col_InProg.NextEvent]: { column: PD_Col_InProg.NextEvent, map: [ActiveTrip$Keys.NextEvent] },
    [PD_Col_InProg.DriverName]: { column: PD_Col_InProg.DriverName, map: [ActiveTrip$Keys.DriverName] },
    [PD_Col_InProg.Waypoints]: {
      column: PD_Col_InProg.Waypoints,
      map: [ActiveTrip$Keys.PicupDetails, PicupDetailsKeys.WaypointStatus],
      format: mfWaypointCompleteStatus,
    },
    [PD_Col_InProg.TripType]: { column: PD_Col_InProg.TripType, map: 'trip_type', format: mfGetDriverType },
  },
};

export const PostDispatchUserScheduleTableMap: TableDataMap = {
  cells: {
    [PD_Col_Sched.PicupId]: { column: PD_Col_Sched.PicupId, map: ActiveTrip$Keys.PicupId },
    [PD_Col_Sched.CustomerRefNum]: {
      column: PD_Col_Sched.CustomerRefNum,
      map: [ActiveTrip$Keys.PicupDetails, PicupDetailsKeys.CustomerReference],
      format: mfGetNestedObjectValue,
    },
    [PD_Col_Sched.Scheduled]: {
      column: PD_Col_Sched.Scheduled,
      map: [ActiveTrip$Keys.PicupDetails, PicupDetailsKeys.ScheduledDate],
      format: mfGetNestedObjectValue,
      displayPipeArgs: ['timeAgo'],
    },
    [PD_Col_Sched.ScheduledTimeStamp]: {
      column: PD_Col_Sched.ScheduledTimeStamp,
      map: [ActiveTrip$Keys.PicupDetails, PicupDetailsKeys.ScheduledDate],
      format: mfGetNestedObjectValue,
      displayPipeArgs: ['date', 'dd LLL HH:mm'],
    },
    [PD_Col_Sched.LastEvent]: {
      column: PD_Col_Sched.LastEvent,
      map: [ActiveTrip$Keys.LastEvent],
      format: mfGetLastItemTime,
      displayPipeArgs: ['timeAgo'],
    },
    [PD_Col_Sched.LastEventDetails]: {
      column: PD_Col_Sched.LastEventDetails,
      map: [ActiveTrip$Keys.LastEvent],
      format: mfGetLastItemDetails,
    },
    [PD_Col_Sched.Waypoints]: {
      column: PD_Col_Sched.Waypoints,
      map: ActiveTrip$Keys.TotalWaypoints,
      format: mfWaypointTotal,
    },
  },
};

export const AdminNotesTableDataMap: TableDataMap = {
  cells: {
    driver_id: {
      column: 'driver_id',
      map: 'driver_id',
    },
    order_id: {
      column: 'order_id',
      map: 'order_id',
    },
    bucket_id: {
      column: 'bucket_id',
      map: 'bucket_id',
    },
    public: {
      column: 'public',
      map: 'public',
    },
    timestamp: {
      column: 'timestamp',
      map: 'timestamp',
      format: firebaseNotesDateObject,
      displayPipeArgs: ['date', 'dd LLL HH:mm'],
      highlighted: true,
      highlightColorClass: mfPublicNoteHighlighter,
      highlightColorMap: ['public'],
    },
    type: {
      column: 'type',
      map: 'origin',
      format: mfHumanizeString,
    },
    author: {
      column: 'author',
      map: 'author_name',
      format: mfHumanizeString,
    },
    driver: {
      column: 'driver',
      map: 'driver_name',
      format: mfHumanizeString,
    },
    category: {
      column: 'category',
      map: 'category',
    },
    content: {
      column: 'content',
      map: ['content'],
      format: htmlContent,
    },
  },
};

export const AdminNotesTableColumns: TableColumn[] = [
  { name: 'order_id', displayName: 'Order ID', hidden: true },
  { name: 'driver_id', displayName: 'Driver ID', hidden: true },
  { name: 'bucket_id', displayName: 'Bucket ID', hidden: true },
  { name: 'public', displayName: 'Public', hidden: true },
  { name: 'timestamp', displayName: 'Timestamp', sortable: true },
  { name: 'type', displayName: 'Type', sortable: true },
  { name: 'author', displayName: 'Author', sortable: true },
  { name: 'driver', displayName: 'Driver', sortable: true },
  { name: 'category', displayName: 'Category', sortable: true },
  { name: 'content', displayName: 'Content', sortable: true },
];

export const AdminFinanceColumns: TableColumn[] = [
  { name: Admin_Col_Finance.PicupId, displayName: ' ', hidden: true },
  {
    name: Admin_Col_Finance.WhenFinalized,
    displayName: 'Finalized',
    sortable: true,
  },
  {
    name: Admin_Col_Finance.CustomerReference,
    displayName: 'Customer Reference',
    sortable: true,
  },
  { name: Admin_Col_Finance.Owner, displayName: 'Owner', sortable: true },
  { name: Admin_Col_Finance.DriverName, displayName: 'Driver Name', sortable: true },
  { name: Admin_Col_Finance.DriverPhone, displayName: 'Driver Phone', sortable: true },
  {
    name: Admin_Col_Finance.MerchantCostQuote,
    displayName: 'Merchant Quote',
    sortable: true,
  },
  {
    name: Admin_Col_Finance.MerchantCostActual,
    displayName: 'Merchant Actual',
    sortable: true,
  },
  {
    name: Admin_Col_Finance.DriverEarningsQuote,
    displayName: 'Driver Quote',
    sortable: true,
  },
  {
    name: Admin_Col_Finance.DriverEarningsActual,
    displayName: 'Driver Actual',
    sortable: true,
  },
  { name: Admin_Col_Finance.Vehicle, displayName: 'Vehicle' },
];

export const AdminFinanceDataMap: TableDataMap = {
  cells: {
    [Admin_Col_Finance.PicupId]: {
      column: Admin_Col_Finance.PicupId,
      map: 'picup_id',
    },
    [Admin_Col_Finance.WhenFinalized]: {
      column: Admin_Col_Finance.WhenFinalized,
      map: 'when_finalized',
      displayPipeArgs: ['date', 'dd LLL HH:mm'],
    },
    [Admin_Col_Finance.Owner]: {
      column: Admin_Col_Finance.Owner,
      map: 'owner',
    },
    [Admin_Col_Finance.DriverName]: {
      column: Admin_Col_Finance.DriverName,
      format: mfGetNestedArrayPropertyCommaSeparated,
      map: ['drivers', 'name'],
    },
    [Admin_Col_Finance.DriverPhone]: {
      column: Admin_Col_Finance.DriverPhone,
      format: mfGetNestedArrayPropertyCommaSeparated,
      map: ['drivers', 'phone'],
      displayPipeArgs: ['none'],
    },
    [Admin_Col_Finance.CustomerReference]: {
      column: Admin_Col_Finance.CustomerReference,
      map: 'customer_reference',
    },
    [Admin_Col_Finance.MerchantCostQuote]: {
      column: Admin_Col_Finance.MerchantCostQuote,
      map: 'merchant_cost_quote',
      prefix: environment.currency_symbol + ' ',
      highlighted: true,
      highlightColorClass: mfSetHealthHighlightColor,
      highlightColorMap: ['finance', 'merchant_cost_quote', 'merchant_cost_actual'],
    },
    [Admin_Col_Finance.MerchantCostActual]: {
      column: Admin_Col_Finance.MerchantCostActual,
      map: 'merchant_cost_actual',
      prefix: environment.currency_symbol + ' ',
      highlighted: true,
      highlightColorClass: mfSetAdminHealthHighlightColor,
      highlightColorMap: ['finance', 'merchant_cost_quote', 'merchant_cost_actual'],
    },
    [Admin_Col_Finance.DriverEarningsQuote]: {
      column: Admin_Col_Finance.DriverEarningsQuote,
      map: 'driver_earnings_quote',
      prefix: environment.currency_symbol + ' ',
      highlighted: true,
      highlightColorClass: mfSetHealthHighlightColor,
      highlightColorMap: ['finance', 'driver_earnings_quote', 'driver_earnings_actual'],
    },
    [Admin_Col_Finance.DriverEarningsActual]: {
      column: Admin_Col_Finance.DriverEarningsActual,
      map: 'driver_earnings_actual',
      prefix: environment.currency_symbol + ' ',
      highlighted: true,
      highlightColorClass: mfSetHealthHighlightColor,
      highlightColorMap: ['finance', 'driver_earnings_quote', 'driver_earnings_actual'],
    },
    [Admin_Col_Finance.Vehicle]: {
      column: Admin_Col_Finance.Vehicle,
      map: '',
      iconMap: ['drivers', 'vehicle'],
      iconFormat: mfiVehicleType,
      iconColor: UiColors.Blue,
      iconSizeRem: 2,
    },
  },
};

export const EftPaymentColumns: TableColumn[] = [
  { name: 'payment_id', displayName: 'Payment ID', hidden: true },
  { name: 'when_created', displayName: 'Created', sortable: true },
  { name: 'reference', displayName: 'Reference' },
  { name: 'bought_by', displayName: 'Bought For' },
  { name: 'amount', displayName: 'Amount' },
  { name: 'status', displayName: 'Status' },
];

export const EftPaymentDataMap: TableDataMap = {
  cells: {
    payment_id: { column: 'payment_id', map: '_id' },
    when_created: {
      column: 'when_created',
      map: 'when_created',
      displayPipeArgs: ['date', 'medium'],
    },
    reference: { column: 'reference', map: 'payment_reference' },
    bought_by: {
      column: 'bought_by',
      map: ['domain_details', 'aggregate_name'],
      format: mfGetNestedObjectValue,
    },
    amount: { column: 'amount', map: 'amount', prefix: TablePrefixes.Zar },
    status: { column: 'status', map: ['status'], format: mfFormatEnum },
  },
};

export const AdminUserColumns: TableColumn[] = [
  { name: 'user_id', displayName: 'User ID', hidden: true },
  { name: 'name', displayName: 'User Name', sortable: true },
  { name: 'phone', displayName: 'Phone', sortable: true },
  { name: 'email', displayName: 'Email', sortable: true },
];

export const AdminUserDataMap: TableDataMap = {
  cells: {
    user_id: { column: 'User ID', map: 'user_id' },
    name: { column: 'User Name', map: 'name' },
    phone: { column: 'Phone', map: 'phone', displayPipeArgs: ['none'] },
    email: { column: 'Email', map: 'email' },
  },
};

export const AdminDriverColumns: TableColumn[] = [
  { name: 'driver_id', displayName: 'Driver ID', hidden: true },
  { name: 'driver_name', displayName: 'Driver Name', sortable: true },
  { name: 'phone', displayName: 'Phone', sortable: true },
  { name: 'email', displayName: 'Email', sortable: true },
  { name: 'city', displayName: 'City', sortable: true },
  { name: 'suburb', displayName: 'Suburb', sortable: true },
  { name: 'area', displayName: 'Area', sortable: true },
  { name: 'is_active', displayName: 'Active', sortable: true },
  { name: 'vehicle', displayName: 'Vehicle', sortable: true },
  { name: 'role', displayName: 'Fleet', sortable: true },
  // { name: 'fleet', displayName: 'Fleet', sortable: false },
  { name: 'location_last_updated', displayName: 'Last Heard', sortable: true },
];

export const AdminDriverDataMap: TableDataMap = {
  cells: {
    driver_id: { column: 'Driver ID', map: 'driver_id' },
    driver_name: { column: 'Driver Name', map: 'name' },
    phone: { column: 'Phone', map: 'phone', displayPipeArgs: ['none'] },
    email: { column: 'Email', map: 'email' },
    city: { column: 'City', map: 'city' },
    suburb: { column: 'Suburb', map: 'suburb' },
    area: { column: 'Area', map: 'area' },
    is_active: {
      column: 'Active',
      map: ['is_active'],
      format: mfFormatEnum,
      displayPipeArgs: ['none'],
      highlightColorClass: mfSetAdminHealthHighlightColor,
      highlightColorMap: ['drivers', 'is_active', null],
    },
    role: { column: 'Role', map: ['role'] },
    vehicle: {
      column: 'Vehicle',
      map: '',
      iconMap: ['vehicle'],
      iconFormat: mfiVehicleType,
      iconColor: UiColors.Blue,
      iconSizeRem: 2,
    },
    fleet:{
      column: 'Fleet',
      map: 'fleet',
    },
    location_last_updated: {
      column: 'Last Heard',
      map: 'last_heard',
      displayPipeArgs: ['date', 'mediumDate'],
    },
  },
};

export const AdminDriverPrimaryActions: TableAction[] = [
  {
    event: 'viewDriver',
    title: 'View Driver',
    icon: IconTypes.Eye,
    icon_color: UiColors.Grey8,
  },
];

export const AdminUserPrimaryActions: TableAction[] = [
  {
    event: 'viewUser',
    title: 'View User',
    icon: IconTypes.Eye,
    icon_color: UiColors.Grey8,
  },
];

export const FSAdminActiveOrderTableColumns: TableColumn[] = [
  { name: 'trip_id', displayName: 'Trip ID', hidden: true },
  { name: 'bucket_id', displayName: 'Bucket ID', hidden: true },
  { name: 'customer_reference', displayName: 'Customer Reference', sortable: false },
  { name: 'business_name', displayName: 'Business', sortable: true },
  { name: 'scheduled_date', displayName: 'Scheduled Date', sortable: true },
  { name: 'status', displayName: 'Status', sortable: false },
  { name: 'warehouse_name', displayName: 'Warehouse', sortable: true },
  { name: 'is_round_trip', displayName: 'Trip Type', sortable: false },
  { name: 'driver_names', displayName: 'Drivers', sortable: false },
  { name: 'actual_fleet_allocation', displayName: 'Actual Fleet', sortable: false },
  { name: 'planned_fleet_allocation', displayName: 'Planned Fleet', sortable: false },
  { name: 'last_event', displayName: 'Last Event', sortable: true },
  { name: 'last_updated', displayName: 'Last Updated', sortable: true },
  { name: 'assigned_to', displayName: 'Assigned To', sortable: false },
  { name: 'parcel_count', displayName: 'Parcels', sortable: false },
];

export const FSAdminStagedAssignmentsTableColumns: TableColumn[] = [
  { name: 'trip_id', displayName: 'Trip ID', hidden: true },
  { name: 'bucket_id', displayName: 'Bucket ID', hidden: true },
  { name: 'customer_reference', displayName: 'Customer Reference', sortable: false },
  { name: 'business_name', displayName: 'Business', sortable: false },
  { name: 'scheduled_date', displayName: 'Scheduled Date', sortable: false },
  { name: 'status', displayName: 'Status', sortable: false },
  { name: 'warehouse_name', displayName: 'Warehouse', sortable: false },
  { name: 'is_round_trip', displayName: 'Trip Type', sortable: false },
  { name: 'driver_names', displayName: 'Drivers', sortable: false },
  { name: 'actual_fleet_allocation', displayName: 'Actual Fleet', sortable: false },
  { name: 'planned_fleet_allocation', displayName: 'Planned Fleet', sortable: false },
  { name: 'last_event', displayName: 'Last Event', sortable: false },
  { name: 'last_updated', displayName: 'Last Updated', sortable: false },
  { name: 'assigned_to', displayName: 'Assigned To', sortable: false },
  { name: 'parcel_count', displayName: 'Parcels', sortable: false },
];

export const FSAdminActiveOrderTableDataMap: TableDataMap = {
  cells: {
    trip_id: { column: 'trip_id', map: 'Id' },
    bucket_id: { column: 'bucket_id', map: 'BucketId' },
    is_round_trip: { column: 'is_round_trip', map: 'IsRoundTrip', format: mfRoundTripFormatter },
    customer_reference: { column: 'customer_reference', map: 'CustomerReference' },
    business_name: { column: 'business_name', map: ['BusinessName'] },
    scheduled_date: {
      column: 'scheduled_date',
      map: ['ScheduledDate'],
      format: firestoreDateObject,
      displayPipeArgs: ['date', 'dd LLL HH:mm'],
    },
    status: { column: 'status', map: 'OrderState' },
    warehouse_name: { column: 'warehouse_name', map: 'WarehouseName' },
    driver_names: {
      column: 'driver_names',
      map: ['DriverNames', 'FleetAllocation', 'CourierName'],
      format: mfGetDriverNamesCommaSeparated,
    },
    actual_fleet_allocation: {
      column: 'actual_fleet_allocation',
      map: ['ActualFleetAllocation'],
      format: mfHandleNoneFleet,
    },
    planned_fleet_allocation: {
      column: 'planned_fleet_allocation',
      map: ['PlannedFleetAllocation'],
      format: mfHandleNoneFleetPlanned,
    },

    last_event: {
      column: 'last_event',
      map: ['LastEventName'],
      format: firestorePostDispatchEventNameMapping,
      highlighted: true,
      highlightColorMap: ['LastEventName'],
      highlightColorClass: firestorePostDispatchEventHighlighter,
    },
    last_updated: {
      column: 'last_updated',
      map: ['Timestamp'],
      format: firestoreDateObject,
      displayPipeArgs: ['timeAgo'],
    },
    parcel_count: {
      column: 'parcel_count',
      map: ['ParcelCount']
    },
    assigned_to: {
      column: 'assigned_to',
      map: ['Reservation'],
      format: mfHandleNoneDriverReservation
    },
  },
};

export const FSAdminOrderTableColumns: TableColumn[] = [
  { name: 'trip_id', displayName: 'Trip ID', hidden: true },
  { name: 'bucket_id', displayName: 'Bucket ID', hidden: true },
  { name: 'customer_reference', displayName: 'Customer Reference', sortable: false },
  { name: 'business_name', displayName: 'Business', sortable: true },
  { name: 'scheduled_date', displayName: 'Scheduled Date', sortable: true },
  { name: 'status', displayName: 'Status', sortable: false },
  { name: 'warehouse_name', displayName: 'Warehouse', sortable: true },
  { name: 'is_round_trip', displayName: 'Trip Type', sortable: false },
  { name: 'driver_names', displayName: 'Drivers', sortable: false },
  // { name: 'actual_fleet_allocation', displayName: 'Actual Fleet', sortable: false },
  { name: 'planned_fleet_allocation', displayName: 'Planned Fleet', sortable: false },
  { name: 'last_event', displayName: 'Last Event', sortable: true },
  { name: 'last_updated', displayName: 'Last Updated', sortable: true },
  { name: 'assigned_to', displayName: 'Assigned To', sortable: false },
  { name: 'parcel_count', displayName: 'Parcels', sortable: false },
];

export const FSAdminOrderTableDataMap: TableDataMap = {
  cells: {
    trip_id: { column: 'trip_id', map: 'Id' },
    bucket_id: { column: 'bucket_id', map: 'BucketId' },
    is_round_trip: { column: 'is_round_trip', map: 'IsRoundTrip', format: mfRoundTripFormatter },
    customer_reference: { column: 'customer_reference', map: 'CustomerReference' },
    business_name: { column: 'business_name', map: ['BusinessName'] },
    scheduled_date: {
      column: 'scheduled_date',
      map: ['ScheduledDate'],
      format: firestoreDateObject,
      displayPipeArgs: ['date', 'dd LLL HH:mm'],
    },
    status: { column: 'status', map: 'OrderState' },
    warehouse_name: { column: 'warehouse_name', map: 'WarehouseName' },
    driver_names: {
      column: 'driver_names',
      map: ['DriverNames', 'FleetAllocation', 'CourierName'],
      format: mfGetDriverNamesCommaSeparated,
    },
    actual_fleet_allocation: {
      column: 'actual_fleet_allocation',
      map: ['ActualFleetAllocation'],
      format: mfHandleNoneFleet,
    },
    planned_fleet_allocation: {
      column: 'planned_fleet_allocation',
      map: ['PlannedFleetAllocation'],
      format: mfHandleNoneFleetPlanned,
    },
    assigned_to: {
      column: 'assigned_to',
      map: ['Reservation'],
      format: mfHandleNoneDriverReservation
    },
    last_event: {
      column: 'last_event',
      map: ['LastEventName'],
      format: firestorePostDispatchEventNameMapping,
      highlighted: true,
      highlightColorMap: ['LastEventName'],
      highlightColorClass: firestorePostDispatchEventHighlighter,
    },
    last_updated: {
      column: 'last_updated',
      map: ['Timestamp'],
      format: firestoreDateObject,
      displayPipeArgs: ['timeAgo'],
    },
    parcel_count: {
      column: 'parcel_count',
      map: ['ParcelCount']
    },
  },
};

export const FSAdminAlertOrderTableColumns: TableColumn[] = [
  { name: 'trip_id', displayName: 'Trip ID', hidden: true },
  { name: 'bucket_id', displayName: 'Bucket ID', hidden: true },
  { name: 'customer_reference', displayName: 'Customer Reference', sortable: false },
  { name: 'business_name', displayName: 'Business', sortable: true },
  { name: 'scheduled_date', displayName: 'Scheduled Date', sortable: true },
  { name: 'lottery_count', displayName: 'Total Lottery Attempts', sortable: false },
  { name: 'warehouse_name', displayName: 'Warehouse', sortable: true },
  { name: 'is_round_trip', displayName: 'Trip Type', sortable: false },
  { name: 'driver_names', displayName: 'Drivers', sortable: false },
  // { name: 'actual_fleet_allocation', displayName: 'Actual Fleet', sortable: false },
  { name: 'planned_fleet_allocation', displayName: 'Planned Fleet', sortable: false },
  { name: 'last_event', displayName: 'Last Event', sortable: true },
  { name: 'last_updated', displayName: 'Last Updated', sortable: true },
  { name: 'assigned_to', displayName: 'Assigned To', sortable: false },
  { name: 'parcel_count', displayName: 'Parcels', sortable: false },
];

export const FSAdminAlertOrderTableDataMap: TableDataMap = {
  cells: {
    trip_id: { column: 'trip_id', map: 'Id' },
    bucket_id: { column: 'bucket_id', map: 'BucketId' },
    is_round_trip: { column: 'is_round_trip', map: 'IsRoundTrip', format: mfRoundTripFormatter },
    customer_reference: { column: 'customer_reference', map: 'CustomerReference' },
    business_name: { column: 'business_name', map: ['BusinessName'] },
    scheduled_date: {
      column: 'scheduled_date',
      map: ['ScheduledDate'],
      format: firestoreDateObject,
      displayPipeArgs: ['date', 'dd LLL HH:mm'],
    },
    lottery_count: {
      column: 'lottery_count',
      map: ['LotteryRestartCount', 'TotalLotteryAttempts'],
      format: lotteryRestartCountFormatter,
      highlighted: true,
      highlightColorMap: ['LotteryRestartCount', 'TotalLotteryAttempts'],
      highlightColorClass: lotteryRestartCountHighlighter,
    },
    warehouse_name: { column: 'warehouse_name', map: 'WarehouseName' },
    driver_names: {
      column: 'driver_names',
      map: ['DriverNames', 'FleetAllocation', 'CourierName'],
      format: mfGetDriverNamesCommaSeparated,
    },
    // actual_fleet_allocation: {
    //   column: 'actual_fleet_allocation',
    //   map: ['ActualFleetAllocation'],
    //   format: mfHandleNoneFleetActual,
    // },
    planned_fleet_allocation: {
      column: 'planned_fleet_allocation',
      map: ['PlannedFleetAllocation'],
      format: mfHandleNoneFleetPlanned,
    },
    assigned_to: {
      column: 'assigned_to',
      map: ['Reservation'],
      format: mfHandleNoneDriverReservation
    },
    last_event: {
      column: 'last_event',
      map: ['LastEventName'],
      format: firestorePostDispatchEventNameMapping,
      highlighted: true,
      highlightColorMap: ['LastEventName'],
      highlightColorClass: firestorePostDispatchEventHighlighter,
    },
    last_updated: {
      column: 'last_updated',
      map: ['Timestamp'],
      format: firestoreDateObject,
      displayPipeArgs: ['timeAgo'],
    },
    parcel_count: {
      column: 'parcel_count',
      map: ['ParcelCount']
    },
  },
};

export const FSAdminFailedFinalAlertOrderTableColumns: TableColumn[] = [
  { name: 'trip_id', displayName: 'Trip ID', hidden: true },
  { name: 'bucket_id', displayName: 'Bucket ID', hidden: true },
  { name: 'customer_reference', displayName: 'Customer Reference', sortable: false },
  { name: 'business_name', displayName: 'Business', sortable: true },
  { name: 'scheduled_date', displayName: 'Scheduled Date', sortable: true },
  { name: 'lottery_count', displayName: 'Total Lottery Attempts', sortable: false },
  { name: 'warehouse_name', displayName: 'Warehouse', sortable: true },
  { name: 'is_round_trip', displayName: 'Trip Type', sortable: false },
  { name: 'driver_names', displayName: 'Drivers', sortable: false },
  { name: 'actual_fleet_allocation', displayName: 'Actual Fleet', sortable: false },
  { name: 'planned_fleet_allocation', displayName: 'Planned Fleet', sortable: false },
  { name: 'last_event', displayName: 'Last Event', sortable: true },
  { name: 'last_updated', displayName: 'Last Updated', sortable: true },
  { name: 'assigned_to', displayName: 'Assigned To', sortable: false },
  { name: 'parcel_count', displayName: 'Parcels', sortable: false }
];

export const FSAdminFailedFinalAlertOrderTableDataMap: TableDataMap = {
  cells: {
    trip_id: { column: 'trip_id', map: 'Id' },
    bucket_id: { column: 'bucket_id', map: 'BucketId' },
    is_round_trip: { column: 'is_round_trip', map: 'IsRoundTrip', format: mfRoundTripFormatter },
    customer_reference: { column: 'customer_reference', map: 'CustomerReference' },
    business_name: { column: 'business_name', map: ['BusinessName'] },
    scheduled_date: {
      column: 'scheduled_date',
      map: ['ScheduledDate'],
      format: firestoreDateObject,
      displayPipeArgs: ['date', 'dd LLL HH:mm'],
    },
    lottery_count: {
      column: 'lottery_count',
      map: ['LotteryRestartCount', 'TotalLotteryAttempts'],
      format: lotteryRestartCountFormatter,
      highlighted: true,
      highlightColorMap: ['LotteryRestartCount', 'TotalLotteryAttempts'],
      highlightColorClass: lotteryRestartCountHighlighter,
    },
    warehouse_name: { column: 'warehouse_name', map: 'WarehouseName' },
    driver_names: {
      column: 'driver_names',
      map: ['DriverNames', 'FleetAllocation', 'CourierName'],
      format: mfGetDriverNamesCommaSeparated,
    },
    actual_fleet_allocation: {
      column: 'actual_fleet_allocation',
      map: ['ActualFleetAllocation'],
      format: mfHandleNoneFleet,
    },
    planned_fleet_allocation: {
      column: 'planned_fleet_allocation',
      map: ['PlannedFleetAllocation'],
      format: mfHandleNoneFleetPlanned,
    },
    assigned_to: {
      column: 'assigned_to',
      map: ['Reservation'],
      format: mfHandleNoneDriverReservation
    },
    last_event: {
      column: 'last_event',
      map: ['LastEventName'],
      format: firestorePostDispatchEventNameMapping,
      highlighted: true,
      highlightColorMap: ['LastEventName'],
      highlightColorClass: firestorePostDispatchEventHighlighter,
    },
    last_updated: {
      column: 'last_updated',
      map: ['Timestamp'],
      format: firestoreDateObject,
      displayPipeArgs: ['timeAgo'],
    },
    parcel_count: {
      column: 'parcel_count',
      map: ['ParcelCount']
    },
  },
};

export const FSAdminActiveLastMileTableMap: TableDataMap = {
  cells: {
    last_mile_id: { column: 'last_mile_id', map: 'Id' },
    driver: {
      column: 'driver',
      map: 'DriverName',
    },
    business_names: {
      column: 'business_names',
      map: ['BusinessNames'],
      format: mfGetArrayPropertyCommaSeparated,
    },
    driver_role: { column: 'driver_role', map: 'Role' },

    last_event: {
      column: 'last_event',
      map: ['LastEventName'],
      format: firestorePostDispatchEventNameMapping,
      // highlighted: true,
      // highlightColorMap: ['LastEventName'],
      // highlightColorClass: firestorePostDispatchEventHighlighter,
    },
    last_updated: {
      column: 'last_updated',
      map: ['Timestamp'],
      format: firestoreDateObject,
      displayPipeArgs: ['timeAgo'],
      highlighted: true,
      highlightColorMap: ['Timestamp'],
      highlightColorClass: fireStorePostDispatchimeAgoHighlighter,
    },
    has_failed_collection: { column: 'has_failed_collections', map: 'HasFailedCollection' },
    has_failed_delivery: { column: 'has_failed_delivery', map: 'HasFailedDelivery' },
    // vehicle_id: { column: 'vehicle_id', map: 'vehicle_id', format: mfHumanizeString },

    // parcel_count: {
    //   column: 'parcel_count',
    //   map: ['progress', 'parcel_count'],
    //   format: mfGetNestedPercentageObjectValue,
    // },
    // progress: {
    //   column: 'progress',
    //   map: ['progress', 'percent_complete'],
    //   format: mfGetNestedPercentageObjectValue,
    //   displayPipeArgs: DisplayPipes.decimalsNone,
    //   postfix: '%',
    // },
  },
};

export const FSAdminActiveLastMileTableColumns: TableColumn[] = [
  { name: 'last_mile_id', displayName: 'Last Mile ID', hidden: true },
  { name: 'driver', displayName: 'Driver', sortable: false },
  { name: 'business_names', displayName: 'Business', sortable: false },
  { name: 'driver_role', displayName: 'Driver Role', sortable: false },
  { name: 'last_event', displayName: 'Last Event', sortable: true },
  { name: 'last_updated', displayName: 'Last Updated', sortable: true },
  { name: 'has_failed_collection', displayName: 'Failed Collections', sortable: true },
  { name: 'has_failed_delivery', displayName: 'Failed Deliveries', sortable: true },
];

export const FSAdminActiveCourierOrdersTableMap: TableDataMap = {
  cells: {
    courier_order_id: { column: 'courier_order_id', map: 'Id' },
    business_reference: { column: 'business_reference', map: 'BusinessReference' },
    courier_reference: { column: 'courier_reference', map: 'CourierReference' },
    order_waybill: { column: 'order_waybill', map: 'OrderWaybillNumber' },
    customer_name: { column: 'courier_reference', map: ['Parcels'], format: getDeeplyNestedCustomerName },
    business_name: { column: 'business_name', map: 'BusinessName' },
    courier_name: { column: 'courier_name', map: 'CourierName' },
    courier_code: { column: 'courier_code', map: 'CourierCode' },
    warehouse_name: { column: 'warehouse_name', map: 'WarehouseName' },
    last_event: {
      column: 'last_event',
      map: ['TrackingEventType'],
      format: mfHumanizeString,
      highlighted: true,
      highlightColorMap: ['TrackingEventType'],
      highlightColorClass: firestoreCourierPostDispatchEventHighlighter,
    },
    last_updated: {
      column: 'last_updated',
      map: ['Timestamp'],
      format: firestoreDateObject,
      displayPipeArgs: ['timeAgo'],
    },
    // driver: {
    //   column: 'driver',
    //   map: 'driver',
    // },
    // last_event: {
    //   column: 'last_event',
    //   map: ['last_mile', 'event_title'],
    //   format: mfGetNestedObjectValue,
    //   highlighted: true,
    //   highlightColorMap: ['last_mile', 'event_health'],
    //   highlightColorClass: mfNestedLastEventHighlighter,
    // },
    // last_updated: {
    //   column: 'last_updated',
    //   map: ['last_mile', 'event_timestamp'],
    //   format: mfGetNestedObjectValue,
    //   displayPipeArgs: ['timeAgo'],
    // },
    // vehicle_id: { column: 'vehicle_id', map: 'vehicle_id', format: mfHumanizeString },

    // parcel_count: {
    //   column: 'parcel_count',
    //   map: ['progress', 'parcel_count'],
    //   format: mfGetNestedPercentageObjectValue,
    // },
    // progress: {
    //   column: 'progress',
    //   map: ['progress', 'percent_complete'],
    //   format: mfGetNestedPercentageObjectValue,
    //   displayPipeArgs: DisplayPipes.decimalsNone,
    //   postfix: '%',
    // },
  },
};

export const FSAdminActiveCourierOrdersTableColumns: TableColumn[] = [
  { name: 'courier_order_id', displayName: 'Courier Order ID', hidden: true },
  { name: 'business_reference', displayName: 'BusinessReference', hidden: false, sortable: false },
  { name: 'order_waybill', displayName: 'Order Waybill', hidden: false, sortable: false },
  { name: 'customer_name', displayName: 'Customer Name', hidden: false, sortable: false },
  { name: 'business_name', displayName: 'Business', hidden: false, sortable: false },
  { name: 'courier_name', displayName: 'Courier Name', hidden: false, sortable: true },
  { name: 'courier_code', displayName: 'Courier Code', hidden: false, sortable: false },
  { name: 'warehouse_name', displayName: 'Warehouse Name', hidden: false, sortable: true },
  { name: 'last_event', displayName: 'Last Event', sortable: true },
  { name: 'last_updated', displayName: 'Last Updated', sortable: true },
];
