<app-section class="overall" *ngIf="WarehouseVisit">
  <app-flex-container
    [theme]="UiThemes.Dark"
    [padding]="'2rem'"
    [borderedItems]="true"
    [justifyContent]="justifyContent.SpaceBetween"
    [borderBottom]="true"
  >
    <app-icon-with-text [text]="'WAREHOUSE ROUTE'" [textBold]="true" [sizeRem]="1" [color]="UiColors.White">
    </app-icon-with-text>
    <app-icon-with-text
      [icon]="IconTypes.Trip"
      [iconColor]="UiColors.Blue"
      [iconSizeRem]="1"
      [iconSpacingRem]="1"
      [text]="
        WarehouseVisit.EnRouteAt
          ? 'Started: ' + (WarehouseVisit.EnRouteAt.toMillis() | date: 'medium')
          : ('Assigned' | uppercase)
      "
      [sizeRem]="1"
      [color]="UiColors.White"
    ></app-icon-with-text>
    <app-icon-with-text
      [icon]="IconTypes.Trip"
      [iconColor]="UiColors.Blue"
      [iconSizeRem]="1"
      [iconSpacingRem]="1"
      [text]="
        WarehouseVisit.FinalizedAt
          ? 'Ended: ' + (WarehouseVisit.FinalizedAt.toMillis() | date: 'medium')
          : ('In Progress' | uppercase)
      "
      [sizeRem]="1"
      [color]="UiColors.White"
    ></app-icon-with-text>
    <div class="controls">
      <app-basic-button
        *ngIf="!WarehouseVisit.FinalizedAt && (user.is_admin || fleetAllocation === 'Contractor')"
        [processing]="reconciling"
        [title]="'Reconcile Route'"
        [type]="buttonTypes.Primary"
        [active]="false"
        (click)="reconcileRoute()"
      >
      </app-basic-button>
      <app-basic-button
        *ngIf="!WarehouseVisit.FinalizedAt && user.is_admin"
        [processing]="endingRoute"
        [title]="'Optimise Route'"
        [type]="buttonTypes.Warning"
        [active]="false"
        (click)="endRoute()"
      >
      </app-basic-button>
    </div>
  </app-flex-container>

  <div class="summary" *ngIf="!showMap">
    <app-grid-container
      [columns]="'repeat(4, 1fr)'"
      [padding]="'0rem'"
      [colGap]="'2rem'"
      [rowGap]="'0rem'"
      [theme]="UiThemes.HighlightedSummary"
    >
      <app-stats-icon
        [icon]="IconTypes.Parcel"
        [dataSizeRem]="1.5"
        [iconColor]="UiColors.Blue"
        [iconSizeRem]="2"
        [label]="'Packages'"
        [loading]="false"
        [data]="
          collectionsOverviewTableData.length + returnsOverviewTableData.length + collectedReturnsTableData.length
        "
        [textColour]="UiColors.White"
      >
      </app-stats-icon>

      <app-stats-icon
        [icon]="IconTypes.Parcel"
        [dataSizeRem]="1.5"
        [iconColor]="UiColors.Green"
        [iconSizeRem]="2"
        [label]="'Collections'"
        [loading]="false"
        [data]="collectionsOverviewTableData.length"
        [textColour]="UiColors.White"
      >
      </app-stats-icon>

      <app-stats-icon
        [icon]="IconTypes.Parcel"
        [dataSizeRem]="1.5"
        [iconColor]="UiColors.Red"
        [iconSizeRem]="2"
        [label]="'Returns'"
        [loading]="false"
        [data]="returnsOverviewTableData.length"
        [textColour]="UiColors.White"
      >
      </app-stats-icon>

      <app-stats-icon
        [icon]="IconTypes.Parcel"
        [dataSizeRem]="1.5"
        [iconColor]="UiColors.Yellow"
        [iconSizeRem]="2"
        [label]="'Collected Returns'"
        [loading]="false"
        [data]="collectedReturnsTableData.length"
        [textColour]="UiColors.White"
      >
      </app-stats-icon>
    </app-grid-container>
  </div>

  <div class="route-WarehouseVisit" *ngIf="WarehouseVisit && !showMap">
    <heading
      [icon]="IconTypes.Warehouse"
      [showToggle]="true"
      [color]="WarehouseRouteStatus.color"
      (click)="collapsible.toggle()"
    >
      <span>
        <span>{{ WarehouseVisit.WarehouseName | uppercase }}</span
        ><br />
        <span class="warehouse-contact">{{ WarehouseVisit.WarehouseAddress | uppercase }} </span></span
      >
      <!-- <h4 *ngIf="waypoint_reconciled" class="admin-action">Reconciled</h4> -->
      <app-flex-container>
        <app-icon-timestamp-status
          [icon]="WarehouseRouteStatus.icon"
          [iconColor]="WarehouseRouteStatus.color"
          [iconSizeRem]="0.7"
          [text]="WarehouseRouteStatus.label"
          [start]="WarehouseVisit.VisitingAt?.toMillis()"
          [end]="WarehouseVisit.FinalizedAt?.toMillis()"
          [timestamp]="
            WarehouseVisit.VisitState === 'Finalized'
              ? WarehouseVisit.FinalizedAt?.toMillis()
              : WarehouseVisit.VisitingAt
              ? WarehouseVisit.VisitingAt?.toMillis()
              : undefined
          "
          [distance]="WarehouseRouteStatus.distance"
          [sizeRem]="0.83"
          [failures]="WarehouseVisit.failed_count"
        >
        </app-icon-timestamp-status>
      </app-flex-container>
    </heading>
    <div appCollapsible [expanded]="true" #collapsible="appCollapsible">
      <div [ngStyle]="{ 'padding-bottom.rem': 2 }">
        <div class="customer">
          <div class="customer-info">
            <app-flex-container
              [theme]="UiThemes.Dark"
              [borderBottom]="true"
              [borderTop]="true"
              [justifyContent]="justifyContent.SpaceBetween"
              [padding]="'2rem'"
            >
              <div class="customer-details">
                <div class="customer-detail">
                  <app-icon-with-text
                    [icon]="IconTypes.Warehouse"
                    [iconColor]="UiColors.Grey5"
                    [iconSizeRem]="1"
                    [iconSpacingRem]="2"
                    [text]="WarehouseVisit?.Building ? WarehouseVisit.Building : '-'"
                    [sizeRem]="1"
                    [color]="UiColors.White"
                  >
                  </app-icon-with-text>
                </div>
                <div class="customer-detail">
                  <app-icon-with-text
                    [icon]="IconTypes.Phone"
                    [iconColor]="UiColors.Grey5"
                    [iconSizeRem]="1"
                    [iconSpacingRem]="2"
                    [text]="WarehouseVisit?.WarehousePhone ? WarehouseVisit.WarehousePhone : '-'"
                    [sizeRem]="1"
                    [color]="UiColors.White"
                  >
                  </app-icon-with-text>
                </div>
              </div>
              <div class="image-wrap" *ngIf="WarehouseVisit.collection_signature || WarehouseVisit.return_signature">
                <div>
                  <div class="customer-detail">
                    <app-icon-with-text
                      [icon]="IconTypes.Profile"
                      [iconColor]="UiColors.Blue"
                      [iconSizeRem]="1"
                      [iconSpacingRem]="1"
                      [text]="
                        WarehouseVisit.collection_signature
                          ? WarehouseVisit.collection_signature.signee
                          : WarehouseVisit.return_signature.signee
                      "
                      [sizeRem]="1"
                      [color]="UiColors.White"
                    >
                    </app-icon-with-text>
                  </div>

                  <app-image-with-caption
                    [widthRem]="16.67"
                    [heightRem]="6.67"
                    [src]="
                      WarehouseVisit.collection_signature
                        ? WarehouseVisit.collection_signature.url
                        : WarehouseVisit.return_signature.url
                    "
                    [download]="true"
                    [showCaption]="false"
                  >
                  </app-image-with-caption>
                </div>
              </div>
            </app-flex-container>

            <div *ngIf="collectionsOverviewTableData?.length" class="breakdown-table">
              <heading [compact]="true">
                <h4>Warehouse Collections</h4>
              </heading>
              <app-table
                [columns]="parcelOverviewColumns"
                [tableData]="collectionsOverviewTableData"
                [rowsSelectable]="false"
              >
              </app-table>
            </div>

            <div *ngIf="returnsOverviewTableData?.length" class="breakdown-table">
              <heading [compact]="true">
                <h4>Failed Delivery Returns</h4>
              </heading>
              <app-table
                [columns]="parcelOverviewColumns"
                [tableData]="returnsOverviewTableData"
                [rowsSelectable]="false"
              >
              </app-table>
            </div>

            <div *ngIf="collectedReturnsTableData?.length" class="breakdown-table">
              <heading [compact]="true">
                <h4>Collected Waypoint Returns</h4>
              </heading>
              <app-table
                [columns]="parcelOverviewColumns"
                [tableData]="collectedReturnsTableData"
                [rowsSelectable]="false"
              >
              </app-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-section>
