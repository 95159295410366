import { AddressBookEntry, Order, WebhookResponse } from './shared.interfaces';
import { Injectable } from '@angular/core';
import axios, { azureAxios } from 'app/api/axios';
import { BehaviorSubject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectorActingAs } from 'app/auth/auth.reducer';
import { AuthService } from 'app/auth/auth.service';
import { SimpleModalService } from 'ngx-simple-modal';
import { NotificationsService } from './notifications/notifications.service';
import firebase from 'firebase/app';
import 'firebase/storage';
import { ParcelTimelineItem } from './parcel-timeline/parcel-timeline.interface';
import { ActingAs } from 'app/interfaces/auth.interfaces';
import { AngularFirestore, QuerySnapshot } from '@angular/fire/firestore';
@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private WAREHOUSE_BASE_URL = '/warehouse/';
  private WAREHOUSE_DETAILS_ENDPOINT = '/details';

  warehouseDetails$ = new BehaviorSubject(undefined);
  actingAs: ActingAs;

  constructor(
    private store: Store<any>,
    public authService: AuthService,
    public simpleModalService: SimpleModalService,
    private notificationsService: NotificationsService,
    private firestore: AngularFirestore
  ) {
    this.store.select(selectorActingAs).subscribe((next) => {
      this.actingAs = next;
    });
  }

  getInvoices(start_date, end_date) {
    return axios({
      method: 'POST',
      baseURL: 'https://picup-prod-billing.azurewebsites.net',
      url: '/v1/generic/public/api_get_xero_invoices',
      data: {
        StartDate: start_date,
        EndDate: end_date,
        BusinessId: this.actingAs.id,
      },
    });
  }

  getWarehouseDetails(warehouse_id) {
    axios({
      method: 'GET',
      url: this.WAREHOUSE_BASE_URL + warehouse_id + this.WAREHOUSE_DETAILS_ENDPOINT,
    }).then((response) => {
      this.warehouseDetails$.next(response?.data.warehouse_details);
    });
  }

  downloadOrderPOD(orderWaybill) {
    return axios({
      method: 'GET',
      url: '/pdf/report/pod/' + orderWaybill,
      responseType: 'blob',
    })
      .then((response) => {
        const data = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = data;
        link.setAttribute('download', 'POD-' + orderWaybill + '.pdf');
        document.body.appendChild(link);
        link.click();
        return response;
      })
      .catch((error) => this.notificationsService.publish({ type: 'error', message: error.response?.data.message }));
  }


  searchDriverByName(data) {
    return axios({
      method: 'POST',
      url: '/trip-management/query-driver-by-name/',
      data: data,
    })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw error;
      });
  }

  getAddresses(addressRequestData: {
    business_id: string | null;
    user_id: string | null;
    warehouse_id?: string | null;
    address_book_id?: string | null;
  }): Promise<AddressBookEntry[]> {
    return axios({
      method: 'POST',
      url: '/address-book/get-address-book',
      data: addressRequestData,
    })
      .then((response) => response.data)
      .catch((error) => {
        this.notificationsService.publish({
          type: 'error',
          message: error.response?.data.message,
        });
      });
  }

  setAddress(address_book_entry: AddressBookEntry): Promise<any> {
    return axios({
      method: 'POST',
      url: '/address-book/set-address',
      data: address_book_entry,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  deleteAddress(addressDeleteData: {
    business_id: string | null;
    user_id: string | null;
    address_book_id: number;
    warehouse_id?: string | null;
  }): Promise<any> {
    return axios({
      method: 'POST',
      url: '/address-book/delete-address',
      data: addressDeleteData,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  updateDriverDetails(driver_model) {
    return axios({
      method: 'POST',
      url: 'driver/' + driver_model.id + '/update-driver-details',
      data: driver_model,
    })
      .then((response) => {
        this.notificationsService.publish({
          type: 'success',
          message: 'Driver Updated',
        });
        return response?.data;
      })
      .catch((error) =>
        this.notificationsService.publish({
          type: 'error',
          message: error.response?.data.message,
        })
      );
  }

  listImages(driver_id) {
    const storage = firebase.storage();
    const pathReference = storage.ref('drivers/' + driver_id);
    return pathReference.listAll().then((response) => {
      const items = [];
      response.items.forEach((image) => {
        items.push(image.fullPath.split('/')[2].split('.')[0]);
      });
      return items;
    });
  }

  getImage(driver_id: string, image_name: string, fileExtension: string): Promise<any> {
    const storage = firebase.storage();
    const pathReference = storage.ref(`drivers/${driver_id}/${image_name}.${fileExtension}`);
    return pathReference
      .getDownloadURL()
      .then((url) => {
        if (image_name === 'profile_pic') {
          this.setFirebaseProfileURL(driver_id, url);
          return url;
        } else {
          return url;
        }
      })
      .catch(() => {
        return null;
      });
  }

  setFirebaseProfileURL(driver_id, url) {
    return axios({
      method: 'POST',
      url: 'driver/' + driver_id + '/set-profile-picture',
      data: {
        profile_picture_url: url,
        thumbnail_url: url,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  uploadImage(driver_id: string, file_name: string, image: File): Promise<any> {
    const jpgRef = firebase
      .storage()
      .ref(`drivers/${driver_id}/${file_name}.${'jpg'}`);
    const pdfRef = firebase
      .storage()
      .ref(`drivers/${driver_id}/${file_name}.${'pdf'}`);
    jpgRef.delete().catch((err) => {
      return err;
    });
    pdfRef.delete().catch((err) => {
      return err;
    });
    if (image.name.substring(image.name.lastIndexOf('.') + 1).toLowerCase() === 'jpg') {
      return jpgRef.put(image).then(() => {
        return;
      });
    } else {
      return pdfRef.put(image).then(() => {
        return;
      });
    }
  }

  clearImage(driver_id: string, file_name: string): Promise<any> {
    const jpgRef = firebase
      .storage()
      .ref(`drivers/${driver_id}/${file_name}.${'jpg'}`);
    const pdfRef = firebase
      .storage()
      .ref(`drivers/${driver_id}/${file_name}.${'pdf'}`);
    let result: Promise<any>;
    result = jpgRef.delete().catch((err) => {
      return err;
    });
    result = pdfRef.delete().catch((err) => {
      return err;
    });
    return result;
  }

  toggleDriverOnline(driver_id, context) {
    return axios({
      method: 'POST',
      url: '/driver/' + context,
      data: {
        metadata: {
          driver_id: driver_id,
          timestamp: new Date(),
          location: {
            latitude: 0,
            longitude: 0,
          },
        },
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  async getOrderTrackingEvents(order_id: string): Promise<WebhookResponse<ParcelTimelineItem[]>> {
    // Pass in the columns you want returned into the $select clause
    const azureClient = azureAxios(new Date().toUTCString());
    try {
      const response = await azureClient.get(`Webhooks()?$filter=PartitionKey%20eq%20'${order_id}'%20and%20IsPoisonQueue%20eq%20false&$select=ParcelWaybillNumber,Timestamp,EventDescription,EventArg1,EventArg2`);
      return response.data;
    } catch (error) {
      this.notificationsService.publish({
        type: 'error',
        message: error.response?.data.message,
      });
      throw error;
    }
  }

  getOrderFromFirestoreForSummary(orderId: string): Observable<QuerySnapshot<Order>>{
    return this.firestore.collection<Order>('orders', (ref) => {
      return ref
        .where('Id', '==', orderId)
    }).get();
  }
}
