import { Directive } from '@angular/core';

@Directive({
  selector: '[appMapDrawingHelper]'
})
export class MapDrawingHelperDirective {
  constructor() { }

  static createMapMarker(map: google.maps.Map, coord: google.maps.LatLng): google.maps.Marker {
    const marker = new google.maps.Marker({
      position: coord,
      map: map
    });


    return marker;
  }

  static removeMapMarker(marker: google.maps.Marker): void {
    if (marker) {
      marker.setMap(null);
    }
  }

}
