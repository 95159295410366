<div class="second-grid">
    <div class="content-grid">
      <div class="second-content">
        <h2>{{ Title }}</h2>
        <img src="/assets/img/packages/underline.svg" alt="" />
        <p>
            {{ subtitle }}
        </p>
      </div>
      <div class="arrow">
        <img src="/assets/img/arrow.svg" alt="" />
      </div>
    </div>
    <div class="second-image">
       <img src="{{ Imgsrc }}" alt=""/>
    </div>
  </div>
