import { Component, OnInit } from '@angular/core';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { MapTypes } from 'app/shared/map/map.interfaces';
import axios from 'app/api/axios';
import { AuthService } from 'app/auth/auth.service';
import { Store } from '@ngrx/store';
import { SimpleModalService } from 'ngx-simple-modal';
import { ZoneEditorComponent } from 'app/shared/zone-editor/zone-editor.component';
import { NotificationsService } from 'app/shared/notifications/notifications.service';
import { ActingAs } from 'app/interfaces/auth.interfaces';
import { selectorActingAs } from 'app/auth/auth.reducer';
import { darkMapStyle } from 'app/shared/map/map.constants';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';

@Component({
  selector: 'app-zones',
  templateUrl: './zones.component.html',
  styleUrls: ['./zones.component.scss'],
})
export class ZonesComponent implements OnInit {
  actingAs: ActingAs;
  firstLoad = true;
  zoneDetails;
  mapElement;

  public Icons = {
    Colors: UiColors,
    Types: IconTypes,
  };
  public MapTypes = MapTypes;
  public UiColors = UiColors;
  public IconTypes = IconTypes;
  buttonTypes = ButtonTypes;
  showMap = false;

  all_zones;

  gMapOptions: google.maps.MapOptions = {
    center: {lat: -33.9765176, lng: 18.4654239},
    zoomControl: false,
    clickableIcons: false,
    mapTypeControl: false,
    maxZoom: 18,
    scrollwheel: true,
    styles: darkMapStyle,
    streetViewControl: true,
  };

  constructor(
    public store: Store<any>,
    private authService: AuthService,
    public simpleModalService: SimpleModalService,
    public notificationsService: NotificationsService
  ) {
    this.store.select(selectorActingAs).subscribe((next) => {
      this.actingAs = next;
      this.getBusinessZones();
    });
  }

  ngOnInit() {}

  selectZone(zone) {
    this.zoneDetails = zone;
    let zone_index;
    const swapPositions = (array, a, b) => {
      [array[a], array[b]] = [array[b], array[a]];
    };
    this.all_zones.spatial_data.forEach(function (zoneObject, index) {
      if (zone.name === zoneObject.name) {
        zone_index = index;
      }
    });
    swapPositions(this.all_zones.spatial_data, 0, zone_index);
    this.firstLoad = false;
    this.mapElement.fitBounds(this.extendMapBounds(zone.geojson));
  }

  mapElementReady(map) {
    this.mapElement = map;
  }

  extendMapBounds(geoArray) {
    const bounds = new google.maps.LatLngBounds();
    geoArray.features.forEach(function (feature) {
      if (feature.geometry.type === 'Point') {
        bounds.extend(new google.maps.LatLng(feature.geometry.coordinates[1], feature.geometry.coordinates[0]));
      }
      if (feature.geometry.type === 'Polygon') {
        feature.geometry.coordinates.forEach((coordinate) => {
          coordinate.forEach((element) => {
            bounds.extend(new google.maps.LatLng(element[1], element[0]));
          });
        });
      }
      if (feature.geometry.type === 'MultiPolygon') {
        feature.geometry.coordinates.forEach((coordinate) => {
          coordinate.forEach((element) => {
            element.forEach((deepest) => {
              bounds.extend(new google.maps.LatLng(deepest[1], deepest[0]));
            });
          });
        });
      }
    });
    return bounds;
  }

  getBusinessZones() {
    if (this.actingAs) {
      const data = {
        business_id: this.actingAs.id,
        type: 'zones',
      };
      axios.post('/spatial/spatial-geography', data).then((response) => {
        this.all_zones = response?.data;
      });
    }
  }
  closeDetails() {
    this.zoneDetails = null;
  }

  editZone(zone) {
    const data = {
      zone: zone,
      business_id: this.actingAs.id,
    };
    this.simpleModalService.addModal(ZoneEditorComponent, data).subscribe((result) => {
      if (!result) {
        return;
      } else {
        this.notificationsService.publish({
          type: 'success',
          message: 'Zone Updated',
        });
        this.closeDetails();
        this.getBusinessZones();
      }
    });
  }

  editZoneDrivers(zone) {
    // dont know what this is for but removed console.log
    // code added so it can build
    const data = {
      zone: zone
    };
    //  console.log(zone);
  }

  // Styles:
  multiStyle() {
    return {
      fillOpacity: 0.3,
      strokeOpacity: 1,
      fillColor: UiColors.Orange,
      strokeColor: UiColors.Orange,
    };
  }

  highlightStyle() {
    return {
      fillOpacity: 0.4,
      strokeOpacity: 1,
      fillColor: '#32b3ff',
      strokeColor: '#32b3ff',
    };
  }
}
