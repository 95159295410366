import { Component, Input, HostListener, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { IconTypes } from '../icon/icon.interfaces';
import { UiColors } from '../../interfaces/ui.interfaces';
import { Store } from '@ngrx/store';
import { selectorActingAs, selectorUser, selectorUserPermissions } from 'app/auth/auth.reducer';
import { environment } from 'environments/environment';
import { PlanningService } from 'app/planning/planning.service';
import { ActingAs, AuthUser } from 'app/interfaces/auth.interfaces';
import { AccessManagementService } from 'app/auth/access-management.service';
import { AccessManagementModule } from 'app/auth/access-management.interface';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
  actingAs: ActingAs;
  url: string;
  @Input() active: boolean;
  user: AuthUser;
  icons = {
    types: IconTypes,
    colors: UiColors,
  };
  showEditConsignment: boolean;
  isNavOpen = false;
  user_permissions;
  showPlanning: boolean = false;

  editConsignmentBusinesses = [
    'business-d8695050-fe97-4c7a-a5d0-fe59a7f531ac',
    'business-01f12694-44f5-4068-b0b6-6b721ed0e306',
    'business-d2e7041a-8b02-4ff8-a888-7a6ff824c07c',
    'business-94bd2b8b-870c-4fd2-be9b-911325239b44',
    'business-6f1fd375-0659-4aea-b933-86e8276f464d',
    'business-4a120d71-3e8f-4d8c-b4ae-9f8747e5333b',
    'business-177a3104-5aea-4594-b772-5a4f1d8492db',
  ];

  availableModules: AccessManagementModule[] = [];

  constructor(
    public store: Store<any>,
    private authService: AuthService,
    public router: Router,
    private element: ElementRef,
    private planningService: PlanningService,
    private accessManagementService: AccessManagementService,
  ) {
    this.store.select(selectorActingAs).subscribe((next) => {
      this.actingAs = next;
      this.showEditConsignment = this.editConsignmentBusinesses.includes(this.actingAs.id);
    });
    this.planningService.planningBusinesses$.subscribe((data) => {
      if (data?.length > 0) {
        this.showPlanning = true;
      } else {
        this.showPlanning = false;
      }
    });
    this.store.select(selectorUser).subscribe((next) => {
      this.user = next;
    });
    this.store.select(selectorUserPermissions).subscribe((next) => (this.user_permissions = next));
    this.accessManagementService.availableModules$.subscribe((modules) => {
      if (modules) {
        this.availableModules = modules;
      }
    });
  }

  onLogout(): void {
    this.authService.logout();
  }

  selectLink(): void {
    this.isNavOpen = false;
  }

  toggleNav(): void {
    this.isNavOpen = !this.isNavOpen;
  }

  handleNav(route: string[]): void {
    this.router.navigate(route);
  }

  showNav(module, mode = 'view'): boolean {
    if (this.actingAs) {
      if (this.user.is_admin && this.actingAs.package !== 'individual' && mode === 'view') {
        switch (module) {
          case 'account':
            return this.actingAs.package === 'individual';
          case 'planning':
            return this.showPlanning && !environment.production;
          case 'operations-tracking':
            return true;
          case 'advanced-search':
            return true;
          default:
            return true;
        }
      } else {
        switch (module) {
          case 'place-order':
            if (environment.appVersion === 2) {
              return this.actingAs.package !== 'individual' && environment.envName !== 'onthedot' && this.actingAs.package.toLowerCase() !== 'basic';
            } else {
              return this.actingAs.package.toLowerCase() !== 'basic';
            }
          case 'post-dispatch':
            return true;
          case 'operations-tracking':
            return this.user.is_admin;
          case 'account':
            return this.actingAs.package === 'individual';
          case 'buckets':
            return this.actingAs.package !== 'basic' && this.actingAs.package !== 'individual';
          case 'freight':
            return this.actingAs.package === 'freight-shipper' || this.actingAs.package === 'freight';
          case 'warehouse':
            return (
              (this.actingAs.package === 'tms' || this.actingAs.package === 'freight') &&
              environment.envName !== 'onthedot'
            );
          case 'manage-fleet':
            return this.user_permissions?.modules?.includes('user')
              ? false
              : this.actingAs.package === 'tms' || this.actingAs.package === 'freight'
                ? true
                : false;
          case 'geocoding':
            return this.actingAs.package === 'tms' || this.actingAs.package === 'freight' ? true : false;
          case 'manage-business':
            return this.user_permissions?.modules?.includes('user') || this.actingAs.package === 'individual'
              ? false
              : true;
          case 'advanced-search':
            return true;
          case 'financials':
            // should probably find a better way to do this
            return !!this.availableModules.find((x) => x.ModuleName === 'Financials');
          case 'reporting':
            return (
              (this.actingAs.package === 'tms' ||
                this.actingAs.package === 'freight' ||
                this.actingAs.package === 'enterprise') &&
              environment.envName !== 'onthedot'
            );
          case 'billing':
            return false;
          case 'settings':
            return this.user_permissions?.modules?.includes('user') ? false : true;
          case 'planning':
            return this.showPlanning && this.user.is_admin;
        }
      }
    }
  }

  @HostListener('document:click', ['$event'])
  closeNav(event): void {
    if (this.isNavOpen && !this.element.nativeElement.contains(event.target)) {
      this.isNavOpen = false;
    }
  }
}
