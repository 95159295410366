<div class="container" (click)="handleClick()">
  <app-flex-container 
    [flexDirection]="'column'" 
    [justifyContent]="justifyContent.SpaceEvenly">
    <app-icon 
      class="icon" 
      [icon]="icon" 
      [color]="iconColor" 
      [size]="iconSizeRem">
    </app-icon>

    <div class="text" 
      [ngStyle]="{ 'font-size.rem': fontSizeRem, fontWeight: textBold ? '800' : '400' }">
      {{ text }}
    </div>
  </app-flex-container>
</div>
