import { Component, OnInit, ViewChild } from '@angular/core';
import { UploadOrdersService } from '../upload-orders.service';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { DatePickerModalComponent } from '../../../shared/modals/date-picker-modal.component';
import { SimpleModalService } from 'ngx-simple-modal';
import { Store } from '@ngrx/store';
import { Consignment, Shipment } from './consignment.interface';
import * as moment from 'moment';
import { completeAssign } from '../../../shared/helpers';
import { ActingAs, Warehouse } from 'app/interfaces/auth.interfaces';
import { selectorActingAs, selectorWarehouses } from 'app/auth/auth.reducer';
import { PhoneValidationPipe } from 'app/shared/pipes/phone-validation.pipe';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { UiColors } from 'app/interfaces/ui.interfaces';

@Component({
  selector: 'app-create-consignment',
  templateUrl: './create-consignment.component.html',
  styleUrls: ['./create-consignment.component.scss'],
})
export class CreateConsignmentComponent implements OnInit {
  @ViewChild('file') file;
  zoneData = [];
  orderFound = false;
  invoiceData = [];
  lookUpValue;
  processing: boolean;
  chosenWarehouseName: string;
  warehouseOptions = [];
  override = false;
  parcelTypes = ['parcel-a4-envelope', 'parcel-small', 'parcel-medium', 'parcel-large', 'parcel-xlarge'];
  consignment: Consignment = {
    delivery_date: null,
    shift_start: null,
    shift_end: null,
    warehouse_id: null,
    warehouse_name: null,
  };
  shipment;
  shipmentData: Shipment = {
    invoice_number: null,
    consignment: null,
    address: null,
    contact: {
      customer_name: null,
      customer_phone: null,
    },
    parcels: {
      'parcel-a4-envelope': 0,
      'parcel-small': 0,
      'parcel-medium': 0,
      'parcel-large': 0,
      'parcel-xlarge': 0,
    },
  };

  iconTypes = IconTypes;
  buttonTypes = ButtonTypes;
  uiColours = UiColors;

  actingAs: ActingAs;
  warehouses: Warehouse[];

  constructor(
    public simpleModalService: SimpleModalService,
    public uploadOrdersService: UploadOrdersService,
    public store: Store<any>,
    public notificationService: NotificationsService,
    private phoneValidationPipe: PhoneValidationPipe
  ) {
    this.store.select(selectorActingAs).subscribe((next) => {
      this.actingAs = next;
    });
    this.store.select(selectorWarehouses).subscribe((next) => (this.warehouses = next));
    this.mapWarehouses();
    this.resetForm();
  }

  resetForm() {
    this.lookUpValue = null;
    this.shipment = completeAssign({}, this.shipmentData);
    this.shipmentData.contact.customer_name = null;
    this.shipmentData.contact.customer_phone = null;
    this.override = false;
  }

  checkValue(value) {
    if (value === '' || value === undefined || value === null) {
      return true;
    } else {
      return false;
    }
  }

  checkParcels() {
    const parcels: any = this.shipment.parcels;
    let boolean = false;
    if (Object.keys(parcels).every((k) => parcels[k] === 0)) {
      boolean = true;
    }
    return boolean;
  }

  overrideTrue() {
    this.override = true;
    const address = [
      this.shipment.address_line1,
      this.shipment.address_line2,
      this.shipment.address_line3,
      this.shipment.address_line4,
    ];
    this.shipment['address'] = address
      .filter(function (val) {
        return val;
      })
      .join(', ');
  }
  overrideFalse() {
    this.override = false;
    this.shipment['address'] = null;
  }

  validate() {
    if (this.checkValue(this.shipment.invoice_number)) {
      this.alertError('Please add an Invoice Number for this consignment');
    } else if (this.checkValue(this.shipment.contact.customer_name)) {
      this.alertError('Please add an Customer Name for this consignment');
    } else if (!this.phoneValidationPipe.transform(this.shipment.contact.customer_phone)) {
      this.alertError('Please add a valid Phone number for this consignment');
    } else if (this.checkParcels()) {
      this.alertError('Please add at lease 1 parcel to this consignment.');
    } else {
      this.sendInvoiceData();
    }
  }

  alertError(message) {
    this.notificationService.publish({
      type: 'error',
      message: message,
    });
  }

  mapWarehouses() {
    this.warehouses.forEach((warehouse) => {
      this.warehouseOptions.push({
        name: warehouse.name,
        value: warehouse.id,
      });
    });
  }

  onPlacesChange() {
    // sconsole.log($event);
  }

  setInitialValues() {
    this.lookUpValue = null;
    this.consignment.delivery_date = null;
    this.chosenWarehouseName = this.warehouses[0].name;
    this.consignment.warehouse_id = this.warehouses[0].id;
    this.consignment.warehouse_name = this.warehouses[0].name;
    this.orderFound = false;
  }

  uploadConsignmentData() {
    this.file.nativeElement.click();
  }

  addParcel(parcelType) {
    this.shipment.parcels[parcelType]++;
  }

  removeParcel(parcelType) {
    if (this.shipment.parcels[parcelType] > 0) {
      this.shipment.parcels[parcelType]--;
    }
  }

  sendInvoiceData() {
    this.processing = true;
    const data = {
      consignment: this.consignment,
      shipment: this.shipment,
    };
    this.uploadOrdersService
      .setSingleConsignment(data)
      .then((response) => {
        this.processing = false;
        const blob = new Blob([response?.data], { type: 'application/pdf' });
        const blobURL = URL.createObjectURL(blob);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = blobURL;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
        this.resetForm();
      })
      .catch(() => {
        this.processing = false;
      });
  }

  removeOrderFromConsignment() {
    this.uploadOrdersService.removeOrderFromConsignment(this.shipment.order_id).then(() => this.setInitialValues());
  }

  openDatePickerModal() {
    this.simpleModalService.addModal(DatePickerModalComponent).subscribe((date: any) => {
      if (!date) {
        return;
      }
      this.consignment.delivery_date = moment(date).format('YYYY-MM-DD');
    });
  }

  ngOnInit() {
    this.setInitialValues();
  }
}
