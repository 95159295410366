import * as CryptoJS from 'crypto-js';

class SharedKeyGenerator {
  storageAccountName;
  storageAccountKey;
  tableName;
  constructor(storageAccount: string, tableName: string, storageKey: string) {
    this.storageAccountName = storageAccount;
    this.storageAccountKey = storageKey;
    this.tableName = tableName;
  }
  GenerateSharedKeyLite(utcDateString: string): string {
    // Table name plus () is to allow for filtering after the resource response
    const stringToSign = utcDateString + '\n' + this._getCanonicalizedResource(this.tableName + '()');
    const hash = CryptoJS.HmacSHA256(
      CryptoJS.enc.Utf8.parse(stringToSign),
      CryptoJS.enc.Base64.parse(this.storageAccountKey)
    );
    const signature = CryptoJS.enc.Base64.stringify(hash);
    return 'SharedKeyLite ' + this.storageAccountName + ':' + signature;
  }

  _getCanonicalizedResource(path: string): string {
    return `/${this.storageAccountName}/${path}`;
  }
}

export default SharedKeyGenerator;
