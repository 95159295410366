export enum ReceivedStagedOrdersModalActionsEnum {
  Close = 'close',
  ReceiveOrders = 'receiveOrders',
}

export enum AvailableBucketsPrimaryActionsEnum {
  SelectBucket = 'selectBucket',
}

export enum ReceivedStagedOrdersResultEnum {
  NewBucket = 'newBucket',
}
