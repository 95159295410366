import { Component, OnInit, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ModalSizes, ModalAction } from 'app/shared/modals/modal.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';

import * as noUiSlider from 'nouislider';
import { JustifyContent } from 'app/shared/flex-container/flex-container.interfaces';

@Component({
  selector: 'app-consign-radius',
  templateUrl: './consign-radius.component.html',
  styleUrls: ['./consign-radius.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConsignRadiusComponent extends SimpleModalComponent<any, any> implements OnInit {
  @ViewChild('rangeSlider', { static: true }) slider: ElementRef;
  public ModalSizes = ModalSizes;
  public iconTypes = IconTypes;
  public uiColors = UiColors;
  public uiThemes = UiThemes;
  public buttonTypes = ButtonTypes;
  public actions: ModalAction[] = [
    { name: 'cancel', title: 'Cancel' },
    { name: 'confirm', title: 'Confirm', isPrimary: true },
  ];
  public justifyContent = JustifyContent;

  selected_radius;
  consignment_name;
  no_routing = false;
  editRadius: boolean = false;

  constructor() {
    super();
  }

  ngOnInit(): void {
    noUiSlider.create(this.slider.nativeElement, {
      start: [30000],
      range: {
        min: 0,
        max: 500000,
      },
      step: 2000,
    });

    this.slider.nativeElement.noUiSlider.on('update', (values) => {
      this.selected_radius = Math.ceil(values);
    });
  }

  handleAction($event) {
    switch ($event) {
      case 'cancel':
        this.result = undefined;
        this.close();
        break;
      case 'confirm':
        this.result = {
          selected_radius: this.selected_radius,
          consignment_name: this.consignment_name?.trim(),
          no_routing: this.no_routing,
        };
        this.close();
    }
  }
  toggle() {
    this.no_routing = !this.no_routing;
  }

  setRadius(eventTarget){
    this.selected_radius = eventTarget.value * 1000;
  }
}
