export interface Timeline {
  EventArg1: string;
  EventArg2: string;
  EventArg3: string;
  EventDescription: string;
  ParcelWaybillNumber: string;
  Timestamp: string;
}


export enum TimelinePosition{
  first = 1,
  other = 2,
  last = 3
}

export enum ShipmentStateEnum {
  New = 'New',
  FulfillmentConfirmed = ' Fulfillment Confirmed',
  DriverAssigned = 'Driver Assigned',
  OutForCollection = 'Out For Collection',
  AtCollectionWaypoint = 'At Collection Waypoint ',
  CourierCollected = 'CourierCollected',
  CourierCollectionFailed = 'Courier Collection Failed',
  AtCourierCenter = 'At Courier Center',
  ParcelVolumised = 'Parcel Volumised',
  InTransit = 'In Transit',
  OutForDelivery = 'Out For Delivery',
  EnRouteToWaypoint = 'En Route To Waypoint',
  AtDeliveryWaypoint = 'At Delivery Waypoint',
  DeliverySuccessful = 'Delivery Successful',
  DeliveryFailed = 'Delivery Failed',
  Cancelled = 'Cancelled',
  Returned = 'Returned',
  Error = 'Error',
  GetVisitsFailed = 'GetVisitsFailed',
  PreAllocationCodeSet = 'PreAllocationCodeSet',
  DispatchingTripFailed = 'DispatchingTripFailed',
  Delivered = 'Delivered',
  Collected = 'Collected',
  FailedDelivery = 'Failed Delivery',
  ExecutionFailed = 'Execution Failed',
  FailedCollection = 'Failed Collection',
  FailedReturn = 'Failed Return',
  Assigned = 'Assigned',
  PendingRecollection = 'Pending Recollection',
  PendingRedelivery = 'Pending Redelivery',
  PendingAllocation = 'Pending Allocation',
  FinalCollectionAttemptFailed = 'Final Collection Attempt Failed',
  PendingReturn = 'Pending Return',
  None = 'None',
}
