<heading [icon]="Icons.Types.Courier" [color]="Icons.Colors.Blue">
  <h4 class="heading-title">Costing Settings</h4>
</heading>
<div class="costing-form" *ngIf="process_bucket_options">
  <h3>Trip Settings</h3>
  <div class="costing-inputs trip-settings">
      <div class="input">
        <div class="form-group">
          <label>3RD PARTY ONLY</label>
          <app-basic-button
            class="button-pl"
            [title]="is_courier_only ? 'Yes' : 'No'"
            [type]="buttonTypes.Primary"
            (click)="is_courier_only = !is_courier_only"
            [disabled]="readOnly"
          >
          </app-basic-button>
        </div>
      </div>
      <!-- <div class="button-div-placement; input">
        <div class="form-group">
          <label class="button-pl">Start/End Task</label>
          <app-basic-button 
            class="control-button; button-pl" 
            [title]="'Configure'" 
            [type]="buttonTypes.Primary" 
            (click)="openStartEndTaskModal()"
            [disabled]="readOnly">
          </app-basic-button>
        </div>
      </div> -->
      <div *ngIf="!is_courier_only" class="input">
        <div class="form-group">
          <label>TRIP TYPE</label>
          <app-basic-button
            [title]="process_bucket_options.is_round_trip ? 'Round Trip' : 'One Way'"
            [type]="buttonTypes.Primary"
            (click)="process_bucket_options.is_round_trip = !process_bucket_options.is_round_trip"
            [disabled]="readOnly"
          >
          </app-basic-button>
        </div>
      </div>
      <div *ngIf="!is_courier_only" class="input">
        <div class="form-group">
          <label>AUTO START PICUP LOTTERY</label>
          <app-basic-button
            class="button-pl"
            [title]="process_bucket_options.is_schedule_lottery ? 'Yes' : 'No'"
            [type]="buttonTypes.Primary"
            (click)="process_bucket_options.is_schedule_lottery = !process_bucket_options.is_schedule_lottery"
            [disabled]="readOnly"
          >
          </app-basic-button>
        </div>
      </div>
      <div *ngIf="!useNewContractorSettings" class="vehicle-block">
        <label *ngIf="multipleVehicles">RECOMMENDED VEHICLES</label>
        <label *ngIf="!multipleVehicles">RECOMMENDED VEHICLE</label>

        <ng-template ngFor let-vehicle [ngForOf]="vehicles">
          <svg-icon
            [svgStyle]="{ 'width.rem': '4' }"
            *ngIf="process_bucket_options.fleet[vehicle].count > 0"
            [applyClass]="true"
            [src]="'/assets/img/vehicles/' + vehicle + '-blue.svg'"
            class="vehicle-icon"
          ></svg-icon>
        </ng-template>
      </div>
  </div>
  <h5 *ngIf="!useNewContractorSettings" 
    class="toggle" 
    (click)="collapsibleAdvancedSettings.toggle(); 
    toggleSettings()">
    Override Defaults
    <svg-icon
      [applyClass]="true"
      [svgStyle]="{ 'width.rem':'0.7' }"
      [src]="!settingsOpen ? '/assets/img/icons/down-black.svg' : '/assets/img/icons/up.svg'"
      class="heading-icon"
    >
    </svg-icon>
  </h5>
  <div appCollapsible [expanded]="false" #collapsibleAdvancedSettings="appCollapsible" class="advanced-options">
    <app-grid-container [padding]="'1rem 0'" [columns]="'repeat(4, 1fr)'" [colGap]="'2rem'" [rowGap]="'1rem'"
      [theme]="UiThemes.Light">
      <ng-template ngFor let-vehicle [ngForOf]="vehicles">
        <div class="vehicle-block">
          <svg-icon 
            (click)="selectVehicle(vehicle)" 
            [applyClass]="true" 
            [src]="
              selected_vehicles.includes(vehicle)
                ? '/assets/img/vehicles/' + vehicle + '-blue.svg'
                : '/assets/img/vehicles/' + vehicle + '.svg'
            " 
            class="vehicle-icon"
            [disabled]="readOnly">
          </svg-icon>
        </div>
      </ng-template>
    </app-grid-container>
  </div>

  <app-contractor-settings *ngIf="useNewContractorSettings" [bucket_contractors]="bucket_contractors"
    (contractorsChangedEvent)="contractorsChanged($event)">
  </app-contractor-settings>

  <div class="tms-settings" *ngIf="fleet_management && !is_courier_only">
    <h3>{{useNewContractorSettings ? 'Picup Fleet Settings' : 'Fleet Settings'}}</h3>
    <app-grid-container [padding]="'1rem 0'" [columns]="'repeat(4, 1fr)'" [colGap]="'2rem'" [rowGap]="'1rem'"
      [theme]="UiThemes.Light">
      <ng-template ngFor let-vehicle [ngForOf]="vehicles">
        <div class="vehicle-block">
          <svg-icon [src]="
              process_bucket_options.fleet[vehicle]?.count > 0
                ? '/assets/img/vehicles/' + vehicle + '-blue.svg'
                : '/assets/img/vehicles/' + vehicle + '.svg'
            " class="vehicle-icon"></svg-icon>
          <input-field [type]="'number'" [label]="'Capacity'"
            (valueChanged)="process_bucket_options.fleet[vehicle].capacity = $event"
            [value]="process_bucket_options.fleet[vehicle]?.capacity"></input-field>
          <input-field [type]="'number'" [label]="'Count'"
            (valueChanged)="process_bucket_options.fleet[vehicle].count = $event"
            [value]="process_bucket_options.fleet[vehicle]?.count"></input-field>
        </div>
      </ng-template>
    </app-grid-container>
  </div>

  <div class="proceed">
    <app-basic-button
      class="proceed"
      [title]="'Process'"
      [type]="buttonTypes.Highlight"
      [active]="true"
      [processing]="inProcess"
      (click)="onProcessBucket()"
      [disabled]="readOnly"
    >
    </app-basic-button>
  </div>
</div>