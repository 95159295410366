import { Component, Input, OnInit } from '@angular/core';
import { LastMileService } from '../last-mile.service';
import {
  TableDataMap,
  DisplayPipes,
  TableColumn,
  TableAction,
  TableActionTaken,
  TableCell,
  TableDataMapFormatter,
} from 'app/shared/table/table.interfaces';
import { environment } from 'environments/environment';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { AdminService } from 'app/admin/admin.service';
import { ConfirmModalComponent } from 'app/shared/modals/confirm-modal.component';
import { TextInputModalComponent } from 'app/shared/modals/text-input-modal/text-input-modal.component';
import { SimpleModalService } from 'ngx-simple-modal';
import {
  mfHumanizeString,
  mfGetNestedArrayPropertyCommaSeparated,
} from 'app/shared/table/map-formatters.table.helpers';
import { Router } from '@angular/router';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';

export const mfGetFinanceModelName: TableDataMapFormatter = (map: [string], item: any) => {
  switch (item[map[0]]) {
    case '0':
      return ' Parcs Pricing';
    case '1':
      return 'Fixed Price per Parcel';
    case '2':
      return 'Fixed Price per Km';
    case '3':
      return 'Fixed Price per Radius';
    case '4':
      return 'V2 Parcs Pricing';
  }
};

@Component({
  selector: 'app-last-mile-financials',
  templateUrl: './last-mile-financials.component.html',
  styleUrls: ['./last-mile-financials.component.scss'],
})
export class LastMileFinancialsComponent implements OnInit {
  @Input() driverId: string;

  public IconTypes = IconTypes;
  public UiColors = UiColors;
  buttonTypes = ButtonTypes;

  driverEarningsDataMap: TableDataMap = {
    cells: {
      route_id: { column: 'route_id', map: 'route_id' },
      driver_ledger_id: { column: 'driver_ledger_id', map: 'driver_ledger_id' },
      driver_name: {
        column: 'driver_name',
        map: 'driver_name',
      },
      driver_earnings: {
        column: 'driver_earnings',
        map: 'driver_earnings',
        prefix: environment.currency_symbol,
        displayPipeArgs: DisplayPipes.decimalsTwo,
      },
      driver_id: {
        column: 'driver_id',
        map: 'driver_id',
      },
      price_excl_vat: {
        column: 'price_excl_vat',
        map: 'price_excl_vat',
        prefix: environment.currency_symbol,
        displayPipeArgs: DisplayPipes.decimalsTwo,
      },
      earnings_model: { column: 'earnings_model', map: ['earnings_model'], format: mfGetFinanceModelName },
      pricing_model: { column: 'pricing_model', map: ['pricing_model'], format: mfGetFinanceModelName },
      route_type: { column: 'route_type', map: 'route_type', format: mfHumanizeString },
      successful_collections: {
        column: 'successful_collections',
        map: 'successful_collections',
      },
      successful_deliveries: { column: 'successful_deliveries', map: 'successful_deliveries' },
      failed_collections: { column: 'failed_collections', map: 'failed_collections' },
      failed_deliveries: { column: 'failed_deliveries', map: 'failed_deliveries' },
      returns: { column: 'returns', map: 'returns' },
      owner_name: { column: 'owner_name', map: ['owner', 'name'], format: mfGetNestedArrayPropertyCommaSeparated },
    },
  };

  driverEarningsTableColumns: TableColumn[] = [
    { name: 'route_id', displayName: 'Route ID', hidden: true },
    { name: 'driver_ledger_id', displayName: 'Driver Ledger ID', hidden: true },
    { name: 'driver_name', displayName: 'Driver', sortable: true },
    { name: 'owner_name', displayName: 'Owner', sortable: true },
    { name: 'driver_earnings', displayName: 'Earnings', sortable: true },
    { name: 'earnings_model', displayName: 'Earning Model', sortable: true },
    { name: 'driver_id', displayName: 'Driver Id', hidden: true },
    { name: 'price_excl_vat', displayName: 'Price Ex Vat', sortable: true },
    { name: 'pricing_model', displayName: 'Pricing Model', sortable: true },
    { name: 'route_type', displayName: 'Route Type', sortable: true },
    { name: 'successful_collections', displayName: 'Successful Collections', sortable: true },
    { name: 'successful_deliveries', displayName: 'Successful Deliveries', sortable: true },
    { name: 'failed_collections', displayName: 'Failed Collections', sortable: true },
    { name: 'failed_deliveries', displayName: 'Failed Deliveries', sortable: true },
    { name: 'returns', displayName: 'Returns', sortable: true },
  ];

  public AdminFinancePrimaryActions: TableAction[] = [
    { event: 'viewRoute', title: 'View Route', icon: IconTypes.Eye, icon_color: UiColors.Grey8 },
    { event: 'approve', title: 'Approve', icon: IconTypes.Check, icon_color: UiColors.Grey8 },
    { event: 'edit', title: 'Edit', icon: IconTypes.Edit, icon_color: UiColors.Grey8 },
  ];

  public AdminFinanceBulkActions = [
    { event: 'approve', title: 'Approve', icon: IconTypes.Check, icon_color: UiColors.Green },
  ];

  driverEarnings;

  constructor(
    private lastMileService: LastMileService,
    private adminService: AdminService,
    private simpleModalService: SimpleModalService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getTableData();
  }

  getTableData() {
    this.lastMileService.getDriverEarningsForRoutes(this.driverId, null).then((response) => {
      this.driverEarnings = response;
    });
  }

  handleDriverEarningsAction($event: TableActionTaken): void {
    const approvalObj = [];
    $event.rows.forEach((element) => {
      approvalObj.push({
        route_id: element.cells.find((cell: TableCell) => cell.column === 'route_id').value,
        amount: element.cells.find((cell: TableCell) => cell.column === 'driver_earnings').value,
        driver_ledger_id: element.cells.find((cell: TableCell) => cell.column === 'driver_ledger_id').value,
      });
    });
    switch ($event.action.event) {
      case 'viewRoute':
        this.router.navigate(['/dashboard/routes/', approvalObj[0].route_id]);
        break;
      case 'approve':
        this.simpleModalService
          .addModal(ConfirmModalComponent, {
            title: 'Are you sure?',
            message: 'This will approve the selected driver earnings, and cannot be undone',
            description: null,
          })
          .subscribe((isConfirmed) => {
            if (!isConfirmed) {
              return;
            }
            this.approveDriverLedger(approvalObj);
          });

        break;
      case 'edit':
        this.simpleModalService
          .addModal(TextInputModalComponent, {
            title: 'Edit And Approve Ledger',
            content: ['Total Amount', 'Edit Reason'],
            iconColor: UiColors.Orange,
            iconType: IconTypes.Alert,
          })
          .subscribe((result) => {
            if (!result) {
              return;
            }
            approvalObj[0].amount = parseFloat(result[0]);
            approvalObj[0].reason = result[1];
            this.approveDriverLedger(approvalObj);
          });
        break;
    }
  }

  approveDriverLedger(approval_array): void {
    this.adminService.approveDriverLedger(approval_array).then(() => this.getTableData());
  }
}
