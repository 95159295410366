<div class="modal">
  <div class="modal-content">
    <heading [icon]="Icons.Types.Business" [color]="Icons.Colors.Blue">
      <h4 class="heading-title">{{'Change Package'}}</h4>

    </heading>

    <div class="modal-body">
      <div class="form-wrap">
        <div class="row">

          <app-grid-container [columns]="'repeat(2, 1fr)'" [theme]="UiThemes.Light" [rowGap]="'2rem'" [colGap]="'2rem'">
            <app-dropdown class="dropdown" [label]="'Package Type'"
              [activeItem]="billing_package ? billing_package : 'Select a package'" [dropdownTheme]="'light'">
              <div dropdownRef>
                <ng-template ngFor let-package [ngForOf]="packages">
                  <a class="dropdown-item" (click)="billing_package = package">{{package}}</a>
                </ng-template>
              </div>
            </app-dropdown>

            <app-dropdown class="dropdown" [label]="'Payment Terms'"
              [activeItem]="payment_terms ? payment_terms : 'Payment Terms'" [dropdownTheme]="'light'">
              <div dropdownRef>
                <ng-template ngFor let-term [ngForOf]="terms">
                  <a class="dropdown-item" (click)="payment_terms = term">{{term}}</a>
                </ng-template>
              </div>
            </app-dropdown>


          </app-grid-container>
          <app-grid-container [columns]="'repeat(1, 1fr)'" [theme]="UiThemes.Light" [rowGap]="'2rem'" [colGap]="'2rem'">
            <input-field class="package-label" [type]="'password'" (valueChanged)="password = $event" [value]="password" [label]="'Password'">
            </input-field>
            <div *ngIf="displayError" class="error">
              {{'Incorrect Password'}}
          </div>
          </app-grid-container>
          
        </div>
      </div>
      <div class="modal-footer">
        <app-basic-button [title]="'Cancel'" [type]="buttonTypes.Tertiary" (click)="cancel();"></app-basic-button>
        <app-basic-button [disabled]="!payment_terms || !billing_package" [title]="'Submit'" [type]="buttonTypes.Secondary" (click)="onChangePackage()"></app-basic-button>
      </div>
    </div>
  </div>
</div>