import { Component, Input, OnInit } from '@angular/core';
import { UiThemes } from 'app/interfaces/ui.interfaces';
import { SimpleModalService } from 'ngx-simple-modal';
import { JustifyContent } from '../flex-container/flex-container.interfaces';
import { IconTypes } from '../icon/icon.interfaces';
import { RequestDemoModalComponent } from '../request-demo-modal/request-demo-modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-static-cta',
  templateUrl: './static-cta.component.html',
  styleUrls: ['./static-cta.component.scss']
})
export class StaticCtaComponent {
  UiThemes = UiThemes;
  IconTypes = IconTypes;
  justifyContent = JustifyContent

  @Input() text: string;
  @Input() hideDemoCTA = false;
  @Input() title: string;

  requestDemo(): void {
    window.open('https://docs.google.com/forms/d/e/1FAIpQLSdBzSrPXiFjoSWEqJhymOwQ1FA7KGLqfdQ_n9iEWZtToK1m2Q/viewform?usp=sf_link')
  }

}
