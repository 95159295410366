
<!-- TODO: add [activeWarehouseFilter]="activeWarehouseFilter" -->
<app-collapsible-order-table
*ngIf="pendingRoutes | async as pendingRoutes"
[tripType]="'Pending Drivers'"
[trips]="pendingRoutes"
[tripsTotal]="pendingRoutesTotal"
[expanded]="true"
[objectType]="CollapsibleTableTypeEnum.LastMile"
[icon]="Icons.Types.Trip"
[color]="Icons.Colors.Blue"
[tableDataMap]="fsActiveLastMileTableMap"
[tableColumns]="fsActiveLastMileTableColumns"
[primaryActions]="activeDriverPrimaryActions"
[defaultDisplayAmount]="pendingRoutesAmount"
(paginateEvent)="handlePendingLastMiles($event)"
(tableSortChanged)="handleLastMileSortChange($event)"
>
</app-collapsible-order-table>
