import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectorActingAs, selectorUser, selectorUserPermissions, selectorWarehouses } from 'app/auth/auth.reducer';
import { CourierOrdersService } from 'app/dashboard/courier-orders/courier-orders.service';
import { ReconcileCourierOrderModalComponent } from 'app/dashboard/courier-orders/reconcile-courier-order-modal/reconcile-courier-order-modal.component';
import {
  SqlTripAlertTableMap,
  SqlTripTableColumns,
  SqlTripTableMap,
} from 'app/dashboard/new-trips/new-trips.constants';
import { ActingAs, AuthUser, UserRoles, Warehouse } from 'app/interfaces/auth.interfaces';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { JustifyContent } from 'app/shared/flex-container/flex-container.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import {
  CollapsibleTableTypeEnum,
  CourierCode,
  CourierOrder,
  LastMile,
  Order,
  Paginate,
  PaginateEnum,
  PD_Courier_Order_Table_Actions,
  PostDispatchTableEnum,
  PostDispatchViewEnum,
} from 'app/shared/shared.interfaces';
import { SortEvent, TableAction } from 'app/shared/table/table.interfaces';
import moment from 'moment';
import { SimpleModalService } from 'ngx-simple-modal';
import { BehaviorSubject, from, Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { setPostDispatchFilters } from './post-dispatch.actions';
import { PostDispatchState } from './post-dispatch.reducer';
import { PostDispatchService } from './post-dispatch.service';
import { selectorPostDispatchFilters } from './post-dispatch.reducer';
import {
  FSactiveCourierOrdersTableMap,
  FSactiveLastMileTableColumns,
  FSactiveLastMileTableMap,
  FSActiveOrderTableColumns,
  FSActiveOrderTableDataMap,
  FSAlertOrderTableColumns,
  FSAlertOrderTableDataMap,
  FSAllFailedFinalTableColumns,
  FSAllFailedFinalTableDataMap,
  FScourierOrdersTableColumns,
  FSOrderTableColumns,
  FSOrderTableDataMap,
} from './post-dispatch.constants';
import { OrderFrontEndStateEnum } from './post-dispatch.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { AccessManagementService } from 'app/auth/access-management.service';
import { AccessLevelName, AccessManagementModules } from 'app/auth/access-management.interface';
import { PostDispatchDispatchComponent } from './modules/dispatch/post-dispatch-dispatch/post-dispatch-dispatch.component';
import { PostDispatchTrackingComponent } from './modules/tracking/post-dispatch-tracking/post-dispatch-tracking.component';
import { DocumentChangeAction } from '@angular/fire/firestore';
import { ScheduledDropDownEnum } from 'app/admin/admin-post-dispatch/admin-post-dispatch/admin-post-dispatch.interface';

interface PostDispatchPayload {
  mode: string;
  warehouse: Warehouse;
  fleet_allocation: string;
  limit: number;
  business_id?: string;
}

@Component({
  selector: 'app-post-dispatch',
  templateUrl: './post-dispatch.component.html',
  styleUrls: ['./post-dispatch.component.scss'],
})
export class PostDispatchComponent implements OnInit, OnDestroy {
  @Input() bucket_mode;
  searchString: string;
  activeWarehouseFilter = new BehaviorSubject(null);
  warehouseFilterId = new BehaviorSubject(null);
  fleetFilter = new BehaviorSubject(null);
  modeFilter = new BehaviorSubject('active');
  ngDestroyed$ = new Subject();
  actingAs: ActingAs;
  warehouses: Warehouse[];
  todaySummaryData;
  available_warehouses;
  available_warehouse_ids = [];
  postDispatchSummaryData;
  user_permissions;
  justifyContent = JustifyContent;
  buttonTypes = ButtonTypes;
  routeView = true;
  orderView = false;
  pendingView = false;
  courierView = false;
  Icons = {
    Colors: UiColors,
    Types: IconTypes,
  };
  showCompleted = false;
  refreshLoading = false;
  PostDispatchTableEnum = PostDispatchTableEnum;

  customDateObj = {
    title: null,
    start_date: null,
    end_date: null,
  };

  postDispatchFilters: PostDispatchState;
  CollapsibleTableTypeEnum = CollapsibleTableTypeEnum;

  public sqlTripTableColumns = SqlTripTableColumns;
  public sqlTripTableMap = SqlTripTableMap;
  public sqlTripAlertTableMap = SqlTripAlertTableMap;

  fsOrderTableColumns = FSOrderTableColumns;
  fsOrderTableDataMap = FSOrderTableDataMap;

  fsActiveOrderColumns = FSActiveOrderTableColumns;
  fsActiveOrderTableDataMap = FSActiveOrderTableDataMap;

  fsAlertOrderTableColumns = FSAlertOrderTableColumns;
  fsAlertOrderTableDataMap = FSAlertOrderTableDataMap;

  fsActiveLastMileTableMap = FSactiveLastMileTableMap;
  fsActiveLastMileTableColumns = FSactiveLastMileTableColumns;

  fsActiveCourierOrdersTableMap = FSactiveCourierOrdersTableMap;
  fsActiveCourierOrdersTableColumns = FScourierOrdersTableColumns;

  activeDriverPrimaryActions: TableAction[] = [];
  postDispatchTablePrimaryActions: TableAction[] = [];
  courierOrderBulkActions: TableAction[] = [];
  user: AuthUser;

  inProgressFirestoreTrips: Observable<Order[]>;
  inProgressAmount: number = 25;
  inProgressSubscription: Subscription;
  inProgressTotal: number;

  scheduledFirestoreTrips: Observable<Order[]>;
  scheduledAmount: number = 25;
  scheduledSubscription: Subscription;
  scheduledTotal: number;

  alertFirestoreTrips: Observable<Order[]>;
  alertAmount: number = 25;
  alertSubscription: Subscription;
  alertTotal: number;

  manualAssignmentFirstoreTrips: Observable<Order[]>;
  manualAssignmentAmount: number = 25;
  manualAssignmentSubscription: Subscription;
  manualAssignmentTotal: number;

  lotteryRunningFirestoreTrips: Observable<Order[]>;
  lotteryRunningAmount: number = 25;
  lotteryRunningSubscription: Subscription;
  lotteryRunningTotal: number;

  completedFirestoreTrips: Observable<Order[]>;
  completedAmount: number = 25;
  completedSubscription: Subscription;
  completedTotal: number;

  OrderFrontEndStateEnum = OrderFrontEndStateEnum;

  activeRoutes: Observable<LastMile[]>;
  activeRoutesAmount: number = 25;
  activeRoutesSubscription: Subscription;
  activeRoutesTotal: number;

  pendingRoutes: Observable<LastMile[]>;
  pendingRoutesAmount: number = 25;
  pendingRoutesSubscription: Subscription;
  pendingRoutesTotal: number;

  courierOrders: Observable<CourierOrder[]>;
  courierOrdersAmount: number = 25;
  courierOrdersSubscription: Subscription;
  courierOrderTotal: number;

  failedFinalAlertsColumns = FSAllFailedFinalTableColumns;
  failedFinalAlertsDataMap = FSAllFailedFinalTableDataMap;

  failedFinalAlertFirestoreTrips: Observable<Order[]>;
  failedFinalAlertAmount: number = 10;
  failedFinalAlertSubscription: Subscription;
  failedFinalAlertTotal: number;

  PostDispatchViewEnum = PostDispatchViewEnum;

  courierCodes: CourierCode[];
  courierFilter: CourierCode;

  accessLevelName: string;

  accessLevelNameEnum = AccessLevelName

  stagedAssignmentsFirestoreTrips: Observable<Order[]>;
  stagedAssignmentAmount: number = 10;
  stagedAssignmentSubscription: Subscription;
  stagedAssignmentTotal: number;

  scheduledDropDownItems = Object.values(ScheduledDropDownEnum);

  showBuckets$: Observable<string>;

  @ViewChild('tracking') tracking: PostDispatchTrackingComponent;
  @ViewChild('dispatch') dispatch: PostDispatchDispatchComponent;

  constructor(
    private store: Store<any>,
    private simpleModalService: SimpleModalService,
    private postDispatchService: PostDispatchService,
    private courierOrdersService: CourierOrdersService,
    private accessManagementService: AccessManagementService
  ) {
    this.store
      .select(selectorActingAs)
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe((next) => {
        this.actingAs = next;
        this.postDispatchService.actingAs = this.actingAs;
      });

    this.store.select(selectorUser).subscribe((next) => {
      this.user = next;
      this.populateTableActions();
    });

    this.store.select(selectorPostDispatchFilters).subscribe((filters: PostDispatchPayload) => {
      this.activeWarehouseFilter.next(filters.warehouse);
      this.warehouseFilterId.next(filters.warehouse?.id);
      this.fleetFilter.next(filters.fleet_allocation);
      this.modeFilter.next(filters.mode);
      this.fetchData();
    });

    this.accessManagementService.availableModules$.subscribe(x => {
      this.accessLevelName = x.find(y => y?.ModuleName.toLowerCase() === AccessManagementModules.dashboard.toLowerCase())?.AccessLevelName.toLowerCase() ?? AccessLevelName.legacy;
      switch(this.accessLevelName){
        case AccessLevelName.dispatcher: {
          this.toggleMode(PostDispatchViewEnum.Dispatch);
          break;
        }
        case AccessLevelName.tracker: {
          this.toggleMode(PostDispatchViewEnum.Tracking);
          break;
        }
        case AccessLevelName.legacy: {
          break;
        }
      }
    })

    this.store.select(selectorUserPermissions).subscribe((next) => (this.user_permissions = next));
    this.store.select(selectorWarehouses).subscribe((next) => (this.warehouses = next));
    this.showBuckets$ = this.postDispatchService.showBuckets$;
  }

  ngOnInit(): void {
    this.handleSubscriptions();
    this.handlePostDispatchTotals();
    this.getCourierCodes(this.actingAs.id);
    if (this.user_permissions?.warehouses?.length && !this.user_permissions?.modules?.includes('super-admin')) {
      this.available_warehouses = this.warehouses.filter((warehouse: Warehouse) =>
        this.user_permissions.warehouses.includes(warehouse.id)
      );
      this.available_warehouses.forEach((warehouse) => {
        this.available_warehouse_ids = [];
        this.available_warehouse_ids.push(warehouse.id);
      });
    } else {
      this.available_warehouses = this.warehouses;
    }

    this.customDateObj.title = 'lastSeven';
    this.customDateObj.start_date = moment().subtract(7, 'days').format();
    this.customDateObj.end_date = moment().format();
  }

  ngOnDestroy(): void {
    this.alertSubscription?.unsubscribe();
    this.inProgressSubscription?.unsubscribe();
    this.scheduledSubscription?.unsubscribe();
    this.manualAssignmentSubscription?.unsubscribe();
    this.lotteryRunningSubscription?.unsubscribe();
    this.completedSubscription?.unsubscribe();
    this.courierOrdersSubscription?.unsubscribe();
    this.activeRoutesSubscription?.unsubscribe();
    this.pendingRoutesSubscription?.unsubscribe();
    this.failedFinalAlertSubscription?.unsubscribe();
    this.stagedAssignmentSubscription?.unsubscribe();
  }

  getCourierCodes(business_id: string): void {
    this.courierOrdersService.getCourierCodes(business_id).then((response) => {
      if (response) {
        this.courierCodes = response;
      }
    });
  }

  refreshData(): void {
    this.handlePostDispatchTotals();
    this.handleSubscriptions();
  }

  handlePostDispatchTotals(): void {
    this.postDispatchService.getPostDispatchTotals(false).then((data) => {
      this.alertTotal = data.output.find((x) => x.State === OrderFrontEndStateEnum.Alert)?.Cnt;
      this.scheduledTotal = data.output.find((x) => x.State === OrderFrontEndStateEnum.Scheduled)?.Cnt;
      this.inProgressTotal = data.output.find((x) => x.State === OrderFrontEndStateEnum.InProgress)?.Cnt;
      this.completedTotal = data.output.find((x) => x.State === OrderFrontEndStateEnum.Completed)?.Cnt;
      this.lotteryRunningTotal = data.output.find((x) => x.State === OrderFrontEndStateEnum.LotteryRunning)?.Cnt;
      this.manualAssignmentTotal = data.output.find(
        (x) => x.State === OrderFrontEndStateEnum.WaitingForManualAssignment
      )?.Cnt;
    });
  }

  handleCourierOrderSortChange($event: SortEvent): void {
    this.postDispatchService.courierOrderOrderBy = $event;
    this.handleCourierOrders({ Amount: this.activeRoutesAmount, Direction: PaginateEnum.None });
  }

  handleLastMileSortChange($event: SortEvent, isActive: boolean): void {
    this.postDispatchService.lastMileOrderBy = $event;
    if (isActive) {
      this.handleActiveLastMiles({ Amount: this.activeRoutesAmount, Direction: PaginateEnum.None });
    } else {
      this.handlePendingLastMiles({ Amount: this.pendingRoutesAmount, Direction: PaginateEnum.None });
    }
  }

  handleTripsSortChange($event: SortEvent, table: OrderFrontEndStateEnum): void {
    this.postDispatchService.orderOrderBy = $event;
    switch (table) {
      case OrderFrontEndStateEnum.Alert:
        this.handleAlertTrips({ Amount: this.alertAmount, Direction: PaginateEnum.None });
        break;
      case OrderFrontEndStateEnum.InProgress:
        this.handleInProgressTrips({ Amount: this.alertAmount, Direction: PaginateEnum.None });
        break;
      case OrderFrontEndStateEnum.LotteryRunning:
        this.handleLotteryRunningTrips({ Amount: this.alertAmount, Direction: PaginateEnum.None });
        break;
      case OrderFrontEndStateEnum.Scheduled:
        this.handleScheduledTrips({ Amount: this.alertAmount, Direction: PaginateEnum.None });
        break;
      case OrderFrontEndStateEnum.WaitingForManualAssignment:
        this.handleManualAssignmentTrips({ Amount: this.alertAmount, Direction: PaginateEnum.None });
        break;
      case OrderFrontEndStateEnum.Completed:
        this.handleCompletedTrips({ Amount: this.alertAmount, Direction: PaginateEnum.None });
        break;
      case OrderFrontEndStateEnum.StagedAssignment:
        this.handleStagedAssignmentsTrips({Amount: this.stagedAssignmentAmount, Direction: PaginateEnum.None})
        break;
    }
  }

  handleInProgressTrips($event: Paginate): void {
    this.inProgressAmount = $event.Amount;
    this.inProgressSubscription?.unsubscribe();
    this.inProgressSubscription = this.postDispatchService
      .getFireStoreOrderSummaryStreamByState(
        this.inProgressAmount,
        $event.Direction,
        OrderFrontEndStateEnum.InProgress,
        false
      )
      .subscribe((response) => {
        if (response[0]) {
          this.postDispatchService.setTripSnapshot(
            {
              First: response[0].payload.doc,
              Last: response[response.length - 1].payload.doc,
            },
            OrderFrontEndStateEnum.InProgress
          );

          const data: Order[] = [];
          for (const item of response) {
            data.push(item.payload.doc.data());
          }
          this.inProgressFirestoreTrips = from([data]);
        } else {
          this.inProgressFirestoreTrips = from([]);
        }
      });
  }

  handleScheduledTrips($event: Paginate): void {
    this.scheduledAmount = $event.Amount;
    this.scheduledSubscription?.unsubscribe();
    this.scheduledSubscription = this.postDispatchService
      .getFireStoreOrderSummaryStreamByState(this.scheduledAmount, $event.Direction, OrderFrontEndStateEnum.Scheduled, false)
      .subscribe((response) => {
        if (response[0]) {
          this.postDispatchService.setTripSnapshot(
            {
              First: response[0].payload.doc,
              Last: response[response.length - 1].payload.doc,
            },
            OrderFrontEndStateEnum.Scheduled
          );

          const data: Order[] = [];
          for (const item of response) {
            data.push(item.payload.doc.data());
          }
          this.scheduledFirestoreTrips = from([data]);
        } else {
          this.scheduledFirestoreTrips = from([]);
        }
      });
  }

  handleAlertTrips($event: Paginate): void {
    this.alertAmount = $event.Amount;
    this.alertSubscription?.unsubscribe();
    this.alertSubscription = this.postDispatchService
      .getFireStoreOrderSummaryStreamByState(this.alertAmount, $event.Direction, OrderFrontEndStateEnum.Alert, false)
      .subscribe((response) => {
        if (response[0]) {
          this.postDispatchService.setTripSnapshot(
            {
              First: response[0].payload.doc,
              Last: response[response.length - 1].payload.doc,
            },
            OrderFrontEndStateEnum.Alert
          );

          const data: Order[] = [];
          for (const item of response) {
            data.push(item.payload.doc.data());
          }
          this.alertFirestoreTrips = from([data]);
        } else {
          this.alertFirestoreTrips = from([]);
        }
      });
  }

  handleFailedFinalAlertTrips($event: Paginate): void {
    this.failedFinalAlertAmount = $event.Amount;
    this.failedFinalAlertSubscription?.unsubscribe();
    this.failedFinalAlertSubscription = this.postDispatchService
      .getFireStoreOrderSummaryStreamByState(this.failedFinalAlertAmount, $event.Direction, OrderFrontEndStateEnum.CompletedAllFailedFinalCollection, false, true)
      .subscribe((response) => {
        if (response[0]) {
          this.postDispatchService.setTripSnapshot(
            {
              First: response[0].payload.doc,
              Last: response[response.length - 1].payload.doc,
            },
            OrderFrontEndStateEnum.CompletedAllFailedFinalCollection
          );

          const data: Order[] = [];
          for (const item of response) {
            data.push(item.payload.doc.data());
          }
          this.failedFinalAlertFirestoreTrips = from([data]);
          this.failedFinalAlertTotal = data.length;
        } else {
          this.failedFinalAlertFirestoreTrips = from([]);
        }
      });
  }

  handleManualAssignmentTrips($event: Paginate): void {
    this.manualAssignmentAmount = $event.Amount;
    this.manualAssignmentSubscription?.unsubscribe();
    this.manualAssignmentSubscription = this.postDispatchService
      .getFireStoreOrderSummaryStreamByState(
        this.manualAssignmentAmount,
        $event.Direction,
        OrderFrontEndStateEnum.WaitingForManualAssignment,
        false
      )
      .subscribe((response) => {
        if (response[0]) {
          this.postDispatchService.setTripSnapshot(
            {
              First: response[0].payload.doc,
              Last: response[response.length - 1].payload.doc,
            },
            OrderFrontEndStateEnum.WaitingForManualAssignment
          );

          const data: Order[] = [];
          for (const item of response) {
            data.push(item.payload.doc.data());
          }
          this.manualAssignmentFirstoreTrips = from([data]);
        } else {
          this.manualAssignmentFirstoreTrips = from([]);
        }
      });
  }

  handleCompletedTrips($event: Paginate): void {
    this.completedAmount = $event.Amount;
    this.completedSubscription?.unsubscribe();
    this.completedSubscription = this.postDispatchService
      .getFireStoreOrderSummaryStreamByState(this.completedAmount, $event.Direction, OrderFrontEndStateEnum.Completed, false)
      .subscribe((response) => {
        if (response[0]) {
          this.postDispatchService.setTripSnapshot(
            {
              First: response[0].payload.doc,
              Last: response[response.length - 1].payload.doc,
            },
            OrderFrontEndStateEnum.Completed
          );

          const data: Order[] = [];
          for (const item of response) {
            data.push(item.payload.doc.data());
          }
          this.completedFirestoreTrips = from([data]);
        } else {
          this.completedFirestoreTrips = from([]);
        }
      });
  }

  handleStagedAssignmentsTrips($event: Paginate): void {
    this.stagedAssignmentAmount = $event.Amount;
    this.stagedAssignmentSubscription?.unsubscribe();
    this.stagedAssignmentSubscription = this.postDispatchService
      .getFireStoreOrderSummaryStreamByState(this.stagedAssignmentAmount, $event.Direction, OrderFrontEndStateEnum.StagedAssignment, false)
      .subscribe((response: DocumentChangeAction<Order>[]) => {
        if (response[0]) {
          this.postDispatchService.setTripSnapshot(
            {
              First: response[0].payload.doc,
              Last: response[response.length - 1].payload.doc,
            },
            OrderFrontEndStateEnum.StagedAssignment
          );

          const data: Order[] = [];
          for (const item of response) {
            data.push(item.payload.doc.data());
          }
          this.stagedAssignmentsFirestoreTrips = from([data]);
        } else {
          this.stagedAssignmentsFirestoreTrips = from([]);
        }
      });
  }

  handleLotteryRunningTrips($event: Paginate): void {
    this.lotteryRunningAmount = $event.Amount;
    this.lotteryRunningSubscription?.unsubscribe();
    this.lotteryRunningSubscription = this.postDispatchService
      .getFireStoreOrderSummaryStreamByState(
        this.lotteryRunningAmount,
        $event.Direction,
        OrderFrontEndStateEnum.LotteryRunning,
        false
      )
      .subscribe((response) => {
        if (response[0]) {
          this.postDispatchService.setTripSnapshot(
            {
              First: response[0].payload.doc,
              Last: response[response.length - 1].payload.doc,
            },
            OrderFrontEndStateEnum.LotteryRunning
          );

          const data: Order[] = [];
          for (const item of response) {
            data.push(item.payload.doc.data());
          }
          this.lotteryRunningFirestoreTrips = from([data]);
        } else {
          this.lotteryRunningFirestoreTrips = from([]);
        }
      });
  }

  handleActiveLastMiles($event: Paginate): void {
    this.activeRoutesAmount = $event.Amount;
    this.activeRoutesSubscription?.unsubscribe();
    this.activeRoutesSubscription = this.postDispatchService
      .getActiveFirestoreLastmiles(false)
      .subscribe((response) => {
        this.activeRoutes = from([response]);
        this.activeRoutesTotal = response.length;
      });
  }

  handlePendingLastMiles($event: Paginate): void {
    this.pendingRoutesAmount = $event.Amount;
    this.pendingRoutesSubscription?.unsubscribe();
    this.pendingRoutesSubscription = this.postDispatchService
      .getPendingFirestoreLastmiles(false)
      .subscribe((response) => {
        this.pendingRoutes = from([response]);
        this.pendingRoutesTotal = response.length;
      });
  }

  handleCourierOrders($event: Paginate): void {
    this.courierOrdersAmount = $event.Amount;
    this.courierOrdersSubscription?.unsubscribe();
    this.courierOrdersSubscription = this.postDispatchService
      .getCourierFirestoreOrdersStream(this.courierOrdersAmount, $event.Direction, false)
      .subscribe((response) => {
        if (response[0]) {
          this.postDispatchService.courierOrderSnapshot = {
            First: response[0].payload.doc,
            Last: response[response.length - 1].payload.doc,
          };

          const data: CourierOrder[] = [];
          for (const item of response) {
            data.push(item.payload.doc.data());
          }

          this.courierOrders = from([data]);
        } else {
          this.courierOrders = from([]);
        }
      });
  }

  reconcileCourierOrder(courierOrderIds: string[]): void {
    this.simpleModalService.addModal(ReconcileCourierOrderModalComponent).subscribe((result) => {
      if (!result) {
        return;
      } else {
        this.courierOrdersService.overrideCourierOrderState(courierOrderIds, result[0], result[1]);
      }
    });
  }

  populateTableActions(): void {
    if (this.user.is_admin) {
      this.postDispatchTablePrimaryActions = [
        { event: 'viewTrip', title: 'View Trip', icon: IconTypes.Eye, icon_color: UiColors.Grey8 },
        {
          event: 'copyId',
          title: 'Copy ID',
          icon: IconTypes.Details,
          icon_color: UiColors.Grey8,
        },
      ];
      this.activeDriverPrimaryActions = [
        { event: 'viewTrip', title: 'View Trip', icon: IconTypes.Eye, icon_color: UiColors.Grey8 },
        {
          event: 'copyId',
          title: 'Copy ID',
          icon: IconTypes.Details,
          icon_color: UiColors.Grey8,
        },
      ];
    } else {
      this.postDispatchTablePrimaryActions = [
        { event: 'viewTrip', title: 'View Trip', icon: IconTypes.Eye, icon_color: UiColors.Grey8 },
      ];
      this.activeDriverPrimaryActions = [
        { event: 'viewTrip', title: 'View Trip', icon: IconTypes.Eye, icon_color: UiColors.Grey8 },
      ];
    }

    if (this.user.is_admin || this.actingAs.roles.includes(UserRoles.Administrator || UserRoles.SuperAdministrator)) {
      this.courierOrderBulkActions = [
        {
          event: PD_Courier_Order_Table_Actions.Reconcile,
          title: 'Reconcile',
          icon: IconTypes.Check,
          icon_color: UiColors.Green,
        },
      ];
    }
  }

  toggleMode(mode: PostDispatchViewEnum): void {
    const payload: PostDispatchPayload = {
      warehouse: this.activeWarehouseFilter.getValue(),
      fleet_allocation: this.fleetFilter.getValue(),
      mode,
      limit: null,
    };
    this.store.dispatch(new setPostDispatchFilters(payload));
    this.refreshData();
  }

  handleSubscriptions(): void {
    const mode = this.modeFilter.getValue() as PostDispatchViewEnum;
    switch (mode) {
      case PostDispatchViewEnum.Active: {
        this.handleActiveLastMiles({ Amount: this.activeRoutesAmount, Direction: PaginateEnum.None });
        this.pendingRoutesSubscription?.unsubscribe();
        this.courierOrdersSubscription?.unsubscribe();
        this.alertSubscription?.unsubscribe();
        this.failedFinalAlertSubscription?.unsubscribe();
        this.scheduledSubscription?.unsubscribe();
        this.manualAssignmentSubscription?.unsubscribe();
        this.lotteryRunningSubscription?.unsubscribe();
        this.completedSubscription?.unsubscribe();
        this.inProgressSubscription?.unsubscribe();
        break;
      }
      case PostDispatchViewEnum.Order: {
        this.getTripsData();
        this.activeRoutesSubscription?.unsubscribe();
        this.pendingRoutesSubscription?.unsubscribe();
        this.courierOrdersSubscription?.unsubscribe();
        break;
      }
      case PostDispatchViewEnum.Courier: {
        this.handleCourierOrders({ Amount: this.courierOrdersAmount, Direction: PaginateEnum.None });
        this.activeRoutesSubscription?.unsubscribe();
        this.pendingRoutesSubscription?.unsubscribe();
        this.alertSubscription?.unsubscribe();
        this.failedFinalAlertSubscription?.unsubscribe();
        this.scheduledSubscription?.unsubscribe();
        this.manualAssignmentSubscription?.unsubscribe();
        this.lotteryRunningSubscription?.unsubscribe();
        this.completedSubscription?.unsubscribe();
        this.inProgressSubscription?.unsubscribe();
        break;
      }
      case PostDispatchViewEnum.Pending: {
        this.handlePendingLastMiles({ Amount: this.pendingRoutesAmount, Direction: PaginateEnum.None });
        this.activeRoutesSubscription?.unsubscribe();
        this.courierOrdersSubscription?.unsubscribe();
        this.alertSubscription?.unsubscribe();
        this.failedFinalAlertSubscription?.unsubscribe();
        this.scheduledSubscription?.unsubscribe();
        this.manualAssignmentSubscription?.unsubscribe();
        this.lotteryRunningSubscription?.unsubscribe();
        this.completedSubscription?.unsubscribe();
        this.inProgressSubscription?.unsubscribe();
        break;
      }
      case PostDispatchViewEnum.Dispatch: {
        this.activeRoutesSubscription?.unsubscribe();
        this.courierOrdersSubscription?.unsubscribe();
        this.alertSubscription?.unsubscribe();
        this.failedFinalAlertSubscription?.unsubscribe();
        this.scheduledSubscription?.unsubscribe();
        this.manualAssignmentSubscription?.unsubscribe();
        this.lotteryRunningSubscription?.unsubscribe();
        this.completedSubscription?.unsubscribe();
        this.inProgressSubscription?.unsubscribe();
        this.pendingRoutesSubscription?.unsubscribe();

        this.dispatch.refreshData();

        break;
      }
      case PostDispatchViewEnum.Tracking: {
        this.activeRoutesSubscription?.unsubscribe();
        this.courierOrdersSubscription?.unsubscribe();
        this.alertSubscription?.unsubscribe();
        this.failedFinalAlertSubscription?.unsubscribe();
        this.scheduledSubscription?.unsubscribe();
        this.manualAssignmentSubscription?.unsubscribe();
        this.lotteryRunningSubscription?.unsubscribe();
        this.completedSubscription?.unsubscribe();
        this.inProgressSubscription?.unsubscribe();
        this.pendingRoutesSubscription?.unsubscribe();

        this.tracking.refreshData();

        break;
      }
      default : {
        this.activeRoutesSubscription?.unsubscribe();
        this.courierOrdersSubscription?.unsubscribe();
        this.alertSubscription?.unsubscribe();
        this.failedFinalAlertSubscription?.unsubscribe();
        this.scheduledSubscription?.unsubscribe();
        this.manualAssignmentSubscription?.unsubscribe();
        this.lotteryRunningSubscription?.unsubscribe();
        this.completedSubscription?.unsubscribe();
        this.inProgressSubscription?.unsubscribe();
        this.pendingRoutesSubscription?.unsubscribe();
        break;
      }
    }
  }

  getPostDispatchSummaryData(): void {
    this.postDispatchSummaryData = undefined;
    const warehouseParam = this.warehouseFilterId.getValue()
      ? [this.warehouseFilterId.getValue()]
      : this.available_warehouse_ids;
    this.postDispatchService.getPostDispatchSummary(warehouseParam).then((res) => {
      this.postDispatchSummaryData = res;
    });
  }

  getTodaysSummary(date: string): void {
    this.todaySummaryData = undefined;
    const warehouseParam = this.warehouseFilterId.getValue()
      ? [this.warehouseFilterId.getValue()]
      : this.available_warehouse_ids;
    this.postDispatchService.getTodaysSummary(warehouseParam, date).then((response) => {
      this.todaySummaryData = response;
    });
  }

  clearSearch(): void {
    if (document.getElementById('input')) {
      document.getElementById('input').focus();
    }
    this.searchString = null;
  }

  setWarehouseFilter(warehouse: Warehouse): void {
    this.refreshLoading = true;
    const payload: PostDispatchPayload = {
      warehouse,
      fleet_allocation: this.fleetFilter.getValue(),
      mode: this.modeFilter.getValue(),
      limit: null,
      business_id: this.actingAs.id,
    };
    if (!warehouse) {
      payload.warehouse = null;
    } else {
      payload.warehouse = warehouse;
    }
    this.store.dispatch(new setPostDispatchFilters(payload));
    this.refreshData();
  }

  fetchData(): void {
    switch (this.modeFilter.getValue()) {
      case 'active':
        this.getTodaysSummary(moment().format('YYYY-MM-DD'));
        this.getPostDispatchSummaryData();
        break;
      case 'pending':
        this.getTodaysSummary(moment().format('YYYY-MM-DD'));
        this.getPostDispatchSummaryData();
        break;
      case 'courier':
        break;
      case 'order':
        this.getPostDispatchSummaryData();
        break;
    }
  }

  setFleetFilter(fleet_allocation: string): void {
    const payload: PostDispatchPayload = {
      warehouse: this.activeWarehouseFilter.getValue(),
      fleet_allocation,
      mode: this.modeFilter.getValue(),
      limit: null,
      business_id: this.actingAs.id,
    };
    this.store.dispatch(new setPostDispatchFilters(payload));
    this.refreshData();
  }

  overrideDate(date: string): void {
    this.getTodaysSummary(moment(date).format('YYYY-MM-DD'));
  }

  getTripsData(): void {
    this.handleInProgressTrips({ Amount: this.inProgressAmount, Direction: PaginateEnum.None });
    this.handleAlertTrips({ Amount: this.alertAmount, Direction: PaginateEnum.None });
    this.handleScheduledTrips({ Amount: this.scheduledAmount, Direction: PaginateEnum.None });
    this.handleLotteryRunningTrips({ Amount: this.lotteryRunningAmount, Direction: PaginateEnum.None });
    this.handleManualAssignmentTrips({ Amount: this.manualAssignmentAmount, Direction: PaginateEnum.None });
    this.handleCompletedTrips({ Amount: this.manualAssignmentAmount, Direction: PaginateEnum.None });
    this.handleFailedFinalAlertTrips({Amount: this.failedFinalAlertAmount, Direction: PaginateEnum.None});
    this.handleStagedAssignmentsTrips({Amount: this.stagedAssignmentAmount, Direction: PaginateEnum.None})
  }

  setCourierFilter(courierCode: CourierCode): void {
    this.courierFilter = courierCode;
    if (courierCode) {
      this.postDispatchService.courierCodeFilter$.next([courierCode.courier_code]);
    } else {
      this.postDispatchService.courierCodeFilter$.next([]);
    }
    this.handleCourierOrders({ Amount: this.courierOrdersAmount, Direction: PaginateEnum.None });
  }

  scheduledSelected(selected: string): void{
    this.postDispatchService.showBuckets = selected;
    this.refreshData();
  }
}
