import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconTypes } from '../icon/icon.interfaces';
import { UiColors } from '../../interfaces/ui.interfaces';
import { HeadingProps } from './heading.interfaces';

@Component({
  selector: 'heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss'],
})
export class HeadingComponent implements HeadingProps {
  @Input() icon: IconTypes;
  @Input() color: UiColors;
  @Input() compact: boolean;
  @Input() highlight: string | number;
  @Input() showToggle: boolean;
  @Input() toggleActive: boolean;
  @Input() border = true;

  @Output() clickEvent = new EventEmitter();

  ui = {
    icons: IconTypes,
    colors: UiColors,
  };

  public handleClick() {
    if (!this.showToggle) {
      return;
    }

    this.toggleActive = !this.toggleActive;
    this.clickEvent.emit();
  }
}
