import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'humanizeTime'
})
export class HumanizeTimePipe implements PipeTransform {
  // pass in seconds only
  transform(value: number): string {
    if (!value) {
      return '';
    }
    let descriptor = '';
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor(value % 3600 / 60);
    const seconds = Math.floor(value % 3600 % 60);
    if (hours > 0) {
      descriptor += `${hours}hr${hours === 1 ? '' : 's'}`
    }
    if (minutes > 0) {
      descriptor += `${hours > 0 ? ' ' : ''} ${minutes}min${minutes === 1 ? '' : 's'}`
    }
    if (seconds > 0) {
      descriptor += `${minutes > 0 ? ' ' : ''} ${seconds}sec${seconds === 1 ? '' : 's'}`
    }
    return descriptor;
  }

}
