<app-flex-container [justifyContent]="JustifyContent.Center">
  <app-basic-button
    *ngIf="user.is_admin || fleet === 'Contractor'"
    [active]="true"
    [title]="lotteries?.length ? 'Restart ' + fleet + ' Lottery' : 'Start ' + fleet + ' Lottery'"
    [type]="buttonTypes.Primary"
    (click)="startLotteryFromBackend(fleet)"
    [disabled]="(((lotteries && lotteries[0]?.status < 40) || drivers) && lotteries?.length) || drivers?.length"
    [processing]="processing"
  ></app-basic-button>

  <app-basic-button
    *ngIf="fleet === 'PicupPlus' && user.is_admin"
    [active]="true"
    [title]="lotteries?.length ? 'Restart Picup Lottery Once' : 'Start Picup Lottery Once'"
    [type]="buttonTypes.Primary"
    (click)="startLotteryFromBackend(fleetAllocationEnum.Picup)"
    [disabled]="(((lotteries && lotteries[0]?.status < 40) || drivers) && lotteries?.length) || drivers?.length"
    [processing]="processing"
  ></app-basic-button>

  <app-basic-button
    *ngIf="fleet === 'Contractor' && user.is_admin"
    [active]="true"
    [title]="lotteries?.length ? 'Restart as Picup Lottery Once' : 'Start as Picup Lottery Once'"
    [type]="buttonTypes.Primary"
    (click)="startLotteryFromBackend(fleetAllocationEnum.Picup)"
    [disabled]="(((lotteries && lotteries[0]?.status < 40) || drivers) && lotteries?.length) || drivers?.length"
    [processing]="processing"
  ></app-basic-button>

  <app-basic-button
    *ngIf="HasPreferredDrivers && user.is_admin"
    [active]="true"
    [title]="lotteries?.length ? 'Restart as Preferred Lottery Once' : 'Start as Preferred Lottery Once'"
    [type]="buttonTypes.Primary"
    (click)="startLotteryFromBackend(fleetAllocationEnum.Preferred)"
    [disabled]="(((lotteries && lotteries[0]?.status < 40) || drivers) && lotteries?.length) || drivers?.length"
    [processing]="processing"
  ></app-basic-button>

  <app-basic-button
    *ngIf="user.is_admin || fleet === 'Contractor'"
    [active]="true"
    [title]="lotteries?.length ? 'Restart Lottery Cycle' : 'Start Lottery Cycle'"
    [type]="buttonTypes.Primary"
    (click)="startMultipleLotteryFromBackend(fleet)"
    [disabled]="(((lotteries && lotteries[0]?.status < 40) || drivers) && lotteries?.length) || drivers?.length"
    [processing]="processing"
  ></app-basic-button>
</app-flex-container>

<ng-template ngFor let-lottery [ngForOf]="lotteries" let-i="index" let-cnt="count">
  <app-lottery-details [isAdmin]="user?.is_admin" [index]="cnt - i - 1" [lottery]="lottery"></app-lottery-details>
</ng-template>
