<app-modal
  [open]="true"
  [actions]="actions"
  [highlightPrimaryAction]="true"
  [size]="ModalSizes.Large"
  (handleAction)="handleAction($event)"
>
  <heading [icon]="IconTypes.Bucket" [color]="uiColors.Blue">
    <h4 class="heading-title">Select a bucket</h4>
    <app-basic-button [title]="'Create New Bucket'" [type]="buttonTypes.Primary" (click)="createNewBucket()"> </app-basic-button>
    <div
      *ngIf="
        user_permissions?.modules?.includes('super-admin') ||
        !user_permissions?.modules?.length ||
        !user_permissions?.modules[0]
      "
      class="option"
    >
      <input type="checkbox" id="routing" (click)="toggle()" [checked]="showAll" />
      <h6>SHOW ALL</h6>
    </div>
  </heading>
  <div class="modal-content">
    <app-table
      [rawData]="availableBuckets"
      [dataMap]="availableBucketsTableDataMap"
      [columns]="availableBucketsTableColumns"
      [isLoading]="!availableBuckets"
      [primaryActions]="availableBucketsPrimaryActions"
      (actionTaken)="selectBucket($event)"
      [rowsSelectable]="false"
    >
    </app-table>
  </div>
</app-modal>
