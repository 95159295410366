  <app-grid-container [columns]="'repeat(2, 1fr)'">
    <div>
      <app-section>
        <h4>{{ 'Route Report' | uppercase }}</h4>
        <app-basic-button
          [processing]="loading"
          [disabled]="!customDateObj.end_date && !customDateObj.start_date"
          class="nav-button"
          (click)="user.is_admin ? getReport('route-admin') : getReport('route')"
          [title]="'Generate'"
          [type]="buttonTypes.Primary"
        >
        </app-basic-button>
      </app-section>
      <app-section>
        <h4>{{ 'Payment Report - By Orders' | uppercase }}</h4>
        <app-basic-button
          [processing]="loading"
          [disabled]="!customDateObj.end_date && !customDateObj.start_date"
          class="nav-button"
          (click)="getReport('payment-orders')"
          [title]="'Generate'"
          [type]="buttonTypes.Primary"
        >
        </app-basic-button>
      </app-section>
      <app-section>
        <h4>{{ 'Payment Report - By Parcels' | uppercase }}</h4>
        <app-basic-button
          [processing]="loading"
          [disabled]="!customDateObj.end_date && !customDateObj.start_date"
          class="nav-button"
          (click)="getReport('payment-parcels')"
          [title]="'Generate'"
          [type]="buttonTypes.Primary"
        >
        </app-basic-button>
      </app-section>
  
      <app-section>
        <h4>{{ 'Parcel SLA Report' | uppercase }}</h4>
        <app-basic-button
          [processing]="loading"
          [disabled]="!customDateObj.end_date && !customDateObj.start_date"
          class="nav-button"
          (click)="getReport('parcel-sla')"
          [title]="'Generate'"
          [type]="buttonTypes.Primary"
        >
        </app-basic-button>
      </app-section>
      <app-section>
        <h4>{{ 'Orders by Warehouse Count' | uppercase }}</h4>
  
        <app-basic-button
          [processing]="loading"
          [disabled]="!customDateObj.end_date && !customDateObj.start_date"
          class="nav-button"
          (click)="getReport('orders-by-warehouse')"
          [title]="'Generate'"
          [type]="buttonTypes.Primary"
        >
        </app-basic-button>
      </app-section>
  
      <app-section>
        <h4>{{ 'Deleted Orders by Warehouse' | uppercase }}</h4>
  
        <app-basic-button
          [processing]="loading"
          [disabled]="!customDateObj.end_date && !customDateObj.start_date"
          class="nav-button"
          (click)="getReport('get-deleted-orders-by-warehouse')"
          [title]="'Generate'"
          [type]="buttonTypes.Primary"
        >
        </app-basic-button>
      </app-section>
  
      <app-section>
        <h4>{{ 'Parcel Outcome by Parcel State' | uppercase }}</h4>
  
        <app-basic-button
          [processing]="loading"
          [disabled]="!customDateObj.end_date && !customDateObj.start_date"
          class="nav-button"
          (click)="getReport('get-parcel-outcome-by-parcel-state')"
          [title]="'Generate'"
          [type]="buttonTypes.Primary"
        >
        </app-basic-button>
      </app-section>
      <app-section>
        <h4>{{ 'Bucket Status Report' | uppercase }}</h4>
  
        <app-basic-button
          [processing]="loading"
          [disabled]="!customDateObj.end_date && !customDateObj.start_date"
          class="nav-button"
          (click)="getReport('get-buckets-report')"
          [title]="'Generate'"
          [type]="buttonTypes.Primary"
        >
        </app-basic-button>
      </app-section>
      <app-section>
        <h4>{{ 'Active Courier Orders Report' | uppercase }}</h4>
  
        <app-basic-button
          [processing]="loading"
          [disabled]="!customDateObj.end_date && !customDateObj.start_date"
          class="nav-button"
          (click)="getReport('active-courier-order-report')"
          [title]="'Generate'"
          [type]="buttonTypes.Primary"
        >
        </app-basic-button>
      </app-section>
      <app-section>
        <h4>{{ 'Completed Courier Orders Report' | uppercase }}</h4>
  
        <app-basic-button
          [processing]="loading"
          [disabled]="!customDateObj.end_date && !customDateObj.start_date"
          class="nav-button"
          (click)="getReport('completed-courier-order-report')"
          [title]="'Generate'"
          [type]="buttonTypes.Primary"
        >
        </app-basic-button>
      </app-section>
      <app-section>
        <h4>{{ 'Driver Rating Report' | uppercase }}</h4>
  
        <app-basic-button
          [processing]="loading"
          [disabled]="!customDateObj.end_date && !customDateObj.start_date"
          class="nav-button"
          (click)="getReport('driver-rating-report')"
          [title]="'Generate'"
          [type]="buttonTypes.Primary"
        >
        </app-basic-button>
      </app-section>
      <app-section>
        <h4>{{ 'Driver Productivity Report' | uppercase }}</h4>
  
        <app-basic-button
          [processing]="loading"
          [disabled]="!customDateObj.end_date && !customDateObj.start_date"
          class="nav-button"
          (click)="getReport('get-driver-productivity-report')"
          [title]="'Generate'"
          [type]="buttonTypes.Primary"
        >
        </app-basic-button>
      </app-section>
      <app-section>
        <h4>{{ 'SLA Report' | uppercase }}</h4>
  
        <app-basic-button
          [processing]="loading"
          [disabled]="!customDateObj.end_date && !customDateObj.start_date"
          class="nav-button"
          (click)="getReport('sla-report')"
          [title]="'Generate'"
          [type]="buttonTypes.Primary"
        >
        </app-basic-button>
      </app-section>
    </div>
    <div *ngIf="user.is_admin">
      <app-section>
        <h4>{{ 'Geocoded Distance Report (Admin only)' | uppercase }}</h4>
  
        <app-basic-button
          [processing]="loading"
          [disabled]="!customDateObj.end_date && !customDateObj.start_date"
          class="nav-button"
          (click)="getReport('geocoded-distance-cost-report')"
          [title]="'Generate'"
          [type]="buttonTypes.Primary"
        >
        </app-basic-button>
      </app-section>
  
      <app-section>
        <h4>{{ 'Planned On Demand Earnings (Admin only)' | uppercase }}</h4>
  
        <app-basic-button
          [processing]="loading"
          [disabled]="!customDateObj.end_date && !customDateObj.start_date"
          class="nav-button"
          (click)="getReport('planned-on-demand-earnings')"
          [title]="'Generate'"
          [type]="buttonTypes.Primary"
        >
        </app-basic-button>
      </app-section>
      <app-section>
        <h4>{{ 'Planned Driver Earnings (Admin only)' | uppercase }}</h4>
  
        <app-basic-button
          [processing]="loading"
          [disabled]="!customDateObj.end_date && !customDateObj.start_date"
          class="nav-button"
          (click)="getReport('planned-driver-earnings-report')"
          [title]="'Generate'"
          [type]="buttonTypes.Primary"
        >
        </app-basic-button>
      </app-section>
    </div>
  </app-grid-container>
  