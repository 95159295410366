export interface PayStackPaymentResponse {
  message: string;
  reference: string;
  status: string;
  trans: string;
  transaction: string;
  trxref: string;
}

export interface PaystackSavedCard {
  CardId: string;
  CardDetails: CardDetails
}
export interface CardDetails {
  authorization_code: string;
  card_type: string;
  last4: string;
  exp_month: string;
  exp_year: string;
  bin: string;
  bank: string;
  channel: string;
  signature: string;
  reusable: boolean;
  country_code: string;
  account_name: string;
}

export enum CardTypes {
  Visa = 'visa ',
  MasterCard = 'mastercard',
  Paypal = 'paypal',
  AmericanExpress = 'americanexpress',
}

export enum PaymentTypeEnum {
  Account = 'Account',
  Credits = 'Credits',
  OnlinePayment = 'Online Payment',
  CreditCard = 'Credit Card',
  PayStack = 'Paystack'
}

export enum BackendPaymentTypesEnum {
  None = 0,
  Credits = 1,
  Peach = 2,
  Eft = 3,
  Account = 4,
  Paystack = 5
}

export enum PayStackResponseStatusEnum {
  Success = 'success',
}
