<app-modal
  [open]="true"
  [highlightPrimaryAction]="true"
  [size]="ModalSizes.Large"
  [actions]="actions"
  (handleAction)="handleAction($event)"
>
  <div class="modal-content">
    <heading [icon]="IconTypes.Parcel" [color]="UiColors.Blue">
      {{ 'Timeline' | uppercase }}
      <app-dropdown [activeItem]="filteredTimelineData ? filteredTimelineData[0].ParcelWaybillNumber : 'All Parcels'">
        <a class="dropdown-item" (click)="filterParcels(null)">All Parcels</a>
        <div dropdownRef>
          <a *ngFor="let parcel of parcelList" class="dropdown-item" (click)="filterParcels(parcel)">{{ parcel }}</a>
        </div>
      </app-dropdown>
    </heading>
    <div class="modal-body">
      <app-loader *ngIf="loading; else showData" [loadingMessage]="'Loading Timeline'"></app-loader>
      <ng-template #showData>
        <app-table
        *ngIf="timelineData; else noData"
        [columns]="trackingTableColumns"
        [dataMap]="trackingTableDataMap"
        [rawData]="filteredTimelineData ? filteredTimelineData : timelineData"
        [rowsSelectable]="false"
        [primaryActions]="tablePrimaryActions"
        (actionTaken)="respondToTableAction($event)"
      >
      </app-table>
      <ng-template #noData>
        <app-loader [displayLoader]="false" [loadingMessage]="'No data available'"></app-loader>
      </ng-template>
      </ng-template>
    </div>
  </div>
</app-modal>
