import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'myfilter'
})
export class MyFilterPipe implements PipeTransform {
  transform(value: any, filter: string) {
    filter = filter ? filter.toLocaleLowerCase() : '';
    return filter && value ?
      value.filter(address =>
        (address.id.toString().indexOf(filter.toString()) !== -1) ||
                (address.suburb.toLocaleLowerCase().indexOf(filter) !== -1) ||
                (address.postal_code.indexOf(filter) !== -1) ||
                (address.address1.toLocaleLowerCase().indexOf(filter) !== -1) ||
                (address.address2 ? address.address2.toLocaleLowerCase().indexOf(filter) !== -1 : '') ||
                (address.address3 ? address.address3.toLocaleLowerCase().indexOf(filter) !== -1 : '') ||
                (address.address4 ? address.address4.toLocaleLowerCase().indexOf(filter) !== -1 : '') ||
                (address.orders[0] ? address.orders[0].customer_name.toLocaleLowerCase().indexOf(filter) !== -1 : '')
      ) :
      value;
  }
}
