<div class="tabs">
  <div class="tab active">
    <h4>Select Fulfillment Option</h4>
  </div>
</div>
<section>
  <app-grid-container [columns]="'repeat(1, 1fr)'" [colGap]="'2rem'" [padding]="'2rem 2rem 0rem 2rem'"
    [borderRight]="true">
    <div class="summary">
      <app-grid-container [columns]="'repeat(2, 1fr)'" [colGap]="'2rem'" [padding]="'3rem'">
        <label>
          Customer Reference:
          <p>{{ quoteResult?.request.customer_ref }}</p>
        </label>
        <label>
          Scheduled Collection Time:
          <p>
            {{
            quoteResult?.request.scheduled_date
            ? (quoteResult?.request.scheduled_date | date: 'medium')
            : 'Activate Immediately'
            }}
          </p>
        </label>
      </app-grid-container>
    </div>
    <app-waypoint-summary-block *ngIf="!quoteResult.request.sender?.warehouse_id"
      [waypoint]="quoteResult?.request.sender"></app-waypoint-summary-block>
    <ng-template ngFor [ngForOf]="quoteResult?.request.receivers" let-waypoint>
      <app-waypoint-summary-block [waypoint]="waypoint"></app-waypoint-summary-block>
    </ng-template>
  </app-grid-container>

  <app-grid-container *ngIf="
      quoteResult?.third_party?.fullfillment_options?.length > 0 ||
      (quoteResult?.picup?.service_types && quoteResult?.picup?.service_types.length > 0)
    " [columns]="
      'repeat(' +
      (quoteResult?.picup?.service_types?.length + quoteResult?.third_party?.fullfillment_options?.length) +
      ', 1fr)'
    " [colGap]="'2rem'" [padding]="'2rem'" [borderRight]="true">
    <ng-template ngFor let-option [ngForOf]="quoteResult?.picup?.service_types">
      <div class="service-type-block" [style.opacity]="option.valid ? 1 : 1"
        (click)="selectMethod('Picup', option.description, option.quote_id, option.price_incl_vat)"
        [ngClass]="{ active: isActive(option.description) }">
        <div class="heading">
          <h4>On Demand: {{ option.description }}</h4>
        </div>
        <div class="vehicle">
          <img [src]="'/assets/img/vehicles/' + option.description + '.png'" />
        </div>
        <app-grid-container [columns]="'repeat(1, 1fr)'" [borderRight]="true">
          <app-stats-icon [padding]="'0'" [dataSizeRem]="2" [icon]="IconTypes.Dollar" [iconColor]="UiColors.Blue"
            [iconSizeRem]="2" [label]="'Delivery Cost'"
            [data]="option.price_incl_vat ? (option.price_incl_vat | currency: currency_symbol + ' ') : '-'">
          </app-stats-icon>
          <app-stats-icon [padding]="'0'" [dataSizeRem]="2" [icon]="IconTypes.History" [iconColor]="UiColors.Blue"
            [iconSizeRem]="2" [label]="'Delivery Duration'" [data]="option.duration ? option.duration : '-'">
          </app-stats-icon>
        </app-grid-container>
      </div>
    </ng-template>
    <ng-template ngFor let-option [ngForOf]="quoteResult?.third_party?.fullfillment_options">
      <div class="service-type-block courier"
        (click)="selectMethod('picup','courier_requests', option.collections, option.price_incl_vat)"
        [ngClass]="{ active: isActive(option.collections) }">
        <div class="heading">
          <h4>Third Party: {{ option.description }}</h4>
        </div>
        <div class="vehicle">
          <img [src]="'/assets/img/vehicles/' + fulfillmentOptionImageMap.get(option.vehicle_id) + '.png'" />
        </div>
        <app-grid-container [columns]="'repeat(1, 1fr)'" [borderRight]="true">
          <app-stats-icon [padding]="'0'" [dataSizeRem]="2" [icon]="IconTypes.Dollar" [iconColor]="UiColors.Green"
            [iconSizeRem]="2" [label]="'Delivery Cost'"
            [data]="option.price_incl_vat ? (option.price_incl_vat | currency: currency_symbol + ' ') : '-'">
          </app-stats-icon>
          <h5>
            Collection By: <strong>{{ momentPipe(option.collected_before) }}</strong>
          </h5>
          <h5>
            Delivered By: <strong>{{ momentPipe(option.delivered_before) }}</strong>
          </h5>
        </app-grid-container>
      </div>
    </ng-template>
  </app-grid-container>

  <app-grid-container *ngIf="
      (quoteResult?.third_party?.fullfillment_options.length === 0 || !quoteResult.third_party) &&
      (!quoteResult?.picup?.service_types || quoteResult?.picup?.service_types.length === 0)
    " [columns]="'repeat(1, 1fr)'" [colGap]="'2rem'" [padding]="'2rem'" [borderRight]="true">
    <div class="no-results">
      <div class="service-type-block no-service">
        <div class="heading">
          <h4>Unserviced Locations</h4>
        </div>
        <div class="vehicle">
          <img [src]="'/assets/img/noorders.png'" />
        </div>
        <app-grid-container [columns]="'repeat(1, 1fr)'" [borderRight]="true">
          <h5>
            Unfortunately, we do not service the delivery points you've chosen. Please confirm your delivery or
            collection is within our bounds.
          </h5>
        </app-grid-container>
      </div>
    </div>
  </app-grid-container>

  <h4 *ngIf="error" class="error">{{ error }}</h4>

  <app-grid-container *ngIf="paymentRequest" [columns]="'repeat(1, 1fr)'" [colGap]="'2rem'"
    [padding]="'0rem 2rem 2rem 2rem'" [borderRight]="true">
    <div class="payment-block">
      <div class="heading">
        <h3>
          <app-icon [icon]="IconTypes.Lock" [color]="UiColors.Grey7" [size]="2"></app-icon>
          Payment
        </h3>
        <p>
          Choose to pay for your request with your existing credit balance or use your credit/debit card to make an
          instant payment
        </p>
      </div>
      <app-flex-container *ngIf="wallet && wallet.credits" class="amounts" [justifyContent]="justifyContent.FlexStart"
        [padding]="'1rem 0rem 2rem 0rem'">
        <h3>
          <label>Subtotal:</label>
          {{ paymentRequest.price_incl_vat | currency: currency_symbol + ' ' }}
        </h3>
        <ng-container *ngIf="wallet.credits > paymentRequest.price_incl_vat">
          <h3>
            <label>Available Credits:</label>
            {{ wallet.credits | currency: currency_symbol + ' ' }}
          </h3>

          <h3>
            <label>Credits After Transaction:</label>
            {{ wallet.credits - paymentRequest.price_incl_vat | currency: currency_symbol + ' ' }}
          </h3>
        </ng-container>

        <ng-container *ngIf="wallet.credits < paymentRequest.price_incl_vat">
          <h3 class="insufficient-credits">
            <label>Available Credits:</label>
            {{ wallet.credits | currency: currency_symbol + ' ' }}
          </h3>
        </ng-container>

      </app-flex-container>
      <app-payment 
        [availableCredits]="wallet.credits" 
        [intention]="intention" 
        [request]="paymentRequest"
        [price]="paymentRequest.price_incl_vat">
      </app-payment>
    </div>
  </app-grid-container>

  <app-flex-container 
    [justifyContent]="justifyContent.FlexStart" 
    [padding]="'2rem'">
    <app-basic-button 
      [title]="'Back'" 
      [type]="ButtonTypes.Highlight" 
      [active]="true" 
      [processing]="processing"
      (click)="goBack()">
    </app-basic-button>
  </app-flex-container>
</section>