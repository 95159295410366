<app-modal [open]="true" [actions]="actions" [highlightPrimaryAction]="true" (handleAction)="handleAction($event)">
  <heading [icon]="IconTypes.Bucket" [color]="uiColors.Blue">
    <h4 class="heading-title">Create a bucket</h4>
  </heading>

  <app-grid-container [columns]="'repeat(2, 1fr)'" [margin]="'2rem'" [rowGap]="'2rem'">
    <app-date-picker [date]="delivery_date" (changeDate)="dateChanged($event)"></app-date-picker>
    <div class="shift-selector">
      <app-shift-selector  class="app-shift-selector" (changeShift)="changeShift($event)">
      </app-shift-selector>
      <div class="warehouse-selector">
        <app-dropdown
          *ngIf="warehouse_choice"
          [activeItem]="selected_warehouse ? selected_warehouse.name : 'Select a Warehouse'"
          [dropdownTheme]="'light'"
          [labelTheme]="'dark'"
          [label]="'Warehouse'"
        >
          <div dropdownRef>
            <a *ngFor="let warehouse of warehouses" class="dropdown-item" (click)="selected_warehouse = warehouse">{{
              warehouse.name
            }}</a>
          </div>
        </app-dropdown>
      </div>
    </div>
  </app-grid-container>
</app-modal>
