// Shared Helper Functions

// Checks if a given value is of the supplied type
// eg:  value: 'some string', type: 'String' would return true.
// @param {any} value - the item to check
// @param {string} type - the string type of the required value/item (ie 'string' or 'number' or 'object')
import {UiColors} from '../interfaces/ui.interfaces';

export const checkType = (value, type): boolean => {
  const typeTitleCase = type.charAt(0).toUpperCase() + type.substr(1).toLowerCase();
  return Object.prototype.toString.call(value) === `[object ${typeTitleCase}]`;
};

// Checks if the given value is a number
//  if true: returns the given value
//  if false: returns 0
export const safeNumber = (val: number): number => {
  const _val = Number(val);

  if (isNaN(_val)) { // Number(null) already returns 0;
    return 0;
  }

  return _val;
};

// Safely calculates the sum of 2 values.
// This is done by making sure both values are numbers
// Any value that isn't convertible into a number is converted to 0.
export const calcSafeSum = (curr: number, next: number): number => (
  safeNumber(curr) + safeNumber(next)
);

// Original via: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign#Copying_accessors
export const completeAssign = (target, ...sources) => {
  sources.forEach(source => {
    const descriptors = Object.keys(source).reduce((_descriptors, key) => {
      _descriptors[key] = Object.getOwnPropertyDescriptor(source, key);
      return _descriptors;
    }, {});
    // by default, Object.assign copies enumerable Symbols too
    Object.getOwnPropertySymbols(source).forEach(sym => {
      const descriptor = Object.getOwnPropertyDescriptor(source, sym);
      if (descriptor.enumerable) {
        descriptors[sym] = descriptor;
      }
    });
    Object.defineProperties(target, descriptors);
  });
  return target;
};

export const setColorClass = (color): UiColors | string => {
  if (!color || !Object.values(UiColors).includes(color)) {
    return '';
  }

  return color;
};

export const validateEmail = (email: string): boolean => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
};

export const validatePassword = (password: string): boolean => {
  return password.length > 5;
};

export const validatePhoneNumber = (phoneNumber: string): boolean => {
  return phoneNumber.length >= 10;
};
