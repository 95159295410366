<app-section>
  <app-flex-container
    [theme]="UiThemes.Dark"
    [padding]="'2rem'"
    [borderedItems]="true"
    [justifyContent]="JustifyContent.SpaceBetween"
    [borderBottom]="true"
  >
    <app-icon-with-text
      [text]="'DRIVER ASSIGNED ORDERS'"
      [textBold]="true"
      [sizeRem]="1"
      [color]="UiColors.White"
    >
    </app-icon-with-text>
    <div class="controls">
      <app-basic-button
        *ngIf="actingAs.roles.includes(UserRoles.SuperAdministrator)"
        [title]="'Clear Last Mile'"
        [type]="buttonTypes.Warning"
        [active]="false"
        (click)="clearLastMile()"
      ></app-basic-button>

      <div *ngIf="!canTransfer && !canUnassign && orderId">
        <app-basic-button
          [disabled]="true"
          [title]="'End active route to make adjustments'"
          [type]="buttonTypes.Primary"
          [active]="false"
          (click)="transferParcels()"
        ></app-basic-button>
      </div>
      <div *ngIf="(canTransfer || canUnassign) && orderId">
        <app-basic-button
          [disabled]="!canTransfer"
          [title]="'Transfer Parcels'"
          [type]="buttonTypes.Primary"
          [active]="false"
          (click)="transferParcels()"
        >
        </app-basic-button>
        <app-basic-button
          [title]="'Reconcile Parcels'"
          [type]="buttonTypes.Primary"
          [active]="false"
          (click)="reconcileRoute()"
        >
        </app-basic-button>
        <app-basic-button
          [processing]="processing"
          [disabled]="!canUnassign"
          [title]="'Unassign Orders'"
          [type]="buttonTypes.Warning"
          [active]="false"
          (click)="unassignDriver()"
        >
        </app-basic-button>
        <!-- <app-basic-button
          [processing]="processing"
          [disabled]="!canUnassign"
          [title]="'Unassign Parcels'"
          [type]="buttonTypes.Warning"
          [active]="false"
          (click)="unassignParcels()"
        >
        </app-basic-button> -->
      </div>
    </div>
  </app-flex-container>
  <app-fs-business-reference-breakdown
    [context]="'lastMile'"
    [businessReferenceBreakdown]="businessReferenceBreakdown"
  >
  </app-fs-business-reference-breakdown>
</app-section>
