import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'app/auth/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {

  @Output() sendReset = new EventEmitter<string>();
  @Output() goBack = new EventEmitter<void>()

  forgotPasswordFormGroup: FormGroup = new FormGroup({
    emailControl: new FormControl('',[Validators.email, Validators.required])
  });

  constructor(public authService: AuthService){}

  submit(): void {
    if(this.forgotPasswordFormGroup.valid){
      // return data to attempt login
      this.sendReset.emit(this.forgotPasswordFormGroup.controls.emailControl.value);
    }
  }

  back(): void {
    this.goBack.emit();
  }

}
