<heading 
  [icon]="iconTypes.Parcel" 
  [color]="uiColours.Red">
  <h4 class="heading-title">Re-Attempt Parcels</h4>
</heading>
<app-table
  *ngIf="mappedTableData"
  [columns]="parcelTableColumns"
  [dataMap]="parcelTableDataMap"
  [rawData]="mappedTableData"
  [rowsSelectable]="true"
  [bulkActions]="parcelBulkActions"
  (actionTaken)="handleTableActions($event)"
  [zeroDataMessage]="'No Parcels to Re-Attempt'"
>
</app-table>
