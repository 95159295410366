import { Component, Input } from '@angular/core';

export enum ButtonTypes {
  Error = 'error',
  Green = 'green',
  Highlight = 'highlight',
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Warning = 'warning',
  Orange = 'orange',
}

export interface BasicButton {
  type: ButtonTypes;
  title: string;
  active?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  processing?: boolean;
  block?: boolean;
  callback?: any;
}

@Component({
  selector: 'app-basic-button',
  templateUrl: './basic-button.component.html',
  styleUrls: ['./basic-button.component.scss']
})
export class BasicButtonComponent {
  @Input() type: ButtonTypes;
  @Input() title: string;
  @Input() active: boolean;
  @Input() disabled: boolean;
  @Input() hidden: boolean;
  @Input() processing: boolean;
  @Input() block = false;

  buttonTypes = ButtonTypes;
}
