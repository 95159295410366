import { MaintenanceService } from './../../../../maintenance.service';
import { ButtonTypes } from './../../../../shared/buttons/basic-button.component';
import { ActingAs, UserRoles } from './../../../../interfaces/auth.interfaces';
import { selectorActingAs } from './../../../../auth/auth.reducer';
import { Observable } from 'rxjs';
import { Component, Input, OnChanges, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { ManageBucketService } from '../../manage-bucket.service';

// Reducers
import { selectorActiveBucketId } from '../../manage-bucket.reducer';
import { selectorUser } from '../../../../auth/auth.reducer';

// Services
import { Store } from '@ngrx/store';
import { TripsService } from '../../../trips.service';
import { TripCostsService } from '../trip-costs/trip-costs.service';

// Interfaces
import { Bucket, BucketState, BucketStates, BucketStatesUi, BucketStatuses, FSBucketTableColumns, FSBucketTableDataMap } from '../../../buckets/buckets.interfaces';
import { TableColumn, TableDataMap } from '../../../../shared/table/table.interfaces';
import { TripsTableData } from '../trip-costs/trip-costs.interfaces';
import { IconTypes } from '../../../../shared/icon/icon.interfaces';
import { Business } from '../../../../interfaces/businesses.interfaces';
import { UiColors } from '../../../../interfaces/ui.interfaces';

// Constants & Helpers
import { tripsTableColumns, confirmationPicupTablePrimaryActions } from '../trip-costs/trip-costs.constants';

import { OrderSelectionService } from '../order-selection/order-selection.service';
import { ManageBusinessService } from '../../../manage-business/manage-business.service';
import { Order_Keys, OrdersTableColumns } from '../order-selection/order-selection.interfaces';
import { AuthUser } from '../../../../interfaces/auth.interfaces';
import { SimpleModalService } from 'ngx-simple-modal';
import { ConfirmModalComponent } from 'app/shared/modals/confirm-modal.component';
import { environment } from 'environments/environment';
import { FSOrderTableColumns, FSOrderTableDataMap } from 'app/dashboard/post-dispatch/post-dispatch.constants';
import { ConfirmInputModalComponent } from 'app/shared/modals/confirm-input-modal/confirm-input-modal.component';
import moment from 'moment';
import { Order } from 'app/shared/shared.interfaces';

// Format Function for mapping data to the table object
export const formatGetLastEvent: (map: string[] | number[], item: any) => string | number = (
  map: string[] | number[],
  item: any
) => {
  return item[map[0]].slice(-1)[0].description;
};

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit, OnChanges, OnDestroy {
  @Input() state: BucketState;
  @Input() readOnly = false;
  @Output() processingDispatch = new EventEmitter<boolean>(false);

  processing;
  downloading = false;

  bucketFirestoreTrips$: Observable<Order[]>;

  environment = environment;
  business: Business;
  @Input() bucket: Bucket;
  user: AuthUser;
  bucketId: string;
  waybillOptions = [];
  tripsheetOptions = [
    {
      name: 'csv',
      displayName: 'CSV (Spreadsheet)',
    },
    {
      name: 'pdf',
      displayName: 'PDF (Printable)',
    },
  ];
  blockSameDayBucket = false;
  public IconTypes = IconTypes;
  public UiColors = UiColors;
  public UserRoles = UserRoles;
  public ButtonTypes = ButtonTypes;

  // Status for the Create Picups call.
  creatingPicups = false;
  picupsCreated = false;

  // Copy of the bucket statuses enum for use in the view.
  public bucketStatuses = BucketStatuses;

  // Trips Table setup
  _tripsTableColumns: TableColumn[] = tripsTableColumns;
  _tablePrimaryActions = confirmationPicupTablePrimaryActions;

  trips: TripsTableData = {
    unassigned: [],
    picup: [],
    contract: [],
    courier: [],
    count: 0,
    previouslySaved: false,
    hasFetched: false,
  };

  // Courier Orders Setup
  courierOrderTableMap = this.tripsService.courierTableMap;
  courierOrderColumns = this.tripsService.courierTableColumns;

  // Picups Table setup
  picupTrips: TripsTableData = {
    picup: [],
    contract: [],
    courier: [],
    hasFetched: false,
  };

  orders = {
    unassigned: [],
    hasFetched: false,
  };

  ordersAssigned = false;
  child_buckets = [];

  courierOrderTables;
  couriers;
  fetchedCouriers = false;

  fsOrderTableColumns = FSBucketTableColumns;
  fsOrderTableDataMap = FSBucketTableDataMap;
  BucketStates = BucketStates;

  public postDispatchTableMap = this.tripsService.postDispatchTableMap.inProgress;
  public picupTableColumns = this.tripsService.postDispatchColumns.inProgress;
  postDispatchTablePrimaryActions = this.tripsService.postDispatchTablePrimaryActions;

  public ordersTableColumns: TableColumn[] = [
    { name: OrdersTableColumns.Id, displayName: '#', sortable: true },
    { name: OrdersTableColumns.BusinessReference, displayName: 'Business Reference', sortable: true },
    // { name: OrdersTableColumns.DeliveryDate, displayName: 'Delivery Date', sortable: true },
    { name: OrdersTableColumns.CustomerName, displayName: 'Customer Name', sortable: true },
    { name: OrdersTableColumns.CustomerPhone, displayName: 'Customer Phone', sortable: true },
    { name: OrdersTableColumns.CustomerAddress, displayName: 'Address', sortable: true },
  ];

  public ordersDataMap: TableDataMap = {
    cells: {
      [OrdersTableColumns.Id]: { column: OrdersTableColumns.Id, map: Order_Keys.OrderId },
      [OrdersTableColumns.BusinessReference]: {
        column: OrdersTableColumns.BusinessReference,
        map: Order_Keys.BusinessReference,
      },
      // [OrdersTableColumns.DeliveryDate]: { column: OrdersTableColumns.DeliveryDate, map: Order_Keys.DeliveryDate },
      [OrdersTableColumns.CustomerName]: { column: OrdersTableColumns.CustomerName, map: Order_Keys.CustomerName },
      [OrdersTableColumns.CustomerPhone]: {
        column: OrdersTableColumns.CustomerPhone,
        map: Order_Keys.CustomerPhone,
        displayPipeArgs: ['none'],
      },
      [OrdersTableColumns.CustomerAddress]: {
        column: OrdersTableColumns.CustomerAddress,
        map: Order_Keys.GeocodedAddress,
        annotation_map: [Order_Keys.ClientAddress],
      },
    },
  };

  // Toggle Suburbs on the table
  hideSuburbs = true;

  // Sets the heading for the view on load.
  headingText = 'Trips Selected ' + BucketStatesUi.Confirmation;

  // Subscriptions;
  tripsSubscription;
  picupsSubscription;
  createdPicupsSubscription;
  creatingPicupsSubscription;
  ordersSubscription;
  courierSubscription;
  courierOrdersSubscription;
  courierOrdersBucket;

  tableNameMap = new Map();

  postDispatchSummaryData;

  // Default text for the Create Picups button
  dispatchingButtonText = 'Confirm';

  // SQL Data:
  sqlTrips;

  actingAs: ActingAs;

  constructor(
    public store: Store<any>,
    private tripsService: TripsService,
    private tripCostsService: TripCostsService,
    private orderSelectionService: OrderSelectionService,
    private manageBucketService: ManageBucketService,
    private manageBusinessService: ManageBusinessService,
    private simpleModalService: SimpleModalService,
    private maintenanceService: MaintenanceService
  ) {
    // Need to fix this with flat map maybe?
    this.store.select(selectorActiveBucketId).subscribe((next) => {
      this.bucketId = next;
    });
    this.store.select(selectorUser).subscribe((next) => (this.user = next));
    this.store.select(selectorActingAs).subscribe((next) => (this.actingAs = next));

    // Picups Subscriptions
    this.picupsSubscription = this.tripsService.picupsBucket$.subscribe((observer) => {
      this.picupTrips = !observer ? this.picupTrips : observer;
    });

    this.creatingPicupsSubscription = this.tripsService.creatingPicups$.subscribe((observer) => {
      this.creatingPicups = !observer ? false : observer;
      this.dispatchingButtonText = this.creatingPicups ? 'Dispatching' : this.dispatchingButtonText;
    });

    this.createdPicupsSubscription = this.tripsService.createdSuccessfully$.subscribe((observer) => {
      this.picupsCreated = !observer ? false : observer;
      this.dispatchingButtonText = this.picupsCreated ? 'Dispatched' : this.dispatchingButtonText;
    });

    // Trip Costs Subscription

    // Orders Subscription
    this.ordersSubscription = this.orderSelectionService.orders$.subscribe((observer) => {
      if (!observer) {
        return;
      }
      this.orders.unassigned = observer;
      this.orders.hasFetched = true;
      if (this.orders.unassigned.length > 0) {
        this.assignBucketOrders();
        if (this.fetchedCouriers) {
          this.createCourierTables();
        }
      }
    });
    this.getCouriers();

    this.courierOrdersSubscription = this.tripsService.courierOrdersBucket$.subscribe((observer) => {
      this.courierOrdersBucket = observer || this.courierOrdersBucket;
    });
  }

  getCouriers(): void {
    this.manageBucketService.getCourierDetails(this.bucketId).then((response) => {
      this.couriers = response;
      response?.forEach((courier) => {
        courier?.service_types?.forEach((service_type) => {
          this.tableNameMap.set(
            courier.courier_id + ' - ' + service_type.id.toLowerCase(),
            courier.name + ' - ' + service_type.description.replace(/_/g, ' ')
          );
        });
      });
      this.tableNameMap.set('unassigned', 'ignored');
      this.fetchedCouriers = true;

      this.createCourierTables();
    });
  }

  checkSameDayBucketCutoff() {
    const today = moment().format('YYYY-MM-DD');
    const hour = new Date().getHours();
    if (this.state.status !== BucketStatuses.Completed && this.bucket.delivery_date <= today && hour > 14) {
      this.dispatchingButtonText = 'Today\'s Cutoff Has Already Passed - Please Dispatch Tomorrow';
      this.blockSameDayBucket = true;
    }
  }

  ngOnInit() {
    this.manageBucketService.getFirestoreTrips(parseInt(this.bucketId, 10));
    this.bucketFirestoreTrips$ = this.manageBucketService.bucketFirestoreTrips$;
    if (this.environment.envName !== 'onthedot') {
      this.getWaybillOptions();
    }

    if (this.state && this.state.state === BucketStates.Dispatching) {
      if (this.state.status === BucketStatuses.Completed) {
        this.dispatchingButtonText = 'Dispatched';
        this.headingText = 'Trips ' + BucketStatesUi.Dispatched;
        // this.tripsService.init(true, this.postDispatchTableMap, this.picupTableColumns);
        this.getPostDispatchSummaryData();
        this.manageBusinessService.getCouriers();
        this.orderSelectionService.getCourierOrders();
      } else {
        this.tripCostsService.init();
        this.manageBusinessService.getCouriers();
        this.orderSelectionService.getCourierOrders();
        this.tripsSubscription = this.tripCostsService.trips$.subscribe((observer) => {
          if (observer) {
            this.trips = observer;
          }
        });
      }
    }
  }

  getPostDispatchSummaryData() {
    this.manageBucketService.getPostDispatchSummary(true).then((res) => {
      this.postDispatchSummaryData = res;
    });
  }

  ngOnChanges() {
    if (
      ((this.bucket && this.actingAs.id === 'business-8944f769-bdba-4496-85b3-085c0ce16959') ||
        (this.bucket && this.actingAs.id === 'business-52d1a2a3-9847-42e7-97ae-c3d6f4942d33')) &&
      !this.user.is_admin
    ) {
      this.checkSameDayBucketCutoff();
    }
    if (this.state && this.state.state === BucketStates.Dispatching) {
      if (this.state.status === BucketStatuses.Completed) {
        this.dispatchingButtonText = 'Dispatched';
        this.headingText = 'Trips ' + BucketStatesUi.Dispatched;
      } else {
        if (environment.appVersion === 1) {
          this.tripCostsService.init();
        }
      }
    }
  }

  ngOnDestroy() {
    this.orderSelectionService.clearCourierOrders();
    this.tripCostsService.clearTrips();
    this.tripsService.stopPolling = true;
    this.picupsSubscription.unsubscribe();
    if (this.tripsSubscription) {
      this.tripsSubscription.unsubscribe();
    }
    this.creatingPicupsSubscription.unsubscribe();
    this.createdPicupsSubscription.unsubscribe();
    this.ordersSubscription.unsubscribe();

    this.courierOrdersSubscription.unsubscribe();
    this.bucket = null;
    this.picupTrips = null;
    this.bucketId = null;
    this.trips = {
      unassigned: [],
      picup: [],
      contract: [],
      courier: [],
      count: 0,
      previouslySaved: false,
      hasFetched: false,
    };
  }

  private createCourierTables() {
    this.courierOrderTables = [];
    this.courierOrderTables = Object.keys(this.orders)
      .filter((key) => ['hasFetched', 'unassigned'].indexOf(key) === -1)
      .map((key) => {
        return {
          name: key,
          orders: this.orders[key],
        };
      });

    this.courierOrderTables.push({
      name: 'unassigned',
      orders: this.orders.unassigned,
    });
  }

  private assignBucketOrders() {
    [...this.orders.unassigned]?.forEach((order) => {
      if (order.courier_id !== null) {
        if (this.orders[order.courier_id + ' - ' + order.courier_service_type_uid] === undefined) {
          this.orders[order.courier_id + ' - ' + order.courier_service_type_uid] = [];
        }
        this.orders[order.courier_id + ' - ' + order.courier_service_type_uid].push(order);
        this.orders.unassigned = this.orders.unassigned.filter((current) => {
          return current.order_id !== order.order_id;
        });
      }
    });
    this.ordersAssigned = true;
  }

  checkforSLA() {
    this.processing = true;
    const currentDate = new Date().getTime() / 1000;
    if (this.bucket.shift_start_date_time.seconds < currentDate) {
      this.simpleModalService
        .addModal(ConfirmModalComponent, {
          title: 'SLA cannot be guaranteed',
          message:
            'The shift start time for this bucket has already passed. Would you like to go ahead and dispatch anyway?',
        })
        .subscribe((isConfirmed) => {
          if (!isConfirmed) {
            this.processing = false;
            return;
          }
          this.createSQLtrips();
        });
    } else {
      this.createSQLtrips();
    }
  }

  createSQLtrips() {
    this.processingDispatch.emit(true);
    this.tripsService.createSQLTrips().then(() => {
      this.processing = false;
      this.processingDispatch.emit(false);
    });
  }

  deleteOrders() {
    this.simpleModalService
      .addModal(ConfirmInputModalComponent, {
        title: 'Delete Orders',
        message:
          'Are you sure you wish to delete these orders, this cannnot be undone. To confirm, please type "delete" as shown',
        key: 'delete',
      })
      .subscribe((result) => {
        if (!result) {
          return;
        }
        this.maintenanceService.deleteOrdersForBucket(parseInt(this.bucketId, 10));
      });
  }

  downloadTripSheet($event) {
    this.downloading = true;
    this.manageBucketService.downloadTripSheet($event, this.bucketId).then(() => (this.downloading = false));
  }

  downloadDefaultWaybill() {
    this.downloading = true;
    const template_object = this.waybillOptions[0].name;
    let waybill_name;
    switch (true) {
      case template_object.has_picup_template && template_object.has_courier_template:
        waybill_name = template_object.template_name + ' - Both';
        break;
      case template_object.has_picup_template:
        waybill_name = template_object.template_name + ' - Picup';
        break;
      case template_object.has_courier_template:
        waybill_name = template_object.template_name + ' - Courier';
        break;
    }
    const APIData = {
      template_id: template_object.template_id,
      print_picup: template_object.has_picup_template,
      print_courier: template_object.has_courier_template,
      print_small_template: !template_object.has_picup_template,
      template_name: template_object.template_name,
      displayName: waybill_name,
    };
    this.manageBucketService.downloadWaybills(APIData).then(() => (this.downloading = false));
  }

  downloadWaybills(template_object) {
    this.downloading = true;
    let APIData;
    if (template_object === 'child_buckets') {
      APIData = {
        bucket_ids: this.child_buckets,
        template_id: -1,
      };
    } else {
      APIData = {
        template_id: template_object.template_id,
        print_picup: template_object.has_picup_template,
        print_courier: template_object.has_courier_template,
        print_small_template: template_object.print_small_template,
        template_name: template_object.template_name,
        displayName: template_object.has_picup_template
          ? template_object.template_name + ' - Picup'
          : template_object.template_name + ' - Courier',
      };
    }
    this.manageBucketService.downloadWaybills(APIData).then(() => (this.downloading = false));
  }

  handleConfirmationsTableActions($event) {
    this.manageBucketService.handleConfirmationTableActions($event);
  }

  handleTableCourierDispatchActions($event) {
    this.tripsService.handleTableCourierOrderActions($event);
  }

  handleTablePostDispatchActions($event) {
    this.tripsService.handleTablePostDispatchActions($event);
  }

  handleSQLTableActions($event) {
    this.tripsService.handleSQLTableActions($event);
  }

  getWaybillOptions() {
    this.manageBucketService.getWaybillOptions().then((response) => {
      response?.templates?.forEach((template) => {
        const option = {
          name: {
            ...template,
          },
          displayName: template.has_picup_template
            ? template.template_name + ' - Picup'
            : template.template_name + ' - Courier',
        };

        this.waybillOptions.push(option);
      });

      if (response.child_buckets.length > 0) {
        this.waybillOptions.push({ name: 'child_buckets', displayName: 'Child Buckets' });
        response?.child_buckets?.forEach((bucket) => {
          this.child_buckets.push(bucket.bucket_id);
        });
      }
    });
  }
}
