import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-tree-graph',
  templateUrl: './tree-graph.component.html',
  styleUrls: ['./tree-graph.component.scss']
})
export class TreeGraphComponent implements OnInit {

  constructor() { }

  @Input() data;
  @Input() noDataMessage;
  @Input() showLegend = true;
  
  ngOnInit() {
  }
}
