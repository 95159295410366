<div class="static-banner" [ngStyle]="{ 'background-image': 'url(' + backgroundImgUrl + ')' }">
  <div class="overlay" [style.background]="backgroundOverlay"></div>
  <div class="content">
    <h2>{{ textTitle }}</h2>
    <p>{{ textContent }}</p>
    <ng-content></ng-content>
    <app-slider-button *ngIf="ctaIcon" class="login-button" [style.marginRight.px]="30" routerLink="/login" [theme]="ctaTheme"
      [iconType]="ctaIcon" [labelText]="ctaText">
    </app-slider-button>
  </div>
  <img src="" alt="" />
  <div class="curve" *ngIf="showCurve">
    <img src="/assets/img/swoo.png" alt="" />
  </div>
</div>
