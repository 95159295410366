export interface GetWarehouseDetailsResponse {
  warehouse_details: WarehouseDetails
}

export interface WarehouseDetails {
  address: string;
  country: string;
  country_short_code: string;
  email: string;
  latitude: number;
  longitude: number;
  name: string;
  phone: string;
  postal_code: string;
  suburb: string;
}

export enum DriverFilterTypeEnum {
  PicupPlus = 'Picup Plus',
  Contractor = 'Contractor',
  InTransit = 'In-transit',
  None = 'None',
}

export interface GetDriversResponse {
  can_see_earnings: boolean;
  email: string;
  id: string;
  is_activated: boolean;
  name: string;
  phone: string;
  vehicle: string;
}

export interface Driver {
  id: string;
  is_activated: boolean;
  name: string;
  phone: string;
  latitude?: number;
  longitude?: number;
  vehicle: string;
  online?: boolean;
  orderCount?: number;
  onTrip?: boolean;
  distanceToWarehouse?: string;
  parcelCount?: number;
  fleet?: string;
  todaysOrders?: number;
  businesses?: string[];
  lastMileId?: string;
  lastMileLastEvent?: string;
  parcelsDelivered?: number;
  roundTripWarehouse?: DriverWarehouse
  businessesOnLastMile?: string[]
}

export interface DriverWarehouse {
  warehouseId: string;
  warehouseName: string;
}

export interface DriverDetails {
  name: string;
  phone: string;
  email: string;
  acting_as: number;
  fleets: string[];
}

export interface DriverSearchResponse {
  name: string;
  email: string;
  phone: string;
  _id: string;
}

export interface OrderStats{
  parcelsDelivered: number;
}
