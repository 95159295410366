<app-modal
  [open]="true"
  [actions]="actions"
  [highlightPrimaryAction]="true"
  [size]="ModalSizes.Small"
  (handleAction)="handleAction($event)"
>
  <heading [icon]="IconTypes.Alert" [color]="UiColors.Orange">
    <h4 class="heading-title">{{ title }}</h4>
    <p *ngIf="description" class="heading-description">{{ description }}</p>
  </heading>

  <div class="modal-body">
    <p class="description">{{ message }}</p>
    <app-flex-container class="buttons" [justifyContent]="justifyContent.Center">
      <app-basic-button
        class="control-button"
        [title]="allow_false ? 'Yes' : 'Confirm'"
        [type]="buttonTypes.Primary"
        (click)="handleButton(true)"
      >
      </app-basic-button>
      <app-basic-button
        *ngIf="allow_false"
        class="control-button"
        [title]="'No'"
        [type]="buttonTypes.Primary"
        (click)="handleButton(false)"
      >
      </app-basic-button>
    </app-flex-container>
  </div>
</app-modal>
