<!--Lottery Running Orders -->
<!-- TODO [activeWarehouseFilter]="activeWarehouseFilter" -->
<app-collapsible-order-table
*ngIf="lotteryRunningFirestoreTrips | async as lotteryRunningTrips"
[tripType]="'Lottery Trips'"
[trips]="lotteryRunningTrips"
[tripsTotal]="lotteryRunningTotal"
[objectType]="CollapsibleTableTypeEnum.Order"
[icon]="Icons.Types.Trip"
[color]="Icons.Colors.Green"
[tableDataMap]="fsOrderTableDataMap"
[tableColumns]="fsOrderTableColumns"
[primaryActions]="postDispatchTablePrimaryActions"
[defaultDisplayAmount]="lotteryRunningAmount"
[showPagination]="true"
(paginateEvent)="handleLotteryRunningTrips($event)"
(tableSortChanged)="handleTripsSortChange($event)"
>
</app-collapsible-order-table>
