import { MapMarkerColors } from 'app/interfaces/ui.interfaces';

export interface StartEndPointAddress{
  startAddress?: FullAddressModel;
  endAddress?: FullAddressModel;
}

export interface FormattedAddress{
  address: string;
  lat: string;
  lng: string;
  warehouseId?: string;
}

export interface FullAddressModel extends FormattedAddress {
  address: string;
  lat: string;
  lng: string;
  warehouseId?: string;
  reason?: string; // title
}

export interface mapMarker{
  latitude: string,
  longitude: string,
  status: MapMarkerColors,
  label: string,
}

export enum TaskType {
  start = 'start',
  end = 'end'
}
