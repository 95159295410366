<div class="stats" *ngIf="dailySummaryStats">
  <div class="stats-header">
    <h3>
      {{ date.value | date: 'mediumDate' }} Summary
      <mat-icon matTooltip="These statistics are representative of the data in the table below">help</mat-icon>
    </h3>
    <div>
      <mat-form-field appearance="standard">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input />
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>Choose a date</mat-label>
        <input matInput [matDatepicker]="picker" (dateChange)="getTodaysSummary(date.value)" [formControl]="date" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="charts">
    <ngx-charts-advanced-pie-chart [label]="'Total Parcels'" [view]="[400, 170]" [results]="pieChartData">
    </ngx-charts-advanced-pie-chart>
    <div class="total-orders">
      <h3>Orders</h3>
      <h3>{{ dataSource.data.length }}</h3>
    </div>
  </div>
</div>

<table mat-table matSort [dataSource]="dataSource" [trackBy]="trackBy">
  <ng-container matColumnDef="customer_reference">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Reference</th>
    <td mat-cell *matCellDef="let row">{{ row.customer_reference }}</td>
  </ng-container>

  <ng-container matColumnDef="progress">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Progress</th>
    <td mat-cell *matCellDef="let row">{{ row.progress }}</td>
  </ng-container>

  <ng-container matColumnDef="assigned">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Assigned</th>
    <td mat-cell *matCellDef="let row">{{ row.assigned }}</td>
  </ng-container>

  <ng-container matColumnDef="collected">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Collected</th>
    <td mat-cell *matCellDef="let row">{{ row.collected }}</td>
  </ng-container>

  <ng-container matColumnDef="failed_collection">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Failed Collection</th>
    <td mat-cell *matCellDef="let row">{{ row.failed_collection }}</td>
  </ng-container>

  <ng-container matColumnDef="delivered">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Delivered</th>
    <td mat-cell *matCellDef="let row">{{ row.delivered }}</td>
  </ng-container>

  <ng-container matColumnDef="failed_delivery">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Failed Delivery</th>
    <td mat-cell *matCellDef="let row">{{ row.failed_delivery }}</td>
  </ng-container>

  <ng-container matColumnDef="returned">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Returned</th>
    <td mat-cell *matCellDef="let row">{{ row.returned }}</td>
  </ng-container>

  <ng-container matColumnDef="cancelled">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Cancelled</th>
    <td mat-cell *matCellDef="let row">{{ row.cancelled }}</td>
  </ng-container>

  <ng-container matColumnDef="failed_return">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Failed Return</th>
    <td mat-cell *matCellDef="let row">{{ row.failed_return }}</td>
  </ng-container>

  <ng-container matColumnDef="updated_at">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Updated</th>
    <td mat-cell *matCellDef="let row">{{ row.updated_at | date: 'short' }}</td>
  </ng-container>

  <ng-container matColumnDef="pending">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Pending</th>
    <td mat-cell *matCellDef="let row">{{ row.pending }}</td>
  </ng-container>

  <ng-container matColumnDef="completed">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Completed</th>
    <td mat-cell *matCellDef="let row">{{ row.completed }}</td>
  </ng-container>

  <ng-container matColumnDef="driver_name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Driver</th>
    <td mat-cell *matCellDef="let row">{{ row.driver_name }}</td>
  </ng-container>

  <ng-container matColumnDef="final_collection_attempt_failed">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Final Collection Failed</th>
    <td mat-cell *matCellDef="let row">{{ row.final_collection_attempt_failed }}</td>
  </ng-container>

  <ng-container matColumnDef="failed">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Failed</th>
    <td mat-cell *matCellDef="let row">{{ row.failed }}</td>
  </ng-container>

  <ng-container matColumnDef="total">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Total</th>
    <td mat-cell *matCellDef="let row">{{ row.total }}</td>
  </ng-container>

  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let row"><mat-icon (click)="viewOrder(row.order_id)">visibility</mat-icon></td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr (dblClick)="viewOrder(row.order_id)" mat-row *matRowDef="let row; columns: displayedColumns"></tr>

  <!-- Row shown when there is no matching data. -->
  <!-- <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
    </tr> -->
</table>
<mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of trips"></mat-paginator>
<div class="loader-container" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>
