import { SortDirection, TableDataByColumn, TableRow, TableSortData } from './table.interfaces';

export const compareNumbers = (curr: number, next: number, direction: SortDirection): number => {
  if (direction === SortDirection.ASC) {
    return curr - next;
  } else {
    return next - curr;
  }
};

export const compareStrings = (curr: string, next: string, direction: SortDirection): -1 | 0 | 1 => {
  const currUpper = curr ? curr.toUpperCase() : '';
  const nextUpper = next ? next.toUpperCase() : '';

  if (currUpper < nextUpper) {
    return direction === SortDirection.ASC ? -1 : 1;
  }

  if (currUpper > nextUpper) {
    return direction === SortDirection.ASC ? 1 : -1;
  }

  return 0;
};

export const compareAscending = (val1: TableSortData, val2: TableSortData): number => {
  const curr = Number(val1.value);
  const next = Number(val2.value);

  if (isNaN(curr) || isNaN(next)) {
    return compareStrings(val1.value as string, val2.value as string, SortDirection.ASC);
  } else {
    return compareNumbers(curr, next, SortDirection.ASC);
  }
};

export const compareDescending = (val1: TableSortData, val2: TableSortData): number => {
  const curr = Number(val1.value);
  const next = Number(val2.value);

  if (isNaN(curr) || isNaN(next)) {
    return compareStrings(val1.value as string, val2.value as string, SortDirection.DES);
  } else {
    return compareNumbers(curr, next, SortDirection.DES);
  }
};

export const sortColumn = (
  tableData: TableRow[],
  columnData: TableDataByColumn[],
  columnToSort: string,
  direction: SortDirection
): TableRow[] => {
  const mapped = columnData.find((item) => item.column === columnToSort).sortData;

  if (direction === SortDirection.ASC) {
    mapped.sort(compareAscending);
  } else {
    mapped.sort(compareDescending);
  }

  return mapped.map((el) => {
    return tableData.find((item) => item.index === el.index);
  });
};
