import { Component, OnInit, Input } from '@angular/core';
import { UiThemes } from 'app/interfaces/ui.interfaces';
import { IconTypes } from '../icon/icon.interfaces';

@Component({
  selector: 'app-three-grids',
  templateUrl: './three-grids.component.html',
  styleUrls: ['./three-grids.component.scss']
})
export class ThreeGridsComponent implements OnInit {
  UiThemes = UiThemes;
  IconTypes = IconTypes;

  @Input() firstImgSrc: string;
  @Input() firstTitle: string;
  @Input() firstSubtitle: string;
  @Input() firstImgSrc2: string;

  @Input() secondImgSrc: string;
  @Input() secondTitle: string;
  @Input() secondSubtitle: string;
  @Input() secondImgSrc2: string;
  @Input() routerLink: string;
  @Input() routerLink2: string;
  @Input() routerLink3: string;

  @Input() thirdImgSrc: string;
  @Input() thirdTitle: string;
  @Input() thirdSubtitle: string;
  @Input() thirdImgSrc2: string;
  @Input() backgroundColor: string = 'green';
  constructor() { }

  ngOnInit(): void {
  }

}
