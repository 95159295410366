import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { PaystackSavedCard, CardTypes } from '../../payment.interfaces';
import { PaystackService } from '../paystack.service';

@Component({
  selector: 'app-saved-paystack-cards',
  templateUrl: './saved-paystack-cards.component.html',
  styleUrls: ['./saved-paystack-cards.component.scss'],
})
export class SavedPaystackCardsComponent implements OnInit {
  IconTypes = IconTypes;
  ButtonTypes = ButtonTypes;
  UiThemes = UiThemes;
  UiColors = UiColors;
  cardTypes = CardTypes;

  @Input() showPayNow: boolean = true;
  @Input() userId: string;
  @Input() businessId: string;
  @Input() savedCards: PaystackSavedCard[];
  @Input() loadingPayment: boolean = false;
  @Output() cardSelected: EventEmitter<string> = new EventEmitter<string>();
  @Output() cardsLoaded: EventEmitter<string> = new EventEmitter<string>();
  loading: boolean = false;

  constructor(private paystackService: PaystackService) {}

  ngOnInit(): void {
    this.loading = true;
    this.getSavedCards();
  }

  getSavedCards(): void {
    this.paystackService.getSavedPaystackCards(this.userId, this.businessId).then((res) => {
      this.savedCards = res.Data;
      this.loading = false;
      this.cardsLoaded.emit(res.Data);
    });
  }

  selectCard(authCode): void {
    this.cardSelected.emit(authCode);
  }

  deleteSavedCard(authCode): void {
    this.paystackService.deleteSavedCardPaystackCard(authCode).then(() => this.getSavedCards());
  }
}
