<nav>
    <app-flex-container [justifyContent]="justifyContent.SpaceBetween" [padding]="'1rem'">
        <div class="left">
            <app-slider-button 
                routerLink='/' 
                *ngIf="showHome" 
                [theme]="uiThemes.Home" 
                [iconType]="IconTypes.Warehouse"
                [labelText]="'Home'">
            </app-slider-button>
            <app-slider-button  
                routerLink='/drive' 
                *ngIf="!showHome" 
                [iconType]="IconTypes.Steering" 
                [theme]="uiThemes.Home"
                [labelText]="'Drive'"></app-slider-button>
        </div>
        <div class="right">
            <app-slider-button 
                (click)="navOpen = !navOpen" 
                [iconType]="IconTypes.Menu" 
                [theme]="uiThemes.Home"
                [labelText]="'Menu'">
            </app-slider-button>
        </div>
    </app-flex-container>
</nav>

<div class="mobile-nav" *ngIf="!navOpen">
    <app-icon-button [icon]="IconTypes.Menu" [iconActive]="IconTypes.BackArrow" [color]="UiColors.Grey9"
      [paddingRem]="1" (onClick)="navOpen = !navOpen"></app-icon-button>

</div>

<div class="overlay-nav" [class.show]="navOpen" *ngIf="navOpen">
    <div class="close">

    </div>
    <div class="content">
        <div class="close">
            <app-icon class="item" (click)="navOpen = !navOpen" [icon]="IconTypes.Cross" [size]="1.5"
                [color]="UiColors.Blue"></app-icon>

        </div>

        <app-flex-container [flexWrap]="'wrap'" [padding]="'2rem 0rem'" [justifyContent]="justifyContent.SpaceBetween">
            <ul>
                <li [routerLink]="'/'"><span>00</span>
                    <h2>Home</h2>
                </li>
                <li [routerLink]="'/packages'"><span>01</span>
                    <h2>Packages</h2>
                </li>
                <!-- <li [routerLink]="'/'"><span>02</span>
                    <h2>Features</h2>
                </li> -->
                <li [routerLink]="'/drive'"><span>02</span>
                    <h2>Drive</h2>
                </li>
                <li [routerLink]="'/contact'"><span>03</span>
                    <h2>Contact Us</h2>
                </li>
            </ul>
            <h4>Your<br>delivery<br>management<br>App</h4>
        </app-flex-container>
        <div class="social">
            <app-icon class="item" [icon]="IconTypes.Up" [size]="1.5" [color]="UiColors.Blue"></app-icon>
            <app-icon class="item" [icon]="IconTypes.Profile" [size]="1.5" [color]="UiColors.Blue"></app-icon>
            <app-icon class="item" [icon]="IconTypes.Business" [size]="1.5" [color]="UiColors.Blue"></app-icon>
        </div>
    </div>
</div>