import { Component, Input, OnChanges } from '@angular/core';
import { BucketState, BucketStates, BucketStatuses } from '../../../buckets/buckets.interfaces';

@Component({
  selector: 'app-costing',
  templateUrl: './costing.component.html',
  styleUrls: ['./costing.component.scss'],
})
export class CostingComponent implements OnChanges {
  @Input() state: BucketState;

  title: string;
  status: string;
  message: string;
  progress: number;
  error: string;

  ngOnChanges() {
    this.title = this.state.state === BucketStates.PollingRoutific ? 'Optimising Routes' : this.state.state;
    this.message = undefined;
    this.progress = this.state.progress;
    this.status = this.state.status;
    this.error = this.state.error;

    switch (this.state.status) {
      case BucketStatuses.Enqueued:
      case BucketStatuses.Processing:
      case BucketStatuses.Polling:
        this.status = BucketStatuses.Processing;
        break;
      case BucketStatuses.Initializing:
        if (this.state.state === BucketStates.Costing) {
          this.message = ' This might take a while.';
        }
        break;
    }
  }
}
