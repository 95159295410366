// Creates the dash array string
// Returns a string with "dash-length:number, dash-space:number"
export const getDashArray = (dashLength, spaceLength): string => {
  return dashLength + ', ' + spaceLength;
};

// Extends the getDashArray,
// Calculates the dash-space to be: The total length of the stroke - The length of the dash
export const getSpinnerDashArray = (spinnerLength, spinnerBgLength): string => {
  return getDashArray (spinnerLength, spinnerBgLength - spinnerLength);
};

// Returns the current length of the loader
// maxLength here would be the total length of the stroke
export const getLoaderLength = (percentageComplete, maxLength): number => {
  return percentageComplete * maxLength;
};
