import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';

// Components
import { DashboardComponent } from './dashboard.component';
import { OverviewComponent } from './buckets/overview/overview.component';
import { UploadOrdersComponent } from './upload-orders/upload-orders.component';
import { ManageBucketComponent } from '../dashboard/buckets/manage-bucket/manage-bucket.component';
import { ManageBusinessComponent } from './manage-business/manage-business.component';
import { GeocodingComponent } from './geocoding/geocoding.component';
import { UploadDetailsComponent } from './upload-orders/upload-details/upload-details.component';
import { CsvErrorsComponent } from './upload-orders/csv-errors/csv-errors.component';
import { CreateConsignmentComponent } from './upload-orders/create-consignment/create-consignment.component';

import { PlaceOrderComponent } from './place-order/place-order.component';
import { EditConsignmentComponent } from './upload-orders/edit-consignment/edit-consignment.component';
import { GenerateRandomBucketComponent } from './upload-orders/generate-random-bucket/generate-random-bucket.component';
import { BucketsComponent } from './buckets/buckets.component';
import { HomeComponent } from './home/home.component';
import { ManageUserComponent } from './manage-user/manage-user.component';
import { StagedOrdersComponent } from './buckets/staged-orders/staged-orders.component';
import { LastMileComponent } from './last-mile/last-mile.component';
import { LastMileFinancialsComponent } from './last-mile/last-mile-financials/last-mile-financials.component';
import { OperationsTrackingComponent } from 'app/operations-tracking/operations-tracking.component';
import { ReportingComponent } from 'app/reporting/components/reporting.component';
import { AlertsComponent } from 'app/shared/alerts/alerts.component';
import { HomeOverviewComponent } from './home/components/home-overview/home-overview.component';

const routes: Routes = [
  {
    path: 'dashboard',
    data: { breadcrumb: 'Dashboard' },
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', data: { breadcrumb: 'Home' }, component: HomeComponent, children: [
        {
          path: '',
          redirectTo: 'overview',
          data: { breadcrumb: 'Home' },
          pathMatch: 'full'
        },
        {
          path: 'overview',
          data: { breadcrumb: 'Overview' },
          component: HomeOverviewComponent
        }
      ] },
      { path: 'alerts', component: AlertsComponent, data: { breadcrumb: 'Alerts' } },
      {
        path: 'reporting',
        data: { breadcrumb: 'reporting' },
        component: ReportingComponent,
        loadChildren: () => import('../reporting/reporting.module').then((m) => m.ReportingModule),
      },
      {
        path: 'buckets',
        component: BucketsComponent,
        data: { breadcrumb: 'Buckets' },
        children: [
          { path: '', component: OverviewComponent, outlet: 'Buckets', data: { breadcrumb: 'View Buckets' } },
          {
            path: ':id',
            component: ManageBucketComponent,
            outlet: 'Buckets',
            data: { breadcrumb: 'Manage Bucket' },
          },
        ],
      },
      { path: 'staged-orders/:id', component: StagedOrdersComponent, data: { breadcrumb: 'Staged Orders' } },
      {
        path: 'upload-orders',
        data: { breadcrumb: 'Order Upload' },
        component: UploadOrdersComponent,
        children: [
          {
            path: 'create-consignment',
            component: CreateConsignmentComponent,
            outlet: 'UploadOrders',
            data: { breadcrumb: 'Create Consignment' },
          },
          {
            path: 'edit-consignment',
            component: EditConsignmentComponent,
            outlet: 'UploadOrders',
            data: { breadcrumb: 'Edit Consignment' },
          },
          {
            path: 'generate-random-bucket',
            component: GenerateRandomBucketComponent,
            outlet: 'UploadOrders',
            data: { breadcrumb: 'Generate Random Bucket' },
          },
          {
            path: 'upload-details',
            component: UploadDetailsComponent,
            outlet: 'UploadOrders',
            data: { breadcrumb: 'Details' },
          },
          { path: 'csv-errors', component: CsvErrorsComponent, outlet: 'UploadOrders', data: { breadcrumb: 'Errors' } },
        ],
      },
      { path: 'manage-business/:id', data: { breadcrumb: 'Business Management' }, component: ManageBusinessComponent },
      {
        path: 'manage-fleet',
        data: { breadcrumb: 'Manage-Fleet' },
        loadChildren: () => import('./manage-fleet/manage-fleet.module').then((m) => m.ManageFleetModule),
      },
      {
        path: 'operations-tracking',
        data: { breadcrumb: 'Operations Tracking' },
        component: OperationsTrackingComponent,
        loadChildren: () =>
          import('../operations-tracking/operations-tracking.module').then((m) => m.OperationsTrackingModule),
      },
      { path: 'account', data: { breadcrumb: 'Account' }, component: ManageUserComponent },
      {
        path: 'geocoding',
        data: { breadcrumb: 'Geocoding' },
        component: GeocodingComponent,
      },
      {
        path: 'post-dispatch',
        data: { breadcrumb: 'Post Dispatch' },
        loadChildren: () => import('./post-dispatch/post-dispatch.module').then((m) => m.PostDispatchModule),
      },
      {
        path: 'orders',
        data: { breadcrumb: 'Trips' },
        loadChildren: () => import('./new-trips/new-trips.module').then((m) => m.NewTripsModule),
      },
      {
        path: 'courier-orders',
        data: { breadcrumb: 'Courier Orders' },
        loadChildren: () => import('./courier-orders/courier-orders.module').then((m) => m.CourierOrdersModule),
      },
      {
        path: 'last-mile/:id',
        data: { breadcrumb: 'Last Mile' },
        component: LastMileComponent,
        children: [
          {
            path: 'details',
            data: { breadcrumb: 'Details' },
            outlet: 'LastMile',
            component: LastMileComponent,
          },
          {
            path: 'financials',
            data: { breadcrumb: 'Financials' },
            outlet: 'LastMile',
            component: LastMileFinancialsComponent,
          },
        ],
      },
      {
        path: 'advanced-search',
        data: { breadcrumb: 'Advanced Search' },
        loadChildren: () => import('../advanced-search/advanced-search.module').then((m) => m.AdvancedSearchModule),
      },
      { path: 'place-order', data: { breadcrumb: 'Place Order' }, component: PlaceOrderComponent },
      // { path: 'billing', data: { breadcrumb: 'Billing' }, component: BillingViewComponent },
      {
        path: 'settings',
        data: { breadcrumb: 'Settings' },
        loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
      },
      {
        path: 'planning',
        data: { breadcrumb: 'Planning' },
        loadChildren: () => import('../planning/planning.module').then((m) => m.PlanningModule),
      },
      {
        path: 'billing',
        data: { breadcrumb: 'Billing' },
        loadChildren: () => import('../billing/billing.module').then((m) => m.BillingModule),
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
