import { inject, Injectable } from '@angular/core'
import { NotificationsService } from 'app/shared/notifications/notifications.service';
import { BehaviorSubject } from 'rxjs';
import { ErrorResponse } from './error.interceptor';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  private readonly notificationService = inject(NotificationsService);

  expiredPassword$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  set invalidPassword(value: boolean){
    this.expiredPassword$.next(value)
  }
  readonly isExpiredPassword$ = this.expiredPassword$.asObservable();

  invalidOtp$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  set invalidOtp(value: boolean){
    this.invalidOtp$.next(value)
  }
  readonly isInvalidOtp$ = this.invalidOtp$.asObservable();


  handleSpecialError(error: ErrorResponse, type: string): void{
    switch (type) {
      case ErrorTypes[0]:
      {
        this.expiredPassword$.next(true);
        break;
      }
      case ErrorTypes[1]:
      {
        this.notificationService.publish({
          message: 'Password incorrect please check your password and try again',
          type: 'error'
        })
        break;
      }
      case ErrorTypes[2]:
      {
        this.notificationService.publish({
          message: `Auth Error: ${error.Errors[0].Message}.`,
          type: 'error'
        })
        break;
      }
      case ErrorTypes[3]:
      {
        this.notificationService.publish({
          message: 'OTP has been used please request a new one',
          type: 'error'
        })
        this.invalidOtp$.next(true);
        break;
      }
      case ErrorTypes[4]:
      {
        this.notificationService.publish({
          message: 'OTP incorrect please check it and try again or request a new one',
          type: 'error'
        })
        this.invalidOtp$.next(true);
        break;
      }
      case ErrorTypes[5]:
      {
        this.notificationService.publish({
          // ticket number
          message: error.Errors[0].Message,
          type: 'error'
        })
        break;
      }
      case ErrorTypes[6]:
      {
        this.notificationService.publish({
          // ticket number
          message: 'This password has been used before please create a different one',
          type: 'error'
        })
        break;
      }
      case ErrorTypes[7]:
      {
        this.notificationService.publish({
          // ticket number
          message: 'User not found, please check that your email is correct and try again',
          type: 'error'
        })
        break;
      }
      default:
        // ticket number
        this.notificationService.publish({
          message: `An error occurred. Please try again later or contact support with ticket number: ${error.error.TicketNumber}`,
          type: 'error',
        });
        break;
    }
  }
}

export enum ErrorTypes {
  'Security.PasswordExpired',
  'Security.PasswordInvalid',
  'Auth.Firebase',
  'Security.OtpUsed',
  'Security.OtpInvalid',
  'Security.LockedOut',
  'Security.PasswordUsedBefore',
  'User.NotFound'
}
