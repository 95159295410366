import { Action } from '@ngrx/store';
import { Bucket } from './buckets.interfaces';

export const SET_ACTIVE_BUCKET = 'SET_ACTIVE_BUCKET';
export const SET_ACTIVE_BUCKET_ID = 'SET_ACTIVE_BUCKET_ID';
// export const SET_ACTIVE_BUCKETS = 'SET_ACTIVE_BUCKETS';
export const SET_BUCKET_FILTERS = 'SET_BUCKET_FILTERS';
export const CLEAR_BUCKET_FILTERS = 'CLEAR_BUCKET_FILTERS';

export class setActiveBucket implements Action {
  readonly type = SET_ACTIVE_BUCKET;
  constructor(public payload: Bucket) {}
}

export class setActiveBucketId implements Action {
  readonly type = SET_ACTIVE_BUCKET_ID;
  constructor(public payload: string) {}
}

// export class setActiveBuckets implements Action {
//   readonly type = SET_ACTIVE_BUCKETS;
//   constructor(public payload: Bucket[]) {}
// }

export class setBucketFilters implements Action {
  readonly type = SET_BUCKET_FILTERS;
  constructor(public payload: { warehouse; date; type }) {}
}

export class clearBucketFilters implements Action {
  readonly type = CLEAR_BUCKET_FILTERS;
}
