import { VehicleTypes } from 'app/dashboard/trips.enums';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { IconProps, IconTypes } from 'app/shared/icon/icon.interfaces';
import { TableColumn, TableDataMap, TableDataMapIconFormatter } from 'app/shared/table/table.interfaces';

export const mfIconVehicleType: TableDataMapIconFormatter = (
  map: [string, string],
  item: unknown,
  icon: IconProps
): IconProps => {
  switch (item[map[0]]) {
    case VehicleTypes.Car:
      icon.icon = IconTypes.Car;
      icon.color = UiColors.Grey5;
      break;
    case VehicleTypes.MotorCycle:
      icon.icon = IconTypes.Motorbike;
      icon.color = UiColors.Grey5;
      break;
    case VehicleTypes.VanLarge:
      icon.icon = IconTypes.Truck;
      icon.color = UiColors.Grey5;
      break;
    case VehicleTypes.VanSmall:
      icon.icon = IconTypes.SmallVan;
      icon.color = UiColors.Grey5;
      break;
    case VehicleTypes.Freight:
      icon.icon = IconTypes.VehicleFreight;
      icon.color = UiColors.Grey5;
      break;
    default:
      icon.icon = IconTypes.Cross;
      icon.color = UiColors.Grey5;
      break;
  }

  return icon;
};

export const ContractorColumns: TableColumn[] = [
  { name: 'driver_id', displayName: 'Driver ID', hidden: true },
  { name: 'name', displayName: 'Name', sortable: true },
  { name: 'phone', displayName: 'Phone', sortable: true },
  { name: 'email', displayName: 'Email', sortable: true },
  { name: 'vehicle', displayName: 'Vehicle', sortable: true },
];

export const ContractorDataMap: TableDataMap = {
  cells: {
    driver_id: { column: 'Driver ID', map: 'driver_id' },
    name: { column: 'Name', map: 'driver_name' },
    phone: { column: 'Phone', map: 'driver_phone', displayPipeArgs: ['none'] },
    email: { column: 'Email', map: 'driver_email' },
    vehicle: {
      column: 'Vehicle',
      map: '',
      iconMap: ['vehicle_id', 'vehicle_id'],
      iconFormat: mfIconVehicleType,
      iconColor: UiColors.Blue,
      iconSizeRem: 2,
    },
  },
};
