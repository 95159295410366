import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChildren } from '@angular/core';
import { AuthUser } from 'app/interfaces/auth.interfaces';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import moment, { Moment } from 'moment';
import { NotesCategories, notesModel, NotesTypeEnum } from './notes.constants';
import { NotesService } from './notes.service';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
})
export class NotesComponent implements OnInit {
  // New inputs
  @Input() notesDisplay: notesModel[] = [];
  @Input() user: AuthUser;
  @Input() id: string; // Driver, order or bucketId
  @Input() notesTypes: NotesTypeEnum;
  @Input() businessId: string;
  @Input() driverId: string; // for order notes
  @Input() driverName: string;

  @ViewChildren('content') children;
  @Output() notesViewed = new EventEmitter<boolean>();
  public IconTypes = IconTypes;
  public buttonTypes = ButtonTypes;
  public UiThemes = UiThemes;
  uiColors = UiColors;

  notesCategories = NotesCategories;

  showSave = false;
  constructor(private cdRef: ChangeDetectorRef, private notesService: NotesService) {}

  ngOnInit(): void {
    this.markNotesViewed();
  }

  convertTime(timestamp: any): string | Moment {
    if (!timestamp) {
      return 'Now';
    } else {
      return moment(timestamp.seconds, 'X').format('lll');
    }
  }

  createNote(): void {
    this.showSave = true;
    const note_model: notesModel = {
      author_id: this.user.user_id,
      author_name: this.user.name,
      owner: this.businessId ?? null,
      order_id: this.notesTypes === NotesTypeEnum.Order ? this.id :  null,
      bucket_id: this.notesTypes === NotesTypeEnum.Bucket ? this.id :  null,
      driver_id: this.notesTypes === NotesTypeEnum.Driver ? this.id
        :  this.notesTypes === NotesTypeEnum.Order ? this.driverId ?? null
          : null,
      read_by: [this.user.user_id],
      driver_name: this.driverName ?? null,
      origin: this.notesTypes.toString(),
      content: '',
      category: null,
      public: !this.user.is_admin,
    };
    this.notesDisplay.push(note_model);
    this.cdRef.detectChanges();
    this.children.last.nativeElement.focus();
  }

  markNotesViewed(): void {
    this.notesService.markNotesAsViewed();
    this.notesViewed.emit(true);
  }

  saveNote(): void {
    this.notesService.saveNote(this.notesDisplay[this.notesDisplay.length - 1]);
    this.showSave = false;
  }

  setContent(eventTarget: any, index: number): void{
    this.notesDisplay[index].content = eventTarget.innerText
  }
}
