import { SettingsService } from 'app/dashboard/settings/settings.service';
import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Trip, Waypoint, Contact, Address, Parcel } from 'app/dashboard/place-order/trip.interfaces';
import { PlaceOrderService } from 'app/dashboard/place-order/place-order.service';
import { selectorActingAs } from 'app/auth/auth.reducer';
import { Router } from '@angular/router';
import { LotteryOptions } from 'app/dashboard/settings/lottery-options/lottery-options.interface';
import { MatDialog } from '@angular/material/dialog';
import { PaymentTerms } from 'app/interfaces/auth.interfaces';

@Component({
  selector: 'app-one-to-one',
  templateUrl: './one-to-one.component.html',
  styleUrls: ['./one-to-one.component.scss'],
})
export class OneToOneComponent implements OnInit {
  @Input() picupDetails = <Trip>{
    fleet_allocation: 'Picup',
    optimize_waypoints: true,
    should_run_lottery: true,
    sender: <Waypoint>{
      is_valid: false,
      contact: <Contact>{},
      address: <Address>{},
    },
    receivers: new Array<Waypoint>(),
  };
  intention;
  lottery_options: LotteryOptions = {
    should_run_lottery: true,
  };
  paymentRequest = {
    type: null,
    body: null,
    originatingBody: null,
    price: null,
  };
  methodSelected;
  quoteResult;
  phase;
  actingAs: any;
  recreateDetails;
  paymentTerms = PaymentTerms

  constructor(
    private store: Store<any>,
    private placeOrder: PlaceOrderService,
    private router: Router,
    private settingsService: SettingsService,
    public dialog: MatDialog
  ) {
    this.placeOrder.quoteResult.subscribe((quoteResult) => {
      if (quoteResult) {
        this.quoteResult = quoteResult;
        this.paymentRequest.originatingBody = quoteResult;
        this.phase = 'quote';
      } else {
        this.phase = 'details';
      }
    });
    this.store.select(selectorActingAs).subscribe((next) => (this.actingAs = next));
    if (this.recreateDetails) {
      this.picupDetails = this.recreateDetails;
      this.picupDetails.sender.is_valid = true;
      this.picupDetails.receivers.forEach((receiver) => {
        receiver.is_valid = true;
      });
    }
  }

  ngOnInit(): void {
    this.getLotteryOptions();
  }

  selectedMethod(method: any): void {
    this.paymentRequest.type = method.type;
    this.paymentRequest.body = method.requestBody;
    this.paymentRequest.originatingBody = this.quoteResult.data;
    this.paymentRequest.price = method.price;
    if (method.type === 'picup_request') {
      this.intention = 1;
    } else {
      this.intention = 2;
    }
    this.methodSelected = true;
  }

  getLotteryOptions(): void {
    this.settingsService.getLotteryOptions().then((response) => {
      if (!response) {
        this.lottery_options.should_run_lottery = true;
      } else {
        this.lottery_options = response;
      }
      this.picupDetails.should_run_lottery = this.lottery_options?.should_run_lottery;
      if (this.picupDetails.receivers.length === 0) {
        this.clearForm();
      }
    });
  }

  addReceiver(): void {
    this.picupDetails.receivers.push({
      is_valid: false,
      contact: <Contact>{},
      address: <Address>{},
      special_instructions: null,
      parcels: Array<Parcel>(),
    });
    if (this.actingAs.package === 'freight') {
      this.picupDetails.receivers[0].parcels.push({ size: 'parcel-large', reference: 'picup-freight-' + 0 });
    }
  }

  clearForm(): void {
    this.picupDetails.fleet_allocation = this.actingAs.package === 'tms' ? 'Contractor' : 'Picup';
    this.picupDetails.is_round_trip = false;
    this.picupDetails.should_run_lottery = this.lottery_options.should_run_lottery;
    this.picupDetails.customer_ref = null;
    this.picupDetails.receivers = Array<Waypoint>();
    this.picupDetails.sender = <Waypoint>{
      address: <Address>{},
      contact: <Contact>{},
    };
    this.addReceiver();
  }

  back(): void {
    this.phase = 'details';
  }
}
