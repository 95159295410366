import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'environments/environment';

interface CourierDetails {
  Name: string;
  Contact: string;
  Email?: string;
}

@Pipe({
  name: 'courierPhone'
})
export class CourierPhonePipe implements PipeTransform {

  courierDetails: CourierDetails[] = [
    { Name: 'Dawn Wing', Contact: '(021) 659-4000', Email: 'info@dpdlaser.co.za'},
    { Name: '1Fetch', Contact: '+27861112710', Email: 'support@1fetch.co.za'},
    { Name: 'My Courier', Contact: '(021) 951 6454', Email: 'info@mycouriersa.co.za'},
    { Name: 'CourierIT', Contact: '(021) 555-6777', Email: null},
    { Name: 'RAM', Contact: '(021) 958-2600', Email: null},
  ];

  transform(courierName: string): string {
    let contact = this.courierDetails.find((x) => x.Name === courierName)?.Contact;
    if (!contact) {
      contact = environment.supportPhoneNumber;
    }
    return contact;
  }

}
