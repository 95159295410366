<div class="sign-up-container">
    <form [formGroup]="signUpFormGroup">
        <!-- first & last name  -->
        <div class="multi-lines">
            <!-- first name  -->
            <div class="input-style">
                <mat-form-field [appearance]="'standard'" class="form-width" >
                    <mat-label class="label-colour">First Name</mat-label>
                    <input matInput type="text" formControlName="firstNameControl" >
                </mat-form-field>
                <ng-container *ngIf="signUpFormGroup.controls.firstNameControl?.invalid && signUpFormGroup.controls.firstNameControl?.touched">
                    <div *ngIf="signUpFormGroup.controls.firstNameControl?.errors?.required ?? false" 
                        class="validation-text">
                        First name is required
                    </div>
                </ng-container>
            </div>
            <!-- last name  -->
            <div class="input-style">
                <mat-form-field [appearance]="'standard'"  class="form-width">
                    <mat-label class="label-colour">Last Name</mat-label>
                    <input matInput type="text" formControlName="lastNameControl" >
                </mat-form-field>
                <ng-container *ngIf="signUpFormGroup.controls.lastNameControl?.invalid && signUpFormGroup.controls.lastNameControl?.touched">
                    <div *ngIf="signUpFormGroup.controls.lastNameControl?.errors?.required ?? false" 
                        class="validation-text">
                        Last name is required
                    </div>
                </ng-container>
            </div>
        </div>
        <!-- phone  -->
        <mat-form-field [appearance]="'standard'" class="input-style">
            <mat-label class="label-colour">Phone</mat-label>
            <input matInput [type]="'tel'" formControlName="phoneControl" >
        </mat-form-field>
        <ng-container *ngIf="signUpFormGroup.controls.phoneControl?.invalid && signUpFormGroup.controls.phoneControl?.touched">
            <div *ngIf="signUpFormGroup.controls.phoneControl?.errors?.required ?? false" 
                class="validation-text">
                Phone number is required
            </div>
            <div *ngIf="signUpFormGroup.controls.phoneControl?.errors?.invalidNumber ?? false" 
                class="validation-text">
                Phone number is invalid
            </div>
        </ng-container>

        <!-- email  -->
        <mat-form-field [appearance]="'standard'" class="input-style">
            <mat-label class="label-colour">Email</mat-label>
            <input matInput type="email" formControlName="emailControl" >
        </mat-form-field>
        <ng-container *ngIf="signUpFormGroup.controls.emailControl?.invalid && signUpFormGroup.controls.emailControl?.touched">
            <div *ngIf="signUpFormGroup.controls.emailControl?.errors?.required ?? false" 
                class="validation-text">
                Email is required
            </div>
            <div *ngIf="signUpFormGroup.controls.emailControl?.errors?.email ?? false"
                class="validation-text">
                Invalid Email
            </div>
        </ng-container>

        <div class="multi-lines">
            <!-- Password  -->
            <div class="input-style">
                <mat-form-field [appearance]="'standard'" class="form-width">
                    <mat-label class="label-colour">
                        Password 
                    </mat-label>
                    <div class="align-items">
                        <input matInput [type]="hidePassword ? 'password' : 'text'" 
                            formControlName="passwordControl" 
                        >
                        <button mat-icon-button matPrefix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide Password'" [attr.aria-pressed]="hidePassword" type="button">
                            <mat-icon>{{hidePassword ? 'visibility_off': 'visibility'}}</mat-icon>
                        </button>
                    </div>
                </mat-form-field>
                <ng-container *ngIf="signUpFormGroup.controls.passwordControl?.invalid && signUpFormGroup.controls.passwordControl?.touched">
                    <div *ngIf="signUpFormGroup.controls.passwordControl?.errors?.required ?? false" 
                        class="validation-text">
                        Password is required
                    </div>
                    <div *ngIf="tooltipString" 
                        class="validation-text">
                        <div class="label-error-div" [matTooltip]="tooltipString">
                            Password is invalid                         
                            <mat-icon class="icon-error" 
                                >
                                info_outline
                            </mat-icon>
                        </div>
                    </div>
                </ng-container>
            </div>
            <!-- Confirm -->
            <div class="input-style">
                <mat-form-field [appearance]="'standard'" class="form-width">
                    <mat-label class="label-colour">Confirm</mat-label>
                    <div style="display: flex;">
                        <input matInput [type]="hidePasswordConfirm ? 'password' : 'text'" formControlName="confirmControl" >
                        <button mat-icon-button matSuffix (click)="hidePasswordConfirm = !hidePasswordConfirm" [attr.aria-label]="'Hide Password'" [attr.aria-pressed]="hidePassword" type="button">
                            <mat-icon>{{hidePasswordConfirm ? 'visibility_off': 'visibility'}}</mat-icon>
                        </button>
                </div>
                </mat-form-field>
                <ng-container *ngIf="signUpFormGroup.controls.confirmControl?.invalid && signUpFormGroup.controls.confirmControl?.touched">
                    <div *ngIf="signUpFormGroup.controls.confirmControl?.errors?.passwordMismatch"
                        class="validation-text">
                        Passwords don't match
                    </div>
                </ng-container>
            </div>
        </div>
    </form>
    <div class="button-container">
        <!-- buttons -->
        <div>
            <button class="login-button-style" 
                mat-raised-button 
                [disabled]="signUpFormGroup.invalid || (authService.isCreatingAccount$ | async)"
                (click)="emitSignUp()">                
                <div class="button-content">
                    <svg-icon 
                        *ngIf="authService.isCreatingAccount$ | async"
                        [svgStyle]="{ 'width.rem': '1' }" 
                        src="/assets/img/icons/spinner.svg" 
                        class="spin">
                    </svg-icon>
                    Sign Up
                </div></button>
        </div>
    </div>
</div>