<app-dispatch-alerts #alerts></app-dispatch-alerts>

<app-dispatch-lotteries #completed></app-dispatch-lotteries>

<app-dispatch-failed #failed></app-dispatch-failed>

<app-dispatch-manual #lotteries></app-dispatch-manual>

<app-dispatch-scheduled #manual></app-dispatch-scheduled>

<app-dispatch-completed #scheduled></app-dispatch-completed>
