<app-modal
  [open]="true"
  [highlightPrimaryAction]="true"
  [size]="ModalSizes.Large"
  [actions]="actions"
  (handleAction)="handleAction($event)"
>
  <div class="modal-content">
    <heading [icon]="IconTypes.Parcel" [color]="UiColors.Blue">
      {{ 'Timeline' | uppercase }}
      <app-dropdown [activeItem]="filteredTableData ? filteredTableData[0].parcel_waybill : 'All Parcels'">
        <a class="dropdown-item" (click)="filterParcels(null)">All Parcels</a>
        <div dropdownRef>
          <a *ngFor="let parcel of parcelList" class="dropdown-item" (click)="filterParcels(parcel)">{{ parcel }}</a>
        </div>
      </app-dropdown>
    </heading>
    <div class="modal-body">
      <app-table
        [columns]="trackingTableColumns"
        [dataMap]="trackingTableDataMap"
        [rawData]="filteredTableData ? filteredTableData : tableData"
        [rowsSelectable]="false"
      >
      </app-table>
    </div>
  </div>
</app-modal>
