import { Subscription } from 'rxjs';
import { NewTripsService } from 'app/dashboard/new-trips/new-trips.service';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { HealthStates } from 'app/dashboard/trips.enums';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { JustifyContent } from 'app/shared/flex-container/flex-container.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { MapTypes } from 'app/shared/map-view/map-view.interfaces';
import {
  DisplayPipes,
  TableColumn,
  TableDataMapFormatter,
  TableDataSetHighlightColor,
} from 'app/shared/table/table.interfaces';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';

export const mfDriverResponse: TableDataMapFormatter = (map: any, item: any) => {
  switch (item[map]) {
    case 1:
      return 'Accepted';
    case 2:
      return 'Declined';
    case 3:
      return 'Assigned';
    case 0:
      return 'Awaiting Response';
  }
};

export const mfFleetType: TableDataMapFormatter = (map: any, item: any) => {
  switch (item[map]) {
    case 0:
      return 'Picup';
    case 1:
      return 'Contractor';
    case 2:
      return 'Hybrid';
  }
};

export const mfSetLotteryReponseColor: TableDataSetHighlightColor = (map: string[], item: any) => {
  switch (item[map[0]]) {
    case 0:
      return 'highlight-' + HealthStates.Default;
    case 1:
      return 'highlight-' + HealthStates.Good;
    case 2:
      return 'highlight-' + HealthStates.Bad;
    case 3:
      return 'highlight-' + HealthStates.Primary;
  }
};

@Component({
  selector: 'app-lottery-details',
  templateUrl: './lottery-details.component.html',
  styleUrls: ['./lottery-details.component.scss'],
})
export class LotteryDetailsComponent implements OnInit, OnDestroy {
  @Input() lottery;
  @Input() index;
  @Input() isAdmin: boolean;
  icons = {
    types: IconTypes,
    colors: UiColors,
  };
  IconTypes = IconTypes;
  UiThemes = UiThemes;
  justifyContent = JustifyContent;
  buttonTypes = ButtonTypes;
  timeLeft;
  intervalfn;
  environment = environment;
  showMap = false;
  mapTypes = MapTypes;
  warehouse_waypoint;
  drivers = [];
  startedAt;
  processing = false;
  lotterySeenSubscription: Subscription;

  lotteryTypes = new Map<number, string>([
    [1, 'Picup'],
    [2, 'Contract'],
    [3, 'Picup Plus'],
  ]);

  vehicleTypes = new Map<string, IconTypes>([
    ['vehicle-motorcycle', IconTypes.Motorbike],
    ['vehicle-car', IconTypes.Car],
    ['vehicle-small-van', IconTypes.SmallVan],
    ['vehicle-large-van', IconTypes.SmallVan],
  ]);
  vehicleNames = new Map<string, string>([
    ['vehicle-motorcycle', 'Motorbike'],
    ['vehicle-car', 'Car'],
    ['vehicle-small-van', 'Small Van'],
    ['vehicle-large-van', 'Large Van'],
  ]);

  lotteryColumns: TableColumn[] = [
    { name: 'name', displayName: 'Name' },
    { name: 'phone', displayName: 'Phone' },

    { name: 'fleet', displayName: 'Fleet' },
    { name: 'vehicle', displayName: 'Vehicle' },
    { name: 'distance', displayName: 'Distance Away' },
    { name: 'seen_at', displayName: 'Seen' },
    { name: 'response', displayName: 'Response' },
  ];
  lotteryDataMap;
  lotterySeenObject;

  constructor(private newTripsService: NewTripsService) {}

  calcTime(closes_at) {
    const timeNow = new Date();
    const closingTime = closes_at.toDate();
    const duration = closingTime.getSeconds() - timeNow.getSeconds();
    return duration;
  }

  cancelLottery() {
    this.processing = true;
    this.newTripsService
      .cancelLottery(this.lottery.order_id)
      .then(() => (this.processing = false))
      .catch(() => (this.processing = false));
  }

  ngOnDestroy(): void {
    this.lotterySeenSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.lotterySeenSubscription = this.newTripsService.getLotterySeenObject(this.lottery.id).subscribe((next) => {
      this.lotterySeenObject = next?.drivers_seen;
      this.lotteryDataMap = {
        cells: {
          name: { column: 'name', map: 'name' },
          phone: { column: 'phone', map: 'phone' },

          response: {
            column: 'response',
            map: 'response',
            format: mfDriverResponse,
            highlightColorClass: mfSetLotteryReponseColor,
            highlightColorMap: ['response'],
          },
          fleet: { column: 'fleet', map: 'fleet', format: mfFleetType },
          vehicle: {
            column: 'vehicle',
            map: 'vehicle',
          },
          seen_at: { column: 'seen_at', map: ['id'], format: this.mfGetLotterySeen, displayPipeArgs: ['timeAgo'] },
          distance: { column: 'distance', map: 'distance', displayPipeArgs: DisplayPipes.decimalsTwo, postfix: 'km' },
        },
      };
    });
    this.startedAt = moment(this.lottery.created_at.toDate()).format('HH:mm');
    if (this.lottery.closes_at) {
      this.startTimer(this.lottery.closes_at.toDate());
    }
    this.constructWaypoints();
    this.constructDrivers();
  }
  startTimer(eventime) {
    const eventTime: any = moment(eventime).unix();
    const currentTime: any = moment().unix();
    let leftTime = eventTime - currentTime;
    let duration: any = moment.duration(leftTime, 'seconds');
    const interval = 1000;
    this.intervalfn = setInterval(function () {
      if (leftTime > 0) {
        duration = moment.duration(duration - interval, 'milliseconds');
        this.timeLeft =
          duration.as('seconds') < 10 || (duration.as('seconds') > 60 && duration.as('seconds') < 70)
            ? duration.minutes() + ':0' + duration.seconds()
            : duration.minutes() + ':' + duration.seconds();

        leftTime -= 1;
      } else {
        this.timeLeft = 'Complete';
        clearInterval(this.intervalfn);
      }
    }, interval);
  }
  // 20=waiting, 30=assigning, 40=complete, 50=alert
  showStatus(status) {
    switch (status) {
      case 10:
        return 'Waiting for response';
      case 30:
        return 'Assigning';
      case 40:
        return 'Complete';
      case 50:
        return 'Alert';
      case 60:
        return 'Cancelled';
    }
  }

  toggleMap(): void {
    this.showMap = !this.showMap;
  }

  mfGetLotterySeen: TableDataMapFormatter = (map: string[], item: any) => {
    if (this.lotterySeenObject) {
      const timeStamp: firebase.default.firestore.Timestamp = this.lotterySeenObject[item[map[0]]];
      if (timeStamp) {
        return timeStamp.toMillis();
      } else {
        return null;
      }
    }
  };

  constructDrivers(): void {
    this.drivers = [];
    this.lottery.drivers?.forEach((driver) => {
      this.drivers.push(driver.id);
    });
  }

  constructWaypoints(): void {
    this.warehouse_waypoint = {
      lat: this.lottery.origin.lat,
      lng: this.lottery.origin.lng,
      visit_state: 'Pending',
      index: 0,
      waypoint_type: 'Warehouse',
    };
  }
}
