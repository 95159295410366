import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { IconButtonProps } from './button.interfaces';
import { IconTypes } from '../icon/icon.interfaces';
import { UiColors } from '../../interfaces/ui.interfaces';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent implements OnChanges, IconButtonProps {
  @Input() active = false;
  @Input() toggleActive = false;
  @Input() disabled = false;
  @Input() processing = false;
  @Input() icon: IconTypes;
  @Input() iconActive: IconTypes;
  @Input() iconSizeRem = 2;
  @Input() color: UiColors;
  @Input() colorActive: UiColors;
  @Input() paddingRem: number | number[] = [5,1]; // TODO: Test was [5,1]
  @Output() onClick = new EventEmitter();

  _icon: IconTypes;
  _color: UiColors;

  ngOnChanges() {
    this.iconActive = this.iconActive ? this.iconActive : this.icon;
    this.colorActive = this.colorActive ? this.colorActive : this.color;

    this.setIconAttributes();
  }

  private setIconAttributes(): void {
    if (this.processing) {
      this._icon = IconTypes.Spinner;
      return;
    }

    if (this.active) {
      this._icon = this.iconActive;
      this._color = this.colorActive;
    } else {
      this._icon = this.icon;
      this._color = this.color;
    }
  }

  public handleClick() {
    if (this.disabled) {
      return;
    }

    if (this.toggleActive) {
      this.active = !this.active;
      this.setIconAttributes();
    }

    this.onClick.emit();
  }
}
