<app-modal [open]="true" [actions]="actions" [highlightPrimaryAction]="true" [size]="ModalSizes.Large"
  (handleAction)="handleAction($event)">
  <div class="modal-content">
    <heading [icon]="IconTypes.Phone" [color]="UiColors.Blue">
      <h4 class="heading-title">{{ edit ? 'Edit Contact' : 'Add Contact' }}</h4>
    </heading>

    <div class="modal-body">
      <app-grid-container [columns]="'repeat(2, 1fr)'" [padding]="'0 2rem'" [colGap]="'2rem'" [rowGap]="'0rem'">
        <input-field [type]="'text'" (valueChanged)="address_model.description = $event"
          [value]="address_model.description" [label]="'Description'"></input-field>
        <input-field [type]="'text'" (valueChanged)="address_model.name = $event" [value]="address_model.name"
          [label]="'Name'"></input-field>
      </app-grid-container>

      <app-grid-container [columns]="'repeat(1, 1fr)'" [padding]="'2rem 2rem 0 2rem'" [colGap]="'2rem'"
        [rowGap]="'0rem'">
        <app-places-autocomplete [label]="'Search Address'" (placesResponse)="onPlacesChange($event)"
          [value]="address_model.formatted_address" [placeholder]="''"></app-places-autocomplete>
      </app-grid-container>
      <app-grid-container 
        *ngIf="address_model.latitude" 
        [columns]="'repeat(1, 1fr)'" 
        [colGap]="'2rem'"
        [theme]="UiThemes.Light" 
        [padding]="'2rem 2rem 0 2rem'">
        <google-map
          #map
          width="100%"
          height="200px"
          class="map" 
          id="map" 
          (tilesloaded)="mapElementReady()"
          [options]="mapOptions" 
          [zoom]="14" >
        </google-map>
      </app-grid-container>
      <app-grid-container [columns]="'repeat(2, 1fr)'" [padding]="'2rem'" [colGap]="'2rem'" [rowGap]="'2rem'">
        <input-field [type]="'text'" (valueChanged)="address_model.complex = $event" [value]="address_model.complex"
          [label]="'Business/Complex'"></input-field>
        <input-field [type]="'text'" (valueChanged)="address_model.unit_no = $event" [value]="address_model.unit_no"
          [label]="'Unit/No.'"></input-field>
        <input-field [type]="'tel'" (valueChanged)="address_model.phone = $event" [value]="address_model.phone"
          [label]="'Phone Number'"></input-field>
        <input-field [type]="'text'" (valueChanged)="address_model.email = $event" [value]="address_model.email"
          [label]="'Email'"></input-field>
      </app-grid-container>
      <div class="errors">
        <ul *ngFor="let error of errors">
          <li>
            <h4>{{ error }}</h4>
          </li>
        </ul>
      </div>
    </div>
  </div>
</app-modal>