import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ModalSizes, ModalAction } from '../modals/modal.interfaces';
import { GridJustifyItems } from '../grid-container/grid-container.interfaces';
import { IconTypes } from '../icon/icon.interfaces';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { ButtonTypes } from '../buttons/basic-button.component';

@Component({
  selector: 'app-admin-actions',
  templateUrl: './admin-actions.component.html',
  styleUrls: ['./admin-actions.component.scss']
})
export class AdminActionsComponent extends SimpleModalComponent<any, any> implements OnInit {
  admin_action;
  action_type;
  selected_action;

  public ModalSizes = ModalSizes;
  public GridJustifyItems = GridJustifyItems;
  public IconTypes = IconTypes;
  public uiColors = UiColors;
  public UiThemes = UiThemes;
  public ButtonTypes = ButtonTypes;
  public actions: ModalAction[] = [
    { name: 'cancel', title: 'Cancel' },
    { name: 'confirm', title: 'Confirm', isPrimary: true }
  ];

  constructor() {
    super();
  }

  ngOnInit() {
    if (this.admin_action[0].contact_index || this.admin_action[0].contact_index === 0) {
      this.action_type = 'contact';
    } else {
      this.action_type = 'waypoint';
    }
  }

  selectReason(action) {
    this.selected_action = action + '-' + this.action_type;
  }

  handleAction($event) {
    switch ($event) {
      case 'cancel':
        this.close();
        break;
      case 'confirm':
        this.result = this.selected_action;
        this.close();
    }
  }
}
