<app-modal
  [open]="true"
  [actions]="actions"
  [highlightPrimaryAction]="true"
  [size]="ModalSizes.Large"
  (handleAction)="handleAction($event)"
>
  <div class="modal-content">
    <heading 
      [icon]="IconTypes.Steering" 
      [color]="uiColours.Blue">
      <h4 class="heading-title">Select Driver</h4>
    </heading>
    <div class="modal-body">
      <app-table
        *ngIf="drivers_array.length > 0"
        [columns]="driverTableColumns"
        [dataMap]="driverTableDataMap"
        [rawData]="drivers_array"
        [rowsSelectable]="false"
        [primaryActions]="driverPrimaryActions"
        (actionTaken)="respondToTableAction($event)"
        [zeroDataMessage]="'No Drivers Found'"
      >
      </app-table>
    </div>
  </div>
</app-modal>
