import {Component, Input, OnInit} from '@angular/core';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { SimpleModalComponent } from 'ngx-simple-modal';

export interface Errors {
  errors: Array<string>;
}

@Component({
  selector: 'app-csv-errors',
  templateUrl: './csv-errors.component.html',
  styleUrls: ['./csv-errors.component.scss']
})

export class CsvErrorsComponent extends SimpleModalComponent<Errors, any> implements OnInit {
  @Input() errors: string[];

  uiColors = UiColors;
  iconTypes = IconTypes;
  buttonTypes = ButtonTypes;
  constructor(
  ) {
    super();
  }

  ngOnInit() {
  }

}
