<label class="{{theme === PillInputTheme.Light ? 'pill-input-label-light' : 'pill-input-label'}}">{{label}}</label>
<div class="{{theme}}">
    <div class="pill-list-wrapper">
        <div class="pill" *ngFor="let pill of items; let i = index">
            {{pill}}<button (click)="removeItem(i)" class="pill-clear-button">x</button>
        </div>
        <input-field 
        [disabled]="disabled"
        class="pill-input-field" 
        #input
        type="text"
        [readOnly]="!allowFreeText"
        (focusTriggered)="onFocus($event)"
        (valueChanged)="onValueChanged($event)"
        [placeholder]="items?.length > 0 ? '' : placeholder" 
        (keydown.backspace)="onBackSpacePress($event)" 
        (keydown.enter)="onEnterPress($event)"
        [styles]="'border-radius: 5px'"
        >
        </input-field>
    </div>
    <button *ngIf="items?.length > 0" (click)="clearItems()">x</button>
</div>
<div class="dropdown-container">
    <div *ngIf="isInFocus && filteredDropdownItems?.length > 0" class="dropdown-content">
        <div 
        *ngFor="let item of filteredDropdownItems" 
        class="dropdown-item"
        (click)="addItem(item)">
        {{item.Name}}
        </div>
    </div>
</div>



