import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { UiThemes, UiColors } from 'app/interfaces/ui.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { environment } from 'environments/environment';
import { ModalAction, ModalSizes } from 'app/shared/modals/modal.interfaces';

@Component({
  selector: 'app-add-credits',
  templateUrl: './add-credits.component.html',
  styleUrls: ['./add-credits.component.scss'],
})
export class AddCreditsComponent extends SimpleModalComponent<any, any> implements OnInit {
  amount;
  currency_symbol = environment.currency_symbol;
  public UiThemes = UiThemes;
  Icons = {
    Colors: UiColors,
    Types: IconTypes,
  };

  ModalSizes = ModalSizes;
  IconTypes = IconTypes;
  uiColors = UiColors;
  public actions: ModalAction[] = [
    { name: 'close', title: 'Close' },
    { name: 'confirm', title: 'Confirm', isPrimary: true },
  ];

  constructor() {
    super();
  }

  handleAction($event: string): void {
    switch ($event) {
      case 'close':
        this.close();
        break;
      case 'confirm':
        this.result = this.amount;
        this.close();
        break;
    }
  }

  ngOnInit() {}
}
