import {
  getDeeplyNestedCustomerName,
  lotteryRestartCountFormatter,
  mfGetDriverNamesCommaSeparated,
  mfHandleNoneFleet,
  mfHumanizeString,
  mfRoundTripFormatter,
} from './../../shared/table/map-formatters.table.helpers';
import { HighlightHealthStates } from 'app/dashboard/trips.enums';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { firestoreDateObject } from 'app/shared/table/map-formatters.table.helpers';
import {
  TableColumn,
  TableDataMap,
  TableDataMapFormatter,
  TableDataSetHighlightColor,
} from 'app/shared/table/table.interfaces';
import moment from 'moment';

export const firestorePostDispatchEventNameMapping: TableDataMapFormatter = (map: [string], item: any) => {
  if (FSLastEventMapping.get(item[map[0]])) {
    return FSLastEventMapping.get(item[map[0]]);
  } else {
    return item[map[0]];
  }
};

export const fireStorePostDispatchimeAgoHighlighter: TableDataSetHighlightColor = (
  map: string[],
  item: any
): HighlightHealthStates => {
  const timeAgoSecs = moment(Date.now()).diff(item[map[0]].toMillis(), 'minutes');
  if (timeAgoSecs) {
    if (timeAgoSecs <= 15) {
      return HighlightHealthStates.Good;
    } else if (timeAgoSecs <= 30) {
      return HighlightHealthStates.Caution;
    } else if (timeAgoSecs <= 45) {
      return HighlightHealthStates.Warning;
    } else {
      return HighlightHealthStates.Bad;
    }
  } else {
    return HighlightHealthStates.Primary;
  }
};

export const firestorePostDispatchEventHighlighter: TableDataSetHighlightColor = (
  map: string[],
  item: any
): HighlightHealthStates => {
  return FSLastEventColorHighlightMapping.get(item[map[0]]);
};

export const firestoreCourierPostDispatchEventHighlighter: TableDataSetHighlightColor = (
  map: string[],
  item: any
): HighlightHealthStates => {
  return FSCourierTrackingHighlighter.get(item[map[0]]);
};

export const lotteryRestartCountHighlighter: TableDataSetHighlightColor = (
  map: string[],
  item: any
): HighlightHealthStates => {
  const lotteryCount = item[map[1]] || item[map[1]] >= 0 ? item[map[1]] : item[map[0]];
  if (lotteryCount < 6) {
    return HighlightHealthStates.Good;
  }
  if (lotteryCount <= 10) {
    return HighlightHealthStates.Warning;
  }
  if (lotteryCount > 10) {
    return HighlightHealthStates.Bad;
  }
};

export const FSOrderTableColumns: TableColumn[] = [
  { name: 'trip_id', displayName: 'Trip ID', hidden: true },
  { name: 'customer_reference', displayName: 'Customer Reference', sortable: false },
  { name: 'scheduled_date', displayName: 'Scheduled Date', sortable: true },
  { name: 'status', displayName: 'Status', sortable: false },
  { name: 'warehouse_name', displayName: 'Warehouse', sortable: true },
  { name: 'is_round_trip', displayName: 'Trip Type', sortable: false },
  { name: 'driver_names', displayName: 'Drivers', sortable: false },
  // { name: 'actual_fleet_allocation', displayName: 'Actual Fleet', sortable: false },
  { name: 'planned_fleet_allocation', displayName: 'Planned Fleet', sortable: false },
  { name: 'last_event', displayName: 'Last Event', sortable: true },
  { name: 'last_updated', displayName: 'Last Updated', sortable: true },
  { name: 'parcel_count', displayName: 'Parcels', sortable: false },
];

export const FSOrderTableDataMap: TableDataMap = {
  cells: {
    trip_id: { column: 'trip_id', map: 'Id' },
    is_round_trip: { column: 'is_round_trip', map: 'IsRoundTrip', format: mfRoundTripFormatter },
    customer_reference: { column: 'customer_reference', map: 'CustomerReference' },
    scheduled_date: {
      column: 'scheduled_date',
      map: ['ScheduledDate'],
      format: firestoreDateObject,
      displayPipeArgs: ['date', 'dd LLL HH:mm'],
    },
    status: { column: 'status', map: 'OrderState' },
    warehouse_name: { column: 'warehouse_name', map: 'WarehouseName' },
    driver_names: {
      column: 'driver_names',
      map: ['DriverNames', 'FleetAllocation', 'CourierName'],
      format: mfGetDriverNamesCommaSeparated,
    },
    actual_fleet_allocation: {
      column: 'actual_fleet_allocation',
      map: ['ActualFleetAllocation'],
      format: mfHandleNoneFleet,
    },
    planned_fleet_allocation: {
      column: 'planned_fleet_allocation',
      map: ['PlannedFleetAllocation'],
      format: mfHandleNoneFleet,
    },

    last_event: {
      column: 'last_event',
      map: ['LastEventName'],
      format: firestorePostDispatchEventNameMapping,
      highlighted: true,
      highlightColorMap: ['LastEventName'],
      highlightColorClass: firestorePostDispatchEventHighlighter,
    },
    last_updated: {
      column: 'last_updated',
      map: ['Timestamp'],
      format: firestoreDateObject,
      displayPipeArgs: ['timeAgo'],
    },
    parcel_count: {
      column: 'parcel_count',
      map: ['ParcelCount']
    },
  },
};

export const FSActiveOrderTableColumns: TableColumn[] = [
  { name: 'trip_id', displayName: 'Trip ID', hidden: true },
  { name: 'customer_reference', displayName: 'Customer Reference', sortable: false },
  { name: 'scheduled_date', displayName: 'Scheduled Date', sortable: true },
  { name: 'lottery_count', displayName: 'Total Lottery Attempts', sortable: false },
  { name: 'warehouse_name', displayName: 'Warehouse', sortable: true },
  { name: 'is_round_trip', displayName: 'Trip Type', sortable: false },
  { name: 'driver_names', displayName: 'Drivers', sortable: false },
  { name: 'actual_fleet_allocation', displayName: 'Actual Fleet', sortable: false },
  { name: 'planned_fleet_allocation', displayName: 'Planned Fleet', sortable: false },
  { name: 'last_event', displayName: 'Last Event', sortable: true },
  { name: 'last_updated', displayName: 'Last Updated', sortable: true },
  { name: 'parcel_count', displayName: 'Parcels', sortable: false },
];

export const FSActiveOrderTableDataMap: TableDataMap = {
  cells: {
    trip_id: { column: 'trip_id', map: 'Id' },
    is_round_trip: { column: 'is_round_trip', map: 'IsRoundTrip', format: mfRoundTripFormatter },
    customer_reference: { column: 'customer_reference', map: 'CustomerReference' },
    scheduled_date: {
      column: 'scheduled_date',
      map: ['ScheduledDate'],
      format: firestoreDateObject,
      displayPipeArgs: ['date', 'dd LLL HH:mm'],
    },
    warehouse_name: { column: 'warehouse_name', map: 'WarehouseName' },
    driver_names: {
      column: 'driver_names',
      map: ['DriverNames', 'FleetAllocation', 'CourierName'],
      format: mfGetDriverNamesCommaSeparated,
    },
    actual_fleet_allocation: {
      column: 'actual_fleet_allocation',
      map: ['ActualFleetAllocation'],
      format: mfHandleNoneFleet,
    },
    planned_fleet_allocation: {
      column: 'planned_fleet_allocation',
      map: ['PlannedFleetAllocation'],
      format: mfHandleNoneFleet,
    },
    lottery_count: {
      column: 'lottery_count',
      map: ['LotteryRestartCount', 'TotalLotteryAttempts'],
      format: lotteryRestartCountFormatter,
      highlighted: true,
      highlightColorMap: ['LotteryRestartCount', 'TotalLotteryAttempts'],
      highlightColorClass: lotteryRestartCountHighlighter,
    },
    last_event: {
      column: 'last_event',
      map: ['LastEventName'],
      format: firestorePostDispatchEventNameMapping,
      highlighted: true,
      highlightColorMap: ['LastEventName'],
      highlightColorClass: firestorePostDispatchEventHighlighter,
    },
    last_updated: {
      column: 'last_updated',
      map: ['Timestamp'],
      format: firestoreDateObject,
      displayPipeArgs: ['timeAgo'],
    },
    parcel_count: {
      column: 'parcel_count',
      map: ['ParcelCount']
    },
  },
};


export const FSAlertOrderTableColumns: TableColumn[] = [
  { name: 'trip_id', displayName: 'Trip ID', hidden: true },
  { name: 'customer_reference', displayName: 'Customer Reference', sortable: false },
  { name: 'scheduled_date', displayName: 'Scheduled Date', sortable: true },
  { name: 'lottery_count', displayName: 'Total Lottery Attempts', sortable: false },
  { name: 'warehouse_name', displayName: 'Warehouse', sortable: true },
  { name: 'is_round_trip', displayName: 'Trip Type', sortable: false },
  { name: 'driver_names', displayName: 'Drivers', sortable: false },
  // { name: 'actual_fleet_allocation', displayName: 'Actual Fleet', sortable: false },
  { name: 'planned_fleet_allocation', displayName: 'Planned Fleet', sortable: false },
  { name: 'last_event', displayName: 'Last Event', sortable: true },
  { name: 'last_updated', displayName: 'Last Updated', sortable: true },
  { name: 'parcel_count', displayName: 'Parcels', sortable: false },
];

export const FSAlertOrderTableDataMap: TableDataMap = {
  cells: {
    trip_id: { column: 'trip_id', map: 'Id' },
    is_round_trip: { column: 'is_round_trip', map: 'IsRoundTrip', format: mfRoundTripFormatter },
    customer_reference: { column: 'customer_reference', map: 'CustomerReference' },
    scheduled_date: {
      column: 'scheduled_date',
      map: ['ScheduledDate'],
      format: firestoreDateObject,
      displayPipeArgs: ['date', 'dd LLL HH:mm'],
    },
    warehouse_name: { column: 'warehouse_name', map: 'WarehouseName' },
    driver_names: {
      column: 'driver_names',
      map: ['DriverNames', 'FleetAllocation', 'CourierName'],
      format: mfGetDriverNamesCommaSeparated,
    },
    // actual_fleet_allocation: {
    //   column: 'actual_fleet_allocation',
    //   map: ['ActualFleetAllocation'],
    //   format: mfHandleNoneFleet,
    // },
    planned_fleet_allocation: {
      column: 'planned_fleet_allocation',
      map: ['PlannedFleetAllocation'],
      format: mfHandleNoneFleet,
    },
    lottery_count: {
      column: 'lottery_count',
      map: ['LotteryRestartCount', 'TotalLotteryAttempts'],
      format: lotteryRestartCountFormatter,
      highlighted: true,
      highlightColorMap: ['LotteryRestartCount', 'TotalLotteryAttempts'],
      highlightColorClass: lotteryRestartCountHighlighter,
    },
    last_event: {
      column: 'last_event',
      map: ['LastEventName'],
      format: firestorePostDispatchEventNameMapping,
      highlighted: true,
      highlightColorMap: ['LastEventName'],
      highlightColorClass: firestorePostDispatchEventHighlighter,
    },
    last_updated: {
      column: 'last_updated',
      map: ['Timestamp'],
      format: firestoreDateObject,
      displayPipeArgs: ['timeAgo'],
    },
    parcel_count: {
      column: 'parcel_count',
      map: ['ParcelCount']
    },
  },
};

export const FSAllFailedFinalTableColumns: TableColumn[] = [
  { name: 'trip_id', displayName: 'Trip ID', hidden: true },
  { name: 'customer_reference', displayName: 'Customer Reference', sortable: false },
  { name: 'scheduled_date', displayName: 'Scheduled Date', sortable: true },
  { name: 'lottery_count', displayName: 'Total Lottery Attempts', sortable: false },
  { name: 'warehouse_name', displayName: 'Warehouse', sortable: true },
  { name: 'is_round_trip', displayName: 'Trip Type', sortable: false },
  { name: 'driver_names', displayName: 'Drivers', sortable: false },
  // { name: 'actual_fleet_allocation', displayName: 'Actual Fleet', sortable: false },
  { name: 'planned_fleet_allocation', displayName: 'Planned Fleet', sortable: false },
  { name: 'last_event', displayName: 'Last Event', sortable: true },
  { name: 'last_updated', displayName: 'Last Updated', sortable: true },
  { name: 'parcel_count', displayName: 'Parcels', sortable: false },
];

export const FSAllFailedFinalTableDataMap: TableDataMap = {
  cells: {
    trip_id: { column: 'trip_id', map: 'Id' },
    is_round_trip: { column: 'is_round_trip', map: 'IsRoundTrip', format: mfRoundTripFormatter },
    customer_reference: { column: 'customer_reference', map: 'CustomerReference' },
    scheduled_date: {
      column: 'scheduled_date',
      map: ['ScheduledDate'],
      format: firestoreDateObject,
      displayPipeArgs: ['date', 'dd LLL HH:mm'],
    },
    warehouse_name: { column: 'warehouse_name', map: 'WarehouseName' },
    driver_names: {
      column: 'driver_names',
      map: ['DriverNames', 'FleetAllocation', 'CourierName'],
      format: mfGetDriverNamesCommaSeparated,
    },
    // actual_fleet_allocation: {
    //   column: 'actual_fleet_allocation',
    //   map: ['ActualFleetAllocation'],
    //   format: mfHandleNoneFleet,
    // },
    planned_fleet_allocation: {
      column: 'planned_fleet_allocation',
      map: ['PlannedFleetAllocation'],
      format: mfHandleNoneFleet,
    },
    lottery_count: {
      column: 'lottery_count',
      map: ['LotteryRestartCount', 'TotalLotteryAttempts'],
      format: lotteryRestartCountFormatter,
      highlighted: true,
      highlightColorMap: ['LotteryRestartCount', 'TotalLotteryAttempts'],
      highlightColorClass: lotteryRestartCountHighlighter,
    },
    last_event: {
      column: 'last_event',
      map: ['LastEventName'],
      format: firestorePostDispatchEventNameMapping,
      highlighted: true,
      highlightColorMap: ['LastEventName'],
      highlightColorClass: firestorePostDispatchEventHighlighter,
    },
    last_updated: {
      column: 'last_updated',
      map: ['Timestamp'],
      format: firestoreDateObject,
      displayPipeArgs: ['timeAgo'],
    },
    parcel_count: {
      column: 'parcel_count',
      map: ['ParcelCount']
    },
  },
};

export const FSactiveLastMileTableMap: TableDataMap = {
  cells: {
    last_mile_id: { column: 'last_mile_id', map: 'Id' },
    driver: {
      column: 'driver',
      map: 'DriverName',
    },
    last_event: {
      column: 'last_event',
      map: ['LastEventName'],
      format: firestorePostDispatchEventNameMapping,
      highlighted: true,
      highlightColorMap: ['LastEventName'],
      highlightColorClass: firestorePostDispatchEventHighlighter,
    },
    last_updated: {
      column: 'last_updated',
      map: ['Timestamp'],
      format: firestoreDateObject,
      displayPipeArgs: ['timeAgo'],
    },
    // vehicle_id: { column: 'vehicle_id', map: 'vehicle_id', format: mfHumanizeString },

    // parcel_count: {
    //   column: 'parcel_count',
    //   map: ['progress', 'parcel_count'],
    //   format: mfGetNestedPercentageObjectValue,
    // },
    // progress: {
    //   column: 'progress',
    //   map: ['progress', 'percent_complete'],
    //   format: mfGetNestedPercentageObjectValue,
    //   displayPipeArgs: DisplayPipes.decimalsNone,
    //   postfix: '%',
    // },
  },
};

export const FSactiveLastMileTableColumns: TableColumn[] = [
  { name: 'last_mile_id', displayName: 'Last Mile ID', hidden: true },
  { name: 'driver', displayName: 'Driver', sortable: false },
  { name: 'last_event', displayName: 'Last Event', sortable: true },
  { name: 'last_updated', displayName: 'Last Updated', sortable: true },
];

export const FSactiveCourierOrdersTableMap: TableDataMap = {
  cells: {
    courier_order_id: { column: 'courier_order_id', map: 'Id' },
    business_reference: { column: 'business_reference', map: 'BusinessReference' },
    courier_name: { column: 'courier_name', map: 'CourierName' },
    courier_reference: { column: 'courier_reference', map: 'CourierReference' },
    customer_name: { column: 'courier_reference', map: ['Parcels'], format: getDeeplyNestedCustomerName },
    order_waybill: { column: 'order_waybill', map: ['OrderWaybillNumber'] },
    courier_code: { column: 'courier_code', map: 'CourierCode' },
    warehouse_name: { column: 'warehouse_name', map: 'WarehouseName' },
    last_event: {
      column: 'last_event',
      map: ['TrackingEventType'],
      format: mfHumanizeString,
      highlighted: true,
      highlightColorMap: ['TrackingEventType'],
      highlightColorClass: firestoreCourierPostDispatchEventHighlighter,
    },
    last_updated: {
      column: 'last_updated',
      map: ['Timestamp'],
      format: firestoreDateObject,
      displayPipeArgs: ['timeAgo'],
    },
    // driver: {
    //   column: 'driver',
    //   map: 'driver',
    // },
    // last_event: {
    //   column: 'last_event',
    //   map: ['last_mile', 'event_title'],
    //   format: mfGetNestedObjectValue,
    //   highlighted: true,
    //   highlightColorMap: ['last_mile', 'event_health'],
    //   highlightColorClass: mfNestedLastEventHighlighter,
    // },
    // last_updated: {
    //   column: 'last_updated',
    //   map: ['last_mile', 'event_timestamp'],
    //   format: mfGetNestedObjectValue,
    //   displayPipeArgs: ['timeAgo'],
    // },
    // vehicle_id: { column: 'vehicle_id', map: 'vehicle_id', format: mfHumanizeString },

    // parcel_count: {
    //   column: 'parcel_count',
    //   map: ['progress', 'parcel_count'],
    //   format: mfGetNestedPercentageObjectValue,
    // },
    // progress: {
    //   column: 'progress',
    //   map: ['progress', 'percent_complete'],
    //   format: mfGetNestedPercentageObjectValue,
    //   displayPipeArgs: DisplayPipes.decimalsNone,
    //   postfix: '%',
    // },
  },
};

export const FScourierOrdersTableColumns: TableColumn[] = [
  { name: 'courier_order_id', displayName: 'Courier Order ID', hidden: true },
  { name: 'business_reference', displayName: 'Business Reference', hidden: false, sortable: false },
  { name: 'courier_reference', displayName: 'Courier Reference', hidden: false, sortable: false },
  { name: 'order_waybill', displayName: 'Order Waybill', hidden: false, sortable: false },
  { name: 'customer_name', displayName: 'Customer Name', hidden: false, sortable: false },
  { name: 'courier_name', displayName: 'Courier Name', hidden: false, sortable: true },
  { name: 'courier_code', displayName: 'Courier Code', hidden: false, sortable: false },
  { name: 'warehouse_name', displayName: 'Warehouse Name', hidden: false, sortable: true },
  { name: 'last_event', displayName: 'Last Event', sortable: true },
  { name: 'last_updated', displayName: 'Last Updated', sortable: true },
];

export const FSLastEventMapping = new Map<string, string>([
  ['CourierOrderStateUpdated', 'Courier Status Manually Updated'],
  ['CourierTrackingEventProcessed', 'Courier Event Processed'],
  ['WaypointRouteEnded', 'Waypoint Route Ended'],
  ['CourierWaybillCreated', 'Courier Waybill Created'],
  ['DriverArrivedAtWaypoint', 'Driver Arrived at Waypoint'],
  ['LastMileParcelsUnassigned', 'Parcels Unassigned'],
  ['DriverEnRouteToWarehouse', 'Driver En Route to Warehouse'],
  ['ParcelsAssignedFromOrderAggregate', 'Parcels Assigned'],
  ['WaypointRouteOptimized', 'Route Optimized'],
  ['WarehouseRouteOptimized', 'Route Optimized'],
  ['OrderCompleted', 'Order Completed'],
  ['OrderCancelled', 'Order Cancelled'],
  ['ParcelsCancelled', 'Parcels Cancelled'],
  ['CancelParcelsFailed', 'Failed to Cancel Parcels'],
  ['OrderCreated', 'Order Created'],
  ['CreateCourierOrderRequested', 'Courier Order Created'],
  ['ParcelsAdded', 'Parcels Added'],
  ['ParcelDetailsUpdated', 'Parcels Updated'],
  ['ParcelsAssignedToLastMile', 'Parcels Assigned'],
  ['LastMileParcelsTransferred', 'Parcels Transferred'],
  ['TriedToAssignLotteryDriver', 'Tried to assign Lottery Driver'],
  ['SucceedToAssignLotteryDriver', 'Lottery Driver Assigned'],
  ['FailedToAssignLotteryDriver', 'Failed to assign Lottery Driver'],
  ['StartLotteryScheduled', 'Start Lottery Scheduled'],
  ['LotteryStarted', 'Lottery Started'],
  ['LotteryFailedToStart', 'No Drivers Found Nearby'],
  ['LotterySkipped', 'Lottery Skipped'],
  ['LotteryCancelled', 'Lottery Cancelled'],
  ['ParcelsUnassignedFromLastMileAggregate', 'Parcels Unassigned'],
  ['AssignLastMileParcelsFailed', 'Failed to Assign Parcels'],
  ['OptimizeRouteFailed', 'Optimize Route Failed'],
  ['ParcelsFromLastMileReconciled', 'Parcels Reconciled'],
  ['OrderPaymentHandledFromOrderPaymentAggregate', 'Order Payment Handled'],
  ['LastMileRouteStarted', 'Last Mile Route Started'],
  ['LastMileRouteEnded', 'Last Mile Route Ended'],
  ['LastMileRouteParcelsCollected', 'Last Mile Parcels Collected'],
  ['LastMileRouteParcelsFailedCollection', 'Last Mile Failed Collection'],
  ['CreateCourierWaybillSucceeded', 'Courier Waybill Created'],
  ['ParcelsAssignedToCourierOrder', 'Parcels Assigned to Courier'],
  ['CourierOrderCompleted', 'Courier Order Completed'],
  ['CourierOrderStatusUpdated', 'Courier Status Updated'],
  ['CourierOrderCompleteUpdated', 'Courier Order Completed'],
  ['CourierOrderCancelledUpdated', 'Courier Order Cancelled'],
  ['CreateCourierOrderFailed', 'Courier Order Failed'],
  ['FailedToCreateCourierWaybill', 'Failed to Create Waybill'],
  ['DriverEnRouteToWaypoint', 'Driver En-Route To Waypoint'],
  ['DriverDeliveredParcelsAtWaypoint', 'Parcels Delivered'],
  ['DriverFinalizedAtWaypoint', 'Driver Finalized At Waypoint'],
  ['LotterySkipped', 'Lottery Cycles Complete'],
]);

export const FSLastEventColorHighlightMapping = new Map<string, HighlightHealthStates>([
  ['CourierTrackingEventProcessed', HighlightHealthStates.Primary],
  ['WaypointRouteEnded', HighlightHealthStates.Primary],
  ['DriverArrivedAtWaypoint', HighlightHealthStates.Primary],
  ['LastMileParcelsUnassigned', HighlightHealthStates.Default],
  ['DriverEnRouteToWarehouse', HighlightHealthStates.Primary],
  ['WaypointRouteOptimized', HighlightHealthStates.Primary],
  ['WarehouseRouteOptimized', HighlightHealthStates.Primary],
  ['ParcelsAssignedFromOrderAggregate', HighlightHealthStates.Primary],
  ['OrderCompleted', HighlightHealthStates.Good],
  ['OrderCancelled', HighlightHealthStates.Bad],
  ['ParcelsCancelled', HighlightHealthStates.Bad],
  ['CancelParcelsFailed', HighlightHealthStates.Bad],
  ['OrderCreated', HighlightHealthStates.Primary],
  ['CreateCourierOrderRequested', HighlightHealthStates.Default],
  ['ParcelsAdded', HighlightHealthStates.Default],
  ['ParcelDetailsUpdated', HighlightHealthStates.Default],
  ['ParcelsAssignedToLastMile', HighlightHealthStates.Good],
  ['LastMileParcelsTransferred', HighlightHealthStates.Default],
  ['TriedToAssignLotteryDriver', HighlightHealthStates.Default],
  ['SucceedToAssignLotteryDriver', HighlightHealthStates.Good],
  ['FailedToAssignLotteryDriver', HighlightHealthStates.Bad],
  ['StartLotteryScheduled', HighlightHealthStates.Default],
  ['LotteryStarted', HighlightHealthStates.Primary],
  ['LotteryFailedToStart', HighlightHealthStates.Bad],
  ['LotterySkipped', HighlightHealthStates.Default],
  ['LotteryCancelled', HighlightHealthStates.Warning],
  ['CourierOrderStateUpdated', HighlightHealthStates.Good],
  ['ParcelsUnassignedFromLastMileAggregate', HighlightHealthStates.Primary],
  ['AssignLastMileParcelsFailed', HighlightHealthStates.Warning],
  ['OptimizeRouteFailed', HighlightHealthStates.Bad],
  ['ParcelsFromLastMileReconciled', HighlightHealthStates.Default],
  ['OrderPaymentHandledFromOrderPaymentAggregate', HighlightHealthStates.Good],
  ['LastMileRouteStarted', HighlightHealthStates.Good],
  ['LastMileRouteEnded', HighlightHealthStates.Good],
  ['LastMileRouteParcelsCollected', HighlightHealthStates.Primary],
  ['LastMileRouteParcelsFailedCollection', HighlightHealthStates.Bad],
  ['CreateCourierWaybillSucceeded', HighlightHealthStates.Good],
  ['CourierWaybillCreated', HighlightHealthStates.Good],
  ['ParcelsAssignedToCourierOrder', HighlightHealthStates.Primary],
  ['CourierOrderCompleted', HighlightHealthStates.Primary],
  ['CourierOrderStatusUpdated', HighlightHealthStates.Primary],
  ['CourierOrderCompleteUpdated', HighlightHealthStates.Good],
  ['CourierOrderCancelledUpdated', HighlightHealthStates.Warning],
  ['CreateCourierOrderFailed', HighlightHealthStates.Bad],
  ['FailedToCreateCourierWaybill', HighlightHealthStates.Warning],
  ['DriverEnRouteToWaypoint', HighlightHealthStates.Primary],
  ['DriverDeliveredParcelsAtWaypoint', HighlightHealthStates.Good],
  ['DriverFinalizedAtWaypoint', HighlightHealthStates.Good],
  ['LotterySkipped', HighlightHealthStates.Bad],
]);

export const FSLastEventUIColorMapping = new Map<string, any>([
  ['CourierTrackingEventProcessed', UiColors.Green],
  ['WaypointRouteEnded', UiColors.Green],
  ['DriverArrivedAtWaypoint', UiColors.Green],
  ['LastMileParcelsUnassigned', UiColors.Green],
  ['DriverEnRouteToWarehouse', UiColors.Green],
  ['WaypointRouteOptimized', UiColors.Green],
  ['WarehouseRouteOptimized', UiColors.Green],
  ['CourierOrderStateUpdated', UiColors.Green],
  ['ParcelsAssignedFromOrderAggregate', UiColors.Green],
  ['OrderCompleted', UiColors.Green],
  ['OrderCancelled', UiColors.Red],
  ['ParcelsCancelled', UiColors.Red],
  ['CancelParcelsFailed', UiColors.Red],
  ['OrderCreated', UiColors.Green],
  ['CreateCourierOrderRequested', UiColors.Green],
  ['ParcelsAdded', UiColors.Green],
  ['ParcelDetailsUpdated', UiColors.Green],
  ['ParcelsAssignedToLastMile', UiColors.Green],
  ['LastMileParcelsTransferred', UiColors.Green],
  ['TriedToAssignLotteryDriver', UiColors.Green],
  ['SucceedToAssignLotteryDriver', UiColors.Green],
  ['FailedToAssignLotteryDriver', UiColors.Red],
  ['StartLotteryScheduled', UiColors.Green],
  ['LotteryStarted', UiColors.Green],
  ['LotteryFailedToStart', UiColors.Red],
  ['LotterySkipped', UiColors.Green],
  ['LotteryCancelled', UiColors.Orange],
  ['ParcelsUnassignedFromLastMileAggregate', UiColors.Green],
  ['AssignLastMileParcelsFailed', UiColors.Orange],
  ['OptimizeRouteFailed', UiColors.Red],
  ['ParcelsFromLastMileReconciled', UiColors.Green],
  ['OrderPaymentHandledFromOrderPaymentAggregate', UiColors.Green],
  ['LastMileRouteStarted', UiColors.Green],
  ['LastMileRouteEnded', UiColors.Green],
  ['LastMileRouteParcelsCollected', UiColors.Green],
  ['LastMileRouteParcelsFailedCollection', UiColors.Red],
  ['CreateCourierWaybillSucceeded', UiColors.Green],
  ['CourierWaybillCreated', UiColors.Green],
  ['ParcelsAssignedToCourierOrder', UiColors.Green],
  ['CourierOrderCompleted', UiColors.Green],
  ['CourierOrderStatusUpdated', UiColors.Green],
  ['CourierOrderCompleteUpdated', UiColors.Green],
  ['CourierOrderCancelledUpdated', UiColors.Orange],
  ['CreateCourierOrderFailed', UiColors.Red],
  ['FailedToCreateCourierWaybill', UiColors.Red],
]);

export const FSCourierTrackingHighlighter = new Map<string, HighlightHealthStates>([
  ['Unknown', HighlightHealthStates.Default],
  ['None', HighlightHealthStates.Primary],
  ['GeneralEvent', HighlightHealthStates.Primary],
  ['WaybillCreated', HighlightHealthStates.Primary],
  ['WaybillProcessed', HighlightHealthStates.Primary],
  ['CourierSelected', HighlightHealthStates.Primary],
  ['ReadyForCollection', HighlightHealthStates.Primary],
  ['OutForCollection', HighlightHealthStates.Primary],
  ['CourierCollected', HighlightHealthStates.Good],
  ['CollectionFailed', HighlightHealthStates.Warning],
  ['AtCourierCenter', HighlightHealthStates.Primary],
  ['ParcelVolumised', HighlightHealthStates.Primary],
  ['InTransit', HighlightHealthStates.Primary],
  ['OutForDelivery', HighlightHealthStates.Primary],
  ['Delivered', HighlightHealthStates.Good],
  ['FailedDelivery', HighlightHealthStates.Warning],
  ['PodImageCaptured', HighlightHealthStates.Primary],
  ['WaybillCreationFailed', HighlightHealthStates.Bad],
  ['Cancelled', HighlightHealthStates.Bad],
  ['Complete', HighlightHealthStates.Good],
  ['PricingAdjustment', HighlightHealthStates.Primary],
]);
