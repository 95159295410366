import { Pipe, PipeTransform } from '@angular/core';
import { Report, ReportTypeEnum } from '../reporting.data';

@Pipe({
  name: 'reportsByType'
})
export class ReportByTypePipe implements PipeTransform {

  transform(allReports: Report[], type: ReportTypeEnum, isAdmin: boolean): Report[] {
    let reports: Report[] = allReports;
    if (!isAdmin) {
      reports = reports.filter((x) => x.IsAdminOnly === false);
    }
    return reports.filter((x) => x.Type === type);
  }

}
