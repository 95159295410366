export enum MapTypes {
  OrderTracking = 'order_tracking',
  DriverProfile = 'driver_profile',
  Tracking = 'tracking',
  Drivers = 'drivers',
  Trips = 'trips',
  Vetting = 'vetting',
  Overview = 'overview',
  Fleet = 'fleet',
  ViewFreight = 'viewFreight',
  Crossdocks = 'crossdocks',
  Zones = 'zones',
  Alerts = 'alerts',
}
