import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Report } from 'app/reporting/reporting.data';

@Component({
  selector: 'app-report-item',
  templateUrl: './report-item.component.html',
  styleUrls: ['./report-item.component.scss']
})
export class ReportItemComponent implements OnInit {
  @Input() report: Report;
  @Input() isAdmin: boolean = false;
  @Input() isLoading: boolean = false;
  @Output() reportSelected: EventEmitter<Report> = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

}
