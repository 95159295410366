import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-success-vs-failure-per-allocation',
  templateUrl: './success-vs-failure-per-allocation.component.html',
  styleUrls: ['./success-vs-failure-per-allocation.component.scss'],
})
export class SuccessVsFailurePerAllocationComponent implements OnInit {
  data = [
    {
      name: 'Picup',
      series: [
        {
          name: '2021-07-02',
          value: 100,
        },
        {
          name: '2021-07-06',
          value: 98,
        },
        {
          name: '2021-07-08',
          value: 85,
        },
        {
          name: '2021-07-09',
          value: 95,
        },
        {
          name: '2021-07-12',
          value: 100,
        },
        {
          name: '2021-07-16',
          value: 95,
        },
      ],
    },
    {
      name: 'Contractor',
      series: [
        {
          name: '2021-07-02',
          value: 66,
        },
        {
          name: '2021-07-06',
          value: 78,
        },
        {
          name: '2021-07-08',
          value: 96,
        },
        {
          name: '2021-07-09',
          value: 80,
        },
        {
          name: '2021-07-12',
          value: 86,
        },
        {
          name: '2021-07-16',
          value: 74,
        },
      ],
    },
    {
      name: 'Courier',
      series: [
        {
          name: '2021-07-02',
          value: 78,
        },
        {
          name: '2021-07-06',
          value: 67,
        },
        {
          name: '2021-07-08',
          value: 94,
        },
        {
          name: '2021-07-09',
          value: 96,
        },
        {
          name: '2021-07-12',
          value: 66,
        },
        {
          name: '2021-07-16',
          value: 100,
        },
      ],
    },
  ];

  colorScheme = {
    domain: ['#02DE73', '#ff7100', '#27B7FC'],
  };

  constructor() {}

  ngOnInit(): void {}
}
