<div
  class="card-wrap"
  [ngClass]="{
    expired: checkExpiry(
      bucket.delivery_date_end ? bucket.delivery_date_end : bucket.delivery_date,
      bucket.current_status
    ),
    alert: bucket.current_state === 'Alert'
  }"
  (click)="selectBucket(bucket)"
>
  <div *ngIf="bucket.flagged_orders > 0" class="highlight-triangle"></div>

  <div
    [ngClass]="{ alert: bucket.current_state === 'Alert' }"
    class="card-date"
    *ngIf="!bucket.delivery_date_end || bucket.delivery_date_end === '0001-01-01T00:00:00'"
  >
    <h1>{{ bucket.delivery_date | date: 'dd' }}</h1>

    <div class="card-day">
      <h6>{{ bucket.delivery_date | date: 'LLL' }}</h6>
      <div class="divider"></div>
      <h6>{{ bucket.delivery_date | date: 'E' }}</h6>
    </div>
    <h5 class="card-time">
      {{ bucket.shift_start_date_time.toDate() | date: 'HH:mm' }} -
      {{ bucket.shift_end_date_time.toDate() | date: 'HH:mm' }}
    </h5>
  </div>

  <div
    [ngClass]="{ alert: bucket.current_state === 'Alert' }"
    class="card-date multi"
    *ngIf="bucket.delivery_date_end && bucket.delivery_date_end !== '0001-01-01T00:00:00'"
  >
    <h2>
      <app-icon
        [color]="bucket.current_state === 'Alert' ? UiColors.Red : UiColors.Blue"
        [size]="2.3"
        [icon]="IconTypes.StartArrow"
      ></app-icon>
      {{ bucket.delivery_date | date: 'dd' }}<span> {{ bucket.delivery_date | date: 'LLL' }}</span>
    </h2>
    <h2>
      <app-icon
        [color]="bucket.current_state === 'Alert' ? UiColors.Red : UiColors.Blue"
        [size]="2.3"
        [icon]="IconTypes.EndArrow"
      ></app-icon>
      {{ bucket.delivery_date_end | date: 'dd' }}<span> {{ bucket.delivery_date_end | date: 'LLL' }}</span>
    </h2>
    <div class="card-day">
      <h6>{{ 'MULTI' }}</h6>
      <div class="divider"></div>
      <h6>{{ getDays(bucket.delivery_date, bucket.delivery_date_end) }} Days</h6>
    </div>
    <h5 class="card-time">
      {{ bucket.shift_start_date_time.toDate() | date: 'HH:mm' }} -
      {{ bucket.shift_end_date_time.toDate() | date: 'HH:mm' }}
    </h5>
  </div>

  <div class="card-body">
    <div class="card-details">
      <app-bucket-status [bucket]="bucket" [size]="'large'"></app-bucket-status>

      <div class="spacer"></div>
      <h4 *ngIf="admin" class="card-business">{{ bucket.business_name }}</h4>
      <div *ngIf="admin" class="divider"></div>
      <h4 class="card-warehouse">{{ bucket.warehouse_name }}</h4>
      <div class="divider"></div>
      <h4>{{ '#' + bucket.bucket_id }}</h4>
    </div>

    <div class="card-state">
      <div>
        <h2>
          {{ bucket?.middle_mile_orders > 0 ? bucket.total_orders - bucket.middle_mile_orders : bucket.total_orders }}
        </h2>
        <h6>Orders</h6>
      </div>
      <div *ngIf="bucket?.middle_mile_orders">
        <h2>{{ bucket.middle_mile_orders }}</h2>
        <h6>Middle Mile</h6>
      </div>
      <div>
        <h2>{{ bucket.flagged_orders }}</h2>
        <h6>Flagged</h6>
      </div>
      <div>
        <h2>{{ bucket.failed_orders + bucket.unverified_orders }}</h2>
        <h6>Unverified</h6>
      </div>
      <div>
        <h2>{{ bucket.unrouted || 0 }}</h2>
        <h6>Unrouted</h6>
      </div>
      <div>
        <h2>{{ bucket.routes }}</h2>
        <h6>Routes</h6>
      </div>
      <div>
        <h2>
          <span *ngIf="bucket.avg_cost_per_order"> {{ environment.currency_symbol }}</span
          >{{ (bucket.avg_cost_per_order | number: '1.2-2') || '...' }}
        </h2>
        <h6>Per Order</h6>
      </div>
      <div>
        <h2>
          <span *ngIf="bucket.avg_price_per_parcel"> {{ environment.currency_symbol }}</span
          >{{ (bucket.avg_price_per_parcel | number: '1.2-2') || '...' }}
        </h2>
        <h6>Per Parcel</h6>
      </div>
    </div>
  </div>
</div>
