import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ModalSizes, ModalAction } from 'app/shared/modals/modal.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { JustifyContent } from 'app/shared/flex-container/flex-container.interfaces';
import * as moment from 'moment';

@Component({
  selector: 'app-manual-routing',
  templateUrl: './manual-routing.component.html',
  styleUrls: ['./manual-routing.component.scss'],
})
export class ManualRoutingComponent extends SimpleModalComponent<any, any> implements OnInit {
  trip_name;
  public ModalSizes = ModalSizes;
  public IconTypes = IconTypes;
  public uiColors = UiColors;
  public UiThemes = UiThemes;
  public ButtonTypes = ButtonTypes;
  public justifyContent = JustifyContent;
  public actions: ModalAction[] = [];

  delivery_date = moment().format('YYYY-MM-DD');
  total_orders;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.updateActions();
  }

  dateChanged($event) {
    this.delivery_date = moment($event).format('YYYY-MM-DD');
  }

  updateActions() {
    this.actions = [
      { name: 'cancel', title: 'Cancel' },
      { name: 'clear', title: 'Clear' },
      { name: 'confirm', title: 'Confirm', isPrimary: true, disabled: !this.trip_name },
    ];
  }

  handleAction($event) {
    switch ($event) {
      case 'cancel':
        this.close();
        break;
      case 'clear':
        this.result = { delivery_date: this.delivery_date, trip_name: null };
        this.close();
        break;
      case 'confirm':
        this.trip_name = this.trip_name.trim();
        this.trip_name = this.trip_name.split(' ').join('');
        this.trip_name = this.trip_name.toUpperCase();
        this.result = { delivery_date: this.delivery_date, trip_name: this.trip_name };
        this.close();
        break;
    }
  }
}
