<div *ngIf="displayedColumns().length > 0">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container *ngFor="let data of tableColumns">
        <!-- Text column -->
        <ng-container matColumnDef="{{data.id}}">
            <ng-container *ngIf="data.type === tableType.string">
                <th mat-header-cell *matHeaderCellDef> {{data.displayName}} </th>
                <td mat-cell *matCellDef="let element"> {{data.format ? data.format(element) : data}} </td>
            </ng-container>
            <ng-container *ngIf="data.type === tableType.checkbox">
                <th mat-header-cell *matHeaderCellDef> 
                    <mat-checkbox
                        (change)="$event ? toggleAllRows() : null"
                        [checked]="selection.hasValue() && isAllSelected()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let element">       
                    <mat-checkbox 
                        (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(element) : null"
                        [checked]="selection.isSelected(element)">
                    </mat-checkbox> 
                </td>
            </ng-container>
        </ng-container>
    </ng-container>
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns()"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns();"></tr>
    </table>
    <ng-container *ngIf="enablePaging">
        <mat-paginator 
            [pageSizeOptions]="[5, 10]"
            showFirstLastButtons>
        </mat-paginator>
    </ng-container>
</div>