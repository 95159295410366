import { Component, OnInit, Input } from '@angular/core';
import { TableDataMap, TableColumn } from '../table/table.interfaces';

@Component({
  selector: 'app-parcel-report-table',
  templateUrl: './parcel-report-table.component.html',
  styleUrls: ['./parcel-report-table.component.scss'],
})
export class ParcelReportTableComponent implements OnInit {
  @Input() ParcelReportData;

  public parcelReportColumns: TableColumn[] = [
    { name: 'parcel_reference', displayName: 'Parcel Reference', sortable: true },
    { name: 'delivery_contact_name', displayName: 'Delivery Contact Name', sortable: true },
    { name: 'delivery_contact_phone', displayName: 'Contact Phone', sortable: true },
    { name: 'delivery_address', displayName: 'Delivery Address', sortable: true },
    { name: 'collection_contact_name', displayName: 'Collection Contact Name', sortable: true },
    { name: 'collection_contact_phone', displayName: 'Contact Phone', sortable: true },
    { name: 'collection_address', displayName: 'Collection Address', sortable: true },
    { name: 'driver_name', displayName: 'Driver', sortable: true },
    { name: 'failure_reason', displayName: 'Failure Reason', sortable: true },
    { name: 'failed_at', displayName: 'Failure Time', sortable: true },
  ];

  public parcelReportDataMap: TableDataMap = {
    cells: {
      parcel_reference: { column: 'parcel_reference', map: 'parcel_reference', displayPipeArgs: ['none'] },
      delivery_contact_name: { column: 'delivery_contact_name', map: 'delivery_contact_name' },
      delivery_contact_phone: {
        column: 'delivery_contact_phone',
        map: 'delivery_contact_phone',
        displayPipeArgs: ['none'],
      },
      delivery_address: { column: 'delivery_address', map: 'delivery_address' },
      collection_contact_name: { column: 'collection_contact_name', map: 'collection_contact_name' },
      collection_contact_phone: {
        column: 'collection_contact_phone',
        map: 'collection_contact_phone',
        displayPipeArgs: ['none'],
      },
      collection_address: { column: 'collection_address', map: 'collection_address' },
      driver_name: { column: 'driver_name', map: 'driver_name' },
      failure_reason: { column: 'failure_reason', map: 'failure_reason' },
      failed_at: { column: 'failed_at', map: 'failed_at', displayPipeArgs: ['date', 'HH:mm'] },
    },
  };

  constructor() {}

  ngOnInit() {}
}
