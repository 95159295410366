/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
import { Component, DoCheck, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import * as moment from 'moment';

// Services
import { DatePipe, DecimalPipe } from '@angular/common';

// Interfaces
import {
  SortDirection,
  SortEvent,
  TableAction,
  TableActionTaken,
  TableColumn,
  TableColumnSortedState,
  TableDataByColumn,
  TableDataMap,
  TableRow,
  TableSummaryData,
} from './table.interfaces';

// Helpers
import {
  formatCellValue,
  mapTableDataToColumns,
  getVisibleColumnsCount,
  hideCell,
  mapDataToTable,
  shouldShowAction,
  doesRequireSortData,
} from './table.helpers';
import { getSummaryData } from './summary.table.helpers';
import { sortColumn } from './sorting.table.helpers';
import { checkType } from '../helpers';
import { UiColors, UiThemes } from '../../interfaces/ui.interfaces';
import { IconTypes } from '../icon/icon.interfaces';
import { environment } from 'environments/environment';
import { Paginate, PaginateEnum } from '../shared.interfaces';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  providers: [DecimalPipe, DatePipe],
})
export class TableComponent implements OnInit, OnChanges, DoCheck {
  // Required table data for loading the table.
  // Table Columns:
  @Input() columns: TableColumn[];

  // Table Data: Either load pre-formatted data:
  @Input() tableData: TableRow[];
  shownData: TableRow[];

  // OR load rawData and the dataMap and the component will handle the processing internally
  @Input() rawData: any[];
  @Input() dataMap: TableDataMap;

  // Table Actions: Optional - specifies the table actions
  @Input() primaryActions: TableAction[];
  @Input() secondaryActions: TableAction[];
  @Input() bulkActions: TableAction[];

  // Toggles and settings for the table
  @Input() hideColHeaders = false;
  @Input() rowsSelectable = true;
  @Input() overrideRowIsSelectable = false;
  @Input() displayRowHeaders = false;
  @Input() showAnnotations = false;
  @Input() showViewMore = false;
  @Input() showViewAll = false;
  @Input() toggleColumns: TableColumn[];
  @Input() stickyActions = false;
  @Input() isLoading: boolean;
  @Input() zeroDataMessage: string;
  @Input() theme: UiThemes;
  @Input() showBulkActionsAlways = false;
  @Input() readOnly = false;

  // Handles displaying totals and averages on the table.
  @Input() showTableTotals = false;
  @Input() showTableAverages = false;
  @Input() showSelectionTotals = false;
  @Input() showSelectionAverages = false;

  // Handles positioning of the totals and averages sections on the table.
  @Input() showTableSummaryTop = false;
  @Input() showTableSummaryBottom = true;
  @Input() showSelectionSummaryTop = true;
  @Input() showSelectionSummaryBottom = false;

  // pagination
  @Input() showPagination: boolean = false;
  @Input() paginationDisplayAmounts: number[];
  @Input() defaultDisplayAmount: number = 25;
  @Input() currentPageIndex: number;
  @Input() totalPages: number;
  @Input() displayPageCount: boolean;

  // Events emitted by the table component:
  @Output() moreDataRequested = new EventEmitter<boolean>();
  @Output() viewAllRequested = new EventEmitter<boolean>();
  @Output() actionTaken = new EventEmitter<TableActionTaken>();
  @Output() updateSelectedRows = new EventEmitter<TableRow[]>();
  @Output() updateUnselectedRows = new EventEmitter<TableRow[]>();
  @Output() paginate = new EventEmitter<Paginate>();
  @Output() displayAmountChanged = new EventEmitter<number>();
  @Output() tableSortChanged = new EventEmitter<SortEvent>();

  IconTypes = IconTypes;
  UiColors = UiColors;

  timezoneAdjust = null;

  showPrimaryActions: boolean;
  showSecondaryActions: boolean;
  showBulkActions: boolean;

  PaginateEnum = PaginateEnum;

  isTableDataPopulated = false;
  hasSetFirstTableSummaryData = false;
  hasSelectedRows = false;
  requiresSummaryData: boolean;

  visibleColumnsCount: number;

  requiresSortData: boolean;
  selectionSummaryData: TableSummaryData;
  tableColumnData: TableDataByColumn[];
  sortStatus: TableColumnSortedState;
  hasSortedColumn: boolean;
  tableSummaryData: TableSummaryData = {
    totals: { cells: [] },
    averages: { cells: [] },
  };

  _hideCell = hideCell;
  _SortDirection = SortDirection;

  environment = environment;
  currentSortState: SortEvent;

  constructor(private decimalPipe: DecimalPipe, private datePipe: DatePipe) {}

  ngOnInit(): void {
    this.initColumnsVisibility();
    if (environment.envName === 'Africa') {
      this.timezoneAdjust = '+0000';
    }
    this.visibleColumnsCount = getVisibleColumnsCount(this.columns);

    this.showPrimaryActions = shouldShowAction(this.primaryActions);
    this.showSecondaryActions = shouldShowAction(this.secondaryActions);
    this.showBulkActions = shouldShowAction(this.bulkActions);

    this.tableData = this.rawData ? mapDataToTable(this.rawData, this.dataMap, this.columns) : this.tableData;
    this.shownData = this.tableData;
    this.requiresSortData = doesRequireSortData(this.columns);

    if (this.requiresSortData) {
      this.sortStatus = this.initSortedState(this.columns);
    }
  }

  ngOnChanges(): void {
    this.setColumnsVisibility(this.toggleColumns);
    this.requiresSummaryData =
      this.showTableTotals || this.showTableAverages || this.showSelectionTotals || this.showSelectionAverages;

    // If raw data has been submitted, the table maps the data to the table,
    // otherwise it uses the submitted table data object.
    this.tableData = this.rawData ? mapDataToTable(this.rawData, this.dataMap, this.columns) : this.tableData;

    // Fixes issues with polling data, or updated table data
    // If the table is updated, but currently has a sorted column,
    // this ensures that the sorted column persists.
    if (this.isTableDataPopulated && (this.tableData.length > 0 || this.rawData) && this.hasSortedColumn) {
      const key = Object.keys(this.sortStatus).find((statusKey) => this.sortStatus[statusKey].status !== undefined);
      const direction: SortDirection = this.sortStatus[key].status;
      this.handleSort({ name: key }, direction);
    } else {
      // Only show data once the filters have been applied
      if (this.tableData) {
        this.shownData = this.tableData;
      }
    }

    // If the table needs to sort data or display summaries:
    if (this.isTableDataPopulated && this.tableData.length > 0 && (this.requiresSummaryData || this.requiresSortData)) {
      this.tableData = this.tableData.filter(function (element) {
        return element !== undefined;
      });
      this.tableColumnData = mapTableDataToColumns(this.tableData, this.columns);

      if (this.requiresSummaryData) {
        this.tableSummaryData = getSummaryData(this.tableColumnData);
      }
    }
  }

  ngDoCheck(): void {
    if (this.requiresSummaryData || this.requiresSortData) {
      if (!this.isTableDataPopulated && this.tableData && this.tableData.length > 0) {
        this.isTableDataPopulated = true;
      }

      if (this.isTableDataPopulated && !this.hasSetFirstTableSummaryData) {
        this.tableColumnData = mapTableDataToColumns(this.tableData, this.columns);

        if (this.requiresSummaryData) {
          this.tableSummaryData = getSummaryData(this.tableColumnData);
        }
        this.hasSetFirstTableSummaryData = true;
      }
    }
  }

  setDisplayAmount(amount: number): void {
    this.defaultDisplayAmount = amount;
    this.paginateData(PaginateEnum.None);
  }

  checkActionsDependency(dependency, rowData) {
    return !!rowData.cells.find((cell) => cell.column === dependency)?.value;
  }

  checkActionsReverseDependency(reverse_dependency, rowData) {
    return !rowData.cells.find((cell) => cell.column === reverse_dependency)?.value;
  }

  checkForFieldMatchesOnActions(field, rowData, valuesArray) {
    const fieldValue = rowData.cells.find((cell) => cell.column === field).value;
    return valuesArray.includes(fieldValue);
  }

  paginateData(direction: PaginateEnum): void {
    this.paginate.emit({ Amount: this.defaultDisplayAmount, Direction: direction });
  }

  handleRowSelect(): void {
    const selectedRows = this.getSelectedRows();
    this.updateSelectedRows.emit(selectedRows);
    this.updateUnselectedRows.emit(this.getUnselectedRows());

    if (selectedRows.length > 0) {
      this.hasSelectedRows = true;
      this.selectionSummaryData = getSummaryData(mapTableDataToColumns(this.getSelectedRows(), this.columns));
    } else {
      this.hasSelectedRows = false;
    }
  }

  handleSort(col: TableColumn, direction: SortDirection): void {
    this.sortStatus = this.updateSortedState(col.name, direction);
    if (this.showPagination && !this.checkIfSameSortState(col, direction)) {
      const field =
        typeof this.dataMap.cells[col.name].map === 'string'
          ? this.dataMap.cells[col.name].map
          : this.dataMap.cells[col.name].map[0];
      this.currentSortState = {
        field: field.toString(),
        direction: direction,
      };
      this.tableSortChanged.emit(this.currentSortState);
    } else {
      this.tableData = sortColumn(this.tableData, this.tableColumnData, col.name, direction);
      this.hasSortedColumn = true;
      // Only show data once the filters have been applied
      this.shownData = this.tableData;
    }
  }

  checkIfSameSortState(col: TableColumn, direction: string): boolean {
    if (
      this.dataMap.cells[col.name].map.toString() === this.currentSortState?.field &&
      direction === this.currentSortState?.direction
    ) {
      return true;
    }
    return false;
  }

  initSortedState(columns: TableColumn[]): TableColumnSortedState {
    const _sortedState: TableColumnSortedState = {};
    columns.forEach((col) => {
      _sortedState[col.name] = {
        status: col.sortable && col.sortDirection ? col.sortDirection : undefined,
      };
    });

    return _sortedState;
  }

  updateSortedState(col: string, direction: SortDirection): TableColumnSortedState {
    const _sortedState: TableColumnSortedState = {};

    Object.keys(this.sortStatus).forEach((key) => {
      _sortedState[key] = {};
      _sortedState[key].status = col === key ? direction : undefined;
    });

    return _sortedState;
  }

  requestMoreData(): void {
    this.moreDataRequested.emit(true);
  }

  requestViewAll(): void {
    this.viewAllRequested.emit(true);
  }

  formatCell(val, pipeArgs, prefix, postfix): string | number {
    let returnVal = val;
    if (!pipeArgs) {
      returnVal = val;
    }
    if (checkType(pipeArgs, 'array')) {
      switch (pipeArgs[0]) {
        case 'none':
          returnVal = val;
          break;
        case 'date':
          returnVal = this.datePipe.transform(val, pipeArgs[1], this.timezoneAdjust);
          break;
        case 'timeAgo':
          returnVal = moment(val).fromNow();
          returnVal = returnVal === 'Invalid date' ? '' : returnVal;
          break;
        case 'number':
          returnVal = this.decimalPipe.transform(val, pipeArgs[1]) as string;
          break;
      }
    } else if (!isNaN(val) && pipeArgs) {
      returnVal = this.decimalPipe.transform(val, pipeArgs) as string;
    }

    return formatCellValue(returnVal, prefix, postfix);
  }

  formatCellAnnotation(val, pipeArgs): string | number {
    let returnVal = val;
    if (checkType(pipeArgs, 'array')) {
      switch (pipeArgs[0]) {
        case 'date':
          returnVal = this.datePipe.transform(val, pipeArgs[1]);
          break;
        case 'timeAgo':
          returnVal = moment(val).fromNow();
          returnVal = returnVal === 'Invalid date' ? '' : returnVal;
          break;
        case 'number':
          returnVal = this.decimalPipe.transform(val, pipeArgs[1]) as string;
          break;
      }
    } else if (!isNaN(val)) {
      returnVal = this.decimalPipe.transform(val, pipeArgs) as string;
    }

    return returnVal;
  }

  takeAction(action: TableAction, row: TableRow): void {
    const rows = row ? [row] : this.getSelectedRows();

    if (!rows.length) {
      return;
    }

    // Only reset the table selection if the action taken is a bulk action.
    if (
      shouldShowAction(this.bulkActions) &&
      this.bulkActions.find((item) => item.event === action.event) !== undefined
    ) {
      this.setAllRowsSelection(false);
    }

    this.actionTaken.emit({ action, rows });
  }

  shouldShowBulkActions(): boolean {
    if (this.showBulkActionsAlways) {
      return true;
    } else {
      return this.tableData.reduce((sum, item) => sum + (item?.selected ? 1 : 0), 0) > 0;
    }
  }

  getSelectedRows(): TableRow[] {
    if (this.tableData) {
      return this.tableData.filter((row) => row?.selected);
    }
  }

  getUnselectedRows(): TableRow[] {
    return this.tableData.filter((row) => !row?.selected);
  }

  toggleAll(event): void {
    this.setAllRowsSelection(event.target.checked);
  }

  setAllRowsSelection(newSelectionState: boolean): void {
    this.tableData.forEach((data: TableRow) => {
      if (data.isSelectable === undefined || data.isSelectable) {
        data.selected = newSelectionState;
      }
    });

    this.handleRowSelect();
  }

  isAllChecked(): boolean {
    if (this.tableData.length === 0) {
      return false;
    }

    return this.tableData.every((data) => data?.selected);
  }

  initColumnsVisibility(): void {
    this.columns.forEach((col) => {
      if (col.hidden === undefined) {
        col.hidden = false;
      }
    });
  }

  setColumnsVisibility(colsToToggle: TableColumn[]): void {
    if (this.columns === undefined || colsToToggle === undefined) {
      return;
    }

    this.initColumnsVisibility();
    this.visibleColumnsCount = getVisibleColumnsCount(this.columns);

    colsToToggle.forEach((colToggle) => {
      this.columns.find((col) => colToggle.name === col.name).hidden = colToggle.hidden;
    });
  }

  trackByFn(index) {
    return index;
  }
}
