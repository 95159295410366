<app-modal 
  [open]="true" 
  [actions]="actions" 
  [highlightPrimaryAction]="true" 
  (handleAction)="handleAction($event)">
  <heading [icon]="IconTypes.Bucket" 
    [color]="uiColors.Blue">
    <h4 class="heading-title">Edit Bucket Details</h4>
  </heading>

  <app-grid-container [columns]="'repeat(2, 1fr)'" [margin]="'2rem'" [rowGap]="'2rem'">
    <app-date-picker [date]="delivery_date" (changeDate)="dateChanged($event)"></app-date-picker>
    <div class="shift-selector">
      <app-shift-selector
        [selectedRange]="initial_times"
        class="app-shift-selector"
        (changeShift)="changeShift($event)"
      >
      </app-shift-selector>
      <div class="details">
        <h6>Order Count</h6>
        <h3>{{ total_orders }}</h3>
      </div>
    </div>
  </app-grid-container>
</app-modal>
