<div class="card" [ngClass]="classes" [ngStyle]="{'padding': padding}">
  <h4 class="title">{{title}}</h4>
  <h6 *ngIf="subTitle" class="sub-title">{{subTitle}}</h6>
  <ng-content>

  </ng-content>
  <div class="actions">
    <app-icon-button *ngFor="let action of actions"
                     [icon]="action.icon"
                     [iconSizeRem]="action.iconSizeRem"
                     [color]="action.color"
                     (onClick)="handleActionClick(action.name)"
    ></app-icon-button>
  </div>
</div>