import { Component, Input } from '@angular/core';
import { IconTypes } from '../icon/icon.interfaces';
import { UiColors } from '../../interfaces/ui.interfaces';

@Component({
  selector: 'app-stats-icon',
  templateUrl: './stats-icon.component.html',
  styleUrls: ['./stats-icon.component.scss'],
})
export class StatsIconComponent {
  @Input() textColour;
  @Input() icon: IconTypes;
  @Input() iconColor: UiColors;
  @Input() iconSizeRem: number;
  @Input() label: string;
  @Input() data: string | number;
  @Input() dataSizeRem = 3;
  @Input() loading: boolean;
  @Input() labelAlign = 'center';
  @Input() padding = '2rem';
  @Input() textTransform = 'initial';
  @Input() noData = false;
}
