<div class="login-container">
    <form [formGroup]="loginFormGroup">
        <ng-container *ngIf="!(authService.otpRequired$ | async); else otpField">
                <!-- email -->
                <mat-form-field [appearance]="'standard'" class="input-style">
                    <mat-label class="label-colour">Email</mat-label>
                    <input matInput type="email" formControlName="emailControl" (keydown.enter)="login()">
                </mat-form-field>
                <!-- Validation error messages -->
                <ng-container *ngIf="loginFormGroup.controls.emailControl?.invalid && loginFormGroup.controls.emailControl?.touched" >
                    <div *ngIf="loginFormGroup.controls.emailControl?.errors?.required ?? false" class="validation-text">Email is required</div>
                    <div *ngIf="loginFormGroup.controls.emailControl?.errors?.email ?? false" class="validation-text">Invalid Email</div>
                </ng-container>
                <!-- password -->
                <mat-form-field class="input-style pd-tp">
                    <mat-label class="label-colour">Password</mat-label>
                    <div class="align-items">
                        <input matInput [type]="hidePassword ? 'password' : 'text'" formControlName="passwordControl" (keydown.enter)="login()">
                        <button mat-icon-button matSuffix 
                        (click)="hidePassword = !hidePassword" 
                        [attr.aria-label]="'Hide Password'" 
                        [attr.aria-pressed]="hidePassword" 
                        type="button">
                            <mat-icon>{{hidePassword ? 'visibility_off': 'visibility'}}</mat-icon>
                        </button>
                    </div>
                </mat-form-field>
                <!-- Validation error messages -->
                <ng-container *ngIf="loginFormGroup.controls.passwordControl?.invalid && loginFormGroup.controls.passwordControl?.touched">
                    <div *ngIf="loginFormGroup.controls.passwordControl?.errors?.required ?? false" class="validation-text">Password is required</div>
                </ng-container>
            
        </ng-container>
        <ng-template #otpField>
            <mat-form-field [appearance]="'standard'" class="input-style">
                <mat-label class="label-colour">OTP</mat-label>
                <input matInput formControlName="otpControl" (keydown.enter)="login()">
            </mat-form-field>

        </ng-template>
    </form>
    <div class="button-container">
        <!-- buttons -->
        <div>
            <button class="login-button-style" 
                mat-raised-button 
                (click)="login()" 
                [disabled]="authService.loggingIn$ | async">
                <div class="button-content">
                    <svg-icon 
                        *ngIf="authService.loggingIn$ | async"
                        [svgStyle]="{ 'width.rem': '1' }" 
                        src="/assets/img/icons/spinner.svg" 
                        class="spin">
                    </svg-icon>
                    Log In
                </div>
            </button>
        </div>
        <ng-container *ngIf="!(authService.otpRequired$ | async); else backButton">
            <div class="forgot-style" (click)="emitForgotPassword()">Forgot Password</div>
        </ng-container>
        <ng-template #backButton>
            <div class="forgot-style"></div>
        </ng-template> 
        <div *ngIf="errorService.isInvalidOtp$ | async">
            <button class="otp-resend-style" mat-raised-button (click)="emitResendOTP()">Resend OTP</button>
        </div>
    </div>
</div>