<!-- <div *ngIf="isNewUser" class="explainer-bar-collapser">
  <div class="left" (click)="isExplainerExpanded = !isExplainerExpanded">
    <img src="/assets/img/icons/placeholder-thumbnail.svg" />
    <div class="title">An introduction to Picup</div>
    <mat-icon>expand_more</mat-icon>
  </div>
  <mat-icon (click)="hideExplainer()">close</mat-icon>
</div>
<div *ngIf="isExplainerExpanded" [@fadeInOut] class="explainer-bar">
  <app-carousel>
    <div *ngFor="let item of carouselItems">
      <app-carousel-item (itemClicked)="carouselItemClicked($event)" [item]="item"></app-carousel-item>
    </div>
  </app-carousel>
  <div class="how-to-section">
    <div class="row">
      <div class="img-col"></div>
      <div class="title column">How to</div>
    </div>
    <div class="row">
      <div class="img-col">
        <img src="/assets/img/placeholder-icon.png" />
      </div>
      <div class="column">
        <div class="sub-title">Create your first bucket</div>
        Learn how to create a bucket from order verification through to dispatch.
      </div>
    </div>
    <div class="row">
      <div class="img-col">
        <img src="/assets/img/placeholder-icon.png" />
      </div>
      <div class="column">
        <div class="sub-title">Create your first Picup</div>
        Do you have a single delivery requirement? Learn how to place a single Picup and delivery.
      </div>
    </div>
  </div>
</div> -->
<div class="navigation-bar">
  <div class="navigation-item {{ item === selectedNavigation ? 'active' : '' }}" *ngFor="let item of navigationItems">
    {{ item }}
  </div>
  <div class="filter-bar">
    <div class="filter">
      <div class="filter-title">Date range</div>
      <div class="datepicker-input">
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
          <input readonly class="text-dark" matStartDate formControlName="start" placeholder="Start date" />
          <input readonly class="text-dark" matEndDate formControlName="end" placeholder="End date" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker">
          <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
        </mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </div>
    </div>
    <div class="filter">
      <div class="filter-title">Warehouse/Hub</div>
      <div>
        <button class="custom-menu-btn" [matMenuTriggerFor]="warehouseMenu">
          {{ selectedWarehouse ? (selectedWarehouse | warehouseName: warehouses) : 'Select Warehouse' }}
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
        <mat-menu class="custom-menu" #warehouseMenu="matMenu">
          <input
            (click)="$event.stopPropagation()"
            class="custom-input text-dark"
            [formControl]="warehouseSearch"
            placeholder="Search"
          />
          <button
            (click)="warehouseSelected(warehouse.id)"
            *ngFor="let warehouse of warehouses | filterWarehouses: warehouseSearch.value"
            mat-menu-item
          >
            {{ warehouse.name }}
          </button>
        </mat-menu>
      </div>
    </div>
    <div (click)="resetFilters()" class="reset-btn">
      <div class="btn-flex">
        <button matTooltip="Reset filters" mat-icon-button><mat-icon class="fs-40">tune</mat-icon></button>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
