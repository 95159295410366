import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_NAMED_OPTIONS, NamedOptions } from 'apollo-angular';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { environment } from 'environments/environment';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry'
import { HttpErrorResponse } from '@angular/common/http';
import { AccessManagementService } from './auth/access-management.service';

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: (httpLink: HttpLink, accessManagementService: AccessManagementService): NamedOptions => {
        const basic = setContext(() => ({
          headers: {
            Accept: 'charset=utf-8',
          },
        }));
        const auth = setContext(() => {
          const token = localStorage.getItem('acmToken');
          if (token === null) {
            return {};
          } else {
            return {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            };
          }
        });
        const error = onError(({ networkError, operation, forward, }) => {
          const status = (networkError as HttpErrorResponse).status;
          if (status === 401) {
            accessManagementService.refreshToken().then((response) => {
              accessManagementService.setACMToken(response);
              return forward(operation);
            });
          }
        });
        const retry = new RetryLink({
          attempts: {
            max: 1
          },
          delay: {
            initial: 200
          }
        });
        return {
          [environment.graphQL.costing.clientName]: {
            cache: new InMemoryCache(),
            link: ApolloLink.from([error, retry, basic, auth, httpLink.create({ uri: environment.graphQL.costing.url })]),
          },
          [environment.graphQL.financials.clientName]: {
            cache: new InMemoryCache(),
            link: ApolloLink.from([error,retry, basic, auth, httpLink.create({ uri: environment.graphQL.financials.url })]),
          },
          [environment.graphQL.accessManagement.clientName]: {
            cache: new InMemoryCache(),
            link: ApolloLink.from([error, retry, basic, auth, httpLink.create({ uri: environment.graphQL.accessManagement.url })]),
          },
        };
      },
      deps: [HttpLink, AccessManagementService],
    },
  ],
})
export class GraphQLModule {}
