<div class="header">
  <app-breadcrumbs></app-breadcrumbs>

  <app-dropdown
    [activeItem]="
      router.url.includes('admin') || router.url.includes('last-mile')
        ? 'Admin View'
        : actingAs?.name
        ? actingAs.name
        : user.name
    "
    class="header-business-select"
    (click)="clearSearch()"
  >
    <input-field
      *ngIf="this.businesses && this.businesses.length > 5"
      [type]="'text'"
      [placeholder]="'Search...'"
      [value]="searchString"
      [autoComplete]="'off'"
      (valueChanged)="searchString = $event"
    ></input-field>
    <a *ngIf="user?.is_admin" routerLink="/admin/post-dispatch/dispatch" class="dropdown-item admin">Admin View</a>
    <a class="dropdown-item user" (click)="selectContext(null)">{{ user.name }}</a>
    <div *ngIf="this.businesses && this.businesses.length > 0" class="spacer">
      <hr />
    </div>
    <a
      *ngFor="let business of businesses | stringFilterPipe: searchString:['name']"
      class="dropdown-item"
      (click)="selectContext(business.business_id)"
    >
      {{ business.name }}
    </a>
  </app-dropdown>

  <div class="header-user">
    <div class="header-user-text">
      <h6>{{ user?.name }}</h6>
      <p>{{ user?.role }}</p>
    </div>

    <div *ngIf="wallet && actingAs.payment_terms === 'prepaid'" class="header-user-credits">
      <h6>{{ wallet?.credits | currency: currency_symbol + ' ' }}</h6>
    </div>

    <div class="avatar">
      <ngx-avatars
        class="name"
        [name]="user?.name"
        [src]="user?.avatar"
        alt="Contact Person"
        size="36"
        (clickOnAvatar)="navigateAlerts()"
      ></ngx-avatars>
      <div class="notification" *ngIf="businessInvites.length > 0">
        {{ businessInvites.length }}
      </div>
      <div *ngIf="isInviteMenuOpen" class="dropdown-list">
        <label *ngIf="businessInvites.length > 0" class="dropdown-item">Pending Invites:</label>
        <label *ngIf="businessInvites.length === 0" class="dropdown-item">No Pending Invitations</label>
        <div class="spacer" *ngIf="businessInvites.length > 0">
          <hr />
        </div>
        <a *ngFor="let invite of businessInvites" class="dropdown-item" (click)="acceptInvite(invite.business_id)">{{
          invite.business_name
        }}</a>
      </div>
    </div>
  </div>
</div>
