import { IconTypes } from './../../../shared/icon/icon.interfaces';
import { UiColors } from './../../../interfaces/ui.interfaces';
import { CourierOrdersService } from './../courier-orders.service';
import { Component, Input, OnInit, SimpleChange, OnChanges } from '@angular/core';
import { mfHumanizeEventString } from 'app/shared/table/map-formatters.table.helpers';
import { TableColumn, TableDataMap } from 'app/shared/table/table.interfaces';
import { OrderTrackingEvent } from './courier-orders-timeline.interface';

@Component({
  selector: 'app-courier-orders-timeline',
  templateUrl: './courier-orders-timeline.component.html',
  styleUrls: ['./courier-orders-timeline.component.scss'],
})
export class CourierOrdersTimelineComponent implements OnInit, OnChanges {
  @Input() orderId: string;
  @Input() refresh = false;
  @Input() orderWaybillNumber: string;
  parcelList = [];
  loading: boolean = false;

  timelineCourierRawData: OrderTrackingEvent[];

  UiColors = UiColors;
  IconTypes = IconTypes;

  timelineCourierColumns: TableColumn[] = [
    { name: 'parcel_waybill', displayName: 'Waybill' },
    { name: 'created_at', displayName: 'Created' },
    { name: 'event_name', displayName: 'Event' },
    { name: 'event_arg1', displayName: 'Event Arg 1' },
    { name: 'event_arg2', displayName: 'Event Arg 2' },
  ];
  timelineCourierDataMap: TableDataMap = {
    cells: {
      parcel_waybill: { column: 'parcel_waybill', map: 'ParcelWaybillNumber' },
      created_at: { column: 'created_at', map: 'Timestamp', displayPipeArgs: ['date', 'medium'] },
      event_name: {
        column: 'event_name',
        map: 'EventDescription',
        format: mfHumanizeEventString,
      },
      event_arg1: { column: 'event_arg1', map: 'EventArg1' },
      event_arg2: { column: 'event_arg2', map: 'EventArg2' },
    },
  };

  constructor(private courierOrdersService: CourierOrdersService) {}

  ngOnInit(): void {
    this.loading = true;
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes.refresh && changes.refresh.currentValue === changes.refresh.previousValue) {
      return;
    }
    this.getTrackingEvents();
  }

  getTrackingEvents(): void {
    this.courierOrdersService.getOrderTrackingEvents(this.orderId).then((response) => {
      const initialData: OrderTrackingEvent[] = response.value;
      this.timelineCourierRawData = initialData.filter(
        (event) => event.ParcelWaybillNumber.split('_')[0] === this.orderWaybillNumber
      );
      this.timelineCourierRawData.forEach((event) => {
        if (this.parcelList.indexOf(event.ParcelWaybillNumber) === -1) {
          this.parcelList.push(event.ParcelWaybillNumber);
        }
      });
      this.loading = false;
    });
  }
}
