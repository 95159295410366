import { MaintenanceService } from './../../../maintenance.service';
import { UserRoles } from './../../../interfaces/auth.interfaces';
import { selectorActingAs } from './../../../auth/auth.reducer';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectorUser } from 'app/auth/auth.reducer';
import { vehicleTypes, vehicleNames } from 'app/dashboard/new-trips/new-trips.constants';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { JustifyContent } from 'app/shared/flex-container/flex-container.interfaces';
import { GridJustifyItems } from 'app/shared/grid-container/grid-container.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { ModalSizes } from 'app/shared/modals/modal.interfaces';
import { ActingAs } from 'app/interfaces/auth.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { DriverDetails } from 'app/dashboard/drivers/drivers.interface';
import { Subscription } from 'rxjs';
import { DriverService } from 'app/driver/driver.service';

@Component({
  selector: 'app-last-mile-driver-details',
  templateUrl: './last-mile-driver-details.component.html',
  styleUrls: ['./last-mile-driver-details.component.scss'],
})
export class LastMileDriverDetailsComponent implements OnInit, OnDestroy {
  @Input() driverId: string;
  @Input() lastMileId: string;
  @Input() orderId: string;

  driverDetails: DriverDetails;
  user;
  actingAs: ActingAs;
  public UserRoles = UserRoles;
  public IconTypes = IconTypes;
  public UiColors = UiColors;
  buttonTypes = ButtonTypes;
  public ModalSizes = ModalSizes;
  public UiThemes = UiThemes;
  public JustifyContent = JustifyContent;
  GridJustifyItems = GridJustifyItems;

  public vehicleTypes = vehicleTypes;
  public vehicleNames = vehicleNames;

  processing = false;
  driverSubscription: Subscription;

  constructor(
    private router: Router,
    public store: Store<any>,
    private maintenanceService: MaintenanceService,
    private driverService: DriverService
  ) {
    // Only want to show the driver profile if admin
    this.store.select(selectorUser).subscribe((next) => (this.user = next));
    this.store.select(selectorActingAs).subscribe((next) => (this.actingAs = next));
  }

  ngOnInit(): void {
    this.getDriver();
  }

  ngOnDestroy(): void {
    this.driverSubscription?.unsubscribe();
  }

  getDriver(): void {
    this.driverSubscription = this.driverService.getDriverDetails(this.driverId).subscribe((val) => {
      this.driverDetails = val;
    });
  }

  viewDriver(): void {
    this.router.navigate([`${this.user.is_admin ? '/admin/drivers/driver/' : '/dashboard/manage-fleet/drivers/'}${this.driverId}`]);
  }

  rehydrateRM(): void {
    this.processing = true;
    this.maintenanceService
      .rehydrateFirestoreReadmodel(this.lastMileId, this.orderId)
      .then(() => (this.processing = false))
      .catch(() => (this.processing = false));
  }
}
