<!-- TODO: ccheck appearance -->
<mat-form-field [appearance]="'standard'"> 
    <mat-label>{{label}}</mat-label>
    <mat-date-range-input [min]="minDate" [max]="maxDate" [rangePicker]="picker">
      <input matStartDate placeholder="Start date">
      <input matEndDate placeholder="End date">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker">
        <mat-icon matDatepickerToggleIcon>
            <app-icon
            [color]="color"
            [size]="1.5"
            [icon]="icon"
            ></app-icon>
        </mat-icon>
    </mat-datepicker-toggle>
    <mat-date-range-picker #picker>
      <mat-date-range-picker-actions>
        <button matDateRangePickerCancel>Cancel</button>
        <button color="primary" matDateRangePickerApply>Apply</button>
      </mat-date-range-picker-actions>
    </mat-date-range-picker>
  </mat-form-field>
