import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isDescriptionEnabled'
})
export class IsDescriptionEnabledPipe implements PipeTransform {

  transform(enabledDescriptions: number[], indexNumber: number): boolean {
    return enabledDescriptions.findIndex(x => x === indexNumber) >= 0 ?? false;
  }

}
