<label *ngIf="label" [className]="'label-'+ labelTheme">{{label}}</label>
<div [style]="styles" #dropdown [className]="'dropdown dropdown-'+ dropdownTheme" [ngClass]="{'dropdown-open': isMenuOpen, 'disabled':disabled}"
  [class.disabled]="disabled">
  <div (click)="toggleMenu()" class="dropdown-toggle" [class.disabled]="disabled">
    {{activeItem}}
    <div *ngIf="useTooltip && !isMenuOpen">
      <app-icon appTooltip [tooltipText]="tooltipText" [icon]="icon" [color]="iconColor" [size]="iconSize"></app-icon>
    </div>
  </div>
  <div *ngIf="isMenuOpen" [ngClass]="dropdownClass">
    <ng-content></ng-content>
  </div>
</div>