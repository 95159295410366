// Modules
import { SharedModule } from '../../shared/shared.module';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { StoreModule } from '@ngrx/store';

// // Effects
// import { EffectsModule } from '@ngrx/effects';
// import { ManageBucketEffects } from './manage-bucket.effects';

// Reducer
import { ManageBucketReducer } from './manage-bucket.reducer';

// Services
import { ManageBucketService } from './manage-bucket.service';
import { TripCostsService } from './manage-bucket/trip-costs/trip-costs.service';

// Components
import { CostingSettingsComponent } from './manage-bucket/costing-settings/costing-settings.component';
import { TripCostsComponent } from './manage-bucket/trip-costs/trip-costs.component';
import { ViewTripComponent } from './manage-bucket/trip-costs/view-trip/view-trip.component';
import { VerifyComponent } from './manage-bucket/verify/verify.component';
import { VerifyMapComponent } from './manage-bucket/verify/map/verify-map.component';
import { ConfirmationComponent } from './manage-bucket/confirmation/confirmation.component';
import { AutoGeocoderComponent } from './manage-bucket/auto-geocoder/auto-geocoder.component';
import { ImportingComponent } from './manage-bucket/importing/importing.component';
import { CostingComponent } from './manage-bucket/costing/costing.component';
import { OrderSelectionComponent } from './manage-bucket/order-selection/order-selection.component';
import { OrderSelectionService } from './manage-bucket/order-selection/order-selection.service';
import { OverviewComponent } from './overview/overview.component';
import { StatusComponent } from './overview/status/status.component';
import { ProgressComponent } from './overview/progress/progress.component';
import { ChangeBucketComponent } from './manage-bucket/verify/change-bucket/change-bucket.component';
import { VerifyTableComponent } from './manage-bucket/verify/verify-table/verify-table.component';
import { CrossdockComponent } from './manage-bucket/verify/crossdock/crossdock.component';
import { ModifyBucketOrderComponent } from './modify-bucket-order/modify-bucket-order.component';
import { StagedOrdersComponent } from './staged-orders/staged-orders.component';
import { ReceiveStagedOrdersComponent } from './receive-staged-orders/receive-staged-orders.component';
import { EditBucketComponent } from './manage-bucket/verify/edit-bucket/edit-bucket.component';
import { CommonModule } from '@angular/common';
import { ConsignRadiusComponent } from './manage-bucket/verify/consign-radius/consign-radius.component';
import { ManualRoutingComponent } from './manage-bucket/verify/manual-routing/manual-routing.component';
import { PostDispatchModule } from '../post-dispatch/post-dispatch.module';
import { BucketCardComponent } from './overview/bucket-card/bucket-card.component';
import { ContractorSettingsComponent } from './manage-bucket/costing-settings/contractor-settings/contractor-settings.component';
import { DriverSelectModalComponent } from './manage-bucket/costing-settings/driver-select-modal/driver-select-modal.component';
import { EffectsModule } from '@ngrx/effects';
import { ManageBucketEffects } from './manage-bucket.effects';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    PostDispatchModule,
    StoreModule.forFeature('buckets', { manageBuckets: ManageBucketReducer }),
    EffectsModule.forFeature([ManageBucketEffects]),
  ],
  declarations: [
    CostingSettingsComponent,
    TripCostsComponent,
    ViewTripComponent,
    VerifyComponent,
    VerifyMapComponent,
    ConfirmationComponent,
    AutoGeocoderComponent,
    CostingComponent,
    ImportingComponent,
    OrderSelectionComponent,
    OverviewComponent,
    StatusComponent,
    ProgressComponent,
    ChangeBucketComponent,
    VerifyTableComponent,
    CrossdockComponent,
    ModifyBucketOrderComponent,
    StagedOrdersComponent,
    ReceiveStagedOrdersComponent,
    EditBucketComponent,
    ConsignRadiusComponent,
    ManualRoutingComponent,
    BucketCardComponent,
    ContractorSettingsComponent,
    DriverSelectModalComponent,
  ],
  exports: [
    SharedModule,
    CostingSettingsComponent,
    TripCostsComponent,
    ViewTripComponent,
    VerifyComponent,
    VerifyMapComponent,
    VerifyTableComponent,
    ConfirmationComponent,
    AutoGeocoderComponent,
    CostingComponent,
    ImportingComponent,
    OrderSelectionComponent,
    OverviewComponent,
    StatusComponent,
    ProgressComponent,
    CrossdockComponent,
    ModifyBucketOrderComponent,
    StagedOrdersComponent,
    BucketCardComponent,
  ],
  providers: [ManageBucketService, TripCostsService, OrderSelectionService],
  entryComponents: [],
  schemas: [NO_ERRORS_SCHEMA],
})
export class ManageBucketModule {
  constructor() { }
}
