import { Component, Input } from '@angular/core';
import { ImageWithCaptionProps } from './image-with-caption.interfaces';
import { UiColors } from '../../interfaces/ui.interfaces';
import {IconTypes} from '../icon/icon.interfaces';

@Component({
  selector: 'app-image-with-caption',
  templateUrl: './image-with-caption.component.html',
  styleUrls: ['./image-with-caption.component.scss']
})
export class ImageWithCaptionComponent implements ImageWithCaptionProps {
  @Input() widthRem: number;
  @Input() heightRem: number;
  @Input() paddingRem: number;
  @Input() src: string;
  @Input() download: boolean = false;
  @Input() showCaption: boolean = true;
  @Input() bgColor: UiColors = UiColors.Grey1;

  public UiColors = UiColors;
  public IconTypes = IconTypes;
}
