import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'flexPercentage'
})
export class FlexPercentagePipe implements PipeTransform {

  transform(partialValue: number = 0, totalValue = 0): number {
    return (100 * partialValue) / totalValue;
  }

}
