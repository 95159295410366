import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'activationDate'
})
export class ActivationDatePipe implements PipeTransform {

  transform(activationDate: firebase.default.firestore.Timestamp | null, scheduledDate: firebase.default.firestore.Timestamp | null): number {
    if (activationDate) {
      return activationDate.toMillis();
    }

    if (scheduledDate) {
      return scheduledDate.toMillis();
    }
  }

}
