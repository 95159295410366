import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { selectorWarehouses } from 'app/auth/auth.reducer';
import { Warehouse } from 'app/interfaces/auth.interfaces';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { DashboardFilters, FleetEnum } from './home.interface';
import { HomeService } from './services/home.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnDestroy {
  navigationItems: string[] = ['Overview'];
  selectedNavigation: string = 'Overview';
  range = new FormGroup({
    start: new FormControl(moment().subtract(3,'d').toDate()),
    end: new FormControl(new Date()),
  });
  warehouses: Warehouse[] = [];
  selectedWarehouse: string = null;
  warehouseSearch: FormControl = new FormControl('');
  selectedFleet: string = '';
  fleets: string[] = Object.values(FleetEnum);
  dashboardFilters: DashboardFilters;
  filterSubscription: Subscription;
  formSubscription: Subscription;
  storeSubscription: Subscription;
  constructor(private homeService: HomeService, private store: Store) {
    this.filterSubscription = this.homeService.dashboardFilters$.subscribe((f) => {
      this.dashboardFilters = f;
    });
    this.formSubscription = this.range.valueChanges.subscribe((x) => {
      if (x.end) {
        this.homeService.setDashboardFilters({ ...this.dashboardFilters, dateFrom: x.start, dateTo: x.end });
      }
    });
    this.storeSubscription = this.store.select(selectorWarehouses).subscribe((next) => (this.warehouses = next));
  }

  ngOnDestroy(): void {
    this.filterSubscription?.unsubscribe();
    this.formSubscription?.unsubscribe();
    this.storeSubscription?.unsubscribe();
  }

  warehouseSelected(warehouseId: string): void {
    this.selectedWarehouse = warehouseId;
    this.homeService.setDashboardFilters({ ...this.dashboardFilters, warehouseId });
  }

  resetFilters(): void {
    this.selectedWarehouse = '';
    this.range.controls['start'].setValue(moment().subtract(7,'d').toDate());
    this.range.controls['end'].setValue(new Date());
  }
}
