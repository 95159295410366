import { Component, OnInit } from '@angular/core';
import { ModalSizes, ModalAction } from 'app/shared/modals/modal.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { DriverLoadService } from '../driver-load.service';
import axios from 'app/api/axios';
import { UiColors } from 'app/interfaces/ui.interfaces';

@Component({
  selector: 'app-action-parcels',
  templateUrl: './action-parcels.component.html',
  styleUrls: ['./action-parcels.component.scss'],
})
export class ActionParcelsComponent extends SimpleModalComponent<any, any> implements OnInit {
  public ModalSizes = ModalSizes;
  public iconTypes = IconTypes;
  uiColors= UiColors;
  public actions: ModalAction[] = [
    { name: 'close', title: 'Close', isPrimary: false },
    { name: 'confirm', title: 'Confirm', isPrimary: true },
  ];

  order_id;
  active_parcels;
  failure_reasons;
  resultOptions = new Map<string, string>([
    ['Collect', 'Collect'],
    ['FailCollection', 'Failed Collection'],
    ['Deliver', 'Deliver'],
    ['FailDelivery', 'Failed Delivery'],
    ['Cancel', 'Cancel'],
    ['None', 'None'],
  ]);

  parcelActions = [];
  constructor(private driverLoadService: DriverLoadService) {
    super();
  }

  ngOnInit(): void {
    this.getActiveParcels();
    this.getFailureReasons();
  }

  showOption(parcel_state, option) {
    const delivered = parcel_state === 'Completed' || parcel_state === 'Cancelled';
    const collected =
      parcel_state === 'Collected' ||
      parcel_state === 'OutForDelivery' ||
      parcel_state === 'Completed' ||
      parcel_state === 'Cancelled';
    switch (option) {
      case 'Collect':
        return !collected;
      case 'FailCollection':
        return !collected;
      case 'Deliver':
        return !delivered;
      case 'FailDelivery':
        return !delivered;
      case 'Cancel':
        return !delivered;
      case 'None':
        return true;
    }
  }

  handleAction($event) {
    switch ($event) {
      case 'close':
        this.close();
        break;
      case 'confirm':
        this.active_parcels.forEach((parcel) => {
          if (parcel.result) {
            this.parcelActions.push({
              parcel_waybill: parcel.parcel_waybill,
              result: parcel.result,
            });
          }
        });
        // this.result = { parcels: this.parcelActions, order_id: this.order_number }
        this.result = this.parcelActions;
        this.close();
        break;
    }
  }

  getActiveParcels() {
    axios({
      method: 'GET',
      url: 'order/' + this.order_id + '/get-active-parcels/',
    }).then((response) => {
      this.active_parcels = response?.data;
    });
  }

  getFailureReasons() {
    this.driverLoadService.getFailureReasons().then((response) => (this.failure_reasons = response));
  }
}
