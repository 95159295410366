import { AuthUser, ActingAs, Warehouse, UserPermissions, CourierIntegrationOrderRecreateConfig, UberEnabledWarehousesConfig } from '../interfaces/auth.interfaces';
import { } from '../interfaces/businesses.interfaces';
import { StoreAction } from '../interfaces/auth.interfaces';
import { Action } from '@ngrx/store';

export interface State {
  user: AuthUser;
  userAccess: any;
  businesses: [];
  warehouses: Warehouse[];
  auth_token: any;
  actingAs: ActingAs;
  isAuthenticated: boolean;
  courierIntegrationOrderRecreateConfig: CourierIntegrationOrderRecreateConfig;
}

export interface authState {
  auth: State;
}

// Initial State
export const initialState: State = {
  user: null,
  businesses: null,
  warehouses: null,
  auth_token: null,
  actingAs: null,
  userAccess: null,
  isAuthenticated: false,
  courierIntegrationOrderRecreateConfig: null
};

// Pretend this is dynamically injected at runtime
const initialStateFromSomewhere = JSON.parse(localStorage?.getItem('state'));

// In this function dynamic state slices, if they exist, will overwrite static state at runtime.
export function getInitialState() {
  if (initialStateFromSomewhere !== undefined && initialStateFromSomewhere !== null) {
    const state = initialStateFromSomewhere.auth;
    const isAuthenticated = state?.isAuthenticated;
    return { ...initialState, ...state, ...isAuthenticated };
  } else {
    return { ...initialState };
  }
}

export const SET_BUSINESSES = 'SET_BUSINESSES';
export const SET_ACCOUNTS = 'SET_ACCOUNTS';
export const SET_USER = 'SET_USER';
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const SET_ACTING_AS = 'SET_ACTING_AS';
export const SET_USER_ACCESS_ROLES = 'SET_USER_ACCESS_ROLES';
export const SET_WAREHOUSES = 'SET_WAREHOUSES';
export const SET_IS_AUTHENTICATED = 'SET_IS_AUTHENTICATED';
export const SET_LAST_MILE_COLLECTION_NAME = 'SET_LAST_MILE_COLLECTION_NAME';
export const SET_COURIER_INTEGRATION_CONFIG = 'SET_COURIER_INTEGRATION_CONFIG';
export const SET_UBER_ENABLED_WAREHOUSES_CONFIG = 'SET_UBER_ENABLED_WAREHOUSES_CONFIG';


export const selectorAuth = (state) => state.auth;
export const selectorActingAs = (state): ActingAs => state.auth.actingAs;
export const selectorUser = (state): AuthUser => state.auth.user;
export const selectorBusinesses = (state) => state.auth.businesses;
export const selectorUserPermissions = (state): UserPermissions => state.auth.userAccess;
export const selectorWarehouses = (state): Warehouse[] => state.auth.warehouses;
export const isUserAuthenticated = (state) => state.auth.isAuthenticated;
export const selectCourierIntegrationOrderRecreateConfig = (state) => state.auth.courierIntegrationOrderRecreateConfig;
export const selectUberEnabledWarehousesConfig = (state) => state.auth.uberEnabledWarehousesConfig;
export const selectLastMileCollectionName = (state): string => state.auth.lastMileCollectionName;



export const setAuthToken = (token: any) => ({
  type: SET_AUTH_TOKEN,
  payload: token,
});

export const setActingAs = (account: any) => ({
  type: SET_ACTING_AS,
  payload: account,
});

export const setUser = (options: AuthUser) => ({
  type: SET_USER,
  payload: options,
});

export const setAccounts = (options: []) => ({
  type: SET_ACCOUNTS,
  payload: options,
});

export const setBusinesses = (options: any) => ({
  type: SET_BUSINESSES,
  payload: options,
});

export const setIsAuthenticated = (options: boolean) => ({
  type: SET_IS_AUTHENTICATED,
  payload: options,
});

export const setLastMileCollectionName = (name: string) => ({
  type: SET_LAST_MILE_COLLECTION_NAME,
  payload: name
})

export class setWarehouses implements Action {
  readonly type = SET_WAREHOUSES;
  constructor(public payload: string) { }
}

export class setUserAccessRoles implements Action {
  readonly type = SET_USER_ACCESS_ROLES;
  constructor(public payload: string) { }
}

export class setCourierIntegrationConfig implements Action {
  readonly type = SET_COURIER_INTEGRATION_CONFIG;
  constructor(public payload: CourierIntegrationOrderRecreateConfig) { }
}

export class setUberEnabledWarehousesConfig implements Action {
  readonly type = SET_UBER_ENABLED_WAREHOUSES_CONFIG;
  constructor(public payload: UberEnabledWarehousesConfig) { }
}

export function authReducer(state: State = getInitialState(), action: StoreAction) {
  console.log(action);
  switch (action.type) {
    case SET_USER:
      return Object.assign({}, state, {
        user: <AuthUser>action.payload.user,
      });
    case SET_ACCOUNTS:
      return Object.assign({}, state, {
        user: <AuthUser>action.payload.user,
        businesses: <[]>action.payload.businesses,
        isAuthenticated: true,
      });
    case SET_AUTH_TOKEN:
      return Object.assign({}, state, {
        auth_token: action.payload,
      });
    case SET_ACTING_AS:
      return Object.assign({}, state, {
        actingAs: action.payload,
      });
    case SET_BUSINESSES:
      return Object.assign({}, state, {
        businesses: action.payload.businesses,
      });
    case SET_WAREHOUSES:
      return {
        ...state,
        warehouses: action.payload,
      };
    case SET_USER_ACCESS_ROLES:
      return {
        ...state,
        userAccess: action.payload,
      };
    case SET_IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case SET_COURIER_INTEGRATION_CONFIG:
      return {
        ...state,
        courierIntegrationOrderRecreateConfig: action.payload,
      };
    case SET_UBER_ENABLED_WAREHOUSES_CONFIG:
      return {
        ...state,
        uberEnabledWarehousesConfig: action.payload,
      };
    case SET_LAST_MILE_COLLECTION_NAME:
      return {
        ...state,
        lastMileCollectionName: action.payload,
      };
    default:
      return state;
  }
}
