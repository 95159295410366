<div class="button-wrap">
  <app-page-filter-buttons
    *ngIf="sectionIds"
    (sectionSelected)="selectedSection = $event"
    [selectedSection]="selectedSection"
    [sectionIds]="sectionIds"
  ></app-page-filter-buttons>
  <div class="buttons">
    <app-basic-button [title]="'Delete Business'" [type]="buttonTypes.Warning" (click)="deleteBusiness()">
    </app-basic-button>
    <app-basic-button *ngIf="user.is_admin" [title]="'Package'" [type]="buttonTypes.Primary" (click)="changePackage()">
    </app-basic-button>
    <app-basic-button *ngIf="user.is_admin" [title]="'Add Credits'" [type]="buttonTypes.Primary" (click)="addCredits()">
    </app-basic-button>
    <app-basic-button *ngIf="user.is_admin" [title]="'View Ledger'" [type]="buttonTypes.Primary" (click)="viewLedger()">
    </app-basic-button>
    <app-basic-button
      *ngIf="user.is_admin"
      [title]="'Create Business'"
      [type]="buttonTypes.Highlight"
      (click)="createBusiness()"
    >
    </app-basic-button>
  </div>
</div>

<app-section *ngIf="(selectedSection === 'General' || selectedSection === undefined) && business_model">
  <heading [icon]="IconTypes.Gear" [color]="uiColors.Blue">
    <h4 class="heading-title">General</h4>
  </heading>
  <app-grid-container
    [columns]="'repeat(4, 1fr)'"
    [padding]="'3rem 5rem'"
    [colGap]="'2rem'"
    [rowGap]="'2rem'"
    [theme]="UiThemes.Light"
  >
    <input-field
      [maxLength]="50"
      [type]="'text'"
      (valueChanged)="business_model.name = $event"
      [value]="business_model.name"
      [label]="'Company Name'"
      [autoComplete]="'off'"
    ></input-field>
    <input-field
      [type]="'tel'"
      (valueChanged)="business_model.phone = $event"
      [value]="business_model.phone"
      [label]="'Work Number'"
      [autoComplete]="'off'"
    ></input-field>
    <input-field
      [type]="'text'"
      (valueChanged)="business_model.vat_number = $event"
      [value]="business_model.vat_number"
      [label]="'VAT Number'"
      [autoComplete]="'off'"
    ></input-field>
    <input-field
      [type]="'text'"
      (valueChanged)="business_model.registration_number = $event"
      [value]="business_model.registration_number"
      [label]="'Reg Number'"
      [autoComplete]="'off'"
    ></input-field>
    <app-pill-input
      *ngIf="canAccessLocations()"
      [items]="business_model.supported_geocode_country_codes"
      [dropdownItems]="filteredCodes"
      [label]="'locations (country code)'"
      (newItemEvent)="addNewCountryCode($event)"
      (clearAllEvent)="clearCountryCodes()"
      (clearItemEvent)="clearCountryCode($event)"
      (valueChanged)="filterCountryCodes($event)"
    ></app-pill-input>
  </app-grid-container>
  <div class="invalidmessage" *ngIf="phoneNotValid">
    <p>Your work number is invalid</p>
  </div>
  <div class="controls" *ngIf="!hasChanged()">
    <app-basic-button
      [title]="'Save Changes'"
      [active]="true"
      [type]="buttonTypes.Highlight"
      (click)="updateBusiness()"
    >
    </app-basic-button>
    <app-basic-button [title]="'Cancel'" [type]="buttonTypes.Primary" (click)="cancel()"> </app-basic-button>
  </div>
</app-section>

<app-buy-credits *ngIf="(selectedSection === 'Credits' || selectedSection === undefined) && showCredits">
</app-buy-credits>

<app-invoices [context]="'business'" *ngIf="selectedSection === 'Invoices' || selectedSection === undefined">
</app-invoices>

<app-integration-details
  (reload)="reload()"
  [actingAs]="actingAs"
  [businessDetails]="business_details"
  *ngIf="showIntegration && (selectedSection === 'Integration' || selectedSection === undefined)"
>
</app-integration-details>

<app-address-book [context]="'business'" *ngIf="selectedSection === 'Addresses' || selectedSection === undefined">
</app-address-book>
