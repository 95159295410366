import { EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Component, ElementRef, OnInit } from '@angular/core';
import { UiThemes } from 'app/interfaces/ui.interfaces';
import * as noUiSlider from 'nouislider';

@Component({
  selector: 'app-slider-value',
  templateUrl: './slider-value.component.html',
  styleUrls: ['./slider-value.component.scss'],
})
export class SliderValueComponent implements OnInit {
  @ViewChild('rangeSlider', { static: true }) slider: ElementRef;
  @Output() sliderResult = new EventEmitter<any>();
  @Input() divisor: number;
  @Input() nameText: string;
  @Input() measure: string;
  @Input() start: number;
  @Input() maxValue: number;
  @Input() minValue: number;
  @Input() step: number;

  public UiThemes = UiThemes;
  sliderResultView;

  constructor() {}

  ngOnInit(): void {
    noUiSlider.create(this.slider.nativeElement, {
      start: this.start,
      range: {
        min: this.minValue,
        max: this.maxValue,
      },
      step: this.step,
    });

    this.slider.nativeElement.noUiSlider.on('update', (values) => {
      this.sliderResultView = Math.ceil(values);
      this.sliderResult.emit(Math.ceil(values));
    });
  }
}
