import { Component, Input, OnInit } from '@angular/core';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { environment } from 'environments/environment';
import { Bucket } from '../../buckets.interfaces';
import { ManageBucketService } from '../../manage-bucket.service';
import * as moment from 'moment';

@Component({
  selector: 'app-bucket-card',
  templateUrl: './bucket-card.component.html',
  styleUrls: ['./bucket-card.component.scss'],
})
export class BucketCardComponent implements OnInit {
  @Input() bucket: Bucket;
  @Input() admin = false;

  IconTypes = IconTypes;
  UiColors = UiColors;
  environment = environment;
  constructor(private manageBucketService: ManageBucketService) {}

  ngOnInit(): void {}

  checkExpiry(delivery_date: string, status: string): boolean {
    const today = new Date();
    const date = new Date(delivery_date);
    return today > date && status === 'Completed';
  }

  getDays(start: string, end: string): number {
    const end_date = moment(end);
    const start_date = moment(start);
    const diff = end_date.diff(start_date, 'days') + 1;
    return diff;
  }
  selectBucket(bucket: Bucket): void {
    this.manageBucketService.setActiveBucket(bucket.bucket_id);
  }
}
