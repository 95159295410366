export enum MapTypes {
  Tracking = 'tracking',
  Drivers = 'drivers',
  Trips = 'trips',
  Vetting = 'vetting',
  Overview = 'overview',
  Fleet = 'fleet',
  ViewFreight = 'viewFreight',
  Crossdocks = 'crossdocks',
  Zones = 'zones',
  Alerts = 'alerts'
}

export interface MapDrawer {
  active: boolean,
  width: string,
  edge: 'right' | 'left'
}

export interface viewMapMarker {
  center: google.maps.LatLngLiteral,
  type: MarkerTypesEnum,
  business_reference: string
}

export enum MarkerTypesEnum {
  VETTED = 'VETTED',
  SKIPPED = 'SKIPPED',
  UNVERIFIED = 'UNVERIFIED'
}
