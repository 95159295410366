  <!-- Manual Assignment Orders -->
  <!-- TODO   [activeWarehouseFilter]="activeWarehouseFilter" -->
  <app-collapsible-order-table
  *ngIf="manualAssignmentFirstoreTrips | async as manualAssignmentTrips"
  [tripType]="'Manual Assignment Trips'"
  [trips]="manualAssignmentTrips"
  [tripsTotal]="manualAssignmentTotal"
  [objectType]="CollapsibleTableTypeEnum.Order"
  [icon]="Icons.Types.Trip"
  [color]="Icons.Colors.Yellow"
  [tableDataMap]="fsOrderTableDataMap"
  [tableColumns]="fsOrderTableColumns"
  [primaryActions]="postDispatchTablePrimaryActions"
  [defaultDisplayAmount]="manualAssignmentAmount"
  [showPagination]="true"
  (paginateEvent)="handleManualAssignmentTrips($event)"
  (tableSortChanged)="handleTripsSortChange($event)"
  >
  </app-collapsible-order-table>
