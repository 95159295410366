import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';
import { Apollo, gql } from 'apollo-angular';
import { GetUserByIdResponse } from 'app/billing/financials.interface';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import {
  GetAccessManagementBusinessResponse,
  GetAccessManagementUserVendorsResponse,
  GetAccessManagementVendorDriversResponse,
  GetAccessManagementVendorsResponse,
} from './access-management.interface';

@Injectable({
  providedIn: 'root'
})
export class AccessManagementGqlService {

  constructor(private apollo: Apollo) { }

  userById(userUid: string): Observable<ApolloQueryResult<GetUserByIdResponse>> {
    return this.apollo.use(environment.graphQL.accessManagement.clientName).query({
      query: gql`
      {
        users (where: {
          uid: {
            eq: ${JSON.stringify(userUid)}
          }
        }) {
          nodes {
            fullName
          }
        }
      }`,
    });
  }

  getVendorDrivers(vendorUid: string): Observable<ApolloQueryResult<GetAccessManagementVendorDriversResponse>> {
    return this.apollo.use(environment.graphQL.accessManagement.clientName).query({
      fetchPolicy: 'no-cache',
      query: gql`
      {
        vendors(where: { uid: { eq: ${JSON.stringify(vendorUid)} } }) {
          nodes {
            drivers {
              uid
              user {
                fullName
                phone
              }
            }
          }
        }
      }`,
    });
  }

  getBusinessWithUid(uid: string): Observable<ApolloQueryResult<GetAccessManagementBusinessResponse>> {
    return this.apollo.use(environment.graphQL.accessManagement.clientName).query({
      query: gql`
      {
        businesses (where: {
          uid: {
            eq: ${JSON.stringify(uid)}
          }
        }) {
          nodes {
            id
            uid
            name
          }
        }
      }
      `,
    });
  }

  getVendors(): Observable<ApolloQueryResult<GetAccessManagementVendorsResponse>> {
    return this.apollo.use(environment.graphQL.accessManagement.clientName).query({
      fetchPolicy: 'no-cache',
      query: gql`
        {
          vendors {
            nodes {
              uid
              name
              id
            }
          }
        }
      `,
    });
  }

  getUserVendors(userUid: string): Observable<ApolloQueryResult<GetAccessManagementUserVendorsResponse>> {
    return this.apollo.use(environment.graphQL.accessManagement.clientName).query({
      query: gql`
      {
        vendorUsers (where: {
          user: {
            uid: {
              eq: ${JSON.stringify(userUid)}
            }
          }
        }) {
          nodes {
            vendor {
              id
              uid
              name
            }
          }
        }
      }
      `,
    });
  }
}
