import { Component, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { NotificationsService } from 'app/shared/notifications/notifications.service';
import { NotificationTypeEnum } from 'app/shared/shared.interfaces';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  oobCode: string = '';
  showPassword: boolean = false;
  IconTypes = IconTypes;
  UiColors = UiColors;
  invalidResetLink: boolean = false;
  resetPasswordForm = new FormGroup({
    newPassword: new FormControl('',[Validators.required],[this.passwordValidators()]),
  });

  resetPassword$ = new Subject<void>();
  passwordErrors$ = new BehaviorSubject<string[]>([]);
  set passwordErrors(value: string[]) {
    this.passwordErrors$.next(value);
  }

  resetProcessing$ = new BehaviorSubject<boolean>(false);
  set resetProcessing(value: boolean) {
    this.resetProcessing$.next(value);
  }

  disableScreen$ = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private notificationsService: NotificationsService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data.pipe(take(1)).subscribe((value: any) => {
      this.disableScreen$.next(value.enableResetPasswordPage.IsValid);
    });

    this.oobCode = this.activatedRoute.snapshot.queryParams['oobCode'];
  }

  passwordReset(): void {
    if (this.password.invalid) {
      return;
    }
    this.resetProcessing = true;
    this.authService.confirmPasswordReset( this.password.value, this.oobCode).pipe(take(1))
      .subscribe({next: () => {
        this.notificationsService.publish({
          message: 'Password reset successful, please log in using your new password.',
          type: NotificationTypeEnum.Success,
        });
        this.router.navigate(['/login']);
      },  complete: () => {
        this.resetProcessing = false;
      }});
  }

  toggleVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  get password(): AbstractControl {
    return this.resetPasswordForm.get('newPassword');
  }

  passwordValidators(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      const value = control.value;
      if(!value){
        return null;
      }
      this.resetPassword$.next()
      return this.authService.CheckPasswordComplexity(value)
        .pipe(
          takeUntil(this.resetPassword$),
          map((errors) => {
            if(errors.Problems.length > 0) {
              // build errors
              const errorArray = []
              const array = []
              errors.Problems.forEach(err => {
                errorArray.push({[err.Code.toString()]: err.Message})
                array.push(err.Message)
              });
              this.passwordErrors = array;
              return of(errors)
            }
            this.passwordErrors = []
            return null
          }));
    }
  }

  redirectToReset(): void{
    this.router.navigate(['/reset'])
  }

  // for dev purposes
  generateRandomPassword(length: number): void {
    let result = '';
    const characters = ['ABCDEFGHIJKLMNOPQRSTUVWXYZ','abcdefghijklmnopqrstuvwxyz','0123456789','!@#$%^&*']
    const charactersLength = characters.length;
    // const float = (Math.floor(Math.random() * characters.length));
    let counter = 0;
    while (counter < length) {
      const set = Math.floor(Math.random() * characters.length);
      result += characters[set].charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    // console.log(result);
  }

}

