<div #carousel class="carousel">
    <div class="navigation">
        <mat-icon (click)="goLeft()">chevron_left</mat-icon>
    </div>
    <div #carouselSlides [style.max-width]="maxWidth" class="slides-container">
        <ng-content #content></ng-content>
    </div>
    <div class="navigation">
        <mat-icon (click)="goRight()">chevron_right</mat-icon>
    </div>
</div>
