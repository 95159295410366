  <!-- In Progress Orders -->
  <!-- TODO: add [activeWarehouseFilter]="activeWarehouseFilter" -->
  <app-collapsible-order-table
  *ngIf="inProgressFirestoreTrips | async as inProgressTrips"
  [tripType]="'In Progress Trips'"
  [trips]="inProgressTrips"
  [tripsTotal]="inProgressTotal"
  [objectType]="CollapsibleTableTypeEnum.Order"
  [icon]="Icons.Types.Trip"
  [color]="Icons.Colors.Blue"
  [tableDataMap]="fsOrderTableDataMap"
  [tableColumns]="fsOrderTableColumns"
  [primaryActions]="postDispatchTablePrimaryActions"
  [defaultDisplayAmount]="inProgressAmount"
  [showPagination]="true"
  (paginateEvent)="handleInProgressTrips($event)"
  (tableSortChanged)="handleTripsSortChange($event)"
  >
  </app-collapsible-order-table>
