import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-polar',
  templateUrl: './polar.component.html',
  styleUrls: ['./polar.component.scss']
})
export class PolarComponent implements OnInit {

  constructor() { }
  @Input() data;
  @Input() noDataMessage;
  @Input() showLegend = true;
  ngOnInit() {
  }

}
