import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewEncapsulation } from '@angular/core';
import { IconTypes } from '../icon/icon.interfaces';
import { MenuDropDownItems } from './menu-drop-down.interfaces';
import { UiColors } from '../../interfaces/ui.interfaces';

@Component({
  selector: 'app-menu-dropdown',
  templateUrl: './menu-drop-down.component.html',
  styleUrls: ['./menu-drop-down.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MenuDropDownComponent {
  @Input() iconType: IconTypes;
  @Input() iconSize: number;
  @Input() iconColor: UiColors;
  @Input() label: string;
  @Input() items: MenuDropDownItems[];
  @Input() dropDownWidthRem = 20;
  @Input() isOpen = false;
  @Input() disabled = false;
  @Input() processing: boolean;

  @Output() itemClick = new EventEmitter<string>();

  constructor(private element: ElementRef) {}
  toggleDropDown() {
    this.isOpen = !this.isOpen;
  }

  handleItemClick(action, disabled) {
    if (!disabled && !this.disabled) {
      this.itemClick.emit(action);
      this.isOpen = false;
    }
  }

  @HostListener('document:click', ['$event'])
  closeNav(event) {
    if (this.isOpen && !this.element.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }
}
