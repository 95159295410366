<!-- <app-section class="admin-actions">
  <app-menu-dropdown
    *ngIf="user.is_admin"
    [iconType]="icons.types.FirebaseOnGrid"
    [iconSize]="1.5"
    [iconColor]="icons.colors.Grey5"
    [dropDownWidthRem]="23"
    [items]="firebaseMenuOptions"
    (itemClick)="handleFirebaseClick()"
  ></app-menu-dropdown>
  <app-icon
    *ngIf="user.is_admin"
    [icon]="icons.types.EventStoreOnGrid"
    [size]="1.5"
    [color]="icons.colors.Grey5"
    [ngClass]="'admin-button'"
    [showHover]="true"
    (click)="handleEventStoreClick()"
  ></app-icon>
</app-section> -->

<!-- <app-basic-button-group
    *ngIf="tabs$"
    [buttons]="viewTabs"
    [currentTab]="tabs$[2]?.label === null ? 'Details' : tabs$[2]?.label"e
    [buttonRemPadding]="1"
    (buttonClicked)="handleTabNavigation($event)"
  ></app-basic-button-group> -->
<section *ngIf="fsLastMileMappedData">
  <app-section
    *ngIf="
      failedParcels.length && (driverRole === 'Contractor' || user.is_admin)
    "
  >
    <app-last-mile-failed-parcels
      [lastMileId]="lastMileId"
      [failedParcels]="failedParcels"
    >
    </app-last-mile-failed-parcels>
  </app-section>
  <app-flex-container
    class="route-container"
    [justifyContent]="JustifyContent.FlexStart"
  >
    <app-last-mile-fs-waypoint-route
      *ngIf="fsLastMileMappedData.WaypointVisits && showRoute"
      [fleetAllocation]="fleetAllocation"
      [RouteStartedAt]="fsLastMileMappedData.RouteStartedAt"
      [WaypointVisits]="fsLastMileMappedData.WaypointVisits"
      [Parcels]="fsLastMileMappedData.Parcels"
      [lastMileId]="fsLastMileMappedData?.Id"
      (refreshDataEvent)="refreshLastMile()"
      [orderId]="orderId"
    >
    </app-last-mile-fs-waypoint-route>
  </app-flex-container>
  <app-flex-container
    class="route-container"
    [justifyContent]="JustifyContent.FlexStart"
  >
    <app-last-mile-fs-parcels
      *ngIf="fsLastMileMappedData.Parcels"
      [lastMileId]="fsLastMileMappedData?.Id"
      [orderId]="orderId"
      [Parcels]="fsLastMileMappedData.Parcels"
      (refreshDataEvent)="refreshLastMile()"
    >
    </app-last-mile-fs-parcels>
  </app-flex-container>
  <!-- *ngIf="fsLastMileMappedData.Parcels"
      [Parcels]="fsLastMileMappedData.Parcels" -->

  <app-flex-container
    *ngIf="fsLastMileMappedData.StagedAssignments?.length"
    class="route-container"
    [justifyContent]="JustifyContent.FlexStart"
  >
    <app-last-mile-staged-assignments
      [lastMileId]="fsLastMileMappedData?.Id"
      (refreshDataEvent)="refreshLastMile()"
      [Parcels]="fsLastMileMappedData.StagedAssignments"
    >
    </app-last-mile-staged-assignments>
  </app-flex-container>

  <app-last-mile-driver-details
    [lastMileId]="fsLastMileMappedData?.Id"
    [driverId]="fsLastMileMappedData?.DriverId"
    [orderId]="orderId"
  >
  </app-last-mile-driver-details>
</section>
<app-section *ngIf="fsLastMileMappedData">
  <heading
    [icon]="iconTypes.Driver"
    [color]="uiColors.Blue"
  >
    <h4 class="heading-title">Trip Tracking</h4>
    <app-basic-button 
    [title]="'View Map'" 
    [type]="buttonTypes.Primary" 
    (click)="showMap = !showMap">
    </app-basic-button>
  </heading>
  <ng-container *ngIf="showMap">
    <div class="map-wrap">
      <app-map-view
        [mapType]="mapTypes.Tracking"
        [markers]="markers"
        [driverId]="fsLastMileMappedData?.DriverId"
        [geoJsonDataLayers]="[driverTravelData]"
        [polylines]="[lastMilePolyline?.Polylines]"
      ></app-map-view>
    </div>
  </ng-container>
</app-section>

<app-section *ngIf="!fsLastMileMappedData">
  <div class="loading-route">
    <h3>Loading Driver Load..</h3>
  </div>
</app-section>
