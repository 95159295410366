import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { UiThemes, UiColors } from 'app/interfaces/ui.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { ManageBusinessService } from '../manage-business.service';
import { Store } from '@ngrx/store';
import { selectorActingAs } from 'app/auth/auth.reducer';
import { ModalAction, ModalSizes } from 'app/shared/modals/modal.interfaces';

@Component({
  selector: 'app-set-webhook',
  templateUrl: './set-webhook.component.html',
  styleUrls: ['./set-webhook.component.scss'],
})
export class SetWebhookComponent extends SimpleModalComponent<any, any> implements OnInit {
  public actions: ModalAction[] = [
    { name: 'close', title: 'Close' },
    { name: 'apply', title: 'Apply', isPrimary: true },
  ];
  public UiThemes = UiThemes;
  ModalSizes = ModalSizes;
  IconTypes = IconTypes;
  uiColors = UiColors;
  Icons = {
    Colors: UiColors,
    Types: IconTypes,
  };
  webhookURL;
  actingAs;
  businessDetails;
  webhook_model = {
    webhook_url: null,
    webhook_url_v2: null,
    api_key: null,
    user_name: null,
    password: null,
  };
  sectionIds = [{ name: 'none' }, { name: 'api-key' }, { name: 'basic' }];
  selectedSection;

  constructor(public manageBusinessService: ManageBusinessService, private store: Store<any>) {
    super();
    this.store.select(selectorActingAs).subscribe((next) => {
      this.actingAs = next;
      if (this.actingAs.webhook_url) {
        this.webhookURL = this.actingAs.webhook_url;
      }
    });
  }

  setWebhookURL() {
    this.manageBusinessService.setWebhookURL(this.webhookURL).then(() => this.close());
  }

  ngOnInit() {
    this.webhook_model = {
      webhook_url: this.businessDetails.picup_webhook_url,
      webhook_url_v2: this.businessDetails.picup_webhook_url_v2,
      api_key: this.businessDetails.api_key,
      user_name: this.businessDetails.user_name,
      password: this.businessDetails.password,
    };
    if (this.webhook_model.api_key) {
      this.selectedSection = 'api-key';
    } else if (this.webhook_model.user_name) {
      this.selectedSection = 'basic';
    } else {
      this.selectedSection = 'none';
    }
  }

  handleAction($event: string): void {
    switch ($event) {
      case 'close':
        this.close();
        break;
      case 'apply':
        if (this.selectedSection === 'none') {
          this.webhook_model.api_key = null;
          this.webhook_model.user_name = null;
          this.webhook_model.password = null;
        } else if (this.selectedSection === 'basic') {
          this.webhook_model.api_key = null;
        } else {
          this.webhook_model.user_name = null;
          this.webhook_model.password = null;
        }
        this.result = this.webhook_model;
        this.close();
        break;
    }
  }
}
