import { Component, OnInit } from '@angular/core';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { IconTypes } from '../icon/icon.interfaces';
import { ModalAction, ModalSizes } from '../modals/modal.interfaces';
import { TableActionTaken, TableColumn, TableDataMap } from '../table/table.interfaces';
import { Router } from '@angular/router';
import { mfHumanizeString } from '../table/map-formatters.table.helpers';
import { SharedService } from '../shared.service';
import { ParcelTimelineItem } from './parcel-timeline.interface';
import { ModalActionsEnum } from '../shared.interfaces';

interface ParcelTimelineData {
  order_id: string;
}

@Component({
  selector: 'app-parcel-timeline',
  templateUrl: './parcel-timeline.component.html',
  styleUrls: ['./parcel-timeline.component.scss'],
})
export class ParcelTimelineComponent extends SimpleModalComponent<ParcelTimelineData, void> implements OnInit {
  public IconTypes = IconTypes;
  public UiColors = UiColors;
  public UiThemes = UiThemes;
  public ModalSizes = ModalSizes;

  loading: boolean = false;
  order_id: string;

  public actions: ModalAction[] = [{ name: ModalActionsEnum.Close, title: ModalActionsEnum.Close, isPrimary: false }];

  timelineData: ParcelTimelineItem[];
  parcelList: string[] = [];
  filteredTimelineData: ParcelTimelineItem[];

  trackingTableColumns: TableColumn[] = [
    { name: 'timestamp', displayName: 'When Created' },
    { name: 'event_name', displayName: 'Event' },
    { name: 'parcel_waybill', displayName: 'Parcel Waybill' },
  ];

  trackingTableDataMap: TableDataMap = {
    cells: {
      timestamp: { column: 'timestamp', map: 'Timestamp', displayPipeArgs: ['date', 'medium'] },
      event_name: { column: 'event_name', map: 'EventDescription', format: mfHumanizeString },
      parcel_waybill: { column: 'parcel_waybill', map: 'ParcelWaybillNumber' },
    },
  };

  tablePrimaryActions = [{ event: 'order', title: 'Go to Trip', icon: IconTypes.Eye }];

  constructor(private router: Router, private sharedService: SharedService) {
    super();
  }

  ngOnInit(): void {
    this.loading = true;
    this.getOrderTrackingEvents(this.order_id);
  }

  getOrderTrackingEvents(order_id: string): void {
    if (!order_id) {
      this.loading = false;
      return;
    }
    this.sharedService.getOrderTrackingEvents(order_id).then((response) => {
      this.timelineData = response.value;
      this.setUpFilters();
      this.loading = false;
    });
  }

  setUpFilters(): void {
    this.timelineData?.forEach((event) => {
      if (this.parcelList.indexOf(event.ParcelWaybillNumber) === -1) {
        this.parcelList.push(event.ParcelWaybillNumber);
      }
    });
    this.filterParcels(this.parcelList[0]);
  }

  filterParcels(parcel_waybill: string): void {
    this.filteredTimelineData = undefined;
    if (!parcel_waybill) {
      return;
    }
    this.filteredTimelineData = this.timelineData.filter((parcels) => {
      return parcels.ParcelWaybillNumber === parcel_waybill;
    });
  }

  respondToTableAction($event: TableActionTaken): void {
    switch ($event.action.event) {
      case 'order': {
        this.router.navigate(['/dashboard/orders/trip/', this.order_id]);
        this.close();
        break;
      }
    }
  }

  handleAction($event: string): void {
    switch ($event) {
      case ModalActionsEnum.Close:
        this.close();
        break;
    }
  }
}
