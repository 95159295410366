import { UiThemes } from '../../interfaces/ui.interfaces';
import { SpacingProperties } from '../flex-child/flex-child.interfaces';

export interface GridContainerProps {
  columns: string;
  rowGap: string;
  colGap: string;
  borderBottom: boolean;
  borderTop: boolean;
  padding: SpacingProperties | string;
  margin: SpacingProperties | string;
  theme: UiThemes;
}

export enum GridJustifyItems {
  Start = 'start',
  End = 'end',
  Center = 'center',
  Stretch = 'stretch',
  Inherit = 'inherit'
}
