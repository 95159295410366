<div class="modal">
  <div class="modal-content">
    <heading [icon]="Icons.Types.Geocoded" [color]="Icons.Colors.Blue">
      <h4 class="heading-title">Schedule Collection</h4>
    </heading>
    <div class="modal-body">
      <app-grid-container [columns]="'repeat(2, 1fr)'" [padding]="'2rem'" [colGap]="'2rem'" [rowGap]="'2rem'">
        <app-date-picker [date]="date" (changeDate)="dateChanged($event)"></app-date-picker>
        <app-time-selector (changeTime)="timeChanged($event)"></app-time-selector>
      </app-grid-container>
      <div class="modal-footer">
        <app-basic-button 
          [title]="'Cancel'" 
          [type]="buttonTypes.Primary" 
          (click)="cancel()"> 
        </app-basic-button>
        <app-basic-button 
          [title]="'Select Date'" 
          [type]="buttonTypes.Highlight" 
          [active]="true" 
          (click)="returnDateTime()">
        </app-basic-button>
      </div>
    </div>
  </div>
</div>
