<button [ngClass]="{
  'button': true,
  'block': block,
  'button-green': type==buttonTypes.Green,
  'button-orange': type==buttonTypes.Orange,
  'button-highlight': type==buttonTypes.Highlight,
  'button-primary': type==buttonTypes.Primary,
  'button-secondary': type==buttonTypes.Secondary,
  'button-tertiary': type==buttonTypes.Tertiary,
  'button-warning': type==buttonTypes.Warning,
  'button-error': type==buttonTypes.Error,
  'active': active}" 
  [disabled]="disabled || processing"
  *ngIf="!hidden"
  >
  <svg-icon [svgStyle]="{ 'width.rem': '1' }" *ngIf="processing" src="/assets/img/icons/spinner.svg" class="spin icon"></svg-icon>
  {{title}}
</button>