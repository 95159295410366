<heading [icon]="iconTypes.MapMarker" [color]="uiColors.Blue" *ngIf="dialogRef">
  <h4 class="heading-title">{{ 'Set Start and End tasks' }}</h4>
</heading>
<div class="container-pt" [formGroup]="startEndFormGroup">
  <div class="address-container">
    <div class="items-container">
      <div class="sub-heading-container">
        <div class="sub-heading">Start Task</div>
      </div>
      <div class="items-container-pt2">
        <div class="address-bar-div">
          <app-address-select
            class="address-bar"
            [addressIn]="startAddressTypeControl.value"
            (address)="startAddressTypeControl.setValue($event)"
            (clearMap)="clearMap($event)"
            [isValid]="!startAddressTypeControl.invalid"
          >
          </app-address-select>
        </div>
        <ng-container *ngIf="startAddressTypeControl.value">
          <div class="task-bar-div">
            <input-field
              [label]="'Start Task'"
              [value]="(addressModel$ | async).startAddress?.reason ?? null"
              (valueChanged)="setStartTask($event)"
              [isInvalid]="startTaskControl.invalid"
            >
            </input-field>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="items-container">
      <div class="sub-heading-container">
        <div class="sub-heading">End Task</div>
        <!-- <div>
          <ng-container *ngIf="startAddressTypeControl.value">
            <app-basic-button [title]="'Same as start'" [type]="buttonTypes.Primary" (click)="setStartAsEnd()">
            </app-basic-button>
          </ng-container>
        </div> -->
      </div>
      <div class="items-container-pt2">
        <div class="address-bar-div">
          <app-address-select
            [heading]="'End Address'"
            [type]="taskType.end"
            [addressIn]="endAddressTypeControl.value"
            class="address-bar"
            (clearMap)="clearMap($event)"
            (address)="endAddressTypeControl.setValue($event)"
            [isValid]="!endAddressTypeControl.invalid"
          >
          </app-address-select>
        </div>
        <ng-container *ngIf="endAddressTypeControl.value">
          <div class="task-bar-div">
            <input-field
              [label]="'End Task'"
              [value]="(addressModel$ | async).endAddress?.reason ?? null"
              (valueChanged)="setEndTask($event)"
              [isInvalid]="endTaskControl.invalid"
            >
            </input-field>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <ng-container *ngIf="showMap">
    <div>
      <app-map-view [markers]="markers"></app-map-view>
    </div>
  </ng-container>
  <div class="button-div">
    <app-basic-button
      [disabled]="startEndFormGroup.invalid"
      [title]="'Next'"
      [type]="buttonTypes.Highlight"
      (click)="startEndFormGroup.valid && saveData()"
    >
    </app-basic-button>
  </div>
</div>
