<div class="button-group"
     [ngClass]="{
     'space-between': spaceBetween,
     'defined-space': buttonRemPadding,
     'align-right': alignRight
     }">
  <app-basic-button *ngFor="let button of buttons; let i = index"
      class="button-group-button"
      [ngStyle]="{
      'padding-right.rem': (i + 1 === buttons.length || button.hidden) ? 0 : buttonRemPadding
      }"
      [type]="button.type || buttonTypes.Primary"
      [title]="button.title"
      [active]="currentTab === button.title"
      [disabled]="button.disabled"
      [hidden]="button.hidden"
      [processing]="button.processing"
      [block]="displayVertical"
      (click)="handleButtonClick(button.title)"
  >
  {{button.hidden}}
  </app-basic-button>
  <ng-content></ng-content>
</div>