<div class="header-breadcrumbs">
  <ng-template ngFor let-first="first" let-breadcrumb [ngForOf]="breadcrumbs$">
    <a *ngIf="first;else no_link" class="breadcrumbs-item bold" [routerLink]="breadcrumb.url">
      {{ breadcrumb.label }}
    </a>
    <ng-template #no_link>
      <span  class="breadcrumbs-item">
        {{ breadcrumb.label }}
      </span>
    </ng-template>
  </ng-template>
</div>