<div class="customer">
    <div class="customer-info">
      <app-flex-container
        [theme]="UiThemes.Dark"
        [borderBottom]="true"
        [borderTop]="true"
        [justifyContent]="justifyContent.SpaceBetween"
        [padding]="'2rem'"
      >
        <div class="customer-details">
          <div class="customer-detail name">
            <app-icon-with-text
              [icon]="IconTypes.Warehouse"
              [iconColor]="UiColors.Blue"
              [iconSizeRem]="1.5"
              [iconSpacingRem]="1.5"
              [text]="contact?.Name ? contact.Name : '-'"
              [sizeRem]="2"
              [color]="UiColors.White"
            >
            </app-icon-with-text>
          </div>
          <div class="customer-detail">
            <app-icon-with-text
              [icon]="IconTypes.Mail"
              [iconColor]="UiColors.Grey5"
              [iconSizeRem]="1"
              [iconSpacingRem]="2"
              [text]="contact?.Email ? contact.Email : '-'"
              [sizeRem]="1"
              [color]="UiColors.White"
            >
            </app-icon-with-text>
          </div>
          <div class="customer-detail">
            <app-icon-with-text
              [icon]="IconTypes.Phone"
              [iconColor]="UiColors.Grey5"
              [iconSizeRem]="1"
              [iconSpacingRem]="2"
              [text]="contact?.Phone ? contact.Phone : '-'"
              [sizeRem]="1"
              [color]="UiColors.White"
            >
            </app-icon-with-text>
          </div>
        </div>
      </app-flex-container>
      <app-table
        [columns]="parcelOverviewColumns"
        [tableData]="parcelOverviewTableData"
        [rowsSelectable]="false"
      ></app-table>
    </div>
  </div>
  