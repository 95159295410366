<app-section>
  <heading [icon]="iconTypes.Driver" [color]="uiColors.Blue">
    <h4 class="heading-title">Trip Tracking</h4>
    <app-basic-button 
    [title]="'View Map'" 
    [type]="buttonTypes.Primary" 
    (click)="toggleMap()">
    </app-basic-button>
  </heading>
  <ng-container  *ngIf="showMap">
    <div class="map-wrap">
      <app-map-view [polylines]="[tripPolylines]" [mapType]="mapTypes.Tracking" [markers]="markers"></app-map-view>
    </div>
  </ng-container>
</app-section>
