<app-modal
  [open]="true"
  [actions]="actions"
  [highlightPrimaryAction]="true"
  [size]="ModalSizes.Medium"
  (handleAction)="handleAction($event)"
>
  <heading [icon]="IconTypes.Bucket" [color]="uiColors.Blue">
    <h4 class="heading-title">Admin Action Requested</h4>
  </heading>

  <div class="modal-body">
    <app-grid-container [columns]="'repeat(1, 1fr)'" [padding]="'2rem'" [colGap]="'0'" [rowGap]="'0'">
        <h3>{{admin_action[0].failure_reason_key}}</h3>
      <app-dropdown
        [dropdownTheme]="'light'"
        [activeItem]="selected_action ? selected_action : 'Select an Action'"
        class="heading-dropdown"
      >
        <div dropdownRef>
          <a (click)="selectReason('fail')" class="dropdown-item">
            {{ 'Fail ' + action_type }}
          </a>
          <a (click)="selectReason('skip')" class="dropdown-item">
            {{ 'Skip ' + action_type }}
          </a>
        </div>
      </app-dropdown>
    </app-grid-container>
  </div>
</app-modal>
