<app-modal
  [open]="true"
  (handleAction)="handleAction($event)"
  [actions]="actions"
  [highlightPrimaryAction]="true"
  [size]="ModalSizes.Medium"
>
  <heading [icon]="IconTypes.Business" [color]="uiColors.Blue">
    <h4 class="heading-title">{{ 'Add Credits' }}</h4>
  </heading>
  <div class="modal-body">
    <div class="form-wrap">
      <div class="row">
        <app-grid-container [columns]="'repeat(1, 1fr)'" [theme]="UiThemes.Light" [rowGap]="'2rem'" [colGap]="'2rem'">
          <input-field
            class="package-label"
            [type]="'amount'"
            [placeholder]="currency_symbol"
            (valueChanged)="amount = $event"
            [value]="amount"
            [label]="'Amount (' + currency_symbol + ')'"
          >
          </input-field>
        </app-grid-container>
      </div>
    </div>
  </div>
</app-modal>
