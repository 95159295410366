<div class="bucket-table">
  <div class="details">
    <div>{{ bucketData.warehouseName | uppercase }}</div>
    <div class="bucket" (click)="navigateToBucket(bucketData.bucketNo)">BUCKET #{{ bucketData.bucketNo }}</div>
    <div>{{ bucketData.bucketDate }}</div>
  </div>
  <div class="number-layout">
    <div>
      <p class="numbers-small">No. of Orders</p>
    </div>
    <div>
      <p class="numbers-big">{{ bucketData.numberOfOrders }}</p>
      <p></p>
    </div>
  </div>
  <div class="number-layout">
    <div>
      <p class="numbers-small">No. of Parcels</p>
    </div>
    <div>
      <p class="numbers-big">{{ bucketData.numberOfParcels }}</p>
      <p></p>
    </div>
  </div>
  <div class="number-layout">
    <div>
      <p class="numbers-small">Flagged</p>
    </div>
    <div>
      <p class="numbers-big">{{ bucketData.numberFlagged }}</p>
      <p></p>
    </div>
  </div>
  <div class="number-layout">
    <div>
      <p class="numbers-small">Routes</p>
    </div>
    <div>
      <p class="numbers-big">{{ bucketData.numberOfRoutes }}</p>
      <p></p>
    </div>
  </div>
  <div class="number-layout">
    <div>
      <p class="bucket">{{ bucketData.orderState }}</p>
    </div>
  </div>
</div>
