<ng-container *ngIf="(showTotals || showAverages)">
  <tr>
    <th [attr.colspan]="visibleColumnsCount + (showRowHeader ? 1 : 0) + (showRowActions ? 1 : 0)" class="table-summary-header">
      {{summaryTitle}}
      <label class="table-summary-label" *ngIf="isTableSummary && hasSelection">
        <span class="table-summary-label-text">Subtract Selection</span>
        <input type="checkbox" (change)="handleSubtractSelection(false)">
      </label>
    </th>
  </tr>
  <tr *ngIf="showTotals"
      app-table-summary-row
      [showRowHeader]="showRowHeader"
      [summaryData]="summaryData?.totals"
      [rowTitle]="'SUM'"
      [showRowActions]="showRowActions"
      [columns]="columns">
  </tr>
  <tr *ngIf="showAverages"
      app-table-summary-row
      [showRowHeader]="showRowHeader"
      [summaryData]="summaryData?.averages"
      [rowTitle]="'AVG'"
      [showRowActions]="showRowActions"
      [columns]="columns">
  </tr>
</ng-container>