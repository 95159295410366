import { Pipe, PipeTransform } from '@angular/core';

export const LastEventMapping = new Map<string, string>([
  ['CourierOrderStateUpdated', 'Courier Status Manually Updated'],
  ['CourierTrackingEventProcessed', 'Courier Event Processed'],
  ['WaypointRouteEnded', 'Waypoint Route Ended'],
  ['CourierWaybillCreated', 'Courier Waybill Created'],
  ['DriverArrivedAtWaypoint', 'Driver Arrived at Waypoint'],
  ['LastMileParcelsUnassigned', 'Parcels Unassigned'],
  ['DriverEnRouteToWarehouse', 'Driver En Route to Warehouse'],
  ['ParcelsAssignedFromOrderAggregate', 'Parcels Assigned'],
  ['WaypointRouteOptimized', 'Route Optimized'],
  ['WarehouseRouteOptimized', 'Route Optimized'],
  ['OrderCompleted', 'Order Completed'],
  ['OrderCancelled', 'Order Cancelled'],
  ['ParcelsCancelled', 'Parcels Cancelled'],
  ['CancelParcelsFailed', 'Failed to Cancel Parcels'],
  ['OrderCreated', 'Order Created'],
  ['CreateCourierOrderRequested', 'Courier Order Created'],
  ['ParcelsAdded', 'Parcels Added'],
  ['ParcelDetailsUpdated', 'Parcels Updated'],
  ['ParcelsAssignedToLastMile', 'Parcels Assigned'],
  ['LastMileParcelsTransferred', 'Parcels Transferred'],
  ['TriedToAssignLotteryDriver', 'Tried to assign Lottery Driver'],
  ['SucceedToAssignLotteryDriver', 'Lottery Driver Assigned'],
  ['FailedToAssignLotteryDriver', 'Failed to assign Lottery Driver'],
  ['StartLotteryScheduled', 'Start Lottery Scheduled'],
  ['LotteryStarted', 'Lottery Started'],
  ['LotteryFailedToStart', 'No Drivers Found Nearby'],
  ['LotterySkipped', 'Lottery Skipped'],
  ['LotteryCancelled', 'Lottery Cancelled'],
  ['ParcelsUnassignedFromLastMileAggregate', 'Parcels Unassigned'],
  ['AssignLastMileParcelsFailed', 'Failed to Assign Parcels'],
  ['OptimizeRouteFailed', 'Optimize Route Failed'],
  ['ParcelsFromLastMileReconciled', 'Parcels Reconciled'],
  ['OrderPaymentHandledFromOrderPaymentAggregate', 'Order Payment Handled'],
  ['LastMileRouteStarted', 'Last Mile Route Started'],
  ['LastMileRouteEnded', 'Last Mile Route Ended'],
  ['LastMileRouteParcelsCollected', 'Last Mile Parcels Collected'],
  ['LastMileRouteParcelsFailedCollection', 'Last Mile Failed Collection'],
  ['CreateCourierWaybillSucceeded', 'Courier Waybill Created'],
  ['ParcelsAssignedToCourierOrder', 'Parcels Assigned to Courier'],
  ['CourierOrderStatusUpdated', 'Courier Status Updated'],
  ['CourierOrderCompleteUpdated', 'Courier Order Completed'],
  ['CourierOrderCancelledUpdated', 'Courier Order Cancelled'],
  ['CreateCourierOrderFailed', 'Courier Order Failed'],
  ['FailedToCreateCourierWaybill', 'Failed to Create Waybill'],
  ['DriverEnRouteToWaypoint', 'Driver En-Route To Waypoint'],
  ['DriverDeliveredParcelsAtWaypoint', 'Parcels Delivered'],
  ['DriverFinalizedAtWaypoint', 'Driver Finalized At Waypoint'],
  ['LotterySkipped', 'Lottery Cycles Complete'],
  ['AllCollectionsFailed', 'All Collections Failed'],
  ['DriverCollectedParcelsAtWaypoint', 'Driver Collected Parcels At Waypoint']
]);
@Pipe({
  name: 'lastEvent'
})
export class LastEventPipe implements PipeTransform {

  transform(value: string): string {
    return LastEventMapping.get(value) ?? value;
  }

}
