<div class="package-selector-bar">
    <div class="flex-container">
        <div class="selector-block" *ngFor="let package of packages">
            <div *ngIf="package.showInSelector">
                <img class="package-image" [src]="'/assets/img/static/' + package.packageType + '/banner.png'" alt="">
                <h4 class="title">{{package.title}} {{package.highlight}}</h4>
                <h5>{{package.subTitle}}</h5>
                <app-slider-button 
                    (click)="navigateToPackage(package.packageType)" 
                    [iconType]="IconTypes.Eye"
                    [labelText]="'View More'" 
                    >
                    <!-- TODO: Add these maybe? 
                    [iconColor]="UiColors.Blue" 
                    [primaryColor]="UiColors.Grey7"
                    [secondaryColor]="UiColors.White" -->
                </app-slider-button>
            </div>
        </div>
    </div>
</div>