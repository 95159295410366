<app-section class="contractor-settings">
    <div class="contractor-header">
        <div class="contractor-title">
            <h3>Contractor Settings</h3>
            <app-icon appTooltip [tooltipText]="'If you have available Contract drivers to fulfil these orders, you can select them now.'" class="icon" [icon]="Icons.QuestionCircle" [size]="2" [color]="UiColors.Blue"></app-icon>
        </div>
        <app-basic-button 
            (click)="addDriver()" 
            class="add-driver" 
            [type]="ButtonTypes.Primary" 
            [title]="'Add Drivers'">
        </app-basic-button>
    </div>
    <app-loader *ngIf="loading; else displayNoContractors" [displayLoader]="true" [loadingMessage]="'Loading selected drivers'"></app-loader>
    <ng-template #displayNoContractors>
        <app-loader *ngIf="contractors.length < 1; else displayContractors" [displayLoader]="false" [loadingMessage]="'No preferred drivers selected'"></app-loader>
    <ng-template #displayContractors>
        <div class="contractors-table">
            <app-table
            [rawData]="contractors"
            [dataMap]="contractorDataMap"
            [columns]="contractorColumns"
            [isLoading]="!contractors"
            [rowsSelectable]="false"
            [theme]="UiThemes.Light"
            >
            </app-table>
        </div>
    </ng-template>
    </ng-template>
    
    <!-- <app-grid-container
      [padding]="'1rem'"
      [columns]="'repeat(4, 1fr)'"
      [colGap]="'2rem'"
      [rowGap]="'1rem'"
      [theme]="UiThemes.Light"
    >
        <div class="vehicle-block">
            <svg-icon
            [src]="
                bucket_contractors?.motorcycle_count > 0
                ? '/assets/img/vehicles/' + VehicleSizesEnum.vehicle_motorcycle + '-blue.svg'
                : '/assets/img/vehicles/' + VehicleSizesEnum.vehicle_motorcycle + '.svg'
            "
            class="vehicle-icon"
            ></svg-icon>
            <input-field
            [type]="'number'"
            [label]="'Count'"
            (valueChanged)="bucket_contractors.motorcycle_count = $event"
            [value]="bucket_contractors?.motorcycle_count"
            ></input-field>
        </div>
        <div class="vehicle-block">
            <svg-icon
            [src]="
                bucket_contractors?.car_count > 0
                ? '/assets/img/vehicles/' + VehicleSizesEnum.vehicle_car + '-blue.svg'
                : '/assets/img/vehicles/' + VehicleSizesEnum.vehicle_car + '.svg'
            "
            class="vehicle-icon"
            ></svg-icon>
            <input-field
            [type]="'number'"
            [label]="'Count'"
            (valueChanged)="bucket_contractors.car_count = $event"
            [value]="bucket_contractors?.car_count"
            ></input-field>
        </div>
        <div class="vehicle-block">
            <svg-icon
            [src]="
                bucket_contractors?.small_van_count > 0
                ? '/assets/img/vehicles/' + VehicleSizesEnum.vehicle_small_van + '-blue.svg'
                : '/assets/img/vehicles/' + VehicleSizesEnum.vehicle_small_van + '.svg'
            "
            class="vehicle-icon"
            ></svg-icon>
            <input-field
                [type]="'number'"
                [label]="'Count'"
                (valueChanged)="bucket_contractors.small_van_count = $event"
                [value]="bucket_contractors?.small_van_count"
            ></input-field>
        </div>
        <div class="vehicle-block">
            <svg-icon
            [src]="
                bucket_contractors?.large_van_count > 0
                ? '/assets/img/vehicles/' + VehicleSizesEnum.vehicle_large_van + '-blue.svg'
                : '/assets/img/vehicles/' + VehicleSizesEnum.vehicle_large_van + '.svg'
            "
            class="vehicle-icon"
            ></svg-icon>
            <input-field
            [type]="'number'"
            [label]="'Count'"
            (valueChanged)="bucket_contractors.large_van_count = $event"
            [value]="bucket_contractors?.large_van_count"
            ></input-field>
        </div>
    </app-grid-container> -->
</app-section>
