import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortBy',
})
export class SortByPipe implements PipeTransform {
  transform(array: unknown[], field: string, direction: string = 'descending'): unknown[] {
    if (!Array.isArray(array)) {
      return;
    }
    const newArray = [...array];
    newArray.sort((a: unknown, b: unknown) => {
      if (parseFloat(a[field]) > parseFloat(b[field])) {
        return direction === 'ascending' ? 1 : -1;
      } else if (parseFloat(a[field]) < parseFloat(b[field])) {
        return direction === 'ascending' ? -1 : 1;
      } else {
        return 0;
      }
    });
    return newArray;
  }
}
