<div class="holder">
  <div class="icon-slider" [ngClass]="theme">
    <svg-icon *ngIf="iconType"
      class="icon-box"
      [svgStyle]="{ 'width.rem': iconSizeRem.toString() }"
      [src]="'/assets/img/icons/' + iconType + '.svg'"
    ></svg-icon>
    <div class="text-box">
      <h4>{{ labelText }}</h4>
    </div>
  </div>
</div>
