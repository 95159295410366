import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { ModalSizes, ModalAction } from 'app/shared/modals/modal.interfaces';
import { BucketService } from '../bucket.service';
import * as moment from 'moment';
import { TableColumn, TableDataMap, TableAction, TableCell } from 'app/shared/table/table.interfaces';
import {
  AvailableBucketsPrimaryActionsEnum,
  ReceivedStagedOrdersModalActionsEnum,
  ReceivedStagedOrdersResultEnum,
} from './receive-staged-orders.interface';

@Component({
  selector: 'app-receive-staged-orders',
  templateUrl: './receive-staged-orders.component.html',
  styleUrls: ['./receive-staged-orders.component.scss'],
})
export class ReceiveStagedOrdersComponent extends SimpleModalComponent<any, any> implements OnInit {
  availableBuckets;
  warehouseId;
  delivery_date = moment(new Date()).format('YYYY-MM-DD');
  shift_start;
  shift_end;
  bucketOrderIds;
  createBucket;

  public ModalSizes = ModalSizes;
  public IconTypes = IconTypes;
  public uiColors = UiColors;
  public UiThemes = UiThemes;
  public buttonTypes = ButtonTypes;

  public actions: ModalAction[] = [
    { name: ReceivedStagedOrdersModalActionsEnum.Close, title: 'Close', isPrimary: false },
    { name: ReceivedStagedOrdersModalActionsEnum.ReceiveOrders, title: 'Receive Orders', isPrimary: true },
  ];

  availableBucketsTableColumns: TableColumn[] = [
    { name: 'bucket_id', displayName: 'Bucket ID ', sortable: true },
    { name: 'warehouse_name', displayName: 'Warehouse', sortable: true },
    { name: 'delivery_date', displayName: 'Delivery Date', sortable: true },
    { name: 'shift_start', displayName: 'Shift Start', sortable: true },
    { name: 'shift_end', displayName: 'Shift End', sortable: true },
  ];

  availableBucketsTableDataMap: TableDataMap = {
    cells: {
      bucket_id: { column: 'bucket_id', map: 'bucket_id' },
      warehouse_name: {
        column: 'warehouse_name',
        map: 'warehouse_name',
      },
      delivery_date: {
        column: 'delivery_date',
        map: 'delivery_date',
        displayPipeArgs: ['date', 'mediumDate'],
      },
      shift_start: {
        column: 'shift_start',
        map: 'shift_start',
      },
      shift_end: {
        column: 'shift_end',
        map: 'shift_end',
      },
    },
  };
  availableBucketsPrimaryActions: TableAction[] = [
    {
      event: AvailableBucketsPrimaryActionsEnum.SelectBucket,
      title: 'Select',
      icon: IconTypes.Check,
      icon_color: UiColors.Grey8,
    },
  ];

  constructor(public bucketService: BucketService, public simpleModalService: SimpleModalService) {
    super();
  }

  findAvailableBuckets(warehouseId: string): void {
    this.bucketService
      .getAssignableBucketsForWarehouse(warehouseId)
      .then((response) => (this.availableBuckets = response));
  }

  handleAction($event) {
    switch ($event) {
      case ReceivedStagedOrdersModalActionsEnum.Close:
        this.close();
        break;
    }
  }

  dateChanged($event) {
    this.delivery_date = moment($event).format('YYYY-MM-DD');
  }
  changeShift($event) {
    this.shift_start = moment.utc($event[0] * 3600000).format('HH:mm');
    this.shift_end = moment.utc($event[1] * 3600000).format('HH:mm');
  }

  selectBucket($event) {
    const bucket_details = {
      delivery_date: $event.rows[0].cells.find((cell: TableCell) => cell.column === 'delivery_date').value,
      shift_start: $event.rows[0].cells.find((cell: TableCell) => cell.column === 'shift_start').value,
      shift_end: $event.rows[0].cells.find((cell: TableCell) => cell.column === 'shift_end').value,
      warehouse_id: this.warehouseId,
    };
    this.result = { bucket_details, order_ids: this.bucketOrderIds };
    this.close();
  }

  ngOnInit(): void {
    this.findAvailableBuckets(this.warehouseId);
  }

  createNewBucket(): void {
    this.result = ReceivedStagedOrdersResultEnum.NewBucket;
    this.close();
  }
}
