import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UiThemes } from 'app/interfaces/ui.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { JustifyContent } from 'app/shared/flex-container/flex-container.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { PostDispatchService } from '../post-dispatch.service';

@Component({
  selector: 'app-v2-post-dispatch-summary',
  templateUrl: './v2-post-dispatch-summary.component.html',
  styleUrls: ['./v2-post-dispatch-summary.component.scss'],
})
export class V2PostDispatchSummaryComponent implements OnInit, OnChanges {
  public IconTypes = IconTypes;
  public ButtonTypes = ButtonTypes;
  public UiThemes = UiThemes;
  justifyContent = JustifyContent;

  @Input() viewMode: string;
  @Input() postDispatchSummaryData;

  @Input() warehouseParam;
  cardColor = '#232837';
  collectionTotalData;
  deliveryTotalData;
  lastMileParcelSummaryData;
  courierParcelSummaryData;
  ParcelReportData;
  orderParcelSummaryData;
  @Input() bucket_id = undefined;

  parcelRouteSummaryColors = {
    domain: [],
  };

  parcelTripSummaryColors = {
    domain: [],
  };

  parcelCourierSummaryColors = {
    domain: [],
  };

  constructor(private postDipatchService: PostDispatchService) {}

  reMap(dataSet: Array<any>, type: string): Array<any> {
    const final = [];

    function humanize(value: string) {
      let nice = value.replace(/_/g, ' ');
      nice = nice.replace(/([A-Z])/g, ' $1');
      return nice;
    }

    for (const [key, value] of Object.entries(dataSet)) {
      if (key !== 'total' && key !== 'none' && value > 0) {
        final.push({
          name: humanize(key),
          value: value,
        });
        switch (type) {
          case 'last-mile-parcel':
            this.getParcelRouteColors(key);
            break;
          case 'order-parcel':
            this.getParcelTripColors(key);
            break;
          case 'courier':
            this.getParcelCourierColors(key);
            break;
        }
      }
    }
    return final;
  }

  getParcelCourierColors(key: string): void {
    switch (key) {
      case 'order_created':
        this.parcelCourierSummaryColors.domain.push('#ffffff');
        break;
      case 'collected':
        this.parcelCourierSummaryColors.domain.push('#216388');
        break;
      case 'failed_collection':
        this.parcelCourierSummaryColors.domain.push('#ff7100');
        break;
      case 'delivered':
        this.parcelCourierSummaryColors.domain.push('#02de73');
        break;
      case 'failed_delivery':
        this.parcelCourierSummaryColors.domain.push('#f1453d');
        break;
      case 'cancelled':
        this.parcelCourierSummaryColors.domain.push('#f1453d');
        break;
      case 'waybill_created':
        this.parcelCourierSummaryColors.domain.push('#27b7fc');
        break;
      case 'courier_selected':
        this.parcelCourierSummaryColors.domain.push('#ffffff');
        break;
      case 'ready_for_collection':
        this.parcelCourierSummaryColors.domain.push('#f8e71c');
        break;
      case 'out_for_collection':
        this.parcelCourierSummaryColors.domain.push('#f8e71c');
        break;
      case 'collection_failed':
        this.parcelCourierSummaryColors.domain.push('#ff7100');
        break;
      case 'at_courier_center':
        this.parcelCourierSummaryColors.domain.push('#216388');
        break;
      case 'parcel_volumised':
        this.parcelCourierSummaryColors.domain.push('#27b7fc');
        break;
      case 'in_transit':
        this.parcelCourierSummaryColors.domain.push('#27b7fc');
        break;
      case 'out_for_delivery':
        this.parcelCourierSummaryColors.domain.push('#27b7fc');
        break;
      case 'pod_image_captured':
        this.parcelCourierSummaryColors.domain.push('#27b7fc');
        break;
      case 'complete':
        this.parcelCourierSummaryColors.domain.push('#02de73');
        break;
      case 'pricing_adjustment':
        this.parcelCourierSummaryColors.domain.push('#ff7100');
        break;
      default:
        this.parcelCourierSummaryColors.domain.push('#ffffff');
        break;
    }
  }

  getParcelTripColors(key: string): void {
    switch (key) {
      case 'pending_allocation':
        this.parcelTripSummaryColors.domain.push('#ffffff');
        break;
      case 'allocated_to_picup':
        this.parcelTripSummaryColors.domain.push('#ffffff');
        break;
      case 'allocated_to_courier':
        this.parcelTripSummaryColors.domain.push('#ffffff');
        break;
      case 'out_for_collection':
        this.parcelTripSummaryColors.domain.push('#f8e71c');
        break;
      case 'failed_return':
        this.parcelTripSummaryColors.domain.push('#f1453d');
        break;
      case 'collected':
        this.parcelTripSummaryColors.domain.push('#216388');
        break;
      case 'at_sorting_facility':
        this.parcelTripSummaryColors.domain.push('#27b7fc');
        break;
      case 'out_for_delivery':
        this.parcelTripSummaryColors.domain.push('#27b7fc');
        break;
      case 'cancelled':
        this.parcelTripSummaryColors.domain.push('#ff7100');
        break;
      case 'delivered':
        this.parcelTripSummaryColors.domain.push('#02de73');
        break;
      case 'allocation_failed':
        this.parcelTripSummaryColors.domain.push('#f1453d');
        break;
      case 'failed_collection':
        this.parcelTripSummaryColors.domain.push('#ff7100');
        break;
      case 'failed_delivery':
        this.parcelTripSummaryColors.domain.push('#f1453d');
        break;
      case 'pending':
        this.parcelTripSummaryColors.domain.push('#ffffff');
        break;
      case 'in_progress':
        this.parcelTripSummaryColors.domain.push('#27b7fc');
        break;
      case 'completed':
        this.parcelTripSummaryColors.domain.push('#02de73');
        break;
      case 'failed':
        this.parcelTripSummaryColors.domain.push('#f1453d');
        break;
      default:
        this.parcelTripSummaryColors.domain.push('#ffffff');
        break;
    }
  }

  getParcelRouteColors(key: string): void {
    switch (key) {
      case 'pending_allocation':
        this.parcelRouteSummaryColors.domain.push('#ffffff');
        break;
      case 'allocated_to_picup':
        this.parcelRouteSummaryColors.domain.push('#ffffff');
        break;
      case 'allocated_to_courier':
        this.parcelRouteSummaryColors.domain.push('#ffffff');
        break;
      case 'out_for_collection':
        this.parcelRouteSummaryColors.domain.push('#f8e71c');
        break;
      case 'failed_return':
        this.parcelRouteSummaryColors.domain.push('#f1453d');
        break;
      case 'collected':
        this.parcelRouteSummaryColors.domain.push('#216388');
        break;
      case 'at_sorting_facility':
        this.parcelRouteSummaryColors.domain.push('#27b7fc');
        break;
      case 'out_for_delivery':
        this.parcelRouteSummaryColors.domain.push('#27b7fc');
        break;
      case 'cancelled':
        this.parcelRouteSummaryColors.domain.push('#ff7100');
        break;
      case 'delivered':
        this.parcelRouteSummaryColors.domain.push('#02de73');
        break;
      case 'allocation_failed':
        this.parcelRouteSummaryColors.domain.push('#f1453d');
        break;
      case 'failed_collection':
        this.parcelRouteSummaryColors.domain.push('#ff7100');
        break;
      case 'failed_delivery':
        this.parcelRouteSummaryColors.domain.push('#f1453d');
        break;
      case 'pending':
        this.parcelRouteSummaryColors.domain.push('#ffffff');
        break;
      case 'in_progress':
        this.parcelRouteSummaryColors.domain.push('#27b7fc');
        break;
      case 'completed':
        this.parcelRouteSummaryColors.domain.push('#02de73');
        break;
      case 'failed':
        this.parcelRouteSummaryColors.domain.push('#f1453d');
        break;
      case 'assigned':
        this.parcelRouteSummaryColors.domain.push('#ffffff');
        break;
      case 'returned':
        this.parcelRouteSummaryColors.domain.push('#216388');
        break;
      default:
        this.parcelRouteSummaryColors.domain.push('#ffffff');
        break;
    }
  }

  ngOnChanges(): void {
    this.lastMileParcelSummaryData = this.reMap(
      this.postDispatchSummaryData.last_mile_summary.parcel,
      'last-mile-parcel'
    );
    this.courierParcelSummaryData = this.reMap(this.postDispatchSummaryData.courier_summary.parcel, 'courier');
    this.orderParcelSummaryData = this.reMap(this.postDispatchSummaryData.order_summary.parcel, 'order-parcel');
    this.ParcelReportData = undefined;
  }

  getReportBreakdown(type, $event): void {
    let status = $event.name;
    status = status.replace(/\s/g, '_');
    this.postDipatchService.getPostDispatchParcels(type, status, this.warehouseParam, this.bucket_id).then((res) => {
      this.ParcelReportData = res;
    });
  }

  ngOnInit(): void {}
}
