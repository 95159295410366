<div *ngIf="driverDetails">
  <heading 
    [icon]="IconTypes.Driver" 
    [color]="UiColors.Blue"
    >DRIVER DETAILS
    <div class="controls">
      <app-basic-button
        *ngIf="actingAs.roles.includes(UserRoles.SuperAdministrator)"
        [title]="'Rehydrate'"
        [type]="buttonTypes.Warning"
        [active]="false"
        [processing]="processing"
        (click)="rehydrateRM()"
      ></app-basic-button>
      <app-basic-button
        *ngIf="user.is_admin"
        [title]="'View Driver'"
        [type]="buttonTypes.Primary"
        [active]="false"
        (click)="viewDriver()"
      >
      </app-basic-button>
    </div>
  </heading>
  <app-grid-container
    [columns]="'repeat(4, 1fr)'"
    [colGap]="'2rem'"
    [rowGap]="'2rem'"
    [padding]="'2rem'"
    [theme]="UiThemes.Dark"
    [justifyItems]="GridJustifyItems.Center"
  >
    <app-icon-with-text
      [paddingRem]="1"
      [icon]="IconTypes.Profile"
      [iconColor]="UiColors.Blue"
      [iconSizeRem]="1.5"
      [iconSpacingRem]="1.5"
      [text]="driverDetails?.name"
      [sizeRem]="1"
    >
    </app-icon-with-text>
    <app-icon-with-text
      [paddingRem]="1"
      [icon]="IconTypes.Phone"
      [iconColor]="UiColors.Blue"
      [iconSizeRem]="1.5"
      [iconSpacingRem]="1.5"
      [text]="driverDetails?.phone"
      [sizeRem]="1"
    >
    </app-icon-with-text>
    <app-icon-with-text
      [paddingRem]="1"
      [icon]="IconTypes.Mail"
      [iconColor]="UiColors.Blue"
      [iconSizeRem]="1.5"
      [iconSpacingRem]="1.5"
      [text]="driverDetails?.email"
      [sizeRem]="1"
    >
    </app-icon-with-text>
    <app-icon-with-text
      *ngIf="driverDetails.active_vehicle"
      [paddingRem]="1"
      [icon]="vehicleTypes.get(driverDetails?.active_vehicle)"
      [iconColor]="UiColors.Blue"
      [iconSizeRem]="1.5"
      [iconSpacingRem]="1.5"
      [text]="vehicleNames.get(driverDetails?.active_vehicle)"
      [sizeRem]="1"
    >
    </app-icon-with-text>
  </app-grid-container>
</div>
