import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { VehicleTrackingMapService } from './vehicle-tracking-map.service';
import { MapViewService } from '../map-view.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-vehicle-tracking-map',
  templateUrl: './vehicle-tracking-map.component.html',
  styleUrls: ['./vehicle-tracking-map.component.scss'],
})
export class VehicleTrackingMapComponent implements OnInit, OnDestroy {
  @Input() mapElement;
  @Input() driverId;
  @Input() showOnline = false;

  iconUrl = '/assets/img/vehicles/vehicle-motorbike-top.svg';
  marker: google.maps.Marker;

  @Input() set driverIcon(driverIcon: string){
    if(driverIcon){
      this.iconUrl = driverIcon
    }
    if(this.marker){
      this.marker.setIcon({
        url: this.iconUrl,
        scaledSize: new google.maps.Size(50, 50),
        origin: new google.maps.Point(0, 0),
        rotation: 90,
        anchor: new google.maps.Point(25, 25),
      })
    }
  }
  @Output() markerClick: EventEmitter<string> = new EventEmitter<string>();

  lastHeard = new Date().getTime();
  markerIndex = 0;
  vehicleLocationSubscription$: Subscription;

  constructor(private vehicleTrackingMapService: VehicleTrackingMapService, private mapViewService: MapViewService) {}

  generateMarkers(response) {
    if (response) {
      if (!this.marker && response.location) {
        this.createMarker(response.location);
      } else {
        const date = new Date();

        this.updateMarker(response.location, date.getTime());
      }
    }
  }

  mapClicked($event): void {
    const position = {
      latitude: $event.coords.lat,
      longitude: $event.coords.lng,
    };
    const date = new Date();
    this.updateMarker(position, date.getTime());
  }

  createMarker(position): void {
    const coOrds = new google.maps.LatLng(position.latitude, position.longitude);
    this.marker = new google.maps.Marker({
      position: coOrds,
      map: this.mapElement,
      icon: {
        url: this.iconUrl,
        scaledSize: new google.maps.Size(50, 50),
        origin: new google.maps.Point(0, 0),
        rotation: 90,
        anchor: new google.maps.Point(25, 25),
      },
      zIndex: 10,
    });
    this.marker.addListener('click', () => {
      this.markerClick.emit(this.driverId);
    });
    this.mapViewService.addMarkerToBounds(1, position.latitude, position.longitude);
  }

  updateMarker(position, time): void {
    if (this.marker) {
      const frames = [];
      const fromLat = this.marker.getPosition().lat();
      const fromLng = this.marker.getPosition().lng();
      const toLat = position.latitude;
      const toLng = position.longitude;
      const bearing = position.bearing;

      for (let percent = 0; percent < 1; percent += 0.01) {
        const curLat = fromLat + percent * (toLat - fromLat);
        const curLng = fromLng + percent * (toLng - fromLng);
        frames.push(new google.maps.LatLng(curLat, curLng));
      }

      const wait = (time - this.lastHeard) / frames.length;
      this.move(this.marker, frames, 0, wait, this.marker.getPosition(), bearing);
      this.lastHeard = time;

      // marker, frames, index, time, new destination, bearing
    }
  }

  move(marker, latlngs, index, wait, newDestination, bearing): void {
    if (index === 0) {
      this.markerIndex = 0;
    }
    if (index !== this.markerIndex) {
      return;
    }
    this.markerIndex++;
    // Set Position
    this.marker.setPosition(latlngs[index]);

    // Set Rotation
    const icon = marker.getIcon();
    const rotate_ref = document.querySelector<HTMLInputElement>('img[src*=\'-top\']');
    if (rotate_ref) {
      rotate_ref.style.transform = 'rotate(' + bearing + 'deg)';
    }

    // document.querySelector('#markerLayer img').style.transform = 'rotate(' + deg + 'deg)'

    if (index !== latlngs.length - 1) {
      // call the next "frame" of the animation
      setTimeout(() => {
        this.move(marker, latlngs, index + 1, wait, newDestination, bearing);
      }, wait);
    }
  }

  // getBearing(startLat, startLng, destLat, destLng) {
  //   function toRadians(degrees) {
  //     return (degrees * Math.PI) / 180;
  //   }

  //   // Converts from radians to degrees.
  //   function toDegrees(radians) {
  //     return (radians * 180) / Math.PI;
  //   }

  //   startLat = toRadians(startLat);
  //   startLng = toRadians(startLng);
  //   destLat = toRadians(destLat);
  //   destLng = toRadians(destLng);
  //   let bearing;

  //   const y = Math.sin(destLng - startLng) * Math.cos(destLat);
  //   const x =
  //     Math.cos(startLat) * Math.sin(destLat) - Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
  //   bearing = Math.atan2(y, x);
  //   bearing = toDegrees(bearing);

  //   return ((bearing + 360) % 360).toFixed(0);
  // }

  getIconUrl(res): void {
    if(this.iconUrl.includes('/assets/img/vehicles')) {
      this.iconUrl = '/assets/img/vehicles/' + (res?.active_vehicle ? res.active_vehicle : 'vehicle-car') + '-top.svg';
    }
  }

  ngOnInit(): void {
    this.vehicleLocationSubscription$ = this.vehicleTrackingMapService
      .subscribeToLocation(this.driverId)
      .subscribe((res: any) => {
        if(this.showOnline){
          if (res) {
            if(res.online){
              this.getIconUrl(res);
              this.generateMarkers(res);
            }
          }
        } else {
          if (res) {
            this.getIconUrl(res);
            this.generateMarkers(res);
          }
        }

      });
  }

  ngOnDestroy(): void {
    this.vehicleLocationSubscription$.unsubscribe();
    if (this.marker) {
      this.marker.setMap(null);
    }
  }
}
