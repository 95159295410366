import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { DashboardRoutingModule } from './dashboard-routing.module';

import { UploadOrdersComponent } from './upload-orders/upload-orders.component';
import { UploadOrdersModule } from './upload-orders/upload-orders.module';

import { SharedModule } from '../shared/shared.module';
import { DashboardComponent } from './dashboard.component';

import { GeocodingComponent } from './geocoding/geocoding.component';
import { ManageBucketComponent } from './buckets/manage-bucket/manage-bucket.component';
import { ManageBucketModule } from './buckets/manage-bucket.module';
import { ManageBusinessComponent } from './manage-business/manage-business.component';
import { ManageBusinessModule } from './manage-business/manage-business.module';
import { TripsService } from './trips.service';

import { PostDispatchModule } from './post-dispatch/post-dispatch.module';
import { PostDispatchComponent } from './post-dispatch/post-dispatch.component';

// TripView
import { GlobalSearchComponent } from './global-search/global-search.component';
import { PlaceOrderComponent } from './place-order/place-order.component';
import { DriverReportTableComponent } from './reporting/driver-report-table/driver-report-table.component';
import { DriverScoreTableComponent } from './reporting/driver-score-table/driver-score-table.component';
import { BucketsComponent } from './buckets/buckets.component';
import { HomeComponent } from './home/home.component';
import { ManageUserComponent } from './manage-user/manage-user.component';
import { ManageUserModule } from './manage-user/manage-user.module';
import { ZonesComponent } from './geocoding/zones/zones.component';
import { NewZoneNameComponent } from './geocoding/zones/new-zone-name/new-zone-name.component';

import { CommonModule } from '@angular/common';

import { NewTripsModule } from './new-trips/new-trips.module';
import { NotesModule } from './notes/notes.module';
import { VisualReportsComponent } from './reporting/visual-reports/visual-reports.component';
import { CsvReportsComponent } from './reporting/csv-reports/csv-reports.component';
import { SuccessVsFailurePerAllocationComponent } from './reporting/visual-reports/success-vs-failure-per-allocation/success-vs-failure-per-allocation.component';
import { BucketStatusBreakdownComponent } from './reporting/visual-reports/bucket-status/bucket-status-breakdown.component';
import { DriverPerformanceBreakdownComponent } from './reporting/visual-reports/driver-performance-breakdown/driver-performance-breakdown.component';
import { DeliverySLABreakdownComponent } from './reporting/visual-reports/delivery-sla-breakdown/delivery-sla-breakdown.component';
import { HomeOverviewComponent } from './home/components/home-overview/home-overview.component';
import { FilterWarehousesPipe } from './home/pipes/filter-warehouses.pipe';
import { WarehouseNamePipe } from './home/pipes/warehouse-name.pipe';
import { ProgressBarGraphComponent } from './home/components/progress-bar-graph/progress-bar-graph.component';
import { FlexPercentagePipe } from './home/pipes/flex-percentage.pipe';
import { RandomColorPipe } from './home/pipes/random-color.pipe';
import { OrderStatisticsComponent } from './home/components/home-overview/order-statistics/order-statistics.component';
import { BucketTableComponent } from './home/components/home-overview/bucket-table/bucket-table.component';
import { HideDeliveryBucketStatsPipe } from './home/pipes/hide-delivery-bucket-stats.pipe';
import { DashboardLoaderPipe } from './home/pipes/dashboard-loader.pipe';


@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    ManageBucketModule,
    ManageBusinessModule,
    DashboardRoutingModule,
    UploadOrdersModule,
    PostDispatchModule,
    ManageUserModule,
    NewTripsModule,
    NotesModule
  ],
  exports: [],
  declarations: [
    UploadOrdersComponent,
    DashboardComponent,
    GeocodingComponent,
    ManageBucketComponent,
    ManageBusinessComponent,
    PostDispatchComponent,
    GlobalSearchComponent,
    PlaceOrderComponent,
    DriverReportTableComponent,
    DriverScoreTableComponent,
    BucketsComponent,
    HomeComponent,
    ManageUserComponent,
    ZonesComponent,
    NewZoneNameComponent,
    VisualReportsComponent,
    CsvReportsComponent,
    SuccessVsFailurePerAllocationComponent,
    BucketStatusBreakdownComponent,
    DriverPerformanceBreakdownComponent,
    DeliverySLABreakdownComponent,
    HomeOverviewComponent,
    FilterWarehousesPipe,
    WarehouseNamePipe,
    ProgressBarGraphComponent,
    FlexPercentagePipe,
    RandomColorPipe,
    OrderStatisticsComponent,
    BucketTableComponent,
    HideDeliveryBucketStatsPipe,
    DashboardLoaderPipe
  ],
  providers: [TripsService],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [],
})
export class DashboardModule {
  constructor() {}
}
