import { Component, OnInit } from '@angular/core';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { IconTypes } from '../icon/icon.interfaces';
import { ModalAction, ModalSizes } from '../modals/modal.interfaces';
import { RadioSelectActionEnum, RadioSelectData, RadioSelectDetails } from './radio-select-modal.interface';

@Component({
  selector: 'app-radio-select-modal',
  templateUrl: './radio-select-modal.component.html',
  styleUrls: ['./radio-select-modal.component.scss'],
})
export class RadioSelectModalComponent extends SimpleModalComponent<RadioSelectData, string> implements OnInit {
  public actions: ModalAction[] = [
    { name: RadioSelectActionEnum.Close, title: 'Close', isPrimary: false },
    { name: RadioSelectActionEnum.Confirm, title: 'Confirm', isPrimary: true },
  ];
  title: string;
  modalSize: ModalSizes = ModalSizes.Large;
  icon: IconTypes = IconTypes.Driver;
  radioOptions: string[] = [];
  message: string;
  selectedRadio: string;
  details: RadioSelectDetails;
  UiColors = UiColors;
  IconTypes = IconTypes;
  color: UiColors = UiColors.Grey8;

  constructor() {
    super();
  }

  ngOnInit(): void {}

  handleAction($event: string): void {
    if ($event === RadioSelectActionEnum.Confirm) {
      if (!this.selectedRadio) {
        return;
      }
      this.result = this.selectedRadio;
      this.close();
    } else {
      this.close();
    }
  }

  navigateToBucket(): void {
    window.open(window.location.origin + `/dashboard/buckets/(Buckets:${this.details?.bucketId})`, '_blank');
  }
}
