import { AddressBookEntry } from './../../shared.interfaces';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Trip, Waypoint, Contact, Address, Parcel } from '../../../dashboard/place-order/trip.interfaces';
import { JustifyContent } from 'app/shared/flex-container/flex-container.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { UiThemes, UiColors } from 'app/interfaces/ui.interfaces';
import { TimeDatePickerModalComponent } from 'app/shared/modals/time-date-picker.modal/time-date-picker.modal.component';
import { PlaceOrderService } from 'app/dashboard/place-order/place-order.service';
import { SimpleModalService } from 'ngx-simple-modal';
import { Store } from '@ngrx/store';
import { selectorActingAs } from 'app/auth/auth.reducer';
import { SharedService } from 'app/shared/shared.service';
import { NotificationsService } from 'app/shared/notifications/notifications.service';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationModalComponent, ConfirmationModalData } from 'app/shared/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-quote-one-to-many',
  templateUrl: './quote-one-to-many.component.html',
  styleUrls: ['./quote-one-to-many.component.scss']
})
export class QuoteOneToManyComponent implements OnInit, OnDestroy {
  public justifyContent = JustifyContent;
  public IconTypes = IconTypes;
  public ButtonTypes = ButtonTypes;
  public UiThemes = UiThemes;
  public UiColors = UiColors;

  @Input() picupDetails = <Trip>{
    sender: <Waypoint>{
      contact: <Contact>{},
      address: <Address>{},
    },
    receivers: Array<Waypoint>(),
  };

  @Output() quote = new EventEmitter();
  @Output() action = new EventEmitter();

  activeTab = 1;
  displayInvalid = false;

  parcelTypes = ['parcel-a4-envelope', 'parcel-small', 'parcel-medium', 'parcel-large', 'parcel-xlarge'];

  prettyDateTime;
  error: any;
  processing = false;
  actingAs;
  vehicleNiceName: string;
  environment = environment;
  private unsubscribe$ = new Subject<void>();


  constructor(
    private notificationService: NotificationsService,
    private placeOrderService: PlaceOrderService,
    private simpleModalService: SimpleModalService,
    private store: Store<any>,
    public sharedService: SharedService,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.store.select(selectorActingAs).pipe(takeUntil(this.unsubscribe$)).subscribe((next) => (this.actingAs = next));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  changeAllocation(): void {
    if (this.picupDetails.fleet_allocation === 'Contractor') {
      this.picupDetails.fleet_allocation = 'Picup ';
    } else {
      this.picupDetails.fleet_allocation = 'Contractor';
    }
  }

  changeVehicleType(): void {
    switch (this.picupDetails.vehicle_id) {
      case undefined:
        this.vehicleNiceName = 'Motorcycle';
        this.picupDetails.vehicle_id = 'vehicle-motorcycle';
        break;
      case 'vehicle-motorcycle':
        this.vehicleNiceName = 'Car';
        this.picupDetails.vehicle_id = 'vehicle-car';
        break;
      case 'vehicle-car':
        this.vehicleNiceName = 'Small Van';
        this.picupDetails.vehicle_id = 'vehicle-small-van';
        break;
      case 'vehicle-small-van':
        this.vehicleNiceName = 'Large Van';
        this.picupDetails.vehicle_id = 'vehicle-large-van';
        break;
      default:
        this.picupDetails.vehicle_id = undefined;
    }
  }

  checkAddressSave(): void {
    if (this.picupDetails.sender.save) {
      const addressBookData: AddressBookEntry = {
        description: this.picupDetails.sender.contact_description ?? this.picupDetails.sender.contact.name,
        business_id: this.actingAs.id,
        name: this.picupDetails.sender.contact.name,
        suburb: this.picupDetails.sender.address.suburb,
        postal_code: this.picupDetails.sender.address.postal_code,
        formatted_address: this.picupDetails.sender.address.formatted_address,
        phone: this.picupDetails.sender.contact.cellphone,
        email: this.picupDetails.sender.contact.email,
        complex: this.picupDetails.sender.address.complex,
        unit_no: this.picupDetails.sender.address.unit_no,
        country: this.picupDetails.sender.address.country,
        country_short_code: this.picupDetails.sender.address.country_short_code,
        latitude: this.picupDetails.sender.address.latitude,
        longitude: this.picupDetails.sender.address.longitude,
      };

      this.sharedService
        .setAddress(addressBookData)
        .then(() => {
          this.notificationService.publish({
            type: 'success',
            message: 'Contact Added',
          });
          this.picupDetails.sender.save = false;
        })
        .catch((error) => {
          this.notificationService.publish({
            type: 'error',
            message: error.response?.data.message,
          });
          this.picupDetails.sender.save = false;
        });
    }
    this.picupDetails.receivers.forEach((waypoint) => {
      if (waypoint.save) {
        const addressBookData: AddressBookEntry = {
          description: waypoint.contact_description ?? waypoint.contact.name,
          business_id: this.actingAs.id,
          name: waypoint.contact.name,
          suburb: waypoint.address.suburb,
          postal_code: waypoint.address.postal_code,
          formatted_address: waypoint.address.formatted_address,
          phone: waypoint.contact.cellphone,
          email: waypoint.contact.email,
          complex: waypoint.address.complex,
          unit_no: waypoint.address.unit_no,
          country: waypoint.address.country,
          country_short_code: waypoint.address.country_short_code,
          latitude: waypoint.address.latitude,
          longitude: waypoint.address.longitude,
        };
        this.sharedService
          .setAddress(addressBookData)
          .then(() => {
            this.notificationService.publish({
              type: 'success',
              message: 'Contact Added',
            });
            waypoint.save = false;
          })
          .catch((error) => {
            this.notificationService.publish({
              type: 'error',
              message: error.response?.data.message,
            });
            waypoint.save = false;
          });
      }
    });
  }

  validateDetails(): boolean {
    return (
      this.picupDetails.customer_ref &&
      this.picupDetails.customer_ref.length < 26 &&
      this.picupDetails.receivers[this.activeTab - 2].is_valid === true
    );
  }

  createFreightTrip(): void {
    this.checkAddressSave();
    if (this.validateDetails()) {
      this.error = null;
      this.displayInvalid = false;
      this.processing = true;
      if (this.picupDetails.receivers.length === 2) {
        this.picupDetails.optimize_waypoints = false;
      }
      this.placeOrderService.createFreight(this.picupDetails).catch((error) => {
        this.processing = false;
        this.error = error.validation_errors;
      });
    }
    this.displayInvalid = true;
  }

  createOneToMany(): void {
    this.processing = true;
    this.checkAddressSave();
    this.picupDetails.fleet_allocation = 'Picup';
    this.picupDetails.business_id = this.actingAs.id;
    if (this.picupDetails.sender.warehouse_id) {
      this.picupDetails.sender = { warehouse_id: this.picupDetails.sender.warehouse_id };
    }
    if (this.validateDetails()) {
      this.placeOrderService.createOrder(this.picupDetails).then((response) => {
        this.processing = false;
        this.router.navigate(['/dashboard/orders/trip/', response.order_id]);
      });
    } else {
      this.displayInvalid = true;
      this.processing = false;
    }
  }

  getQuote(): void {
    this.checkAddressSave();
    if (this.validateDetails()) {
      this.error = null;
      this.displayInvalid = false;
      this.processing = true;
      if (this.picupDetails.sender.warehouse_id) {
        this.picupDetails.sender = {
          warehouse_id: this.picupDetails.sender.warehouse_id,
          special_instructions: this.picupDetails.sender.special_instructions,
        };
      }

      if (this.picupDetails.receivers.length === 2) {
        this.picupDetails.optimize_waypoints = false;
      }

      this.placeOrderService
        .quotePicup(this.picupDetails)
        .then((response) => {
          this.processing = false;
          this.quote.emit(response?.data);
        })
        .catch((error) => {
          this.processing = false;
          this.error = error.validation_errors;
        });
    } else {
      this.displayInvalid = true;
      this.processing = false;
    }
    if (this.picupDetails.customer_ref.length > 25) {
      this.error = [{ errors: ['Customer Reference may not be over 25 Characters'] }];
    }
  }

  toggleSchedule(): void {
    const date = this.picupDetails.scheduled_date;
    if (date) {
      return (this.picupDetails.scheduled_date = null);
    } else {
      this.simpleModalService.addModal(TimeDatePickerModalComponent).subscribe((x) => {
        if (x) {
          this.picupDetails.scheduled_date = x.jsDateTime;
          this.prettyDateTime = x.prettyDateTime;
        }
      });
    }
  }

  removeReceiver(index: number): void {
    this.picupDetails.receivers.splice(index, 1);
    this.activeTab = this.picupDetails.receivers.length + 1;
  }

  clearForm(): void {
    this.displayInvalid = false;
    this.error = null;
    this.picupDetails.fleet_allocation = 'Picup';
    this.picupDetails.merchant_id = 'merchant-d827f668-d434-4ce5-b853-878f874ae746';
    this.picupDetails.courier_costing = 'NONE';
    this.picupDetails.is_round_trip = false;
    this.picupDetails.customer_ref = null;
    this.picupDetails.receivers = Array<Waypoint>();
    this.picupDetails.sender = <Waypoint>{
      address: <Address>{},
      contact: <Contact>{},
      contact_description: null,
      searchAddress: false,
    };
    this.picupDetails.receivers.push({
      is_valid: false,
      contact: <Contact>{},
      address: <Address>{},
      special_instructions: null,
      parcels: Array<Parcel>(),
    });
    window.scrollTo(0, 0);
    // this.startEndPointService.resetStartEndTasks()
    this.activeTab = 1;
  }

  changeTab(index: number): void {
    switch (index) {
      case 0:
        if (this.picupDetails.sender.is_valid && this.picupDetails.customer_ref) {
          this.activeTab = index + 2;
          this.displayInvalid = false;
        } else {
          this.displayInvalid = true;
        }
        break;
      default:
        if (this.picupDetails.receivers[index - 1].is_valid) {
          this.activeTab = index + 2;
          this.displayInvalid = false;
        } else {
          this.displayInvalid = true;
        }
    }
  }

  addReceiver(): void {
    this.checkAddressSave();
    this.error = null;
    if (this.activeTab === 1 && this.picupDetails.receivers.length > 0) {
      if (this.picupDetails.sender.is_valid && this.picupDetails.customer_ref) {
        this.activeTab = this.activeTab + 1;
        this.displayInvalid = false;
      } else {
        this.displayInvalid = true;
      }
    } else if (this.activeTab < this.picupDetails.receivers.length) {
      this.activeTab = this.activeTab + 1;
      this.displayInvalid = false;
    } else {
      if (this.picupDetails.receivers[this.activeTab - 2]?.is_valid === true) {
        this.picupDetails.receivers.push({
          is_valid: false,
          contact: <Contact>{},
          address: <Address>{},
          special_instructions: null,
          parcels: Array<Parcel>(),
        });
        if (this.actingAs.package === 'freight') {
          this.picupDetails.receivers[this.picupDetails.receivers.length - 2].parcels.push({
            size: 'parcel-large',
            reference: 'picup-freight-' + this.activeTab,
          });
        }
        this.activeTab = this.activeTab + 1;
        this.displayInvalid = false;
      } else {
        if (this.picupDetails.receivers.length) {
          this.displayInvalid = true;
        }
      }
    }
  }

  tasksSaved(success: boolean): void {
    if (success) {
      this.activeTab = 1;
    } else {
      this.openValidationErrorPopup();
    }
  }

  ngOnInit(): void {
    if (this.picupDetails.receivers.length === 0) {
      this.addReceiver();
    }
  }

  clickAwayFromTasks(): void {
    if (this.activeTab !== 0) {
      this.activeTab = 1;
    }
  }

  openValidationErrorPopup(): void {
    const configuration: MatDialogConfig<ConfirmationModalData> = {
      width: '25%',
      data: {
        cancelButtonText: 'Cancel',
        confirmationButtonText: 'Continue without saving',
        description: 'There are currently unsaved changes. Please make sure that all required fields are entered before navigating away, else all your unsaved changes will be lost.',
        title: 'Unsaved changes'
      },
    };
    const dialogRef = this.dialog.open(
      ConfirmationModalComponent,
      configuration
    );

    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe$)
    ).subscribe((result: boolean) => {
      if (result) {
        this.activeTab = 1;
      }
    });
  }
}
