<app-section>
  <app-flex-container
    class="overall"
    [flexWrap]="'wrap'"
    [justifyContent]="justifyContent.FlexStart"
    [padding]="'2rem'"
    [theme]="UiThemes.Dark"
    *ngIf="
      (!viewMode || viewMode === 'active') &&
      lastMileParcelSummaryData?.length &&
      parcelRouteSummaryColors?.domain?.length
    "
  >
    <div class="pieholder">
      <h4>Active Route Parcel Status Overview</h4>
      <app-complex-pie
        [colorScheme]="parcelRouteSummaryColors"
        (onSelected)="getReportBreakdown('last_mile', $event)"
        [pieData]="lastMileParcelSummaryData"
      >
      </app-complex-pie>
    </div>
  </app-flex-container>
  <app-flex-container
    class="overall"
    *ngIf="viewMode === 'order' && orderParcelSummaryData?.length && parcelTripSummaryColors?.domain?.length"
    [flexWrap]="'wrap'"
    [justifyContent]="justifyContent.FlexStart"
    [padding]="'2rem'"
    [theme]="UiThemes.Dark"
  >
    <div class="pieholder">
      <h4>Trip Parcel Status Overview</h4>
      <app-complex-pie
        [colorScheme]="parcelTripSummaryColors"
        (onSelected)="getReportBreakdown('order', $event)"
        [pieData]="orderParcelSummaryData"
      >
      </app-complex-pie>
    </div>
  </app-flex-container>
  <app-flex-container
    class="overall"
    *ngIf="viewMode === 'courier' && courierParcelSummaryData?.length && parcelCourierSummaryColors?.domain?.length"
    [flexWrap]="'wrap'"
    [justifyContent]="justifyContent.FlexStart"
    [padding]="'2rem'"
    [theme]="UiThemes.Dark"
  >
    <div class="pieholder">
      <h4>Courier Parcel Status Overview</h4>
      <app-complex-pie
        [colorScheme]="parcelCourierSummaryColors"
        (onSelected)="getReportBreakdown('courier', $event)"
        [pieData]="courierParcelSummaryData"
      >
      </app-complex-pie>
    </div>
  </app-flex-container>
  <app-parcel-report-table [ParcelReportData]="ParcelReportData"></app-parcel-report-table>
</app-section>
