import { Component, OnInit, Input } from '@angular/core';
import { JustifyContent } from '../flex-container/flex-container.interfaces';
import { IconTypes } from '../icon/icon.interfaces';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';

@Component({
  selector: 'app-static-site-nav',
  templateUrl: './static-site-nav.component.html',
  styleUrls: ['./static-site-nav.component.scss']
})
export class StaticSiteNavComponent implements OnInit {

  public justifyContent = JustifyContent;
  public IconTypes = IconTypes;
  public UiColors = UiColors
  uiThemes = UiThemes;

  @Input() showHome = true;
  navOpen = false;

  constructor() { }

  ngOnInit() {
  }

}
