import { Pipe, PipeTransform } from '@angular/core';
import { Driver } from '../assign-driver-modal/assign-driver-modal.interface';

@Pipe({
  name: 'assignDriverPipe'
})
export class AssignDriverPipePipe implements PipeTransform {

  transform(driver: Driver, businessId: string, warehouseId: string): boolean {
    const isDriverAssignedToOtherBusiness = driver.businessesOnLastMile?.some(driverBusinessId => {
      return driverBusinessId !== businessId
    }) ?? false;

    let isDriverOnRoundTrip = false
    if(driver.roundTripWarehouse?.warehouseId && warehouseId){
      isDriverOnRoundTrip = (driver.roundTripWarehouse?.warehouseId !== warehouseId);
    }
    return isDriverAssignedToOtherBusiness || isDriverOnRoundTrip;
  }

}
