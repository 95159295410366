import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { selectorActingAs } from 'app/auth/auth.reducer';
import { PieChartData } from 'app/operations-tracking/operations-tracking.interface';
import { OperationsTrackingService } from 'app/operations-tracking/operations-tracking.service';
import moment from 'moment';
import { DailySummaryData, DailySummaryStats } from './daily-summary.interface';

@Component({
  selector: 'app-daily-summary',
  templateUrl: './daily-summary.component.html',
  styleUrls: ['./daily-summary.component.scss']
})
export class DailySummaryComponent implements OnInit, AfterViewInit {
  warehouseFilter: string[] = [];
  businessId: string = '';
  postDispatchSummaryData: any;
  displayedColumns: string[] = [
    'customer_reference',
    'progress',
    'assigned',
    'collected',
    'failed_collection',
    'delivered',
    'failed_delivery',
    'returned',
    'cancelled',
    'failed_return',
    'updated_at',
    'pending',
    'completed',
    'driver_name',
    'final_collection_attempt_failed',
    'failed',
    'total',
    'action'
  ];
  date: FormControl = new FormControl(new Date());
  dataSource: MatTableDataSource<DailySummaryData> = new MatTableDataSource([]);
  dailySummaryStats: DailySummaryStats;
  loading: boolean = false;
  pieChartData: PieChartData[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(private operationsTrackingService: OperationsTrackingService, private store: Store) {
    this.store.select(selectorActingAs).subscribe((x) => (this.businessId = x.id));
  }

  ngOnInit(): void {
    this.getTodaysSummary(moment(new Date()).format('YYYY-MM-DD'));
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getTodaysSummary(date: string): void {
    this.loading = true;
    this.operationsTrackingService.getTodaysSummary(this.businessId, this.warehouseFilter, date).then((response) => {
      this.dataSource.data = response;
      this.buildStats(response);
    });
  }

  trackBy(_index: number, item: DailySummaryData): string {
    return item.order_id;
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  buildStats(data: DailySummaryData[]): void {
    const stats: DailySummaryStats = {
      orders_in_assigned_state: 0,
      assigned: 0,
      cancelled: 0,
      collected: 0,
      completed: 0,
      delivered: 0,
      failed: 0,
      failed_collection: 0,
      failed_delivery: 0,
      failed_return: 0,
      final_collection_attempt_failed: 0,
      pending: 0,
      returned: 0,
      total: 0
    };

    data.forEach((x) => {
      stats.assigned += x.assigned;
      stats.cancelled += x.cancelled;
      stats.collected += x.collected;
      stats.completed += x.completed;
      stats.delivered += x.delivered;
      stats.failed += x.failed;
      stats.failed_collection += x.failed_collection;
      stats.failed_delivery += x.failed_delivery;
      stats.failed_return += x.failed_return;
      stats.final_collection_attempt_failed += x.final_collection_attempt_failed;
      stats.pending += x.pending;
      stats.returned += x.returned;
      stats.total += x.total;
      stats.orders_in_assigned_state = (x.assigned > 0) ? stats.orders_in_assigned_state + 1 : stats.orders_in_assigned_state + 0;
    });

    this.pieChartData = [
      { name: 'Cancelled', value: stats.cancelled },
      { name: 'Completed', value: stats.completed },
      { name: 'Failed', value: stats.failed },
      { name: 'Returned', value: stats.returned },
      { name: 'In Progress', value: stats.total - (stats.cancelled + stats.completed + stats.failed + stats.returned)}
    ];
    this.dailySummaryStats = stats;
    this.loading = false;
  }

  viewOrder(orderId: string): void {
    window.open(`${window.location.origin}/dashboard/orders/trip/${orderId}`);
  }

}
