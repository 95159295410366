<app-section>
    <heading [icon]="Icons.Types.Parcel" [color]="Icons.Colors.Blue">
      <h4 class="heading-title">
        <strong>Place a Picup</strong>
      </h4>
    </heading>

    <div class="card-wrap">
      <div class="card">
        <div class="center">
            <svg-icon src="/assets/img/icons/trip.svg"></svg-icon>
            <app-basic-button
          [title]="'Place a Picup'"
          [type]="buttonTypes.Primary"
          (click)="navigateToPlace()">
            </app-basic-button>
              
            
        </div>

      </div>
    </div>
  </app-section>