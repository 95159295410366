<app-section>
  <heading [icon]="iconTypes.Dollar" [color]="uiColors.Blue">
    <h4 class="heading-title">Credits</h4>
  </heading>

  <app-grid-container [columns]="'repeat(1, 1fr)'" [padding]="'5rem'" [colGap]="'2rem'" [rowGap]="'2rem'"
    [theme]="UiThemes.Light">
    <h3>Current Available Balance: <span class="credit">{{wallet?.credits | currency: currency_symbol + ' '}}</span></h3>
    <div>
      <h4>Important Notice: </h4>
      <p>We will no longer be supporting credits as a payment method. Please save a credit card for easier future purchases.</p>
      <!-- <app-grid-container [columns]="'repeat(2, 1fr)'" [padding]="'0'" [colGap]="'2rem'" [rowGap]="'2rem'"
        [theme]="UiThemes.Light">
        <div class="amounts">
          <app-basic-button-group [buttons]="paymentAmounts" [buttonRemPadding]="1"
            (buttonClicked)="handleButtonClick($event)" [currentTab]="currentTab">
          </app-basic-button-group>
          <input [placeholder]="currency_symbol" id="ownAmount" *ngIf="ownAmount" type="text"
            [ngModel]="price | currency: currency_symbol : 'symbol':'0.0'" (ngModelChange)="price=$event"
            (keydown.enter)="$event.target.blur()" [ngModelOptions]="{updateOn:'blur'}">
        </div>
        <app-basic-button class="reset" [type]="buttonTypes.Primary" [title]="'Reset'" (click)="resetButtons()">
        </app-basic-button>
      </app-grid-container> -->


    </div>
    <!-- <app-payment #payment *ngIf="price" [price]="price" [intention]="3"></app-payment> -->

  </app-grid-container>
</app-section>