import { Pipe, PipeTransform } from '@angular/core';
import { DashboardDeliveryStats } from '../home.interface';

@Pipe({
  name: 'hideDeliveryBucketStats'
})
export class HideDeliveryBucketStatsPipe implements PipeTransform {

  transform(deliveryStats: DashboardDeliveryStats): boolean {
    return !Object.keys(deliveryStats).every((stat) => deliveryStats[stat] === 0);
  }

}
