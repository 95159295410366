<app-loader *ngIf="!mapReady" [loadingMessage]="'Loading map'"></app-loader>

<div
  class="vetting-filters py-2"
  *ngIf="mapType === MapTypes.Vetting && !hideFilters"
  [ngStyle]="{
    marginLeft: drawer.active && drawer.edge === 'left' ? 'calc(' + drawer.width + ' + 1rem)' : 0,
    marginRight: drawer.active && drawer.edge === 'right' ? 'calc(' + drawer.width + ' + 1rem)' : 0
  }"
>
  <form class="form-inline">
    <div class="form-group">
      <input
        type="checkbox"
        class="filled-in"
        id="checkbox111"
        (click)="checkbox('VETTED')"
        [checked]="filters.includes('VETTED')"
      />
      <label for="checkbox111">Vetted</label>
    </div>
    <div class="form-group">
      <input
        type="checkbox"
        class="filled-in"
        id="checkbox333"
        (click)="checkbox('SKIPPED')"
        [checked]="filters.includes('SKIPPED')"
      />
      <label for="checkbox333">Skipped</label>
    </div>
    <div class="form-group">
      <input
        type="checkbox"
        class="filled-in"
        id="checkbox444"
        (click)="checkbox('UNVERIFIED')"
        [checked]="filters.includes('UNVERIFIED')"
      />
      <label for="checkbox444">Unverified</label>
    </div>
    <div class="form-group">
      <input
        type="checkbox"
        class="filled-in"
        id="checkbox666"
        (click)="checkbox('FLAGGED')"
        [checked]="filters.includes('FLAGGED')"
      />
      <label for="checkbox666">Flagged</label>
    </div>
  </form>
</div>

<google-map
  #map
  [height]="mapHeight"
  [width]="'100%'"
  (tilesloaded)="mapElementReady()"
  [options]="gMapOptions">
</google-map>
