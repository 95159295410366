<div class="progress">
  <div>
    <svg-icon [class.current]="currentStatus === 'geocoding'"
              class="icon dot"
              *ngIf="!isGeocodingComplete()"
              src="../../../assets/img/icons/dot.svg"></svg-icon>
    <svg-icon class="icon tick"
              *ngIf="isGeocodingComplete()"
              src="../../../assets/img/icons/check.svg"></svg-icon>
    <span *ngIf="currentStatus === 'geocoding'">Geocoding</span>
  </div>
  <div>
    <svg-icon [class.current]="currentStatus === 'Verifying'"
              class="icon dot"
              *ngIf="!isVerifyComplete()"
              src="../../../assets/img/icons/dot.svg"></svg-icon>
    <svg-icon class="icon tick"
              *ngIf="isVerifyComplete()"
              src="../../../assets/img/icons/check.svg"></svg-icon>
    <span *ngIf="currentStatus === 'Verifying'">Verifying</span>
  </div>
  <div>
    <svg-icon [class.current]="currentStatus === 'processing'"
              class="icon dot"
              *ngIf="!isProcessingComplete()"
              src="../../../assets/img/icons/dot.svg"></svg-icon>
    <svg-icon class="icon tick"
              *ngIf="isProcessingComplete()"
              src="../../../assets/img/icons/check.svg"></svg-icon>
    <span *ngIf="currentStatus === 'processing'">Processing</span>
  </div>
  <div>
    <svg-icon [class.current]="currentStatus === 'trip_selection'"
              class="icon dot"
              *ngIf="!isTripSelectionComplete()"
              src="../../../assets/img/icons/dot.svg"></svg-icon>
    <svg-icon class="icon tick"
              *ngIf="isTripSelectionComplete()"
              src="../../../assets/img/icons/check.svg"></svg-icon>
    <span *ngIf="currentStatus === 'trip_selection'">Trip Selection</span>
  </div>
  <div>
    <svg-icon [class.current]="currentStatus === 'finalise'"
              class="icon dot"
              *ngIf="!isFinaliseComplete()"
              src="../../../assets/img/icons/dot.svg"></svg-icon>
    <svg-icon class="icon tick"
              *ngIf="isFinaliseComplete()"
              src="../../../assets/img/icons/check.svg"></svg-icon>
    <span *ngIf="currentStatus === 'finalise'">Finalise</span>
  </div>
</div>