import { Component, HostListener, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { selectorActingAs, selectorBusinesses, selectorUser } from '../../auth/auth.reducer';
import { Store } from '@ngrx/store';
import { AuthService } from '../../auth/auth.service';
import { IconTypes } from '../icon/icon.interfaces';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { MenuDropDownItems } from '../menu-dropdown/menu-drop-down.interfaces';
import { Router } from '@angular/router';
import { PaymentService } from '../payment/payment.service';
import { environment } from 'environments/environment';
import { AccessManagementService } from 'app/auth/access-management.service';
import { AccessManagementGqlService } from 'app/auth/access-management-gql.service';
import { Subscription } from 'rxjs';
import { ActingAs } from 'app/interfaces/auth.interfaces';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnChanges, OnDestroy {
  actingAs: ActingAs;
  wallet;
  user;
  accMeUser: any;
  businesses;
  isMenuOpen = false;
  searchString: string;
  public icons = {
    types: IconTypes,
    colors: UiColors,
  };
  adminMenuOptions: MenuDropDownItems[] = [];
  businessInvites = [];
  isInviteMenuOpen = false;
  currency_symbol = environment.currency_symbol;
  accessManagementSubscription: Subscription;

  constructor(
    public router: Router,
    public store: Store<any>,
    public authService: AuthService,
    public paymentService: PaymentService,
    private accessManagementService: AccessManagementService,
    private accManGQLService: AccessManagementGqlService
  ) {
    this.store.select(selectorActingAs).subscribe((next) => {
      if (next) {
        this.actingAs = next;
      } else {
        this.authService.logout();
      }
    });
    this.paymentService.wallet.subscribe((wallet) => {
      if (wallet) {
        this.wallet = wallet;
      }
    });
    this.store.select(selectorUser).subscribe((next) => {
      if (next) {
        this.user = next;
      }
    });
  }

  selectContext(business_id) {
    this.isMenuOpen = false;
    this.authService.setActingAs(business_id, true);
    this.getAccessManagementBusiness(business_id);
    this.clearSearch();
  }

  getAccessManagementBusiness(businessId: string): void {
    this.accessManagementService.setCurrentBusinessId(businessId);
    this.accessManagementService.getBusinessModules(businessId);
  }

  clearSearch() {
    if (document.getElementById('input')) {
      document.getElementById('input').focus();
    }
    this.searchString = null;
  }

  getBusinessInvites() {
    this.authService.pendingInvites.subscribe((invites) => {
      this.businessInvites = invites;
    });
  }

  acceptInvite(business_id) {
    this.authService.acceptInvite(business_id);
  }

  inviteDropdown() {
    this.isInviteMenuOpen = !this.isInviteMenuOpen;
  }

  navigateAlerts() {
    this.router.navigate(['/dashboard/alerts']);
  }

  @HostListener('document:click', ['$event'])
  closeMenu(event) {
    if (!this.isInviteMenuOpen) {
      return;
    }
    if (event.target.classList.contains('avatar-content')) {
      return;
    }
    this.isInviteMenuOpen = false;
  }
  ngOnInit() {
    this.store.select(selectorBusinesses).subscribe((next) => {
      if (next) {
        this.businesses = next;
      }
    });
    this.getBusinessInvites();
  }

  ngOnChanges(): void {
    this.getBusinessInvites();
  }

  ngOnDestroy(): void {
    this.accessManagementSubscription?.unsubscribe();
  }
}
