  <app-modal
  [open]="true"
  [actions]="actions"
  [highlightPrimaryAction]="true"
  [size]="ModalSizes.Large"
  (handleAction)="handleAction($event)"
>
  <heading [icon]="IconTypes.Alert" [color]="uiColors.Orange">
    <h4 class="heading-title">{{ title }}</h4>
  </heading>

  <div class="modal-body">
    <app-grid-container [columns]="'repeat(2, 1fr)'" [padding]="'2rem'" [colGap]="'2rem'" [rowGap]="'2rem'">
        <app-date-picker [date]="currentDate" (changeDate)="dateChanged($event)"></app-date-picker>
        <div *ngIf="startingTime.length > 0">
            <app-time-selector [selectedRange]="startingTime" [title]="'Activation Time'" (changeTime)="timeChanged($event)"></app-time-selector>
            <div class="dropdown-container">
                <h4>Select a Fleet</h4>
                <app-dropdown [dropdownTheme]="UiThemes.Dark" [activeItem]="fleet_allocation ? (fleet_allocation) : 'Select a Fleet'">
                    <div dropdownRef>
                    <a *ngFor="let fleet of fleet_types" class="dropdown-item" (click)="fleet_allocation = fleet"> {{ fleet }}</a>
                    </div>
                </app-dropdown>
            </div>
        </div>
      </app-grid-container>
  </div>
</app-modal>
  