<!-- TODO add [activeWarehouseFilter]="activeWarehouseFilter" -->
<app-collapsible-order-table
*ngIf="activeRoutes | async as activeRoutes"
[tripType]="'Active Drivers'"
[trips]="activeRoutes"
[tripsTotal]="activeRoutesTotal"
[objectType]="CollapsibleTableTypeEnum.LastMile"
[expanded]="true"
[icon]="Icons.Types.Trip"
[color]="Icons.Colors.Blue"
[tableDataMap]="fsActiveLastMileTableMap"
[tableColumns]="fsActiveLastMileTableColumns"
[primaryActions]="activeDriverPrimaryActions"
[defaultDisplayAmount]="activeRoutesAmount"
(paginateEvent)="handleActiveLastMiles($event)"
(tableSortChanged)="handleLastMileSortChange($event)"
>
</app-collapsible-order-table>