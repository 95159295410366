import { Component, OnInit } from '@angular/core';
import { BulkSendToCourierService, BulkTableDisplay } from '../../bulk-send-to-courier.service';
import { MatTableDataSource } from '@angular/material/table';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-bulk-send-to-courier-table',
  templateUrl: './bulk-send-to-courier-table.component.html',
  styleUrls: ['./bulk-send-to-courier-table.component.scss']
})
export class BulkSendToCourierTableComponent implements OnInit {
  displayedColumns: string[] = ['time', 'id', 'status', 'message'];
  totalOrders: number = 10;
  unsubscribe$ = new Subject<void>();
  data = new MatTableDataSource<BulkTableDisplay>([]);
  processingOrders = false

  constructor(private bulkSendToCourierService: BulkSendToCourierService){}

  ngOnInit(): void {
    this.bulkSendToCourierService.processingList$.pipe(takeUntil(this.unsubscribe$)).subscribe((value: BulkTableDisplay[]) => {
      this.data.data = value;
    });

    this.bulkSendToCourierService.isLoading$.pipe(takeUntil(this.unsubscribe$)).subscribe((value: boolean) => {
      this.processingOrders = value;
    });

    this.bulkSendToCourierService.totalOrders$.pipe(takeUntil(this.unsubscribe$)).subscribe((value: number) => {
      this.totalOrders = value;
    });
  }

  copyId(orderId: string): void {
    navigator.clipboard.writeText(orderId);
  }
}
