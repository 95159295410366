import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MapViewService {
  markersForBounds = new BehaviorSubject([]);
  overlayData = new BehaviorSubject(undefined);

  constructor() {}

  addMarkerToBounds(key, latitude, longitude): void {
    const marker = {
      key: key,
      latitude: latitude,
      longitude: longitude,
    };

    this.markersForBounds.next(this.markersForBounds.getValue().concat([marker]));
  }

  removeMarkersForBound(): void {
    this.markersForBounds.next([]);
  }
}
