<app-section>
    <div class="shadow-wrap">
        <heading [icon]="Icons.Types.Map" [color]="Icons.Colors.Orange">
            <h4 class="heading-title"> Zone Map</h4>
            <div class="controls">
                <!-- Commented out doesn't work -->
                <!-- <app-basic-button [title]="'Add Zone'" [type]="buttonTypes.Primary" [active]="false" (click)="editZone(null)">
                </app-basic-button> -->
                <app-basic-button
                    [title]="'View Map'" 
                    [type]="buttonTypes.Primary" 
                    (click)="showMap = !showMap">
                </app-basic-button>
            </div>
        </heading>
        <ng-container *ngIf="showMap">
            <div class="map-wrap">
                <google-map
                    width="100%"
                    height="550px"
                    (mapReady)="mapElementReady($event)"
                    [zoom]="12"
                    [options]="gMapOptions">
                    <div *ngIf="all_zones">
                        <ng-template ngFor let-index="index" let-zone [ngForOf]="all_zones?.spatial_data">
                            <agm-data-layer 
                                [geoJson]="zone.geojson"
                                [style]="firstLoad ? multiStyle : index > 0 ? multiStyle : highlightStyle"
                                (layerClick)="selectZone(zone)">
                            </agm-data-layer>
                        </ng-template>

                    </div>


                </google-map>
                <div *ngIf="zoneDetails" class="zone-details">
                    <div class="actions-wrap">
                        <div class="actions">
                            <app-basic-button 
                                [title]="'Edit Drivers'"
                                [type]="buttonTypes.Primary" 
                                [active]="true"
                                (click)="editZoneDrivers(zoneDetails)">
                            </app-basic-button>
                            <app-basic-button 
                                [title]="'Edit Zone'" 
                                [type]="buttonTypes.Primary" 
                                [active]="true"
                                (click)="editZone(zoneDetails)">
                            </app-basic-button>
                        </div>
                        <span></span>
                        <svg-icon class="close-details" src="../../../../assets/img/icons/back-arrow.svg"
                            (click)="closeDetails()">
                        </svg-icon>
                    </div>
                    <div class="block">
                        <div class="title">
                            <div class="row">
                                <h4>Zone Details:</h4>
                                <div class="underline"></div>
                                <div class="row row-double">
                                    <h2>Name</h2>
                                    <p>{{ zoneDetails.name }}</p>
                                    <h2>Assigned Drivers</h2>
                                    <p>3</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</app-section>