<div *ngIf="results">
  <div class="pbg-header">
    <div class="pbg-total">{{ total }}</div>
    <div class="pbg-title">{{ title }}</div>
  </div>
  <div #graph class="progress-bar-graph">
    <div
      [matTooltip]="result.name + ': ' + result.value"
      class="graph-item"
      [style.flex]="result.value | flexPercentage: total"
      [style.background]="result.color"
      *ngFor="let result of results"
    ></div>
  </div>
  <div class="legend">
    <div *ngFor="let result of results" class="legend-item">
        <div [style.background]="result.color" class="legend-color-display"></div>
        <div>
            {{result.name}}
        </div>
    </div>
  </div>
</div>
