import { selectorUser } from './../../auth/auth.reducer';
import { AuthUser } from './../../interfaces/auth.interfaces';
import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { ToastService } from './toast.service';
import Toast from './toast.interfaces';

@Component({
  moduleId: module.id,
  selector: 'app-toasts',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastsComponent implements OnInit {
  public toast: Toast;
  user: AuthUser;

  constructor(private toastService: ToastService, private store: Store<any>) {
    this.store.select(selectorUser).subscribe((next) => (this.user = next));
  }

  ngOnInit(): void {
    this.toastService.publication().subscribe((toast) => {
      if (!toast) {
        return;
      }

      this.toast = toast;
      setTimeout(() => this.close(), this.user.is_admin && toast.type !== 'success' ? 20000 : 8000);
    });
  }

  close(): void {
    this.toast = null;
  }

  toastClass(toast) {
    return {
      success: toast.type === 'success',
      error: toast.type === 'error',
      alert: toast.type === 'info',
    };
  }
}
