import { Component, OnInit } from '@angular/core';
import { IconTypes } from 'app/shared/icon/icon.interfaces';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

  iconTypes = IconTypes;
  mailText: string = '';
  constructor() { }

  ngOnInit(): void {
  }

  openMailer(): void{
    this.mailText = 'mailto:sales@picup.co.za';
    window.location.href = this.mailText;
  }

}
