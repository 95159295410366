<app-section>
  <heading [icon]="IconTypes.Details" [color]="UiColors.Blue">
    {{ 'Order Timeline' | uppercase }}
    <div class="flex-container">
      <mat-slide-toggle [(ngModel)]="visual"> Visual Timeline </mat-slide-toggle>
      <app-dropdown [activeItem]="filteredTableData ? filteredTableData[0].ParcelWaybillNumber : 'All Parcels'">
        <a class="dropdown-item" (click)="filterParcels(null)">All Parcels</a>
        <div dropdownRef>
          <a *ngFor="let parcel of parcelList" class="dropdown-item" (click)="filterParcels(parcel)">{{ parcel }}</a>
        </div>
      </app-dropdown>
    </div>
  </heading>
  <ng-container *ngIf="!visual">
    <app-table
      [columns]="timelineColumns"
      [dataMap]="timelineDataMap"
      [rawData]="filteredTableData ? filteredTableData : timelineRawData"
      [rowsSelectable]="false"
    >
    </app-table>
  </ng-container>
  <ng-container *ngIf="visual">
    <div class="flex-container">
      <app-order-timeline-visual [timelineData]="timelineTrips$ | async"></app-order-timeline-visual>
    </div>
  </ng-container>
</app-section>