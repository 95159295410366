import {
  Component,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  AfterViewInit,
  AfterViewChecked,
  NgZone,
  ElementRef,
  OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { selectorActingAs } from 'app/auth/auth.reducer';
import { ActingAs } from 'app/interfaces/auth.interfaces';
import { environment } from 'environments/environment';
import { AutocompletePreferences } from './places-autocomplete.interface';
@Component({
  selector: 'app-places-autocomplete',
  templateUrl: './places-autocomplete.component.html',
  styleUrls: ['./places-autocomplete.component.scss'],
})
export class PlacesAutocompleteComponent implements AfterViewInit, AfterViewChecked, OnInit {
  @Input() value: string;
  @Input() placeholder: string = '';
  @Input() autoComplete: string = 'none';
  @Input() label: string;
  @Input() isInvalid: boolean = false;
  @Input() labelTheme: string = 'dark';
  @Input() showClear: boolean = false;
  @Output() placesResponse = new EventEmitter<google.maps.places.PlaceResult>();
  @Output() clearAutoComplete = new EventEmitter<null>();
  @ViewChild('addressText') addressText: ElementRef;
  autocompleteInput: string;
  queryWait: boolean;
  actingAs: ActingAs;
  preferences: AutocompletePreferences = {
    fields: environment.autocompletePref.fields,
    componentRestrictions: { country: ['ZA'] },
    types: environment.autocompletePref.types,
  };
  constructor(private zone: NgZone, private store: Store<any>) {}

  ngOnInit(): void {
    this.store.select(selectorActingAs).subscribe((next: ActingAs) => {
      this.actingAs = next;
      if (this.actingAs?.localisation?.supported_geocode_country_codes?.length > 0) {
        this.preferences.componentRestrictions.country = this.actingAs.localisation.supported_geocode_country_codes;
      }
    });
  }

  ngAfterViewInit(): void {
    this.getPlaceAutocomplete();
  }

  ngAfterViewChecked(): void {
    document.getElementById('autocomplete').setAttribute('autocomplete', 'none');
  }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addressText.nativeElement, this.preferences);
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place: google.maps.places.PlaceResult = autocomplete.getPlace();
      this.invokeEvent(place);
    });
  }

  resetAutocomplete(): void {
    this.addressText = null;
    this.value = null;
    this.clearAutoComplete.emit();
  }

  invokeEvent(place: google.maps.places.PlaceResult): void {
    this.zone.run(() => {
      this.placesResponse.emit(place);
    });
  }
}
