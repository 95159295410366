export interface Order {
  business_reference: string;
  client_address: string;
  customer_name: string;
  customer_phone: string;
  delivery_date: string;
  geocoded_address: string;
  order_id: string;
  latitude: number;
  longitude: number;
  special_instructions: string;
}

export enum Order_Keys {
  BusinessReference = 'business_reference',
  CustomerName = 'customer_name',
  CustomerPhone = 'customer_phone',
  ClientAddress = 'client_address',
  DeliveryDate = 'delivery_date',
  OrderId = 'order_id',
  BucketOrderId = 'bucket_order_id',
  GeocodedPostalCode = 'geocoded_postal_code',
  GeocodedSuburb = 'geocoded_suburb',
  GeocodedAddress = 'geocoded_address',
  Latitude = 'latitude',
  Longitude = 'longitude',
  SpecialInstructions = 'special_instructions',
}


export const enum OrdersTableColumns {
  BusinessReference = 'businessReference',
  CustomerAddress = 'customerAddress',
  CustomerName = 'customerName',
  CustomerPhone = 'customerPhone',
  DeliveryDate = 'deliveryDate',
  Id = 'id',
  BucketOrderId = 'bucketOrderId',
}


