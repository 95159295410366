<app-section *ngIf="courierOrderDetails">
  <app-flex-container [justifyContent]="JustifyContent.SpaceBetween">
    <app-flex-container>
      <app-basic-button class="back-button" [title]="'Back'" [type]="ButtonTypes.Primary" (click)="backButton()">
      </app-basic-button>
      <app-basic-button-group
        [buttons]="viewTabs"
        [currentTab]="activeTab"
        [buttonRemPadding]="1"
        (buttonClicked)="handleTabNavigation($event)"
      ></app-basic-button-group>
    </app-flex-container>
    <app-basic-button
      *ngIf="courierOrderDetails.BucketId"
      [title]="'View Bucket'"
      [type]="ButtonTypes.Primary"
      (click)="goToBucket()"
    ></app-basic-button>
  </app-flex-container>
  <app-flex-container class="actions" [justifyContent]="JustifyContent.SpaceBetween">
    <app-flex-container>
      <app-menu-dropdown
        *ngIf="hasElevatedPermissions()"
        class="latest-state"
        [iconType]="IconTypes.AdminDashboardOnGrid"
        [iconSize]="1.5"
        [iconColor]="UiColors.Grey5"
        [items]="menuOptions"
        [dropDownWidthRem]="22"
        (itemClick)="handleActionClick($event)"
      >
      </app-menu-dropdown>
    </app-flex-container>
    <div class="latest-state">
      {{ courierOrderStatus?.name | humanize | uppercase }}
      <app-icon [icon]="IconTypes.Dot" [size]="2" [color]="courierOrderStatus?.colour"> </app-icon>
    </div>
  </app-flex-container>
</app-section>

<app-section *ngIf="courierOrderDetails?.CreateCourierOrderResponse?.CollectionResponse?.ErrorMessage">
  <div>
    <heading [icon]="IconTypes.Alert" [color]="UiColors.Red">
      <h4 class="heading-title">Error</h4>
    </heading>
    <app-grid-container [padding]="'2rem'" [columns]="'repeat(1, 1fr)'" [theme]="UiThemes.Dark">
      {{ courierOrderDetails?.CreateCourierOrderResponse?.CollectionResponse?.ErrorMessage }}
    </app-grid-container>
  </div>
</app-section>

<app-section *ngIf="courierOrderDetails && activeTab === 'Details'">
  <div>
    <div class="title">
      <app-icon-with-text [text]="'COURIER ORDER'" [textBold]="true" [sizeRem]="1" [color]="UiColors.White">
      </app-icon-with-text>
    </div>
    <app-fs-business-reference-breakdown
      [context]="'courier'"
      [businessReferenceBreakdown]="businessReferenceBreakdown"
    >
    </app-fs-business-reference-breakdown>
  </div>
</app-section>

<app-section *ngIf="!courierOrderDetails">
  <div class="loading-route">
    <h3>Loading Courier Order..</h3>
  </div>
</app-section>
<app-courier-orders-timeline
  [orderId]="courierOrderDetails.OrderId"
  [orderWaybillNumber]="courierOrderDetails.OrderWaybillNumber"
  *ngIf="activeTab === 'Timeline' && courierOrderDetails"
  [refresh]="update"
></app-courier-orders-timeline>
<app-section *ngIf="activeTab === 'Notes' && fsOrderDetails">
  <app-notes 
    [id]="courierOrderDetails?.OrderId" 
    [user]="user" 
    [notesDisplay]="orderNotes"
    [businessId]="fsOrderDetails?.BusinessId"
    [driverId]="fsOrderDetails?.assigned_drivers"
    [driverName]="fsOrderDetails?.DriverNames"
    [notesTypes]="notesTypes.Order"
    ></app-notes>
</app-section>
