import { Pipe, PipeTransform } from '@angular/core';
import { Warehouse } from 'app/interfaces/auth.interfaces';

@Pipe({
  name: 'filterWarehouses'
})
export class FilterWarehousesPipe implements PipeTransform {

  transform(warehouses: Warehouse[], filterText: string): Warehouse[] {
    if (!warehouses) {
      return [];
    }
    return warehouses?.filter((w) => w.name?.toLowerCase().includes(filterText.toLowerCase()));
  }

}
