import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-special-courier-final-screen',
  templateUrl: './special-courier-final-screen.component.html',
  styleUrls: ['./special-courier-final-screen.component.scss']
})
export class SpecialCourierFinalScreenComponent {
  @Input() description: string = '';

}
