import { Component, OnInit } from '@angular/core';
import { UploadOrdersService } from '../upload-orders.service';
import { Router } from '@angular/router';
import { IconTypes } from '../../../shared/icon/icon.interfaces';
import { UiColors, UiThemes } from '../../../interfaces/ui.interfaces';
import { Store } from '@ngrx/store';
import { selectorActingAs, selectorWarehouses } from 'app/auth/auth.reducer';
import { NotificationsService } from 'app/shared/notifications/notifications.service';
import { ActingAs, Warehouse } from 'app/interfaces/auth.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';

@Component({
  selector: 'app-upload-details',
  templateUrl: './upload-details.component.html',
  styleUrls: ['./upload-details.component.scss'],
})
export class UploadDetailsComponent implements OnInit {
  public UiThemes = UiThemes;
  processing = false;
  upload_response: any;
  warehouses: Warehouse[] = [];
  actingAs: ActingAs;
  newBuckets = 0;
  errorPresent;
  buttonTypes = ButtonTypes;

  Icons = {
    Colors: UiColors,
    Types: IconTypes,
  };

  constructor(
    public uploadOrdersService: UploadOrdersService,
    private notificationsService: NotificationsService,
    public router: Router,
    public store: Store<any>
  ) {
    this.upload_response = this.uploadOrdersService.upload_response;
    this.store.select(selectorActingAs).subscribe((next) => {
      this.actingAs = next;
    });
    this.store.select(selectorWarehouses).subscribe((next) => (this.warehouses = next));
  }

  getWarehouseNames() {
    for (let i = 0; i < this.upload_response.validation.bucket.length; i++) {
      for (let e = 0; e < this.warehouses.length; e++) {
        if (this.upload_response.validation.bucket[i].warehouse_id === this.warehouses[e].id) {
          this.upload_response.validation.bucket[i]['warehouse_name'] = this.warehouses[e].name;
        }
      }
    }
  }

  processCSV() {
    this.processing = true;
    this.uploadOrdersService.processCSV(this.upload_response).catch((error) => {
      this.notificationsService.publish({
        type: 'error',
        message: error.response?.data.message,
      });
      throw error;
    });
  }

  calculateNewBuckets() {
    this.newBuckets = 0;
    if (this.upload_response.validation) {
      for (let i = 0; i < this.upload_response.validation.bucket.length; i++) {
        if (this.upload_response.validation.bucket[i].bucket_id === -1) {
          this.newBuckets = this.newBuckets + 1;
        }
      }
    }
  }

  checkErrors() {
    if (this.upload_response.error_message) {
      this.errorPresent = true;
    }
    if (this.upload_response.validation) {
      this.upload_response.validation.bucket.forEach((bucket) => {
        if (!bucket.delivery_date || !bucket.shift_start || !bucket.shift_end || !bucket.warehouse_id) {
          this.errorPresent = true;
        }
      });
    }
  }

  cancelUpload() {
    this.router.navigate(['/dashboard/buckets']);
  }

  ngOnInit() {
    if (!this.upload_response) {
      this.router.navigate(['/dashboard/buckets']);
    }
    this.checkErrors();
    if (this.upload_response.validation) {
      this.calculateNewBuckets();
      this.getWarehouseNames();
    }
  }
}
