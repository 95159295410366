import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-complex-pie',
  templateUrl: './complex-pie.component.html',
  styleUrls: ['./complex-pie.component.scss'],
})
export class ComplexPieComponent implements OnInit {
  @Input() pieData;
  @Input() colorScheme;
  @Input() noDataMessage;
  @Output() selected = new EventEmitter<string>();
  @Input() view: [number, number]= [400, 170];

  constructor() {}

  onSelect($event) {
    this.selected.emit($event);
  }

  ngOnInit() {}
}
