import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedService } from '../shared.service';
import { Order, OrderParcel } from '../shared.interfaces';
import { QuerySnapshot } from '@angular/fire/firestore';
import { AuthUser, ActingAs } from 'app/interfaces/auth.interfaces';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { ButtonTypes } from '../buttons/basic-button.component';
import { JustifyContent } from '../flex-container/flex-container.interfaces';
import { IconTypes } from '../icon/icon.interfaces';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-order-summary-popup',
  templateUrl: './order-summary-popup.component.html',
  styleUrls: ['./order-summary-popup.component.scss']
})
export class OrderSummaryPopupComponent implements OnDestroy {

  JustifyContent = JustifyContent;
  user: AuthUser;
  actingAs: ActingAs;
  ButtonTypes = ButtonTypes;
  icons = {
    types: IconTypes,
    colors: UiColors,
  };
  UiThemes = UiThemes;

  summaryData: SummaryData;
  environment = environment;

  vehicleTypes = new Map<string, IconTypes>([
    ['vehicle-motorcycle', IconTypes.Motorbike],
    ['vehicle-car', IconTypes.Car],
    ['vehicle-small-van', IconTypes.SmallVan],
    ['vehicle-large-van', IconTypes.SmallVan],
  ]);
  vehicleNames = new Map<string, string>([
    ['vehicle-motorcycle', 'Motorbike'],
    ['vehicle-car', 'Car'],
    ['vehicle-small-van', 'Small Van'],
    ['vehicle-large-van', 'Large Van'],
  ]);

  unsubscribe$ = new Subject<void>();


  constructor(@Inject(MAT_DIALOG_DATA) public data: {orderId: string}, private sharedService: SharedService){
    if(data) {
      this.getSummaryDataFromId(data.orderId)
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


  getSummaryDataFromId(orderId: string): void{
    this.sharedService.getOrderFromFirestoreForSummary(orderId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((orderData: QuerySnapshot<Order>) => {
        this.summaryData = orderData.docs.map(FSOrderData => {
          const order = FSOrderData.data();
          return {
            quote: {
              distanceInMeters: order.Quote?.DistanceInMeters ?? 0,
              durationInMinutes: order.Quote?.DurationInMinutes ?? 0,
              earningsAmount: order.Quote?.Earnings.find(earnings => earnings.Fleet === order.FleetAllocation).Amount ?? order.Quote?.Earnings[0].Amount
            },
            waypoint: {
              waypoints: 0,
              contacts: 0
            },
            parcel: this.getParcelCounts(order.Parcels),
            vehicleType: order.Vehicle,
            username: order.Reservation?.Username
          } as SummaryData
        })[0];
      });
  }

  getParcelCounts(parcels: OrderParcel[]): ParcelData {
    const totals: ParcelData = {
      total: 0,
      collected: 0,
      delivered: 0,
      failed: 0,
      returned: 0,
    };
    parcels.forEach((parcel: OrderParcel) => {
      totals.total++;
      switch (parcel.OrderParcelState) {
        case 'Collected':
        case 'AtSortingFacility':
        case 'OutForDelivery':
          totals.collected++;
          break;
        case 'Delivered':
          totals.delivered++;
          break;
        case 'OutForReturn':
        case 'Cancelled':
        case 'AllocationFailed':
        case 'ExecutionFailed':
        case 'FailedCollection':
        case 'FailedDelivery':
        case 'FailedReturn':
          totals.failed++;
          break;
        case 'Returned':
          totals.returned++;
          break;
      }
    });
    return totals
  }

}

export interface ParcelData {
  total: number;
  collected: number;
  delivered: number;
  failed: number;
  returned: number;
}

export interface WaypointData {
  waypoints: number;
  contacts: number;
}

export interface QuoteData {
  distanceInMeters: number;
  durationInMinutes: number;
  earningsAmount: number;
}

export interface SummaryData {
  quote: QuoteData;
  waypoint: WaypointData;
  parcel: ParcelData;
  vehicleType: string;
  username?: string;
}
