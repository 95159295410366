import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ModalSizes, ModalAction } from 'app/shared/modals/modal.interfaces';
import { TableColumn, TableDataMap } from 'app/shared/table/table.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';

@Component({
  selector: 'app-tracking-table',
  templateUrl: './tracking-table.component.html',
  styleUrls: ['./tracking-table.component.scss'],
})
export class TrackingTableComponent extends SimpleModalComponent<any, any> implements OnInit {
  ModalSizes = ModalSizes;
  tableData;
  filteredTableData;
  parcelList = [];
  public IconTypes = IconTypes;
  public UiColors = UiColors;
  public UiThemes = UiThemes;

  public actions: ModalAction[] = [{ name: 'close', title: 'Close', isPrimary: false }];

  trackingTableColumns: TableColumn[] = [
    { name: 'timestamp', displayName: 'When Created' },
    { name: 'event_description', displayName: 'Description' },
  ];

  trackingTableDataMap: TableDataMap = {
    cells: {
      timestamp: { column: 'timestamp', map: 'timestamp', displayPipeArgs: ['date', 'medium'] },
      event_description: { column: 'event_description', map: 'event_description' },
    },
  };

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.tableData.forEach((event) => {
      if (this.parcelList.indexOf(event.parcel_waybill) === -1) {
        this.parcelList.push(event.parcel_waybill);
      }
    });
    this.filterParcels(this.parcelList[0]);
  }

  filterParcels(parcel_waybill) {
    this.filteredTableData = undefined;
    if (!parcel_waybill) {
      return;
    }
    this.filteredTableData = this.tableData.filter((parcels) => {
      return parcels.parcel_waybill === parcel_waybill;
    });
  }

  handleAction($event) {
    switch ($event) {
      case 'close':
        this.close();
        break;
    }
  }
}
