import { Component, OnInit } from '@angular/core';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { JustifyContent } from 'app/shared/flex-container/flex-container.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ConfirmModel } from '../confirm-modal.component';
import { ModalSizes, ModalAction } from '../modal.interfaces';

@Component({
  selector: 'app-boolean-modal',
  templateUrl: './boolean-modal.component.html',
  styleUrls: ['./boolean-modal.component.scss'],
})
export class BooleanModalComponent extends SimpleModalComponent<ConfirmModel, boolean | null> implements OnInit {
  public ModalSizes = ModalSizes;
  public IconTypes = IconTypes;
  public UiColors = UiColors;
  buttonTypes = ButtonTypes;
  public actions: ModalAction[] = [{ name: 'cancel', title: 'Cancel' }];
  public justifyContent = JustifyContent;
  title: string;
  description: string;
  allow_false: boolean;
  message: string;
  constructor() {
    super();
  }

  ngOnInit(): void {}

  handleAction($event) {
    switch ($event) {
      case 'cancel':
        this.result = null;
        this.close();
        break;
    }
  }
  handleButton(button_press: boolean): void {
    this.result = button_press;
    this.close();
  }
}
