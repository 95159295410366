<div class="button-wrap">
  <app-page-filter-buttons
    *ngIf="sectionIds"
    (sectionSelected)="selectedSection = $event"
    [selectedSection]="selectedSection"
    [sectionIds]="sectionIds"
  ></app-page-filter-buttons>
  <div class="buttons">
    <app-basic-button *ngIf="user.is_admin" [title]="'Add Credits'" [type]="buttonTypes.Primary" (click)="addCredits()">
    </app-basic-button>
    <app-basic-button
      *ngIf="user.is_admin"
      [title]="'Create Business'"
      [type]="buttonTypes.Highlight"
      (click)="createBusiness()"
    >
    </app-basic-button>
  </div>
</div>

<app-section *ngIf="selectedSection === 'General' || selectedSection === undefined">
  <heading [icon]="iconTypes.Gear" [color]="uiColours.Blue">
    <h4 class="heading-title">General</h4>
    <div class="controls">
      <app-basic-button
        [title]="'Reset Password'"
        [type]="buttonTypes.Warning"
        [active]="false"
        (click)="resetPassword()"
      >
      </app-basic-button>
      <app-basic-button [title]="'Save Changes'" [type]="buttonTypes.Primary" [active]="true" (click)="saveChanges()">
      </app-basic-button>
    </div>
  </heading>
  <app-grid-container
    [columns]="'repeat(2, 1fr)'"
    [padding]="'2rem 5rem 5rem'"
    [colGap]="'2rem'"
    [rowGap]="'2rem'"
    [theme]="UiThemes.Light"
  >
    <input-field [type]="'text'" (valueChanged)="user_model.name = $event" [value]="user_model.name" [label]="'Name'">
    </input-field>
    <input-field
      [disabled]="true"
      [type]="'text'"
      (valueChanged)="user_model.email = $event"
      [value]="user_model.email"
      [label]="'Email'"
    ></input-field>
    <input-field
      [type]="'tel'"
      (valueChanged)="user_model.phone_number = $event"
      [value]="user_model.phone"
      [label]="'Contact Number'"
    ></input-field>
    <app-dropdown
      class="dropdown"
      [label]="'Default Account'"
      [activeItem]="user_model.preferred_business_id ? user_model.preferred_business_name : 'Select a business'"
      [dropdownTheme]="'light'"
    >
      <div dropdownRef>
        <a
          class="dropdown-item"
          (click)="user_model.preferred_business_id = undefined; user_model.preferred_business_name = null"
          >None</a
        >
        <a
          class="dropdown-item"
          (click)="user_model.preferred_business_id = user.user_id; user_model.preferred_business_name = user.name"
          >{{ user.name }}</a
        >
        <ng-template ngFor let-business [ngForOf]="businesses">
          <a
            class="dropdown-item"
            (click)="
              user_model.preferred_business_id = business.business_id;
              user_model.preferred_business_name = business.name
            "
            >{{ business.name }}</a
          >
        </ng-template>
      </div>
    </app-dropdown>
    <div class="invalidmessage" *ngIf="phoneNotValid">
      <p>Your contact number is invalid</p>
    </div>
  </app-grid-container>
</app-section>

<app-buy-credits *ngIf="selectedSection === 'Credits' || selectedSection === undefined"> </app-buy-credits>

<!-- <app-invoices [context]="'user'" *ngIf="selectedSection === 'Invoices' || selectedSection === undefined"></app-invoices> -->

<app-address-book [context]="'user'" *ngIf="selectedSection === 'Address Book' || selectedSection === undefined">
</app-address-book>
