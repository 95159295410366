<div class="border-layout">
    <div class="image-layout">
        <img src="/assets/email/picup-logo.svg" alt="image">
    </div>
    <div class="layout-top">
        <div class="heading-padding">
            <p class="heading">We're saying goodbye to our pre-paid service</p>
        </div>  
        <div class="text-layout">
            <div>
            <p class="main-text">Dear valued Picup client</p> 
            </div>
            <div>
                <p class="main-text">
                    As you are aware, we've said goodbye to our pre-paid service option and are only supporting post-paid accounts through our Basic or Enterprise packages.
                </p>
                <p class="main-text">
                    If you did not manage to make the transition last year and would like to place an On Demand Picup, don't worry - it is really quick and easy!
            </div>
            <div class="button-area">
                <p class="main-text">To make the switch</p>
                <button class="button-style"
                    (click)="openMailer()">
                    <div class="button-inside-layout">
                        <mat-icon class="icon-padding">mail</mat-icon>
                        &nbsp;
                        <p class="button-text">GET IN TOUCH</p>
                    </div>
                </button>
            </div>
        </div>
        <div class="lists-layout">
            <div style="width:100%">
                <div>
                    <p class="sub-heading">Basic</p>
                </div>
                <div class="after-payment-padding">
                    <p class="payment-text">Monthly subscription for <span class="text-bold">R199</span></p>
                </div>
                <div class="points-layout">
                    <app-icon [icon]="iconTypes.CheckRingRound" [size]="2"></app-icon>
                    <p class="point-text">On-demand dispatching</p>
                </div>
                <div class="points-layout">
                    <app-icon [icon]="iconTypes.CheckRingRound" [size]="2"></app-icon>
                    <p class="point-text">Post-paid billing</p>
                </div>
                <div class="points-layout">
                    <app-icon [icon]="iconTypes.CheckRingRound" [size]="2"></app-icon>
                    <p class="point-text">Sign up</p>
                </div>
                <div class="points-layout"> 
                    <app-icon [icon]="iconTypes.CheckRingRound" [size]="2"></app-icon>
                    <p class="point-text">Online Support</p>
                </div>
                <div class="points-layout">
                    <app-icon [icon]="iconTypes.CheckRingRound" [size]="2"></app-icon>
                    <p class="point-text">Access to Picup’s nationwide delivery</p>
                </div>
                <div class="image-layout">
                    <img src="/assets/img/AB_Motorcycle.svg" alt="image">
                </div>
            </div>

            <div style="width:100%">
                <div>
                    <p class="sub-heading">Enterprise</p>
                </div>
                <div class="after-payment-padding">
                    <p class="payment-text">Subscription starting from <span class="text-bold">R999</span></p>
                </div>
                <div class="points-layout">
                    <app-icon [icon]="iconTypes.CheckRingRound" [size]="2"></app-icon>
                    <p class="point-text">Smart on-demand dispatching</p>
                </div>
                <div class="points-layout">
                    <app-icon [icon]="iconTypes.CheckRingRound" [size]="2"></app-icon>
                    <p class="point-text">Post-paid billing</p>
                </div>
                <div class="points-layout">
                    <app-icon [icon]="iconTypes.CheckRingRound" [size]="2"></app-icon>
                    <p class="point-text">Smart Geocoding</p>
                </div>
                <div class="points-layout">
                    <app-icon [icon]="iconTypes.CheckRingRound" [size]="2"></app-icon>
                    <p class="point-text">Multi-order placement</p>
                </div>
                <div class="points-layout">
                    <app-icon [icon]="iconTypes.CheckRingRound" [size]="2"></app-icon>
                    <p class="point-text">3rd party national delivery</p>
                </div>
                <div class="points-layout">
                    <app-icon [icon]="iconTypes.CheckRingRound" [size]="2"></app-icon>
                    <p class="point-text">Personalised branding</p>
                </div>
                <div class="points-layout">
                    <app-icon [icon]="iconTypes.CheckRingRound" [size]="2"></app-icon>
                    <p class="point-text">Access to our nationwide delivery</p>
                </div>
                <div class="points-layout">
                    <app-icon [icon]="iconTypes.CheckRingRound" [size]="2"></app-icon>
                    <p class="point-text">Online support</p>
                </div>
                <div class="points-layout">
                    <app-icon [icon]="iconTypes.CheckRingRound" [size]="2"></app-icon>
                    <p class="point-text">250 orders</p>
                </div>
            </div>
        </div>
    </div>
</div>
