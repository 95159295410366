<div class="grid" 
    [ngClass]="classes" 
    [ngStyle]="{
    'grid-template-columns': columns,
    'grid-gap': (rowGap ? rowGap : 0) + ' ' + (colGap ? colGap : 0),
    padding: padding,
    margin: margin,
    'justify-items': justifyItems,
    'alignItems': alignItems
  }">
  <ng-content></ng-content>
</div>