export interface DriverSelectData {
  drivers: string[];
}

export interface DriverData {
  name: string;
  id: string;
}

export enum DriverSelectActionsEnum {
  Cancel = 'Cancel',
  Confirm = 'Confirm',
}

export enum DriverSelectFilterButtonEnum {
  All = 'All',
  Motorcyle = 'Motorcycle',
  Car = 'Car',
  SmallVan = 'Small Van',
  LargeVan = 'Large Van',
}
