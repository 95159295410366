import { Component, OnInit, Input } from '@angular/core';
import { Color } from '@swimlane/ngx-charts';

@Component({
  selector: 'app-gauge',
  templateUrl: './gauge.component.html',
  styleUrls: ['./gauge.component.scss']
})
export class GaugeComponent implements OnInit {
  @Input() data;

  colorScheme = {
    domain: ['#F1453D', '#27B7FC', '#02DE73']
  } as Color;

  placeHolder;
  view: [number, number] = [400, 200];
  constructor() { }

  onSelect($event) {
    this.placeHolder = $event;
    // console.log($event);
  }

  ngOnInit() {

  }

}
