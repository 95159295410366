<app-flex-container 
    [ngClass]="theme" 
    [theme]="theme" 
    [padding]="'5rem'" 
    [flexDirection]="'column'" 
    [flexWrap]="'wrap'"
    [justifyContent]="JustifyContent.Center"
    >
    <svg-icon class="banner-spacer" [applyClass]="true"
        [src]="'/assets/img/icons/' + IconTypes.UnderlineTitle + '.svg'"></svg-icon>
    <h2 class="main-text">{{text}}</h2>
    <svg-icon class="banner-spacer" [applyClass]="true"
        [src]="'/assets/img/icons/' + IconTypes.UnderlineTitle + '.svg'"></svg-icon>
    <ng-content></ng-content>
</app-flex-container>