import { Component, Input, HostListener, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { IconTypes } from '../icon/icon.interfaces';
import { UiColors } from '../../interfaces/ui.interfaces';
import { Store } from '@ngrx/store';
import * as fromAuth from 'app/auth/auth.reducer';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-navigation-admin',
  templateUrl: './navigation-admin.component.html',
  styleUrls: ['./navigation-admin.component.scss'],
})
export class NavigationAdminComponent {
  actingAs;
  url: string;
  @Input() active: boolean;
  showEditConsignment;

  icons = {
    types: IconTypes,
    colors: UiColors,
  };
  isNavOpen = false;
  displayedOptions = [];

  editConsignmentBusinesses = [
    'business-d8695050-fe97-4c7a-a5d0-fe59a7f531ac',
    'business-01f12694-44f5-4068-b0b6-6b721ed0e306',
    'business-d2e7041a-8b02-4ff8-a888-7a6ff824c07c',
    'business-94bd2b8b-870c-4fd2-be9b-911325239b44',
    'business-6f1fd375-0659-4aea-b933-86e8276f464d',
    'business-4a120d71-3e8f-4d8c-b4ae-9f8747e5333b',
    'business-177a3104-5aea-4594-b772-5a4f1d8492db',
  ];

  constructor(
    public store: Store<fromAuth.State>,
    private authService: AuthService,
    public router: Router,
    private element: ElementRef
  ) {
    this.store.select(fromAuth.selectorActingAs).subscribe((next) => {
      this.displayedOptions = [];
      this.actingAs = next;
      this.showEditConsignment = this.editConsignmentBusinesses.includes(this.actingAs.id);
    });
  }

  onLogout(): void {
    this.authService.logout();
  }

  selectLink(): void {
    this.isNavOpen = false;
  }

  toggleNav(): void {
    this.isNavOpen = !this.isNavOpen;
  }

  handleNav(route: string): void {
    this.router.navigate([route]);
  }

  showNav(module: string): boolean {
    const one = environment.appVersion === 1;
    switch (module) {
      case 'freights':
        return one;
      case 'planning':
        return true && !environment.production;
      case 'admin-courier-orders':
        return true;
      default:
        return true;
    }
  }

  @HostListener('document:click', ['$event'])
  closeNav(event: Event): void {
    if (this.isNavOpen && !this.element.nativeElement.contains(event.target)) {
      this.isNavOpen = false;
    }
  }
}
