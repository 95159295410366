<div class="image-with-caption" [ngClass]="bgColor" [ngStyle]="{'width.rem': widthRem, 'height.rem': heightRem, 'padding.rem': paddingRem}">
  <div class="image" *ngIf="src">
    <a class="link" *ngIf="download" [href]="src" target="_blank" title="Download Image">
      <span class="hover">
        <app-icon
            class="download-icon"
            [icon]="IconTypes.Delivery"
            [color]="UiColors.Grey1"
            [size]="1.5"
        ></app-icon>
      </span>
      <img [src]="src" />
    </a>
    <img *ngIf="!download" [src]="src" />
  </div>
  <div *ngIf="showCaption" class="caption">
    <ng-content></ng-content>
  </div>
</div>