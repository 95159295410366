<app-modal [open]="true" [actions]="actions" [highlightPrimaryAction]="true" [size]="ModalSizes.Large"
  (handleAction)="handleAction($event)">
  <div class="modal-content">
    <heading [icon]="iconTypes.Parcel" [color]="uiColors.Blue">
      <h4 class="heading-title">Select Parcels</h4>
    </heading>
    <div class="modal-body">
      <div class="actions-wrap" *ngIf="assignable_parcels?.length > 10">
        <div class="search-bar">
          <label for="search">
            <svg-icon src="../../../assets/img/icons/search.svg" class="icon"></svg-icon>
          </label>
          <input type="text" 
            placeholder="Filter..." 
            (ngModelChange)="searchString = $event" [ngModel]="searchString"
             />
        </div>
      </div>
      <app-table [columns]="parcelTableColumns" [dataMap]="parcelTableDataMap" [rawData]="Parcels"
        [rowsSelectable]="true"
        [bulkActions]="parcelBulkActions" (actionTaken)="handleTableActions($event)"
        [zeroDataMessage]="assign ? 'No Parcels Assignable' : 'No Parcels Transferable'">
      </app-table>
    </div>
  </div>
</app-modal>