import { Component, Input, OnInit } from '@angular/core';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import axios from 'app/api/axios';
import { RatingEnum } from './ratingEnum';
import { ToastService } from 'app/shared/toast/toast.service';
import { NotificationTypeEnum } from 'app/shared/shared.interfaces';

@Component({
  selector: 'app-driver-rating',
  templateUrl: './driver-rating.component.html',
  styleUrls: ['./driver-rating.component.scss'],
})
export class DriverRatingComponent implements OnInit {
  public IconTypes = IconTypes;
  public UiColors = UiColors;
  public UiThemes = UiThemes;
  constructor( public toastService: ToastService ) {}

  @Input() driverName;
  @Input() businessId;
  @Input() businessReference;

  reviewText;
  rating;
  currentRatingState = RatingEnum;
  success = false;

  rateDriver(): void {
    this.toastService.publish({
      message: 'Rating Saved',
      type: NotificationTypeEnum.Success,
    });
    axios({
      method: 'POST',
      headers: { 'api-key': this.businessId },
      data: {
        business_reference: this.businessReference,
        rating: this.rating,
        comments: this.reviewText,
      },
      url: '/integration/rating/rate-driver',
    }).then(() => {
      this.success = true;
      this.toastService.publish({
        message: 'Rating Saved',
        type: NotificationTypeEnum.Success,
      });
    });
  }

  ngOnInit(): void {

  }
}
