import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timePipe'
})
export class TimePipe implements PipeTransform {
  transform(value: string) {
    return value.slice(0, -3);
  }
}
