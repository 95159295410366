import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import axios from 'app/api/axios';
import { AxiosResponse } from 'axios';
import { NotificationsService } from '../notifications/notifications.service';
import { LastMile, NotificationTypeEnum } from '../shared.interfaces';
import { Driver, GetWarehouseDetailsResponse, WarehouseDetails } from './assign-driver-modal.interface';
import firebase from 'firebase/app';

@Injectable({
  providedIn: 'root',
})
export class AssignDriverService {
  lastMileCollectionName = 'last-mile';
  constructor(private notificationService: NotificationsService, private firestore: AngularFirestore) {}

  async getWarehouse(warehouseId: string): Promise<void | WarehouseDetails> {
    return axios({
      method: 'GET',
      url: `warehouse/${warehouseId}/details`,
    })
      .then((response: AxiosResponse<GetWarehouseDetailsResponse>) => {
        return response.data.warehouse_details;
      })
      .catch(() => {
        this.notificationService.publish({
          message: 'Failed to fetch warehouse for this order',
          type: NotificationTypeEnum.Error,
        });
      });
  }

  async searchDrivers(
    value: string,
    warehouseId: string,
    businessId: string,
    isAdmin: boolean,
    useWarehouse: boolean
  ): Promise<void | any> {
    return axios({
      method: 'POST',
      url: '/generic/cqrs/query-drivers-by-name',
      data: {
        business_id: businessId,
        warehouse_id: useWarehouse ? warehouseId : null,
        name: value,
        return_all: useWarehouse ? false : isAdmin,
      },
    })
      .then((response) => {
        return response.data.output;
      })
      .catch(() => {
        this.notificationService.publish({ message: 'Failed to search drivers', type: NotificationTypeEnum.Error });
      });
  }

  getInTransitDrivers(warehouseId: string): Promise<firebase.firestore.QuerySnapshot<LastMile>> {
    const ref = this.firestore.collection<LastMile>(this.lastMileCollectionName).ref;
    const query = ref.where('Parcels', '!=', {}).where('WarehouseIds', 'array-contains', warehouseId);
    return query.get();
  }

  getWarehouseDrivers(warehouse_id: string): Promise<void | Driver[]> {
    return axios({
      method: 'POST',
      url: '/generic/cqrs/get-warehouse-drivers',
      data: {
        warehouse_id,
      },
    }).then((response: AxiosResponse<Driver[]>) => {
      return response?.data;
    });
  }
}
