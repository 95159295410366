<div class="button-wrap">
  <div [class.bottom]="orientation === 'bottom'" class="tooltip-content" *ngIf="open">
    <ng-content></ng-content>
  </div>
  <button class="tooltip-button button"
          [ngClass]="{
            'button-highlight': type === 'highlight',
            'button-primary': type === 'primary',
            'button-secondary': type === 'secondary',
            'button-tertiary': type === 'tertiary',
            'button-warning': type === 'warning',
            'button-error': type === 'error',
            'active': active
          }"
          [disabled]="disabled"
          (click)="open = !open">
    {{title}}
  </button>
</div>
