export interface StagedOrders {
  awaiting_dispatch: number;
  en_route: number;
  failed: number;
  last_update: any;
  orders: StagedOrder[];
  received: number;
  staged: number;
  total_orders: number;
  warehouse_id: string;
  warehouse_name: string;
}

export interface StagedOrder {
  arrived_date: any;
  bucket_order_id: number;
  business_id: string;
  business_reference: string;
  customer_address: string;
  customer_email: string;
  customer_name: string;
  customer_phone: string;
  delivery_date_end: any;
  delivery_date_start: any;
  delivery_window_end: any;
  delivery_window_start: any;
  dispatched_date: any;
  failure_reason: string;
  from_bucket: any;
  from_warehouse_id: any;
  from_warehouse_name: any;
  is_arrived: boolean;
  is_dispatched: boolean;
  is_middle_mile: boolean;
  leg_index: number;
  order_date_created: string;
  order_id: number;
  order_last_updated: any;
  parcel_count: number;
  previous_failure_reason: string;
  staged_order_source: string | null;
  status: string;
  to_warehouse_id: string;
  to_warehouse_name: string;
  waybill_number: string;
}

export enum StagedOrdersActionEnum {
  ReceiveOrders = 'receiveOrders',
  EditOrder = 'editOrder',
  DeleteOrders = 'deleteOrders',
}

export enum StagedOrderSourceEnum {
  FinalCollectionAttemptFailed = 'FinalCollectionAttemptFailed',
  Returned = 'Returned',
  New = 'New',
}
