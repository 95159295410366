// Functions for formatting Data to the Table Map.
// (used by the format? attribute in the TableDataMap interface.)
import { LastEventConstant } from 'app/dashboard/new-trips/new-trips.enums';
import { CourierStatuses, HealthStates } from 'app/dashboard/trips.enums';
import { TableDataMapFormatter, TableDataSetHighlightColor } from './table.interfaces';
import { Order, Reservation } from '../shared.interfaces';
import { OrderTimelineFirestoreEventData } from 'app/dashboard/new-trips/new-trips.interfaces';

// Divides the first element of an array by the second.
export const mfDivXY: TableDataMapFormatter = (map: [string | number, string | number], item: any) => {
  return Number(item[map[0]]) / Number(item[map[1]]);
};

// Displays first 2 values provided with a ' / ' between them ie 'x / y'.
export const mfXOverY: TableDataMapFormatter = (map: [string | number, string | number], item: any) => {
  return item[map[0]] + ' / ' + item[map[1]];
};

export const mfDivXYPerc: TableDataMapFormatter = (map: [string | number, string | number], item: any) => {
  return (Number(item[map[0]]) / Number(item[map[1]])) * 100;
};

export const mfGetArrayLength: TableDataMapFormatter = (map: [string], item: any) => {
  return item[map[0]].length;
};

export const mfGetNestedObjectValue: TableDataMapFormatter = (map: [string, string], item: any) => {
  if (item[map[0]]) {
    const getObject = item[map[0]];
    if (getObject[map[1]]) {
      return getObject[map[1]];
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const mfTruncateString: TableDataMapFormatter = (map: [string], item: any) => {
  if (item[map[0]]) {
    if (item[map[0]].length < 60) {
      return item[map[0]];
    } else {
      return item[map[0]].slice(0, 60) + '...';
    }
  } else {
    return null;
  }
};

export const mfGetNestedObjectValueNumber: TableDataMapFormatter = (map: [string, string], item: any) => {
  if (item[map[0]]) {
    const getObject = item[map[0]];
    if (getObject[map[1]]) {
      return getObject[map[1]];
    } else {
      return 0;
    }
  } else {
    return null;
  }
};

export const mfCountInArray: TableDataMapFormatter = (map: any, item: any) => {
  if (item[map]) {
    return item[map].length;
  }
};

export const mfCountInNestedArray: TableDataMapFormatter = (map: any, item: any) => {
  let sum = 0;
  item[map[0]].forEach((route) => {
    sum = sum + route.waypoints.length;
  });
  return sum;
};

// returns a comma separated list of a property in an array. map[0] is the array, map[1] is the property.
// example: string of driver names in an array of drivers.
export const mfGetNestedArrayPropertyCommaSeparated: TableDataMapFormatter = (map: [string, string], item: any) => {
  const resultsArray = [];
  if (item[map[0]]) {
    item[map[0]].forEach((element) => {
      resultsArray.push(element[map[1]]);
    });
    return resultsArray.join(', ');
  }
};

export const mfGetArrayPropertyCommaSeparated: TableDataMapFormatter = (map: [string], item: any) => {
  const resultsArray = [];
  if (item[map[0]]?.length) {
    item[map[0]].forEach((element) => {
      resultsArray.push(element);
    });
    return resultsArray.join(', ');
  } else {
    return null;
  }
};

export const mfGetDriverNamesCommaSeparated: TableDataMapFormatter = (map: string[], item: any) => {
  // DriverNames, FleetAllocation, CourierName
  if (item[map[1]] === 'None') {
    return item[map[2]];
  } else {
    const resultsArray = [];
    if (item[map[0]]?.length) {
      item[map[0]].forEach((element) => {
        resultsArray.push(element);
      });
      return resultsArray.join(', ');
    } else {
      return null;
    }
  }
};

export const mfHandleNoneFleetActual: TableDataMapFormatter = (map: string[], item: any) => {
  if (item[map[0]] === 'None') {
    return 'None';
  } else {
    return item[map[0]];
  }
};

export const mfHandleNoneFleetPlanned: TableDataMapFormatter = (map: string[], item: any) => {
  if (item[map[0]] === 'None') {
    return 'Courier';
  } else {
    return item[map[0]];
  }
};

export const mfHandleNoneDriverReservation: TableDataMapFormatter = (map: string[], item: Order) => {
  if (!item[map[0]]) {
    return 'Unassigned';
  }
  if(item.Reservation){
    return item.Reservation.Username
  }
};

export const mfHandleNoneFleet: TableDataMapFormatter = (map: string[], item: any) => {
  if (item[map[0]] === 'None') {
    return 'Courier';
  } else {
    return item[map[0]];
  }
};

export const mfGetNestedObjectValuesByKeyArray: TableDataMapFormatter = (map: [string, string], item: any) => {
  const getNestedObject = (nestedObj, pathArr) => {
    return pathArr.reduce((obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : null), nestedObj);
  };
  // pass in your object structure as array elements
  // to access nested array, just pass in array index as an element the path array.
  // eg1 const name = getNestedObject(user, ['personalInfo', 'name']);
  // eg2 const city = getNestedObject(user, ['personalInfo', 'addresses', 0, 'city']);
  return getNestedObject(item, map);
};

export const mfFindNestedValueByProperty: TableDataMapFormatter = (map: [string], item: any) => {
  const property = map[0];
  const object = item;

  function findProp(obj, key, out) {
    let i;
    const proto = Object.prototype;
    const ts = proto.toString;
    const hasOwn = proto.hasOwnProperty.bind(obj);

    if ('[object Array]' !== ts.call(out)) {
      out = [];
    }

    for (i in obj) {
      if (hasOwn(i)) {
        if (i === key) {
          out.push(obj[i]);
        } else if ('[object Array]' === ts.call(obj[i]) || '[object Object]' === ts.call(obj[i])) {
          findProp(obj[i], key, out);
        }
      }
    }
    return out;
  }
  const value: string = findProp(object, property, null);
  return value[0];
};

export const mfPercentageProgress: TableDataMapFormatter = (map: any, item: any) => {
  return item[map] * 100 + ' %';
};

export const mfLastEventFormatter: TableDataMapFormatter = (map: any, item: any) => {
  return LastEventConstant[item[map]].label;
};

export const mfRoundTripFormatter: TableDataMapFormatter = (map: any, item: any) => {
  if (item[map] === true) {
    return 'Round Trip';
  } else if (item[map] === false) {
    return 'One Way';
  } else {
    return '-';
  }
};

export const mfLastEventHighlighter: TableDataSetHighlightColor = (map: string[], item: any) => {
  switch (item[map[0]]) {
    case 'Alert':
      return 'highlight-bad';
    case 'good':
      return 'highlight-good';
    case 'warning':
      return 'highlight-warning';
    case 'alert':
      return 'highlight-bad';
    case 'none':
      return 'highlight-primary';
  }
};

export const mfNestedLastEventHighlighter: TableDataSetHighlightColor = (map: string[], item: any) => {
  switch (item[map[0]][map[1]]) {
    case 'Alert':
      return 'highlight-bad';
    case 'good':
      return 'highlight-good';
    case 'warning':
      return 'highlight-warning';
    case 'alert':
      return 'highlight-bad';
    case 'none':
      return 'highlight-primary';
  }
};

export const mfRemapFreightStatus: TableDataMapFormatter = (map: any, item: any) => {
  switch (item[map]) {
    case 'bidding':
      return 'Matching Transporters';
    case 'trips_pending':
      return 'Transporter Assigned';
  }
};

export const mfCourierStatusStrings: TableDataMapFormatter = (map: any, item: any) => {
  switch (item[map]) {
    case CourierStatuses.WaybillCreated:
      return 'Waybill Created';
    case CourierStatuses.OrderCreated:
      return 'Order Created';
    case CourierStatuses.WaybillCreationFailed:
      return 'Waybill Not Created';
    case CourierStatuses.WaybillAccepted:
      return 'Waybill Accepted';
    case CourierStatuses.WaybillAcceptedFailed:
      return 'Waybill Not Accepted';
    case CourierStatuses.OrderCollected:
      return 'Order Collected';
    case CourierStatuses.OrderDelivered:
      return 'Order Delivered';
    case CourierStatuses.OrderCompleted:
      return 'Order Completed';
    case CourierStatuses.OrderCancelled:
      return 'Order Cancelled';
    case CourierStatuses.DeliveryFailed:
      return 'Delivery Failed';
    case CourierStatuses.Archived:
      return 'Archived';
    case CourierStatuses.OrderOutForDelivery:
      return 'Out for Delivery';
  }
};

export const mfSetRatioHighlightColor: TableDataSetHighlightColor = (_map: any, item: any) => {
  if (item.route_type === 'WaypointRoute') {
    if (item.driver_earnings_ratio <= 1.05 && item.driver_earnings_ratio >= 0.95) {
      return 'highlight-' + HealthStates.Good;
    }
    if (item.driver_earnings_ratio <= 1.1 && item.driver_earnings_ratio >= 0.9) {
      return 'highlight-' + HealthStates.Warning;
    } else {
      return 'highlight-' + HealthStates.Bad;
    }
  } else {
    if (item.total_collections > 0) {
      return 'highlight-' + HealthStates.Default;
    } else {
      return 'highlight-' + HealthStates.Primary;
    }
  }
};

export const getDeeplyNestedCustomerName: TableDataMapFormatter = (map: string[], item: any) => {
  if (item[map[0]]) {
    const ParcelArray = item[map[0]];
    return ParcelArray[0]?.Destination?.Contact?.Name;
  } else {
    return '-';
  }
};

export const mfHumanizeString: TableDataMapFormatter = (map: any, item: any) => {
  if (typeof item[map] !== 'string') {
    return item[map];
  }
  let value = item[map].split(/(?=[A-Z])/).join(' ');
  value = value[0].toUpperCase() + value.slice(1);
  return value;
};

export const mfAmountPaid: TableDataMapFormatter = (map: any, item: any) => {
  if (item[map]) {
    if (item[map] === 0) {
      return 'No';
    } else {
      return 'Yes';
    }
  } else {
    return null;
  }
};

export const mfHumanizeEventString: TableDataMapFormatter = (map: any, item: any) => {
  if (typeof item[map] !== 'string') {
    return item[map];
  }

  let string = item[map];
  function isUpperCase(str) {
    return str === str.toUpperCase();
  }
  if (isUpperCase(item[map])) {
    string = string.toLowerCase();
  }

  let value = string.split(/(?=[A-Z])/);
  if (value.length === 4) {
    value.pop();
    value = value.join(' ');
  } else {
    value[0] = value[0].replaceAll('_', ' ');

    value = value.join(' ');
  }
  value = value[0].toUpperCase() + value.slice(1);
  return value;
};

export const mfHumanizeNestedEventString: TableDataMapFormatter = (map: any, item: any) => {
  const prop: string = item[map[0]][map[1]];
  if (typeof prop !== 'string') {
    return prop;
  }

  let string = prop;
  function isUpperCase(str) {
    return str === str.toUpperCase();
  }
  if (isUpperCase(prop)) {
    string = string.toLowerCase();
  }

  let value: any[] | string = string.split(/(?=[A-Z])/);
  if (value.length === 4) {
    value.pop();
    value = value.join(' ');
  } else {
    value[0] = value[0].replaceAll('_', ' ');

    value = value.join(' ');
  }
  value = value[0].toUpperCase() + value.slice(1);
  return value;
};

export const mfGetNestedDriverType: TableDataMapFormatter = (map: any, item: any) => {
  switch (item['driver'][map]) {
    case 0:
      return 'Picup';
      break;
    case 1:
      return 'Contract';
      break;
    default:
      return 'Picup';
  }
};

export const mfGetDriverType: TableDataMapFormatter = (map: any, item: any) => {
  switch (item[map]) {
    case 0:
      return 'Picup';
      break;
    case 1:
      return 'Contract';
      break;
    default:
      return 'Picup';
  }
};

export const mfGetNestedObjectAndSumValues: TableDataMapFormatter = (map: [string, string, string], item: any) => {
  const getObject = item[map[0]];
  return getObject[map[1]] + getObject[map[2]];
};

export const mfWaypointCompleteStatus: TableDataMapFormatter = (map: [string, string, string], item: any) => {
  // TODO: REMOVE TEST

  const test = map;
  if (item.waypoint_status) {
    const complete = item.waypoint_status.completed_waypoints;
    const total = item.waypoint_status.total_waypoints;
    return complete + ' / ' + total;
  } else {
    return null;
  }
};

export const mfWaypointCompleteProgress: TableDataMapFormatter = (map: [string], item: any) => {
  const getObject = item[map[0]];
  if (getObject.completed_waypoints > 0) {
    const complete = getObject.completed_waypoints;
    const total = getObject.total_waypoints;
    return ((complete / total) * 100).toFixed(0) + '%';
  } else {
    return 0 + '%';
  }
};

export const mfWaypointTotal: TableDataMapFormatter = (map: [string, string, string], item: any) => {
  // TODO: REMOVE TEST
  const test = map;
  if (item.waypoint_status) {
    return item.waypoint_status.total_waypoints;
  } else {
    return null;
  }
};

export const firebaseDateObject: TableDataMapFormatter = (_map: [string], item: OrderTimelineFirestoreEventData) => {
  return item.Timestamp?.toMillis().toString();
};

export const firebaseNotesDateObject: TableDataMapFormatter = (_map: [string], item: any) => {
  return item.timestamp?.toMillis().toString();
};

export const firestoreDateObject: TableDataMapFormatter = (map: [string], item: any) => {
  if (item[map[0]]) {
    return item[map[0]].toMillis();
  }
};

export const htmlContent: TableDataMapFormatter = (map: [string], item: any) => {
  const span = document.createElement('span');
  span.innerHTML = item[map[0]];
  return span.textContent || span.innerText;
};

export const lotteryRestartCountFormatter: TableDataMapFormatter = (map: string[], item: any) => {
  return item[map[1]] || item[map[1]] >= 0 ? item[map[1]] : item[map[0]];
};
