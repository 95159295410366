import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { StoreAction } from '../../interfaces/auth.interfaces';
import { ManageBucketService } from './manage-bucket.service';
import { Router } from '@angular/router';
import { SET_ACTIVE_BUCKET_ID } from './manage-bucket.actions';

@Injectable({
  providedIn: 'root',
})
export class ManageBucketEffects {
  constructor(
    private actions$: Actions<StoreAction>,
    public manageBucketService: ManageBucketService,
    public router: Router
  ) {}

  // @Effect({ dispatch: false })

  setActiveBucket = createEffect(() => {
    return this.actions$.pipe(
      ofType(SET_ACTIVE_BUCKET_ID),
      map((action: any) => {
        this.router.navigate(['dashboard/buckets/', { outlets: { Buckets: [action.payload] } }]);
        this.manageBucketService.getBucketState();
      }))
  }, { dispatch: false});

  // setActiveBucket(): Observable<StoreAction> {
  //   return this.actions$.pipe(
  //     ofType(SET_ACTIVE_BUCKET_ID),
  //     map((action: any) => action.payload),
  //     tap((payload) => {
  //       this.router.navigate(['dashboard/buckets/', { outlets: { Buckets: [payload] } }]);
  //       this.manageBucketService.getBucketState();
  //     })
  //   );
  // }
}
