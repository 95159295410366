import { Warehouse } from 'app/interfaces/auth.interfaces';
import { Business } from 'app/interfaces/businesses.interfaces';

export enum AdminPostDispatchTabEnum {
  Tracking = 'tracking',
  Dispatch = 'dispatch',
  ActiveRoutes = 'Active Routes',
  CurrentTrips = 'Current Trips',
  PastFutureTrips = 'Past And Future Trips',
  PendingRoutes = 'Pending Routes',
}

export enum AdminPostDispatchUrlEnum {
  Tracking = 'tracking',
  Dispatch = 'dispatch',
  ActiveRoutes = 'active-routes',
  CurrentTrips = 'current-trips',
  PastFutureTrips = 'past-future-trips',
  PendingRoutes = 'pending-routes',
}

export interface PostDispatchPayload {
  mode: string;
  warehouse: Warehouse;
  fleet_allocation: string;
  limit: number;
  business: Business;
  scheduledMode: ScheduledDropDownEnum;
}

export enum ScheduledDropDownEnum{
  All = 'All',
  OnDemand = 'On-Demand',
  Scheduled = 'Scheduled'
}
