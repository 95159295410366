import { Injectable } from '@angular/core';
import axios from 'app/api/axios';
import { selectorActingAs, selectorUser } from '../../auth/auth.reducer';
import { NotificationsService } from '../../shared/notifications/notifications.service';
import { SimpleModalService } from 'ngx-simple-modal';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { CsvErrorsComponent } from './csv-errors/csv-errors.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActingAs } from 'app/interfaces/auth.interfaces';

@Injectable({
  providedIn: 'root',
})
export class UploadOrdersService {
  user: any;
  authState: any;
  actingAs: ActingAs;
  upload_response: any;
  token: string;
  constructor(
    public store: Store<any>,
    private notificationsService: NotificationsService,
    private simpleModalService: SimpleModalService,
    public router: Router,
    public afAuth: AngularFireAuth
  ) {
    this.store.select(selectorActingAs).subscribe((next) => {
      this.actingAs = next;
    });
    this.store.select(selectorUser).subscribe((next) => (this.user = next));
  }

  createConsignment() {
    this.router.navigate(['dashboard/upload-orders', { outlets: { UploadOrders: ['create-consignment'] } }]);
  }

  editConsignment() {
    this.router.navigate(['dashboard/upload-orders', { outlets: { UploadOrders: ['edit-consignment'] } }]);
  }

  goToGenerateRandomBucket() {
    this.router.navigate(['dashboard/upload-orders', { outlets: { UploadOrders: ['generate-random-bucket'] } }]);
  }

  generateRandomBucket(details) {
    return axios({
      method: 'POST',
      url: '/import/' + this.actingAs.id + '/create-random',
      data: details,
    })
      .then((response) => {
        this.router.navigate(['dashboard/buckets', { outlets: { Buckets: [response.data.bucket_details.bucket_id] } }]);
        this.notificationsService.publish({
          type: 'success',
          message: 'Bucket Ready for Import',
        });
        return;
      })
      .catch((error) => {
        if (error.response?.data.errors === undefined) {
          this.notificationsService.publish({
            type: 'error',
            message: error.response?.data.message,
          });
        } else {
          const errors = { errors: error.response?.data.errors };
          this.throwErrorModal(errors);
        }
        throw error;
      });
  }
  validateCSV(processDetails) {
    return axios({
      method: 'POST',
      url: '/import/' + this.actingAs.id + '/validate-file',
      data: processDetails,
    }).then((response) => {
      return response?.data;
    });
  }

  uploadCSV(file) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const xlsUrl = '/import/' + this.actingAs.id + '/upload-xlsx/';
    const csvUrl = '/import/' + this.actingAs.id + '/upload-csv/';
    let url;

    function getExtension() {
      return file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length) || file.name;
    }
    if (file.type === 'text/csv') {
      url = csvUrl;
    } else {
      const extension = getExtension();
      if (extension === 'xlsx' || extension === 'xls' || extension === 'xlsm' || extension === 'xlsb') {
        url = xlsUrl;
      } else {
        url = csvUrl;
      }
    }
    return axios({
      method: 'POST',
      url: url,
      data: formData,
    })
      .then((response) => {
        this.upload_response = response?.data;
        this.router.navigate(['dashboard/upload-orders', { outlets: { UploadOrders: ['upload-details'] } }]);
      })
      .catch((error) => {
        if (error.response?.data.errors === undefined) {
          this.notificationsService.publish({
            type: 'error',
            message: error.response?.data.message,
          });
        } else {
          const errors = { errors: error.response?.data.errors };
          this.throwErrorModal(errors);
        }
        throw error;
      });
  }

  throwErrorModal(errors) {
    this.simpleModalService.addModal(CsvErrorsComponent, errors);
  }

  uploadConsignmentData(file) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const url = '/consignment/' + this.actingAs.id + '/upload-waltons-ftp-orders/';
    return axios({
      method: 'POST',
      url: url,
      data: formData,
    })
      .then((response) => {
        this.notificationsService.publish({
          type: 'success',
          message: 'Invoices added Successfully',
        });
        return response;
      })
      .catch((error) => {
        this.alertError(error);
        throw error;
      });
  }

  processCSV(processDetails) {
    return axios({
      method: 'POST',
      url: '/import/' + this.actingAs.id + '/process-file/',
      data: processDetails,
    })
      .then((response) => {
        this.notificationsService.publish({
          type: 'success',
          message: 'Buckets Successfully Created',
        });
        this.router.navigate(['/dashboard/buckets']);
        return response;
      })
      .catch((error) => {
        this.alertError(error);
        throw error;
      });
  }

  setSingleConsignment(data) {
    return axios({
      method: 'POST',
      responseType: 'arraybuffer',
      url: '/consignment/' + this.actingAs.id + '/set-single-consignment/',
      data: data,
    })
      .then((response) => {
        this.notificationsService.publish({
          type: 'success',
          message: 'Consignment Added/Modified Successfully',
        });
        return response;
      })
      .catch((error) => {
        this.notificationsService.publish({
          type: 'error',
          message: error.response?.data.message,
        });
        throw error;
      });
  }

  removeOrderFromConsignment(orderId) {
    return axios({
      method: 'POST',
      url: '/consignment/' + this.actingAs.id + '/remove-order-from-consignment/' + orderId,
    })
      .then(() => {
        this.notificationsService.publish({
          type: 'success',
          message: 'Order Removed From Consignment',
        });
        return;
      })
      .catch((error) => {
        this.notificationsService.publish({
          type: 'error',
          message: error.response?.data.message,
        });
        throw error;
      });
  }

  alertError(error) {
    this.notificationsService.publish({
      type: 'error',
      message: error.response?.data.message,
    });
  }
}
