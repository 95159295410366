import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { ModalSizes, ModalAction } from 'app/shared/modals/modal.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';

@Component({
  selector: 'app-new-zone-name',
  templateUrl: './new-zone-name.component.html',
  styleUrls: ['./new-zone-name.component.scss']
})
export class NewZoneNameComponent extends SimpleModalComponent<any, any> implements OnInit {

  public ModalSizes = ModalSizes;
  public IconTypes = IconTypes;
  public UiColors = UiColors;
  public UiThemes = UiThemes;
  public ButtonTypes = ButtonTypes;
  public actions: ModalAction[] = [
    { name: 'cancel', title: 'Cancel' },
    { name: 'confirm', title: 'Confirm', isPrimary: true },
  ];

  zone_name;

  constructor() {
    super();
  }

  handleAction($event) {
    if ($event === 'cancel') {
      this.close();
    }
    if ($event === 'confirm') {
      this.result = this.zone_name;
      this.close();
    }
  }

  ngOnInit() {
  }

}
