import { Component, OnInit, Input } from '@angular/core';
import { MapService } from 'app/shared/map/map.service';
import { MapTypes } from 'app/shared/map-view/map-view.interfaces';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';

@Component({
  selector: 'app-new-trips-map',
  templateUrl: './new-trips-map.component.html',
  styleUrls: ['./new-trips-map.component.scss'],
})
export class NewTripsMapComponent implements OnInit {
  @Input() tripId: string;
  @Input() activeWaypointMarkers;
  @Input() historicWaypointMarkers;
  @Input() driverId;
  @Input() routes;
  @Input() markers;
  markerDetails;
  @Input() tripPolylines;
  mapTypes = MapTypes;
  uiColors = UiColors;
  iconTypes = IconTypes;
  buttonTypes = ButtonTypes;
  showMap = false
  constructor(private mapService: MapService) {}

  ngOnInit(): void {}

  handleMapClick(marker) {
    if (marker.clickEvent) {
      if (marker.clickEvent.feature.getProperty('flagged_reason')) {
        this.markerDetails = {
          discrepancy: marker.clickEvent.feature.getProperty('flagged_reason'),
          driver_name: marker.clickEvent.feature.getProperty('driver_name'),
          source_event: marker.clickEvent.feature.getProperty('source_event'),
          when: marker.clickEvent.feature.getProperty('when'),
        };
      }
    }
  }

  toggleMap(): void {
    this.showMap = !this.showMap
  }

  closeDetails() {
    this.markerDetails = null;
  }
}
