<div class="modal">
  <div class="modal-content">
    <heading [icon]="Icons.Types.Driver" [color]="Icons.Colors.Blue">
      <h4 class="heading-title">Search For Driver</h4>
    </heading>
    <div class="modal-body" *ngIf="drivers === undefined">
      <div class="form-wrap">
        <div class="row">
          <input-field [type]="'text'" (valueChanged)="driverName = $event" [value]="driverName" [label]="'Driver Name'"
            (keydown.enter)="searchDriver(driverName)"></input-field>
        </div>
      </div>
      <div class="modal-footer">
        <app-basic-button 
          [title]="'Cancel'" 
          [type]="buttonTypes.Tertiary" 
          (click)="close();">
        </app-basic-button>
        <app-basic-button 
          [title]="'Search'" 
          [type]="buttonTypes.Tertiary" 
          (click)="searchDriver(driverName)">
        </app-basic-button>
      </div>
    </div>

    <!-- Results: -->

    <!-- Table Results -->

    <div class="modal-body" *ngIf="drivers">
      <div class="form-wrap">
        <div class="row">
          <h4>Please select a driver to add:</h4>
        </div>
        <div class="driver-table">
          <app-table 
            [zeroDataMessage]="'No Drivers Found'" 
            [columns]="driverTableColumns" 
            [tableData]="shownDrivers"
            [rowsSelectable]="false" 
            [showSelectionSummaryBottom]="false" 
            [showSelectionSummaryTop]="false"
            [primaryActions]="tablePrimaryActions" 
            (actionTaken)="respondToTableActions($event)"
            [showViewMore]="showViewMore" 
            (moreDataRequested)="loadMoreTableData()">

          </app-table>
          <div class="modal-footer">
            <app-basic-button 
              [title]="'Cancel'" 
              [type]="buttonTypes.Tertiary" 
              (click)="close();">
            </app-basic-button>
          </div>
        </div>
      </div>
    </div>