<div class="modal">
  <div class="modal-content">
    <div class="splitter">
      <!-- TODO mapStyle type -->
      <app-map 
        [geoJson]="geoJson" 
        [mapType]="mapTypes.Crossdocks" 
        [mapHeight]="'100%'"> 
      </app-map>
    </div>

    <div class="splitter crossdock-container gradient-bg">
      <div class="block">
        <div class="row">
          <h4>Add a cross dock</h4>
          <div class="underline"></div>
        </div>
        <div class="row starting">
          <label
            >Starting Warehouse:
            <h4>{{ activeBucket.warehouse_name }}</h4>
          </label>
        </div>
        <ng-template ngFor let-address let-i="index" [ngForOf]="dropDownOptions">
          <div class="row">
            <app-dropdown
              class="dropdown"
              [label]="'Crossdock'"
              [activeItem]="crossdocks[i + 1] ? crossdocks[i + 1].warehouse_name : 'Select a Warehouse'"
              [dropdownTheme]="'light'"
              [labelTheme]="'light'"
            >
              <div dropdownRef>
                <ng-template ngFor let-warehouse [ngForOf]="selection">
                  <a (click)="addWarehouse(warehouse, i); getGeoJson()" class="dropdown-item">{{
                    warehouse.warehouse_name
                  }}</a>
                </ng-template>
              </div>
            </app-dropdown>
          </div>
        </ng-template>
        <div class="add-button">
          <app-basic-button [title]="'Add Crossdock'" [type]="buttonTypes.Tertiary" (click)="addOption()"></app-basic-button>
        </div>
      </div>
      <div class="block">
        <div class="controls">
          <app-basic-button [title]="'Cancel'" [type]="buttonTypes.Tertiary" (click)="cancel()"></app-basic-button>
          <app-basic-button
            [active]="true"
            [disabled]="crossdocks.length < 2"
            [title]="'Process'"
            [type]="buttonTypes.Highlight"
            (click)="process()"
          ></app-basic-button>
        </div>
      </div>
    </div>
  </div>
</div>
