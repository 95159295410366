import { Component, OnInit, Input } from '@angular/core';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { darkMapStyle } from 'app/shared/map/map.constants';

@Component({
  selector: 'app-alerts-map',
  templateUrl: './alerts-map.component.html',
  styleUrls: ['./alerts-map.component.scss']
})
export class AlertsMapComponent implements OnInit {
  @Input() alertsData;
  @Input() vehicles;

  public UiColors = UiColors;
  public IconTypes = IconTypes;

  public map: google.maps.LatLngLiteral = {
    lat: -29.9537074,
    lng: 23.6451965,
  };
  mapStyles = darkMapStyle;
  alertDetails;

  gMapOptions: google.maps.MapOptions = {
    center: this.map,
    zoomControl: false,
    mapTypeControl: true,
    maxZoom: 18,
    //  fitBounds: true,
    streetViewControl: false
  };

  constructor() { }

  ngOnInit() {
  }

  markerClick(alert) {
    this.alertDetails = alert
  }

  closeDetails() {
    this.alertDetails = null;
  }

}
