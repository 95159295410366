import {Component, Input, OnChanges} from '@angular/core';
import {ValueWithLabelProps} from './value-with-label.interfaces';
import {UiSizes, UiThemes} from '../../interfaces/ui.interfaces';

@Component({
  selector: 'app-value-with-label',
  templateUrl: './value-with-label.component.html',
  styleUrls: ['./value-with-label.component.scss'],
})
export class ValueWithLabelComponent implements ValueWithLabelProps, OnChanges {
  @Input() label: string;
  @Input() value: string;
  @Input() border: boolean;
  @Input() uppercaseLabel: boolean;
  @Input() uppercaseValue: boolean;
  @Input() theme = UiThemes.Dark;
  @Input() size: UiSizes = UiSizes.Small;
  @Input() padding = '1rem';

  public classes: string[];

  ngOnChanges() {
    this.classes = this.setClasses();
  }

  setClasses() {
    const _classes: string[] = [];
    if (this.theme) {
      _classes.push(this.theme);
    }

    if (this.size) {
      _classes.push(this.size);
    }

    if (this.border) {
      _classes.push('border');
    }

    return _classes;
  }
}
