import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'app/auth/auth.service';
import { ErrorService } from 'app/auth/error.service';
import { LoginInterface } from 'app/login/login.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-login-main',
  templateUrl: './login-main.component.html',
  styleUrls: ['./login-main.component.scss']
})
export class LoginMainComponent implements OnDestroy {

  @Output() loginData: EventEmitter<LoginInterface> = new EventEmitter<LoginInterface>();
  @Output() resendOtp: EventEmitter<LoginInterface> = new EventEmitter<LoginInterface>();
  @Output() forgotPassword: EventEmitter<void> = new EventEmitter<void>();
  @Output() sendResetPasswordEmail: EventEmitter<string> = new EventEmitter<string>();

  loginFormGroup: FormGroup = new FormGroup({
    emailControl: new FormControl('',[Validators.email, Validators.required]),
    passwordControl: new FormControl('',Validators.required),
    otpControl: new FormControl('')
  });

  hidePassword = true;
  optRequired = false;
  unsubscribe$ = new Subject<void>()

  constructor(public authService: AuthService, public errorService: ErrorService ){

    this.errorService.isExpiredPassword$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      if(value){
        this.sendResetPasswordEmail.emit(this.loginFormGroup.controls.emailControl.value);
      }
    });

    this.errorService.invalidOtp$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      if(value){
        this.authService.loginStatus = false;
      }
    });

    this.authService.otpRequired$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      this.optRequired = value;
    });

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.authService.oneTimePinRequired = false;
  }

  login(): void{
    if(this.optRequired && this.loginFormGroup.controls.otpControl.value.length === 0){
      return;
    }
    if(this.loginFormGroup.valid){
      this.loginData.emit({
        email: this.loginFormGroup.controls.emailControl.value,
        password: this.loginFormGroup.controls.passwordControl.value,
        otp: this.loginFormGroup.controls.otpControl.value
      });
    } else {
      this.loginFormGroup.markAsTouched();
    }
  }

  emitForgotPassword(): void {
    this.forgotPassword.emit();
  }

  emitResendOTP(): void{
    if(this.loginFormGroup.valid){
      this.resendOtp.emit({
        email: this.loginFormGroup.controls.emailControl.value,
        password: this.loginFormGroup.controls.passwordControl.value
      });
    }
  }
}
