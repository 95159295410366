<app-tracking-inprogress-trips 
    #inprogress
    [postDispatchTablePrimaryActions]="postDispatchTablePrimaryActions">
</app-tracking-inprogress-trips>
<app-tracking-active-routes 
    #active
    [activeDriverPrimaryActions]="activeDriverPrimaryActions">
</app-tracking-active-routes>
<app-tracking-pending 
    #pending
    [activeDriverPrimaryActions]="activeDriverPrimaryActions">
</app-tracking-pending>
<app-tracking-completed 
    #completed 
    [postDispatchTablePrimaryActions]="postDispatchTablePrimaryActions" >
</app-tracking-completed>