<mat-form-field *ngIf="!(operationsTrackingService.isAdmin$ | async) && warehouses && warehouses?.length > 0 && (operationsTrackingService.teams$ | async).length > 0" appearance="standard">
  <mat-label>Team</mat-label>
  <mat-select [formControl]="teamsControl" multiple>
    <mat-option *ngFor="let team of (operationsTrackingService.teams$ | async); trackBy: trackByTeamName" [value]="team.teamName">
      {{team.teamName}}
    </mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field *ngIf="warehouses && warehouses?.length > 0" appearance="standard">
  <mat-label>Warehouses</mat-label>
  <mat-select
    [formControl]="warehouseControl"
    multiple>
    <input-field
      (valueChanged)="filterWarehouse($event)"
      [type]="'text'"
      [placeholder]="'Search...'"
      [autoComplete]="'off'">
    </input-field>
    <mat-option *ngFor="let warehouse of filteredWarehouses; trackBy: trackByWarehouseId"
      [value]="warehouse.id">
      {{ warehouse.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field *ngIf="filteredBusiness?.length > 0" appearance="standard">
  <mat-label>Businesses</mat-label>
  <mat-select [formControl]="businessControl" multiple>
    <input-field
      (valueChanged)="filterBusinesses($event)"
      [type]="'text'"
      [placeholder]="'Search...'"
      [autoComplete]="'off'">
    </input-field>
    <mat-option *ngFor="let business of filteredBusiness; trackBy: trackByBusinessId" 
      [value]="business.business_id">
      {{ business.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field appearance="standard">
  <mat-label>Fleet</mat-label>
  <mat-select (selectionChange)="applyFleetControlFilter()" [formControl]="fleetControl">
    <mat-option *ngFor="let fleet of fleets" [value]="fleet === 'All' ? '' : fleet">{{ fleet }}</mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field appearance="standard">
  <mat-label>Last Event</mat-label>
  <mat-select (selectionChange)="applyLastEventFilter()" [formControl]="lastEventControl">
    <mat-option
      *ngFor="let lastEvent of lastEvents | keyvalue"
      [value]="lastEvent.key === 'All' ? '' : lastEvent.key"
      >{{ lastEvent.value ? lastEvent.value : 'All' }}</mat-option
    >
  </mat-select>
</mat-form-field>
<div class="header">
  <h3>{{ dataSource.filteredData.length }} {{ lastMileState }} Route{{ dataSource.data.length === 1 ? '' : 's' }}</h3>
</div>
<table mat-table matSort [dataSource]="dataSource" matSortActive="Timestamp" matSortDirection="desc" class="mat-elevation-z8" [trackBy]="trackBy">
  <ng-container matColumnDef="DriverName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Driver Name</th>
    <td mat-cell *matCellDef="let element">{{ element.DriverName }}</td>
  </ng-container>

  <ng-container matColumnDef="WarehouseName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Warehouse Name</th>
    <td mat-cell *matCellDef="let element">{{ element.WarehouseIds | warehouseName: warehouses}}</td>
  </ng-container>

  <ng-container matColumnDef="BusinessName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Business Name</th>
    <td mat-cell *matCellDef="let element">{{ element.BusinessNames}}</td>
  </ng-container>

  <ng-container matColumnDef="Role">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
    <td mat-cell *matCellDef="let element">{{ element.Role }}</td>
  </ng-container>

  <ng-container matColumnDef="Timestamp">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Updated</th>
    <td class="pd-l-5 {{ element.Timestamp?.toMillis() | timeAgoColour }}" mat-cell *matCellDef="let element">
      {{ element.Timestamp?.toMillis() | timeAgo }}
    </td>
  </ng-container>

  <ng-container matColumnDef="LastEventName">
    <th mat-header-cell *matHeaderCellDef>Last Event</th>
    <td class="pd-l-5" mat-cell *matCellDef="let element">{{ element.LastEventName | lastEvent }}</td>
  </ng-container>

  <ng-container matColumnDef="HasFailedCollection">
    <th mat-header-cell *matHeaderCellDef>Failed Collection</th>
    <td mat-cell *matCellDef="let element">{{ element.HasFailedCollection }}</td>
  </ng-container>

  <ng-container matColumnDef="HasFailedDelivery">
    <th mat-header-cell *matHeaderCellDef>Failed Delivery</th>
    <td mat-cell *matCellDef="let element">{{ element.HasFailedDelivery }}</td>
  </ng-container>

  <ng-container matColumnDef="Actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td class="actions" mat-cell *matCellDef="let element">
      <mat-icon matTooltip="View" (click)="handleTableAction(element.Id, ActionsEnum.ViewLastMile)">visibility</mat-icon>
      <mat-icon matTooltip="CopyId" (click)="handleTableAction(element.Id, ActionsEnum.CopyId)">content_copy</mat-icon>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<mat-paginator [pageSizeOptions]="[5, 10, 20, 50, 100]" [pageSize]="pageSize" showFirstLastButtons> </mat-paginator>
<div class="display-info" *ngIf="this.dataSource.data.length === 0">
  <h4>No {{ lastMileState }} Routes Currently</h4>
  <mat-spinner></mat-spinner>
  <p>We'll continue to monitor...</p>
</div>
