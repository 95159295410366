<app-quote-one-to-many *ngIf="phase === 'details'" 
  [picupDetails]="picupDetails">
</app-quote-one-to-many>

<app-select-method
  *ngIf="phase === 'quote'"
  (selectedMethod)="selectedMethod($event)"
  (clear)="clearForm()"
  (back)="back()"
  [quoteResult]="quoteResult?.data"
>
</app-select-method>
