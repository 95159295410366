import { Component, Input, OnInit } from '@angular/core';
import { UiColors, UiThemes, MapMarkerColors } from 'app/interfaces/ui.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { JustifyContent } from 'app/shared/flex-container/flex-container.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { ModalSizes } from 'app/shared/modals/modal.interfaces';
import { SharedService } from 'app/shared/shared.service';

@Component({
  selector: 'app-order-pod',
  templateUrl: './order-pod.component.html',
  styleUrls: ['./order-pod.component.scss'],
})
export class OrderPodComponent implements OnInit {
  constructor(private sharedService: SharedService) {}
  public ButtonTypes: ButtonTypes;
  public IconTypes = IconTypes;
  public UiColors = UiColors;
  public ModalSizes = ModalSizes;
  public UiThemes = UiThemes;
  public MapMarkerColors = MapMarkerColors;
  public justifyContent = JustifyContent;

  @Input() podData;
  @Input() reference;

  ngOnInit(): void {}
}
