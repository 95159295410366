<app-section>
  <heading [icon]="IconTypes.Stats" [color]="UiColors.Blue">
    <h4 class="heading-title">{{ headingText }}</h4>
    <div class="button-wrap">
      <app-menu-dropdown
        class="waybills"
        [label]="'Download Trip Sheet'"
        [items]="tripsheetOptions"
        [dropDownWidthRem]="22"
        (itemClick)="downloadTripSheet($event)"
        [processing]="downloading"
        [disabled]="downloading || readOnly"
      >
      </app-menu-dropdown>

      <app-basic-button
        *ngIf="waybillOptions.length === 1"
        download
        [title]="'Download Waybills'"
        [type]="ButtonTypes.Secondary"
        (click)="downloadDefaultWaybill()"
        [processing]="downloading"
        [disabled]="readOnly"
      >
      </app-basic-button>
      <app-menu-dropdown
        *ngIf="waybillOptions.length > 1"
        class="waybills"
        [label]="'Download Waybills'"
        [items]="waybillOptions"
        [dropDownWidthRem]="22"
        (itemClick)="downloadWaybills($event)"
        [processing]="downloading"
        [disabled]="downloading || readOnly"
      >
      </app-menu-dropdown>

      <app-basic-button
        [title]="dispatchingButtonText"
        [active]=""
        [type]="ButtonTypes.Green"
        [disabled]="
          state.status === bucketStatuses.Completed ||
          (trips.contract.length === 0 && trips.picup.length === 0 && !courierOrderTables) ||
          blockSameDayBucket 
          || readOnly
        "
        (click)="checkforSLA()"
        [processing]="processing"
      >
      </app-basic-button>

      <app-basic-button
        *ngIf="actingAs.roles.includes(UserRoles.SuperAdministrator) && state.status === bucketStatuses.Completed"
        [title]="'Delete Orders'"
        [type]="ButtonTypes.Error"
        [active]="false"
        [processing]="processing"
        (click)="deleteOrders()"
        [disabled]="readOnly">
      </app-basic-button>
    </div>
  </heading>
</app-section>
<app-section *ngIf="postDispatchSummaryData">
  <app-v2-post-dispatch-summary
    [bucket_id]="bucketId"
    [postDispatchSummaryData]="postDispatchSummaryData"
    [isActiveRouteView]="false"
    [viewMode]="'order'"
  >
  </app-v2-post-dispatch-summary>
</app-section>

<ng-container *ngIf="state.status === bucketStatuses.Completed && bucketFirestoreTrips$ | async as bucketTrips">
  <app-section>
    <heading [icon]="IconTypes.Trip" [color]="UiColors.Blue">
      <h4 class="heading-title">
        <strong>{{ bucketTrips?.length ? bucketTrips.length : '' }}</strong>
        <span [ngPlural]="bucketTrips?.length">
          <ng-template ngPluralCase="=1"> Trip</ng-template>
          <ng-template ngPluralCase="=0"> Trips</ng-template>
          <ng-template ngPluralCase="other"> Trips</ng-template>
        </span>
      </h4>
    </heading>
    <app-table
      [columns]="fsOrderTableColumns"
      [dataMap]="fsOrderTableDataMap"
      [rawData]="bucketTrips"
      [rowsSelectable]="false"
      [primaryActions]="postDispatchTablePrimaryActions"
      (actionTaken)="handleSQLTableActions($event)"
      [zeroDataMessage]="'No Trips Dispatched'"
      [readOnly]="readOnly"
    >
    </app-table>
  </app-section>
</ng-container>

<ng-container
  *ngIf="state.status !== bucketStatuses.Errored && state.status !== bucketStatuses.Completed && this.trips"
>
  <app-section>
    <heading [icon]="IconTypes.Trip" [color]="UiColors.Blue">
      <h4 class="heading-title">
        <strong *ngIf="trips.hasFetched">{{ trips.picup.length }}</strong>
        <i *ngIf="!trips.hasFetched" class="status-in-progress fa fa-spinner fa-spin"></i>
        <span> Picup Trips</span>
      </h4>
      <div class="button-wrap">
        <app-basic-button [title]="'Toggle Suburbs'" [type]="ButtonTypes.Secondary" (click)="hideSuburbs = !hideSuburbs">
        </app-basic-button>
      </div>
    </heading>
    <app-table
      [columns]="_tripsTableColumns"
      [tableData]="trips.picup"
      [rowsSelectable]="false"
      [primaryActions]="!user.is_admin || environment.appVersion === 2 ? undefined : _tablePrimaryActions"
      [isLoading]="!trips.hasFetched"
      [toggleColumns]="[
        { name: 'suburbs', hidden: hideSuburbs },
        { name: 'preferred_drivers_count', hidden: !user.is_admin }
      ]"
      (actionTaken)="handleConfirmationsTableActions($event)"
      [showTableTotals]="true"
      [readOnly]="readOnly"
    >
    </app-table>
  </app-section>
  <app-section>
    <heading [icon]="IconTypes.Trip" [color]="UiColors.Blue">
      <h4 class="heading-title">
        <strong *ngIf="trips.hasFetched">{{ trips.contract.length }}</strong>
        <i *ngIf="!trips.hasFetched" class="status-in-progress fa fa-spinner fa-spin"></i>
        <span> Contract Trips</span>
      </h4>
      <div class="button-wrap">
        <app-basic-button [title]="'Toggle Suburbs'" [type]="ButtonTypes.Secondary" (click)="hideSuburbs = !hideSuburbs">
        </app-basic-button>
      </div>
    </heading>
    <app-table
      [columns]="_tripsTableColumns"
      (actionTaken)="handleConfirmationsTableActions($event)"
      [tableData]="trips.contract"
      [rowsSelectable]="false"
      [primaryActions]="!user.is_admin || environment.appVersion === 2 ? undefined : _tablePrimaryActions"
      [isLoading]="!trips.hasFetched"
      [toggleColumns]="[{ name: 'suburbs', hidden: hideSuburbs }]"
      [showTableTotals]="true"
      [readOnly]="readOnly"
    >
    </app-table>
  </app-section>
</ng-container>
<div *ngIf="courierOrderTables">
  <app-section *ngFor="let courier of courierOrderTables">
    <div *ngIf="courier.orders?.length">
      <heading [icon]="IconTypes.Courier" [color]="courier.name === 'unassigned' ? UiColors.Yellow : UiColors.Green">
        <h4 class="heading-title">
          <strong *ngIf="courier.orders !== undefined">{{ courier.orders.length }}</strong>
          <i *ngIf="courier.orders === undefined" class="status-in-progress fa fa-spinner fa-spin"></i>
          <span> {{ tableNameMap.get(courier.name) }} Orders</span>
        </h4>
      </heading>
      <app-table
        *ngIf="courier.orders"
        [rawData]="courier.orders"
        [dataMap]="ordersDataMap"
        [columns]="ordersTableColumns"
        [rowsSelectable]="false"
        [zeroDataMessage]="'All orders have been allocated.'"
        [showTableTotals]="state.state !== BucketStates.Dispatching"
        [readOnly]="readOnly"
      >
      </app-table>
    </div>
  </app-section>
</div>

