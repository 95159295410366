import { BasicButton, ButtonTypes } from './../../shared/buttons/basic-button.component';
import { JustifyContent } from './../../shared/flex-container/flex-container.interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location, TitleCasePipe } from '@angular/common';
import { CourierOrdersService } from './courier-orders.service';
import { FSLastEventMapping, FSLastEventUIColorMapping } from '../post-dispatch/post-dispatch.constants';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { AuthService } from 'app/auth/auth.service';
import { Store } from '@ngrx/store';
import { selectorActingAs, selectorUser, selectorUserPermissions } from 'app/auth/auth.reducer';
import { ActingAs, AuthUser, UserRoles } from 'app/interfaces/auth.interfaces';
import { MenuDropDownItems } from 'app/shared/menu-dropdown/menu-drop-down.interfaces';
import { environment } from 'environments/environment';
import { SimpleModalService } from 'ngx-simple-modal';
import { ReconcileCourierOrderModalComponent } from './reconcile-courier-order-modal/reconcile-courier-order-modal.component';
import { CourierOrderDetails, CourierOrderParcel, CourierOrdersTabsEnum } from './courier-orders.interface';
import { NotesService } from '../notes/notes.service';
import { Subscription } from 'rxjs';
import { NewTripsService } from '../new-trips/new-trips.service';
import { NotesTypeEnum } from '../notes/notes.constants';

@Component({
  selector: 'app-courier-orders',
  templateUrl: './courier-orders.component.html',
  styleUrls: ['./courier-orders.component.scss'],
  providers: [TitleCasePipe]
})
export class CourierOrdersComponent implements OnInit, OnDestroy {
  courierOrderId: string;
  courierOrderDetails: CourierOrderDetails;
  firestoreSubscriptionCourierOrder: Subscription;
  firestoreSubscriptionOrderDetails: Subscription;
  fsOrderDetails;
  UserRoles = UserRoles;
  courierOrderStatus = {
    colour: null,
    name: null,
  };
  public menuOptions: MenuDropDownItems[] = [];
  viewTabs: BasicButton[] = this.setViewTabs();
  businessReferenceBreakdown;
  update = false;

  JustifyContent = JustifyContent;
  ButtonTypes = ButtonTypes;
  IconTypes = IconTypes;
  UiColors = UiColors;
  UiThemes = UiThemes;
  notesTypes = NotesTypeEnum;
  user: AuthUser;
  actingAs: ActingAs;
  activeTab: string = CourierOrdersTabsEnum.Details;
  orderNotesSubscription: Subscription;
  orderNotes = [];
  newNotesSubscription: Subscription;
  newNotesPresent = false;
  activeUserPermissions: any;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private courierOrdersService: CourierOrdersService,
    private authService: AuthService,
    private store: Store<any>,
    private simpleModalService: SimpleModalService,
    private notesService: NotesService,
    private newTripsService: NewTripsService,
    private titleCasePipe: TitleCasePipe
  ) {
    this.route.params.subscribe((params) => {
      this.courierOrderId = params['id'];
    });
    this.store.select(selectorActingAs).subscribe((next) => (this.actingAs = next));
    this.store.select(selectorUser).subscribe((next) => {
      this.user = next;
    });
    this.store.select(selectorUserPermissions).subscribe((next) => {
      this.activeUserPermissions = next;
    });
  }

  ngOnInit(): void {
    this.activeTab = CourierOrdersTabsEnum.Details;
    this.firestoreSubscriptionCourierOrder = this.courierOrdersService
      .getCourierOrderDetails(this.courierOrderId)
      .subscribe((res: any) => {
        if (res) {
          this.handleActingAs(res.BusinessId);
          this.courierOrderDetails = res;
          this.courierOrderStatus.name = FSLastEventMapping.get(res.LastEventName);
          this.courierOrderStatus.colour = FSLastEventUIColorMapping.get(res.LastEventName);
          this.createSummaryData(res.Parcels);
          this.setTripMenuItems();
          this.notesService.getOrderNotes(this.courierOrderDetails.OrderId);
          this.getOrderNotes();
          this.firestoreSubscriptionOrderDetails = this.newTripsService
            .getOrderDetailsObject(this.courierOrderDetails.OrderId)
            .subscribe((result: any) => {
              if (result) {
                this.fsOrderDetails = result;
              } else {
                this.newTripsService.rehydrateAggregate(this.courierOrderDetails.OrderId);
              }
            });
        } else {
          this.courierOrdersService.rehydrateAggregate(this.courierOrderId);
        }
      });
  }

  getOrderNotes(): void {
    this.orderNotesSubscription = this.notesService.orderNotes$.subscribe((data) => {
      this.orderNotes = data;
    });
    this.newNotesSubscription = this.notesService.newNotes$.subscribe((data) => {
      if (data) {
        this.newNotesPresent = true;
      } else {
        this.newNotesPresent = false;
      }
      this.viewTabs = this.setViewTabs();
    });
  }

  handleActingAs(businessId: string): void {
    if (businessId !== this.actingAs.id) {
      this.authService.setActingAs(businessId, false);
    }
  }

  ngOnDestroy(): void {
    this.firestoreSubscriptionCourierOrder.unsubscribe();
    this.firestoreSubscriptionOrderDetails.unsubscribe();
  }

  reduceParcelDetails(parcel) {
    return {
      Dimensions: parcel.Dimensions,
      ParcelReference: parcel.ParcelReference,
      ParcelWaybill: parcel.ParcelWaybill,
      CourierParcelState: parcel.CourierParcelState,
      OrderParcelState: parcel.OrderParcelState,
      Barcode: parcel.Barcode,
      FailedReason: this.transformFailureReasonToHumanize(parcel.FailedReason)
    };
  }

  goToBucket(): void {
    this.router.navigate(['dashboard/buckets/', { outlets: { Buckets: [this.courierOrderDetails.BucketId] } }]);
  }

  setTripMenuItems(): void {
    if (this.courierOrderStatus?.name === 'Completed' || this.courierOrderStatus?.name === 'Cancelled') {
      this.menuOptions = [];
    } else {
      this.menuOptions = [
        {
          name: 'recreate-courier-order',
          displayName: 'Attempt Recreate',
          hidden: !this.hasElevatedPermissions(),
        },
        {
          name: 'reconcile-courier-order',
          displayName: 'Reconcile Courier Order',
          hidden:
            !this.user.is_admin ||
            !this.actingAs.roles.includes(UserRoles.Administrator) ||
            !this.actingAs.roles.includes(UserRoles.SuperAdministrator),
        },
        {
          name: 'rehydrate-courier-order',
          displayName: 'Rehydrate',
          hidden:
            !this.actingAs.roles.includes(UserRoles.Administrator) ||
            !this.actingAs.roles.includes(UserRoles.SuperAdministrator),
        },
        {
          name: 'view-stream',
          displayName: 'View Eventstore Stream',
          hidden: !this.actingAs.roles.includes(UserRoles.SuperAdministrator),
        },
        {
          name: 'view-firebase',
          displayName: 'View Firebase Node',
          hidden: !this.actingAs.roles.includes(UserRoles.SuperAdministrator),
        },
      ];
    }
  }

  handleActionClick($event: string): void {
    switch ($event) {
      case 'recreate-courier-order':
        this.courierOrdersService.recreateCourierOrder(this.courierOrderId);
        break;
      case 'reconcile-courier-order':
        this.reconcileCourierOrder();
        break;
      case 'rehydrate-courier-order':
        this.courierOrdersService.rehydrateAggregate(this.courierOrderId);
        break;
      case 'view-stream':
        window.open(environment.eventStoreStream + this.courierOrderId);
        break;
      case 'view-firebase':
        window.open(
          'https://console.firebase.google.com/u/0/project/' +
            environment.firebase.projectId +
            '/firestore/data/courier-orders/' +
            this.courierOrderId
        );
        break;
    }
  }

  reconcileCourierOrder(): void {
    this.simpleModalService.addModal(ReconcileCourierOrderModalComponent).subscribe((result) => {
      if (!result) {
        return;
      } else {
        this.courierOrdersService.overrideCourierOrderState([this.courierOrderId], result[0], result[1]);
      }
    });
  }

  createSummaryData(parcels: CourierOrderParcel[]): void {
    this.businessReferenceBreakdown = {};
    parcels.forEach((parcel) => {
      const parcelBusinessReference = parcel.BusinessReference;
      const origin = parcel.OriginWarehouse ? parcel.OriginWarehouse : parcel.Origin;
      const destination = parcel.DestinationWarehouse ? parcel.DestinationWarehouse : parcel.Destination;
      if (Object.keys(this.businessReferenceBreakdown).includes(parcelBusinessReference)) {
        this.businessReferenceBreakdown[parcelBusinessReference].Parcels.push(this.reduceParcelDetails(parcel));
      } else {
        this.businessReferenceBreakdown[parcelBusinessReference] = {
          Parcels: [this.reduceParcelDetails(parcel)],
          Origin: origin,
          LastMileId: parcel.LastMileId,
          Destination: destination,
          BusinessId: parcel.BusinessId,
          OrderId: parcel.OrderId,
          ServiceTypeCode: this.courierOrderDetails.ServiceTypeDescription,
          CourierReference: this.courierOrderDetails.CourierReference,
          CourierName: this.courierOrderDetails.CourierName,
          BusinessReference: parcel.BusinessReference,
          ServiceTypeDescription: parcel.ServiceTypeDescription,
          DriverName: parcel.DriverName,
        };
      }
      this.businessReferenceBreakdown = Object.values(this.businessReferenceBreakdown);
    });
  }

  setViewTabs(): BasicButton[] {
    return Object.values(CourierOrdersTabsEnum).map((data: string) => {
      return { type: ButtonTypes.Primary, title: data };
    });
  }

  backButton(): void {
    this.location.back();
  }

  handleTabNavigation($event: string): void {
    this.activeTab = $event;
  }

  hasElevatedPermissions(): boolean {
    if (
      this.actingAs.roles.includes(UserRoles.Administrator) ||
      this.actingAs.roles.includes(UserRoles.SuperAdministrator)
    ) {
      return true;
    }

    if (
      this.activeUserPermissions.modules?.includes('super-admin') ||
      this.activeUserPermissions.modules?.includes('admin')
    ) {
      return true;
    }

    if (this.user.is_admin) {
      return true;
    }

    return false;
  }

  transformFailureReasonToHumanize(reason: string): string{
    if(reason){
      const lower = reason.toLowerCase();
      const regexUnderScore = new RegExp(/_/g);
      const removedUnderScore = lower.replace(regexUnderScore, ' ');
      return this.titleCasePipe.transform(removedUnderScore);
    }
    return '—'
  }
}
