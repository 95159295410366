import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import axios from 'app/api/axios';
import { authState, selectorActingAs } from 'app/auth/auth.reducer';
import { ActingAs, FSGlobalConfig } from 'app/interfaces/auth.interfaces';
import { NotificationsService } from 'app/shared/notifications/notifications.service';
import moment from 'moment';

export interface DateRange {
  startDate: string;
  endDate: string;
}

@Injectable({
  providedIn: 'root',
})
export class ReportingService {
  private dateRange: DateRange;
  private actingAs: ActingAs;
  constructor(
    private notificationsService: NotificationsService,
    private store: Store<authState>,
    public firestore: AngularFirestore) {
    this.store.select(selectorActingAs).subscribe((ac) => (this.actingAs = ac));
    this.dateRange = this.initializeDateRange();
  }

  initializeDateRange(): DateRange {
    return {
      startDate: moment(new Date()).startOf('day').format('YYYY-MM-DD'),
      endDate: moment(new Date()).endOf('day').format('YYYY-MM-DD'),
    };
  }

  getDateRange(): DateRange {
    return this.dateRange;
  }

  setDateRange(dateRange: DateRange): void {
    this.dateRange = {
      startDate: moment(dateRange.startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment(dateRange.endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    };
  }

  getReport(reportUrl: string, fileName: string, isAdmin: boolean = false, warehouseIds?: string[]): Promise<void> {
    return axios({
      responseType: 'blob',
      method: 'POST',
      url: reportUrl,
      data: {
        business_id: isAdmin ? null : this.actingAs.id,
        date_from: this.dateRange.startDate,
        date_to: this.dateRange.endDate,
        warehouseIds: warehouseIds
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName} - ${this.dateRange.startDate} ${this.dateRange.endDate}.xlsx`);
        document.body.appendChild(link);
        link.click();
        return;
      })
      .catch((error) => {
        this.handleBlobError(error);
        throw error.response?.data.message;
      });
  }

  getAdminReport(reportUrl: string, fileName: string): Promise<void> {
    return axios({
      method: 'GET',
      url: `${reportUrl}?startDate=${this.dateRange.startDate}&endDate=${this.dateRange.endDate}`,
      responseType: 'blob', // important
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName} | ${this.dateRange.startDate} - ${this.dateRange.endDate}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        this.handleBlobError(error);
        throw error.response?.data.message;
      });
  }

  handleBlobError(error: any): void {
    const responseObj = error.response.data.text();
    responseObj.then((data: string) => {
      this.notificationsService.publish({ type: 'error', message: JSON.parse(data).message });
    });
  }

  getDateRangePeriod(): any{
    return this.firestore.collection<FSGlobalConfig>('frontend-config').doc('global').valueChanges();
  }
}
