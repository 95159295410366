<app-section [theme]="UiThemes.Dark">
  <div [ngStyle]="{ opacity: route.ended_at ? 0.8 : 1 }">
    <app-flex-container
      [padding]="'2rem'"
      [borderedItems]="true"
      [justifyContent]="justifyContent.SpaceBetween"
      [borderBottom]="true"
    >
      <app-icon-with-text
        [text]="(route.route_type === 'Warehouse' ? 'WAREHOUSE ' : '') + 'ROUTE ' + (index + 1)"
        [textBold]="true"
        [sizeRem]="1"
        [color]="icons.colors.White"
      ></app-icon-with-text>

      <app-icon-with-text
        *ngIf="route.consignments && route.route_type !== 'Warehouse'"
        [icon]="icons.types.Details"
        [iconColor]="icons.colors.Blue"
        [iconSizeRem]="1"
        [iconSpacingRem]="1"
        [text]="route.consignments[0].consignment"
        [textBold]="true"
        [sizeRem]="1"
        [color]="icons.colors.White"
      ></app-icon-with-text>

      <app-icon-with-text
        [icon]="icons.types.Trip"
        [iconColor]="icons.colors.Blue"
        [iconSizeRem]="1"
        [iconSpacingRem]="1"
        [text]="'Started: ' + (route.started_at | date: 'medium')"
        [sizeRem]="1"
        [color]="icons.colors.White"
      ></app-icon-with-text>
      <app-icon-with-text
        [icon]="icons.types.Trip"
        [iconColor]="icons.colors.Blue"
        [iconSizeRem]="1"
        [iconSpacingRem]="1"
        [text]="route.ended_at ? 'Ended: ' + (route.ended_at | date: 'medium') : ('In Progress' | uppercase)"
        [sizeRem]="1"
        [color]="icons.colors.White"
      ></app-icon-with-text>
      <app-basic-button [title]="'Toggle Map'" [type]="buttonTypes.Primary" [active]="false" (click)="toggleMap()">
      </app-basic-button>
    </app-flex-container>

    <div class="summary">
      <app-grid-container
        [columns]="route.route_type === 'Warehouse' ? 'repeat(5, 1fr)' : 'repeat(4, 1fr)'"
        [padding]="'0rem'"
        [colGap]="'2rem'"
        [rowGap]="'0rem'"
        [theme]="uiThemes.HighlightedSummary"
      >
        <app-stats-icon
          [icon]="icons.types.Parcel"
          [dataSizeRem]="1.5"
          [iconColor]="icons.colors.Blue"
          [iconSizeRem]="2"
          [label]="'Packages'"
          [loading]="false"
          [data]="route?.parcel_count"
        >
        </app-stats-icon>

        <app-stats-icon
          [icon]="icons.types.Parcel"
          [dataSizeRem]="1.5"
          [iconColor]="icons.colors.Green"
          [iconSizeRem]="2"
          [label]="route.route_type === 'Warehouse' ? 'Collected' : 'Delivered'"
          [loading]="false"
          [data]="route.route_type === 'Warehouse' ? route.parcel_states.collected : route.parcel_states.delivered"
        >
        </app-stats-icon>

        <app-stats-icon
          [icon]="icons.types.Parcel"
          [dataSizeRem]="1.5"
          [iconColor]="icons.colors.Red"
          [iconSizeRem]="2"
          [label]="'Failed'"
          [loading]="false"
          [data]="
            route.route_type === 'Warehouse'
              ? route.parcel_states.failed_collection
              : route.parcel_states.failed_delivery
          "
        >
        </app-stats-icon>

        <app-stats-icon
          *ngIf="route.route_type === 'Warehouse'"
          [icon]="icons.types.Parcel"
          [dataSizeRem]="1.5"
          [iconColor]="icons.colors.Yellow"
          [iconSizeRem]="2"
          [label]="'Returned'"
          [loading]="false"
          [data]="route.parcel_states.returned.toString()"
        >
        </app-stats-icon>
        <app-stats-icon
          [icon]="icons.types.Driver"
          [dataSizeRem]="1.5"
          [iconColor]="icons.colors.Blue"
          [iconSizeRem]="2"
          [label]="'Driver'"
          [loading]="false"
          [data]="route.driver_name"
        >
        </app-stats-icon>
      </app-grid-container>
    </div>
    <div class="route-details" *ngIf="!showMap">
      <!-- <app-new-trips-waypoints
        [routeType]="route.route_type"
        [consignments]="route.consignments"
        id="waypoint-{{ i }}"
        *ngFor="let waypoint of driver_waypoints; let i = index"
        [waypoint]="waypoint"
        [allWaypoints]="waypoints"
        [parcels]="parcels"
        [waypointOpen]="waypointOpenStates[i]"
        (waypointOpenState)="saveOpenWaypoints(i, $event)"
        [index]="i + 1"
        [signatures]="signatures"
      ></app-new-trips-waypoints> -->
    </div>
    <div class="map-wrap" *ngIf="showMap">
      <app-map-view
        [mapType]="mapTypes.Tracking"
        [waypoints]="driver_waypoints"
        [polylines]="[[route?.polylines]]"
        [driverId]="route?.driver_id"
      ></app-map-view>
    </div>
  </div>
</app-section>
