<app-modal
  [open]="true"
  [actions]="actions"
  [size]="ModalSizes.Medium"
  [highlightPrimaryAction]="true"
  (handleAction)="handleAction($event)"
>
  <heading [icon]="IconTypes.Bucket" [color]="uiColors.Blue">
    <h4 class="heading-title">Override Courier Order State</h4>
  </heading>

  <div class="content">
    <app-dropdown
      class="dropdown-container"
      [dropdownTheme]="'light'"
      [label]="'Select Status'"
      [activeItem]="selectedStateName ? selectedStateName : 'Status'"
    >
      <div dropdownRef>
        <a *ngFor="let state of states" class="dropdown-item" (click)="setActiveState(state)">{{
          state.name | humanize
        }}</a>
      </div> </app-dropdown
    ><br />
    <input-field
      [type]="'text'"
      (valueChanged)="overrideReason = $event"
      [autoComplete]="'off'"
      [value]="overrideReason"
      [label]="'Reason'"
      [required]="true"
      [readOnly]="false"
    ></input-field>
  </div>
</app-modal>
