<div class="topbar justify-content-between">
  <div>
    <app-breadcrumbs></app-breadcrumbs>
  </div>
  <div>
    <div class="btn-group topbar-account" dropdown>
      <button dropdownToggle type="button" class="btn dropdown-toggle">
        {{ actingAs?.name }}
      </button>
      <div class="dropdown-menu dropdown-primary">
        <ng-template ngFor let-business [ngForOf]="businesses">
          <a class="dropdown-item" (click)="selectBusiness(business.business_id)">{{ business.name }}</a>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="chip mb-0 ml-4 mr-0">
    <img src="https://mdbootstrap.com/img/Photos/Avatars/avatar-6.jpg" alt="Contact Person" />{{ user?.name }}
  </div>
</div>
