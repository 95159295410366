import { Component, OnInit } from '@angular/core';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { ModalAction, ModalSizes } from 'app/shared/modals/modal.interfaces';
import { ModalActionsEnum } from 'app/shared/shared.interfaces';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  selector: 'app-open-new-trip-dialog',
  templateUrl: './open-new-trip-dialog.component.html',
  styleUrls: ['./open-new-trip-dialog.component.scss']
})
export class OpenNewTripDialogComponent extends SimpleModalComponent<void, boolean> implements OnInit {
  actions: ModalAction[] = [
    { name: ModalActionsEnum.Cancel, title: ModalActionsEnum.Cancel},
    { name: ModalActionsEnum.Confirm, title: ModalActionsEnum.Confirm, isPrimary: true}
  ];
  IconTypes = IconTypes;
  ModalSizes = ModalSizes;
  uiColors = UiColors;
  constructor() {
    super();
    this.result = false;
  }

  ngOnInit(): void {
  }

  handleAction($event: string): void {
    switch ($event) {
      case ModalActionsEnum.Cancel:
        this.result = false;
        this.close();
        break;
      case ModalActionsEnum.Confirm:
        this.result = true;
        this.close();
        break;
    }
  }
}
