<div id="autocomplete-wrap">
  <label 
    [className]="'label-'+ labelTheme" 
    for="">
    {{label}}
  </label>
  <input 
    [autocomplete]="autoComplete" 
    [class.invalid]="isInvalid" 
    [required]="true" 
    id="autocomplete" 
    class="input" 
    type="text" 
    [(ngModel)]="value"  
    #addressText 
    [placeholder]=placeholder>
  <svg-icon *ngIf="showClear" 
    (click)="resetAutocomplete()" 
    class="clear-icon" 
    src="/assets/img/icons/unrouted.svg">
  </svg-icon>
</div>