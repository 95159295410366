<app-modal
  [open]="true"
  [actions]="actions"
  [highlightPrimaryAction]="true"
  [size]="ModalSizes.Medium"
  (handleAction)="handleAction($event)"
>
  <heading [icon]="IconTypes.Alert" [color]="uiColors.Orange">
    <h4 class="heading-title">{{ 'Failed to send to courier' }}</h4>
  </heading>

  <div class="modal-body">
    <p>There was an error sending the order to courier</p>
    <p>Message:</p>
    <p>{{message}}</p>
  </div>
</app-modal>
