import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Warehouse } from 'app/interfaces/auth.interfaces';

export interface SelectWarehouseData {
  Warehouses: Warehouse[];
}

@Component({
  selector: 'app-select-warehouses',
  templateUrl: './select-warehouses.component.html',
  styleUrls: ['./select-warehouses.component.scss']
})
export class SelectWarehousesComponent implements OnInit {
  warehouses: FormControl = new FormControl([]);
  constructor(public dialogRef: MatDialogRef<SelectWarehousesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SelectWarehouseData) { }

  ngOnInit(): void {
  }

}
