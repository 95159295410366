import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ModalSizes, ModalAction } from 'app/shared/modals/modal.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { JustifyContent } from 'app/shared/flex-container/flex-container.interfaces';
import { TableColumn, TableDataMap } from 'app/shared/table/table.interfaces';
import { mfGetDriverType } from 'app/shared/table/map-formatters.table.helpers';
import axios from 'app/api/axios';
import { NotificationsService } from '../notifications/notifications.service';
import { ButtonTypes } from '../buttons/basic-button.component';

@Component({
  selector: 'app-edit-preferred-drivers',
  templateUrl: './edit-preferred-drivers.component.html',
  styleUrls: ['./edit-preferred-drivers.component.scss'],
})
export class EditPreferredDriversComponent extends SimpleModalComponent<any, any> implements OnInit {
  trip_id;
  bucket_id;
  business_id;
  user_id;
  picup_id;
  context;

  currentDriverTableData;
  driverQueryString: string;
  searchResults;

  public ModalSizes = ModalSizes;
  public IconTypes = IconTypes;
  public UiColors = UiColors;
  public UiThemes = UiThemes;
  public justifyContent = JustifyContent;
  buttonTypes = ButtonTypes;

  public actions: ModalAction[] = [{ name: 'close', title: 'Close', isPrimary: false }];

  public driverTableColumns: TableColumn[] = [
    { name: 'driverId', displayName: 'Id', hidden: true },
    { name: 'driver', displayName: 'Driver' },
    { name: 'phone', displayName: 'Phone Number' },
    { name: 'email', displayName: 'Email' },
    { name: 'type', displayName: 'Type' },
  ];

  public driverTableDataMap: TableDataMap = {
    cells: {
      driverId: { column: 'driverId', map: 'driver_id' },
      driver: { column: 'driver', map: 'driver_name' },
      phone: {
        column: 'phone',
        map: 'driver_phone',
        displayPipeArgs: ['none'],
      },
      email: { column: 'email', map: 'driver_email' },
      // vehicle: { column: 'vehicle', map: 'vehicle_id' },
      type: { column: 'type', map: 'driver_role' },
    },
  };

  public driverSearchTableColumns: TableColumn[] = [
    { name: 'driverId', displayName: 'Id', hidden: true },
    { name: 'driver', displayName: 'Driver' },
    { name: 'vehicle', displayName: 'Vehicle' },
    { name: 'phone', displayName: 'Phone' },
    { name: 'actingAs', displayName: 'Type' },
  ];

  public driverSearchTableDataMap: TableDataMap = {
    cells: {
      driverId: { column: 'driverId', map: '_id' },
      driver: { column: 'driver', map: 'name' },
      vehicle: { column: 'vehicle', map: 'vehicles' },
      phone: { column: 'phone', map: 'phone' },
      actingAs: {
        column: 'actingAs',
        map: 'acting_as',
        format: mfGetDriverType,
      },
    },
  };

  public currentDriverTablePrimaryActions = [{ event: 'remove', title: 'Remove Driver', icon: IconTypes.Trash }];

  public searchDriverTablePrimaryActions = [{ event: 'add', title: 'Add Driver', icon: IconTypes.Check }];

  constructor(private notificationsService: NotificationsService) {
    super();
  }

  handleAction($event) {
    switch ($event) {
      case 'close':
        this.close();
        break;
    }
  }

  respondToTableAction($event) {
    const driverId = $event.rows[0].cells.find((cell) => cell.column === 'driverId').value;
    if ($event.action.event === 'remove') {
      this.removePreferredDriver(driverId);
    }
    if ($event.action.event === 'add') {
      this.addPreferredDriver(driverId);
    }
  }

  ngOnInit() {
    if (this.bucket_id) {
      this.context = 'bucket';
    }
    if (this.picup_id) {
      this.context = 'picup';
    }
    this.getPreferredDrivers();
  }

  getPreferredDrivers() {
    if (this.bucket_id) {
      axios.get('/bucket/' + this.bucket_id + '/get-preferred-drivers/' + this.trip_id).then((response) => {
        this.currentDriverTableData = response?.data;
      });
    } else if (this.picup_id) {
      axios.get('/picup/' + this.picup_id + '/get-preferred-drivers/').then((response) => {
        this.currentDriverTableData = response?.data;
      });
    }
  }

  addPreferredDriver(driverId) {
    if (this.bucket_id) {
      axios({
        method: 'POST',
        url: '/bucket/' + this.bucket_id + '/add-preferred-drivers/' + this.trip_id,
        data: [driverId],
      })
        .then(() => this.getPreferredDrivers())
        .catch((error) => this.notify(false, error.response?.data.message));
    } else if (this.picup_id) {
      axios
        .get('/picup/' + this.picup_id + '/add-preferred-driver/' + driverId)
        .then(() => this.getPreferredDrivers())
        .catch((error) => this.notify(false, error.response?.data.message));
    }
  }

  removePreferredDriver(driverId) {
    if (this.bucket_id) {
      axios({
        method: 'POST',
        url: '/bucket/' + this.bucket_id + '/remove-preferred-drivers/' + this.trip_id,
        data: [driverId],
      })
        .then(() => this.getPreferredDrivers())
        .catch((error) => this.notify(false, error.response?.data.message));
    } else if (this.picup_id) {
      axios
        .get('/picup/' + this.picup_id + '/remove-preferred-driver/' + driverId)
        .then(() => this.getPreferredDrivers())
        .catch((error) => this.notify(false, error.response?.data.message));
    }
  }

  findDriverByName() {
    return axios({
      method: 'POST',
      url: '/trip-management/query-driver-by-name',
      data: {
        business_id: this.business_id,
        name: this.driverQueryString,
        user_id: this.user_id,
      },
    }).then((drivers) => {
      this.searchResults = drivers.data;
    });
  }

  notify(successful, message) {
    const notificationType = successful ? 'success' : 'error';
    this.notificationsService.publish({
      type: notificationType,
      message: message,
    });
  }
}
