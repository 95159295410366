import { Component, OnInit, Input } from '@angular/core';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { JustifyContent } from 'app/shared/flex-container/flex-container.interfaces';

@Component({
  selector: 'app-slider-button',
  templateUrl: './slider-button.component.html',
  styleUrls: ['./slider-button.component.scss'],
})
export class SliderButtonComponent implements OnInit {
  constructor() {}

  public IconTypes = IconTypes;
  public UiColors = UiColors;
  public UiThemes = UiThemes;
  public justifyContent = JustifyContent;

  @Input() iconSizeRem = 1.5;
  @Input() iconType: IconTypes = IconTypes.Down;
  @Input() labelText = 'Demo Text';
  @Input() theme: UiThemes = UiThemes.Light;

  ngOnInit(): void {}
}
