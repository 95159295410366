<h3 class="completed-header">
  Search Completed Trips
  <mat-icon
    matTooltip="Use the search criteria below to find completed trips. When specifying a driver name, this value needs to be an exact match, and only up to 10 warehouses may be selected at a time."
    >help</mat-icon
  >
</h3>
<div class="filter-section">
  <mat-form-field *ngIf="!(operationsTrackingService.isAdmin$ | async) && warehouses && warehouses?.length > 0 && (operationsTrackingService.teams$ | async).length > 0" appearance="standard">
    <mat-label>Team</mat-label>
    <mat-select [formControl]="teamsControl" multiple>
      <mat-option *ngFor="let team of (operationsTrackingService.teams$ | async); trackBy: trackByTeamName" 
        [value]="team.teamName" >
        {{team.teamName}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="warehouses && warehouses?.length > 0" appearance="standard">
    <mat-label>Warehouses</mat-label>
    <mat-select
      [formControl]="warehouseControl"
      multiple>
      <input-field
        (valueChanged)="filterWarehouse($event)"
        [type]="'text'"
        [placeholder]="'Search...'"
        [autoComplete]="'off'">
      </input-field>
      <mat-option *ngFor="let warehouse of filteredWarehouses; trackBy: trackById" 
        [value]="warehouse.id">
        {{ warehouse.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="filteredBusiness?.length > 0" appearance="standard">
    <mat-label>Businesses</mat-label>
    <mat-select [formControl]="businessControl" multiple>
      <input-field
        (valueChanged)="filterBusinesses($event)"
        [type]="'text'"
        [placeholder]="'Search...'"
        [autoComplete]="'off'">
      </input-field>
      <mat-option *ngFor="let business of filteredBusiness" [value]="business.business_id">{{ business.name }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="standard">
    <mat-label>Fleet</mat-label>
    <mat-select (selectionChange)="filterOrders()" [formControl]="fleetControl">
      <mat-option *ngFor="let fleet of fleets" [value]="fleet === 'All' ? '' : fleet">{{ fleet }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="standard">
    <mat-label>Customer Reference</mat-label>
    <input [formControl]="customerReferenceControl" matInput />
  </mat-form-field>
  <mat-form-field appearance="standard">
    <mat-label>Driver Name</mat-label>
    <input [formControl]="driverNameControl" matInput />
  </mat-form-field>
  <mat-icon
    (click)="
      filterOrders()
    "
    matTooltip="Search"
    >search</mat-icon
  >
  <mat-icon (click)="resetFilters()" matTooltip="Reset filters">tune</mat-icon>
</div>

<table mat-table matSort [dataSource]="dataSource" matSortActive="Timestamp" matSortDirection="desc">
  <ng-container matColumnDef="CustomerReference">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer Reference</th>
    <td mat-cell *matCellDef="let element">{{ element.CustomerReference }}</td>
  </ng-container>

  <ng-container matColumnDef="WarehouseName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Warehouse Name</th>
    <td mat-cell *matCellDef="let element">{{ element.WarehouseName }}</td>
  </ng-container>

  <ng-container matColumnDef="BusinessName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Business Name</th>
    <td mat-cell *matCellDef="let element">{{ element.BusinessId | businessName: businesses }}</td>
  </ng-container>

  <ng-container matColumnDef="DriverNames">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Driver Names</th>
    <td mat-cell *matCellDef="let element">{{ element.DriverNames }}</td>
  </ng-container>

  <ng-container matColumnDef="ScheduledDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Scheduled Date</th>
    <td mat-cell *matCellDef="let element">{{ element.ScheduledDate?.toMillis() | date: 'medium' }}</td>
  </ng-container>

  <ng-container matColumnDef="Timestamp">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Updated</th>
    <td class="pd-l-5 {{ element.Timestamp?.toMillis() | timeAgoColour }}" mat-cell *matCellDef="let element">
      {{ element.Timestamp?.toMillis() | timeAgo }}
    </td>
  </ng-container>

  <ng-container matColumnDef="LastEventName">
    <th mat-header-cell *matHeaderCellDef>Last Event</th>
    <td class="{{ element.LastEventName | lastEventColour }} pd-l-5" mat-cell *matCellDef="let element">
      {{ element.LastEventName | lastEvent }}
    </td>
  </ng-container>

  <ng-container matColumnDef="IsRoundTrip">
    <th mat-header-cell *matHeaderCellDef>Trip Type</th>
    <td class="pd-l-5" mat-cell *matCellDef="let element">{{ element.IsRoundTrip ? 'Round Trip' : 'One Way' }}</td>
  </ng-container>

  <ng-container matColumnDef="FleetAllocation">
    <th mat-header-cell *matHeaderCellDef>Fleet</th>
    <td mat-cell *matCellDef="let element">{{ element.FleetAllocation }}</td>
  </ng-container>

  <ng-container matColumnDef="AssignedTo">
    <th mat-header-cell *matHeaderCellDef>Assigned</th>
    <td mat-cell *matCellDef="let element">{{ element.Reservation?.Username  ?? 'Unassigned'}}</td>
  </ng-container>

  <ng-container matColumnDef="Actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td class="actions" mat-cell *matCellDef="let element">
      <mat-icon matTooltip="View" (click)="handleTableAction({ Id: element.Id, Action: ActionEnum.View })"
        >visibility</mat-icon
      >
      <mat-icon matTooltip="Copy ID" (click)="handleTableAction({ Id: element.Id, Action: ActionEnum.CopyId })"
        >content_copy</mat-icon
      >
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<mat-paginator [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons [pageSize]="pageSize"> </mat-paginator>
