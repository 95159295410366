import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Color } from '@swimlane/ngx-charts';

@Component({
  selector: 'app-line-graph',
  templateUrl: './line-graph.component.html',
  styleUrls: ['./line-graph.component.scss'],
})
export class LineGraphComponent implements OnInit {
  @Input() noDataMessage;
  @Output() onSelected = new EventEmitter<string>();
  constructor() {}

  @Input() colorScheme = {
    domain: ['#02DE73', '#F1453D', '#27B7FC', '#F8E71C'],
  } as Color;
  @Input() data;

  onSelect($event) {
    this.onSelected.emit($event);
  }

  ngOnInit() {}
}
