import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange } from '@angular/core';
import { TableColumn, TableDataMap, TableDataSetHighlightColor } from 'app/shared/table/table.interfaces';
import { Store } from '@ngrx/store';
import { selectorActiveBucketId } from '../../../manage-bucket.reducer';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { TableCell } from '../../../../../shared/table/table.interfaces';
import { ManageBucketService } from '../../../manage-bucket.service';
import { GeocoderConfig } from 'app/shared/geocoding-tool/geocoding-tool.component';
import { HealthStates } from 'app/dashboard/trips.enums';
import { selectorUserPermissions } from 'app/auth/auth.reducer';
import { environment } from 'environments/environment';
import { mfTruncateString } from 'app/shared/table/map-formatters.table.helpers';
import { mfiParcelType } from 'app/dashboard/buckets/buckets.helpers';
import { SimpleModalService } from 'ngx-simple-modal';

export const mfSetHealthHighlightColor: TableDataSetHighlightColor = (map: string[], item: any) => {
  switch (item[map[0]]) {
    case 'Ready':
      return 'highlight-' + HealthStates.Good;
    default:
      return 'highlight-' + HealthStates.Warning;
  }
};

@Component({
  selector: 'app-verify-table',
  templateUrl: './verify-table.component.html',
  styleUrls: ['./verify-table.component.scss'],
})
export class VerifyTableComponent implements OnChanges, OnInit {
  @Output() geocodeRequested = new EventEmitter<any>();
  @Output() changeBucketRequested = new EventEmitter<any>();
  @Output() editOrderRequested = new EventEmitter<any>();
  @Output() removeOrderRequested = new EventEmitter<any>();
  @Output() toggleTableRequested = new EventEmitter<any>();
  @Output() manualRoutingRequested = new EventEmitter<any>();
  @Output() restageOrderRequested = new EventEmitter<any>();

  @Input() update = false;
  @Input() readOnly = false;
  environment = environment;
  user_permissions;
  numberOfResults = 15;
  bucketTableData = [];
  shownOrders = [];
  filteredOrders = [];
  assignableBuckets = [];
  bucketId: string;
  searchString: string;
  showViewMore = true;
  public Icons = {
    Colors: UiColors,
    Types: IconTypes,
  };

  verifyOrdersColumns: TableColumn[] = [
    { name: 'address_id', displayName: 'Address ID', hidden: true },
    { name: 'order_id', displayName: 'Order ID', sortable: true, hidden: true },
    { name: 'bucket_order_id', displayName: 'Bucket Order ID', sortable: true, hidden: true },
    { name: 'visit_type', displayName: 'Visit Type' },
    { name: 'business_reference', displayName: 'Business Reference' },
    { name: 'waybill_number', displayName: 'Waybill' },
    { name: 'customer_name', displayName: 'Customer', sortable: true },
    { name: 'customer_phone', displayName: 'Phone Number' },
    { name: 'consignment', displayName: 'Consignment', sortable: true },
    { name: 'duration', displayName: 'Duration', sortable: true },
    { name: 'status', displayName: 'Status', sortable: true },
    { name: 'health', displayName: 'Health', sortable: true },
    { name: 'geocoded_data', displayName: 'Geocoded Data' },
    { name: 'special_instructions', displayName: 'Special Instructions' },
    { name: 'payment', displayName: 'Payment' },
  ];
  verifyOrdersDataMap: TableDataMap = {
    cells: {
      address_id: { column: 'Address ID', map: 'address_id' },
      visit_type: {
        column: 'Visit Type',
        map: 'visit_type',
        // iconFormat: mfiParcelType,
        // iconMap: ['visit_type'],
        // iconColor: UiColors.Grey5,
        // iconSizeRem: 1,
      },
      order_id: {
        column: 'Order ID',
        map: 'order_id',
        displayPipeArgs: ['none'],
      },
      bucket_order_id: {
        column: 'Bucket Order Id',
        map: 'bucket_order_id',
        displayPipeArgs: ['none'],
      },
      customer_name: { column: 'Customer', map: 'customer_name' },
      customer_phone: {
        column: 'Phone Number',
        map: 'customer_phone',
        displayPipeArgs: ['none'],
      },
      consignment: { column: 'Consignment', map: 'consignment' },
      status: { column: 'Status', map: 'status' },
      health: {
        column: 'Health',
        map: 'health',
        highlighted: true,
        highlightColorClass: mfSetHealthHighlightColor,
        highlightColorMap: ['health'],
      },

      duration: { column: 'Duration', map: 'duration_at_stop_minutes', postfix: 'm' },
      geocoded_data: { column: 'Geocoded Data', map: 'geocoded_address' },
      special_instructions: { column: 'Special Instructions', map: ['special_instructions'], format: mfTruncateString },

      business_reference: {
        column: 'Business Reference',
        map: 'business_reference',
      },
      waybill_number: {
        column: 'Waybill',
        map: 'waybill_number',
      },
      payment: {
        column: 'payment',
        map: 'total_parcels_value',
      },
    },
  };
  public tablePrimaryActions = [];
  public tableBulkActions = [
    {
      event: 'geocode',
      title: 'Geocode',
      icon: IconTypes.Geocoder,
      icon_color: UiColors.Blue,
    },
    {
      event: 'bucket',
      title: 'Move to Bucket',
      icon: IconTypes.Bucket,
      icon_color: UiColors.Green,
    },
    {
      event: 'manual',
      title: 'Manual Routing',
      icon: IconTypes.Trip,
      icon_color: UiColors.Yellow,
    },
    {
      event: 'restageOrders',
      title: 'Re-Stage Orders',
      icon: IconTypes.Bucket,
      icon_color: UiColors.Yellow,
    },
    {
      event: 'remove',
      title: 'Remove Orders',
      icon: IconTypes.Trash,
      icon_color: UiColors.Red,
    },
    // { event: 'flag', title: 'Flag Order', icon: IconTypes.Flag },
  ];

  // TODO: Flag orders after orderID is passed in flags.

  constructor(public store: Store<any>, private manageBucketService: ManageBucketService) {
    this.store.select(selectorActiveBucketId).subscribe((next) => (this.bucketId = next));
    this.store.select(selectorUserPermissions).subscribe((next) => (this.user_permissions = next));
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes.readOnly) {
      this.setupBulkActions();
    }
    if (changes.update && changes.update.currentValue === changes.update.previousValue) {
      return;
    }
    this.getTableData();
  }

  ngOnInit() {
    this.setupBulkActions();

    // hide manual routing if not OTD
    if (environment.envName !== 'onthedot' && environment.envName !== 'Azure-Dev') {
      this.tableBulkActions.splice(2, 1);
    }

  }

  respondToTableActions($event) {
    const ids = [];
    const order_id = $event.rows[0].cells.find((cell: TableCell) => cell.column === 'order_id').value;
    const config: GeocoderConfig = {
      addresses: ids,
      bucket_id: null,
      order_id,
    };
    switch ($event.action.event) {
      case 'bucket':
        $event.rows.forEach((element) => {
          ids.push(element.cells.find((cell: TableCell) => cell.column === 'order_id').value);
        });
        this.requestNewBucket(ids);
        break;
      case 'geocode':
        $event.rows.forEach((element) => {
          ids.push(element.cells.find((cell: TableCell) => cell.column === 'address_id').value);
        });
        this.geocodeOrder(config);
        break;
      case 'edit':
        $event.rows.forEach((element) => {
          this.editOrder(element.cells.find((cell: TableCell) => cell.column === 'business_reference').value);
        });
        break;
      case 'restageOrders':
        $event.rows.forEach((element) => {
          ids.push(element.cells.find((cell: TableCell) => cell.column === 'bucket_order_id').value);
          this.restageOrder(ids);
        });
        break;
      case 'remove':
        $event.rows.forEach((element) => {
          ids.push(element.cells.find((cell: TableCell) => cell.column === 'order_id').value);
        });
        this.removeOrders(ids);
        break;
      case 'manual':
        $event.rows.forEach((element) => {
          ids.push(element.cells.find((cell: TableCell) => cell.column === 'order_id').value);
        });
        this.manualRouting(ids);
    }
  }

  toggleTable() {
    this.toggleTableRequested.emit(true);
  }

  manualRouting(business_references) {
    this.manualRoutingRequested.emit(business_references);
  }

  restageOrder(bucket_order_ids) {
    this.restageOrderRequested.emit(bucket_order_ids);
  }

  editOrder(business_reference) {
    this.editOrderRequested.emit(business_reference);
  }

  removeOrders(order_ids) {
    this.removeOrderRequested.emit(order_ids);
  }

  geocodeOrder(addresses) {
    this.geocodeRequested.emit(addresses);
  }

  requestNewBucket(ids) {
    this.changeBucketRequested.emit(ids);
  }

  getTableData() {
    this.manageBucketService.getBucketTableData().then((response) => {
      this.bucketTableData = response;
      this.showOrders();
    });
  }

  filterOrders(searchString) {
    const newArray = this.bucketTableData.filter((order) => {
      return order.business_reference.toLowerCase().includes(searchString.toLowerCase());
    });
    this.filteredOrders = newArray;
    this.showOrders();
  }

  loadMoreTableData() {
    this.shownOrders = [];
    this.numberOfResults = this.numberOfResults + 5;
    this.showOrders();
    if (this.bucketTableData.length <= this.shownOrders.length) {
      this.showViewMore = false;
    }
  }

  showAllData() {
    this.shownOrders = [];
    this.numberOfResults = null;
    this.showOrders();
  }

  showOrders() {
    let i = 0;
    this.shownOrders = [];
    if (this.filteredOrders.length >= 0 && this.searchString) {
      if (!this.numberOfResults) {
        this.shownOrders = this.filteredOrders;
        this.showViewMore = false;
      } else {
        this.filteredOrders.forEach((order) => {
          i++;
          if (i <= this.numberOfResults) {
            this.shownOrders.push(order);
          }
        });
        this.calculateViewMore(this.filteredOrders, this.shownOrders);
      }
    } else {
      if (!this.numberOfResults) {
        this.shownOrders = this.bucketTableData;
        this.showViewMore = false;
      } else {
        this.bucketTableData.forEach((order) => {
          if (order) {
            i++;
            if (i <= this.numberOfResults) {
              this.shownOrders.push(order);
            }
          }
        });
        this.calculateViewMore(this.bucketTableData, this.shownOrders);
      }
    }
  }

  calculateViewMore(comparison, shown) {
    if (comparison.length <= shown.length) {
      this.showViewMore = false;
    } else {
      this.showViewMore = true;
    }
  }

  setupBulkActions(): void {
    if(this.readOnly){
      this.tablePrimaryActions = [{ event: 'bucket', title: 'Move to Bucket', icon: IconTypes.Bucket }];
      this.tableBulkActions = [{
        event: 'bucket',
        title: 'Move to Bucket',
        icon: IconTypes.Bucket,
        icon_color: UiColors.Green,
      }]
    } else {
      this.tablePrimaryActions = [
        {
          event: 'edit',
          title: 'Edit',
          icon: IconTypes.Edit,
        },
        {
          event: 'geocode',
          title: 'Geocode',
          icon: 'geocoder.svg',
        },
        { event: 'bucket', title: 'Move to Bucket', icon: IconTypes.Bucket },
        {
          event: 'remove',
          title: 'Remove Order',
          icon: IconTypes.Trash,
        },
        // { event: 'flag', title: 'Flag Order', icon: IconTypes.Flag },
      ];
    }
  }
}
