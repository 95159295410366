import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { PaymentService } from './payment.service';
import { BasicButton, ButtonTypes } from '../buttons/basic-button.component';
import { NotificationsService } from '../notifications/notifications.service';
import { UiThemes } from 'app/interfaces/ui.interfaces';
import { IconTypes } from '../icon/icon.interfaces';
import { Store } from '@ngrx/store';
import { environment } from 'environments/environment';
import { selectorActingAs, selectorUser } from 'app/auth/auth.reducer';
import {
  PaymentTypeEnum,
  BackendPaymentTypesEnum,
  PayStackPaymentResponse,
  PayStackResponseStatusEnum,
} from './payment.interfaces';
import { MethodIntentionEnum } from '../one-to-one/select-method/select-method.interface';
import { ActingAs, AuthUser } from 'app/interfaces/auth.interfaces';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit, OnDestroy, OnChanges {
  public IconTypes = IconTypes;
  public ButtonTypes = ButtonTypes;
  public UiThemes = UiThemes;
  loading = false;
  paymentOpen = false;
  creditsSelected = false;
  accountSelected = false;
  payStackSelected = false;
  environment = environment;
  currency_symbol = environment.currency_symbol;
  paymentDetails = environment.paymentDetails;
  wallet;
  actingAs: ActingAs;
  user: AuthUser;
  paymentError;
  paymentSub;
  paymentResponse;
  showAccount;
  paymentResponseSubscription;
  checkoutId: string;
  @Input() intention: number;
  @Input() request: any;
  @Input() price: number;
  @Input() availableCredits: number;
  waitingForPeachRepsonse = false;

  // Waiting for backend implementation here
  showPaystack = true;

  public paymentMethods: BasicButton[] = [];

  // CheckoutIntention
  // 0 None,
  // 1 CreatePicup,
  // 2 CreateCourierOrder,
  // 3 AddCredits

  constructor(
    public notificationsService: NotificationsService,
    private paymentService: PaymentService,
    public store: Store<any>
  ) {
    this.paymentService.wallet.subscribe((wallet) => {
      this.wallet = wallet;
    });
    this.paymentService.paymentError.subscribe((error) => (this.paymentError = error));

    this.store.select(selectorActingAs).subscribe((next) => (this.actingAs = next));
    this.store.select(selectorUser).subscribe((next) => (this.user = next));
  }

  ngOnInit(): void {
    this.paymentResponse = null;
    this.paymentMethods = this.populateButtons();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.availableCredits) {
      this.creditsSelected = false;
      this.paymentOpen = false;
      this.paymentResponse = null;
      this.paymentService.startFromScratch();
    }
  }

  ngOnDestroy(): void {
    this.paymentService.startFromScratch();
  }

  populateButtons(): BasicButton[] {
    return [
      {
        type: ButtonTypes.Primary,
        title: PaymentTypeEnum.Account,
        hidden: !this.actingAs.payment_methods.includes(PaymentTypeEnum.Account)
      },
      {
        type: ButtonTypes.Primary,
        title: PaymentTypeEnum.Credits,
        hidden: !(this.availableCredits > this.price),
      },
      {
        type: ButtonTypes.Primary,
        title: PaymentTypeEnum.OnlinePayment,
        hidden:
          !this.actingAs.payment_methods.includes('Peach'),
      },
      {
        type: ButtonTypes.Primary,
        title: 'EFT',
        hidden: this.intention === 3 ? (this.actingAs.payment_methods.includes('Eft') ? false : true) : true,
      },
    ];
  }

  // TODO: fix typing
  handleButtonClick(paymentType: PaymentTypeEnum | any): void {
    this.paymentService.startFromScratch();
    this.creditsSelected = false;
    switch (paymentType) {
      case PaymentTypeEnum.Account: {
        this.request['payment_type'] = BackendPaymentTypesEnum.Account;
        this.accountSelected = true;
        this.paymentOpen = true;
        break;
      }
      case PaymentTypeEnum.Credits: {
        this.request['payment_type'] = BackendPaymentTypesEnum.Credits;
        this.creditsSelected = true;
        this.paymentOpen = true;
        break;
      }
      case PaymentTypeEnum.OnlinePayment: {
        this.request['payment_type'] = BackendPaymentTypesEnum.Paystack;
        this.payStackSelected = true;
        this.paymentOpen = true;
        break;
      }
    }
  }

  paystackTransactionOutcome(response: PayStackPaymentResponse): void {
    if (response.status === PayStackResponseStatusEnum.Success) {
      this.request['payment_type'] = BackendPaymentTypesEnum.Paystack;
      this.createTripFromPaystackPayment(this.request);
    }
  }

  createTripFromPaystackPayment(request): void {
    this.loading = true;
    this.payStackSelected = false;
    this.paymentService
      .createOneToManyFromQuote(request)
      .then(() => {
        this.loading = false;
      })
      .catch((res) => {
        this.loading = false;
        this.paymentError = res;
      });
  }

  confirmAccount(): void {
    this.loading = true;
    this.accountSelected = false;
    this.paymentService
      .createOneToMany(this.request)
      .then(() => (this.loading = true))
      .catch(() => (this.loading = false));
    this.paymentOpen = true;
  }

  resetPayment(): void {
    this.creditsSelected = false;
    this.accountSelected = false;
    this.payStackSelected = false;
    this.paymentOpen = false;
    this.paymentError = null;
    this.checkoutId = null;
    this.paymentResponse = null;
    this.loading = null;
    this.paymentService.startFromScratch();
  }

  // TODO: figure out why its missing
  confirmCredits(): void {}
}
