import { Injectable } from '@angular/core';
import { NotificationsService } from './notifications/notifications.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlingService {
  constructor(private notificationsService: NotificationsService) {}

  handleBlobError(error: any): void {
    const responseObj = error.response.data.text();
    responseObj.then((data: string) => {
      this.notificationsService.publish({ type: 'error', message: JSON.parse(data).message });
    });
  }
}
