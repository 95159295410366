import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import moment from 'moment';
import { ButtonTypes } from '../buttons/basic-button.component';

@Component({
  moduleId: module.id,
  template: `
    <div class="modal">
      <div class="modal-content">
        <div class="modal-body">
          <app-date-picker [maxDate]="maxDate" (changeDate)="returnDate($event)"></app-date-picker>
          <div *ngIf="useTimePicker" class="time-picker">
            <app-dropdown [activeItem]="selectedHour" [label]="'Select an hour'" [dropdownTheme]="'dark'">
              <div dropdownRef>
                <a *ngFor="let hour of hours" class="dropdown-item" (click)="setHour(hour)">{{ hour }}</a>
              </div>
            </app-dropdown>
            <app-dropdown [activeItem]="selectedMinute" [label]="'Select a minute'" [dropdownTheme]="'dark'">
              <div dropdownRef>
                <a *ngFor="let minute of minutes" class="dropdown-item" (click)="setMinutes(minute)">{{ minute }}</a>
              </div>
            </app-dropdown>
          </div>
          <div class="modal-footer">
            <app-basic-button
              [title]="'Cancel'"
              [type]="buttonType.Tertiary"
              (click)="close()"></app-basic-button>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./date-picker-modal.component.scss'],
})
export class DatePickerModalComponent extends SimpleModalComponent<any, string> implements OnInit {
  maxDate: Date;
  useTimePicker: boolean;
  selectedHour: string = '00';
  selectedMinute: string = '00';
  hours = Array.from(Array(24).keys());
  minutes = Array.from(Array(60).keys());
  buttonType = ButtonTypes;

  constructor() {
    super();
  }

  ngOnInit(): void {}

  returnDate(date: Date): void {
    let datePickerResult: Date | string = date;
    if (this.useTimePicker) {
      datePickerResult = moment(date)
        .hour(Number(this.selectedHour))
        .minute(Number(this.selectedMinute))
        .format('YYYY-MM-DD hh:mm:ss A');
    }
    this.result = datePickerResult.toString();
    this.close();
  }

  setHour(hour: number): void {
    this.selectedHour = hour.toString();
  }

  setMinutes(minute: number): void {
    this.selectedMinute = minute.toString();
  }
}
