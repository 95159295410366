<div class="module-group">
    <h4>{{module_group[0].module_group}}</h4>


    <div *ngIf="showAddButton" class="add-module">
        <app-basic-button 
            [title]="'Add Module'" 
            [type]="buttonTypes.Secondary" 
            [active]="false"
            (click)="emitChangeModule(null, module_group, null, null)">
        </app-basic-button>
    </div>

    <ng-template ngFor let-index="index" let-module [ngForOf]="module_group">
        <div 
            class="module-wrap" 
            *ngIf="showModule(module.module_id)">
            <app-basic-button 
                [disabled]="index === 0" 
                [title]="'<'" 
                [type]="buttonTypes.Primary" 
                [active]="false"
                (click)="emitChangeModule(module, module_group, index, 'left')">
            </app-basic-button>
            <div class="module-card">
                <div class="heading-wrap">
                    <p class="module_name">{{module.module_name | uppercase}}</p>

                </div>
                <ul>
                    <li *ngIf="module.unit_price === 0"><small> - Base Module</small></li>
                    <li *ngIf="module.unit_price > 0"><small> - Includes {{module.included_units}} Units</small></li>
                    <li *ngIf="module.unit_price > 0"><small> - Additional Units @ {{module.unit_price | currency: 'R'}}
                            each.
                        </small></li>
                </ul>
            </div>
            <app-basic-button 
                [disabled]="index === (module_group.length - 1)" 
                [title]="'>'" 
                [type]="buttonTypes.Primary"
                [active]="false" 
                (click)="emitChangeModule(module, module_group, index, 'right')">
            </app-basic-button>
        </div>

    </ng-template>

</div>