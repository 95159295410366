import { Component, OnInit, ViewChild } from '@angular/core';
import { IconTypes } from '../icon/icon.interfaces';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { JustifyContent } from '../flex-container/flex-container.interfaces';
import { environment } from 'environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { RequestDemoModalComponent } from '../request-demo-modal/request-demo-modal.component';

@Component({
  selector: 'app-static-header',
  templateUrl: './static-header.component.html',
  styleUrls: ['./static-header.component.scss'],
})
export class StaticHeaderComponent implements OnInit {
  public justifyContent = JustifyContent;
  public IconTypes = IconTypes;
  public UiColors = UiColors;
  context = environment.envName;
  path: string;
  validwaybill = false;
  searchResult: any;
  searchText: string;
  processing = false;
  placeholderText = 'Waybill Number';
  showMobileNav = false;
  @ViewChild("solutionsMenu") solutions;
  @ViewChild("industriesMenu") industries;


  constructor(private router: Router, private activatedRoute: ActivatedRoute, private simpleModalService: SimpleModalService) {
    router.events.subscribe(() => { });
  }


  toggleMenu(menu) {
    switch (menu) {
      case 'solutionsMenu':
        this.toggleMenuState(this.solutions.nativeElement)
        break;
      case 'industriesMenu':
        this.toggleMenuState(this.industries.nativeElement)
        break;
    }
  }

  toggleMenuState(element) {
    // Close all menu's first
    this.solutions.nativeElement.className = 'mobile-child-menu';
    this.industries.nativeElement.className = 'mobile-child-menu';

    if (element.className != 'active') {
      element.className = 'active';
    } else {
      element.className = 'mobile-child-menu';
    }
  }


  searchOrders(): void {

  }

  ngOnInit(): void { }

  trackOrder(): void {
    let wayBillValue = (<HTMLInputElement>document.getElementById("waybill")).value;
    if (wayBillValue) {
      let trackcontainer = document.querySelector('.tracking').className = 'block';
    } else {
      alert('The input is not filled')
    }
  }

  close(): void {
    document.querySelector('.block').className = 'tracking';
  }

  signUp(): void {
    this.simpleModalService.addModal(RequestDemoModalComponent, null);
  }
}
