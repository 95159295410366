<app-section>
  <div class="overall" *ngIf="WaypointVisits">
    <app-flex-container
      [theme]="UiThemes.Dark"
      [padding]="'2rem'"
      [borderedItems]="true"
      [justifyContent]="JustifyContent.SpaceBetween"
      [borderBottom]="true"
    >
      <app-icon-with-text [text]="'WAYPOINT ROUTE'" [textBold]="true" [sizeRem]="1" [color]="UiColors.White">
      </app-icon-with-text>
      <app-icon-with-text
        [icon]="IconTypes.Trip"
        [iconColor]="UiColors.Blue"
        [iconSizeRem]="1"
        [iconSpacingRem]="1"
        [text]="
          RouteStartedAt
            ? 'Started: ' + (RouteStartedAt.toMillis() | date: 'medium')
            : RouteStartedAt
            ? (RouteStartedAt.toMillis() | date: 'medium')
            : ('Assigned' | uppercase)
        "
        [sizeRem]="1"
        [color]="UiColors.White"
      ></app-icon-with-text>
      <app-icon-with-text
        [icon]="IconTypes.Trip"
        [iconColor]="UiColors.Blue"
        [iconSizeRem]="1"
        [iconSpacingRem]="1"
        [text]="
          RouteEndedAt
            ? 'Ended: ' + (RouteEndedAt.toMillis() | date: 'medium')
            : RouteEndedAt
            ? (RouteEndedAt.toMillis() | date: 'medium')
            : ('In Progress' | uppercase)
        "
        [sizeRem]="1"
        [color]="UiColors.White"
      ></app-icon-with-text>
      <div class="controls">
        <app-basic-button
          *ngIf="!RouteEndedAt && (user.is_admin || fleetAllocation === 'Contractor') && !isHistoricRoute"
          [processing]="reconciling"
          [title]="'Reconcile Route'"
          [type]="buttonTypes.Primary"
          [active]="false"
          (click)="reconcileRoute()"
        >
        </app-basic-button>
        <app-basic-button
          *ngIf="isHistoricRoute"
          [processing]="reconciling"
          [title]="'Toggle Map'"
          [type]="buttonTypes.Highlight"
          [active]="false"
          (click)="toggleMap()"
        >
        </app-basic-button>
        <app-basic-button
          *ngIf="!RouteEndedAt && !isHistoricRoute"
          [processing]="endingRoute"
          [title]="'Optimise Route'"
          [type]="buttonTypes.Warning"
          [active]="false"
          (click)="endRoute()"
        >
        </app-basic-button>
        <app-basic-button
          *ngIf="RouteEndedAt && !isHistoricRoute"
          [processing]="endingRoute"
          [title]="'Toggle Map'"
          [type]="buttonTypes.Primary"
          [active]="false"
          (click)="toggleMap()"
        >
        </app-basic-button>
      </div>
    </app-flex-container>
    <div *ngIf="!showMap">
      <div class="summary">
        <app-grid-container
          [columns]="'repeat(4, 1fr)'"
          [padding]="'0rem'"
          [colGap]="'2rem'"
          [rowGap]="'0rem'"
          [theme]="UiThemes.HighlightedSummary"
        >
          <app-stats-icon
            [icon]="IconTypes.Parcel"
            [dataSizeRem]="1.5"
            [iconColor]="UiColors.Blue"
            [iconSizeRem]="2"
            [label]="'Packages'"
            [loading]="false"
            [data]="parcelStateBreakdown.total"
            [textColour]="UiColors.White"
          >
          </app-stats-icon>

          <app-stats-icon
            [icon]="IconTypes.Parcel"
            [dataSizeRem]="1.5"
            [iconColor]="UiColors.Blue"
            [iconSizeRem]="2"
            [label]="'Collected'"
            [loading]="false"
            [data]="parcelStateBreakdown.collected"
            [textColour]="UiColors.White"
          >
          </app-stats-icon>

          <app-stats-icon
            [icon]="IconTypes.Parcel"
            [dataSizeRem]="1.5"
            [iconColor]="UiColors.Green"
            [iconSizeRem]="2"
            [label]="'Delivered'"
            [loading]="false"
            [data]="parcelStateBreakdown.delivered"
            [textColour]="UiColors.White"
          >
          </app-stats-icon>

          <app-stats-icon
            [icon]="IconTypes.Parcel"
            [dataSizeRem]="1.5"
            [iconColor]="UiColors.Red"
            [iconSizeRem]="2"
            [label]="'Failed Delivery'"
            [loading]="false"
            [data]="parcelStateBreakdown.failed"
            [textColour]="UiColors.White"
          >
          </app-stats-icon>
        </app-grid-container>
      </div>
      <app-last-mile-fs-waypoint
        *ngFor="let waypoint of WaypointVisits"
        [Parcels]="Parcels"
        [waypoint]="waypoint"
      >
      </app-last-mile-fs-waypoint>
    </div>
    <div *ngIf="showMap" class="map-wrap">
      <app-map-view
        [mapType]="mapTypes.Tracking"
        [markers]="markers"
        [geoJsonDataLayers]="[driverTravelData]"
        [polylines]="[lastMilePolyline]"
      ></app-map-view>
    </div>
  </div>
</app-section>
