import { Component, OnInit } from '@angular/core';
import { UiThemes, UiColors } from 'app/interfaces/ui.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ManageBusinessService } from '../manage-business.service';
import { environment } from '../../../../environments/environment';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';

@Component({
  selector: 'app-set-enterprise',
  templateUrl: './set-enterprise.component.html',
  styleUrls: ['./set-enterprise.component.scss'],
})
export class SetEnterpriseComponent extends SimpleModalComponent<any, any> implements OnInit {
  public UiThemes = UiThemes;
  Icons = {
    Colors: UiColors,
    Types: IconTypes,
  };
  password;
  displayError;
  billing_package;
  payment_terms;
  buttonTypes = ButtonTypes;

  packages = ['basic', 'enterprise', 'tms', 'freight', 'freight-shipper'];
  terms = ['prepaid', 'postpaid'];

  constructor(public manageBusinessService: ManageBusinessService) {
    super();
  }

  onChangePackage(): void {
    if (this.password === environment.changePackagePassword) {
      this.manageBusinessService.changePackage(this.billing_package, this.payment_terms).then(() => this.close());
    } else {
      this.displayError = true;
    }
  }

  cancel(): void {
    this.close();
  }

  ngOnInit(): void {}
}
