import { Component, OnInit } from '@angular/core';
import { AlertsService } from './alerts.service';
import { UiThemes, UiColors } from 'app/interfaces/ui.interfaces';
import { IconTypes } from '../icon/icon.interfaces';
import { MapTypes } from '../map/map.interfaces';
import { Store } from '@ngrx/store';
import { selectorActingAs } from 'app/auth/auth.reducer';
import { ButtonTypes } from '../buttons/basic-button.component';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
})
export class AlertsComponent implements OnInit {
  public UiThemes = UiThemes;
  public IconTypes = IconTypes;
  public UiColors = UiColors;
  public MapTypes = MapTypes;
  buttonTypes = ButtonTypes;

  vehicle_alerts = [];
  vehicles = [];
  vehicle_ids;

  showMap = false;

  constructor(public alertsService: AlertsService, public store: Store<any>) {
    this.store.select(selectorActingAs).subscribe((next) => {
      if (next.vehicle_registration_numbers) {
        this.vehicle_ids = next.vehicle_registration_numbers;
        this.getAlerts();
      }
    });
  }

  ngOnInit() {}

  toggleMap() {
    this.showMap = !this.showMap;
  }

  refresh() {
    this.getAlerts();
  }

  getAlerts() {
    if (this.vehicle_ids) {
      this.alertsService.getCarTrackAlerts(this.vehicle_ids).then((response) => {
        if (response) {
          this.vehicle_alerts = response?.data.reverse();
        }
      });
    }
  }
}
