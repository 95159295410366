<app-section>
    <app-flex-container class="flex-right" [justifyContent]="justifyContent.SpaceBetween">
      <div class="tabs">
        <div *ngIf="accessLevelName === accessLevelNameEnum.dispatcher"
          class="tab" 
          (click)="toggleMode(PostDispatchViewEnum.Dispatch)" 
          [class.active]="modeFilter.getValue() === 'dispatch'">
          <h4>Dispatch</h4>
        </div>
        <div *ngIf="accessLevelName === accessLevelNameEnum.tracker"
          class="tab" 
          (click)="toggleMode(PostDispatchViewEnum.Tracking)" 
          [class.active]="modeFilter.getValue() === 'tracking'">
          <h4>Tracking</h4>
        </div>
        <div 
          *ngIf="accessLevelName === accessLevelNameEnum.legacy" 
          class="tab" 
          (click)="toggleMode(PostDispatchViewEnum.Active)" 
          [class.active]="modeFilter.getValue() === 'active'">
          <h4>Active Routes</h4>
        </div>
        <div *ngIf="accessLevelName === accessLevelNameEnum.legacy" 
          class="tab" 
          (click)="toggleMode(PostDispatchViewEnum.Pending)" 
          [class.active]="modeFilter.getValue() === 'pending'">
          <h4>Pending Routes</h4>
        </div>
        <div *ngIf="accessLevelName === accessLevelNameEnum.legacy" 
          class="tab" 
          (click)="toggleMode(PostDispatchViewEnum.Courier)" 
          [class.active]="modeFilter.getValue() === 'courier'">
          <h4>Active Courier Orders</h4>
        </div>
        <div *ngIf="accessLevelName === accessLevelNameEnum.legacy"  
          class="tab" 
          (click)="toggleMode(PostDispatchViewEnum.Order)" 
          [class.active]="modeFilter.getValue() === 'order'">
          <h4>Trips</h4>
        </div>
      </div>
  
      <div class="actions-wrap">
        <app-basic-button
        [title]="'Refresh'"
        [type]="buttonTypes.Primary"
        (click)="refreshData()"
        >
        </app-basic-button>

        <ng-container *ngIf="{value: showBuckets$ | async} as showBuckets">
          <ng-container *ngIf="showBuckets.value !== undefined && modeFilter.getValue() === 'order'">
          <app-dropdown
            [activeItem]="showBuckets ? showBuckets.value : 'Scheduled Filter'"
            (click)="clearSearch()"
          >
            <div dropdownRef>
              <a
                *ngFor="let item of scheduledDropDownItems"
                class="dropdown-item"
                (click)="scheduledSelected(item)"
                >{{ item }}</a
              >
            </div>
          </app-dropdown>
  
          </ng-container>
        </ng-container>
        <app-dropdown
        *ngIf="modeFilter.getValue() === 'courier'"
          [activeItem]="courierFilter?.courier_name ? courierFilter?.courier_name : 'Select a Courier'"
        >
          <div dropdownRef>
            <a class="dropdown-item" (click)="setCourierFilter(null)">{{ 'All Couriers' }} </a>
            <a
              *ngFor="let item of courierCodes"
              class="dropdown-item"
              (click)="setCourierFilter(item)"
              >{{ item.courier_name }}</a
            >
          </div>
        </app-dropdown>
        <app-dropdown
          [activeItem]="activeWarehouseFilter.getValue() ? activeWarehouseFilter.getValue().name : 'All warehouses'"
          (click)="clearSearch()"
        >
          <input-field
            [type]="'text'"
            [placeholder]="'Search...'"
            [value]="searchString"
            [autoComplete]="'off'"
            (valueChanged)="searchString = $event"
          ></input-field>
          <a class="dropdown-item" (click)="setWarehouseFilter(null)">All warehouses</a>
          <div dropdownRef>
            <a
              *ngFor="let warehouse of available_warehouses | stringFilterPipe: searchString:['name']"
              class="dropdown-item"
              (click)="setWarehouseFilter(warehouse)"
              >{{ warehouse.name }}</a
            >
          </div>
        </app-dropdown>
        <app-dropdown
          *ngIf="!courierView"
          [activeItem]="fleetFilter.getValue() ? (fleetFilter.getValue() | titlecase) : 'Fleet'"
        >
          <div dropdownRef>
            <a class="dropdown-item" (click)="setFleetFilter(null)">{{ 'All' }}</a>
            <a class="dropdown-item" (click)="setFleetFilter('All Picup')">{{ 'All Picup' }}</a>
            <a class="dropdown-item" (click)="setFleetFilter('PicupPlus')">{{ 'Picup Plus' }}</a>
            <a class="dropdown-item" (click)="setFleetFilter('Picup')">{{ 'Picup' }}</a>
            <a class="dropdown-item" (click)="setFleetFilter('Contractor')">{{ 'Contract' }}</a>
            <a class="dropdown-item" (click)="setFleetFilter('Courier')">{{ 'Courier' }}</a>
          </div>
        </app-dropdown>
      </div>
    </app-flex-container>
  </app-section>
  
  <app-section *ngIf="postDispatchSummaryData">
    <app-v2-post-dispatch-summary
      [warehouseParam]="
        this.warehouseFilterId.getValue() ? [this.warehouseFilterId.getValue()] : this.available_warehouse_ids
      "
      [postDispatchSummaryData]="postDispatchSummaryData"
      [viewMode]="modeFilter.getValue()"
    >
    </app-v2-post-dispatch-summary>
  </app-section>
  
  <app-post-dispatch-today
    *ngIf="modeFilter.getValue() === 'active'"
    [todaySummaryData]="todaySummaryData"
    (dateChangedEvent)="overrideDate($event)"
  ></app-post-dispatch-today>

  <app-collapsible-order-table
  *ngIf="modeFilter.getValue() === 'active' && activeRoutes | async as activeRoutes"
  [tripType]="'Active Drivers'"
  [trips]="activeRoutes"
  [tripsTotal]="activeRoutesTotal"
  [objectType]="CollapsibleTableTypeEnum.LastMile"
  [expanded]="true"
  [icon]="Icons.Types.Trip"
  [color]="Icons.Colors.Blue"
  [tableDataMap]="fsActiveLastMileTableMap"
  [tableColumns]="fsActiveLastMileTableColumns"
  [primaryActions]="activeDriverPrimaryActions"
  [activeWarehouseFilter]="activeWarehouseFilter"
  [defaultDisplayAmount]="activeRoutesAmount"
  (paginateEvent)="handleActiveLastMiles($event)"
  (tableSortChanged)="handleLastMileSortChange($event, true)"
  >
  </app-collapsible-order-table>

  <app-collapsible-order-table
  *ngIf="modeFilter.getValue() === 'pending' && pendingRoutes | async as pendingRoutes"
  [tripType]="'Pending Drivers'"
  [trips]="pendingRoutes"
  [tripsTotal]="pendingRoutesTotal"
  [expanded]="true"
  [objectType]="CollapsibleTableTypeEnum.LastMile"
  [icon]="Icons.Types.Trip"
  [color]="Icons.Colors.Blue"
  [tableDataMap]="fsActiveLastMileTableMap"
  [tableColumns]="fsActiveLastMileTableColumns"
  [primaryActions]="activeDriverPrimaryActions"
  [activeWarehouseFilter]="activeWarehouseFilter"
  [defaultDisplayAmount]="pendingRoutesAmount"
  (paginateEvent)="handlePendingLastMiles($event)"
  (tableSortChanged)="handleLastMileSortChange($event, false)"
  >
  </app-collapsible-order-table>

  <!-- Alert Orders -->
  <app-collapsible-order-table
  *ngIf="modeFilter.getValue() === 'order' && alertFirestoreTrips | async as alertTrips"
  [tripType]="'Alert Trips'"
  [trips]="alertTrips"
  [tripsTotal]="alertTotal"
  [objectType]="CollapsibleTableTypeEnum.Order"
  [icon]="Icons.Types.Trip"
  [color]="Icons.Colors.Red"
  [tableDataMap]="fsAlertOrderTableDataMap"
  [tableColumns]="fsAlertOrderTableColumns"
  [primaryActions]="postDispatchTablePrimaryActions"
  [activeWarehouseFilter]="activeWarehouseFilter"
  [defaultDisplayAmount]="alertAmount"
  [showPagination]="true"
  (paginateEvent)="handleAlertTrips($event)"
  (tableSortChanged)="handleTripsSortChange($event, OrderFrontEndStateEnum.Alert)"
  >
  </app-collapsible-order-table>

<!-- All failed final collections -->
<app-collapsible-order-table
*ngIf="modeFilter.getValue() === 'order' && failedFinalAlertFirestoreTrips | async as failedFinalAlertTrips"
[tripType]="'Failed Final Collections'"
[trips]="failedFinalAlertTrips"
[tripsTotal]="failedFinalAlertTotal"
[objectType]="CollapsibleTableTypeEnum.Order"
[icon]="Icons.Types.Trip"
[color]="Icons.Colors.Red"
[tableDataMap]="failedFinalAlertsDataMap"
[tableColumns]="failedFinalAlertsColumns"
[primaryActions]="postDispatchTablePrimaryActions"
[defaultDisplayAmount]="failedFinalAlertAmount"
[showPagination]="true"
[isAdmin]="true"
(paginateEvent)="handleFailedFinalAlertTrips($event)"
(tableSortChanged)="handleTripsSortChange($event, OrderFrontEndStateEnum.CompletedAllFailedFinalCollection)"
>
</app-collapsible-order-table>

  <!-- Manual Assignment Orders -->
  <app-collapsible-order-table
  *ngIf="modeFilter.getValue() === 'order' && manualAssignmentFirstoreTrips | async as manualAssignmentTrips"
  [tripType]="'Manual Assignment Trips'"
  [trips]="manualAssignmentTrips"
  [tripsTotal]="manualAssignmentTotal"
  [objectType]="CollapsibleTableTypeEnum.Order"
  [icon]="Icons.Types.Trip"
  [color]="Icons.Colors.Yellow"
  [tableDataMap]="fsOrderTableDataMap"
  [tableColumns]="fsOrderTableColumns"
  [primaryActions]="postDispatchTablePrimaryActions"
  [activeWarehouseFilter]="activeWarehouseFilter"
  [defaultDisplayAmount]="manualAssignmentAmount"
  [showPagination]="true"
  (paginateEvent)="handleManualAssignmentTrips($event)"
  (tableSortChanged)="handleTripsSortChange($event, OrderFrontEndStateEnum.WaitingForManualAssignment)"
  >
  </app-collapsible-order-table>

  <!--Lottery Running Orders -->
  <app-collapsible-order-table
  *ngIf="modeFilter.getValue() === 'order' && lotteryRunningFirestoreTrips | async as lotteryRunningTrips"
  [tripType]="'Lottery Trips'"
  [trips]="lotteryRunningTrips"
  [tripsTotal]="lotteryRunningTotal"
  [objectType]="CollapsibleTableTypeEnum.Order"
  [icon]="Icons.Types.Trip"
  [color]="Icons.Colors.Green"
  [tableDataMap]="fsOrderTableDataMap"
  [tableColumns]="fsOrderTableColumns"
  [primaryActions]="postDispatchTablePrimaryActions"
  [activeWarehouseFilter]="activeWarehouseFilter"
  [defaultDisplayAmount]="lotteryRunningAmount"
  [showPagination]="true"
  (paginateEvent)="handleLotteryRunningTrips($event)"
  (tableSortChanged)="handleTripsSortChange($event, OrderFrontEndStateEnum.LotteryRunning)"
  >
  </app-collapsible-order-table>

  <!-- In Progress Orders -->
  <app-collapsible-order-table
  *ngIf="modeFilter.getValue() === 'order' && inProgressFirestoreTrips | async as inProgressTrips"
  [tripType]="'In Progress Trips'"
  [trips]="inProgressTrips"
  [tripsTotal]="inProgressTotal"
  [objectType]="CollapsibleTableTypeEnum.Order"
  [icon]="Icons.Types.Trip"
  [color]="Icons.Colors.Blue"
  [tableDataMap]="fsOrderTableDataMap"
  [tableColumns]="fsOrderTableColumns"
  [tableDataMap]="fsActiveOrderTableDataMap"
  [tableColumns]="fsActiveOrderColumns"
  [primaryActions]="postDispatchTablePrimaryActions"
  [activeWarehouseFilter]="activeWarehouseFilter"
  [defaultDisplayAmount]="inProgressAmount"
  [showPagination]="true"
  (paginateEvent)="handleInProgressTrips($event)"
  (tableSortChanged)="handleTripsSortChange($event, OrderFrontEndStateEnum.InProgress)"
  >
  </app-collapsible-order-table>

  <!-- Staged Assignments Orders -->
  <app-collapsible-order-table
  *ngIf="modeFilter.getValue() === 'order' && stagedAssignmentsFirestoreTrips | async as stagedAssignments"
  [tripType]="'Staged Assignments'"
  [trips]="stagedAssignments"
  [tripsTotal]="stagedAssignmentTotal"
  [objectType]="CollapsibleTableTypeEnum.Order"
  [icon]="Icons.Types.Trip"
  [color]="Icons.Colors.Blue"
  [tableDataMap]="fsActiveOrderTableDataMap"
  [tableColumns]="fsActiveOrderColumns"
  [primaryActions]="postDispatchTablePrimaryActions"
  [defaultDisplayAmount]="stagedAssignmentAmount"
  [showPagination]="true"
  (paginateEvent)="handleStagedAssignmentsTrips($event)"
  (tableSortChanged)="handleTripsSortChange($event, OrderFrontEndStateEnum.InProgress)"
  >
  </app-collapsible-order-table>
  

  <!-- Scheduled Orders -->
  <app-collapsible-order-table
  *ngIf="modeFilter.getValue() === 'order' && scheduledFirestoreTrips | async as scheduledTrips"
  [tripType]="'Scheduled Trips'"
  [trips]="scheduledTrips"
  [tripsTotal]="scheduledTotal"
  [objectType]="CollapsibleTableTypeEnum.Order"
  [icon]="Icons.Types.Calendar"
  [color]="Icons.Colors.Blue"
  [tableDataMap]="fsOrderTableDataMap"
  [tableColumns]="fsOrderTableColumns"
  [primaryActions]="postDispatchTablePrimaryActions"
  [activeWarehouseFilter]="activeWarehouseFilter"
  [defaultDisplayAmount]="scheduledAmount"
  [showPagination]="true"
  (paginateEvent)="handleScheduledTrips($event)"
  (tableSortChanged)="handleTripsSortChange($event, OrderFrontEndStateEnum.Scheduled)"
  >
  </app-collapsible-order-table>

  <!-- Completed Orders -->
  <app-collapsible-order-table
  *ngIf="modeFilter.getValue() === 'order' && completedFirestoreTrips | async as completedTrips"
  [tripType]="'Completed Trips'"
  [trips]="completedTrips"
  [tripsTotal]="completedTotal"
  [objectType]="CollapsibleTableTypeEnum.Order"
  [icon]="Icons.Types.Check"
  [color]="Icons.Colors.Green"
  [tableDataMap]="fsActiveOrderTableDataMap"
  [tableColumns]="fsActiveOrderColumns"
  [primaryActions]="postDispatchTablePrimaryActions"
  [activeWarehouseFilter]="activeWarehouseFilter"
  [defaultDisplayAmount]="completedAmount"
  [showPagination]="true"
  (paginateEvent)="handleCompletedTrips($event)"
  (tableSortChanged)="handleTripsSortChange($event, OrderFrontEndStateEnum.Completed)"
  >
  </app-collapsible-order-table>

  <app-collapsible-order-table
  *ngIf="modeFilter.getValue() === 'courier' && courierOrders | async as courierOrders"
  [tripType]="'Courier Orders'"
  [trips]="courierOrders"
  [tripsTotal]="courierOrderTotal"
  [objectType]="CollapsibleTableTypeEnum.CourierOrder"
  [expanded]="true"
  [icon]="Icons.Types.Trip"
  [color]="Icons.Colors.Green"
  [tableDataMap]="fsActiveCourierOrdersTableMap"
  [tableColumns]="fsActiveCourierOrdersTableColumns"
  [primaryActions]="activeDriverPrimaryActions"
  [activeWarehouseFilter]="activeWarehouseFilter"
  [defaultDisplayAmount]="courierOrdersAmount"
  [bulkActions]="courierOrderBulkActions"
  [showPagination]="true"
  (paginateEvent)="handleCourierOrders($event)"
  (tableSortChanged)="handleCourierOrderSortChange($event)"
  (reconcileEvent)="reconcileCourierOrder($event)"
  >
  </app-collapsible-order-table>

  <app-post-dispatch-tracking 
    *ngIf="modeFilter.getValue() === 'tracking'"
    #tracking>
  </app-post-dispatch-tracking>

  <app-post-dispatch-dispatch 
    *ngIf="modeFilter.getValue() === 'dispatch'"
    #dispatch>
  </app-post-dispatch-dispatch>
  
