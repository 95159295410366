import { Component, OnInit, Input } from '@angular/core';
import { Route, Waypoint } from '../new-trips.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { MapTypes } from 'app/shared/map-view/map-view.interfaces';
import { JustifyContent } from 'app/shared/flex-container/flex-container.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';

@Component({
  selector: 'app-new-trips-routes',
  templateUrl: './new-trips-routes.component.html',
  styleUrls: ['./new-trips-routes.component.scss'],
})
export class NewTripsRoutesComponent implements OnInit {
  @Input() route: Route;
  @Input() waypoints: Waypoint[];
  @Input() index;
  @Input() parcels;
  @Input() waypoint_index;
  @Input() signatures;

  driver_waypoints = [];
  showMap = false;
  uiThemes = UiThemes;
  public icons = {
    types: IconTypes,
    colors: UiColors,
  };
  justifyContent = JustifyContent;
  buttonTypes = ButtonTypes;
  public waypointOpenStates: boolean[] = [];
  UiThemes = UiThemes;
  mapTypes = MapTypes;
  constructor() {}

  ngOnInit(): void {
    this.driver_waypoints = this.route.waypoints.filter((waypoint) => {
      return waypoint?.waypoint_id !== -1;
    });
  }

  toggleMap() {
    this.showMap = !this.showMap;
  }

  ngAfterViewChecked(): void {
    if (this.waypoint_index) {
      this.waypointOpenStates[this.waypoint_index] = true;
      const openWaypoint = document.getElementById('waypoint-' + this.waypoint_index);
      if (openWaypoint) {
        openWaypoint.scrollIntoView({ behavior: 'smooth', block: 'start' });
        this.waypoint_index = null;
      }
    }
  }

  public saveOpenWaypoints(waypoint, $event) {
    this.waypointOpenStates[waypoint] = $event;
  }
}
