import { NotificationsService } from './../../shared/notifications/notifications.service';
import { Injectable } from '@angular/core';
import axios, { azureAxios } from 'app/api/axios';
import { AxiosResponse } from 'axios';
import { AngularFirestore } from '@angular/fire/firestore';
import { CourierCode, CourierOrder } from 'app/shared/shared.interfaces';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { CourierAlert } from 'app/admin/courier/courier-overview/features/courier-alerts/courier-alerts.interface';

@Injectable({
  providedIn: 'root',
})
export class CourierOrdersService {
  constructor(private notificationsService: NotificationsService, private firestore: AngularFirestore) {
    axios.defaults.headers.common['x-api-key'] = environment.courierIntegrationModule.apiKey;
  }

  getCourierOrderDetails(courier_order_id: string): Observable<CourierOrder> {
    const docRef = this.firestore.collection<CourierOrder>('courier-orders');
    return docRef.doc(courier_order_id).valueChanges();
  }

  overrideCourierOrderState(courierOrderIds: string[], stateEnum: string, reason: string): void {
    const data = {
      state: stateEnum,
      reason: reason,
      courier_order_ids: courierOrderIds,
    };
    axios({
      method: 'POST',
      url: '/courier-order/override-states',
      data: data,
    })
      .then(() => {
        this.notificationsService.publish({
          type: 'success',
          message: 'Courier Order State Overriden',
        });
      })
      .catch(() =>
        this.notificationsService.publish({
          type: 'error',
          message: 'Could not update courier order state. Please contact support.',
        })
      );
  }

  rehydrateAggregate(courierOrderId: string) {
    axios({
      method: 'POST',
      url: '/firestore/rehydrate-firestore-readmodel/' + courierOrderId,
    })
      .then((res) => {
        return res.data;
      })
      .catch(() =>
        this.notificationsService.publish({
          type: 'error',
          message: 'Error building order - Please contact support. (Error Code:RH-' + courierOrderId + ')',
        })
      );
  }

  recreateCourierOrder(courierOrderId: string): void {
    axios({
      method: 'POST',
      url: '/courier-order/' + courierOrderId + '/create-waybill',
    })
      .then(() => {
        this.notificationsService.publish({
          type: 'success',
          message: 'Successfully Reattempted Courier Order Creation',
        });
      })
      .catch((error) => {
        this.notificationsService.publish({
          type: 'error',
          message: error.response.data.message,
        });
      });
  }

  notify(successful: boolean, message: string): void {
    const notificationType = successful ? 'success' : 'error';
    this.notificationsService.publish({
      type: notificationType,
      message: message,
    });
  }

  async getOrderTrackingEvents(order_id: string): Promise<any | AxiosResponse<any>> {
    // Pass in the columns you want returned into the $select clause
    const azureClient = azureAxios(new Date().toUTCString());
    try {
      const response = await azureClient.get(`Webhooks()?$filter=PartitionKey%20eq%20'${order_id}'%20and%20IsPoisonQueue%20eq%20false&$select=ParcelWaybillNumber,Timestamp,EventDescription,EventArg1,EventArg2`);
      return response.data;
    } catch (error) {
      this.notify(false, error.response?.data.message);
      throw error;
    }
  }

  getCourierCodes(business_id?: string): Promise<CourierCode[] | void> {
    return axios({
      method: 'POST',
      url: '/generic/cqrs/get-courier-codes',
      data: business_id,
    })
      .then((response: AxiosResponse<CourierCode[]>) => {
        return response.data;
      })
      .catch((error) => {
        this.notificationsService.publish({
          type: 'error',
          message: error.response.data.message,
        });
      });
  }

  getCourierAlerts(): Promise<CourierAlert[] | void> {
    return axios({
      method: 'GET',
      baseURL: environment.courierIntegrationModule.baseUrl + '/alerts/',
    })
      .then((response: AxiosResponse<CourierAlert[]>) => {
        return response?.data;
      })
      .catch((error) => {
        this.notificationsService.publish({
          type: 'error',
          message: error.response?.data?.message,
        });
      });
  }
}
