<span class="icon-with-text" [ngClass]="classes" [ngStyle]="{'line-height.rem': (sizeRem * 1.25), 'padding.rem' : paddingRem}">
  <span *ngIf="iconOnRight" class="text" [ngStyle]="{'font-size.rem': sizeRem, fontWeight: textBold ? '800' : '400' }"> {{text}}</span>
  <span *ngIf="icon !== undefined" class="icon-wrap"
        [ngStyle]="
        {'margin-right.rem': !iconOnRight && (iconSpacingRem || (sizeRem * .5)),
        'margin-left.rem': iconOnRight && (iconSpacingRem || (sizeRem * .5))}">
    <app-icon
        [icon]="icon"
        [color]="iconColor"
        [size]="iconSizeRem"
    ></app-icon>
  </span>
  <span *ngIf="!iconOnRight" class="text" [ngStyle]="{'font-size.rem': sizeRem, fontWeight: textBold ? '800' : '400' }"> {{text}}</span>
</span>