<section>
    <app-full-page-split [theme]="UiThemes.Blue" [fullHeight]="false" class="center-block" [title]="title">
        <div class="cta-content">
            <p>{{text}}</p>
            <app-flex-container class="features" [flexWrap]="'wrap'" [padding]="'2rem 0rem'"
                [justifyContent]="justifyContent.Center">
                <ng-content></ng-content>
                <app-slider-button 
                    *ngIf="!hideDemoCTA" 
                    (click)="requestDemo();" 
                    [iconType]="IconTypes.Details"
                    [labelText]="'Request a Demo'">
                </app-slider-button>
            </app-flex-container>
        </div>
    </app-full-page-split>
</section>