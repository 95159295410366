import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-summary-line',
  templateUrl: './summary-line.component.html',
  styleUrls: ['./summary-line.component.scss']
})
export class SummaryLineComponent {
  @Input() loading: boolean;
}
