<div class="main" *ngIf="driverName">
  <div *ngIf="success">
    <h2 class="thank-you">Thank you!</h2><br>
    <!-- <img [src]="'/assets/img/static-site/solutions/picup-tms.png'" alt=""> -->

  </div>
  <div class="ng-marg25" *ngIf="!success">
    <app-full-page-split [theme]="UiThemes.Dark" [title]="'How was your delivery with ' + driverName"
      [subtitle]="'Have Your Say'" [fullHeight]="false">
    </app-full-page-split>
  </div>

  <form>
    <div class="rating">
      <input id="star5" name="star" type="radio" value="5" class="radio-btn hide" />
      <label for="star5" (click)="rating = currentRatingState.Excellent">☆</label>
      <input id="star4" name="star" type="radio" value="4" class="radio-btn hide" />
      <label for="star4" (click)="rating = currentRatingState.Good">☆</label>
      <input id="star3" name="star" type="radio" value="3" class="radio-btn hide" />
      <label for="star3" (click)="rating = currentRatingState.Neutral">☆</label>
      <input id="star2" name="star" type="radio" value="2" class="radio-btn hide" />
      <label for="star2" (click)="rating = currentRatingState.Poor">☆</label>
      <input id="star1" name="star" type="radio" value="1" class="radio-btn hide" />
      <label for="star1" (click)="rating = currentRatingState.Bad">☆</label>
      <div class="clear"></div>
    </div>
    <br />

    <div class="rating-state">
      <ul [ngSwitch]="rating">
        <li *ngSwitchCase="currentRatingState.Excellent">Excellent</li>
        <li *ngSwitchCase="currentRatingState.Good">Good</li>
        <li *ngSwitchCase="currentRatingState.Neutral">Neutral</li>
        <li *ngSwitchCase="currentRatingState.Poor">Poor</li>
        <li *ngSwitchCase="currentRatingState.Bad">Bad</li>
        <li *ngSwitchDefault></li>
      </ul>
    </div>
    <small class="explainer">Click on a star to add your rating</small>
    <app-grid-container [columns]="'repeat(1, 1fr)'" [padding]="'4rem 2rem'" [colGap]="'1rem'" [rowGap]="'1rem'">
      <input-field [type]="'text'" [labelTheme]="'grey'" (valueChanged)="reviewText = $event" [autoComplete]="'off'"
        [value]="reviewText"></input-field>
    </app-grid-container>
    <app-slider-button (click)="rateDriver()" [iconType]="IconTypes.Details" [labelText]="'Rate'"> </app-slider-button>

  </form>
</div>