<section *ngIf="!isLoading && shownData?.length > 0" class="table-wrap">
  <table class="table" [ngClass]="theme" cellspacing="0">
    <thead *ngIf="!hideColHeaders">
      <tr>
        <th *ngIf="displayRowHeaders"></th>
        <th *ngIf="rowsSelectable">
          <input type="checkbox" (change)="toggleAll($event)" [checked]="isAllChecked()" />
        </th>
        <ng-container *ngFor="let col of columns; trackBy: trackByFn">
          <th
            *ngIf="!col.hidden"
            [ngClass]="{
              'sortable-col': col.sortable,
              'sorted-col': sortStatus && sortStatus[col.name]?.status !== undefined
            }"
          >
            {{ col.displayName }}
            <span *ngIf="col.sortable" class="sort-buttons-wrap">
              <app-icon-button
                [icon]="IconTypes.Up"
                [iconSizeRem]="sortStatus[col.name]?.status === _SortDirection.ASC ? 1.1 : 0.8"
                [color]="UiColors.Grey5"
                [active]="sortStatus[col.name]?.status === _SortDirection.ASC"
                [colorActive]="UiColors.Blue"
                (onClick)="handleSort(col, _SortDirection.ASC)"
              ></app-icon-button>
              <app-icon-button
                [icon]="IconTypes.Down"
                [iconSizeRem]="sortStatus[col.name]?.status === _SortDirection.DES ? 1.1 : 0.8"
                [color]="UiColors.Grey5"
                [active]="sortStatus[col.name]?.status === _SortDirection.DES"
                [colorActive]="UiColors.Blue"
                (onClick)="handleSort(col, _SortDirection.DES)"
              ></app-icon-button>
            </span>
          </th>
        </ng-container>
        <th *ngIf="showPrimaryActions || showSecondaryActions"></th>
      </tr>
      <tr *ngIf="showBulkActions && shouldShowBulkActions()" class="table-head-actions">
        <th
          [attr.colspan]="
            visibleColumnsCount + (rowsSelectable ? 1 : 0) + (showPrimaryActions || showSecondaryActions ? 1 : 0)
          "
        >
          <button 
            *ngFor="let action of bulkActions; trackBy: trackByFn" 
            (click)="takeAction(action, null)"
            [disabled]="readOnly">
            <app-icon 
              [icon]="action.icon" 
              [size]="1.2" 
              [color]="action.icon_color"
              >
            </app-icon>
            {{ action.title }}
          </button>
        </th>
      </tr>
    </thead>
    <ng-container *ngIf="shownData.length > 0">
      <tbody
        *ngIf="showTableSummaryTop"
        app-table-summary
        [showTotals]="showTableTotals"
        [showAverages]="showTableAverages"
        [showRowHeader]="rowsSelectable"
        [showRowActions]="showPrimaryActions || showSecondaryActions"
        [summaryTitle]="'Table Summary'"
        [summaryData]="tableSummaryData"
        [columns]="columns"
        [hasSelection]="hasSelectedRows"
        [isTableSummary]="true"
        [visibleColumnsCount]="visibleColumnsCount"
      ></tbody>
      <tbody
        *ngIf="hasSelectedRows && showSelectionSummaryTop"
        app-table-summary
        [showTotals]="showSelectionTotals"
        [showAverages]="showSelectionAverages"
        [showRowHeader]="rowsSelectable"
        [showRowActions]="showPrimaryActions || showSecondaryActions"
        [summaryTitle]="'Selection Summary'"
        [summaryData]="selectionSummaryData"
        [columns]="columns"
        [visibleColumnsCount]="visibleColumnsCount"
      ></tbody>
    </ng-container>
    <tbody>
      <tr *ngFor="let row of shownData; trackBy: trackByFn">
        <th *ngIf="displayRowHeaders">{{ row.heading }}</th>
        <td *ngIf="rowsSelectable">
          <ng-container *ngIf="row.isSelectable === undefined || overrideRowIsSelectable || row.isSelectable">
            <input type="checkbox" [(ngModel)]="row.selected" (change)="handleRowSelect()" />
          </ng-container>
        </td>
        <ng-container *ngFor="let cell of row?.cells; trackBy: trackByFn">
          <td
            *ngIf="!_hideCell(cell.column, columns)"
            [class.highlight]="cell.highlighted && !cell.highlightColorClass"
            [ngClass]="cell.highlightColorClass"
            [ngStyle]="cell.color && { color: cell.color }"
          >
            <app-icon *ngIf="cell.icon" [icon]="cell.icon" [color]="cell.iconColor" [size]="cell.iconSizeRem">
            </app-icon>
            <!-- {{ cell.icon && '&nbsp;' }} -->
            {{
              cell.value !== undefined ? formatCell(cell.value, cell.displayPipeArgs, cell.prefix, cell.postfix) : ''
            }}
            <p
              *ngIf="showAnnotations"
              [ngStyle]="cell.annotation_color && { color: cell.annotation_color }"
              class="annotation"
            >
              {{
                cell.annotation !== undefined
                  ? formatCellAnnotation(cell.annotation, cell.displayAnnotationPipeArgs)
                  : ''
              }}
            </p>
          </td>
        </ng-container>
        <td class="table-actions" *ngIf="showPrimaryActions || showSecondaryActions">
          <div [style.visibility]="getSelectedRows().length > 0 && !stickyActions ? 'hidden' : 'visible'">
            <div>
              <ng-container *ngIf="showPrimaryActions">
                <!-- Don't know the conciquence of changing the bello to a button -->
                <button
                  *ngFor="let action of primaryActions; trackBy: trackByFn"
                  [title]="action.title"
                  (click)="takeAction(action, row)"
                  [disabled]="readOnly"
                >
                  <app-icon
                    *ngIf="
                      (action.dependency === undefined || checkActionsDependency(action.dependency, row)) &&
                      (action.reverse_dependency === undefined ||
                        checkActionsReverseDependency(action.reverse_dependency, row)) &&
                      (action.valuesArray === undefined ||
                        action.field === undefined ||
                        checkForFieldMatchesOnActions(action.field, row, action.valuesArray)) &&
                      !action.hidden
                    "
                    class="table-icon"
                    [icon]="action.icon"
                    [size]="1.2"
                    [color]="action.icon_color"
                  ></app-icon>
                </button>
              </ng-container>
              <app-reveal-more *ngIf="showSecondaryActions">
                <a
                  *ngFor="let action of secondaryActions; trackBy: trackByFn"
                  [title]="action.title"
                  (click)="takeAction(action, row)"
                >
                  <app-icon-button
                    [icon]="action.icon"
                    [iconSizeRem]="1.2"
                    [color]="action.icon_color"
                    (onClick)="takeAction(action, row)"
                    [disabled]="readOnly"
                  ></app-icon-button>
                  {{ action.title }}
                </a>
              </app-reveal-more>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
    <ng-container *ngIf="shownData.length > 0">
      <tbody
        *ngIf="hasSelectedRows && showSelectionSummaryBottom"
        app-table-summary
        [showTotals]="showSelectionTotals"
        [showAverages]="showSelectionAverages"
        [showRowHeader]="rowsSelectable"
        [showRowActions]="showPrimaryActions || showSecondaryActions"
        [summaryTitle]="'Selection Summary'"
        [summaryData]="selectionSummaryData"
        [columns]="columns"
        [visibleColumnsCount]="visibleColumnsCount"
      ></tbody>
      <tbody
        *ngIf="showTableSummaryBottom"
        app-table-summary
        [showTotals]="showTableTotals"
        [showAverages]="showTableAverages"
        [showRowHeader]="rowsSelectable"
        [showRowActions]="showPrimaryActions || showSecondaryActions"
        [summaryTitle]="'Table Summary'"
        [summaryData]="tableSummaryData"
        [columns]="columns"
        [hasSelection]="hasSelectedRows"
        [isTableSummary]="true"
        [visibleColumnsCount]="visibleColumnsCount"
      ></tbody>
    </ng-container>
  </table>
  <div *ngIf="showViewMore && !showViewAll">
    <button (click)="requestMoreData()" class="table-view-more">View More</button>
  </div>
  <div *ngIf="showViewAll" class="view-all">
    <button (click)="requestMoreData()" class="table-view-more">View More</button>
    <button (click)="requestViewAll()" class="table-view-more">View All</button>
  </div>
</section>
<div *ngIf="showPagination && shownData?.length > 0" class="pagination">
  <app-dropdown
    [activeItem]="defaultDisplayAmount.toString()">
    <div dropdownRef>
      <a
        *ngFor="let amount of paginationDisplayAmounts"
        class="dropdown-item"
        (click)="setDisplayAmount(amount)"
        >{{ amount }}
      </a>
    </div>
  </app-dropdown>
  <div *ngIf="displayPageCount" class="page-amount-display">
    {{currentPageIndex}} / {{totalPages}}
  </div>
  <div>
    <app-icon
      [color]="UiColors.White"
      [size]="2.3"
      [icon]="IconTypes.EndArrow"
      class="arrow-icon"
      (click)="paginateData(PaginateEnum.Backward)"
    ></app-icon>
    <app-icon
      [color]="UiColors.White"
      [size]="2.3"
      [icon]="IconTypes.StartArrow"
      class="arrow-icon"
      (click)="paginateData(PaginateEnum.Forward)"
    ></app-icon>
  </div>
</div>
<app-loader *ngIf="isLoading" [loadingMessage]="'Loading table data'"></app-loader>

<div *ngIf="zeroDataMessage && !isLoading && shownData?.length === 0" class="no-data-container">
  <h3 class="no-data-message">{{ zeroDataMessage }}</h3>
</div>
