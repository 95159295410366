import { Component, OnInit, ViewChild } from '@angular/core';
import { SharedService } from '../../shared/shared.service';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { trigger, transition, animate, style } from '@angular/animations';
import { UploadOrdersService } from './upload-orders.service';
import { SimpleModalService } from 'ngx-simple-modal';

@Component({
  selector: 'app-upload-orders',
  templateUrl: './upload-orders.component.html',
  styleUrls: ['./upload-orders.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(200%)' }),
        animate('800ms ease-in', style({ transform: 'translateY(0%)' })),
      ]),
      transition(':leave', [animate('1000ms ease-in', style({ transform: 'translateY(-100%)' }))]),
    ]),
  ],
})
export class UploadOrdersComponent implements OnInit {
  @ViewChild('file') file;
  fileImporting;

  constructor(
    public sharedService: SharedService,
    public uploadOrdersService: UploadOrdersService,
    public simpleModalService: SimpleModalService,
    public router: Router,
    public store: Store<any>
  ) {}

  ngOnInit() {}
}
