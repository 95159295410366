import { QueryDocumentSnapshot } from '@angular/fire/firestore';
import { CourierOrder, LastMile, Order } from 'app/shared/shared.interfaces';
import { TableRow } from '../../shared/table/table.interfaces';

export interface PostDispatchPicups {
  // scheduled: TableRow[];
  cancelled: TableRow[];
  complete: TableRow[];
  hasFetched: boolean;
}

export enum PD_Col_InProg {
  PicupId = 'picupId',
  TripId = 'tripId',
  WhenAcceptedByCustomer = 'whenAcceptedByCustomer',
  ActivatedTimeStamp = 'activatedTimeStamp',
  LastEvent = 'lastEvent',
  LastEventDetails = 'lastEventDetails',
  NextEvent = 'nextEvent',
  UserOrBusiness = 'userOrBusiness',
  CustomerRefNum = 'customerRefNum',
  Consignment = 'consignment',
  WarehouseName = 'warehouseName',
  WarehouseId = 'warehouseId',
  OwnerName = 'ownerName',
  DriverName = 'driverName',
  Waypoints = 'waypoints',
  PicupDetails = 'picup_details',
  Progress = 'progress',
  TripType = 'tripType',
}

export enum PD_Col_Sched {
  PicupId = 'picupId',
  TripId = 'tripId',
  ScheduledTimeStamp = 'scheduledTimeStamp',
  Scheduled = 'Scheduled',
  LastEvent = 'lastEvent',
  LastEventDetails = 'lastEventDetails',
  UserOrBusiness = 'userOrBusiness',
  CustomerRefNum = 'customerRefNum',
  Consignment = 'consignment',
  WarehouseName = 'warehouseName',
  WarehouseId = 'warehouseId',
  OwnerName = 'ownerName',
  DriverName = 'driverName',
  Waypoints = 'waypoints',
}

export interface OrderPaginationSnapshot {
  First: QueryDocumentSnapshot<Order>;
  Last: QueryDocumentSnapshot<Order>;
}

export interface LastMilePaginationSnapshot {
  First: QueryDocumentSnapshot<LastMile>;
  Last: QueryDocumentSnapshot<LastMile>;
}

export interface CourierOrderPaginationSnapshot {
  First: QueryDocumentSnapshot<CourierOrder>;
  Last: QueryDocumentSnapshot<CourierOrder>;
}

export enum OrderFrontEndStateEnum {
  Scheduled = 'Scheduled',
  Completed = 'Completed',
  InProgress = 'InProgress',
  WaitingForManualAssignment = 'WaitingForManualAssignment',
  LotteryRunning = 'LotteryRunning',
  Alert = 'Alert',
  CompletedAllFailedFinalCollection = 'CompletedAllFailedFinalCollection',
  StagedAssignment = 'StagedAssignment'
}

export enum LastMileFrontEndState {
  Active = 'active',
  Pending = 'pending'
}

export interface PostDispatchTotals {
  error_number: number;
  output: PostDispatchTotal[];
}

export interface PostDispatchTotal {
  State: string;
  Cnt: number;
}
