import { Component, Input, OnInit } from '@angular/core';
import { UiThemes } from 'app/interfaces/ui.interfaces';
import { JustifyContent } from '../flex-container/flex-container.interfaces';
import { IconTypes } from '../icon/icon.interfaces';

@Component({
  selector: 'app-static-overview-banner',
  templateUrl: './static-overview-banner.component.html',
  styleUrls: ['./static-overview-banner.component.scss']
})
export class StaticOverviewBannerComponent implements OnInit {
  UiThemes = UiThemes;
  IconTypes = IconTypes;
  JustifyContent = JustifyContent;

  @Input()theme;
  @Input()text;

  constructor() { }

  ngOnInit(): void {}

}
