import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lotteryCountColour'
})
export class LotteryCountColourPipe implements PipeTransform {

  transform(value: number): string {
    switch (true) {
      case value < 6: return 'good';
      case value <= 10: return 'warning';
      case value > 10: return 'bad';
    }
  }

}
