<app-section>
  <heading [icon]="Icons.Types.Mapping" [color]="Icons.Colors.Blue">
    <h4 class="heading-title">
      <strong>Status shit</strong>
    </h4>
  </heading>

  <div class="summary-table gradient-bg">
    <div class="row">
      <div class="summary-block">
        <h2>First stat</h2>
      </div>
      <div class="summary-block">
          <app-grid-container [columns]="'repeat(1, 1fr)'" [theme]="UiThemes.Dark" [padding]="'0rem'" [colGap]="'0rem'"
          [rowGap]="'0rem'">
          <div class="gauge-holder">
              <h6>Alerts</h6>
              <app-gauge [data]="data"></app-gauge>
          </div>
          
        </app-grid-container>
          
        </div>
      <div class="summary-block">
          <h2>Third stat</h2>
      </div>
      <div class="summary-block">
          <h2>First stat</h2>
        </div>
        <div class="summary-block">
            <h2>Second stat</h2>
          </div>
        <div class="summary-block">
            <h2>Third stat</h2>
        </div>
    </div>
  </div>
</app-section>