<app-modal
  [open]="true"
  [actions]="actions"
  [highlightPrimaryAction]="true"
  [size]="ModalSizes.Small"
  (handleAction)="handleAction($event)"
>
  <heading [icon]="IconTypes.Alert" [color]="UiColors.Orange">
    <h4 class="heading-title">{{ title }}</h4>
    <p *ngIf="description" class="heading-description">{{ description }}</p>
  </heading>

  <div class="modal-body">
    <p class="description">{{ message }}</p>
  </div>
</app-modal>
