import {iconBaseType, iconBaseUrl} from './icon.constants';

export const getIconUrl = ( icon: string ) => {
  let url: string = icon;
  if (icon.indexOf(iconBaseUrl) === -1) {
    url = iconBaseUrl + url;
  }
  if (icon.indexOf('.') === -1) {
    url = url + '.' + iconBaseType;
  }
  return url;
};
