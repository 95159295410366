import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationsService } from 'app/shared/notifications/notifications.service';
import { OperationsTrackingService } from 'app/operations-tracking/operations-tracking.service';
import { OrderFrontEndStateEnum } from 'app/dashboard/post-dispatch/post-dispatch.interfaces';
import { OrderStateEnum, OrderSummary } from 'app/operations-tracking/operations-tracking.interface';
import { OrderTrackingComponent } from '../order-tracking/order-tracking.component';
import { NewTripsService } from 'app/dashboard/new-trips/new-trips.service';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-lottery',
  templateUrl: './lottery.component.html',
  styleUrls: ['./lottery.component.scss'],
})
export class LotteryComponent extends OrderTrackingComponent implements OnInit, OnDestroy {
  lotterySubscription: Subscription;
  dataSource = new MatTableDataSource<OrderSummary>([]);
  OrderStateEnum = OrderStateEnum;
  constructor(
    public operationsTrackingService: OperationsTrackingService,
    public notificationsService: NotificationsService,
    public newTripsService: NewTripsService,
    public store: Store
  ) {
    super(notificationsService, operationsTrackingService, store, newTripsService);
  }

  ngOnInit(): void {
    this.operationsTrackingService.usedIds$.pipe(takeUntil(this.unsubscribe$)).subscribe((ids: string[]) => {
      this.selectedIds = ids;
      this.getLotteryOrders();
    });
  }

  ngOnDestroy(): void {
    this.lotterySubscription?.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getLotteryOrders(): void {
    const batches = this.getFirestoreBatchesForOrders(OrderFrontEndStateEnum.LotteryRunning)
    this.lotterySubscription?.unsubscribe();
    this.lotterySubscription = combineLatest(batches).subscribe((ordersSummary: OrderSummary[][]) => {
      let orders: OrderSummary[] = [];
      orders = orders.concat(...ordersSummary);
      this.dataSource.data = orders;
    });
  }
}
