<div *ngIf="picupDetails">
  <app-flex-container [justifyContent]="justifyContent.SpaceBetween">
    <div class="tabs">
      <!-- <div
      class="tab"
      (click)="activeTab = 0"
      [ngClass]="{
        active: activeTab === 0,
        valid: picupDetails.sender.is_valid,
        'not-valid':
          !picupDetails.sender.is_valid ||
          !this.picupDetails?.customer_ref ||
          this.picupDetails?.customer_ref?.length > 25
      }"
    >
      <h4>Tasks</h4>
    </div> -->
      <div
        class="tab"
        (click)="activeTab = 1"
        [ngClass]="{
          active: activeTab === 1,
          valid: picupDetails.sender.is_valid,
          'not-valid':
            !picupDetails.sender.is_valid ||
            !this.picupDetails?.customer_ref ||
            this.picupDetails?.customer_ref?.length > 25
        }"
      >
        <h4>Collection</h4>
      </div>
      <ng-template ngFor let-tab let-index="index" [ngForOf]="picupDetails.receivers">
        <div class="tab" (click)="changeTab(index)" [ngClass]="{ active: activeTab === index + 2 }">
          <h4>
            Delivery {{ index + 1
            }}<svg-icon
              *ngIf="picupDetails.receivers.length > 1"
              src="/assets/img/icons/cross.svg"
              (click)="removeReceiver(index)"
              class="icon"
            ></svg-icon>
          </h4>
        </div>
      </ng-template>
      <div class="tab add-point" (click)="addReceiver()">
        <h4>Add Another Delivery Point</h4>
      </div>
    </div>
    <div class="optimize">
      <ng-container *ngIf="picupDetails?.receivers?.length > 1">
        Optimize Waypoints:
        <input
          type="checkbox"
          (change)="picupDetails.optimize_waypoints = !picupDetails.optimize_waypoints"
          [checked]="picupDetails.optimize_waypoints"
        />
      </ng-container>
      <app-basic-button 
        class="reset" 
        [title]="'Reset'" 
        [type]="ButtonTypes.Warning" 
        (click)="clearForm()"></app-basic-button>
    </div>
  </app-flex-container>
  <app-grid-container
    *ngIf="activeTab === 1"
    [theme]="UiThemes.Light"
    [columns]="'repeat(1, 1fr)'"
    [padding]="'2rem'"
    [colGap]="'1rem'"
    [rowGap]="'1rem'"
  >
    <app-grid-container 
      [columns]="'repeat(2, 1fr)'" 
      [padding]="'1rem 0rem'" 
      [colGap]="'2rem'" 
      [rowGap]="'2rem'">
      <input-field
        [type]="'text'"
        (valueChanged)="picupDetails.customer_ref = $event"
        [autoComplete]="'off'"
        [isInvalid]="displayInvalid && (!picupDetails.customer_ref || picupDetails.customer_ref?.length > 25)"
        [value]="picupDetails.customer_ref"
        [label]="'Customer Reference'"
        [required]="true"
      ></input-field>

      <app-grid-container
        class="tripSettings"
        [columns]="'repeat(4, 1fr)'"
        [padding]="'0rem 0rem'"
        [colGap]="'2rem'"
        [rowGap]="'2rem'"
      >
        <div>
          <label>Collection Time</label>
          <app-basic-button
            *ngIf="!picupDetails.scheduled_date"
            [title]="'Immediately'"
            [type]="ButtonTypes.Primary"
            (click)="toggleSchedule()"
          >
          </app-basic-button>
          <input-field
            *ngIf="picupDetails.scheduled_date"
            [type]="'text'"
            [autoComplete]="'off'"
            [svgActionIcon]="picupDetails.scheduled_date ? IconTypes.Cross + '.svg' : null"
            (click)="toggleSchedule()"
            (valueChanged)="(picupDetails.scheduled_date ? prettyDateTime : 'Activate Immediately')"
            [value]="picupDetails.scheduled_date ? prettyDateTime : 'Activate Immediately'"
          ></input-field>
        </div>
        <div *ngIf="actingAs.package === 'tms'">
          <label>Fleet</label>
          <app-basic-button
            [title]="picupDetails.fleet_allocation"
            [type]="ButtonTypes.Primary"
            (click)="changeAllocation()"
          >
          </app-basic-button>
        </div>
        <div>
          <label>Vehicle Type</label>
          <app-basic-button
            [title]="picupDetails.vehicle_id ? vehicleNiceName : 'Auto'"
            [type]="ButtonTypes.Primary"
            (click)="changeVehicleType()"
          >
          </app-basic-button>
        </div>
        <div>
          <label>Allocation:</label>
          <app-basic-button
            [title]="picupDetails.should_run_lottery ? 'Lottery' : 'Manual'"
            [type]="ButtonTypes.Primary"
            (click)="picupDetails.should_run_lottery = !picupDetails.should_run_lottery"
          >
          </app-basic-button>
        </div>
      </app-grid-container>
    </app-grid-container>

    <app-grid-container
      class="waypoints"
      [columns]="'repeat(1, 1fr)'"
      [padding]="'1rem 0rem'"
      [colGap]="'2rem'"
      [rowGap]="'2rem'"
      [theme]="UiThemes.Light"
    >
      <app-waypoint
        [packageType]="actingAs.package"
        [displayInvalid]="displayInvalid"
        [isCollection]="true"
        [waypoint]="picupDetails.sender"
      >
      </app-waypoint>
    </app-grid-container>
  </app-grid-container>

  <ng-template ngFor let-waypoint [ngForOf]="picupDetails.receivers" let-index="index">
    <app-grid-container
      *ngIf="activeTab === index + 2"
      [theme]="UiThemes.Light"
      [columns]="'repeat(1, 1fr)'"
      [padding]="'2rem'"
      [colGap]="'1rem'"
      [rowGap]="'1rem'"
    >
      <app-waypoint
        [packageType]="actingAs.package"
        [displayInvalid]="displayInvalid"
        [waypoint]="waypoint"
      ></app-waypoint>
    </app-grid-container>
  </ng-template>

  <app-flex-container [theme]="UiThemes.Light" [padding]="'2rem'" [justifyContent]="justifyContent.Center" class="cta" *ngIf="activeTab >= 1">
    <div class="error" *ngIf="error">
      <ul *ngFor="let vError of error">
        <ul *ngFor="let vvError of vError.errors">
          <li>
            <h4>{{ vvError }}</h4>
          </li>
        </ul>
      </ul>
    </div>
  </app-flex-container>

  <app-flex-container [theme]="UiThemes.Light" [padding]="'2rem'" [justifyContent]="justifyContent.FlexEnd" class="cta"
  *ngIf="activeTab >= 1">
    <div>
      <app-basic-button
        [title]="activeTab === 1 ? 'Next' : 'Add Delivery Point'"
        [type]="activeTab === 1 ? ButtonTypes.Highlight : ButtonTypes.Primary"
        (click)="addReceiver()"
      >
      </app-basic-button>
      <app-basic-button
        *ngIf="actingAs.package !== 'freight' && activeTab > 1"
        [title]="'Get Quote'"
        [type]="ButtonTypes.Highlight"
        [active]="true"
        [processing]="processing"
        (click)="getQuote()"
      >
      </app-basic-button>

      <app-basic-button
        *ngIf="actingAs.package === 'freight' && environment.appVersion === 1"
        [title]="'Create Trip'"
        [type]="ButtonTypes.Highlight"
        [active]="true"
        [processing]="processing"
        (click)="createFreightTrip()"
        [disabled]="activeTab === 1"
      >
      </app-basic-button>
    </div>
  </app-flex-container>
</div>
