import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {UiThemes} from '../../interfaces/ui.interfaces';
import {SpacingProperties} from '../flex-child/flex-child.interfaces';
import {buildSpacingString} from '../flex-child/flex-child.helpers';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss']
})
export class SectionComponent implements OnInit, OnChanges {
  @Input() theme: UiThemes;
  @Input() padding: string | SpacingProperties;
  @Input() margin: string | SpacingProperties = '2rem 0';

  public classes: string[];
  public styleProps = {};

  ngOnInit() {
    this.classes = this.setClasses();
    this.styleProps = this.createStyleProps();
  }

  ngOnChanges() {
    this.classes = this.setClasses();
    this.styleProps = this.createStyleProps();
  }

  setClasses() {
    const classes: string[] = [];
    if (this.theme) {
      classes.push(this.theme);
    }
    return classes;
  }

  createStyleProps() {
    const _props = {};

    if (this.margin !== undefined) {
      if (typeof this.margin === 'string') {
        _props['margin'] = this.margin;
      } else {
        _props['margin'] = buildSpacingString(this.margin);
      }
    }

    if (this.padding !== undefined) {
      if (typeof this.padding === 'string') {
        _props['padding'] = this.padding;
      } else {
        _props['padding'] = buildSpacingString(this.padding);
      }
    }
    return _props;
  }
}
