import { Component, Inject, OnInit, OnDestroy, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { SettingsService } from 'app/dashboard/settings/settings.service';
import {
  Driver,
  DriverDetails,
  DriverFilterTypeEnum,
  DriverSearchResponse,
  GetDriversResponse,
  WarehouseDetails,
  OrderStats,
} from './assign-driver-modal.interface';
import { AssignDriverService } from './assign-driver.service';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { DriverState, ManageFleetService } from 'app/dashboard/manage-fleet/manage-fleet.service';
import { AssignedOrder } from 'app/dashboard/new-trips/new-trips.interfaces';
import { AssignedOrdersData } from 'app/dashboard/new-trips/driver-load/assign-driverload/assign-driver.interface';
import { MatPaginator } from '@angular/material/paginator';
import { FormControl } from '@angular/forms';
import { IconTypes } from '../icon/icon.interfaces';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { darkMapStyle } from '../map/map.constants';
import { LastMile, LastMileParcelStateEnum } from '../shared.interfaces';
import { LastEventMapping } from 'app/operations-tracking/pipes/last-event-colour.pipe';
import { ActingAs } from 'app/interfaces/auth.interfaces';
import { Store } from '@ngrx/store';
import { selectorActingAs } from 'app/auth/auth.reducer';
import { takeUntil } from 'rxjs/operators';
import { NotificationsService } from '../notifications/notifications.service';
import { AutoNotesService } from 'app/dashboard/notes/auto-notes.service';

export interface AssignDriverData {
  WarehouseId?: string;
  BusinessId: string;
  IsPicupEmployee: boolean;
  SelectedDriverId?: string;
}

interface FireStoreSearchResult {
  id: string;
  active_last_mile_id: string;
  active_route_id: string;
  lat: number;
  lng: number;
  fleet: number;
  vehicle: string;
  is_online: boolean;
  assigned_orders: AssignedOrder[];
}

@Component({
  selector: 'app-assign-driver-modal',
  templateUrl: './assign-driver-modal.component.html',
  styleUrls: ['./assign-driver-modal.component.scss'],
})
export class AssignDriverModalComponent implements OnInit, OnDestroy, AfterViewInit {
  driverFilterTypes: string[] = [];
  selectedFilter: string = DriverFilterTypeEnum.PicupPlus;
  restrictToWarehouse: boolean = true;
  dataSource = new MatTableDataSource<Driver>([]);
  searchText: string = '';
  latitude: number = 0;
  longitude: number = 0;
  showMap = false;
  lastEventMappingEnum = LastEventMapping;
  displayedColumns: string[] = [
    'Status',
    'Name',
    'Vehicle',
    'Last Mile Status',
    'KM from WH',
    'Contact',
    'Parcels',
    'Assigned Trips',
    'Businesses',
    'Fleet',
    'Actions',
  ];
  isMapDefaultSet = false;
  markers: google.maps.Marker[] = [];
  @ViewChild('map', { static: false }) gMap: any;
  public mapElement: google.maps.Map;
  loading: boolean = false;
  subscriptions: Subscription[] = [];
  disabledRestrict: boolean = false;
  driverData: Driver[] = [];
  searchFormControl: FormControl = new FormControl('');
  iconTypesEnum = IconTypes;
  uiColorsEnum = UiColors;
  actingAs: ActingAs = undefined;
  unsubscribe$ = new Subject<void>();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  mapOptions: google.maps.MapOptions = {
    styles: darkMapStyle,
    zoomControl: false,
    scrollwheel: true,
    streetViewControl: false,
    disableDefaultUI: true
  };

  constructor(
    private dialogRef: MatDialogRef<AssignDriverModalComponent, {driverId: string, driverName: string}>,
    @Inject(MAT_DIALOG_DATA) public data: AssignDriverData,
    private assignDriverService: AssignDriverService,
    private settingsService: SettingsService,
    private fleetService: ManageFleetService,
    private store: Store,
    private cdr: ChangeDetectorRef,
    private notificationService: NotificationsService
  ) {
    this.setupFilters(this.data.IsPicupEmployee);
    if (!this.data.WarehouseId) {
      this.disabledRestrict = true;
      this.restrictToWarehouse = false;
    }
  }

  ngOnInit(): void {

    this.store.select(selectorActingAs).pipe(takeUntil(this.unsubscribe$)).subscribe((next) => {
      this.actingAs = next;
    });
    this.getWarehouse(this.data.WarehouseId);
    if (!this.data.WarehouseId) {
      const index = this.displayedColumns.findIndex((str) => str === 'KM from WH');
      this.displayedColumns.splice(index, 1);
    }
    if (this.data.IsPicupEmployee) {
      this.getPicupPlusDrivers();
    } else {
      this.getContractors();
    }
  }

  toggleMap(): void{
    this.showMap = !this.showMap;
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    this.handleSubscriptions();
  }

  public mapElementReady(): void {
    if (this.gMap && !this.isMapDefaultSet) {
      this.isMapDefaultSet = true;
      this.mapElement = this.gMap.googleMap;
      if (this.latitude) {
        this.mapElement.setCenter({ lat: this.latitude, lng: this.longitude });
        this.addWarehouseMarker();
      }
      if(this.driverData){
        this.setupDriverMarkers(this.driverData);
      }
    }
  }

  setupFilters(isPicup: boolean): void {
    if (isPicup) {
      this.selectedFilter = DriverFilterTypeEnum.PicupPlus;
      this.driverFilterTypes = [
        DriverFilterTypeEnum.PicupPlus,
        DriverFilterTypeEnum.Contractor,
        DriverFilterTypeEnum.InTransit,
      ];
      return;
    }
    this.selectedFilter = DriverFilterTypeEnum.Contractor;
    this.driverFilterTypes = [DriverFilterTypeEnum.Contractor];
  }

  defaultSort(): number {
    return 0;
  }

  search(value: string): void {
    if (value?.length >= 3) {
      this.loading = true;
      this.isMapDefaultSet = false;
      this.selectedFilter = DriverFilterTypeEnum.None;
      this.dataSource.data = [];
      this.handleSubscriptions();
      this.assignDriverService
        .searchDrivers(
          value,
          this.data.WarehouseId,
          this.data.BusinessId,
          this.data.IsPicupEmployee,
          this.restrictToWarehouse
        )
        .then((res: DriverSearchResponse[]) => {
          const driverIds = res.map((x) => x._id);
          this.buildDriverTableData(driverIds);
        });
    }
  }

  getWarehouse(warehouseId: string): void {
    if (!warehouseId) {
      return;
    }
    this.assignDriverService.getWarehouse(warehouseId).then((res: WarehouseDetails) => {
      this.latitude = res.latitude;
      this.longitude = res.longitude;
    });
  }

  getContractors(): void {
    this.loading = true;
    this.dataSource.data = [];
    this.handleSubscriptions();
    if (!this.restrictToWarehouse) {
      this.fleetService.getBusinessDrivers(this.data.BusinessId).then((response: Driver[]) => {
        const driverIds = response.map((x) => x.id);
        this.buildDriverTableData(driverIds);
      });
      return;
    }
    this.assignDriverService.getWarehouseDrivers(this.data.WarehouseId).then((response: Driver[]) => {
      const driverIds = response.map((x) => x.id);
      this.buildDriverTableData(driverIds);
    });
  }

  getPicupPlusDrivers(): void {
    this.loading = true;
    this.dataSource.data = [];
    this.handleSubscriptions();
    this.settingsService
      .getPicupPlusDrivers(this.data.BusinessId, this.restrictToWarehouse ? this.data.WarehouseId : null)
      .then((response: GetDriversResponse[]) => {
        const driverIds = response.map((x) => x.id);
        this.buildDriverTableData(driverIds);
      });
  }

  // 2D array of batches of 10
  getIdBatches(data: string[]): string[][] {
    const ids = [...data];
    const batches: string[][] = [];
    while (ids?.length) {
      const driverIdBatch = ids.splice(0, 10);
      batches.push(driverIdBatch);
    }
    return batches;
  }

  getIntransitDrivers(): void {
    if (!this.data.WarehouseId) {
      return;
    }
    this.loading = true;
    this.dataSource.data = [];
    this.handleSubscriptions();
    this.restrictToWarehouse = true;
    this.assignDriverService.getInTransitDrivers(this.data.WarehouseId).then((response) => {
      const driverIds = response.docs.map((x) => x.data().DriverId);
      this.buildDriverTableData(driverIds);
    });
  }

  async buildDriverTableData(driverIds: string[]): Promise<void> {
    this.driverData = [];
    const batches = this.getIdBatches(driverIds);
    try {
      const batchResults = await Promise.all(batches.map(async (batch) => {
        const [driversState, driversAssignedOrders, driversDetails] = await forkJoin([
          this.fleetService.getDriversState(batch),
          this.fleetService.getDriversAssignedOrders(batch),
          this.fleetService.getDriversDetails(batch)
        ]).toPromise();

        const lastMileIds = driversAssignedOrders.docs.map((doc) => doc.data().LastMileId);
        const lastMiles = await this.fleetService.getLastMilesById(lastMileIds).toPromise();

        return batch.map(driverId => {
          const driverStateDoc = driversState.docs.find((doc) => doc.id === driverId)?.data();
          const driverAssignedOrderDoc = driversAssignedOrders.docs.find((doc) => doc.id === driverId)?.data();
          const driverDetails = driversDetails.docs.find((doc) => doc.id === driverId)?.data();
          const lastMileDetails = lastMiles.docs.find((doc) => doc.data().DriverId === driverId)?.data();

          if (!driverDetails || !driverDetails?.name || !driverDetails?.phone) {
            return null;
          }

          const assignedOrdersData = this.getAssignedOrdersData(driverAssignedOrderDoc?.AssignedOrders);
          const businessNames = this.getBusinessFromAssignedOrders(driverAssignedOrderDoc?.AssignedOrders);
          const orderStats = this.getCollectedParcels(lastMileDetails);

          return this.buildDriverData(driverId, driverDetails, driverStateDoc, assignedOrdersData, businessNames, lastMileDetails, orderStats);
        });
      }));

      this.driverData = batchResults.flat().filter(driver => driver !== null);

      if (this.mapElement) {
        this.setupDriverMarkers(this.driverData);
      }

      this.driverData.sort((a, b) => +b.online - +a.online);
      this.dataSource.data = this.driverData;
      this.loading = false;

      this.cdr.detectChanges();
      this.dataSource.paginator = this.paginator;

    } catch (error) {
      console.error('Error processing batches:', error);
      // Handle the error as needed (e.g., show a notification to the user)
      this.notificationService.publish({message: 'Failed to fetch driver data, please refresh and try again or contact support'});
      this.loading = false;
    }
  }

  buildDriverData(
    id: string,
    driverDetails: DriverDetails,
    driverStateDoc: DriverState,
    assignedOrdersData: AssignedOrdersData,
    businessNames: string[],
    lastMileDetails: LastMile,
    orderStats: OrderStats
  ): Driver {
    const driver: Driver = {
      id: id,
      is_activated: null,
      name: driverDetails?.name,
      phone: driverDetails?.phone,
      vehicle: driverStateDoc?.active_vehicle ?? '',
      todaysOrders: driverStateDoc?.todays_orders?.length ?? 0,
      online: driverStateDoc?.online ?? false,
      onTrip: !!driverStateDoc?.active_route_id,
      businesses: businessNames,
      lastMileId: lastMileDetails?.Id,
      lastMileLastEvent: lastMileDetails?.LastEventName,
      businessesOnLastMile: lastMileDetails?.BusinessIds,
      roundTripWarehouse: {
        warehouseId: lastMileDetails?.RoundTripWarehouseId,
        warehouseName: lastMileDetails?.RoundTripWarehouseName
      },
      orderCount: assignedOrdersData?.OrderCount,
      parcelCount: assignedOrdersData?.ParcelCount,
      parcelsDelivered: orderStats?.parcelsDelivered,
      latitude: driverStateDoc?.location?.latitude,
      longitude: driverStateDoc?.location?.longitude,
      fleet: driverDetails?.fleets.toString().length > 1 ? driverDetails?.fleets.toString() : driverDetails?.acting_as === 0 ? 'Picup' : 'Contractor',
      distanceToWarehouse: this.data.WarehouseId
        ? this.getDistanceToWarehouse(
          driverStateDoc?.location?.latitude,
          driverStateDoc?.location?.longitude,
          this.latitude,
          this.longitude
        )?.toFixed(2) ?? '-'
        : '-',
    };
    return driver;
  }

  getAssignedOrdersData(assignedOrders: AssignedOrder[]): AssignedOrdersData {
    let orderCount: number = 0;
    let parcelCount: number = 0;
    const endOfDay = new Date().setUTCHours(23,59,59,999);
    assignedOrders?.forEach((order: AssignedOrder) => {
      if (order.CollectionDate.toDate() <= new Date(endOfDay)) {
        orderCount++;
        parcelCount += order.ParcelCount;
      }
    });
    return { OrderCount: orderCount, ParcelCount: parcelCount };
  }

  getDistanceToWarehouse(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const from: google.maps.LatLng = new google.maps.LatLng({ lat: lat1, lng: lon1 });
    const to: google.maps.LatLng = new google.maps.LatLng({ lat: lat2, lng: lon2 });
    const distanceInKm = google.maps.geometry.spherical.computeDistanceBetween(from, to) / 1000;
    return distanceInKm;
  }

  deg2rad(degrees: number): number {
    return degrees * (Math.PI / 180);
  }

  handleSubscriptions(): void {
    this.subscriptions.forEach((x) => {
      x?.unsubscribe();
    });
    this.subscriptions = [];
  }

  selectDriver(driver: Driver): void {
    if(this.disabledAssignButton(driver)){
      return;
    }
    this.dialogRef.close({driverId: driver.id, driverName: driver.name});
  }

  setFilter(filter: string): void {
    this.selectedFilter = filter;
    this.isMapDefaultSet = false;
    switch (filter) {
      case DriverFilterTypeEnum.PicupPlus: {
        this.getPicupPlusDrivers();
        break;
      }
      case DriverFilterTypeEnum.Contractor: {
        this.getContractors();
        break;
      }
      case DriverFilterTypeEnum.InTransit: {
        this.getIntransitDrivers();
        break;
      }
      case DriverFilterTypeEnum.None: {
        this.search(this.searchFormControl.value);
        break;
      }
    }
  }

  setupDriverMarkers(drivers): void {
    // create markers for each driver
    drivers.forEach((res: any) => {
      if (!res.online) {
        return;
      }
      const resMarker = new google.maps.Marker({
        position: { lat: res.latitude, lng: res.longitude },
        map: this.mapElement,
        icon: {
          url: this.getDriverIconURL(res),
          scaledSize: new google.maps.Size(50, 35),
          labelOrigin: new google.maps.Point(25, 45)
        },
        label: {
          text: res.name,
          className: 'marker-label',
          fontWeight: 'regular',
          fontSize: '12px',
          color:'#fff',
        },
        title: res.id,
      });
      // resMarker.addListener('click', () => {
      //   // this.markerClicked(res.id)
      //   alert('Not Implemented');
      // });
      this.markers.push(resMarker);
    });
  }

  addWarehouseMarker(): void{
    new google.maps.Marker({
      position: { lat: this.latitude, lng: this.longitude },
      map: this.mapElement,
      label: 'C',
      icon: {
        path:
          'M11.9,5.9C11.9,2.7,9.2,0,5.9,0S0,2.7,0,5.9c0,0.2,0,0.4,0,0.6C0.4,10,3.9,16.9,5.9,16.9 c1.8,0,5.5-6.8,5.9-10.3C11.9,6.4,11.9,6.2,11.9,5.9z',
        fillColor: '#27b7fc',
        fillOpacity: 1,
        strokeWeight: 0,
        scale: 2,
        labelOrigin: new google.maps.Point(6, 6),
        anchor: new google.maps.Point(6, 15),
      },
      title: 'Collection Hub',
    });
    if (this.mapElement) {
      this.mapElement.setCenter({ lat: this.latitude, lng: this.longitude });
    }
  }

  getDriverIconURL(driver: FireStoreSearchResult): string {
    if (driver.vehicle) {
      switch (driver.vehicle) {
        case 'vehicle-motorcycle':
          return '/assets/img/vehicle-motorcycle.svg';
        case 'vehicle-car':
          return '/assets/img/vehicle-car.svg';
        case 'vehicle-small-van':
          return '/assets/img/vehicle-small-van.svg';
        case 'vehicle-large-van':
          return '/assets/img/vehicle-large-van.svg';
      }
    }
  }

  openDriver(driverId: string): void {
    if (driverId) {
      window.open(
        `${window.location.origin}/${
          this.data.IsPicupEmployee ? 'dashboard/last-mile' : 'dashboard/manage-fleet/drivers'
        }/${driverId}`,
        '_blank'
      );
    }
  }

  getBusinessFromAssignedOrders(assignedOrders: AssignedOrder[]): string[] {
    const businessNames = [];
    if(assignedOrders?.length > 0) {
      assignedOrders.forEach(order => {
        if(businessNames.findIndex(name => name === order?.BusinssName) < 0){
          businessNames.push(order.BusinssName);
        }
      });
    }
    return businessNames;
  }

  getCollectedParcels(lastMile: LastMile): OrderStats{
    if(!lastMile){
      return {
        parcelsDelivered: 0
      }
    }

    const parcels = Object.keys(lastMile?.Parcels).map(x => ({
      key: x, value: lastMile.Parcels[x]
    }));

    let parcelsDelivered = 0;
    parcels.forEach(parcel => {
      if (parcel.value.LastMileParcelState === LastMileParcelStateEnum.Delivered) {
        parcelsDelivered++;
      } else if (parcel.value.LastMileParcelState === LastMileParcelStateEnum.Returned) {
        parcelsDelivered++;
      }
    });

    return {
      parcelsDelivered: parcelsDelivered
    }
  }

  disabledAssignButton(driver: Driver): boolean{
    return this.driverOnRoundTripForOtherWarehouse(driver) || this.driverAssignedToOtherBusiness(driver)
  }

  private driverAssignedToOtherBusiness(driver: Driver): boolean {
    return driver.businessesOnLastMile?.some(businessId => {
      return businessId !== this.actingAs.id
    })?? false;
  }

  private driverOnRoundTripForOtherWarehouse(driver: Driver): boolean {
    if(driver.roundTripWarehouse?.warehouseId && this.data?.WarehouseId){
      return (driver.roundTripWarehouse?.warehouseId !== this.data?.WarehouseId) ?? false;
    }
    return false;
  }

  disabledTooltip(driver: Driver): string {
    if(this.driverAssignedToOtherBusiness(driver)){
      return `Driver is on a trip for ${driver.businesses[0]}`
    }

    if(this.driverOnRoundTripForOtherWarehouse(driver)){
      return 'Driver is on a round trip for a different warehouse'
    }

    return 'Assign driver to trip'
  }
}
