<h3>Reports</h3>
<!-- <div class="tabs-container">
    <div class="tab" *ngFor="let tab of tabs" [routerLink]="tab.route">
      <div [class]="tab.name === activeTab ? 'tab-text-highlight' : 'tab-text'">
        <p [class]="tab.name === activeTab ? 'highlight' : ''">{{tab.name}}</p>
      </div> 
    </div>
</div> -->
<hr />
<div class="filters">
  <mat-form-field appearance="standard">
    <mat-label> Select date range </mat-label>
    <mat-date-range-input
      [min]="minDate"
      [formGroup]="rangePickerGroup"
      [rangePicker]="picker"
    >
      <input matStartDate formControlName="start" placeholder="Start date" readonly />
      <input matEndDate formControlName="end" placeholder="End date" readonly />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker [maxRange]="dateMaxRange" #picker ></mat-date-range-picker>
  </mat-form-field>
  <div (click)="initializeDateRangePicker()" class="reset-btn">
    <mat-icon>tune</mat-icon>
    <h5>RESET</h5>
  </div>
</div>
<div class="reports-container">
  <mat-accordion>
    <mat-expansion-panel [togglePosition]="'after'" *ngFor="let reportType of ReportTypeEnum | keyvalue">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ reportType.value }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="report-item-container">
        <app-report-item
          [isLoading]="isLoading"
          (reportSelected)="handleReport($event)"
          [isAdmin]="isAdminUser"
          [report]="report"
          *ngFor="let report of reports | reportsByType: reportType.value:isAdminUser"
        >
        </app-report-item>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<div *ngIf="isLoading" class="loading-container">
  <mat-spinner></mat-spinner>
  <h4>Loading report...</h4>
</div>
<!-- <router-outlet></router-outlet> -->
