<div class="map-wrap">
  <div class="loader" *ngIf="loading"></div>
  <div class="overlay" *ngIf="enableOverlay">
    <app-flex-container
      [justifyContent]="justifyContent.SpaceBetween"
      class="main-wrap"
      *ngIf="mapType !== MapTypes.OrderTracking && mapType !== MapTypes.DriverProfile"
    >
      <div>
        <app-basic-button
          [title]="followBounds ? 'Auto Resize' : 'Manual'"
          [type]="buttonTypes.Primary"
          (click)="toggleFollowBounds()"
        ></app-basic-button>
        <app-basic-button
          [title]="'Toggle traffic'"
          [type]="buttonTypes.Primary"
          (click)="toggleTrafficLayer(!showTrafficLayer)"
        ></app-basic-button>
      </div>

      <div>
        <form class="form-inline">
          <div class="form-group" *ngIf="waypoints?.length > 0">
            <input
              type="checkbox"
              class="filled-in"
              id="hideActiveWaypointMarkers"
              [checked]="!hideWaypointMarkers"
              (click)="toggleMapElementFilter('hideWaypointMarkers')"
            />
            <label for="hideWaypointMarkers">Waypoints</label>
          </div>
          <div class="form-group" *ngIf="markers?.length > 0">
            <input
              type="checkbox"
              class="filled-in"
              id="markers"
              [checked]="!hideMarkers"
              (click)="toggleMapElementFilter('hideMarkers')"
            />
            <label for="markers">Markers</label>
          </div>
          <div class="form-group" *ngIf="driverId">
            <input
              type="checkbox"
              class="filled-in"
              id="driverIds"
              [checked]="!hideDrivers"
              (click)="toggleMapElementFilter('hideDrivers')"
            />
            <label for="driverIds">Drivers</label>
          </div>
          <div class="form-group" *ngIf="driverIds?.length && enableShowDriverCheckBox">
            <input
              type="checkbox"
              class="filled-in"
              id="driverIds"
              [checked]="!hideDrivers"
              (click)="toggleMapElementFilter('hideDrivers')"
            />
            <label for="driverIds">Drivers</label>
          </div>
          <div class="form-group" *ngIf="polylines?.length > 0 && polylines[0]?.length">
            <input
              type="checkbox"
              class="filled-in"
              id="polylines"
              [checked]="!hidePolylines"
              (click)="toggleMapElementFilter('hidePolylines')"
            />
            <label for="polylines">Planned Route</label>
          </div>
          <div
            class="form-group"
            *ngIf="geoJsonDataLayers.length > 0 && mapType !== mapTypes.Trips && mapType !== mapTypes.Tracking"
          >
            <input
              type="checkbox"
              class="filled-in"
              id="geoJsonDataLayers"
              [checked]="!hideDataLayers"
              (click)="toggleMapElementFilter('hideDataLayers')"
            />
            <label for="geoJsonDataLayers">Planned Route</label>
          </div>
          <div class="form-group" *ngIf="geoJsonDataLayers.length > 0 && mapType === mapTypes.Tracking">
            <input
              type="checkbox"
              class="filled-in"
              id="geoJsonDataLayers"
              [checked]="!hideDataLayers"
              (click)="toggleMapElementFilter('hideDataLayers')"
            />
            <label for="geoJsonDataLayers">Driver Data</label>
          </div>
        </form>
      </div>
    </app-flex-container>
  </div>

  <!-- polylines
markers
waypointMarkers
driverIds
geoJsonDataLayers -->
  <google-map
    #map
    [height]="height"
    [width]="'100%'"
    [ngStyle]="mapStyle"
    [ngClass]="{ loading: loading }"
    class="map"
    id="map"
    [zoom]="14"
    (tilesloaded)="mapElementReady()"
    [options]="gMapOptions"
  >
    <div *ngIf="mapElement">
      <div *ngIf="!hideDrivers">
        <app-vehicle-tracking-map
          *ngIf="driverId"
          #VehicleTrackingMapComponent
          [mapElement]="mapElement"
          [driverId]="driverId"
          [driverIcon]="driverIcon"
        >
        </app-vehicle-tracking-map>
      </div>

      <div *ngIf="!hideDrivers">
        <app-vehicle-tracking-map
          *ngFor="let driverId of driverIds"
          #VehicleTrackingMapComponent
          [mapElement]="mapElement"
          [driverId]="driverId"
          [showOnline]="showOnline"
          (markerClick)="markerOutput($event)"
        >
        </app-vehicle-tracking-map>
      </div>

      <div *ngIf="!hidePolylines">
        <app-map-polyline
          *ngFor="let polyline of polylines"
          [mapElement]="mapElement"
          [polylineColorIn]="polylineColor"
          [polyline]="polyline"
          [polylineType]="polylineType"
        >
        </app-map-polyline>
      </div>

      <div *ngIf="!hideMarkers">
        <app-map-marker *ngFor="let marker of markers; trackBy: trackByMapMarker" [mapElement]="mapElement" [markerIn]="marker"> </app-map-marker>
      </div>

      <div *ngIf="!hideWaypointMarkers">
        <app-map-waypoint-marker
          *ngFor="let waypoint of waypoints; let index = index"
          [mapElement]="mapElement"
          [index]="index + 1"
          [waypoint]="waypoint"
        >
        </app-map-waypoint-marker>
      </div>

      <div *ngIf="!hideDataLayers && enableOverlay">
        <app-map-geojson-datalayer
          [mapType]="mapType"
          *ngFor="let dataLayer of geoJsonDataLayers"
          [mapElement]="mapElement"
          [dataLayer]="dataLayer"
          [useDefaultGeoJson]="useDefaultGeoJson"
        ></app-map-geojson-datalayer>
      </div>
    </div>
  </google-map>
</div>
