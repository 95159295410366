import { Component, Input, HostListener, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { IconTypes } from '../icon/icon.interfaces';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DropdownComponent {
  @Input() activeItem: string;
  @Input() label: string;
  @Input() labelTheme = 'dark';
  @Input() dropdownTheme: string;
  @Input() disabled = false;
  @Input() useTooltip: boolean = false;
  @Input() tooltipText: string = '';
  @Input() icon: IconTypes = IconTypes.Truck;
  @Input() iconSize: number = 2;
  @Input() iconColor: UiColors = UiColors.Grey5;
  @Input() styles: string = '';
  @ViewChild('dropdown') dropdown: ElementRef;
  isMenuOpen: boolean = false;
  dropdownClass: string;

  toggleMenu(): void {
    if (!this.disabled) {
      const topOffset = this.dropdown.nativeElement.offsetTop;
      const windowHeight = window.outerHeight;
      if (topOffset > windowHeight / 2) {
        this.dropdownClass = 'dropdown-list-reverse';
      } else {
        this.dropdownClass = 'dropdown-list';
      }
      this.isMenuOpen = !this.isMenuOpen;
    }
  }

  @HostListener('document:click', ['$event'])
  closeMenu(event: any): void {
    if (event.target.classList.contains('search')) {
      return;
    }
    if (event.target.classList.contains('ng-valid')) {
      return;
    }
    if (!this.isMenuOpen) {
      return;
    }
    if (event.target.classList.contains('dropdown-toggle')) {
      return;
    }
    this.isMenuOpen = false;
  }
}
