import { Component, Input, OnInit } from '@angular/core';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { TableActionTaken, TableCell, TableColumn, TableDataMap } from 'app/shared/table/table.interfaces';
import { NewTripsService } from '../new-trips.service';

@Component({
  selector: 'app-new-trips-failed',
  templateUrl: './new-trips-failed.component.html',
  styleUrls: ['./new-trips-failed.component.scss'],
})
export class NewTripsFailedComponent implements OnInit {
  @Input() failedParcels;
  @Input() lastMileId;

  parcelTableColumns: TableColumn[] = [
    { name: 'parcel_waybill', displayName: 'Waybill' },
    { name: 'contact_name', displayName: 'Contact Name' },
    { name: 'address', displayName: 'Address' },
    { name: 'phone', displayName: 'Phone' },
    { name: 'failed_at', displayName: 'Failed At:' },
    { name: 'attempts', displayName: 'Attempts' },
    { name: 'failed_reason', displayName: 'Reason' },
  ];

  parcelTableDataMap: TableDataMap = {
    cells: {
      parcel_waybill: { column: 'parcel_waybill', map: 'parcel_waybill' },
      address: {
        column: 'address',
        map: 'destination_address',
      },
      contact_name: { column: 'contact_name', map: 'destination_name' },
      phone: { column: 'phone', map: 'destination_phone' },
      failed_at: { column: 'failed_at', map: 'finalized_at', displayPipeArgs: ['date', 'mediumTime'] },
      attempts: { column: 'attempts', map: 'failed_attempts' },
      failed_reason: { column: 'failed_reason', map: 'failed_reason' },
    },
  };

  parcelBulkActions = [{ event: 'reattempt', title: 'Re-Attempt', icon: IconTypes.Check, icon_color: UiColors.Green }];

  public UiThemes = UiThemes;
  public icons = {
    types: IconTypes,
    colors: UiColors,
  };
  constructor(private newTripsService: NewTripsService) {}

  ngOnInit(): void {}

  handleTableActions($event: TableActionTaken): void {
    const parcel_array = [];
    $event.rows.forEach((element) => {
      parcel_array.push(element.cells.find((cell: TableCell) => cell.column === 'parcel_waybill').value);
    });
    this.newTripsService.bulkReattempt(parcel_array, this.lastMileId);
  }
}
