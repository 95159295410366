<div class="navigation">
  <div class="navigation-inner">
    <app-icon-button
      [icon]="icons.types.Menu"
      [iconActive]="icons.types.BackArrow"
      [color]="icons.colors.Orange"
      [paddingRem]="2"
      (onClick)="toggleNav()"
    ></app-icon-button>

    <app-icon-button
      *ngIf="showNav('admin-trips')"
      [icon]="icons.types.Trip"
      [color]="icons.colors.Grey3"
      [colorActive]="icons.colors.Orange"
      [active]="router.url.includes('/admin/post-dispatch/')"
      [paddingRem]="2"
      (onClick)="handleNav('/admin/post-dispatch/current-trips')"
    ></app-icon-button>
    <app-icon-button
      *ngIf="showNav('operations-tracking')"
      [icon]="icons.types.Details"
      [color]="icons.colors.Grey3"
      [colorActive]="icons.colors.Orange"
      [active]="router.url.includes('/admin/operations-tracking/')"
      [paddingRem]="2"
      (onClick)="handleNav('/admin/operations-tracking')"
    ></app-icon-button>
    <app-icon-button
      *ngIf="showNav('admin-buckets')"
      [icon]="icons.types.Bucket"
      [color]="icons.colors.Grey3"
      [colorActive]="icons.colors.Orange"
      [active]="router.url === '/admin/buckets'"
      [paddingRem]="2"
      (onClick)="handleNav('/admin/buckets')"
    ></app-icon-button>
    <app-icon-button
      *ngIf="showNav('planning')"
      [icon]="icons.types.Planning"
      [color]="showNav('planning') ? icons.colors.Grey3 : icons.colors.Yellow"
      [colorActive]="icons.colors.Orange"
      [active]="router.url.includes('/admin/planning')"
      [paddingRem]="2"
      (onClick)="handleNav('/admin/planning/overview')"
    >
    </app-icon-button>

    <app-icon-button
      *ngIf="showNav('admin-courier-orders')"
      [icon]="icons.types.Truck"
      [color]="icons.colors.Grey3"
      [colorActive]="icons.colors.Orange"
      [active]="router.url.includes('/admin/courier-orders')"
      [paddingRem]="2"
      (onClick)="handleNav('/admin/courier-orders/active')"
    ></app-icon-button>
    <app-icon-button
      *ngIf="showNav('admin-financials')"
      [icon]="icons.types.Unrouted"
      [color]="icons.colors.Grey3"
      [colorActive]="icons.colors.Orange"
      [active]="router.url === '/admin/financials'"
      [paddingRem]="2"
      (onClick)="handleNav('/admin/financials')"
    ></app-icon-button>
    <app-icon-button
      *ngIf="showNav('billing')"
      [icon]="icons.types.Dollar"
      [color]="icons.colors.Grey3"
      [colorActive]="icons.colors.Orange"
      [active]="router.url === '/admin/billing'"
      [paddingRem]="2"
      (onClick)="handleNav('/admin/billing')"
    ></app-icon-button>

    <!-- <app-icon-button
      *ngIf="showNav('freights')"
      [icon]="icons.types.Freight"
      [color]="icons.colors.Grey3"
      [colorActive]="icons.colors.Orange"
      [active]="router.url === '/admin/freights'"
      [paddingRem]="2"
      (onClick)="handleNav('/admin/freights')"
    ></app-icon-button> -->
    <app-icon-button
      *ngIf="showNav('users')"
      [icon]="icons.types.Profile"
      [color]="icons.colors.Grey3"
      [colorActive]="icons.colors.Orange"
      [active]="router.url === '/admin/users'"
      [paddingRem]="2"
      (onClick)="handleNav('/admin/users')"
    ></app-icon-button>
    <app-icon-button
      *ngIf="showNav('team')"
      [icon]="icons.types.Group"
      [color]="icons.colors.Grey3"
      [colorActive]="icons.colors.Orange"
      [active]="router.url === '/admin/team-management'"
      [paddingRem]="2"
      (onClick)="handleNav('/admin/team-management')"
    ></app-icon-button>

    <app-icon-button
      *ngIf="showNav('admin-areas')"
      [icon]="icons.types.Color"
      [color]="icons.colors.Grey3"
      [colorActive]="icons.colors.Orange"
      [active]="router.url === '/admin/areas'"
      [paddingRem]="2"
      (onClick)="handleNav('/admin/areas')"
    ></app-icon-button>
    <app-icon-button
      *ngIf="showNav('admin-drivers')"
      [icon]="icons.types.Driver"
      [color]="icons.colors.Grey3"
      [colorActive]="icons.colors.Orange"
      [active]="router.url === '/admin/drivers'"
      [paddingRem]="2"
      (onClick)="handleNav('/admin/drivers')"
    ></app-icon-button>
    <app-icon-button
      *ngIf="showNav('failure-reasons')"
      [icon]="icons.types.Alert"
      [color]="icons.colors.Grey3"
      [colorActive]="icons.colors.Orange"
      [active]="router.url === '/admin/failure-reasons'"
      [paddingRem]="2"
      (onClick)="handleNav('/admin/failure-reasons')"
    ></app-icon-button>
    <app-icon-button
      *ngIf="showNav('reporting')"
      [icon]="icons.types.History"
      [color]="icons.colors.Grey3"
      [colorActive]="icons.colors.Orange"
      [active]="router.url === '/admin/reporting'"
      [paddingRem]="2"
      (onClick)="handleNav('/admin/reporting')"
    ></app-icon-button>
    <app-icon-button
      *ngIf="showNav('notes')"
      [icon]="icons.types.Notes"
      [color]="icons.colors.Grey3"
      [colorActive]="icons.colors.Orange"
      [active]="router.url === '/admin/notes'"
      [paddingRem]="2"
      (onClick)="handleNav('/admin/notes')"
    ></app-icon-button>
    <app-icon-button
      *ngIf="showNav('advanced-search')"
      [icon]="icons.types.Search"
      [color]="showNav('advanced-search') ? icons.colors.Grey3 : icons.colors.Orange"
      [colorActive]="icons.colors.Blue"
      [active]="router.url.includes('/admin/advanced-search')"
      [paddingRem]="2"
      (onClick)="handleNav('/admin/advanced-search')"
    >
    </app-icon-button>
    <app-icon-button [icon]="icons.types.Logout" [color]="icons.colors.Red" [paddingRem]="2" (onClick)="onLogout()">
    </app-icon-button>
  </div>
</div>

<div *ngIf="isNavOpen" class="drawer drawer-open">
  <button class="navigation-link" (click)="toggleNav()">Close</button>
  <button
    class="navigation-link"
    *ngIf="showNav('admin-trips')"
    routerLink="/admin/post-dispatch/current-trips"
    (click)="selectLink()"
  >
    Admin Trips
  </button>
  <button 
    class="navigation-link" 
    *ngIf="showNav('operations-tracking')" 
    routerLink="/admin/operations-tracking" 
    (click)="selectLink()">
    Operations Tracking
  </button>
  <button 
    class="navigation-link" 
    *ngIf="showNav('admin-buckets')" 
    routerLink="/admin/buckets" 
    (click)="selectLink()">
    Buckets
  </button>

  <button
    class="navigation-link"
    *ngIf="showNav('planning')"
    routerLink="/admin/planning/overview"
    (click)="selectLink()"
  >
    Planning
  </button>
  <button
    class="navigation-link"
    *ngIf="showNav('admin-courier-orders')"
    routerLink="/admin/courier-orders/active"
    (click)="selectLink()"
  >
    Courier Orders
  </button>
  <button
    class="navigation-link"
    *ngIf="showNav('admin-financials')"
    routerLink="/admin/financials"
    (click)="selectLink()"
  >
    Financials
  </button>
  <button
    class="navigation-link"
    *ngIf="showNav('billing')"
    routerLink="/admin/billing"
    (click)="selectLink()"
  >
    Billing
  </button>
  <button class="navigation-link" *ngIf="showNav('users')" routerLink="/admin/users" (click)="selectLink()">
    User Management
  </button>
  <button class="navigation-link" *ngIf="showNav('team')" routerLink="/admin/team-management" (click)="selectLink()">
    Team Management
  </button>
  <button class="navigation-link" *ngIf="showNav('admin-areas')" routerLink="/admin/areas" (click)="selectLink()">
    Areas
  </button>
  <button class="navigation-link" *ngIf="showNav('admin-drivers')" routerLink="/admin/drivers" (click)="selectLink()">
    Drivers
  </button>
  <button
    class="navigation-link"
    *ngIf="showNav('failure-reasons')"
    routerLink="/admin/failure-reasons"
    (click)="selectLink()"
  >
    Failure Reasons
  </button>
  <button class="navigation-link" *ngIf="showNav('reporting')" routerLink="/admin/reporting" (click)="selectLink()">
    Reports
  </button>
  <button class="navigation-link" *ngIf="showNav('notes')" routerLink="/admin/notes" (click)="selectLink()">
    Notes
  </button>
  <button
    class="navigation-link"
    *ngIf="showNav('advanced-search')"
    routerLink="/admin/advanced-search"
    (click)="selectLink()"
  >
    Search
  </button>

  <button class="navigation-link" (click)="onLogout()">Log out</button>
</div>
