<app-modal
  [open]="true"
  (handleAction)="handleAction($event)"
  [actions]="actions"
  [highlightPrimaryAction]="true"
  [size]="ModalSizes.Medium"
>
  <heading [icon]="Icons.Types.Business" [color]="Icons.Colors.Blue">
    <h4 class="heading-title">Create Business</h4>
  </heading>

  <div class="modal-body">
    <div class="form-wrap">
      <div class="row">
        <app-grid-container [columns]="'repeat(2, 1fr)'" [theme]="UiThemes.Light" [rowGap]="'2rem'" [colGap]="'2rem'">
          <input-field
            [maxLength]="50"
            [type]="'text'"
            (valueChanged)="business_model.name = $event"
            [value]="business_model.name"
            [label]="'Name'"
          ></input-field>
          <input-field
            [type]="'tel'"
            (valueChanged)="business_model.phone = $event"
            [value]="business_model.phone"
            [label]="'Phone'"
          ></input-field>
          <input-field
            [type]="'text'"
            (valueChanged)="business_model.registration_number = $event"
            [value]="business_model.registration_number"
            [label]="'Registration Number'"
          ></input-field>
          <input-field
            [type]="'text'"
            (valueChanged)="business_model.vat_number = $event"
            [value]="business_model.vat_number"
            [label]="'VAT Number'"
          ></input-field>
        </app-grid-container>
        <div class="alert">
          <p *ngIf="phoneTouched">PHONE INVALID</p>
        </div>
      </div>
    </div>
  </div>
</app-modal>
