import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ManageBucketService } from '../../../manage-bucket.service';
import { ModalSizes, ModalAction } from 'app/shared/modals/modal.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { BucketService } from 'app/dashboard/buckets/bucket.service';
import { Store } from '@ngrx/store';
import * as fromAuth from 'app/auth/auth.reducer';
import { ActingAs } from 'app/interfaces/auth.interfaces';
import { selectorActiveBucketId } from 'app/dashboard/buckets/manage-bucket.reducer';
import { TableColumn, TableDataMap, TableAction, TableActionTaken } from 'app/shared/table/table.interfaces';

@Component({
  selector: 'app-change-bucket',
  templateUrl: './change-bucket.component.html',
  styleUrls: ['./change-bucket.component.scss'],
})
export class ChangeBucketComponent extends SimpleModalComponent<any, any> implements OnInit {
  public ModalSizes = ModalSizes;
  availableBuckets;
  public IconTypes = IconTypes;
  public uiColors = UiColors;
  public UiThemes = UiThemes;
  public buttonTypes = ButtonTypes;
  public actions: ModalAction[] = [{ name: 'cancel', title: 'Cancel' }];
  actingAs: ActingAs;
  showAll = false;
  currentBucketId: string;
  user_permissions;

  availableBucketsTableColumns: TableColumn[] = [
    { name: 'bucket_id', displayName: 'Bucket ID ', sortable: true },
    { name: 'warehouse_name', displayName: 'Warehouse', sortable: true },
    { name: 'delivery_date', displayName: 'Delivery Date', sortable: true },
    { name: 'shift_start', displayName: 'Shift Start', sortable: true },
    { name: 'shift_end', displayName: 'Shift End', sortable: true },
  ];

  availableBucketsTableDataMap: TableDataMap = {
    cells: {
      bucket_id: { column: 'bucket_id', map: 'bucket_id' },
      warehouse_name: {
        column: 'warehouse_name',
        map: 'warehouse_name',
      },
      delivery_date: {
        column: 'delivery_date',
        map: 'delivery_date',
        displayPipeArgs: ['date', 'mediumDate'],
      },
      shift_start: {
        column: 'shift_start',
        map: 'shift_start',
      },
      shift_end: {
        column: 'shift_end',
        map: 'shift_end',
      },
    },
  };
  availableBucketsPrimaryActions: TableAction[] = [
    { event: 'selectBucket', title: 'Select', icon: IconTypes.Check, icon_color: UiColors.Grey8 },
  ];

  constructor(
    private manageBucketService: ManageBucketService,
    private bucketService: BucketService,
    private store: Store<fromAuth.State>
  ) {
    super();
    this.store.select(fromAuth.selectorActingAs).subscribe((next) => (this.actingAs = next));
    this.store.select(selectorActiveBucketId).subscribe((next) => (this.currentBucketId = next));
    this.store.select(fromAuth.selectorUserPermissions).subscribe((next) => (this.user_permissions = next));
  }
  cancel() {
    this.result = 'cancelled';
    this.close();
  }

  findAvailableBuckets() {
    this.manageBucketService.getAssignableBuckets().then((response) => (this.availableBuckets = response));
  }
  ngOnInit() {
    this.findAvailableBuckets();
  }

  selectBucket($event: TableActionTaken): void {
    const bucket_id = $event.rows[0].cells.find((cell) => cell.column === 'bucket_id').value;
    this.result = bucket_id;
    this.close();
  }

  handleAction($event) {
    if ($event === 'cancel') {
      this.close();
    }
  }

  toggle(): void {
    if (this.showAll) {
      this.findAvailableBuckets();
    } else {
      this.findAllBucketsForBusiness();
    }
    this.showAll = !this.showAll;
  }

  findAllBucketsForBusiness(): void {
    this.bucketService.getAssignableBucketsForBusiness(this.actingAs.id).then((response) => {
      this.availableBuckets = response;
    });
  }

  createNewBucket(): void {
    this.result = 'newBucket';
    this.close();
  }
}
