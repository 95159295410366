<div class="date-time-wrap">
  <app-date-picker class="app-date-picker" [date]="date" [end_date]="end_date" [shiftDates]="shiftDates" (changeDate)="changeDate($event)">
  </app-date-picker>

  <div class="selected-date-shift">
    <div class="selected-date">
      <h6> Selected Date</h6>
      <h2 *ngIf="!end_date">{{date | date:'E, dd MMM'}}</h2>
      <h2 *ngIf="end_date">{{'Multiple' | uppercase}}</h2>
    </div>
    <app-shift-selector [selectedRange]="[shift_start, shift_end]" [availableRanges]="[]" class="app-shift-selector"
      (changeShift)="changeShift($event)">
    </app-shift-selector>
  </div>

  <div class="select-details">
    <div class="wrap">
      <div>
        <h6>Warehouse</h6>
        <app-dropdown [dropdownTheme]="'light'" [labelTheme]="'light'"
          [activeItem]="activeWarehouseName ? activeWarehouseName : 'Select Warehouse'">
          <div dropdownRef>
            <a *ngFor="let warehouse of warehouses" class="dropdown-item"
              (click)="CSVUploadDetails.validation.bucket[0].warehouse_id = warehouse.id; activeWarehouseName = warehouse.name; errors = null; validated = false;">{{ warehouse.name }}</a>
          </div>
        </app-dropdown>
      </div>

      <div>
        <h6>Total Orders</h6>
        <h3>{{CSVUploadDetails?.validation.bucket[0].orders}}</h3>
      </div>
      <div>
        <h6>New Geocodes</h6>
        <h3>{{CSVUploadDetails?.validation.not_geocoded}}</h3>
      </div>

      <app-basic-button 
        [disabled]="errors || !this.activeWarehouseName" 
        *ngIf="!validated" 
        [title]="'Validate'" 
        [type]="buttonTypes.Primary"
        [active]="toggleHighlight" (click)="validateCSV()">
      </app-basic-button>
      <app-basic-button 
        [processing]="processing" 
        *ngIf="validated" 
        [title]="'Continue'" 
        [type]="buttonTypes.Highlight" 
        [active]="toggleHighlight"
        (click)="processCSV()">
      </app-basic-button>
    </div>
    <div class="message">
      <h4 *ngIf="errors" class="errors">{{errors.error_message}}</h4>
      <h4 *ngIf="validated && newBucket" class="valid">No errors found, a new bucket will be created</h4>
      <h4 *ngIf="validated && !newBucket" class="valid">Orders will be added to bucket#: {{CSVUploadDetails.validation.bucket[0].bucket_id}}</h4>
    </div>
  </div>
</div>