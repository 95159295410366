import { Component, OnInit, Input, ViewChild, OnDestroy, OnChanges, SimpleChanges, AfterViewInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { MapTypes } from './map-view.interfaces';
import { VehicleTrackingMapComponent } from './vehicle-tracking-map/vehicle-tracking-map.component';
import { MapViewService } from './map-view.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { VehicleTrackingMapService } from './vehicle-tracking-map/vehicle-tracking-map.service';
import { ButtonTypes } from '../buttons/basic-button.component';
import { JustifyContent } from '../flex-container/flex-container.interfaces';
import { GoogleMap, MapMarker } from '@angular/google-maps';
import { darkMapStyle } from '../map/map.constants';

@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss'],
})
export class MapViewComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() polylines = [];
  @Input() markers = [];
  @Input() waypoints = [];
  @Input() driverId;
  @Input() geoJsonDataLayers = [];
  @Input() polylineColor = '#26b7fc';
  @Input() driverIds = [];
  @Input() polylineType = 'routific';
  @Input() useDefaultGeoJson: boolean = false;
  @Input() enableOverlay = true;

  @Input() loading = false;

  @Input() mapType: string;
  @Input() height: string = '500px';
  @Input() mapStyle = {
    'height.px': 500,
  };
  @Input() mapColours: any;
  @Input() showOnline: boolean = false;
  @Input() enableShowDriverCheckBox = true;
  @Input() driverIcon: string;
  @Input()  set mapStyles(mapStyles: google.maps.MapTypeStyle[]){
    const styles = mapStyles ?? darkMapStyle;
    this.mapColourPallet = styles;
  }
  @Output() markerClick: EventEmitter<any> = new EventEmitter();

  infowindow;
  buttonTypes = ButtonTypes;
  justifyContent = JustifyContent;

  hidePolylines = false;
  hideMarkers = false;
  hideWaypointMarkers = false;
  hideDrivers = false;
  hideDataLayers = true;
  MapTypes = MapTypes;

  stopSettingBounds$: Subject<boolean> = new Subject();
  followBounds = false;
  overlayDataSubscription: Subscription;
  boundsSubscription: Subscription;
  showTrafficLayer: boolean = false;
  trafficLayer: google.maps.TrafficLayer;
  mapColourPallet = darkMapStyle;

  @ViewChild('VehicleTrackingMapComponent', { static: false }) vehicleTrackingMapComponent: VehicleTrackingMapComponent;
  @ViewChild('map', { static: false }) gMap: GoogleMap;


  public mapTypes = MapTypes;

  public mapElement: google.maps.Map;
  public map: google.maps.LatLngLiteral = {
    lat: -26.234399,
    lng: 27.911409,
  };

  gMapOptions: google.maps.MapOptions = {
    styles: this.mapColourPallet,
    center: this.map,
    zoomControl: false,
    scrollwheel: true,
    streetViewControl: false,
    clickableIcons: false,
    disableDefaultUI: true
  };

  constructor(private mapViewService: MapViewService, private vehicleTrackingMapService: VehicleTrackingMapService, private cdr: ChangeDetectorRef) {
    // this.overlayDataSubscription = this.mapViewService.overlayData.subscribe((data) => {
    //   console.log(data);
    // });
  }

  ngOnInit(): void {
    this.subscribeToMarkersForBounds();
    this.trafficLayer = new google.maps.TrafficLayer();
    this.cdr.detectChanges()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.geoJsonDataLayers && this.geoJsonDataLayers[0] && this.mapType !== 'tracking') {
      this.hideDataLayers = false;
      this.updateMapBounds(this.geoJsonDataLayers, 'geoArray');
    }
  }

  ngOnDestroy(): void {
    this.boundsSubscription.unsubscribe();
    this.mapViewService.removeMarkersForBound();
  }

  toggleMapElementFilter(element: string): void {
    this[element] = !this[element];
  }

  ngAfterViewInit(): void {
    this.infowindow = new google.maps.InfoWindow();
  }

  toggleFollowBounds(): void {
    if (this.followBounds) {
      this.stopSettingBounds$.next(true);
      this.stopSettingBounds$.complete();
      this.boundsSubscription.unsubscribe();
    } else {
      this.stopSettingBounds$ = new Subject();
      this.subscribeToMarkersForBounds();
    }
    this.followBounds = !this.followBounds;
  }

  subscribeToMarkersForBounds(): void {
    this.boundsSubscription = this.mapViewService.markersForBounds
      .pipe(takeUntil(this.stopSettingBounds$))
      .subscribe((markers) => {
        if (markers.length > 0 && this.mapElement) {
          this.updateMapBounds(markers, 'markers');
        }
      });
  }

  public mapElementReady(): void {
    if (this.gMap) {
      this.mapElement = this.gMap.googleMap;
      this.gMap.googleMap.setOptions({styles: this.mapColourPallet});
      this.addClickEvent();
    }
  }

  // mapClicked($event) {
  //   // this.mapElement.infowindow.close();
  //   this.vehicleTrackingMapComponent.mapClicked($event);
  // }

  updateMapBounds(markers, context): void {
    const bounds = new google.maps.LatLngBounds();
    if (context === 'markers') {
      markers.forEach((marker) => {
        bounds.extend(new google.maps.LatLng(marker.latitude, marker.longitude));
      });
    }
    if (context === 'geoArray') {
      if (markers) {
        markers.forEach((geoArray) => {
          if (geoArray) {
            geoArray?.features.forEach((feature) => {
              if (feature?.geometry?.coordinates.length) {
                bounds.extend(new google.maps.LatLng(feature.geometry.coordinates[1], feature.geometry.coordinates[0]));
              }
            });
          }
        });
      }
    }

    if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
      const extendPoint1 = new google.maps.LatLng(
        bounds.getNorthEast().lat() + 0.005,
        bounds.getNorthEast().lng() + 0.005
      );
      const extendPoint2 = new google.maps.LatLng(
        bounds.getNorthEast().lat() - 0.005,
        bounds.getNorthEast().lng() - 0.005
      );
      bounds.extend(extendPoint1);
      bounds.extend(extendPoint2);
    }
    if (!this.followBounds) {
      setTimeout(() => {
        this.mapElement.fitBounds(bounds);
      }, 500);
    }
  }

  addClickEvent(): void {
    this.mapElement.data.addListener('click', (event) => {
      const feat = event.feature;

      // Check if window info is for driver discrepancy - add more cases like this in the future for other GeoJson
      if (feat.getProperty('key') === 'driver_discrepancy') {
        const html =
          '<div class=\'scrollFix\'><h4>Location Discrepancy</h4><h6><strong>Driver:</strong> ' +
          feat.getProperty('driver_name') +
          '</h6><h6><strong>Event:</strong> ' +
          this.getNiceName(feat.getProperty('source_event')) +
          '</h6><h6><strong>Distance:</strong> ' +
          (feat.getProperty('discrepancy_distance_meters') / 1000).toFixed(2) +
          'km </h6>';
        this.infowindow.setContent(html);
        this.infowindow.setPosition(event.latLng);
        this.infowindow.setOptions({ pixelOffset: new google.maps.Size(0, -34) });
        this.infowindow.open(this.mapElement);
      }
      // overlay for trip selection map:
      if (feat.getProperty('trip_id')) {
        const html =
          '<div class=\'scrollFix\'><h4>Waypoint Marker</h4><h6><strong>Trip ID:</strong> ' +
          feat.getProperty('trip_id') +
          '</h6><h6><strong>Address:</strong> ' +
          this.getNiceName(feat.getProperty('geocoded_address')) +
          '</h6><h6><strong>Waypoint #:</strong> ' +
          feat.getProperty('marker_symbol') +
          '</h6>';
        this.infowindow.setContent(html);
        this.infowindow.setPosition(event.latLng);
        this.infowindow.setOptions({ pixelOffset: new google.maps.Size(0, -34) });
        this.infowindow.open(this.mapElement);
      }
    });
  }

  getNiceName(event: string): string {
    switch (event) {
      case 'DriverFinalizeAtWaypoint':
        return 'Finalized at Waypoint';
      case 'DriverArriveAtWaypoint':
        return 'Arrived at Waypoint';
      case 'DriverFinalizeContact':
        return 'Arrived at Contact';
      case 'DriverArriveAtContact':
        return 'Arrived at Contact';
      default:
        return event;
    }
  }

  toggleTrafficLayer(showTrafficLayer: boolean): void {
    this.trafficLayer.setMap(showTrafficLayer ? this.mapElement : null);
    this.showTrafficLayer = !this.showTrafficLayer;
  }

  markerOutput(event){
    this.markerClick.emit(event)
  }

  trackByMapMarker(_index: number, marker: MapMarker): string {
    if (!marker) {
      return null;
    }
    return marker.label.toString();
  }

}
