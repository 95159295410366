<div class="navigation">
  <div class="navigation-inner">
    <app-icon-button
      [icon]="icons.types.Menu"
      [iconActive]="icons.types.BackArrow"
      [color]="icons.colors.Blue"
      [paddingRem]="2"
      (onClick)="toggleNav()"
    ></app-icon-button>

    <app-icon-button
      *ngIf="showNav('place-order')"
      [icon]="icons.types.Trip"
      [color]="showNav('place-order', 'color') ? icons.colors.Grey3 : icons.colors.Yellow"
      [colorActive]="icons.colors.Blue"
      [active]="router.url === '/dashboard/place-order'"
      [paddingRem]="2"
      (onClick)="handleNav(['/dashboard/place-order'])"
    >
    </app-icon-button>

    <app-icon-button
      *ngIf="showNav('post-dispatch')"
      [icon]="icons.types.Truck"
      [color]="showNav('post-dispatch', 'color') ? icons.colors.Grey3 : icons.colors.Yellow"
      [colorActive]="icons.colors.Blue"
      [active]="router.url === '/dashboard/post-dispatch/overview'"
      [paddingRem]="2"
      (onClick)="handleNav(['/dashboard/post-dispatch/overview'])"
    ></app-icon-button>

    <app-icon-button
      *ngIf="showNav('operations-tracking')"
      [icon]="icons.types.Details"
      [color]="showNav('operations-tracking', 'color') ? icons.colors.Grey3 : icons.colors.Yellow"
      [colorActive]="icons.colors.Blue"
      [active]="router.url.includes('/dashboard/operations-tracking')"
      [paddingRem]="2"
      (onClick)="handleNav(['/dashboard/operations-tracking'])"
    ></app-icon-button>

    <app-icon-button
      *ngIf="showNav('account')"
      [icon]="icons.types.Profile"
      [color]="icons.colors.Grey3"
      [colorActive]="icons.colors.Blue"
      [active]="router.url === '/dashboard/account'"
      [paddingRem]="2"
      (onClick)="handleNav(['/dashboard/account'])"
    ></app-icon-button>

    <app-icon-button
      *ngIf="showNav('buckets')"
      [icon]="icons.types.Bucket"
      [color]="showNav('buckets', 'color') ? icons.colors.Grey3 : icons.colors.Yellow"
      [colorActive]="icons.colors.Blue"
      [active]="router.url === '/dashboard/buckets'"
      [paddingRem]="2"
      (onClick)="handleNav(['/dashboard/buckets'])"
    >
    </app-icon-button>

    <app-icon-button
      *ngIf="showNav('planning')"
      [icon]="icons.types.Details"
      [color]="showNav('details', 'color') ? icons.colors.Grey3 : icons.colors.Yellow"
      [colorActive]="icons.colors.Blue"
      [active]="router.url.includes('planning')"
      [paddingRem]="2"
      (onClick)="handleNav(['/dashboard/planning/overview'])"
    >
    </app-icon-button>

    <app-icon-button
      *ngIf="showNav('warehouse') && showEditConsignment"
      [icon]="icons.types.Warehouse"
      [color]="showNav('warehouse', 'color') ? icons.colors.Grey3 : icons.colors.Yellow"
      [colorActive]="icons.colors.Blue"
      [active]="router.url === '/dashboard/warehouse'"
      [paddingRem]="2"
      (onClick)="handleNav(['/dashboard/warehouse'])"
    ></app-icon-button>

    <app-icon-button
      *ngIf="showNav('financials')"
      [icon]="icons.types.Dollar"
      [color]="showNav('financials', 'color') ? icons.colors.Grey3 : icons.colors.Yellow"
      [colorActive]="icons.colors.Blue"
      [active]="router.url.includes('/dashboard/billing')"
      [paddingRem]="2"
      (onClick)="handleNav(['/dashboard/billing'])"
    >
    </app-icon-button>
    <app-icon-button
      *ngIf="showNav('manage-fleet')"
      [icon]="icons.types.Steering"
      [color]="showNav('manage-fleet', 'color') ? icons.colors.Grey3 : icons.colors.Yellow"
      [colorActive]="icons.colors.Blue"
      [active]="router.url === '/dashboard/manage-fleet/overview'"
      [paddingRem]="2"
      (onClick)="handleNav(['/dashboard/manage-fleet/overview'])"
    >
    </app-icon-button>
    <app-icon-button
      *ngIf="showNav('geocoding')"
      [icon]="icons.types.Geocoder"
      [color]="showNav('geocoding', 'color') ? icons.colors.Grey3 : icons.colors.Yellow"
      [colorActive]="icons.colors.Blue"
      [active]="router.url === '/dashboard/geocoding'"
      [paddingRem]="2"
      (onClick)="handleNav(['/dashboard/geocoding'])"
    >
    </app-icon-button>
    <app-icon-button
      *ngIf="showNav('manage-business')"
      [icon]="icons.types.Business"
      [color]="showNav('manage-business', 'color') ? icons.colors.Grey3 : icons.colors.Yellow"
      [colorActive]="icons.colors.Blue"
      [active]="router.url.includes('manage-business')"
      [paddingRem]="2"
      (onClick)="handleNav(['/dashboard/manage-business', this.actingAs.id])"
    ></app-icon-button>

    <app-icon-button
      *ngIf="showNav('advanced-search')"
      [icon]="icons.types.Search"
      [color]="showNav('advanced-search', 'color') ? icons.colors.Grey3 : icons.colors.Orange"
      [colorActive]="icons.colors.Blue"
      [active]="router.url.includes('/dashboard/advanced-search')"
      [paddingRem]="2"
      (onClick)="handleNav(['/dashboard/advanced-search'])"
    ></app-icon-button>

    <app-icon-button
      *ngIf="showNav('reporting')"
      [disabled]="false"
      [icon]="icons.types.History"
      [color]="showNav('reporting', 'color') ? icons.colors.Grey3 : icons.colors.Yellow"
      [colorActive]="icons.colors.Blue"
      [active]="router.url === '/dashboard/reporting'"
      (onClick)="handleNav(['/dashboard/reporting'])"
      [paddingRem]="2"
    >
    </app-icon-button>

    <!-- <app-icon-button
      *ngIf="showNav('billing')"
      [icon]="icons.types.Dollar"
      [color]="icons.colors.Grey3"
      [colorActive]="icons.colors.Blue"
      [active]="router.url === '/dashboard/billing'"
      [paddingRem]="2"
      (onClick)="handleNav(['/dashboard/billing'])"
    >
    </app-icon-button> -->

    <app-icon-button
      *ngIf="showNav('settings')"
      [icon]="icons.types.Gear"
      [color]="icons.colors.Grey3"
      [colorActive]="icons.colors.Blue"
      [active]="router.url.includes('/dashboard/settings')"
      [paddingRem]="2"
      (onClick)="handleNav(['/dashboard/settings'])"
    >
    </app-icon-button>

    <app-icon-button [icon]="icons.types.Logout" [color]="icons.colors.Orange" [paddingRem]="2" (onClick)="onLogout()">
    </app-icon-button>
  </div>
</div>

<div *ngIf="isNavOpen" class="drawer drawer-open">
  <button class="navigation-link" (click)="toggleNav()">Close</button>
  <button
    class="navigation-link"
    *ngIf="showNav('place-order')"
    routerLink="/dashboard/place-order"
    (click)="selectLink()"
  >
    Place Order
  </button>
  <button
    class="navigation-link"
    *ngIf="showNav('post-dispatch')"
    routerLink="/dashboard/post-dispatch/overview"
    (click)="selectLink()"
  >
    Tracking
  </button>
  <button
    class="navigation-link"
    *ngIf="showNav('operations-tracking')"
    routerLink="/dashboard/operations-tracking"
    (click)="selectLink()"
  >
    Operations Tracking
  </button>
  <button class="navigation-link" *ngIf="showNav('account')" routerLink="/dashboard/account" (click)="selectLink()">
    Account
  </button>
  <button class="navigation-link" *ngIf="showNav('buckets')" routerLink="/dashboard/buckets" (click)="selectLink()">
    Buckets
  </button>
  <button
    class="navigation-link"
    *ngIf="showNav('planning')"
    routerLink="/dashboard/planning/overview"
    (click)="selectLink()"
  >
    Planning
  </button>
  <button class="navigation-link" *ngIf="showNav('financials')" routerLink="/dashboard/billing" (click)="selectLink()">
    Financials
  </button>
  <button
    class="navigation-link"
    *ngIf="showNav('warehouse') && showEditConsignment"
    routerLink="/dashboard/warehouse"
    (click)="selectLink()"
  >
    Warehouse
  </button>
  <button
    class="navigation-link"
    *ngIf="showNav('manage-fleet')"
    routerLink="/dashboard/manage-fleet/overview"
    (click)="selectLink()"
  >
    Manage Fleet
  </button>
  <button class="navigation-link" *ngIf="showNav('geocoding')" routerLink="/dashboard/geocoding" (click)="selectLink()">
    Geocoder
  </button>
  <button
    class="navigation-link"
    *ngIf="showNav('manage-business')"
    [routerLink]="'/dashboard/manage-business/' + actingAs.id"
    (click)="selectLink()"
  >
    Manage Business
  </button>
  <button
    class="navigation-link"
    *ngIf="showNav('advanced-search')"
    routerLink="/dashboard/advanced-search"
    (click)="selectLink()"
  >
    Search Orders
  </button>
  <button class="navigation-link" *ngIf="showNav('reporting')" routerLink="/dashboard/reporting" (click)="selectLink()">
    Reporting
  </button>
  <!-- <button class="navigation-link" *ngIf="showNav('billing')" routerLink="/dashboard/billing" (click)="selectLink()">
    Billing
  </button> -->
  <button class="navigation-link" *ngIf="showNav('settings')" routerLink="/dashboard/settings" (click)="selectLink()">
    Settings
  </button>
  <button class="navigation-link" (click)="onLogout()">Log out</button>
</div>
