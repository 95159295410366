import { StoreAction } from '../../interfaces/auth.interfaces';
import * as Actions from './manage-bucket.actions';

const initialStateFromSomewhere = JSON.parse(localStorage.getItem('state'));

export const initialState = {
  activeBucket: null,
  activeBucketId: null,
  bucketFilters: {
    warehouse: null,
    date: null,
    type: 'active',
    limit: 100,
  },
};

export function getInitialState() {
  if (initialStateFromSomewhere) {
    if (initialStateFromSomewhere.buckets !== undefined && initialStateFromSomewhere.buckets !== null) {
      const state = initialStateFromSomewhere.buckets.manageBuckets;
      return { ...initialState, ...state };
    } else {
      return { ...initialState };
    }
  } else {
    return { ...initialState };
  }
}

export const selectorBucketFilters = (state) => state.buckets.manageBuckets.bucketFilters;
export const selectorActiveBucket = (state) => state.buckets.manageBuckets.activeBucket;
export const selectorActiveBucketId = (state) => state.buckets.manageBuckets.activeBucketId;
// export const selectorActiveBuckets = (state) => state.buckets.manageBuckets.activeBuckets;

export function ManageBucketReducer(state = getInitialState(), action: StoreAction) {
  switch (action.type) {
    case Actions.SET_ACTIVE_BUCKET:
      return {
        ...state,
        activeBucket: action.payload,
      };
    case Actions.SET_ACTIVE_BUCKET_ID:
      return {
        ...state,
        activeBucketId: action.payload,
      };
    // case Actions.SET_ACTIVE_BUCKETS:
    //   return {
    //     ...state,
    //     activeBuckets: action.payload,
    //   };
    case Actions.SET_BUCKET_FILTERS:
      return {
        ...state,
        bucketFilters: action.payload,
      };
    case Actions.CLEAR_BUCKET_FILTERS:
      return {
        ...state,
        bucketFilters: initialState.bucketFilters,
      };
    default:
      return state;
  }
}
