<app-section>
    <heading [icon]="IconTypes.Alert" [color]="UiColors.Blue">
        <h4 class="heading-title">{{"Vehicle Alerts" | uppercase}}</h4>
        <div class="controls">
            <app-basic-button class="control-button" [title]="!showMap ? 'Toggle Map' : 'Toggle Table' "
                [type]="buttonTypes.Primary" (click)="toggleMap()">
            </app-basic-button>

            <app-basic-button [title]="'Refresh'" [type]="buttonTypes.Primary" [active]="true" (click)="refresh()">
            </app-basic-button>

        </div>
    </heading>

    
    <app-alerts-table *ngIf="!showMap" [alertsData]="vehicle_alerts"></app-alerts-table>
    <app-alerts-map *ngIf="showMap" [vehicles]="vehicles" [alertsData]="vehicle_alerts"></app-alerts-map>

</app-section>