import { Component, EventEmitter, OnDestroy, Optional, Output } from '@angular/core';
import { FormattedAddress, FullAddressModel, TaskType, mapMarker } from './start-end-point.interfaces';
import { StartEndPointService } from './start-end-point.service';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MapMarkerColors, UiColors } from 'app/interfaces/ui.interfaces';
import { ButtonTypes } from '../buttons/basic-button.component';
import { AbstractControl, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { IconTypes } from '../icon/icon.interfaces';

enum ControlTypes {
  START_ADDRESS = 'startAddress',
  END_ADDRESS = 'endAddress',
  START_TASK = 'startTask',
  END_TASK = 'endTask',
}

@Component({
  selector: 'app-start-end-point',
  templateUrl: './start-end-point.component.html',
  styleUrls: ['./start-end-point.component.scss']
})
export class StartEndPointComponent implements OnDestroy {
  @Output() savedSuccessful: EventEmitter<boolean> = new EventEmitter<boolean>();
  private unsubscribe$ = new Subject<void>();

  get startAddressTypeControl(): FormControl {
    return this.startEndFormGroup.controls.startAddress as FormControl;
  }

  get endAddressTypeControl(): FormControl {
    return this.startEndFormGroup.controls.endAddress as FormControl;
  }

  get startTaskControl(): FormControl {
    return this.startEndFormGroup.controls.startTask as FormControl;
  }

  get endTaskControl(): FormControl {
    return this.startEndFormGroup.controls.endTask as FormControl;
  }

  readonly addressModel$ = this.startEndPointService.outModel$;

  startEndFormGroup = this.fb.group({
    startAddress: ['',],
    startTask: [''],
    endAddress: [''],
    endTask: [''],
  });

  taskType = TaskType;
  markers: mapMarker[] = []
  showMap = false;
  buttonTypes = ButtonTypes;
  endValue: FullAddressModel;
  iconTypes = IconTypes;
  uiColors = UiColors;

  constructor(private startEndPointService: StartEndPointService, private fb: FormBuilder, @Optional() public dialogRef?: MatDialogRef<StartEndPointComponent>) {

    this.startAddressTypeControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((startAddress: FullAddressModel) => {
      this.removeMarker('S');
      if (startAddress) {
        this.setStartAddress(startAddress);
        this.markers.push({
          latitude: startAddress.lat,
          longitude: startAddress.lng,
          status: MapMarkerColors.Lightblue,
          label: 'S',
        });
        this.showMap = true;
      }
      if (this.markers.length === 0) {
        this.showMap = false
      }
    });

    this.endAddressTypeControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((endAddress: FullAddressModel) => {
      this.removeMarker('E');
      if (endAddress) {
        this.setEndAddress(endAddress);
        this.markers.push({
          latitude: endAddress.lat,
          longitude: endAddress.lng,
          status: MapMarkerColors.Red,
          label: 'E',
        });
        this.showMap = true;
      }
      if (this.markers.length === 0) {
        this.showMap = false
      }
    });

    this.startEndFormGroup.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.startEndPointService.isFormValid = this.startEndFormGroup.valid;
    })

    this.populateFormControls();
  }

  ngOnDestroy(): void {
    if (this.startEndFormGroup.invalid) {
      this.startEndFormGroup.reset();
      this.startEndPointService.outModel$.next({ startAddress: null, endAddress: null });
    }

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private populateFormControls(): void {
    this.startEndPointService.outModel$.pipe(take(1)).subscribe((addressValue: { startAddress: FullAddressModel, endAddress: FullAddressModel }) => {
      if (!addressValue.startAddress) {
        return
      }

      this.startAddressTypeControl.setValue(addressValue?.startAddress ?? null);
      this.endAddressTypeControl.setValue(addressValue?.endAddress ?? null);
      this.startTaskControl.setValue(addressValue?.startAddress.reason ?? null);
      this.endTaskControl.setValue(addressValue?.endAddress.reason ?? null);
    });
  }

  private removeMarker(type: string): void {
    this.markers = this.markers.filter((marker: mapMarker) =>
      marker.label !== type
    );
  }

  private validatorLogic(source: string): void {
    switch (source) {
      case ControlTypes.START_ADDRESS:
      {
        this.startTaskControl.setValidators(Validators.required);
        this.startAddressTypeControl.setValidators(Validators.required);
        this.endAddressTypeControl.setValidators(Validators.required);
        this.endAddressTypeControl.markAsTouched();
        this.startTaskControl.markAsTouched();
        this.startTaskControl.updateValueAndValidity();
        this.startEndFormGroup.markAllAsTouched();
        this.endAddressTypeControl.updateValueAndValidity();

        break;
      }
      case ControlTypes.END_ADDRESS:
      {
        this.endTaskControl.setValidators(Validators.required);
        this.endTaskControl.markAsTouched();
        this.endTaskControl.updateValueAndValidity();
        break;
      }
    }
    this.startEndFormGroup.updateValueAndValidity();
  }

  setStartAddress(address: FormattedAddress): void {
    this.startEndPointService.startAddress = address
    this.validatorLogic(ControlTypes.START_ADDRESS);
  }

  setEndAddress(address: FormattedAddress): void {
    this.startEndPointService.endAddress = address
    this.validatorLogic(ControlTypes.END_ADDRESS);
  }

  setStartTask(task: string): void {
    this.startTaskControl.setValue(task);
    this.startEndPointService.startReason = task;
    this.validatorLogic(ControlTypes.START_TASK);
  }

  setEndTask(task: string): void {
    this.endTaskControl.setValue(task);
    this.startEndPointService.endReason = task;
    this.validatorLogic(ControlTypes.END_TASK);
  }

  // Removed because BE does not support this. Start cannot be the same as the end address

  // setStartAsEnd(): void {
  //   this.endAddressTypeControl.setValue(this.startAddressTypeControl.value);
  // }

  clearMap(type: TaskType): void {
    switch (type) {
      case TaskType.start:
        this.startEndPointService.startAddress = null;
        this.startEndPointService.startReason = null;
        this.startAddressTypeControl.setValue(null);
        break;
      case TaskType.end:
        this.startEndPointService.endAddress = null;
        this.startEndPointService.endReason = null;
        this.endAddressTypeControl.setValue(null);
        break;
    }
  }

  saveData(): void {
    const valid = this.startEndPointService.isFormValid;
    this.savedSuccessful.emit(valid);
    if (this.dialogRef) {
      this.dialogRef.close(valid);
    }
  }

  validateAddress(control: AbstractControl): { [key: string]: boolean } {
    const value = control.value;
    if (!value?.address) {
      return { address: false }
    }
    if (!(value?.lat && value?.lng)) {
      return { latLng: false }
    }
    return null
  }
}
