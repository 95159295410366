/* eslint-disable @typescript-eslint/no-shadow */
export enum ParcelTypes {
  Collection = 'Collection',
  Delivery = 'Delivery',
}

export enum ParcelSizes {
  Envelope = 'Envelope',
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
  XLarge = 'X-Large',
}

export enum ParcelCollectionStates {
  Collected = 'Collected',
  Failed = 'Failed',
  PendingCollection = 'Pending Collection',
}

export enum ParcelDeliveryStates {
  Delivered = 'Delivered',
  Failed = 'Failed',
  Pending = 'Pending',
}

export enum WaypointStates {
  Finalized = 'Finalized',
  Visiting = ' Visiting',
  AtDestination = 'At Destination',
  Failed = 'Failed',
  NoContact = 'No Contact',
  Pending = 'Pending',
  Completed = 'Completed',
  PicupCancelled = 'Picup cancelled',
  EnRoute = 'En Route',
  Dispatching = 'Dispatching',
  PartialFailure = 'Partial Failure',
  Cancelled = 'Cancelled',
}

export enum WaypointStatus {
  Pending = 'Pending',
  OnRoute = 'On Route',
  AtWaypoint = 'At Waypoint',
  PicupFinalised = 'Picup Finalised',
  PicupComplete = 'Picup Complete',
  PicupCancelled = 'Picup Cancelled',
}

export enum VehicleTypes {
  Car = 'vehicle-car',
  MotorCycle = 'vehicle-motorcycle',
  VanLarge = 'vehicle-large-van',
  VanSmall = 'vehicle-small-van',
  Freight = 'vehicle-freight',
}

export enum DriverLotteryStatuses { // This needs to be finalised/fleshed out
  AcceptedByDriver = 'Accepted by Driver',
  AwaitingResponse = 'Awaiting response from driver',
  RejectedByDriver = 'Rejected by driver',
  AssignedToDriver = 'Assigned to driver',
}

export enum TripTypes {
  All = -1,
  Picup = 0,
  Contract = 1,
  Courier = 2,
}

export enum HealthStates {
  Bad = 'bad',
  Good = 'good',
  Warning = 'warning',
  Primary = 'primary',
  Default = 'default',
}

export enum HighlightHealthStates {
  Bad = 'highlight-bad',
  Good = 'highlight-good',
  Caution = 'highlight-caution',
  Warning = 'highlight-warning',
  Primary = 'highlight-primary',
  Default = 'highlight-default',
}

export enum LotteryStateEnums {
  WaitingForDriverResponse = 0,
  DriverAccepted = 1,
  DriverRejected = 2,
  DriverAssigned = 3,
  DriverConfirmed = 4,
}

export enum ActiveTrip$Keys {
  WhenAcceptedByCustomer = 'when_accepted_by_customer',
  CompletedWaypoints = 'completed_waypoints',
  NextEventDescription = 'next_event_description',
  PicupState = 'picup_state',
  Health = 'health',
  LastEvent = 'last_event',
  NextEvent = 'next_event_description',
  PicupId = 'picup_id',
  TotalWaypoints = 'total_waypoints',
  PicupDetails = 'picup_details',
  DriverName = 'driver_name',
  DriverId = 'driver_id',
  WaypointStatus = 'waypoint_status',
}

export enum PicupDetailsKeys {
  BusinessId = 'business_id',
  Consignment = 'consignment',
  CustomerId = 'customer_id',
  CustomerReference = 'customer_reference',
  DeliveryDate = 'delivery_date',
  LotteryId = 'lottery_id',
  Owner = 'owner',
  OwnerName = 'owner_name',
  ScheduledDate = 'scheduled_date',
  ShiftStart = 'shift_start',
  ShitEnd = 'shift_end',
  BucketId = 'sql_bucket_id',
  TripId = 'trip_id',
  TripType = 'trip_type',
  WarehouseName = 'warehouse_name',
  WarehouseId = 'warehouse_id',
  WaypointStatus = 'waypoint_status',
}

export enum PicupSources {
  Bucket = 'bucket',
  Business = 'business',
  Admin = 'admin',
  AdminCouriers = 'admin-couriers',
}

export enum CourierStatuses {
  OrderCreated = 'OrderCreated',
  WaybillCreated = 'WaybillCreated',
  WaybillCreationFailed = 'WaybillCreationFailed',
  WaybillAccepted = 'WaybillAccepted',
  WaybillAcceptedFailed = 'WaybillAcceptedFailed',
  OrderCollected = 'OrderCollected',
  OrderDelivered = 'OrderDelivered',
  OrderCompleted = 'OrderCompleted',
  OrderCancelled = 'OrderCancelled',
  DeliveryFailed = 'DeliveryFailed',
  Archived = 'Archived',
  OrderOutForDelivery = 'OrderOutForDelivery',
}

export enum DriverVehicleEnum {
  VehicleMotorcycle = 'vehicle-motorcycle',
  VehicleCar = 'vehicle-car',
  VehicleSmallVan = 'vehicle-small-van',
  VehicleLargeVan = 'vehicle-large-van',
  VehicleFreight = 'vehicle-freight',
}
