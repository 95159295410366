import { Warehouse } from './../../interfaces/auth.interfaces';
import { Action } from '@ngrx/store';
import { ScheduledDropDownEnum } from 'app/admin/admin-post-dispatch/admin-post-dispatch/admin-post-dispatch.interface';
import { Business } from 'app/interfaces/businesses.interfaces';

export const SET_POST_DISPATCH_FILTERS = 'SET_POST_DISPATCH_FILTERS';
export const CLEAR_POST_DISPATCH_FILTERS = 'CLEAR_POST_DISPATCH_FILTERS';

export class setPostDispatchFilters implements Action {
  readonly type = SET_POST_DISPATCH_FILTERS;
  constructor(
    public payload: { mode: string; warehouse: Warehouse; fleet_allocation: string; limit: number; business?: Business; scheduledMode?: ScheduledDropDownEnum }
  ) {}
}

export class clearPostDispatchFilters implements Action {
  readonly type = CLEAR_POST_DISPATCH_FILTERS;
}
