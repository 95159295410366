<h1 mat-dialog-title>Select Warehouses</h1>
<div mat-dialog-content>
  <div>Select warehouses that you'd like to use below:</div>
  <mat-form-field appearance="fill">
    <mat-label>Warehouses</mat-label>
    <mat-select [formControl]="warehouses" multiple>
      <mat-option *ngFor="let warehouse of data.Warehouses" [value]="warehouse.id">{{ warehouse.name }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Close</button>
  <button [disabled]="warehouses.value.length === 0" mat-button [mat-dialog-close]="warehouses.value">Confirm</button>
</div>
