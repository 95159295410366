import { Component, Input, SimpleChange, OnChanges } from '@angular/core';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { AlertsService } from '../alerts.service';

@Component({
  selector: 'app-alerts-table',
  templateUrl: './alerts-table.component.html',
  styleUrls: ['./alerts-table.component.scss']
})
export class AlertsTableComponent implements OnChanges {
  @Input() alertsData;

  vehicleAlertsTableMap = this.alertsService.vehicleAlertsTableMap
  vehicleAlertsTableColumns = this.alertsService.vehicleAlertsTableColumns

  searchString: string;
  searchType = true;
  filteredAlerts = [];
  numberOfResults = 15;
  shownAlerts = [];
  showViewMore = true;
  buttonTypes = ButtonTypes;


  constructor(
    public alertsService: AlertsService,
  ) { }

  toggleSearchType() {
    this.searchString = null
    this.searchType = !this.searchType
    this.filteredAlerts = [];
    this.showAlerts();
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes.update && changes.update.currentValue === changes.update.previousValue) {
      return;
    }
    this.showAlerts();
  }

  filterAlerts(searchString) {
    const newArray = this.alertsData.filter(alert => {
      return this.searchType ? alert.registration.toLowerCase().includes(searchString.toLowerCase()) : alert.event_description.toLowerCase().includes(searchString.toLowerCase());

    })
    this.filteredAlerts = newArray
    this.showAlerts();

  }

  loadMoreTableData() {
    this.shownAlerts = [];
    this.numberOfResults = this.numberOfResults + 5;
    this.showAlerts();
    if (this.alertsData.length <= this.shownAlerts.length) {
      this.showViewMore = false;
    }
  }

  showAlerts() {
    let i = 0;
    this.shownAlerts = [];

    if (this.filteredAlerts.length >= 0 && this.searchString) {
      this.filteredAlerts.forEach(alert => {
        i++
        if (i <= this.numberOfResults) {
          this.shownAlerts.push(alert)
        }
      })
      this.calculateViewMore(this.filteredAlerts, this.shownAlerts)
    } else {
      this.alertsData.forEach(alert => {
        if (alert) {
          i++
          if (i <= this.numberOfResults) {
            this.shownAlerts.push(alert)
          }
        }
      })
      this.calculateViewMore(this.alertsData, this.shownAlerts)
    }
  }

  calculateViewMore(comparison, shown) {
    if (comparison.length <= shown.length) {
      this.showViewMore = false
    } else {
      this.showViewMore = true
    }
  }
}
