import { Component, Input, OnChanges } from '@angular/core';
import { JustifyContent, FlexContainerProps } from './flex-container.interfaces';
import { UiThemes } from '../../interfaces/ui.interfaces';

@Component({
  selector: 'app-flex-container',
  templateUrl: './flex-container.component.html',
  styleUrls: ['./flex-container.component.scss'],
})
export class FlexContainerComponent implements FlexContainerProps, OnChanges {
  @Input() justifyContent: JustifyContent = JustifyContent.SpaceAround;
  @Input() borderBottom = false;
  @Input() borderTop = false;
  @Input() borderRight = false;
  @Input() borderedItems = false; // TODO: Make this work
  @Input() padding;
  @Input() theme: UiThemes;
  @Input() flexWrap;
  @Input() flexDirection = 'row';

  public classes: string[];

  ngOnChanges() {
    this.classes = this.setClasses();
  }

  setClasses() {
    const classes: string[] = [];
    if (this.theme) {
      classes.push(this.theme);
    }
    if (this.borderBottom) {
      classes.push('border-bottom');
    }
    if (this.borderTop) {
      classes.push('border-top');
    }
    if (this.borderRight) {
      classes.push('border-right');
    }
    if (this.borderedItems) {
      classes.push('bordered-items');
    }

    return classes;
  }
}
