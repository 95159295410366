import { IconTypes } from '../icon/icon.interfaces';
import { ModalSizes } from '../modals/modal.interfaces';

export interface RadioSelectData {
  title: string;
  message: string;
  modalSize: ModalSizes;
  icon: IconTypes;
  radioOptions: string[];
  selectedRadio?: string;
  details?: RadioSelectDetails;
}

export interface RadioSelectDetails {
  bucketId: string;
  businessName: string;
  warehouseName: string;
}

export enum RadioSelectActionEnum {
  Close = 'Close',
  Confirm = 'Confirm',
}
