import { Pipe, PipeTransform } from '@angular/core';
import { Warehouse } from 'app/interfaces/auth.interfaces';

@Pipe({
  name: 'warehouseName'
})
export class WarehouseNamePipe implements PipeTransform {

  transform(value: string[], ...args: [Warehouse[]]): unknown {
    const warehouseNames = [];
    value.forEach(id => {
      warehouseNames.push(args[0].find(warehouse => warehouse?.id === id)?.name);
    });
    return warehouseNames
  }

}
