import { Component, HostListener, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-reveal-more',
  templateUrl: './reveal-more.component.html',
  styleUrls: ['./reveal-more.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class RevealMoreComponent {
  public open = false;

  toggleMenu() {
    this.open = !this.open;
  }

  @HostListener('document:click', ['$event'])
  closeMenu(event) {
    if (!this.open) {
      return;
    }

    if (event.target.closest('.reveal-toggle')) {
      return;
    }

    this.open = false;
  }
}
