import { Component, OnInit, Input } from '@angular/core';
import { Color } from '@swimlane/ngx-charts';

@Component({
  selector: 'app-heatmap',
  templateUrl: './heatmap.component.html',
  styleUrls: ['./heatmap.component.scss'],
})
export class HeatmapComponent implements OnInit {
  @Input() noDataMessage;
  @Input() data;
  constructor() {}

  colorScheme = {
    domain: ['#F1453D', '#27B7FC', '#02DE73'],
  } as Color;

  ngOnInit() {}
}
