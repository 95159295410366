import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { AccessManagementService } from './access-management.service';
import { JwtHelperService } from 'app/shared/jwt-helper/jwt-helper.service';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService,
    private router: Router,
    private accessManagementService: AccessManagementService,
    public afAuth: AngularFireAuth,
    private jwtHelper: JwtHelperService) {}

  canActivate(): Observable<boolean> {

    const fsLogin = this.afAuth?.user?.pipe(take(1), map(user => {
      const valid = !!user
      if(!valid || !user || !user?.uid ){
        this.router.navigate(['/login']);
      }
      return valid;
    }));

    if(!fsLogin){
      this.router.navigate(['/login']);
      return of(false);
    }

    const auth = localStorage.getItem('acmToken');
    if(!auth || auth === 'undefined'){
      this.router.navigate(['/login']);
    }
    const tokenExpired = this.hasTokenExpired(auth);
    if(tokenExpired) {
      const refreshToken = localStorage.getItem('refreshToken');
      if(!refreshToken || refreshToken === 'undefined'){
        this.router.navigate(['/login']);
      }
      return this.refresh(refreshToken);
    }
    return fsLogin;
  }

  private hasTokenExpired(authToken): boolean {
    try{
      const decoded = this.jwtHelper.parseJwt(authToken);
      if(decoded){
        const date = decoded.exp;
        const currentDay = Math.floor(new Date().getTime()/1000.0)
        return (date - currentDay) <= 0;
      }
      return false;
    } catch {
      this.router.navigate(['/login']);
    }
  }

  private refresh(refreshToken: string): Observable<boolean> {
    return this.authService.refreshToken(refreshToken).pipe(
      take(1),
      switchMap((value) => {
        this.accessManagementService.setACMToken(value.AccessToken);
        this.accessManagementService.setRefreshToken(value.RefreshToken);
        return of(true);
      }),
      catchError(() => {
        this.router.navigate(['/login']);
        return of(false)
      })
    );
  }

}
