<app-modal
  [open]="true"
  [actions]="actions"
  [highlightPrimaryAction]="true"
  [size]="ModalSizes.Large"
  (handleAction)="handleAction($event)"
>
  <div class="modal-content">
    <heading 
      [icon]="IconTypes.Truck" 
      [color]="UiColors.Blue">
      <h4 class="heading-title">Preferred Drivers</h4>
      <div class="controls">
        <app-basic-button 
          [title]="'Refresh'" 
          [type]="buttonTypes.Primary" 
          (click)="getPreferredDrivers()"> 
        </app-basic-button>
      </div>
    </heading>

    <div class="modal-body">
      <div class="nearby">
        <app-table
          *ngIf="currentDriverTableData"
          [columns]="driverTableColumns"
          [dataMap]="driverTableDataMap"
          [rawData]="currentDriverTableData"
          [primaryActions]="currentDriverTablePrimaryActions"
          (actionTaken)="respondToTableAction($event)"
          [zeroDataMessage]="'No preferred Drivers Assigned'"
          [rowsSelectable]="false"
        >
        </app-table>
        <hr />
      </div>

      <div class="search">
        <h4>Search:</h4>
        <div class="search">
          <input-field
            [type]="'text'"
            (valueChanged)="driverQueryString = $event"
            [value]="driverQueryString"
            [label]="'Driver Name'"
            (keydown.enter)="findDriverByName()"
          ></input-field>
          <app-basic-button 
            [title]="'Search'" 
            [type]="buttonTypes.Highlight"
            [active]="true" 
            (click)="findDriverByName()">
          </app-basic-button>
        </div>
        <div class="search-table" *ngIf="searchResults">
          <app-table
            *ngIf="searchResults"
            [columns]="driverSearchTableColumns"
            [dataMap]="driverSearchTableDataMap"
            [rawData]="searchResults"
            [zeroDataMessage]="'No Drivers Match the Search Criteria'"
            [rowsSelectable]="false"
            [primaryActions]="searchDriverTablePrimaryActions"
            (actionTaken)="respondToTableAction($event)"
          >
          </app-table>
        </div>
      </div>
    </div>
  </div>
</app-modal>
