
    
    <!-- TODO: Previous Icon '/assets/img/icons/trip.svg' -->
    <heading 
      [icon]="icontype.Trip" 
      [color]="uiColours.Blue">
      <h4 class="heading-title">
        Trip <strong>{{tripId}}</strong>
      </h4>
      <div class="button-wrap">
        <app-basic-button
            [title]="'Toggle Map'"
            [type]="buttonTypes.Primary"
            (click)="toggleMap()">
        </app-basic-button>
        <app-basic-button
            [title]="'Back to trips'"
            [type]="buttonTypes.Secondary"
            (click)="actionCloseTripView()"
            [disable]="readOnly">
        </app-basic-button>
      </div>
    </heading>

    <div *ngIf="showMap" class="trip-map">
      <app-map 
        [mapType]="MapTypes.Trips" 
        [geoJson]="singleTripGeoJson"
        [hideFilters]="true">
      </app-map>
    </div>

    <div class="trip-table">
      <app-table [columns]="tripDetailsTableColumns"
                 [tableData]="tripOrders"
                 [showAnnotations]="true"
                 [rowsSelectable]="false"
                 [readOnly]="readOnly">
      </app-table>
    </div>
