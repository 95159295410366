import { Component, Input, ElementRef, ViewChild, OnInit, OnChanges } from '@angular/core';
import { getDashArray, getSpinnerDashArray, getLoaderLength } from './processing-indicator.helpers';
import { BucketStatuses } from '../../dashboard/buckets/buckets.interfaces';

@Component({
  selector: 'app-processing-indicator',
  templateUrl: './processing-indicator.component.html',
  styleUrls: ['./processing-indicator.component.scss'],
})
export class ProcessingIndicatorComponent implements OnInit, OnChanges {
  @ViewChild('processingIndicatorSpinner', { static: true }) processingIndicatorSpinner: ElementRef;
  @ViewChild('processingIndicatorLoader', { static: true }) processingIndicatorLoader: ElementRef;

  @Input() percentageComplete: number;
  @Input() title: string;
  @Input() status = 'Processing';
  @Input() message: string;
  @Input() timeLeft: string;
  @Input() error: string;

  // Default values
  loaderBgLength: number = null;
  loaderDashArray = '';

  _bucketStatuses = BucketStatuses;

  spinnerBgLength: number = null;
  spinnerLength = 90;
  spinnerDashArray = '';

  ngOnInit() {
    // Gets the length of the stroke for the Indicator Loader (the inner loading circle)
    this.loaderBgLength = this.processingIndicatorLoader.nativeElement.getTotalLength();
    this.calcSpinnerValues();
  }

  ngOnChanges() {
    this.calcSpinnerValues();
  }

  calcSpinnerValues() {
    // TODO Should these values update on window resize as well?
    if (this.percentageComplete < 1) {
      this.spinnerBgLength = this.processingIndicatorSpinner.nativeElement.getTotalLength();
      this.spinnerDashArray = getSpinnerDashArray(this.spinnerLength, this.spinnerBgLength);
    }

    this.loaderDashArray = getDashArray(
      getLoaderLength(this.percentageComplete, this.loaderBgLength),
      this.loaderBgLength
    );
  }
}
