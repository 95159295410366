import { NotificationsService } from './shared/notifications/notifications.service';
import { Injectable } from '@angular/core';
import axios from 'app/api/axios';
import { AutoNotesService } from './dashboard/notes/auto-notes.service';
import { AutoNotesTypes } from './dashboard/notes/notes.constants';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceService {
  constructor(
    private notificationsService: NotificationsService,
    private autoNotesService: AutoNotesService
  ) {}

  forceCompleteOrders(order_id: string): Promise<any> {
    return axios({
      method: 'POST',
      url: '/maintenance/force-complete-orders',
      data: [order_id],
    })
      .then((response) => {
        this.notify(true, 'Order Force-Completed');
        return response;
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  recreateTrip(order_id: string): Promise<any> {
    return axios({
      method: 'POST',
      url: '/order/recreate-order/' + order_id,
    })
      .then((response) => {
        this.notify(true, 'Trip Recreated');
        return response.data;
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  unassignOrderParcels(order_id: string): Promise<any> {
    return axios({
      method: 'POST',
      url: '/maintenance/unassign-order-parcels/' + order_id,
    })
      .then((response) => {
        this.autoNotesService.generateNote({
          autoNotesType: AutoNotesTypes.parcelsUnassigned,
          orderId: order_id
        })
        this.notify(true, 'Order Parcels Unassigned');
        return response;
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  clearLastMile(lastmile_id: string): Promise<any> {
    return axios({
      method: 'POST',
      url: '/last-mile/' + lastmile_id + '/clear-last-mile',
    })
      .then((response) => {
        this.notify(true, 'Last Mile Cleared');
        return response;
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  deleteOrdersForBucket(bucket_id: number): Promise<any> {
    return axios({
      method: 'POST',
      url: '/maintenance/delete-orders-for-bucket/' + bucket_id,
    })
      .then((response) => {
        this.notify(true, 'Bucket Orders Deleted');
        return response;
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  rehydrateFirestoreReadmodel(read_model_id: string, orderId: string): Promise<any> {
    return axios({
      method: 'POST',
      url: '/firestore/rehydrate-firestore-readmodel/' + read_model_id,
    })
      .then((response) => {
        this.autoNotesService.generateNote({
          autoNotesType: AutoNotesTypes.rehydrateOrder,
          orderId: orderId
        })
        this.notify(true, 'Readmodel Rehydrated');
        return response;
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  notify(successful: boolean, message: string): void {
    const notificationType = successful ? 'success' : 'error';
    this.notificationsService.publish({
      type: notificationType,
      message: message,
    });
  }
}

// {{picup-url}}/{{picup-version}}/firestore/rehydrate-firestore-readmodel/:rmid
