import {
  mfNestedLastEventHighlighter,
} from './../../shared/table/map-formatters.table.helpers';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { WaypointStates } from '../trips.enums';
import { TableColumn, TableDataMap, DisplayPipes, TableAction } from 'app/shared/table/table.interfaces';
import {
  mfHumanizeString,
  mfCountInArray,
  mfGetArrayPropertyCommaSeparated,
  mfLastEventHighlighter,
  mfAmountPaid,
  mfGetNestedObjectValue,
} from 'app/shared/table/map-formatters.table.helpers';
import { SQLOrdersTableColumns } from './new-trips.interfaces';
import { environment } from 'environments/environment';

export enum WaypointMarkerColors {
  pending = '#27B7FC',
  complete = '#02DE73',
  failed = '#F1453D',
  cancelled = '#F1453D',
  partial = '#F1453D',
  enRoute = '#F8E71C',
  dispatching ='#FF7100',
  visiting = '#FF7100',
  // $blue: #27B7FC;
  // $light-blue: #B3E8FF;
  // $green: #02DE73;
  // $yellow: #F8E71C;
  // $orange: #FF7100;
  // $red: #F1453D;
}
export const WaypointStatusConstant = {
  pending: {
    icon: IconTypes.Dot,
    color: UiColors.Blue,
    label: WaypointStates.Pending,
  },
  complete: {
    icon: IconTypes.Check,
    color: UiColors.Green,
    label: WaypointStates.Finalized,
  },
  cancelled: {
    icon: IconTypes.Cross,
    color: UiColors.Red,
    label: WaypointStates.Cancelled,
  },
  failed: {
    icon: IconTypes.Cross,
    color: UiColors.Red,
    label: WaypointStates.Failed,
  },
  enRoute: {
    icon: IconTypes.Dot,
    color: UiColors.Yellow,
    label: WaypointStates.EnRoute,
  },
  dispatching: {
    icon: IconTypes.Dot,
    color: UiColors.Orange,
    label: WaypointStates.Dispatching,
  },
  visiting: {
    icon: IconTypes.Dot,
    color: UiColors.Orange,
    label: WaypointStates.Visiting,
  },
  inProgress: {
    icon: IconTypes.Dot,
    color: UiColors.Orange,
    label: WaypointStates.Visiting,
  },
  partial: {
    icon: IconTypes.Cross,
    color: UiColors.Red,
    label: WaypointStates.PartialFailure,
  },
};

export const parcelOverviewColumns: TableColumn[] = [
  { name: 'parcel_state', displayName: 'Status' },
  { name: 'parcel_reference', displayName: 'Parcel Reference' },
  { name: 'waybill', displayName: 'Waybill Number' },
  { name: 'parcel_value', displayName: 'Parcel Value' },
  { name: 'amount_paid', displayName: 'Paid' },
  { name: 'payment_type', displayName: 'Payment Type' },
  { name: 'reconciled_reason', displayName: 'Reconciled Reason' },
  { name: 'failed_reason', displayName: 'Failure Reason' },
];

export const parcelOverviewDataMap: TableDataMap = {
  cells: {
    parcel_state: {
      column: 'parcel_state',
      map: ['ParcelState'],
      format: mfHumanizeString,
    },
    parcel_reference: {
      column: 'parcel_reference',
      map: 'ParcelReference',
      displayPipeArgs: ['none'],
    },
    waybill: {
      column: 'waybill',
      map: 'ParcelWaybill',
      displayPipeArgs: ['none'],
    },

    parcel_value: {
      column: 'ParcelValue',
      map: 'ParcelValue',
      prefix: environment.currency_symbol,
      displayPipeArgs: DisplayPipes.decimalsTwo,
    },
    amount_paid: {
      column: 'AmountPaid',
      map: 'AmountPaid',
      format: mfAmountPaid,
    },
    payment_type: {
      column: 'PaymentType',
      map: 'PaymentType',
      format: mfHumanizeString,
    },
    failed_reason: {
      column: 'Failure',
      map: 'FailedReason',
      format: mfHumanizeString,
    },
    reconciled_reason: {
      column: 'reconciled_reason',
      map: 'ReconciledReason',
      format: mfHumanizeString,
    },
  },
};

export const vehicleNames = new Map<string, string>([
  ['vehicle-motorcycle', 'Motorbike'],
  ['vehicle-car', 'Car'],
  ['vehicle-small-van', 'Small Van'],
  ['vehicle-large-van', 'Large Van'],
]);

export const vehicleTypes = new Map<string, IconTypes>([
  ['vehicle-motorcycle', IconTypes.Motorbike],
  ['vehicle-car', IconTypes.Car],
  ['vehicle-small-van', IconTypes.SmallVan],
  ['vehicle-large-van', IconTypes.SmallVan],
]);

export const SqlTripTableMap: TableDataMap = {
  cells: {
    [SQLOrdersTableColumns.TripID]: { column: SQLOrdersTableColumns.TripID, map: 'order_id' },
    [SQLOrdersTableColumns.FleetAllocation]: { column: SQLOrdersTableColumns.FleetAllocation, map: 'fleet_allocation' },
    [SQLOrdersTableColumns.CustomerReference]: {
      column: SQLOrdersTableColumns.CustomerReference,
      map: ['customer_references'],
    },
    [SQLOrdersTableColumns.DeliveryDate]: {
      column: SQLOrdersTableColumns.DeliveryDate,
      map: 'delivery_date',
    },
    [SQLOrdersTableColumns.OrderStatus]: {
      column: SQLOrdersTableColumns.OrderStatus,
      map: 'order_state',
      format: mfHumanizeString,
    },
    [SQLOrdersTableColumns.WarehouseName]: {
      column: SQLOrdersTableColumns.WarehouseName,
      map: 'warehouse_name',
    },

    [SQLOrdersTableColumns.LastEvent]: {
      column: SQLOrdersTableColumns.LastEvent,
      map: ['order', 'event_title'],
      format: mfGetNestedObjectValue,
      highlighted: false,
      highlightColorMap: ['order', 'event_health'],
      highlightColorClass: mfNestedLastEventHighlighter,
    },
    [SQLOrdersTableColumns.LastUpdated]: {
      column: SQLOrdersTableColumns.LastUpdated,
      map: ['order', 'event_timestamp'],
      displayPipeArgs: ['timeAgo'],
      format: mfGetNestedObjectValue,
    },
    [SQLOrdersTableColumns.DriverNames]: {
      column: SQLOrdersTableColumns.LastUpdated,
      map: ['drivers'],
      format: mfGetArrayPropertyCommaSeparated,
    },
    [SQLOrdersTableColumns.Waypoints]: {
      column: SQLOrdersTableColumns.Waypoints,
      map: ['contact_count'],
    },
    [SQLOrdersTableColumns.Parcels]: {
      column: SQLOrdersTableColumns.Parcels,
      map: 'parcel_count',
    },
  },
};

export const SqlTripTableColumns: TableColumn[] = [
  { name: SQLOrdersTableColumns.TripID, displayName: 'ID', hidden: true },
  { name: SQLOrdersTableColumns.CustomerReference, displayName: 'Reference', sortable: true },
  { name: SQLOrdersTableColumns.DeliveryDate, displayName: 'Delivery Date', sortable: true },
  { name: SQLOrdersTableColumns.OrderStatus, displayName: 'Status', sortable: true },
  { name: SQLOrdersTableColumns.WarehouseName, displayName: 'Warehouse', sortable: true },
  { name: SQLOrdersTableColumns.FleetAllocation, displayName: 'Fleet', sortable: true },
  { name: SQLOrdersTableColumns.LastEvent, displayName: 'Last Event', sortable: true },
  { name: SQLOrdersTableColumns.LastUpdated, displayName: 'Last Updated', sortable: true },
  { name: SQLOrdersTableColumns.DriverNames, displayName: 'Drivers', sortable: true },
  { name: SQLOrdersTableColumns.Waypoints, displayName: 'Contacts', sortable: true },
  { name: SQLOrdersTableColumns.Parcels, displayName: 'Parcels', sortable: true },
];

export const postDispatchSQLTablePrimaryActions: TableAction[] = [
  { event: 'viewTrip', title: 'View Trip', icon: IconTypes.Eye, icon_color: UiColors.Grey8 },
];

export const SqlTripAlertTableMap: TableDataMap = {
  cells: {
    [SQLOrdersTableColumns.TripID]: { column: SQLOrdersTableColumns.TripID, map: 'order_id' },
    [SQLOrdersTableColumns.FleetAllocation]: { column: SQLOrdersTableColumns.FleetAllocation, map: 'fleet_allocation' },
    [SQLOrdersTableColumns.CustomerReference]: {
      column: SQLOrdersTableColumns.CustomerReference,
      map: ['customer_references'],
    },
    [SQLOrdersTableColumns.DeliveryDate]: {
      column: SQLOrdersTableColumns.DeliveryDate,
      map: ['delivery_date'],
    },
    [SQLOrdersTableColumns.OrderStatus]: {
      column: SQLOrdersTableColumns.OrderStatus,
      map: 'order_state',
      format: mfHumanizeString,
      highlighted: true,
      highlightColorMap: ['order_state'],
      highlightColorClass: mfLastEventHighlighter,
    },
    [SQLOrdersTableColumns.WarehouseName]: {
      column: SQLOrdersTableColumns.WarehouseName,
      map: 'warehouse_name',
    },

    [SQLOrdersTableColumns.LastEvent]: {
      column: SQLOrdersTableColumns.LastEvent,
      map: 'event_name',
    },
    [SQLOrdersTableColumns.LastUpdated]: {
      column: SQLOrdersTableColumns.LastUpdated,
      map: 'event_timetamp',
      displayPipeArgs: ['timeAgo'],
    },
    [SQLOrdersTableColumns.DriverNames]: {
      column: SQLOrdersTableColumns.LastUpdated,
      map: ['drivers'],
      format: mfGetArrayPropertyCommaSeparated,
    },
    [SQLOrdersTableColumns.Waypoints]: {
      column: SQLOrdersTableColumns.Waypoints,
      map: ['contact_count'],
    },
    [SQLOrdersTableColumns.Consignments]: {
      column: SQLOrdersTableColumns.Consignments,
      map: 'consignments',
      format: mfCountInArray,
    },
    [SQLOrdersTableColumns.Parcels]: {
      column: SQLOrdersTableColumns.Parcels,
      map: 'parcel_count',
    },
  },
};

export const parcelTableColumns: TableColumn[] = [
  { name: 'BusinessReference', displayName: 'Business Reference', sortable: true, hidden: false },
  { name: 'ParcelWaybill', displayName: 'Parcel Waybill', sortable: true, hidden: false },
  { name: 'ParcelReference', displayName: 'Parcel Reference', sortable: true, hidden: false },
  { name: 'FormattedAddress', displayName: 'Address', sortable: true, hidden: false },
  { name: 'CustomerName', displayName: 'Customer Name', sortable: true, hidden: false },
  { name: 'OrderParcelState', displayName: 'State', sortable: true, hidden: false },
];

export const parcelTableDataMap: TableDataMap = {
  cells: {
    BusinessReference: { column: 'BusinessReference', map: 'BusinessReference' },
    ParcelWaybill: {
      column: 'ParcelWaybill',
      map: 'ParcelWaybill',
    },
    ParcelReference: { column: 'ParcelReference', map: 'ParcelReference' },
    FormattedAddress: { column: 'FormattedAddress', map: 'FormattedAddress' },
    CustomerName: { column: 'CustomerName', map: 'CustomerName' },
    OrderParcelState: { column: 'OrderParcelState', map: 'OrderParcelState' },
  },
};
