<app-section *ngIf="trips">
    <heading
    [icon]="icon"
    [color]="color"
    [showToggle]="true"
    (click)="collapsibleComplete.toggle()"
    >
    <h4 class="heading-title">
        <strong>{{tripsTotal ? tripsTotal : ''}} </strong>
        <span [ngPlural]="trips?.length">
        <ng-template ngPluralCase="=0">No {{tripType}}</ng-template>
        <ng-template ngPluralCase="other">{{tripType}}</ng-template>
        </span>
    </h4>
    </heading>
    <div appCollapsible [expanded]="expanded" #collapsibleComplete="appCollapsible">
    <app-table
        [columns]="tableColumns"
        [dataMap]="tableDataMap"
        [rawData]="trips"
        [primaryActions]="primaryActions"
        (actionTaken)="handleSQLTableActions($event)"
        [bulkActions]="bulkActions"
        [rowsSelectable]="bulkActions?.length > 0 ? true : false"
        [zeroDataMessage]="activeWarehouseFilter?.getValue() ? 'No trips for Selected Warehouse' : 'No ' + tripType"
        (paginate)="handlePagination($event)"
        [defaultDisplayAmount]="defaultDisplayAmount"
        [showPagination]="showPagination"
        [paginationDisplayAmounts]="paginationDisplayAmounts"
        [currentPageIndex]="currentPageIndex"
        [totalPages]="totalPages"
        [displayPageCount]="tripsTotal ? true : false"
        (tableSortChanged)="handleSort($event)"
    >
    </app-table>
    </div>
</app-section>
