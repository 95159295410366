import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import axios from 'app/api/axios';
import { SimpleModalService } from 'ngx-simple-modal';
import { NotificationsService } from '../../shared/notifications/notifications.service';
import { GeocodingToolComponent } from '../../shared/geocoding-tool/geocoding-tool.component';
import { TableColumn } from '../../shared/table/table.interfaces';
import { IconTypes } from '../../shared/icon/icon.interfaces';
import { UiColors } from '../../interfaces/ui.interfaces';
import { AuthService } from 'app/auth/auth.service';
import { selectorActingAs } from 'app/auth/auth.reducer';
import { ActingAs } from 'app/interfaces/auth.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';

@Component({
  selector: 'app-geocoding',
  templateUrl: './geocoding.component.html',
  styleUrls: ['./geocoding.component.scss'],
})
export class GeocodingComponent implements OnInit {
  filterargs: string;
  actingAs: ActingAs;
  addresses = [];
  shownAddresses = [];
  geocodeBusinessRef;
  address_summary;
  numberOfResults = 10;
  showViewMore = true;
  searchText;
  firstLoad = true;
  buttonType = ButtonTypes;

  public Icons = {
    Colors: UiColors,
    Types: IconTypes,
  };

  public showOriginalAddress = false;
  tableColumns: TableColumn[] = [
    { name: 'id', displayName: 'ID', hidden: true },
    { name: 'customerName', displayName: 'Customer Name' },
    { name: 'suburb', displayName: 'Suburb' },
    { name: 'postalCode', displayName: 'Postal Code' },
    { name: 'address', displayName: 'Address' },
  ];

  public tablePrimaryActions = [
    // { event: 'courier', title: 'Third Party', icon: 'courier.svg' },
    { event: 'geocode', title: 'Geocode', icon: IconTypes.Geocoder },
    // { event: 'trash', title: 'Remove', icon: 'trash.svg' }
  ];

  public tableBulkActions = [
    // { event: 'courier', title: 'Third Party', icon: 'courier.svg', icon_color: UiColors.Blue },
    { event: 'geocode', title: 'Geocode', icon: IconTypes.Geocoder, icon_color: UiColors.Green },
    // { event: 'trash', title: 'Remove', icon: 'trash.svg', icon_color: UiColors.Red }
  ];

  public tableSecondaryActions = [
    { event: 'change_address', title: 'Change Address', icon: IconTypes.Flag },
    { event: 'contact_info', title: 'Contact Info', icon: IconTypes.Profile},
  ];

  constructor(
    public authService: AuthService,
    public store: Store<any>,
    private simpleModalService: SimpleModalService,
    private notificationsService: NotificationsService
  ) {
    this.store.select(selectorActingAs).subscribe((next) => (this.actingAs = next));

    this.getSummary();
  }

  respondToTableAction(event) {
    const action = event.action.event;
    const addresses = [];
    if (action === 'geocode') {
      event.rows.forEach((element) => {
        addresses.push(element.cells[0].value);
      });
      this.openGeocoder(addresses, null);
    }
  }

  openGeocoder(addresses, bucket_id) {
    const config = {
      addresses: addresses,
      bucket_id: bucket_id,
    };
    this.simpleModalService.addModal(GeocodingToolComponent, config).subscribe((result) => {
      if (result) {
        const notificationType = result.successful ? 'success' : 'error';
        this.notificationsService.publish({
          type: notificationType,
          message: result.message ? result.message : 'No addresses to Geocode',
        });
      }
      this.getSummary();
    });
  }

  search(searchText) {
    axios({
      method: 'POST',
      url: '/geocoder/' + this.actingAs.id + '/search-address',
      data: { search_text: searchText },
    }).then((response) => {
      this.addresses = response?.data;
      this.showAddresses();
    });
  }

  loadMoreTableData() {
    this.numberOfResults = this.numberOfResults + 5;
    this.showAddresses();
    if (this.addresses.length <= this.shownAddresses.length) {
      this.showViewMore = false;
    }
  }

  showAllData() {
    this.numberOfResults = null;
    this.showViewMore = false;
    this.showAddresses();
  }

  getSummary() {
    axios({
      method: 'GET',
      url: '/business/' + this.actingAs.id + '/address-status-summary',
    }).then((response) => {
      this.address_summary = response?.data;
    });
  }

  showAddresses() {
    this.firstLoad = false;
    let i = 0;
    this.shownAddresses = [];
    if (!this.numberOfResults) {
      this.addresses.map((x) => {
        this.shownAddresses.push({
          cells: [
            {
              column: 'id',
              value: x.addressId,
              highlighted: false,
            },
            {
              column: 'customerName',
              value: x.customer_name,
              highlighted: false,
            },
            {
              column: 'suburb',
              value: x.geocoded_suburb,
              annotation: x.suburb,
              highlighted: false,
            },
            {
              column: 'postalCode',
              value: x.geocoded_postal_code,
              displayPipeArgs: ['none'],
              annotation: x.postal_code,
              highlighted: false,
            },
            {
              column: 'address',
              value: x.geocoded_address,
              annotation: x.address,
              annotation_color: '#FF7100',
              highlighted: false,
            },
          ],
        });
      });
    } else {
      this.addresses.map((x) => {
        i++;
        if (i <= this.numberOfResults) {
          this.shownAddresses.push({
            cells: [
              {
                column: 'id',
                value: x.addressId,
                highlighted: false,
              },
              {
                column: 'customerName',
                value: x.customer_name,
                highlighted: false,
              },
              {
                column: 'suburb',
                value: x.geocoded_suburb,
                annotation: x.suburb,
                highlighted: false,
              },
              {
                column: 'postalCode',
                value: x.geocoded_postal_code,
                displayPipeArgs: ['none'],
                annotation: x.postal_code,
                highlighted: false,
              },
              {
                column: 'address',
                value: x.geocoded_address,
                annotation: x.address,
                annotation_color: '#FF7100',
                highlighted: false,
              },
            ],
          });
        }
      });
      if (this.addresses.length <= this.shownAddresses.length) {
        this.showViewMore = false;
      }
    }
  }

  ngOnInit() {}
}
