import { Component, OnInit, Input } from '@angular/core';
import { ModalSizes, ModalAction } from '../modal.interfaces';
import { GridJustifyItems } from 'app/shared/grid-container/grid-container.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { NotificationsService } from 'app/shared/notifications/notifications.service';

export interface ConfirmInputModel {
  title: string;
  key: string;
  message: string;
}

@Component({
  selector: 'app-confirm-input-modal',
  templateUrl: './confirm-input-modal.component.html',
  styleUrls: ['./confirm-input-modal.component.scss']
})
export class ConfirmInputModalComponent extends SimpleModalComponent<ConfirmInputModel, boolean> implements ConfirmInputModel {
  public ModalSizes = ModalSizes;
  public GridJustifyItems = GridJustifyItems;
  public IconTypes = IconTypes;
  public UiColors = UiColors;
  public UiThemes = UiThemes;
  public ButtonTypes = ButtonTypes;
  public actions: ModalAction[] = [
    { name: 'cancel', title: 'Cancel' },
    { name: 'confirm', title: 'Confirm', isPrimary: true }
  ];

  title: string;
  message: string;
  key: string;
  user_input: string;

  constructor(private notificationsService: NotificationsService) {
    super();
  }

  handleAction($event) {
    switch ($event) {
      case 'cancel':
        this.result = false;
        this.close();
        break;
      case 'confirm':
        if (this.user_input === this.key) {
          this.result = true;
          this.close();
        } else {
          this.notificationsService.publish({ type: 'error', message: 'Incorrect Input' });
        }
        break;
    }
  }
}
