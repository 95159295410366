import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { MapTypes } from 'app/shared/map/map.interfaces';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { ManageBucketService } from 'app/dashboard/buckets/manage-bucket.service';
import { NotificationsService } from 'app/shared/notifications/notifications.service';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';

@Component({
  selector: 'app-crossdock',
  templateUrl: './crossdock.component.html',
  styleUrls: ['./crossdock.component.scss'],
})
export class CrossdockComponent extends SimpleModalComponent<any, any> implements OnInit {
  geoJson;
  color = ['#02DE73', '#F8E71C', '#FF7100', '#F1453D', '#B3E8FF'];
  activeBucket;
  crossDockResult;
  selection = [];
  crossdocks = [];
  dropDownOptions = [];
  mapStyle = {
    'height.px': +(100 + '%'),
  };

  public Icons = {
    Colors: UiColors,
    Types: IconTypes,
  };
  public mapTypes = MapTypes;
  buttonTypes = ButtonTypes

  constructor(private manageBucketService: ManageBucketService, private notificationsService: NotificationsService) {
    super();
  }

  ngOnInit() {
    this.getPeerWarehouses();
    this.addOption();
  }

  process() {
    this.result = this.crossDockResult;
    this.close();
  }

  cancel() {
    this.result = undefined;
    this.close();
  }

  getPeerWarehouses() {
    const blankArray = [];
    this.manageBucketService.getCrossDockGeoJson(this.activeBucket.bucket_id, blankArray).then((response) => {
      this.selection = response.result.warehouses;
      this.getStartingWarehouse();
    });
  }

  addOption() {
    this.dropDownOptions.push({ value: null });
  }

  addWarehouse(warehouse, i) {
    const warehouse_model = {
      warehouse_id: warehouse.warehouse_id,
      warehouse_name: warehouse.warehouse_name,
      latitude: warehouse.latitude,
      longitude: warehouse.longitude,
      color: this.color[i + 1],
    };
    this.crossdocks[i + 1] = warehouse_model;
  }

  getStartingWarehouse() {
    let i = 0;
    let index;
    this.selection.forEach((warehouse) => {
      i++;
      if (warehouse.warehouse_id === this.activeBucket.warehouse_id) {
        const warehouse_model = {
          warehouse_id: warehouse.warehouse_id,
          warehouse_name: warehouse.warehouse_name,
          latitude: warehouse.latitude,
          longitude: warehouse.longitude,
          color: this.color[0],
        };
        this.crossdocks[0] = warehouse_model;
        index = i;
      }
    });
    this.selection.splice(index - 1, 1);
    this.getGeoJson();
  }

  getGeoJson() {
    if (this.crossdocks[0]) {
      this.manageBucketService
        .getCrossDockGeoJson(this.activeBucket.bucket_id, this.crossdocks)
        .then((response) => {
          this.geoJson = response.geojson;
          this.crossDockResult = response.result;
        })
        .catch((error) => this.notificationsService.publish({ type: 'error', message: error.response?.data.message }));
    }
  }
}
