import { Injectable } from '@angular/core';
import { FormattedAddress, StartEndPointAddress } from './start-end-point.interfaces';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class StartEndPointService {
  outModel$ = new BehaviorSubject<StartEndPointAddress>({
    startAddress: null,
    endAddress: null
  });

  isFormValid: boolean = true

  set startAddress(address: FormattedAddress) {
    this.outModel$.next({
      startAddress:
        address ? {
          address: address?.address,
          lat: address?.lat,
          lng: address?.lng,
          warehouseId: address?.warehouseId,
          reason: this.outModel$?.value?.startAddress?.reason
        } : null,
      endAddress:
        this.outModel$.value?.endAddress ? {
          address: this.outModel$.value?.endAddress?.address,
          lat: this.outModel$.value?.endAddress?.lat,
          lng: this.outModel$.value?.endAddress?.lng,
          warehouseId: this.outModel$.value?.endAddress?.warehouseId,
          reason: this.outModel$?.value?.endAddress?.reason
        } : null
    });
  }

  set endAddress(address: FormattedAddress) {
    this.outModel$.next({
      endAddress: address ? {
        address: address?.address,
        lat: address?.lat,
        lng: address?.lng,
        warehouseId: address?.warehouseId,
        reason: this.outModel$.value.endAddress?.reason
      } : null,
      startAddress: this.outModel$.value?.startAddress ? {
        address: this.outModel$.value?.startAddress?.address,
        lat: this.outModel$.value?.startAddress?.lat,
        lng: this.outModel$.value?.startAddress?.lng,
        warehouseId: this.outModel$.value?.startAddress?.warehouseId,
        reason: this.outModel$.value?.startAddress?.reason
      } : null
    });
  }

  set startReason(reason: string) {
    this.outModel$.next({
      startAddress: {
        address: this.outModel$.value?.startAddress?.address,
        lat: this.outModel$.value?.startAddress?.lat,
        lng: this.outModel$.value?.startAddress?.lng,
        warehouseId: this.outModel$.value?.startAddress?.warehouseId,
        reason: reason
      },
      endAddress: this.outModel$.value?.endAddress ? {
        address: this.outModel$.value?.endAddress?.address,
        lat: this.outModel$.value?.endAddress?.lat,
        lng: this.outModel$.value?.endAddress?.lng,
        warehouseId: this.outModel$.value?.endAddress?.warehouseId,
        reason: this.outModel$.value.endAddress?.reason
      } : null
    });
  }

  set endReason(reason: string) {
    this.outModel$.next({
      startAddress: {
        address: this.outModel$.value?.startAddress?.address,
        lat: this.outModel$.value?.startAddress?.lat,
        lng: this.outModel$.value?.startAddress?.lng,
        warehouseId: this.outModel$.value?.startAddress?.warehouseId,
        reason: this.outModel$.value?.startAddress?.reason
      },
      endAddress: {
        address: this.outModel$.value?.endAddress?.address,
        lat: this.outModel$.value?.endAddress?.lat,
        lng: this.outModel$.value?.endAddress?.lng,
        warehouseId: this.outModel$.value?.endAddress?.warehouseId,
        reason: reason
      }
    });
  }

  resetStartEndTasks(): void {
    this.outModel$.next({
      startAddress: null,
      endAddress: null
    });
  }

  saveData(): boolean {
    if (this.isFormValid) {
      return false
    }
    return true;
  }
}


