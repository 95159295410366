<app-modal
  [open]="true"
  [actions]="actions"
  [highlightPrimaryAction]="true"
  [size]="ModalSizes.Large"
  (handleAction)="handleAction($event)"
>
  <div class="modal-content">
    <heading [icon]="IconTypes.Map" [color]="UiColors.Blue">
      <h4 *ngIf="!business_id" class="heading-title">Picup Area Editor</h4>
      <h4 *ngIf="business_id" class="heading-title">Zone Editor</h4>
      <h4 class="warning" *ngIf="confirmed !== undefined && !confirmed && business_id">
        Overlapping zones will be overwritten
      </h4>
      <h4 class="green" *ngIf="confirmed !== undefined && confirmed && business_id">
        No Overlapping Zones. Edits will be added.
      </h4>
      <div class="controls">
        <app-basic-button
          *ngIf="!drawMode"
          [title]="'Draw'"
          [type]="buttonTypes.Highlight"
          [active]="true"
          (click)="toggleDrawMode()"
        >
        </app-basic-button>
        <app-basic-button
          *ngIf="drawMode"
          [title]="currentMode ? 'Add' : 'Subtract'"
          [type]="currentMode ? buttonTypes.Highlight : buttonTypes.Error"
          [active]="false"
          [disabled]="this.new_zone.length > 0"
          (click)="toggleMode()"
        >
        </app-basic-button>
        <app-basic-button 
          id="reset" 
          [title]="'Reset'" 
          [type]="buttonTypes.Primary" 
          (click)="reset()"> 
        </app-basic-button>
      </div>
    </heading>

    <div class="modal-body">
      <div class="map-wrap">
        <google-map
          width="100%"
          height="100%"
          (mapReady)="mapElementReady($event)"
          [zoom]="12"
          [options]="mapOptions"
        >
          <!-- <agm-data-layer 
            *ngIf="zone" 
            [geoJson]="zone.geojson" 
            [style]="confirmed ? confirmStyle : highlightStyle">
          </agm-data-layer>
          <agm-data-layer
            *ngIf="intersecting_zones"
            [geoJson]="intersecting_zones"
            [style]="intersectingStyle"
          ></agm-data-layer>

          <ng-template ngFor let-index="index" [ngForTrackBy]="trackByFunction" let-zone [ngForOf]="selected_suburbs">
            <agm-data-layer [geoJson]="zone" [style]="suburbStyle" (layerClick)="removeZone(index)"> </agm-data-layer>
          </ng-template> -->
        </google-map>
      </div>
    </div>
  </div>
</app-modal>
