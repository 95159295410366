<div class="grids">
  <div class="first-grid" [class.alightmentRight]="alignment === 'right'">
    <div class="first-image">
      <img src="{{ imgSrc }}" alt="" />
    </div>
    <div class="content-grid">
      <div class="first-content">
        <h2>{{ title }}</h2>
        <img src="/assets/img/packages/underline.svg" alt="" />
        <p>
          {{ subtitle }}
        </p>
      </div>

      <div class="arrow" routerLink="{{ linkText }}">
        <svg-icon [applyClass]="true" [src]="'/assets/img/icons/' + IconTypes.SecondArrow + '.svg'"></svg-icon>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
</div>
