import { Component, OnInit } from '@angular/core';
import { ModalSizes, ModalAction } from 'app/shared/modals/modal.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { UiColors } from 'app/interfaces/ui.interfaces';
import * as moment from 'moment';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  selector: 'app-driver-shift-select-modal',
  templateUrl: './driver-shift-select-modal.component.html',
  styleUrls: ['./driver-shift-select-modal.component.scss'],
})
export class DriverShiftSelectModalComponent extends SimpleModalComponent<any, any> implements OnInit {
  public ModalSizes = ModalSizes;
  public IconTypes = IconTypes;
  public UiColors = UiColors;
  public actions: ModalAction[] = [
    { name: 'close', title: 'Cancel', isPrimary: false },
    { name: 'selectShift', title: 'Select', isPrimary: true },
  ];
  shift_start;
  shift_end;
  initial_times;
  delivery_date = moment(new Date()).format('YYYY-MM-DD');

  constructor() {
    super();
  }

  dateChanged($event) {
    this.delivery_date = moment($event).format('YYYY-MM-DD');
  }
  changeShift($event) {
    this.shift_start = moment.utc($event[0] * 3600000).format('HH:mm');
    this.shift_end = moment.utc($event[1] * 3600000).format('HH:mm');
  }

  ngOnInit(): void {
    this.initial_times = [
      this.shift_start ? this.timeStringToFloat(this.shift_start) : this.timeStringToFloat('07:00'),
      this.shift_end ? this.timeStringToFloat(this.shift_end) : this.timeStringToFloat('19:00'),
    ];
  }

  handleAction($event) {
    switch ($event) {
      case 'close':
        this.result = undefined;
        this.close();
        break;
      case 'selectShift': {
        const shift_details = {
          delivery_date: this.delivery_date,
          shift_start: this.shift_start,
          shift_end: this.shift_end,
        };
        this.result = shift_details;
        this.close();
      }
    }
  }
  timeStringToFloat(time) {
    const hoursMinutes = time.split(/[.:]/);
    const hours = parseInt(hoursMinutes[0], 10);
    const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    return hours + minutes / 60;
  }
}
