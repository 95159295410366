import { Component, Input, OnChanges } from '@angular/core';
import { IconTypes } from '../icon/icon.interfaces';
import { UiColors } from '../../interfaces/ui.interfaces';
import { IconTimestampStatusProps } from './icon-timestamp-status.interfaces';
import * as moment from 'moment';

@Component({
  selector: 'app-icon-timestamp-status',
  templateUrl: './icon-timestamp-status.component.html',
  styleUrls: ['./icon-timestamp-status.component.scss'],
})
export class IconTimestampStatusComponent implements IconTimestampStatusProps, OnChanges {
  @Input() icon: IconTypes;
  @Input() iconColor: UiColors;
  @Input() iconSizeRem: number;
  @Input() text: string;
  @Input() sizeRem: number;
  @Input() timestamp: string;
  @Input() distance;
  @Input() duration;
  @Input() failures;

  @Input() start;
  @Input() end;

  @Input() color: UiColors = UiColors.White;
  @Input() uppercase = true;
  @Input() divider = '|';
  @Input() timeFormat = 'HH:mm';

  formattedText: string;
  durationFormat: any;
  dateTime: string;
  failureText: string;

  ngOnChanges() {
    if (this.timestamp) {
      this.dateTime = moment(this.timestamp).format(this.timeFormat);
    } else if (this.distance !== undefined) {
      this.dateTime = Math.ceil(this.distance / 1000).toString() + 'km';
    }
    if (this.end) {
      const x = moment(this.start);
      const y = moment(this.end);
      if (this.start) {
        this.durationFormat = moment.duration(x.diff(y)).humanize();
      } else {
        this.durationFormat = 'No Data';
      }
    }
    if (this.failures > 0) {
      this.failureText = this.failures + ' failed parcels';
    }
    this.formattedText =
      this.text +
      (this.end ? ' ' + this.divider + ' ' + this.durationFormat : '') +
      (this.dateTime ? ' ' + this.divider + ' ' + this.dateTime : '');
  }
}
