<app-section>
  <heading 
    [icon]="iconTypes.Phone" 
    [color]="uiColours.Blue">
    <h4 class="heading-title">Address Book</h4>
    <div class="button-wrap">
      <app-basic-button 
      [title]="'Add Address'" 
      [type]="buttonTypes.Primary" 
      (click)="addAddress()"> 
    </app-basic-button>
    </div>
  </heading>
  <div class="actions-wrap" *ngIf="addresses.length > 5">
    <div class="search-bar">
      <div class="input">
        <label for="search">
          <svg-icon src="/assets/img/icons/search.svg" class="icon"></svg-icon>
        </label>
        <input
          type="text"
          placeholder="Filter..."
          (ngModelChange)="searchString = $event; filterAddresses(searchString)"
          [ngModel]="searchString"
          (keydown.enter)="filterAddresses(searchString)"
        />
      </div>
    </div>
  </div>

  <app-table
    *ngIf="addresses && addresses.length > 0; else noData"
    [rawData]="shownAddresses"
    [columns]="addressColumns"
    [dataMap]="addressTableDataMap"
    [primaryActions]="tablePrimaryActions"
    (actionTaken)="respondToTableActions($event)"
    [rowsSelectable]="false"
    (moreDataRequested)="loadMoreTableData()"
    [showViewMore]="showViewMore"
  ></app-table>

  <ng-template #noData>
    <app-grid-container
      [columns]="'repeat(1, 1fr)'"
      [padding]="'4rem 5rem'"
      [colGap]="'2rem'"
      [rowGap]="'2rem'"
      [theme]="UiThemes.Light"
    >
      <h3 class="noData">No addresses present, add one now.</h3>
    </app-grid-container>
  </ng-template>
</app-section>
