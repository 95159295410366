import { Component } from '@angular/core';
import { LocalStorageService } from './shared/local-storage.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { FreshworksService } from './shared/freshworks/freshworks.service';

@Component({
  selector: 'mdb-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.css'],
})
export class AppComponent {
  service;
  apiLoaded: Observable<boolean>;

  constructor(private localStorageService: LocalStorageService, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer, private freshworksService: FreshworksService) {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleApiKey}&callback=initMap&libraries=visualization,geometry,places,drawing`;
    script.async = true;
    document.head.appendChild(script);
    this.localStorageService.init();

    iconRegistry
      .addSvgIcon(
        'summarize',
        sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/summarize'),
      )

    iconRegistry
      .addSvgIcon(
        'palette',
        sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/color'),
      )
  }
}
