import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { IconTypes } from '../icon/icon.interfaces';
import { MenuItem } from './menu.interface';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  @Input() label: string = 'Actions';
  @Input() items: MenuItem[] = [];
  @Input() theme: string = 'empty'; // empty, fill
  @Input() isOpen: boolean = false;
  @Input() color: UiColors = UiColors.Grey2;
  @Input() iconType: IconTypes = IconTypes.Down;
  @Input() iconSize: number = 1.2;
  @Output() itemSelected: EventEmitter<MenuItem> = new EventEmitter<MenuItem>();
  @Input() isNested: boolean = false;
  nestedMenuItems: MenuItem[] = [];
  activeItem: MenuItem;
  constructor() {}

  ngOnInit(): void {}

  @HostListener('window:click')
  clickout(): void {
    this.isOpen = false;
    this.nestedMenuItems = [];
    this.activeItem = null;
  }

  setIsOpen($event: Event): void {
    $event.stopPropagation();
    this.isOpen = !this.isOpen;
  }

  selectItem(item: MenuItem): void {
    this.itemSelected.emit(item);
  }

  itemHover(item: MenuItem): void {
    if (item.nestedItems && item.nestedItems.length > 0) {
      this.activeItem = item;
      this.nestedMenuItems = item.nestedItems;
    } else {
      this.activeItem = null;
      this.nestedMenuItems = [];
    }
  }
}
