<main>
  <app-header *ngIf="!loading"></app-header>

  <app-navigation *ngIf="!loading"></app-navigation>
  <!-- <div class="overlay" *ngIf="showBanner">
    <div class="content">
      <div class="lockdown">
        <h2>Lock-Down Operating Procedure</h2>
        <p>
          President Cyril Ramaphosa’s statement on the Escalation of Measures to Combat COVID-19 Epidemic on 23 March
          2020 confirms that "Companies that are essential to the production and transportation of food, basic goods and
          medical supplies will remain open".
        </p>
        <p>
          <strong
            >In this regard, we note that Picup Technologies (Pty) Ltd may only engage in the transportation of the
            following:</strong
          >
        </p>
        <ul>
          <li class="list-header">1. Food</li>
          <li>
            <ul>
              <li>a. Any food product, including non-alcoholic beverages</li>
              <li>b. Animal food; and</li>
              <li>c. Chemicals, packaging, and ancillary products used in the production of any food product.</li>
            </ul>
          </li>
          <li class="list-header">
            2. Cleaning and hygiene products
          </li>
          <li>
            <ul>
              <li>a. Toilet Paper, sanitary pads, sanitary tampons, condoms;</li>
              <li>b. Hand sanitiser, disinfectants, soap, alcohol for industrial use, household cleaning products and
                personal
                protective equipment; and</li>
              <li>c. Chemicals, packaging and ancillary products used in the production of any of the above.</li>
            </ul>
          </li>
          <li class="list-header">3. Medical</li>
          <li>
            <ul>
              <li>a. Medical and hospital supplies, equipment and personal protective equipment; and</li>
              <li>b. Chemicals, packaging and ancillary products used in the production of any of the above.</li>
            </ul>
          </li>
          <li class="list-header">4. Fuel, including coal and gas</li>
          <li class="list-header">5. Basic goods, including airtime and electricity.</li>
          <li class="list-header">6. Deliveries for goods allowed in the current stage of Lockdown.</li>
          <li class="list-header">All products with the exception of Alcohol, and Tobacco</li>
        </ul>
        <p>
          <strong
            >Please note, in order to comply with the new curfew times. We will not be accepting deliveries after
            18h30.</strong
          >
        </p>
        <p>
          If you are in doubt whether your product can be transported during the lockdown period, please contact
          <a href="mailto:info@picup.co.za"></a>info@picup.co.za.
        </p>
        <p>
          <strong
            >Should customers attempt to distribute anything other than the above products, we will not be permitted to
            assist and cancellation fees may apply to orders.</strong
          >
        </p>
        <br />
        <button class="button" (click)="showBanner = false">Accept</button>
      </div>
    </div>
  </div> -->
  <div class="container margin-bottom-25" *ngIf="!loading">
    <router-outlet></router-outlet>
  </div>

  <div class="loader-wrap" *ngIf="loading">
    <div class="loader">
      <img src="/assets/img/loader-dark.gif" alt="" />
    </div>
  </div>
</main>
