import { Component,  OnInit, Input } from '@angular/core';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { IconTypes } from '../icon/icon.interfaces';

@Component({
  selector: 'app-static-banner',
  templateUrl: './static-banner.component.html',
  styleUrls: ['./static-banner.component.scss'],
})
export class StaticBannerComponent implements OnInit {
  UiThemes = UiThemes;
  IconTypes = IconTypes;
  UiColours = UiColors;

  @Input() showCurve = true;
  @Input() textTitle: string;
  @Input() textContent: string;
  @Input() ctaText: string;
  @Input() ctaLink: string;
  @Input() ctaIcon: IconTypes;
  @Input() ctaTheme: UiThemes;
  @Input() backgroundImgUrl: string;
  @Input() backgroundOverlay;
  @Input() showKarooBanner: string = 'nothome';
  constructor() {}

  ngOnInit(): void {}
}
