import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { Store } from '@ngrx/store';
import { selectorUser } from 'app/auth/auth.reducer';
import { AuthUser } from 'app/interfaces/auth.interfaces';
import { switchMap, takeUntil } from 'rxjs/operators';
import { NotificationsService } from 'app/shared/notifications/notifications.service';
@Injectable({
  providedIn: 'root',
})
export class NotesService {
  user: AuthUser;
  noteIds = [];
  notes$: Observable<any>;
  orderNotes$ = new BehaviorSubject(undefined);
  adminNotes$: Observable<any>;
  newNotes$ = new BehaviorSubject(undefined);
  notesRef: AngularFirestoreCollection<any>;
  typeFilter$: BehaviorSubject<string | null>;
  publicFilter$: BehaviorSubject<boolean | null>;
  authorFilter$: BehaviorSubject<string | null>;
  categoryFilter$: BehaviorSubject<string | null>;
  unsubscribe$ = new Subject<void>()
  private _loadingNotes$ = new BehaviorSubject<boolean>(false);
  readonly loadingNotes$ = this._loadingNotes$.asObservable();

  constructor(private firestore: AngularFirestore, private store: Store<any>, private notificationService: NotificationsService) {
    this.store.select(selectorUser).pipe(takeUntil(this.unsubscribe$)).subscribe((next) => (this.user = next));
  }

  public getAdminNotes(): void {
    this.typeFilter$ = new BehaviorSubject(null);
    this.categoryFilter$ = new BehaviorSubject(null);
    this.publicFilter$ = new BehaviorSubject(null);
    this.authorFilter$ = new BehaviorSubject(null);
    this.adminNotes$ = combineLatest([
      this.categoryFilter$,
      this.typeFilter$,
      of(this.user.is_admin),
      this.publicFilter$,
      this.authorFilter$,
    ]).pipe(
      switchMap(([category, type, user, publicNote, author_name]) =>
        this.firestore
          .collection('notes', (ref) => {
            let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
            if (category) {
              query = query.where('category', '==', category);
            }
            if (type) {
              query = query.where('origin', '==', type);
            }
            if (!user) {
              query = query.where('public', '==', true);
            }
            if (publicNote !== null) {
              query = query.where('public', '==', publicNote);
            }
            if (author_name) {
              query = query.where('author_name', '==', author_name);
            }
            query = query.orderBy('timestamp', 'desc');
            query = query.limit(1000);
            return query;
          })
          .valueChanges()
      )
    );
  }

  public getDriverNotes(driver_id: string): void {
    this.notes$ = combineLatest([of(this.user.is_admin)]).pipe(
      switchMap(([user]) =>
        this.firestore
          .collection('notes', (ref) => {
            let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
            query = query.where('driver_id', '==', driver_id);
            if (!user) {
              query = query.where('public', '==', true);
            }
            query = query.orderBy('timestamp', 'asc');
            return query;
          })
          .valueChanges()
      )
    );
  }

  public markNotesAsViewed(): void {
    const docRef = this.firestore.collection('notes');
    this.noteIds.forEach((note) => {
      docRef.doc(note).update({
        read_by: firebase.firestore.FieldValue.arrayUnion(this.user.user_id),
      });
    });
    this.newNotes$.next(undefined);
  }

  public saveNote(note_data: any): void {
    note_data.content = this.HTMLParse(note_data.content);
    note_data['timestamp'] = firebase.firestore.FieldValue.serverTimestamp();
    this.firestore.collection('notes').add(note_data);
  }

  HTMLParse(content: string): string {
    const span = document.createElement('span');
    span.innerHTML = content;
    return span.textContent || span.innerText;
  }

  getBucketNotes(bucket_id: string): void {
    this.orderNotes$.next(undefined);
    this.notesRef = this.firestore.collection('notes');
    this.notesRef.ref
      .where('bucket_id', '==', bucket_id)
      .orderBy('timestamp', 'asc')
      .onSnapshot((querySnapshot) => {
        const picupNotes = [];
        this.noteIds = [];
        querySnapshot.forEach((note) => {
          if (this.user.is_admin) {
            picupNotes.push(note.data());
            this.noteIds.push(note.id);
          } else if (note.data().public === true) {
            picupNotes.push(note.data());
            this.noteIds.push(note.id);
          }
        });
        this.orderNotes$.next(picupNotes);
        picupNotes.forEach((note) => {
          if (!note.read_by || !note.read_by.includes(this.user.user_id)) {
            this.newNotes$.next(true);
          }
        });
      });
  }

  getOrderNotes(order_id: string): void {
    this._loadingNotes$.next(true);
    this.orderNotes$.next(undefined);
    this.notesRef = this.firestore.collection('notes');
    this.notesRef.ref
      .where('order_id', '==', order_id)
      .orderBy('timestamp', 'asc')
      .onSnapshot((querySnapshot) => {
        const picupNotes = [];
        this.noteIds = [];
        querySnapshot.forEach((note) => {
          if (this.user.is_admin) {
            picupNotes.push(note.data());
            this.noteIds.push(note.id);
          } else if (note.data().public === true) {
            picupNotes.push(note.data());
            this.noteIds.push(note.id);
          }
        });
        this.orderNotes$.next(picupNotes);
        picupNotes.forEach((note) => {
          if (!note.read_by || !note.read_by.includes(this.user.user_id)) {
            this.newNotes$.next(true);
          }
        });
        this._loadingNotes$.next(false);
      });
  }

  getOrderNotesActual(order_id: string): any{
    this.notesRef = this.firestore.collection('notes');
    return this.notesRef.ref
      .where('order_id', '==', order_id)
      .orderBy('timestamp', 'asc')
      .get();
  }

  // the above 2 functions are slow and incorrect still need to figure out what they actually do
  // and phase them out

  getOrderNotesActualPt3(order_id: string): void{
    this._loadingNotes$.next(true);
    this.firestore.collection('notes', (ref) => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where('order_id', '==', order_id);
      query = query.orderBy('timestamp', 'asc');
      return query
    }).valueChanges().pipe(takeUntil(this.unsubscribe$))
      .subscribe({next: notes => {
        this.orderNotes$.next(notes);
        this._loadingNotes$.next(false);
      },
      error: () => {
        this.notificationService.publish({message: 'Error loading notes', type: 'error'})
        this._loadingNotes$.next(false);
      },
      complete: () => {
        this._loadingNotes$.next(false);
      }});
  }
}
