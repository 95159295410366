import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringFilterPipe',
})
export class StringFilterPipe implements PipeTransform {
  transform(array: any[], filterString: string, field: [string, string?]) {
    if (filterString == null) {
      filterString = '';
    }
    if (array === null || array === undefined || array.length === 0) {
      return;
    }

    filterString = filterString.toLowerCase();
    if (field?.length === 1 && field[0]) {
      return array.filter((elem) => {
        return elem[field[0]].toLowerCase().indexOf(filterString) > -1;
      });
    } else {
      return array.filter((elem) => elem[field[1]].toLowerCase().indexOf(filterString) > -1);
    }
  }
}
