import {Component, EventEmitter, Input, Output} from '@angular/core';
import { BasicButton, ButtonTypes } from '../buttons/basic-button.component';

@Component({
  selector: 'app-basic-button-group',
  templateUrl: './basic-button-group.component.html',
  styleUrls: ['./basic-button-group.component.scss']
})
export class BasicButtonGroupComponent {
  @Input() buttons: BasicButton[];
  @Input() currentTab: string;
  @Input() displayVertical = false;
  @Input() spaceBetween = false;
  @Input() alignRight = false;
  @Input() buttonRemPadding: number;

  @Output() buttonClicked = new EventEmitter<string>();

  buttonTypes = ButtonTypes;

  handleButtonClick($event) {
    this.buttonClicked.emit($event);
  }
}
