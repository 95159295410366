import { UiColors } from '../../interfaces/ui.interfaces';

export interface IconProps {
  icon: IconTypes;
  color: UiColors;
  size: number;
  showHover?: boolean;
  isActive?: boolean;
}

export enum IconTypes {
  AdminDashboard = 'admin-dashboard',
  AdminDashboardOnGrid = 'admin-dashboard-ongrid',
  Alert = 'alert',
  BackArrow = 'back-arrow',
  Binoculars = 'binoculars',
  Bucket = 'bucket',
  Business = 'business',
  BusinessCreditWallet = 'business-credit-wallet',
  BusinessSelected = 'business-selected',
  Calendar = 'calendar',
  Camera = 'camera',
  Car = 'car',
  Check = 'check',
  Collection = 'collection',
  Courier = 'courier',
  Color = 'color',
  Copy = 'copy',
  Cross = 'cross',
  Dashboard = 'dashboard',
  DebitCard = 'debit-card',
  Dollar = 'dollar',
  DollarSign = 'dollar-sign',
  DashboardSelected = 'dashboard-selected',
  Delete = 'remove',
  Delivery = 'delivery',
  Destination = 'destination',
  Details = 'details',
  Dot = 'dot',
  Driver = 'driver',
  Down = 'down',
  Edit = 'edit',
  EventStore = 'eventstore',
  EventStoreOnGrid = 'eventstore-ongrid',
  Eye = 'eye',
  EndArrow = 'end-arrow',
  FailedGeocode = 'failed-geocode',
  Freight = 'freight',
  Firebase = 'firebase',
  FirebaseOnGrid = 'firebase-ongrid',
  Flag = 'flag',
  Gear = 'gear',
  Geocoded = 'geocoded',
  Geocoder = 'geocoder',
  GeocoderSelected = 'geocoder-selected',
  GeocodeSkipped = 'geocode-skipped',
  History = 'history',
  Link = 'link',
  Logout = 'logout',
  Lock = 'lock',
  Mail = 'mail',
  Map = 'map',
  MapMarker = 'map-marker',
  Marker = 'marker',
  Mapping = 'mapping',
  Menu = 'menu',
  Mobile = 'mobile',
  Mouse = 'mouse',
  MobileOnGrid = 'mobile-ongrid',
  Motorbike = 'motorbike',
  Overdue = 'overdue',
  Parcel = 'parcel',
  Phone = 'phone',
  Profile = 'profile',
  ProfileSelected = 'profile-selected',
  Progress = 'progress',
  Planning = 'planning',
  Refresh = 'refresh',
  Scheduled = 'scheduled',
  Search = 'search',
  SearchSelected = 'search-selected',
  SmallVan = 'small-van',
  Spinner = 'spinner',
  StartFlag = 'start-flag',
  StartArrow = 'start-arrow',
  Stats = 'stats',
  Steering = 'steering',
  Summary = 'summary',
  Target = 'tracking-target',
  Trash = 'trash',
  Trip = 'trip',
  Unrouted = 'unrouted',
  Truck = 'truck',
  TruckSelected = 'truck-selected',
  Up = 'up',
  Underline = 'underline',
  UnderlineTitle = 'underline-title',
  FulfillmentCheapest = 'courier-economy',
  FulfillmentFastest = 'courier-express',
  Users = 'profile',
  Warehouse = 'warehouse',
  VehicleFreight = 'vehicle-freight',
  ArrowWhite = 'arrow-white',
  SecondArrow = 'second-arrow',
  Question = 'question',
  QuestionCircle = 'question-circle',
  RightChevron = 'right-chevron',
  Notification = 'notification',
  RefreshRight = 'refresh-right',
  CheckFill = 'check-fill',
  CheckedRadio = 'checked-radio',
  UncheckedRadio = 'unchecked-radio',
  CheckRingBlue = 'check-ring-blue',
  CheckRingOrange = 'check-ring-orange',
  PinAlternativeBlue = 'pin-alternative-blue',
  PinAlternativeOrange = 'pin-alternative-orange',
  DownloadFile = 'download-file',
  Filters = 'filters',
  Warehouse2 = 'warehouse2',
  MapMarker2 = 'mapmarker2',
  Map2 = 'map2',
  CheckRingRound = 'check_ring_round',
  PinAlt = 'pin_alt',
  Ungroup = 'ungroup',
  DoubleBox = 'double-box',
  Document = 'document',
  SmallParcel = 'small-parcel',
  MediumParcel = 'medium-parcel',
  LargeParcel = 'large-pacel',
  Plus = 'plus',
  Minus = 'minus',
  Summarize = 'summarize',
  Notes = 'quick_reference_all',
  Group = 'team_group'
}
