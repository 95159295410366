<app-section *ngIf="id">
  <heading [icon]="IconTypes.Details" [color]="uiColors.Blue">
    <h4 class="heading-title">{{ 'Notes' }}</h4>
    <div class="controls">
      <app-basic-button
        *ngIf="!showSave"
        [title]="'Add Note'"
        [active]="true"
        [type]="buttonTypes.Highlight"
        (click)="createNote()"
      >
      </app-basic-button>
    </div>
  </heading>
  <app-flex-container [flexDirection]="'column'" [flexWrap]="'wrap'" [padding]="'0'" [theme]="UiThemes.Dark">
    <ng-template ngFor let-index="index" let-note [ngForOf]="notesDisplay">
      <app-grid-container class="note" [borderBottom]="true" [columns]="'1fr 1fr 1fr 3fr 1fr'">
        <h4 class="author">{{ note.author_name }}</h4>
        <app-dropdown
          *ngIf="index === notesDisplay?.length - 1 && this.showSave"
          class="dropdown"
          [activeItem]="note?.category ? note.category : 'Select Category'"
          [dropdownTheme]="'grey-small'"
        >
          <div dropdownRef>
            <ng-template ngFor let-category [ngForOf]="notesCategories | keyvalue">
              <a class="dropdown-item" (click)="note.category = category.value">{{ category.value }}</a>
            </ng-template>
          </div>
        </app-dropdown>
        <p class="category" *ngIf="!note.category && (index !== notesDisplay?.length - 1 || !this.showSave)">
          {{ 'No Category' }}
        </p>
        <p class="category" *ngIf="note.category && (index !== notesDisplay?.length - 1 || !this.showSave)">
          {{ note.category }}
        </p>
        <p class="timestamp">{{ convertTime(note.timestamp) }}</p>
        <p
          #content
          [ngClass]="(index === notesDisplay?.length - 1 && this.showSave) ? 'text-border' : ''"
          [attr.contenteditable]="index === notesDisplay?.length - 1 && this.showSave ? true : false"
          [innerHTML]="note.content"
          class="content"
          (blur)="setContent($event.target, index)"
        ></p>
        <div class="public" *ngIf="this.user.is_admin">
          <input
            [disabled]="index !== notesDisplay?.length - 1 || !this.showSave"
            type="checkbox"
            (change)="note.public = !note.public"
            [checked]="note.public"
          />
          {{ 'Public' }}
        </div>
      </app-grid-container>
    </ng-template>

    <h4 class="noData" *ngIf="!notesDisplay || notesDisplay?.length === 0">No notes present. Add one now</h4>
    <div *ngIf="showSave" class="save-wrap">
      <app-basic-button *ngIf="showSave" [title]="'Save'" [type]="buttonTypes.Primary" (click)="saveNote()"> </app-basic-button>
    </div>
  </app-flex-container>
</app-section>
