<div class="graph-container" *ngIf="data && data.length > 0; else noData">
  <ngx-charts-bar-vertical-stacked
    [results]="data"
    [gradient]="gradient"
    [xAxis]="showXAxis"
    [barPadding]="50"
    [yAxis]="showYAxis"
    [legend]="showLegend"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    [scheme]="colorScheme"
  >
  </ngx-charts-bar-vertical-stacked>
</div>
<ng-template #noData>
  <h3 *ngIf="noDataMessage">{{ noDataMessage }}</h3>
</ng-template>
