<app-modal
  [open]="true"
  [actions]="actions"
  [size]="ModalSizes.Large"
  [highlightPrimaryAction]="true"
  (handleAction)="handleAction($event)"
>
  <heading [icon]="IconTypes.Bucket" [color]="uiColors.Blue">
    <h4 class="heading-title">
      {{ 'Manual Routing' }}
    </h4>
  </heading>
  <app-grid-container 
    [columns]="'repeat(2, 1fr)'" 
    [margin]="'2rem'" 
    [rowGap]="'2rem'">
    <app-date-picker 
      [date]="delivery_date" 
      (changeDate)="dateChanged($event)"></app-date-picker>
    <div class="details">
      <input-field
        [type]="'text'"
        [label]="'Route Name'"
        (valueChanged)="trip_name = $event; updateActions()"
        [value]="trip_name"
      ></input-field>
      <h6>Order Count</h6>
      <h3>{{ total_orders }}</h3>
    </div>
  </app-grid-container>
</app-modal>
