<button 
  class="button" 
  (click)="handleClick()" 
  [disabled]="disabled" 
  [ngStyle]="{'padding.rem':paddingRem}">
  <app-icon
      [icon]="_icon"
      [color]="_color"
      [size]="iconSizeRem"
  >
  </app-icon>
</button>