import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FSOrderTableColumns, FSOrderTableDataMap } from 'app/dashboard/post-dispatch/post-dispatch.constants';
import { OrderFrontEndStateEnum } from 'app/dashboard/post-dispatch/post-dispatch.interfaces';
import { PostDispatchService } from 'app/dashboard/post-dispatch/post-dispatch.service';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { Order, CollapsibleTableTypeEnum, PaginateEnum, Paginate } from 'app/shared/shared.interfaces';
import { TableAction, SortEvent } from 'app/shared/table/table.interfaces';
import { Observable, Subscription, from } from 'rxjs';

@Component({
  selector: 'app-dispatch-scheduled',
  templateUrl: './dispatch-scheduled.component.html',
  styleUrls: ['./dispatch-scheduled.component.scss']
})
export class DispatchScheduledComponent implements OnInit, OnDestroy {
  @Input() postDispatchTablePrimaryActions: TableAction[] = [];
  Icons = {
    Colors: UiColors,
    Types: IconTypes,
  };
  scheduledFirestoreTrips: Observable<Order[]>;
  scheduledAmount: number = 10;
  scheduledSubscription: Subscription;
  scheduledTotal: number;

  fsOrderTableColumns = FSOrderTableColumns;
  fsOrderTableDataMap = FSOrderTableDataMap;

  CollapsibleTableTypeEnum = CollapsibleTableTypeEnum;

  constructor(private postDispatchService: PostDispatchService) {}

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy(): void {
    this.scheduledSubscription.unsubscribe();
  }

  getData(): void {
    this.handleScheduledTrips({ Amount: this.scheduledAmount, Direction: PaginateEnum.None });
    this.handlePostDispatchTotals();
  }

  handlePostDispatchTotals(): void {
    this.postDispatchService.getPostDispatchTotals(false).then((data) => {
      this.scheduledTotal = data.output.find((x) => x.State === OrderFrontEndStateEnum.Scheduled)?.Cnt;
    });
  }

  handleScheduledTrips($event: Paginate): void {
    this.scheduledAmount = $event.Amount;
    this.scheduledSubscription?.unsubscribe();
    this.scheduledSubscription = this.postDispatchService
      .getFireStoreOrderSummaryStreamByState(this.scheduledAmount, $event.Direction, OrderFrontEndStateEnum.Scheduled, false)
      .subscribe((response) => {
        if (response[0]) {
          this.postDispatchService.setTripSnapshot(
            {
              First: response[0].payload.doc,
              Last: response[response.length - 1].payload.doc,
            },
            OrderFrontEndStateEnum.Scheduled
          );

          const data: Order[] = [];
          for (const item of response) {
            data.push(item.payload.doc.data());
          }
          this.scheduledFirestoreTrips = from([data]);
        } else {
          this.scheduledFirestoreTrips = from([]);
        }
      });
  }

  handleTripsSortChange($event: SortEvent): void {
    this.postDispatchService.orderOrderBy = $event;
    this.handleScheduledTrips({ Amount: this.scheduledAmount, Direction: PaginateEnum.None });
  }
}
