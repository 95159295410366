import { Component, OnInit } from '@angular/core';
import { IconTypes } from '../icon/icon.interfaces';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { JustifyContent } from '../flex-container/flex-container.interfaces';
import { environment } from 'environments/environment';
import { SimpleModalService } from 'ngx-simple-modal';
import { RequestDemoModalComponent } from '../request-demo-modal/request-demo-modal.component';

@Component({
  selector: 'app-static-footer',
  templateUrl: './static-footer.component.html',
  styleUrls: ['./static-footer.component.scss'],
})
export class StaticFooterComponent implements OnInit {
  public justifyContent = JustifyContent;
  public IconTypes = IconTypes;
  public UiColors = UiColors;
  uiThemes = UiThemes;
  context = environment.envName;
  contactNumber = environment.supportPhoneNumber;
  constructor(private simpleModalService: SimpleModalService) {}

  signUp(): void {
    this.simpleModalService.addModal(RequestDemoModalComponent, null);
  }

  ngOnInit() {}
}
