import { Component, OnDestroy } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationsService } from 'app/shared/notifications/notifications.service';
import { OperationsTrackingService } from 'app/operations-tracking/operations-tracking.service';
import { OrderFrontEndStateEnum } from 'app/dashboard/post-dispatch/post-dispatch.interfaces';
import { OrderStateEnum, OrderSummary } from 'app/operations-tracking/operations-tracking.interface';
import { OrderTrackingComponent } from '../order-tracking/order-tracking.component';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-in-progress',
  templateUrl: './in-progress.component.html',
  styleUrls: ['./in-progress.component.scss']
})
export class InProgressComponent extends OrderTrackingComponent implements OnDestroy {
  inProgressSubscription: Subscription;
  dataSource = new MatTableDataSource<OrderSummary>([]);
  OrderStateEnum = OrderStateEnum;
  tableColumns = ['CustomerReference','WarehouseName','ScheduledDate','Drivers' ,'LastEventName', 'Timestamp', 'LotteryRestartCount', 'IsRoundTrip', 'FleetAllocation','AssignedTo','Parcels' ,'Actions']

  constructor(public operationsTrackingService: OperationsTrackingService,
    public notificationsService: NotificationsService,
    public store: Store) {
    super(notificationsService, operationsTrackingService, store);
    if(this.isAdmin) {
      this.tableColumns = ['BusinessName','CustomerReference','WarehouseName','ScheduledDate','Drivers' ,'LastEventName', 'Timestamp', 'LotteryRestartCount', 'IsRoundTrip', 'FleetAllocation','AssignedTo','Parcels' ,'Actions']
    } else {
      this.tableColumns = ['CustomerReference','WarehouseName','ScheduledDate','Drivers' ,'LastEventName', 'Timestamp', 'LotteryRestartCount', 'IsRoundTrip', 'FleetAllocation','AssignedTo','Parcels' ,'Actions']
    }
    this.operationsTrackingService.usedIds$.pipe(takeUntil(this.unsubscribe$)).subscribe((ids: string[]) => {
      this.selectedIds = ids;
      this.getInProgressOrders();
    });
  }

  ngOnDestroy(): void {
    this.inProgressSubscription?.unsubscribe();
    this.warehouseSubscription?.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getInProgressOrders(): void {
    const batches = this.getFirestoreBatchesForOrders(OrderFrontEndStateEnum.InProgress)
    this.inProgressSubscription?.unsubscribe();
    this.inProgressSubscription = combineLatest(batches).subscribe((res) => {
      let orders: OrderSummary[] = [];
      orders = orders.concat(...res);
      this.dataSource.data = orders;
    });
  }
}
