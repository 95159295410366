import { Component, OnInit } from '@angular/core';
import { IconTypes } from '../icon/icon.interfaces';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { JustifyContent } from '../flex-container/flex-container.interfaces';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-ondemand-features',
  templateUrl: './ondemand-features.component.html',
  styleUrls: ['./ondemand-features.component.scss']
})
export class OndemandFeaturesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
