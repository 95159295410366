import { Component, OnInit, ViewChild } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { IconTypes } from '../icon/icon.interfaces';
import { SharedService } from '../shared.service';
import { TableAction, TableColumn } from '../table/table.interfaces';
import { ButtonTypes } from '../buttons/basic-button.component';

@Component({
  selector: 'app-search-driver-name',
  templateUrl: './search-driver-name.component.html',
  styleUrls: ['./search-driver-name.component.scss']
})
export class SearchDriverNameComponent extends SimpleModalComponent<any, any> implements OnInit {

  @ViewChild('container', {static: true} ) container: any;
  user_id;
  business_id;
  driverName;
  drivers;
  shownDrivers = [];
  Icons = {
    Colors: UiColors,
    Types: IconTypes
  };
  showViewMore = true;
  numberOfResults = 10;
  buttonTypes = ButtonTypes;

  driverTableColumns: TableColumn[] = [
    { name: 'driver_name', displayName: 'Driver Name' },
    { name: 'email', displayName: 'Email Address' },
    { name: 'phone', displayName: 'Phone Number' },
    { name: 'is_online', displayName: 'Status' },
    { name: 'acting_as', displayName: 'Type' },
    { name: 'driverId', displayName: 'Driver ID', hidden: true },
  ];

  tablePrimaryActions: TableAction[] = [
    { event: 'select', title: 'Select', icon: IconTypes.Driver }
  ];

  constructor(
    public sharedService: SharedService
  ) {
    super();
  }

  loadMoreTableData() {
    this.numberOfResults = this.numberOfResults + 5;
    this.showDrivers();
    if (this.drivers.length <= this.shownDrivers.length) {
      this.showViewMore = false;
    }
  }

  showDrivers() {
    let i = 0;
    this.drivers.map(
      x => {
        i++;
        if (i <= this.numberOfResults) {
          this.shownDrivers.push(
            {
              cells: [
                {
                  column: 'driver_name',
                  value: x.name,
                  highlighted: false,
                },
                {
                  column: 'email',
                  value: x.email,
                  highlighted: false,
                },
                {
                  column: 'phone',
                  value: x.phone,
                  highlighted: false,
                },
                {
                  column: 'is_online',
                  value: x.is_online ? 'Online' : 'Offline',
                  highlighted: false,
                  displayPipeArgs: ['none'],
                },
                {
                  column: 'acting_as',
                  value: (x.acting_as === 0) ? 'Picup' : 'Contract',
                  highlighted: false,
                },
                {
                  column: 'driverId',
                  value: x._id,
                  highlighted: false,
                },
              ]
            }
          )
        }
      }
    );
    if (this.drivers.length <= this.shownDrivers.length) {
      this.showViewMore = false;
    }
  }

  searchDriver(driverName) {
    const APIData = {
      user_id: this.user_id,
      business_id: this.business_id,
      name: driverName,
    }
    this.sharedService.searchDriverByName(APIData)
      .then(
        res => {
          this.drivers = res.data;
          this.showDrivers();
        }
      )
  }

  respondToTableActions($event) {
    this.result = $event.rows[0].cells.find(cell => (cell.column === 'driverId')).value;
    this.close();
  }

  ngOnInit() {

  }
}
