import { Action } from '@ngrx/store';

export const SET_ACTIVE_TRIP_ID = 'SET_ACTIVE_TRIP_ID';
export const SET_ACTIVE_TRIP_WAREHOUSE_ID = 'SET_ACTIVE_TRIP_WAREHOUSE_ID';
export const CLEAR_ACTIVE_TRIP_STORE = 'CLEAR_ACTIVE_TRIP_STORE';
export const SET_ACTIVE_TRIP_DETAILS = 'SET_ACTIVE_TRIP_DETAILS';

export class setActiveTripId implements Action {
  readonly type = SET_ACTIVE_TRIP_ID;
  constructor(public payload: string) {}
}

export class setActiveTripWarehouseId implements Action {
  readonly type = SET_ACTIVE_TRIP_WAREHOUSE_ID;
  constructor(public payload: string) {}
}

export class clearActiveTripStore implements Action {
  readonly type = CLEAR_ACTIVE_TRIP_STORE;
}

export class setActiveTripDetails implements Action {
  readonly type = SET_ACTIVE_TRIP_DETAILS;
  constructor(public payload: {}) {}
}
