import { Component, Input, HostListener, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'tooltip-button',
  templateUrl: './tooltip-button.component.html',
  styleUrls: ['./basic-button.component.scss', './tooltip-button.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TooltipButtonComponent {
  @Input() type: string;
  @Input() title: string;
  @Input() active: boolean;
  @Input() disabled: boolean;
  @Input() orientation = 'top';

  public open = false;

  @HostListener('document:click', ['$event'])
  closeMenu(event) {
    if (!this.open) {
      return;
    }

    if (event.target.classList.contains('tooltip-button')) {
      return;
    }

    this.open = false;
  }
}
