import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ButtonTypes } from '../buttons/basic-button.component';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent implements OnInit {
  buttonTypes = ButtonTypes;
  constructor(private location: Location) {}

  backButton(): void {
    this.location.back();
  }

  ngOnInit(): void {}
}
