<div class="saved-card-block">
    <app-saved-paystack-cards [loadingPayment]="loadingPayment" (cardSelected)="savedCardSelected($event)" (cardsLoaded)="cardsLoaded($event)" [userId]="userId" [businessId]="businessId">
    </app-saved-paystack-cards>
</div>

<div class="pay-button-group">
    <app-basic-button 
        [type]="ButtonTypes.Green" 
        [active]="true" 
        [title]="savedCards?.length ? 'Add Another Method' : 'Pay Now'"
    (click)="payWithPaystack()">
    </app-basic-button>

<app-basic-button 
    [type]="ButtonTypes.Primary" 
    [title]="'Cancel'" 
    (click)="resetPayment()"> </app-basic-button>
</div>
