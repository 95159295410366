import { SelectedTripsIds, TripsTableData } from './trip-costs.interfaces';
import { completeAssign } from '../../../../shared/helpers';
import { environment } from 'environments/environment';

export const assignTripsToTables = (
  trips: TripsTableData,
  selectedTripsIds: SelectedTripsIds,
  callback: any = () => {}
): TripsTableData => {
  const emptyTripsData: TripsTableData = {
    unassigned: [],
    picup: [],
    contract: [],
    courier: [],
    count: 0,
    previouslySaved: false,
    hasFetched: false,
  };
  const _trips = completeAssign({}, emptyTripsData, trips);

  // If there are no pre-assigned trips, send everything to the courier table
  // The presumption here is that as soon as trips are saved, the unassigned array is empty/undefined

  const hasContractTrips = selectedTripsIds?.contract.length > 0;
  const hasPicupTrips = selectedTripsIds?.picup.length > 0;
  // must be handled elsewhere
  _trips.previouslySaved = hasContractTrips || hasPicupTrips;
  _trips.unassigned = [];

  trips.unassigned.forEach((item) => {
    const id = item.cells.find((cell) => cell.column === 'id').value as number;
    if (hasContractTrips && selectedTripsIds.contract.find((tripId) => tripId === id)) {
      _trips.contract.push(item);
    } else if (hasPicupTrips && selectedTripsIds.picup.find((tripId) => tripId === id)) {
      _trips.picup.push(item);
    } else {
      _trips.unassigned.push(item);
    }
  });

  callback();
  return _trips;
};
