<div class="map-wrap">
    <google-map 
        width="100%"
        [zoom]="6" 
        [options]="gMapOptions">

        <ng-template ngFor let-index="index" let-alert [ngForOf]="alertsData">
            <!-- <map-marker [agmFitBounds]="true" [iconUrl]="{
                        url: '/assets/img/markers/Failed.svg',
                        scaledSize: {
                            width: 32,
                            height:35
                        }}" [label]="{
                            color: UiColors.White, 
                            text: '!', 
                            fontSize: '12px',
                            labelClass: 'label-class',
                            fontWeight: '700'
                          }" [latitude]="alert.Latitude" [agmFitBounds]="true" [longitude]="alert.Longitude"
                (markerClick)="markerClick(alert)">
            </map-marker> -->
        </ng-template>

        <ng-template ngFor let-index="index" let-vehicle [ngForOf]="vehicles">
            <!-- <agm-marker *ngIf="vehicle.latitude" [iconUrl]="{
                    url: '/assets/img/vehicle-small-van.svg',
                    scaledSize: {height: 35,width: 50}}" [latitude]="vehicle.latitude" [longitude]="vehicle.longitude"
                (markerClick)="markerClick(vehicle)">
            </agm-marker> -->
        </ng-template>


    </google-map>
    <div *ngIf="alertDetails" class="alert-details">
        <div class="actions-wrap">
            <span></span>
            <svg-icon class="close-details" src="../../../../assets/img/icons/back-arrow.svg" (click)="closeDetails()">
            </svg-icon>
        </div>
        <div class="block">
            <div class="title">
                <div class="row">
                    <h4>Alert Details:</h4>
                    <div class="underline"></div>
                </div>
            </div>
            <div class="row row-double" *ngIf="alertDetails.Registration || alertDetails.EventDescription">
                <label>Registration
                    <p>{{ alertDetails?.Registration }}</p></label>
                <label>Description
                    <p>{{ alertDetails?.EventDescription }}</p></label>
            </div>
            <div class="row" *ngIf="alertDetails.EventTimeStamp">
                <label>Time:
                    <p>{{ alertDetails?.EventTimeStamp | date: 'medium' }}</p></label>
            </div>
            <div class="row row-double" *ngIf="alertDetails.Speed || alertDetails.RoadSpeed">
                <label>Speed
                    <p>{{ alertDetails?.Speed }}</p></label>
                <label>Speed Limit
                    <p>{{ alertDetails?.RoadSpeed }}</p></label>
            </div>
        </div>
    </div>
</div>