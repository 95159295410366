import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
  selector: 'app-material-input',
  templateUrl: './material-input.component.html',
  styleUrls: ['./material-input.component.scss'],
})
export class MaterialInputComponent implements OnInit {
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() disabled: boolean = false;
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() value: string = '';
  @Output() changed: EventEmitter<string> = new EventEmitter<string>();
  constructor() {}

  ngOnInit(): void {}
}
