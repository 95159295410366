import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'upcomingToLatest',
})
export class UpcomingToLatestPipe implements PipeTransform {
  transform(array: any, fieldName: any): any {
    if (array) {
      const now: Date = new Date();

      array.sort((a: any, b: any) => {
        const date1: Date = new Date(a[fieldName]);
        const date2: Date = new Date(b[fieldName]);

        // If the first date passed
        if (date1 < now) {
          // If the second date passed
          if (date2 < now) {
            return 0;
          } else {
            return 1;
          }
        } else {
          // If the second date passed
          if (date2 < now) {
            return -1;
          } else if (date1 < date2) {
            return -1;
          } else if (date1 > date2) {
            return 1;
          } else {
            return 0;
          }
        }
      });
      array.reverse();
    }
    // reversed for now
    return array;
  }

  //   A quick explanation for that if tree:

  // If the first date is in the past

  // 1.1 If the second date is in the past - the order of them does not matter

  // 1.2 Else, meaning the second is in the future, bring second date higher in the order

  // Else, meaning the first date is in the future

  // 2.1 If the second date is in the past, bring first date higher in the order

  // 2.2 Else if the first date is before the second date, meaning first date is closer to now than the second date, bring first date higher in the order

  // 2.3 Else, meaning the second date is closer to now than the first date, bring the second date higher in the order
}
