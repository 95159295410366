import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { selectorActingAs, selectorUserPermissions, selectorWarehouses } from 'app/auth/auth.reducer';
import { ActingAs, Warehouse } from 'app/interfaces/auth.interfaces';
import { AutocompletePreferences } from 'app/shared/places-autocomplete/places-autocomplete.interface';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormattedAddress, FullAddressModel, TaskType } from '../../start-end-point.interfaces';

@Component({
  selector: 'app-address-select',
  templateUrl: './address-select.component.html',
  styleUrls: ['./address-select.component.scss']
})
export class AddressSelectComponent implements OnInit, OnDestroy, OnChanges {

  @Input() heading: string = 'Start Address';
  @Input() type: TaskType = TaskType.start;
  @Input() addressIn: FullAddressModel;
  @Input() isValid: boolean = true;
  @Output() address = new EventEmitter<FormattedAddress>();
  @Output() clearMap = new EventEmitter<TaskType>();

  addressTypeControl = new FormControl();
  selectedWarehouse = new FormControl();
  selectedAddressBook = new FormControl();

  manualInputFormGroup = this.formBuilder.group({
    manualInput: ''
  });
  preferences: AutocompletePreferences = {
    fields: environment.autocompletePref.fields,
    componentRestrictions: { country: ['ZA'] },
    types: environment.autocompletePref.types,
  };
  private unsubscribe$ = new Subject<void>();
  warehouses: Warehouse[] = [];
  available_warehouses: Warehouse[] = []
  userPermissions: any;
  actingAs: ActingAs;
  allowWarehouseSelect: boolean = false;
  allowAddressBook: boolean = false;
  taskType = TaskType;
  manualPlaceholder = ''

  constructor(private formBuilder: FormBuilder, private store: Store) {
    this.store.select(selectorWarehouses).pipe(takeUntil(this.unsubscribe$)).subscribe((next: Warehouse[]) => (this.warehouses = next));
    this.store.select(selectorUserPermissions).pipe(takeUntil(this.unsubscribe$)).subscribe((next) => (this.userPermissions = next));
    this.store.select(selectorActingAs).pipe(takeUntil(this.unsubscribe$)).subscribe((next: ActingAs) => (this.actingAs = next));
  }

  ngOnInit(): void {
    if (this.userPermissions?.warehouses?.length && !this.userPermissions?.modules?.includes('super-admin')) {
      this.available_warehouses = this.warehouses.filter((warehouse) =>
        this.userPermissions.warehouses.includes(warehouse.id)
      );
    } else {
      this.available_warehouses = this?.warehouses;
    }
    if (this.available_warehouses?.length) {
      this.allowWarehouseSelect = true;
    }

    this.selectedWarehouse.valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((warehouseSelected: Warehouse) => {
        this.formatOutgoingAddress(warehouseSelected.address, warehouseSelected.location.latitude, warehouseSelected.location.longitude, warehouseSelected.id)
      });

    this.selectedAddressBook.valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((warehouseSelected: Warehouse) => {
        this.formatOutgoingAddress(warehouseSelected.address,
          warehouseSelected.location.latitude,
          warehouseSelected.location.longitude,
          warehouseSelected.id)
      });
    this.formatIncomingAddress();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.addressIn) {
      if (changes?.addressIn?.currentValue?.address !== changes?.addressIn?.previousValue?.address) {
        this.formatIncomingAddress();
      }
    }
  }

  googleAddress(place: google.maps.places.PlaceResult): void {
    this.formatOutgoingAddress(place.formatted_address,
      place.geometry.location.lat().toString(),
      place.geometry.location.lng().toString())

  }

  clearSelection(type: TaskType): void {
    this.clearMap.emit(type);
  }

  formatOutgoingAddress(address: string, lat: string, lng: string, warehouseId?: string): void {
    this.address.emit({
      address,
      lat,
      lng,
      warehouseId
    })
  }

  formatIncomingAddress(): void {
    if (this.addressIn) {
      if (this.addressIn.warehouseId) {
        this.addressTypeControl.setValue('warehouse');
        const warehouseUsed = this.available_warehouses.find((warehouse: Warehouse) => warehouse.id === this.addressIn.warehouseId);
        this.selectedWarehouse.setValue(warehouseUsed);
      } else {
        this.addressTypeControl.setValue('manual');
        this.manualPlaceholder = this.addressIn.address;
      }
    }
  }
}
