<app-section>
  <app-flex-container
    [theme]="UiThemes.Dark"
    [padding]="'2rem'"
    [borderedItems]="true"
    [justifyContent]="JustifyContent.SpaceBetween"
    [borderBottom]="true"
  >
    <app-icon-with-text
      [text]="'DRIVER STAGED ASSIGNMENTS'"
      [textBold]="true"
      [sizeRem]="1"
      [color]="UiColors.White"
    >
    </app-icon-with-text>
    <button 
      *ngIf="driverId" 
      (click)="goToDriver()">
      Go to Driver
    </button>
  </app-flex-container>
  <app-table
    [showAnnotations]="true"
    [columns]="parcelColumns"
    [dataMap]="parcelDataMap"
    [rawData]="Parcels"
    [rowsSelectable]="true"
    [bulkActions]="parcelBulkActions"
    (actionTaken)="handleTableActions($event)"
    [zeroDataMessage]="'No Shipments to Display'"
  ></app-table>

  <app-section *ngIf="showMap">
    <heading
      [icon]="IconTypes.Driver"
      [color]="UiColors.Blue"
    >
      <h4 class="heading-title">Trip Tracking</h4>
    <app-basic-button 
      [title]="'View Map'" 
      [type]="buttonTypes.Primary" 
      (click)="toggleShowMap = !toggleShowMap">
    </app-basic-button>
    </heading>
    <ng-container *ngIf="toggleShowMap">
      <div class="map-wrap">
        <app-map-view
          [mapType]="mapTypes.Tracking"
          [markers]="markers"
          [driverId]="Parcels[0].DriverId"
        ></app-map-view>
      </div>
    </ng-container>
  </app-section>
</app-section>
