import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-section',
  templateUrl: './loading-section.component.html',
  styleUrls: ['./loading-section.component.scss']
})
export class LoadingSectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
