<div class="full-container">
    <!-- header -->
    <div class="header-container">
        <!-- icon -->
        <div class="header-icon-container">
            <mat-icon class="header-icon">warning</mat-icon>
        </div>
        <!-- heading -->
        <div class="header-text">
            {{data.heading}}
        </div>
        <!-- close button -->
        <div class="icon-container">
            <button mat-icon-button mat-dialog-close class="header-icon">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <!-- body -->
    <div class="body-container">
        <!-- table stuff -->
        <app-generic-modular-table
        class="table-width"
        [dataSource]="data.tableData"
        [tableColumns]="data.tableColumns"
        [enablePaging]="data.showPaging ?? false"
        (selectionChanges)="selectedItems($event)">
        </app-generic-modular-table>
    </div>
    <div class="footer-container">
        <div class="footer-content">
            <div class="cancel-button-container">
                <button 
                class="cancel-button"
                mat-raised-button
                mat-dialog-close>
                    Cancel
                </button>
            </div>
            <div class="confirm-button">
                <button mat-raised-button
                    (click)="save()">
                    Confirm
                </button>
            </div>

        </div>
    </div>

</div>