<app-table *ngIf="driverScores.length > 0; else noData" [rowsSelectable]="true"  [columns]="driverScoreColumns"
  [dataMap]="driverScoreDataMap" [rawData]="driverScores"
  [showTableAverages]="true" [showTableTotals]="true" [overrideRowIsSelectable]="true" [showSelectionTotals]="true"
  [showSelectionAverages]="true" [showSelectionSummaryTop]="false" [showSelectionSummaryBottom]="true">
</app-table>
<ng-template #noData>
  <h3 class="noData">No data for this date range</h3>
</ng-template>



