<div class="select-wrap">
  <label>{{label}}</label>
  <div class="dropdown-wrap">
    <app-dropdown [activeItem]="selectedOption?.name">
      <div dropdownRef>
        <a *ngFor="let option of filteredOptions"
            class="dropdown-item"
            (click)="onChange(option)">{{ option.name }}
        </a>
      </div>
    </app-dropdown>
  </div>
</div>
