import { Component, Input, OnDestroy } from '@angular/core';
import { Timeline, TimelinePosition } from './order-timeline-visual.interfaces';
import { Subject } from 'rxjs';
import { OrderTimelineFirestore, OrderTimelineFirestoreEventData } from 'app/dashboard/new-trips/new-trips.interfaces';

@Component({
  selector: 'app-order-timeline-visual',
  templateUrl: './order-timeline-visual.component.html',
  styleUrls: ['./order-timeline-visual.component.scss']
})
export class OrderTimelineVisualComponent {

  @Input() set timelineData(orderTimelineData: OrderTimelineFirestore) {
    this.timeline = orderTimelineData?.Events?.map((data: OrderTimelineFirestoreEventData) => {
      return {
        EventArg1: '',
        EventArg2: data.UserEmail,
        EventArg3: '',
        EventDescription: data.EventName,
        Timestamp: data.Timestamp.toMillis().toString(),
        ParcelWaybillNumber: ''
      };
    }).sort((first, second) => {
      return <any>new Date(first.Timestamp) - <any>new Date(second.Timestamp);
    });
  }

  timeline: Timeline[];

  timelinePositionEnum = TimelinePosition;

  getPosition(timeline: Timeline): TimelinePosition {
    const maxIndex = this.timeline.length - 1;
    if (timeline === this.timeline[0]) {
      return TimelinePosition.first
    }
    if (timeline === this.timeline[maxIndex]) {
      return TimelinePosition.last
    }
    return TimelinePosition.other
  }

}



