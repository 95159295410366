export interface Report {
  DisplayName: string;
  Type: ReportTypeEnum;
  IsAdminOnly: boolean;
  FileName: string;
  Url: string;
  UsesGenericService: boolean;
  RequiresWarehouse?: boolean;
}

export interface ReportingTab {
  route: string;
  name: string;
}

export const Tabs: ReportingTab[] = [
  {name: 'Buckets', route: 'buckets'},
  {name: 'Planning', route: 'planning'}
];

export enum ReportTypeEnum {
  Couriers = 'Couriers',
  Finances = 'Finances',
  Drivers = 'Drivers',
  Orders = 'Orders',
  Parcels = 'Parcels',
  Buckets = 'Buckets',
  General = 'General',
}

export const Reports: Report[] = [
  {
    DisplayName: 'Routes',
    IsAdminOnly: false,
    Type: ReportTypeEnum.General,
    FileName: 'Route Report',
    Url: '/generic/csv/get-route-report-client',
    UsesGenericService: true
  },
  {
    DisplayName: 'Payment - By Orders',
    IsAdminOnly: false,
    Type: ReportTypeEnum.Finances,
    FileName: 'PaymentByOrders',
    Url: '/generic/csv/get-payments-by-orders',
    UsesGenericService: true
  },
  {
    DisplayName: 'Payment - By Parcels',
    IsAdminOnly: false,
    Type: ReportTypeEnum.Finances,
    FileName: 'PaymentByParcels',
    Url: '/generic/csv/get-payments-by-parcels',
    UsesGenericService: true
  },
  {
    DisplayName: 'SLA',
    IsAdminOnly: false,
    Type: ReportTypeEnum.Parcels,
    FileName: 'SLA',
    Url: '/generic/csv/get-sla-report',
    UsesGenericService: true
  },
  {
    DisplayName: 'Parcel SLA',
    IsAdminOnly: false,
    Type: ReportTypeEnum.Parcels,
    FileName: 'ParcelSLA',
    Url: '/generic/csv/get-parcel-sla-report',
    UsesGenericService: true
  },
  {
    DisplayName: 'Orders By Warehouse',
    IsAdminOnly: false,
    Type: ReportTypeEnum.Orders,
    FileName: 'OrdersByWarehouse',
    Url: '/generic/csv/get-business-orders-by-warehouse',
    UsesGenericService: true
  },
  {
    DisplayName: 'Deleted Orders By Warehouse',
    IsAdminOnly: false,
    Type: ReportTypeEnum.Orders,
    FileName: 'DeletedOrdersByWarehouse',
    Url: '/generic/csv/get-deleted-orders-by-warehouse',
    UsesGenericService: true
  },
  {
    DisplayName: 'Parcel Outcome By Parcel State',
    IsAdminOnly: false,
    Type: ReportTypeEnum.Parcels,
    FileName: 'ParcelOutcomeByParcelState',
    Url: '/generic/csv/get-parcel-outcome-by-parcel-state',
    UsesGenericService: true
  },
  {
    DisplayName: 'Bucket Status',
    IsAdminOnly: false,
    Type: ReportTypeEnum.Buckets,
    FileName: 'BucketStatus',
    Url: '/generic/csv/get-buckets-report',
    UsesGenericService: true
  },
  {
    DisplayName: 'Active Courier Orders',
    IsAdminOnly: false,
    Type: ReportTypeEnum.Couriers,
    FileName: 'ActiveCourierOrders',
    Url: '/generic/csv/active-courier-order-report',
    UsesGenericService: true
  },
  {
    DisplayName: 'Completed Courier Orders',
    IsAdminOnly: false,
    Type: ReportTypeEnum.Couriers,
    FileName: 'CompletedCourierOrders',
    Url: '/generic/csv/completed-courier-order-report',
    UsesGenericService: true
  },
  {
    DisplayName: 'Driver Rating',
    IsAdminOnly: false,
    Type: ReportTypeEnum.Drivers,
    FileName: 'DriverRating',
    Url: '/generic/csv/get-driver-rating-report',
    UsesGenericService: true
  },
  {
    DisplayName: 'Geocoded Distance Cost',
    IsAdminOnly: true,
    Type: ReportTypeEnum.Finances,
    FileName: 'GeocodedDistance',
    Url: '/generic/csv/geocoded-distance-cost-report',
    UsesGenericService: true
  },
  {
    DisplayName: 'Planned On Demand Earnings',
    IsAdminOnly: true,
    Type: ReportTypeEnum.Finances,
    FileName: 'PlannedOnDemandEarnings',
    Url: '/generic/csv/planned-on-demand-earnings',
    UsesGenericService: true
  },
  {
    DisplayName: 'Planned Driver Earnings',
    IsAdminOnly: true,
    Type: ReportTypeEnum.Finances,
    FileName: 'PlannedDriverEarnings',
    Url: '/generic/csv/planned-driver-earnings-report',
    UsesGenericService: true
  },
  {
    DisplayName: 'Driver Productivity',
    IsAdminOnly: false,
    Type: ReportTypeEnum.Drivers,
    FileName: 'DriverProductivity',
    Url: '/generic/csv/get-driver-productivity-report',
    UsesGenericService: true
  },
  {
    DisplayName: 'Staged Orders for Business',
    IsAdminOnly: false,
    Type: ReportTypeEnum.Orders,
    FileName: 'StagedOrdersForBusiness',
    Url: '/generic/csv/get-staged-orders',
    UsesGenericService: true,
  },
  {
    DisplayName: 'Staged Orders for Warehouses',
    IsAdminOnly: false,
    Type: ReportTypeEnum.Orders,
    FileName: 'StagedOrdersForWarehouse',
    Url: '/generic/csv/get-staged-orders',
    UsesGenericService: true,
    RequiresWarehouse: true,
  },
];

export const AdminViewReports: Report[] = [
  {
    DisplayName: 'User/Business Summary',
    IsAdminOnly: true,
    Type: ReportTypeEnum.General,
    FileName: 'PlannedDriverEarnings',
    Url: 'reporting/export-user-business-summary',
    UsesGenericService: false
  },
  {
    DisplayName: 'Routes',
    IsAdminOnly: true,
    Type: ReportTypeEnum.General,
    FileName: 'Route Report',
    Url: '/generic/csv/get-route-report-admin',
    UsesGenericService: true
  },
  {
    DisplayName: 'SLA',
    IsAdminOnly: false,
    Type: ReportTypeEnum.Parcels,
    FileName: 'SLA',
    Url: '/generic/csv/get-sla-report',
    UsesGenericService: true
  },
  {
    DisplayName: 'Parcel SLA',
    IsAdminOnly: false,
    Type: ReportTypeEnum.Parcels,
    FileName: 'ParcelSLA',
    Url: '/generic/csv/get-parcel-sla-report',
    UsesGenericService: true
  },
  {
    DisplayName: 'Planned On Demand Earnings',
    IsAdminOnly: true,
    Type: ReportTypeEnum.Finances,
    FileName: 'PlannedOnDemandEarnings',
    Url: '/generic/csv/planned-on-demand-earnings',
    UsesGenericService: true
  },
  {
    DisplayName: 'Active Courier Orders',
    IsAdminOnly: false,
    Type: ReportTypeEnum.Couriers,
    FileName: 'ActiveCourierOrders',
    Url: '/generic/csv/active-courier-order-report',
    UsesGenericService: true
  },
  {
    DisplayName: 'Completed Courier Orders',
    IsAdminOnly: false,
    Type: ReportTypeEnum.Couriers,
    FileName: 'CompletedCourierOrders',
    Url: '/generic/csv/completed-courier-order-report',
    UsesGenericService: true
  },
  {
    DisplayName: 'Planned Driver Earnings',
    IsAdminOnly: true,
    Type: ReportTypeEnum.Finances,
    FileName: 'PlannedDriverEarnings',
    Url: '/generic/csv/planned-driver-earnings-report',
    UsesGenericService: true
  },
  {
    DisplayName: 'Orders Fulfilled By Plus Drivers',
    IsAdminOnly: true,
    Type: ReportTypeEnum.Orders,
    FileName: 'OrdersFulfilledByPlusDrivers',
    Url: '/generic/csv/order-fulfilled-by-plus-drivers-report',
    UsesGenericService: true
  },
  {
    DisplayName: 'Driver Recruitment',
    IsAdminOnly: true,
    Type: ReportTypeEnum.Drivers,
    FileName: 'DriverRecruitment',
    Url: '/generic/csv/get-driver-recruitment-report',
    UsesGenericService: true
  },
  {
    DisplayName: 'Route Driver Earnings',
    IsAdminOnly: true,
    Type: ReportTypeEnum.Finances,
    FileName: 'RouteDriverEarnings',
    Url: '/generic/csv/planned-route-driver-earnings',
    UsesGenericService: true
  },
  {
    DisplayName: 'Geocoded Distance Cost',
    IsAdminOnly: true,
    Type: ReportTypeEnum.Finances,
    FileName: 'GeocodedDistance',
    Url: '/generic/csv/geocoded-distance-cost-report',
    UsesGenericService: true
  },
  {
    DisplayName: 'Driver Rating',
    IsAdminOnly: false,
    Type: ReportTypeEnum.Drivers,
    FileName: 'DriverRating',
    Url: '/generic/csv/get-driver-rating-report',
    UsesGenericService: true
  },
  {
    DisplayName: 'Daily Summary',
    IsAdminOnly: false,
    Type: ReportTypeEnum.General,
    FileName: 'DriverRating',
    Url: '/generic/csv/get-daily-summary-report',
    UsesGenericService: true
  }
];
