<table class="financials-summary">
  <thead class="header">
    <tr>
      <th class="heading">Type</th>
      <th class="heading">Amount</th>
    </tr>
  </thead>
  <tfoot class="footer">
    <tr>
      <th class="heading">Total</th>
      <td class="value">{{data?.total | currency : (this.currency_symbol + ' ')}}</td>
    </tr>
  </tfoot>
  <tbody class="content">
    <tr *ngFor="let row of data?.rows">
      <th class="heading">{{row?.label }}</th>
      <td class="value">{{row?.value | currency : (this.currency_symbol + ' ')}}</td>
    </tr>
    <tr *ngIf="data?.income">
      <th class="heading">{{"income" | uppercase }}</th>
      <td class="value">{{data.income | currency : (this.currency_symbol + ' ')}}</td>
    </tr>
    <tr *ngIf="data?.expenses">
      <th class="heading">{{"expenses" | uppercase }}</th>
      <td class="value">{{data.expenses | currency : (this.currency_symbol + ' ')}}</td>
    </tr>
  </tbody>
</table>