import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter,
} from '@angular/core';
import * as noUiSlider from 'nouislider';

@Component({
  selector: 'app-time-selector',
  templateUrl: './time-selector.component.html',
  styleUrls: ['./time-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TimeSelectorComponent implements OnInit {
  @Input() availableRanges: Array<Array<number>>;
  @Input() title: string = 'Collection Time';
  @Output() changeTime: EventEmitter<any> = new EventEmitter<any>();

  @Input() selectedRange = [9];
  @ViewChild('rangeSlider', { static: true }) slider: ElementRef;

  constructor() {}

  rangeString(range) {
    if (range.length > 1) {
      return `${Math.floor(parseInt(range[0], 10))}:${
        range[0] % 1 === 0 ? '00' : (parseFloat(range[0]) % 1) * 60
      }   - ${Math.floor(parseInt(range[1], 10))}:${range[1] % 1 === 0 ? '00' : (parseFloat(range[1]) % 1) * 60}`;
    } else {
      return `${Math.floor(parseInt(range[0], 10))}:${range[0] % 1 === 0 ? '00' : (parseFloat(range[0]) % 1) * 60}`;
    }
  }

  ngOnInit() {
    noUiSlider.create(this.slider.nativeElement, {
      start: this.selectedRange,
      connect: true,
      range: {
        min: 0,
        max: 23.75,
      },
      step: 0.25,
    });

    this.slider.nativeElement.noUiSlider.on('update', (values) => {
      this.selectedRange = values;
      this.changeTime.emit(this.selectedRange);
    });
  }
}
