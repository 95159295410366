<app-modal
  [open]="true"
  [actions]="actions"
  [highlightPrimaryAction]="true"
  [size]="modalSize"
  (handleAction)="handleAction($event)"
>
  <div class="modal-content">
    <heading 
      [icon]="icon" 
      [color]="UiColors.Blue">
      <h4 class="heading-title">{{title}}</h4>
    </heading>
    <div class="modal-body">
        <p class="message">{{message}}</p>
        <div *ngIf="details">
            <div class="bucket-details">
                <p>Bucket ID: {{details.bucketId ? details.bucketId : 'N/A'}}</p>
                <app-icon
                class="link-icon"
                (mouseenter)="color = UiColors.Blue"
                (mouseleave)="color = UiColors.Grey8"
                *ngIf="details.bucketId"
                [color]="color"
                [size]="2.5"
                [icon]="IconTypes.Link"
                (click)="navigateToBucket()"
              ></app-icon>
            </div>
            
            <p>Business: {{details.businessName ? details.businessName : 'N/A'}}</p>
            <p>Warehouse: {{details.warehouseName ? details.warehouseName : 'N/A'}}</p>
        </div>
        <div class="radio-buttons">
            <div *ngFor="let radio of radioOptions" class="radio-buttons">
                <input id={{radio}} name="radio" type="radio" [(ngModel)]="selectedRadio" value={{radio}} />
                <label for={{radio}} (click)="selectedRadio = radio">{{radio}}</label>
            </div>
        </div>
    </div>
  </div>
</app-modal>
