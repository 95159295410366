import { Component, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { ModalComponentProps, ModalSizes } from './modal.interfaces';
import { ButtonTypes } from '../buttons/basic-button.component';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements ModalComponentProps {
  @Input() open = false;
  @Input() highlightPrimaryAction = true;
  @Input() actions; // Put Primary action last.
  @Input() size: ModalSizes;
  @Input() hideFooter: boolean;
  @Output() handleAction = new EventEmitter<string>();

  public ButtonTypes = ButtonTypes;

  handleClick($event) {
    this.handleAction.emit($event);
  }

  @HostListener('document:click', ['$event'])
  closeModal(event) {
    if (this.open) {
      if (event.target.classList.contains('open')) {
        this.handleAction.emit('cancel');

        this.open = false;
      }
    }
  }
}
