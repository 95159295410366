import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { parcelTableColumns, parcelTableDataMap } from 'app/dashboard/new-trips/new-trips.constants';
import { MapMarkerColors, UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { JustifyContent } from 'app/shared/flex-container/flex-container.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { ModalSizes } from 'app/shared/modals/modal.interfaces';
import { TableActionTaken, TableCell } from 'app/shared/table/table.interfaces';
import { LastMileService } from '../last-mile.service';
import { Parcel } from 'app/dashboard/new-trips/new-trips.interfaces';
import { UserRoles } from 'app/interfaces/auth.interfaces';
import { MapTypes } from 'app/shared/map-view/map-view.interfaces';

@Component({
  selector: 'app-last-mile-staged-assignments',
  templateUrl: './last-mile-staged-assignments.component.html',
  styleUrls: ['./last-mile-staged-assignments.component.scss'],
})
export class LastMileStagedAssignmentsComponent implements OnInit {
  @Input() lastMileId: string;
  @Input() Parcels: Parcel[];
  @Input() showMap: boolean = false
  @Output() refreshDataEvent = new EventEmitter();

  toggleShowMap = false;

  driverParcels: Parcel[];
  driverTravelData: any;
  markers = [];
  parcelColumns = parcelTableColumns;
  parcelDataMap = parcelTableDataMap;
  parcelBulkActions = [
    {
      event: 'force-assign',
      title: 'Merge Orders',
      icon: IconTypes.Truck,
      icon_color: UiColors.Blue,
    },
    {
      event: 'unassign',
      title: 'Unassign',
      icon: IconTypes.Cross,
      icon_color: UiColors.Red,
    }
  ];

  IconTypes = IconTypes;
  UiColors = UiColors;
  ModalSizes = ModalSizes;
  UiThemes = UiThemes;
  public icons = {
    types: IconTypes,
    colors: UiColors,
  };
  mapTypes = MapTypes;
  JustifyContent = JustifyContent;
  buttonTypes = ButtonTypes;
  driverId = null;

  constructor(private lastMileService: LastMileService) {}

  ngOnInit(): void {
    if(this.lastMileService.actingAs.roles.includes(UserRoles.SuperAdministrator)){
      this.parcelBulkActions = [
        {
          event: 'force-assign',
          title: 'Merge Orders',
          icon: IconTypes.Truck,
          icon_color: UiColors.Blue,
        },
        {
          event: 'unassign',
          title: 'Unassign',
          icon: IconTypes.Cross,
          icon_color: UiColors.Red,
        }
      ];
    } else {
      this.parcelBulkActions = [{
        event: 'unassign',
        title: 'Unassign',
        icon: IconTypes.Cross,
        icon_color: UiColors.Red,
      }]
    }
    this.constructMapData()
    this.driverId = this.Parcels[0]?.DriverId
  }

  handleTableActions($event: TableActionTaken): void {
    const parcelArray: string[] = [];
    $event.rows.forEach((element) => {
      const waybill = element.cells.find((cell: TableCell) => cell.column === 'ParcelWaybill').value.toString();
      parcelArray.push(waybill);
    });
    switch ($event.action.event) {
      case 'force-assign': {
        this.lastMileService
          .forceAssignStagedParcels(parcelArray, this.lastMileId)
          .then(() => this.refreshDataEvent.emit());
        break;
      }
      case 'unassign': {
        this.lastMileService.unassignStagedParcels(parcelArray, this.lastMileId).then(() => {
          this.refreshDataEvent.emit();
        });
        break;
      }
    }
  }

  constructMapData(): void {
    this.markers = [];
    const Parcels = Object.values(this.Parcels);
    Parcels.forEach((parcel: any) => {
      const origin = parcel.OriginWarehouse ? parcel.OriginWarehouse : parcel.Origin;
      const destination = parcel.DestinationWarehouse ? parcel.DestinationWarehouse : parcel.Destination;
      const markerColours = {
        Collection: MapMarkerColors.Blue,
        Delivery: MapMarkerColors.Blue,
      };
      const marker1 = {
        latitude: destination.Latitude,
        longitude: destination.Longitude,
        status: markerColours.Delivery,
        label: 'D',
      };
      const marker2 = {
        latitude: origin.Latitude,
        longitude: origin.Longitude,
        status: markerColours.Collection,
        label: 'C',
      };
      this.markers.push(marker1, marker2);
    });
  }

  goToDriver(): void {
    if (!this.driverId) {
      return;
    }
    window.open(window.location.origin + '/dashboard/manage-fleet/drivers/' + this.driverId, '_blank');
  }
}
