<heading [icon]="iconTypes.Details" [color]="uiColors.Blue" mat-dialog-title>
    <h4 class="heading-title">{{ 'Notes' }}</h4>
    <div class="controls">
      <app-basic-button
        [title]="'Open Order'"
        [active]="true"
        [type]="buttonTypes.Highlight"
        (click)="openOrder()"
      >
      </app-basic-button>
    </div>
</heading>
<mat-dialog-content class="container">
  <app-flex-container [flexDirection]="'column'" [flexWrap]="'wrap'" [padding]="'0'" [theme]="UiThemes.Dark">
    <ng-container *ngIf="orderNotes$ | async as notes; else noNotes">
      <ng-container *ngIf="notes.length > 0; else noNotes">
        <ng-container *ngFor="let note of notes">
        <app-grid-container class="note" [borderBottom]="true" [columns]="'1fr 1fr 1fr 3fr 1fr'">
          <h4 class="author">{{ note.author_name }}</h4>
          <p class="category">
            {{ note?.category? note?.category :  'No Category' }}
          </p>
          <p class="timestamp">{{ convertTime(note.timestamp) }}</p>
          <p
            #content
            [innerHTML]="note.content"
            class="content"
          ></p>
          <div class="public" *ngIf="isAdmin">
            <input
              [disabled]="true"
              type="checkbox"
              (change)="note.public = !note.public"
              [checked]="note.public"
            />
            {{ 'Public' }}
          </div>
        </app-grid-container>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #noNotes>
    <ng-container *ngIf="loadingNotes$ | async; else noData">
      <app-loader [loadingMessage]="'Fetching Notes'" [displayLoader]="true">
      </app-loader>
    </ng-container>
    <ng-template #noData>
      <h4 class="noData">No notes present. Add one now</h4>
    </ng-template>
  </ng-template>
  </app-flex-container>
</mat-dialog-content>

