<app-modal
  [open]="true"
  [actions]="actions"
  [highlightPrimaryAction]="true"
  [size]="ModalSizes.Medium"
  (handleAction)="handleAction($event)"
>
  <heading [icon]="iconType" [color]="iconColor">
    <h4 class="heading-title">{{ title }}</h4>
  </heading>

  <div class="modal-body">
    <app-flex-container [flexDirection]="'column'" [flexWrap]="'wrap'" [padding]="'2rem'" [theme]="UiThemes.Light">
      <ng-template ngFor let-index="index" let-field [ngForOf]="content">
        <input-field
          [type]="types?.length ? types[index] : 'text'"
          (valueChanged)="user_input[index] = $event"
          [autoComplete]="'off'"
          [value]="user_input[index] ? user_input[index] : ''"
          [label]="field"
        ></input-field>
      </ng-template>
    </app-flex-container>
  </div>
</app-modal>
