<app-modal
  [open]="true"
  [actions]="actions"
  [highlightPrimaryAction]="true"
  (handleAction)="handleAction($event)"
  [size]="ModalSizes.Large"
>
  <heading [icon]="IconTypes.Bucket" [color]="uiColors.Blue">
    <h4 class="heading-title">Select a bucket</h4>
    <div class="warehouse-selector">
      <app-dropdown
        [activeItem]="selected_warehouse ? selected_warehouse.name : 'Select a Warehouse'"
        [dropdownTheme]="'grey'"
      >
        <div dropdownRef>
          <a class="dropdown-item" (click)="selectWarehouse(null)">{{ 'All' }}</a>
          <a *ngFor="let warehouse of availableWarehouses" class="dropdown-item" (click)="selectWarehouse(warehouse)">{{
            warehouse.name
          }}</a>
        </div>
      </app-dropdown>
    </div>
    <app-basic-button [title]="'Create New Bucket'" [type]="buttonTypes.Primary" (click)="onCreateBucket()"> </app-basic-button>
  </heading>
  <div class="modal-content">
    <app-table
      *ngIf="showTable"
      [rawData]="shownBuckets"
      [dataMap]="availableBucketsTableDataMap"
      [columns]="availableBucketsTableColumns"
      [isLoading]="!availableBuckets"
      [primaryActions]="availableBucketsPrimaryActions"
      (actionTaken)="selectBucket($event)"
      [rowsSelectable]="false"
    >
    </app-table>
    <div class="empty-view-wrap" *ngIf="!showTable">
      <div class="empty-view-container-wrap">
        <div class="empty-view-container">
          <h4 class="empty-view-message-text">
            {{ 'Please select a warehouse above' }}
          </h4>
        </div>
      </div>
    </div>
  </div>
</app-modal>
