import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import {
  CollapsibleTableTypeEnum,
  CourierOrder,
  LastMile,
  NotificationTypeEnum,
  Order,
  Paginate,
  PaginateEnum,
  PD_Courier_Order_Table_Actions,
  PostDispatchTableEnum,
} from 'app/shared/shared.interfaces';
import { SortEvent, TableAction, TableActionTaken, TableCell } from 'app/shared/table/table.interfaces';
import { CollapsibleDirective } from 'app/directives/collapsible/collapsible.directive';
import { NewTripsService } from 'app/dashboard/new-trips/new-trips.service';
import { environment } from 'environments/environment';
import { NotificationsService } from '../notifications/notifications.service';
import { FleetAllocationEnum } from 'app/dashboard/new-trips/new-trips.interfaces';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { IconTypes } from '../icon/icon.interfaces';
import { MatDialog } from '@angular/material/dialog';
import { NotesModalComponent } from 'app/dashboard/notes/notes-modal/notes-modal.component';
import { NotesService } from 'app/dashboard/notes/notes.service';
import { OrderSummaryPopupComponent } from '../order-summary-popup/order-summary-popup.component';

@Component({
  selector: 'app-collapsible-order-table',
  templateUrl: './collapsible-order-table.component.html',
  styleUrls: ['./collapsible-order-table.component.scss']
})
export class CollapsibleOrderTableComponent implements OnInit, OnChanges {
  @Input() trips: Order[] | LastMile[] | CourierOrder[];
  @Input() tripsTotal: number;
  @Input() tripType: string;
  @Input() objectType: CollapsibleTableTypeEnum; // Order, LastMile, CourierOrder
  @Input() icon: IconTypes;
  @Input() color: UiColors;
  @Input() tableDataMap;
  @Input() tableColumns;
  @Input() primaryActions: TableAction[];
  @Input() activeWarehouseFilter;
  @Input() paginationTableEnum: PostDispatchTableEnum;
  @Input() showPagination: boolean;
  @Input() defaultDisplayAmount: number = 25;
  @Input() paginationDisplayAmounts: number[] = environment.defaultPaginationSizes;
  @Input() expanded: boolean;
  @Input() bulkActions: TableAction[];
  @Input() isAdmin: boolean;
  @Output() paginateEvent = new EventEmitter<Paginate>();
  @Output() tableSortChanged = new EventEmitter<SortEvent>();
  @Output() reconcileEvent = new EventEmitter<string[]>();
  @ViewChild(CollapsibleDirective) el: CollapsibleDirective;
  hasPaginated: boolean;
  currentPageIndex: number = 1;
  totalPages: number = 1;

  constructor(
    private router: Router,
    private newTripsService: NewTripsService,
    private notificationService: NotificationsService,
    private dialog: MatDialog,
    private notesService: NotesService
  ) {}

  ngOnInit(): void {
    this.getTotalPages(this.defaultDisplayAmount);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.firstChange && this.el && this.hasPaginated && this.el.expanded) {
      this.el.resize();
    }

    this.getTotalPages(this.defaultDisplayAmount);
  }

  getTotalPages(displayAmount: number): void {
    if (this.tripsTotal > 0) {
      const value = Math.ceil(this.tripsTotal / displayAmount);
      if (value < 1) {
        this.totalPages = 1;
      } else {
        this.totalPages = value;
      }
    }
  }

  handlePagination($event: Paginate): void {
    this.hasPaginated = true;
    this.getTotalPages($event.Amount);
    if ($event.Direction === PaginateEnum.Forward && this.currentPageIndex < this.totalPages) {
      this.currentPageIndex++;
    } else if ($event.Direction === PaginateEnum.Backward && this.currentPageIndex > 1) {
      this.currentPageIndex--;
    }

    if ($event.Amount !== this.defaultDisplayAmount) {
      this.currentPageIndex = 1;
    }
    this.paginateEvent.emit($event);
  }

  handleSort($event: SortEvent): void {
    this.tableSortChanged.emit($event);
  }

  handleSQLTableActions($event: TableActionTaken): void {
    switch (this.objectType) {
      case CollapsibleTableTypeEnum.Order:
        this.handleOrderSelect($event);
        break;
      case CollapsibleTableTypeEnum.LastMile:
        this.handleDriverTableActions($event);
        break;
      case CollapsibleTableTypeEnum.CourierOrder:
        this.handleCourierOrderTableActions($event);
        break;
    }
  }

  handleOrderSelect($event: TableActionTaken): void {
    const tripId = $event.rows[0].cells.find((cell) => cell.column === 'trip_id')?.value.toString();
    const fleetAllocation = $event.rows[0].cells.find((cell) => cell.column === 'fleet_allocation')?.value.toString();
    const selBox = document.createElement('textarea');
    switch ($event.action.event) {
      case 'viewTrip':
        if (this.isAdmin) {
          window.open(window.location.origin + '/dashboard/orders/trip/' + tripId, '_blank');
        } else {
          this.router.navigate(['dashboard/orders/trip/', tripId]);
        }
        break;
      case 'startLottery':
        // Only start as Picup Lottery for now.
        this.newTripsService.startLotteryBackground(tripId, FleetAllocationEnum.Picup).then(() => {
          this.notificationService.publish({ message: 'Lottery restart successful', type: NotificationTypeEnum.Success});
        });
        break;
      case 'copyId':
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = tripId as string;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        break;
      case 'notes':
      {
        this.notesService.getOrderNotesActual(tripId).
          then(querySnapshot => {
            const picupNotes = [];
            const noteIds = [];
            querySnapshot.forEach((note) => {
              if (this.isAdmin) {
                picupNotes.push(note.data());
                noteIds.push(note.id);
              } else if (note.data().public === true) {
                picupNotes.push(note.data());
                noteIds.push(note.id);
              }
            });
            if(picupNotes) {
              const dialogRef = this.dialog.open(NotesModalComponent,
                {
                  panelClass: 'noBorder',
                  height: '400px',
                  width: '800px'
                });
              dialogRef.componentInstance.id = tripId;
              dialogRef.componentInstance.isAdmin = this.isAdmin;
            }
          });
        break;
      }
      case 'summary':
      {
        if(tripId){
          this.dialog.open(
            OrderSummaryPopupComponent,
            {
              panelClass: 'noBorder',
              width: '800px',
              height: '280px',
              data:{
                orderId: tripId
              }
            }
          );
        }
        break;
      }
      case 'assign':
      {
        if(tripId){
          this.newTripsService.reserveOrderForUser(tripId)
        }
        break;
      }
      case 'unassign':
      {
        if(tripId){
          this.newTripsService.unreserveOrderForUser(tripId)
        }
        break;
      }
    }
  }

  handleDriverTableActions($event: TableActionTaken): void {
    const lastMileId = $event.rows[0].cells.find((cell) => cell.column === 'last_mile_id').value as string;
    const selBox = document.createElement('textarea');
    switch ($event.action.event) {
      case 'viewTrip':
        if (this.isAdmin) {
          window.open(window.location.origin + '/dashboard/last-mile/' + lastMileId, '_blank');
        } else {
          this.router.navigate(['dashboard/last-mile/', lastMileId]);
        }
        break;
      case 'copyId':
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = lastMileId as string;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        break;
    }
  }

  handleCourierOrderTableActions($event: TableActionTaken): void {
    const ids: string[] = [];
    $event.rows.forEach((element) => {
      ids.push(element.cells.find((cell: TableCell) => cell.column === 'courier_order_id').value.toString());
    });
    const selBox = document.createElement('textarea');
    switch ($event.action.event) {
      case 'viewTrip':
        if (this.isAdmin) {
          window.open(window.location.origin + '/dashboard/courier-orders/order/' + ids[0], '_blank');
        } else {
          this.router.navigate(['dashboard/courier-orders/order/', ids[0]]);
        }
        break;
      case 'copyId':
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = ids[0];
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        break;
      case PD_Courier_Order_Table_Actions.Reconcile:
        this.reconcileEvent.emit(ids);
        break;
    }
  }
}
