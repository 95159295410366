import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Router } from '@angular/router';
import { StoreAction } from '../interfaces/auth.interfaces';
import { AuthService } from './auth.service';
import { SET_ACCOUNTS, SET_ACTING_AS } from './auth.reducer';

@Injectable({
  providedIn: 'root',
})
export class AuthEffects {
  constructor(private actions$: Actions<StoreAction>, public router: Router, private authService: AuthService) {}

  @Effect({ dispatch: false })
  public accountSelected(): Observable<StoreAction> {
    return this.actions$.pipe(
      ofType(SET_ACTING_AS),
      tap(() => {
        this.authService.getUserAccessRoles();
        this.authService.getWarehousesForBusiness();
      })
    );
  }

  @Effect({ dispatch: false })
  saveAccounts(): Observable<StoreAction> {
    return this.actions$.pipe(
      ofType(SET_ACCOUNTS),
      tap(() => {
        this.authService.initialize();
      })
    );
  }

  // @Effect({ dispatch: false })
  // updateAccounts(): Observable<StoreAction> {
  //   return this.actions$.pipe(
  //     ofType(STORE_INIT),
  //     tap(() => {
  //       this.authService.refreshAccount();
  //     })
  //   );
  // }
}
