import { distinctUntilChanged, filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { BreadCrumb } from './breadcrumbs';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit {
  breadcrumbs$;
  constructor(public router: Router, public activatedRoute: ActivatedRoute) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        distinctUntilChanged()
      )
      .subscribe(() => (this.breadcrumbs$ = this.buildBreadCrumb(this.activatedRoute.root)));
  }

  buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: Array<BreadCrumb> = []): Array<BreadCrumb> {
    // Initial route goes below if you want to add later
    const label = route.routeConfig ? route.routeConfig.data['breadcrumb'] : '';
    const path = route.routeConfig ? route.routeConfig.path : '';

    const nextUrl = `${url}${path}/`;
    const breadcrumb = {
      label: label,
      url: nextUrl,
    };
    const newBreadcrumbs = [...breadcrumbs, breadcrumb];
    if (route.firstChild) {
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    newBreadcrumbs.splice(0, 1);
    // TODO: REMOVE THIS WHEN FULL SITE IS FLESHED OUT
    return newBreadcrumbs;
  }

  ngOnInit() {
    this.breadcrumbs$ = this.buildBreadCrumb(this.activatedRoute.root);
  }
}
