import { Component, Input, OnInit } from '@angular/core';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import {
  mfFindNestedValueByProperty,
  mfGetArrayPropertyCommaSeparated,
  mfGetNestedObjectValue,
} from 'app/shared/table/map-formatters.table.helpers';
import { TableActionTaken, TableCell, TableColumn, TableDataMap } from 'app/shared/table/table.interfaces';
import { NewTripsService } from '../new-trips.service';

@Component({
  selector: 'app-new-trips-failed-courier',
  templateUrl: './new-trips-failed-courier.component.html',
  styleUrls: ['./new-trips-failed-courier.component.scss'],
})
export class NewTripsFailedCourierComponent implements OnInit {
  @Input() failedCourierOrders;

  public UiThemes = UiThemes;
  public icons = {
    types: IconTypes,
    colors: UiColors,
  };

  failedCourierTableColumns: TableColumn[] = [
    { name: 'courier_order_id', displayName: '', hidden: true },
    { name: 'courier_name', displayName: 'Courier Name' },
    { name: 'courier_type', displayName: 'Courier Type' },
    { name: 'parcels', displayName: 'Parcels' },
    { name: 'destination_name', displayName: 'Customer Name' },
    { name: 'destination_phone', displayName: 'Phone' },
    { name: 'destination_address', displayName: 'Address' },
    { name: 'error_message', displayName: 'Message' },
  ];

  failedCourierTableDataMap: TableDataMap = {
    cells: {
      courier_order_id: { column: 'courier_order_id', map: 'courier_order_id' },
      courier_name: { column: 'courier_name', map: 'courier_name' },
      courier_type: {
        column: 'courier_type',
        map: 'courier_type',
      },
      parcels: { column: 'parcels', map: ['parcel_waybills'], format: mfGetArrayPropertyCommaSeparated },
      destination_name: {
        column: 'destination_name',
        map: ['contacts', 'destination_name'],
        format: mfGetNestedObjectValue,
      },
      destination_phone: {
        column: 'destination_phone',
        map: ['contacts', 'destination_phone'],
        format: mfGetNestedObjectValue,
      },
      destination_address: {
        column: 'destination_address',
        map: ['contacts', 'destination_address'],
        format: mfGetNestedObjectValue,
      },
      error_message: { column: 'error_message', map: 'error_message' },
    },
  };

  failedCourierBulkActions = [
    { event: 'reattempt', title: 'Re-Attempt', icon: IconTypes.Check, icon_color: UiColors.Green },
  ];

  constructor(private newTripsService: NewTripsService) {}

  ngOnInit(): void {}

  handleTableActions($event: TableActionTaken): void {
    const courier_order_id_array = [];
    $event.rows.forEach((element) => {
      courier_order_id_array.push(element.cells.find((cell: TableCell) => cell.column === 'courier_order_id').value);
    });
    this.newTripsService.enqueueCourierOrders(courier_order_id_array);
  }
}
