import { TableCell, TableDataByColumn, TableRow, TableSummaryData } from './table.interfaces';
import { calcSafeSum } from '../helpers';

export const calcSummaryData = (columnData: TableDataByColumn[], calcAverage: boolean = false): TableRow => {
  if (columnData.length === 0) {
    return <TableRow>{ cells: [] };
  }

  return <TableRow>{
    cells: columnData.map((column) => {
      // check if shouldCalculate is true, then check if all values in column array is not a number, if it is, set shouldCalculate to false.
      let value: string | number = '';
      if (column.values.length > 0 && column.shouldCalculate) {
        const valuesFiltered = column.values.filter((val) => typeof val === 'number');
        if (valuesFiltered.length > 0) {
          value = (column.values.reduce(calcSafeSum) as number) / (calcAverage ? column.values.length : 1);
        }
      }

      return <TableCell>{
        column: column.column,
        value,
        prefix: column.prefix,
        postfix: column.postfix,
        displayPipeArgs: calcAverage && column.displayPipeArgs === undefined ? '1.2-2' : column.displayPipeArgs,
      };
    }),
  };
};

export const getSummaryData = (columnData: TableDataByColumn[]): TableSummaryData => {
  return {
    totals: calcSummaryData(columnData),
    averages: calcSummaryData(columnData, true),
  };
};
