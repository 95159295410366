import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-vertical-grouped-bar',
  templateUrl: './vertical-grouped-bar.component.html',
  styleUrls: ['./vertical-grouped-bar.component.scss'],
})
export class VerticalGroupedBarComponent implements OnInit {
  constructor() {}
  @Input() noDataMessage;
  @Input() data;
  @Input() colorScheme;
  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Count';
  showYAxisLabel = true;
  yAxisLabel = 'Driver';

  ngOnInit() {}
}
