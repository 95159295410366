import { Component, OnInit } from '@angular/core';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { Store } from '@ngrx/store';
import { selectorUser, selectorBusinesses } from 'app/auth/auth.reducer';
import { ManageUserService } from './manage-user.service';
import { SimpleModalService } from 'ngx-simple-modal';
import { AddBusinessComponent } from '../manage-business/add-business/add-business.component';
import { AddCreditsComponent } from '../manage-business/add-credits/add-credits.component';
import { AuthUser } from 'app/interfaces/auth.interfaces';
import { ManageBusinessService } from '../manage-business/manage-business.service';
import { PhoneValidationPipe } from 'app/shared/pipes/phone-validation.pipe';

@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.scss'],
})
export class ManageUserComponent implements OnInit {
  public iconTypes = IconTypes;
  public buttonTypes = ButtonTypes;
  public UiThemes = UiThemes;
  uiColours = UiColors;

  sectionIds;
  selectedSection: string;
  user: AuthUser;
  user_model;
  businesses;
  phoneNotValid: boolean = false;

  constructor(
    public store: Store<any>,
    private simpleModalService: SimpleModalService,
    private manageUserService: ManageUserService,
    private manageBusinessService: ManageBusinessService,
    private phoneValidationPipe: PhoneValidationPipe
  ) {
    this.store.select(selectorBusinesses).subscribe((businesses) => (this.businesses = businesses));
    this.store.select(selectorUser).subscribe((user) => {
      this.user = user;
      this.setSectionIds();
      this.user_model = JSON.parse(JSON.stringify(user));
      this.getBusinessNames();
    });
  }

  addCredits(): void {
    this.simpleModalService.addModal(AddCreditsComponent).subscribe((result) => {
      if (!result) {
        return;
      }
      this.manageUserService.addCreditsToUser(result, this.user.user_id);
    });
  }

  createBusiness() {
    this.simpleModalService.addModal(AddBusinessComponent).subscribe((result) => {
      if (!result) {
        return;
      }
      this.manageBusinessService.createBusiness(result);
    });
  }

  setSectionIds() {
    this.sectionIds = [{ name: 'General' }, { name: 'Credits' }, { name: 'Invoices' }, { name: 'Address Book' }];
  }

  getBusinessNames() {
    if (this.user.preferred_business_id) {
      this.businesses.forEach((business) => {
        if (business.business_id === this.user.preferred_business_id) {
          this.user_model['preferred_business_name'] = business.name;
        }
        if (this.user.preferred_business_id === this.user.user_id) {
          this.user_model['preferred_business_name'] = this.user.name;
        }
      });
    }
  }

  resetPassword() {
    this.manageUserService.resetPassword();
  }

  saveChanges() {
    if (!this.phoneValidationPipe.transform(this.user_model.phone_number)) {
      this.phoneNotValid = true;
      return;
    }
    this.manageUserService.updateUser(this.user_model);
    this.phoneNotValid = false;
  }

  ngOnInit() {
    this.getBusinessNames();
  }
}
