import axios, { AxiosInstance } from 'axios';
import { environment } from 'environments/environment';
import SharedKeyGenerator from './sharedKeyGen';

const mainAxios = axios.create({
  baseURL: environment.integration.rootUrl,
});

export const unAuthedAxios = axios.create({
  baseURL: environment.integration.rootUrl,
});

const AZURE_STORAGE_KEY = environment.azure.storageKey;
const AZURE_STORAGE_ACCOUNT = environment.azure.storageAccount;
// Make sure that CORS is enabled for Table Service in Azure Storage account. *, GET, *, *
const sharedKey = new SharedKeyGenerator(AZURE_STORAGE_ACCOUNT, 'Webhooks', AZURE_STORAGE_KEY);
// These headers are requirements for Azure.
export const azureAxios = (utcDateString: string): AxiosInstance => axios.create({
  baseURL: `https://${AZURE_STORAGE_ACCOUNT}.table.core.windows.net`,
  timeout: 2000,
  headers: {
    'x-ms-date': utcDateString,
    Authorization: sharedKey.GenerateSharedKeyLite(utcDateString),
    Accept: 'application/json;odata=nometadata',
    'x-ms-version': '2015-12-11',
  },
});

export const firebaseAxios = axios.create({
  baseURL: environment.integration.firebaseFunction,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
});

mainAxios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('id_token');
  config.headers.Authorization = 'Bearer ' + token;
  return config;
});

mainAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default mainAxios;
