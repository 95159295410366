<div class="forgot-container">
    <div>
        <span class="heading-text">Password Reset</span>
    </div>
    <div>
        <form [formGroup]="forgotPasswordFormGroup">
            <!-- email -->
            <mat-form-field [appearance]="'standard'" class="input-style">
                <mat-label class="label-colour">Email</mat-label>
                <input 
                    matInput 
                    type="email" 
                    formControlName="emailControl" 
                    >
            </mat-form-field>
            <!-- Validation messages -->
            <ng-container *ngIf="forgotPasswordFormGroup.controls.emailControl?.invalid && forgotPasswordFormGroup.controls.emailControl?.touched" >
                <div *ngIf="forgotPasswordFormGroup.controls.emailControl?.errors?.required ?? false" 
                    class="validation-text">
                    Email is required
                </div>
                <div *ngIf="forgotPasswordFormGroup.controls.emailControl?.errors?.email ?? false"
                    class="validation-text">
                    Invalid Email
                </div>
            </ng-container>
        </form>
    </div>
    <div class="button-container">
        <!-- buttons -->
        <div 
            class="forgot-style" 
            (click)="back()">
            Back
        </div>
        <div>
            <button 
                [disabled]="forgotPasswordFormGroup.controls.emailControl?.invalid || (authService.isResetPasswordLoading$ | async)"
                class="login-button-style" 
                mat-raised-button 
                (click)="submit()">
                <div class="button-content">
                    <svg-icon 
                    *ngIf="authService.isResetPasswordLoading$ | async"
                    [svgStyle]="{ 'width.rem': '1' }" 
                    src="/assets/img/icons/spinner.svg" 
                    class="spin">
                    </svg-icon>
                    send link
                </div>
            </button>
        </div>
    </div>
</div>
