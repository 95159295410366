import { IconProps, IconTypes } from 'app/shared/icon/icon.interfaces';
import { TableDataMapIconFormatter } from 'app/shared/table/table.interfaces';
import { ParcelTypes } from '../trips.enums';
import { BucketState, Bucket, BucketStatuses, BucketStates, BucketStatesUi } from './buckets.interfaces';

export const getBucketState = (bucket: Bucket): BucketState => {
  return {
    state: bucket.current_state,
    status: bucket.current_status,
    progress: 0,
    bucket_id: bucket.bucket_id,
  };
};

export const getBucketUiState = (bucketState: BucketState): BucketStatesUi | boolean => {
  if (!bucketState) {
    return false;
  }

  switch (bucketState.state) {
    case BucketStates.Alert:
      return BucketStatesUi.Alert;
    case BucketStates.Importing:
      return BucketStatesUi.Importing;
    case BucketStates.AutoGeocoding:
      return BucketStatesUi.AutoGeocoding;
    case BucketStates.PreRouteManagement:
      return BucketStatesUi.Verifying;
    case BucketStates.PollingRoutific:
      if (bucketState.status === BucketStatuses.Pending) {
        return BucketStatesUi.CostingSettings;
      }
      return BucketStatesUi.Costing;
    case BucketStates.Costing:
      return BucketStatesUi.Costing;
    case BucketStates.SelectingTrips:
      return BucketStatesUi.TripSelection;
    case BucketStates.SelectingCourierOrders:
      return BucketStatesUi.CourierOrderSelection;
    case BucketStates.Dispatching:
      if (bucketState.status === BucketStatuses.Completed) {
        return BucketStatesUi.Dispatched;
      }
      return BucketStatesUi.Confirmation;
    default:
      return false;
  }
};

export const setBucketUiState = (uiState: BucketStatesUi, bucket: Bucket): BucketState => {
  const tempBucketState: BucketState = getBucketState(bucket);

  if (!uiState) {
    return tempBucketState;
  }

  switch (uiState) {
    case BucketStatesUi.Importing:
      return Object.assign({}, tempBucketState, { state: BucketStates.Importing, status: BucketStatuses.Completed });
    case BucketStatesUi.AutoGeocoding:
      return Object.assign({}, tempBucketState, {
        state: BucketStates.AutoGeocoding,
        status: BucketStatuses.Completed,
      });
    case BucketStatesUi.Verifying:
      return Object.assign({}, tempBucketState, {
        state: BucketStates.PreRouteManagement,
        status: BucketStatuses.Completed,
      });
    case BucketStatesUi.CostingSettings:
      return Object.assign({}, tempBucketState, {
        state: BucketStates.PollingRoutific,
        status: BucketStatuses.Pending,
      });
    case BucketStatesUi.Costing:
      return Object.assign({}, tempBucketState, { state: BucketStates.Costing, status: BucketStatuses.Completed });
    case BucketStatesUi.TripSelection:
      return Object.assign({}, tempBucketState, {
        state: BucketStates.SelectingTrips,
        status: BucketStatuses.Completed,
      });
    case BucketStatesUi.CourierOrderSelection:
      return Object.assign({}, tempBucketState, {
        state: BucketStates.SelectingCourierOrders,
        status: BucketStatuses.Completed,
      });
    case BucketStatesUi.Confirmation:
      return Object.assign({}, tempBucketState, { state: BucketStates.Dispatching, status: BucketStatuses.Pending });
    case BucketStatesUi.Dispatched:
      return Object.assign({}, tempBucketState, { state: BucketStates.Dispatching, status: BucketStatuses.Completed });
  }
};

export const mfiParcelType: TableDataMapIconFormatter = (map: string[], item: any, icon: IconProps): IconProps => {
  if (item[map[0]] === ParcelTypes.Collection) {
    icon.icon = IconTypes.Collection;
  }

  if (item[map[0]] === ParcelTypes.Delivery) {
    icon.icon = IconTypes.Delivery;
  }

  return icon;
};
