import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import firebase from 'firebase/app';
import { AcceptedDriverDocumentFileTypesEnum, DriverImagesEnum, Order } from 'app/shared/shared.interfaces';
import { DriverAssignedOrders, DriverDetails, DriverState, UpdateDriverDetailsRequest } from './driver.interface';
import { NotificationTest } from 'app/dashboard/drivers/drivers.interface';
import { firebaseAxios } from 'app/api/axios';
import axios from 'app/api/axios';
import { NotificationsService } from 'app/shared/notifications/notifications.service';
import { AutoNotesTypes } from 'app/dashboard/notes/notes.constants';
import { AutoNotesService } from 'app/dashboard/notes/auto-notes.service';

@Injectable({
  providedIn: 'root'
})
export class DriverService {
  driverDetails$: BehaviorSubject<DriverDetails> = new BehaviorSubject(null);
  driverState$: BehaviorSubject<DriverState> = new BehaviorSubject(null);
  showMap$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  set showMap(value: boolean){
    this.showMap$.next(value);
  }

  constructor(private firestore: AngularFirestore, private notificationService: NotificationsService, private autoNotesService: AutoNotesService) { }

  getDriverDetails(id: string): Observable<DriverDetails> {
    return this.firestore.collection<DriverDetails>('driver-details').doc(id).valueChanges();
  }

  getDriverDetailsForMap(id: string): Observable<firebase.firestore.DocumentSnapshot<DriverDetails>> {
    return this.firestore.collection<DriverDetails>('driver-details').doc(id).get();
  }
  getDriverStateForMap(id: string): Observable<firebase.firestore.DocumentSnapshot<DriverState>> {
    return this.firestore.collection<DriverState>('driver-states').doc(id).get();
  }

  getDriverAssignedOrders(id: string): Observable<DriverAssignedOrders> {
    return this.firestore.collection<DriverAssignedOrders>('driver-assigned-orders').doc(id).valueChanges();
  }

  getDriverState(id: string): Observable<DriverState> {
    return this.firestore.collection<DriverState>('driver-states').doc(id).valueChanges();
  }

  getDriverImage(id: string, imageType: DriverImagesEnum, fileType: AcceptedDriverDocumentFileTypesEnum): Promise<string> {
    const storage = firebase.storage();
    const pathReference = storage.ref(`drivers/${id}/${imageType}.${fileType}`);
    return pathReference
      .getDownloadURL()
      .then((url) => {
        return url;
      })
      .catch(() => {
        return null;
      });
  }

  getExistingDriverFileTypes(driver_id: string): Promise<string[]> {
    const storage = firebase.storage();
    const pathReference = storage.ref(`drivers/${driver_id}`);
    return pathReference.listAll().then((response) => {
      const items = [];
      response.items.forEach((image) => {
        items.push(image.fullPath.split('/')[2].split('.')[0]);
      });
      return items;
    });
  }

  getExistingDriverFileTypesForIds(driver_ids: string[]): void {
    driver_ids.forEach(driver_id => {
      const storage = firebase.storage();
      const pathReference = storage.ref(`drivers/${driver_id}`);
      return pathReference.listAll().then((response) => {
        const items = [];
        response.items.forEach((image) => {
          items.push(image.fullPath.split('/')[2].split('.')[0]);
        });
        return items;
      });
    })
  }

  getDriverHistory(driverName: string) {
    return this.firestore.collection<Order>('orders', (ref) => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      if (driverName) {
        query = query.where('DriverNames', 'array-contains', driverName);
      }
      query = query.orderBy('Timestamp', 'desc').limit(100);
      return query;
    }).get();
  }

  updateDriverStatePlusRequestsOnly(driverId: string, plusRequestsOnly: boolean): Promise<void> {
    return this.firestore.doc('driver-states/' + driverId).update({ plus_requests_only: plusRequestsOnly });
  }

  updateDriverStateOnline(driverId: string, online: boolean): Promise<void> {
    return this.firestore.doc('driver-states/' + driverId).update({ online });
  }

  updateDriverCanSeeEarnings(driverId: string, can_see_earnings: boolean): Promise<void> {
    return this.firestore.doc('driver-details/' + driverId).update({ can_see_earnings });
  }

  updateDriverStateDirectAssignment(driverId: string, is_eligible_for_direct_assignment: boolean): Promise<void> {
    return this.firestore.doc('driver-states/' + driverId).update({ eligible_for_direct_assignment: is_eligible_for_direct_assignment });
  }

  async testDriverNotification(data: NotificationTest): Promise<void> {
    return firebaseAxios({
      method: 'POST',
      url: '/sendTestNotificationToDriverIds',
      data: data,
    }).then((res) => {
      return res.data;
    });
  }

  updateDriverActiveState(driverId: string, setActive: boolean): Promise<any> {
    return axios({
      method: 'POST',
      url: `/driver/${driverId}/${setActive ? 'activate' : 'deactivate'}`,
    }).then(() => {
      this.autoNotesService.generateNote(
        {
          autoNotesType: setActive ? AutoNotesTypes.driverActivated : AutoNotesTypes.driverDeactivated,
          driverId: driverId
        })
    }).catch((error) => {
      this.notificationService.publish({ type: 'error', message: error.response?.data.message });
    });
  }

  setRole(driver_id: string, role: number): Promise<void> {
    return axios({
      method: 'POST',
      url: '/driver/' + driver_id + '/set-role',
      data: { role },
    })
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  async getPlusAssignments(driver_id: string): Promise<void> {
    return axios({
      method: 'POST',
      url: '/generic/cqrs/get-picupplus-businesses-and-warehouses-for-driver',
      data: { driver_id: driver_id },
    })
      .then((response) => {
        return response?.data.output;
      })
      .catch((error) => {
        throw error;
      });
  }

  async updateDriverDetails(id: string, data: UpdateDriverDetailsRequest): Promise<void> {
    return axios({
      method: 'POST',
      url: `driver/${id}/update-driver-details`,
      data
    })
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        throw error;
      });
  }
}
