<!-- todo:  [activeWarehouseFilter]="activeWarehouseFilter" -->
<!-- Completed Orders -->
 <app-collapsible-order-table
 *ngIf="completedFirestoreTrips | async as completedTrips"
 [tripType]="'Completed Trips'"
 [trips]="completedTrips"
 [tripsTotal]="completedTotal"
 [objectType]="CollapsibleTableTypeEnum.Order"
 [icon]="Icons.Types.Check"
 [color]="Icons.Colors.Green"
 [tableDataMap]="fsOrderTableDataMap"
 [tableColumns]="fsOrderTableColumns"
 [primaryActions]="postDispatchTablePrimaryActions"
 [defaultDisplayAmount]="completedAmount"
 [showPagination]="true"
 (paginateEvent)="handleCompletedTrips($event)"
 (tableSortChanged)="handleTripsSortChange($event)"
 >
 </app-collapsible-order-table>
