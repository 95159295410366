import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FSAdminOrderTableColumns, FSAdminOrderTableDataMap } from 'app/admin/admin.contants';
import { OrderFrontEndStateEnum } from 'app/dashboard/post-dispatch/post-dispatch.interfaces';
import { PostDispatchService } from 'app/dashboard/post-dispatch/post-dispatch.service';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { Order, CollapsibleTableTypeEnum, PaginateEnum, Paginate } from 'app/shared/shared.interfaces';
import { TableAction, SortEvent } from 'app/shared/table/table.interfaces';
import { Observable, Subscription, from } from 'rxjs';

@Component({
  selector: 'app-tracking-completed',
  templateUrl: './tracking-completed.component.html',
  styleUrls: ['./tracking-completed.component.scss']
})
export class TrackingCompletedComponent implements OnInit, OnDestroy {

  @Input() postDispatchTablePrimaryActions: TableAction[] = [];
  Icons = {
    Colors: UiColors,
    Types: IconTypes,
  };

  completedFirestoreTrips: Observable<Order[]>;
  completedAmount: number = 10;
  completedSubscription: Subscription;
  completedTotal: number;

  fsOrderTableColumns = FSAdminOrderTableColumns;
  fsOrderTableDataMap = FSAdminOrderTableDataMap;

  CollapsibleTableTypeEnum = CollapsibleTableTypeEnum;
  OrderFrontEndStateEnum = OrderFrontEndStateEnum;

  constructor(private postDispatchService: PostDispatchService) { }

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy(): void {
    this.completedSubscription.unsubscribe();
  }

  getData(): void {
    this.handleCompletedTrips({ Amount: this.completedAmount, Direction: PaginateEnum.None });
    this.handlePostDispatchTotals();
  }

  handlePostDispatchTotals(): void {
    this.postDispatchService.getPostDispatchTotals(false).then((data) => {
      this.completedTotal = data.output.find((x) => x.State === OrderFrontEndStateEnum.Completed)?.Cnt;
    });
  }

  handleCompletedTrips($event: Paginate): void {
    this.completedAmount = $event.Amount;
    this.completedSubscription?.unsubscribe();
    this.completedSubscription = this.postDispatchService
      .getFireStoreOrderSummaryStreamByState(this.completedAmount, $event.Direction, OrderFrontEndStateEnum.Completed, false)
      .subscribe((response) => {
        if (response[0]) {
          this.postDispatchService.setTripSnapshot(
            {
              First: response[0].payload.doc,
              Last: response[response.length - 1].payload.doc,
            },
            OrderFrontEndStateEnum.Completed
          );

          const data: Order[] = [];
          for (const item of response) {
            data.push(item.payload.doc.data());
          }
          this.completedFirestoreTrips = from([data]);
        } else {
          this.completedFirestoreTrips = from([]);
        }
      });
  }

  handleTripsSortChange($event: SortEvent): void {
    this.postDispatchService.orderOrderBy = $event;
    this.handleCompletedTrips({ Amount: this.completedAmount, Direction: PaginateEnum.None });
  }
}

