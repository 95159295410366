export enum Admin_Col_Finance {
  PicupId = 'picup_id',
  ParcelReference = 'parcel_reference',
  BusinessId = 'business_id',
  UserId = 'user_id',
  TripId = 'trip_id',
  State = 'state',
  CustomerReference = 'customer_reference',
  Owner = 'owner',
  DriverName = 'driver_name',
  DriverPhone = 'driver_phone',
  WaypointCount = 'waypoint_count',
  ParcelCount = 'parcel_count',
  MerchantCostQuote = 'merchant_cost_quote',
  MerchantCostActual = 'merchant_cost_actual',
  DriverEarningsQuote = 'driver_earnings_quote',
  DriverEarningsActual = 'driver_earnings_actual',
  NumberOfDrivers = 'number_of_drivers',
  WhenFinalized = 'when_finalized',
  WhenCompleted = 'when_completed',
  WhenCancelled = 'when_cancelled',
  WhenFinancialsApproved = 'when_financials_approved',
  Vehicle = 'vehicle',
}

export interface LedgerResponse {
  driver_ledger_id: string;
  opening_balance: number;
  closing_balance: number;
  driver_name: string;
  items: LedgerItems[];
}

export interface LedgerItems {
  amount: number;
  balance: number;
  reason: string;
  timestamp: string;
  client: string;
}

export interface FBDriverModel {
  account_number: string;
  acting_as: number;
  area: string;
  branch_code: string;
  city: string;
  driver_id: string;
  email: string;
  is_activated: boolean;
  is_deleted: boolean;
  is_online: boolean;
  location: [];
  name: string;
  phone: string;
  profile_picture: string;
  profile_url: string;
  role: number;
  thumbs_url: string;
  user_id: string;
  vehicles: [string];
  vehicle: string;
  vehicle_description: string;
  xero_contact_id: string;
  suburb: string;
  status: string;
}

export interface mapData {
  lng: number;
  lat: number;
  radius: number;
}

export enum Admin_Trips_Columns {
  Scheduled = 'scheduled',
  PicupId = 'picupId',
  TripId = 'tripId',
  WhenAcceptedByCustomer = 'whenAcceptedByCustomer',
  LastEvent = 'lastEvent',
  LastEventDetails = 'lastEventDetails',
  NextEvent = 'nextEvent',
  CustomerRefNum = 'customerRefNum',
  OwnerName = 'ownerName',
  DriverName = 'driverName',
  Waypoints = 'waypoints',
  Progress = 'progress',
  TripType = 'tripType',
  ScheduledTimeStamp = 'scheduledTimeStamp',
  WarehouseName = 'warehouseName',
  ClaimedByUserName = 'claimedByUserName',
  ClaimedByUserId = 'claimedByUserId',
}

export enum AdminTrip$Keys {
  WhenAcceptedByCustomer = 'when_accepted_by_customer',
  Health = 'health',
  LastEvent = 'last_event',
  NextEvent = 'next_event_description',
  PicupId = 'picup_id',
  PicupDetails = 'picup_details',
  DriverName = 'driver_name',
  DriverId = 'driver_id',
  WaypointStatus = 'waypoint_status',
  CustomerReference = 'customer_reference',
  TripId = 'trip_id',
  OwnerName = 'owner_name',
  TripType = 'trip_type',
  Admin = 'admin',
  Describe = 'describe',
  ScheduledDate = 'scheduled_date',
  TotalWaypoints = 'total_waypoints',
  WarehouseName = 'warehouse_name',
  ClaimedByUserId = 'claimed_by_user_id',
  ClaimedByUserName = 'claimed_by_user_name',
}

export interface AccountBalanceObj {
  driver_id: string;
  balanace: number;
  driver_name: string;
}

export enum InProgressEnum {
  None = 'None',
  Pending = 'Pending',
  Assigned = 'Assigned',
  InProgress = 'InProgress',
  Unassigned = 'Unassigned',
  LotteryRunning = 'LotteryRunning',
}
