import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { AuthService } from 'app/auth/auth.service';
import firebase from 'firebase/app';

import { Store } from '@ngrx/store';
import { selectorActingAs, selectorUserPermissions } from 'app/auth/auth.reducer';
import { Bucket, BucketsPayload, StagedBucketsModel } from './buckets.interfaces';
import axios from 'app/api/axios';
import { NotificationsService } from 'app/shared/notifications/notifications.service';
import { ActingAs } from 'app/interfaces/auth.interfaces';
import { switchMap } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { selectorBucketFilters } from './manage-bucket.reducer';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class BucketService {
  historic_buckets = new BehaviorSubject(undefined);
  buckets = new BehaviorSubject(undefined);
  stagedBuckets = new BehaviorSubject(undefined);
  stagedOrdersForWarehouse = new BehaviorSubject(undefined);
  actingAs: ActingAs;
  ENTERPRISE_BUCKETS = 'enterprise-buckets';
  business_id: string;
  bucketSubscription;
  historicBucketSubscription;
  user_permissions;

  businessFilter$: BehaviorSubject<string | null>;
  warehouseFilter$: BehaviorSubject<string | null>;
  dateFilter$: BehaviorSubject<string | null>;
  limitFilter$: BehaviorSubject<number>;

  allowed_warehouses: BehaviorSubject<string[]>;

  buckets$: Observable<any>;
  openBuckets$: Observable<any>;
  deletedBuckets$: Observable<any>;

  filters;

  twoDaysAgo = new Date(Date.now() - 172800000);
  twoDaysAgoTicks = this.twoDaysAgo.getTime() * 10000 + 621355968000000000;

  constructor(
    public authService: AuthService,
    public notificationsService: NotificationsService,
    public store: Store<any>,
    private firestore: AngularFirestore
  ) {
    this.warehouseFilter$ = new BehaviorSubject(null);
    this.allowed_warehouses = new BehaviorSubject(null);
    this.dateFilter$ = new BehaviorSubject(null);
    this.limitFilter$ = new BehaviorSubject(null);
    this.store.select(selectorUserPermissions).subscribe((next) => {
      this.user_permissions = next;
      this.allowed_warehouses.next(next?.warehouses);
      this.store.select(selectorBucketFilters).subscribe((filters) => {
        this.filters = filters;
        this.warehouseFilter$.next(filters?.warehouse?.id);
        this.limitFilter$.next(filters.limit);
        this.dateFilter$.next(filters.date);
      });
      this.store.select(selectorActingAs).subscribe((data) => {
        this.actingAs = data;
        this.business_id = data?.id;
        this.businessFilter$ = new BehaviorSubject(this.actingAs?.id);
        // this.subscriptions();
        this.getOpenBuckets();
        this.getBuckets();
        this.getDeletedBuckets();
      });
    });
  }

  getBuckets(): void {
    this.buckets$ = combineLatest([
      this.businessFilter$,
      this.warehouseFilter$,
      this.allowed_warehouses,
      this.dateFilter$,
      this.limitFilter$,
    ]).pipe(
      switchMap(([business_id, warehouse_id, warehouse_ids, date, limit]) =>
        this.firestore
          .collection('buckets', (ref) => {
            let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
            const yesterday = moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD');
            query = query.where('IsDeleted', '==', false);
            if (business_id) {
              query = query.where('business_id', '==', business_id);
            }
            if (warehouse_ids?.length) {
              query = query.where('warehouse_id', 'in', warehouse_ids);
            }
            if (warehouse_id) {
              query = query.where('warehouse_id', '==', warehouse_id);
            }
            if (date) {
              query = query.where('delivery_date', '==', date);
            } else if (!date && limit) {
              query = query.where('delivery_date', '>=', yesterday);
              query = query.orderBy('delivery_date', 'desc');
            } else {
              query = query.orderBy('delivery_date', 'desc');
            }
            if (limit) {
              query = query.limit(limit);
            }
            return query;
          })
          .valueChanges()
      )
    );
  }

  getOpenBuckets(): void {
    this.openBuckets$ = combineLatest([
      this.businessFilter$,
      this.warehouseFilter$,
      this.allowed_warehouses,
      this.limitFilter$,
    ]).pipe(
      switchMap(([business_id, warehouse_id, warehouse_ids, limit]) =>
        this.firestore
          .collection('buckets', (ref) => {
            let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
            query = query.where('IsDeleted', '==', false);
            if (business_id) {
              query = query.where('business_id', '==', business_id);
            }
            if (warehouse_ids?.length) {
              query = query.where('warehouse_id', 'in', warehouse_ids);
            }
            if (warehouse_id) {
              query = query.where('warehouse_id', '==', warehouse_id);
            }
            if (limit) {
              query = query.limit(limit);
            }
            query = query.where('current_status', '!=', 'Completed');
            query = query.orderBy('current_status', 'desc');
            query = query.orderBy('delivery_date', 'desc');
            return query;
          })
          .valueChanges()
      )
    );
  }

  getDeletedBuckets(): void {
    this.deletedBuckets$ = combineLatest([
      this.businessFilter$,
      this.warehouseFilter$,
      this.allowed_warehouses,
      this.dateFilter$,
      this.limitFilter$,
    ]).pipe(
      switchMap(([business_id, warehouse_id, warehouse_ids, date, limit]) =>
        this.firestore
          .collection('buckets', (ref) => {
            let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
            const yesterday = moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD');
            query = query.where('IsDeleted', '==', true);
            if (business_id) {
              query = query.where('business_id', '==', business_id);
            }
            if (warehouse_ids?.length) {
              query = query.where('warehouse_id', 'in', warehouse_ids);
            }
            if (warehouse_id) {
              query = query.where('warehouse_id', '==', warehouse_id);
            }
            if (date) {
              query = query.where('delivery_date', '==', date);
            } else if (!date && limit) {
              query = query.where('delivery_date', '>=', yesterday);
              query = query.orderBy('delivery_date', 'desc');
            } else {
              query = query.orderBy('delivery_date', 'desc');
            }
            if (limit) {
              query = query.limit(limit);
            }
            return query;
          })
          .valueChanges()
      )
    );
  }

  getStagedBucketsForBusiness(business_id: string, payload: BucketsPayload, pageNumber: number): Promise<void> {
    if (business_id) {
      return axios({
        method: 'POST',
        url: '/generic/cqrs/IncomingOrdersForWarehouseSummary_TVF_CallerProc',
        data: {
          BucketOrderIds: '',
          BusinessId: business_id,
          WarehouseId : payload.warehouse?.id ?? null,
          PageNumber : pageNumber,
          PageSize : payload.limit
        }
      })
        .then((response) => {
          if (this.user_permissions?.warehouses?.length && !this.user_permissions?.modules?.includes('super-admin')) {
            const allowed_buckets = [];
            response?.data.forEach((bucket: StagedBucketsModel) => {
              if (this.user_permissions.warehouses.includes(bucket.ToWarehouseId)) {
                allowed_buckets.push(bucket);
              }
            });
            this.stagedBuckets.next(allowed_buckets);
          } else {
            this.stagedBuckets.next(response?.data);
          }
        })
        .catch((error) => {
          throw error.response?.data;
        });
    }
  }

  removeStagedOrders(bucket_order_ids) {
    return axios({
      method: 'POST',
      url: '/bucket/remove-orders',
      data: {
        bucket_order_ids: bucket_order_ids,
        business_id: this.business_id,
      },
    })
      .then((response) => {
        this.notificationsService.publish({ type: 'success', message: 'Orders Removed' });
        return response?.data;
      })
      .catch((error) => {
        this.notificationsService.publish({ type: 'error', message: error.response?.data.message });
        throw error.response?.data;
      });
  }

  getStagedOrdersForWarehouse(warehouse_id) {
    return axios({
      method: 'GET',
      url: '/bucket/incoming-orders/' + this.business_id + '/' + warehouse_id,
    })
      .then((response) => {
        this.stagedOrdersForWarehouse.next(response?.data);
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }

  getBucketOrdersForWarehouse(warehouse_id) {
    return axios({
      method: 'POST',
      url: '/generic/cqrs/get-bucket-orders',
      data: { business_id: this.business_id, warehouse_id: warehouse_id, is_staged_only: true },
    })
      .then((response) => {
        this.stagedOrdersForWarehouse.next({ orders: response?.data });
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }

  getAssignableBucketsForWarehouse(warehouseId) {
    return axios({
      method: 'GET',
      url: 'bucket/get-assignable-buckets-for-warehouse/' + warehouseId,
    })
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        this.notificationsService.publish({ type: 'error', message: error.response?.data.message });
      });
  }

  getAssignableBucketsForBusiness(business_id) {
    return axios({
      method: 'GET',
      url: 'bucket/get-assignable-buckets-for-business/' + business_id,
    })
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        this.notificationsService.publish({ type: 'error', message: error.response?.data.message });
      });
  }

  receiveStagedOrders(bucket_details, bucket_order_ids) {
    const data = {
      business_id: this.business_id,
      warehouse_id: bucket_details.warehouse_id,
      delivery_date: bucket_details.delivery_date,
      shift_start: bucket_details.shift_start,
      shift_end: bucket_details.shift_end,
      bucket_order_ids: bucket_order_ids,
    };
    return axios({
      method: 'POST',
      url: '/bucket/receive-orders',
      data: data,
    })
      .then((response) => {
        this.notificationsService.publish({ type: 'success', message: 'Orders Added to Bucket' });
        return response?.data;
      })
      .catch((error) => {
        this.notificationsService.publish({ type: 'error', message: error.response?.data.message });
        throw error.response?.data;
      });
  }

  getReturnsForBusiness() {
    return axios({
      method: 'POST',
      url: '/generic/cqrs/get-returned-parcels',
      data: {
        order_id: null,
        owner_id: this.business_id,
        warehouse_id: null,
      },
    })
      .then((response) => {
        return response?.data.output;
      })
      .catch((error) => {
        this.notificationsService.publish({ type: 'error', message: error.response?.data.message });
        throw error.response?.data;
      });
  }

  cancelParcelReturns(returnParcels) {
    return axios({
      method: 'POST',
      url: '/order/cancel-returned-parcels',
      data: returnParcels,
    })
      .then((response) => {
        this.notificationsService.publish({ type: 'success', message: 'Orders Cancelled' });
        return response;
      })
      .catch((error) => {
        this.notificationsService.publish({ type: 'error', message: error.response?.data.message });
        throw error.response?.data;
      });
  }

  updateBucketDetails(bucket_details, bucket_id: number): Promise<any> {
    return axios({
      method: 'POST',
      url: '/bucket/' + bucket_id + '/update-bucket-details',
      data: bucket_details,
    })
      .then((response) => {
        this.notificationsService.publish({ type: 'success', message: 'Bucket Updated' });
        return response?.data;
      })
      .catch((error) => {
        this.notificationsService.publish({ type: 'error', message: error.response?.data.message });
        throw error.response?.data;
      });
  }
}
