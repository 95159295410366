import { combineLatest, BehaviorSubject, Observable } from 'rxjs';
import * as moment from 'moment';
import { Bucket } from '../buckets.interfaces';
import { map } from 'rxjs/internal/operators/map';

const actionRequiredFilter = (buckets) => buckets.filter((bucket) => bucket.flagged_orders > 0);

const moreThanOneHourInTheFuture = (datetime) => moment().diff(datetime, 'hours') <= -1;

const openCountFilter = (buckets$: Observable<Bucket[]>): Observable<Bucket[]> =>
  buckets$?.pipe(map((buckets) => buckets?.filter((bucket) => bucket.current_status !== 'Completed')));

const closedFilter = (buckets: any[]) =>
  buckets.filter((bucket) => bucket.current_state === 'Dispatching' && bucket.current_status === 'Completed');

const openFilter = (buckets: Bucket[]) => buckets.filter((bucket) => bucket.current_status !== 'Completed');
const dispatchedFilter = (buckets: Bucket[]) =>
  buckets.filter((bucket) => bucket.current_state === 'Dispatching' && bucket.current_status === 'Completed');
const dispatchedCountFilter = (buckets$: Observable<Bucket[]>): Observable<Bucket[]> =>
  buckets$.pipe(
    map((buckets) =>
      buckets?.filter((bucket) => bucket.current_state === 'Dispatching' && bucket.current_status === 'Completed')
    )
  );

const filterByWarehouse = (buckets: Bucket[], warehouse: string) => {
  if (!warehouse) {
    return buckets;
  }

  return buckets.filter((bucket) => bucket.warehouse_name === warehouse);
};

const filterByBusiness = (buckets: any[], business: string) => {
  if (!business) {
    return buckets;
  }
  return buckets.filter((bucket) => bucket.business_name === business);
};

const filterByDate = (buckets: any[], date: string) => {
  if (!date) {
    return buckets;
  }

  return buckets.filter((bucket) => moment(bucket.delivery_date).isSame(date));
};

const filterByType = (buckets, type) => {
  switch (type) {
    case 'active':
      return buckets;
    case 'action_required':
      return actionRequiredFilter(buckets);
    case 'open':
      return openFilter(buckets);
    case 'closed':
      return closedFilter(buckets);
    case 'Dispatched':
      return dispatchedFilter(buckets);
    case 'staged':
      return [];
    case 'all':
      return buckets;
    default:
      return [];
  }
};

const combineAdminFilters = (bucket$: BehaviorSubject<any[]>, activeBusinessFilter: BehaviorSubject<string>) =>
  combineLatest(bucket$, activeBusinessFilter, (buckets: any[], businessFilter: string) =>
    filterByBusiness(buckets, businessFilter)
  );

const combineSearchFilters = (
  active_buckets: BehaviorSubject<any[]>,
  activeTypeFilter: BehaviorSubject<string>,
  activeWarehouseFilter: BehaviorSubject<string>,
  activeDateFilter: BehaviorSubject<string>
) =>
  combineLatest(
    active_buckets,
    activeTypeFilter,
    activeWarehouseFilter,
    activeDateFilter,
    (buckets: any[], typeFilter: string, warehouseFilter: string, dateFilter: string) =>
      filterByDate(filterByWarehouse(filterByType(buckets, typeFilter), warehouseFilter), dateFilter)
  );

const combineNewFilters = (
  active_buckets: Observable<Bucket[]>,
  activeWarehouseFilter: BehaviorSubject<string>,
  activeDateFilter: BehaviorSubject<string>
): Observable<Bucket[]> => {
  return combineLatest([active_buckets, activeWarehouseFilter, activeDateFilter]).pipe(
    map(([buckets, warehouse_name, active_date]) => {
      return filterByDate(newWarehouseFilter(buckets, warehouse_name), active_date);
    })
  );
};

const newWarehouseFilter = (buckets$: Bucket[], warehouse_name: string) => {
  if (!warehouse_name) {
    return buckets$;
  }
  return buckets$.filter((bucket) => bucket.warehouse_name === warehouse_name);
};
export {
  combineAdminFilters,
  combineSearchFilters,
  actionRequiredFilter,
  openCountFilter,
  openFilter,
  dispatchedFilter,
  closedFilter,
  dispatchedCountFilter,
  filterByBusiness,
  combineNewFilters,
};
