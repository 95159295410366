import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-glow-pill',
  templateUrl: './glow-pill.component.html',
  styleUrls: ['./glow-pill.component.scss']
})
export class GlowPillComponent implements OnInit {

  @Input() content;
  constructor() { }

  ngOnInit() {
  }

}
