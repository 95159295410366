<div class="input-wrap">
  <label [className]="'label-' + labelTheme">{{ label }}</label>
  <div class="input-field-wrap">
    <svg-icon *ngIf="svgIcon" class="password-icon" [src]="'../../../assets/img/icons/' + svgIcon"></svg-icon>
    <span>
      <input
        [maxlength]="maxLength"
        [class.invalid]="isInvalid"
        [class.error]="error"
        [type]="type"
        [value]="value || ''"
        [(ngModel)]="value"
        [placeholder]="[placeholder ? placeholder : '']"
        [readOnly]="readOnly"
        [disabled]="disabled"
        (focus)="onFocus(true)"
        (focusout)="onFocus(false)"
        (ngModelChange)="onChange()"
        [autocomplete]="autoComplete"
        id="input"
        step="0.01"
        [required]="required"
        (blur)="trimWhiteSpace()"
        [style]="styles"
      />
      <ng-content></ng-content>
    </span>
    <svg-icon
      *ngIf="svgActionIcon2 && visibleAction2"
      class="icon-two"
      (click)="action2Clicker()"
      [src]="'../../../assets/img/icons/' + svgActionIcon2"
      [svgStyle]="{ fill: svgActionIconColor2 }"
    ></svg-icon>
    <svg-icon
      *ngIf="svgActionIcon"
      class="icon-one"
      (click)="actionClicker()"
      [src]="'../../../assets/img/icons/' + svgActionIcon"
      [svgStyle]="{ fill: svgActionIconColor }"
    ></svg-icon>
  </div>
  <span *ngIf="error" class="error-message">{{ error }}</span>
</div>
