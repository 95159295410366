<app-modal
  [open]="true"
  [actions]="actions"
  [highlightPrimaryAction]="true"
  [size]="ModalSizes.Medium"
  (handleAction)="handleAction($event)"
>
  <heading [icon]="IconTypes.Alert" [color]="uiColors.Blue">
    <h4 class="heading-title">{{ 'View Recreated Trip' }}</h4>
  </heading>

  <div class="modal-body">
    <p>To view the new trip, please click confirm, else click cancel or outside the modal to ignore.</p>
  </div>
</app-modal>
