import { Injectable } from '@angular/core';
import axios from 'app/api/axios';
import { NotificationsService } from '../../shared/notifications/notifications.service';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { ActingAs } from 'app/interfaces/auth.interfaces';
import { selectorActingAs } from 'app/auth/auth.reducer';
import * as moment from 'moment';
import { ErrorHandlingService } from 'app/shared/error-handling.service';

export interface RerportsDateObj {
  title: string;
  start_date: string;
  end_date: string;
}

@Injectable({
  providedIn: 'root',
})
export class ReportingService {
  actingAs: ActingAs;
  dateRange = new BehaviorSubject([]);

  constructor(
    private notificationService: NotificationsService,
    private store: Store<any>,
    private errorHandlingService: ErrorHandlingService
  ) {
    this.store.select(selectorActingAs).subscribe((next) => (this.actingAs = next));
  }

  setDateRange(date) {
    this.dateRange.next(date);
  }

  getDriversScores() {
    const payload = this.dateRange.getValue();
    payload['business_id'] = this.actingAs.id;
    return axios({
      method: 'POST',
      url: '/sql/driver-metrics',
      data: payload,
    })
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        this.notificationService.publish({ type: 'error', message: error.response?.data.message });
        throw error.response?.data.message;
      });
  }

  getBusinessDriverReasonReport() {
    return axios({
      method: 'POST',
      url: '/enterprise/' + this.actingAs.id + '/business-driver-failure-reason-summary',
      data: this.dateRange.getValue(),
    })
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        this.notificationService.publish({ type: 'error', message: error.response?.data.message });
        throw error.response?.data.message;
      });
  }

  getSingleDriverReasonReport(driverId) {
    return axios({
      method: 'POST',
      url: '/enterprise/' + driverId + '/driver-failure-reason-summary',
      data: this.dateRange.getValue(),
    })
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        this.notificationService.publish({ type: 'error', message: error.response?.data.message });
        throw error.response?.data.message;
      });
  }

  getSuburbHotspots() {
    const payload = {
      business_id: this.actingAs.id,
      start_date: '2017-05-27',
      end_date: '2020-12-27',
    };
    return axios({
      method: 'POST',
      url: '/sql/suburb-hotspots',
      data: payload,
    })
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        this.notificationService.publish({ type: 'error', message: error.response?.data.message });
        throw error.response?.data.message;
      });
  }

  getBusinessDateStatusSummary(statusLevel, statusType) {
    const payload = this.dateRange.getValue();
    payload['status_level'] = statusLevel;
    payload['status_type'] = statusType;
    return axios({
      method: 'POST',
      url: '/enterprise/' + this.actingAs.id + '/business-date-status-summary',
      data: this.dateRange.getValue(),
    })
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        this.notificationService.publish({ type: 'error', message: error.response?.data.message });
        throw error.response?.data.message;
      });
  }

  getReport(type: string, dates): any {
    switch (type) {
      case 'payment-orders':
        return this.getPaymentReport('orders', dates)
          .then((response) => response)
          .catch((err) => err);
      case 'sla-report':
        return this.getSLAReport(dates)
          .then((response) => response)
          .catch((err) => err);
      case 'payment-parcels':
        return this.getPaymentReport('parcels', dates)
          .then((response) => response)
          .catch((err) => err);
      case 'route':
        return this.getRouteReport(dates)
          .then((response) => response)
          .catch((err) => err);
      case 'route-admin':
        return this.getRouteReportAdmin(dates)
          .then((response) => response)
          .catch((err) => err);
      case 'parcel-sla':
        return this.getParcelSLAReport(dates)
          .then((response) => response)
          .catch((err) => err);
      case 'geocoded-distance-cost-report':
        return this.getGeocodedDistanceCostReport(dates)
          .then((response) => response)
          .catch((err) => err);
      case 'orders-by-warehouse':
        return this.getBusinessOrdersByWarehouse(dates)
          .then((response) => response)
          .catch((err) => err);
      case 'planned-on-demand-earnings':
        return this.getPlannedOnDemandEarningsReport(dates)
          .then((response) => response)
          .catch((err) => err);
      case 'get-deleted-orders-by-warehouse':
        return this.getDeletedOrdersByWarehouse(dates)
          .then((response) => response)
          .catch((err) => err);
      case 'get-parcel-outcome-by-parcel-state':
        return this.getParcelOutcomeByParcelState(dates)
          .then((response) => response)
          .catch((err) => err);
      case 'get-buckets-report':
        return this.getBucketsReport(dates)
          .then((response) => response)
          .catch((err) => err);
      case 'active-courier-order-report':
        return this.getActiveCourierOrderReport(dates)
          .then((response) => response)
          .catch((err) => err);
      case 'completed-courier-order-report':
        return this.getCompletedCourierOrderReport(dates)
          .then((response) => response)
          .catch((err) => err);
      case 'planned-driver-earnings-report':
        return this.getPlannedDriverEarningsReport(dates)
          .then((response) => response)
          .catch((err) => err);
      case 'driver-rating-report':
        return this.getDriverRatingReport(dates)
          .then((response) => response)
          .catch((err) => err);
      case 'get-driver-productivity-report':
        return this.getDriverProductivityReport(dates)
          .then((response) => response)
          .catch((err) => err);
    }
  }

  getPlannedDriverEarningsReport(dates: RerportsDateObj): Promise<any> {
    return axios({
      responseType: 'blob',
      method: 'POST',
      url: '/generic/csv/planned-driver-earnings-report',
      data: {
        business_id: this.actingAs.id,
        date_from: dates.start_date,
        date_to: dates.end_date,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          'PlannedDriverEarnings' +
            ' - ' +
            moment(dates.start_date).format('YYYY-MM-DD') +
            ' ' +
            moment(dates.end_date).format('YYYY-MM-DD') +
            '.xlsx'
        );
        document.body.appendChild(link);
        link.click();
        return response;
      })
      .catch((error) => {
        this.errorHandlingService.handleBlobError(error);
        throw error.response?.data.message;
      });
  }

  getActiveCourierOrderReport(dates: RerportsDateObj): Promise<any> {
    return axios({
      responseType: 'blob',
      method: 'POST',
      url: '/generic/csv/active-courier-order-report',
      data: {
        business_id: this.actingAs.id,
        date_from: dates.start_date,
        date_to: dates.end_date,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          'ActiveCourierOrderReport' +
            ' - ' +
            moment(dates.start_date).format('YYYY-MM-DD') +
            ' ' +
            moment(dates.end_date).format('YYYY-MM-DD') +
            '.xlsx'
        );
        document.body.appendChild(link);
        link.click();
        return response;
      })
      .catch((error) => {
        this.errorHandlingService.handleBlobError(error);
        throw error.response?.data.message;
      });
  }

  getCompletedCourierOrderReport(dates: RerportsDateObj): Promise<any> {
    return axios({
      responseType: 'blob',
      method: 'POST',
      url: '/generic/csv/completed-courier-order-report',
      data: {
        business_id: this.actingAs.id,
        date_from: dates.start_date,
        date_to: dates.end_date,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          'CompletedCourierOrderReport' +
            ' - ' +
            moment(dates.start_date).format('YYYY-MM-DD') +
            ' ' +
            moment(dates.end_date).format('YYYY-MM-DD') +
            '.xlsx'
        );
        document.body.appendChild(link);
        link.click();
        return response;
      })
      .catch((error) => {
        this.errorHandlingService.handleBlobError(error);
        throw error.response?.data.message;
      });
  }

  getBucketsReport(dates: RerportsDateObj): Promise<any> {
    return axios({
      responseType: 'blob',
      method: 'POST',
      url: '/generic/csv/get-buckets-report',
      data: {
        business_id: this.actingAs.id,
        date_from: dates.start_date,
        date_to: dates.end_date,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          'BucketsReport' +
            ' - ' +
            moment(dates.start_date).format('YYYY-MM-DD') +
            ' ' +
            moment(dates.end_date).format('YYYY-MM-DD') +
            '.xlsx'
        );
        document.body.appendChild(link);
        link.click();
        return response;
      })
      .catch((error) => {
        this.errorHandlingService.handleBlobError(error);
        throw error.response?.data.message;
      });
  }

  getParcelOutcomeByParcelState(dates: RerportsDateObj): Promise<any> {
    return axios({
      responseType: 'blob',
      method: 'POST',
      url: '/generic/csv/get-parcel-outcome-by-parcel-state',
      data: {
        business_id: this.actingAs.id,
        date_from: dates.start_date,
        date_to: dates.end_date,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          'ParcelOutcomeByParcelState' +
            ' - ' +
            moment(dates.start_date).format('YYYY-MM-DD') +
            ' ' +
            moment(dates.end_date).format('YYYY-MM-DD') +
            '.xlsx'
        );
        document.body.appendChild(link);
        link.click();
        return response;
      })
      .catch((error) => {
        this.errorHandlingService.handleBlobError(error);
        throw error.response?.data.message;
      });
  }

  getDeletedOrdersByWarehouse(dates: RerportsDateObj): Promise<any> {
    return axios({
      responseType: 'blob',
      method: 'POST',
      url: '/generic/csv/get-deleted-orders-by-warehouse',
      data: {
        business_id: this.actingAs.id,
        date_from: dates.start_date,
        date_to: dates.end_date,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          'DeletedOrdersByWarehouse' +
            ' - ' +
            moment(dates.start_date).format('YYYY-MM-DD') +
            ' ' +
            moment(dates.end_date).format('YYYY-MM-DD') +
            '.xlsx'
        );
        document.body.appendChild(link);
        link.click();
        return response;
      })
      .catch((error) => {
        this.errorHandlingService.handleBlobError(error);
        throw error.response?.data.message;
      });
  }

  getPlannedOnDemandEarningsReport(dates: RerportsDateObj): Promise<any> {
    return axios({
      responseType: 'blob',
      method: 'POST',
      url: '/generic/csv/planned-on-demand-earnings',
      data: {
        business_id: this.actingAs.id,
        date_from: dates.start_date,
        date_to: dates.end_date,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          'PlannedOnDemandEarnings' +
            ' - ' +
            moment(dates.start_date).format('YYYY-MM-DD') +
            ' ' +
            moment(dates.end_date).format('YYYY-MM-DD') +
            '.xlsx'
        );
        document.body.appendChild(link);
        link.click();
        return response;
      })
      .catch((error) => {
        this.errorHandlingService.handleBlobError(error);
        throw error.response?.data.message;
      });
  }

  getGeocodedDistanceCostReport(dates: RerportsDateObj): Promise<any> {
    return axios({
      responseType: 'blob',
      method: 'POST',
      url: '/generic/csv/geocoded-distance-cost-report',
      data: {
        business_id: this.actingAs.id,
        date_from: dates.start_date,
        date_to: dates.end_date,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          'GeocodedDistanceCost' +
            ' - ' +
            moment(dates.start_date).format('YYYY-MM-DD') +
            ' ' +
            moment(dates.end_date).format('YYYY-MM-DD') +
            '.xlsx'
        );
        document.body.appendChild(link);
        link.click();
        return response;
      })
      .catch((error) => {
        this.errorHandlingService.handleBlobError(error);
        throw error.response?.data.message;
      });
  }

  getBusinessOrdersByWarehouse(dates: RerportsDateObj): Promise<any> {
    return axios({
      responseType: 'blob',
      method: 'POST',
      url: '/generic/csv/get-business-orders-by-warehouse',
      data: {
        business_id: this.actingAs.id,
        date_from: dates.start_date,
        date_to: dates.end_date,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          'BusinessOrdersByWarehouse' +
            ' - ' +
            moment(dates.start_date).format('YYYY-MM-DD') +
            ' ' +
            moment(dates.end_date).format('YYYY-MM-DD') +
            '.xlsx'
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        this.errorHandlingService.handleBlobError(error);
        throw error.response?.data.message;
      });
  }

  getParcelSLAReport(dates: RerportsDateObj): Promise<any> {
    return axios({
      responseType: 'blob',
      method: 'POST',
      url: '/generic/csv/get-parcel-sla-report',
      data: {
        business_id: this.actingAs.id,
        date_from: dates.start_date,
        date_to: dates.end_date,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          'ParcelSLAReport' +
            ' ' +
            moment(dates.start_date).format('YYYY-MM-DD') +
            ' ' +
            moment(dates.end_date).format('YYYY-MM-DD') +
            '.xlsx'
        );
        document.body.appendChild(link);
        link.click();
        return response;
      })
      .catch((error) => {
        this.errorHandlingService.handleBlobError(error);
        throw error.response?.data.message;
      });
  }

  getPaymentReport(type: string, dates: RerportsDateObj): Promise<any> {
    const url = '/generic/csv/get-payments-by-' + type;
    return axios({
      responseType: 'blob',
      method: 'POST',
      url: url,
      data: {
        business_id: this.actingAs.id,
        date_from: dates.start_date,
        date_to: dates.end_date,
      },
    })
      .then((response) => {
        const url2 = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = url2;
        link.setAttribute(
          'download',
          'PaymentReport' +
            '-' +
            type +
            ' ' +
            moment(dates.start_date).format('YYYY-MM-DD') +
            ' ' +
            moment(dates.end_date).format('YYYY-MM-DD') +
            '.xlsx'
        );
        document.body.appendChild(link);
        link.click();
        return response;
      })
      .catch((error) => {
        this.errorHandlingService.handleBlobError(error);
        throw error.response?.data.message;
      });
  }

  getSLAReport(dates: RerportsDateObj): Promise<any> {
    const url = '/generic/csv/get-sla-report';
    return axios({
      responseType: 'blob',
      method: 'POST',
      url: url,
      data: {
        business_id: this.actingAs.id,
        date_from: dates.start_date,
        date_to: dates.end_date,
      },
    })
      .then((response) => {
        const url2 = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = url2;
        link.setAttribute(
          'download',
          'SLA-report' +
            '-' +
            ' ' +
            moment(dates.start_date).format('YYYY-MM-DD') +
            ' ' +
            moment(dates.end_date).format('YYYY-MM-DD') +
            '.xlsx'
        );
        document.body.appendChild(link);
        link.click();
        return response;
      })
      .catch((error) => {
        this.errorHandlingService.handleBlobError(error);
        throw error.response?.data.message;
      });
  }

  getRouteReportAdmin(dates: RerportsDateObj): Promise<any> {
    return axios({
      responseType: 'blob',
      method: 'POST',
      url: '/generic/csv/get-route-report-admin',
      data: {
        business_id: this.actingAs.id,
        date_from: dates.start_date,
        date_to: dates.end_date,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          'Route Report' +
            ' - ' +
            moment(dates.start_date).format('YYYY-MM-DD') +
            ' ' +
            moment(dates.end_date).format('YYYY-MM-DD') +
            '.xlsx'
        );
        document.body.appendChild(link);
        link.click();
        return response;
      })
      .catch((error) => {
        this.errorHandlingService.handleBlobError(error);
        throw error.response?.data.message;
      });
  }

  getDriverRatingReport(dates: RerportsDateObj): Promise<any> {
    return axios({
      responseType: 'blob',
      method: 'POST',
      url: '/generic/csv/get-driver-rating-report',
      data: {
        business_id: this.actingAs.id,
        date_from: dates.start_date,
        date_to: dates.end_date,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          'Driver Rating Report' +
            ' - ' +
            moment(dates.start_date).format('YYYY-MM-DD') +
            ' ' +
            moment(dates.end_date).format('YYYY-MM-DD') +
            '.xlsx'
        );
        document.body.appendChild(link);
        link.click();
        return response;
      })
      .catch((error) => {
        this.errorHandlingService.handleBlobError(error);
        throw error.response?.data.message;
      });
  }

  getDriverProductivityReport(dates: RerportsDateObj): Promise<any> {
    return axios({
      responseType: 'blob',
      method: 'POST',
      url: '/generic/csv/get-driver-productivity-report',
      data: {
        business_id: this.actingAs.id,
        date_from: dates.start_date,
        date_to: dates.end_date,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          'Driver Productivity Report' +
            ' - ' +
            moment(dates.start_date).format('YYYY-MM-DD') +
            ' ' +
            moment(dates.end_date).format('YYYY-MM-DD') +
            '.xlsx'
        );
        document.body.appendChild(link);
        link.click();
        return response;
      })
      .catch((error) => {
        this.errorHandlingService.handleBlobError(error);
        throw error.response?.data.message;
      });
  }

  getRouteReport(dates: RerportsDateObj): Promise<any> {
    return axios({
      responseType: 'blob',
      method: 'POST',
      url: '/generic/csv/get-route-report-client',
      data: {
        business_id: this.actingAs.id,
        date_from: dates.start_date,
        date_to: dates.end_date,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          'Route Report' +
            ' - ' +
            moment(dates.start_date).format('YYYY-MM-DD') +
            ' ' +
            moment(dates.end_date).format('YYYY-MM-DD') +
            '.xlsx'
        );
        document.body.appendChild(link);
        link.click();
        return response;
      })
      .catch((error) => {
        this.errorHandlingService.handleBlobError(error);
        throw error.response?.data.message;
      });
  }

  getParcelReportByDate(APIData) {
    const body = {
      start_date: APIData.start_date,
      end_date: APIData.end_date,
    };
    return axios({
      method: 'POST',
      url: 'enterprise/' + this.actingAs.id + '/parcels-by-date/Delivery/' + APIData.series,
      data: body,
    })
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        this.notificationService.publish({ type: 'error', message: error.response?.data.message });
        throw error.response?.data.message;
      });
  }
}
