import { Component, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ModalSizes, ModalAction } from 'app/shared/modals/modal.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-bucket',
  templateUrl: './edit-bucket.component.html',
  styleUrls: ['./edit-bucket.component.scss'],
})
export class EditBucketComponent extends SimpleModalComponent<any, any> implements OnInit, AfterViewInit {
  public ModalSizes = ModalSizes;
  public IconTypes = IconTypes;
  public uiColors = UiColors;
  public UiThemes = UiThemes;
  public buttonTypes = ButtonTypes;
  public actions: ModalAction[] = [
    { name: 'close', title: 'Close', isPrimary: false },
    { name: 'editBucket', title: 'Change Bucket', isPrimary: true },
  ];

  bucket_id;
  delivery_date;
  shift_start;
  shift_end;
  total_orders;
  initial_times;

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.initial_times = [this.timeStringToFloat(this.shift_start), this.timeStringToFloat(this.shift_end)];
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  timeStringToFloat(time) {
    const hoursMinutes = time.split(/[.:]/);
    const hours = parseInt(hoursMinutes[0], 10);
    const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    return hours + minutes / 60;
  }

  handleAction($event) {
    switch ($event) {
      case 'close':
        this.close();
        break;
      case 'editBucket': {
        const bucket_details = {
          delivery_date: this.delivery_date,
          shift_start: this.shift_start,
          shift_end: this.shift_end,
          delivery_date_end: null,
        };
        this.result = bucket_details;
        this.close();
      }
    }
  }

  dateChanged($event) {
    this.delivery_date = moment($event).format('YYYY-MM-DD');
  }
  changeShift($event) {
    this.shift_start = moment.utc($event[0] * 3600000).format('HH:mm');
    this.shift_end = moment.utc($event[1] * 3600000).format('HH:mm');
  }
}
