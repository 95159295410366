import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { JustifyContent } from 'app/shared/flex-container/flex-container.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { ModalSizes } from 'app/shared/modals/modal.interfaces';
import { mfHumanizeString, mfAmountPaid } from 'app/shared/table/map-formatters.table.helpers';
import { mapDataToTable } from 'app/shared/table/table.helpers';
import { TableDataMap, DisplayPipes, TableColumn } from 'app/shared/table/table.interfaces';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-last-mile-fs-warehouse-contact',
  templateUrl: './last-mile-fs-warehouse-contact.component.html',
  styleUrls: ['./last-mile-fs-warehouse-contact.component.scss'],
})
export class LastMileFsWarehouseContactComponent implements OnChanges {
  constructor() {}
  @Input() contacts;
  @Input() Parcels;
  contact;
  parcelOverviewTableData;

  public IconTypes = IconTypes;
  public UiColors = UiColors;
  public ModalSizes = ModalSizes;
  public UiThemes = UiThemes;
  public justifyContent = JustifyContent;

  parcelOverviewDataMap: TableDataMap = {
    cells: {
      parcel_action: {
        column: 'parcel_action',
        map: ['ParcelAction'],
        format: mfHumanizeString,
      },
      parcel_state: {
        column: 'parcel_state',
        map: ['LastMileParcelState'],
        format: mfHumanizeString,
      },
      parcel_reference: {
        column: 'parcel_reference',
        map: 'ParcelReference',
        displayPipeArgs: ['none'],
      },
      waybill: {
        column: 'waybill',
        map: 'ParcelWaybill',
        displayPipeArgs: ['none'],
      },

      parcel_value: {
        column: 'ParcelValue',
        map: 'ParcelValue',
        prefix: environment.currency_symbol,
        displayPipeArgs: DisplayPipes.decimalsTwo,
      },
      amount_paid: {
        column: 'AmountPaid',
        map: 'AmountPaid',
        format: mfAmountPaid,
      },
      payment_type: {
        column: 'PaymentType',
        map: 'PaymentType',
        format: mfHumanizeString,
      },
      failed_reason: {
        column: 'Failure',
        map: 'FailedReason',
        format: mfHumanizeString,
      },
      reconciled_reason: {
        column: 'reconciled_reason',
        map: 'ReconciledReason',
        format: mfHumanizeString,
      },
    },
  };

  parcelOverviewColumns: TableColumn[] = [
    { name: 'parcel_action', displayName: 'Action' },
    { name: 'parcel_state', displayName: 'Status' },
    { name: 'parcel_reference', displayName: 'Parcel Reference' },
    { name: 'waybill', displayName: 'Waybill Number' },
    { name: 'parcel_value', displayName: 'Parcel Value' },
    { name: 'amount_paid', displayName: 'Paid' },
    { name: 'payment_type', displayName: 'Payment Type' },
    { name: 'reconciled_reason', displayName: 'Reconciled Reason' },
    { name: 'failed_reason', displayName: 'Failure Reason' },
  ];

  createCombinedArray() {
    const parcelArray = [];
    this.contacts.forEach((contact) => {
      contact.Deliveries?.forEach((parcel) => {
        parcelArray.push({ ...this.Parcels[parcel], ParcelAction: 'delivery' });
      });
    });
    this.contacts.forEach((contact) => {
      contact.Collections?.forEach((parcel) => {
        parcelArray.push({ ...this.Parcels[parcel], ParcelAction: 'collection' });
      });
    });
    this.contacts.forEach((contact) => {
      contact.Returns?.forEach((parcel) => {
        parcelArray.push({ ...this.Parcels[parcel], ParcelAction: 'return' });
      });
    });
    this.parcelOverviewTableData = mapDataToTable(parcelArray, this.parcelOverviewDataMap, this.parcelOverviewColumns);
  }

  ngOnChanges(): void {
    this.createCombinedArray();
    this.contact = this.contacts[0];
  }
}
