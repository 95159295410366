import { Component, Input, ViewChild } from '@angular/core';
import { darkMapStyle } from 'app/shared/map/map.constants';
import { FsCourierOrderDriverLocation, FsDriverDetails } from '../models/driver-details.model';
import { GoogleMap } from '@angular/google-maps';

interface MapMarker {
  latitude: number;
  longitude: number;
  status: string;
  label: string;
}

@Component({
  selector: 'app-courier-order-map',
  templateUrl: './courier-order-map.component.html',
  styleUrls: ['./courier-order-map.component.scss']
})
export class CourierOrderMapComponent {
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap | undefined;

  @Input() mapStyle: google.maps.MapTypeStyle[] = darkMapStyle;
  @Input() center: google.maps.LatLngLiteral = {
    lat: -29.9537074,
    lng: 23.6451965
  };
  @Input() height = '500px';
  @Input() width = '100%';
  @Input() set driverDetails(driverDetails: FsDriverDetails) {
    if (driverDetails) {
      let marker = this.driverMarker.get(driverDetails.courier_code);
      if (marker) {
        this.updateMarker(marker, driverDetails);
      } else {
        marker = new google.maps.Marker({
          position: new google.maps.LatLng(
            driverDetails.location.latitude,
            driverDetails.location.longitude,
          ),
          map: this.map?.googleMap,
          icon: {
            url: this.getIconUrl(driverDetails.active_vehicle),
            scaledSize: new google.maps.Size(50, 50),
            origin: new google.maps.Point(0, 0),
            rotation: 180,
            anchor: new google.maps.Point(25, 25),
          }
        });

        this.previousLocation = driverDetails.location;
      }

      this.driverMarker.set(driverDetails.courier_code, marker);

      this.map?.googleMap.setCenter({ lat: driverDetails.location.latitude, lng: driverDetails.location.longitude });
    }
  }

  @Input() set mapMarkers(markers: MapMarker[]) {
    this.markers = markers;
    if (this.markers) {
      this.updateMapBounds();
    }
  }

  private driverMarker: Map<string, google.maps.Marker> = new Map();

  markers: MapMarker[];
  iconUrl = '/assets/img/vehicles/vehicle-motorbike-top.svg';
  previousLocation: FsCourierOrderDriverLocation;

  zoom = 14;
  mapOptions: google.maps.MapOptions = {
    styles: this.mapStyle,
    zoomControl: false,
    scrollwheel: true,
    streetViewControl: false,
    clickableIcons: false,
    disableDefaultUI: true,
    center: {
      lat: -29.9537074,
      lng: 23.6451965
    },
  };

  private getIconUrl(vehicle_key: string): string {
    return '/assets/img/vehicles/' + (vehicle_key ? vehicle_key : 'vehicle-car') + '-top.svg';
  }

  private updateMarker(marker: google.maps.Marker, driverDetails: FsDriverDetails): void {
    marker?.setPosition(new google.maps.LatLng(
      driverDetails.location.latitude,
      driverDetails.location.longitude,
    ));

    const bearing = this.calculateBearing(
      this.previousLocation.latitude,
      this.previousLocation.longitude,
      driverDetails.location.latitude,
      driverDetails.location.longitude
    );

    const rotate_ref = document.querySelector<HTMLInputElement>('img[src*=\'-top\']');
    if (rotate_ref) {
      rotate_ref.style.transform = 'rotate(' + bearing + 'deg)';
    }

    this.previousLocation = driverDetails.location;
  }

  private calculateBearing(lat1: number, lng1: number, lat2: number, lng2: number): number {
    const rad = Math.PI / 180;
    const phi1 = lat1 * rad;
    const phi2 = lat2 * rad;
    const deltaLambda = (lng2 - lng1) * rad;

    const y = Math.sin(deltaLambda) * Math.cos(phi2);
    const x = Math.cos(phi1) * Math.sin(phi2) -
      Math.sin(phi1) * Math.cos(phi2) * Math.cos(deltaLambda);
    const theta = Math.atan2(y, x);

    const bearing = (theta * 180 / Math.PI + 360) % 360;
    return bearing;
  }

  private updateMapBounds(): void {
    const bounds = new google.maps.LatLngBounds();
    this.markers?.forEach((marker: MapMarker) => {
      bounds.extend(
        new google.maps.LatLng(marker.latitude, marker.longitude),
      );
    });
    this.map?.googleMap?.fitBounds(bounds, 100);
  }

  trackByMapMarker(_index: number, marker: MapMarker): string {
    if (!marker) {
      return null;
    }
    return marker.label;
  }
}
