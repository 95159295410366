import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapTypes } from '../map-view/map-view.interfaces';

export interface DialogData {
  // this is temporary
  location1: any;
  location2: any;
}

@Component({
  selector: 'app-side-map',
  templateUrl: './side-map.component.html',
  styleUrls: ['./side-map.component.scss'],
})
export class SideMapComponent implements OnInit {
  MapTypes = MapTypes;
  constructor(public dialogRef: MatDialogRef<SideMapComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit(): void {}
}
