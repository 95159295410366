import { SortDirection } from '@angular/material/sort';

export interface TableColumn {
  id: string;
  displayName?: string;
  hidden?: boolean;
  sortable?: boolean;
  sortDirection?: SortDirection;
  type?: TableTypeEnum;
  dataMapValues?: string[];
  format?: (element: unknown) => string;
}

export enum TableTypeEnum {
  string = 'String',
  number = 'Number',
  dateTime= 'DateTime',
  checkbox = 'Checkbox'
}
