<div class="actions-wrap">
  <div class="flex-left">
    <app-back-button class="back-button"></app-back-button>
    <app-basic-button-group
      [buttons]="viewTabs"
      [currentTab]="viewNotes ? 'Notes' : 'Details'"
      [buttonRemPadding]="1"
      (buttonClicked)="handleTabNavigation($event)"
    ></app-basic-button-group>
  </div>

  <div class="button-wrap" *ngIf="!readOnlyMode">
    <app-basic-button
      *ngIf="_getUiState(current_bucket_state) === _BucketStatesUi.Verifying"
      [title]="'Edit Bucket'"
      [type]="buttonTypes.Primary"
      (click)="editBucket()"
    >
    </app-basic-button>
    <app-basic-button
      *ngIf="!(_getUiState(current_bucket_state) === _BucketStatesUi.Dispatched) || user.is_admin"
      [title]="'Reset Bucket'"
      [type]="_getUiState(current_bucket_state) === _BucketStatesUi.Dispatched ? buttonTypes.Warning : buttonTypes.Primary"
      [active]="resettingBucket"
      [disabled]="resettingBucket || processingDispatch"
      (click)="resetBucket()"
    >
    </app-basic-button>
    <app-basic-button
      *ngIf="
        !(_getUiState(current_bucket_state) === _BucketStatesUi.Dispatched) &&
        !user_permissions?.modules?.includes('user')
      "
      [title]="'Remove Bucket'"
      [type]="buttonTypes.Warning"
      [active]="removingBucket"
      [disabled]="removingBucket"
      (click)="removeBucket()"
    >
    </app-basic-button>
    <app-basic-button
      *ngIf="shouldLoadView(_BucketStatesUi.Verifying)"
      [title]="'Finalize vetting'"
      [type]="buttonTypes.Primary"
      [active]="!finalizeVettingInProcess"
      [processing]="finalizeVettingInProcess"
      (click)="finalizeVetting()"
    >
    </app-basic-button>
  </div>
</div>

<div class="bucket-summary" *ngIf="bucket">
  <app-summary-line *ngIf="!multi_day_bucket">
    #{{ bucket?.bucket_id }}
    |
    {{ bucket?.delivery_date | date: 'EEE dd LLL' }}
    |
    {{ bucket?.shift_start | timePipe }}-{{ bucket?.shift_end | timePipe }}
    |
    {{ bucket?.warehouse_name }}
  </app-summary-line>
  <app-summary-line *ngIf="multi_day_bucket">
    #{{ bucket?.bucket_id }}
    |
    {{ bucket?.delivery_date | date: 'dd LLL' }} - {{ bucket?.delivery_date_end | date: 'dd LLL' }}
    |
    {{ bucket?.warehouse_name }}
  </app-summary-line>
  <div *ngIf="enableDeletedFlags">
      <span class="delted-text">You are viewing a deleted bucket</span>
  </div>
  <div class="state-stepper" *ngIf="user.is_admin && !viewNotes">
    <a
      (click)="updateStep(_BucketStatesUi.AutoGeocoding)"
      [ngClass]="{ current: _getUiState(navigationState) === _BucketStatesUi.AutoGeocoding }"
      >{{ _BucketStatesUi.AutoGeocoding }}</a
    >
    <a
      (click)="updateStep(_BucketStatesUi.Verifying)"
      [ngClass]="{ current: _getUiState(navigationState) === _BucketStatesUi.Verifying }"
      >{{ _BucketStatesUi.Verifying }}</a
    >
    <a
      (click)="updateStep(_BucketStatesUi.CostingSettings)"
      [ngClass]="{ current: _getUiState(navigationState) === _BucketStatesUi.CostingSettings }"
      >{{ _BucketStatesUi.CostingSettings }}</a
    >
    <a
      (click)="updateStep(_BucketStatesUi.Costing)"
      [ngClass]="{ current: _getUiState(navigationState) === _BucketStatesUi.Costing }"
      >{{ _BucketStatesUi.Costing }}</a
    >
    <a
      (click)="updateStep(_BucketStatesUi.TripSelection)"
      [ngClass]="{ current: _getUiState(navigationState) === _BucketStatesUi.TripSelection }"
      >{{ _BucketStatesUi.TripSelection }}</a
    >
    <a
      (click)="updateStep(_BucketStatesUi.CourierOrderSelection)"
      [ngClass]="{ current: _getUiState(navigationState) === _BucketStatesUi.CourierOrderSelection }"
      >{{ _BucketStatesUi.CourierOrderSelection }}</a
    >
    <a
      (click)="updateStep(_BucketStatesUi.Confirmation)"
      [ngClass]="{ current: _getUiState(navigationState) === _BucketStatesUi.Confirmation }"
      >{{ _BucketStatesUi.Confirmation }}</a
    >
    <a
      (click)="updateStep(_BucketStatesUi.Dispatched)"
      [ngClass]="{ current: _getUiState(navigationState) === _BucketStatesUi.Dispatched }"
      >{{ _BucketStatesUi.Dispatched }}</a
    >
  </div>
  <p class="created-date" *ngIf="user?.is_admin">Created: {{dateCreated | date: 'medium'}}</p>
  <app-bucket-status [bucket]="bucket" [state]="current_bucket_state"></app-bucket-status>
</div>

<div class="stage">
  <app-importing
    *ngIf="shouldLoadView(_BucketStatesUi.Importing) && !viewNotes"
    class="alignViewCenter"
    [state]="current_bucket_state"
  >
  </app-importing>

  <app-auto-geocoder
    *ngIf="shouldLoadView(_BucketStatesUi.AutoGeocoding) && !viewNotes"
    class="alignViewCenter"
    [bucket_id]="bucket?.bucket_id.toString()"
    [state]="current_bucket_state"
  >
  </app-auto-geocoder>

  <app-verify *ngIf="shouldLoadView(_BucketStatesUi.Verifying) && !viewNotes" 
    [readOnly]="readOnlyMode">
  </app-verify>

  <app-costing-settings *ngIf="shouldLoadView(_BucketStatesUi.CostingSettings) && !viewNotes" 
    [readOnly]="readOnlyMode">
  </app-costing-settings>

  <app-costing
    *ngIf="(shouldLoadView(_BucketStatesUi.Costing) || shouldLoadView(_BucketStatesUi.Alert)) && !viewNotes"
    class="alignViewCenter"
    [state]="current_bucket_state"
  >
  </app-costing>

  <app-trip-costs
    *ngIf="shouldLoadView(_BucketStatesUi.TripSelection) && !viewNotes"
    [state]="current_bucket_state"
    [readOnly]="readOnlyMode"
  ></app-trip-costs>

  <app-order-selection
    *ngIf="shouldLoadView(_BucketStatesUi.CourierOrderSelection) && !viewNotes"
    [state]="current_bucket_state"
    [user]="user"
    [user_permissions]="user_permissions"
    [readOnly]="readOnlyMode"
  ></app-order-selection>

  <app-confirmation
    [bucket]="bucket"
    *ngIf="(shouldLoadView(_BucketStatesUi.Confirmation) || shouldLoadView(_BucketStatesUi.Dispatched)) && !viewNotes"
    (processingDispatch)="respondToProcessingDispatch($event)"
    [state]="current_bucket_state"
    [readOnly]="readOnlyMode"
  ></app-confirmation>
  
  <app-notes 
    *ngIf="viewNotes" 
    [id]="bucket?.bucket_id?.toString()"
    [user]="user" 
    [notesDisplay]="bucketNotes" 
    [businessId]="bucket?.business_id"
    [notesTypes]="notesTypes.Bucket">
  </app-notes>
</div>
