import { Component, Input, OnInit } from '@angular/core';
import axios from 'app/api/axios';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { NotificationsService } from '../../../../shared/notifications/notifications.service';
import { BucketStatuses, BucketState } from '../../../buckets/buckets.interfaces';
import { AutoGeocoderComponentProps } from './auto-geocoder.interfaces';

@Component({
  selector: 'app-auto-geocoder',
  templateUrl: './auto-geocoder.component.html',
  styleUrls: ['./auto-geocoder.component.scss'],
})
export class AutoGeocoderComponent implements AutoGeocoderComponentProps, OnInit {
  @Input() state: BucketState;
  @Input() bucket_id: string;
  @Input() readOnly = false

  geocoder_started = false;
  processing = false;
  buttonTypes = ButtonTypes

  constructor(private notificationsService: NotificationsService) {}

  ngOnInit() {
    if (this.state && this.state.status === BucketStatuses.Pending && !this.readOnly) {
      if (this.bucket_id) {
        axios.post(`/bucket/${this.bucket_id}/auto-geocode`).catch((error) => {
          this.geocoder_started = false;
          this.processing = false;

          this.notificationsService.publish({
            message: error.response?.data.message,
            type: 'error',
          });
        });
      }
    }
  }

  autoGeocode() {
    this.processing = true;
    this.geocoder_started = true;
    if (this.bucket_id) {
      axios.post(`/bucket/${this.bucket_id}/auto-geocode`).catch((error) => {
        this.geocoder_started = false;
        this.processing = false;

        this.notificationsService.publish({
          message: error.response?.data.message,
          type: 'error',
        });
      });
    }
  }
}
