import { Component, OnInit } from '@angular/core';
import { IconTypes } from '../icon/icon.interfaces';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { Router } from '@angular/router';
import { ButtonTypes } from '../buttons/basic-button.component';

@Component({
  selector: 'app-place-picup-button',
  templateUrl: './place-picup-button.component.html',
  styleUrls: ['./place-picup-button.component.scss']
})
export class PlacePicupButtonComponent implements OnInit {
  public Icons = {
    Colors: UiColors,
    Types: IconTypes
  };
  buttonTypes = ButtonTypes;
  constructor(
    public router: Router,
  ) { }

  ngOnInit() {
  }

  navigateToPlace() {
    this.router.navigate(['/dashboard/place-order']);
  }

}
