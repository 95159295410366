import { Component, Input, OnChanges } from '@angular/core';
import { UiThemes } from '../../interfaces/ui.interfaces';
import { GridContainerProps, GridJustifyItems } from './grid-container.interfaces';
import { SpacingProperties } from '../flex-child/flex-child.interfaces';

@Component({
  selector: 'app-grid-container',
  templateUrl: './grid-container.component.html',
  styleUrls: ['./grid-container.component.scss'],
})
export class GridContainerComponent implements GridContainerProps, OnChanges {
  @Input() columns = '1';
  @Input() rowGap = '0';
  @Input() colGap = '0';
  @Input() justifyItems: GridJustifyItems = GridJustifyItems.Inherit;
  @Input() borderBottom: boolean;
  @Input() borderTop: boolean;
  @Input() borderRight: boolean;
  @Input() padding: SpacingProperties | string;
  @Input() margin: SpacingProperties | string;
  @Input() theme: UiThemes;
  @Input() alignItems;

  public classes: string[];

  ngOnChanges() {
    this.classes = this.setClasses();
  }

  setClasses() {
    const classes: string[] = [];
    if (this.theme) {
      classes.push(this.theme);
    }
    if (this.borderBottom) {
      classes.push('border-bottom');
    }
    if (this.borderTop) {
      classes.push('border-top');
    }

    return classes;
  }
}
