import { Component, Input } from '@angular/core';
import { Timeline, TimelinePosition } from '../../order-timeline-visual.interfaces';

@Component({
  selector: 'app-timeline-point',
  templateUrl: './timeline-point.component.html',
  styleUrls: ['./timeline-point.component.scss']
})
export class TimelinePointComponent {

  @Input() timeline: Timeline
  @Input() showDate = false;
  @Input() position: TimelinePosition = TimelinePosition.first;

  timelinePositionEnum = TimelinePosition;

  getClassBasedOnTime(date: string): string{
    if(new Date(date) < new Date()){
      return 'active-color'
    } else {
      return 'disabled-color'
    }
  }

}
