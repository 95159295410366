/* eslint-disable @typescript-eslint/indent */
import { selectLastMileCollectionName, selectorUserPermissions } from './../../auth/auth.reducer';
import { PostDispatchState, selectorPostDispatchFilters } from './post-dispatch.reducer';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from '../../../../node_modules/rxjs';
import axios from 'app/api/axios';
import firebase from 'firebase';

// Services
import { Store } from '@ngrx/store';
import { AngularFirestore, CollectionReference, DocumentChangeAction, QuerySnapshot } from '@angular/fire/firestore';

// Reducers
import { selectorActingAs } from '../../auth/auth.reducer';

// Interfaces

import { ActingAs } from 'app/interfaces/auth.interfaces';
import { combineLatest } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map, take } from 'rxjs/operators';
import { CourierOrder, LastMile, Order, PaginateEnum } from 'app/shared/shared.interfaces';
import {
  CourierOrderPaginationSnapshot,
  LastMilePaginationSnapshot,
  OrderFrontEndStateEnum,
  OrderPaginationSnapshot,
  PostDispatchTotals
} from './post-dispatch.interfaces';
import { SortEvent } from 'app/shared/table/table.interfaces';
import { ScheduledDropDownEnum } from 'app/admin/admin-post-dispatch/admin-post-dispatch/admin-post-dispatch.interface';

@Injectable({
  providedIn: 'root',
})
export class PostDispatchService {
  picups$ = new BehaviorSubject(undefined);
  picup$ = new BehaviorSubject(undefined);
  ENTERPRISE_BUSINESS_SUMMARY_BASE_URL = 'enterprise-business-summmary/';

  actingAs: ActingAs;

  // Trip Observables
  alertFirestoreTrips$: Observable<Order[]>;
  manualAssignmentFirstoreTrips$: Observable<any>;
  lotteryRunningFirestoreTrip$: Observable<Order[]>;
  inProgressSnapshot: OrderPaginationSnapshot;
  alertSnapshot: OrderPaginationSnapshot;
  manualAssignmentSnapshot: OrderPaginationSnapshot;
  completedSnapshot: OrderPaginationSnapshot;
  scheduledSnapshot: OrderPaginationSnapshot;
  lotteryRunningSnapshot: OrderPaginationSnapshot;
  activeRouteSnapshot: LastMilePaginationSnapshot;
  pendingRouteSnapshot: LastMilePaginationSnapshot;
  courierOrderSnapshot: CourierOrderPaginationSnapshot;
  orderOrderBy: SortEvent;
  lastMileOrderBy: SortEvent;
  courierOrderOrderBy: SortEvent;
  scheduledFirestoreTrips$: Observable<Order[]>;
  completedFirestoreTrips$: Observable<Order[]>;

  activeLastMiles$: Observable<any>;
  pendingLastMiles$: Observable<any>;

  courierFirestoreOrders$: Observable<CourierOrder[]>;

  inProgress = ['None', 'Pending', 'Assigned', 'InProgress', 'Unassigned', 'LotteryRunning'];
  completed = ['Completed', 'Cancelled'];

  businessFilter$: BehaviorSubject<string | null>;
  adminBusinessFilter$: BehaviorSubject<string | null>;
  warehouseFilter$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  fleetFilter$: BehaviorSubject<string | null>;
  adminFleetFilter$: BehaviorSubject<string | null>;
  limitFilter$: BehaviorSubject<number>;
  allowed_warehouses: BehaviorSubject<string[]>;
  courierCodeFilter$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  courierLastEventFilter$: BehaviorSubject<string[] | null> = new BehaviorSubject<string[] | null>([]);

  private readonly _showBuckets$: BehaviorSubject<string> = new BehaviorSubject(ScheduledDropDownEnum.All);
  readonly showBuckets$= this._showBuckets$.asObservable();

  set showBuckets(selection: string) {
    this._showBuckets$.next(selection)
  }
  lastMileCollectionName = 'last-mile';
  constructor(private store: Store<any>,
    private firestore: AngularFirestore) {
    this.businessFilter$ = new BehaviorSubject(null);
    this.allowed_warehouses = new BehaviorSubject(null);
    this.fleetFilter$ = new BehaviorSubject(null);
    this.limitFilter$ = new BehaviorSubject(300);
    this.warehouseFilter$ = new BehaviorSubject(null);
    this.adminBusinessFilter$ = new BehaviorSubject(null);
    this.adminFleetFilter$ = new BehaviorSubject('All Picup');

    this.store.select(selectorActingAs).subscribe((next) => {
      this.actingAs = next;
      this.businessFilter$.next(next?.id);
    });
    this.store.select(selectorUserPermissions).subscribe((next) => {
      this.allowed_warehouses.next(next?.warehouses);
    });
    this.store.select(selectorPostDispatchFilters).subscribe((next: PostDispatchState['postDispatchFilters']) => {
      this.warehouseFilter$.next(next?.warehouse?.id ?? null);
      this.fleetFilter$.next(next?.fleet_allocation);
      this.adminBusinessFilter$.next(next?.business?.business_id);
      this._showBuckets$.next(next?.scheduledMode ?? ScheduledDropDownEnum.All);
    });
    this.store.select(selectLastMileCollectionName).subscribe(value => {
      this.lastMileCollectionName = value ?? 'last-mile'
    })
    const fleet: string = localStorage.getItem('fleet');
    this.setAdminFleetFilter(fleet ? fleet : 'All Picup');
  }

  setLimitFilter(value: number): void {
    this.limitFilter$.next(value);
  }

  getCourierFirestoreOrders(
    amountToDisplay: number,
    direction: PaginateEnum,
    isAdmin: boolean
  ): Observable<QuerySnapshot<CourierOrder>> {
    return this.firestore
      .collection<CourierOrder>('courier-orders', (ref) => {
        return this.createCourierOrdersQuery(ref, direction, amountToDisplay, isAdmin);
      })
      .get();
  }

  getCourierFirestoreOrdersStream(
    amountToDisplay: number,
    direction: PaginateEnum,
    isAdmin: boolean
  ): Observable<DocumentChangeAction<CourierOrder>[]> {
    return this.firestore
      .collection<CourierOrder>('courier-orders', (ref) => {
        return this.createCourierOrdersQuery(ref, direction, amountToDisplay, isAdmin);
      })
      .snapshotChanges();
  }

  createCourierOrdersQuery(
    ref: CollectionReference<firebase.firestore.DocumentData>,
    direction: PaginateEnum,
    amountToDisplay: number = 0,
    isAdmin: boolean
  ): firebase.firestore.Query<firebase.firestore.DocumentData> {
    let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
    query = query.where('IsComplete', '==', false);
    query = query.where('IsCancelled', '==', false);

    if (isAdmin) {
      if (this.adminBusinessFilter$.value) {
        query = query.where('BusinessId', '==', this.adminBusinessFilter$?.value);
      }
    } else {
      if (this.businessFilter$.value) {
        query = query.where('BusinessId', '==', this.businessFilter$?.value);
      }
    }

    if (this.courierCodeFilter$?.value.length > 0) {
      query = query.where('CourierCode', 'in', this.courierCodeFilter$.value);
    }

    if (this.courierLastEventFilter$?.value && this.courierLastEventFilter$.value.length > 0) {
      query = query.where('TrackingEventType', 'in', this.courierLastEventFilter$.value);
    }

    if (this.allowed_warehouses.value?.length) {
      query = query.where('WarehouseId', 'in', this.allowed_warehouses?.value);
    }
    if (this.warehouseFilter$.value) {
      query = query.where('WarehouseId', '==', this.warehouseFilter$?.value);
    }
    if (this.courierOrderOrderBy) {
      query = query.orderBy(this.courierOrderOrderBy?.field, this.courierOrderOrderBy?.direction);
    } else {
      query = query.orderBy('Timestamp', 'desc');
    }

    if (amountToDisplay && direction !== PaginateEnum.Backward) {
      query = query.limit(amountToDisplay);
    }

    if (direction === PaginateEnum.Forward) {
      query = query.startAfter(this.courierOrderSnapshot?.Last);
    }

    if (direction === PaginateEnum.Backward) {
      query = query.endBefore(this.courierOrderSnapshot?.First).limitToLast(amountToDisplay);
    }
    return query;
  }

  getActiveFirestoreLastmiles(isAdmin: boolean): Observable<LastMile[]> {
    return this.firestore
      .collection<LastMile>(this.lastMileCollectionName, (ref) => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        if (isAdmin) {
          if (this.adminBusinessFilter$?.value) {
            query = query.where('BusinessIds', 'array-contains', this.adminBusinessFilter$.value);
          }
        } else {
          if (this.businessFilter$.value && !this.warehouseFilter$.value && !this.allowed_warehouses.value?.length) {
            query = query.where('BusinessIds', 'array-contains', this.businessFilter$.value);
          }
          if (this.warehouseFilter$.value) {
            query = query.where('WarehouseIds', 'array-contains', this.warehouseFilter$.value);
          }
        }

        if (isAdmin) {
          if (this.adminFleetFilter$.value === 'All Picup') {
            query = query.where('Role', 'in', ['Picup', 'PicupPlus']);
          } else if (this.adminFleetFilter$.value) {
            query = query.where('Role', '==', this.adminFleetFilter$.value);
          }
        } else {
          if (this.fleetFilter$.value) {
            if (this.fleetFilter$.value === 'All Picup') {
              query = query.where('Role', 'in', ['Picup', 'PicupPlus']);
            } else {
              query = query.where('Role', '==', this.fleetFilter$.value);
            }
          }
        }

        if (this.allowed_warehouses.value?.length && !this.warehouseFilter$.value) {
          query = query.where('WarehouseIds', 'array-contains-any', this.allowed_warehouses.value);
        }

        if (this.limitFilter$.value) {
          query = query.limit(this.limitFilter$.value);
        }

        query = query.where('ActiveRouteId', '!=', null);
        return query;
      })
      .valueChanges();
  }

  getPendingFirestoreLastmiles(isAdmin: boolean): Observable<LastMile[]> {
    return this.firestore
          .collection<LastMile>(this.lastMileCollectionName, (ref) => {
            let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;

            query = query.where('Parcels', '!=', {});

            if (isAdmin) {
              if (this.adminBusinessFilter$?.value) {
                query = query.where('BusinessIds', 'array-contains', this.adminBusinessFilter$.value);
              }
            } else {
              if (
                this.businessFilter$.value &&
                !this.warehouseFilter$.value &&
                !this.allowed_warehouses.value?.length
              ) {
                query = query.where('BusinessIds', 'array-contains', this.businessFilter$.value);
              }
              if (this.warehouseFilter$.value) {
                query = query.where('WarehouseIds', 'array-contains', this.warehouseFilter$.value);
              }
            }

            if (isAdmin) {
              if (this.adminFleetFilter$.value === 'All Picup') {
                query = query.where('Role', 'in', ['Picup', 'PicupPlus']);
              } else if (this.adminFleetFilter$.value) {
                query = query.where('Role', '==', this.adminFleetFilter$.value);
              }
            } else {
              if (this.fleetFilter$.value) {
                if (this.fleetFilter$.value === 'All Picup') {
                  query = query.where('Role', 'in', ['Picup', 'PicupPlus']);
                } else {
                  query = query.where('Role', '==', this.fleetFilter$.value);
                }
              }
            }

            if (this.allowed_warehouses.value?.length && !this.warehouseFilter$.value) {
              query = query.where('WarehouseIds', 'array-contains-any', this.allowed_warehouses.value);
            }

            if (this.limitFilter$.value) {
              query = query.limit(this.limitFilter$.value);
            }

            query = query.where('ActiveRouteId', '==', null);
            return query;
          })
          .valueChanges()
  }

  getFireStoreOrders(
    amountToDisplay: number,
    direction: PaginateEnum,
    state: OrderFrontEndStateEnum,
    isAdmin: boolean
  ): Observable<QuerySnapshot<Order>> {
    return this.firestore
      .collection<Order>('orders', (ref) => {
        return this.createTripsQuery(ref, direction, amountToDisplay, state, isAdmin);
      })
      .get();
  }

  getFireStoreOrdersStream(
    amountToDisplay: number,
    direction: PaginateEnum,
    state: OrderFrontEndStateEnum
  ): Observable<DocumentChangeAction<Order>[]> {
    return this.firestore
      .collection<Order>('orders', (ref) => {
        return this.createStagedTripsQuery(ref, direction, amountToDisplay, state);
      })
      .snapshotChanges();
  }

  getFireStoreOrderSummaryStreamByState(
    amountToDisplay: number,
    direction: PaginateEnum,
    state: OrderFrontEndStateEnum,
    isAdmin: boolean,
    failedFinal: boolean = false,
    orderBy: 'asc' | 'desc' = 'desc'
  ): Observable<DocumentChangeAction<Order>[]> {
    return this.firestore
      .collection<Order>('order-summary', (ref) => {
        return this.createTripsQuery(ref, direction, amountToDisplay, state, isAdmin, failedFinal, orderBy);
      })
      .snapshotChanges();
  }

  setLastMileSnapShot(snapshot: LastMilePaginationSnapshot, isActive: boolean): void {
    if (isActive) {
      this.activeRouteSnapshot = snapshot;
    } else {
      this.pendingRouteSnapshot = snapshot;
    }
  }

  setTripSnapshot(snapshot: OrderPaginationSnapshot, frontEndState: OrderFrontEndStateEnum): void {
    switch (frontEndState) {
      case OrderFrontEndStateEnum.InProgress:
        this.inProgressSnapshot = snapshot;
        break;
      case OrderFrontEndStateEnum.Alert:
        this.alertSnapshot = snapshot;
        break;
      case OrderFrontEndStateEnum.LotteryRunning:
        this.lotteryRunningSnapshot = snapshot;
        break;
      case OrderFrontEndStateEnum.Scheduled:
        this.scheduledSnapshot = snapshot;
        break;
      case OrderFrontEndStateEnum.WaitingForManualAssignment:
        this.manualAssignmentSnapshot = snapshot;
        break;
      case OrderFrontEndStateEnum.Completed:
        this.completedSnapshot = snapshot;
        break;
      case OrderFrontEndStateEnum.CompletedAllFailedFinalCollection:
        this.completedSnapshot = snapshot;
        break;
    }
  }

  // createLastMileQuery(
  //   ref: CollectionReference<firebase.firestore.DocumentData>,
  //   direction: PaginateEnum,
  //   amountToDisplay: number = 0,
  //   isActive: boolean,
  //   isAdmin: boolean
  // ): firebase.firestore.Query<firebase.firestore.DocumentData> {
  //   let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;

  //   if (!isActive) {
  //     query = query.where('Parcels', '!=', []);
  //   }

  //   if (isAdmin) {
  //     if (this.adminBusinessFilter$?.value) {
  //       query = query.where('BusinessIds', 'array-contains', this.adminBusinessFilter$.value);
  //     }
  //   } else {
  //     if (this.businessFilter$.value && !this.warehouseFilter$.value && !this.allowed_warehouses.value?.length) {
  //       query = query.where('BusinessIds', 'array-contains', this.businessFilter$.value);
  //     }
  //     if (this.warehouseFilter$.value) {
  //       query = query.where('WarehouseIds', 'array-contains', this.warehouseFilter$.value);
  //     }
  //   }

  //   if (this.fleetFilter$.value) {
  //     if (this.fleetFilter$.value === 'All Picup') {
  //       query = query.where('Role', 'in', ['Picup', 'PicupPlus']);
  //     } else {
  //       query = query.where('Role', '==', this.fleetFilter$.value);
  //     }
  //   }

  //   if (this.lastMileOrderBy) {
  //     query = query.orderBy('Parcels', 'desc');
  //     query = query.orderBy(this.lastMileOrderBy?.field, this.lastMileOrderBy?.direction);
  //   } else {
  //     query = query.orderBy('Parcels', 'desc');
  //     query = query.orderBy('Timestamp', 'desc');
  //   }

  //   if (amountToDisplay && direction !== PaginateEnum.Backward) {
  //     query = query.limit(amountToDisplay);
  //   }

  //   if (direction === PaginateEnum.Forward) {
  //     query = query.startAfter(isActive ? this.activeRouteSnapshot.Last : this.pendingRouteSnapshot.Last);
  //   }

  //   if (direction === PaginateEnum.Backward) {
  //     query = query
  //       .endBefore(isActive ? this.activeRouteSnapshot.First : this.pendingRouteSnapshot.First)
  //       .limitToLast(amountToDisplay);
  //   }

  //   return query;
  // }

  createTripsQuery(
    ref: CollectionReference<firebase.firestore.DocumentData>,
    direction: PaginateEnum,
    amountToDisplay: number = 0,
    state: OrderFrontEndStateEnum,
    isAdmin: boolean,
    failedFinal = false,
    orderbyDir: 'asc'|'desc' = 'desc'
  ): firebase.firestore.Query<firebase.firestore.DocumentData> {
    let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
    // checks the state
      query = query.where('FrontEndState', '==', state);

    // business filter
    if (isAdmin) {
      if (this.adminBusinessFilter$?.value) {
        query = query.where('BusinessId', '==', this.adminBusinessFilter$.value);
      }
    } else {
      if (this.businessFilter$.value) {
        query = query.where('BusinessId', '==', this.businessFilter$.value);
      }
    }

    // for the bucket toggle only on admin (might be an issue will need to create duplicate indexs for admin and non-admin)
      if(this._showBuckets$.value !== ScheduledDropDownEnum.All){
        if(this._showBuckets$.value === ScheduledDropDownEnum.Scheduled){
          query = query.where('BucketId', '!=', null);
        } else {
          query = query.where('BucketId', '==', null);
        }
        query = query.orderBy('BucketId', 'desc');
      }

    // warehouse filter for non-admin post-dispatch (same issue as above)
    if (!isAdmin) {
      if (this.allowed_warehouses.value?.length) {
        query = query.where('WarehouseId', 'in', this.allowed_warehouses.value);
      }

      if (this.warehouseFilter$.value) {
        query = query.where('WarehouseId', '==', this.warehouseFilter$.value);
      }
    }

    // fleet filter for both post-dispatch screens
    if (isAdmin) {
      if (this.adminFleetFilter$.value === 'All Picup') {
        query = query.where('FleetAllocation', 'in', ['Picup', 'PicupPlus']);
      } else if (this.adminFleetFilter$.value) {
        query = query.where('FleetAllocation', '==', this.adminFleetFilter$.value);
      }
    } else {
      if (this.fleetFilter$.value) {
        if (this.fleetFilter$.value === 'All Picup') {
          query = query.where('FleetAllocation', 'in', ['Picup', 'PicupPlus']);
        } else {
          query = query.where('FleetAllocation', '==', this.fleetFilter$.value);
        }
      }
    }

    // final failed collection table
    if(failedFinal) {
      query = query.where('AllFailedFinalCollection', '==', true);
      query = query.where('FFCHasBeenRecreated', '==', false);
    } else {
      query = query.where('AllFailedFinalCollection', '==', false);
    }

    // ordering (each orderby creates a new index)
    if (this.orderOrderBy) {
      query = query.orderBy(this.orderOrderBy?.field, this.orderOrderBy?.direction);
    } else {
      query = query.orderBy('Timestamp', orderbyDir);
    }

    // amount per page
    if (amountToDisplay && direction !== PaginateEnum.Backward) {
      query = query.limit(amountToDisplay);
    }

    if (direction === PaginateEnum.Forward) {
      query = query.startAfter(this.getSnapshotByState(state).Last);
    }

    if (direction === PaginateEnum.Backward) {
      query = query.endBefore(this.getSnapshotByState(state).First).limitToLast(amountToDisplay);
    }

    return query;
  }

  createStagedTripsQuery(
    ref: CollectionReference<firebase.firestore.DocumentData>,
    direction: PaginateEnum,
    amountToDisplay: number = 0,
    state: OrderFrontEndStateEnum
  ): firebase.firestore.Query<firebase.firestore.DocumentData> {
    let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;

    query = query.where('OrderState', '==', state);
    query = query.where('FleetAllocation', 'in', ['Picup', 'PicupPlus']);

    if (amountToDisplay && direction !== PaginateEnum.Backward) {
      query = query.limit(amountToDisplay);
    }

    if (direction === PaginateEnum.Forward) {
      query = query.startAfter(this.getSnapshotByState(state).Last);
    }

    if (direction === PaginateEnum.Backward) {
      query = query.endBefore(this.getSnapshotByState(state).First).limitToLast(amountToDisplay);
    }

    return query;
  }

  getSnapshotByState(state: OrderFrontEndStateEnum): OrderPaginationSnapshot {
    switch (state) {
      case OrderFrontEndStateEnum.Alert:
        return this.alertSnapshot;
      case OrderFrontEndStateEnum.InProgress:
        return this.inProgressSnapshot;
      case OrderFrontEndStateEnum.LotteryRunning:
        return this.lotteryRunningSnapshot;
      case OrderFrontEndStateEnum.Scheduled:
        return this.scheduledSnapshot;
      case OrderFrontEndStateEnum.WaitingForManualAssignment:
        return this.manualAssignmentSnapshot;
      case OrderFrontEndStateEnum.Completed:
        return this.completedSnapshot;
    }
  }

  getPostDispatchParcels(type, status, warehouse_ids, bucket_id): Promise<any> {
    return axios({
      method: 'POST',
      url: '/generic/cqrs/get-post-dispatch-parcels-summary-v2',
      data: {
        business_id: this.actingAs.id,
        type: type,
        status: status,
        is_include_completed: bucket_id ? true : false,
        warehouse_ids: warehouse_ids,
        bucket_id: bucket_id,
      },
    }).then((response) => {
      return response?.data;
    });
  }

  getPostDispatchTotals(isAdmin: boolean): Promise<PostDispatchTotals> {
    return axios({
      method: 'POST',
      url: '/generic/cqrs/get-post-dispatch-totals',
      data: {
        business_id: isAdmin ? this.adminBusinessFilter$?.value : this.businessFilter$?.value,
        warehouse_id: this.warehouseFilter$?.value,
        fleet: isAdmin ? this.adminFleetFilter$.value : this.fleetFilter$.value,
        hasbucketid: this._showBuckets$.value === ScheduledDropDownEnum.Scheduled ? true
                        : this._showBuckets$.value === ScheduledDropDownEnum.All ? null
                        : false
      },
    }).then((response) => {
      return response?.data;
    });
  }

  getTodaysSummary(warehouse_ids: string[], date: string): Promise<any> {
    return axios({
      method: 'POST',
      url: 'generic/cqrs/last-mile-order-progress',
      data: {
        business_id: this.actingAs.id,
        warehouse_ids: warehouse_ids,
        collection_date_to: date,
        collection_date_from: date,
      },
    }).then((response) => {
      return response?.data;
    });
  }

  getPostDispatchSummary(warehouse_ids) {
    return axios({
      method: 'POST',
      url: 'generic/cqrs/get-post-dispatch-summary-by-business-v2',
      data: {
        business_id: this.actingAs.id,
        warehouse_ids: warehouse_ids,
        include_completed: false,
      },
    }).then((response) => {
      return response?.data;
    });
  }

  setAdminFleetFilter(fleet: string): void {
    localStorage.setItem('fleet', fleet);
    this.adminFleetFilter$.next(fleet);
  }

  getFailedFirestoreLastmiles(isAdmin: boolean): Observable<LastMile[]> {
    return combineLatest([this.getHasFailedCollectionFirestoreLastmiles(isAdmin), this.getHasFailedDeliveryFirestoreLastmiles(isAdmin)])
    .pipe(map(([x, y]) => x.concat(y)));
  }


  getHasFailedCollectionFirestoreLastmiles(isAdmin: boolean): Observable<LastMile[]> {
    return this.firestore
      .collection<LastMile>(this.lastMileCollectionName, (ref) => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        if(isAdmin){
          if (this.adminBusinessFilter$?.value) {
            query = query.where('BusinessIds', 'array-contains', this.adminBusinessFilter$.value);
          }

          if (this.adminFleetFilter$.value === 'All Picup') {
            query = query.where('Role', 'in', ['Picup', 'PicupPlus']);
          } else if (this.adminFleetFilter$.value) {
            query = query.where('Role', '==', this.adminFleetFilter$.value);
          }

          if (this.allowed_warehouses.value?.length && !this.warehouseFilter$.value) {
            query = query.where('WarehouseIds', 'array-contains-any', this.allowed_warehouses.value);
          }
        } else {
          if (
            this.businessFilter$.value &&
            !this.warehouseFilter$.value &&
            !this.allowed_warehouses.value?.length
          ) {
            query = query.where('BusinessIds', 'array-contains', this.businessFilter$.value);
          }
          if (this.warehouseFilter$.value) {
            query = query.where('WarehouseIds', 'array-contains', this.warehouseFilter$.value);
          }
          if (this.fleetFilter$.value) {
            if (this.fleetFilter$.value === 'All Picup') {
              query = query.where('Role', 'in', ['Picup', 'PicupPlus']);
            } else {
              query = query.where('Role', '==', this.fleetFilter$.value);
            }
          }
        }

        if (this.limitFilter$.value) {
          query = query.limit(this.limitFilter$.value);
        }

        query = query.where('ActiveRouteId', '!=', null);
        query = query.where('HasFailedCollection','==' , true);
        return query;
      })
      .valueChanges();
  }

  getHasFailedDeliveryFirestoreLastmiles(isAdmin: boolean): Observable<LastMile[]> {
    return this.firestore
      .collection<LastMile>(this.lastMileCollectionName, (ref) => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        if(isAdmin){
          if (this.adminBusinessFilter$?.value) {
            query = query.where('BusinessIds', 'array-contains', this.adminBusinessFilter$.value);
          }

          if (this.adminFleetFilter$.value === 'All Picup') {
            query = query.where('Role', 'in', ['Picup', 'PicupPlus']);
          } else if (this.adminFleetFilter$.value) {
            query = query.where('Role', '==', this.adminFleetFilter$.value);
          }

          if (this.allowed_warehouses.value?.length && !this.warehouseFilter$.value) {
            query = query.where('WarehouseIds', 'array-contains-any', this.allowed_warehouses.value);
          }
        } else {
          if (
            this.businessFilter$.value &&
            !this.warehouseFilter$.value &&
            !this.allowed_warehouses.value?.length
          ) {
            query = query.where('BusinessIds', 'array-contains', this.businessFilter$.value);
          }
          if (this.warehouseFilter$.value) {
            query = query.where('WarehouseIds', 'array-contains', this.warehouseFilter$.value);
          }
          if (this.fleetFilter$.value) {
            if (this.fleetFilter$.value === 'All Picup') {
              query = query.where('Role', 'in', ['Picup', 'PicupPlus']);
            } else {
              query = query.where('Role', '==', this.fleetFilter$.value);
            }
          }
        }


        if (this.limitFilter$.value) {
          query = query.limit(this.limitFilter$.value);
        }

        query = query.where('ActiveRouteId', '!=', null);
        query = query.where('HasFailedDelivery','==' , true);
        return query;
      })
      .valueChanges();
  }
}
