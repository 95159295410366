import { Injectable } from '@angular/core';
import axios from 'app/api/axios';
import { Store } from '@ngrx/store';
import { selectorUser, selectorActingAs } from 'app/auth/auth.reducer';
import { NotificationsService } from 'app/shared/notifications/notifications.service';
import { AuthService } from 'app/auth/auth.service';
import { AxiosResponse } from 'axios';

@Injectable({
  providedIn: 'root',
})
export class ManageUserService {
  user;
  actingAs;

  constructor(
    public store: Store<any>,
    private notificationsService: NotificationsService,
    private authService: AuthService
  ) {
    this.store.select(selectorUser).subscribe((next) => {
      this.user = next;
    });
    this.store.select(selectorActingAs).subscribe((next) => {
      this.actingAs = next;
    });
  }

  async updateFirebaseUser(user_model) {
    await this.authService.updateFirebaseUser(user_model);
  }

  addCreditsToUser(amount: string, user_id: string): Promise<any | AxiosResponse<any>> {
    return axios({
      method: 'POST',
      url: 'user/' + user_id + '/give-credits',
      data: {
        amount: amount,
      },
    })
      .then(() => {
        this.notifyAndUpdate(true, amount + ' Credits Given');
        return;
      })
      .catch((error) => {
        this.notifyAndUpdate(false, error.response?.data.message);
        return;
      });
  }

  resetPassword(): void {
    this.authService
      .sendPasswordResetEmail(this.user.email)
      .subscribe(
        {next: () => {
          this.notifyAndUpdate(true, 'Please check your email for a password reset link');
        }, error: (error) => {
          this.notifyAndUpdate(false, error.response?.data.message);
        }});
  }

  updateUser(user_model) {
    if (user_model.preferred_business_id || user_model.preferred_business_id === undefined) {
      axios({
        method: 'POST',
        url: 'user/' + this.user.user_id + '/update-settings',
        data: {
          settings: {
            preferred_business_id: user_model.preferred_business_id,
          },
        },
      });
    }
    const updateData = {
      details: {
        name: user_model.name,
        email: user_model.email,
        phone: user_model.phone,
      },
    };
    this.updateFirebaseUser(user_model)
      .then(() => {
        axios({
          method: 'POST',
          url: 'user/' + this.user.user_id + '/update',
          data: updateData,
        })
          .then(() => {
            this.notifyAndUpdate(true, 'Details Successfully Updated');
            return;
          })
          .catch((error) => {
            this.notifyAndUpdate(false, error.response?.data.message);
            return;
          });
      })
      .catch((err) => this.notifyAndUpdate(false, err.response?.data.message));
  }

  notifyAndUpdate(successful, message) {
    const notificationType = successful ? 'success' : 'error';
    this.notificationsService.publish({
      type: notificationType,
      message: message,
    });
    this.authService.updateUser();
  }
}
