import { PostDispatchRoutingModule } from './post-dispatch-routing.module';
import { NgModule } from '@angular/core';
import { PostDispatchReducer } from './post-dispatch.reducer';

// Modules
import { SharedModule } from '../../shared/shared.module';

// Services
import { PostDispatchService } from './post-dispatch.service';
// import { V2PostDispatchSummaryComponent } from './v2-post-dispatch-summary/v2-post-dispatch-summary.component';
// import { PostDispatchTodayComponent } from './post-dispatch-today/post-dispatch-today.component';
import { StoreModule } from '@ngrx/store';

@NgModule({
  imports: [SharedModule, PostDispatchRoutingModule, StoreModule.forFeature('post_dispatch', PostDispatchReducer)],
  declarations: [],
  exports: [SharedModule],
  providers: [PostDispatchService],
})
export class PostDispatchModule {
  constructor() {}
}
