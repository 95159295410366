export enum FleetEnum {
  Picup = 'Picup',
  PicupPlus = 'PicupPlus',
  Contractor = 'Contractor',
}

// move interfaces

export interface DashboardDeliveryStats {
  openCount: number;
  pendingCount: number;
  stagedOrderCount: number;
  activeCount: number;
  completedCount: number;
}

export interface DashboardOrderStats {
  totalOrders: number;
  totalParcels: number;
  percentageCompleteOrders: number;
  percentageDeliveredParcels: number;
}

export interface DashboardBucketTable {
  warehouseName: string;
  bucketNo: string;
  bucketDate: string;
  numberOfOrders: number;
  numberOfParcels: number;
  numberFlagged: number;
  numberOfRoutes: number;
  orderState: string;
}

export interface DashboardFilters {
  dateFrom: Date;
  dateTo: Date;
  warehouseId: string;
}

export interface DeliveryStatsResponse {
  Buckets: BucketStats;
  Trips: TripStats;
  DispatchedTripsCountByFleetByDay: DispatchedTripsCountByFleetByDay;
}

export interface BucketStats {
  OpenCount: number;
  PendingCount: number;
  StagedOrderCount: number;
}

export interface TripStats {
  ActiveCount: number;
  CompletedCount: number;
}

export interface DispatchedTripsCountByFleetByDay {
  TotalDispatchedBucketCount: number;
  UniqueWarehousesUsedOverPeriod: number;
  [key: string]: any;
}
