import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ModalSizes, ModalAction } from '../modal.interfaces';
import { GridJustifyItems } from 'app/shared/grid-container/grid-container.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';

export interface TextInputModel {
  title: string;
  content: Array<string>;
  iconType: IconTypes;
  iconColor: UiColors;
  types?: Array<string>;
}

@Component({
  selector: 'app-text-input-modal',
  templateUrl: './text-input-modal.component.html',
  styleUrls: ['./text-input-modal.component.scss'],
})
export class TextInputModalComponent extends SimpleModalComponent<TextInputModel, any> implements OnInit {
  public ModalSizes = ModalSizes;
  public GridJustifyItems = GridJustifyItems;
  public IconTypes = IconTypes;
  public UiColors = UiColors;
  public UiThemes = UiThemes;
  public ButtonTypes = ButtonTypes;
  public actions: ModalAction[] = [
    { name: 'cancel', title: 'Cancel' },
    { name: 'confirm', title: 'Confirm', isPrimary: true },
  ];

  title: string;
  content: Array<string>;
  user_input: Array<string> = [];
  iconType: IconTypes;
  iconColor: UiColors;
  types: Array<string> = [];

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.content.forEach(() => {
      this.user_input.push('');
    });
  }

  handleAction($event: string): void {
    switch ($event) {
      case 'cancel':
        this.result = false;
        this.close();
        break;
      case 'confirm':
        this.result = this.user_input;
        this.close();
        break;
    }
  }
}
