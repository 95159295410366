<div>
  <div class="header-bar">
    <button mat-button class="button-select" (click)="bulkSendToCourier()">Send all to courier
    </button>
  </div>
</div>
<app-orders-table
  [dataSource]="dataSource"
  [orderState]="OrderStateEnum.Alert"
  [warehouses]="warehouses"
  [businesses]="businesses"
  [displayedColumns]="displayedColumns"
  [allowRestartLottery]="true"
  [showFilters]="false"
  (tableActionTaken)="handleTableAction($event)"
></app-orders-table>
