<div class="dashboard-loader" *ngIf="[graphLoading, orderStatisticsLoading, fleetStatsLoading] | dashboardLoader; else showData">
  <mat-spinner></mat-spinner>
</div>
<ng-template #showData>
  <div class="delivery-blocks">
    <div class="stats-1">
      <div  class="stats-1-left">
        <div *ngIf="bucketsData?.openCount > 0" class="lg-block">
          Open
          <div class="lg-title">{{bucketsData?.openCount}}</div>
          Buckets
        </div>
        <div *ngIf="bucketsData?.completedCount > 0" class="sm-block bg-blue">
          <div class="sm-title">{{bucketsData?.completedCount}}</div>
          <div>Completed</div>
          <div>Deliveries</div>
        </div>
        <div *ngIf="bucketsData?.pendingCount > 0" class="sm-block bg-grey">
          <div class="sm-title">{{bucketsData?.pendingCount}}</div>
          <div>Buckets Pending</div>
          <div>Dispatch</div>
        </div>
        <div *ngIf="bucketsData?.activeCount > 0" class="sm-block bg-grey">
          <div class="sm-title">{{bucketsData?.activeCount}}</div>
          <div>Trips Enroute</div>
        </div>
        <div *ngIf="bucketsData?.stagedOrderCount > 0" class="outline-block">
          <div class="sm-title">{{bucketsData?.stagedOrderCount}}</div>
          <div>Staged Orders</div>
        </div>
        <div (click)="goToPlacePicup()" class="new-picup-btn"><mat-icon>add</mat-icon> New Picup</div>
      </div>
    </div>
  </div>
  <div class="stats-container">
    <div class="flex-container">
      <div class="delivery-stats">
        <div class="delivery-content">
          <div class="chart-container">
            <div class="chart-actions-bar">
              <div class="actions">
              </div>
              <div class="warehouses">Total no. Warehouses {{ warehouses?.length ?? 0 }}</div>
            </div>
            <div *ngIf="totalDispatched > 0" class="bucket-info">
              <div class="amount">{{totalDispatched}}</div>
              <div class="info-header">Total dispatched trips</div>
            </div>
            <ngx-charts-line-chart
              [view]="[undefined, 250]"
              [results]="lineChartData"
              [showXAxisLabel]="true"
              [showYAxisLabel]="true"
              [xAxis]="true"
              [yAxis]="true"
              [legend]="true"
              [legendPosition]="legendPosition"
              [legendTitle]="''"
              [title]="'Dispatched Trips'"
              style="fill: white;"
            ></ngx-charts-line-chart>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-container pdl-2">
      <div class="fleet-statistics">
        <div class="fleet-content">
          <div *ngIf="lineGraphResults" class="driver-section">
            <app-progress-bar-graph [results]="lineGraphResults" [title]="'Active drivers'"></app-progress-bar-graph>
          </div>
        </div>
      </div>
      <div class="order">
        <app-order-statistics [parcelStats]="parcelOrderStats"></app-order-statistics>
      </div>
    </div>
  </div>
  
  <div *ngIf="buckets?.length > 0" class="buckets-section">
    <h4>Last 18 Buckets Dispatched</h4>
    <div class="buckets-container">
      <div class="buckets">
        <div class="bucket" *ngFor="let bucket of displayedBuckets">
          <app-bucket-table  [bucketData]="bucket"></app-bucket-table>
        </div>
      </div>
      <div *ngIf="displayedBuckets?.length > 0" class="arrow-buttons">
        <button mat-icon-button (click)="changeArray(false)"><mat-icon>arrow_upward</mat-icon></button>
        <button mat-icon-button (click)="changeArray(true)"><mat-icon>arrow_downward</mat-icon></button>
      </div>
    </div>
  </div>
</ng-template>

