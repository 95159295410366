import { Component, Input, Output, EventEmitter, OnChanges, SimpleChange, ViewChild } from '@angular/core';
import axios from 'app/api/axios';
import { IconTypes } from '../../../../../shared/icon/icon.interfaces';
import { UiColors } from '../../../../../interfaces/ui.interfaces';
import { MapTypes } from '../../../../../shared/map/map.interfaces';
import { GeocoderConfig } from 'app/shared/geocoding-tool/geocoding-tool.component';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { MapComponent } from 'app/shared/map/map.component';

@Component({
  selector: 'app-verify-map',
  templateUrl: './verify-map.component.html',
  styleUrls: ['./verify-map.component.scss'],
})
export class VerifyMapComponent implements OnChanges {
  @Output() changeBucketRequested = new EventEmitter<any>();
  @Output() editOrderRequested = new EventEmitter<any>();
  @Output() removeOrderRequested = new EventEmitter<any>();

  @Input() bucketID: string;
  @Input() update = false;
  @Input() geoJson;
  @Output() rejectedWarningEmit = new EventEmitter<boolean>();

  @Output() orderFlagged = new EventEmitter<any>();
  @Output() geocodeRequested = new EventEmitter<any>();
  @Output() toggleTableRequested = new EventEmitter<any>();
  @Output() updateGeoJson = new EventEmitter<any>();

  public Icons = {
    Colors: UiColors,
    Types: IconTypes,
  };
  public mapTypes = MapTypes;
  public uiColors = UiColors;
  public iconTypes = IconTypes;
  buttonTypes = ButtonTypes
  orderDetails: any;
  showOrderDetails = false;
  toggleActive = false;
  selectedBusinessRefs: bucketMarkerDetails[] = [];
  @ViewChild('map', { static: false }) mapView: MapComponent;

  constructor() {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes.update) {
      if (changes.update.currentValue === changes.update.previousValue) {
        return;
      }
      this.updateGeoJson.emit();
      this.closeDetails();
    }
  }

  closeDetails() {
    this.orderDetails = null;
    this.selectedBusinessRefs = [];
  }

  editOrder() {
    this.editOrderRequested.emit(this.orderDetails.business_reference);
  }

  removeOrder() {
    this.removeOrderRequested.emit([this.orderDetails.business_reference]);
  }

  flagOrder(business_reference, reason) {
    this.orderFlagged.emit({ business_reference, reason });
  }

  toggleTable() {
    this.toggleTableRequested.emit(true);
  }

  geocodeOrder(address_id, order_id): void {
    const address_ids = [];
    address_ids.push(address_id);
    const config: GeocoderConfig = {
      addresses: address_ids,
      bucket_id: null,
      order_id: order_id,
    };
    this.geocodeRequested.emit(config);
    this.closeDetails();
  }

  changeBucket(): void {
    const ids = this.selectedBusinessRefs;
    this.changeBucketRequested.emit(ids);
    this.closeDetails();
  }

  mapClicked(business_reference: string): void {
    axios
      .post(`/bucket/${this.bucketID}/business-reference-orders/`, [business_reference])
      .then((response) => {
        (this.orderDetails = response?.data[0])
        this.selectedBusinessRefs.push({
          business_reference: this.orderDetails.business_reference, order_id: this.orderDetails.order_id
        });
      });
  }

  removeMapMarker(ids: string[]) {
    ids.forEach(e => {
      this.mapView.removeMarkers(e);
    });
  }
}

export interface bucketMarkerDetails{
  order_id?: string;
  business_reference?: string;
}
