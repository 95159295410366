<app-section>
  <heading 
    [showToggle]="true" 
    (click)="collapsible.toggle()" 
    [icon]="icons.types.Alert" 
    [color]="icons.colors.Red">
    <h4 class="heading-title">Failed Parcels</h4>
  </heading>
  <div appCollapsible [expanded]="false" #collapsible="appCollapsible">
    <app-table
      [columns]="parcelTableColumns"
      [dataMap]="parcelTableDataMap"
      [rawData]="failedParcels"
      [rowsSelectable]="true"
      [bulkActions]="parcelBulkActions"
      (actionTaken)="handleTableActions($event)"
      [showBulkActionsAlways]="true"
    >
    </app-table>
  </div>
</app-section>
