import { ModuleWithProviders, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';

// Directives
import { CollapsibleDirective } from './collapsible/collapsible.directive';
import { TooltipDirective } from './tooltip/tooltip.directive';

@NgModule({
  declarations: [CollapsibleDirective, TooltipDirective],
  exports: [CollapsibleDirective, TooltipDirective],
  schemas: [NO_ERRORS_SCHEMA],
})
export class DirectivesModule {
  public static forRoot(): ModuleWithProviders<DirectivesModule> {
    return { ngModule: DirectivesModule, providers: [] };
  }
}
