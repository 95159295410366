export enum ModifyBucketIntentionEnum {
  Add = 'add',
  Edit = 'edit',
}

export enum ModifyBucketModalActionsEnum {
  Cancel = 'cancel',
  Confirm = 'confirm',
  SaveAndPrint = 'saveAndPrint',
  Loading = 'loading',
}
