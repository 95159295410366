import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ManageBusinessService } from '../manage-business.service';
import {IconTypes} from '../../../shared/icon/icon.interfaces';
import {UiColors} from '../../../interfaces/ui.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';

@Component({
  selector: 'app-gift-codes-edit',
  templateUrl: './gift-codes-edit.component.html',
  styleUrls: ['./gift-codes-edit.component.scss']
})
export class GiftCodesEditComponent extends SimpleModalComponent<null, any> implements OnInit {
  pricingModels = [
    { name: 'Envelope', value: 'parcel-a4-envelope' },
    { name: 'Small', value: 'parcel-small' },
    { name: 'Medium', value: 'parcel-medium' },
    { name: 'Large', value: 'parcel-large' },
    { name: 'Extra Large', value: 'parcel-xlarge' },
  ];
  parcel_size = 'parcel-medium';
  name;
  buttonTypes = ButtonTypes;

  Icons = {
    Colors: UiColors,
    Types: IconTypes
  };
  constructor(
    private manageBusinessService: ManageBusinessService) {
    super();
  }

  addGiftCode() {
    this.manageBusinessService.addGiftCode(this.name, this.parcel_size, );
    this.close();
  }

  cancel() {
    this.close();
  }


  ngOnInit() {
  }

}
