import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ModalSizes, ModalAction } from '../modals/modal.interfaces';
import { IconTypes } from '../icon/icon.interfaces';
import axios from 'app/api/axios';
import { TableColumn, TableDataMap } from '../table/table.interfaces';
import { UiColors } from 'app/interfaces/ui.interfaces';

@Component({
  selector: 'app-select-driver-modal',
  templateUrl: './select-driver-modal.component.html',
  styleUrls: ['./select-driver-modal.component.scss'],
})
export class SelectDriverModalComponent extends SimpleModalComponent<any, any> implements OnInit {
  public ModalSizes = ModalSizes;
  public IconTypes = IconTypes;
  uiColours = UiColors;
  public actions: ModalAction[] = [{ name: 'close', title: 'Close', isPrimary: false }];
  public driverTableColumns: TableColumn[] = [
    { name: 'id', displayName: 'Id', hidden: true },
    { name: 'driverId', displayName: 'DriverId', hidden: true },
    { name: 'name', displayName: 'Name' },
    { name: 'phone', displayName: 'Phone Number' },
    { name: 'email', displayName: 'Email' },
  ];

  public driverTableDataMap: TableDataMap = {
    cells: {
      id: { column: 'id', map: 'id' },
      driverId: { column: 'driverId', map: 'driver_id' },
      name: { column: 'name', map: 'name' },
      phone: {
        column: 'phone',
        map: 'phone',
        displayPipeArgs: ['none'],
      },
      email: { column: 'email', map: 'email' },
    },
  };

  public driverPrimaryActions = [{ event: 'select', title: 'Select', icon: IconTypes.Check }];

  driver_ids;
  drivers_array = [];
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.driver_ids.forEach((driver_id) => {
      axios.get('/last-mile/last_mile_id/' + driver_id).then((response) => {
        this.drivers_array.push(response?.data);
      });
    });
  }

  handleAction($event) {
    switch ($event) {
      case 'close':
        this.result = undefined;
        this.close();
        break;
    }
  }

  respondToTableAction($event) {
    const driverLoadId = $event.rows[0].cells.find((cell) => cell.column === 'id').value;
    this.result = driverLoadId;
    this.close();
  }
}
