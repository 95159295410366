<ngx-charts-gauge
      [view]="view"
      [scheme]="colorScheme"
      [results]="data"
      [min]="0"
      [max]="10"
      [angleSpan]="240"
      [startAngle]="-120"
      [bigSegments]="2"
      [smallSegments]="5"
      (select)="onSelect($event)"
      [legend]="false"
      [showText]="false">
    </ngx-charts-gauge>