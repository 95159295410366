import { Injectable } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import { Store } from '@ngrx/store';
import { selectorActingAs, selectorUser } from 'app/auth/auth.reducer';
import { AuthUser } from 'app/interfaces/auth.interfaces';
import axios from 'app/api/axios';
import {
  TableDataMap,
  TableColumn,
  TableDataSetHighlightColor,
  TableDataMapFormatter,
} from '../table/table.interfaces';
import { HealthStates } from 'app/dashboard/trips.enums';
import { AngularFireDatabase } from '@angular/fire/database';

export const mfSetAlertSeenHighlighter: TableDataSetHighlightColor = (map: [string, string], item: any) => {
  switch (item[map[0]]) {
    case true:
      return 'highlight-' + HealthStates.Default;
    case false:
      return 'highlight-' + HealthStates.Primary;
  }
};

export const mfSetAlertSeenFormatter: TableDataMapFormatter = (map: any, item: any) => {
  switch (item[map]) {
    case true:
      return 'Seen';
    case false:
      return 'New';
  }
};

@Injectable({
  providedIn: 'root',
})
export class AlertsService {
  user: AuthUser;
  vehicle_ids = [];

  public vehicleAlertsTableColumns: TableColumn[] = [
    { name: 'id', displayName: 'ID', hidden: true },
    { name: 'event_time_stamp', displayName: 'Event Time Stamp', sortable: true },
    { name: 'registration', displayName: 'Registration', sortable: true },
    { name: 'event_description', displayName: 'Description', sortable: true },
    { name: 'seen', displayName: 'Status', sortable: true },
  ];

  public vehicleAlertsTableMap: TableDataMap = {
    cells: {
      id: { column: 'id', map: 'id' },
      event_time_stamp: { column: 'event_time_stamp', map: 'EventTimeStamp', displayPipeArgs: ['date', 'medium'] },
      event_description: { column: 'event_description', map: 'EventLongDescription' },
      registration: { column: 'registration', map: 'Registration' },
      seen: {
        column: 'seen',
        map: 'seen',
        format: mfSetAlertSeenFormatter,
        highlighted: true,
        highlightColorClass: mfSetAlertSeenHighlighter,
        highlightColorMap: ['seen'],
      },
    },
  };

  constructor(public authService: AuthService, private store: Store<any>, private db: AngularFireDatabase) {
    this.store.select(selectorUser).subscribe((next) => (this.user = next));
    this.store.select(selectorActingAs).subscribe((next) => {
      if (next.vehicle_registration_numbers) {
        this.vehicle_ids = next.vehicle_registration_numbers;
      }
    });
  }

  getCarTrackAlerts(vehicle_ids) {
    return axios({
      method: 'POST',
      url: '/cartrack/get-alerts',
      data: {
        user_id: this.user.user_id,
        registration_numbers: vehicle_ids,
        auto_accept: true,
        show_historic: true,
      },
    });
  }
}
