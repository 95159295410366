<div #menu (click)="setIsOpen($event)" [class]="theme + ' menu-dropdown' + (isOpen ? ' fill' : '')">
    <label>{{label}}</label>
    <app-icon
    [color]="color"
    [size]="iconSize"
    [icon]="iconType"
    ></app-icon>
</div>
<div class="menu-container">
    <div *ngIf="isOpen" class="menu">
        <div *ngFor="let item of items" (click)="selectItem(item)" (mouseenter)="itemHover(item)" [class]="item === activeItem ? 'menu-item-highlight' : 'menu-item'">
            {{item.displayName}}
        </div>
    </div>
    <div *ngIf="nestedMenuItems && nestedMenuItems.length > 0" class="extra-menu">
        <div *ngFor="let item of nestedMenuItems" (click)="selectItem(item)" class="menu-item">
            {{item.displayName}}
        </div>
    </div>
</div>

