import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { NotificationsService } from 'app/shared/notifications/notifications.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { ActingAs, CourierIntegrationOrderRecreateConfig} from 'app/interfaces/auth.interfaces';
import { selectCourierIntegrationOrderRecreateConfig } from 'app/auth/auth.reducer';
import { catchError, concatMap, take, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject, from, of } from 'rxjs';
import * as fromAuth from 'app/auth/auth.reducer';
import { RecreateCourierOrderResponse } from 'app/dashboard/new-trips/new-trips.interfaces';
import { environment } from 'environments/environment';
import { AutoNotesService } from 'app/dashboard/notes/auto-notes.service';
import { AutoNotesTypes } from 'app/dashboard/notes/notes.constants';

@Injectable()
export class BulkSendToCourierService implements OnDestroy {

  // Courier order integration config
  private readonly _courierIntegrationOrderRecreateConfig$ = new BehaviorSubject<CourierIntegrationOrderRecreateConfig>(null);
  readonly courierIntegrationOrderRecreateConfig$ = this._courierIntegrationOrderRecreateConfig$.asObservable();

  private readonly _uberEnabledWarehouses$ = new BehaviorSubject<string[]>(null);
  readonly uberEnabledWarehouses$ = this._uberEnabledWarehouses$.asObservable();

  private readonly _isLoading$ = new BehaviorSubject<boolean>(false);
  readonly isLoading$ = this._isLoading$.asObservable();

  private readonly _processingList$ = new BehaviorSubject<BulkTableDisplay[]>([])
  readonly processingList$ = this._processingList$.asObservable();

  private readonly _totalOrders$ = new BehaviorSubject<number>(0);
  readonly totalOrders$ = this._totalOrders$.asObservable();

  private unsubscribe$ = new Subject<void>();
  private processingList: BulkTableDisplay[] = []


  set isLoading(state: boolean) {
    this._isLoading$.next(state);
  }

  private actingAs: ActingAs;

  constructor(private store: Store<any>,
    private notificationsService: NotificationsService,
    private firestore: AngularFirestore,
    private httpClient: HttpClient,
    private autoNotesService: AutoNotesService) {

    this.store.select(fromAuth.selectorActingAs).pipe(take(1)).subscribe((actingAs) => (this.actingAs = actingAs));
    this.store.select(selectCourierIntegrationOrderRecreateConfig).pipe(take(1)).subscribe((config: CourierIntegrationOrderRecreateConfig) => this._courierIntegrationOrderRecreateConfig$.next(config));
  }

  ngOnDestroy(): void {
    this._courierIntegrationOrderRecreateConfig$.next(null);
    this._uberEnabledWarehouses$.next(null);
    this._isLoading$.next(null);
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  bulkRecreateCourierOrder(orderIds: string[]): void {
    this._isLoading$.next(true);
    this._totalOrders$.next(orderIds.length);
    // for each of the orders, we will recreate the order as a courier order
    from(orderIds).pipe(
      concatMap((orderId) =>
        this.recreateCourierOrder(orderId).pipe(
          tap((order: RecreateCourierOrderResponse) => {
            if(order.is_success){
              this.autoNotesService.generateNote({autoNotesType: AutoNotesTypes.sendToCourier})
            }
            this.processingList.push({
              id: orderId,
              success: order.is_success,
              message: order.output[0],
              time: new Date()
            });
            this._processingList$.next(this.processingList);
          }),
          catchError(() => {
            this.processingList.push({
              id: orderId,
              success: false,
              message: 'Order failed to process',
              time: new Date()
            });
            return of(null); // Handle the error and continue the sequence
          })
        )
      )
    ).pipe(takeUntil(this.unsubscribe$)).subscribe({
      complete: () => {
        this._isLoading$.next(false);
      }
    });
  }

  clearList(): void{
    this.processingList = [];
    this._processingList$.next([]);
    this._totalOrders$.next(0);
  }

  private recreateCourierOrder(orderId: string): Observable<RecreateCourierOrderResponse> {
    const body = this.getOrderRecreateRequest();
    return this.httpClient.post<RecreateCourierOrderResponse>(`${environment.integration.rootUrl}order/${orderId}/recreate-as-courier-order`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('id_token')}`,
          'api-key': this.actingAs.id
        },
      });
  }

  private getOrderRecreateRequest(): any {
    // For now, we will always only have 1 courier, but later we would need to find the courier here by courierId, that will be passed down to this method!
    // const courierConfig = Object.keys(this.courierIntegrationOrderRecreateConfig).map((key) => this.courierIntegrationOrderRecreateConfig[key]);
    const courier_id = Object.keys(this._courierIntegrationOrderRecreateConfig$.getValue())[0];
    const { service_type_uid } = this._courierIntegrationOrderRecreateConfig$.getValue()[courier_id];

    return {
      courier_id,
      service_type_uid,
      cancel_original: true,
      validate_with_courier: true
    };
  }
}

export interface BulkTableDisplay{
  id: string;
  success: boolean;
  message?: string;
  time: Date;
}
