<div class="table-wrap lookup">
  <heading [icon]="Icons.Types.Details" [color]="Icons.Colors.Blue">
    <h4 class="heading-title">Orders</h4>
  </heading>
  <div class="actions-wrap" *ngIf="bucketTableData.length > 10">
    <div class="search-bar">
      <label for="search">
        <svg-icon src="/assets/img/icons/search.svg" class="icon"></svg-icon>
      </label>
      <input
        type="text"
        placeholder="Filter..."
        (ngModelChange)="searchString = $event; filterOrders(searchString)"
        [ngModel]="searchString"
        (keydown.enter)="filterOrders(searchString)"
      />
    </div>
  </div>
  <!-- TODO: tableActions -->
  <app-table
    [columns]="verifyOrdersColumns"
    [dataMap]="verifyOrdersDataMap"
    [rawData]="shownOrders"
    [showViewMore]="showViewMore"
    (moreDataRequested)="loadMoreTableData()"
    (viewAllRequested)="showAllData()"
    [showViewAll]="showViewMore"
    [rowsSelectable]="true"
    [showSelectionSummaryBottom]="true"
    [showSelectionSummaryTop]="false"
    [primaryActions]="tablePrimaryActions"
    [showAnnotations]="true"
    (actionTaken)="respondToTableActions($event)"
    [bulkActions]="tableBulkActions"
    [zeroDataMessage]="'No Orders Found'"
  >
  </app-table>
</div>
