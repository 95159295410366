import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-delivery-sla-breakdown',
  templateUrl: './delivery-sla-breakdown.component.html',
  styleUrls: ['./delivery-sla-breakdown.component.scss'],
})
export class DeliverySLABreakdownComponent implements OnInit {
  data = [
    {
      name: 'WC',
      value: 1012,
    },
    {
      name: 'GP',
      value: 1251,
    },
    {
      name: 'KZN',
      value: 655,
    },
    {
      name: 'PE',
      value: 123,
    },
    {
      name: 'NW',
      value: 12,
    },
  ];

  colorScheme = { domain: ['#02DE73', '#F1453D', '#27B7FC', '#f8e71c', '#ff7100'] };

  getBucketBreakdown($event): void {
    alert($event);
  }

  constructor() {}

  ngOnInit(): void {}
}
