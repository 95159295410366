<app-section>
  <heading [icon]="IconTypes.Trip" [color]="UiColors.Blue">
    <h4 class="heading-title">
      <strong>Delivery Performance Per Type</strong>
    </h4>
  </heading>
  <app-grid-container [padding]="'0rem'" [columns]="'repeat(1, 1fr)'" [theme]="UiThemes.Dark">
    <app-success-vs-failure-per-allocation></app-success-vs-failure-per-allocation>
  </app-grid-container>
</app-section>

<app-grid-container [padding]="'0rem'" [colGap]="'4rem'" [columns]="'repeat(2, 1fr)'">
  <app-section>
    <app-grid-container [padding]="'0rem'" [columns]="'repeat(1, 1fr)'" [theme]="UiThemes.Dark">
      <heading [icon]="IconTypes.Bucket" [color]="UiColors.Blue">
        <h4 class="heading-title">
          <strong>Bucket State Breakdown</strong>
        </h4>
      </heading>
      <app-bucket-status-breakdown></app-bucket-status-breakdown>
    </app-grid-container>
  </app-section>
  <app-section>
    <app-grid-container [padding]="'0rem'" [columns]="'repeat(1, 1fr)'" [theme]="UiThemes.Dark">
      <heading [icon]="IconTypes.Check" [color]="UiColors.Blue">
        <h4 class="heading-title">
          <strong>Provincial Breakdown</strong>
        </h4>
      </heading>
      <app-delivery-sla-breakdown></app-delivery-sla-breakdown>
    </app-grid-container>
  </app-section>
</app-grid-container>

<app-section>
  <heading [icon]="IconTypes.Steering" [color]="UiColors.Blue">
    <h4 class="heading-title">
      <strong>Driver Performance</strong>
    </h4>
  </heading>
  <app-grid-container [padding]="'1rem'" [columns]="'repeat(1, 1fr)'" [theme]="UiThemes.Dark">
    <app-driver-performance-breakdown></app-driver-performance-breakdown>
  </app-grid-container>
</app-section>
