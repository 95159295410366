import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FilterItem } from 'app/planning/planning.interfaces';
import { PillInputDropdownItem, PillInputTheme } from './pill-input.interface';

@Component({
  selector: 'app-pill-input',
  templateUrl: './pill-input.component.html',
  styleUrls: ['./pill-input.component.scss'],
})
export class PillInputComponent implements OnInit, OnChanges {
  @Input() placeholder: string = '';
  @Input() label: string = '';
  @Input() items: string[] = [];
  @Input() dropdownItems: PillInputDropdownItem[] = [];
  @Input() allowFreeText: boolean = true;
  @Input() theme: PillInputTheme = PillInputTheme.Default;
  @Input() disabled = false
  @Output() newItemEvent = new EventEmitter<FilterItem>();
  @Output() clearAllEvent = new EventEmitter();
  @Output() clearItemEvent = new EventEmitter<number>();
  @Output() valueChanged = new EventEmitter<string>();
  @ViewChild('input') input: HTMLInputElement;
  isInFocus: boolean = false;
  PillInputTheme = PillInputTheme;
  filteredDropdownItems: PillInputDropdownItem[] = [];
  constructor() {}

  ngOnInit(): void {}

  // object references of items will need to be updated to trigger this, therefore basic assigning in parent component will not trigger it
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items || changes.dropdownItems) {
      this.filteredDropdownItems = this.dropdownItems?.filter((x) => !this.items.includes(x.Name));
    }
  }

  onEnterPress($event: Event): void {
    const eventTarget = $event.target as HTMLInputElement;
    if (eventTarget.value.trim().length > 0) {
      const res = {Id: null, Name: eventTarget.value} as FilterItem;
      this.newItemEvent.emit(res);
    }
    this.input.value = '';
  }

  onBackSpacePress($event: Event): void {
    const eventTarget = $event.target as HTMLInputElement;
    if (eventTarget.value === '') {
      this.clearItemEvent.emit(this.items.length - 1);
    }
  }

  removeItem(index: number): void {
    this.clearItemEvent.emit(index);
  }

  clearItems(): void {
    this.clearAllEvent.emit();
  }

  onFocus(focus: boolean): void {
    if (focus) {
      this.isInFocus = focus;
    } else {
      // if we lose focus of the input, it hides the dropdown selection, which prevents the click event from going off
      setTimeout(() => {
        this.isInFocus = focus;
      }, 200);
    }
  }
  // TODO: test - PillInputDropdownItem
  addItem(value: PillInputDropdownItem): void {
    const res = { Id: value.Id, Name: value.Name} as FilterItem;
    this.newItemEvent.emit(res);
    this.input.value = '';
    this.isInFocus = false;
  }

  onValueChanged(value: string): void {
    this.valueChanged.emit(value);
  }
}
