import {Component, HostBinding, Input, OnChanges} from '@angular/core';
import {AlignSelf, FlexChildProps, SpacingProperties} from './flex-child.interfaces';
import {buildSpacingString} from './flex-child.helpers';

@Component({
  selector: 'app-flex-child',
  templateUrl: './flex-child.component.html',
  styleUrls: ['./flex-child.component.scss'],

})
export class FlexChildComponent implements FlexChildProps, OnChanges {
  @Input() alignSelf: AlignSelf;
  @Input() margin: string | SpacingProperties;
  @Input() padding: string | SpacingProperties;
  @Input() flex: number;

  @HostBinding('style.alignSelf') hostAlignSelf: string;
  @HostBinding('style.margin') hostMargin: string;
  @HostBinding('style.padding') hostPadding: string;
  @HostBinding('style.flex') hostFlex: string;

  ngOnChanges() {
    this.createStyleProps();
  }

  createStyleProps() {
    if (this.alignSelf !== undefined) {
      this.hostAlignSelf = this.alignSelf;
    }

    if (this.flex !== undefined) {
      this.hostFlex = this.flex.toString();
    }

    if (this.margin !== undefined) {
      if (typeof this.margin === 'string') {
        this.hostMargin = this.margin;
      } else {
        this.hostMargin = buildSpacingString(this.margin);
      }
    }

    if (this.padding !== undefined) {
      if (typeof this.padding === 'string') {
        this.hostPadding = this.padding;
      } else {
        this.hostPadding = buildSpacingString(this.padding);
      }
    }
  }
}
