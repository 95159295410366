import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ModalSizes, ModalAction } from 'app/shared/modals/modal.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { TableColumn, TableDataMap, TableActionTaken, TableCell } from 'app/shared/table/table.interfaces';
import { UiColors } from 'app/interfaces/ui.interfaces';
import {
  mfGetNestedObjectValuesByKeyArray,
  mfHumanizeString,
} from 'app/shared/table/map-formatters.table.helpers';

@Component({
  selector: 'app-assign-parcels',
  templateUrl: './assign-parcels.component.html',
  styleUrls: ['./assign-parcels.component.scss'],
})
export class AssignParcelsComponent extends SimpleModalComponent<any, any> implements OnInit {
  public ModalSizes = ModalSizes;
  public iconTypes = IconTypes;
  uiColors = UiColors;
  public actions: ModalAction[] = [{ name: 'close', title: 'Close', isPrimary: false }];
  Parcels;

  parcelTableColumns: TableColumn[] = [
    { name: 'business_reference', displayName: 'Business Reference' },
    { name: 'parcel_waybill', displayName: 'Waybill' },
    { name: 'contact_name', displayName: 'Contact Name' },
    { name: 'parcel_value', displayName: 'Value' },
    { name: 'address', displayName: 'Address' },
    { name: 'assigned_driver', displayName: 'Assigned Driver' },
    { name: 'last_mile_parcel_state', displayName: 'Parcel State' },
  ];

  parcelTableDataMap: TableDataMap = {
    cells: {
      parcel_waybill: { column: 'parcel_waybill', map: 'ParcelWaybill' },
      business_reference: { column: 'business_reference', map: 'BusinessReference' },
      parcel_value: { column: 'parcel_value', map: 'ParcelValue' },
      address: {
        column: 'address',
        map: ['Destination', 'FormattedAddress'],
        format: mfGetNestedObjectValuesByKeyArray,
      },
      contact_name: {
        column: 'contact_name',
        map: ['Destination', 'Contact', 'Name'],
        format: mfGetNestedObjectValuesByKeyArray,
      },
      assigned_driver: { column: 'assigned_driver', map: 'DriverName' },
      last_mile_parcel_state: {
        column: 'last_mile_parcel_state',
        map: 'LastMileParcelState',
        format: mfHumanizeString,
      },
    },
  };

  parcelBulkActions = [];

  order_id: string;
  last_mile_id: string;
  assignable_parcels = [];
  shownParcels = [];
  filteredParcels = [];
  numberOfResults = 10;
  searchString: string;
  showViewMore = true;
  selectedParcels = [];
  assign: boolean;
  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.assign) {
      this.parcelBulkActions = [
        { event: 'assign', title: 'Assign', icon: IconTypes.Check, icon_color: UiColors.Green },
      ];
    } else {
      this.parcelBulkActions = [
        { event: 'assign', title: 'Transfer', icon: IconTypes.Check, icon_color: UiColors.Green },
      ];
    }
  }

  handleAction($event: string): void {
    switch ($event) {
      case 'close':
        this.close();
        break;
    }
  }

  handleTableActions($event: TableActionTaken): void {
    $event.rows.forEach((element) => {
      this.selectedParcels.push(element.cells.find((cell: TableCell) => cell.column === 'parcel_waybill').value);
    });
    switch ($event.action.event) {
      case 'assign':
        this.result = this.selectedParcels;
        this.close();
        break;
    }
  }
}
