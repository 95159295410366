import { Component, Input, OnChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectorUser } from 'app/auth/auth.reducer';
import { ReconcileWarehouseModalComponent } from 'app/dashboard/last-mile/reconcile-route/reconcile-warehouse-modal.component';
import { parcelOverviewColumns, WaypointStatusConstant } from 'app/dashboard/new-trips/new-trips.constants';
import { Parcel, WaypointStatusSettings } from 'app/dashboard/new-trips/new-trips.interfaces';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { JustifyContent } from 'app/shared/flex-container/flex-container.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { ModalSizes } from 'app/shared/modals/modal.interfaces';
import { mfAmountPaid, mfHumanizeString } from 'app/shared/table/map-formatters.table.helpers';
import { mapDataToTable } from 'app/shared/table/table.helpers';
import { DisplayPipes, TableDataMap } from 'app/shared/table/table.interfaces';
import { environment } from 'environments/environment';
import { SimpleModalService } from 'ngx-simple-modal';
import { LastMileService } from '../last-mile.service';

@Component({
  selector: 'app-last-mile-fs-warehouse-route',
  templateUrl: './last-mile-fs-warehouse-route.component.html',
  styleUrls: ['./last-mile-fs-warehouse-route.component.scss'],
})
export class LastMileFsWarehouseRouteComponent implements OnChanges {
  @Input() WarehouseVisit;
  @Input() lastMileId: string;
  @Input() Parcels: Parcel[];
  @Input() RouteStartedAt;
  @Input() RouteEndedAt;
  @Input() fleetAllocation;

  public IconTypes = IconTypes;
  public UiColors = UiColors;
  public ModalSizes = ModalSizes;
  public UiThemes = UiThemes;
  public justifyContent = JustifyContent;
  buttonTypes = ButtonTypes;

  user;
  endingRoute;
  parcelOverviewDataMap: TableDataMap = {
    cells: {
      parcel_state: {
        column: 'parcel_state',
        map: ['LastMileParcelState'],
        format: mfHumanizeString,
      },
      parcel_reference: {
        column: 'parcel_reference',
        map: 'ParcelReference',
        displayPipeArgs: ['none'],
      },
      waybill: {
        column: 'waybill',
        map: 'ParcelWaybill',
        displayPipeArgs: ['none'],
      },

      parcel_value: {
        column: 'ParcelValue',
        map: 'ParcelValue',
        prefix: environment.currency_symbol,
        displayPipeArgs: DisplayPipes.decimalsTwo,
      },
      amount_paid: {
        column: 'AmountPaid',
        map: 'AmountPaid',
        format: mfAmountPaid,
      },
      payment_type: {
        column: 'PaymentType',
        map: 'PaymentType',
        format: mfHumanizeString,
      },
      failed_reason: {
        column: 'Failure',
        map: 'FailedReason',
        format: mfHumanizeString,
      },
      reconciled_reason: {
        column: 'reconciled_reason',
        map: 'ReconciledReason',
        format: mfHumanizeString,
      },
    },
  };
  public parcelOverviewColumns = parcelOverviewColumns;
  returnsOverviewTableData;
  collectionsOverviewTableData;
  collectedReturnsTableData;
  reconciling;
  showMap = false;

  WarehouseRouteStatus: WaypointStatusSettings = {
    icon: undefined,
    color: undefined,
    timestamp: undefined,
    distance: undefined,
    label: undefined,
  };

  constructor(
    private simpleModalService: SimpleModalService,
    private lastMileService: LastMileService,
    private store: Store
  ) {
    this.store.select(selectorUser).subscribe((next) => (this.user = next));
  }

  constructIntentionTables(type) {
    const combinedParcelArray = type;
    return mapDataToTable(combinedParcelArray.flat(), this.parcelOverviewDataMap, this.parcelOverviewColumns);
  }

  ngOnChanges(): void {
    this.WarehouseRouteStatus = this.setWaypointStatus();
    this.returnsOverviewTableData = this.constructIntentionTables(this.WarehouseVisit.Returns);
    this.collectionsOverviewTableData = this.constructIntentionTables(this.WarehouseVisit.Collections);
    this.collectedReturnsTableData = this.constructIntentionTables(this.WarehouseVisit.CollectedReturns);
  }

  endRoute() {
    this.lastMileService
      .endWarehouseRoute(this.lastMileId)
      .then(() => (this.endingRoute = false))
      .catch(() => (this.endingRoute = false));
  }

  reconcileRoute(): void {
    this.reconciling = true;
    const modal_data = {
      Parcels: this.Parcels,
      route_type: 'warehouse',
    };
    this.simpleModalService
      .addModal(ReconcileWarehouseModalComponent, modal_data)
      .subscribe((result: { parcel_waybill: string; reason: string; result: string }[]) => {
        if (!result) {
          this.reconciling = false;
          return;
        }
        this.lastMileService
          .reconcileWarehouseRoute(result, this.lastMileId)
          .then(() => {
            this.reconciling = false;
          })
          .catch(() => {
            this.reconciling = false;
          });
      });
  }

  setWaypointStatus(): WaypointStatusSettings {
    switch (this.WarehouseVisit?.VisitState) {
      case 'Finalized':
        if (
          this.WarehouseVisit.Returns.length &&
          this.WarehouseVisit.Returns?.every((parcel) => parcel.LastMileParcelState === 'Returned')
        ) {
          return WaypointStatusConstant.complete;
        }
        if (
          this.WarehouseVisit.Returns.length &&
          this.WarehouseVisit.Returns?.every((parcel) => parcel.LastMileParcelState === 'FailedReturn')
        ) {
          return WaypointStatusConstant.failed;
        }
        if (
          this.WarehouseVisit.Collections.length &&
          this.WarehouseVisit.Collections.every((parcel) => parcel.FailedReason)
        ) {
          return WaypointStatusConstant.failed;
        } else if (
          this.WarehouseVisit.Collections.length &&
          this.WarehouseVisit.Collections.some((parcel) => parcel.FailedReason)
        ) {
          return WaypointStatusConstant.partial;
        } else if (
          this.WarehouseVisit.Collections.length &&
          this.WarehouseVisit.Collections.some((parcel) => parcel.LastMileParcelState === 'Assigned')
        ) {
          return WaypointStatusConstant.pending;
        } else {
          return WaypointStatusConstant.complete;
        }
      case 'Pending':
        return WaypointStatusConstant.pending;
      case 'EnRoute':
        return WaypointStatusConstant.enRoute;
      case 'Visiting':
        return WaypointStatusConstant.visiting;
      case 'Dispatching':
        return WaypointStatusConstant.dispatching;
      case 'Cancelled':
        return WaypointStatusConstant.cancelled;
      default:
        return WaypointStatusConstant.pending;
    }
  }

  toggleMap(): void {
    this.showMap = !this.showMap;
    if (this.showMap) {
      // this.getDriverTravelData();
    }
  }
}
