import {Component, Input, OnInit} from '@angular/core';
import {TableColumn, TableSummaryData} from '../table.interfaces';

@Component({
  selector: '[app-table-summary]',
  templateUrl: './table-summary.component.html',
  styleUrls: ['./table-summary.component.scss']
})
export class TableSummaryComponent implements OnInit {
  @Input() showTotals = false;
  @Input() showAverages = false;
  @Input() showRowHeader = true;
  @Input() showRowActions = true;
  @Input() summaryTitle = 'Summary';
  @Input() summaryData: TableSummaryData;
  @Input() columns: TableColumn[];
  @Input() visibleColumnsCount: number;
  @Input() isTableSummary: boolean;
  @Input() hasSelection: boolean;

  ngOnInit() {
    // TEMP to disable subtract selection from totals feature
    this.isTableSummary = false;
    this.hasSelection = false;
  }

  handleSubtractSelection (state) {
    if (state) {
      // TODO: Figure out whether to do calc in table (will need selection totals)
      // TODO: (cont...) or pass back out to table. Then wire it all up.
    }
  }
}
