import { TableAction, TableCell, TableColumn, TableDataByColumn, TableDataMap, TableRow } from './table.interfaces';
import { checkType, completeAssign } from '../helpers';
import { IconProps } from '../icon/icon.interfaces';

export const hideCell = (colName: string, columns: TableColumn[]) => {
  return columns.find((col: TableColumn) => col.name === colName).hidden;
};

export const shouldShowAction = (actions: TableAction[]): boolean =>
  typeof actions !== 'undefined' && actions.length > 0;

export const doesRequireSortData = (columns: TableColumn[]): boolean => {
  return columns.find((col) => col.sortable) !== undefined;
};

export const getVisibleColumnsCount = (columns: TableColumn[]): number => {
  return columns.length - columns.filter((col) => col.hidden).length;
};

export const formatCellValue = (val: string | number, prefix: string, postfix: string): string => {
  const _prefix = prefix ? prefix : '';
  const _postfix = postfix ? postfix : '';

  // val !== val is a check for a NaN value
  if (val === null || val === '' || val !== val) {
    return '—';
  }

  return _prefix + val + _postfix;
};

export const mapDataToTable = (data: any[], dataMap: TableDataMap, columns: TableColumn[]): TableRow[] => {
  return data.map((item, i) => ({
    index: i,
    cells: columns.map((col) => {
      const cell = dataMap.cells[col.name];
      let value: string | number;
      let icon: IconProps = {
        icon: cell.icon,
        color: cell.iconColor,
        size: cell.iconSizeRem,
      };
      let annotation: string | number;
      let colorClass: string;

      if (cell.format) {
        value = cell.format(<string[]>cell.map, item);
      } else {
        value = cell.map !== '' && item[<string>cell.map] === undefined ? '' : item[<string>cell.map];
      }

      if (cell.iconFormat) {
        icon = cell.iconFormat(cell.iconMap, item, icon);
      }

      if (cell.formatAnnotation) {
        annotation = cell.formatAnnotation(<string[]>cell.annotation_map, item);
      } else {
        annotation = cell.annotation_map ? item[<string>cell.annotation_map] : undefined;
      }

      if (cell.highlightColorClass) {
        colorClass = cell.highlightColorClass(cell.highlightColorMap, item);
      }

      return <TableCell>{
        column: col.name,
        value,
        annotation,
        prefix: dataMap.cells[col.name].prefix,
        postfix: dataMap.cells[col.name].postfix,
        displayPipeArgs: dataMap.cells[col.name].displayPipeArgs,
        displayAnnotationPipeArgs: dataMap.cells[col.name].displayAnnotationPipeArgs,
        highlighted: dataMap.cells[col.name].highlighted,
        highlightColorClass: colorClass,
        color: dataMap.cells[col.name].color,
        annotation_color: dataMap.cells[col.name].annotation_color,
        icon: icon.icon,
        iconColor: icon.color,
        iconSizeRem: icon.size,
      };
    }),
    isSelectable: dataMap.row ? item[dataMap.row.isSelectable] : undefined,
    heading: dataMap.row ? item[dataMap.row.heading] : undefined,
    selected: dataMap.row ? item[dataMap.row.selected] : undefined,
  }));
};

export const mapTableDataToColumns = (rows: TableRow[], columns: TableColumn[]): TableDataByColumn[] => {
  const dataToReturn: TableDataByColumn[] = Object.keys(rows[0]?.cells).map((colIndex) => {
    return {
      column: rows[0]?.cells[colIndex].column,
      sortData: [],
      values: [],
      shouldCalculate: columns.find((col) => col.name === rows[0]?.cells[colIndex].column).shouldCalculate,
      prefix: rows[0]?.cells[colIndex].prefix,
      postfix: rows[0]?.cells[colIndex].postfix,
      displayPipeArgs: rows[0]?.cells[colIndex].displayPipeArgs,
    };
  });

  rows.forEach((row) => {
    if (row.cells) {
      row?.cells?.forEach((cell) => {
        const currCol = dataToReturn.find((item) => cell.column === item.column);
        currCol.sortData.push({
          index: row.index,
          value: checkType(cell.value, 'String') ? cell.value.toString().toLowerCase() : cell.value,
        });
        currCol.values.push(cell.value as number);
      });
    }
  });

  return dataToReturn;
};
