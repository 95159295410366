import { Component, OnInit, Input } from '@angular/core';
import { SharedService } from '../shared.service';
import { TableColumn, TableDataMap } from '../table/table.interfaces';
import { IconTypes } from '../icon/icon.interfaces';
import { Router } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { NotificationsService } from '../notifications/notifications.service';
import * as moment from 'moment';
import { SimpleModalService } from 'ngx-simple-modal';
import { DatePickerModalComponent } from '../modals/date-picker-modal.component';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { Store } from '@ngrx/store';
import { selectorActingAs } from 'app/auth/auth.reducer';
import { ButtonTypes } from '../buttons/basic-button.component';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
})
export class InvoicesComponent implements OnInit {
  @Input() context;
  invoices;
  dateRange;
  customDates = false;
  showViewMore = true;
  numberOfResults = 10;
  shownInvoices = [];
  searchString: string;
  filteredInvoices = [];
  customStartDate = 'Start';
  customEndDate = 'End';
  customDateObj = {
    start_date: null,
    end_date: null,
  };
  public UiThemes = UiThemes;
  iconTypes = IconTypes;
  buttonTypes = ButtonTypes;
  uiColours = UiColors;

  public invoiceColumns: TableColumn[] = [
    { name: 'invoice_number', displayName: 'Invoice #', sortable: true },
    { name: 'reference', displayName: 'Reference', sortable: true },
    { name: 'date', displayName: 'Date', sortable: true },
    { name: 'status', displayName: 'Status', sortable: true },
    { name: 'total', displayName: 'Total', sortable: true },
    { name: 'paid', displayName: 'Paid', sortable: true },
    { name: 'dueDate', displayName: 'Due', sortable: true },
    { name: 'invoice_id', displayName: 'invoice_id', hidden: true },
    { name: 'invoice_url', displayName: 'invoice_url', hidden: true },
  ];
  public invoiceTableDataMap: TableDataMap = {
    cells: {
      invoice_number: { column: 'Invoice #', map: 'InvoiceNumber' },
      reference: { column: 'Reference', map: 'Reference' },
      date: { column: 'Date', map: 'Date', displayPipeArgs: ['date', 'yyyy-MM-dd'] },
      status: { column: 'Status', map: 'Status' },
      total: { column: 'Total', map: 'Total', prefix: 'R ' },
      paid: { column: 'Amount Paid', map: 'AmountPaid', prefix: 'R ' },
      dueDate: { column: 'Due Date', map: 'DueDate', displayPipeArgs: ['date', 'yyyy-MM-dd'] },
      invoice_id: { column: 'Invoice Id', map: 'InvoiceId' },
      invoice_url: { column: 'Invoice Id', map: 'InvoiceUrl' },
    },
  };
  public tablePrimaryActions = [{ event: 'invoice', title: 'View Invoice', icon: IconTypes.Details }];

  constructor(
    public sharedService: SharedService,
    public router: Router,
    public authService: AuthService,
    public notificationsService: NotificationsService,
    public simpleModalService: SimpleModalService,
    public store: Store<any>
  ) {}
  setCustomDates() {
    this.customDates = true;
  }

  openDatePickerModal(dateChoice) {
    this.simpleModalService.addModal(DatePickerModalComponent).subscribe((date) => {
      switch (dateChoice) {
        case 'start_date':
          this.customDateObj.start_date = date;
          this.customDateObj.start_date = moment(this.customDateObj.start_date).format('YYYY-MM-DD');
          this.customStartDate = this.customDateObj.start_date;
          break;
        case 'end_date':
          this.customDateObj.end_date = date;
          this.customDateObj.end_date = moment(this.customDateObj.end_date).format('YYYY-MM-DD');
          this.customEndDate = this.customDateObj.end_date;
          break;
        default:
          return;
      }
      if (this.customDateObj.start_date && this.customDateObj.end_date) {
        this.getInvoices(this.customDateObj.start_date, this.customDateObj.end_date);
      }
    });
  }

  setPredefinedDate(dateType) {
    this.customDates = false;
    const dateObj = {
      title: dateType,
      start_date: null,
      end_date: null,
    };
    switch (dateType) {
      case 'thisMonth':
        this.dateRange = 'thisMonth';
        dateObj.start_date = moment().startOf('month');
        dateObj.end_date = moment();
        break;
      case 'lastMonth':
        this.dateRange = 'lastMonth';
        dateObj.start_date = moment().subtract(1, 'months').startOf('month');
        dateObj.end_date = moment().subtract(1, 'months').endOf('month');
        break;
      default:
    }
    dateObj.start_date = moment(dateObj.start_date).format('YYYY-MM-DD');
    dateObj.end_date = moment(dateObj.end_date).format('YYYY-MM-DD');
    this.getInvoices(dateObj.start_date, dateObj.end_date);
  }

  respondToTableActions($event) {
    if ($event.action.event === 'invoice') {
      const InvoiceUrl = $event.rows[0].cells.find((cell) => cell.column === 'invoice_url').value;
      window.open(InvoiceUrl);
    }
  }

  getInvoices(start_date, end_date) {
    this.sharedService
      .getInvoices(start_date, end_date)
      .then((res) => {
        if (res.data.Data) {
          this.invoices = res.data.Data.reverse();
          if (this.invoices) {
            this.showInvoices();
          }
        }
      })
      .catch((error) => {
        throw error;
      });
  }

  getAccount() {
    this.store.select(selectorActingAs).subscribe(() => {
      this.getInvoices(this.customDateObj.start_date, this.customDateObj.end_date);
    });
  }

  ngOnInit() {
    this.getAccount();
  }

  calculateViewMore(comparison, shown) {
    if (comparison.length <= shown.length) {
      this.showViewMore = false;
    } else {
      this.showViewMore = true;
    }
  }

  loadMoreTableData() {
    this.shownInvoices = [];
    this.numberOfResults = this.numberOfResults + 5;
    this.showInvoices();
    if (this.invoices.length <= this.shownInvoices.length) {
      this.showViewMore = false;
    }
  }

  showAllData() {
    this.numberOfResults = null;
    this.showViewMore = false;
    this.showInvoices();
  }

  showInvoices() {
    let i = 0;
    this.shownInvoices = [];

    if (this.filteredInvoices.length >= 0 && this.searchString) {
      if (!this.numberOfResults) {
        this.shownInvoices = this.filteredInvoices;
      } else {
        this.filteredInvoices.forEach((invoice) => {
          i++;
          if (i <= this.numberOfResults) {
            this.shownInvoices.push(invoice);
          }
          this.calculateViewMore(this.filteredInvoices, this.shownInvoices);
        });
      }
    } else {
      if (!this.numberOfResults) {
        this.shownInvoices = this.invoices;
      } else {
        this.invoices.forEach((invoice) => {
          i++;
          if (i <= this.numberOfResults) {
            this.shownInvoices.push(invoice);
          }
          this.calculateViewMore(this.invoices, this.shownInvoices);
        });
      }
    }
  }

  filterInvoices(searchString) {
    const newArray = this.invoices.filter((alert) => {
      return alert.reference.toLowerCase().includes(searchString.toLowerCase());
    });
    this.filteredInvoices = newArray;
    this.showInvoices();
  }
}
