<section *ngIf="(active_buckets | async) !== null; else loading" class="view-overview">
  <div class="button-wrap">
    <div>
      <app-basic-button
        *ngIf="open_buckets$ | async; else emptyButton"
        [title]="
          (active_buckets | async)
            ? (active_buckets | async)?.length + ' Recent'
            : 'Recent'
        "
        [type]="buttonTypes.Highlight"
        [active]="activeTypeFilter.getValue() === 'active'"
        (click)="setTypeFilter('active')"
      >
      </app-basic-button>

      <app-basic-button
        *ngIf="open_buckets$ | async; else emptyButton"
        [title]="(open_buckets$ | async) !== null ? (open_buckets$ | async)?.length + ' Open' : 'Open'"
        [type]="buttonTypes.Primary"
        [active]="activeTypeFilter.getValue() === 'open'"
        (click)="setTypeFilter('open')"
      >
      </app-basic-button>

      <app-basic-button
        *ngIf="dispatched_buckets$ | async; else emptyButton"
        [title]="
          (dispatched_buckets$ | async) !== null ? (dispatched_buckets$ | async)?.length + ' Dispatched' : 'Dispatched'
        "
        [type]="buttonTypes.Primary"
        [active]="activeTypeFilter.getValue() === 'closed'"
        (click)="setTypeFilter('closed')"
      >
      </app-basic-button>
      <!-- deleted buckets -->
      <span>
        <app-basic-button
          *ngIf="(deleted_buckets$ | async); else emptyButton"
          [title]="
            (deleted_buckets$ | async) !== null ? (deleted_buckets$ | async)?.length + ' Deleted' : 'Deleted'
          "
          [type]="buttonTypes.Primary"
          [active]="activeTypeFilter.getValue() === 'deleted'"
          (click)="setTypeFilter('deleted')"
        >
        </app-basic-button>
      </span>
      <app-basic-button
        class="piped"
        [title]="all_count ? all_count + ' All ' : 'View All'"
        [type]="buttonTypes.Primary"
        [active]="activeTypeFilter.getValue() === 'all'"
        (click)="setTypeFilter('all')"
      >
      </app-basic-button>
      <app-basic-button
        [title]="stagedBuckets ? stagedBuckets.length + ' Staged ' : 'View Staged'"
        [type]="buttonTypes.Primary"
        [active]="activeTypeFilter.getValue() === 'staged'"
        (click)="setTypeFilter('staged')"
      >
      </app-basic-button>
    </div>

    <div>
      <app-basic-button [title]="'Add Order'" [type]="buttonTypes.Primary" [active]="true" (click)="createOrder()">
      </app-basic-button>
      <app-upload-button></app-upload-button>
    </div>
  </div>

  <div class="actions-wrap" *ngIf="activeTypeFilter.getValue() !== 'returns'">
    <app-dropdown *ngIf="activeTypeFilter.getValue() !== 'all'" [activeItem]="'View ' + activeLimitFilter.getValue()">
      <a class="dropdown-item" (click)="setLimitFilter(5)">5</a>
      <a class="dropdown-item" (click)="setLimitFilter(10)">10</a>
      <a class="dropdown-item" (click)="setLimitFilter(50)">50</a>
      <a class="dropdown-item" (click)="setLimitFilter(100)">100</a>
      <a class="dropdown-item" (click)="setLimitFilter(250)">250</a>
      <a class="dropdown-item" (click)="setLimitFilter(500)">500</a>
      <a class="dropdown-item" (click)="setLimitFilter(1000)">1000</a>
    </app-dropdown>
    <app-dropdown
      [activeItem]="activeWarehouseFilter.getValue() ? activeWarehouseFilter.getValue()?.name : 'All warehouses'"
      (click)="clearSearch()"
    >
      <input-field
        [type]="'text'"
        [placeholder]="'Search...'"
        [value]="searchString"
        [autoComplete]="'off'"
        (valueChanged)="searchString = $event"
      ></input-field>
      <a class="dropdown-item" (click)="setWarehouseFilter(null)">All warehouses</a>
      <div dropdownRef *ngIf="available_warehouses?.length">
        <a
          *ngFor="let warehouse of available_warehouses | stringFilterPipe: searchString:['name']"
          class="dropdown-item"
          (click)="setWarehouseFilter(warehouse)"
          >{{ warehouse.name }}</a
        >
      </div>
    </app-dropdown>

    <div class="filter-cards">
      <span class="filter-cards-label" (click)="openDatePickerModal()" *ngIf="!activeDateFilter.getValue()"
        >Filter by Date</span
      >
      <span class="filter-cards-label" (click)="openDatePickerModal()" *ngIf="activeDateFilter.getValue()">
        Filtered by {{ activeDateFilter.getValue() | date: 'dd LLL' }}
        <app-basic-button [title]="'Clear'" [type]="buttonTypes.Secondary" (click)="clearDateFilter()"> </app-basic-button>
      </span>
      <svg-icon
        [svgStyle]="{ 'width.rem': '1' }"
        (click)="openDatePickerModal()"
        *ngIf="!activeDateFilter.getValue()"
        src="../../../assets/img/icons/filter.svg"
        class="icon"
      ></svg-icon>
    </div>
  </div>
  <div
    *ngIf="
      !(active_buckets | async)?.length &&
      activeTypeFilter.getValue() !== 'staged' &&
      activeTypeFilter.getValue() !== 'open' &&
      activeTypeFilter.getValue() !== 'all' &&
      activeTypeFilter.getValue() !== 'deleted'
    "
    class="empty-view-wrap"
  >
    <div class="empty-view-container-wrap">
      <div class="empty-view-container">
        <h3 class="empty-view-message-text">
          {{ !(active_buckets | async) ? 'Loading Buckets...' : 'No Active Buckets Available' }}
        </h3>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      !(open_buckets$ | async)?.length &&
      activeTypeFilter.getValue() === 'open'
    "
    class="empty-view-wrap"
  >
    <div class="empty-view-container-wrap">
      <div class="empty-view-container">
        <h3 class="empty-view-message-text">
          {{ !(open_buckets$ | async) ? 'Loading Buckets...' : 'No Open Buckets Available' }}
        </h3>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      !(deleted_buckets$ | async)?.length &&
      activeTypeFilter.getValue() === 'deleted'
    "
    class="empty-view-wrap"
  >
    <div class="empty-view-container-wrap">
      <div class="empty-view-container">
        <h3 class="empty-view-message-text">
          {{ !(open_buckets$ | async) ? 'Loading Buckets...' : 'No Deleted Buckets' }}
        </h3>
      </div>
    </div>
  </div>
  <div class="staged-orders" *ngIf="activeTypeFilter.getValue() === 'staged'">
    <div class="empty-view-wrap" *ngIf="!stagedBuckets || stagedBuckets.length === 0">
      <div class="empty-view-container-wrap">
        <div class="empty-view-container">
          <h3 class="empty-view-message-text">
            {{ !loadedStaged ? 'Loading Staged Orders...' : 'No Staged Buckets Available' }}
          </h3>
        </div>
      </div>
    </div>

    <ng-template ngFor let-stagedBucket [ngForOf]="stagedBuckets">
      <div
        *ngIf="
          !activeWarehouseFilter.getValue() || activeWarehouseFilter.getValue().name === stagedBucket.ToWarehouseName
        "
        class="card-wrap"
        (click)="selectStagedOrders(stagedBucket.ToWarehouseId)"
      >
        <div class="card-date">
          <h1>
            <app-icon [icon]="IconTypes.Details" [size]="4" [color]="UiColors.Blue"></app-icon>
          </h1>
          <div class="card-day">
            <h6>{{ 'Last Updated:' }}</h6>
          </div>
          <h5 class="card-time">
            {{ stagedBucket.last_update ? (stagedBucket.last_update | date: 'dd LLL HH:mm') : 'NEW' }}
          </h5>
        </div>
        <div class="card-body">
          <div class="card-details">
            <h4 class="staged-warehouse">{{ stagedBucket.ToWarehouseName }}</h4>
            <div class="spacer"></div>
            <h4 class="card-warehouse">{{ 'Staged Orders' }}</h4>
          </div>

          <div class="card-state">
            <div>
              <h2>{{ stagedBucket.OrderCount }}</h2>
              <h6>Total Orders</h6>
            </div>

            <div>
              <h2>{{ stagedBucket.EnRoute }}</h2>
              <h6>En Route</h6>
            </div>

            <div>
              <h2>{{ stagedBucket.Received }}</h2>
              <h6>Received</h6>
            </div>
            <div>
              <h2>{{ stagedBucket.Failed }}</h2>
              <h6>Failed</h6>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>

  <div *ngIf="activeTypeFilter.getValue() === 'active'">
    <app-bucket-card
      *ngFor="let bucket of active_buckets | async; trackBy: trackByFunction"
      [bucket]="bucket"
    ></app-bucket-card>
  </div>
  <div *ngIf="activeTypeFilter.getValue() === 'open'">
    <app-bucket-card
      *ngFor="let bucket of open_buckets$ | async; trackBy: trackByFunction"
      [bucket]="bucket"
    ></app-bucket-card>
  </div>
  <div *ngIf="activeTypeFilter.getValue() === 'closed'">
    <app-bucket-card
      *ngFor="let bucket of dispatched_buckets$ | async; trackBy: trackByFunction"
      [bucket]="bucket"
    ></app-bucket-card>
  </div>
  <div *ngIf="activeTypeFilter.getValue() === 'all'">
    <app-bucket-card
      *ngFor="let bucket of active_buckets | async; trackBy: trackByFunction"
      [bucket]="bucket"
    ></app-bucket-card>
  </div>
  <div *ngIf="activeTypeFilter.getValue() === 'deleted'">
    <app-bucket-card
      *ngFor="let bucket of deleted_buckets$ | async; trackBy: trackByFunction"
      [bucket]="bucket"
    >
    </app-bucket-card>
  </div>
</section>

<ng-template #loading>
  <h4>Loading...</h4>
</ng-template>

<ng-template #emptyButton>
  <app-basic-button [title]="'Loading...'" [type]="buttonTypes.Primary" [active]="false" (click)="(null)"> </app-basic-button>
</ng-template>
