<section>
  <ng-container *ngIf="upload_response?.validation?.bucket.length === 1">
    <heading [icon]="Icons.Types.Details" [color]="Icons.Colors.Blue">
      <div class="left">
        <h4 class="heading-title">Single Bucket Upload</h4>
        <p class="heading-description">Please confirm or change your date, shift and warehouse</p>
      </div>
      <app-basic-button [title]="'Cancel Upload'" [type]="buttonTypes.Primary" (click)="cancelUpload()"> </app-basic-button>
    </heading>

    <date-time [warehouses]="warehouses" [CSVUploadDetails]="upload_response"></date-time>
  </ng-container>

  <ng-container *ngIf="upload_response.validation?.bucket.length > 1 || !upload_response.validation">
    <heading [icon]="Icons.Types.Details" [color]="Icons.Colors.Blue">
      <div class="left">
        <h4 class="heading-title">Multiple Bucket Upload</h4>
        <p class="heading-description">Please confirm your upload</p>
      </div>
      <app-basic-button [title]="'Cancel Upload'" [type]="buttonTypes.Primary" (click)="cancelUpload()"> </app-basic-button>
    </heading>

    <div class="wrap">
      <div class="order-summary" *ngIf="upload_response.validation">
        <div>
          <h6>Total Orders</h6>
          <h3>{{ upload_response?.validation.orders }}</h3>
        </div>
        <div>
          <h6>New Geo-codes</h6>
          <h3>{{ upload_response?.validation.not_geocoded }}</h3>
        </div>
        <div>
          <h6>Total Buckets</h6>
          <h3>{{ upload_response?.validation.bucket.length }}</h3>
        </div>
        <div>
          <h6>New Buckets</h6>
          <h3>{{ newBuckets }}</h3>
        </div>
      </div>
      <ng-container *ngIf="upload_response.validation">
        <div class="bucket-card" *ngFor="let bucket of upload_response?.validation.bucket">
          <app-grid-container [columns]="'repeat(4, 1fr)'" [colGap]="'2rem'" [padding]="'2rem 5rem'" [rowGap]="'2rem'">
            <div>
              <h6>Delivery Date</h6>
              <h3 *ngIf="bucket.delivery_date; else error">{{ bucket.delivery_date | date: 'EEE, MMMM d' }}</h3>
            </div>
            <div>
              <h6>Shift</h6>
              <h3 *ngIf="bucket.shift_start && bucket.shift_end; else error">
                {{ bucket.shift_start | slice: 0:-3 }} - {{ bucket.shift_end | slice: 0:-3 }}
              </h3>
            </div>
            <div>
              <h6>Warehouse</h6>
              <h3 *ngIf="bucket.warehouse_id; else error">{{ bucket.warehouse_name }}</h3>
            </div>
            <app-grid-container [columns]="'repeat(2, 1fr)'" [colGap]="'2rem'" [padding]="'0rem'" [rowGap]="'2rem'">
              <div>
                <h6>Orders</h6>
                <h3 *ngIf="bucket.orders; else error">{{ bucket.orders }}</h3>
              </div>
              <div>
                <h6>Bucket#</h6>
                <h3 *ngIf="bucket.bucket_id === -1">New</h3>
                <h3 *ngIf="bucket.bucket_id !== -1">{{ bucket.bucket_id }}</h3>
              </div>
            </app-grid-container>
          </app-grid-container>
        </div>
      </ng-container>

      <div class="action-bar">
        <h4 class="error" *ngIf="errorPresent">
          {{
            upload_response?.error_message
              ? upload_response.error_number === 2627
                ? 'Please make sure your Business References are unique, alternatively, you can remove the column to have these generated for you.'
                : upload_response?.error_message
              : 'Please make sure all upload information is correct and try again.'
          }}
        </h4>
        <h4 class="success" *ngIf="!errorPresent">No issues found.</h4>
        <app-basic-button
          [disabled]="errorPresent"
          [title]="'Process'"
          [type]="buttonTypes.Highlight"
          [active]="true"
          [processing]="processing"
          (click)="processCSV()"
        >
        </app-basic-button>
      </div>
    </div>
  </ng-container>

  <ng-template #error>
    <h3 class="missingDataError">Missing Data</h3>
  </ng-template>
</section>
