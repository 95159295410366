<div class="graph-container" *ngIf="data && data.length > 0; else noData">
  <ngx-charts-line-chart
    [autoScale]="false"
    [scheme]="colorScheme"
    [showGridLines]="true"
    [results]="data"
    [animations]="true"
    [xAxis]="true"
    [yAxis]="true"
    [showXAxisLabel]="true"
    [xAxisLabel]="'Scheduled Date'"
    [yAxisLabel]="'Status %'"
    [showYAxisLabel]="true"
    [legend]="true"
    (select)="onSelect($event)"
  >
  </ngx-charts-line-chart>
</div>
<ng-template #noData>
  <h3 *ngIf="noDataMessage">{{ noDataMessage }}</h3>
</ng-template>
