import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription, combineLatest } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationsService } from 'app/shared/notifications/notifications.service';
import { OperationsTrackingService } from 'app/operations-tracking/operations-tracking.service';
import { OrderFrontEndStateEnum } from 'app/dashboard/post-dispatch/post-dispatch.interfaces';
import { OpTrBusinesses, OperationsTrackingTableActionEnum, OrderStateEnum, OrderSummary } from 'app/operations-tracking/operations-tracking.interface';
import { OrderTrackingComponent } from '../order-tracking/order-tracking.component';
import { Store } from '@ngrx/store';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FleetAllocationEnum } from 'app/dashboard/new-trips/new-trips.interfaces';
import { ActingAs, Warehouse } from 'app/interfaces/auth.interfaces';
import { takeUntil } from 'rxjs/operators';
import { TeamDetails, WarehouseDetails } from 'app/admin/team-management/Interfaces/team-management';
import { selectorActingAs } from 'app/auth/auth.reducer';

@Component({
  selector: 'app-completed',
  templateUrl: './completed.component.html',
  styleUrls: ['./completed.component.scss'],
})
export class CompletedComponent extends OrderTrackingComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  completedSubscription: Subscription;
  dataSource = new MatTableDataSource<OrderSummary>([]);
  OrderStateEnum = OrderStateEnum;
  displayedColumns: string[] = this.isAdmin
    ? ['CustomerReference','BusinessName','WarehouseName','DriverNames','ScheduledDate', 'LastEventName', 'Timestamp', 'IsRoundTrip', 'FleetAllocation', 'AssignedTo','Actions']
    : ['CustomerReference','WarehouseName','DriverNames','ScheduledDate', 'LastEventName', 'Timestamp', 'IsRoundTrip', 'FleetAllocation', 'AssignedTo','Actions'];
  warehouseControl = new FormControl([]);
  businessControl = new FormControl([]);
  teamsControl = new FormControl([]);
  filteredWarehouses = this.warehouses;
  filteredBusiness = this.businesses;
  fleetControl = new FormControl(FleetAllocationEnum.Picup);
  fleets: string[] = ['All', 'All Picup','Picup', 'PicupPlus', 'Contractor'];
  driverNameControl = new FormControl('');
  customerReferenceControl = new FormControl('');
  ActionEnum = OperationsTrackingTableActionEnum;
  pageSize = 5;
  teams: TeamDetails[] = [];
  actingAs: ActingAs;

  constructor(
    public operationsTrackingService: OperationsTrackingService,
    public notificationsService: NotificationsService,
    public store: Store
  ) {
    super(notificationsService, operationsTrackingService, store);
    this.operationsTrackingService.usedIds$.pipe(takeUntil(this.unsubscribe$)).subscribe((ids: string[]) => {
      this.selectedIds = ids;
      this.searchCompletedOrders();
    });

    operationsTrackingService.teams$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(teams => {
        this.teams = teams;
      });
  }

  ngOnInit(): void {
    this.store.select(selectorActingAs).pipe(takeUntil(this.unsubscribe$)).subscribe((next) => {
      this.actingAs = next;
    });
    this.warehouseControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((selectedWarehouses: string[]) => {
        this.operationsTrackingService.usedIds = [...selectedWarehouses];
        this.storeWarehouseFilterPerBusiness()
      });

    this.businessControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((selectedBusinesses: string[]) => {
        this.operationsTrackingService.usedIds = [...selectedBusinesses];
        this.storeFilters();
      });

    this.teamsControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((teamNames: string[]) => {
        const warehouses = [];
        teamNames.forEach((teamName: string) => {
          const teamWarehouses = this.teams.find(value => value.teamName === teamName)?.warehouses ?? [];
          teamWarehouses.forEach((warehouse: WarehouseDetails) => {
            if(!warehouses.some(value => value === warehouse.id)){
              warehouses.push(warehouse.id)
            }
          })
        });
        this.warehouseControl.setValue(warehouses);
      })

    const filters = JSON.parse(localStorage.getItem('opsFilters'));
    this.pageSize = filters?.pageSize ?? 5;
    this.loadFilters();
    this.businessControl.setValue(filters?.selectedBusinesses ?? []);
    this.fleetControl.setValue(filters?.selectedFleet);
  }

  ngOnDestroy(): void {
    this.completedSubscription?.unsubscribe();
    this.warehouseSubscription?.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.paginator.page.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.storeFilters();
    })
    this.filterOrders()
  }

  searchCompletedOrders(): void {
    const batches = this.getFirestoreBatchesForOrders(OrderFrontEndStateEnum.Completed)
    this.completedSubscription?.unsubscribe();
    this.completedSubscription = combineLatest(batches).subscribe((ordersSummary:  OrderSummary[][]) => {
      let orders: OrderSummary[] = [];
      orders = orders.concat(...ordersSummary);
      this.dataSource.data = orders;
    });
  }

  filterOrders(): void{
    this.dataSource.filterPredicate = (data: OrderSummary, filterType: string) => {
      let fleetControlFilterPass = false;
      let filterPass = false;
      if(this.fleetControl.value === 'All Picup'){
        fleetControlFilterPass = data.FleetAllocation === 'Picup' || data.FleetAllocation === 'PicupPlus'
      }else if(this.fleetControl.value === ''){
        fleetControlFilterPass = true
      } else {
        fleetControlFilterPass = data.FleetAllocation === this.fleetControl.value
      }

      if(this.driverNameControl?.value.length > 1 && filterType){
        filterPass = data.DriverNames.findIndex((name: string) => name === this.driverNameControl?.value) >= 0
      } else if(this.customerReferenceControl?.value.length > 1){
        filterPass = data.CustomerReference === this.customerReferenceControl?.value
      } else {
        filterPass = true;
      }

      return filterPass && fleetControlFilterPass
    };

    this.dataSource.filter = 'Value';
    this.storeFilters();
  }

  resetFilters(): void {
    this.warehouseControl.setValue([]);
    this.fleetControl.setValue(FleetAllocationEnum.Picup);
    this.driverNameControl.setValue('');
    this.customerReferenceControl.setValue('');
  }

  filterWarehouse(searchTerm: string): void{
    if(searchTerm.length > 2 ){
      this.filteredWarehouses = this.warehouses.filter((warehouse: Warehouse) => {
        return warehouse?.name?.toLowerCase().includes(searchTerm.toLowerCase());
      })
    } else {
      this.filteredWarehouses = this.warehouses;
    }
  }

  filterBusinesses(searchTerm: string): void{
    if(searchTerm.length > 2 ){
      this.filteredBusiness = this.businesses.filter((business: OpTrBusinesses) => {
        return business?.name?.toLowerCase().includes(searchTerm.toLowerCase());
      })
    } else {
      this.filteredBusiness = this.businesses;
    }
  }

  trackById(_index: number, item: Warehouse): string{
    return item.id;
  }

  trackByTeamName(_index: number, item: TeamDetails): string {
    return item.teamName;
  }

  storeFilters(): void {
    const filtersGlobal = JSON.parse(localStorage.getItem('opsFilters'));
    const filterValues = {
      selectedBusinesses: this.businessControl.value,
      selectedFleet: this.fleetControl.value,
      selectedEvent: filtersGlobal?.selectedEvent,
      selectedOnDemand: filtersGlobal?.selectedOnDemand,
      selectedAssignedStatus: filtersGlobal?.selectedAssignedStatus,
      pageSize: this.paginator?.pageSize ?? this.pageSize
    }
    localStorage.setItem('opsFilters', JSON.stringify(filterValues));
  }

  storeWarehouseFilterPerBusiness(): void {
    const fValues = {
      selectedWarehouses: this.warehouseControl.value,
      selectedTeams: this.teamsControl.value,
    }
    localStorage.setItem(`opsFilters-${this.actingAs.id}`, JSON.stringify(fValues));
  }

  loadFilters(): void {
    const filtersGlobal = JSON.parse(localStorage.getItem('opsFilters'));
    this.pageSize = filtersGlobal?.pageSize ?? 5;
    this.fleetControl.setValue(filtersGlobal?.selectedFleet ?? '');

    if(this.isAdmin){
      this.businessControl.setValue(filtersGlobal?.selectedBusinesses ?? []);
    }

    if(!this.isAdmin){
      const filters = JSON.parse(localStorage.getItem(`opsFilters-${this.actingAs.id}`));
      this.teamsControl.setValue(filters?.selectedTeams ?? []);
      this.warehouseControl.setValue(filters?.selectedWarehouses ?? []);
    }
  }
}
