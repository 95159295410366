import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-static-site',
  templateUrl: './static-site.component.html',
  styleUrls: ['./static-site.component.scss']
})
export class StaticSiteComponent implements OnInit {

  constructor() {

  }

  ngOnInit(): void {
  }

}
