<div class="container-input">
  <ng-container *ngIf="addressTypeControl.value === null">
    <mat-form-field class="max-width">
      <!-- selector for type -->
      <label>{{ heading }}</label>
      <mat-select [formControl]="addressTypeControl" class="drop-down-style" [class.invalid]="!isValid">
        <mat-option value="warehouse" *ngIf="allowWarehouseSelect"> Warehouse </mat-option>
        <mat-option value="book" *ngIf="allowAddressBook"> Address Book </mat-option>
        <mat-option value="manual"> Manual Address </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <!-- warehouse addresses -->
  <ng-container *ngIf="addressTypeControl.value === 'warehouse'">
    <label class="selected-label"
      >Warehouse
      <button mat-icon-button>
        <mat-icon class="button-icon" (click)="addressTypeControl.setValue(null); clearSelection(type)">close</mat-icon>
      </button>
    </label>
    <mat-select [formControl]="selectedWarehouse" class="drop-down-style">
      <mat-option [value]="warehouse" *ngFor="let warehouse of available_warehouses">
        {{ warehouse.name }}
      </mat-option>
    </mat-select>
  </ng-container>

  <!-- AddressBook -->
  <ng-container *ngIf="addressTypeControl.value === 'book' || addressTypeControl.value === 'book'">
    <label class="selected-label"
      >Address Book
      <button mat-icon-button (click)="addressTypeControl.setValue(null)">
        <mat-icon class="button-icon">close</mat-icon>
      </button>
    </label>
    <mat-select [formControl]="selectedAddressBook">
      <mat-option [value]="warehouse" *ngFor="let warehouse of available_warehouses">
        {{ warehouse.name }}
      </mat-option>
    </mat-select>
  </ng-container>

  <!-- manual addresses -->
  <div [hidden]="!(addressTypeControl.value === 'manual')">
    <label class="selected-label"
      >Manual Address
      <button mat-icon-button>
        <mat-icon class="button-icon" (click)="addressTypeControl.setValue(null); clearSelection(type)">close</mat-icon>
      </button>
    </label>
    <form [formGroup]="manualInputFormGroup">
      <app-places-autocomplete
        class="max-width"
        [value]="manualPlaceholder"
        (placesResponse)="googleAddress($event)"
        [placeholder]="'Address'"
      >
      </app-places-autocomplete>
    </form>
  </div>
</div>
