import { Component, OnInit, Input } from '@angular/core';
import { GridJustifyItems } from 'app/shared/grid-container/grid-container.interfaces';

@Component({
  selector: 'app-waypoint-summary-block',
  templateUrl: './waypoint-summary-block.component.html',
  styleUrls: ['./waypoint-summary-block.component.scss'],
})
export class WaypointSummaryBlockComponent implements OnInit {
  @Input() waypoint;

  public GridJustifyItems = GridJustifyItems;

  constructor() {}

  ngOnInit() {}
}
