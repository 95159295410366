import { takeUntil } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/database';
import { TripsService } from 'app/dashboard/trips.service';
import { Store } from '@ngrx/store';
import { selectorActingAs } from 'app/auth/auth.reducer';
import axios from 'app/api/axios';
import { ActingAs } from 'app/interfaces/auth.interfaces';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  destroyed$: Subject<boolean> = new Subject<boolean>();

  picupWaypoints$ = new BehaviorSubject([]);
  private ENTERPRISE_WAYPOINTS_BASE_URL = '/enterprise-waypoints/';
  public waypointMarkers = new BehaviorSubject([]);
  public driverIds = new BehaviorSubject([]);
  public drivers = new BehaviorSubject([]);
  public vehicles = new BehaviorSubject([]);
  public polylines = new BehaviorSubject([]);
  public waypoints$ = new BehaviorSubject([]);
  actingAs: ActingAs;
  addDriverIdFirebaseSubscription;
  addVehicleIdFirebaseSubscription;

  constructor(private store: Store<any>, public db: AngularFireDatabase, public tripService: TripsService) {
    this.store.select(selectorActingAs).subscribe((next) => (this.actingAs = next));
  }

  addWaypointMarker(markers) {
    this.waypointMarkers.next(this.waypointMarkers.getValue().concat(markers));
  }

  addPolyline(polylines) {
    this.polylines.next(this.polylines.getValue().concat(polylines));
  }

  dumpExistingData() {
    this.waypointMarkers.next([]);
    this.driverIds.next([]);
    this.drivers.next([]);
    this.polylines.next([]);
    this.vehicles.next([]);
    if (this.addDriverIdFirebaseSubscription) {
      this.addDriverIdFirebaseSubscription.unsubscribe();
    }
    if (this.addVehicleIdFirebaseSubscription) {
      this.addVehicleIdFirebaseSubscription.unsubscribe();
    }
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  getAdvancedWaypoints(picup_id) {
    return axios({
      method: 'POST',
      url: '/trip-management/travel-data',
      data: {
        picup_id: picup_id,
        discrapancy_threshold_meters: '250',
        include_combined: false,
      },
    }).then((res) => {
      return res;
    });
  }

  getAreasGeoJson(areasArray) {
    return axios({
      method: 'POST',
      url: '/area/areas-geojson',
      data: areasArray,
    }).then((res) => {
      return res.data;
    });
  }
}
