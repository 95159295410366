export enum UiColors {
  White = 'white',
  Blue = 'blue',
  Green = 'green',
  Orange = 'orange',
  Red = 'red',
  Yellow = 'yellow',
  Grey1 = 'grey1',
  Grey2 = 'grey2',
  Grey3 = 'grey3',
  Grey4 = 'grey4',
  Grey5 = 'grey5',
  Grey6 = 'grey6',
  Grey7 = 'grey7',
  Grey8 = 'grey8',
  Grey9 = 'grey9',
}

export enum MapMarkerColors {
  Blue = '#27B7FC',
  Lightblue = '#B3E8FF',
  Green = '#02DE73',
  Yellow = '#F8E71C',
  Orange = '#FF7100',
  Red = '#F1453D',
}

export enum UiThemes {
  HighlightedSummary = 'theme_highlightedSummary',
  Dark = 'theme_dark',
  Light = 'theme_light',
  Blue = 'theme_blue',
  GreyLight = 'theme_grey_light',
  GreyDark = 'theme_grey_Dark',
  None = 'theme_none',
  Home = 'theme_home',
  Custom = 'theme_custom',
  FirstCompany = 'theme_firstcompany',
  SecondCompany = 'theme_secondcompany',
  CompanyBanner = 'theme_companybanner',
  DriverBanner = 'theme_driverbanner',
  Enterprise = 'theme_enterprise',
}

export enum UiSizes {
  Small = 'size_small',
  Medium = 'size_medium',
  Large = 'size_large',
}
