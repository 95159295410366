export interface FlexChildProps {
  alignSelf: string;
  flex: number;
  margin: string | SpacingProperties;
  padding: string | SpacingProperties;
}

export enum AlignSelf {
  Auto = 'auto',
  Normal = 'normal',
  Center = 'center',
  Start = 'start',
  End = 'end',
  SelfStart = 'self-start',
  SelfEnd = 'self-end',
  FlexStart = 'flex-start',
  FlexEnd = 'flex-end',
  Baseline = 'baseline',
  FirstBaseline = 'first baseline',
  LastBaseline = 'last baseline',
  Stretch = 'stretch',
  SafeCenter = 'safe center',
  UnsafeCenter = 'unsafe center',
  Inherit = 'inherit',
  Initial = 'initial',
  Unset = 'unset'
}

export interface SpacingProperties {
  bottom?: string;
  left?: string;
  right?: string;
  top?: string;
}
