<section>
  <heading 
    [icon]="IconTypes.Details"
    [color]="uiColours.Blue">
    <h4 class="heading-title">Generate Costing Bucket</h4>
  </heading>
  <app-grid-container
    [columns]="'repeat(4, 1fr)'"
    [padding]="'2rem'"
    [colGap]="'2rem'"
    [rowGap]="'2rem'"
    [theme]="UiThemes.Light"
  >
    <input-field
      (click)="openDatePickerModal()"
      [svgIcon]="'details.svg'"
      [type]="'text'"
      (valueChanged)="randomBucketDetails.delivery_date = $event; checkValid()"
      [value]="randomBucketDetails.delivery_date"
      [label]="'Delivery Date'"
      [labelTheme]="'dark'"
    >
    </input-field>
    <input-field
      [placeholder]="'Eg.  08:00'"
      [type]="'text'"
      (valueChanged)="randomBucketDetails.shift_start = $event; checkValid()"
      [value]="randomBucketDetails.shift_start"
      [label]="'Shift Start'"
      [labelTheme]="'dark'"
    >
    </input-field>
    <input-field
      [placeholder]="'Eg. 17:00'"
      [type]="'text'"
      (valueChanged)="randomBucketDetails.shift_end = $event; checkValid()"
      [value]="randomBucketDetails.shift_end"
      [label]="'Shift End'"
      [labelTheme]="'dark'"
    >
    </input-field>
    <app-dropdown
      [activeItem]="randomBucketDetails.warehouse_name"
      [dropdownTheme]="'light'"
      [labelTheme]="'dark'"
      [label]="'Dispatch Warehouse'"
    >
      <div dropdownRef>
        <a
          *ngFor="let warehouse of warehouses"
          class="dropdown-item"
          (click)="
            randomBucketDetails.warehouse_id = warehouse.id;
            randomBucketDetails.warehouse_name = warehouse.name;
            checkValid()
          "
          >{{ warehouse.name }}</a
        >
      </div>
    </app-dropdown>
  </app-grid-container>
  <app-grid-container
    [columns]="'repeat(4, 1fr)'"
    [padding]="'2rem'"
    [colGap]="'1em'"
    [rowGap]="'2rem'"
    [theme]="UiThemes.Light"
  >
    <app-places-autocomplete
      [label]="'Center of Radius'"
      [labelTheme]="'dark'"
      (placesResponse)="onPlacesChange($event); checkValid()"
      [value]="formattedAddress"
    >
    </app-places-autocomplete>
    <input-field
      [placeholder]="'15000'"
      [type]="'text'"
      (valueChanged)="randomBucketDetails.radius_in_meters = $event; checkValid()"
      [value]="randomBucketDetails.radius_in_meters"
      [label]="'Radius in Meters'"
      [labelTheme]="'dark'"
    >
    </input-field>
    <app-dropdown
      [activeItem]="randomBucketDetails.parcel_size"
      [dropdownTheme]="'light'"
      [labelTheme]="'dark'"
      [label]="'Parcel Size'"
    >
      <div dropdownRef>
        <a
          *ngFor="let parcelSize of parcelSizeArray"
          class="dropdown-item"
          (click)="randomBucketDetails.parcel_size = parcelSize; checkValid()"
          >{{ parcelSize }}</a
        >
      </div>
    </app-dropdown>
    <input-field
      [placeholder]="'300'"
      [type]="'text'"
      (valueChanged)="randomBucketDetails.number_of_orders = $event; checkValid()"
      [value]="randomBucketDetails.number_of_orders"
      [label]="'Number of Deliveries'"
      [labelTheme]="'dark'"
    >
    </input-field>
  </app-grid-container>
  <app-flex-container
    [justifyContent]="justifyContent.FlexEnd"
    [padding]="'2rem'"
    [borderBottom]="true"
    [theme]="UiThemes.Light"
  >
    <app-basic-button 
      [title]="'Cancel'" 
      [processing]="processing" 
      [type]="ButtonTypes.Primary" 
      (click)="cancel()">
    </app-basic-button>
    <app-basic-button
      [disabled]="!is_valid"
      [title]="'Create Bucket'"
      [processing]="processing"
      [active]="true"
      [type]="ButtonTypes.Highlight"
      (click)="createBucket()"
    >
    </app-basic-button>
  </app-flex-container>
</section>
