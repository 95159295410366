import { Component, OnDestroy } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { LastMile } from 'app/shared/shared.interfaces';
import { ActingAs, UserPermissions, Warehouse } from 'app/interfaces/auth.interfaces';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationsService } from 'app/shared/notifications/notifications.service';
import { OperationsTrackingService } from 'app/operations-tracking/operations-tracking.service';
import { LastMileStateEnum } from 'app/operations-tracking/operations-tracking.interface';
import { OrderTrackingComponent } from '../order-tracking/order-tracking.component';
import { Store } from '@ngrx/store';
import { NewTripsService } from 'app/dashboard/new-trips/new-trips.service';
import { LastMileFrontEndState } from 'app/dashboard/post-dispatch/post-dispatch.interfaces';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-active-routes',
  templateUrl: './active-routes.component.html',
  styleUrls: ['./active-routes.component.scss']
})
export class ActiveRoutesComponent extends OrderTrackingComponent implements OnDestroy {
  lastMileSubscription: Subscription;
  actingAs: ActingAs;
  businessWarehouses: Warehouse[];
  userPermissions: UserPermissions;
  dataSource = new MatTableDataSource<LastMile>([]);
  LastMileStateEnum = LastMileStateEnum;
  isAdminView: boolean = false;
  emptyWarehouse: Warehouse = {
    id: 'none',
    name: 'No warehouse',
    warehouse_id: null,
    warehouse_code: null,
    address: null,
    email: null,
    phone: null,
    latitude: 0,
    longitude: 0,
    suburb: null,
    postal_code: null,
    country: null,
    country_short_code: null,
    contact: {
      ClientAddress: null,
      Email: null,
      Instructions: null,
      Name: null,
      PhoneNumber: null
    },
    allow_business_consolidation: false,
    is_eligible_for_da_consolidation: false,
    default_special_instructions: null
  }

  constructor(
    public operationsTrackingService: OperationsTrackingService,
    public notificationsService: NotificationsService,
    public store: Store,
    public newTripsService: NewTripsService) {
    super(notificationsService, operationsTrackingService, store, newTripsService);
    this.operationsTrackingService.usedIds$.pipe(takeUntil(this.unsubscribe$)).subscribe((ids: string[]) => {
      this.selectedIds = ids;
      this.getActiveLastMiles();
    });
  }

  ngOnDestroy(): void {
    this.lastMileSubscription?.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getActiveLastMiles(): void {
    const batches = this.getFirestoreBatchesForLastMiles(LastMileFrontEndState.Active)
    this.lastMileSubscription?.unsubscribe();
    this.lastMileSubscription = combineLatest(batches).subscribe((res: LastMile[][]) => {
      let lastMiles: LastMile[] = [];
      lastMiles = lastMiles.concat(...res);
      this.dataSource.data = lastMiles;
    });
  }

  filterChanges(ids: string[]): void {
    this.selectedIds = ids;
    this.getActiveLastMiles();
  }
}
