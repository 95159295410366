import { Pipe, PipeTransform } from '@angular/core';
import { UiColors } from 'app/interfaces/ui.interfaces';

@Pipe({
  name: 'tripHealth'
})
export class TripHealthPipe implements PipeTransform {

  transform(orderState: string): UiColors {
    switch (orderState) {
      case 'None':
        return UiColors.Blue;
      case 'Scheduled':
        return UiColors.Blue;
      case 'Pending':
        return UiColors.Blue;
      case 'Assigned':
        return UiColors.Blue;
      case 'InProgress':
        return UiColors.Green;
      case 'Completed':
        return UiColors.Green;
      case 'Unassigned':
        return UiColors.Orange;
      case 'Cancelled':
        return UiColors.Red;
      case 'Alert':
        return UiColors.Red;
      case 'LotteryRunning':
        return UiColors.Yellow;
      case 'AttemptingDirectAssignment':
        return UiColors.Blue;
      default:
        return UiColors.Blue;
    }
  }
}
