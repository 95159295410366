import { Component, Input, OnChanges } from '@angular/core';
import { WaypointStatusConstant } from 'app/dashboard/new-trips/new-trips.constants';
import { WaypointStatusSettings } from 'app/dashboard/new-trips/new-trips.interfaces';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { ModalSizes } from 'app/shared/modals/modal.interfaces';

@Component({
  selector: 'app-last-mile-fs-waypoint',
  templateUrl: './last-mile-fs-waypoint.component.html',
  styleUrls: ['./last-mile-fs-waypoint.component.scss'],
})
export class LastMileFsWaypointComponent implements OnChanges {
  @Input() waypoint;
  @Input() Parcels;
  public IconTypes = IconTypes;
  public UiColors = UiColors;
  public ModalSizes = ModalSizes;
  public UiThemes = UiThemes;

  parcelArray;

  waypointStatus: WaypointStatusSettings = {
    icon: undefined,
    color: undefined,
    timestamp: undefined,
    label: undefined,
  };

  constructParcels(): void {
    this.parcelArray = [];
    this.waypoint.Contacts.forEach((contact) => {
      contact.Deliveries?.forEach((parcel) => {
        this.parcelArray.push({ ...this.Parcels[parcel], parcel_action: 'delivery' });
      });
    });
    this.waypoint.Contacts.forEach((contact) => {
      contact.Collections?.forEach((parcel) => {
        this.parcelArray.push({ ...this.Parcels[parcel.ParcelWaybill], parcel_action: 'collection' });
      });
    });
    this.waypoint.Contacts.forEach((contact) => {
      contact.Returns?.forEach((parcel) => {
        this.parcelArray.push({ ...this.Parcels[parcel.ParcelWaybill], parcel_action: 'return' });
      });
    });
    this.waypointStatus = this.setWaypointStatus();
  }

  setWaypointStatus(): WaypointStatusSettings {
    switch (this.waypoint?.VisitState) {
      case 'Finalized':
        if (
          (this.parcelArray.some(
            (parcel) => parcel.LastMileParcelState === 'Delivered' && parcel.parcel_action === 'delivery'
          ) &&
            this.parcelArray.some(
              (parcel) => parcel.LastMileParcelState === 'FailedDelivery' && parcel.parcel_action === 'delivery'
            )) ||
          (this.parcelArray.some(
            (parcel) => parcel.LastMileParcelState === 'Collected' && parcel.parcel_action === 'collection'
          ) &&
            this.parcelArray.some(
              (parcel) => parcel.LastMileParcelState === 'FailedCollection' && parcel.parcel_action === 'collection'
            )) ||
          (this.parcelArray.some(
            (parcel) => parcel.LastMileParcelState === 'Returned' && parcel.parcel_action === 'return'
          ) &&
            this.parcelArray.some(
              (parcel) => parcel.LastMileParcelState === 'FailedReturn' && parcel.parcel_action === 'return'
            ))
        ) {
          return WaypointStatusConstant.partial;
        } else if (
          (this.parcelArray.some(
            (parcel) => parcel.LastMileParcelState === 'FailedDelivery' && parcel.parcel_action === 'delivery'
          ) &&
            !this.parcelArray.some(
              (parcel) => parcel.LastMileParcelState === 'Delivered' && parcel.parcel_action === 'delivery'
            )) ||
          (this.parcelArray.some(
            (parcel) => parcel.LastMileParcelState === 'FailedCollection' && parcel.parcel_action === 'collection'
          ) &&
            !this.parcelArray.some(
              (parcel) => parcel.LastMileParcelState === 'Collected' && parcel.parcel_action === 'collection'
            )) ||
          (this.parcelArray.some(
            (parcel) => parcel.LastMileParcelState === 'FailedReturn' && parcel.parcel_action === 'return'
          ) &&
            !this.parcelArray.some(
              (parcel) => parcel.LastMileParcelState === 'Returned' && parcel.parcel_action === 'return'
            ))
        ) {
          return WaypointStatusConstant.failed;
        } else {
          return WaypointStatusConstant.complete;
        }
      case 'Pending':
        return WaypointStatusConstant.pending;
      case 'EnRoute':
        return WaypointStatusConstant.enRoute;
      case 'Visiting':
        return WaypointStatusConstant.visiting;
      case 'Dispatching':
        return WaypointStatusConstant.dispatching;
      case 'Cancelled':
        return WaypointStatusConstant.cancelled;
      default:
        return WaypointStatusConstant.pending;
    }
  }

  constructor() {}

  ngOnChanges(): void {
    this.constructParcels();
    // this.checkReconciled();
  }

  ngOnInit(): void {}
}
