<div class="full-page-split" [ngClass]="theme" [class.fullHeight]="fullHeight" [class.reverse]="odd">
  <div *ngIf="heroImgUrl" class="hero">
    <img [src]="heroImgUrl" alt="" />
  </div>
  <div class="title" *ngIf="title">
    <h5>{{ subtitle }}</h5>
    <h2>{{ title }}</h2>
    <svg-icon [applyClass]="true" [src]="'/assets/img/icons/' + IconTypes.UnderlineTitle + '.svg'"></svg-icon>
  </div>
  <ng-content></ng-content>
</div>
