import { ButtonTypes } from './../buttons/basic-button.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { ConfirmModalComponent } from '../modals/confirm-modal.component';
import { UploadOrdersService } from '../../dashboard/upload-orders/upload-orders.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectorActingAs } from 'app/auth/auth.reducer';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-upload-button',
  templateUrl: './upload-button.component.html',
  styleUrls: ['./upload-button.component.scss'],
})
export class UploadButtonComponent {
  @ViewChild('file') file;
  // list of business to show edit consignment for - Currently the 5 waltons business_ids
  editConsignmentBusinesses = [
    'business-d2e7041a-8b02-4ff8-a888-7a6ff824c07c',
    'business-94bd2b8b-870c-4fd2-be9b-911325239b44',
    'business-6f1fd375-0659-4aea-b933-86e8276f464d',
    'business-4a120d71-3e8f-4d8c-b4ae-9f8747e5333b',
    'business-177a3104-5aea-4594-b772-5a4f1d8492db',
  ];
  user;
  processing: boolean;
  showEditConsignment = false;
  showGenerateRandomBucket = true;
  actingAs;
  environment = environment;
  ButtonTypes = ButtonTypes;

  constructor(
    public uploadOrdersService: UploadOrdersService,
    public simpleModalService: SimpleModalService,
    public router: Router,
    public store: Store<any>
  ) {
    this.user = this.uploadOrdersService.user;
    this.store.select(selectorActingAs).subscribe((next) => {
      this.actingAs = next;
      if (this.editConsignmentBusinesses.includes(this.actingAs.id)) {
        this.showEditConsignment = true;
      } else {
        this.showEditConsignment = false;
      }
      if (this.user.is_admin && environment.envName !== 'beta' && environment.envName !== 'Production') {
        this.showGenerateRandomBucket = true;
      } else {
        this.showGenerateRandomBucket = false;
      }
    });
  }

  uploadCSV() {
    this.file.nativeElement.click();
  }
  onFileAdded() {
    this.processing = true;
    const file: { [key: string]: File } = this.file.nativeElement.files[0];
    this.uploadOrdersService.uploadCSV(file).catch(() => {
      this.processing = false;
    });
  }

  createConsignment() {
    this.uploadOrdersService.createConsignment();
  }

  editConsignment() {
    this.uploadOrdersService.editConsignment();
  }

  generateRandomBucket() {
    this.uploadOrdersService.goToGenerateRandomBucket();
  }
}
