import { Injectable, inject } from '@angular/core';
import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationsService } from 'app/shared/notifications/notifications.service';
import { ErrorService, ErrorTypes } from './error.service';

export interface ErrorResponse extends HttpErrorResponse {
  Type: string;
  Status: number;
  Title: string;
  Detail: string;
  Instance: string;
  TicketNumber: string;
  ValidationErrors: [{
    ErrorMessage: string;
    PropertyName: string;
  }],
  Errors: [{
    Code: string;
    Message: string;
  }]
}
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private readonly notificationService = inject(NotificationsService);
  private readonly errorService = inject(ErrorService);

  private isErrorResponse(error: HttpErrorResponse): error is ErrorResponse {
    return (error as ErrorResponse).Title !== undefined;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<any> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          return next.handle(request);
        }
        if (error && this.isErrorResponse(error.error)) {
          if (ErrorTypes[error.error.Errors[0].Code] >= 0) {
            this.errorService.handleSpecialError(error?.error, error.error.Errors[0].Code)
            return of(error);
          }
          const { Title, Detail } = (error?.error as ErrorResponse);
          this.notificationService.publish({
            message: `${Title}:\n${Detail}`,
            type: 'error',
          });

        } else if(error?.error?.message?.length > 0) {
          this.notificationService.publish({
            message: `${error.error?.message}`,
            type: 'error',
          });
        } else {
          this.notificationService.publish({
            message: `An error occurred. Please try again later${error?.error?.TicketNumber ? ' or contact support with ticket number: ' + error?.error?.TicketNumber : '!'}`,
            type: 'error',
          });
        }

        return throwError(error);
      }),
    );
  }
}
