import { Component, OnInit } from '@angular/core';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';

@Component({
  selector: 'app-visual-reports',
  templateUrl: './visual-reports.component.html',
  styleUrls: ['./visual-reports.component.scss'],
})
export class VisualReportsComponent implements OnInit {
  UiThemes = UiThemes;
  public IconTypes = IconTypes;
  public UiColors = UiColors;

  constructor() {}

  ngOnInit(): void {}
}
