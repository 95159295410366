import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { JustifyContent } from 'app/shared/flex-container/flex-container.interfaces';
import { ModalSizes, ModalAction } from 'app/shared/modals/modal.interfaces';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-reconcile-warehouse-modal',
  templateUrl: './reconcile-warehouse-modal.component.html',
  styleUrls: ['./reconcile-warehouse-modal.component.scss'],
})
export class ReconcileWarehouseModalComponent extends SimpleModalComponent<any, any> implements OnInit {
  public IconTypes = IconTypes;
  public uiColors = UiColors;
  public UiThemes = UiThemes;
  public justifyContent = JustifyContent;
  public ModalSizes = ModalSizes;

  public actions: ModalAction[] = [
    { name: 'cancel', title: 'Cancel' },
    { name: 'confirm', title: 'Confirm', isPrimary: true },
  ];
  route_type;
  Parcels;
  filteredParcels: any[] = [];
  reconcile_reason;
  all_result;
  displayInvalid = false;
  resulting_parcels = [];
  warehouse_options = ['Collect', 'Deliver', 'FailDelivery', 'Return', 'FailFinalCollection'];
  businessReferences: string[] = [];
  references: FormControl = new FormControl();
  constructor() {
    super();
  }

  filterParcels(): void {
    this.references.valueChanges.subscribe((value: string[]) => {
      if (value.length === 0) {
        this.filteredParcels = [...this.Parcels];
        return;
      }
      let parcels = [];
      value.forEach((val) => {
        parcels = [...parcels, ...this.Parcels.filter((x) => x.BusinessReference === val)];
      });
      this.filteredParcels = parcels;
    });
  }

  ngOnInit(): void {
    if (this.route_type === 'return') {
      this.warehouse_options = ['Return'];
    }
    this.filteredParcels = [...this.Parcels];
    this.businessReferences = this.getUniqueBusinessReferences();
    this.filterParcels();
  }

  getUniqueBusinessReferences(): string[] {
    const businessReferences: string[] = [];
    this.Parcels.forEach((x) => {
      const index = businessReferences.findIndex((y) => y === x.BusinessReference);
      if (index === -1) {
        businessReferences.push(x.BusinessReference);
      }
    });
    return businessReferences;
  }

  handleAction($event: string): void {
    switch ($event) {
      case 'cancel':
        this.close();
        break;
      case 'confirm':
        if (!this.reconcile_reason) {
          this.displayInvalid = true;
          return;
        }
        this.filteredParcels.forEach((parcel) => {
          if (parcel.result !== undefined) {
            this.resulting_parcels.push({
              parcel_waybill: parcel.ParcelWaybill,
              result: parcel.result,
              failure_reason: parcel.failure_reason,
            });
          }
        });
        this.result = { reconcile_parcels: this.resulting_parcels, reconcile_reason: this.reconcile_reason };
        this.close();
        break;
    }
  }

  setAll(result: string): void {
    this.all_result = result;
    this.filteredParcels.forEach((parcel) => {
      if (
        parcel.parcel_state !== 'Delivered' &&
        parcel.parcel_state !== 'Returned' &&
        parcel.parcel_state !== 'FinalCollectionAttemptFailed' &&
        parcel.LastMileParcelState !== 'Delivered' &&
        parcel.LastMileParcelState !== 'Returned' &&
        parcel.LastMileParcelState!== 'FinalCollectionAttemptFailed'
      ) {
        parcel['result'] = result;
      }
    });
  }
}
