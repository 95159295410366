import { StoreAction } from 'app/interfaces/auth.interfaces';
import * as Actions from './new-trips.actions';

export const initialState = {
  activeTripId: null,
  activeTripWarehouseId: null,
  activeTripDetails: null,
};

export const selectorActiveTripId = (state) => state.trips.manageTrips.activeTripId;
export const selectorActiveTripWarehouseId = (state) => state.trips.manageTrips.activeTripWarehouseId;
export const selectorActiveTripDetails = (state) => state.trips.manageTrips.activeTripDetails;

export function NewTripsReducer(state = initialState, action: StoreAction) {
  switch (action.type) {
    case Actions.SET_ACTIVE_TRIP_ID:
      return {
        ...state,
        activeTripId: action.payload,
      };
    case Actions.SET_ACTIVE_TRIP_WAREHOUSE_ID:
      return {
        ...state,
        activeTripWarehouseId: action.payload,
      };
    case Actions.SET_ACTIVE_TRIP_DETAILS:
      return {
        ...state,
        activeTripDetails: action.payload,
      };
    case Actions.CLEAR_ACTIVE_TRIP_STORE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
