import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TableAction } from 'app/shared/table/table.interfaces';
import { DispatchAlertsComponent } from '../tables/dispatch-alerts/dispatch-alerts.component';
import { DispatchCompletedComponent } from '../tables/dispatch-completed/dispatch-completed.component';
import { DispatchFailedComponent } from '../tables/dispatch-failed/dispatch-failed.component';
import { DispatchLotteriesComponent } from '../tables/dispatch-lotteries/dispatch-lotteries.component';
import { DispatchManualComponent } from '../tables/dispatch-manual/dispatch-manual.component';
import { DispatchScheduledComponent } from '../tables/dispatch-scheduled/dispatch-scheduled.component';

@Component({
  selector: 'app-post-dispatch-dispatch',
  templateUrl: './post-dispatch-dispatch.component.html',
  styleUrls: ['./post-dispatch-dispatch.component.scss']
})
export class PostDispatchDispatchComponent implements OnInit {
  @Input() postDispatchTablePrimaryActions: TableAction[] = [];
  @Input() activeDriverPrimaryActions: TableAction[] = [];
  @Input() warehouseFilter: any;

  @ViewChild('alerts') alerts: DispatchAlertsComponent
  @ViewChild('completed') completed: DispatchCompletedComponent
  @ViewChild('failed') failed: DispatchFailedComponent
  @ViewChild('lotteries') lotteries: DispatchLotteriesComponent
  @ViewChild('manual') manual: DispatchManualComponent
  @ViewChild('scheduled') scheduled: DispatchScheduledComponent

  constructor() { }

  ngOnInit(): void {
  }

  refreshData(): void {
    this.alerts.getData();
    this.completed.getData();
    this.failed.getData();
    this.lotteries.getData();
    this.manual.getData();
    this.scheduled.getData();
  }

}
