<app-section *ngIf="loaded">
  <heading [color]="UiColors.Blue" [icon]="IconTypes.Courier">
    <h4 class="heading-title">Assigned Orders</h4>
    <div class="button-wrap" style="align-items: center">
      <p class="trip-selected-summary">
        <strong>
          <span *ngIf="orders !== undefined">{{ orders.selected }}</span>
          <i *ngIf="orders === undefined" class="status-in-progress fa fa-spinner fa-spin"></i>
          <span> orders selected for Couriers.</span>
        </strong>
        <br />
        <strong *ngIf="orders !== undefined">{{ orders.unassigned.length }}</strong>
        <i *ngIf="orders === undefined" class="status-in-progress fa fa-spinner fa-spin"></i>
        <span> unselected orders.</span>
      </p>
      <app-basic-button
        [title]="'Auto-Assign'"
        [type]="buttonTypes.Primary"
        (click)="autoAssign()"
        [disabled]="orders.selected > 0 || readOnly"
        *ngIf="!user_permissions?.modules?.includes('user')"
      >
      </app-basic-button>
      <app-basic-button
        [title]="'Reset'"
        [type]="buttonTypes.Primary"
        [disabled]="orders.selected === 0 || readOnly"
        (click)="resetOrderTables()"
        *ngIf="!user_permissions?.modules?.includes('user')"
      >
      </app-basic-button>
      <!-- TODO Test orders.unassigned.length -->
      <app-basic-button
        [title]="saveButtonLabel"
        [type]="buttonTypes.Primary"
        [processing]="savingOrdersInProgress"
        [disabled]="ordersSaved || orders.unassigned.length > 0 || readOnly"
        (click)="saveOrders()"
        *ngIf="!user_permissions?.modules?.includes('user')"
      >
      </app-basic-button>
      <app-basic-button
        [title]="'Save and Proceed'"
        [type]="buttonTypes.Primary"
        [processing]="finaliseOrdersInProgress"
        [disabled]="orders.unassigned.length > 0 || readOnly"
        (click)="finaliseOrders()"
      >
      </app-basic-button>
    </div>
  </heading>
</app-section>

<app-section *ngIf="loaded">
  <heading [icon]="IconTypes.Trip" [color]="UiColors.Yellow">
    <h4 class="heading-title">
      <strong *ngIf="ordersLoaded">{{ orders.unassigned.length }}</strong>
      <i *ngIf="!ordersLoaded" class="status-in-progress fa fa-spinner fa-spin"></i>
      <span> Unassigned Orders</span>
    </h4>
  </heading>
  <app-table
    *ngIf="orders"
    [tableData]="orders.unassigned"
    [columns]="ordersTableColumns"
    [bulkActions]="ordersBulkActions.unassigned"
    [isLoading]="!ordersLoaded"
    [rowsSelectable]="true"
    [zeroDataMessage]="'Congratulations! All orders have been assigned.'"
    (actionTaken)="handleTableAction($event, 'unassigned')"
    [showAnnotations]="true"
    [readOnly]="readOnly"
  >
  </app-table>
</app-section>
<app-section *ngIf="loaded && orders.ignored.length">
  <heading [icon]="IconTypes.Trip" [color]="UiColors.Red">
    <h4 class="heading-title">
      <strong *ngIf="ordersLoaded">{{ orders.ignored.length }}</strong>
      <i *ngIf="!ordersLoaded" class="status-in-progress fa fa-spinner fa-spin"></i>
      <span> Ignored Orders</span>
    </h4>
  </heading>
  <app-table
    *ngIf="orders"
    [tableData]="orders.ignored"
    [columns]="ordersTableColumns"
    [bulkActions]="ordersBulkActions.ignored"
    [isLoading]="!ordersLoaded"
    [rowsSelectable]="true"
    [zeroDataMessage]="'Congratulations! All orders have been assigned.'"
    (actionTaken)="handleTableAction($event, 'ignored')"
    [showAnnotations]="true"
    [readOnly]="readOnly"
  >
  </app-table>
</app-section>

<div *ngFor="let courier of couriers">
  <app-section *ngFor="let service_type of courier.service_types">
    <heading [icon]="IconTypes.Courier" [color]="UiColors.Green">
      <h4 class="heading-title">
        <strong
          *ngIf="
            orders !== undefined && orders[courier.courier_id + ' - ' + service_type.id.toLowerCase()] !== undefined
          "
          >{{ orders[courier.courier_id + ' - ' + service_type.id.toLowerCase()].length }}</strong
        >
        <i
          *ngIf="
            orders === undefined && orders[courier.courier_id + ' - ' + service_type.id.toLowerCase()] === undefined
          "
          class="status-in-progress fa fa-spinner fa-spin"
        ></i>
        <span> {{ courier.name + ' - ' + tableNameMap.get(service_type.id.toLowerCase() | lowercase) }} Orders</span>
      </h4>
    </heading>
    <app-table
      *ngIf="orders[courier.courier_id + ' - ' + service_type.id.toLowerCase()]"
      [tableData]="orders[courier.courier_id + ' - ' + service_type.id.toLowerCase()]"
      [columns]="ordersTableColumns"
      [rowsSelectable]="!user_permissions?.modules?.includes('user')"
      [bulkActions]="ordersBulkActions[courier.courier_id + ' - ' + service_type.id.toLowerCase()]"
      [zeroDataMessage]="'This courier has not had any orders assigned yet.'"
      (actionTaken)="handleTableAction($event, courier.courier_id + ' - ' + service_type.id.toLowerCase())"
      [showAnnotations]="true"
      [readOnly]="readOnly"
    >
    </app-table>
  </app-section>
</div>
