<div class="menu-dropdown-wrap">
  <div class="menu-title" [ngClass]="{ isOpen: isOpen }" (click)="toggleDropDown()">
    <app-icon
      *ngIf="iconType"
      [icon]="iconType"
      [size]="iconSize"
      [color]="iconColor"
      [showHover]="true"
      [isActive]="isOpen"
    ></app-icon>
    <span [ngClass]="{ processing: processing, disabled: processing }" *ngIf="label">{{ label }}</span>
  </div>
  <div
    *ngIf="isOpen"
    class="menu-dropdown"
    [ngStyle]="{ 'width.rem': dropDownWidthRem, 'top.rem': iconSize * 1.5 || 2 }"
  >
    <ul>
      <ng-template ngFor let-item [ngForOf]="items">
        <li
          *ngIf="!item.hidden"
          class="menu-item"
          [ngClass]="{ disabled: item.disabled || disabled }"
          (click)="handleItemClick(item.name, item.disabled)"
        >
          {{ item.displayName }}
        </li>
      </ng-template>
    </ul>
  </div>
</div>
