<app-loader *ngIf="loading; else showData" [loadingMessage]="'Loading Timeline...'"></app-loader>
<ng-template #showData>
  <app-section>
    <heading [icon]="IconTypes.Details" [color]="UiColors.Blue">
      {{ 'Courier Timeline' | uppercase }}
    </heading>
    <app-table
      [columns]="timelineCourierColumns"
      [dataMap]="timelineCourierDataMap"
      [rawData]="timelineCourierRawData"
      [rowsSelectable]="false"
      [zeroDataMessage]="'No events received from couriers'"
    >
    </app-table>
  </app-section>
</ng-template>

