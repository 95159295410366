<div class="sidebar">
  <div>
    <div class="sidebar-logo">
      <a routerLink="/dashboard/" class="text-center">
        <img src="./assets/img/picup-logo.svg" alt="Picup Logo">
        <h6 class="mt-2 text-white">ENTERPRISE</h6>
      </a>
    </div>

    <div class="sidebar-action">
      <a routerLink="/dashboard/upload-orders">
        <!-- <i class="fa fa-arrow-right"></i> -->
        <i class="fa fa-upload"></i>Upload Orders
      </a>
    </div>

    <div class="sidebar-menu">
      <a routerLink="/dashboard/geocoding">
        <i class="fa fa-globe"></i> Geocoding</a>
      <a routerLink="/dashboard/manage-business">
        <i class="fa fa-book"></i>Manage Business</a>
      <a routerLink="/dashboard/profile">
        <i class="fa fa-user"></i>Account</a>
      <a routerLink="/dashboard/credits">
        <i class="fa fa-bar-chart"></i>Reporting</a>
      <a routerLink="/dashboard/credits">
        <i class="fa fa-industry"></i>Hubs</a>
      <a routerLink="/dashboard/credits">
        <i class="fa fa-plus"></i>Add Driver</a>
      <a routerLink="/dashboard/credits">
        <i class="fa fa-plug"></i>Courier Integrations</a>
      <a routerLink="/dashboard/credits">
        <i class="fa fa-address-book"></i>Accounting</a>
      <a routerLink="/dashboard/credits">
        <i class="fa fa-search"></i>Analytics</a>
    </div>
  </div>

  <div>
    <div class="sidebar-menu bottom-menu">
      <a (click)="onLogout();">
        <i class="fa fa-sign-out"></i>Log Out</a>
    </div>
  </div>
</div>