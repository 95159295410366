<app-flex-container *ngIf="activeBucket" [padding]="'0 0 2rem 0'" [justifyContent]="JustifyContent.SpaceBetween">
  <div class="check">
    <h4 class="warning" *ngIf="unverifiedWarning !== undefined && unverifiedWarning">
      Warning: You have unverified orders.
    </h4>
    <h4 class="green" *ngIf="unverifiedWarning !== undefined && !unverifiedWarning">
      Success: All orders are ready to go.
    </h4>
    <h4 *ngIf="unverifiedWarning !== undefined && !unverifiedWarning && activeBucket.flagged_orders > 0">
      {{ activeBucket.flagged_orders }} orders flagged.
    </h4>
  </div>
  <div class="controls">
    <app-basic-button class="control-button" 
      [title]="'Consign'" 
      [type]="buttonTypes.Primary" 
      (click)="consign()"
      [disabled]="readOnly">
    </app-basic-button>
    <app-basic-button
      *ngIf="actingAs.package === 'tms' || user.is_admin"
      class="control-button"
      [title]="'Vet All'"
      [type]="buttonTypes.Primary"
      (click)="vetAll()"
      [disabled]="readOnly"
    >
    </app-basic-button>
    <app-basic-button
      class="control-button"
      [title]="'Add Order'"
      [type]="buttonTypes.Primary"
      (click)="respondToEditOrder(null)"
      [disabled]="readOnly"
    >
    </app-basic-button>
    <app-basic-button
      *ngIf="user.is_admin"
      class="control-button"
      [title]="'CrossDock'"
      [processing]="loadingGeoJson"
      [type]="buttonTypes.Primary"
      (click)="openCrossdockModal()"
      [disabled]="readOnly"
    >
    </app-basic-button>
    <app-basic-button
      class="control-button"
      [title]="!tableToggle ? 'Toggle Map' : 'Toggle Table'"
      [type]="buttonTypes.Primary"
      (click)="respondToTableToggle()"
    >
    </app-basic-button>
    <app-basic-button
      [disabled]="!unverifiedWarning"
      class="control-button"
      [title]="'Geocode Bucket'"
      [type]="buttonTypes.Highlight"
      (click)="geocodeBucket()"
      [active]="true"
      [disabled]="readOnly"
    >
    </app-basic-button>
  </div>
</app-flex-container>

<!-- TODO: Wire up failure >>>> -->

<app-verify-map
  #verifyMap
  *ngIf="tableToggle"
  [bucketID]="bucketId.toString()"
  (orderFlagged)="respondToFlag($event)"
  (geocodeRequested)="respondToGeocode($event)"
  (toggleTableRequested)="respondToTableToggle()"
  (changeBucketRequested)="respondToMapChangeBucket($event)"
  [geoJson]="geoJson"
  (updateGeoJson)="getGeoJson()"
  (editOrderRequested)="respondToEditOrder($event)"
  (removeOrderRequested)="respondToRemoveOrder($event)"
  [update]="updateMap"
>
</app-verify-map>

<app-verify-table
  [hidden]="tableToggle"
  [readOnly]="readOnly"
  *ngIf="tableFirstLoad"
  (orderFlagged)="respondToFlag($event)"
  (geocodeRequested)="respondToGeocode($event)"
  (changeBucketRequested)="respondToChangeBucket($event)"
  (editOrderRequested)="respondToEditOrder($event)"
  (removeOrderRequested)="respondToRemoveOrder($event)"
  (restageOrderRequested)="respondToRestageOrder($event)"
  [update]="updateTable"
  (manualRoutingRequested)="respondToManualRouting($event)"
>
</app-verify-table>
