import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import axios from 'app/api/axios';
import {
  TableColumn,
  TableRow,
  TableDataMapFormatter,
  TableDataMap,
} from '../../../../../shared/table/table.interfaces';
import { mapDataToTable } from '../../../../../shared/table/table.helpers';
import { MapTypes } from '../../../../../shared/map/map.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';

export const enum ViewTripTableColumns {
  id = 'id',
  customerName = 'customerName',
  customerPhone = 'customerPhone',
  address = 'address',
  businessRef = 'businessRef',
}

interface TripOrder {
  business_reference: string;
  client_address: string;
  customer_name: string;
  customer_phone: string;
  delivery_date: string;
  geocoded_address: string;
  is_incorrectly_geocoded: string | null;
  is_removed_from_trip: string | null;
  latitude: number;
  longitude: number;
  sequence: number;
  special_instructions: string | null;
  vehicle: string;
}

export const mfJoinFields: TableDataMapFormatter = (map: (string | number)[], item: any) => {
  return map
    .filter((key) => item[key] !== undefined && item[key] !== null)
    .map((key) => item[key])
    .join(', ');
};

@Component({
  selector: 'app-view-trip',
  templateUrl: './view-trip.component.html',
  styleUrls: ['./view-trip.component.scss'],
})
export class ViewTripComponent implements OnInit {
  @Input() tripId: number;
  @Input() bucketId: number;
  @Input() readOnly = false;
  @Output() closeTripView = new EventEmitter<boolean>();

  public MapTypes = MapTypes;

  showMap = false;
  tripOrders: TableRow[] = [];
  singleTripGeoJson: any; // TODO Create interface
  // filterArgs = 'trip_id';
  // filterReverse = false;
  buttonTypes = ButtonTypes;
  uiColours = UiColors;
  icontype = IconTypes;

  viewTripTableDataMap: TableDataMap = {
    cells: {
      [ViewTripTableColumns.id]: {
        column: ViewTripTableColumns.id,
        map: 'sequence',
      },
      [ViewTripTableColumns.customerName]: {
        column: ViewTripTableColumns.customerName,
        map: 'customer_name',
      },
      // [ViewTripTableColumns.customerPhone]: {
      //   column: ViewTripTableColumns.customerPhone,
      //   map: 'customer_phone',
      // },
      [ViewTripTableColumns.businessRef]: {
        column: ViewTripTableColumns.businessRef,
        map: 'business_reference',
        displayPipeArgs: ['none'],
      },
      [ViewTripTableColumns.address]: {
        column: ViewTripTableColumns.address,
        map: 'geocoded_address',
        annotation_map: ['client_address'],
      },
    },
  };

  tripDetailsTableColumns: TableColumn[] = [
    { name: ViewTripTableColumns.id, displayName: '#', sortable: true },
    { name: ViewTripTableColumns.customerName, displayName: 'Customer Name', sortable: true },
    // { name: ViewTripTableColumns.customerPhone, displayName: 'Customer Phone', sortable: true },
    { name: ViewTripTableColumns.address, displayName: 'Address', sortable: true },
    { name: ViewTripTableColumns.businessRef, displayName: 'Business Reference', sortable: true },
  ];

  toggleMap() {
    this.showMap = !this.showMap;
  }

  ngOnInit() {
    this.getGeoJson(this.tripId);
    this.getTripOrders(this.tripId);
  }

  actionCloseTripView() {
    this.closeTripView.emit(true);
  }

  getGeoJson(trip_id) {
    axios
      .get(`/bucket/${this.bucketId}/geojson/${trip_id}`)
      .then((response) => (this.singleTripGeoJson = response?.data));
  }

  getTripOrders(trip_id) {
    axios
      .get(`/bucket/${this.bucketId}/trip-orders/${trip_id}`)
      .then(
        (response) =>
          (this.tripOrders = mapDataToTable(response?.data, this.viewTripTableDataMap, this.tripDetailsTableColumns))
      );
  }

  // ACTIONS REMOVED FROM VIEW TRIP FOR NOW
  // removeOrder(businessReference, is_removed, reason) {
  //   const data = {
  //     business_reference: businessReference,
  //     is_removed,
  //     reason
  //   };
  //
  //   axios.post(`/bucket/${this.bucket.bucket_id}/toggle-removed/`, data)
  //     .then(() => this.getTripOrders(this.selectedTripId));
  // }
}
