import {Component, Input, OnChanges} from '@angular/core';
import {IconTypes} from '../icon/icon.interfaces';
import {UiColors} from '../../interfaces/ui.interfaces';
import {IconWithTextProps} from './icon-with-text.interfaces';
import {setColorClass} from '../helpers';

@Component({
  selector: 'app-icon-with-text',
  templateUrl: './icon-with-text.component.html',
  styleUrls: ['./icon-with-text.component.scss']
})
export class IconWithTextComponent implements IconWithTextProps, OnChanges {
  @Input() icon: IconTypes;
  @Input() iconColor: UiColors;
  @Input() iconSizeRem: number;
  @Input() iconSpacingRem: number;
  @Input() iconOnRight = false;
  @Input() text: string;
  @Input() textBold: boolean;
  @Input() color: UiColors;
  @Input() sizeRem: number;
  @Input() uppercase = false;
  @Input() paddingRem;

  classes: string;

  ngOnChanges() {
    this.classes = setColorClass(this.color) + (this.uppercase ? ' uppercase' : '');
  }
}
