import { Component, OnInit, Input, AfterViewInit } from '@angular/core';

export interface ProgressBarGraphData {
  name: string;
  value: number;
  color?: string;
}

@Component({
  selector: 'app-progress-bar-graph',
  templateUrl: './progress-bar-graph.component.html',
  styleUrls: ['./progress-bar-graph.component.scss']
})
export class ProgressBarGraphComponent implements OnInit {
  @Input() results: ProgressBarGraphData[] = [];
  @Input() title: string;
  @Input() showLegend: boolean = true;
  total: number = 0;
  constructor() { }

  ngOnInit(): void {
    this.total = this.results.reduce((a, b) => {
      return a + b.value
    }, 0);
    this.results.forEach((x) => {
      if (!x.color) {
        x.color = this.generateRandomColour();
      }
    });
  }

  generateRandomColour(): string {
    const n = (Math.random() * 0xfffff * 1000000).toString(16);
    return '#' + n.slice(0, 6);
  }

}
