// <reference path="../../../../node_modules/@types/googlemaps/index.d.ts" />
import { Component, EventEmitter, Input, Output, OnDestroy, SimpleChanges, OnChanges, ViewChild, OnInit } from '@angular/core';
import { MapDrawer, MapTypes } from './map.interfaces';
import { MapService } from './map.service';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { Store } from '@ngrx/store';
import { selectorActingAs, selectorUser } from 'app/auth/auth.reducer';
import { darkMapStyle } from './map.constants';
import { ButtonTypes } from '../buttons/basic-button.component';
import { GoogleMap } from '@angular/google-maps';
import { styleFunc } from './map.style-functions';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnDestroy, OnChanges, OnInit {
  map: google.maps.LatLngLiteral = {
    lat: -33.9765176,
    lng: 18.4654239,
  };

  gMapOptions: google.maps.MapOptions = {
    styles: darkMapStyle,
    center: this.map,
    zoomControl: false,
    scrollwheel: true,
    streetViewControl: false,
    clickableIcons: true,
    disableDefaultUI: true,
    mapTypeControl: false
  };
  mapElement;
  dataLayerProcessing;
  filters: any = ['VETTED', 'SKIPPED', 'UNVERIFIED'];
  fleet_filters = ['DRIVERS', 'VEHICLES'];
  mapReady = false;
  bounds;
  driversPresent = false;
  vehiclesPresent = false;
  waypointsPresent = false;
  UiColors = UiColors;
  polylinesArray = [];
  polylineObject;
  buttonTypes = ButtonTypes;

  labelOptions = {
    color: 'white',
    fontFamily: 'Open Sans',
    fontSize: '100px',
    fontWeight: '700',
  };

  vehicleTypes = new Map<string, string>([
    ['vehicle-motorcycle', '/assets/img/vehicles/vehicle-large-van-blue.svg'],
    ['vehicle-car', '/assets/img/vehicles/vehicle-large-van-blue.svg'],
    ['vehicle-small-van', '/assets/img/vehicles/vehicle-large-van-blue.svg'],
    ['vehicle-large-van', '/assets/img/vehicles/vehicle-large-van-blue.svg'],
  ]);

  public MapTypes = MapTypes;

  @Input() geoJson: any;
  @Input() picupId;
  waypoints$;
  boundsSet = false;
  bs = false
  rebuild;
  advancedDataLayers;
  drivers = [];
  vehicles$;
  vehicles = [];
  vehicleMarkers = [];
  markers = [];
  gMarkers: google.maps.Marker[] = [];
  @Input() polylines;
  @Input() polylineColor = '#26b7fc';
  @Input() controls: boolean = false;
  @Input() mapType: MapTypes;
  @Input() mapHeight = '500px';
  @Input() mapStyle = {
    'height.px': 500,
  };
  @Input() drawer: MapDrawer = { active: false, width: '0', edge: 'right' };
  @Input() hideFilters = false;
  @Input() Failed$;
  @Input() mapOptions = false;
  @Input() tripPolylines: any[] = [];


  @Output() clickEvent = new EventEmitter<string>();

  actingAs;
  user;
  showAdvancedOptions = false;
  crossDocksTrigger = false;

  geoJsonOptions = {
    driver_polyline: false,
    driver_action_points: false,
    telematics_polyline: false,
  };
  @ViewChild('map', { static: false }) gMap: GoogleMap;

  constructor(private mapService: MapService, public store: Store<any>) {
    this.store.select(selectorUser).subscribe((next) => (this.user = next));
    this.store.select(selectorActingAs).subscribe((next) => {
      this.actingAs = next;
      if (this.actingAs.package === 'tms' || this.actingAs.package === 'freight' || this.user.is_admin) {
        this.showAdvancedOptions = true;
      }
    });
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.geoJson && !changes.mapType) {
      if (!changes.geoJson.firstChange && this.mapType === MapTypes.Crossdocks) {
        if (this.mapElement) {
          this.mapReady = false;
          this.crossDocksTrigger = true;
          this.decodeGeoJson();
          this.showMap();
        }
      }
    }

    if (changes.polylineColor && this.polylinesArray) {
      this.polylinesArray.forEach((polyline) => {
        polyline.setOptions({
          strokeColor: this.polylineColor,
        });
      });
    }
  }

  ngOnDestroy(): void {
    this.mapService.dumpExistingData();
    this.polylinesArray = [];
    this.mapElement = null;
  }

  showMap() {
    Array.prototype.forEach.call(document.getElementsByClassName('map'), function (element) {
      element.style.visibility = 'visible';
    });
  }

  extendMapBounds(geoArray, context) {
    this.mapReady = true;
    if (this.boundsSet) {
      return;
    }
    const bounds = new google.maps.LatLngBounds();
    if (context !== 'drivers' && context !== 'combined' && context !== 'vehicles') {
      if (geoArray.length > 0) {
        geoArray.forEach((feature) => {
          if (feature.location.length > 0) {
            bounds.extend(new google.maps.LatLng(feature.location[0], feature.location[1]));
          }
        });
      }
    }

    if (context === 'combined' && this.mapType === MapTypes.Fleet) {
      if (geoArray.length > 0) {
        geoArray.forEach((feature) => {
          if (feature.location && (this.mapOptions ? feature.is_online : feature)) {
            bounds.extend(new google.maps.LatLng(feature.location.latitude, feature.location.longitude));
          }
        });
      }
    }

    if (context === 'drivers' && this.mapType === MapTypes.Fleet) {
      if (geoArray.length > 0) {
        geoArray.forEach((feature) => {
          if (feature.location && (this.mapOptions ? feature.is_online : feature)) {
            bounds.extend(new google.maps.LatLng(feature.location.latitude, feature.location.longitude));
          }
        });
      }
    }

    if (context === 'vehicles' && this.mapType === MapTypes.Fleet) {
      if (geoArray.length > 0) {
        geoArray.forEach((feature) => {
          if (feature.location && (this.mapOptions ? feature.is_online : feature)) {
            bounds.extend(new google.maps.LatLng(feature.location.latitude, feature.location.longitude));
          }
        });
      }
    }

    if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
      const extendPoint1 = new google.maps.LatLng(
        bounds.getNorthEast().lat() + 0.005,
        bounds.getNorthEast().lng() + 0.005
      );
      const extendPoint2 = new google.maps.LatLng(
        bounds.getNorthEast().lat() - 0.005,
        bounds.getNorthEast().lng() - 0.005
      );
      bounds.extend(extendPoint1);
      bounds.extend(extendPoint2);
    }

    if (this.mapReady) {
      if (!bounds.isEmpty()) {
        this.mapElement.fitBounds(bounds);
        this.showMap();
      } else {
        this.mapElement.setCenter(new google.maps.LatLng(3.51342104, 22.67578125));
        this.mapElement.setZoom(3);
        this.showMap();
      }
    }
  }

  mapLayerLevelChecks(layer) {
    this.geoJsonOptions[layer] = !this.geoJsonOptions[layer];
  }

  getDriverIconURL(driver) {
    if (driver.vehicles && driver.vehicles.length > 0) {
      switch (driver.vehicles[0]) {
        case 'vehicle-motorcycle':
          return '/assets/img/vehicle-motorcycle.svg';
        case 'vehicle-car':
          return '/assets/img/vehicle-car.svg';
        case 'vehicle-small-van':
          return '/assets/img/vehicle-small-van.svg';
        case 'vehicle-large-van':
          return '/assets/img/vehicle-large-van.svg';
      }
    }
  }

  checkbox(item) {
    if (this.filters.find((x) => x === item)) {
      this.filters.splice(this.filters.indexOf(item), 1);
    } else {
      this.filters.push(item);
    }
  }

  public mapElementReady() {
    if(this.gMap && !this.mapElement){
      this.mapElement = this.gMap;
      if (this.geoJson) {
        this.mapReady = true;
        this.mapElement.fitBounds(this.fitMapBounds());
        this.decodeGeoJson();
        this.showMap();
      }
    }

  }

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  fitMapBounds() {
    this.mapReady = true;
    if (this.mapReady) {
      if (this.geoJson) {
        const bounds = new google.maps.LatLngBounds();
        this.geoJson.features.forEach(function (feature) {
          if (feature.geometry.type === 'Point') {
            bounds.extend(new google.maps.LatLng(feature.geometry.coordinates[1], feature.geometry.coordinates[0]));
          }
          if (feature.geometry.type === 'Polygon') {
            feature.geometry.coordinates.forEach((coordinate) => {
              coordinate.forEach((element) => {
                bounds.extend(new google.maps.LatLng(element[1], element[0]));
              });
            });
          }
          if (feature.geometry.type === 'MultiPolygon') {
            feature.geometry.coordinates.forEach((coordinate) => {
              coordinate.forEach((element) => {
                element.forEach((deepest) => {
                  bounds.extend(new google.maps.LatLng(deepest[1], deepest[0]));
                });
              });
            });
          }
        });

        if (this.mapReady) {
          return bounds;
        }
      }
    }
  }

  decodeGeoJson(): void{
    // this.gMap.data.forEach(fun =>{
    //   this.gMap.data.remove(fun);
    // });
    switch (this.mapType) {
      case MapTypes.Vetting:
      {
        this.gMap.data.addGeoJson(this.geoJson);
        this.gMap.data.setStyle(styleFunc);
        this.gMap.data.addListener('click', (event) => {
          this.clickEvent.emit(event.feature.getProperty('business_reference'));
        })
        break;
      }
      case MapTypes.Crossdocks:
      {
        if(this.crossDocksTrigger){
          this.gMap.data.addGeoJson(this.geoJson);
          this.gMap.data.setStyle(styleFunc);
        }
        break;
      }
      case MapTypes.Trips:
      {
        this.gMap.data.addGeoJson(this.geoJson);
        this.gMap.data.setStyle(styleFunc);
        break;
      }
    }
    this.mapReady = true;
  }

  removeMarkers(business_reference: string): void{
    this.gMap.data.forEach(feturn => {
      if(business_reference === feturn.getProperty('business_reference')){
        this.gMap.data.remove(feturn);
      }
    });
  }
}
