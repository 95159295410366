import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import axios from 'app/api/axios';

// Services
import { Store } from '@ngrx/store';
import { NotificationsService } from '../../../../shared/notifications/notifications.service';

// Reducers
import { selectorActiveBucketId } from '../../manage-bucket.reducer';

// Interfaces
import {
  SelectedTripsIds,
  SelectedTripsResponse,
  TripCostsResponse,
  TripsTableData,
  TripSummaryData,
} from './trip-costs.interfaces';

// Constants and Helpers
import {
  tripsSummaryTableColumns,
  tripsSummaryTableDataMap,
  tripsTableColumns,
  tripsTableDataMap,
  unroutedOrdersDataMap,
  unroutedOrdersTableColumns,
} from './trip-costs.constants';
import { mapDataToTable } from '../../../../shared/table/table.helpers';
import { assignTripsToTables } from './trip-costs.helpers';
import { TripTypes } from '../../../trips.enums';
import { environment } from 'environments/environment';

// Urls:
const API_BUCKET_BASE = '/bucket/';
const API_ENDPOINT_GEOJSON = '/geojson/';
const API_ENDPOINT_SELECT_TRIPS = '/select-trips/';
const API_ENDPOINT_TRIP_SELECTION_COMPLETE = '/trip-selection-complete';
const API_ENDPOINT_TRIP_COSTS = '/trip-costs/';
const API_ENDPOINT_SELECTED_TRIPS = '/selected-trips/';
const API_ENDPOINT_UNROUTED_ORDERS = '/unrouted-orders/';

// Generate Endpoint Urls
const getUrlGeoData = (bucketId: number): string => API_BUCKET_BASE + bucketId + API_ENDPOINT_GEOJSON;
const getUrlSaveTrips = (bucketId: number): string => API_BUCKET_BASE + bucketId + API_ENDPOINT_SELECT_TRIPS;
const getUrlFinaliseTrips = (bucketId: number): string =>
  API_BUCKET_BASE + bucketId + API_ENDPOINT_TRIP_SELECTION_COMPLETE;
const getUrlTripCosts = (bucketId: number): string => API_BUCKET_BASE + bucketId + API_ENDPOINT_TRIP_COSTS;
const getUrlSelectedTrips = (bucketId: number): string => API_BUCKET_BASE + bucketId + API_ENDPOINT_SELECTED_TRIPS;
const getUrlUnrouted = (bucketId: number): string => API_BUCKET_BASE + bucketId + API_ENDPOINT_UNROUTED_ORDERS;

@Injectable({
  providedIn: 'root',
})
export class TripCostsService {
  bucketId: number;
  isInitializing = false;

  trips$ = new BehaviorSubject(undefined);
  summaryData$ = new BehaviorSubject(undefined);
  finalisingInProgress$ = new BehaviorSubject(undefined);
  savingInProgress$ = new BehaviorSubject(undefined);
  savedSuccessfully$ = new BehaviorSubject(undefined);
  tripGeoData$ = new BehaviorSubject(undefined);

  trips: TripsTableData = {
    unassigned: [],
    picup: [],
    contract: [],
    unrouted: [],
    courier: [],
    count: 0,
    previouslySaved: false,
    hasFetched: false,
  };

  selectedTripsIds: SelectedTripsIds = {
    unassigned: [],
    picup: [],
    contract: [],
    courier: [],
    hasFetched: false,
  };

  constructor(private store: Store<any>, private notificationsService: NotificationsService) {
    this.store.select(selectorActiveBucketId).subscribe((next) => {
      this.bucketId = next;
    });
  }

  public init() {
    if (!this.isInitializing) {
      this.isInitializing = true;
      this.getSelectedTrips();
      this.finalisingInProgress$.next(false);
    }
  }

  public getGeoData() {
    axios.get(getUrlGeoData(this.bucketId)).then((response) => this.tripGeoData$.next(response?.data));
  }

  public saveV2Trips(trips) {
    this.savingInProgress$.next(true);
    const APIdata = [];
    if (trips.picup.length) {
      const picupObj = {
        type: 'Picup',
        selected_trips: trips.picup,
      };
      APIdata.push(picupObj);
    }
    if (trips.contract.length) {
      const contractObj = {
        type: 'Contractor',
        selected_trips: trips.contract,
      };
      APIdata.push(contractObj);
    }
    return axios
      .post(getUrlSaveTrips(this.bucketId), APIdata)
      .then((response) => {
        this.notificationsService.publish({
          type: 'success',
          message: 'Trips saved successfully.',
        });
        this.savedSuccessfully$.next(true);
        this.savingInProgress$.next(false);
        return response;
      })
      .catch((error) => {
        this.notificationsService.publish({
          type: 'error',
          message: 'Error: ' + error.response?.data.message,
        });
        this.savingInProgress$.next(false);
        throw error;
      });
  }
  public save(trips) {
    this.savingInProgress$.next(true);

    return axios
      .post(getUrlSaveTrips(this.bucketId), trips)
      .then((response) => {
        this.notificationsService.publish({
          type: 'success',
          message: 'Trips saved successfully.',
        });
        this.savedSuccessfully$.next(true);
        this.savingInProgress$.next(false);
        return response;
      })
      .catch((error) => {
        this.notificationsService.publish({
          type: 'error',
          message: 'Error: ' + error.response?.data.message,
        });
        this.savingInProgress$.next(false);
        throw error;
      });
  }

  public finalise() {
    this.finalisingInProgress$.next(true);
    axios
      .post(getUrlFinaliseTrips(this.bucketId))
      .then(() => {
        this.finalisingInProgress$.next(false);
        this.notificationsService.publish({ type: 'success', message: 'Finalised Trip Costs' });
      })
      .catch((error) => {
        this.finalisingInProgress$.next(false);
        this.notificationsService.publish({ type: 'error', message: 'Error: ' + error.response?.data.message });
      });
  }

  private getTripCosts() {
    axios.get(getUrlTripCosts(this.bucketId)).then((response: TripCostsResponse) => {
      if (response?.data.trip_costs.length > 0) {
        this.trips.unassigned = mapDataToTable(response?.data.trip_costs, tripsTableDataMap, tripsTableColumns);
      }
      if (response?.data.unrouted.length > 0) {
        this.trips.unrouted = mapDataToTable(
          response?.data.unrouted,
          unroutedOrdersDataMap,
          unroutedOrdersTableColumns
        );
      }
      this.trips.count = this.trips.unassigned.length;
      this.trips.hasFetched = true;
      if (response?.data.summary.length > 0) {
        this.summaryData$.next(this.prepareSummaryData(response?.data.summary));
      }
      this.assignTrips();
    });
  }

  public getUnroutedOrders() {
    return axios.get(getUrlUnrouted(this.bucketId)).then((response) => {
      return response?.data;
    });
  }

  private getSelectedTrips() {
    axios.get(getUrlSelectedTrips(this.bucketId)).then((response: SelectedTripsResponse) => {
      if (environment.appVersion === 1) {
        this.selectedTripsIds = Object.assign({}, this.selectedTripsIds, response?.data);
        this.selectedTripsIds.hasFetched = true;
      } else {
        this.selectedTripsIds = {
          ...this.selectedTripsIds,
          contract: response?.data.Contractor ? response?.data.Contractor : [],
          picup: response?.data.Picup ? response?.data.Picup : [],
        };
        this.selectedTripsIds.hasFetched = true;
      }
      this.assignTrips();
      this.getTripCosts();
    });
  }

  private prepareSummaryData(summaryData: TripSummaryData[]) {
    const allTripsSummaryTotals = summaryData.find((item) => item.type === TripTypes.All);
    const allTripsSummaryAverages = {};

    Object.keys(allTripsSummaryTotals).forEach((key) => {
      if (['count', 'trip_type', 'type', 'bucket_id'].indexOf(key) === -1) {
        allTripsSummaryAverages[key] = allTripsSummaryTotals[key] / allTripsSummaryTotals.count;
      } else {
        allTripsSummaryAverages[key] = allTripsSummaryTotals[key];
      }
    });

    allTripsSummaryTotals['rowHeading'] = 'Total Sum';
    allTripsSummaryAverages['rowHeading'] = 'Total Average';

    return mapDataToTable(
      [allTripsSummaryTotals, allTripsSummaryAverages],
      tripsSummaryTableDataMap,
      tripsSummaryTableColumns
    );
  }

  private assignTrips() {
    if (!(this.trips.hasFetched && this.selectedTripsIds.hasFetched)) {
      return;
    }
    this.trips$.next(assignTripsToTables(this.trips, this.selectedTripsIds));
  }

  public clearTrips(): void {
    this.isInitializing = false;
    this.trips$.next(undefined);
    this.trips = {
      unassigned: [],
      picup: [],
      contract: [],
      unrouted: [],
      courier: [],
      count: 0,
      previouslySaved: false,
      hasFetched: false,
    };
  }
}
