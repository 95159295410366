<app-section>
  <heading 
    [icon]="iconTypes.Details" 
    [color]="uiColours.Blue">
    <h4 class="heading-title">Invoices</h4>
    <div class="button-wrap">
      <app-basic-button
        [title]="'This Month'"
        [type]="buttonTypes.Primary"
        [active]="dateRange === 'thisMonth' && !customDates"
        (click)="setPredefinedDate('thisMonth')"
      >
      </app-basic-button>
      <app-basic-button
        [title]="'Last Month'"
        [type]="buttonTypes.Primary"
        [active]="dateRange === 'lastMonth' && !customDates"
        (click)="setPredefinedDate('lastMonth')"
      >
      </app-basic-button>
      <app-basic-button 
        *ngIf="!customDates" 
        [title]="'Custom'" 
        [type]="buttonTypes.Primary" 
        (click)="setCustomDates()">
      </app-basic-button>
      <app-basic-button
        *ngIf="customDates"
        [title]="customStartDate"
        [type]="buttonTypes.Primary"
        (click)="openDatePickerModal('start_date')"
        [active]="customStartDate !== 'Start'"
      >
      </app-basic-button>
      <app-basic-button
        *ngIf="customDates"
        [title]="customEndDate"
        [type]="buttonTypes.Primary"
        (click)="openDatePickerModal('end_date')"
        [active]="customEndDate !== 'End'"
      >
      </app-basic-button>
    </div>
  </heading>
  <div class="actions-wrap" *ngIf="invoices?.length > 10">
    <div class="search-bar">
      <div class="input">
        <label for="search">
          <svg-icon 
            src="/assets/img/icons/search.svg" 
            class="icon">
          </svg-icon>
        </label>
        <input
          type="text"
          placeholder="Filter..."
          (ngModelChange)="searchString = $event; filterInvoices(searchString)"
          [ngModel]="searchString"
          (keydown.enter)="filterInvoices(searchString)"
        />
      </div>
    </div>
  </div>
  <app-table
    *ngIf="invoices && invoices.length > 0; else noData"
    [rawData]="shownInvoices"
    [columns]="invoiceColumns"
    [dataMap]="invoiceTableDataMap"
    [rowsSelectable]="false"
    [primaryActions]="tablePrimaryActions"
    (actionTaken)="respondToTableActions($event)"
    (moreDataRequested)="loadMoreTableData()"
    [showViewMore]="showViewMore"
    (viewAllRequested)="showAllData()"
    [showViewAll]="showViewMore"
  >
  </app-table>

  <ng-template #noData>
    <app-grid-container
      [columns]="'repeat(1, 1fr)'"
      [padding]="'4rem 5rem'"
      [colGap]="'2rem'"
      [rowGap]="'2rem'"
      [theme]="UiThemes.Light"
    >
      <h3 class="noData">No data for this date range</h3>
    </app-grid-container>
  </ng-template>
</app-section>
