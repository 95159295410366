import { Pipe, PipeTransform } from '@angular/core';
import { OpTrBusinesses } from '../operations-tracking.interface';

@Pipe({
  name: 'businessName'
})
export class BusinessNamePipePipe implements PipeTransform {

  transform(value: string, ...args: [OpTrBusinesses[]]): string {
    return args[0].find(warehouse => warehouse?.business_id === value)?.name
  }

}
