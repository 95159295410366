import { Component, OnDestroy, OnInit } from '@angular/core';
import axios from 'app/api/axios';
import firebase from 'firebase/app';

// Services
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { ConfirmModalComponent } from '../../../shared/modals/confirm-modal.component';
import { ManageBucketService } from '../manage-bucket.service';

// Reducers
import { selectorUser, selectorActingAs, selectorUserPermissions, selectorBusinesses } from '../../../auth/auth.reducer';

// Components

// Helpers & Constants
import { getBucketUiState, setBucketUiState } from '../../buckets/buckets.helpers';

// Interfaces
import { Bucket, BucketState, BucketStatesUi, BucketStatuses } from '../../buckets/buckets.interfaces';
import { EditBucketComponent } from './verify/edit-bucket/edit-bucket.component';
import { Subscription } from 'rxjs';
import { setActiveBucket } from '../manage-bucket.actions';
import { ActingAs, UserPermissions } from 'app/interfaces/auth.interfaces';
import { AuthService } from 'app/auth/auth.service';
import { BucketService } from '../bucket.service';
import { BasicButton, ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { NotesService } from 'app/dashboard/notes/notes.service';
import { SettingsService } from 'app/dashboard/settings/settings.service';
import { BooleanModalComponent } from 'app/shared/modals/boolean-modal/boolean-modal.component';
import { NotesTypeEnum } from 'app/dashboard/notes/notes.constants';
import { ToastService } from 'app/shared/toast/toast.service';
import { NotificationTypeEnum } from 'app/shared/shared.interfaces';
import moment from 'moment';

@Component({
  selector: 'app-manage-bucket',
  templateUrl: './manage-bucket.component.html',
  styleUrls: ['./manage-bucket.component.scss'],
})
export class ManageBucketComponent implements OnInit, OnDestroy {
  public bucket: Bucket;
  public actingAs: ActingAs;
  public user;
  public businesses;
  public id: number;
  private states: BucketState[] = [];
  user_permissions: UserPermissions;
  public current_bucket_state: BucketState;
  private initial_bucket_state: BucketState;
  public navigationState: BucketState;
  private stateIsOveridden = false;

  public multi_day_bucket = false;

  private stateSubscription: Subscription;
  private routeSubscription: Subscription;
  public removingBucket = false;
  public resettingBucket = false;

  public _BucketStatesUi: any = BucketStatesUi;
  public _getUiState = getBucketUiState;

  public finalizeVettingInProcess = false;

  public readOnlyMode = false;
  public enableDeletedFlags = false;

  processingDispatch = false;

  viewTabs: BasicButton[] = [];
  viewNotes;

  bucketNotes = [];
  bucketNotesSubscription: Subscription;
  newNotesSubscription: Subscription;
  newNotesPresent = false;
  buttonTypes = ButtonTypes;
  notesTypes = NotesTypeEnum;
  dateCreated: string = '';

  constructor(
    public store: Store<any>,
    private simpleModalService: SimpleModalService,
    private notificationsService: NotificationsService,
    public manageBucketService: ManageBucketService,
    public bucketService: BucketService,
    public router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private notesService: NotesService,
    private settingsService: SettingsService,
    private toastService: ToastService,
  ) {
    this.routeSubscription = this.route.params.subscribe((params) => (this.id = params['id']));
    this.store.select(selectorUserPermissions).subscribe((next) => (this.user_permissions = next));
    this.manageBucketService.setActiveBucket(this.id);
    this.stateSubscription = this.manageBucketService.getBucketState().subscribe((bucket: Bucket) => {
      const state = bucket.states;
      // Bail early if we don't have the data in the state that we need
      if (!state || !state.length) {
        return;
      }
      // Keep track of the overall list of states
      this.states = state;
      const newState: BucketState = this.states[this.states.length - 1];
      this.setupInitialState(newState);
      this.setupCurrentState(newState);

      if (!this.initial_bucket_state) {
        this.initial_bucket_state = newState;
        // this.catchStateError();
      }

      // Only update the UI state if we get a new state from the server.
      // IE the new state is different from the state that was present when the page
      // was first loaded
      if (
        !this.current_bucket_state ||
        this.current_bucket_state.state !== newState.state ||
        this.current_bucket_state.status !== newState.status ||
        this.current_bucket_state.progress !== newState.progress
      ) {
        this.current_bucket_state = newState;
        // this.catchStateError();
      }
    });
    this.store.select(selectorUser).subscribe((next) => (this.user = next));
    this.store.select(selectorActingAs).subscribe((next) => (this.actingAs = next));
    this.store.select(selectorBusinesses).subscribe((next) => (this.businesses = next));
  }

  ngOnInit(): void {
    this.getBucketFromFirebase();
    this.viewTabs = this.setViewTabs();
    this.notesService.getBucketNotes(this.id.toString());
    this.getBucketNotes();
  }

  setViewTabs() {
    return [
      { type: ButtonTypes.Primary, title: 'Details' },
      { type: this.newNotesPresent ? ButtonTypes.Highlight : ButtonTypes.Primary, title: 'Notes' },
    ];
  }

  getBucketFromFirebase() {
    this.manageBucketService.getBucketObject().subscribe((next: Bucket) => {
      const dateCreated = next?.states[0]?.date_created as firebase.firestore.Timestamp;
      const momentDate = moment(dateCreated.toMillis());
      const offset = momentDate.utcOffset()
      this.dateCreated = moment(dateCreated.toMillis()).add(offset, 'm').format();
      if (!this.businesses.find((x) => x.business_id === next.business_id)) {
        this.toastService.publish({
          message: 'You do not have permission to view this order',
          type: NotificationTypeEnum.Error,
        });
        localStorage.clear();
        this.authService.logout();
      }
      this.bucket = next;
      this.store.dispatch(new setActiveBucket(next));
      if (this.bucket.business_id !== this.actingAs.id) {
        this.authService.setActingAs(this.bucket.business_id, false);
      }
      if (this.bucket.delivery_date_end && this.bucket.delivery_date_end !== '0001-01-01T00:00:00:00') {
        this.multi_day_bucket = true;
      } else {
        this.multi_day_bucket = false;
      }
      if (this.current_bucket_state) {
        this.current_bucket_state.bucket_id = this.bucket.bucket_id;
      }

      if(next.IsDeleted){
        this.setToReadOnly();
      }
    });
  }

  getBucketNotes(): void {
    this.bucketNotesSubscription = this.notesService.orderNotes$.subscribe((data) => {
      this.bucketNotes = data;
    });
    this.newNotesSubscription = this.notesService.newNotes$.subscribe((data) => {
      if (data) {
        this.newNotesPresent = true;
      } else {
        this.newNotesPresent = false;
      }
      this.viewTabs = this.setViewTabs();
    });
  }

  checkNotesViewed(): void {
    this.newNotesPresent = false;
    this.viewTabs = this.setViewTabs();
    if (this.bucketNotes) {
      this.bucketNotes?.forEach((note) => {
        if (!note.read_by || !note.read_by.includes(this.user.user_id)) {
          this.newNotesPresent = true;
          this.viewTabs = this.setViewTabs();
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.bucket = null;
    this.id = undefined;
    this.states = [];
    this.current_bucket_state = null;
    this.initial_bucket_state = null;
    this.navigationState = null;
    this.manageBucketService.stopPolling = true;
    this.routeSubscription.unsubscribe();
    this.stateSubscription.unsubscribe();
    if (this.newNotesSubscription) {
      this.newNotesSubscription.unsubscribe();
    }
    this.bucketNotesSubscription.unsubscribe();
  }

  handleTabNavigation($event: string): void {
    switch ($event) {
      case 'Details':
        this.viewNotes = false;
        break;
      case 'Notes':
        this.viewNotes = true;
    }
  }

  setupInitialState(newState) {
    const isStateFromANewBucket =
      this.initial_bucket_state && this.initial_bucket_state.bucket_id !== newState.bucket_id;

    if (!isStateFromANewBucket) {
      return;
    }

    // Start from scratch with a new bucket
    this.initial_bucket_state = null;
  }

  setupCurrentState(newState) {
    const isStateFromANewBucket =
      this.current_bucket_state && this.current_bucket_state.bucket_id !== newState.bucket_id;

    if (!isStateFromANewBucket) {
      return;
    }

    // Start from scratch with a new bucket
    this.current_bucket_state = null;
  }

  catchStateError() {
    if (
      this.current_bucket_state &&
      (this.current_bucket_state.status === BucketStatuses.Errored ||
        (this.current_bucket_state.error !== null && this.current_bucket_state.error.length > 0))
    ) {
      this.notificationsService.publish({
        type: 'error',
        message:
          'Error on bucket: ' + (this.current_bucket_state.error.length > 0 ? this.current_bucket_state.error : ''),
      });
    }
  }

  respondToProcessingDispatch($event) {
    this.processingDispatch = $event;
  }

  // DEV Function - used for tabbed state navigation.
  updateStep(selected) {
    this.stateIsOveridden = true;
    this.navigationState = setBucketUiState(selected, this.bucket);
  }

  finalizeVetting() {
    this.finalizeVettingInProcess = true;

    // Check if we have auto-consign options:
    this.settingsService.getConsignmentOptions(this.bucket.warehouse_id).then((response) => {
      if (!response) {
        this.proceedToCosting();
      } else {
        this.autoConsign();
      }
    });
  }

  autoConsign() {
    this.simpleModalService
      .addModal(BooleanModalComponent, {
        title: 'Auto Consign Bucket',
        message: !this.user_permissions?.modules?.includes('user')
          ? 'This warehouse has automatic consignment defined. Apply now?'
          : 'This warehouse has automatic consignment defined. This will be applied now.',
        description: null,
        allow_false: !this.user_permissions?.modules?.includes('user'),
      })
      .subscribe((result) => {
        switch (result) {
          case true:
            axios
              .get(`/bucket/${this.bucket.bucket_id}/auto-consign`)
              .then(() => {
                this.proceedToCosting();
              })
              .catch((error) => {
                this.notificationsService.publish({ type: 'error', message: error.response?.data.message });
              });
            break;
          case false:
            this.proceedToCosting();
            break;
          case null:
            this.finalizeVettingInProcess = false;
            return;
        }
      });
  }

  proceedToCosting(): void {
    axios
      .post(`/bucket/${this.bucket.bucket_id}/pre-route-management`)
      .then(() => {
        this.finalizeVettingInProcess = false;
        this.notificationsService.publish({ type: 'success', message: 'Finalised vetting' });
      })
      .catch((error) => {
        this.finalizeVettingInProcess = false;
        this.notificationsService.publish({ type: 'error', message: error.response?.data.message });
      });
  }

  removeBucket() {
    this.removingBucket = true;
    this.simpleModalService
      .addModal(ConfirmModalComponent, {
        title: 'Are you sure?',
        message: 'You are about to cancel this bucket.',
      })
      .subscribe((isConfirmed) => {
        if (!isConfirmed) {
          this.removingBucket = false;
          return;
        }

        axios
          .delete(`/bucket/${this.bucket.bucket_id}`)
          .then((result) => {
            if (result.status !== 200) {
              return this.notificationsService.publish({ message: 'We couldn\'t remove this bucket', type: 'error' });
            }

            this.notificationsService.publish({ message: 'Bucket removed successfully', type: 'success' });
            this.router.navigate(['/dashboard/buckets']);
          })
          .catch((error) =>
            this.notificationsService.publish({
              message: error.response?.data.message,
              type: 'error',
            })
          );
      });
  }

  editBucket() {
    const modal_data = {
      delivery_date: this.bucket.delivery_date,
      shift_start: this.bucket.shift_start,
      shift_end: this.bucket.shift_end,
      bucket_id: this.bucket.bucket_id,
      total_orders: this.bucket.total_orders,
    };
    this.simpleModalService.addModal(EditBucketComponent, modal_data).subscribe((result) => {
      if (!result) {
        return;
      }
      this.bucketService.updateBucketDetails(result, this.bucket.bucket_id);
    });
  }

  resetBucket() {
    this.resettingBucket = true;
    this.simpleModalService
      .addModal(ConfirmModalComponent, {
        title: 'Are you sure?',
        message: 'You are about to reset this bucket. This will clear all existing routes.',
      })
      .subscribe((isConfirmed) => {
        if (!isConfirmed) {
          this.resettingBucket = false;
          return;
        }
        axios
          .post(`/bucket/${this.bucket.bucket_id}/reset-bucket`)
          .then((result) => {
            this.resettingBucket = false;
            if (result.status !== 200) {
              return this.notificationsService.publish({ message: 'We couldn\'t reset this bucket', type: 'error' });
            }
            this.notificationsService.publish({ message: 'Bucket reset successfully', type: 'success' });
          })
          .catch((error) => {
            this.resettingBucket = false;
            this.notificationsService.publish({
              message: error.response?.data.message,
              type: 'error',
            });
          });
      });
  }

  shouldLoadView(view) {
    if (!this.stateIsOveridden) {
      this.navigationState = this.current_bucket_state;
    }
    return getBucketUiState(this.navigationState) === view;
  }

  setToReadOnly(): void{
    this.readOnlyMode = true
    this.enableDeletedFlags = true;
    this.updateStep(this._BucketStatesUi.Verifying);
  }
}
