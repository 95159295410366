import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { TeamDetails, WarehouseDetails } from 'app/admin/team-management/Interfaces/team-management';
import { authState, selectorActingAs } from 'app/auth/auth.reducer';
import { ActingAs, Warehouse } from 'app/interfaces/auth.interfaces';
import { LastMileStateEnum, OpTrBusinesses, OperationsTrackingTableAction, OperationsTrackingTableActionEnum } from 'app/operations-tracking/operations-tracking.interface';
import { OperationsTrackingService } from 'app/operations-tracking/operations-tracking.service';
import { LastEventMapping } from 'app/operations-tracking/pipes/last-event.pipe';
import { LastMile } from 'app/shared/shared.interfaces';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-last-mile-table',
  templateUrl: './last-mile-table.component.html',
  styleUrls: ['./last-mile-table.component.scss']
})
export class LastMileTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() lastMileState: LastMileStateEnum;
  @Input() warehouses: Warehouse[] = [];
  @Input() businesses: OpTrBusinesses[] = [];
  @Input() dataSource = new MatTableDataSource<LastMile>([]);
  @Output() tableActionTaken: EventEmitter<OperationsTrackingTableAction> = new EventEmitter();
  @Output() warehouseFilterChange: EventEmitter<string[]> = new EventEmitter();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['DriverName','Role', 'BusinessName','WarehouseName','LastEventName', 'Timestamp', 'HasFailedCollection', 'HasFailedDelivery', 'Actions'];
  warehouseControl = new FormControl([]);
  businessControl = new FormControl([]);
  teamsControl = new FormControl([]);
  warehouseAutoCompleteControl = new FormControl('');
  fleetControl = new FormControl('All Picup');
  fleets: string[] = ['All', 'All Picup','Picup', 'PicupPlus', 'Contractor'];
  lastEventControl = new FormControl('');
  lastEvents: Map<string, string> = new Map([
    ['All', ''],
    ...LastEventMapping
  ]);
  ActionsEnum = OperationsTrackingTableActionEnum;
  filteredWarehouses = this.warehouses;
  filteredBusiness = this.businesses;
  teams: TeamDetails[] = [];
  pageSize = 5;
  unsubscribe$ = new Subject<void>();
  actingAs: ActingAs;
  isAdminScreen: boolean;

  constructor(public operationsTrackingService: OperationsTrackingService, private store: Store<authState>) {
    this.fleetControl.setValue(this.operationsTrackingService.fleet)
    this.operationsTrackingService.usedIds$.pipe(take(1)).subscribe((ids: string[]) => {
      // Because of splicing ids will always be empty expect on the initial loading
      if(this.warehouseControl.value !== ids && this.warehouseControl.value?.length > 0 && ids.length > 0){
        this.warehouseControl.setValue(ids);
        this.businessControl.setValue(ids);
      } else if(ids.length === 0 && !this.warehouseControl.value){
        this.warehouseControl.setValue(ids);
        this.businessControl.setValue(ids);
      }
    });
    operationsTrackingService.teams$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(teams => {
        this.teams = teams;
      });
  }

  ngOnInit(): void {
    this.store.select(selectorActingAs).pipe(takeUntil(this.unsubscribe$)).subscribe((next) => {
      this.actingAs = next;
    });
    this.operationsTrackingService.isAdmin$.pipe(takeUntil(this.unsubscribe$)).subscribe((value: boolean) => {
      this.isAdminScreen = value;
    })
    this.filteredWarehouses = this.warehouses;
    this.filteredBusiness = this.businesses;

    this.warehouseControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((selectedWarehouses: string[]) => {
        this.operationsTrackingService.usedIds = [...selectedWarehouses];
        this.storeWarehouseFilterPerBusiness();
      });

    this.businessControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((selectedBusinesses: string[]) => {
        this.operationsTrackingService.usedIds = [...selectedBusinesses];
        this.storeFilters();
      });

    this.teamsControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((teamNames: string[]) => {
        const warehouses = [];
        teamNames.forEach((teamName: string) => {
          const teamWarehouses = this.teams.find(value => value.teamName === teamName)?.warehouses ?? [];
          teamWarehouses.forEach((warehouse: WarehouseDetails) => {
            if(!warehouses.some(value => value === warehouse.id)){
              warehouses.push(warehouse.id)
            }
          })
        });
        this.warehouseControl.setValue(warehouses);
      });

    this.loadFilters();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.paginator.page.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.storeFilters();
    })
    this.applyFleetControlFilter()
  }

  applyLastEventFilter(): void {
    this.dataSource.filterPredicate = (data: LastMile, filter: string) => {
      return !filter || data.LastEventName === this.lastEventControl.value
    };
    this.dataSource.filter = this.lastEventControl.value?.trim().toLowerCase();
    this.storeFilters();
  }

  applyFleetControlFilter(): void {
    this.operationsTrackingService.fleet = this.fleetControl.value;
    this.dataSource.filterPredicate = (data: LastMile, filter: string) => {
      if(this.fleetControl.value === 'All Picup'){
        return !filter || data.Role === 'Picup' || data.Role === 'PicupPlus'
      }
      return !filter || data.Role === this.fleetControl.value
    };
    this.dataSource.filter = this.fleetControl.value.trim().toLowerCase();
    this.storeFilters();
  }

  getActiveLastMiles(warehouseIds: string[]): void {
    this.warehouseFilterChange.emit(warehouseIds);
  }

  handleTableAction(id: string, action: OperationsTrackingTableActionEnum): void {
    this.tableActionTaken.emit({Action: action, Id: id});
  }

  trackBy(_index: number, item: LastMile): string {
    return item.Id;
  }

  filterWarehouse(searchTerm: string): void{
    if(searchTerm.length > 2 ){
      this.filteredWarehouses = this.warehouses.filter((warehouse: Warehouse) => {
        return warehouse?.name?.toLowerCase().includes(searchTerm.toLowerCase());
      })
    } else {
      this.filteredWarehouses = this.warehouses;
    }
  }

  filterBusinesses(searchTerm: string): void{
    if(searchTerm.length > 2 ){
      this.filteredBusiness = this.businesses.filter((business: OpTrBusinesses) => {
        return business?.name?.toLowerCase().includes(searchTerm.toLowerCase());
      })
    } else {
      this.filteredBusiness = this.businesses;
    }
  }

  trackByWarehouseId(_index: number, item: Warehouse): string{
    return item.id;
  }

  trackByBusinessId(_index: number, item: OpTrBusinesses): string{
    return item.business_id;
  }

  trackByTeamName(_index: number, item: TeamDetails): string {
    return item.teamName;
  }

  storeFilters(): void {
    const filtersGlobal = JSON.parse(localStorage.getItem('opsFilters'));
    const filterValues = {
      selectedBusinesses: this.businessControl.value,
      selectedFleet: this.fleetControl.value,
      selectedEvent: this.lastEventControl.value,
      selectedOnDemand: filtersGlobal?.selectedOnDemand,
      selectedAssignedStatus: filtersGlobal?.selectedAssignedStatus,
      pageSize: this.paginator?.pageSize ?? this.pageSize
    }
    localStorage.setItem('opsFilters', JSON.stringify(filterValues));
  }

  storeWarehouseFilterPerBusiness(): void {
    const fValues = {
      selectedWarehouses: this.warehouseControl.value,
      selectedTeams: this.teamsControl.value,
    }
    localStorage.setItem(`opsFilters-${this.actingAs.id}`, JSON.stringify(fValues));
  }

  loadFilters(): void {
    const filtersGlobal = JSON.parse(localStorage.getItem('opsFilters'));
    this.pageSize = filtersGlobal?.pageSize ?? 5;
    this.fleetControl.setValue(filtersGlobal?.selectedFleet ?? '');
    this.lastEventControl.setValue(filtersGlobal?.selectedEvent ?? '')

    if(this.isAdminScreen){
      this.businessControl.setValue(filtersGlobal?.selectedBusinesses ?? []);
    }

    if(!this.isAdminScreen){
      const filters = JSON.parse(localStorage.getItem(`opsFilters-${this.actingAs.id}`));
      this.teamsControl.setValue(filters?.selectedTeams ?? []);
      this.warehouseControl.setValue(filters?.selectedWarehouses ?? []);
    }
  }

}
