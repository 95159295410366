<div class="bucket-status" [ngClass]="size">
  <svg-icon
    [svgStyle]="{ 'width.rem': '0.7' }"
    *ngIf="!isInProgress"
    src="../../../assets/img/icons/dot.svg"
    class="status-icon"
    [ngClass]="{ open: open(), closed: closed(), 'closing-soon': closingSoon(), alert: alert() }"
  ></svg-icon>
  <svg-icon
    [svgStyle]="{ 'width.rem': '1' }"
    *ngIf="isInProgress"
    class="spin status-icon"
    src="../../../assets/img/icons/spinner.svg"
    [ngClass]="{ alert: alert() }"
  ></svg-icon>
  <!-- <span class="status-text" [ngClass]="{'closed' : closed()}">{{closed() ? 'Closed' : currentStatus}}</span>
  <span *ngIf="closingSoon()">&mdash;&nbsp; Closes: {{closingTime() | date:'HH:mm'}}</span> -->
  <span [ngClass]="{ alert: alert() }" class="status-text">{{ !staging ? currentStatus : 'Pending' }}</span>
</div>
