import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LastMileComponent } from './last-mile.component';
import { SharedModule } from 'app/shared/shared.module';
import { LastMileFsWaypointRouteComponent } from './last-mile-fs-waypoint-route/last-mile-fs-waypoint-route.component';
import { LastMileFsWaypointContactComponent } from './last-mile-fs-waypoint-route/last-mile-fs-waypoint/last-mile-fs-waypoint-contact/last-mile-fs-waypoint-contact.component';
import { LastMileFsWaypointComponent } from './last-mile-fs-waypoint-route/last-mile-fs-waypoint/last-mile-fs-waypoint.component';
import { LastMileFsWarehouseRouteComponent } from './last-mile-fs-warehouse-route/last-mile-fs-warehouse-route.component';
import { LastMileDriverDetailsComponent } from './last-mile-driver-details/last-mile-driver-details.component';
import { LastMileFsParcelsComponent } from './last-mile-fs-parcels/last-mile-fs-parcels.component';
import { LastMileFinancialsComponent } from './last-mile-financials/last-mile-financials.component';
import { ReconcileWarehouseModalComponent } from './reconcile-route/reconcile-warehouse-modal.component';
import { LastMileFsWarehouseContactComponent } from './last-mile-fs-waypoint-route/last-mile-fs-waypoint/last-mile-fs-warehouse-contact/last-mile-fs-warehouse-contact.component';
import { LastMileFailedParcelsComponent } from './last-mile-failed-parcels/last-mile-failed-parcels.component';
import { LastMileStagedAssignmentsComponent } from './last-mile-staged-assignments/last-mile-staged-assignments.component';

@NgModule({
  declarations: [
    LastMileComponent,
    LastMileFsWaypointRouteComponent,
    LastMileFsWaypointContactComponent,
    LastMileFsWaypointComponent,
    LastMileFsWarehouseRouteComponent,
    LastMileDriverDetailsComponent,
    LastMileFsParcelsComponent,
    LastMileFinancialsComponent,
    ReconcileWarehouseModalComponent,
    LastMileFsWarehouseContactComponent,
    LastMileFailedParcelsComponent,
    LastMileStagedAssignmentsComponent,
  ],
  exports: [
    ReconcileWarehouseModalComponent,
    LastMileComponent,
    LastMileFsWaypointRouteComponent,
    LastMileFsWarehouseRouteComponent,
    LastMileStagedAssignmentsComponent
  ],
  imports: [CommonModule, SharedModule],
})
export class LastMileModule {}
