import { Component, OnInit } from '@angular/core';
import { Contractor } from 'app/dashboard/buckets/buckets.interfaces';
import { ManageBucketService } from 'app/dashboard/buckets/manage-bucket.service';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { VehicleSizesEnum } from 'app/interfaces/vehicle-type.interfaces';
import { BasicButton, ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { ModalAction, ModalSizes } from 'app/shared/modals/modal.interfaces';
import { SimpleModalComponent } from 'ngx-simple-modal';
import {
  DriverSelectActionsEnum,
  DriverSelectData,
  DriverSelectFilterButtonEnum,
} from './driver-select-modal.interface';

@Component({
  selector: 'app-driver-select-modal',
  templateUrl: './driver-select-modal.component.html',
  styleUrls: ['./driver-select-modal.component.scss'],
})
export class DriverSelectModalComponent extends SimpleModalComponent<DriverSelectData, Contractor[]> implements OnInit {
  drivers: string[];
  vehicleType: VehicleSizesEnum = null;
  contractors: Contractor[];
  filteredContractors: Contractor[];
  ModalSizes = ModalSizes;
  IconTypes = IconTypes;
  UiColors = UiColors;
  actions: ModalAction[] = [
    { name: DriverSelectActionsEnum.Cancel, title: DriverSelectActionsEnum.Cancel },
    { name: DriverSelectActionsEnum.Confirm, title: DriverSelectActionsEnum.Confirm, isPrimary: true },
  ];
  loading: boolean;
  filterButtons: BasicButton[] = [
    { type: ButtonTypes.Primary, title: DriverSelectFilterButtonEnum.All },
    { type: ButtonTypes.Primary, title: DriverSelectFilterButtonEnum.Motorcyle },
    { type: ButtonTypes.Primary, title: DriverSelectFilterButtonEnum.Car },
    { type: ButtonTypes.Primary, title: DriverSelectFilterButtonEnum.SmallVan },
    { type: ButtonTypes.Primary, title: DriverSelectFilterButtonEnum.LargeVan },
  ];
  filter: DriverSelectFilterButtonEnum = DriverSelectFilterButtonEnum.All;
  constructor(private bucketService: ManageBucketService) {
    super();
  }

  ngOnInit(): void {
    this.loading = true;
    this.getAssignableContractors();
  }

  getAssignableContractors(): void {
    this.bucketService.getAssignableContractors().then((response) => {
      if (response) {
        this.contractors = response.output;
        this.filteredContractors = this.contractors;
        this.loading = false;
      }
    });
  }

  toggleContractor(contractor: Contractor): void {
    if (this.drivers.includes(contractor.driver_id)) {
      this.drivers = this.drivers.filter((id) => id !== contractor.driver_id);
    } else {
      this.drivers.push(contractor.driver_id);
    }
  }

  // Privious Type: DriverSelectActionsEnum
  handleAction($event: any): void {
    switch ($event) {
      case DriverSelectActionsEnum.Cancel: {
        this.result = null;
        this.close();
        break;
      }
      case DriverSelectActionsEnum.Confirm: {
        this.result = this.contractors.filter((x) => this.drivers.includes(x.driver_id));
        this.close();
        break;
      }
    }
  }
  //  Privious Type: DriverSelectFilterButtonEnum
  handleFilters($event: any): void {
    this.filter = $event;
    switch ($event) {
      case DriverSelectFilterButtonEnum.All: {
        this.vehicleType = null;
        this.filteredContractors = this.contractors;
        break;
      }
      case DriverSelectFilterButtonEnum.Motorcyle: {
        this.vehicleType = VehicleSizesEnum.vehicle_motorcycle;
        this.filteredContractors = this.contractors.filter(
          (contractor) => contractor.vehicle_id === VehicleSizesEnum.vehicle_motorcycle
        );
        break;
      }
      case DriverSelectFilterButtonEnum.Car: {
        this.vehicleType = VehicleSizesEnum.vehicle_car;
        this.filteredContractors = this.contractors.filter(
          (contractor) => contractor.vehicle_id === VehicleSizesEnum.vehicle_car
        );
        break;
      }
      case DriverSelectFilterButtonEnum.SmallVan: {
        this.vehicleType = VehicleSizesEnum.vehicle_small_van;
        this.filteredContractors = this.contractors.filter(
          (contractor) => contractor.vehicle_id === VehicleSizesEnum.vehicle_small_van
        );
        break;
      }
      case DriverSelectFilterButtonEnum.LargeVan: {
        this.vehicleType = VehicleSizesEnum.vehicle_large_van;
        this.filteredContractors = this.contractors.filter(
          (contractor) => contractor.vehicle_id === VehicleSizesEnum.vehicle_large_van
        );
        break;
      }
    }
  }
}
