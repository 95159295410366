import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { FSAdminAlertOrderTableColumns, FSAdminAlertOrderTableDataMap } from 'app/admin/admin.contants';
import { authState, selectorActingAs } from 'app/auth/auth.reducer';
import { FSOrderTableColumns, FSOrderTableDataMap } from 'app/dashboard/post-dispatch/post-dispatch.constants';
import { OrderFrontEndStateEnum } from 'app/dashboard/post-dispatch/post-dispatch.interfaces';
import { PostDispatchService } from 'app/dashboard/post-dispatch/post-dispatch.service';
import { ActingAs, UserRoles } from 'app/interfaces/auth.interfaces';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { Order, CollapsibleTableTypeEnum, PaginateEnum, Paginate } from 'app/shared/shared.interfaces';
import { TableAction, SortEvent } from 'app/shared/table/table.interfaces';
import { Observable, Subscription, from } from 'rxjs';

@Component({
  selector: 'app-dispatch-manual',
  templateUrl: './dispatch-manual.component.html',
  styleUrls: ['./dispatch-manual.component.scss']
})
export class DispatchManualComponent implements OnInit, OnDestroy {

  @Input() postDispatchTablePrimaryActions: TableAction[] = [];

  Icons = {
    Colors: UiColors,
    Types: IconTypes,
  };

  manualAssignmentFirstoreTrips: Observable<Order[]>;
  manualAssignmentAmount: number = 10;
  manualAssignmentSubscription: Subscription;
  manualAssignmentTotal: number;

  fsOrderTableColumns = FSOrderTableColumns;
  fsOrderTableDataMap = FSOrderTableDataMap;

  CollapsibleTableTypeEnum = CollapsibleTableTypeEnum;

  postDispatchAlertTablePrimaryActions: TableAction[] = [
    { event: 'copyId', title: 'Copy ID', icon: IconTypes.Details, icon_color: UiColors.Grey8 },
    { event: 'viewTrip', title: 'View Trip', icon: IconTypes.Eye, icon_color: UiColors.Grey8 },
  ];

  alertTableColumns = FSAdminAlertOrderTableColumns;
  alertTableDataMap = FSAdminAlertOrderTableDataMap;

  actingAs: ActingAs;

  constructor(private store: Store<authState>, private postDispatchService: PostDispatchService) {}

  ngOnInit(): void {
    this.store.select(selectorActingAs).subscribe((next) => {
      this.actingAs = next;
      if (next.roles.includes(UserRoles.SuperAdministrator)) {
        if (!this.postDispatchAlertTablePrimaryActions.find((x) => x.event === 'startLottery')) {
          this.postDispatchAlertTablePrimaryActions.push({
            event: 'startLottery',
            title: 'Start Lottery',
            icon: IconTypes.Driver,
            icon_color: UiColors.Grey8,
          });
        }
      }
    });
    this.getData();
  }

  ngOnDestroy(): void {
    this.manualAssignmentSubscription.unsubscribe();
  }

  handlePostDispatchTotals(): void {
    this.postDispatchService.getPostDispatchTotals(false).then((data) => {
      this.manualAssignmentTotal = data.output.find(
        (x) => x.State === OrderFrontEndStateEnum.WaitingForManualAssignment
      )?.Cnt;
    });
  }

  getData(): void {
    this.handleManualAssignmentTrips({ Amount: this.manualAssignmentAmount, Direction: PaginateEnum.None });
    this.handlePostDispatchTotals();
  }

  handleManualAssignmentTrips($event: Paginate): void {
    this.manualAssignmentAmount = $event.Amount;
    this.manualAssignmentSubscription?.unsubscribe();
    this.manualAssignmentSubscription = this.postDispatchService
      .getFireStoreOrderSummaryStreamByState(
        this.manualAssignmentAmount,
        $event.Direction,
        OrderFrontEndStateEnum.WaitingForManualAssignment,
        false
      )
      .subscribe((response) => {
        if (response[0]) {
          this.postDispatchService.setTripSnapshot(
            {
              First: response[0].payload.doc,
              Last: response[response.length - 1].payload.doc,
            },
            OrderFrontEndStateEnum.WaitingForManualAssignment
          );

          const data: Order[] = [];
          for (const item of response) {
            data.push(item.payload.doc.data());
          }
          this.manualAssignmentFirstoreTrips = from([data]);
        } else {
          this.manualAssignmentFirstoreTrips = from([]);
        }
      });
  }

  handleTripsSortChange($event: SortEvent): void {
    this.postDispatchService.orderOrderBy = $event;
    this.handleManualAssignmentTrips({ Amount: this.manualAssignmentAmount, Direction: PaginateEnum.None });
  }

}
