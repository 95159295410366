<ng-container *ngIf="state">
  <div *ngIf="state.status === 'Pending'" class="start-geocoding">
    <h2 class="geocoding-title">Processing</h2>

    <app-basic-button
      [processing]="processing"
      [title]="'Start Auto-Geocoder'"
      [type]="buttonTypes.Primary"
      (click)="autoGeocode()"
      [disabled]="readOnly"
    ></app-basic-button>
  </div>
  <app-processing-indicator
    *ngIf="['Enqueued', 'Processing', 'Completed'].indexOf(state.status) > -1"
    [percentageComplete]="state.progress"
    [title]="'Auto Geocoding'"
  >
  </app-processing-indicator>
</ng-container>
