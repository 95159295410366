<h2 mat-dialog-title>Assign driver</h2>
<p>
  Manage your driver table view by <strong>enabling</strong> or <strong>disabling</strong> your driver filter options.
</p>
<div class="filter-bar">
  <div class="filter-types">
    <div
      (click)="setFilter(filterType)"
      class="type {{ selectedFilter === filterType ? 'selected' : '' }}"
      *ngFor="let filterType of driverFilterTypes"
    >
      <mat-icon>check</mat-icon> {{ filterType }}
    </div>
    <div class="warehouse-restrict">
      <mat-slide-toggle
        (change)="setFilter(selectedFilter)"
        [disabled]="disabledRestrict"
        [(ngModel)]="restrictToWarehouse"
        [labelPosition]="'before'"
        color="primary"
        ><strong>Restrict to warehouse</strong></mat-slide-toggle
      >
    </div>
    <div class="warehouse-restrict">
      <mat-slide-toggle  [(ngModel)]="showMap" [labelPosition]="'before'" color="primary"
        ><strong>Show Map</strong></mat-slide-toggle
      >
    </div>
  </div>
  <div class="search-container">
    <div class="search-input">
      <input
        matTooltip="Specify at least 3 characters"
        #searchInput
        [formControl]="searchFormControl"
        (keyup.enter)="search(searchFormControl.value)"
        placeholder="Search for driver"
      />
    </div>
    <div (click)="search(searchFormControl.value)" class="search-btn">
      <mat-icon>search</mat-icon>
    </div>
  </div>
</div>
<div *ngIf="loading; else showData" class="loader">
  <mat-spinner></mat-spinner>
</div>
<ng-template #showData>
  <google-map
    [hidden]="!showMap"
    width="100%"
    #map
    (tilesloaded)="mapElementReady()"
    class="map"
    [zoom]="12"
    [options]="mapOptions"
  ></google-map>

  <ng-container *ngIf="driverData?.length > 0; else noDrivers">
    <table mat-table matSort [dataSource]="dataSource" class="margin-top-1">
      <ng-container matColumnDef="Status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td class="pd-right-5" [class]="element.online ? 'bg-green' : 'bg-red'" mat-cell *matCellDef="let element">
          {{ element.online ? 'Online' : element.online === null ? '-' : 'Offline' }}
        </td>
      </ng-container>
  
      <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td class="pd-right-5" mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>
  
      <ng-container matColumnDef="Vehicle">
        <th mat-header-cell *matHeaderCellDef>Vehicle</th>
        <td class="pd-right-5" mat-cell *matCellDef="let element">
          <img *ngIf="element.vehicle; else noVehicle" [src]="'/assets/img/' + element.vehicle + '.svg'" />
          <ng-template #noVehicle> - </ng-template>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="Last Mile Status">
        <th mat-header-cell *matHeaderCellDef>Last Mile Status</th>
        <td class="pd-right-5 pd-left-5" mat-cell *matCellDef="let element">{{ element.lastMileLastEvent | lastEvent}}</td>
      </ng-container>
  
      <ng-container matColumnDef="KM from WH">
        <th mat-header-cell *matHeaderCellDef>KM from WH</th>
        <td class="pd-right-5" mat-cell *matCellDef="let element">{{ element.distanceToWarehouse }}</td>
      </ng-container>
  
      <ng-container matColumnDef="Contact">
        <th mat-header-cell *matHeaderCellDef>Contact</th>
        <td class="pd-right-5" mat-cell *matCellDef="let element">{{ element.phone }}</td>
      </ng-container>
  
      <ng-container matColumnDef="Parcels">
        <th mat-header-cell *matHeaderCellDef>Parcels</th>
        <td class="pd-right-5" mat-cell *matCellDef="let element">{{ element.parcelsDelivered + '/' + element.parcelCount }}</td>
      </ng-container>
  
      <ng-container matColumnDef="Assigned Trips">
        <th mat-header-cell *matHeaderCellDef>Trips</th>
        <td class="pd-right-5" mat-cell *matCellDef="let element">{{ element.orderCount }}</td>
      </ng-container>
  
      <ng-container matColumnDef="Businesses">
        <th mat-header-cell *matHeaderCellDef>Businesses</th>
        <td class="pd-right-5" mat-cell *matCellDef="let element">{{ element.businesses }}</td>
      </ng-container>
  
      <ng-container matColumnDef="Fleet">
        <th mat-header-cell *matHeaderCellDef>Fleet</th>
        <td class="pd-right-5" mat-cell *matCellDef="let element">{{ element.fleet }}</td>
      </ng-container>
  
      <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let element">
          <div class="action-column" >
            <div [matTooltip]="disabledTooltip(element)"
                (click)="selectDriver(element)" 
                [ngClass]="(element | assignDriverPipe: actingAs.id:data.WarehouseId) ? 'button-disabled' : 'assign-btn'">
                Assign
              </div>
            <div class="icon-button">
              <button mat-icon-button matTooltip="Open Driver" (click)="openDriver(element.lastMileId)">
                <mat-icon class="icon-colour"> visibility </mat-icon>
              </button>
            </div>
          </div>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10]" showFirstLastButtons> </mat-paginator>
  </ng-container>
  <ng-template #noDrivers>
    <div style="display: flex; align-items: center; justify-content: center; width: 100%; height: 15%; font-size: 16px; font-weight: bold;">
      No drivers found. Please check your filter options. 
    </div>
  </ng-template>
  
</ng-template>
