import { Injectable } from '@angular/core';
import axios from 'app/api/axios';
import { Observable, Subject } from 'rxjs';
import Notification from './notification';

interface Email {
  emailAddresses: string[];
  emailSubject: string;
  emailContent: string;
}
@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private subject = new Subject<Notification>();

  publish(notification: Notification): void {
    this.subject.next(notification);
  }

  publication(): Observable<any> {
    return this.subject.asObservable();
  }

  sendEmail(emailContent: Email): void {
    axios({
      method: 'POST',
      url: '/communication/sent-email',
      data: emailContent,
    });
  }
}
