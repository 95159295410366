import {Component, Input} from '@angular/core';
import {FinancialSummaryProps} from './financial-summary.interfaces';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-financial-summary',
  templateUrl: './financial-summary.component.html',
  styleUrls: ['./financial-summary.component.scss'],
})
export class FinancialSummaryComponent implements FinancialSummaryProps {
  @Input() data;
  @Input() border: boolean;
  currency_symbol = environment.currency_symbol;
}
