<ng-container *ngIf="(isCourierRecreateLoading$ | async); else showAll">
  <app-loader [displayLoader]="true" [loadingMessage]="'Sending order to courier'"></app-loader>
</ng-container>

<ng-template #showAll>
  <section *ngIf="fsOrderDetails">
    <app-section>
      <app-flex-container [justifyContent]="JustifyContent.SpaceBetween">
        <app-basic-button class="back-button" [title]="'Back'" [type]="ButtonTypes.Primary" (click)="backButton()">
        </app-basic-button>
        <app-basic-button-group
          [buttons]="viewTabs"
          [currentTab]="tabs$[3]?.label === null ? 'Details' : tabs$[3]?.label"
          [buttonRemPadding]="1"
          (buttonClicked)="handleTabNavigation($event)"
        ></app-basic-button-group>
        <app-flex-container class="flex-right" [justifyContent]="JustifyContent.SpaceEvenly">
          <app-basic-button
            *ngIf="fsOrderDetails?.FleetAllocation !== 'None'"
            class="activate"
            [title]="'Edit Activation Time'"
            [type]="ButtonTypes.Green"
            (click)="editActivationTime()"
            [hidden]="
              (((lotteries && lotteries[lotteries?.length - 1]?.status < 40) || fsOrderDetails?.assigned_drivers) &&
                lotteries?.length > 0) ||
              fsOrderDetails?.assigned_drivers?.length > 0 ||
              fsOrderDetails?.OrderState === 'Alert' ||
              fsOrderDetails?.OrderState === 'Cancelled' ||
              fsOrderDetails?.OrderState === 'Completed' ||
              fsOrderDetails?.OrderState === 'InProgress'
            "
            [processing]="processing"
          ></app-basic-button>
          <app-basic-button
            *ngIf="fsOrderDetails?.BucketId"
            [title]="'View Bucket'"
            [type]="ButtonTypes.Primary"
            (click)="goToBucket()"
          ></app-basic-button>
        </app-flex-container>
      </app-flex-container>
    </app-section>
    <app-flex-container *ngIf="fsOrderDetails" class="admin-actions" [justifyContent]="JustifyContent.SpaceBetween">
      <!-- Admin Actions -->
      <div class="admin-actions-container">
        <app-menu-dropdown
          [iconType]="icons.types.AdminDashboardOnGrid"
          [iconSize]="1.5"
          [iconColor]="icons.colors.Grey5"
          [items]="menuOptions"
          [dropDownWidthRem]="22"
          (itemClick)="handleActionClick($event)"
        >
        </app-menu-dropdown>
        <!-- Event Store  -->
        <app-icon
          *ngIf="user.is_admin"
          [icon]="icons.types.EventStoreOnGrid"
          [size]="1.5"
          [color]="icons.colors.Grey5"
          [ngClass]="'admin-button'"
          [showHover]="true"
          (click)="handleESActionClick()"
        ></app-icon>
        <app-icon
          *ngIf="user.is_admin"
          [icon]="icons.types.FirebaseOnGrid"
          [size]="1.5"
          [color]="icons.colors.Grey5"
          [ngClass]="'admin-button'"
          [showHover]="true"
          (click)="handleFirebaseClick()"
        ></app-icon>
        <div class="viewing-icon">
          <app-icon
            *ngIf="user.is_admin && viewers.length > 0"
            [icon]="icons.types.Eye"
            [size]="1.5"
            [color]="icons.colors.Grey5"
            [ngClass]="'admin-button'"
            [showHover]="true"
            [title]="'Currently viewing: ' + viewers.length"
            (click)="showCurrentlyViewing()"
          >
          </app-icon>
          <div class="icon-count" *ngIf="viewers.length > 1">
            {{ viewers.length }}
          </div>
        </div>
      </div>
      <div [ngStyle]="{ paddingRight: '1rem' }">
        <h4>
          {{ fsOrderDetails?.OrderState + ' ' | humanize | uppercase }}
          <app-icon [icon]="icons.types.Dot" [size]="2" [color]="fsOrderDetails?.OrderState | tripHealth"> </app-icon>
        </h4>
      </div>
    </app-flex-container>
    <app-section>
      <app-summary-line [loading]="!fsOrderDetails">
        <div>
          Activation Time:
          {{ fsOrderDetails.ActivationDate | activationDate : fsOrderDetails.ScheduledDate | date : 'medium' }}
          |
          {{ fsOrderDetails?.BucketId ? 'Bucket Id: ' + fsOrderDetails?.BucketId : 'On Demand' }}
          | {{ fsOrderDetails?.IsRoundTrip ? 'Round Trip' : 'One Way' }} |
          {{ fsOrderDetails?.UseFixedSequencing ? 'Fixed' : 'Variable' }}
        </div>
  
        <div *ngIf="fsOrderDetails && fsOrderDetails.AssignmentMethod && fsOrderDetails.DriverNames?.length > 0">
          <div>
            Assignment Method:
            {{ fsOrderDetails.AssignmentMethod | assignmentMethod | async }}
          </div>
        </div>
  
        <div class="format-column">
          <div>
            {{
              fsOrderDetails?.Reservation?.Username
                ? 'Assigned User: ' + fsOrderDetails?.Reservation?.Username
                : 'Unassigned'
            }}
          </div>
          <div>{{ fsOrderDetails?.CustomerReference }}</div>
        </div>
      </app-summary-line>
    </app-section>
  
    <app-section *ngIf="fsOrderDetails && (tabs$[3]?.label === 'Details' || tabs$[3]?.label === null)">
      <div class="tabs">
        <span class="left">
          <div
            class="tab"
            (click)="activeTab = 'Summary'"
            [ngClass]="{
              active: activeTab === 'Summary'
            }"
          >
            <h4><app-icon [icon]="icons.types.Details" [size]="1.5" [color]="icons.colors.White"></app-icon>Summary</h4>
          </div>
          <div class="drivers" *ngIf="driverList.length > 0">
            <div *ngFor="let item of driverList">
              <div
                *ngIf="item.id"
                (click)="item.lastMileId !== null ? showDriverLastMile(item.lastMileId) : showDriverParcels(item)"
                [ngClass]="{
                  active: activeTab === item.name || activeTab === item.lastMileId
                }"
                class="tab"
              >
                <h4>
                  <app-icon [icon]="icons.types.Steering" [size]="1.5" [color]="icons.colors.White"> </app-icon
                  >{{ item.name ? item.name : 'N/A' }}
                </h4>
              </div>
            </div>
          </div>
        </span>
        <div
          class="tab pull-right"
          (click)="activeTab = 'History'"
          [ngClass]="{
            active: activeTab === 'History'
          }"
          *ngIf="fsOrderDetails?.FleetAllocation != 'None'"
        >
          <h4>
            <app-icon [icon]="icons.types.History" [size]="1.5" [color]="icons.colors.White"></app-icon>Route History
          </h4>
        </div>
      </div>
  
      <ng-template ngFor let-lastMileId [ngForOf]="uniqueLastMileIds">
        <section *ngIf="activeTab === lastMileId">
          <div *ngIf="hasActiveParcels(lastMileId)">
            <app-last-mile
              [fleetAllocation]="fsOrderDetails?.FleetAllocation"
              class="last-mile-container"
              [orderId]="fsOrderDetails?.Id"
              [lastMileId]="lastMileId"
              (lastMileEvent)="resetToDefaultView()"
            >
            </app-last-mile>
          </div>
          <div *ngIf="hasStagedParcels(lastMileId)">
            <app-flex-container class="route-container" [justifyContent]="JustifyContent.FlexStart">
              <app-last-mile-staged-assignments [lastMileId]="lastMileId" [Parcels]="driverParcels" [showMap]="true">
              </app-last-mile-staged-assignments>
            </app-flex-container>
          </div>
        </section>
      </ng-template>
  
      <ng-template ngFor let-item [ngForOf]="driverList">
        <section class="margin-top-2" *ngIf="activeTab === item.name; else lastMileIdCheck">
          <heading [icon]="icons.types.Details" [color]="icons.colors.Blue">
            <h4 class="heading-title">
              <strong> {{ 'Driver Parcels' }}</strong>
            </h4>
            <button *ngIf="activeDriverId" (click)="goToDriver(activeDriverId)">Go to Driver</button>
          </heading>
          <app-table
            [showAnnotations]="true"
            [columns]="parcelColumns"
            [dataMap]="parcelDataMap"
            [rawData]="driverParcels"
            [rowsSelectable]="fsOrderDetails?.OrderState !== 'Completed'"
            [bulkActions]="parcelBulkActions"
            (actionTaken)="handleTableActions($event)"
            [zeroDataMessage]="'No Shipments to Display'"
          >
          </app-table>
        </section>
        <ng-template #lastMileIdCheck>
          <section *ngIf="uniqueLastMileIds.size === 0 && activeTab === item.lastMileId" class="margin-top-2">
            <heading [icon]="icons.types.Details" [color]="icons.colors.Blue">
              <h4 class="heading-title">
                <strong> {{ 'Driver Parcel History' }}</strong>
              </h4>
              <button *ngIf="activeDriverId" (click)="goToDriver(activeDriverId)">Go to Driver</button>
            </heading>
            <app-table
              [showAnnotations]="true"
              [columns]="parcelColumns"
              [dataMap]="parcelDataMap"
              [rawData]="driverParcels"
              [rowsSelectable]="false"
              [zeroDataMessage]="'No Shipments to Display'"
            >
            </app-table>
          </section>
        </ng-template>
      </ng-template>
  
      <section *ngIf="activeTab === 'History'">
        <app-new-trips-history [routes]="fsOrderDetails?.Routes" [polyline]="polyline?.polyline"></app-new-trips-history>
      </section>
  
      <section *ngIf="activeTab === 'Summary'">
        <app-new-trips-sumnmary
          [Parcels]="fsOrderDetails?.Parcels"
          [businessReferenceBreakdown]="businessReferenceBreakdown"
          [uniqueWaypoints]="uniqueWaypoints.size"
          [isAdmin]="user?.is_admin"
          [lottery]="lotteries[0]"
          [vehicle]="fsOrderDetails?.Vehicle"
          [quote]="fsOrderDetails?.Quote"
          [username]="fsOrderDetails?.Reservation?.Username ?? null"
        >
        </app-new-trips-sumnmary>
  
        <div class="title" *ngIf="fsOrderDetails?.FleetAllocation === 'None'">
          <app-icon-with-text [text]="'COURIER ORDERS'" [textBold]="true" [sizeRem]="1" [color]="icons.colors.White">
          </app-icon-with-text>
  
          <app-icon-with-text
            [text]="'COURIER NAME: ' + fsOrderDetails?.CourierName"
            [textBold]="true"
            [sizeRem]="1"
            [color]="icons.colors.White"
          >
          </app-icon-with-text>
        </div>
  
        <app-fs-business-reference-breakdown
          [orderPODs]="orderPODs"
          [context]="fsOrderDetails?.FleetAllocation === 'None' ? 'courier' : 'order'"
          [orderId]="fsOrderDetails.Id"
          [businessReferenceBreakdown]="businessReferenceBreakdown"
          (dataModified)="constructMapData()"
          [orderWaybill]="order_waybill"
        >
        </app-fs-business-reference-breakdown>
  
        <app-new-trips-map
          [tripPolylines]="fsOrderDetails?.FleetAllocation === 'None' ? [] : [polyline?.polyline]"
          [markers]="markers"
        ></app-new-trips-map>
      </section>
  
      <!-- <app-new-trips-failed-courier [failedCourierOrders]="fsOrderDetails?.failed_courier_orders"
        *ngIf="fsOrderDetails?.failed_courier_orders?.length"></app-new-trips-failed-courier> -->
    </app-section>
  
    <app-new-trips-timeline
      [courierIds]="fsOrderDetails?.courier_orders"
      [failedCourierOrders]="fsOrderDetails?.failed_courier_orders"
      [orderId]="id"
      *ngIf="tabs$[3]?.label === 'Timeline'"
    >
    </app-new-trips-timeline>
  
    <app-new-trips-lottery
      [lotteries]="lotteries"
      [drivers]="fsOrderDetails?.assigned_drivers"
      [orderId]="fsOrderDetails?.Id"
      *ngIf="tabs$[3]?.label === 'Lottery'"
      [fleet]="fsOrderDetails?.FleetAllocation"
      [HasPreferredDrivers]="fsOrderDetails?.HasPreferredDrivers"
    >
    </app-new-trips-lottery>
    <app-new-trips-notes
      [fsOrderDetails]="fsOrderDetails"
      [user]="user"
      [orderId]="fsOrderDetails?.Id"
      [orderNotes]="orderNotes"
      *ngIf="tabs$[3]?.label === 'Notes'"
    ></app-new-trips-notes>
  </section>
  
  <app-section *ngIf="!fsOrderDetails">
    <div class="loading-route">
      <h3>Loading Order Details..</h3>
    </div>
  </app-section>
</ng-template>


