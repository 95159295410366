<app-grid-container [columns]="'repeat(2, 1fr)'" [colGap]="'2rem'"
  [padding]="'3rem'">
  <label>
    Address:
    <p>{{waypoint?.address.formatted_address}}</p>
  </label>
  <label *ngIf="waypoint?.address.complex">
    Business/Apartment Name:
    <p>{{waypoint?.address.complex}}</p>
  </label>
  <label *ngIf="waypoint?.address.unit_no">
    Unit/Floor:
    <p>{{waypoint?.address.unit_no}}</p>
  </label>
  <app-grid-container  [columns]="'repeat(5, 1fr)'" [colGap]="'2rem'">
    <label>
      Name:
      <p>{{waypoint?.contact.name}}</p>
    </label>
    <label>
      Email:
      <p>{{waypoint?.contact.email}}</p>
    </label>
    <label>
      Phone:
      <p>{{waypoint?.contact.cellphone}}</p>
    </label>
    <label *ngIf="waypoint?.special_instructions">
      Special Instructions:
      <p>{{waypoint?.special_instructions}}</p>
    </label>
    <label *ngIf="waypoint?.parcels">
      Parcels:
      <p>{{waypoint?.parcels?.length}}</p>
    </label>
  </app-grid-container>
 
</app-grid-container>