import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { CardProps } from './card.interfaces';
import { UiThemes } from '../../interfaces/ui.interfaces';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements CardProps, OnChanges {
  @Input() actions;
  @Input() title;
  @Input() subTitle;
  @Input() padding = '4rem 5rem 2rem';
  @Input() theme = UiThemes.Light;

  @Output() actionClick = new EventEmitter<string>();

  public classes: string[];

  ngOnChanges() {
    this.classes = this.setClasses();
  }

  setClasses() {
    const classes: string[] = [];
    if (this.theme) {
      classes.push(this.theme);
    }

    return classes;
  }

  handleActionClick($event) {
    this.actionClick.emit($event);
  }
}
