<div *ngIf="podData?.Collection">
  <h4>Collection:</h4>
  <app-flex-container [padding]="'0rem'" [justifyContent]="justifyContent.SpaceBetween">
    <div class="sig" *ngIf="podData.Collection.SignatureUrl">
      <app-image-with-caption
        [widthRem]="12"
        [heightRem]="7"
        [src]="podData.Collection.SignatureUrl"
        [download]="true"
        [showCaption]="false"
      >
      </app-image-with-caption>
    </div>
    <div *ngIf="podData.Collection.VerificationPhotoUrl" class="photo">
      <app-image-with-caption
        [widthRem]="12"
        [heightRem]="7"
        [src]="podData.Collection.VerificationPhotoUrl"
        [download]="true"
        [showCaption]="false"
      >
      </app-image-with-caption>
    </div>
    <div class="details">
      <app-grid-container [columns]="'repeat(2, 1fr)'" [padding]="'0rem'" [colGap]="'2rem'" [rowGap]="'0rem'">
        <div *ngIf="podData.Collection.Signee">
          <span> Received By: </span>
          <strong>{{ podData.Collection.Signee }}</strong>
        </div>
        <div *ngIf="podData.Collection.Relationship">
          <span> Relationship: </span>
          <strong>{{ podData.Collection.Relationship }}</strong>
        </div>
        <div *ngIf="podData.Collection.DriverName">
          <span> Driver Name: </span>
          <strong>{{ podData.Collection.DriverName }}</strong>
        </div>
        <div *ngIf="podData.Collection.Timestamp">
          <span> Timestamp: </span>
          <strong>{{ podData.Collection.Timestamp.toMillis() | date: 'd/M/yyyy, h:mm a' }}</strong>
        </div>
        <div *ngIf="podData.Collection.Latitude || podData.Collection.Longitude">
          <span> Location: </span>
          <strong>{{ podData.Collection.Latitude }}, {{ podData.Collection.Longitude }} </strong>
        </div>
      </app-grid-container>
    </div>
  </app-flex-container>
</div>
<div *ngIf="podData?.Delivery">
  <h4>Delivery:</h4>
  <app-flex-container [padding]="'0rem'" [justifyContent]="justifyContent.SpaceBetween">
    <div *ngIf="podData.Delivery.SignatureUrl" class="sig">
      <app-image-with-caption
        [widthRem]="12"
        [heightRem]="7"
        [src]="podData.Delivery.SignatureUrl"
        [download]="true"
        [showCaption]="false"
      >
      </app-image-with-caption>
    </div>
    <div *ngIf="podData.Delivery.VerificationPhotoUrl" class="photo">
      <app-image-with-caption
        [widthRem]="12"
        [heightRem]="7"
        [src]="podData.Delivery.VerificationPhotoUrl"
        [download]="true"
        [showCaption]="false"
      >
      </app-image-with-caption>
    </div>
    <div class="details">
      <app-grid-container [columns]="'repeat(2, 1fr)'" [padding]="'0rem'" [colGap]="'2rem'" [rowGap]="'0rem'">
        <div *ngIf="podData.Delivery.Signee">
          <span> Received By: </span>
          <strong>{{ podData.Delivery.Signee }}</strong>
        </div>
        <div *ngIf="podData.Delivery.Relationship">
          <span> Relationship: </span>
          <strong>{{ podData.Delivery.Relationship }}</strong>
        </div>
        <div *ngIf="podData.Delivery.DriverName">
          <span> Driver Name: </span>
          <strong>{{ podData.Delivery.DriverName }}</strong>
        </div>
        <div *ngIf="podData.Delivery.Timestamp">
          <span> Timestamp: </span>
          <strong>{{ podData.Delivery.Timestamp.toMillis() | date: 'd/M/yyyy, h:mm a' }}</strong>
        </div>
        <div *ngIf="podData.Delivery.Latitude || podData.Delivery.Longitude">
          <span> Location: </span>
          <strong>{{ podData.Delivery.Latitude }}, {{ podData.Delivery.Longitude }} </strong>
        </div>
      </app-grid-container>
    </div>
  </app-flex-container>
</div>
<div *ngIf="podData?.Return">
  <h4>Return:</h4>
  <app-flex-container 
    [padding]="'0rem'"
    [justifyContent]="justifyContent.SpaceBetween">
    <div *ngIf="podData.Return.SignatureUrl" class="sig">
      <app-image-with-caption
        [widthRem]="12"
        [heightRem]="7"
        [src]="podData.Return.SignatureUrl"
        [download]="true"
        [showCaption]="false"
      >
      </app-image-with-caption>
    </div>
    <div *ngIf="podData.Return.VerificationPhotoUrl" class="photo">
      <app-image-with-caption
        [widthRem]="12"
        [heightRem]="7"
        [src]="podData.Return.VerificationPhotoUrl"
        [download]="true"
        [showCaption]="false"
      >
      </app-image-with-caption>
    </div>
    <div class="details">
      <app-grid-container [columns]="'repeat(2, 1fr)'" [padding]="'0rem'" [colGap]="'2rem'" [rowGap]="'0rem'">
        <div *ngIf="podData.Return.Signee">
          <span> Received By: </span>
          <strong>{{ podData.Return.Signee }}</strong>
        </div>
        <div *ngIf="podData.Return.Relationship">
          <span> Relationship: </span>
          <strong>{{ podData.Return.Relationship }}</strong>
        </div>
        <div *ngIf="podData.Return.DriverName">
          <span> Driver Name: </span>
          <strong>{{ podData.Return.DriverName }}</strong>
        </div>
        <div *ngIf="podData.Return.Timestamp">
          <span> Timestamp: </span>
          <strong>{{ podData.Return.Timestamp.toMillis() | date: 'd/M/yyyy, h:mm a' }}</strong>
        </div>
        <div *ngIf="podData.Return.Latitude || podData.Return.Longitude">
          <span> Location: </span>
          <strong>{{ podData.Return.Latitude }}, {{ podData.Return.Longitude }} </strong>
        </div>
      </app-grid-container>
    </div>
  </app-flex-container>
</div>
