import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import Toast from './toast.interfaces';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private subject = new Subject<Toast>();

  publish(toast: Toast): void {
    this.subject.next(toast);
  }

  publication(): Observable<any> {
    return this.subject.asObservable();
  }
}
