<app-modal
  [open]="true"
  [actions]="actions"
  [highlightPrimaryAction]="true"
  [size]="ModalSizes.Large"
  (handleAction)="handleAction($event)"
>
  <div class="modal-content">
    <heading [icon]="iconTypes.Steering" [color]="uiColors.Blue">
      <h4 class="heading-title">Action Parcels</h4>
    </heading>
    <div class="modal-body">
      <div *ngFor="let parcel of active_parcels">
        <app-grid-container [columns]="'3fr 2fr 3fr'" [colGap]="'2rem'" [rowGap]="'2rem'" [padding]="'0 2rem'">
          <h4>{{ parcel.destination }}</h4>
          <h6>{{ parcel.parcel_waybill }}</h6>
          <app-dropdown
            class="dropdown"
            [activeItem]="parcel.result ? resultOptions.get(parcel.result) : 'Select Result'"
            [dropdownTheme]="'light'"
          >
            <div dropdownRef>
              <ng-template ngFor let-result [ngForOf]="resultOptions | keyvalue">
                <a
                  *ngIf="showOption(parcel.order_parcel_state, result.key)"
                  class="dropdown-item"
                  (click)="parcel['result'] = result.key"
                  >{{ result.value }}</a
                >
              </ng-template>
            </div>
          </app-dropdown>
        </app-grid-container>
      </div>
    </div>
  </div>
</app-modal>
