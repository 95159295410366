import { Component } from '@angular/core';
import { IconTypes } from '../../shared/icon/icon.interfaces';
import { UiColors } from '../../interfaces/ui.interfaces';
import { TableColumn, TableDataMap, TableActionTaken } from 'app/shared/table/table.interfaces';
import { Router } from '@angular/router';
import axios from 'app/api/axios';
import { Store } from '@ngrx/store';
import { selectorActingAs, selectorUserPermissions } from 'app/auth/auth.reducer';
import { ManageBucketService } from '../buckets/manage-bucket.service';
import { environment } from 'environments/environment';
import { ActingAs } from 'app/interfaces/auth.interfaces';
import { SimpleModalService } from 'ngx-simple-modal';
import { ParcelTimelineComponent } from 'app/shared/parcel-timeline/parcel-timeline.component';
import { Location } from '@angular/common';
import { NotificationsService } from 'app/shared/notifications/notifications.service';
import { NotificationTypeEnum } from 'app/shared/shared.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss'],
})
export class GlobalSearchComponent {
  data;
  showViewMore = true;
  numberOfResults = 10;
  processing = false;
  availableWarehouses;
  user_permissions;
  isAdminView: boolean = false;
  buttonTypes = ButtonTypes;
  public Icons = {
    Colors: UiColors,
    Types: IconTypes,
  };
  environment = environment;

  public searchResultColumns2: TableColumn[] = [
    { name: 'bucket_id', displayName: 'Bucket Id' },
    { name: 'picup_aggregate_id', displayName: 'picup_aggregate_id', hidden: true },
    { name: 'courier_order_aggregate_id', displayName: 'courier_order_aggregate_id', hidden: true },
    { name: 'order_aggregate_id', displayName: 'order_aggregate_id', hidden: true },
    { name: 'delivery_date', displayName: 'Bucket Date', sortable: true },
    { name: 'business_reference', displayName: 'Business Reference', sortable: true },
    { name: 'consignment', displayName: 'Consignment' },
    { name: 'customer_email', displayName: 'Customer Email' },
    { name: 'customer_phone', displayName: 'Customer Phone' },
    { name: 'customer_name', displayName: 'Customer Name' },
    { name: 'destination_address', displayName: 'Destination' },
    { name: 'parcel_waybill', displayName: 'parcel_waybill', hidden: true },
  ];

  public searchResultDataMap2: TableDataMap = {
    cells: {
      bucket_id: { column: 'Bucket ID', map: 'bucket_id' },
      picup_aggregate_id: { column: 'Picup ID', map: 'picup_aggregate_id' },
      courier_order_aggregate_id: { column: 'Courier ID', map: 'courier_order_aggregate_id' },
      order_aggregate_id: { column: 'order_aggregate_id', map: 'order_aggregate_id' },
      delivery_date: { column: 'Bucket Date', map: 'delivery_date', displayPipeArgs: ['date', 'mediumDate'] },
      business_reference: { column: 'business_reference', map: 'business_reference' },
      consignment: { column: 'consignment', map: 'consignment' },
      customer_email: { column: 'customer_email', map: 'customer_email' },
      customer_phone: { column: 'customer_phone', map: 'customer_phone' },
      customer_name: { column: 'customer_name', map: 'customer_name' },
      destination_address: { column: 'destination_address', map: 'destination_address' },
      parcel_waybill: { column: 'parcel_waybill', map: 'parcel_waybill' },
    },
  };

  public searchResultColumns: TableColumn[] = [
    { name: 'bucket_id', displayName: 'Bucket Id' },
    { name: 'picup_aggregate_id', displayName: 'picup_aggregate_id', hidden: true },
    { name: 'courier_order_aggregate_id', displayName: 'courier_order_aggregate_id', hidden: true },
    { name: 'order_aggregate_id', displayName: 'order_aggregate_id', hidden: true },
    { name: 'delivery_date', displayName: 'Bucket Date', sortable: true },
    { name: 'business_reference', displayName: 'Business Reference', sortable: true },
    { name: 'consignment', displayName: 'Consignment' },
    { name: 'customer_email', displayName: 'Customer Email' },
    { name: 'customer_name', displayName: 'Customer Name' },
    { name: 'address_line1', displayName: 'Address Line 1' },
    { name: 'address_line2', displayName: 'Address Line 2' },
    { name: 'address_line3', displayName: 'Address Line 3' },
    { name: 'waypoint_index', displayName: 'waypoint_index', hidden: true },
  ];

  public searchResultDataMap: TableDataMap = {
    cells: {
      bucket_id: { column: 'Bucket ID', map: 'bucket_id' },
      picup_aggregate_id: { column: 'Picup ID', map: 'picup_aggregate_id' },
      courier_order_aggregate_id: { column: 'Courier ID', map: 'courier_order_aggregate_id' },
      order_aggregate_id: { column: 'order_aggregate_id', map: 'order_aggregate_id' },
      delivery_date: { column: 'Bucket Date', map: 'delivery_date', displayPipeArgs: ['date', 'mediumDate'] },
      business_reference: { column: 'business_reference', map: 'business_reference' },
      consignment: { column: '', map: 'consignment' },
      customer_email: { column: '', map: 'customer_email' },
      customer_name: { column: '', map: 'customer_name' },
      address_line1: { column: '', map: 'address_line1' },
      address_line2: { column: '', map: 'address_line2' },
      address_line3: { column: '', map: 'address_line3' },
      waypoint_index: { column: 'waypoint_indx', map: 'waypoint_index' },
    },
  };

  public tablePrimaryActions = [
    { event: 'timeline', title: 'View Timeline', icon: IconTypes.History, dependency: 'business_reference' },
    { event: 'bucket', title: 'Go to Bucket', icon: IconTypes.Bucket, dependency: 'bucket_id' },
    { event: 'trip', title: 'Go to Trip', icon: IconTypes.Trip, dependency: 'picup_aggregate_id' },
    { event: 'order', title: 'Go to Trip', icon: IconTypes.Trip, dependency: 'order_aggregate_id' },
    { event: 'courier', title: 'Go to Courier Order', icon: IconTypes.Trip, dependency: 'courier_order_aggregate_id' },
  ];

  shownResults = [];
  searchResult: any;
  searchText: string = '';
  actingAs: ActingAs;
  placeholderText = 'Search by Name, Email, Address, Suburb, Postal Code, Trip ID, Bucket ID';

  constructor(
    private router: Router,
    public store: Store<any>,
    private manageBucketService: ManageBucketService,
    private simpleModalService: SimpleModalService,
    private location: Location,
    private notificationsService: NotificationsService
  ) {
    if (environment.appVersion === 2) {
      this.placeholderText = 'Search by Name, Business Reference or Customer Reference';
    }
    this.store.select(selectorUserPermissions).subscribe((next) => {
      this.user_permissions = next;
      if (this.user_permissions?.warehouses?.length) {
        this.availableWarehouses = this.user_permissions.warehouses;
      }
    });
    this.store.select(selectorActingAs).subscribe((next) => {
      this.actingAs = next;
    });

    const loc: string = this.location.path();
    if (loc === '/admin/search-orders') {
      this.isAdminView = true;
      this.showViewMore = false;
    }
  }

  searchOrders(): void {
    if (this.searchText.length < 3) {
      this.notificationsService.publish({
        message: 'Search has a minimum 3 character requirement',
        type: NotificationTypeEnum.Error,
      });
      return;
    }
    this.shownResults = [];
    this.searchResult = undefined;
    this.processing = true;
    const url = environment.appVersion === 1 ? '/search/global-order' : '/generic/cqrs/search-orders';
    const businessId = this.isAdminView ? null : this.actingAs ? this.actingAs.id : null;
    axios({
      method: 'POST',
      url: url,
      data: {
        search: this.searchText,
        business_id: businessId,
        warehouse_ids: this.availableWarehouses ? this.availableWarehouses : null,
      },
    }).then((result) => {
      this.processing = false;
      this.searchResult = result.data.reverse();
      this.showResults();
    });
  }

  respondToTableAction($event: TableActionTaken): void {
    switch ($event.action.event) {
      case 'bucket': {
        const bucket_id = $event.rows[0].cells.find((cell) => cell.column === 'bucket_id').value;
        this.manageBucketService.setActiveBucket(bucket_id);
        break;
      }
      case 'trip': {
        const picupId = $event.rows[0].cells.find((cell) => cell.column === 'picup_aggregate_id').value;
        const waypointIndex = $event.rows[0].cells.find((cell) => cell.column === 'waypoint_index').value;
        if (this.isAdminView) {
          window.open(window.location.origin + '/dashboard/trip-view/' + picupId, '_blank');
        } else {
          this.router.navigate(['/dashboard/trip-view/', picupId], { queryParams: { waypointIndex: waypointIndex } });
        }
        break;
      }
      case 'order': {
        const orderId = $event.rows[0].cells.find((cell) => cell.column === 'order_aggregate_id').value;
        const orderWaybill = $event.rows[0].cells.find((cell) => cell.column === 'parcel_waybill').value;
        if (this.isAdminView) {
          window.open(`${window.location.origin}/dashboard/orders/trip/${orderId}?waybill=${orderWaybill}`, '_blank');
        } else {
          this.router.navigate(['/dashboard/orders/trip/', orderId], { queryParams: { waybill: orderWaybill } });
        }
        break;
      }
      case 'courier': {
        const courierId = $event.rows[0].cells.find((cell) => cell.column === 'courier_order_aggregate_id').value;
        if (this.isAdminView) {
          window.open(`${window.location.origin}/dashboard/courier-order/${courierId}`, '_blank');
        } else {
          this.router.navigate(['/dashboard/courier-order/', courierId]);
        }
        break;
      }
      case 'timeline': {
        const order_id = $event.rows[0].cells.find((cell) => cell.column === 'order_aggregate_id').value;
        this.simpleModalService.addModal(ParcelTimelineComponent, {
          order_id,
        });
        break;
      }
    }
  }

  showResults() {
    let i = 0;
    if (!this.numberOfResults) {
      this.shownResults = this.searchResult;
      this.showViewMore = false;
    }
    this.searchResult.forEach((element) => {
      i++;
      if (i <= this.numberOfResults) {
        this.shownResults.push(element);
      }
    });
    this.calculateViewMore(this.searchResult, this.shownResults);
  }

  loadMoreTableData() {
    this.shownResults = [];
    this.numberOfResults = this.numberOfResults + 10;
    this.showResults();
    this.calculateViewMore(this.searchResult, this.shownResults);
  }

  showAllData() {
    this.shownResults = [];
    this.numberOfResults = null;
    this.showViewMore = false;
    this.showResults();
  }

  calculateViewMore(comparison, shown) {
    if (comparison.length <= shown.length) {
      this.showViewMore = false;
    } else {
      if (!this.isAdminView) {
        this.showViewMore = true;
      }
    }
  }
}
