import { Input, OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NewTripsService } from '../../new-trips.service';

@Component({
  selector: 'app-historic-waypoint-route',
  templateUrl: './historic-waypoint-route.component.html',
  styleUrls: ['./historic-waypoint-route.component.scss'],
})
export class HistoricWaypointRouteComponent implements OnInit, OnDestroy {
  @Input() waypointRouteId;
  @Input() polyline;
  Route;
  firebaseSubscription: Subscription;

  constructor(private newTripsService: NewTripsService) {}

  ngOnInit(): void {
    this.firebaseSubscription = this.newTripsService.getWaypointRoute(this.waypointRouteId).subscribe((route) => {
      this.Route = route;
    });
  }


  ngOnDestroy() {
    this.firebaseSubscription.unsubscribe();
  }
}
