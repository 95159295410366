import { Pipe, PipeTransform } from '@angular/core';
import { Warehouse } from 'app/interfaces/auth.interfaces';

@Pipe({
  name: 'warehouseName'
})
export class WarehouseNamePipe implements PipeTransform {

  transform(warehouseId: string, warehouses: Warehouse[]): string {
    return warehouses?.find((x) => x.id === warehouseId)?.name;
  }

}
