<div class="shadow-wrap">
  <heading [icon]="Icons.Types.Map" [color]="Icons.Colors.Orange">
    <h4 class="heading-title">Map</h4>
  </heading>
  <div class="map-wrap">
    <app-map
      #map
      [mapType]="mapTypes.Vetting" 
      [geoJson]="geoJson"
      [mapStyle]="{'height.px': 550}"
      [drawer]="{active: orderDetails, width:'40rem', edge: 'right'}" 
      [hideFilters]="orderDetails"
      (clickEvent)="mapClicked($event)">
    </app-map>
    <div *ngIf="orderDetails" class="map-details">
      <div class="actions-wrap">
        <div class="actions">
          <app-basic-button 
            [title]="'Fix Geocode'" 
            [type]="buttonTypes.Primary" 
            [active]="true"
            (click)="geocodeOrder(orderDetails.address_id, orderDetails.order_id)">
          </app-basic-button>
          <tooltip-button [title]="'Manage Order'" [orientation]="'bottom'" [type]="buttonTypes.Primary">
            <a class="manage-order-item" (click)="changeBucket()">Change Bucket</a>
            <a class="manage-order-item" (click)="editOrder()">Edit Order</a>
            <a class="manage-order-item" (click)="removeOrder()">Remove Order</a>
          </tooltip-button>
        </div>
        <svg-icon class="close-details" src="../../../../../assets/img/icons/back-arrow.svg" (click)="closeDetails()">
        </svg-icon>
      </div>
      <div class="meta">
        <span>{{ orderDetails?.status }}</span>
        <span>Address: #{{ orderDetails?.address_id }}</span>
      </div>
      <div class="addresses">
        <div>
          <h2>Customer Name</h2>
          <p>{{orderDetails?.customer_name}}</p>
        </div>
        <div>
          <h2>Customer Phone</h2>
          <p>{{orderDetails?.customer_phone}}</p>
        </div>
      </div>
      <div class="addresses">
        <div>
          <h2>Reference</h2>
          <p>{{orderDetails?.business_reference}}</p>
        </div>
        <div *ngIf="orderDetails?.consignment">
          <h2>Consignment</h2>
          <p>{{orderDetails?.consignment}}</p>
        </div>
      </div>
      <div class="addresses">
        <div>
          <h2>Geocoded Data</h2>
          <p>{{orderDetails?.geocoded_address}}</p>
        </div>
        <div>
          <h2>Original Data</h2>
          <p>{{orderDetails?.client_address}}</p>
        </div>
      </div>
      <div *ngIf="orderDetails?.special_instructions">
        <h2>Special Instructions</h2>
        <p>{{orderDetails?.special_instructions}}</p>
      </div>
    </div>
  </div>
</div>