 <!-- Alert Orders -->
 <!-- TODO   [activeWarehouseFilter]="activeWarehouseFilter"-->
 <app-collapsible-order-table
 *ngIf="alertFirestoreTrips | async as alertTrips"
 [tripType]="'Alert Trips'"
 [trips]="alertTrips"
 [tripsTotal]="alertTotal"
 [objectType]="CollapsibleTableTypeEnum.Order"
 [icon]="Icons.Types.Trip"
 [color]="Icons.Colors.Red"
 [tableDataMap]="fsAlertOrderTableDataMap"
 [tableColumns]="fsAlertOrderTableColumns"
 [primaryActions]="postDispatchTablePrimaryActions"
 [defaultDisplayAmount]="alertAmount"
 [showPagination]="true"
 (paginateEvent)="handleAlertTrips($event)"
 (tableSortChanged)="handleTripsSortChange($event)"
 >
 </app-collapsible-order-table>
