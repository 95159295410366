<div class="mainbanner">
  <div class="homepage-container2">
    <!--Menu Component-->
    <div class="heading-menu">
      <div class="heading-logo">
        <div class="desk-logo">
          <a routerLink="/"><img src="/assets/img/picup-logo.svg" alt="" /></a>
        </div>
        <div class="mobile-logo">
          <a routerLink="/"><img src="/assets/img/static-site/client-logos/logo-mobile.svg" alt="" /></a>
        </div>
      </div>
      <div class="heading-links">
        <div class="menubtn" (click)="showMobileNav = !showMobileNav">
          <a routerLink="/"><img src="/assets/img/static-site/client-logos/white-button.svg" alt="" /></a>
        </div>
        <div class="desktop-nav">
          <ul>
            <li class="dropdown">
              <a class="dropbtn">SOLUTIONS <img src="/assets/img/static-site/client-logos/nav.svg" alt="" /> </a>
              <div class="dropdown-content">
                <app-grid-container [columns]="'repeat(2, 1fr)'" [colGap]="'1rem'" [rowGap]="'1rem'">
                  <a routerLink="/solutions/ondemand" class="dropdown-element" href="#"><img
                      [src]="'/assets/img/static-site/client-logos/picup-on-demand.png'" alt="" />On Demand</a>
                  <a routerLink="/solutions/enterprise" class="dropdown-element" href="#"><img
                      [src]="'/assets/img/static-site/client-logos/picup-enterprise.png'" alt="" />Logistics
                    Management</a>
                  <a routerLink="/solutions/geocoding" class="dropdown-element" href="#"><img
                      [src]="'/assets/img/static-site/client-logos/picup-map.png'" alt="" />Geocoding</a>
                  <a routerLink="/solutions/tms" class="dropdown-element" href="#"><img
                      [src]="'/assets/img/static-site/client-logos/picup-tms.png'" alt="" />Fleet Management</a>
                </app-grid-container>
              </div>
            </li>
            <li class="dropdown">
              <a class="dropbtn">INDUSTRIES {{ path }}<img src="/assets/img/static-site/client-logos/nav.svg"
                  alt="" /></a>
              <div class="dropdown-content">
                <app-grid-container [columns]="'repeat(2, 1fr)'" [colGap]="'1rem'" [rowGap]="'1rem'">
                  <a routerLink="/industries/ecommerce" class="dropdown-element" href="#"><img
                      [src]="'/assets/img/static-site/client-logos/ecommerce.png'" alt="" />Ecommerce</a>
                  <a routerLink="/industries/pharma" class="dropdown-element" href="#"><img
                      [src]="'/assets/img/static-site/client-logos/pharma.png'" alt="" />Pharma</a>
                  <a routerLink="/industries/grocery" class="dropdown-element" href="#"><img
                      [src]="'/assets/img/static-site/client-logos/grocery.png'" alt="" />Grocery</a>
                  <a routerLink="/industries/retail" class="dropdown-element" href="#"><img
                      [src]="'/assets/img/static-site/client-logos/retail.png'" alt="" />Retail</a>
                  <a routerLink="/industries/automotive" class="dropdown-element" href="#"><img
                      [src]="'/assets/img/static-site/client-logos/automotive.png'" alt="" />Automotive</a>
                </app-grid-container>
              </div>
            </li>
            <li>
              <!--<a routerLink="/resources">RESOURCES <img src="/assets/img/static-site/client-logos/nav.svg" alt="" /></a>-->
            </li>
            <li class="nondropdown"><a routerLink="/company">COMPANY</a></li>
            <li class="nondropdown"><a routerLink="/contact">CONTACT US</a></li>
            <li class="nondropdown"><a href="https://medium.com/picup" target="_blank">BLOG</a></li>
            <!-- <li class="nondropdown"><a routerLink="/carbon-neutral">CARBON NEUTRAL</a></li> -->

            <!--<li><a routerLink="/pricing">PRICING</a></li>-->

            <!-- TRACK WAYBILL START -->

            <!-- <div class="row gradient-bg actions-wrap">
              <div class="search-bar">
                <input
                  id="waybill"
                  type="text"
                  [(ngModel)]="searchText"
                  [placeholder]="placeholderText"
                  (keydown.enter)="searchOrders()"
                />
              </div>

              <p (click)="trackOrder()">TRACK</p>
            </div> -->

            <!-- TRACK WAYBILL ENDS -->
          </ul>
        </div>



        <!-- ORDER TRACKING MODEL STARTS -->
        <div class="tracking" id="trackingmodal">
          <app-icon class="closeModal" [icon]="IconTypes.Cross" [color]='UiColors.Grey3' [size]="4" (click)="close();">
          </app-icon>
          <div class="map">
            <h3>TRIP TRACKING</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
              ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
              esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
              non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
              ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
              esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
              non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
          </div>
        </div>

        <!-- ORDER TRACKING MODEL ENDS -->


        <!--MOBILE MENU STARTS-->
        <div class="mobile-nav" *ngIf="showMobileNav">
          <ul>
            <li class="dropdown-image" (click)="toggleMenu('solutionsMenu')">
              SOLUTIONS<img src="/assets/img/static-site/client-logos/nav2.svg" alt="" />
            </li>
            <ul class="mobile-child-menu" #solutionsMenu>
              <li (click)="showMobileNav = !showMobileNav">
                <a routerLink="/solutions/ondemand" class="dropdown-element" href="#"><img
                    [src]="'/assets/img/static-site/client-logos/picup-on-demand.png'" alt="" />On Demand</a>
              </li>
              <li (click)="showMobileNav = !showMobileNav">
                <a routerLink="/solutions/enterprise" class="dropdown-element" href="#"><img
                    [src]="'/assets/img/static-site/client-logos/picup-enterprise.png'" alt="" />Logistics
                  Management</a>
              </li>
              <li (click)="showMobileNav = !showMobileNav">
                <a routerLink="/solutions/tms" class="dropdown-element" href="#"><img
                    [src]="'/assets/img/static-site/client-logos/picup-tms.png'" alt="" />Fleet Management</a>
              </li>
              <li (click)="showMobileNav = !showMobileNav">
                <a routerLink="/solutions/geocoding" class="dropdown-element" href="#"><img
                    [src]="'/assets/img/static-site/client-logos/picup-map.png'" alt="" />Geocoding</a>
              </li>
            </ul>
            <li class="dropdown-image" (click)="toggleMenu('industriesMenu')">
              INDUSTRIES <img src="/assets/img/static-site/client-logos/nav2.svg" alt="" />
            </li>
            <ul class="mobile-child-menu" #industriesMenu>
              <li (click)="showMobileNav = !showMobileNav">
                <a routerLink="/industries/ecommerce" class="dropdown-element" href="#"><img
                    [src]="'/assets/img/static-site/client-logos/ecommerce.png'" alt="" />Ecommerce</a>
              </li>
              <li (click)="showMobileNav = !showMobileNav">
                <a routerLink="/industries/pharma" class="dropdown-element" href="#"><img
                    [src]="'/assets/img/static-site/client-logos/pharma.png'" alt="" />Pharma</a>
              </li>
              <li (click)="showMobileNav = !showMobileNav">
                <a routerLink="/industries/grocery" class="dropdown-element" href="#"><img
                    [src]="'/assets/img/static-site/client-logos/grocery.png'" alt="" />Grocery</a>
              </li>
              <li (click)="showMobileNav = !showMobileNav">
                <a routerLink="/industries/retail" class="dropdown-element" href="#"><img
                    [src]="'/assets/img/static-site/client-logos/retail.png'" alt="" />Retail</a>
              </li>
              <li (click)="showMobileNav = !showMobileNav">
                <a routerLink="/industries/automotive" class="dropdown-element" href="#"><img
                    [src]="'/assets/img/static-site/client-logos/automotive.png'" alt="" />Automotive</a>
              </li>
            </ul>
            <!--<li class="dropdown-image">RESOURCES <img src="/assets/img/static-site/client-logos/nav2.svg" alt="" /></li>-->
            <li (click)="showMobileNav = !showMobileNav" [routerLink]="'/company'">COMPANY</li>
            <li (click)="showMobileNav = !showMobileNav" [routerLink]="'/contact'">CONTACT US</li>
            <!-- <li routerLink="/carbon-neutral">CARBON NEUTRAL</li> -->
            <!-- TRACK WAYBILL START -->
            <!-- 
             <div class="row gradient-bg actions-wrap">
              <div class="search-bar">
                <input
                  id="waybill"
                  type="text"
                  [(ngModel)]="searchText"
                  [placeholder]="placeholderText"
                  (keydown.enter)="searchOrders()"
                />
              </div>

              <p (click)="trackOrder()">TRACK</p>
            </div> -->

            <!-- TRACK WAYBILL ENDS -->
          </ul>

          <div class="second-menu">
            <div (click)="showMobileNav = !showMobileNav" class="drive">
              <a routerLink="/drive">DRIVE</a>
            </div>
            <div class="signup_login">
              <!-- <a (click)="signUp()" id="signup-mobile">SIGN-UP</a> -->
              <a routerLink="/login" id="login-mobile">LOGIN</a>
            </div>
            <div class="socialicons">
              <a href="https://www.facebook.com/PicupSA/" target="_blank"><img
                  src="/assets/img/static-site/client-logos/facebook-logo.svg" alt="" /></a>
              <a href="https://www.instagram.com/picupsa/" target="_blank"><img
                  src="/assets/img/static-site/client-logos/instagram-logo.svg" alt="" /></a>
              <a href="https://www.linkedin.com/company/picup/mycompany/" target="_blank"><img
                  src="/assets/img/static-site/client-logos/linkedin-logo.svg" alt="" /></a>
              <a href="https://twitter.com/picupsa" target="_blank"><img
                  src="/assets/img/static-site/client-logos/twitter-logo.svg" alt="" /></a>
            </div>
          </div>
        </div>
        <!--MOBILE MENU ENDS-->
      </div>

      <div class="country-selector">
        <ul>
          <li><a routerLink="/drive" id="drive">DRIVE</a></li>
          <li><a href="https://docs.google.com/forms/d/e/1FAIpQLSdBzSrPXiFjoSWEqJhymOwQ1FA7KGLqfdQ_n9iEWZtToK1m2Q/viewform?usp=sf_link"  id="signup">BOOK A DEMO</a></li>
          <li><a routerLink="/login" id="login">LOGIN</a></li>
        </ul>
      </div>
    </div>
    <!--Menu Component ends-->
  </div>
  <ng-content></ng-content>
</div>