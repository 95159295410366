import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'timeAgoColour'
})
export class TimeAgoColourPipe implements PipeTransform {

  transform(value: string | Date): string {
    const minutesDifference = moment(Date.now()).diff(value, 'minutes');
    switch (true) {
      case minutesDifference <= 15: return 'primary';
      case minutesDifference <= 30: return 'warning';
      case minutesDifference <= 45: return 'bad';
      case minutesDifference > 45: return 'bad';
    }
  }

}
