import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../../shared/shared.module';
import { CsvErrorsComponent } from './csv-errors/csv-errors.component';
import { UploadDetailsComponent } from './upload-details/upload-details.component'
import { UploadOrdersService } from './upload-orders.service';
import { CreateConsignmentComponent } from './create-consignment/create-consignment.component';
import { EditConsignmentComponent } from './edit-consignment/edit-consignment.component';
import { GenerateRandomBucketComponent } from './generate-random-bucket/generate-random-bucket.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    UploadDetailsComponent,
    CreateConsignmentComponent,
    EditConsignmentComponent,
    CsvErrorsComponent,
    GenerateRandomBucketComponent,
  ],
  providers: [UploadOrdersService],
  entryComponents: []

})
export class UploadOrdersModule { }
