import { Injectable } from '@angular/core';
import axios, { AxiosResponse } from 'axios';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  AccessManagementModule,
  AccessManagementUser,
  LoginResponse,
} from './access-management.interface';
import { LoginUserData } from './auth.interface';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AccessManagementService {
  private acmToken: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private availableModules: BehaviorSubject<AccessManagementModule[]> = new BehaviorSubject<AccessManagementModule[]>(
    []
  );
  private currentBusiness: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private currentUser: BehaviorSubject<any> = new BehaviorSubject(null);
  constructor(private router: Router) {
    const token = localStorage.getItem('acmToken');
    if (token) {
      this.acmToken.next(token);
    }
    const currentBusinessId = localStorage.getItem('currentBusinessId');
    if (currentBusinessId) {
      this.currentBusiness.next(currentBusinessId);
    }
    const modules = localStorage.getItem('modules');
    if (modules && modules !== 'undefined') {
      this.availableModules.next(JSON.parse(modules));
    }
    const user = localStorage.getItem('currentUser');
    if (user && user !== 'undefined') {
      try{
        this.currentUser.next(JSON.parse(user));
      } catch {
        this.router.navigate(['/login']);
      }
    }
  }

  async getBusinessModules(businessId: string): Promise<AccessManagementModule[]> {
    const res: AxiosResponse<AccessManagementModule[]> = await axios({
      method: 'GET',
      baseURL: `${environment.accessManagement.baseUrl}/user/${this.currentUser.value.Uid}/get-business-module-access?BusinessUid=${businessId}`,
      headers: {
        Authorization: `Bearer ${this.acmToken.value}`,
      },
    });
    this.setAvailableModules(res.data);
    return res.data;
  }

  setAvailableModules(modules: AccessManagementModule[]): void {
    this.availableModules.next(modules);
    localStorage.setItem('modules', JSON.stringify(modules));
  }

  get availableModules$(): Observable<AccessManagementModule[]> {
    return new Observable((f) => {
      this.availableModules.subscribe(f);
    });
  }

  setCurrentUser(user: any): void {
    this.currentUser.next(user);
    localStorage.setItem('currentUser', JSON.stringify(user));
  }

  get currentUser$(): Observable<any> {
    return new Observable((f) => {
      this.currentUser.subscribe(f);
    });
  }

  async login(token: string): Promise<LoginResponse> {
    // this is the entry point for the service, we can't have this in the constructor since it'll initialize before the token is in storage
    const res: AxiosResponse<LoginResponse> = await axios({
      method: 'GET',
      baseURL: `${environment.accessManagement.baseUrl}/login/firebase?token=${token}`,
    });
    this.acmToken.next(res.data.AccessToken);
    localStorage.setItem('acmToken', this.acmToken.value);
    this.setCurrentUser(res.data.User);
    return res.data;
  }

  async getUserById(userId: string): Promise<AccessManagementUser> {
    const res: AxiosResponse<AccessManagementUser> = await axios({
      method: 'GET',
      baseURL: `${environment.accessManagement.baseUrl}/user/${userId}`,
      headers: {
        Authorization: `Bearer ${this.acmToken.value}`,
      },
    });
    return res.data;
  }

  async refreshToken(): Promise<string> {
    const res: AxiosResponse<string> = await axios({
      method: 'GET',
      baseURL: `${environment.accessManagement.baseUrl}/login/refresh`,
      headers: {
        Authorization: `Bearer ${this.acmToken.value}`,
      },
    });
    return res.data;
  }

  setCurrentBusinessId(businessId: string): void {
    this.currentBusiness.next(businessId);
    localStorage.setItem('currentBusinessId', businessId);
  }

  get currentBusinessId$(): Observable<string> {
    return new Observable((f) => {
      this.currentBusiness.subscribe(f);
    });
  }

  get acmToken$(): Observable<string> {
    return new Observable((f) => {
      this.acmToken.pipe(take(1)).subscribe(f);
    });
  }

  setACMToken(token: string): void {
    this.acmToken.next(token);
    localStorage.setItem('acmToken', token);
  }

  setRefreshToken(token: string): void {
    localStorage.setItem('refreshToken', token);
  }

  SetUserDataWithAccessToken(userData: LoginUserData): void {
    this.acmToken.next(userData.AccessToken);
    localStorage.setItem('acmToken', userData.AccessToken);
    localStorage.setItem('refreshToken', userData.RefreshToken);
    this.setCurrentUser(userData.User);
  }
}
