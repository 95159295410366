import { PostDispatchComponent } from './post-dispatch.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'overview',
    data: { breadcrumb: 'Overview' },
    pathMatch: 'full',
    component: PostDispatchComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PostDispatchRoutingModule {}
