import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UiColors } from 'app/interfaces/ui.interfaces';

@Component({
  selector: 'app-map-geojson-datalayer',
  templateUrl: './map-geojson-datalayer.component.html',
  styleUrls: ['./map-geojson-datalayer.component.scss'],
})
export class MapGeojsonDatalayerComponent implements OnInit, OnDestroy {
  @Input() dataLayer;
  @Input() mapElement;
  @Input() mapType;
  @Input() useDefaultGeoJson: boolean = false;
  constructor() {}


  ngOnInit(): void {
    if (this.mapType === 'trips') {
      this.addTripsGeoJsonDataLayer();
    } else {
      this.addGeoJsonDataLayer();
    }
  }

  ngOnDestroy(): void {
    this.mapElement.data.forEach((feature) => {
      this.mapElement.data.remove(feature);
    });
  }

  addTripsGeoJsonDataLayer(): void {
    this.mapElement.data.addGeoJson(this.dataLayer);
    this.mapElement.data.setStyle((feature) => {
      function pinSymbol(symbol, useDefaultGeoJson?: boolean) {
        if (symbol === 'warehouse') {
          return {
            url: '/assets/img/map-icon.png',
            size: new google.maps.Size(50, 50),
          };
        } else {
          return {
            path: 'M11.9,5.9C11.9,2.7,9.2,0,5.9,0S0,2.7,0,5.9c0,0.2,0,0.4,0,0.6C0.4,10,3.9,16.9,5.9,16.9 c1.8,0,5.5-6.8,5.9-10.3C11.9,6.4,11.9,6.2,11.9,5.9z',
            fillColor: useDefaultGeoJson ? feature.getProperty('marker-color') : feature.getProperty('marker_color'),
            color: '#fff',
            fillOpacity: 1,
            strokeWeight: 0,
            scale: 2,
            fontColor: 'red',
            labelContent: symbol,
            labelOrigin: new google.maps.Point(6, 6),
            anchor: new google.maps.Point(0, 15),
          };
        }
      }
      function getLabel(marker_symbol) {
        if (marker_symbol === 'warehouse') {
          return;
        } else {
          return {
            text: marker_symbol,
            color: 'white',
            fontFamily: 'Roboto',
            fontWeight: '400',
          };
        }
      }

      return {
        color: '#28b7ff',
        zIndex: 9999,
        clickable: true,
        icon: pinSymbol(
          this.useDefaultGeoJson ? feature.getProperty('marker-symbol') : feature.getProperty('marker_symbol'),
          this.useDefaultGeoJson
        ),
        strokeColor: '#27B7FC',
        label: getLabel(
          this.useDefaultGeoJson ? feature.getProperty('shipment-waybill') : feature.getProperty('marker_symbol')
        ),
      };
    });
  }

  addGeoJsonDataLayer(): void {
    this.mapElement.data.addGeoJson(this.dataLayer);
    this.mapElement.data.setStyle((feature) => {
      // this.addClickEvent();

      function drawLines(lines) {
        if (lines) {
          return 1.5;
        } else {
          return 0;
        }
      }

      function getLabel(waypoint_index, flagged_reason) {
        if (!waypoint_index && !flagged_reason) {
          return null;
        }
        switch (true) {
          case flagged_reason !== undefined:
            return {
              text: waypoint_index === 0 ? 'C' : waypoint_index,
              color: 'white',
              fontFamily: 'Roboto',
              fontWeight: '400',
            };
          case waypoint_index === 0 && !flagged_reason:
            return {
              text: 'C',
              color: 'black',
              fontFamily: 'Roboto',
              fontWeight: '700',
            };
          case waypoint_index !== 0 && !flagged_reason:
            return {
              text: waypoint_index,
              color: 'black',
              fontFamily: 'Roboto',
              fontWeight: '700',
            };
        }
      }

      function pinSymbol(waypoint_index, flagged_reason) {
        switch (true) {
          case !flagged_reason && !waypoint_index:
            return {
              path: 'M11.9,5.9C11.9,2.7,9.2,0,5.9,0S0,2.7,0,5.9c0,0.2,0,0.4,0,0.6C0.4,10,3.9,16.9,5.9,16.9 c1.8,0,5.5-6.8,5.9-10.3C11.9,6.4,11.9,6.2,11.9,5.9z',
              fillColor: UiColors.Red,
              color: '#fff',
              fillOpacity: 1,
              strokeWeight: 0,
              scale: 0,
              fontColor: 'red',
              labelOrigin: null,
              anchor: null,
              visible: false,
            };
          case flagged_reason !== undefined:
            return {
              path: 'M11.9,5.9C11.9,2.7,9.2,0,5.9,0S0,2.7,0,5.9c0,0.2,0,0.4,0,0.6C0.4,10,3.9,16.9,5.9,16.9 c1.8,0,5.5-6.8,5.9-10.3C11.9,6.4,11.9,6.2,11.9,5.9z',
              fillColor: UiColors.Red,
              color: '#fff',
              fillOpacity: 1,
              strokeWeight: 0,
              scale: 2,
              fontColor: 'red',
              labelOrigin: new google.maps.Point(6, 6),
              anchor: new google.maps.Point(0, 15),
            };
          case waypoint_index !== undefined && !flagged_reason:
            return {
              path: 'M11.9,5.9C11.9,2.7,9.2,0,5.9,0S0,2.7,0,5.9c0,0.2,0,0.4,0,0.6C0.4,10,3.9,16.9,5.9,16.9 c1.8,0,5.5-6.8,5.9-10.3C11.9,6.4,11.9,6.2,11.9,5.9z',
              fillColor: 'white',
              color: '#fff',
              fillOpacity: 1,
              strokeWeight: 0,
              scale: 2,
              fontColor: 'red',
              labelOrigin: new google.maps.Point(6, 6),
              anchor: new google.maps.Point(0, 15),
            };
        }
      }

      return {
        color: '#f1453d',
        zIndex: 9999,
        clickable: true,
        icon: pinSymbol(feature.getProperty('waypoint_index'), feature.getProperty('flagged_reason')),
        strokeColor: '#f1453d',
        strokeWeight: drawLines(feature),
        label: getLabel(feature.getProperty('waypoint_index'), feature.getProperty('flagged_reason')),
      };
    });
  }

}
