<app-loader *ngIf="loadingOrders; else displayOrders" class="loader" [loadingMessage]="'Loading Orders'">
</app-loader>
<ng-template #displayOrders>
  <app-section *ngIf="stagedOrders?.orders.length > 0; else displayNoOrders">
    <div class="actions-wrap">
      <app-back-button></app-back-button>
      <app-flex-container [justifyContent]="justifyContent.FlexStart" [borderBottom]="true">
        <app-basic-button class="button-spacing" [title]="'Refresh'" [type]="ButtonTypes.Primary" (click)="getStagedOrdersForWarehouse()">
        </app-basic-button>
        <app-basic-button *ngFor="let tab of viewTabs"
        [title]="tab"
        [type]="ButtonTypes.Primary"
        class="button-spacing"
        [active]="isActive(tab)"
        (click)="handleTabNavigation(tab)"
        ></app-basic-button>
        <app-stats-icon
          [labelAlign]="'start'"
          [padding]="'0'"
          [dataSizeRem]="2"
          [icon]="IconTypes.Details"
          [iconColor]="UiColors.Blue"
          [iconSizeRem]="2"
          [label]="'En Route'"
          [data]="stagedOrders.staged"
        >
        </app-stats-icon>
        <app-stats-icon
          [labelAlign]="'start'"
          [padding]="'0'"
          [dataSizeRem]="2"
          [icon]="IconTypes.Check"
          [iconColor]="UiColors.Green"
          [iconSizeRem]="2"
          [label]="'Received'"
          [data]="stagedOrders.received"
        >
        </app-stats-icon>
        <app-stats-icon
          [labelAlign]="'start'"
          [padding]="'0'"
          [dataSizeRem]="2"
          [icon]="IconTypes.Cross"
          [iconColor]="UiColors.Red"
          [iconSizeRem]="2"
          [label]="'Failed'"
          [data]="stagedOrders.failed"
        >
        </app-stats-icon>
      </app-flex-container>
    </div>
    <app-section *ngIf="displaySections.includes(StagedOrderSourceEnum.FinalCollectionAttemptFailed)">
      <heading 
      [icon]="IconTypes.Details" 
      [color]="UiColors.Blue" 
      [showToggle]="true"
      (click)="collapsibleComplete.toggle()"
      >
        <h4 class="heading-title">{{ failedCollectionOrders.length }} {{ 'Failed Collection Orders' | uppercase }}</h4>
      </heading>
      <div appCollapsible [expanded]="false" #collapsibleComplete="appCollapsible">
        <app-table
          [columns]="stagedOrdersTableColumns"
          [dataMap]="stagedOrdersTableDataMap"
          [rawData]="failedCollectionOrders"
          [rowsSelectable]="true"
          (actionTaken)="respondToTableActions($event)"
          [zeroDataMessage]="'No Failed Collection Orders Remain'"
          [bulkActions]="tableBulkActions"
          [primaryActions]="tablePrimaryActions"
        ></app-table>
      </div>
  </app-section>
  <app-section *ngIf="displaySections.includes(StagedOrderSourceEnum.Returned)">
    <heading 
    [icon]="IconTypes.Details" 
    [color]="UiColors.Blue" 
    [showToggle]="true"
    (click)="collapsibleComplete.toggle()"
    >
      <h4 class="heading-title">{{ returnedOrders.length }} {{ 'Returned Orders' | uppercase }}</h4>
    </heading>
    <div appCollapsible [expanded]="false" #collapsibleComplete="appCollapsible">
      <app-table
        [columns]="stagedOrdersTableColumns"
        [dataMap]="stagedOrdersTableDataMap"
        [rawData]="returnedOrders"
        [rowsSelectable]="true"
        (actionTaken)="respondToTableActions($event)"
        [zeroDataMessage]="'No Returned Orders Remain'"
        [bulkActions]="tableBulkActions"
        [primaryActions]="tablePrimaryActions"
      ></app-table>
    </div>
</app-section>
    <app-section *ngIf="displaySections.includes(StagedOrderSourceEnum.New)">
      <heading 
      [icon]="IconTypes.Details" 
      [color]="UiColors.Blue" 
      [showToggle]="true"
      (click)="collapsibleComplete.toggle()"
      >
        <h4 class="heading-title">{{ noSourceOrders.length }} {{ 'New Orders' | uppercase }}</h4>
      </heading>
      <div appCollapsible [expanded]="false" #collapsibleComplete="appCollapsible">
        <app-table
          [columns]="stagedOrdersTableColumns"
          [dataMap]="stagedOrdersTableDataMap"
          [rawData]="noSourceOrders"
          [rowsSelectable]="true"
          (actionTaken)="respondToTableActions($event)"
          [zeroDataMessage]="'No New Staged Orders Remain'"
          [bulkActions]="tableBulkActions"
          [primaryActions]="tablePrimaryActions"
        ></app-table>
      </div>
  </app-section>
</app-section>
</ng-template>
<ng-template #displayNoOrders>
  <div>
    <div  class="actions-wrap">
      <app-back-button></app-back-button>
    </div>
    <app-loader class="loader" [displayLoader]="false" [loadingMessage]="'No Staged Orders for Warehouse'"></app-loader>
  </div>
</ng-template>