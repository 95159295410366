<app-loader *ngIf="loading; else hasSavedCards" class="loader" [loadingMessage]="'Loading Saved Cards'"></app-loader>
<ng-template #hasSavedCards>
    <div *ngIf="savedCards?.length > 0; else noCards">
        <h5>Previously Saved Cards:</h5>
        <table>
            <thead>
                <tr>
                    <th>
                        Card Number
                    </th>
                    <th>
                        Expires
                    </th>
                    <th>
                        Card Type
                    </th>
                    <th>
                        Bank
                    </th>
                    <th>

                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let card of savedCards">
                    <td>**** **** **** {{card.CardDetails.last4}}</td>
                    <td>{{card.CardDetails.exp_month}}/{{card.CardDetails.exp_year}}</td>
                    <td>
                        <div [ngSwitch]="card.CardDetails.card_type"> <img *ngSwitchCase="cardTypes.Visa"
                                class="card__logo" src="/assets/img/cards/visa.svg" alt=""> <img
                                *ngSwitchCase="cardTypes.MasterCard" class="card__logo"
                                src="/assets/img/cards/mastercard.svg" alt=""> <img *ngSwitchCase="cardTypes.Paypal"
                                class="card__logo" src="/assets/img/cards/paypal.svg" alt=""> <img
                                *ngSwitchCase="cardTypes.AmericanExpress" class="card__logo"
                                src="/assets/img/cards/americanexpress.svg" alt=""> </div>
                    </td>
                    <td>{{card.CardDetails.bank}}</td>
                    <td class="cta">
                        <app-basic-button [processing]="loadingPayment" *ngIf="showPayNow" [type]="ButtonTypes.Green" [active]="true"
                            [title]="'Pay Now'" (click)="selectCard(card.CardDetails.authorization_code)">
                        </app-basic-button>
                        <app-icon [icon]="IconTypes.Delete" [size]="2" [color]="UiColors.Red"
                            (click)="deleteSavedCard(card.CardDetails.authorization_code)"></app-icon>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <ng-template #noCards>
        <app-loader [displayLoader]="false" class="loader" [loadingMessage]="'No Saved Cards'"></app-loader>
    </ng-template>
</ng-template>