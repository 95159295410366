import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IconTypes } from '../icon/icon.interfaces';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { JustifyContent } from '../flex-container/flex-container.interfaces';

@Component({
  selector: 'app-section-icon-button',
  templateUrl: './section-icon-button.component.html',
  styleUrls: ['./section-icon-button.component.scss'],
})
export class SectionIconButtonComponent implements OnInit {
  @Input() icon: IconTypes;
  @Input() iconColor: UiColors;
  @Input() iconSizeRem: number;
  @Input() text: string;
  @Input() color: UiColors;
  @Input() fontSizeRem: number;
  @Input() textBold: boolean;

  @Output() onClick = new EventEmitter();

  justifyContent = JustifyContent;

  constructor() {}

  ngOnInit(): void {}

  public handleClick() {
    this.onClick.emit();
  }
}
