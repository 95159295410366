import { DomSanitizer } from '@angular/platform-browser';
import { MapViewService } from './../map-view.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-map-marker',
  templateUrl: './map-marker.component.html',
  styleUrls: ['./map-marker.component.scss'],
})
export class MapMarkerComponent implements OnDestroy {

  @Input() mapElement: google.maps.Map;
  @Input() set markerIn(marker) {
    this.marker = marker;
    this.createMarker();
  }

  marker;
  googleMarker: google.maps.Marker;

  constructor(private mapViewService: MapViewService, private sanitization: DomSanitizer) {}

  ngOnInit(): void {
    this.createMarker();
  }

  createMarker(): void {
    if (this.googleMarker) {
      this.googleMarker.setMap(null);
      this.googleMarker = null;
    }

    this.mapViewService.addMarkerToBounds(this.marker.id, this.marker.latitude, this.marker.longitude);

    const position = new google.maps.LatLng(this.marker.latitude, this.marker.longitude);
    this.googleMarker = new google.maps.Marker({
      position: position,
      label: this.marker?.icon ? '' : this.marker.label,
      map: this.mapElement,
      icon: this.setIcon(this.marker)
    });
    this.googleMarker.setMap(this.mapElement);
  }

  ngOnDestroy(): void {
    if (this.googleMarker) {
      this.googleMarker.setMap(null);
      this.googleMarker = null;
    }
  }

  setIcon(marker){
    if (this.marker?.icon) {
      return {
        url: this.marker?.icon,
        scaledSize: new google.maps.Size(30, 30),
        anchor: new google.maps.Point(6, 6),
      };
    } else {
      return {
        path:
        'M11.9,5.9C11.9,2.7,9.2,0,5.9,0S0,2.7,0,5.9c0,0.2,0,0.4,0,0.6C0.4,10,3.9,16.9,5.9,16.9 c1.8,0,5.5-6.8,5.9-10.3C11.9,6.4,11.9,6.2,11.9,5.9z',
        fillColor: marker.status,
        color: '#fff',
        fillOpacity: 1,
        strokeWeight: 0,
        scale: 2,
        fontColor: 'red',
        labelOrigin: new google.maps.Point(6, 6),
        anchor: new google.maps.Point(6, 15),
      };
    }
  }
}
