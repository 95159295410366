<div class="stat" [ngStyle]="{ padding: padding }">
  <div class="data-icon-wrap" [ngClass]="{ noData: noData }">
    <app-icon *ngIf="icon" [icon]="icon" [color]="iconColor" [size]="iconSizeRem"></app-icon>
    <div *ngIf="loading" class="loading-container">
      <i class="status-in-progress fa fa-spinner fa-spin"></i>
    </div>
    <div
      *ngIf="!loading && data !== undefined"
      class="data-value"
      [class]="textColour"
      [ngStyle]="{ 'text-transform': textTransform }"
      [style.color]="textColour"
      [style.fontSize.rem]="dataSizeRem"
    >
      {{ data }}
    </div>
  </div>
  <div class="label" [ngStyle]="{ 'text-align': labelAlign, 'text-transform': textTransform }">{{ label }}</div>
</div>
