<div class="shift-selector">
    <h6>Shift</h6>
    <div [hidden]="availableRanges && availableRanges.length" class="range-selector">
      <h2>{{rangeString(selectedRange)}}</h2>
      <div #rangeSlider>
      </div>
    </div>
    <div  [hidden]="!availableRanges || !availableRanges.length" class="dropdown-selector">
      <app-dropdown class="shift-dropdown" [labelTheme]="'light'" [activeItem]="selectedRange ? rangeString(selectedRange) : 'Select a Shift'">
        <div dropdownRef>
          <a *ngFor="let range of availableRanges" class="dropdown-item"
            (click)="selectShift(range)">{{ rangeString(range) }}</a>
        </div>
      </app-dropdown>
    </div>
  </div>
  
  
  