import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import axios from 'app/api/axios';
import { Store } from '@ngrx/store';
import * as moment from 'moment';

import { selectorActiveBucket, selectorActiveBucketId } from './manage-bucket.reducer';
import {
  Bucket,
  BucketContractors,
  GetContractorsResponse,
  GetOrderDetailsRequest,
  SearchAddressBookRequest,
} from '../buckets/buckets.interfaces';
import { Business } from '../../interfaces/businesses.interfaces';
import { NotificationsService } from '../../shared/notifications/notifications.service';
import { BucketService } from './bucket.service';
import { selectorUser, selectorActingAs, selectorWarehouses } from 'app/auth/auth.reducer';
import { SimpleModalService } from 'ngx-simple-modal';
import { EditPreferredDriversComponent } from '../../shared/edit-preferred-drivers/edit-preferred-drivers.component';
import { setActiveBucketId } from './manage-bucket.actions';
import { ActingAs, Warehouse } from 'app/interfaces/auth.interfaces';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { AxiosPromise, AxiosResponse } from 'axios';
import { Order } from 'app/shared/shared.interfaces';
import { ErrorHandlingService } from 'app/shared/error-handling.service';

@Injectable({
  providedIn: 'root',
})
export class ManageBucketService {
  auth;
  actingAs: ActingAs;
  user;
  activeBucketId: number;
  historicBuckets: Bucket[];
  // activeBuckets: Bucket[];
  activeBucket: Bucket;
  business: Business;
  stopPolling = false;
  warehouses: Warehouse[];

  bucketFirestoreTrips$: Observable<Order[]>;

  ENTERPRISE_BUCKET_SUMMARY_BASE_URL = 'enterprise-bucket-summmary/';
  ENTERPRISE_BUCKET_BASE_URL = 'enterprise-buckets/';

  constructor(
    public router: Router,
    public store: Store<any>,
    // private db: AngularFireDatabase,
    public bucketService: BucketService,
    private notificationsService: NotificationsService,
    private simpleModalService: SimpleModalService,
    private firestore: AngularFirestore,
    private errorHandlingService: ErrorHandlingService) {
    this.store.select(selectorUser).subscribe((next) => (this.user = next));
    this.store.select(selectorActingAs).subscribe((next) => (this.actingAs = next));
    this.store.select(selectorWarehouses).subscribe((next) => (this.warehouses = next));
    this.store.select(selectorActiveBucketId).subscribe((next) => (this.activeBucketId = next));
    // this.store.select(selectorActiveBuckets).subscribe((next) => (this.activeBuckets = next));
    this.store.select(selectorActiveBucket).subscribe((next) => {
      this.activeBucket = next;
    });
    this.bucketService.historic_buckets.subscribe((next) => {
      this.historicBuckets = next;
    });
  }

  getFirestoreTrips(bucket_id: number): void {
    this.bucketFirestoreTrips$ = this.firestore
      .collection<Order>('orders', (ref) => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where('BucketId', '==', bucket_id);
      query.get().then((doc) => {
        if (doc.empty) {
          this.rehydrateBucketAggregates(bucket_id);
        }
      });
      return query;
    })
      .valueChanges();
  }
  rehydrateBucketAggregates(bucketId: number) {
    axios({
      method: 'POST',
      url: '/firestore/rehydrate-bucket-orders/' + bucketId,
    })
      .then((res) => {
        return res.data;
      })
      .catch(() =>
        this.notificationsService.publish({
          type: 'error',
          message: 'Error building order - Please contact support. (Error Code:RH-' + bucketId + ')',
        })
      );
  }

  getOrderDetails(payload: GetOrderDetailsRequest): AxiosPromise<any> {
    return axios({
      method: 'POST',
      data: payload,
      url: '/bucket/get-order-details',
    });
  }

  getOrderDetailsStaged(order_id: string) {
    return axios({
      method: 'POST',
      data: { order_id: order_id },
      url: '/generic/cqrs/get-order-details',
    });
  }

  getBucketState() {
    return this.firestore.collection('buckets').doc(this.activeBucketId.toString()).valueChanges();
  }

  vetAll() {
    return axios({
      method: 'GET',
      url: '/bucket/' + this.activeBucketId + '/vet-all',
    })
      .then((response) => {
        this.notificationsService.publish({ type: 'success', message: 'All Orders Auto-Vetted' });
        return response?.data;
      })
      .catch((error) =>
        this.notificationsService.publish({
          type: 'error',
          message: error.response?.data.message,
        })
      );
  }

  getPostDispatchSummary(is_include_completed = false) {
    return axios({
      method: 'POST',
      url: 'generic/cqrs/get-post-dispatch-summary-by-business-v2',
      data: {
        bucket_id: this.activeBucketId,
        business_id: this.actingAs.id,
        is_include_completed: is_include_completed,
      },
    }).then((response) => {
      return response?.data;
    });
  }

  getAutoAssignCourierOrders() {
    return axios({
      method: 'POST',
      url: 'generic/cqrs/get-courier-auto-assignment-for-bucket',
      data: { bucket_id: this.activeBucketId },
    }).then((response) => {
      return response?.data;
    });
  }

  getCourierDetails(bucket_id: string): any {
    return axios({
      method: 'POST',
      url: '/generic/cqrs/get-bucket-couriers',
      data: { bucket_id: bucket_id },
    }).then((response) => {
      return response?.data;
    });
  }

  updateOrder(updatePayload) {
    return axios({
      method: 'POST',
      data: updatePayload,
      url: '/bucket/update-order',
    })
      .then((response) => {
        this.notificationsService.publish({ type: 'success', message: 'Successfully Updated Order' });
        return response?.data;
      })
      .catch((error) => this.notificationsService.publish({ type: 'error', message: error.response?.data.message }));
  }

  addOrderToExistingBucket(updatePayload) {
    return axios({
      method: 'POST',
      data: updatePayload,
      url: '/bucket/add-order',
    })
      .then((response) => {
        this.notificationsService.publish({ type: 'success', message: 'Successfully Added' });
        return response?.data;
      })
      .catch((error) => {
        this.notificationsService.publish({ type: 'error', message: error.response?.data.message });
      });
  }

  setActiveBucket(bucketId: string | number): void {
    this.store.dispatch(new setActiveBucketId(bucketId as string));
  }

  createManualTrip(bucket_id, data) {
    return axios({
      method: 'POST',
      url: 'bucket/' + bucket_id + '/create-manual-trip',
      data: data,
    })
      .then(() => {
        this.notificationsService.publish({ type: 'success', message: 'Manual Trip Created' });
        return;
      })
      .catch((error) => this.notificationsService.publish({ type: 'error', message: error.response?.data.message }));
  }

  updateCrossDocks(bucket_id, crossdock_results) {
    return axios({
      method: 'POST',
      url: 'bucket/' + bucket_id + '/update-cross-dock',
      data: crossdock_results,
    })
      .then(() => {
        this.notificationsService.publish({ type: 'success', message: 'Successfully Created Bucket' });
        return;
      })
      .catch((error) => this.notificationsService.publish({ type: 'error', message: error.response?.data.message }));
  }

  consignOrdersOutsideRadius(bucket_id, data) {
    return axios({
      method: 'POST',
      url: 'bucket/' + bucket_id + '/consign-orders-outside-radius',
      data: data,
    })
      .then(() => {
        this.notificationsService.publish({ type: 'success', message: 'Consignment Created' });
        return;
      })
      .catch((error) => this.notificationsService.publish({ type: 'error', message: error.response?.data.message }));
  }

  getCrossDockGeoJson(bucket_id, crossdock_data) {
    return axios({
      method: 'POST',
      url: 'bucket/' + bucket_id + '/calculate-cross-dock',
      data: crossdock_data,
    }).then((res) => {
      return res.data;
    });
  }

  downloadTripSheet($event, bucketId) {
    let requestUrl;
    switch ($event) {
      case 'csv':
        requestUrl = 'bucket/' + this.activeBucketId + '/download-trip-sheet/';
        break;
      case 'pdf':
        requestUrl = '/pdf/report/trip-sheet/' + bucketId;
        break;
    }
    const date = moment(this.activeBucket.delivery_date).format('YYYY-MM-DD');
    const warehouse_name = this.activeBucket.warehouse_name;
    return axios({
      method: 'GET',
      url: requestUrl,
      responseType: 'blob', // important
    })
      .then((response) => {
        const data = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = data;
        link.setAttribute('download', 'Tripsheet ' + date + ' | ' + warehouse_name + '.' + $event);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        this.notificationsService.publish({ type: 'error', message: error.response?.data.message });
        throw error.response?.data.message;
      });
  }

  downloadDebrief(orderId: string): Promise<void> {
    return axios({
      method: 'GET',
      url: `/pdf/report/debrief/${orderId}`,
      responseType: 'blob',
    })
      .then((response) => {
        const data = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = data;
        link.setAttribute('download', 'Debrief ' + orderId + '.pdf');
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        this.notificationsService.publish({ type: 'error', message: error.response?.data.message });
        throw error.response?.data.message;
      });
  }

  downloadWaybills(APIData) {
    const date = moment(this.activeBucket.delivery_date).format('YYYY-MM-DD');
    const warehouse_name = this.activeBucket.warehouse_name;

    if (!APIData.bucket_ids) {
      APIData['bucket_ids'] = [this.activeBucketId];
    }
    return axios({
      method: 'POST',
      url: '/waybill/download-crossdock-waybills',
      data: APIData,
      responseType: 'blob',
    })
      .then((response) => {
        const data = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = data;
        link.setAttribute('download', date + ' | ' + warehouse_name + ' | ' + APIData.displayName + '.pdf');
        document.body.appendChild(link);
        link.click();
        return response;
      })
      .catch((error) => {
        this.errorHandlingService.handleBlobError(error);
      });
  }

  printWaybill(waybillNumbers: string[]): Promise<void> {
    return axios({
      method: 'POST',
      url: '/integration/download-waybills',
      responseType: 'arraybuffer',
      data: waybillNumbers,
    })
      .then((response) => {
        const data = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = data;
        link.setAttribute(
          'download',
          (waybillNumbers.length === 1 ? waybillNumbers[0] : waybillNumbers.toString()) + '.pdf'
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => this.notificationsService.publish({ type: 'error', message: error.response?.data.message }));
  }

  getBucketObject() {
    return this.firestore.collection('buckets').doc(this.activeBucketId.toString()).valueChanges();
  }

  getStatusReport(statusType, status) {
    return axios({
      method: 'GET',
      url: 'bucket/' + this.activeBucketId + '/parcels/' + statusType + '/' + status,
    }).then((res) => {
      return res.data;
    });
  }

  getAssignableBuckets() {
    return axios({
      method: 'GET',
      url: 'bucket/' + this.activeBucketId + '/get-assignable-buckets',
    }).then((response) => {
      return response?.data;
    });
  }

  getBucketTableData() {
    return axios({
      method: 'POST',
      url: '/bucket/' + this.activeBucketId + '/orders-by-address-status/',
    }).then((response) => {
      return response?.data;
    });
  }

  getVerifyingGeoJson(bucket_id) {
    return axios.get('/bucket/' + bucket_id + '/orders-geojson').then((response) => {
      return response?.data;
    });
  }

  changeBucket(changeBucketData) {
    return axios({
      method: 'POST',
      url: '/bucket/' + this.activeBucketId + '/change-bucket/',
      data: changeBucketData,
    })
      .then(() => {
        this.notificationsService.publish({ type: 'success', message: 'Successfully Transferred' });
        return;
      })
      .catch((error) => this.notificationsService.publish({ type: 'error', message: error.response?.data.message }));
  }

  removeOrder(bucket_id, business_references, order_ids) {
    const data = {
      bucket_id: bucket_id,
      business_references: business_references,
      order_ids: order_ids,
    };
    return axios({
      method: 'POST',
      url: '/bucket/delete-order/',
      data: data,
    })
      .then(() => {
        this.notificationsService.publish({ type: 'success', message: 'Successfully Removed' });
        return;
      })
      .catch((error) => this.notificationsService.publish({ type: 'error', message: error.response?.data.message }));
  }

  getWaybillOptions() {
    return axios.get('/waybill/get-waybill-options-for-bucket/' + this.activeBucketId).then((response) => {
      return response?.data;
    });
  }

  handleConfirmationTableActions($event) {
    const tripId = $event.rows[0].cells.find((cell) => cell.column === 'id').value;
    switch ($event.action.event) {
      case 'editDrivers':
        this.editPreferredDrivers(tripId);
        break;
    }
  }

  editPreferredDrivers(tripId) {
    const modal_data = {
      trip_id: tripId,
      business_id: this.actingAs.id,
      user_id: this.user.user_id,
      bucket_id: this.activeBucketId,
    };
    this.simpleModalService.addModal(EditPreferredDriversComponent, modal_data);
  }

  restageOrders(bucket_order_ids) {
    return axios({
      method: 'POST',
      data: { bucket_order_ids: bucket_order_ids },
      url: '/bucket/' + this.activeBucketId + '/re-stage-order',
    })
      .then((response) => {
        return response?.data;
      })
      .catch((error) => this.notificationsService.publish({ type: 'error', message: error.response?.data.message }));
  }

  addOrderToNewBucket(updatePayload) {
    return axios({
      method: 'POST',
      data: updatePayload,
      url: '/bucket/' + this.actingAs.id + '/add-to-bucket',
    })
      .then((response) => {
        this.notificationsService.publish({ type: 'success', message: 'Successfully Created Bucket' });
        return response?.data;
      })
      .catch((error) => this.notificationsService.publish({ type: 'error', message: error.response?.data.message }));
  }

  getBucketSQLtrips() {
    return axios({
      method: 'POST',
      url: '/generic/cqrs/get-post-dispatch-order-summary-v2',
      data: {
        business_id: this.actingAs.id,
        bucket_id: this.activeBucketId,
        order_status: null,
      },
    }).then((response) => {
      return response?.data;
    });
  }

  searchAddressBook(payload: SearchAddressBookRequest) {
    return axios({
      method: 'POST',
      url: '/address-book/search-address-book',
      data: payload,
    });
  }

  getAssignableContractors(): Promise<GetContractorsResponse | void> {
    return axios({
      method: 'GET',
      url: `bucket/${this.activeBucketId}/get-assignable-contractors-for-bucket`,
    })
      .then((response: AxiosResponse<GetContractorsResponse>) => {
        return response?.data;
      })
      .catch((error) => this.notificationsService.publish({ type: 'error', message: error.response?.data.message }));
  }

  getContractors(): Promise<BucketContractors | void> {
    return axios({
      method: 'GET',
      url: `bucket/${this.activeBucketId}/get-contractors-for-bucket`,
    })
      .then((response: AxiosResponse<BucketContractors>) => {
        return response?.data;
      })
      .catch((error) => this.notificationsService.publish({ type: 'error', message: error.response?.data.message }));
  }

  getBusinessLotteryOptions() {
    return axios({
      method: 'GET',
      url: '/business/' + this.actingAs.id + '/get-lottery-options',
    });
  }
}
