import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';

// Services
import { SharedService } from './shared.service';

// Modules
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DirectivesModule } from '../directives/directives.module';
import { ColorPickerModule } from 'ngx-color-picker';

// Pipes
import { CamelCaseToTitleCase } from './pipes/camelCaseToTitleCase.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { FullTextSearchPipe } from './pipes/trip-filter.pipe';
import { MyFilterPipe } from './pipes/filter.pipe';
import { TimePipe } from './pipes/time.pipe';
import { UnderScoresPipe } from './pipes/underscores.pipe';
import { UpcomingToLatestPipe } from './pipes/upcoming-to-latest.pipe';
import { OrderByDatePipe } from './pipes/order-by-date.pipe';
import { StringFilterPipe } from './pipes/stringFilter.pipe';
import { BypassUrlPipe } from './pipes/bypass-url.pipe';

// Components
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { BasicButtonComponent } from './buttons/basic-button.component';
import { BasicButtonGroupComponent } from './button-groups/basic-button-group.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { ConfirmModalComponent } from './modals/confirm-modal.component';
import { CardComponent } from './card/card.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { DatePickerModalComponent } from './modals/date-picker-modal.component';
import { DateTimeComponent } from './date-time/date-time.component';
import { DropdownComponent } from './dropdowns/dropdown.component';
import { FlexContainerComponent } from './flex-container/flex-container.component';
import { FlexChildComponent } from './flex-child/flex-child.component';
import { GeocodingToolComponent } from './geocoding-tool/geocoding-tool.component';
import { GridContainerComponent } from './grid-container/grid-container.component';
import { HeaderComponent } from './header/header.component';
import { HeadingComponent } from './headings/heading.component';
import { IconButtonComponent } from './buttons/icon-button.component';
import { IconComponent } from './icon/icon.component';
import { IconTimestampStatusComponent } from './icon-timestamp-status/icon-timestamp-status.component';
import { IconWithTextComponent } from './icon-with-text/icon-with-text.component';
import { ImageWithCaptionComponent } from './image-with-caption/image-with-caption.component';
import { InputFieldComponent } from './input-fields/input-field.component';
import { LoaderComponent } from './loader/loader.component';
import { MapComponent } from './map/map.component';
import { MenuDropDownComponent } from './menu-dropdown/menu-drop-down.component';
import { ModalComponent } from './modals/modal.component';
import { NavigationComponent } from './navigation/navigation.component';
import { NoContentPlaceholderComponent } from './no-content-placeholder/no-content-placeholder.component';
import { PageFilterButtonsComponent } from './page-filter-buttons/page-filter-buttons.component';
import { PlacesAutocompleteComponent } from './places-autocomplete/places-autocomplete.component';
import { ProcessingIndicatorComponent } from './processing-indicator/processing-indicator.component';
import { RevealMoreComponent } from './dropdowns/reveal-more.component';
import { SectionComponent } from './section/section.component';
import { SelectComponent } from './selects/select.component';
import { ShiftSelectorComponent } from './shift-selector/shift-selector.component';
import { TimeSelectorComponent } from './time-selector/time-selector.component';
import { SidebarComponent } from './site-partials/sidebar/sidebar.component';
import { StatsIconComponent } from './stats-icon/stats-icon.component';
import { SummaryLineComponent } from './summary-line/summary-line.component';
import { TableComponent } from './table/table.component';
import { TableSummaryComponent } from './table/table-summary/table-summary.component';
import { TableSummaryRowComponent } from './table/table-summary/table-summary-row.component';
import { TooltipButtonComponent } from './buttons/tooltip-button.component';
import { TopBarComponent } from './site-partials/top-bar/top-bar.component';
import { UploadButtonComponent } from './upload-button/upload-button.component';
import { ValueWithLabelComponent } from './value-with-label/value-with-label.component';
import { FinancialSummaryComponent } from './financial-summary/financial-summary.component';
import { TimeDatePickerModalComponent } from './modals/time-date-picker.modal/time-date-picker.modal.component';
import { ParcelReportTableComponent } from './parcel-report-table/parcel-report-table.component';
import { LineGraphComponent } from './charts/line-graph/line-graph.component';
import { HeatmapComponent } from './charts/heatmap/heatmap.component';
import { ComplexPieComponent } from './charts/complex-pie/complex-pie.component';
import { PolarComponent } from './charts/polar/polar.component';
import { ColorPickerModalComponent } from './color-picker-modal/color-picker-modal.component';
import { VerticalGroupedBarComponent } from './charts/vertical-grouped-bar/vertical-grouped-bar.component';
import { TreeGraphComponent } from './charts/tree-graph/tree-graph.component';
import { OneToOneComponent } from './one-to-one/one-to-one.component';
import { WaypointComponent } from './one-to-one/waypoint/waypoint.component';
import { QuoteOneToManyComponent } from './one-to-one/quote-one-to-many/quote-one-to-many.component';
import { SelectMethodComponent } from './one-to-one/select-method/select-method.component';
import { WaypointSummaryBlockComponent } from './one-to-one/select-method/waypoint-summary-block/waypoint-summary-block.component';
import { HomePageStatusComponent } from './home-page-status/home-page-status.component';
import { PlacePicupButtonComponent } from './place-picup-button/place-picup-button.component';
import { GaugeComponent } from './charts/gauge/gauge.component';
import { SearchDriverNameComponent } from './search-driver-name/search-driver-name.component';
import { PaymentComponent } from './payment/payment.component';
import { PeachPaymentsComponent } from './payment/peach-payments/peach-payments.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { LoadingSectionComponent } from './loading-section/loading-section.component';
import { AddressBookComponent } from './address-book/address-book.component';
import { HumanizePipe } from './pipes/humanize.pipe';
import { SliderButtonComponent } from './buttons/slider-button/slider-button.component';
import { EditAddressComponent } from './address-book/edit-address/edit-address.component';
import { StaticSiteNavComponent } from './static-site-nav/static-site-nav.component';
import { FullPageSplitComponent } from './full-page-split/full-page-split.component';
import { GlowPillComponent } from './glow-pill/glow-pill.component';
import { StaticFooterComponent } from './static-footer/static-footer.component';
import { HowToBlockComponent } from './how-to-block/how-to-block.component';
import { NavigationAdminComponent } from './navigation-admin/navigation-admin.component';
import { ZoneEditorComponent } from './zone-editor/zone-editor.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { PackageSelectorComponent } from './package-selector/package-selector.component';
import { VehicleTrackingMapComponent } from './map-view/vehicle-tracking-map/vehicle-tracking-map.component';
import { AlertsComponent } from './alerts/alerts.component';
import { AlertsMapComponent } from './alerts/alerts-map/alerts-map.component';
import { AlertsTableComponent } from './alerts/alerts-table/alerts-table.component';
import { SelectModuleComponent } from './select-module/select-module.component';
import { AdminActionsComponent } from './admin-actions/admin-actions.component';
import { ImageModalComponent } from './modals/image-modal/image-modal.component';
import { MapViewComponent } from './map-view/map-view.component';
import { MapPolylineComponent } from './map-view/map-polyline/map-polyline.component';
import { MapMarkerComponent } from './map-view/map-marker/map-marker.component';
import { MapGeojsonDatalayerComponent } from './map-view/map-geojson-datalayer/map-geojson-datalayer.component';
import { MapWaypointMarkerComponent } from './map-view/map-waypoint-marker/map-waypoint-marker.component';
import { EditPreferredDriversComponent } from './edit-preferred-drivers/edit-preferred-drivers.component';
import { ConfirmInputModalComponent } from './modals/confirm-input-modal/confirm-input-modal.component';
import { TextInputModalComponent } from './modals/text-input-modal/text-input-modal.component';
import { CreateBucketModalComponent } from './buckets/create-bucket-modal/create-bucket-modal.component';
import { AvailableBucketsComponent } from './buckets/available-buckets/available-buckets.component';
import { SectionIconButtonComponent } from './section-icon-button/section-icon-button.component';
import { SelectDriverModalComponent } from './select-driver-modal/select-driver-modal.component';
import { DriverShiftSelectModalComponent } from './driver/driver-shift-select-modal/driver-shift-select-modal.component';
import { NumberCardComponent } from './charts/number-card/number-card.component';
import { ParcelTimelineComponent } from './parcel-timeline/parcel-timeline.component';
import { SortByPipe } from './pipes/sort-by.pipe';
import { SliderValueComponent } from './slider-value/slider-value.component';
import { BooleanModalComponent } from './modals/boolean-modal/boolean-modal.component';
import { OndemandFeaturesComponent } from './ondemand-features/ondemand-features.component';
import { StaticHeaderComponent } from './static-header/static-header.component';
import { SecondsolutionsComponent } from './secondsolutions/secondsolutions.component';
import { ThreeGridsComponent } from './three-grids/three-grids.component';
import { StaticOverviewBannerComponent } from './static-overview-banner/static-overview-banner.component';
import { SolutionsComponent } from './solutions/solutions.component';
import { SideIconWithTextComponent } from './side-icon-with-text/side-icon-with-text.component';
import { StaticCtaComponent } from './static-cta/static-cta.component';
import { StaticBannerComponent } from './static-banner/static-banner.component';
import { RequestDemoModalComponent } from './request-demo-modal/request-demo-modal.component';
import { FsBusinessReferenceBreakdownComponent } from './fs-business-reference-breakdown/fs-business-reference-breakdown.component';
import { OrderPodComponent } from './fs-business-reference-breakdown/order-pod/order-pod.component';
import { PillInputComponent } from './pill-input/pill-input.component';
import { PaystackComponent } from './payment/paystack/paystack.component';
import { SavedPaystackCardsComponent } from './payment/paystack/saved-paystack-cards/saved-paystack-cards.component';
import { CollapsibleOrderTableComponent } from './collapsible-order-table/collapsible-order-table.component';
import { V2PostDispatchSummaryComponent } from 'app/dashboard/post-dispatch/v2-post-dispatch-summary/v2-post-dispatch-summary.component';
import { PostDispatchTodayComponent } from 'app/dashboard/post-dispatch/post-dispatch-today/post-dispatch-today.component';
import { RadioSelectModalComponent } from './radio-select-modal/radio-select-modal.component';
import { PaystackService } from './payment/paystack/paystack.service';
import { PhoneValidationPipe } from './pipes/phone-validation.pipe';
import { MenuComponent } from './menu/menu.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepperModule } from '@angular/material/stepper';
import { MatMenuModule } from '@angular/material/menu';
import { MaterialPlacesAutocompleteComponent } from './material-places-autocomplete/material-places-autocomplete.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MaterialDatePickerComponent } from './material-date-picker/material-date-picker.component';
import { MaterialInputComponent } from './material-input/material-input.component';
import { SideMapComponent } from './side-map/side-map.component';
import { MaterialShiftSelectorComponent } from './material-shift-selector/material-shift-selector.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCardModule } from '@angular/material/card';
import { MatSortModule } from '@angular/material/sort';
import { GoogleMapsModule } from '@angular/google-maps';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AvatarModule } from 'ngx-avatars';
import { MapDrawingHelperDirective } from './map-drawing-helper/map-drawing-helper.directive';
import { AssignDriverModalComponent } from './assign-driver-modal/assign-driver-modal.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { MatButtonModule } from '@angular/material/button';
import { MatSliderModule } from '@angular/material/slider';
import { CarouselComponent } from './carousel/carousel.component';
import { CarouselItemComponent } from './carousel/components/carousel-item/carousel-item.component';
import { MessageComponent } from './discontinued-message/message/message.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { HumanizeTimePipe } from './pipes/humanize-time.pipe';
import { PostDispatchDispatchComponent } from 'app/dashboard/post-dispatch/modules/dispatch/post-dispatch-dispatch/post-dispatch-dispatch.component';
import { PostDispatchTrackingComponent } from 'app/dashboard/post-dispatch/modules/tracking/post-dispatch-tracking/post-dispatch-tracking.component';
import { TrackingActiveRoutesComponent } from 'app/dashboard/post-dispatch/modules/tracking/tables/tracking-active-routes/tracking-active-routes.component';
import { TrackingCompletedComponent } from 'app/dashboard/post-dispatch/modules/tracking/tables/tracking-completed/tracking-completed.component';
import { TrackingInprogressTripsComponent } from 'app/dashboard/post-dispatch/modules/tracking/tables/tracking-inprogress-trips/tracking-inprogress-trips.component';
import { TrackingPendingComponent } from 'app/dashboard/post-dispatch/modules/tracking/tables/tracking-pending/tracking-pending.component';
import { DispatchAlertsComponent } from 'app/dashboard/post-dispatch/modules/dispatch/tables/dispatch-alerts/dispatch-alerts.component';
import { DispatchCompletedComponent } from 'app/dashboard/post-dispatch/modules/dispatch/tables/dispatch-completed/dispatch-completed.component';
import { DispatchFailedComponent } from 'app/dashboard/post-dispatch/modules/dispatch/tables/dispatch-failed/dispatch-failed.component';
import { DispatchLotteriesComponent } from 'app/dashboard/post-dispatch/modules/dispatch/tables/dispatch-lotteries/dispatch-lotteries.component';
import { DispatchManualComponent } from 'app/dashboard/post-dispatch/modules/dispatch/tables/dispatch-manual/dispatch-manual.component';
import { DispatchScheduledComponent } from 'app/dashboard/post-dispatch/modules/dispatch/tables/dispatch-scheduled/dispatch-scheduled.component';
import { IsDescriptionEnabledPipe } from './one-to-one/waypoint/Pipes/isdescriptionenabled.pipe';
import { LastEventPipe } from './pipes/last-event.pipe';
import { OrderSummaryPopupComponent } from './order-summary-popup/order-summary-popup.component';
import { TableSelectModalComponent } from './modals/table-select-modal/table-select-modal.component';
import { GenericModularTableComponent } from './generic-modular-table/generic-modular-table.component';
import { StartEndPointComponent } from './start-end-point/start-end-point.component';
import { AddressSelectComponent } from './start-end-point/components/address-select/address-select.component';
import { AssignDriverPipePipe } from './pipes/assign-driver-pipe.pipe';
import { AuthInterceptor } from './interceptors/auth.interceptor';

@NgModule({
  exports: [
    AvatarModule,
    SliderValueComponent,
    AngularSvgIconModule,
    AutocompleteComponent,
    BasicButtonComponent,
    BasicButtonGroupComponent,
    BreadcrumbsComponent,
    CamelCaseToTitleCase,
    ReplacePipe,
    CardComponent,
    CommonModule,
    DatePickerComponent,
    DateTimeComponent,
    DirectivesModule,
    DropdownComponent,
    FlexContainerComponent,
    FlexChildComponent,
    FormsModule,
    FullTextSearchPipe,
    GeocodingToolComponent,
    GridContainerComponent,
    HeaderComponent,
    HeadingComponent,
    IconButtonComponent,
    IconComponent,
    IconTimestampStatusComponent,
    IconWithTextComponent,
    ImageWithCaptionComponent,
    InputFieldComponent,
    LoaderComponent,
    MapComponent,
    MenuDropDownComponent,
    ModalComponent,
    MyFilterPipe,
    NavigationComponent,
    NoContentPlaceholderComponent,
    PageFilterButtonsComponent,
    PlacesAutocompleteComponent,
    ProcessingIndicatorComponent,
    ReactiveFormsModule,
    RevealMoreComponent,
    SectionComponent,
    SelectComponent,
    ShiftSelectorComponent,
    SidebarComponent,
    StatsIconComponent,
    SummaryLineComponent,
    TableComponent,
    TableSummaryComponent,
    TableSummaryRowComponent,
    TimePipe,
    TooltipButtonComponent,
    TopBarComponent,
    UnderScoresPipe,
    UploadButtonComponent,
    UpcomingToLatestPipe,
    OrderByDatePipe,
    ValueWithLabelComponent,
    FinancialSummaryComponent,
    TimeDatePickerModalComponent,
    ColorPickerModule,
    ParcelReportTableComponent,
    LineGraphComponent,
    HeatmapComponent,
    ComplexPieComponent,
    PolarComponent,
    ColorPickerModalComponent,
    VerticalGroupedBarComponent,
    TreeGraphComponent,
    WaypointComponent,
    OneToOneComponent,
    QuoteOneToManyComponent,
    SelectMethodComponent,
    WaypointSummaryBlockComponent,
    StringFilterPipe,
    HomePageStatusComponent,
    PlacePicupButtonComponent,
    GaugeComponent,
    PaymentComponent,
    PeachPaymentsComponent,
    InvoicesComponent,
    LoadingSectionComponent,
    SliderButtonComponent,
    AddressBookComponent,
    HumanizePipe,
    EditAddressComponent,
    StaticSiteNavComponent,
    FullPageSplitComponent,
    GlowPillComponent,
    StaticFooterComponent,
    HowToBlockComponent,
    NavigationAdminComponent,
    ZoneEditorComponent,
    BackButtonComponent,
    PackageSelectorComponent,
    VehicleTrackingMapComponent,
    AlertsComponent,
    AlertsMapComponent,
    SelectModuleComponent,
    AdminActionsComponent,
    ImageModalComponent,
    MapPolylineComponent,
    MapMarkerComponent,
    MapGeojsonDatalayerComponent,
    MapWaypointMarkerComponent,
    EditPreferredDriversComponent,
    MapViewComponent,
    ConfirmInputModalComponent,
    SectionIconButtonComponent,
    SortByPipe,
    OndemandFeaturesComponent,
    StaticHeaderComponent,
    SolutionsComponent,
    SecondsolutionsComponent,
    ThreeGridsComponent,
    StaticOverviewBannerComponent,
    SideIconWithTextComponent,
    StaticCtaComponent,
    StaticBannerComponent,
    RequestDemoModalComponent,
    FsBusinessReferenceBreakdownComponent,
    OrderPodComponent,
    PillInputComponent,
    PaystackComponent,
    SavedPaystackCardsComponent,
    CollapsibleOrderTableComponent,
    V2PostDispatchSummaryComponent,
    PostDispatchTodayComponent,
    MenuComponent,
    MatNativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MaterialDatePickerComponent,
    MatIconModule,
    MatDialogModule,
    MatInputModule,
    MaterialInputComponent,
    DragDropModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatMenuModule,
    MaterialPlacesAutocompleteComponent,
    MatRadioModule,
    MatButtonToggleModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatSidenavModule,
    TimeSelectorComponent,
    MaterialShiftSelectorComponent,
    PhoneValidationPipe,
    MatSlideToggleModule,
    MatTableModule,
    MatTabsModule,
    MatChipsModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatCardModule,
    MatSortModule,
    GoogleMapsModule,
    NgxChartsModule,
    MatButtonModule,
    MatSliderModule,
    CarouselComponent,
    CarouselItemComponent,
    MessageComponent,
    OverlayModule,
    BypassUrlPipe,
    HumanizeTimePipe,
    PostDispatchTrackingComponent,
    PostDispatchDispatchComponent,
    TrackingInprogressTripsComponent,
    TrackingActiveRoutesComponent,
    TrackingCompletedComponent,
    TrackingPendingComponent,
    DispatchAlertsComponent,
    DispatchCompletedComponent,
    DispatchFailedComponent,
    DispatchLotteriesComponent,
    DispatchManualComponent,
    DispatchScheduledComponent
  ],
  declarations: [
    AutocompleteComponent,
    BasicButtonComponent,
    BasicButtonGroupComponent,
    BreadcrumbsComponent,
    CamelCaseToTitleCase,
    CardComponent,
    ConfirmModalComponent,
    DatePickerComponent,
    DatePickerModalComponent,
    DateTimeComponent,
    DropdownComponent,
    FlexContainerComponent,
    FlexChildComponent,
    FullTextSearchPipe,
    GeocodingToolComponent,
    GridContainerComponent,
    HeaderComponent,
    HeadingComponent,
    IconButtonComponent,
    IconComponent,
    IconTimestampStatusComponent,
    IconWithTextComponent,
    ImageWithCaptionComponent,
    InputFieldComponent,
    LoaderComponent,
    MapComponent,
    MenuDropDownComponent,
    ModalComponent,
    MyFilterPipe,
    NavigationComponent,
    NoContentPlaceholderComponent,
    PageFilterButtonsComponent,
    PlacesAutocompleteComponent,
    ProcessingIndicatorComponent,
    RevealMoreComponent,
    SectionComponent,
    SelectComponent,
    ShiftSelectorComponent,
    SidebarComponent,
    StatsIconComponent,
    SummaryLineComponent,
    TableComponent,
    TableSummaryComponent,
    TableSummaryRowComponent,
    TimePipe,
    TooltipButtonComponent,
    TopBarComponent,
    UnderScoresPipe,
    UploadButtonComponent,
    UpcomingToLatestPipe,
    OrderByDatePipe,
    ValueWithLabelComponent,
    FinancialSummaryComponent,
    TimeSelectorComponent,
    TimeDatePickerModalComponent,
    ParcelReportTableComponent,
    LineGraphComponent,
    HeatmapComponent,
    ComplexPieComponent,
    PolarComponent,
    ColorPickerModalComponent,
    VerticalGroupedBarComponent,
    TreeGraphComponent,
    OneToOneComponent,
    WaypointComponent,
    QuoteOneToManyComponent,
    SelectMethodComponent,
    WaypointSummaryBlockComponent,
    StringFilterPipe,
    HomePageStatusComponent,
    PlacePicupButtonComponent,
    GaugeComponent,
    ReplacePipe,
    SearchDriverNameComponent,
    PaymentComponent,
    PeachPaymentsComponent,
    InvoicesComponent,
    LoadingSectionComponent,
    AddressBookComponent,
    HumanizePipe,
    SliderButtonComponent,
    EditAddressComponent,
    StaticSiteNavComponent,
    FullPageSplitComponent,
    GlowPillComponent,
    StaticFooterComponent,
    HowToBlockComponent,
    NavigationAdminComponent,
    ZoneEditorComponent,
    BackButtonComponent,
    PackageSelectorComponent,
    VehicleTrackingMapComponent,
    AlertsComponent,
    AlertsMapComponent,
    AlertsTableComponent,
    SelectModuleComponent,
    AdminActionsComponent,
    ImageModalComponent,
    MapViewComponent,
    MapPolylineComponent,
    MapMarkerComponent,
    MapGeojsonDatalayerComponent,
    MapWaypointMarkerComponent,
    EditPreferredDriversComponent,
    ConfirmInputModalComponent,
    TextInputModalComponent,
    CreateBucketModalComponent,
    AvailableBucketsComponent,
    SectionIconButtonComponent,
    SelectDriverModalComponent,
    DriverShiftSelectModalComponent,
    NumberCardComponent,
    ParcelTimelineComponent,
    SortByPipe,
    SliderValueComponent,
    BooleanModalComponent,
    OndemandFeaturesComponent,
    StaticHeaderComponent,
    SolutionsComponent,
    SecondsolutionsComponent,
    ThreeGridsComponent,
    StaticOverviewBannerComponent,
    SideIconWithTextComponent,
    StaticCtaComponent,
    StaticBannerComponent,
    RequestDemoModalComponent,
    FsBusinessReferenceBreakdownComponent,
    OrderPodComponent,
    PillInputComponent,
    PaystackComponent,
    SavedPaystackCardsComponent,
    CollapsibleOrderTableComponent,
    V2PostDispatchSummaryComponent,
    PostDispatchTodayComponent,
    RadioSelectModalComponent,
    MenuComponent,
    MaterialDatePickerComponent,
    SideMapComponent,
    MaterialInputComponent,
    MaterialPlacesAutocompleteComponent,
    MaterialShiftSelectorComponent,
    PhoneValidationPipe,
    MapDrawingHelperDirective,
    AssignDriverModalComponent,
    ConfirmationModalComponent,
    CarouselComponent,
    CarouselItemComponent,
    MessageComponent,
    BypassUrlPipe,
    HumanizeTimePipe,
    PostDispatchTrackingComponent,
    PostDispatchDispatchComponent,
    TrackingInprogressTripsComponent,
    TrackingActiveRoutesComponent,
    TrackingCompletedComponent,
    TrackingPendingComponent,
    DispatchAlertsComponent,
    DispatchCompletedComponent,
    DispatchFailedComponent,
    DispatchLotteriesComponent,
    DispatchManualComponent,
    DispatchScheduledComponent,
    IsDescriptionEnabledPipe,
    LastEventPipe,
    OrderSummaryPopupComponent,
    TableSelectModalComponent,
    GenericModularTableComponent,
    StartEndPointComponent,
    AddressSelectComponent,
    AssignDriverPipePipe
  ],
  entryComponents: [],
  providers: [MatDatepickerModule, { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }, PhoneValidationPipe, {
    provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true
  }],
  imports: [
    AvatarModule,
    CommonModule,
    RouterModule,
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    ColorPickerModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatDialogModule,
    MatInputModule,
    MatSelectModule,
    GoogleMapsModule,
    NgxChartsModule,
    MatAutocompleteModule,
    MatIconModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatTableModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatCardModule,
    MatCheckboxModule
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [SharedService, PaystackService],
    };
  }
}
