<div *ngIf="!tripViewActive">
  <app-section>
    <heading [color]="Icons.Colors.Blue" [icon]="Icons.Types.Trip">
      <h4 class="heading-title">Assigned Trips</h4>
      <div class="button-wrap" style="align-items: center">
        <p class="trip-selected-summary">
          <strong>
            <span *ngIf="trips.hasFetched">{{ trips.picup.length }}</span>
            <i *ngIf="!trips.hasFetched" class="status-in-progress fa fa-spinner fa-spin"></i>
            <span> trips selected for Picup.</span>
          </strong>
          <br />
          <span *ngIf="trips.hasFetched">{{ trips.contract.length }}</span>
          <i *ngIf="!trips.hasFetched" class="status-in-progress fa fa-spinner fa-spin"></i>
          <span> trips selected for contractors.</span>
        </p>
        <app-basic-button
          [title]="'Reset'"
          [type]="buttonTypes.Primary"
          [disabled]="hasReset || !(trips.previouslySaved || hasSortedTrips) || readOnly"
          (click)="resetTrips()"
        >
        </app-basic-button>
        <app-basic-button
          [title]="saveButtonLabel"
          [type]="buttonTypes.Primary"
          [disabled]="!(hasReset || hasSortedTrips) || trips.unassigned.length > 0 || saveButtonLabel === 'Saved' || readOnly"
          [processing]="savingTripsInProcess"
          (click)="saveTrips()"
        >
        </app-basic-button>
        <app-basic-button
          [title]="'Save and Proceed'"
          [type]="buttonTypes.Primary"
          [disabled]="trips.unassigned.length > 0"
          [processing]="finaliseTripCostsInProcess || savingTripsInProcess || readOnly"
          (click)="environement.appVersion === 1 ? finaliseTripCosts() : finaliseV2TripCosts()"
        >
        </app-basic-button>
      </div>
    </heading>
  </app-section>

  <app-section>
    <heading [icon]="Icons.Types.Stats" [color]="Icons.Colors.Blue">
      <h4 class="heading-title">Summary</h4>
      <app-basic-button 
        [title]="'Toggle Map'" 
        [type]="buttonTypes.Primary" 
        (click)="toggleMap()"> 
      </app-basic-button>
    </heading>

    <div *ngIf="showTripsOnMap">
      <!-- <app-map [mapType]="MapTypes.Trips" [geoJson]="allTripGeoJson"></app-map> -->
      <app-map-view
        [mapType]="MapTypes.Trips"
        [loading]="!allTripGeoJson"
        [geoJsonDataLayers]="[allTripGeoJson]"
      ></app-map-view>
    </div>
    <!-- TODO: tabledata?  -->
    <app-table
      [columns]="_tripsSummaryTableColumns"
      [tableData]="summary"
      [isLoading]="!trips.hasFetched"
      [theme]="tableThemes.HighlightedSummary"
      [rowsSelectable]="false"
      [displayRowHeaders]="true"
    >
    </app-table>
  </app-section>

  <app-section *ngIf="trips.unrouted.length > 0">
    <heading [icon]="Icons.Types.Unrouted" [color]="Icons.Colors.Orange">
      <h4 class="heading-title">
        <strong *ngIf="trips.hasFetched">{{ trips.unrouted.length }}</strong>
        <i *ngIf="!trips.hasFetched" class="status-in-progress fa fa-spinner fa-spin"></i>
        <span> Unrouted Orders</span>
        (Save and proceed to allocate to 3rd Party Courier)
      </h4>
      <div class="button-wrap">
        <app-basic-button 
          [title]="'Toggle Table'" 
          [type]="buttonTypes.Primary" 
          (click)="showUnrouted = !showUnrouted">
        </app-basic-button>
      </div>
    </heading>
    <app-table
      [hidden]="!showUnrouted"
      [showAnnotations]="true"
      [rowsSelectable]="false"
      [columns]="_unroutedOrdersTableColumns"
      [tableData]="trips.unrouted"
      [isLoading]="!trips.hasFetched"
      [readOnly]="readOnly"
    >
    </app-table>
  </app-section>

  <app-section>
    <heading [icon]="Icons.Types.Trip" [color]="Icons.Colors.Grey1">
      <h4 class="heading-title">
        <strong *ngIf="trips.hasFetched">{{ trips.unassigned.length }}</strong>
        <i *ngIf="!trips.hasFetched" class="status-in-progress fa fa-spinner fa-spin"></i>
        <span> Unsorted Trips</span>
      </h4>
      <div class="button-wrap">
        <app-basic-button 
          [title]="'Toggle Suburbs'" 
          [type]="buttonTypes.Primary" 
          (click)="hideSuburbs = !hideSuburbs">
        </app-basic-button>
      </div>
    </heading>
    <app-table
      [columns]="_tripsTableColumns"
      [tableData]="trips.unassigned"
      [primaryActions]="tblTripsGenericPrimaryActions"
      [bulkActions]="tblTripsUnsortedBulkActions"
      [toggleColumns]="[
        { name: 'suburbs', hidden: hideSuburbs },
        { name: 'driverEarnings', hidden: !user.is_admin },
        { name: 'driverEarningsReturn', hidden: !user.is_admin },
        { name: 'delivery_date', hidden: !multi_date_bucket }
      ]"
      [stickyActions]="true"
      [showSelectionTotals]="true"
      [showSelectionAverages]="true"
      [showSelectionSummaryBottom]="true"
      [isLoading]="!trips.hasFetched"
      (actionTaken)="handleTableTripUnsortedActions($event)"
      [readOnly]="readOnly"
    >
    </app-table>
  </app-section>

  <app-section *ngIf="environement.envName !== 'onthedot'">
    <heading [icon]="Icons.Types.Trip" [color]="Icons.Colors.Blue">
      <h4 class="heading-title">
        <strong *ngIf="trips.hasFetched">{{ trips.picup.length }} </strong>
        <i *ngIf="!trips.hasFetched" class="status-in-progress fa fa-spinner fa-spin"></i>
        <span> Picup Trips</span>
      </h4>
      <div class="button-wrap">
        <app-basic-button 
          [title]="'Toggle Suburbs'" 
          [type]="buttonTypes.Primary" 
          (click)="hideSuburbs = !hideSuburbs">
        </app-basic-button>
      </div>
    </heading>
    <app-table
      [columns]="_tripsTableColumns"
      [tableData]="trips.picup"
      [primaryActions]="tblTripsGenericPrimaryActions"
      [bulkActions]="tblTripsPicupBulkActions"
      [toggleColumns]="[{ name: 'suburbs', hidden: hideSuburbs }]"
      [stickyActions]="true"
      [showTableAverages]="true"
      [showTableTotals]="true"
      [showSelectionTotals]="true"
      [showSelectionAverages]="true"
      [showSelectionSummaryBottom]="true"
      [showSelectionSummaryTop]="false"
      [isLoading]="!trips.hasFetched"
      [zeroDataMessage]="_noSelectedTripsMessage"
      (actionTaken)="handleTableTripPicupActions($event)"
      [readOnly]="readOnly"
    >
    </app-table>
  </app-section>

  <app-section>
    <heading [icon]="Icons.Types.Trip" [color]="Icons.Colors.Orange">
      <h4 class="heading-title">
        <strong *ngIf="trips.hasFetched">{{ trips.contract.length }} </strong>
        <i *ngIf="!trips.hasFetched" class="status-in-progress fa fa-spinner fa-spin"></i>
        <span> Contract Trips</span>
      </h4>
      <div class="button-wrap">
        <app-basic-button 
          [title]="'Toggle Suburbs'" 
          [type]="buttonTypes.Primary" 
          (click)="hideSuburbs = !hideSuburbs">
        </app-basic-button>
      </div>
    </heading>
    <app-table
      [columns]="_tripsTableColumns"
      [tableData]="trips.contract"
      [primaryActions]="tblTripsGenericPrimaryActions"
      [bulkActions]="tblTripsContractBulkActions"
      [toggleColumns]="[
        { name: 'suburbs', hidden: hideSuburbs },
        { name: 'delivery_date', hidden: !multi_date_bucket }
      ]"
      [stickyActions]="true"
      [showTableAverages]="true"
      [showTableTotals]="true"
      [showSelectionTotals]="true"
      [showSelectionAverages]="true"
      [showSelectionSummaryBottom]="true"
      [showSelectionSummaryTop]="false"
      [isLoading]="!trips.hasFetched"
      [zeroDataMessage]="_noSelectedTripsMessage"
      (actionTaken)="handleTableTripContractActions($event)"
      [readOnly]="readOnly"
    >
    </app-table>
  </app-section>

  <app-section>
    <heading [icon]="Icons.Types.Trip" [color]="Icons.Colors.Yellow">
      <h4 class="heading-title">
        <strong *ngIf="trips.hasFetched">{{ trips.courier.length }} </strong>
        <i *ngIf="!trips.hasFetched" class="status-in-progress fa fa-spinner fa-spin"></i>
        <span> Unassigned / Unfulfilled Trips</span>
      </h4>
      <div class="button-wrap">
        <app-basic-button 
          [title]="'Toggle Suburbs'"
          [type]="buttonTypes.Primary" 
          (click)="hideSuburbs = !hideSuburbs">
        </app-basic-button>
      </div>
    </heading>
    <app-table
      [columns]="_tripsTableColumns"
      [tableData]="trips.courier"
      [primaryActions]="tblTripsGenericPrimaryActions"
      [bulkActions]="tblTripsUnsortedBulkActions"
      [toggleColumns]="[
        { name: 'suburbs', hidden: hideSuburbs },
        { name: 'delivery_date', hidden: !multi_date_bucket }
      ]"
      [stickyActions]="true"
      [showTableAverages]="true"
      [showTableTotals]="true"
      [showSelectionTotals]="true"
      [showSelectionAverages]="true"
      [showSelectionSummaryBottom]="true"
      [showSelectionSummaryTop]="false"
      [isLoading]="!trips.hasFetched"
      [zeroDataMessage]="_noSelectedTripsMessage"
      (actionTaken)="handleTableTripCourierActions($event)"
      [readOnly]="readOnly"
    >
    </app-table>
  </app-section>
</div>
<app-view-trip
  *ngIf="tripViewActive"
  [tripId]="selectedTripId"
  [bucketId]="bucketId"
  (closeTripView)="handleCloseTripView()"
></app-view-trip>
