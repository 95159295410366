import {
  DisplayPipes,
  TableAction,
  TableColumn,
  TableDataMap,
  TablePostfixes,
} from '../../../../shared/table/table.interfaces';
import { SubmittedTrips } from './trip-costs.interfaces';
import { mfDivXY } from '../../../../shared/table/map-formatters.table.helpers';
import { IconTypes } from '../../../../shared/icon/icon.interfaces';
import { UiColors } from '../../../../interfaces/ui.interfaces';
import { mfSetPreferredDriverHighlightColor } from 'app/dashboard/post-dispatch/post-dispatch.helpers';
import { environment } from 'environments/environment';
import { mfIconVehicleType } from '../costing-settings/contractor-settings/contractor-settings.constants';

export const noSelectedTripsMessage = 'You have not made any selections';

export const emptySubmittedTrips: SubmittedTrips = {
  picup: [],
  contract: [],
};

export const tripsTableColumns: TableColumn[] = [
  { name: 'id', displayName: 'ID', sortable: true },
  { name: 'delivery_date', displayName: 'Delivery Date', sortable: true },
  { name: 'consignment', displayName: 'Consignment', sortable: true },
  {
    name: 'distance',
    displayName: 'Distance',
    shouldCalculate: true,
    sortable: true,
  },
  {
    name: 'orders',
    displayName: 'Orders',
    shouldCalculate: true,
    sortable: true,
  },
  {
    name: 'parcels',
    displayName: 'Parcels',
    shouldCalculate: true,
    sortable: true,
  },
  {
    name: 'returnKm',
    displayName: 'Return Km',
    shouldCalculate: true,
    sortable: true,
  },
  {
    name: 'PARCSReturn',
    displayName: 'PARCS Return',
    shouldCalculate: true,
    sortable: true,
  },
  {
    name: 'driverEarnings',
    displayName: 'Earnings',
    shouldCalculate: true,
    sortable: true,
  },
  {
    name: 'driverEarningsReturn',
    displayName: 'Return Earnings',
    shouldCalculate: true,
    sortable: true,
  },
  // { name: 'fixedCost', displayName: 'Fixed Cost', shouldCalculate: true,  sortable: true },
  // { name: 'fixedCostsPerParcel', displayName: 'Fixed Price/Parcel', shouldCalculate: true,  sortable: true },
  // {
  //   name: "parcsEstimate",
  //   displayName: "Estimate (ex Vat)",
  //   shouldCalculate: true,
  //   sortable: true,
  // },
  {
    name: 'totalPrice',
    displayName: 'Total (exVat)',
    shouldCalculate: true,
    sortable: true,
  },
  {
    name: 'actualCostPerParcel',
    displayName: 'Price/Parcel',
    shouldCalculate: true,
    sortable: true,
  },
  {
    name: 'actualCostPerOrder',
    displayName: 'Price/Order',
    shouldCalculate: true,
    sortable: true,
  },
  { name: 'suburbs', displayName: 'Suburbs' },
  {
    name: 'preferred_drivers_count',
    displayName: 'Preferred Drivers',
    sortable: true,
  },
  {
    name: 'vehicle_id',
    displayName: 'Vehicle',
    sortable: false,
  },
];

export const tripsTablePrimaryActions: TableAction[] = [{ event: 'viewTrip', title: 'View Trip', icon: IconTypes.Eye }];

export const tripsTableBulkActions = {
  picup: <TableAction>{
    event: 'assignPicup',
    title: 'Assign to Picup',
    icon: IconTypes.Trip,
    icon_color: UiColors.Blue,
  },
  contract: <TableAction>{
    event: 'assignContract',
    title: 'Assign to Contract',
    icon: IconTypes.Trip,
    icon_color: UiColors.Orange,
  },
  courier: <TableAction>{
    event: 'assignCourier',
    title: 'Unassign / Do not fulfill',
    icon: IconTypes.Trip,
    icon_color: UiColors.Red,
  },
  unassign: <TableAction>{
    event: 'unassign',
    title: 'Unassign',
    icon: IconTypes.Trip,
    icon_color: UiColors.Green,
  },
};

export const tripsTableDataMap: TableDataMap = {
  cells: {
    id: {
      column: 'id',
      map: 'trip_id',
      displayPipeArgs: ['none'],
    },
    delivery_date: {
      column: 'delivery_date',
      map: 'delivery_date',
      displayPipeArgs: ['date', 'dd LLL'],
    },
    consignment: {
      column: 'consignment',
      map: 'consignment',
    },
    distance: {
      column: 'distance',
      map: 'distance_in_km',
      postfix: TablePostfixes.Km,
      displayPipeArgs: DisplayPipes.decimalsTwo,
    },
    orders: {
      column: 'orders',
      map: 'order_count',
      displayPipeArgs: DisplayPipes.decimalsNone,
    },
    parcels: {
      column: 'parcels',
      map: 'parcel_count',
      displayPipeArgs: DisplayPipes.decimalsNone,
    },
    // fixedCost: {
    //   column: "fixedCost",
    //   map: "fixed_price",
    //   prefix: environment.currency_symbol + " ",
    //   displayPipeArgs: DisplayPipes.decimalsTwo,
    // },
    returnKm: {
      column: 'returnKm',
      map: 'distance_back_to_depot_in_km',
      postfix: TablePostfixes.Km,
      displayPipeArgs: DisplayPipes.decimalsTwo,
    },
    // fixedCostsPerParcel: {
    //   column: "fixedCostsPerParcel",
    //   map: ["fixed_price", "parcel_count"],
    //   format: mfDivXY,
    //   prefix: environment.currency_symbol + " ",
    //   displayPipeArgs: DisplayPipes.decimalsTwo,
    // },
    driverEarnings: {
      column: 'driverEarnings',
      map: 'driver_earnings',
      prefix: environment.currency_symbol + ' ',
      displayPipeArgs: DisplayPipes.decimalsTwo,
    },
    PARCSReturn: {
      column: 'PARCSReturn',
      map: 'parcs_price_back_to_depot',
      prefix: environment.currency_symbol + ' ',
      displayPipeArgs: DisplayPipes.decimalsTwo,
    },
    driverEarningsReturn: {
      column: 'driverEarningsReturn',
      map: 'driver_earnings_back_to_depot',
      prefix: environment.currency_symbol + ' ',
      displayPipeArgs: DisplayPipes.decimalsTwo,
    },
    // parcsEstimate: {
    //   column: "parcsEstimate",
    //   map: "parcs_price",
    //   prefix: environment.currency_symbol + " ",
    //   displayPipeArgs: DisplayPipes.decimalsTwo,
    // },
    totalPrice: {
      column: 'totalPrice ',
      map: 'parcs_price',
      prefix: environment.currency_symbol + ' ',
      displayPipeArgs: DisplayPipes.decimalsTwo,
    },
    actualCostPerParcel: {
      column: 'actualCostPerParcel',
      map: ['parcs_price', 'parcel_count'],
      format: mfDivXY,
      prefix: environment.currency_symbol + ' ',
      displayPipeArgs: DisplayPipes.decimalsTwo,
    },
    actualCostPerOrder: {
      column: 'actualCostPerOrder',
      map: ['parcs_price', 'order_count'],
      format: mfDivXY,
      prefix: environment.currency_symbol + ' ',
      displayPipeArgs: DisplayPipes.decimalsTwo,
    },
    suburbs: {
      column: 'suburbs',
      map: 'suburbs',
    },
    preferred_drivers_count: {
      column: 'preferred_drivers_count',
      map: 'preferred_drivers_count',
      highlighted: true,
      highlightColorClass: mfSetPreferredDriverHighlightColor,
      highlightColorMap: ['preferred_drivers_count'],
    },
    vehicle_id: {
      column: 'vehicle_id',
      map: '',
      iconMap: ['vehicle_id', 'vehicle_id'],
      iconFormat: mfIconVehicleType,
      iconColor: UiColors.Blue,
      iconSizeRem: 2,
    },
  },
};

export const tripsSummaryTableColumns: TableColumn[] = [
  { name: 'distance', displayName: 'Distance' },
  { name: 'orders', displayName: 'Orders' },
  { name: 'parcels', displayName: 'Parcels' },
  // { name: "fixedCost", displayName: "Fixed Cost" },
  // { name: "fixedCostsPerParcel", displayName: "Fixed Costs/Parcel" },
  // { name: "parcsEstimate", displayName: "Estimate" },
  { name: 'totalCost', displayName: 'Total (exVat)' },
  { name: 'actualCostPerParcel', displayName: 'Price/Parcel' },
  { name: 'actualCostPerOrder', displayName: 'Price/Order' },
];

export const unroutedOrdersDataMap: TableDataMap = {
  cells: {
    businessReference: {
      column: 'businessReference',
      map: 'business_reference',
      displayPipeArgs: ['none'],
    },
    address: {
      column: 'address',
      map: 'geocoded_address',
      annotation_map: 'client_address',
      displayAnnotationPipeArgs: ['none'],
    },
    consignment: {
      column: 'consignment',
      map: 'consignment',
    },
    customerName: {
      column: 'customerName',
      map: 'customer_name',
    },
    latitude: {
      column: 'latitude',
      map: 'latitude',
    },
    longitude: {
      column: 'longitude',
      map: 'longitude',
    },
    status: {
      column: 'status',
      map: 'status',
    },
    unservedReason: {
      column: 'unservedReason',
      map: 'unserved_reason',
    },
  },
};

export const confirmationPicupTablePrimaryActions = [
  { event: 'editDrivers', title: 'Edit Drivers', icon: IconTypes.Driver },
];

export const unroutedOrdersTableColumns: TableColumn[] = [
  { name: 'businessReference', displayName: 'Business Reference' },
  { name: 'address', displayName: 'Address' },
  { name: 'consignment', displayName: 'Consignment' },
  { name: 'customerName', displayName: 'Customer Name' },
  { name: 'latitude', displayName: 'lat', hidden: true },
  { name: 'longitude', displayName: 'lng', hidden: true },
  { name: 'status', displayName: 'Geocode Status' },
  { name: 'unservedReason', displayName: 'Reason' },
];

export const tripsSummaryTableDataMap: TableDataMap = {
  row: {
    heading: 'rowHeading',
  },
  cells: {
    distance: {
      column: 'distance',
      map: 'distance_in_km',
      postfix: TablePostfixes.Km,
      displayPipeArgs: DisplayPipes.decimalsTwo,
    },
    orders: {
      column: 'orders',
      map: 'orders',
      displayPipeArgs: DisplayPipes.decimalsNone,
    },
    parcels: {
      column: 'parcels',
      map: 'parcels',
      displayPipeArgs: DisplayPipes.decimalsNone,
    },
    // fixedCost: {
    //   column: "fixedCost",
    //   map: "fixed_price",
    //   prefix: environment.currency_symbol + " ",
    //   displayPipeArgs: DisplayPipes.decimalsTwo,
    // },
    // fixedCostsPerParcel: {
    //   column: "fixedCostsPerParcel",
    //   map: ["fixed_price", "parcels"],
    //   format: mfDivXY,
    //   prefix: environment.currency_symbol + " ",
    //   displayPipeArgs: DisplayPipes.decimalsTwo,
    // },
    // parcsEstimate: {
    //   column: "parcsEstimate",
    //   map: "parcs_price",
    //   prefix: environment.currency_symbol + " ",
    //   displayPipeArgs: DisplayPipes.decimalsTwo,
    // },
    totalCost: {
      column: 'totalCost',
      map: 'parcs_price',
      prefix: environment.currency_symbol + ' ',
      displayPipeArgs: DisplayPipes.decimalsTwo,
    },
    actualCostPerParcel: {
      column: 'actualCostPerParcel',
      map: ['parcs_price', 'parcels'],
      format: mfDivXY,
      prefix: environment.currency_symbol + ' ',
      displayPipeArgs: DisplayPipes.decimalsTwo,
    },
    actualCostPerOrder: {
      column: 'actualCostPerOrder',
      map: ['parcs_price', 'orders'],
      format: mfDivXY,
      prefix: environment.currency_symbol + ' ',
      displayPipeArgs: DisplayPipes.decimalsTwo,
    },
  },
};
