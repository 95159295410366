import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { ConsignmentConfiguration } from './consignments.interface';

@Injectable({
  providedIn: 'root'
})
export class ConsignmentService {

  constructor(private firestore: AngularFirestore) { }

  getBusinessConsignmentsConfiguration(businessId: string): Observable<firebase.default.firestore.DocumentSnapshot<ConsignmentConfiguration>> {
    const collection = this.firestore.collection('frontend-config');
    return collection.doc<ConsignmentConfiguration>(businessId).get();
  }

  getLiveBusinessConsignmentsConfiguration(businessId: string): Observable<ConsignmentConfiguration> {
    const collection = this.firestore.collection('frontend-config');
    return collection.doc<ConsignmentConfiguration>(businessId).valueChanges();
  }

  saveConfiguration(businessId: string, config: ConsignmentConfiguration, exists: boolean): Promise<void> {
    const collection = this.firestore.collection('frontend-config');
    if (exists) {
      return collection.doc<ConsignmentConfiguration>(businessId).update(config);
    } else {
      return collection.doc<ConsignmentConfiguration>(businessId).set(config);
    }
  }
}
