<div class="modal" [class.open]="open">
  <div class="modal-content" [ngClass]="size">
    <ng-content></ng-content>
    <div class="modal-footer" *ngIf="!hideFooter">
      <app-basic-button *ngFor="let action of actions; index as i; count as c;"
                        [type]="(highlightPrimaryAction && action.isPrimary) ? ButtonTypes.Secondary : ButtonTypes.Tertiary"
                        [title]="action.title"
                        [disabled]="action.disabled"
                        [processing]="action.processing"
                        (click)="handleClick(action.name)"
      ></app-basic-button>
    </div>
  </div>
</div>
