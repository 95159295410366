import { NotificationsService } from './../../../shared/notifications/notifications.service';
import { Component, Input, OnChanges } from '@angular/core';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { TableColumn, TableDataMap } from 'app/shared/table/table.interfaces';
import { NewTripsTimelineService } from './data-access/new-trips-timeline.service';
import { mfHumanizeEventString } from 'app/shared/table/map-formatters.table.helpers';
import { azureAxios } from 'app/api/axios';
import { AxiosResponse } from 'axios';

@Component({
  selector: 'app-new-trips-timeline',
  templateUrl: './new-trips-timeline.component.html',
  styleUrls: ['./new-trips-timeline.component.scss'],
  providers: [
    NewTripsTimelineService
  ]
})
export class NewTripsTimelineComponent implements OnChanges{
  @Input() set orderId(orderId: string) {
    this._orderId = orderId;
    this.newTripsTimelineService.setOrderId(orderId);
  }
  @Input() courierIds: string[];
  @Input() failedCourierOrders;

  timelineTrips$ = this.newTripsTimelineService.tripTimelineData$;
  private _orderId: string;

  public IconTypes = IconTypes;
  public UiColors = UiColors;
  public UiThemes = UiThemes;

  parcelList = [];
  courierParcelList = [];
  filteredTableData;
  filteredCourierTableData;
  timelineCourierRawData;
  timelineRawData;
  timelineColumns: TableColumn[] = [
    { name: 'parcel_waybill', displayName: 'Waybill' },
    { name: 'ago', displayName: 'Ago' },
    { name: 'created_at', displayName: 'Created' },
    { name: 'event_name', displayName: 'Event' },
    { name: 'event_arg1', displayName: 'Event Arg 1' },
    { name: 'event_arg2', displayName: 'Event Arg 2' },
  ];

  timelineDataMap: TableDataMap = {
    cells: {
      parcel_waybill: { column: 'parcel_waybill', map: 'ParcelWaybillNumber' },
      created_at: { column: 'created_at', map: 'Timestamp', displayPipeArgs: ['date', 'medium'] },
      ago: { column: 'ago', map: 'Timestamp', displayPipeArgs: ['timeAgo'] },
      event_name: {
        column: 'event_name',
        map: 'EventDescription',
        format: mfHumanizeEventString,
      },
      event_arg1: { column: 'event_arg1', map: 'EventArg1' },
      event_arg2: { column: 'event_arg2', map: 'EventArg2' },
    },
  };

  visual = false;

  constructor(
    private newTripsTimelineService: NewTripsTimelineService,
    private notificationsService: NotificationsService
  ) { }

  ngOnChanges(): void {
    // this.getCourierTrackingEvents();
    this.getTrackingEvents();
  }

  notify(successful: boolean, message: string): void {
    const notificationType = successful ? 'success' : 'error';
    this.notificationsService.publish({
      type: notificationType,
      message: message,
    });
  }

  async getOrderTrackingEvents(order_id: string): Promise<any | AxiosResponse<any>> {
    // Pass in the columns you want returned into the $select clause
    const azureClient = azureAxios(new Date().toUTCString());
    try {
      const response = await azureClient.get(`Webhooks()?$filter=PartitionKey%20eq%20'${order_id}'%20and%20IsPoisonQueue%20eq%20false&$select=ParcelWaybillNumber,Timestamp,EventDescription,EventArg1,EventArg2`);
      return response.data;
    } catch (error) {
      this.notify(false, error.response?.data.message);
      throw error;
    }
  }

  getTrackingEvents(): void {
    this.getOrderTrackingEvents(this._orderId).then((response) => {
      this.timelineRawData = response.value;
      this.timelineRawData.forEach((event) => {
        if (this.parcelList.indexOf(event.ParcelWaybillNumber) === -1) {
          this.parcelList.push(event.ParcelWaybillNumber);
        }
      });
    });
  }



  filterParcels(parcel_waybill): any {
    this.filteredTableData = undefined;
    if (!parcel_waybill) {
      return;
    }
    this.filteredTableData = this.timelineRawData.filter((parcels) => {
      return parcels.ParcelWaybillNumber === parcel_waybill;
    });
  }
}
