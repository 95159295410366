import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-driver-performance-breakdown',
  templateUrl: './driver-performance-breakdown.component.html',
  styleUrls: ['./driver-performance-breakdown.component.scss'],
})
export class DriverPerformanceBreakdownComponent implements OnInit {
  data = [
    {
      name: 'Matt Strybis',
      series: [
        {
          name: 'Delivery Performance',
          value: 7,
        },
        {
          name: 'Customer Rating',
          value: 8,
        },
        {
          name: 'Driver Behaviour',
          value: 9,
        },
      ],
    },
    {
      name: 'Matt Strybis',
      series: [
        {
          name: 'Delivery Performance',
          value: 7,
        },
        {
          name: 'Customer Rating',
          value: 8,
        },
        {
          name: 'Driver Behaviour',
          value: 9,
        },
      ],
    },
    {
      name: 'Antonio Bruni',
      series: [
        {
          name: 'Delivery Performance',
          value: 10,
        },
        {
          name: 'Customer Rating',
          value: 6,
        },
        {
          name: 'Driver Behaviour',
          value: 1,
        },
      ],
    },
    {
      name: 'Bunna Booyens',
      series: [
        {
          name: 'Delivery Performance',
          value: 6,
        },
        {
          name: 'Customer Rating',
          value: 3,
        },
        {
          name: 'Driver Behaviour',
          value: 1,
        },
      ],
    },
    {
      name: 'Ashley Arendse',
      series: [
        {
          name: 'Delivery Performance',
          value: 3,
        },
        {
          name: 'Customer Rating',
          value: 10,
        },
        {
          name: 'Driver Behaviour',
          value: 10,
        },
      ],
    },
    {
      name: 'Ludwig Wolfe',
      series: [
        {
          name: 'Delivery Performance',
          value: 9,
        },
        {
          name: 'Customer Rating',
          value: 10,
        },
        {
          name: 'Driver Behaviour',
          value: 9,
        },
      ],
    },
    {
      name: 'Ameen Gasant',
      series: [
        {
          name: 'Delivery Performance',
          value: 9,
        },
        {
          name: 'Customer Rating',
          value: 10,
        },
        {
          name: 'Driver Behaviour',
          value: 5,
        },
      ],
    },
    {
      name: 'Clint Abrahams',
      series: [
        {
          name: 'Delivery Performance',
          value: 9,
        },
        {
          name: 'Customer Rating',
          value: 10,
        },
        {
          name: 'Driver Behaviour',
          value: 6,
        },
      ],
    },
  ];

  colorScheme = {
    domain: ['#02DE73', '#ff7100', '#27B7FC'],
  };

  constructor() {}

  ngOnInit(): void {}
}
