<ng-container *ngIf="state">
  <app-processing-indicator
      *ngIf="state.status !== 'Completed'; else importingCompleted"
      [percentageComplete]="state.progress"
      [title]="'Processing'">
  </app-processing-indicator>
</ng-container>

<ng-template #importingCompleted>
  <div class="importing-complete">
    <h2>Processed</h2>
  </div>
</ng-template>