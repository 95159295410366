<div class="actions-wrap" *ngIf="alertsData.length > 10">
  <div class="search-bar">
    <app-basic-button
      class="filter-button"
      [title]="searchType ? 'Registration' : 'Description'"
      [type]="buttonTypes.Primary"
      [active]="false"
      (click)="toggleSearchType()"
    >
    </app-basic-button>
    <div class="input">
      <label for="search">
        <svg-icon src="/assets/img/icons/search.svg" class="icon"></svg-icon>
      </label>
      <input
        type="text"
        placeholder="Filter..."
        (ngModelChange)="searchString = $event; filterAlerts(searchString)"
        [ngModel]="searchString"
        (keydown.enter)="filterAlerts(searchString)"
      />
    </div>
  </div>
</div>

<app-table
  [zeroDataMessage]="'No Alerts Found'"
  (moreDataRequested)="loadMoreTableData()"
  [showViewMore]="showViewMore"
  [rawData]="shownAlerts"
  [dataMap]="vehicleAlertsTableMap"
  [columns]="vehicleAlertsTableColumns"
  [rowsSelectable]="false"
>
</app-table>
