<app-collapsible-order-table
  *ngIf="failedRoutes | async as failedRoutes"
  [tripType]="'Failed Routes'"
  [trips]="failedRoutes"
  [tripsTotal]="failedRoutesTotal"
  [objectType]="CollapsibleTableTypeEnum.LastMile"
  [expanded]="false"
  [icon]="Icons.Types.Trip"
  [color]="Icons.Colors.Blue"
  [tableDataMap]="fsActiveLastMileTableMap"
  [tableColumns]="fsActiveLastMileTableColumns"
  [primaryActions]="postDispatchTablePrimaryActions"
  [defaultDisplayAmount]="failedRoutesAmount"
  [isAdmin]="true"
  (paginateEvent)="handleFailedLastMiles($event)"
  >
</app-collapsible-order-table>
