import { Component, Input, OnInit } from '@angular/core';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { IconTypes } from '../icon/icon.interfaces';
import { DatePickerAppearanceEnum } from './material-date-picker.interface';

@Component({
  selector: 'material-date-picker',
  templateUrl: './material-date-picker.component.html',
  styleUrls: ['./material-date-picker.component.scss'],
})
export class MaterialDatePickerComponent implements OnInit {
  @Input() appearance: DatePickerAppearanceEnum = DatePickerAppearanceEnum.Standard;
  @Input() label: string = 'Enter a date range';
  @Input() color: UiColors = UiColors.Grey3;
  @Input() icon: IconTypes = IconTypes.Calendar;
  @Input() minDate: Date = new Date(2018, 1, 1);
  @Input() maxDate: Date = new Date(new Date().getFullYear() + 2, 1, 1);
  constructor() {}

  ngOnInit(): void {}
}
