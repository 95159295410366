export function styleFunc(feature) {
  const symbol1 = feature.getProperty('marker-symbol');
  const symbol2 = feature.getProperty('marker_symbol');
  const colour1 = feature.getProperty('marker_color');
  const colour2 = feature.getProperty('marker-color');

  const symbol = symbol1 ?? symbol2
  const colour = colour1 ?? colour2

  return {
    color: '#28b7ff',
    clickable: true,
    fillColor: feature.getProperty('fill') ?? 'green',
    icon: pinSymbol(symbol,colour),
    strokeColor: '#4285f4',
    strokeWeight: 1,
    label: getLabel(symbol) ?? null,
  }
}

function getLabel(symbol: string) {
  if (symbol === 'warehouse') {
    return;
  } else if (symbol) {
    return {
      text: symbol,
      color: 'white',
      fontFamily: 'Roboto',
      fontWeight: '700',
    };
  }
}

function pinSymbol(symbol: string, colour: string) {
  if (symbol === 'warehouse') {
    return {
      url: '/assets/img/map-icon.png',
      size: new google.maps.Size(50, 50),
    };
  } else {
    return {
      path:
            'M11.9,5.9C11.9,2.7,9.2,0,5.9,0S0,2.7,0,5.9c0,0.2,0,0.4,0,0.6C0.4,10,3.9,16.9,5.9,16.9 c1.8,0,5.5-6.8,5.9-10.3C11.9,6.4,11.9,6.2,11.9,5.9z',
      fillColor: colour ? colour : '#27B7FC',
      color: '#fff',
      fillOpacity: 1,
      strokeWeight: 0,
      scale: 2,
      fontColor: 'red',
      labelContent: symbol,
      labelOrigin: new google.maps.Point(6, 6),
      anchor: new google.maps.Point(0, 15),
    };
  }
}
