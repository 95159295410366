import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bucket-status-breakdown',
  templateUrl: './bucket-status-breakdown.component.html',
  styleUrls: ['./bucket-status-breakdown.component.scss'],
})
export class BucketStatusBreakdownComponent implements OnInit {
  data = [
    {
      name: 'Auto Geocoding',
      value: 12,
    },
    {
      name: 'Verifying',
      value: 21,
    },
    {
      name: 'Trip Selection',
      value: 6,
    },
    {
      name: 'Confrimation',
      value: 15,
    },
    {
      name: 'Dispatched',
      value: 62,
    },
  ];

  colorScheme = { domain: ['#F1453D', '#27B7FC', '#02DE73', '#27B7FC', '#02DE73', '#02DE73'] };

  constructor() {}

  getBucketBreakdown($event): void {
    alert($event);
  }

  ngOnInit(): void {}
}
