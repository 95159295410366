<app-section>
  <heading
    [icon]="Icons.Types.Trip"
    [color]="Icons.Colors.Green"
    [showToggle]="true"
    (click)="collapsibleTable.toggle()"
  >
    <h4 class="heading-title">
      <span> {{ selectedDate === todaysDate ? "Today's" : selectedDate }} Summary</span>
      <small>Parcels that are on an active route with a collection date of today or future</small>
    </h4>
    <app-basic-button
      [title]="'Override Date'"
      [type]="buttonTypes.Secondary"
      (click)="changeDate($event)"
    >
  </app-basic-button>
  </heading>
  <div appCollapsible [expanded]="false" #collapsibleTable="appCollapsible">
    <app-table
      [primaryActions]="lastMileProgressPrimaryActions"
      (actionTaken)="handleLastMileProgressPrimaryActions($event)"
      [columns]="lastMileProgressTableColumns"
      [dataMap]="lastMileProgressTableMap"
      [rawData]="todaySummaryData"
      [zeroDataMessage]="'No Trips for Today'"
      [rowsSelectable]="false"
      [showTableAverages]="false "
      [showTableTotals]="true"
    >
    </app-table>
  </div>
</app-section>
