import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-trips-history',
  templateUrl: './new-trips-history.component.html',
  styleUrls: ['./new-trips-history.component.scss'],
})
export class NewTripsHistoryComponent implements OnInit {
  @Input() routes;
  @Input() polyline;

  constructor() {}

  ngOnInit(): void {
  }
}
