import { Component, OnInit, HostListener, Input } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss'],
})
export class ImageModalComponent extends SimpleModalComponent<any, any> implements OnInit {
  open = true;
  image_url;
  constructor() {
    super();
  }

  ngOnInit(): void {}

  @HostListener('document:click', ['$event'])
  closeModal($event) {
    if (this.open) {
      if ($event.target.classList.contains('open')) {
        this.open = false;
      }
    }
  }
}
