import { Component, OnInit, HostListener, Input, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import axios from 'app/api/axios';
import { Store } from '@ngrx/store';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { selectorActingAs } from 'app/auth/auth.reducer';
import { ActingAs } from 'app/interfaces/auth.interfaces';
import { ButtonTypes } from '../buttons/basic-button.component';
import { GoogleMap } from '@angular/google-maps';

export interface GeocoderConfig {
  addresses: any;
  bucket_id: number | string;
  order_id?: string | number;
}

// Logic for geocoding:
// - next address depends on what was sent in config.
// - No bucket AND no address = geocode all
// - No bucketID = geocode Bucket
// - No addressID = geocode single address.

@Component({
  selector: 'app-geocoding-tool',
  templateUrl: './geocoding-tool.component.html',
  styleUrls: ['./geocoding-tool.component.scss'],
})
export class GeocodingToolComponent
  extends SimpleModalComponent<GeocoderConfig, any>
  implements GeocoderConfig, OnInit {

  @Input() geocodeMode: any;
  @Input() businessRef: any;

  buttonTypes = ButtonTypes;
  address: string;
  addresses;
  bucket_id;
  order_id: number;
  geocodedMarker: google.maps.Marker = undefined;
  clientMarker: google.maps.Marker = undefined;
  markerUrl = 'https://maps.google.com/mapfiles/ms/icons/green-dot.png';
  mapZoom = 6;
  autocompletePref = environment.autocompletePref;
  original_address: any;
  statuses: any = [];
  new_address;
  zone_response = {
    suburb: {
      id: null,
      name: null,
      geojson: undefined,
    },
    zones: [],
  };
  noGeocode = false;
  autocomplete;
  error;
  updated_coordinates;
  pin_updated = false;
  zone_required = false;
  vettingProcess = false;
  environment = environment;

  manual_entry_required = false;

  explicit_postalcode: string;
  explicit_suburb: string;
  actingAs: ActingAs;

  unit: string;
  building: string = '';

  gMapOptions: google.maps.MapOptions = {
    disableDefaultUI: true,
    zoomControl: false,
    clickableIcons: false,
    mapTypeControl: false,
    maxZoom: 18,
  };

  geocodedMarkerOptions: google.maps.MarkerOptions = {
    draggable: true,
    position: null
  };

  clientMarkerOptions: google.maps.MarkerOptions = {
    draggable: true,
    position: null,
    icon: this.markerUrl,
  };

  infoWindow: google.maps.InfoWindow = new google.maps.InfoWindow();

  @ViewChild('map', { static: false }) gMap: GoogleMap;
  @ViewChild('markerRed', { static: false }) markerRed: any;
  @ViewChild('markerGreen', { static: false }) markerGreen: any;


  constructor(public store: Store<any>) {
    super();
    this.store.select(selectorActingAs).subscribe((next) => (this.actingAs = next));
  }

  ngOnInit(): void {
    this.nextAddress();
  }

  cancel(): void {
    this.result = undefined;
    this.releaseClaim();
    this.close();
  }

  geocodeAll(): void {
    axios
      .get(`/geocoder/${this.actingAs.id}/next-address-to-geocode`)
      .then((response) => this.loadAddress(response?.data))
      .catch((error) => (this.error = error.response?.data.message));
  }

  geocodeBucket(): void {
    axios
      .get(`/bucket/${this.bucket_id}/next-address-to-geocode`)
      .then((response) => this.loadAddress(response?.data))
      .catch((error) => (this.error = error.response?.data.message));
  }

  geocodeSingleAddress(address_id): void {
    axios
      .post(`/geocoder/${this.actingAs.id}/claim-address/`, { address_id, order_id: this.order_id })
      .then((response) => this.loadAddress(response?.data))
      .catch((error) => (this.error = error.response?.data.message));
  }

  nextAddress(): void {
    this.autocomplete = null;
    if (this.addresses === null && this.bucket_id === null) {
      this.geocodeAll();
    } else if (this.bucket_id !== null) {
      this.geocodeBucket();
    } else if (this.addresses !== null) {
      if (this.addresses.length > 0) {
        const addressId = this.addresses[0];
        this.addresses.splice(0, 1);
        this.geocodeSingleAddress(addressId);
      } else {
        this.processComplete();
      }
    }
    this.clearMarker();
  }

  clearData() {
    this.explicit_postalcode = null;
    this.explicit_suburb = null;
    this.manual_entry_required = false;
  }

  clearMarker(): void{
    this.geocodedMarker?.setMap(null);
    this.clientMarker?.setMap(null);
  }

  loadAddress(data) {
    this.clearData();
    this.clearZones();
    this.pin_updated = false;
    this.updated_coordinates = undefined;
    this.original_address = undefined;
    this.vettingProcess = false;
    if (data.address !== null) {
      if (data.is_zone_required === true) {
        this.handleZones();
      }
      this.unit = data.address.unit_or_floor;
      this.building = data.address.building_or_complex_name;
      this.noGeocode = false;
      this.statuses = data.statuses;
      this.original_address = data;
      this.new_address = undefined;
      this.autocomplete = '';
      if (data.address.geocoded_latitude && data.address.geocoded_longitude) {
        this.geocodedMarkerOptions.position = {lat: data.address.geocoded_latitude, lng: data.address.geocoded_longitude}
        this.gMapOptions.center = {lat: data.address.geocoded_latitude, lng: data.address.geocoded_longitude};
        this.mapZoom = 15;
        const coords = {
          lat: data.address.geocoded_latitude,
          lng: data.address.geocoded_longitude,
        };
        this.getZonesFromGeometry(coords);
      } else {
        this.mapZoom = 6;
        this.geocodedMarkerOptions.position = undefined;
        this.gMapOptions.center = {lat: -30.559482, lng: 22.937505999999985};
        this.noGeocode = true;
      }
      if (this.original_address.address.geocoded_suburb && this.original_address.address.geocoded_postalcode) {
        this.explicit_suburb = this.original_address.address.geocoded_suburb;
        this.explicit_postalcode = this.original_address.address.geocoded_postalcode;
      } else {
        if (!this.noGeocode) {
          this.checkSuburbPostalCode();
        }
      }

      if (data.address.vetted_latitude && data.address.vetted_longitude) {
        this.clientMarkerOptions.position = {lat: data.address.vetted_latitude, lng: data.address.vetted_longitude};
      } else if (data.address.client_latitude && data.address.client_longitude) {
        this.clientMarkerOptions.position = {lat: data.address.client_latitude, lng: data.address.client_longitude};
      } else {
        this.clientMarkerOptions.position = this.geocodedMarkerOptions.position;
      }
    } else {
      this.processComplete();
    }
    this.refreshMapandMarkers();
  }

  getAutoCompleteAddress(address) {
    this.clearData();

    this.gMapOptions.center = {lat: address.geometry.location.lat(), lng: address.geometry.location.lng()};
    this.geocodedMarkerOptions.position = {lat: address.geometry.location.lat(), lng: address.geometry.location.lng()};
    this.clientMarkerOptions.position = {lat: address.geometry.location.lat(), lng: address.geometry.location.lng()};

    this.updated_coordinates = { lat: address.geometry.location.lat(), lng: address.geometry.location.lng() };
    this.mapZoom = 15;
    this.new_address = address;
    address.address_components.forEach((component) => {
      if (component.types.includes('postal_code')) {
        // postalcode
        this.explicit_postalcode = component.short_name;
      }
      if (component.types.includes('sublocality')) {
        // suburb
        this.explicit_suburb = component.short_name;
      }
    });
    if (address.types.includes('point_of_interest' || 'establishment')) {
      this.building = address.name;
    }
    this.checkSuburbPostalCode();
    const coords = {
      lat: address.geometry.location.lat(),
      lng: address.geometry.location.lng(),
    };
    if (this.zone_required) {
      this.getZonesFromGeometry(coords);
    }

    this.refreshMapandMarkers();
  }

  processComplete() {
    this.result = { successful: true };
    this.close();
  }

  throwError(error) {
    this.result = { successful: false, message: error.response?.data.message };
    this.close();
  }

  clearAutoComplete() {
    this.clearData();
    this.autocomplete = '';
    this.new_address = undefined;
    if (this.original_address.address) {
      this.gMapOptions.center = {lat: this.original_address.address.geocoded_latitude, lng: this.original_address.address.geocoded_longitude};
      this.geocodedMarkerOptions.position = {
        lat: this.original_address.address.geocoded_latitude,
        lng: this.original_address.address.geocoded_longitude,
      };
    } else {
      this.mapZoom = 6;
      this.geocodedMarkerOptions.position = undefined;
      this.gMapOptions.center = {lat: -30.559482, lng: 22.937505999999985};
      this.noGeocode = true;
    }
  }

  saveNewGeocode() {
    this.error = undefined;
    if (this.manual_entry_required && (!this.explicit_suburb || !this.explicit_postalcode)) {
      this.error = 'Please add a Suburb and Postal Code';
      return;
    }
    const addressData = {
      address_id: this.original_address.address.id,
      google_result: this.new_address,
      coord_result: this.updated_coordinates,
      geocoded_suburb: this.explicit_suburb,
      geocoded_postalcode: this.explicit_postalcode,
      zone: {
        suburb_id: this.zone_response.suburb.id,
        zone_id: this.zone_response.zones[0] ? this.zone_response.zones[0].id : null,
      },
      building_or_complex_name: this.building,
      unit_or_floor: this.unit,
    };

    axios
      .post(`/geocoder/${this.actingAs.id}/save-geocoded-address`, addressData)
      .then(() =>
        this.nextAddress())
      .catch((error) => this.throwError(error));
  }

  skipAddress(): void {
    this.error = undefined;
    axios
      .get(`/geocoder/${this.actingAs.id}/skip-address/${this.original_address.address.id}`)
      .then(() => {
        this.nextAddress();
      })
      .catch((error) => {
        this.throwError(error);
      });
  }

  vetAddress() {
    this.vettingProcess = true;
    this.error = undefined;
    axios
      .post(`/geocoder/${this.actingAs.id}/vet-geocoded-address/`, {
        address_id: this.original_address.address.id,
      })
      .then(() => {
        this.nextAddress();
      })
      .catch((error) => {
        this.throwError(error);
        this.vettingProcess = false;
      });
  }

  flagAddress() {
    this.error = undefined;
    this.noGeocode = false;
    axios
      .get(`/geocoder/${this.actingAs.id}/flag-address/${this.original_address.address.id}`)
      .then(() => this.nextAddress())
      .catch((error) => this.throwError(error));
  }

  onMouseOver(marker: google.maps.Marker) {
    if (this.updated_coordinates !== this.geocodedMarkerOptions.position) {
      this.infoWindow.setContent(
        `
        <ng-template style="color: balck">
          <h4>Suggested location</h4>
            <h6>Click to use</h6>
        </ng-template>`);
      this.infoWindow.open(this.gMap.googleMap, marker);
    }
  }

  onGreenMouseOver(marker) {
    if (!this.geocodedMarkerOptions.position) {
      this.infoWindow.setContent(
        `
        <ng-template style="color: balck">
          <h4>Provided Coordinates</h4>
            <h6>Click to use</h6>
        </ng-template>`);
      this.infoWindow.open(this.gMap.googleMap, marker);
    }
  }

  layerClick($event) {
    this.selectZone($event.feature.getProperty('name'));
  }

  addDriverLocationPin() {
    this.clientMarkerOptions.position = this.geocodedMarkerOptions.position;
  }

  onMouseOut() {

    if (this.infoWindow) {
      this.infoWindow.close();
    }
  }

  onGreenMouseOut() {
    this.infoWindow.close();
  }

  onDragEndGreen(event: google.maps.MapMouseEvent): void {
    this.pin_updated = true;
    this.updated_coordinates = {lat: event.latLng.lat(), lng: event.latLng.lng()}
  }

  onGreenClick($event: google.maps.MapMouseEvent): void {
    if (!this.geocodedMarkerOptions.position) {
      const coords = {
        lat: $event.latLng.lat(),
        lng: $event.latLng.lng(),
      };
      this.pin_updated = true;
      this.autocomplete = '';
      this.new_address = undefined;
      this.reverseGeocodePin(coords);
    }
  }

  onDragEndRed(marker: google.maps.Marker) {
    const coord = marker.getPosition();
    this.pin_updated = true;
    this.autocomplete = '';
    this.new_address = undefined;
    this.reverseGeocodePin(coord);
  }

  selectZone(zone_name) {
    let zone_index;
    const swapPositions = (array, a, b) => {
      [array[a], array[b]] = [array[b], array[a]];
    };
    this.zone_response.zones.forEach(function (zone, index) {
      if (zone_name === zone.name) {
        zone_index = index;
      }
    });
    swapPositions(this.zone_response.zones, 0, zone_index);
  }

  onMouseClick(event:  google.maps.MapMouseEvent) {
    this.pin_updated = true;
    this.updated_coordinates = { lat: event.latLng.lat(), lng: event.latLng.lng() };
    this.clientMarkerOptions.position = this.geocodedMarkerOptions.position;
  }

  reverseGeocodePin(coords) {
    this.clearData();
    axios
      .post('/geocoder/reverse-geocode/', coords)
      .then((response) => {
        const result = response?.data.results[0];
        this.new_address = result;
        this.geocodedMarkerOptions.position = {lat: result.geometry.location.lat, lng: result.geometry.location.lng};
        result.address_components.forEach((component) => {
          if (component.types.includes('postal_code')) {
            // postalcode
            this.explicit_postalcode = component.short_name;
          }
          if (component.types.includes('sublocality')) {
            // suburb
            this.explicit_suburb = component.short_name;
          }
        });
        this.getZonesFromGeometry(coords);

        this.checkSuburbPostalCode();
      })
      .catch((error) => (this.error = error));
  }

  handleZones() {
    this.zone_required = true;
  }

  getZonesFromGeometry(coords) {
    axios
      .post(`/geocoder/${this.actingAs.id}/get-zones`, coords)
      .then((response) => {
        this.zone_response.suburb = response?.data.suburb;
        this.zone_response.zones = response?.data.zones;
      })
      .catch((error) => this.throwError(error));
  }

  clearZones() {
    this.zone_response = {
      suburb: {
        id: null,
        name: null,
        geojson: undefined,
      },
      zones: [],
    };
  }

  checkSuburbPostalCode() {
    if (environment.envName === 'Africa') {
      this.explicit_postalcode = '0000';
    }
    if (!this.explicit_postalcode || !this.explicit_suburb) {
      this.manual_entry_required = true;
    }
  }

  releaseClaim() {
    axios.get(`/geocoder/${this.actingAs.id}/release-claim`);
  }

  zoneStyle() {
    return {
      fillOpacity: 0.4,
      strokeOpacity: 1,
      fillColor: '#ff7100',
      strokeColor: '#ff7100',
    };
  }

  suburbStyle() {
    return {
      fillOpacity: 0.6,
      strokeOpacity: 1,
      fillColor: '#02DE73',
      strokeColor: '#02DE73',
    };
  }

  multiStyle() {
    return {
      fillOpacity: 0.4,
      strokeOpacity: 1,
      fillColor: '#000000',
      strokeColor: '#000000',
    };
  }

  @HostListener('window:unload', ['$event'])
  unloadHandler() {
    this.releaseClaim();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler() {
    this.releaseClaim();
  }

  refreshMapandMarkers(): void{
    this.clearMarker();
    const cMarker = new google.maps.Marker(this.clientMarkerOptions);
    const gMarker = new google.maps.Marker(this.geocodedMarkerOptions);

    this.gMap.googleMap.setOptions(this.gMapOptions);

    this.geocodedMarker = gMarker;
    this.clientMarker = cMarker;
    this.createRedMarkerEvents(gMarker);
    this.createGreenMarkerEvents(cMarker);
    this.clientMarker.setMap(this.gMap.googleMap);
    this.geocodedMarker.setMap(this.gMap.googleMap);
  }

  createRedMarkerEvents(marker: google.maps.Marker): void {
    marker.addListener('click', e => {
      this.onMouseClick(e);
    });
    marker.addListener('dragend', () => {
      this.onDragEndRed(marker);
    });
    marker.addListener('mouseover', () => {
      this.onMouseOver(marker);
    });
    marker.addListener('mouseout', () => {
      this.onMouseOut();
    });
  }

  createGreenMarkerEvents(marker: google.maps.Marker): void {
    marker.addListener('click', e => {
      this.onGreenClick(e);
    });
    marker.addListener('dragend', e => {
      this.onDragEndGreen(e);
    });
    marker.addListener('mouseover', () => {
      this.onGreenMouseOver(marker);
    });
    marker.addListener('mouseout', () => {
      this.onGreenMouseOut();
    });
  }
}
