import { Injectable } from '@angular/core';
import axios from 'app/api/axios';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PaystackService {
  constructor() {
    axios.defaults.headers.common['x-api-key'] = environment.billingModule.apiKey;
  }

  deleteSavedCardPaystackCard(authCode) {
    return axios({
      method: 'POST',
      baseURL: environment.billingModule.baseUrl + '/generic/module/front-end/mod_delete_paystack_card',
      data: {
        authorization_code: authCode,
      },
    }).then((response) => {
      return response.data;
    });
  }

  getSavedPaystackCards(userId, businessId) {
    return axios({
      method: 'POST',
      baseURL: environment.billingModule.baseUrl + '/generic/module/front-end/mod_get_paystack_cards',
      data: {
        business_external_key: businessId,
        user_external_key: userId,
      },
    }).then((response) => {
      return response.data;
    });
  }

  saveCard(transactionId, userId, businessId) {
    return axios({
      method: 'POST',
      baseURL: environment.billingModule.baseUrl + '/generic/module/front-end/mod_save_paystack_card',
      data: {
        business_external_key: businessId,
        user_external_key: userId,
        transaction_id: transactionId,
      },
    }).then((response) => {
      return response.data;
    });
  }

  payWithPaystackSavedCard(authCode, amount, email) {
    return axios({
      method: 'POST',
      baseURL: environment.billingModule.baseUrl + '/generic/paystack/front-end/payment',
      data: {
        authorization_code: authCode,
        email: email,
        amount: amount,
      },
    }).then((response) => {
      // Extra data level due to billing system echoing out the paystack response
      return response.data.data;
    });
  }
}
