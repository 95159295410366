import { AfterViewInit, Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { Store } from '@ngrx/store';
import { authState, selectorActingAs } from 'app/auth/auth.reducer';
import { ActingAs } from 'app/interfaces/auth.interfaces';
import { environment } from 'environments/environment';
import { AutocompletePreferences } from '../places-autocomplete/places-autocomplete.interface';

@Component({
  selector: 'app-material-places-autocomplete',
  templateUrl: './material-places-autocomplete.component.html',
  styleUrls: ['./material-places-autocomplete.component.scss']
})
export class MaterialPlacesAutocompleteComponent implements OnInit, AfterViewInit {
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() label: string = 'Address';
  @Input() value: string = '';
  @Input() placeholder: string = 'Start typing an address';
  @Input() form: FormGroup;
  @Input() controlName: string;
  @ViewChild('googlePlaces') googlePlaces: ElementRef;
  preferences: AutocompletePreferences = {
    fields: environment.autocompletePref.fields,
    componentRestrictions: { country: ['ZA'] },
    types: environment.autocompletePref.types,
  };
  actingAs: ActingAs;
  constructor(private zone: NgZone, private store: Store<authState>) {}

  ngOnInit(): void {
    this.store.select(selectorActingAs).subscribe((next: ActingAs) => {
      this.actingAs = next;
      if (this.actingAs?.localisation?.supported_geocode_country_codes?.length > 0) {
        this.preferences.componentRestrictions.country = this.actingAs.localisation.supported_geocode_country_codes;
      }
    });
  }

  ngAfterViewInit(): void {
    this.getPlaceAutocomplete();
  }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.googlePlaces.nativeElement, this.preferences);
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place: google.maps.places.PlaceResult = autocomplete.getPlace();
      this.invokeEvent(place);
    });
  }

  invokeEvent(place: google.maps.places.PlaceResult): void {
    this.zone.run(() => {
      this.form.controls[this.controlName].setValue(place.formatted_address);
    });
  }

}
