import { Component, OnInit } from '@angular/core';
import { ModalSizes, ModalAction } from 'app/shared/modals/modal.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { BucketService } from 'app/dashboard/buckets/bucket.service';
import * as fromAuth from 'app/auth/auth.reducer';
import { Store } from '@ngrx/store';
import { TableAction, TableActionTaken, TableColumn, TableDataMap } from 'app/shared/table/table.interfaces';
import { Warehouse } from 'app/interfaces/auth.interfaces';
import { AvailableBucketsResultEnum } from 'app/shared/shared.interfaces';

@Component({
  selector: 'app-available-buckets',
  templateUrl: './available-buckets.component.html',
  styleUrls: ['./available-buckets.component.scss'],
})
export class AvailableBucketsComponent extends SimpleModalComponent<any, any> implements OnInit {
  availableBuckets;
  business_id;
  showTable = false;
  public ModalSizes = ModalSizes;
  public IconTypes = IconTypes;
  public uiColors = UiColors;
  public UiThemes = UiThemes;
  public buttonTypes = ButtonTypes;

  user_permissions;
  availableWarehouses = [];
  shownBuckets = [];

  public actions: ModalAction[] = [{ name: 'close', title: 'Close', isPrimary: false }];

  availableBucketsTableColumns: TableColumn[] = [
    { name: 'bucket_id', displayName: 'Bucket ID ', sortable: true },
    { name: 'warehouse_name', displayName: 'Warehouse', sortable: true },
    { name: 'delivery_date', displayName: 'Delivery Date', sortable: true },
    { name: 'shift_start', displayName: 'Shift Start', sortable: true },
    { name: 'shift_end', displayName: 'Shift End', sortable: true },
  ];

  availableBucketsTableDataMap: TableDataMap = {
    cells: {
      bucket_id: { column: 'bucket_id', map: 'bucket_id' },
      warehouse_name: {
        column: 'warehouse_name',
        map: 'warehouse_name',
      },
      delivery_date: {
        column: 'delivery_date',
        map: 'delivery_date',
        displayPipeArgs: ['date', 'mediumDate'],
      },
      shift_start: {
        column: 'shift_start',
        map: 'shift_start',
      },
      shift_end: {
        column: 'shift_end',
        map: 'shift_end',
      },
    },
  };
  availableBucketsPrimaryActions: TableAction[] = [
    { event: 'selectBucket', title: 'Select', icon: IconTypes.Check, icon_color: UiColors.Grey8 },
  ];

  warehouses: Warehouse[];
  selected_warehouse: Warehouse;

  constructor(public bucketService: BucketService, private store: Store<fromAuth.authState>) {
    super();
    this.store.select(fromAuth.selectorUserPermissions).subscribe((next) => (this.user_permissions = next));
    this.store.select(fromAuth.selectorWarehouses).subscribe((next) => (this.warehouses = next));
  }

  findAvailableBuckets() {
    this.bucketService.getAssignableBucketsForBusiness(this.business_id).then((response) => {
      if (response) {
        if (this.user_permissions?.warehouses?.length && !this.user_permissions?.modules?.includes('super-admin')) {
          this.availableBuckets = [];
          response.forEach((bucket) => {
            if (this.user_permissions.warehouses.includes(bucket.warehouse_id)) {
              this.availableBuckets.push(bucket);
            }
          });
        } else {
          this.availableBuckets = response;
        }

        if (response.length === 0 || this.availableBuckets.length === 0) {
          this.result = AvailableBucketsResultEnum.Create;
          this.close();
        }
      }
    });
  }

  selectWarehouse(warehouse: Warehouse) {
    if (!warehouse) {
      this.shownBuckets = this.availableBuckets;
    } else {
      this.selected_warehouse = warehouse;
      this.shownBuckets = this.availableBuckets?.filter((bucket) => bucket.warehouse_id === warehouse.id);
    }
    this.showTable = true;
  }

  constructWarehouseList() {
    if (this.user_permissions?.warehouses?.length && !this.user_permissions?.modules?.includes('super-admin')) {
      this.availableWarehouses = [];
      this.warehouses.forEach((warehouse) => {
        if (this.user_permissions.warehouses.includes(warehouse.id)) {
          this.availableWarehouses.push(warehouse);
        }
      });
    } else {
      this.availableWarehouses = this.warehouses;
    }
  }

  selectBucket($event: TableActionTaken): void {
    const bucket_id = $event.rows[0].cells.find((cell) => cell.column === 'bucket_id').value;
    const bucket_details = this.availableBuckets.find((bucket) => bucket.bucket_id === bucket_id);
    this.result = {
      bucket_id: bucket_id,
      warehouse_id: bucket_details.warehouse_id,
    };
    this.close();
  }

  handleAction($event) {
    switch ($event) {
      case 'close':
        this.result = AvailableBucketsResultEnum.None;
        this.close();
        break;
    }
  }

  ngOnInit(): void {
    this.constructWarehouseList();
    this.findAvailableBuckets();
  }

  onCreateBucket() {
    this.result = AvailableBucketsResultEnum.Create;
    this.close();
  }
}
