import {
  BusinessDrivers,
  Branding,
  BusinessUsers,
  BusinessVehicles,
  CourierIntegration,
} from './businesses.interfaces';

export interface AuthUser {
  email: string | null;
  is_admin: boolean;
  name: string | null;
  phone: string | null;
  preferred_business_id: string | null;
  user_id: string;
}

export interface FSGlobalConfig {
  maintenance_mode: MaintenanceMode;
  reporting_days_limit: number;
  courier_integration_order_recreate_config: CourierIntegrationOrderRecreateConfig;
  users_allow_lottery_changes: string[]
  last_mile_collection_name: string;
}

export interface MaintenanceMode {
  is_active: boolean;
  title: string;
  subtitle: string;
}

export interface StoreAction {
  type: string;
  payload: any;
}

export interface ActingAs {
  id: string;
  package: string;
  payment_methods: string[];
  modules: Module[];
  payment_terms: string;
  name: string;
  phone: string | null;
  email: string | null;
  is_enterprise: boolean;
  default_bucket_shifts: string;
  credit_cards: any;
  drivers: BusinessDrivers[];
  users: BusinessUsers[];
  branding: Branding;
  bucket_preferences: BucketPreferences;
  vehicles: BusinessVehicles[];
  vehicle_registration_numbers: string[];
  couriers: CourierIntegration[];
  webhook_url: string;
  receive_freight_offers: boolean;
  isAuthenticated: boolean;
  roles: UserRoles[];
  localisation: Localisation;
}

export enum PaymentTerms {
  Prepaid = 'prepaid',
  Postpaid = 'postpaid',
}

interface Localisation {
  country: string;
  country_code: string;
  vat_rate: number;
  supported_geocode_country_codes: string[];
}

export interface Module {
  id: string;
  components: Component[];
}

export interface Component {
  id: string;
  permissions;
}

export interface BucketPreferences {
  process_bucket_options;
  fleet;
  is_pre_assign_tracking_number: boolean;
  is_validate: boolean;
  is_odometer_reading_required: boolean;
}

export interface Warehouse {
  id: string;
  warehouse_id: string;
  name: string;
  warehouse_code: string;
  address: string;
  email: string;
  phone: string;
  latitude: number;
  longitude: number;
  suburb: string;
  postal_code: string;
  country: string;
  country_short_code: string;
  contact: WarehouseContact;
  allow_business_consolidation: boolean;
  is_eligible_for_da_consolidation: boolean;
  default_special_instructions: string;
  operating_hours?: WarehouseTimesOut[];
  location?: latlng
}

export interface WarehouseTimesOut {
  day_of_week: string,
  opening_time: string,
  closing_time: string
}

export interface latlng{
  latitude: string;
  longitude: string;
}

export interface WarehouseContact {
  ClientAddress: string;
  Email: string;
  Instructions: string;
  Name: string;
  PhoneNumber: string;
}

export interface UserAccess {
  modules: [string];
  warehouses: [string];
}

export interface UserPermissions {
  modules: string[];
  warehouses: string[];
}

export enum UserRoles {
  User = 'User',
  Administrator = 'Administrator',
  SuperAdministrator = 'SuperAdministrator',
}

export interface CourierIntegrationOrderRecreateConfig {
  [courier_id: string]: {
    enabled_business_uids: string[];
    service_type_uid: string;
  };
}

export interface UberEnabledWarehousesConfig {
  warehouse_ids: string[];
}

