import { HighlightHealthStates } from 'app/dashboard/trips.enums';

export interface PlanningOrder {
  BusinessReference: string;
  OrderWaybill: string;
  ClientName: string;
  CollectBy: string;
  DeliveryBy: string;
  CutoffAt: string;
  OrderState: OrderStateEnum;
  Metadata1: string;
  Metadata2: string;
  Metadata3: string;
  OriginAddress: string;
  DestinationAddress: string;
  OriginHubId: number;
  OriginAddressType: AddressTypeEnum;
  DestinationAddressType: AddressTypeEnum;
  V2OrderWaybill: string;
}

export enum AddressTypeEnum {
  Pharmacy = 'Pharmacy',
  Waypoint = 'Waypoint',
}

export enum ShipmentPhaseEnum {
  All = 'All',
  New = 'New',
  Pending = 'Pending',
  Ready = 'Ready',
  Processing = 'Processing',
  PendingDispatch = 'PendingDispatch',
  Dispatching = 'Dispatching',
  Dispatched = 'Dispatched',
  Finalized = 'Finalized',
  Alert = 'Alert',
  OnHold = 'OnHold',
}

export enum ShipmentStateEnum {
  New = 'New',
  FulfillmentConfirmed = ' Fulfillment Confirmed',
  DriverAssigned = 'Driver Assigned',
  OutForCollection = 'Out For Collection',
  AtCollectionWaypoint = 'At Collection Waypoint ',
  CourierCollected = 'CourierCollected',
  CourierCollectionFailed = 'Courier Collection Failed',
  AtCourierCenter = 'At Courier Center',
  ParcelVolumised = 'Parcel Volumised',
  InTransit = 'In Transit',
  OutForDelivery = 'Out For Delivery',
  EnRouteToWaypoint = 'En Route To Waypoint',
  AtDeliveryWaypoint = 'At Delivery Waypoint',
  DeliverySuccessful = 'Delivery Successful',
  DeliveryFailed = 'Delivery Failed',
  Cancelled = 'Cancelled',
  Returned = 'Returned',
  Error = 'Error',
  GetVisitsFailed = 'GetVisitsFailed',
  PreAllocationCodeSet = 'PreAllocationCodeSet',
  DispatchingTripFailed = 'DispatchingTripFailed',
}

export enum ParcelState {
  New = 'New',
  Collected = 'Collected',
  FailedCollection = 'FailedCollection',
  Delivered = 'Delivered',
  FailedDelivery = 'FailedDelivery',
  Returned = 'Returned',
  Cancelled = 'Cancelled',
  FailedReturn = 'FailedReturn',
  PendingRecollection = 'PendingRecollection',
  PendingRedelivery = 'PendingRedelivery',
  PendingReturn = 'PendingReturn',
  FinalCollectionAttemptFailed = 'FinalCollectionAttemptFailed',
}

export enum ShipmentType {
  FistMile = 'FirstMile',
  MiddleMile = 'MiddleMile',
  LastMile = 'LastMile',
}

export const PlanningShipmentStateHighlightMapping = new Map<string, HighlightHealthStates>([
  [ShipmentStateEnum.GetVisitsFailed, HighlightHealthStates.Warning],
  [ShipmentStateEnum.PreAllocationCodeSet, HighlightHealthStates.Default],
  [ShipmentStateEnum.DispatchingTripFailed, HighlightHealthStates.Bad],
]);

export const PlanningShipmentPhaseHighlightMapping = new Map<string, HighlightHealthStates>([
  [ShipmentPhaseEnum.Pending, HighlightHealthStates.Default],
  [ShipmentPhaseEnum.Ready, HighlightHealthStates.Good],
  [ShipmentPhaseEnum.Processing, HighlightHealthStates.Primary],
  [ShipmentPhaseEnum.Alert, HighlightHealthStates.Bad],
  [ShipmentPhaseEnum.OnHold, HighlightHealthStates.Caution],
  [ShipmentPhaseEnum.PendingDispatch, HighlightHealthStates.Default],
  [ShipmentPhaseEnum.Finalized, HighlightHealthStates.Primary],
  [ShipmentPhaseEnum.Dispatched, HighlightHealthStates.Good],
  [ShipmentPhaseEnum.Dispatching, HighlightHealthStates.Primary],
]);

export const PlanningShipmentAttemptsHighlightMapping = new Map<number, HighlightHealthStates>([
  [0, HighlightHealthStates.Good],
  [1, HighlightHealthStates.Good],
  [2, HighlightHealthStates.Warning],
  [3, HighlightHealthStates.Bad],
]);

export interface StateFilter {
  state: string | null;
  displayName: string;
}

export enum PlanningStateEnum {
  All = 'All',
  New = 'New',
  PendingAllocation = 'Pending Allocation',
  EnRoute = 'En Route',
}

export enum ThirdPartyActionsEnum {
  EditAllocation = 'EditAllocation',
  ConfirmAllocations = 'ConfirmAllocations',
}

export interface PlanningTab {
  route: string;
  name: string;
}

export enum PlanningViewOrderTabEnum {
  Details = 'Details',
  Shipments = 'Shipments',
  Timeline = 'Timeline',
}

export enum PlanningViewShipmentTabEnum {
  Details = 'Details',
  Timeline = 'Timeline',
}

export interface Tab {
  state?: string;
  displayName: string;
  url?: string;
}

export interface BaseResponse<T> {
  IsSuccess: boolean;
  Response: T;
  Errors: BaseError[];
}

export interface BaseError {
  Message: string;
}

export interface GetPagedResponse<T> {
  FilterOptions: any;
  Request: any;
  Rows: T;
  TotalRows: number;
}

export enum PreAllocationEnum {
  OND = 'OND',
  COU = 'COU',
}

export enum ShipmentTypeEnum {
  FirstMile = 'FirstMile',
  LastMile = 'LastMile',
}

export interface Shipment {
  Attempts: number;
  BusinessReference: string;
  ClientName: string;
  CollectBy: string;
  CustodianHubId: number;
  CutoffAt: string;
  DeliveryBy: string;
  FromAddress: string;
  FromAddressType: string;
  FromHubId: string;
  Leg: number;
  OrderWaybill: string;
  PreAllocation: PreAllocationEnum;
  ShipmentPhase: ShipmentPhaseEnum;
  ShipmentState: ShipmentStateEnum;
  ShipmentType: ShipmentTypeEnum;
  ShipmentWaybill: string;
  ToAddress: string;
  ToAddressType: string;
  ToHubId: number;
  V2OrderWaybill: string;
  BatchId?: string;
}

export interface AllocationCode {
  Code: string;
  Name?: string;
  Description?: string;
  ShouldRoute?: boolean;
  Consignment?: string;
  IsGlobal: boolean;
}

export interface ModifyShipmentPreAllocationRequest {
  PreAllocationCode: string;
  ShipmentWaybills: string[];
}

export interface FilterItem {
  Id: number;
  Name: string;
}

export interface RequestDetails {
  Pagination: RequestPagination;
  Filters: RequestFilters;
}

interface RequestPagination {
  PageSize: number;
  PageNumber: number;
}

export interface RequestFilters {
  BusinessIds?: number[];
  HubIds?: number[];
  CustodianIds?: number[];
  CustodianHubIds?: number[];
  ShipmentPhases?: string[];
  OrderStates?: string[];
  PreAllocationCodes?: string[];
}

export enum OrderStateEnum {
  All = 'All',
  New = 'New',
  ValidationFailed = 'ValidationFailed',
  Planned = 'Planned',
  InProgress = 'InProgress',
  Alert = 'Alert',
  Concluded = 'Concluded',
  StagedAssignment = 'StagedAssignment'
}

export interface PlanningTrip {
  TripId: number;
  Distance: number;
  ReturnDistance: number;
  Orders: number;
  Parcels: number;
  Volume: number;
  Weight: number;
  DriverEarnings: number;
  PriceExclVat: number;
  PriceInclVat: number;
}

export const PlanningOrderStateHighlightMapping = new Map<string, HighlightHealthStates>([
  [OrderStateEnum.New, HighlightHealthStates.Primary],
  [OrderStateEnum.Planned, HighlightHealthStates.Good],
  [OrderStateEnum.InProgress, HighlightHealthStates.Primary],
  [OrderStateEnum.Alert, HighlightHealthStates.Bad],
  [OrderStateEnum.Concluded, HighlightHealthStates.Primary],
  [OrderStateEnum.ValidationFailed, HighlightHealthStates.Bad],
]);

export interface ModifyPreAllocationsByTripsRequest {
  IsAcceptShipmentPreAllocations: boolean;
  PreAllocationCode: PreAllocationEnum;
  TripIds: number[];
}

export interface ModifyPreAllocationsByShipmentsRequest {
  IsAcceptShipmentPreAllocations: boolean;
  PreAllocationCode: PreAllocationEnum;
  ShipmentWaybills: string[];
}

export interface DispatchTripsResponse {
  IsSuccess: boolean;
  OrderId: string;
  SourceTripId: number;
}

export enum PlanningActionEnum {
  Dispatch = 'Dispatch',
  DispatchThirdParty = 'Dispatch Third Party',
  GetAllocationShipments = 'Get Allocation Shipments',
  GetOrder = 'Get Order',
  GetOrders = 'Get Orders',
  GetTrips = 'Get Trips',
  GetThirdPartyOrders = 'Get Third Party Orders',
  ModifyTripPreAllocation = 'Modify Trip Pre Allocations',
  ModifyShipmentPreAllocation = 'Modify Shipment Pre Allocations',
  ShipmentsByOrderWaybill = 'Get Shipments by Order Waybill',
  GetHubs = 'Get Hubs',
  GetBusinesses = 'Get Businesses',
  AcceptShipmentPreAllocations = 'Accept Shipment PreAllocations',
  GetShipmentHistory = 'Get Shipment History',
  GetOrderHistory = 'Get Order History',
  GetShipmentDetails = 'Get Shipment Details',
  SetBatchToReady = 'Set Batch To Ready',
  GetShipmentByBatch = 'Get Shipments By Batch ID',
  GetBusinessesByV2Id = 'Get Businesses By V2 ID',
  GetShipmentPhaseCount = 'Get Shipment Phase Count',
  GetShipmentGeoJSON = 'Get Shipment GEO Json',
  GetTripsGeoJson = 'Get Trips GEO Json',
  GetTripGeoJson = 'Get Trip GEO Json',
  GetTrip = 'Get Trip',
}

export interface AcceptShipmentPreAllocationResponse {
  BatchUid: string;
  TripResponse: TripResponse;
}

export interface TripResponse {
  InsertRoutificSolutionResponse: InsertRoutificSolutionResponse;
  CostingResponse: CostingResponse[];
}

export interface InsertRoutificSolutionResponse {
  JobId: string;
  TripCount: number;
  VisitCount: number;
  UnservedCount: number;
}

export interface CostingResponse {
  TripId: number;
  IsSuccess: boolean;
}

export interface ThirdPartyResponse {
  Failure: string;
}

export enum ShipmentActionsEnum {
  ConfirmAllocations = 'ConfirmAllocations',
  EditAllocation = 'EditAllocation',
  ViewShipment = 'ViewShipment',
  EditBulkAllocations = 'EditBulkAllocations',
}

export interface ShipmentHistory {
  ShipmentPhase: string;
  ShipmentState: string;
  ShipmentWaybill: string;
  Timestamp: Date;
}

export interface OrderHistory {
  OrderParcelWaybill: string;
  OrderWaybill: string;
  ShipmentParcelWaybill: string;
  ShipmentPhase: string;
  ShipmentState: string;
  ShipmentWaybill: string;
  Timestamp: Date;
}

export interface SetBatchToReadyRequest {
  BatchId: string;
}

export interface SetPhaseByBatchRequest {
  BatchUid: string;
  Phase: ShipmentPhaseEnum;
  State: string;
  IsPicup: boolean;
  IsThirdparty: boolean;
}

export interface GetHubsRequest {
  BusinessIds?: number[];
}

export interface PlanningBusinessMapping {
  BusinssId: number;
  Name: string;
  V2BusinssId: string;
}

export interface ShipmentPhaseCount {
  Count: number;
  ShipmentPhase: ShipmentPhaseEnum;
}

export interface PlanningOverviewStats {
  ShipmentsCompleted: number;
  ShipmentsDelivered: number;
  InTransitDispatch: number;
  FailedAttempts: number;
  ReturningShipments: number;
  CancelledShipments: number;
  Alerts: number;
}
