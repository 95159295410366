import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.checkIsAdmin().then((isAdmin: boolean) => {
      if (isAdmin) {
        return true;
      } else {
        this.router.navigate(['/dashboard']);
      }
    });
  }
}
