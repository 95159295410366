import { Component, OnInit } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { DatePickerModalComponent } from 'app/shared/modals/date-picker-modal.component';
import * as moment from 'moment';

import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { ParcelSizes } from 'app/interfaces/parcelSize.interfaces';
import { JustifyContent } from 'app/shared/flex-container/flex-container.interfaces';
import { UploadOrdersService } from '../upload-orders.service';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { selectorWarehouses } from 'app/auth/auth.reducer';
import { Warehouse } from 'app/interfaces/auth.interfaces';

@Component({
  selector: 'app-generate-random-bucket',
  templateUrl: './generate-random-bucket.component.html',
  styleUrls: ['./generate-random-bucket.component.scss'],
})
export class GenerateRandomBucketComponent implements OnInit {
  public IconTypes = IconTypes;
  public ButtonTypes = ButtonTypes;
  public UiThemes = UiThemes;
  public ParcelSizes = ParcelSizes;
  public justifyContent = JustifyContent;
  uiColours = UiColors;
  public processing;

  parcelSizeArray = Object.values(ParcelSizes);
  warehouses;
  formattedAddress: string = null;
  is_valid = false;
  randomBucketDetails = {
    latitude: null,
    longitude: null,
    number_of_orders: null,
    radius_in_meters: null,
    delivery_date: null,
    shift_start: null,
    shift_end: null,
    parcel_size: 'parcel-medium',
    warehouse_id: null,
    warehouse_name: null,
  };

  constructor(
    public simpleModalService: SimpleModalService,
    public uploadOrdersService: UploadOrdersService,
    private store: Store<any>,
    public router: Router
  ) {
    this.store.select(selectorWarehouses).subscribe((next: Warehouse[]) => {
      this.warehouses = next;
      this.randomBucketDetails.warehouse_id = next[0].id;
      this.randomBucketDetails.warehouse_name = next[0].name;
    });
  }

  openDatePickerModal() {
    this.simpleModalService.addModal(DatePickerModalComponent).subscribe((date: any) => {
      if (!date) {
        return;
      }
      this.randomBucketDetails.delivery_date = moment(date).format('YYYY-MM-DD');
    });
  }

  onPlacesChange($event) {
    this.formattedAddress = $event.formatted_address;
    this.randomBucketDetails.latitude = $event.geometry.location.lat();
    this.randomBucketDetails.longitude = $event.geometry.location.lng();
  }

  cancel() {
    this.router.navigate(['dashboard/buckets']);
  }

  createBucket() {
    this.uploadOrdersService.generateRandomBucket(this.randomBucketDetails);
  }

  checkValid() {
    if (
      this.randomBucketDetails.latitude &&
      this.randomBucketDetails.longitude &&
      this.randomBucketDetails.number_of_orders &&
      this.randomBucketDetails.radius_in_meters &&
      this.randomBucketDetails.delivery_date &&
      this.randomBucketDetails.shift_start &&
      this.randomBucketDetails.shift_end &&
      this.randomBucketDetails.parcel_size &&
      this.randomBucketDetails.warehouse_id &&
      this.randomBucketDetails.warehouse_name
    ) {
      this.is_valid = true;
    } else {
      this.is_valid = false;
    }
  }

  ngOnInit() {}
}
