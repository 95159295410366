import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripUnderscores'
})
export class UnderScoresPipe implements PipeTransform {
  transform(value: any) {
    const no_scores =  value.replace(/_/g, ' ');
    return no_scores;
  }
}
