<app-modal
  [open]="true"
  [actions]="actions"
  [highlightPrimaryAction]="true"
  [size]="ModalSizes.XLarge"
  (handleAction)="handleAction($event)"
>
  <div class="modal-content">
    <heading [icon]="iconTypes.Steering" [color]="uiColors.Blue">
      <h4 class="heading-title">Assign a Driver</h4>
      <div class="controls">
        <ng-container class="optimize" *ngIf="!consignments && environment.envName === 'onthedot'">
          Send Route to Device:
          <input type="checkbox" (change)="optimizeFlag = !optimizeFlag" [checked]="optimizeFlag" />
        </ng-container>
        <ng-container class="optimize" *ngIf="environment.envName !== 'onthedot'">
          LIMIT TO WAREHOUSE:
          <input type="checkbox" (change)="toggleLimitWarehouse()" [checked]="limit_warehouse" />
        </ng-container>
      </div>
    </heading>
    <div class="modal-body">
      <heading
      [showToggle]="true"
      (click)="contractorCollapsible.toggle()"
      >
        <h4 class="heading-title">
            <strong>Contract drivers </strong>
        </h4>
      </heading>
      <div class="collapsible" appCollapsible [expanded]="true" #contractorCollapsible="appCollapsible">
        <app-table
        [columns]="driverTableColumns"
        [dataMap]="driverTableDataMap"
        [rawData]="paginatedContractors"
        [rowsSelectable]="false"
        [primaryActions]="driverPrimaryActions"
        (actionTaken)="respondToTableAction($event)"
        [zeroDataMessage]="'No Drivers Found'"
        [isLoading]="!all_drivers"
        (paginate)="handlePagination($event, all_drivers, contractorPaginationConfig, DriverType.Contractor)"
        [defaultDisplayAmount]="contractorPaginationConfig.defaultDisplayAmount"
        [showPagination]="true"
        [paginationDisplayAmounts]="contractorPaginationConfig.paginationDisplayAmounts"
        [currentPageIndex]="contractorPaginationConfig.currentPageIndex + 1"
        [totalPages]="contractorPaginationConfig.totalPages"
        [displayPageCount]="true"
        >
        </app-table>
      </div>
      <heading
      [showToggle]="true"
      *ngIf="user.is_admin"
      >
        <h4 class="heading-title">
            <strong>Picup Plus drivers </strong>
        </h4>
      </heading>
      <div *ngIf="user.is_admin" class="collapsible" appCollapsible [expanded]="true" #plusCollapsible="appCollapsible">
        <app-table
        [columns]="plusDriverTableColumns"
        [dataMap]="plusDriverTableDataMap"
        [rawData]="paginatedPlusDrivers"
        [rowsSelectable]="false"
        [primaryActions]="driverPrimaryActions"
        (actionTaken)="respondToTableAction($event)"
        [zeroDataMessage]="'No Drivers Found'"
        [isLoading]="plusDriverLoading"
        (paginate)="handlePagination($event, plus_drivers, plusDriverPaginationConfig, DriverType.PlusDriver)"
        [defaultDisplayAmount]="plusDriverPaginationConfig.defaultDisplayAmount"
        [showPagination]="true"
        [paginationDisplayAmounts]="plusDriverPaginationConfig.paginationDisplayAmounts"
        [currentPageIndex]="plusDriverPaginationConfig.currentPageIndex + 1"
        [totalPages]="plusDriverPaginationConfig.totalPages"
        [displayPageCount]="true"
        >
        </app-table>
      </div>
      <div class="search">
        <h4>Search all drivers:</h4>
        <div class="search-table" *ngIf="driverSearchData">
          <app-table
            [columns]="driverTableColumns"
            [tableData]="driverSearchData"
            [zeroDataMessage]="'No Drivers Match the Search Criteria'"
            [rowsSelectable]="false"
            [primaryActions]="driverPrimaryActions"
            (actionTaken)="respondToTableAction($event)"
          >
          </app-table>
        </div>
        <div class="search">
          <input-field
            [type]="'text'"
            (valueChanged)="driverQueryString = $event"
            [value]="driverQueryString"
            [label]="'Driver Name'"
            (keydown.enter)="findDriverByName()"
          ></input-field>
          <app-basic-button [title]="'Search'" [type]="buttonTypes.Highlight" [active]="true" (click)="findDriverByName()">
          </app-basic-button>
        </div>
      </div>
    </div>
  </div>
</app-modal>
