import { Component, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { NewTripsService } from 'app/dashboard/new-trips/new-trips.service';
import { OrderFrontEndStateEnum } from 'app/dashboard/post-dispatch/post-dispatch.interfaces';
import { OrderStateEnum, OrderSummary } from 'app/operations-tracking/operations-tracking.interface';
import { OperationsTrackingService } from 'app/operations-tracking/operations-tracking.service';
import { NotificationsService } from 'app/shared/notifications/notifications.service';
import { Subscription, combineLatest } from 'rxjs';
import { OrderTrackingComponent } from '../order-tracking/order-tracking.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-staged-assignments',
  templateUrl: './staged-assignments.component.html',
  styleUrls: ['./staged-assignments.component.scss']
})
export class StagedAssignmentsComponent extends OrderTrackingComponent implements OnDestroy {
  stagedAssignmentSubscription: Subscription;
  dataSource = new MatTableDataSource<OrderSummary>([]);
  OrderStateEnum = OrderStateEnum;
  constructor(
    public newTripsService: NewTripsService,
    public notificationsService: NotificationsService,
    public operationsTrackingService: OperationsTrackingService,
    public store: Store
  ) {
    super(notificationsService, operationsTrackingService, store, newTripsService);
    this.operationsTrackingService.usedIds$.pipe(takeUntil(this.unsubscribe$)).subscribe((ids: string[]) => {
      this.selectedIds = ids;
      this.getStagedOrders();
    });
  }

  ngOnDestroy(): void {
    this.stagedAssignmentSubscription?.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getStagedOrders(): void {
    const batches = this.getFirestoreBatchesForOrders(OrderFrontEndStateEnum.StagedAssignment);
    this.stagedAssignmentSubscription?.unsubscribe();
    this.stagedAssignmentSubscription = combineLatest(batches).subscribe((res) => {
      let orders: OrderSummary[] = [];
      orders = orders.concat(...res);
      this.dataSource.data = orders;
    });
  }
}
