import { AuthService } from 'app/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  showBanner = false;
  loading = false;
  constructor(private authService: AuthService) {
    this.authService.loading.subscribe((next) => {
      this.loading = next;
    });
  }

  ngOnInit() {
    if (environment.envName === 'Production') {
      // this.showBanner = true;
    }
  }
}
