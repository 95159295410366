import { Component, OnInit, Input, ViewChildren, Output, EventEmitter } from '@angular/core';
import { NotesTypeEnum } from 'app/dashboard/notes/notes.constants';

@Component({
  selector: 'app-new-trips-notes',
  templateUrl: './new-trips-notes.component.html',
  styleUrls: ['./new-trips-notes.component.scss'],
})
export class NewTripsNotesComponent implements OnInit {
  @Input() user;
  @Input() orderDetails;
  @Input() fsOrderDetails;
  @Input() orderId;
  @Input() orderNotes;
  @ViewChildren('content') children;
  @Output() notesViewed = new EventEmitter<any>();
  notesTypes = NotesTypeEnum;

  constructor() {}

  ngOnInit(): void {}
}
