import { Component, Input, OnDestroy } from '@angular/core';
import { NotesTypeEnum } from '../notes.constants';
import { NotesService } from '../notes.service';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { Moment } from 'moment';
import moment from 'moment';

@Component({
  selector: 'app-notes-modal',
  templateUrl: './notes-modal.component.html',
  styleUrls: ['./notes-modal.component.scss']
})
export class NotesModalComponent implements OnDestroy {

  @Input() set id (id: string) {
    this.orderId = id
    this.notesService.getOrderNotesActualPt3(id);
  }
  @Input() notesType: NotesTypeEnum;
  @Input() isAdmin: boolean;

  buttonTypes = ButtonTypes;
  iconTypes = IconTypes;
  uiColors = UiColors;
  UiThemes = UiThemes;
  orderId: string = ''
  readonly orderNotes$ = this.notesService.orderNotes$;
  readonly loadingNotes$ = this.notesService.loadingNotes$;


  constructor(private notesService: NotesService) {}

  ngOnDestroy(): void {
    this.notesService.orderNotes$.next(null);
  }

  openOrder(): void{
    window.open(window.location.origin + '/dashboard/orders/trip/' + this.orderId, '_blank')
  }

  convertTime(timestamp: any): string | Moment {
    if (!timestamp) {
      return 'Now';
    } else {
      return moment(timestamp.seconds, 'X').format('lll');
    }
  }

}
