import { Component, Input } from '@angular/core';
import {BucketState} from '../../../buckets/buckets.interfaces';

@Component({
  selector: 'app-importing',
  templateUrl: './importing.component.html',
  styleUrls: ['./importing.component.scss']
})
export class ImportingComponent {
  @Input() state: BucketState;
}
