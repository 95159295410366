import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

// Modules
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule, BUCKET } from '@angular/fire/storage';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { DashboardModule } from './dashboard/dashboard.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { DefaultSimpleModalOptionConfig, defaultSimpleModalOptions, SimpleModalModule } from 'ngx-simple-modal';

// Environments
import { environment } from '../environments/environment';

// Services
import { AuthGuard } from './auth/auth.guard';
import { AuthService } from './auth/auth.service';
import { NotificationsService } from './shared/notifications/notifications.service';

// Components
import { AppComponent } from './app.component';
import { NotificationsComponent } from './shared/notifications/notifications.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LoginComponent } from './login/login.component';
import { AngularFirestore, AngularFirestoreModule } from '@angular/fire/firestore';
import { AlertsService } from './shared/alerts/alerts.service';
import { AdminGuard } from './auth/admin.guard';
import { StoreModule } from '@ngrx/store';
import { authReducer } from './auth/auth.reducer';
import { DirectivesModule } from './directives/directives.module';
import { AuthEffects } from './auth/auth.effects';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TrackingTableComponent } from './sms-tracking/tracking-table/tracking-table.component';
import { DriverRatingComponent } from './sms-tracking/driver-rating/driver-rating.component';
import { ToastsComponent } from './shared/toast/toast.component';
import { ToastService } from './shared/toast/toast.service';
import { SmsTrackingComponent } from './sms-tracking/sms-tracking.component';
import { CourierPhonePipe } from './sms-tracking/courier-phone.pipe';
import { GraphQLModule } from './graphql.module';
import { LoginMainComponent } from './login/components/login-main/login-main.component';
import { SignUpComponent } from './login/components/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './login/components/forgot-password/forgot-password.component';
import { CourierOrderMapComponent } from './sms-tracking/courier-order-map/courier-order-map.component';
import { SpecialCourierFinalScreenComponent } from './sms-tracking/special-courier-order-final-screen/special-courier-final-screen.component';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { ErrorInterceptor } from './auth/error.interceptor';

// Pretend this is dynamically injected at runtime
const initialStateFromSomewhere = JSON.parse(localStorage.getItem('business_options'));

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
];

export const initialState: any = {
  businessOptions: null,
  isAuthenticated: false,
};
// In this function dynamic state slices, if they exist, will overwrite static state at runtime.
export function getInitialState() {
  return { ...initialState, ...initialStateFromSomewhere };
}

@NgModule({
  declarations: [
    AppComponent,
    NotificationsComponent,
    ToastsComponent,
    ResetPasswordComponent,
    LoginComponent,
    LoginMainComponent,
    ResetPasswordComponent,
    TrackingTableComponent,
    DriverRatingComponent,
    SmsTrackingComponent,
    CourierPhonePipe,
    SignUpComponent,
    ForgotPasswordComponent,
    CourierOrderMapComponent,
    SpecialCourierFinalScreenComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    SharedModule,
    DashboardModule,
    AppRoutingModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireModule.initializeApp(environment.firebase),
    SimpleModalModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    AngularFirestoreModule,
    DirectivesModule.forRoot(),
    EffectsModule.forRoot([AuthEffects]),
    StoreModule.forRoot(
      {
        auth: authReducer,
      },
      { initialState: getInitialState() }
    ),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    GraphQLModule,
  ],
  providers: [
    AuthService,
    AuthGuard,
    AdminGuard,
    NotificationsService,
    ToastService,
    AlertsService,
    AngularFirestore,
    { provide: BUCKET, useValue: environment.firebase.storageBucket },
    {
      provide: DefaultSimpleModalOptionConfig,
      useValue: { ...defaultSimpleModalOptions, ...{ closeOnClickOutside: true, closeOnEscape: true } },
    },
    httpInterceptorProviders
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
