<div class="dialog-container">
    <div>
        <div class="header-container">
            <h3>
            Processing Orders: {{data.data.length}}/{{totalOrders}}
            </h3>
            <div [hidden]="processingOrders">
                <button mat-icon-button mat-dialog-close><mat-icon>close</mat-icon></button>
            </div>
        </div>
        <div>
            {{data.data.length < totalOrders ? 'Please wait while the orders are being processed:' : 'All orders have been processed'}}
        </div>
    </div>
    <!-- table goes here -->
    <div class="table-container">
        <table mat-table [dataSource]="data">
    <!-- Position Column -->
    <ng-container matColumnDef="time">
        <th mat-header-cell *matHeaderCellDef> Time </th>
        <td mat-cell *matCellDef="let element"> {{element.time | date: 'HH:mm:ss'}}  </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> Order Id </th>
        <td mat-cell *matCellDef="let element"> <div class="id-overflow" [matTooltip]="element.id" (click)="copyId(element.id)">{{element.id}}</div> </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element" [ngClass]="element.success ? 'bg-green' : 'bg-red'"> <mat-icon>{{element.success | statusIcon}}</mat-icon> </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="message">
        <th mat-header-cell *matHeaderCellDef> Message </th>
        <td mat-cell *matCellDef="let element"> {{element.message}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    </div>
</div>