import { Component, Input, OnChanges, OnInit } from '@angular/core';
import * as moment from 'moment';

import { Bucket, BucketState, BucketStatuses, BucketStatesUi } from '../../buckets.interfaces';
import { getBucketState, getBucketUiState } from '../../buckets.helpers';

export type StatusSizes = 'small' | 'medium' | 'large';
export interface StatusSizesObj {
  small: StatusSizes;
  medium: StatusSizes;
  large: StatusSizes;
}

export const statusSizes: StatusSizesObj = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

// TODO: Get this logic from the server:
const HOURS_BEFORE_CLOSING_TO_FLAG = 5;
const HOURS_BEFORE_SHIFT_TO_CLOSE = 1;
const differenceInHours = (time) => moment().diff(time, 'hours');

@Component({
  selector: 'app-bucket-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent implements OnChanges, OnInit {
  @Input() bucket: Bucket;
  @Input() size: StatusSizes = statusSizes.medium;
  @Input() state: BucketState;

  currentStatus: BucketStatesUi | boolean;
  statusObj: BucketState;
  isInProgress: boolean;
  staging = true;

  ngOnChanges() {
    if (this.bucket) {
      this.statusObj = !this.state ? getBucketState(this.bucket) : this.state;
      this.currentStatus = getBucketUiState(this.statusObj);
      this.isInProgress = this.inProgress();
    }
  }

  ngOnInit() {
    if (this.bucket) {
      this.staging = false;
    }
  }

  inProgress() {
    return (
      [BucketStatuses.Enqueued, BucketStatuses.Initializing, BucketStatuses.Processing, BucketStatuses.Polling].indexOf(
        this.statusObj.status
      ) > -1
    );
  }

  closed() {
    if (!this.staging) {
      return differenceInHours(this.bucket.shift_start_date_time) >= -1 * HOURS_BEFORE_SHIFT_TO_CLOSE;
    } else {
      return true;
    }
  }

  open() {
    if (!this.staging) {
      return !this.closed() && !this.closingSoon();
    }
  }

  closingSoon() {
    if (!this.staging) {
      return (
        !this.closed() &&
        differenceInHours(this.bucket.shift_start_date_time) >=
          -1 * (HOURS_BEFORE_SHIFT_TO_CLOSE + HOURS_BEFORE_CLOSING_TO_FLAG)
      );
    }
  }

  alert() {
    return this.currentStatus === 'Alert';
  }

  closingTime() {
    if (!this.staging) {
      return moment(this.bucket.shift_start_date_time).subtract('hours', 1);
    }
  }
}
