import { AddressBookEntry } from './../../shared.interfaces';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { ModalSizes, ModalAction } from 'app/shared/modals/modal.interfaces';
import { GridJustifyItems } from 'app/shared/grid-container/grid-container.interfaces';
import { JustifyContent } from 'app/shared/flex-container/flex-container.interfaces';
import { lightMapStyle } from 'app/shared/map/map.constants';
import { PhoneValidationPipe } from 'app/shared/pipes/phone-validation.pipe';
import { MapDrawingHelperDirective } from 'app/shared/map-drawing-helper/map-drawing-helper.directive';

@Component({
  selector: 'app-edit-address',
  templateUrl: './edit-address.component.html',
  styleUrls: ['./edit-address.component.scss'],
})
export class EditAddressComponent extends SimpleModalComponent<any, AddressBookEntry> implements OnInit {
  public IconTypes = IconTypes;
  public UiColors = UiColors;
  public UiThemes = UiThemes;
  public ModalSizes = ModalSizes;
  public GridJustifyItems = GridJustifyItems;
  public justifyContent = JustifyContent;
  public actions: ModalAction[] = [];


  address_model: AddressBookEntry = {
    address_book_id: null,
    description: null,
    name: null,
    phone: null,
    email: null,
    formatted_address: null,
    latitude: null,
    longitude: null,
    complex: null,
    unit_no: null,
    country: null,
    country_short_code: null,
    postal_code: null,
    suburb: null,
  };
  edit = false;
  valid = true;
  errors: string[] = [];


  mapOptions: google.maps.MapOptions = {
    center: {lat: 0, lng: 0},
    scrollwheel: false,
    styles: lightMapStyle,
    zoomControl: false,
    streetViewControl: false,
    clickableIcons: false,
    disableDefaultUI: true,
    mapTypeControl: false
  }
  mapElement: any;
  @ViewChild('map', { static: false }) gMap: any;

  constructor(private phoneValidationPipe: PhoneValidationPipe) {
    super();
  }

  ngOnInit(): void {
    if (this.address_model?.description) {
      this.edit = true;
      this.actions = [
        { name: 'cancel', title: 'Cancel' },
        { name: 'editAddress', title: 'Edit Address', isPrimary: true },
      ];
      this.mapOptions.center = { lat: this.address_model.latitude, lng: this.address_model.longitude } as unknown as google.maps.LatLng;
    } else {
      this.edit = false;
      this.actions = [
        { name: 'cancel', title: 'Cancel' },
        { name: 'addAddress', title: 'Add Address', isPrimary: true },
      ];
    }
  }

  public mapElementReady(): void {
    if (this.gMap) {
      this.mapElement = this.gMap.googleMap;
      if(this.mapOptions.center){
        MapDrawingHelperDirective.createMapMarker(this.mapElement, this.mapOptions.center as google.maps.LatLng);
      }
    }
  }

  handleAction($event: string): void {
    switch ($event) {
      case 'cancel':
        this.result = undefined;
        this.close();
        break;
      case 'addAddress':
        this.validateEntry().then((valid) => {
          if (valid) {
            this.result = this.address_model;
            this.close();
          } else {
            return;
          }
        });
        return;
      case 'editAddress':
        this.validateEntry().then((valid) => {
          if (valid) {
            this.result = this.address_model;
            this.close();
          } else {
            return;
          }
        });
        break;
    }
    this.close();
  }

  onPlacesChange(address: google.maps.places.PlaceResult): void {
    this.address_model.formatted_address = address.formatted_address;
    this.address_model.latitude = address.geometry.location.lat();
    this.address_model.longitude = address.geometry.location.lng();
    this.mapOptions.center = { lat: address.geometry.location.lat(), lng: address.geometry.location.lng() } as unknown as google.maps.LatLng;

    address.address_components.forEach((component) => {
      // complex
      if (component.types.includes('point_of_interest') || component.types.includes('establishment')) {
        this.address_model.complex = component.short_name;
      }
      if (component.types.includes('sublocality')) {
        // suburb
        this.address_model.suburb = component.short_name;
      }
      if (component.types.includes('country')) {
        // country
        this.address_model.country = component.long_name;
        this.address_model.country_short_code = component.short_name;
      }
      if (component.types.includes('postal_code')) {
        // postalcode
        this.address_model.postal_code = component.short_name;
      }
    });
  }

  async validateEntry(): Promise<boolean> {
    this.valid = true;
    this.errors = [];
    if (this.checkValue(this.address_model.description)) {
      this.valid = false;
      this.errors.push('Missing Description');
    }
    if (this.checkValue(this.address_model.name)) {
      this.valid = false;
      this.errors.push('Missing Name');
    }
    if (this.checkValue(this.address_model.latitude) || this.checkValue(this.address_model.formatted_address)) {
      this.valid = false;
      this.errors.push('Please select a valid address');
    }
    if (!this.phoneValidationPipe.transform(this.address_model.phone)) {
      this.valid = false;
      this.errors.push('Please supply a valid phone number');
    }

    if (!this.validateEmail(this.address_model.email)) {
      this.valid = false;
      this.errors.push('Please use a valid email address');
    }
    return this.valid;
  }

  checkValue(value: string | number): boolean {
    if (value === '' || value === undefined || value === null) {
      return true;
    } else {
      return false;
    }
  }

  checkLength(value: string, length: number): boolean {
    if (!value || value.length < length) {
      return true;
    } else {
      return false;
    }
  }

  validateEmail(email: string): boolean {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

}
